<!--渠道设置-->
<template>
    <div class="main-full-content">
        <el-form class="no_margin" ref="form" :model="queryForm" label-width="120px" size="medium">
            <el-card class="box-card">
                <el-row>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="起运港">
                            <f-select v-model="queryForm.pol"
                                      :is-need="queryForm.pol"
                                      placeholder="请选择" filterable clearable :dict="'bd_port'">
                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="目的港">
                            <f-select v-model="queryForm.podEnd"
                                      :is-need="queryForm.podEnd"
                                      placeholder="请选择" filterable clearable :dict="'bd_port'">
                            </f-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>
        </el-form>
        <el-card class="box-card last_card">
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="73vh"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange">
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'action'">
                    </template>
                    <template v-else-if="scope.column.property === 'shipCompanyId'">
                        <span>{{parseDict(scope.row.shipCompanyId,'ship_company')}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'pol'">
                        <span>{{parseDict(scope.row.pol,'bd_port')}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'transferPort'">
                        <span>{{parseDict(scope.row.transferPort,'bd_port')}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'podEnd'">
                        <span>{{parseDict(scope.row.podEnd,'bd_port')}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'isFling'">
                        <span>{{parseDict(scope.row.isFling,'is_default')}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'isJump'">
                        <span>{{parseDict(scope.row.isJump,'is_default')}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'isAms'">
                        <span>{{parseDict(scope.row.isAms,'is_default')}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'isAmsMore'">
                        <span>{{parseDict(scope.row.isAmsMore,'is_default')}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'isCangdan'">
                        <span>{{parseDict(scope.row.isCangdan,'is_default')}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'operateId'">
                        <span>{{parseDict(scope.row.operateId,'operationsId')}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'operateSure'">
                        <span>{{parseDict(scope.row.operateSure,'is_default')}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'channelId'">
                        <span>{{parseDict(scope.row.channelId,'channelId')}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'supplierId'">
                        <span>{{parseDict(scope.row.supplierId,'bookAgent_id')}}</span>
                    </template>

                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange">
                </el-pagination>
            </el-row>
        </el-card>

    </div>
</template>

<script>
    import QueryMixins from "../../mixins/QueryMixins";
    import {deleteAction, getAction, postAction, putAction} from '@/api/manage'
    import {validateCmb, validateShuliang} from "../../utils/validator";

    export default {
        name: 'Potential',
        mixins: [QueryMixins],
        data() {
            return {
                editStartOptions: {
                    disabledDate: time => {
                        if (!this.queryForm.createTimeEnd) {
                            // time = new Date(time.replace(/-/g, '/'));
                            return time.getTime() < new Date(1970 - 1 - 1).getTime();   //禁止选择1970年以前的日期
                        } else {
                            return time.getTime() > new Date(this.queryForm.createTimeEnd);
                        }
                    }
                },
                dictCodes: ['ship_company', 'bd_port', 'is_default', 'operationsId', 'channelId','bookAgent_id'],

                ids: [],
                row: {},
                form: {},
                formLabelWidth: '150px',
                columns: [
                    {
                        prop: 'surplusSo',
                        label: '剩余仓位',
                        width: 140,
                        fixed: 'left'
                    },
                    {
                        prop: 'name',
                        label: '促销命名',
                        width: 140,
                        fixed: 'left'
                    },

                    {
                        prop: 'osLine',
                        label: '航线',
                        width: 100
                    },
                    {
                        prop: 'shipCompanyId',
                        label: '船司',
                        width: 150
                    },
                    {
                        prop: 'pol',
                        label: '起运港',
                        width: 100
                    },
                    {
                        prop: 'transferPort',
                        label: '中转港',
                        width: 100
                    },
                    {
                        prop: 'podEnd',
                        label: '目的港',
                        width: 100
                    },
                    {
                        prop: 'size',
                        label: '柜型',
                        minWidth: 140
                    },
                    {
                        prop: 'loadingTime',
                        label: '装柜日期',
                        sortable: 'custom',
                        minWidth: 140
                    },
                    {
                        prop: 'jieGoodTime',
                        label: '截货日期',
                        sortable: 'custom',
                        minWidth: 140
                    },
                    {
                        prop: 'etd',
                        label: 'ETD',
                        sortable: 'custom',
                        minWidth: 100
                    },
                    {
                        prop: 'shipNo',
                        label: '船名航次',
                        minWidth: 200,
                    },
                    {
                        prop: 'startPortTime',
                        label: '开港日期',
                        sortable: 'custom',
                        minWidth: 90
                    },
                    {
                        prop: 'delayTime',
                        label: '延船日期',
                        sortable: 'custom',
                        minWidth: 140
                    },
                    {
                        prop: 'isFling',
                        label: '是否甩柜',
                        minWidth: 120
                    },
                    {
                        prop: 'isJump',
                        label: '是否跳港',
                        minWidth: 120
                    },
                ],
                url: {
                    list: '/sys/channelShipManage/saleList',
                },
            }
        },
        methods: {
        }, created() {

        }
    }
</script>
<style lang="scss" scoped>
    .addEditBox {
        max-height: 625px;
        overflow: scroll;
    }
</style>
