<template>
    <div class="main-full-content">

        <el-form class="multiline_select" ref="form" :model="queryForm" label-width="80px" size="medium">
            <el-card class="box-card">
                <el-row class="inputs_row">
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="柜号">
                            <el-input v-model.trim="queryForm.containerNo" @keyup.enter.native="handleCheck"
                                      type="textarea"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="开船时间">
                            <el-date-picker
                                    v-model="btime"
                                    type="datetimerange"
                                    size="small"
                                    range-separator="至"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期"
                                    :picker-options="editStopOptions"
                                    :default-time="['00:00:00', '23:59:59']"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    format="yyyy-MM-dd HH:mm:ss"
                                    unlink-panels
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>

                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="周期">
                            <el-input v-model="queryForm.containerWeekName" type="textarea"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="周数">
                            <el-date-picker
                                    id="getWhichWeek"
                                    v-model="picker.whichWeek"
                                    type="week"
              :format="formatWeekStr"
              placeholder="选择周"
              :picker-options="{'firstDayOfWeek' : 1}"
              @change="changeWhichWeek"
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :md="4" :sm="24">
                        <el-form-item label="国家">
                            <f-select :dict="'bd_country'"
                                      v-model="queryForm.country"
                                      :isNeed="queryForm.country"
                            >
                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="柜子状态">
                            <f-select :dict="'circulation_state'"
                                      v-model="queryForm.circulationState"
                                      :isNeed="queryForm.circulationState"
                            ></f-select>
                        </el-form-item>
                    </el-col>
                  <el-col :xs="24" :sm="12" :md="12" :lg="8">
                    <el-form-item label="锁柜时间">
                      <el-date-picker
                          v-model="lctime"
                          type="datetimerange"
                          size="small"
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                          :picker-options="editStopOptions"
                          :default-time="['00:00:00', '23:59:59']"
                          value-format="yyyy-MM-dd HH:mm:ss"
                          format="yyyy-MM-dd HH:mm:ss"
                          unlink-panels
                      >
                      </el-date-picker>
                    </el-form-item>
                  </el-col>



                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="3">
                        <el-form-item label-width="30px" style="width: 500px">
                                                        <el-button
                                                            type="primary"
                                                            icon="el-icon-search"
                                                            @click="handleCheck"
                                                        >查询
                                                        </el-button
                                                        >
                            <el-button type="primary" @click="handleReset">重置条件</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>
        </el-form>

        <el-card class="box-card last_card">
            <el-row style="display: inline">

                <div>
                    <el-col>
                        <div class="f-p-b-15">
                            LCL：
                            <el-button
                                size="medium"
                                type="primary"
                                @click="exportLclContainerProfitExcelPlus('0',url.downloadLclConProfit,'柜子利润_'+ new Date().toLocaleDateString())"
                                v-if="buttonShowList.dclclgzlrhzb">
                                导出LCL柜子利润汇总表
                            </el-button>

                            <el-button
                                size="medium"
                                type="primary"
                                @click="exportLclContainerProfitExcelPlus('0',url.downloadLclOrderProfit,'LCL订单利润_'+ new Date().toLocaleDateString())"
                                v-if="buttonShowList.dclclddlrmxb">
                                导出LCL订单利润明细表
                            </el-button>

                            <el-divider direction="vertical"></el-divider>
                            FCL：
                            <el-button
                                size="medium"
                                type="primary"
                                @click="exportLclContainerProfitExcelPlus('1',url.downloadLclConProfit,'FCL柜子利润_'+ new Date().toLocaleDateString())"
                                v-if="buttonShowList.dcfclgzlrhzb"
                            >导出FCL柜子利润汇总表
                            </el-button>

                            <el-button size="medium" type="primary"
                                       @click="exportLclContainerProfitExcelPlus('1',url.downloadLclOrderProfit,'FCL订单利润_'+ new Date().toLocaleDateString())"
                                       v-if="buttonShowList.dcfclddlrmxb"
                            >导出FCL订单利润明细表
                            </el-button>
                          <el-upload
                              style="margin-left: 5px"
                              class="upload-demo margin_right_10"
                              action=""
                              :http-request="importOperation"
                              :show-file-list="false"
                              :before-upload="beforeUpload"
                              accept=".xls,.xlsx"
                          >
                            <el-button type="primary" size="medium"

                            >导入应付异常原因</el-button>
                          </el-upload>
                          <el-button
                              style="margin-left: 5px"
                              type="primary"
                              size="medium"
                              @click="downloadModel(url.download, '柜子应付异常原因模板')"

                          >柜子应付异常原因模板</el-button>
                        </div>
                    </el-col>
                </div>


            </el-row>

                        <f-table
                            v-loading="loading"
                            ref="GTable"
                            border
                            size="medium"
                            row-key="id"
                            class="c-th-has-bgcolor"
                            :columns="columns"
                            :data="tableData"
                            :cell-style="{ 'text-align': 'center' }"
                            tableHeight="57vh"
                            :isRecordTableScroll="true"
                            @selection-change="handleSelectionChange"
                            @sort-change="handleTableChange"
                        >
                            <template v-slot="scope">

                                <template v-if="scope.column.property === 'containerWeekName'">
                                  <a
                                      style="color: #00a0e9"
                                      @click.prevent="$intoOperationCabinetEditPage(scope.row.containerId)"
                                  >{{ scope.row.containerWeekName }}</a>
                                </template>


                            </template>


                        </f-table>
                        <el-row class="f-text-right f-p-v-8">
                            <el-pagination
                                v-bind="ipagination"
                                layout="total, sizes, prev, pager, next, jumper"
                                @size-change="handleSizeChange"
                                @current-change="handleCurrentChange"
                            >
                            </el-pagination>
                        </el-row>
        </el-card>

    </div>
</template>
<script>

    import {getAction, postAction} from "@/api/manage";
    import QueryMixins from "@/mixins/QueryMixins";
    import {setUpRouteUtil} from '@/utils/util';


    export default {
        mixins: [QueryMixins],
        components: {},
        data() {
            return {
              isUsePost:true,
                // tableData:[],
                // queryForm:{},
                picker: {},
                disableMixinMounted: true,
                editStartOptions: {
                    disabledDate: (time) => {
                        if (!this.form.etime) {
                            // time = new Date(time.replace(/-/g, '/'));
                            return time.getTime() < new Date(1970 - 1 - 1).getTime(); //禁止选择1970年以前的日期
                        } else {
                            return time.getTime() > new Date(this.form.etime);
                        }
                    },
                },
                editStopOptions: {
                    disabledDate: (time) => {
                        // time = new Date(time.replace(/-/g, '/'));
                        return (
                            time.getTime() < new Date(this.form.stime) ||
                            time.getTime() < new Date(1970 - 1 - 1).getTime() //禁止选择1970年以前的日期
                        );
                    },
                },
                boatStatus: true,
                selectedRowKeys: [],
                selectionRows: [],
                selectedNos: [],
                loading: false,
                form: {},
                columns: [
                    // {
                    //     type: "selection",
                    //     width: 50,
                    // },
                    // {
                    //     prop: "action",
                    //     label: "操作",
                    //     width: 130,
                    // },
                    {
                        prop: "routeLeader",
                        label: "航线操作",

                    },
                    {
                        prop: "containerWeekName",
                        label: "周期数",
                    },
                    {
                        prop: "etdStr",
                        label: "开船时间",
                    },
                    {
                        prop: "isClear",
                        label: "是否清关",
                    },
                    {
                        prop: "endPort",
                        label: "提柜港口",
                    },
                    {
                        prop: "splitWarehouse",
                        label: "拆柜仓库",
                    },
                    {
                        prop: "containerNo",
                        label: "柜号",
                    },
                    {
                      prop: "receiveMoney",
                      label: "应收",
                    },
                    {
                      prop: "payMoney",
                      label: "应付",
                    },
                    {
                      prop: "profit",
                      label: "利润",
                    },
                  {
                    prop: "grossProfitRate",
                    label: "毛利率",
                  },
                  {
                    prop: "status",
                    label: "状态",
                  },
                  {
                    prop: "country",
                    label: "目的港国家",
                  },
                  {
                    prop: "payExceptionReason",
                    label: "应付异常原因",
                  },
                  {
                    prop: "lockContainerTime",
                    label: "锁柜时间",
                  },

                ],
                imgVisible: false,
                imgUrl: '',
                url: {
                    list: "/file/export/excel/container/profit/page",
                  importUrl:'/file/read/excel/importContainerNoWithReason',
                  download:'/file/read/excel/downloadContainerNoWithReasonModel',
                  //更新导出LCL柜子利润汇总表
                  downloadLclConProfit: '/file/export/excel/container/profit/sumData',
                  //更新导出LCL订单利润总表
                  downloadLclOrderProfit: '/file/export/excel/container/profit/sumOrderData',

                },

                rules: {
                    realCtn: [
                        {required: true, message: "请输入实际总件数", trigger: "blur"},
                        {pattern: /^[1-9]\d*$/, message: "只能输入整数", trigger: "blur"},
                    ],
                    realKg: [
                        {required: true, message: "请输入实际总重量", trigger: "blur"},
                        {
                            pattern:
                                /^(?=([1-9]{1,10}$|[0-9]{1,7}\.))(0|[1-9][0-9]*)(\.[0-9]{1,3})?$/,
                            message: "只能输入最多三位小数的数字",
                            trigger: "change",
                        },
                    ],
                },
                stime: [],
                otime: [],
                btime: [],  //开船时间
                ltime: [],  //锁定时间
                rtime: [],  //销账时间
              lctime:[],
              formatWeekStr:'',
            };
        },
        computed: {
          buttonShowList() {
            return {
              'dclclgzlrhzb':this.$btnIsShow('hyFinanceManage/modules/financialStatements/customerBill','0','导出LCL柜子利润汇总表'),
              'dclclddlrmxb':this.$btnIsShow('hyFinanceManage/modules/financialStatements/customerBill','0','导出LCL订单利润明细表'),
              'dcfclgzlrhzb':this.$btnIsShow('hyFinanceManage/modules/financialStatements/customerBill','0','导出FCL柜子利润汇总表'),
              'dcfclddlrmxb':this.$btnIsShow('hyFinanceManage/modules/financialStatements/customerBill','0','导出FCL订单利润明细表'),

            }
          }
        },
        created() {
            // this.queryForm.statementType = '0'
            this.$set(this.queryForm, 'statementType', '2')
        },
        methods: {
          //导入操作绑定
          importOperation(data) {
            console.log("上传文件", data);
            const formData = new FormData();
            formData.append("file", data.file);
            postAction(this.url.importUrl, formData)
                .then((res) => {
                  console.log("产品返回数据", res);
                  if (res.success) {
                    this.$message.success(res.message);
                    this.handleQuery();
                  }
                })
          },
          beforeUpload(file) {
            //对文件大小进行限制
            const isLt2M = file.size / 1024 / 1024 < 15;
            if (!isLt2M) {
              this.$message.warning("文件大小不能超过15MB");
            }
            const isFile = file.name.indexOf(".") !== -1;
            if (!isFile) {
              this.$message.warning("该文件名不合法");
            }
            return isLt2M && isFile;
          },
            exportLclContainerProfitExcelPlus(val, url, fileName) {
                if (val === '0') {
                    this.queryForm.containerType = '0'
                } else if (val === '1') {
                    this.queryForm.containerType = '1'
                }


                if (this.btime && this.btime.length > 1) {
                    this.queryForm.shipmentBoardS = this.btime[0];
                    this.queryForm.shipmentBoardE = this.btime[1];
                }else {
                  this.queryForm.shipmentBoardS = '';
                  this.queryForm.shipmentBoardE = '';
                }
              if (this.lctime && this.lctime.length > 1) {
                this.queryForm.lockContainerTimeS = this.lctime[0];
                this.queryForm.lockContainerTimeE = this.lctime[1];
              }else {
                this.queryForm.lockContainerTimeS = '';
                this.queryForm.lockContainerTimeE = '';
              }
                this.exportExcelPlus(url, fileName);
            },


            orderTypeChange() {
                this.boatStatus = !(this.queryForm.orderType === '0');
            },
            boatTimeChange() {
                this.boatStatus = !(this.queryForm.orderType === '0');
            },


            handleSelectionChange(selection) {
                // 多选
                console.log(selection);
                let arr = [];
                let nos = [];
                this.nameList = [];
                this.cnamelist = [];
                this.emailList = [];
                for (let i = 0; i < selection.length; i++) {
                    arr.push(selection[i]["id"]);
                    nos.push(selection[i]["orderNo"]);
                    this.nameList.push(selection[i]["username"]);
                    this.cnamelist.push(selection[i]["cname"]);
                    this.emailList.push(selection[i]["email"]);
                }
                this.selectionRows = selection;
                this.selectedRowKeys = [...arr];
                this.selectedNos = [...nos];
            },
            handleReceive() {
                console.log("申请收款以下id的费用", this.selectedRowKeys);
            },
            handleReset() {
                this.queryForm = {};
                this.otime = "";
                this.stime = "";
                this.btime = "";
                this.ltime = "";
                this.rtime = "";
                this.lctime = "";
                this.ipagination.currentPage = 1;
                this.boatStatus = true
                this.queryForm.statementType = '0'
            },
            handleCheck() {
                this.loading = true;
                //清空 自拼 非拼的条件
                this.queryForm.containerType = '';
                if (this.stime && this.stime.length > 1) {
                    this.queryForm.settleCreateTimeS = this.stime[0];
                    this.queryForm.settleCreateTimeE = this.stime[1];
                } else {
                    this.queryForm.settleCreateTimeS = '';
                    this.queryForm.settleCreateTimeE = '';
                }
                if (this.otime && this.otime.length > 1) {
                    this.queryForm.orderCreateTimeS = this.otime[0];
                    this.queryForm.orderCreateTimeE = this.otime[1];
                } else {
                    this.queryForm.orderCreateTimeS = '';
                    this.queryForm.orderCreateTimeE = '';
                }
              if (this.btime && this.btime.length > 1) {
                this.queryForm.shipmentBoardS = this.btime[0];
                this.queryForm.shipmentBoardE = this.btime[1];
              }else {
                this.queryForm.shipmentBoardS = '';
                this.queryForm.shipmentBoardE = '';
              }
              if (this.lctime && this.lctime.length > 1) {
                this.queryForm.lockContainerTimeS = this.lctime[0];
                this.queryForm.lockContainerTimeE = this.lctime[1];
              }else {
                this.queryForm.lockContainerTimeS = '';
                this.queryForm.lockContainerTimeE = '';
              }
                // if (this.btime && this.btime.length > 1) {
                //     this.queryForm.boatTimeS = this.btime[0];
                //     this.queryForm.boatTimeE = this.btime[1];
                // }else{
                //     this.queryForm.boatTimeS = '';
                //     this.queryForm.boatTimeE = '';
                // }
                // if (this.ltime && this.ltime.length > 1) {
                //     this.queryForm.lockTimeS = this.ltime[0];
                //     this.queryForm.lockTimeE = this.ltime[1];
                // }else{
                //     this.queryForm.lockTimeS = '';
                //     this.queryForm.lockTimeE = '';
                // }
                // if (this.rtime && this.rtime.length > 1) {
                //     this.queryForm.removeTimeS = this.rtime[0];
                //     this.queryForm.removeTimeE = this.rtime[1];
                // }else{
                //     this.queryForm.removeTimeS = '';
                //     this.queryForm.removeTimeE = '';
                // }
                let form = {...this.queryForm, ...this.filterForm};
                for (let item in form) {
                    if (typeof form[item] === "string") {
                        form[item] = form[item].trim();
                        if (form[item] === "") {
                            form[item] = null;
                        }
                    }
                }
                const {prop, order} = this.isorter;
                // 传入的参数
                const params = {
                    ...form,
                    column: prop,
                    order: order.indexOf("asc") !== -1 ? "asc" : "desc",
                };
                //判断 是否要查询startTime

                if (!params.column) {
                    if (this.queryForm.column === "startTime") {
                        this.$set(params, "column", "startTime");
                    } else {
                        this.$set(params, "column", "createTime");
                    }
                    //提货中查询
                    if (this.queryForm.column === "distributionTime") {
                        this.$set(params, "column", "distributionTime");
                    }
                }
                if (this.ipagination) {
                    params.pageNo = this.ipagination.currentPage;
                    params.pageSize = this.ipagination.pageSize;
                }
                console.log("查询对象", params);

                postAction(this.url.list, params)
                    .then((res) => {
                        if (res.success) {
                            this.$refs.GTable && this.$refs.GTable.handleScrollTop && this.$refs.GTable.handleScrollTop();
                            const {records, total} = res.result;
                            this.tableData = records || res.result;

                            this.ipagination &&
                            (this.ipagination.total = total || res.result.length);
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            onChange() {

                this.$nextTick(() => {
                    let a = document.querySelector("#getWeek").value
                    // this.queryForm.whichWeek = JSON.stringify(JSON.stringify(a))
                    this.queryForm.whichWeek = a
                })
            },
            changeWhichWeek(){
      const now = new Date(this.picker.whichWeek);
    const day = now.getDay() || 7; // 如果getDay()返回0（代表周日），则将其视为7
    const monday = new Date(now.getTime() - (day - 1) * 24 * 60 * 60 * 1000); // 周一
    const sunday = new Date(now.getTime() + (7 - day) * 24 * 60 * 60 * 1000); // 周日

    // 使用ISO周数和年份
    monday.setHours(0, 0, 0, 0); // 设置时间为午夜，以确保准确性
    const isoYear = this.getISOYear(monday);
    const isoWeek = this.getISOWeek(monday);

    const startTime = this.$momonet(monday).format('MM.DD'); // 周一日期
    const endTime = this.$momonet(sunday).format('MM.DD'); // 周日日期

    console.log("选择周期的开始日期：" + startTime + "，结束日期：" + endTime);
    this.whichWeekStr = "(" + startTime + "-" + endTime + ")";
    this.formatWeekStr = isoYear + "-" + (isoWeek < 10 ? '0' + isoWeek : isoWeek) + '周'
    console.log(this.formatWeekStr,'this.formatWeekStr');
    

    this.$nextTick(() => {
        let a = document.querySelector("#getWhichWeek").value;
        this.queryForm.whichWeek = a
    });

    },
    getISOYear(date) {
    const year = date.getFullYear();
    const weekNumber = this.getISOWeek(date);
    if (weekNumber === 52 && date.getMonth() === 0 && date.getDate() <= 7) {
        return year - 1;
    } else if (weekNumber === 1 && date.getMonth() >= 11 && date.getDate() >= 29) {
        return year + 1;
    } else {
        return year;
    }
},

getISOWeek(date) {
    const targetThursday = new Date(date);
    const dayNumber = (date.getDay() + 6) % 7; // 星期一为0，星期日为6；转换为星期一为1，星期日为7
    targetThursday.setDate(date.getDate() - dayNumber + 3); // 星期四
    const firstThursday = new Date(targetThursday.getFullYear(), 0, 1);
    if (firstThursday.getDay() !== 4) {
        firstThursday.setDate(1 + (4 - firstThursday.getDay() + 7) % 7);
    }
    const weeks = 1 + Math.round((targetThursday - firstThursday) / 604800000); // 604800000: milliseconds per week
    return weeks;
},
        },

    };
</script>
<style scoped>
    .el-button {
        user-select: unset;
    }
</style>
