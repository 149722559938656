<template>
    <el-dialog
            title="添加预约出库记录"
            :visible="visible"
            width="30%"
            custom-class="c-custom-dialog"
            append-to-body
            :before-close="handleClose"
            append-to-body
            :close-on-click-modal="false">
        <el-form class="addEditBox" :model="form" :rules="rules" ref="form" label-width="100px" size="medium">


            <el-form-item prop="lclPlaceId" label="拼柜仓库" :label-width="formLabelWidth">
                <f-select v-model="form.lclPlaceId" :isNeed="form.lclPlaceId" :dict="'lclPlace'"></f-select>
            </el-form-item>

            <el-form-item prop="isLimit" label="是否受限制" :label-width="formLabelWidth">
                <f-select v-model="form.isLimit" :isNeed="form.isLimit" :dict="'unit_whether'"></f-select>
            </el-form-item>

            <el-form-item prop="loadingTime" label="装柜时间" :label-width="formLabelWidth">
                <el-date-picker
                        class="f-flex-1"
                        v-model="form.loadingTime"
                        type="datetime"
                        size="small"
                        placeholder="装柜时间"
                        default-time="00:00:00"
                        value-format="yyyy-MM-dd HH:mm:ss">
                </el-date-picker>
            </el-form-item>


        </el-form>
        <span slot="footer">
            <el-button size="medium" @click="handleClose">取消</el-button>
            <el-button type="primary" size="medium" @click="handleSubmit">确认</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import {postAction} from '@/api/manage'

    export default {
        name: 'addWarehousePredictOutRecordDialog',
        props: {
            visible: {
                type: Boolean
            },

        },
        data() {
            return {
                form: {},
                formLabelWidth: '100px',
                rules: {
                    lclPlaceId: [
                        {required: true, message: '请选择拼柜仓库', trigger: 'change'},
                    ],
                    loadingTime: [
                        {required: true, message: '请选择装柜时间', trigger: 'change'},
                    ],
                    isLimit: [
                        {required: true, message: '请选择是否受限制', trigger: 'change'},
                    ],

                },
                url: {
                    //添加预约出库
                    addWarehousePredictOutRecord: '/warehouse/predictOutRecord/addOne',

                },

            }
        },
        watch: {
            visible(val) {

            },
        },
        methods: {
            handleSubmit() {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        postAction(this.url.addWarehousePredictOutRecord, this.form).then((res) => {
                            this.$message({
                                type: 'success',
                                message: res.message
                            });
                            this.handleClose();
                            this.$emit('ok');
                        })
                    }
                })
            },
            handleClose() {
                this.$emit('update:visible', false);
                this.$refs['form'].resetFields();
            }
        },
        created() {
        }
    }
</script>

<style lang="scss" scoped>
    ::v-deep
    .el-dialog__body {
        padding: 20px 40px 20px 6px;
    }

    .addEditBox {
        max-height: 615px;
        overflow: scroll;
    }
</style>
