
<template>
  <el-dialog
      title="派送详情"
      :visible="visible"
      width="35%"
      custom-class="c-custom-dialog"
      :before-close="handleClose"
      append-to-body
      :close-on-click-modal="false">
    <div v-for="(item,index) in formList">
      {{ item.info }}
      <el-form class="addEditBox el-form-item" :model="formList[index]" :rules="rules" :ref="'form' + index" label-width="100px"
               size="medium">
        <el-form-item prop="supplierId" label="供应商" :label-width="formLabelWidth">
          <f-select v-model="formList[index].supplierId" :is-need="formList[index].supplierId"
                    dict="supplier_id"></f-select>
        </el-form-item>
        <el-form-item label="其他费用信息：" :label-width="formLabelWidth">
        <f-table
            border
            row-key="id"
            size="medium"
            class="c-th-has-bgcolor"
            :columns="columns"
            :data="item.warehouseTakeCarCostList"
            :cell-style="{ 'text-align': 'center' }"
          >
            <template v-slot="scope">
              <template v-if="scope.column.property === 'feename'">
                <el-form-item
                 :prop="'warehouseTakeCarCostList.' + scope.$index + '.feecode'"
                 :rules="rules.feecode"
                >
                <f-select
                  v-model="scope.row.feecode"
                  :isNeed="scope.row.feecode"
                  :dict="'delivery_car_fee_type'"
                  @changetAllData="(obj)=>changetFeecode(obj,index,scope.$index)"
                ></f-select>
                </el-form-item>
              </template>
              <template v-else-if="scope.column.property === 'action'">
                <el-button type="text" @click="handleBatch(scope.row,index,'','1')"
                        >新增
                        </el-button
                        >
                        <el-button type="text" @click="handleBatch(scope.row,index,scope.$index,'2')" v-if="scope.$index!==0"
                        >删除
                        </el-button
                        >
              </template>

              <template v-else-if="scope.column.property === 'amount'">
                <el-form-item
                  :prop="'warehouseTakeCarCostList.' + scope.$index + '.amount'"
                 :rules="rules.amount"
                >
                  <el-input v-model="scope.row.amount"></el-input>
                </el-form-item>
              </template>
            </template>
          </f-table>
        </el-form-item>

        <el-form-item prop="driverId" label="选择内部司机" :label-width="formLabelWidth">
          <el-select clearable filterable v-model="formList[index].driverId"
                     @change="handleChangeDriverName(formList[index].driverId,index)">
            <el-option v-for="(i,d) in driverInfoList" :value="i.value" :label="i.label" :key="d"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="driverName" label=" " :label-width="formLabelWidth">
          <el-input v-model="formList[index].driverInfoStr" type="textarea" placeholder="请输入司机姓名、手机号、车牌；回车后自动识别" @keydown.enter.native="handleDistinguish(index)
"></el-input>
        </el-form-item>

        <el-form-item prop="driverName" label="司机姓名" :label-width="formLabelWidth">
          <el-input v-model="formList[index].driverName" maxlength="5" show-word-limit></el-input>
        </el-form-item>

        <el-form-item prop="driverPhone" label="司机电话" :label-width="formLabelWidth">
          <el-input v-model="formList[index].driverPhone" maxlength="11" show-word-limit></el-input>
        </el-form-item>

        <el-form-item prop="driverCarNumber" label="车号" :label-width="formLabelWidth">
          <el-input v-model="formList[index].driverCarNumber" maxlength="10" show-word-limit></el-input>
        </el-form-item>

<!--        <el-form-item prop="driverInfo" label="司机信息" :label-width="formLabelWidth">-->
<!--          &lt;!&ndash;        <el-input v-model="formList[index].driverInfo" maxlength="300" show-word-limit type="textarea"></el-input>&ndash;&gt;-->
<!--          <span>{{ formList[index].driverInfo }}</span>-->
<!--        </el-form-item>-->

      </el-form>
    </div>
    <span slot="footer">
            <el-button size="medium" @click="handleClose">取消</el-button>
            <el-button type="primary" size="medium" @click="handleSubmit">确认</el-button>
        </span>
  </el-dialog>
</template>

<script>
import {postAction} from '@/api/manage'
import {mobilePattern} from "../../../utils/pattern";
import ca from "element-ui/src/locale/lang/ca";

export default {
  name: 'editDriverModule',
  computed: {
    ca() {
      return ca
    },
  },
  props: {
    visible: {
      type: Boolean
    },
    takeIds: {
      type: String,
      default: ''
    },
    hiDriverInfo: {
      type: String,
      default: ''
    },

  },
  data() {
    return {
      driverInfoStr: '',//司机信息， 复制粘贴自动识别司机姓名、手机号、车牌号
      form: {},
      formList: [],
      formLabelWidth: '100px',
      rules: {
        supplierId: [
          {required: true, message: '请选择供应商', trigger: 'change'},
        ],
        // driverName: [
        //   {required: true, message: '请输入司机姓名', trigger: 'change'},
        // ],
        driverPhone: [
          {required: true, message: '请输入司机电话', trigger: 'change'},
          {pattern: mobilePattern, message: '手机格式不对', trigger: 'blur'}
        ],
        driverCarNumber: [
          {required: true, message: '请输入司机车号', trigger: 'change'},
        ],
        takeFee: [
          {required: true, message: '请输入提货费', trigger: 'change'},
        ],
        feecode:[
        {required: true, message: '请选择', trigger: 'change'},
        ],
        amount:[
        {required: false, message: '请选择', trigger: 'change'},
        ]


      },
      url: {
        addOrUpdateDriverInfo: "/warehouse/takeDelivery/addOrUpdateDriverInfo",
        getDriverInfoAndTakeFeeById: '/warehouse/takeDelivery/getDriverInfoAndTakeFeeById',
        delTakeFeeById: "/warehouse/takeDelivery/delTakeFeeById",
        getWarehouseTakeMasterInfoAndCarInfosByIds: '/warehouse/takeDelivery/getWarehouseTakeMasterInfoAndCarInfosByIds',
        editWarehouseTakeMasterInfoAndCarInfosByIds: '/warehouse/takeDelivery/editWarehouseTakeMasterInfoAndCarInfosByIds',
        getSettleList: '/order/os/settle/list',

      },
      driverInfoMap: {},
      driverInfoList: [],
      tableData: [],
      columns: [
        {
          prop: "feename",
          label: "费用名",
        },
        {
          prop: "amount",
          label: "金额",
        },
        {
          prop: "action",
          label: "操作",
          width: 150,
        },
      ],
      //费用对象 下拉框
      hyFeeSelectedList:[],

    }

  },
  watch: {
    visible(val) {
      if (val && this.takeIds) {
        //勾选的订单号进行赋值
        this.$set(this.form, 'takeIds', this.takeIds);
        this.$set(this.form, 'outNoList', []);

        // getAction(this.url.getDriverInfoAndTakeFeeById, {id:this.takeIds}).then(res => {
        //   if (res && res.result) {
        //     let vo = res.result;
        //     this.form = JSON.parse(JSON.stringify(vo));
        //     this.driverInfoList = this.form.warehouseDriverDropDownVoList;
        //     this.driverInfoMap = this.listToMap(this.driverInfoList, 'value');
        //
        //   }
        // })
        postAction(this.url.getWarehouseTakeMasterInfoAndCarInfosByIds, this.takeIds.split(','))
            .then(res => {
              this.formList = res.result.formList;
              this.formList.forEach((item)=>{
                if(!item.warehouseTakeCarCostList) {
                  item.warehouseTakeCarCostList = [{
                    feename:'提货费',
                    amount:'',
                    feecode:'0'
                  }]
                }
              })
              this.driverInfoList = res.result.driverInfoList;
              this.driverInfoMap = this.listToMap(this.driverInfoList, 'value');
            })
      }
    },
  },
  methods: {
    handleBatch(row,index,rowIndex,type) {
      if(type=='1') {
        this.formList[index].warehouseTakeCarCostList.push({
           feename:'提货费',
           amount:'',
           feecode:'0'
        })
      }else if(type =='2'){
        this.formList[index].warehouseTakeCarCostList.splice(rowIndex,1)
      }
      console.log(this.formList,'formList');
      console.log(index,'index');
      console.log(rowIndex,'rowIndex');
    },
    changetFeecode(obj,index,rowIndex) {
      if(obj && obj.title) {
        this.formList[index].warehouseTakeCarCostList[rowIndex].feename = obj.title
      }else {
        this.formList[index].warehouseTakeCarCostList[rowIndex].feename = ''
      }

    },
    addFee() {
      this.tableData.push({
        id: '',
        takeFee: '',
        cur: '',
        supplierId: '',
        supplierName: '',
      })
    },
    delTakeFee(index) {
      this.$confirm(`确定删除第${index + 1}行吗`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "success",
        closeOnClickModal: false,
      })
          .then(() => {
            let id = this.tableData[index].id;
            if (id) {
              postAction(this.url.delTakeFeeById + "?id=" + id)
                  .then(res => {
                    this.$message.success(res.message);
                  })
            }
            this.tableData.splice(index, 1);
          }).catch(() => {

      })
    },
    handleChangeDriverName(val, index) {

      let obj = this.driverInfoMap[val];
      console.log('选择的司机', val, index, obj);
      if (obj) {
        let driverInfo = obj['driverInfo'];
        this.$set(this.formList[index], 'driverInfo', driverInfo);
        this.$set(this.formList[index], 'driverName', obj['name']);
        this.$set(this.formList[index], 'driverPhone', obj['phone']);
        this.$set(this.formList[index], 'driverCarNumber', obj['carNumber']);
        this.$set(this.formList[index], 'canEditDriver', true);
      }else {
        this.$set(this.formList[index], 'canEditDriver', false);
      }

    },
    handleSubmit() {
      // this.$refs['form'].validate((valid) => {
      //   if (valid) {
      //     postAction(this.url.addOrUpdateDriverInfo, this.form).then((res) => {
      //       this.$message({
      //         type: 'success',
      //         message: res.message
      //       })
      //       this.form = {outNoList: []};
      //       this.handleClose();
      //       this.$emit('ok');
      //     })
      //   }
      // })
      //校验
      let avalid = [];
      for (let i = 0; i < this.formList.length; i++) {
        let key = 'form' + i;
        console.log('this.$refs[key]', this.$refs[key])
        this.$refs[key] && this.$refs[key][0].validate((valid) => {
          avalid.push(valid)
        });
      }
      
      if (avalid.every(item => item === true)) {
        postAction(this.url.editWarehouseTakeMasterInfoAndCarInfosByIds, this.formList).then((res) => {
          this.$message({
            type: 'success',
            message: res.message
          })
          this.handleClose();
          this.$emit('ok');
        })
      }
    },
    //将集合转为map
    listToMap(list, name) {
      var map = {};
      for (let i = 0; i < list.length; i++) {
        map[list[i][name]] = list[i];
      }
      return map;
    },
    handleClose() {
      this.form = {};
      this.$emit('update:visible', false);
    },
    handleDistinguish(index) {
      if(this.formList[index] && this.formList[index].driverInfoStr) {
        console.log('触发自动识别');
        let driverInfoStr = this.formList[index].driverInfoStr.replace(/[, ，、\n]/g, '');
        console.log(driverInfoStr, 'driverInfoStr')
        const pattern = /[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽赣贵粤青藏川宁琼使领][A-Z][A-Z0-9]{4}[A-Z0-9挂学警港澳]{1}/g
        let driverCarNumberList = driverInfoStr.match(pattern) || []
        let driverCarNumber = (driverCarNumberList && driverCarNumberList.length && driverCarNumberList[0]) || ''
        let indexN = driverInfoStr.indexOf(driverCarNumber)
        if(driverCarNumber && indexN !== -1) {
          let driverCarNumber2 = driverInfoStr.charAt(indexN + driverCarNumber.length);
          if(driverCarNumber2 && driverCarNumber2 !== '1' && !(/[\u4e00-\u9fff]/.test(driverCarNumber2))) {
            driverCarNumber = driverCarNumber + driverCarNumber2
          }
          console.log(driverInfoStr.charAt(indexN + driverCarNumber.length), '匹配车牌的下一位字符')
        }
        driverCarNumber && (this.formList[index].driverCarNumber = driverCarNumber);
        driverInfoStr = driverInfoStr.replace(new RegExp(driverCarNumber, 'g'), "");
        console.log('车牌是',driverCarNumber)
        const phoneRegex = /(1[3|4|5|7|8]\d{9})/g;
        let driverPhoneList = driverInfoStr.match(phoneRegex) || []
        let driverPhone = (driverPhoneList && driverPhoneList.length && driverPhoneList[0]) || ''
        driverPhone && (this.formList[index].driverPhone = driverPhone);
        console.log('手机号是', driverPhone)
        driverInfoStr = driverInfoStr.replace(new RegExp(driverPhone, 'g'), "");
        driverInfoStr && driverCarNumber && driverPhone && (this.formList[index].driverName = driverInfoStr);
        console.log('名称是', driverInfoStr)
      }
    }
  },
  created() {
  }
}
</script>

<style lang="scss" scoped>
::v-deep
.el-dialog__body {
  padding: 20px 40px 20px 6px;
}


//::v-deep {
//  .addEditBox {
//    .el-form-item__content {
//    margin-left: 0 !important;
//  }
//
//  }
//    }

.addEditBox {
  max-height: 615px;
  overflow: scroll;

}
</style>
