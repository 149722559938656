<!--文件信息-->
<template>
    <div class="main-full-content">
        <el-form class="input_top" ref="form" :model="queryForm" label-width="100px" size="medium">
            <el-card class="box-card">
                <el-row>
                <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <el-form-item label="文件名">
                        <el-input v-model="queryForm.name" style="width: 100%"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <el-form-item label="文件类型">
                        <f-select :dict="'kd_file_type'"
                                  v-model="queryForm.type"
                                  :isNeed="queryForm.type"
                                  :removeValue="removeValue"
                                  style="width: 100%">
                        </f-select>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <el-form-item label-width="30px">
                        <el-upload
                                class="upload-demo"
                                action=""
                                :http-request="addUpload"
                                :show-file-list="false"
                                ref="add"
                                :before-upload="beforeUpload"
                                style="float: left;">
                          <el-button type="primary" size="small" >上传</el-button>
                        </el-upload>
                        <!-- <el-button type="primary" size="medium" @click="saveFile">上传</el-button> -->
                        <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
                        <el-button plain @click="handleReset">重置</el-button>
                    </el-form-item>
                </el-col>
            </el-row>
            </el-card>

        </el-form>
        <el-card class="box-card last_card">
        <div class="f-p-b-15">
            <!-- <el-button type="primary" icon="el-icon-plus" size="medium" @click="handleAdd">新增</el-button> -->
            <el-button type="primary" size="medium" @click="handleBatDel" :disabled="selectionRows.length === 0">批量删除</el-button>
            <el-button type="primary" size="medium" icon="el-icon-download" @click="exportOs">批量下载</el-button>
        </div>
        <div class="c-alert f-m-b-10">
            已选择 <span class="stress">{{this.selectedRowKeys.length}}</span> 项
            <span class="stress f-m-l-12 f-cursor-pointer" @click="handleClearSelection">清空</span>
        </div>
        <f-table
                v-loading="loading"
                ref="GTable"
                border
                size="medium"
                row-key="id"
                class="c-th-has-bgcolor"
                :columns="columns"
                :data="tableData"
                :cell-style="{ 'text-align': 'center' }"
                @selection-change="handleSelectionChange"
                @sort-change="handleTableChange">
            <template v-slot="scope">
                <template v-if="scope.column.property === 'action'">
                    <el-button type="text" @click="handleEdit(scope.row)">编辑</el-button>
                    <el-button type="text" @click="handDel(scope.row)">删除</el-button>
                </template>
                <template v-else-if="scope.column.property === 'type'">
                    <span>{{parseDict(scope.row.type,'kd_file_type')}}</span>
                </template>
                <template v-else-if="scope.column.property==='name'">
                    <a type="text" @click.prevent="downloadFile(scope.row)" style="color:#57a3f3;">{{scope.row.name}}</a>
                </template>
            </template>
        </f-table>
        <el-row class="f-text-right f-p-v-8">
            <el-pagination
                    v-bind="ipagination"
                    layout="total, sizes, prev, pager, next, jumper"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange">
            </el-pagination>
        </el-row>
        </el-card>
        <el-dialog :title="form.id?'编辑文件':'新增文件'" :visible.sync="dialogFormVisible" v-if="dialogFormVisible"
                   center width="30%" :close-on-click-modal="false">
            <el-form :model="form" :rules="rules" ref="form" size="medium">
                <el-row>
                    <el-col :span="24">
                        <el-form-item prop="name" label="文件名" label-width="110px">
                            <el-input v-model="form.name" maxlength="255" show-word-limit></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item prop="type" label="文件类型" label-width="110px">
                            <f-select :is-need="form.type" :dict="'kd_file_type'"
                                      v-model="form.type"
                            ></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="文件" label-width="110px">
                            <f-upload v-model="form.fileList" :limit="form.id?1:1000" :dir="'kdOrder'"></f-upload>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="medium" @click="dialogFormVisible = false">取 消</el-button>
                <el-button size="medium" type="primary" @click="saveFile">确 定</el-button>
            </div>

        </el-dialog>


    </div>
</template>

<script>
    import {deleteAction, getAction, httpAction, postAction} from '@/api/manage'
    import QueryMixins from "../../../mixins/QueryMixins";

    export default {
        name: 'FileModule',
        mixins: [QueryMixins],
        props: {
            order: {
                type: Object,
                default: () => {
                }
            }
        },
        data() {
            return {
                dictCodes: ['kd_file_type'],
                ids: [],
                dialogFormVisible: false,
                form: {
                    url: '',
                    size: '',
                    fileType: '',
                    type: '',
                    id: '',
                    cusId: '',
                    name: '',
                    ossId: '',
                    fileList: [],
                },
                btnDisable: false,
                //表单校验
                rules: {
                    name: [
                        {required: true, message: '请输入文件名', trigger: 'blur'},
                    ],
                    type: [
                        {required: true, message: '请选择文件类型', trigger: 'change'}
                    ]


                },
                row: {},
                columns: [
                    {
                        type: 'selection',
                        width: 50
                    },
                    {
                        prop: 'action',
                        label: '操作',

                    },
                    {
                        prop: 'name',
                        label: '文件名',
                        sortable: 'custom',

                    },
                    {
                        prop: 'type',
                        label: '文件类型',
                        sortable: 'custom',

                    },
                    {
                        prop: 'createBy',
                        label: '上传人',
                        sortable: 'custom',
                    },
                    {
                        prop: 'createTime',
                        label: '上传时间',
                        sortable: 'custom',
                    },

                ],
                url: {
                    list: '/order/KdOrderFile/list',
                    delById: '/order/KdOrderFile/delete/',
                    add: '/order/KdOrderFile/add',
                    edit: '/order/KdOrderFile/edit',
                    getbyid: '/order/KdOrderFile/queryById',
                    deleteBatch: '/order/KdOrderFile/deleteBatch',
                    upload: '/sys/oss/file/upload',
                    delOss: '/sys/oss/file/delete',
                    downloadFile:'/sys/oss/file/downloadById',
                    downloadBatch:'/sys/oss/file/batchDownloadByIds',

                },
                removeValue: '',
            }
        },
        created() {
            console.log('this.order', this.order)
            if (this.order) {
                for (var name in this.order) {
                    this.$set(this.form, name, this.order[name]);
                    this.queryForm.orderNo = this.form.orderNo;
                }
            }
        },
        methods: {
            exportOs(){
                let list = this.tableData;
                if (this.selectionRows && this.selectionRows.length>0 && Array.isArray(this.selectionRows)){
                    list = this.selectionRows.map(s=>{
                        return s.ossId;
                    });
                }
                postAction(this.url.downloadBatch,list,{responseType: 'blob'})
                    .then(response=>{
                        let u = window.URL.createObjectURL(response)
                        let aLink = document.createElement('a')
                        aLink.style.display = 'none'
                        aLink.href = u
                        aLink.setAttribute('download', "客户快递文件.zip")
                        document.body.appendChild(aLink)
                        aLink.click()
                        document.body.removeChild(aLink)
                        window.URL.revokeObjectURL(u)
                    })
            },

            downloadFile: function(file) {
                postAction(this.url.downloadFile, {id:file.ossId}, {responseType: 'blob'})
                    .then(response => {
                        let u = window.URL.createObjectURL(response)
                        let aLink = document.createElement('a')
                        aLink.style.display = 'none'
                        aLink.href = u;
                        // let type = this.parseDict(file.type,'os_order_filelist');
                        // let ss=file.orderNo+"-"+type+"-";
                        aLink.setAttribute('download', file.name)
                        document.body.appendChild(aLink)
                        aLink.click()
                        document.body.removeChild(aLink)
                        window.URL.revokeObjectURL(u)
                    })
            },
          /*  //根据字典文本获取字典集合
            //根据数据字典格式化文本
            //上传文件到oss
            handleUpload(data) {
                console.log("上传文件", data);
                const formData = new FormData();
                formData.append("file", data.file);
                postAction(this.url.upload, formData).then(res => {
                    if (res.success) {
                        //上传成功,返回结果赋值给form对象的文件集合
                        let {id, size, ext, name, url} = res.result;
                        this.fileList.push({ossId: id, size, ext, name, url, id});
                        this.form.url = url;
                        this.form.ossId = id;
                        this.form.size = size;
                        this.form.ext = ext;
                         this.form.fileName = name;
                        console.log('上传的客户文件', this.fileList);
                        console.log('上传的客户文件', this.form);
                        this.$message.success('上传成功');
                        //this.fileList.push(res.result);
                    }
                })
            },
            removeFile(val) {
                if (val && val.status === "success") {
                    // 成功时候的方法
                    //删除oss的文件
                    const index = this.fileList.indexOf(val);
                    deleteAction(this.url.delOss, {id: this.form.ossId})
                        .then(res => {
                            if (res.success) {
                                console.log('执行删除文件', index);
                                this.fileList.splice(index, 1);
                                //同时清空文件的url
                                this.form.url = '';
                                this.form.ossId = '';
                                this.$message.success('删除文件成功')
                            } else {
                                //清空filelist
                                this.fileList.splice(index, 1);
                                console.log('执行删除文件', index);
                            }
                        }).catch(err => {
                        this.fileList.splice(index, 1);
                        this.$refs.upload.clearFiles();

                    })
                    console.log('执行删除文件', this.fileList);
                }

            },*/
            //文件上传之前校验文件合法
            beforeUpload(file) {
              //对文件大小进行限制
              const isLt2M = file.size / 1024 / 1024 < 15
              if (!isLt2M) {
                this.$message.warning('文件大小不能超过15MB')
              }
              const isFile = file.name.indexOf('.') !== -1;
              if (!isFile) {
                this.$message.warning('该文件名不合法')
              }
              return isLt2M && isFile;
            },
            // 新增上传文件
            addUpload(data) {
              this.btnDisable = true;
              if (!this.queryForm.type){
                this.$message.warning('请选择文件类型在上传');
                this.btnDisable = false;
                return false;
              }
              const formData = new FormData();
              formData.append("file", data.file);
              formData.append("dir", 'osOrder');
              postAction(this.url.upload, formData).then(res => {
                if (res.success) {
                  let {id, name} = res.result;
                  //上传成功,返回结果赋值给form对象的文件集合
                  let form = {};
                  this.$set(form,'fileList',res.result);
                  this.$set(form,'ossId',id);
                  this.$set(form,'name',this.queryForm.name || name);
                  this.$set(form,'type',this.queryForm.type);
                  this.$set(form,'orderNo',this.$route.query.orderNo);
                  httpAction(this.url.add, 'post', form)
                          .then(res => {
                            if (res.success) {
                              this.dialogFormVisible = false;
                              this.$message.success(res.message);
                              this.$set(this.queryForm,'type','');
                              this.$set(this.queryForm,'name','');
                              this.handleQuery();
                            } else {
                              this.btnDisable = false;
                            }
                          }).catch(err => {
                    this.btnDisable = false;
                  })
                  // this.fileList.push(res.result);
                }
              })
            },
            //新增
            saveFile: function () {
                this.btnDisable = true;
                console.log("文件对象", this.form.fileList)
                this.form.orderNo = this.order.orderNo;
                //文件的删除和新增统一放到F-upload，这边传递表单对象过去
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        let method = "post";
                        let url = this.url.add;
                        if (this.form.fileList.length <= 0) {
                            this.$message.error('请选择文件')
                            this.btnDisable = false;
                            return false;
                        } else {
                            console.log("新增对象", this.form)
                            if (this.form.id) {
                                url = this.url.edit;
                                method = "put";
                            }
                            let attchlist = [];
                            for (var i = 0; i < this.form.fileList.length; i++) {
                                attchlist.push(this.form.fileList[i].id);
                            }
                            this.form.ossId = attchlist.join(',');
                            httpAction(url, method, this.form).then(res => {
                                if (res.success) {
                                    this.dialogFormVisible = false;
                                    this.$message.success(res.message);
                                    this.handleQuery();
                                } else {
                                    this.btnDisable = false;
                                }
                            }).catch(err => {
                                this.btnDisable = false;
                            })
                        }
                    } else {
                        this.btnDisable = false;
                    }
                })


            },
            handleReset() {
                this.queryForm = {};
                this.queryForm.orderNo = this.order.orderNo;
                this.ipagination.currentPage = 1
                this.handleQuery()
            },


            handleAdd() {
                this.form = {};
                this.fileList = [];
                this.btnDisable = false;
                this.removeValue = '';
                this.dialogFormVisible = true;
            },
            handleEdit(row) {
                this.form = {};
                this.form.fileList = [];
                this.btnDisable = false;
                getAction(this.url.getbyid + '?id=' + row.id).then(res => {
                    if (res.success) {
                        this.form = res.result;
                        this.form.fileList = res.result.attachFile;
                    }
                })
                this.dialogFormVisible = true;
            },
            handDel({id, name}) {
                this.$confirm(`删除${name}?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'success',
                    closeOnClickModal: false,
                }).then(() => {
                    deleteAction(this.url.delById + '?id=' + id).then(res => {
                        this.$message({
                            type: 'success',
                            message: res.message
                        })
                        this.handleSearch()
                    })
                }).catch(err => {
                    this.$message.error(err.message);
                })
            },

            handleBatDel() {
                this.$confirm('确定删除？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'error',
                    closeOnClickModal: false,
                }).then(() => {
                    deleteAction(this.url.deleteBatch, {ids: this.selectedRowKeys.join(',')}).then(res => {
                        this.$message({
                            type: 'success',
                            message: res.message
                        })
                        this.handleSearch()
                    })
                }).catch(err => {
                    this.$message.error(err.message);
                })
            },

        },
    }
</script>

<style lang="scss" scoped>
.upload-demo {
  margin-right: 10px;
}
</style>
