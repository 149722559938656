<template>
    <div>
        <el-dialog
                title="修改币种及添加账户"
                border
                :visible="changeCurVisible"
                :close-on-click-modal="false"
                :destroy-on-close="true"
                :before-close="curBankDialogClose">
            <el-form :rules="rules">
                <el-row>
                    <el-col :span="23">
                        <el-form-item prop="cur" label="折合币种">
                            <f-select v-model="billForm.cur"
                                      :dict="'bd_cur'"
                                      placeholder="请选择折合币种"
                                      clearable
                                      filterable
                                      @change="curChangeBank"
                                      class="f-full-width"></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="23">
                        <el-form-item prop="bank" label="银行账户">
                            <el-select v-model="billForm.bank"
                                       placeholder="根据币种选择银行"
                                       clearable
                                       filterable
                                       class="f-full-width">
                                <el-option v-for="(item,index) in banks" :value="item.id" :label="item.bankName"
                                           :key="item.id">
                                    {{item.bankName + '-' + item.accountName + '-' + item.account + '-' + item.cur }}
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer">
            <el-button type="primary" size="medium" @click="changeCurSubmit">确定</el-button>
        </span>
        </el-dialog>

        <el-dialog
                title="添加费用"
                border
                :visible="addFeeDialogVisible"
                :close-on-click-modal="false"
                :destroy-on-close="true"
                :before-close="handleAddFeeDialogClose">
            <f-table
                    :columns="addColumns"
                    :cell-style="{ 'text-align': 'center' }"
                    :data="noChoose"
                    @selection-change="addSelectionChange"
            ></f-table>
            <span slot="footer">
            <el-button type="primary" size="medium" @click="addSubmit">确定</el-button>
        </span>
        </el-dialog>

        <el-dialog
                :title="title"
                :visible="visible"
                width="70%"
                custom-class="c-custom-dialog"
                :close-on-click-modal="false"
                :destroy-on-close="true"
                :before-close="handleClose"
        >
            <el-form :model="billForm" :rules="rules" ref="billForm" :inline="true" :label-position="right"
                     label-width="109px">
<!--                <el-row>-->
<!--                    <el-col>-->
<!--                        <el-form-item label="上传水单">-->
<!--                            <el-upload-->
<!--                                    class="upload-demo"-->
<!--                                    :action="url.uploadInvoice"-->
<!--                                    accept=".jpg,.png"-->
<!--                                    :http-request="handleUpload"-->
<!--                                    :on-preview="handlePicPreview"-->
<!--                                    :before-upload="beforeUpload"-->
<!--                                    :on-remove="handleRemove"-->
<!--                                    :file-list="fileList"-->
<!--                                    list-type="picture">-->
<!--                                <el-button size="small" type="primary" v-if="showOnly">点击上传</el-button>-->
<!--                                <div slot="tip" class="el-upload__tip" v-if="showOnly">只能上传jpg/png文件，且不超过500kb</div>-->
<!--                            </el-upload>-->
<!--                        </el-form-item>-->
<!--                    </el-col>-->
<!--                </el-row>-->
<!--                <el-row>-->
<!--                    <el-col>-->
<!--                        <el-form-item prop="payMan" label="付款人">-->
<!--                            <el-input v-model="billForm.payMan" style="width: 300px"></el-input>-->
<!--                        </el-form-item>-->
<!--                    </el-col>-->
<!--                </el-row>-->
<!--                <el-row>-->
<!--                    <el-col>-->
<!--                        <el-form-item prop="amount" label="金ㅤ额">-->
<!--                            <el-input v-model="billForm.amount" style="width: 300px"-->
<!--                                      @blur="changeAmount" :disabled="ifDis" oninput="value=value.match(/\d+\.?\d{0,3}/)"></el-input>-->
<!--                        </el-form-item>-->
<!--                    </el-col>-->
<!--                </el-row>-->
<!--                <el-row>-->
<!--                    <el-col>-->
<!--                        <el-form-item label="发票号">-->
<!--                            <el-input v-model="billForm.invoiceNo" style="width: 300px"></el-input>-->
<!--                        </el-form-item>-->
<!--                    </el-col>-->

<!--                </el-row>-->
<!--                <el-row>-->
<!--                    <el-col>-->
<!--                        <el-form-item label="备ㅤ注">-->
<!--                            <el-input v-model="billForm.invoiceRemark" style="width: 300px"></el-input>-->
<!--                        </el-form-item>-->
<!--                    </el-col>-->

<!--                </el-row>-->
<!--                <el-row>-->
<!--                    <el-col>-->
<!--                        <el-button plain @click="addFee" v-if="showOnly">添加费用</el-button>-->
<!--                        <el-button plain @click="changeCurBank" v-if="showOnly">修改币种及添加账户</el-button>-->
<!--                    </el-col>-->
<!--                </el-row>-->

                <f-table
                        v-loading="loading"
                        ref="GTable"
                        border
                        size="medium"
                        row-key="id"
                        class="c-th-has-bgcolor"
                        :columns="columns"
                        :data="billForm.settleList"
                        :cell-style="{ 'text-align': 'center' }"
                        style="margin-top: 15px"
                        @selection-change="handleSelectionChange"
                >
                    <template v-slot="scope">
                        <template v-if="scope.column.property === 'action'">

                            <el-tooltip class="item" effect="dark" content="点击退回至待开账单" placement="top">
                                <el-button type="text" @click="delFee(scope.row)">删除</el-button>
                            </el-tooltip>
                        </template>

                      <template v-else-if="scope.column.property === 'orderNo'">
                        <a style="color: #00a0e9;cursor:pointer" @click.prevent="$intoDocPage(scope.row.orderNo);visible=false">{{ scope.row.orderNo }}</a>
                      </template>

                      <template v-else-if="scope.column.property === 'customer'">
                        <a style="color:#57a3f3;cursor:pointer" @click.prevent="$intoCustomerEditPage(scope.row.cusId);visible=false" >{{scope.row.customer}}</a>
                      </template>
                        <template v-else-if="scope.column.property === 'week'">
                            <a class="f-cursor-pointer" style="color: #00a0e9" @click="infomation(scope.row.containerId)">{{
                                    scope.row.week
                                }}
                            </a>
                        </template>
<!--                        <template v-else-if="scope.column.property === 'applyNo'">-->
<!--                            <a style="color: #00a0e9;cursor:pointer" @click.prevent="showApplyDetail(scope.row)">{{ scope.row.applyNo }}</a>-->
<!--                        </template>-->

<!--                        <template v-else-if="scope.column.property === 'realPay'">-->
<!--                            <el-form-item-->
<!--                                    :rules="[{required: true, message: '实付不能为空', trigger: 'blur'}]">-->
<!--                                <el-input type="text" v-model="scope.row.realPay" @input="changeAmount" :placeholder="scope.row.exchangeTotal"></el-input>-->
<!--                            </el-form-item>-->

<!--                        </template>-->

                    </template>

                </f-table>
            </el-form>

            <span slot="footer">
            <el-button v-if="showOnly" type="primary" size="medium" @click="handleSubmit"
                       :disabled="ifDis">确定申请</el-button>
        </span>
        </el-dialog>
        <apply-detail-dialog ref="applyDetailDialog"></apply-detail-dialog>
    </div>
</template>

<script>
    import {getAction, postAction} from '@/api/manage'
    import applyDetailDialog from "@/views/hyFinanceManage/modules/pay/applyDetailDialog";
    import { setUpRouteUtil } from '@/utils/util';
    // import QueryMixins from "../../../../mixins/QueryMixins";

    export default {
        name: 'receiveApply',
        // mixins: [QueryMixins],
        components:{applyDetailDialog},

        data() {
            return {
                // id:'',
                addColumns: [],
                addFeeDialogVisible: false,
                loading: false,
                right: 'right',
                remark: '',
                imgUrl: '',
                labelPosition: 'right',
                flag: false,
                visible: false,
                changeCurVisible: false,
                isChecked: true,
                ifDis: false,
                allKeys: [],
                fileList: [],
                defaultProps: {
                    children: 'children',
                    label: 'title'
                },
                billForm: {
                    billType: 'noConvert',
                    billTitle: '',
                    payMan: '',
                    amount: '',//实付
                    remark: '',
                    ids: [],
                    orderNos: [],
                    cur: '',//更改的币种
                    bank: '',
                    settleList: [],
                    uploadFileIds:[],//上传文件的id，保存时增加申请单号
                },
                url: {
                    // list: '/sys/bdCountry/list',
                    deleteBatch: '/sys/bdCountry/deleteBatch',
                    openBill: '/file/jasper/openReceiveBill',
                    checkBill: '/shipping/hySettleReceive/checkReceiveSettleId',
                    curGetBank: '/shipping/hySettleReceive/curGetBank',
                    addApply: '/shipping/hySettleReceive/addApply',
                    getSettlesByMergeNo: "/shipping/hySettlePay/getSettlesByMergeNo",
                    uploadInvoice: '/shipping/hySettlePay/uploadInvoice',
                },
                rules: {
                    payMan: [
                        {required: true, message: '请输入付款人', trigger: 'blur'},
                    ],
                    amount: [
                        {required: true, message: '请输入金额', trigger: 'blur'},
                    ],
                    realPay: [
                        {required: true, message: '请输入金额', trigger: 'blur'},
                    ],
                    realMoneyType: [
                        {required: true, message: '请选择币种', trigger: 'blur'},
                    ],
                    bankId: [
                        {required: true, message: '请选择银行', trigger: 'blur'},
                    ],


                },
                title: '收款申请',
                settleIds: [],
                showOnly: true,
                noChoose: [],
                columns: [],
                orderColumns: [
                    {
                        prop: 'orderNo',
                        label: '工作单号',
                        width: 140
                    },
                    {
                        prop: 'applyNo',
                        label: '申请单号',
                        width: 140
                    },
                    {
                        prop: 'customer',
                        label: '客户',
                    },
                    {
                        prop: 'feename',
                        label: '费用中文',
                    },
                    {
                        prop: 'moneyType',
                        label: '应收币种',
                    },
                    {
                        prop: 'total',
                        label: '应收金额',
                    },
                    {
                        prop: 'realMoneyType',
                        label: '折合币种',
                    },
                    {
                        prop: 'exchangeTotal',
                        label: '折合金额',
                    },
                    {
                        prop: 'createBy',
                        label: '申请人',
                    },
                    {
                        prop: 'createTime',
                        label: '申请时间',
                    },
                    {
                        prop: 'salesman',
                        label: '业务员',
                    },
                    {
                        prop: 'operator',
                        label: '操作',
                    },

                ],
                containerColumns:[
                    {
                        type: "selection",
                        width: 50,
                    },
                    {
                        prop: "createTime",
                        label: "录入时间",
                    },
                    {
                        prop: 'applyNo',
                        label: '申请单号',
                        width: 140
                    },
                    {
                        prop: "week",
                        label: "周期",
                        width: 150,
                    },
                    {
                        prop: "containerNo",
                        label: "柜号",
                    },
                    {
                        prop: "settlementStr",
                        label: "结算对象",
                    },
                    {
                        prop: "chineseName",
                        label: "费用中文",
                    },
                    {
                        prop: "englishName",
                        label: "费用英文",
                    },
                    {
                        prop: "moneyType",
                        label: "费用币种",
                    },
                    {
                        prop: "total",
                        label: "合计",
                    },
                    {
                        prop: "createBy",
                        label: "录入人",
                    },
                    {
                        prop: "remark",
                        label: "备注",
                    },


                ],
                selectionRows: [],
                addSelectRows: [],
                banks: [],

            }
        },
        methods: {

            showApplyDetail(row) {
                this.$refs.applyDetailDialog.showDetail(row);
            },
          //进入客服页
            intoDocpage(orderNo) {
                if (orderNo.indexOf("KD")!==-1){
                    // 对于路由不在左侧菜单中的选中菜单处理
                    setUpRouteUtil(this.$route.path, '/kdorder/Edit');
                    this.$router.push("/kdorder/Edit?orderNo=" + orderNo);
                }else {
                    // 对于路由不在左侧菜单中的选中菜单处理
                    setUpRouteUtil(this.$route.path, '/docpage/Edit');
                    this.$router.push("/docpage/Edit?orderNo=" + orderNo);
                }

            },
            infomation(id) {
                // 对于路由不在左侧菜单中的选中菜单处理
                setUpRouteUtil(this.$route.path, '/operation/cabinet/Edit');
                this.$router.push("/operation/cabinet/Edit?id=" + id);
                this.visible = false

            },
            //上传文件到oss
            handleUpload(data) {
                // if (data.file.name.length> 20){
                //     this.$message.warning("文件名过长，自动截取前50个字符");
                //     let index = data.file.name.lastIndexOf(".");
                //     let suffix = data.file.name.substr(index+1);
                //     console.log(suffix)
                //     data.file = new File([data.file], data.file.name.substring(0, 10) + '...' + suffix)
                // }

                const formData = new FormData();
                formData.append("file", data.file);

                postAction(this.url.uploadInvoice, formData).then(res => {
                    // console.log(res)
                    if (res.success) {
                        //上传成功,返回结果赋值给form对象的文件集合
                        this.billForm.uploadFileIds.push(res.result.id);
                        // console.log(this.billForm.uploadFileIds)
                        this.fileList.push(res.result);
                        this.$message.success('上传成功');
                    }else{

                    }
                })
            },
            beforeUpload(file) {
                //对文件大小进行限制
                const isLt2M = file.size / 1024 / 1024 < 15;
                if (!isLt2M) {
                    this.$message.warning("文件大小不能超过15MB");
                }
                const isFile = file.name.indexOf(".") !== -1;
                if (!isFile) {
                    this.$message.warning("该文件名不合法");
                }
                const len = file.name.length < 100;
                if (!len) {
                    this.$message.warning("该文件名太长，请修改文件名最多100个字符");
                }
                return isLt2M && isFile && len;
            },
            changeAmount() {
                let sumPay = 0;
                this.billForm.settleList.forEach(item => sumPay = Number(sumPay) + Number(item.realPay));
                this.billForm.amount = sumPay.toFixed(3);
            },
            toS(v) {
                return String(v)
            },
            delFee(row) {
                // console.log(row)
                this.billForm.settleList.splice(this.billForm.settleList.indexOf(row));
            },
            changeCurBank() {
                this.changeCurVisible = true;
            },
            curChangeBank(val) {
                // console.log(val)
                getAction(this.url.curGetBank, {cur: val, useType:1}).then((res) => {
                    // console.log(res)
                    this.banks = res.result
                })
            },
            addSelectionChange(selection) { // 多选
                // console.log(selection)
                this.addSelectRows = selection;
            },
            addFee() {
                this.addFeeDialogVisible = true;
            },
            addSubmit() {
                // this.settleList.concat(this.addSelectRows)
                // this.addSelectRows = []
                for (let i = 0; i < this.addSelectRows.length; i++) {
                    this.billForm.settleList.push(this.addSelectRows[i])
                    this.noChoose.splice(this.noChoose.indexOf(this.addSelectRows[i]))
                }
                this.addFeeDialogVisible = false;
                this.addSelectRows = []

            },
            changeCurSubmit() {
                // console.log(this.billForm)
                // console.log(this.billForm.settleList)
                //TODO 修改原tableData中的币种和银行
                this.billForm.settleList.forEach(data => {
                    data.realMoneyType = this.billForm.cur
                    data.bank = this.billForm.bank
                })


                this.curBankDialogClose()
            },
            handleSubmit() {
                this.changeAmount();
                // if(Number(this.billForm.amount)===0){
                //     this.$message.warning("金额不能为零！")
                //     return;
                // }
                this.$refs.billForm.validate((valid) => {
                    if (valid) {
                        postAction(this.url.addApply, this.billForm).then((res) => {
                            // console.log(res)
                            if (res.success === true) {
                                this.visible = false
                                this.$emit('ok')
                            }
                        })
                    }
                })
            },
            handleClose() {
                // this.$refs.form.resetFields()
                this.visible = false;

            },
            curBankDialogClose() {
                // this.$refs.form.resetFields()
                this.changeCurVisible = false;

            },
            handleAddFeeDialogClose() {
                // this.$emit('update:visible', false)
                // this.$refs.form.resetFields()
                this.addFeeDialogVisible = false;

            },
            enabled() {
                setTimeout(() => {
                    this.ifDis = false;
                }, 2000)
            },
            edit(selectionRows, arr, columns) {
                // console.log("申请收款的selectionRows", selectionRows)
                this.billForm.settleList = JSON.parse(JSON.stringify(selectionRows))
                this.noChoose = JSON.parse(JSON.stringify(arr));
                this.addColumns = columns;
                this.showOnly = true;
                this.title = '收款申请'
                this.visible = true
            },
            showDetail(row) {
                // console.log('申请对象->', row);
                getAction(this.url.getSettlesByMergeNo, {mergeNo: row.mergeNo}).then((res) => {
                    // console.log(res)
                    if(res.result.agentPayType=='1'){
                        this.billForm.settleList = res.result.containerSettleList;
                        // console.log('切换')
                        this.columns = this.containerColumns
                    }else{
                        this.billForm.settleList = res.result.settleList;
                        this.columns = this.orderColumns
                    }

                    this.billForm.payMan = res.result.payMan;
                    this.billForm.amount = res.result.amount;
                    this.billForm.invoiceRemark = res.result.invoiceRemark;
                    this.billForm.invoiceNo = res.result.invoiceNo;
                    // this.fileList = res.result.uploadedFiles;
                })
                this.title = '合并申请详情'
                this.showOnly = false;
                this.visible = true
            },

            handleRemove(file, fileList) {
                this.fileList.map((item, index) => {
                    if (item.uid == file.uid) {
                        //向服务器发送要删除的文件的路径，删除oss上的文件
                        // console.log(item)
                        postAction(this.url.delFile,{osOrderFileId:item.id, url:item.url}).then((res)=>{
                            // console.log(res)
                            //页面的上的文件也要删除
                            this.fileList.splice(index, 1)
                            this.billForm.uploadFileIds.splice(this.billForm.uploadFileIds.indexOf(item.id),1);
                        })

                    }
                })
            },
            handlePicPreview(file) {
                // console.log(file)
                // console.log('新窗口打开')
                window.open(file.url,'_blank')
            },
            handleSelectionChange(selection) { // 多选
                // console.log(selection)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .btn-wrap {
        text-align: right;
        padding: 10px 30px;
    }

    .content {
        height: 620px;
        padding: 6px 0;
        overflow: auto;
    }

</style>
