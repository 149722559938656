<template>
    <el-dialog
            :title="row.id ? '编辑已清关案例' : '新增已清关案例'"
            :visible="visible"
            width="560px"
            custom-class="c-custom-dialog"
            :close-on-click-modal="false"
            :before-close="handleClose"
    >
        <el-form
                :model="addForm"
                :rules="rules"
                ref="addForm"
                label-width="80px"
                size="medium"
        >
            <el-row>
                <el-col>
                    <el-form-item prop="goodsName" label="品名">
                        <el-input v-model="addForm.goodsName" :is-need="addForm.goodsName"></el-input>
                    </el-form-item>
                </el-col>
                <el-col>
                    <el-form-item prop="country" label="国家">
                        <el-select
                                clearable
                                filterable
                                @click.native="initCountryList"
                                class="f-full-width"
                                v-model="addForm.country"
                                placeholder="请选择">
                            <el-option
                                    v-for="(item,index) in countrylist"
                                    :key="index"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col>
                    <el-form-item prop="hsCode" label="税号" :rules="addForm.country === '美国'? rules.mgHsCode : rules.hsCode">
                        <el-input v-model="addForm.hsCode" maxlength="50" show-word-limit
                                  style="padding: 0 45px 0 0"></el-input>
                    </el-form-item>
                </el-col>
                <el-col>
                    <el-form-item prop="addtionalCode" label="301编码">
                        <f-select v-model="addForm.addtionalCode" :is-need="addForm.addtionalCode"
                                  dict="hs_code_301"></f-select>
                    </el-form-item>
                </el-col>
                <el-col>
                    <el-form-item prop="taxRate" label="税率">
                        <el-input v-model="addForm.taxRate">
                        </el-input>
                    </el-form-item>
                </el-col>
                <el-col>
                    <el-form-item prop="material" label="材质">
                        <el-input v-model="addForm.material" show-word-limit maxlength="1000"
                                  type="textarea"></el-input>
                    </el-form-item>
                </el-col>
                <el-col>
                    <el-form-item prop="purpose" label="用途">
                        <el-input v-model="addForm.purpose" show-word-limit maxlength="1000" type="textarea"></el-input>
                    </el-form-item>
                </el-col>
                <el-col>
                    <el-form-item prop="unitValue" label="申报单价">
                        <el-input v-model="addForm.unitValue" type="number"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <span slot="footer">
      <el-button size="medium" @click="handleClose">取消</el-button>
      <el-button type="primary" size="medium" @click="handleSubmit"
      >确定</el-button
      >
    </span>
    </el-dialog>
</template>

<script>
    import {httpAction} from "@/api/manage";
    import {getallcountries, getcityList, getstateList} from "@/api/manage";
    import {baifenshuPattren, sanweixiaoshu, shiweishuPattern} from "../../../utils/pattern";
    import {noZhongwenValidator} from "@/utils/validator";

    export default {
        name: "EditSuccessClearedCaseDialog",

        props: {
            visible: {
                type: Boolean,
            },
            row: {
                type: Object,
                default: () => {
                },
            },
        },
        data() {
            return {
                formLabelWidth: "100px",
                addForm: {},
                countrylist: [],
                citylist: [],
                statelist: [],
                recMap: {},
                rules: {
                    goodsName:[
                        {required:true,message:'请输入品名',trigger:'change'}
                    ],
                    country: [
                        {required: true, message: '请选择国家', trigger: 'change'},
                    ],
                    hsCode: [
                        {required: true, message: '请输入产品编码', trigger: 'change'},
                    ],
                    mgHsCode: [
                        {required: true, message: '请输入产品编码', trigger: 'change'},
                        {pattern:shiweishuPattern,message: '美国编码只能是十位数字',trigger: 'change'}
                    ],
                    taxRate: [
                        // {required: true, message: '', trigger: 'change'},
                        // {pattern: sanweixiaoshu, message: "税率只能由正整数及3位小数组或者0", trigger: 'change'}
                    ],
                    material: [
                        {validator: noZhongwenValidator,  trigger: 'change'},
                    ],
                    purpose: [
                        {validator: noZhongwenValidator, trigger: 'change'},
                    ],
                    unitValue: [
                        {required: true, message: '请输入生产厂商', trigger: 'change'},
                    ],


                },
                url: {
                    add: "/sys/bdSuccessClearedCase/add",
                    edit: "/sys/bdSuccessClearedCase/edit",
                },
                isWarehouse: null,
            };
        },
        watch: {
            visible(val) {
                if (val && this.row.id) {
                    const {
                        id,
                        goodsName,
                        country,
                        hsCode,
                        addtionalCode,
                        taxRate,
                        purpose,
                        material,
                        unitValue,
                    } = this.row;
                    this.addForm = {
                        id,
                        goodsName,
                        country,
                        hsCode,
                        addtionalCode,
                        taxRate,
                        purpose,
                        material,
                        unitValue,
                    };
                } else {
                    this.addForm = {};
                }
            },
        },
        created() {
        },
        methods: {
            initCountryList() {
                if (this.countrylist.length <= 0) {
                    this.countrylist = [];
                    getallcountries().then(res => {
                        if (res.success) {
                            for (var i = 0; i < res.result.length; i++) {
                                let obj = {};
                                obj.value = res.result[i].cnName;
                                obj.label = res.result[i].twoCode + "-" + res.result[i].cnName;
                                this.countrylist.push(obj);
                            }
                        }
                    }).catch(err => {
                        this.$message.error(err)
                    })
                }

            },
            handleSubmit() {
                let url = this.url.add;
                let method = "POST";
                if (this.row.id) {
                    url = this.url.edit;
                    method = "PUT";
                }
                const params = JSON.parse(JSON.stringify(this.addForm));
                this.$refs.addForm.validate((valid) => {
                    if (valid) {
                        httpAction(url, method, params).then((res) => {
                            this.$message({
                                type: "success",
                                message: res.message,
                            });
                            this.handleClose();
                            this.$emit("ok");
                        });
                    }
                });
            },
            showItemForm(e) {
                if (e === "仓库代码" && this.addForm.shipmentZip) {
                    this.isWarehouse = true;
                } else if (e === "FbaCode" && this.addForm.shipmentCode) {
                    this.isWarehouse = false;
                } else {
                    this.isWarehouse = null;
                }
            },
            handleClose() {
                this.isWarehouse = null;
                this.$emit("update:visible", false);
                this.$refs.addForm.resetFields();
            },
            changetChannelId(e) {
                if (e && e.itemText) {
                    this.addForm.channelName = e.itemText;
                } else {
                    this.addForm.channelName = "";
                }
            },
        },
    };
</script>

<style lang="scss" scoped></style>
