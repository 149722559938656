<template>
    <div>
        <el-card class="box-card">
            <el-form class="multiline_select" ref="form" :model="queryForm" label-width="80px" size="medium">
                <el-card class="box-card">
                    <el-row  class="inputs_row">
                        <el-col :xs="24" :sm="12" :md="12" :lg="6">
                            <el-form-item label="订单号">
                                <el-input  v-model="queryForm.orderNo" placeholder="多个单号请输入完整单号" type="textarea"
                                           @keyup.enter.native="handleSearch"></el-input>
                            </el-form-item>
                        </el-col>

                        <el-col class="no_margin"  :xs="24" :sm="12" :md="12" :lg="6">
                            <el-form-item label="库存状态">
                                <f-select :dict="'warehouse_stock_status'" v-model="queryForm.statusList" :isNeed="queryForm.statusList" :multiple="true"
                                          @keyup.enter.native="handleSearch"></f-select>
                            </el-form-item>
                        </el-col>

                        <el-col  class="no_margin" :xs="24" :sm="12" :md="12" :lg="6">
                            <el-form-item label="渠道名称">
                                <f-select :dict="'transportStatus'" v-model="queryForm.channelIdList" :isNeed="queryForm.channelIdList" :multiple="true"
                                          @keyup.enter.native="handleSearch"></f-select>
                            </el-form-item>
                        </el-col>

                        <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="7">
                            <el-form-item label="入仓时间">
                                <div class="f-flex">
                                    <el-date-picker
                                            class="f-flex-1"
                                            v-model="queryForm.arriveTimeStart"
                                            type="datetime"
                                            size="small"
                                            placeholder="开始时间"
                                            default-time="00:00:00"
                                            value-format="yyyy-MM-dd HH:mm:ss"
                                            @keyup.enter.native="handleSearch"
                                    >
                                    </el-date-picker>
                                    <span class="f-p-h-2">~</span>
                                    <el-date-picker
                                            class="f-flex-1"
                                            v-model="queryForm.arriveTimeEnd"
                                            type="datetime"
                                            size="small"
                                            placeholder="结束时间"
                                            default-time="23:59:59"
                                            value-format="yyyy-MM-dd HH:mm:ss"
                                            @keyup.enter.native="handleSearch"
                                    >
                                    </el-date-picker>
                                </div>
                            </el-form-item>
                        </el-col>

                        <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="6">
                            <el-form-item label="是否盘点">
                                <f-select :dict="'unit_whether'" v-model="queryForm.isCheckList" :isNeed="queryForm.isCheckList" :multiple="true"
                                          @keyup.enter.native="handleSearch"></f-select>
                            </el-form-item>
                        </el-col>

                        <el-col :xs="24" :sm="12" :md="12" :lg="4">
                            <el-form-item label-width="30px">
                                <el-button
                                        type="primary"
                                        icon="el-icon-search"
                                        @click="handleSearch"
                                >查询
                                </el-button
                                >
                                <el-button plain @click="handleReset">重置</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>


                </el-card>
            </el-form>
            <div class="bbb">
                <el-upload
                        class="upload-demo margin_right_10"
                        action=""
                        :http-request="importExcelData"
                        :show-file-list="false"
                        :before-upload="beforeUpload"
                        accept=".xls,.xlsx"
                        v-if="buttonShowList.im"
                >
                    <el-button type="primary" size="medium">导入盘点库存</el-button>
                </el-upload>
                <el-button type="primary" size="medium" @click="exportExcelPlus(url.exportExcelUrl,'库存管理数据')"
                           v-if="buttonShowList.dc">导出库存管理
                </el-button>
            </div>
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="73vh"
            >
                <template v-slot="scope">
<!--                    <template v-if="scope.column.property === 'action'">-->
<!--                        <el-button type="text" @click="handleEdit(scope.row)">编辑</el-button>-->
<!--                    </template>-->
                    <template v-if="scope.column.property === 'orderNo'">
                        <a style="color: #00a0e9" @click.prevent="$intoDocPage(scope.row.orderNo)">{{ scope.row.orderNo }}
                        <span v-if="scope.row.isOver" style="color: red"><br>超过24小时未录材积</span>
                        </a>
                    </template>
                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange">
                </el-pagination>
            </el-row>
        </el-card>
    </div>
</template>

<script>
    import QueryMixins from "@/mixins/QueryMixins.js";
    import {deleteAction, postAction,httpAction} from "@/api/manage";
    export default {
        name: "WarehouseStockCharge",
        mixins: [QueryMixins],
        components: {},
        data() {
            return {
                isUsePost:true,
                formLabelWidth: "120px",

                //维护 派送费 价格模板计算 （费用名称，最小值，最大值，单位，fbacode，成本，（美元转cny汇率），基数，公式展示（(成本*汇率)/基数），计算出来的单价），结算对象
                columns: [
                    {
                        type: "selection",
                    },
                    // {
                    //     prop: "action",
                    //     label: "操作",
                    // },
                    {
                        prop: "orderNo",
                        label: "单号",
                    },
                    {
                        prop: "writeSource",
                        label: "录入来源",
                    },
                    {
                        prop: "statusStr",
                        label: "库存状态",
                    },
                    {
                        prop: "warehouseName",
                        label: "仓库名称",
                    },
                    {
                        prop: "arriveTime",
                        label: "入仓时间",
                    },
                    {
                        prop: "ctnStr",
                        label: "预计件重体",
                    },
                    {
                        prop: "realCtnStr",
                        label: "实际件重体",
                    },
                    {
                        prop: "receiveCountry",
                        label: "国家邮编",
                    },
                    {
                        prop: "channelName",
                        label: "渠道名称",
                    },
                    {
                        prop: "warehouseRemark",
                        label: "仓库备注",
                    },
                    {
                        prop: "arriveLocation",
                        label: "入库库位",
                    },
                    {
                        prop: "tempLocation",
                        label: "临时库位",
                    },
                    {
                        prop: "checkLocation",
                        label: "盘点库位",
                    },
                    {
                        prop: "arriveMan",
                        label: "卸货员",
                    },
                    {
                        prop: "receivedMan",
                        label: "收货员",
                    },
                    {
                        prop: "tallyClerk",
                        label: "理货员",
                    },
                    {
                        prop: "isIssue",
                        label: "是否问题件",
                    },
                    {
                        prop: "preLoadingTime",
                        label: "预计装柜时间",
                    },
                    //volume_audit_time
                    {
                        prop: "volumeAuditTime",
                        label: "材积审核时间",
                    },

                ],
                url: {
                    //查询列表的接口
                    list: "/warehouse/stock/list",
                    //导入excel的接口
                    importExcelUrl: '/warehouse/stock/importCheckRecord',
                    //导出excel的接口
                    exportExcelUrl: '/file/export/excel/downloadWarehouseStockChargeVoExcel',
                },


            };
        },
        methods: {
            //导入excel数据
            importExcelData(data) {
                console.log("上传文件", data);
                const formData = new FormData();
                formData.append("file", data.file);
                postAction(this.url.importExcelUrl, formData)
                    .then((res) => {
                        if (res.success) {
                            this.$message.success(res.message);
                            this.handleQuery();
                        }
                    })
            },
            beforeUpload(file) {
                //对文件大小进行限制
                const isLt2M = file.size / 1024 / 1024 < 15;
                if (!isLt2M) {
                    this.$message.warning("文件大小不能超过15MB");
                }
                const isFile = file.name.indexOf(".") !== -1;
                if (!isFile) {
                    this.$message.warning("该文件名不合法");
                }
                return isLt2M && isFile;
            },
        },
      computed:{
        buttonShowList() {
          return {
            'dc':this.$btnIsShow('warehouse2.0/WarehouseStockCharge','0','导出库存管理'),
            'im':this.$btnIsShow('warehouse2.0/WarehouseStockCharge','0','导入盘点库存'),
          }
        }
      }
    };
</script>

<style lang="scss" scoped>
    .bbb {
        display: flex;
    }

    .dialog-footer {
        text-align: right !important;
    }

    .margin_right_10 {
        margin-right: 10px;
    }
</style>
