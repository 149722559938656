<template>
    <div>
        <el-card class="box-card">
            <el-form class="multiline_select" ref="form" :model="queryForm" label-width="80px" size="medium">
                <el-card class="box-card">
                    <el-row>
                        <el-col :xs="24" :sm="12" :md="12" :lg="6">
                            <el-form-item label="单号">
                                <el-input  v-model="queryForm.orderNo" type="textarea"
                                           @keyup.enter.native="handleSearch"></el-input>
                            </el-form-item>
                        </el-col>
                      <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="FBA ID">
                          <el-input  v-model="queryForm.shipmentId" type="textarea"
                                     @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="客户名">
                          <el-input  v-model="queryForm.customer"
                                     @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="业务员">
                          <f-select  v-model="queryForm.salesmanList" :is-need="queryForm.salesmanList" :dict="'sys_user'"
                                     :multiple="true"
                                     @keyup.enter.native="handleSearch"></f-select>
                        </el-form-item>
                      </el-col>

                      <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="推送类型">
                          <el-select  v-model="queryForm.pushNodes" clearable filterable
                                     :multiple="true"
                                     @keyup.enter.native="handleSearch">
                            <el-option value="0" label="推送trackingID"></el-option>
                            <el-option value="1" label="入仓日期"></el-option>
                            <el-option value="2" label="出口报关日期"></el-option>
                            <el-option value="3" label="实际开船日期"></el-option>
                            <el-option value="4" label="实际船靠时间"></el-option>
                            <el-option value="5" label="进口报关日期"></el-option>
                            <el-option value="6" label="目的港仓库收货日期"></el-option>
                            <el-option value="7" label="亚马逊预约日期"></el-option>
                            <el-option value="9" label="派送日期"></el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                      <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="结果">
                          <el-select  v-model="queryForm.result" filterable clearable
                                      @keyup.enter.native="handleSearch">
                            <el-option value="成功" label="成功"></el-option>
                            <el-option value="失败" label="失败"></el-option>
                            <el-option value="未推" label="未推"></el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                      <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="错误编码">
                          <el-input  v-model="queryForm.errorCode"
                                     @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                      </el-col>
                        <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="7">
                            <el-form-item label="开始时间">
                                <div class="f-flex">
                                    <el-date-picker
                                            class="f-flex-1"
                                            v-model="queryForm.createTimeStart"
                                            type="datetime"
                                            size="small"
                                            placeholder="开始时间"
                                            default-time="00:00:00"
                                            value-format="yyyy-MM-dd HH:mm:ss"
                                            @keyup.enter.native="handleSearch"
                                    >
                                    </el-date-picker>
                                    <span class="f-p-h-2">~</span>
                                    <el-date-picker
                                            class="f-flex-1"
                                            v-model="queryForm.createTimeEnd"
                                            type="datetime"
                                            size="small"
                                            placeholder="结束时间"
                                            default-time="23:59:59"
                                            value-format="yyyy-MM-dd HH:mm:ss"
                                            @keyup.enter.native="handleSearch"
                                    >
                                    </el-date-picker>
                                </div>
                            </el-form-item>
                        </el-col>



                        <el-col :xs="24" :sm="12" :md="12" :lg="4">
                            <el-form-item label-width="30px">
                                <el-button
                                        type="primary"
                                        icon="el-icon-search"
                                        @click="handleSearch"
                                >查询
                                </el-button
                                >
                                <el-button plain @click="handleReset">重置</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>


                </el-card>
            </el-form>


            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="73vh"
            >
                <template v-slot="scope">
                  <template v-if="scope.column.property === 'orderNo'">
                    <a
                        style="color: #00a0e9"
                        @click.prevent="$intoDocPage(scope.row.orderNo)"
                    >{{ scope.row.orderNo }}</a
                    >
                  </template>
                  <template v-else-if="scope.column.property === 'action'">
                    <el-button type="text" @click="pushOne(scope.row)"
                               v-if="scope.row.pushResult !=='成功' && buttonShowList['rePush']">
                      重新推送
                    </el-button>
                  </template>
                  <template v-else-if="scope.column.property === 'pushResult'">
                    <span style="color:green" v-if="scope.row.pushResult === '成功'">{{scope.row.pushResult}}</span>
                    <span style="color:red" v-else>{{scope.row.pushResult}}</span>
                  </template>
                  <template v-else-if="scope.column.property === 'pushTimes'">
                    <el-popover trigger="hover" placement="top" >
                      <!-- 鼠标移动需要提示的其他字段信息（showMessage字段传递对应要展示的字段名称） : 自己的字段超出的鼠标移动展示  -->
                      <span v-for="(item,index) in scope.row.amazonPushLogTimesList" :key="index">
                            发送时间： {{item.createTime}}
                            -- 发送结果：<span :style="item.result === '成功' ? 'color:green' : 'color : red'">{{item.result}}</span>

                          <span v-if="item.result === '失败'">-- 失败原因：：{{item.failedMsg}}</span>

                            <br>
                        </span>
                      <div slot="reference" class="name-wrapper tag-center">
                        <el-tag size="medium" class="tag-ellipsis">{{ scope.row.pushTimes }}</el-tag>
                      </div>
                    </el-popover>
                  </template>
                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange">
                </el-pagination>
            </el-row>
        </el-card>
    </div>
</template>

<script>
    import QueryMixins from "@/mixins/QueryMixins.js";
    import {deleteAction, postAction,httpAction} from "@/api/manage";
    export default {
        name: "TakeOrderWork",
        mixins: [QueryMixins],
        components: {},
        data() {
            return {
                isUsePost:true,

                columns: [
                    // {
                    //     type: "selection",
                    // },
                    {
                        prop: "action",
                        label: "操作",
                    },
                    {
                        prop: "orderNo",
                        label: "订单号",
                    },
                    {
                        prop: "customer",
                        label: "客户名",
                    },
                  {
                    prop: "salesman",
                    label: "销售",
                  },
                  {
                    prop: "shipmentId",
                    label: "FBA ID",
                  },
                  {
                    prop: "trackingId",
                    label: "tracking ID",
                  },
                  {
                    prop: "pushDesc",
                    label: "推送类型",
                  },
                  {
                    prop: "pushResult",
                    label: "结果",
                  },
                  {
                    prop: "failedMsg",
                    label: "失败原因",
                  },
                  {
                    prop: "errorCode",
                    label: "失败编码",
                  },
                  {
                    prop: "createTime",
                    label: "创建时间",
                  },
                  {
                    prop: "pushSuccessTime",
                    label: "推送成功时间",
                  },
                  {
                    prop: "pushTimes",
                    label: "推送次数",
                  },
                  {
                    prop: "eddTime",
                    label: "edd时间",
                  },
                  {
                    prop: "ed",
                    label: "ed天数",
                  },
                ],
                url: {
                    //查询列表的接口
                    list: "/express/test/getAmazonPushLogIPage",
                    pushAgain:'/express/test/pushLogAgainById',
                },
                disableMixinMounted:true


            };
        },
        methods: {
          pushOne({id,pushNode}){
            let str = "此操作会将该订单的所有未推送成功的shipmentId 重新推送一遍"
            if ("0" !== pushNode) {
              str = "此操作只会将该数据 重新推送一遍";
            }
            this.$confirm(str, "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "error",
              closeOnClickModal: false,
            }).then(() => {
              postAction(this.url.pushAgain + "?id="+id)
              .then(res=>{
                this.$message.success(res.message);
                this.handleSearch();
              })
            })
          },
          handleSearch () {
            if(!this.queryForm.pushNodes) {
              this.queryForm.pushNodes =[]
            }
            if(!this.queryForm.salesmanList) {
              this.queryForm.salesmanList =[]
            }
            if(!this.queryForm.orderNo && !this.queryForm.shipmentId && !this.queryForm.customer && !this.queryForm.salesmanList.length && !this.queryForm.pushNodes.length && !this.queryForm.result && !this.queryForm.errorCode && !this.queryForm.createTimeStart && !this.queryForm.createTimeEnd && !this.queryForm.createTimeEnd) {
              this.$message.warning("查询条件不能为空，请输入条件查询");
                      return
            }
            this.ipagination.currentPage = 1;
            this.handleQuery()
          },
        },
      computed:{
          buttonShowList(){
            return {
              'rePush':this.$btnIsShow('channel/AmazonPushLog','1','重新推送'),

            }
          }
      }
    };
</script>

<style lang="scss" scoped>
    .bbb {
        display: flex;
    }

    .dialog-footer {
        text-align: right !important;
    }

    .margin_right_10 {
        margin-right: 10px;
    }
</style>
