<template>
  <div>
    <docpage-edit v-if="isShowEdit" class="components_edit"></docpage-edit>
  </div>
</template>

<script>

import docpageEdit from "@/views/docpage/Edit";

export default {
  name: "BasicModule",
  components: {
    docpageEdit,
  },
  data() {
    return {
      isShowEdit: this.$route.query.orderNo ? true : false
    };
  },
  watch: {
    '$route': {
        deep: true,
        handler(to, from) {
            this.isShowEdit = false;
            setTimeout(() => {this.isShowEdit = this.$route.query.orderNo ? true : false;},50);
        }
    }
  },
  methods: {},

};
</script>

<style lang="scss">
.components_edit .fixed_main .el-tabs__header{
    left: 260px !important;
    width: calc(100% - 260px) !important;
}
</style>
<style lang="scss" scoped>
.components_edit {
  height: 87vh;
  overflow-y: scroll;
}
</style>
