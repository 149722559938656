<template>
    <div>
        <el-tabs tab-position="left"  @tab-click="handleClick">
            <el-tab-pane
                v-for="(tab, index) in tabs"
                :key="index"
                :label="tab.label"
                :lazy="true"
               >
                <component  :is="tab.component" v-if="tab.show" :menuId="tab.value"></component>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import BdVue from '@/views/isystem/BaseData/BdVue'
import {getAction} from "@/api/manage";


export default {
    name: 'EditBtn',
    components: {
        BdVue
    },
    data() {
        return {
            tabs: [],
          //将页面的id传递至下一层
          pageId:'',
          url:{
              getById:'/customer/getById',
              getMenuList:'/sys/dict/getDictItems/btn_menu',
          },
          //是否显示
          parentId:'',
        }
    },
  methods:{
      handleClick(tab){
          console.log('点击选中的tab',tab);

          this.tabs.map(t=>{
              if (t.label !== tab.label){
                  t.show = false;
              }else {
                  t.show = true;
              }
          })
      },
      initPageid() {
        getAction(this.url.getMenuList)
          .then(res=>{
              if (res.success){
                  let list = res.result;
                  for (var i=0;i<list.length;i++) {
                      this.tabs.push({label:list[i].text,value:list[i].value,show:true,component:'BdVue'})
                  }
              }
          })
      }
  },
  created() {
      this.initPageid();

  }
}
</script>

<style lang="scss" scoped>
.el-divider--horizontal {
    margin-top: 16px;
    margin-bottom: 20px;
    .el-divider__text {
        font-size: 20px;
    }
}
</style>
