<!--快递首次下单-->
<template>
    <div class="main-full-content">
        <el-form class="multiline_select no_margin" ref="form" :model="queryForm" label-width="80px" size="medium">
            <el-card class="box-card">
                <el-row class="inputs_row">
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="最早下单时间" label-width="100px">
                            <el-date-picker
                              class="input time"
                              v-model="queryForm.createTime"
                              type="month"
                              size="small"
                              default-time="00:00:00"
                              placeholder="开始时间"
                              value-format="yyyy-MM-dd HH:mm:ss"
                              style="width: 100%"
                              @click.stop.native
                            ></el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                      <el-form-item label="销售组别">
                        <f-select
                            v-model="queryForm.deptIdList"
                            :dict="'sys_depart'"
                            :isNeed="queryForm.deptIdList"
                            :multiple="true"
                        >
                       </f-select>
                      </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label-width="30px">
                            <el-button
                                    type="primary"
                                    icon="el-icon-search"
                                    @click="handleSearch"
                            >查询
                            </el-button
                            >
                            <el-button plain @click="handleReset">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>
        </el-form>
        <el-card class="box-card last_card">
            <div class="f-p-b-15">
                <el-button type="primary" size="medium" icon="el-icon-plus" @click="exportExcelPlus(url.downloadExcelUrl,'快递首次下单报表')">导出</el-button>
            </div>
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="68vh"
                    :isRecordTableScroll="true"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange"
                    @queryChange="handleTableQueryChange"
            >
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'orderNo'">
                        <a style="color: #00a0e9" @click.prevent="$intoDocPage(scope.row.orderNo)"
                        >{{ scope.row.orderNo }}</a>
                    </template>
                    <template v-else-if="scope.column.property === 'customer'">
                        <a type="text" @click.prevent="$intoCustomerEditPage(scope.row.cusId)" style="color:#57a3f3;">{{scope.row.customer}}</a>
                    </template>
                  <template v-else-if="scope.column.property === 'deptId'">
                    <span>{{parseDict(scope.row.deptId,'sys_depart')}}</span>
                  </template>
                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                >
                </el-pagination>
            </el-row>
        </el-card>
    </div>
</template>

<script>
    import {deleteAction, postAction, putAction} from "@/api/manage";
    import QueryMixins from "../../../mixins/QueryMixins";
    import { setUpRouteUtil } from '@/utils/util';

    export default {
        name: "Potential",
        mixins: [QueryMixins],
        data() {
            return {
                dictCodes: ['is_default','sys_depart'],
                ids: [],
                row: {},
                form: {},
                formLabelWidth: "100px",
                columns: [
                    {
                        prop: "salesman",
                        label: "业务",
                        minWidth: 200,
                    },
                   {
                      prop: 'deptId',
                      label: '销售组别',
                    },
                    {
                        prop: "orderNo",
                        label: "订单号",
                        minWidth: 200,
                    },
                    {
                        prop: "createTime",
                        label: "最早下单时间",
                        minWidth: 200,
                        sortable: "custom",
                    },
                    {
                        prop: "customer",
                        label: "客户名",
                        minWidth: 200,
                    },
                    {
                        prop: "customerCreateTime",
                        label: "注册时间",
                        minWidth: 200,
                    },
                ],
                url: {
                    list: "/order/kdOrderReport/kdShouciList",
                    //优化的导出
                    downloadExcelUrl: "/file/export/excel/downloadExpressFirstSubmitOrderExcel",
                },
                isUsePost:true
            };
        },
        methods: {

            intoDocpage(orderNo) {
                // 对于路由不在左侧菜单中的选中菜单处理
                setUpRouteUtil(this.$route.path, '/kdorder/Edit');
                this.$router.push('/kdorder/Edit?orderNo=' + orderNo);
            },
            handleReset() {
                this.queryForm = {};
                this.filterForm = {};
                this.ipagination.currentPage = 1;
                this.handleQuery();
            },
        },
        created() {
        },
    };
</script>

<style lang="scss" scoped>
</style>
