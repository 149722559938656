import axios from '@/utils/request'

const api = {
  // addressEnum: '/interface/label/addressEnum',
  // checkAddress: '/interface/label/checkAddress'
}

export default api



export function addressEnum(parameter) {
  return axios({
    url: '/interface/label/addressEnum',
    method: 'post',
    data: parameter
  })
}


export function checkAddress(parameter) {
  return axios({
    url: '/interface/label/checkAddress',
    method: 'post',
    data: parameter
  })
}
