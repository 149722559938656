<template>
  <div>


    <el-card class="box-card">
      <el-form :model="hbl" ref="hbl" :rules="rules">
        <el-form-item label="提单号" :label-width="formLabelWidth2">
          <el-input
                  v-model="hbl.blNo"
                  maxlength="50"
                  show-word-limit
                  class="padding_right_area"
          ></el-input>
        </el-form-item>
        <el-form-item label="发货人（SHIPPER）" :label-width="formLabelWidth2">
          <el-input
            type="textarea"
            v-model="hbl.shipper"
            maxlength="500"
            show-word-limit
            class="padding_right_area"
            rows="5"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="收货人（CONSIGNEE）"
          :label-width="formLabelWidth2"
        >
          <el-input
            type="textarea"
            v-model="hbl.consignee"
            maxlength="500"
            show-word-limit
            class="padding_right_area"
            rows="5"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="通知人（NOTIFY PARTY）"
          :label-width="formLabelWidth2"
        >
          <el-input
            type="textarea"
            v-model="hbl.notifyParty"
            maxlength="500"
            show-word-limit
            class="padding_right_area"
            rows="5"
          ></el-input>
        </el-form-item>
        <el-form-item label="HBL（备注）" :label-width="formLabelWidth2">
          <el-input
            type="textarea"
            v-model="hbl.remark"
            maxlength="500"
            show-word-limit
            class="padding_right_area"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="f-p-b-15" style="text-align: center">
        <el-button size="medium" type="primary" @click="savebl" :disabled="isShow"
          >保存hbl</el-button
        >
      </div>
    </el-card>
  </div>
</template>

<script>
import { getAction, postAction } from "../../../api/manage";

export default {
  name: "ISFandHblModule",
  props:{
    isShow:{
      type:Boolean,
      default:false,
    }
  },
  components: {},
  data() {
    return {
      isf: {},
      hbl: {},
      formLabelWidth2: "160px",
      url: {
        savehbl: "/interface/ttOrder/addOrUpdateHblByOrderNo",
        gethbl: "/operation/container/getOneMblByContainerId",
      },
      //表单校验
      rules: {
        name: [{ required: true, message: "请输入文件名", trigger: "blur" }],
        type: [
          { required: true, message: "请选择文件类型", trigger: "change" },
        ],
      },
    };
  },
  created() {
    this.$set(this.isf, "orderNo", this.$route.query.orderNo);
    this.$set(this.hbl, "orderNo", this.$route.query.orderNo);
    this.initbl();
  },
  mounted() {},
  methods: {
    initbl() {
      getAction(this.url.gethbl, {type:'0', containerId:this.$route.query.id || ''}).then(
        (res) => {
          if (res.success) {
            if (res.result.id) {
              this.hbl = res.result;
            }
          }
        }
      );
    },
    savebl() {
      this.$set(this.hbl, "containerId", this.$route.query.id || '');
      this.$set(this.hbl, "type", "0");
      postAction(this.url.savehbl, this.hbl).then((res) => {
        this.$message.success(res.message);
        this.initbl();
      });
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
