<template>
    <div class="main-full-content">
        <el-card class="box-card">
            <el-form
                ref="form"
                :model="queryForm"
                label-width="80px"
                size="medium"
            >
                <el-row class="inputs_row">
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="申请编号">
                            <el-input type="textarea" v-model="queryForm.applyNoSelf" @keyup.enter.native="handleCheck"
                                      placeholder="请用逗号或者空格隔开"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="代理商">
                            <el-input v-model="queryForm.settleName" @keyup.enter.native="handleCheck"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="费用币种">
                            <f-select
                                v-model="queryForm.payCur"
                                dict="bd_cur"
                                :is-need="queryForm.payCur"
                            ></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="8">
                        <el-form-item label="申请时间">
                            <el-date-picker
                                class="f-flex-1"
                                v-model="stime"
                                type="datetimerange"
                                size="small"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                :picker-options="editStartOptions"
                                :default-time="['00:00:00', '23:59:59']"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                format="yyyy-MM-dd HH:mm:ss"
                                unlink-panels
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="申请人">
                            <!--                            <el-input v-model="queryForm.creator" @keyup.enter.native="handleCheck"></el-input>-->
                            <f-select :dict="'sys_creator_id'"
                                      v-model="queryForm.creator"
                                      :isNeed="queryForm.creator">
                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="5">
                        <el-form-item label="业务类型">
                            <el-select v-model="queryForm.agentPayType" clearable>
                                <el-option value="0" label="海运"></el-option>
                                <el-option value="1" label="柜子"></el-option>
                                <el-option value="2" label="快递"></el-option>
                                <el-option value="3" label="海运/柜子混合"></el-option>
<!--                                <el-option value="4" label="海外仓"></el-option>-->
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="业务员">
                            <f-select
                                :dict="'sys_user_id'"
                                v-model="queryForm.salesmanId"
                                :is-need="queryForm.salesmanId"
                                :father-val="queryForm.salesmanId"
                            ></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="数据时间">
                            <f-select
                                    class="textarea_table"
                                    v-model="queryForm.dataCreateTime"
                                    :is-need="queryForm.dataCreateTime"
                                    :dict="'data_create_time'"
                                    @keyup.enter.native="handleSearch"
                            ></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label-width="30px" style="">
                            <el-button
                                type="primary"
                                icon="el-icon-search"
                                @click="handleCheck"
                            >查询
                            </el-button
                            >
                            <el-button plain @click="handleReset">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card class="box-card last_card">
            <div class="f-p-b-15">
                <el-button
                    size="medium"
                    type="primary"
                    @click="verifyBatch"
                    v-if="buttonShowList.plsh"
                >批量审核
                </el-button>
              <el-button
                  size="medium"
                  type="primary"
                  @click="checkOpeBatch"
                  :disabled="selectedRowKeys.length === 0"
                  v-if="buttonShowList.plczsh"
              >批量操作审核</el-button>
              <el-button
                  size="medium"
                  type="primary"
                  @click="checkChaBatch"
                  :disabled="selectedRowKeys.length === 0"
                  v-if="buttonShowList.plqdsh"
              >批量渠道审核</el-button>
                <el-button
                    size="medium"
                    type="primary"
                    @click="downloadPayExcel"
                    v-if="buttonShowList.dcexcel"
                >导出excel
                </el-button>
            </div>
            <f-table
                v-loading="loading"
                ref="GTable"
                border
                size="medium"
                row-key="id"
                class="c-th-has-bgcolor"
                :columns="columns"
                :data="tableData"
                :cell-style="{ 'text-align': 'center' }"
                tableHeight="66vh"
                @selection-change="handleSelectionChange"
                @sort-change="handleTableChange">
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'picUrl'">
                        <span v-for="(item, index) in scope.row.fileList" :key="index">
                            <a :href="item.url" target="_Blank" download>{{ item.name }}</a>
                        </span>
                    </template>
                    <template v-else-if="scope.column.property === 'applyNo'">
                        <el-button type="text" @click="showApplyDetail(scope.row)">{{ scope.row.applyNo }}
                        </el-button>
                    </template>
                    <template v-else-if="scope.column.property === 'creator'">
                        <span>{{ parseDict(scope.row.creator, 'sys_create_by') }}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'invoiceNoThird'">
                        <span v-for="(item, index) in scope.row.supplierInvoiceNos" :key="index">
                            {{ item + ';' }}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'agentPayType'">
                        <span v-if="scope.row.agentPayType=='0'">海运费用申请</span>
                        <span v-if="scope.row.agentPayType=='1'">柜子的费用申请</span>
                        <span v-if="scope.row.agentPayType=='2'">快递费用申请</span>
                        <span v-if="scope.row.agentPayType=='3'">订单/柜子混合费用申请</span>
                        <span v-if="scope.row.agentPayType=='4'">海外仓费用申请</span>
                    </template>
                    <template v-else-if="scope.column.property === 'action'">
                        <!--                        <el-button type="text" @click="backToWaitApply(scope.row)">退回待申请</el-button>-->
                        <!--<el-button type="text" @click="handleDele(scope.row)">删除</el-button>-->
                        <el-popconfirm
                            title="删除操作将退回至待申请付款，是否确认？"
                            confirm-button-text="确认"
                            cancel-button-text="不用了"
                            icon="el-icon-info"
                            icon-color="red"
                            @confirm="backToWaitApply(scope.row)">
                            <el-button slot="reference" type="text"
                                       v-if="buttonShowList.sc"
                            > 删除
                            </el-button>
                        </el-popconfirm>
                        <el-divider direction="vertical"/>
                        <el-button type="text" v-if="buttonShowList.xzfksq"
                                   @click="downloadPayApply(scope.row)"
                        > 下载付款申请
                        </el-button>

                    </template>
                  <template v-else-if="scope.column.property === 'opeCheck'">
                    <span>操作审核人：{{scope.row.opeCheckMan}}</span>
                    <br>
                    <span>渠道审核人：{{scope.row.chaCheckMan}}</span>
                  </template>

                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                    v-bind="ipagination"
                    layout="total, sizes, prev, pager, next, jumper"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                >
                </el-pagination>
            </el-row>
        </el-card>

        <pay-apply-dialog ref="payApply" @ok="this.handleQuery"></pay-apply-dialog>
        <apply-detail-dialog ref="applyDetail" @ok="this.handleQuery"></apply-detail-dialog>
        <container-apply-detail-dialog ref="containerApplyDetailDialog"
                                       @ok="this.handleQuery"></container-apply-detail-dialog>
        <apply-mix-detail-dialog ref="applyMixDetailDialog" @ok="this.handleQuery"></apply-mix-detail-dialog>
        <merge-dialog ref="mergeDialog" @ok="this.handleQuery"></merge-dialog>

    </div>

</template>
<script>
import QueryMixins from "../../../../mixins/QueryMixins";
import {getAction, postAction} from "@/api/manage";
import payApplyDialog from "./payApplyDialog";
import applyDetailDialog from "./applyDetailDialog";
import containerApplyDetailDialog from "@/views/hyFinanceManage/modules/pay/containerApplyDetailDialog";
import mergeDialog from "./mergeDialog";
import WaitApplyPayContainer from "@/views/hyFinanceManage/modules/pay/waitApplyPayContainer";
import applyMixDetailDialog from "@/views/hyFinanceManage/modules/pay/applyMixDetailDialog";

export default {
    mixins: [QueryMixins],
    components: {
        WaitApplyPayContainer,
        payApplyDialog,
        applyDetailDialog, containerApplyDetailDialog,
        mergeDialog,
        applyMixDetailDialog
    },
    data() {
        return {
            dictCodes: ['sys_create_by'],
            editStartOptions: {
                disabledDate: (time) => {
                    if (!this.form.etime) {
                        // time = new Date(time.replace(/-/g, '/'));
                        return time.getTime() < new Date(1970 - 1 - 1).getTime(); //禁止选择1970年以前的日期
                    } else {
                        return time.getTime() > new Date(this.form.etime);
                    }
                },
            },
            editStopOptions: {
                disabledDate: (time) => {
                    // time = new Date(time.replace(/-/g, '/'));
                    return (
                        time.getTime() < new Date(this.form.stime) ||
                        time.getTime() < new Date(1970 - 1 - 1).getTime() //禁止选择1970年以前的日期
                    );
                },
            },
            selectedRowKeys: [],
            selectionRows: [],
            selectedNos: [],
            loading: false,
            form: {},
            columns: [
                {
                    type: "selection",
                    width: 50,
                },
                {
                    prop: "action",
                    label: "操作",
                },
                {
                    prop: "agentPayType",
                    label: "申请类型",
                },
                {
                    prop: "applyNo",
                    label: "申请编号",
                },
                {
                    prop: "applyDownTimes",
                    label: "下载次数",
                    width: 60,
                },
                {
                    prop: "invoiceNoThird",
                    label: "代理InvoiceNo",
                },

                {
                    prop: "invoiceRemark",
                    label: "备注",
                },
                {
                    prop: "invoiceNo",
                    label: "发票号",
                },
                {
                    prop: "picUrl",
                    label: "账单附件",
                },
                {
                    prop: "settleName",
                    label: "代理商",
                },
                {
                    prop: "payMoney",
                    label: "应付金额",
                },
                {
                    prop: "payCur",
                    label: "应付币种",
                },
                {
                    prop: "bankName",
                    label: "收款开户行",
                },
                {
                    prop: "settleAccount",
                    label: "收款账号",
                },
                {
                    prop: "payMan",
                    label: "收款人",
                },
                {
                    prop: "creator",
                    label: "申请人",
                },
                {
                    prop: "createTime",
                    label: "申请时间",
                },
                {
                    prop: "lastPayTime",
                    label: "最晚付款时间",
                },
              // {
              //   prop: "opeCheck",
              //   label: "审核情况",
              // },
              // {
              //   prop: "opeCheckMan",
              //   label: "操作审核人",
              // },
              {
                prop: "opeCheckMan",
                label: "操作审核人",
              },
              {
                prop: "chaCheckMan",
                label: "渠道审核人",
              },
            ],

            url: {
                list: "/shipping/hySettlePay/waitVerifyList",
                verifyBatch: "/shipping/hySettlePay/verifyBatch",
                backToWaitApply: "/shipping/hySettlePay/backToWaitApply",
                downloadPayExcel: '/shipping/hySettlePay/downloadPayExcel',
                downloadPayOutApply: "/file/jasper/downloadPayOutApply",
                updateOpe: "/shipping/hySettlePay/updateOpeCheck",
                updateCha: "/shipping/hySettlePay/updateChaCheck",


            },

            rules: {
                realCtn: [
                    {required: true, message: "请输入实际总件数", trigger: "blur"},
                    {pattern: /^[1-9]\d*$/, message: "只能输入整数", trigger: "blur"},
                ],
                realKg: [
                    {required: true, message: "请输入实际总重量", trigger: "blur"},
                    {
                        pattern:
                            /^(?=([1-9]{1,10}$|[0-9]{1,7}\.))(0|[1-9][0-9]*)(\.[0-9]{1,3})?$/,
                        message: "只能输入最多三位小数的数字",
                        trigger: "change",
                    },
                ],

            },
            stime: [],
            otime: [],
            queryForm: {}
        };
    },
    computed: {
      buttonShowList(){
        return {
          'plsh':this.$btnIsShow('hyFinanceManage/modules/pay/waitVerify','0','批量审核'),
          'plczsh':this.$btnIsShow('hyFinanceManage/modules/pay/waitVerify','0','批量操作审核'),
          'plqdsh':this.$btnIsShow('hyFinanceManage/modules/pay/waitVerify','0','批量渠道审核'),
          'dcexcel':this.$btnIsShow('hyFinanceManage/modules/pay/waitVerify','0','导出excel'),
          'sc':this.$btnIsShow('hyFinanceManage/modules/pay/waitVerify','1','删除'),
          'xzfksq':this.$btnIsShow('hyFinanceManage/modules/pay/waitVerify','1','下载付款申请'),
        }
      }
    },
    created() {
      // this.$set(this.queryForm, 'agentPayType', '0');
        this.queryForm.dataCreateTime = '3';
    },

    methods: {
        checkOpeBatch(){
          this.$confirm(`确定操作审核吗`, "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "success",
            closeOnClickModal: false,
          }).then(()=>{
            postAction(this.url.updateOpe,this.selectedRowKeys)
              .then(res=>{
                this.$message({type:'success',message:res.message});
                this.handleCheck();
              })
          })
        },
      checkChaBatch(){
        this.$confirm(`确定渠道审核吗`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "success",
          closeOnClickModal: false,
        }).then(()=>{
          postAction(this.url.updateCha,this.selectedRowKeys)
              .then(res=>{
                this.$message({type:'success',message:res.message});
                this.handleCheck();
              })
        })
      },
        downloadPayApply(row) {
          let that = this;
            // console.log('进入下载付款申请')
            postAction(this.url.downloadPayOutApply, {id: row.id, applyNo: row.applyNo}, {responseType: 'blob'})
                .then(res => {
                  if (res && res.type === 'application/json') {
                    const reader = new FileReader()
                    reader.onload = function () {
                      const message = JSON.parse(reader.result).message
                      that.$message.error(message);// 将错误信息显示出来
                    }
                    reader.readAsText(res)
                    return false;
                  }else {
                    // console.log(res)
                    if (res.size === 0) {
                      this.$message.error('导出失败，请稍后再试或联系管理员')
                      return
                    }
                    var binaryData = [];
                    binaryData.push(res);
                    let filename = row.applyNo + '-' + this.getDateStr() + '付款申请'

                    let url = window.URL.createObjectURL(new Blob(binaryData, {type: 'application/vnd.ms-excel'}));
                    let link = document.createElement('a');
                    link.style.display = 'none';
                    link.href = url;
                    link.setAttribute('download', filename + '.xls');
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);//下载完成移除元素
                    window.URL.revokeObjectURL(url) //释放掉blob对象
                    // else{
                    //     let urlencoded = window.URL.createObjectURL(new Blob(binaryData,type));
                    //     window.open("/static/pdf/web/viewer.html?filename=" + filename + "&file=" + encodeURIComponent(urlencoded));
                    // }

                    // this.visible=false
                    setTimeout(() => {   //设置延迟执行
                      this.$message.success("导出成功！")
                      // this.$emit('ok')
                      // this.handleCheck();
                    }, 3000)
                  }

                })


        },
        handleReset() {
            this.queryForm = {};
            this.$set(this.queryForm, 'agentPayType', '0');
            this.stime = "";
            this.ipagination.currentPage = 1;
            this.handleQuery();
        },
        importBill() {
            this.$message.warning("此功能后续开放！");
        },
        showApplyDetail(row) {
            // console.log('agentPayType', row.agentPayType)
            // console.log('payCur', row.payCur)
            if (row.agentPayType == '1') {
                this.$refs.containerApplyDetailDialog.showDetail(row);
            } else if (row.agentPayType == '0' || row.agentPayType == '2' || row.agentPayType == '4') {
                this.$refs.applyDetail.showDetail(row);
            } else if (row.agentPayType == '3') {
                this.$refs.applyMixDetailDialog.showDetail(row);
            }

        },
        verifyBatch() {
            // console.log("申请付款以下id的费用", this.selectionRows);
            if (this.selectedRowKeys.length === 0) {
                this.$message.warning("请勾选付款申请");
                return;
            }

            // let agentPayType = []; 改多类型多币种多结算对象审核
            // this.selectionRows.forEach((item) => {
            //     agentPayType.push(item.agentPayType);
            // });
            // let agentPayTypeArr = Array.from(new Set(agentPayType));
            // console.log("curArr->", agentPayTypeArr);
            // if (agentPayTypeArr.length > 1) {
            //     this.$message.warning("订单费用付款和柜子费用付款不能一起审核");
            //     return;
            // }
            let noOpeCheck = [];
            let noChaCheck = [];
            let bankObj = 0;
            this.selectionRows.forEach((item) => {
                // console.log(item.settleAccountId)
                if (item.settleAccountId === '' || item.settleAccountId === undefined || item.settleAccountId === null) {
                    bankObj = Number(bankObj) + 1
                }
                if (item.opeCheck !== '1') {
                  noOpeCheck.push(item.id);
                }
              if (item.chaCheck !== '1') {
                noChaCheck.push(item.id);
              }
            });
            // if (noOpeCheck.length > 0) {
            //   this.$message.warning("存在操作还未审核的数据，请操作审核之后在审核！");
            //   return;
            // }
            // if (noChaCheck.length > 0) {
            //   this.$message.warning("存在渠道还未审核的数据，请渠道审核之后在审核！");
            //   return;
            // }
            // console.log("bankObj->", bankObj);
            if (bankObj > 0) {
                this.$message.warning("存在申请号缺少收款银行，请补充完整后再进行审核！");
                return;
            }


            // let countCur = [];
            // this.selectionRows.forEach((item) => {
            //     countCur.push(item.payCur);
            // });
            // let curArr = Array.from(new Set(countCur));
            // console.log("curArr->", curArr);
            // if (curArr.length > 1) { 改多币种多结算对象审核
            //     this.$message.warning("所选费用申请的币种不一致！");
            //     return;
            // }

          let settleIdCount = [];
          let settleNameCount = [];
          this.selectionRows.forEach((item) => {
            settleIdCount.push(item.settleId);
            settleNameCount.push(item.settlement);
          });
          let idArr = Array.from(new Set(settleIdCount));
          let nameArr = Array.from(new Set(settleNameCount));
          if (idArr.length > 1) {
            this.$message.warning("所选费用的结算对象不一致！");
            return;
          }
          if (nameArr.length > 1) {
            this.$message.warning("所选费用的结算对象不一致！");
            return;
          }

            let payCount = [];
            this.selectionRows.forEach((item) => {
                payCount.push(item.id);
            });
            let payArr = Array.from(new Set(payCount));

            //计算选中的申请的合并之后的应付金额
            let allPay = 0;
            this.selectionRows.forEach((item) => {
                allPay = Number(allPay) + Number(item.payMoney);
            });

            let allPaySum = []
            let allCur = []
            this.selectionRows.forEach(row => {
                allCur.push(row.payCur)
            })
            let curNum = Array.from(new Set(allCur))
            // console.log('币种->', curNum)
            //遍历每个币种，计算总和后放入sumFee中
            curNum.forEach(cur => {
                let curRow = this.selectionRows.filter(row => {
                    return row.payCur === cur
                })
                // console.log('币种row->', curRow)
                let total = Number(0);
                let settlementArr = []
                curRow.forEach(i => {
                    total = total + Number(i.payMoney)
                    settlementArr.push(i.settleName)
                })
                // console.log('总计', total)
                let settlement = Array.from(new Set(settlementArr));

                allPaySum.push({cur: cur, total: total.toFixed(3), settlement})
            })


            // this.$refs.mergeDialog.edit(payArr, curArr[0], allPay.toFixed(3)); 改多币种多结算对象审核
            this.$refs.mergeDialog.edit(payArr, allCur, allPaySum);

            // postAction(this.url.verifyBatch, payArr).then((res) => {
            //     console.log(res);
            //     if (res.success === true) {
            //         this.handleQuery();
            //     } else {
            //         this.$message.warning(res.message);
            //     }
            // });
        },
        backToWaitApply(row) {
            // console.log("申请付款以下id的费用", this.selectionRows);
            // if (this.selectedRowKeys.length === 0) {
            //     this.$message.warning("请勾选付款申请");
            //     return;
            // }
            // let payCount = [];
            // this.selectionRows.forEach((item) => {
            //     payCount.push(item.id);
            // });

            // let payArr = Array.from(new Set(payCount));
            // if(payArr.length > 1){
            //     this.$message.warning("所选费用的结算对象不一致！")
            //     return
            // }
            // let arr = this.tableData.filter(item => {return this.selectionRows.indexOf(item) === -1})
            // console.log("arr--",arr)
            // this.$refs.payApply.edit(this.selectionRows,arr,this.columns)
            //跳过审批流程，直接审批完成
            postAction(this.url.backToWaitApply, row).then((res) => {
                // console.log(res);
                if (res.success === true) {
                    this.handleQuery();
                }
            });
        },
        handleSelectionChange(selection) {
            // 多选
            // console.log(selection);
            let arr = [];
            let nos = [];
            this.nameList = [];
            this.cnamelist = [];
            this.emailList = [];
            for (let i = 0; i < selection.length; i++) {
                arr.push(selection[i]["id"]);
                nos.push(selection[i]["orderNo"]);
                this.nameList.push(selection[i]["username"]);
                this.cnamelist.push(selection[i]["cname"]);
                this.emailList.push(selection[i]["email"]);
            }
            this.selectionRows = selection;
            this.selectedRowKeys = [...arr];
            this.selectedNos = [...nos];
        },
        handleReceive() {
            // console.log("申请收款以下id的费用", this.selectedRowKeys);
        },
        handleCheck() {
            if(this.queryForm.applyNoSelf) {
// 定义前缀
let prefix = "FPST";
// 将字符串按空格或逗号分割成数组
let strArray = this.queryForm.applyNoSelf.split(/[\s,，]+/);

// 遍历数组，检查每个字符串是否以FPST开头，如果不是则拼接FPST
for (let i = 0; i < strArray.length; i++) {
    if (!strArray[i].startsWith(prefix)) {
        strArray[i] = prefix + strArray[i];
    }
}
// 将数组重新拼接成字符串，以空格分隔
this.queryForm.applyNo = strArray.join(' ');
            }
            // if(this.queryForm.applyNo){
            //     let strs = this.queryForm.applyNo.split(/[ ,，\r\n\t]/);
            //     if(strs.length > 1){
            //         if(this.queryForm.agentPayType===undefined || this.queryForm.agentPayType==null || this.queryForm.agentPayType===''){
            //             this.$message.warning('多申请号查询时请选择业务类型')
            //         }
            //     }
            // }

            this.loading = true;
            if (this.otime && this.otime.length > 1) {
                this.queryForm.createTimeS = this.otime[0];
                this.queryForm.createTimeE = this.otime[1];
            } else {
                this.queryForm.createTimeS = '';
                this.queryForm.createTimeE = '';
            }
            if (this.stime && this.stime.length > 1) {
                this.queryForm.sCreateTimeS = this.stime[0];
                this.queryForm.sCreateTimeE = this.stime[1];
            } else {
                this.queryForm.sCreateTimeS = '';
                this.queryForm.sCreateTimeE = '';
            }

            let form = {...this.queryForm, ...this.filterForm};
            for (let item in form) {
                if (typeof form[item] === "string") {
                    form[item] = form[item].trim();
                    if (form[item] === "") {
                        form[item] = null;
                    }
                }
            }
            const {prop, order} = this.isorter;
            // 传入的参数
            const params = {
                ...form,
                column: prop,
                order: order.indexOf("asc") !== -1 ? "asc" : "desc",
            };
            //判断 是否要查询startTime

            if (!params.column) {
                if (this.queryForm.column === "startTime") {
                    this.$set(params, "column", "startTime");
                } else {
                    this.$set(params, "column", "createTime");
                }
                //提货中查询
                if (this.queryForm.column === "distributionTime") {
                    this.$set(params, "column", "distributionTime");
                }
            }
            if (this.ipagination) {
                params.pageNo = this.ipagination.currentPage;
                params.pageSize = this.ipagination.pageSize;
            }
            // console.log("查询对象", params);

            getAction(this.url.list, params)
                .then((res) => {
                    if (res.success) {
                        const {records, total} = res.result;
                        this.tableData = records || res.result;

                        this.ipagination &&
                        (this.ipagination.total = total || res.result.length);
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        noConvertBill() {
            // console.log("申请收款以下id的费用", this.selectedRowKeys);
            if (this.selectedRowKeys.length === 0) {
                this.$message.warning("请勾选费用");
                return;
            }

            let countCur = [];
            this.selectionRows.forEach((item) => {
                countCur.push(item.moneyType);
            });
            let curArr = Array.from(new Set(countCur));
            // console.log("curArr->", curArr);
            if (curArr.length > 1) {
                this.$message.warning("所选费用的费用币种不一致！");
                return;
            }

          let settleIdCount = [];
          let settleNameCount = [];
          this.selectionRows.forEach((item) => {
            settleIdCount.push(item.settleId);
            settleNameCount.push(item.settlement);
          });
          let idArr = Array.from(new Set(settleIdCount));
          let nameArr = Array.from(new Set(settleNameCount));
          if (idArr.length > 1) {
            this.$message.warning("所选费用的结算对象不一致！");
            return;
          }
          if (nameArr.length > 1) {
            this.$message.warning("所选费用的结算对象不一致！");
            return;
          }
            this.$refs.noConvert.edit(this.selectedRowKeys, this.selectedNos);
        },
        downloadPayExcel() {
            if ((this.queryForm.applyNoSelf === '' || this.queryForm.applyNoSelf === undefined)
                && (this.queryForm.settleName === '' || this.queryForm.settleName === undefined)
                && (this.stime === null || this.stime.length === 0)
                && (this.queryForm.payCur === '' || this.queryForm.payCur === undefined)) {
                this.$message.warning('导出excel必须添加查询条件')
                return
            }

            this.loading = true;
            this.queryForm.applyNo = this.queryForm.applyNoSelf
            this.queryForm.downloadExcelType = 'payWaitVerify';
            if (this.stime && this.stime.length > 1) {
                this.queryForm.sCreateTimeS = this.stime[0];
                this.queryForm.sCreateTimeE = this.stime[1];
            } else {
                this.queryForm.sCreateTimeS = '';
                this.queryForm.sCreateTimeE = '';
            }

            let form = {...this.queryForm, ...this.filterForm};
            for (let item in form) {
                if (typeof form[item] === "string") {
                    form[item] = form[item].trim();
                    if (form[item] === "") {
                        form[item] = null;
                    }
                }
            }
            const {prop, order} = this.isorter;
            // 传入的参数
            const params = {
                ...form,
                column: prop,
                order: order.indexOf("asc") !== -1 ? "asc" : "desc",
            };
            //判断 是否要查询startTime

            if (!params.column) {
                if (this.queryForm.column === "startTime") {
                    this.$set(params, "column", "startTime");
                } else {
                    this.$set(params, "column", "createTime");
                }
                //提货中查询
                if (this.queryForm.column === "distributionTime") {
                    this.$set(params, "column", "distributionTime");
                }
            }
            if (this.ipagination) {
                params.pageNo = this.ipagination.currentPage;
                params.pageSize = this.ipagination.pageSize;
            }
            // console.log("开始下载");

            let filename = this.getDateStr() + '导出应付待审批'

            let url = this.url.downloadPayExcel

            getAction(url, params, {responseType: 'blob'})
                .then(res => {
                    // console.log(res)
                    if (res.size === 0) {
                        this.$message.error('导出失败，请稍后再试或联系管理员')
                        this.visible = false
                        return
                    }
                    var binaryData = [];
                    binaryData.push(res);

                    let url = window.URL.createObjectURL(new Blob(binaryData, {type: 'application/vnd.ms-excel'}));
                    let link = document.createElement('a');
                    link.style.display = 'none';
                    link.href = url;
                    link.setAttribute('download', filename + '.xlsx');
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);//下载完成移除元素
                    window.URL.revokeObjectURL(url) //释放掉blob对象

                    this.visible = false
                    setTimeout(() => {   //设置延迟执行
                        this.$message.success("导出成功！")
                        // this.$emit('ok')
                        this.handleCheck();
                    }, 3000)
                })
        },
    },
};
</script>
<style scoped>
.el-button {
    user-select: unset;
}
</style>
