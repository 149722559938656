<template>
    <div class="main-full-content">
      <el-form
          class="input_top"
          ref="form"
          :model="queryForm"
          label-width="100px"
          size="medium"
      >
        <el-card class="box-card">
          <el-row class="inputs_row">
            <el-col :xs="24" :sm="12" :md="12" :lg="4">
              <el-form-item label="文件名">
                <el-input v-model="queryForm.fileName"></el-input>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="12" :md="12" :lg="4">
              <el-form-item label="操作人">
                <el-input v-model="queryForm.createBy"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="动作时间" label-width="100px">
                <div class="f-flex">
                  <el-date-picker
                      class="f-flex-1"
                      v-model="queryForm.createTimeStart"
                      type="datetime"
                      size="small"
                      placeholder="开始时间"
                      default-time="00:00:00"
                      value-format="yyyy-MM-dd HH:mm:ss"
                  >
                  </el-date-picker>
                  <span class="f-p-h-2">~</span>
                  <el-date-picker
                      class="f-flex-1"
                      v-model="queryForm.createTimeEnd"
                      type="datetime"
                      size="small"
                      placeholder="结束时间"
                      default-time="23:59:59"
                      value-format="yyyy-MM-dd HH:mm:ss"
                  >
                  </el-date-picker>
                </div>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="4">
              <el-form-item label="文件类型">
                <el-input v-model="queryForm.fileType"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="4">
              <el-form-item label="动作类型">
                <el-select v-model="queryForm.actionType" clearable filterable>
                  <el-option value="1" label="下载"></el-option>
                  <el-option value="2" label="删除"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="4">
              <el-form-item label="下载来源">
                <el-select v-model="queryForm.downloadSource" clearable filterable>
                  <el-option value="1" label="整柜下载"></el-option>
                  <el-option value="2" label="单票下载"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="4">
              <el-form-item label-width="30px">
                <el-button
                    type="primary"
                    icon="el-icon-search"
                    @click="handleQuery">查询
                </el-button>
                <el-button plain @click="handleReset">重置</el-button>
              </el-form-item>
            </el-col>

          </el-row>
        </el-card>
      </el-form>
        <f-table
                v-loading="loading"
                ref="GTable"
                border
                size="medium"
                row-key="id"
                class="c-th-has-bgcolor"
                :columns="columns"
                :data="tableData"
                :cell-style="{ 'text-align': 'center' }"
                @selection-change="handleSelectionChange"
                @sort-change="handleTableChange">
            <template v-slot="scope">
              <template v-if="scope.column.property === 'downloadSource'">
                <span v-if="scope.row.downloadSource === '1'">整柜下载</span>
                <span v-if="scope.row.downloadSource === '2'">单票下载</span>
              </template>
              <template v-else-if="scope.column.property === 'actionType'">
                <span v-if="scope.row.actionType === '1'">下载</span>
                <span v-if="scope.row.actionType === '2'">删除</span>
              </template>
            </template>
        </f-table>

        <el-row class="f-text-right f-p-v-8">
            <el-pagination
                    v-bind="ipagination"
                    layout="total, sizes, prev, pager, next, jumper"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange">
            </el-pagination>
        </el-row>
    </div>
</template>

<script>
    import QueryMixins from "@/mixins/QueryMixins.js"

    export default {
        name: 'fileDownloadLogModule',
        mixins: [QueryMixins],
        props:{
            tableDataPage:{
              type:Object,
              default: () => {}
            }
        },
        data() {
            return {
                isUsePost:true,
                disableMixinMounted : true,
                columns: [
                  {
                    prop: 'fileName',
                    label: '文件名',
                  },
                  {
                    prop: 'fileType',
                    label: '文件类型',
                  },
                  {
                    prop: 'createBy',
                    label: '操作人',
                  },
                  {
                    prop: 'createTime',
                    label: '动作时间',
                  },
                  {
                    prop: 'actionType',
                    label: '动作',
                  },
                  {
                    prop: 'downloadSource',
                    label: '下载来源',
                  },
                ],
                url: {
                    list: '/order/os/file/log/list',
                },
                initData:{
                  orderNo:this.$route.query.orderNo
                },
              tableData: [],
              ipagination: {
                currentPage: 1,
                pageSize: 50,
                pageSizes: [50, 100, 200, 300, 400, 500],
                total: 0
              },
              queryForm: {},
            }
        },
        methods: {

        },
        watch :{
          tableDataPage(val){
            if (val) {
              console.log("赋值给里面的表格，",val);
              this.tableData = val.records;
              this.ipagination.total = val.total;
            }
          }
        },
        created() {

        }
    }
</script>

<style lang="scss" scoped>

</style>
