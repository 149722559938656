<template>
    <!-- 海运制作成本 -->
    <div class="main-full-content">
        <el-form class="input_top" label-width="80px" size="medium" @submit.native.prevent>
            <el-card class="box-card">
                <el-row>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="报价表版本">
                            <el-input v-model.trim="queryForm.dataVersion"
                                @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="渠道名称">
                            <el-input v-model.trim="queryForm.channelName"
                                @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="航线名称">
                            <el-input v-model.trim="queryForm.lineName" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="操作">
                          <el-input v-model.trim="queryForm.operator" @keyup.enter.native="handleSearch"></el-input>
<!--                            <f-select v-model="queryForm.operationId" :is-need="queryForm.operationId"-->
<!--                                :dict="'sys_user_id'" placeholder="请选择" filterable clearable>-->
<!--                            </f-select>-->
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="交货仓">
                            <el-input v-model.trim="queryForm.handWarehouse"
                                @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="拼柜仓">
                            <el-input v-model.trim="queryForm.spellWarehouse"
                                @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label-width="30px" class="search_box">
                            <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
                            <el-button plain @click="handleReset">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>
        </el-form>

        <div lass="box-card last_card">
            <div class="f-p-b-15">
                <el-upload action="/" :show-file-list="false" :http-request="uploadSectionFile" accept=".xls,.xlsx" v-if="buttonShowList.dr"
                    style="margin: 0 10px; display: inline">
                    <el-button size="medium" type="primary">导入航线SOP</el-button>
                </el-upload>
                <el-button v-if="buttonShowList.add" type="primary" size="medium"
                    @click="downloadModel(url.downloadLineSop, '航线SOP模板')">模板下载
                </el-button>
                <el-button v-if="buttonShowList.del" type="primary" size="medium" :disabled="!selectedRowKeys.length"
                    @click="handleDelete">批量删除
                </el-button>
            </div>
            <f-table v-loading="loading" ref="GTable" border size="medium" row-key="id" class="c-th-has-bgcolor"
                :columns="columns" :data="tableData" @selection-change="handleSelectionChange"
                @sort-change="handleTableChange" :cell-style="{ 'text-align': 'center' }" tableHeight="65vh">
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'action'">

                    </template>
                </template>
            </f-table>
        </div>

        <el-row class="f-text-right f-p-v-8">
            <el-pagination v-bind="ipagination" layout="total, sizes, prev, pager, next, jumper"
                @size-change="handleSizeChange" @current-change="handleCurrentChange">
            </el-pagination>
        </el-row>
    </div>
</template>

<script>
import QueryMixins from "@/mixins/QueryMixins.js";
import { getAction, postAction, deleteAction } from "@/api/manage";
export default {
    mixins: [QueryMixins],
    data() {
        return {
            columns: [
                {
                    type: "selection",
                },
                {
                    prop: "dataVersion",
                    label: "报价表版本",
                },
                {
                 prop: "productLine",
                 label: "产品航线",
                },
                {
                    prop: "channelName",
                    label: "渠道名称",
                },
                {
                    prop: "lineName",
                    label: "航线名称",
                },
                {
                    prop: "handWarehouse",
                    label: "交货仓",
                },
                {
                    prop: "spellWarehouse",
                    label: "拼柜仓",
                },
                {
                    prop: "shipCompany",
                    label: "船公司",
                },
                {
                    prop: "startPort",
                    label: "起运港",
                },
                {
                    prop: "endPort",
                    label: "目的港",
                },
                {
                    prop: "operator",
                    label: "排柜操作",
                },
                {
                    prop: "entTime",
                    label: "截仓时间",
                },
                {
                    prop: "volumeWarehouse",
                    label: "出材积仓",
                },
                {
                    prop: "transitPerson",
                    label: "中转负责人",
                },
                {
                    prop: "warehouseOrState",
                    label: "特定仓库/州省",
                },
                {
                    prop: "productRemark",
                    label: "产品备注",
                },
                {
                    prop: "saleRemark",
                    label: "销售备注",
                },
                {
                    prop: "operatorRemark",
                    label: "操作备注",
                },
                {
                  prop: "createTime",
                  label: "导入时间",
                },
                {
                  prop: "createBy",
                  label: "导入人",
                }
            ],
            url: {
                list: "/sys/product/common/getLineSopList", //查询列表的接口
                batchDeleteLineSop: "/sys/product/common/batchDeleteLineSop", //删除
                importLineSop: "/sys/product/common/importLineSop",
                downloadLineSop: "/file/download/model/downloadLineSop"
            },
            isUsePost: true,
        };
    },
    methods: {
        handleDelete() {
            postAction(this.url.batchDeleteLineSop, this.selectedRowKeys).then((res) => {
                this.$message({
                    type: "success",
                    message: res.message,
                });
                this.handleSearch();
            });
        },
        uploadSectionFile(param) {
            //file就是当前添加的文件
            var fileObj = param.file;
            //FormData 对象
            var form = new FormData();
            //文件对象，key是后台接受的参数名称
            form.append("file", fileObj);
            //执行上传方法
            console.log("执行上传方法");
            postAction(this.url.importLineSop, form)
                .then((res) => {
                    //这里做上传之后的操作
                    if (res.success) {
                        this.$message.success(res.message);
                        this.handleQuery();
                    } else {
                        this.handleQuery();
                    }
                })
        },
    },
    computed: {
        buttonShowList() {
            return {
                'add': this.$btnIsShow('product/SOP', '0', '模板下载'),
                'del': this.$btnIsShow('product/SOP', '0', '批量删除'),
                'dr': this.$btnIsShow('product/SOP', '0', '导入'),
            }
        }
    }
};
</script>

<style lang="scss" scoped>
::v-deep .search_box .el-form-item__content {
    text-align: right;
    margin-top: 10px;
}
</style>
