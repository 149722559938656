<template>
  <div class="main-full-content">
    <el-form class="input_top" ref="form" :model="queryForm" label-width="auto" size="medium" @submit.native.prevent>
      <el-card class="box-card">
        <el-row>
          <el-col :span="5">
            <el-form-item label="规则名称">
              <el-input v-model="queryForm.ruleName">
              </el-input>
            </el-form-item>
          </el-col>

          <el-col :span="5">
            <el-form-item label="是否启用">
              <f-select v-model="queryForm.isEnable" :is-need="queryForm.isEnable" :dict="'unit_whether'">
              </f-select>
            </el-form-item>
          </el-col>
          <el-col class="no_margin" :md="6" :sm="24">
            <el-form-item label="录入时间" label-width="100px">
              <div class="f-flex">
                <el-date-picker
                    class="f-flex-1"
                    v-model="queryForm.createTimeStart"
                    type="datetime"
                    size="small"
                    placeholder="开始时间"
                    default-time="00:00:00"
                    value-format="yyyy-MM-dd HH:mm:ss"
                >
                </el-date-picker>
                <span class="f-p-h-2">~</span>
                <el-date-picker
                    class="f-flex-1"
                    v-model="queryForm.createTimeEnd"
                    type="datetime"
                    size="small"
                    placeholder="结束时间"
                    default-time="23:59:59"
                    value-format="yyyy-MM-dd HH:mm:ss"
                >
                </el-date-picker>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="录入人">
              <f-select v-model="queryForm.createBy" :is-need="queryForm.createBy"
                        :data-list="queryDictCodeListMap['sys_user']">
              </f-select>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="配置类型">
              <f-select
                  v-model="queryForm.configType"
                  :is-need="queryForm.configType"
                  :dict="'config_type'"
              ></f-select>
            </el-form-item>
          </el-col>
<!--          <el-col class="no_margin" :md="6" :sm="24">-->
<!--            <el-form-item label="修改时间" label-width="100px">-->
<!--              <div class="f-flex">-->
<!--                <el-date-picker-->
<!--                    class="f-flex-1"-->
<!--                    v-model="queryForm.updateTimeStart"-->
<!--                    type="datetime"-->
<!--                    size="small"-->
<!--                    placeholder="开始时间"-->
<!--                    default-time="00:00:00"-->
<!--                    value-format="yyyy-MM-dd HH:mm:ss"-->
<!--                >-->
<!--                </el-date-picker>-->
<!--                <span class="f-p-h-2">~</span>-->
<!--                <el-date-picker-->
<!--                    class="f-flex-1"-->
<!--                    v-model="queryForm.updateTimeEnd"-->
<!--                    type="datetime"-->
<!--                    size="small"-->
<!--                    placeholder="结束时间"-->
<!--                    default-time="23:59:59"-->
<!--                    value-format="yyyy-MM-dd HH:mm:ss"-->
<!--                >-->
<!--                </el-date-picker>-->
<!--              </div>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
<!--          <el-col :span="5">-->
<!--            <el-form-item label="修改人">-->
<!--              <f-select v-model="queryForm.updateBy" :is-need="queryForm.updateBy"-->
<!--                        :data-list="queryDictCodeListMap['sys_user']">-->
<!--              </f-select>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
          <el-col :span="5">
            <el-form-item label="规则1">
              <f-select
                  v-model="queryForm.firstIdList"
                  :is-need="queryForm.firstIdList"
                  :multiple="true"
                  :data-list="queryDictCodeListMap['sales_first_rule']"
              ></f-select>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="规则2的名称">
              <el-input v-model="queryForm.secondRuleName">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label-width="30px">
              <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
              <el-button plain @click="handleReset">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
    </el-form>
    <el-card class="box-card">
      <div class="f-p-b-15">
        <el-button type="primary" size="medium" icon="el-icon-plus" @click="handleEdit(null,'新增')"
                   v-if="buttonShowList.add">新增
        </el-button>
        <el-button size="medium" type="primary" class="c-theme-error-button" @click="handleDele"
                   :disabled="selectionRows.length === 0"
                   v-if="buttonShowList.sc">批量删除
        </el-button>
        <el-button size="medium" type="primary" icon="el-icon-open" @click="handleEn('1')"
                   :disabled="selectionRows.length === 0"
                   v-if="buttonShowList.en">批量启用
        </el-button>
        <el-button size="medium" type="primary" icon="el-icon-turn-off" @click="handleEn('0')"
                   :disabled="selectionRows.length === 0"
                   v-if="buttonShowList.dis">批量禁用
        </el-button>
      </div>
      <f-table v-loading="loading" ref="GTable" border size="medium" row-key="id" class="c-th-has-bgcolor"
               :columns="columns" :data="tableData" :cell-style="{ 'text-align': 'center' }" tableHeight="66vh"
               @selection-change="handleSelectionChange" @sort-change="handleTableChange">
        <template v-slot="scope">
          <template v-if="scope.column.property === 'action'">
            <el-button type="text" @click="handleEdit(scope.row,'编辑')"
                       v-if="buttonShowList.bj"
            >编辑
            </el-button>

          </template>

          <template v-else-if="scope.column.property === 'isEnable'">
            <span>{{ parseDict2(scope.row.isEnable, 'unit_enable') }}</span>
          </template>

          <template v-else-if="scope.column.property === 'configType'">
            <span>{{ parseDict2(scope.row.configType, 'config_type') }}</span>
          </template>

        </template>
      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination v-bind="ipagination" layout="total, sizes, prev, pager, next, jumper"
                       @size-change="handleSizeChange" @current-change="handleCurrentChange">
        </el-pagination>
      </el-row>
    </el-card>
    <sales-per-config-second-dialog
        :visible="activeVisible"
        :select-form="selectRow"
        :first-rule-dict-list="queryDictCodeListMap['sales_first_rule']"
        :select-base-vo="selectBaseVo"
        :edit-type="selectEditType"
        @update:visible="(value) => { activeVisible = value; }"
        @ok="handleQuery()"
    ></sales-per-config-second-dialog>
  </div>
</template>

<script>
import {postAction, getAction} from '@/api/manage'
import QueryMixins from "@/mixins/QueryMixins";
import salesPerConfigSecondDialog from "@/views/salePerConfig/modules/salesPerConfigSecondDialog.vue";

export default {
  name: 'salesPerConfig',
  mixins: [QueryMixins],
  components: {
    salesPerConfigSecondDialog
  },
  data() {
    return {

      isUsePost: true,
      // 传递给编辑框使用
      selectBaseVo: {},
      formLabelWidth: '110px',
      //控制弹框显示
      activeVisible: false,
      columns: [
        {
          type: 'selection',
          width: 50,
        },
        {
          prop: 'action',
          label: '操作',
        },

        {
          prop: 'finalConfigName',
          label: '配置规则名',

        },
        {
          prop: 'ruleFirstDetail',
          label: '规则1配置项',
          isHtml : true,
        },
        {
          prop: 'ruleSecondDetail',
          label: '规则2配置项',
          isHtml : true,
        },
        {
          prop: 'ruleResultDetail',
          label: '规则配置结果',
          isHtml : true,
        },
        {
          prop: 'ruleEnableDetail',
          label: '规则启用状态',
          isHtml : true,
        },
        {
          prop: 'configType',
          label: '配置类型',
        },
        {
          prop: 'createTime',
          label: '创建时间',
        },
        {
          prop: 'createBy',
          label: '创建人',
        },


      ],
      url: {
        list: '/shipping/sales/salesPerFinalRules/getConfigPage',
        delete: '/shipping/sales/salesPerFinalRules/deleteConfig',
        updateIsShow: '/shipping/sales/salesPerFinalRules/updateEnableConfig',
        getBaseInfo: '/shipping/sales/per/config/getBaseModel',
        changePageVoToDto: '/shipping/sales/salesPerFinalRules/changePageVoToDto',
      },
      selectRow: {},
      selectEditType: '',
      needQueryDictCodes: ['sys_user', 'sys_user_id', 'sales_first_rule'],
    }
  },
  methods: {
    async handleEdit(row, editType) {
      await getAction(this.url.getBaseInfo)
          .then(res => {
            this.selectBaseVo = res.result;
          })
      // 重新更新 那个字典map
      await this.buildDictMapByCodes(['sales_first_rule']);
      if (row) {
       // 调用接口 将此对象 传递过来 重新封装成 dto
        await postAction(this.url.changePageVoToDto,row)
            .then(res => {
              this.selectRow = res.result;
            })
      } else {
        this.selectRow = {};
      }
      this.selectEditType = editType;
      this.activeVisible = true;
    },

    handleDele() {
      this.$confirm(`确定删除`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false,
      }).then(() => {
        postAction(this.url.delete,
            {
              idList: this.selectedRowKeys
            }).then(res => {
          this.$message({
            type: 'success',
            message: res.message
          })
          this.handleQuery()
        })
      }).catch(() => {

      })
    },
    handleEn(type) {
      this.$confirm(`确定${type === '1' ? '启用' : '禁用'}`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false,
      }).then(() => {
        postAction(this.url.updateIsShow,
            {
              idList: this.selectedRowKeys,
              isEnable: type
            }
        ).then(res => {
          this.$message({
            type: 'success',
            message: res.message
          })
          this.handleQuery()
        })
      }).catch(() => {

      })
    },
    handleDis() {
      this.$confirm(`确定禁用`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false,
      }).then(() => {
        postAction(this.url.dis, this.selectedRowKeys).then(res => {
          this.$message({
            type: 'success',
            message: res.message
          })
          this.handleQuery()
        })
      }).catch(() => {

      })
    },
  }, created() {

  },
  computed: {
    buttonShowList() {
      return {
        'add': this.$btnIsShow('salePerConfig/salesPerConfig', '0', '新增'),
        'bj': this.$btnIsShow('salePerConfig/salesPerConfig', '1', '编辑'),
        'copy': this.$btnIsShow('salePerConfig/salesPerConfig', '1', '复制'),
        'sc': this.$btnIsShow('salePerConfig/salesPerConfig', '1', '删除'),
        'en': this.$btnIsShow('salePerConfig/salesPerConfig', '1', '启用'),
        'dis': this.$btnIsShow('salePerConfig/salesPerConfig', '1', '禁用'),
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
