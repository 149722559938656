<template>
    <!-- 海运制作成本 -->
    <div class="main-full-content">
        <el-form class="input_top" label-width="80px" size="medium" @submit.native.prevent>
            <el-card class="box-card">
                <el-row>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="报价表版本">
                            <el-input v-model.trim="queryForm.dataVersion"
                                @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="渠道名称">
                            <el-input v-model.trim="queryForm.channelName"
                                @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="起运港">
                            <el-input v-model.trim="queryForm.startPort" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="目的港">
                            <el-input v-model.trim="queryForm.endPort" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label-width="30px">
                            <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
                            <el-button plain @click="handleReset">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>
        </el-form>

        <div lass="box-card last_card">
            <div class="f-p-b-15">
                <el-upload action="/" :show-file-list="false" :http-request="uploadSectionFile" accept=".xls,.xlsx" v-if="buttonShowList.dr"
                    style="margin: 0 10px; display: inline">
                    <el-button size="medium" type="primary">导入海运制作成本</el-button>
                </el-upload>
                <el-button v-if="buttonShowList.add" type="primary" size="medium"
                    @click="downloadModel(url.downloadMakeCost, '海运制作成本模板')">模板下载
                </el-button>
                <el-button v-if="buttonShowList.del" type="primary" size="medium" :disabled="!selectedRowKeys.length"
                    @click="handleDelete">批量删除
                </el-button>
            </div>
            <f-table v-loading="loading" ref="GTable" border size="medium" row-key="id" class="c-th-has-bgcolor"
                :columns="columns" :data="tableData" @selection-change="handleSelectionChange"
                @sort-change="handleTableChange" :cell-style="{ 'text-align': 'center' }" tableHeight="73vh">
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'action'">

                    </template>
                </template>
            </f-table>
        </div>

        <el-row class="f-text-right f-p-v-8">
            <el-pagination v-bind="ipagination" layout="total, sizes, prev, pager, next, jumper"
                @size-change="handleSizeChange" @current-change="handleCurrentChange">
            </el-pagination>
        </el-row>
    </div>
</template>

<script>
import QueryMixins from "@/mixins/QueryMixins.js";
import { getAction, postAction, deleteAction } from "@/api/manage";
export default {
    mixins: [QueryMixins],
    data() {
        return {
            columns: [
                {
                    type: "selection",
                },
                {
                    prop: "no",
                    label: "序号",
                },
                {
                    prop: "week",
                    label: "周数",
                },
                {
                    prop: "country",
                    label: "国家",
                },
                {
                    prop: "dataVersion",
                    label: "报价表版本",
                },
                {
                    prop: "channelName",
                    label: "渠道名称",
                },
                {
                    prop: "area",
                    label: "辐射区域",
                },
                {
                    prop: "handWarehouse",
                    label: "交货仓",
                },
                {
                    prop: "startPort",
                    label: "起运港",
                },
                {
                    prop: "endPort",
                    label: "目的港",
                },
                {
                    prop: "fortyHq",
                    label: "40HQ运费",
                },
                {
                    prop: "fortyFiveHq",
                    label: "45HQ运费",
                },
                {
                    prop: "increase",
                    label: "相对上周涨跌",
                },
                {
                    prop: "channelShipCompany",
                    label: "渠道船司匹配",
                },
                {
                    prop: "mapRule",
                    label: "尾舱配货规则",
                },
                {
                    prop: "remark",
                    label: "特殊备注",
                },
                {
                  prop: "createTime",
                  label: "导入时间",
                },
                {
                  prop: "createBy",
                  label: "导入人",
                }
            ],
            url: {
                list: "/sys/product/common/getMakeCostList", //查询列表的接口
                batchDeleteMakeCost: "/sys/product/common/batchDeleteMakeCost", //删除
                importMakeCost: "/sys/product/common/importMakeCost",
                downloadMakeCost: "/file/download/model/downloadMakeCost"
            },
            isUsePost: true,
        };
    },
    methods: {
        handleDelete() {
            postAction(this.url.batchDeleteMakeCost, this.selectedRowKeys).then((res) => {
                this.$message({
                    type: "success",
                    message: res.message,
                });
                this.handleSearch();
            });
        },
        uploadSectionFile(param) {
            //file就是当前添加的文件
            var fileObj = param.file;
            //FormData 对象
            var form = new FormData();
            //文件对象，key是后台接受的参数名称
            form.append("file", fileObj);
            //执行上传方法
            console.log("执行上传方法");
            postAction(this.url.importMakeCost, form)
                .then((res) => {
                    //这里做上传之后的操作
                    if (res.success) {
                        this.$message.success(res.message);
                        this.handleQuery();
                    } else {
                        this.handleQuery();
                    }
                })
        },
    },
    computed: {
        buttonShowList() {
            return {
                'add': this.$btnIsShow('product/ProductionCosts', '0', '模板下载'),
                'del': this.$btnIsShow('product/ProductionCosts', '0', '批量删除'),
                'dr': this.$btnIsShow('product/ProductionCosts', '0', '导入'),
            }
        }
    }
};
</script>

<style></style>
