<template>
    <div>
        <div class="f-p-b-15">
            <el-button type="primary" size="small" icon="el-icon-plus" @click="handleAdd({})">新增</el-button>
        </div>
        <f-table
                v-loading="loading"
                ref="GTable"
                border
                size="medium"
                row-key="id"
                class="c-th-has-bgcolor"
                :columns="columns"
                :data="tableData"
                :isorter="isorter"
                :options="typelist"
                :show-operation="true"
                :cell-style="{ 'text-align': 'center' }"
                @selection-change="handleSelectionChange"
                @sort-change="handleTableChange"
                @queryChange="handleTableQueryChange">
            <template v-slot="scope">
                <template v-if="scope.column.property === 'action'">
                    <el-button type="text" @click="handleEdit(scope.row)">编辑</el-button>
                    <el-button type="text" @click="handleDel(scope.row)">删除</el-button>
                    <el-button type="text" @click="handlSetToDefault(scope.row)" v-if="scope.row.isdefault==='0'">设为默认
                    </el-button>
                    <el-button type="text" @click="handlqxDefault(scope.row)" v-if="scope.row.isdefault==='1'">取消默认
                    </el-button>
                </template>
                <template v-else-if="scope.column.property === 'type'">
                  <span v-if="scope.row.type === '1'">负责人</span>
                  <span v-else-if="scope.row.type === '2'">财务</span>
                  <span v-else>客服</span>
                </template>
              <template v-else-if="scope.column.property === 'isdefault'">
                <span v-if="scope.row.isdefault === '1'">是</span>
                <span v-else>否</span>
              </template>
            </template>
        </f-table>
        <el-dialog :title="form.id?'编辑联系人':'新增联系人'" :visible.sync="dialogFormVisible" width="30%" center :close-on-click-modal="false"
        v-if="dialogFormVisible">

            <el-form :model="form" :rules="rules" ref="form">
                <el-form-item label="姓名" prop="name" :label-width="formLabelWidth">
                    <el-input v-model="form.name" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="联系人岗位" prop="type" :label-width="formLabelWidth">
                    <el-select v-model="form.type" placeholder="请选择" filterable clearable>
                        <el-option v-for="item in typelist" :key="item.value" :label="item.label"
                                   :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="手机号" prop="phone" :label-width="formLabelWidth">
                    <el-input v-model="form.phone" autocomplete="off"></el-input>
                </el-form-item>

                <el-form-item label="座机" prop="tel" :label-width="formLabelWidth">
                    <el-input v-model="form.tel" autocomplete="off"></el-input>
                </el-form-item>

                <el-form-item label="qq" prop="qq" :label-width="formLabelWidth">
                    <el-input v-model="form.qq" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="邮箱" prop="email" :label-width="formLabelWidth">
                    <el-input v-model="form.email" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="备注" prop="remark" :label-width="formLabelWidth">
                    <el-input v-model="form.remark" autocomplete="off" type="textarea"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveContact" :disabled="btnDisabled">确 定</el-button>
            </div>

        </el-dialog>


    </div>
</template>

<script>
    import QueryMixins from "@/mixins/QueryMixins.js"
    import {emailPattern, mobilePattern} from "@/utils/pattern";
    import {deleteAction, postAction, putAction,getAction} from "@/api/manage";

    export default {
        name: 'AccountModule',
        mixins: [QueryMixins],
        components: {},
        data() {
            return {
                //联系人类型0公司负责人 1报价对接人 2操作联系人 3财务联系人',

                typelist: [
                    {value: '1', label: '负责人'},
                    {value: '2', label: '财务'},
                    {value: '3', label: '客服'},
                ],
                form: {},
                dialogFormVisible: false,
              btnDisabled:false,
                formLabelWidth: '120px',
                //表单校验
                rules: {
                    name: [
                        {required: true, message: '请输入姓名', trigger: 'blur'},
                        {max: 50, message: '长度不能超过50', trigger: 'change'},
                    ],
                    type: [
                        {required: true, message: '请选择联系人岗位', trigger: 'change'},

                    ],
                    phone: [
                        {required: true, message: '请输入手机号', trigger: 'blur'},
                        // {pattern: mobilePattern, message: '请输入正确的手机号', trigger: 'change'}
                    ],
                    email: [
                        {required: true, message: '请输入邮箱', trigger: 'blur'},
                        {pattern: emailPattern, message: '请输入正确的邮箱', trigger: 'change'}
                    ],
                },

                row: {},
                visible: false,
                columns: [
                    {
                        prop: 'action',
                        label: '操作',

                    },
                    {
                        prop: 'name',
                        label: '姓名',

                        minWidth: 80
                    },
                    {
                        prop: 'type',
                        label: '职位',

                        sortable: 'custom',
                        minWidth: 100
                    },
                    {
                        prop: 'phone',
                        label: '手机号',

                        minWidth: 80,
                        sortable: 'custom'
                    },
                    {
                        prop: 'tel',
                        label: '座机',

                        minWidth: 80,
                        sortable: 'custom'
                    },
                    {
                        prop: 'qq',
                        label: 'QQ',

                        minWidth: 80,
                        sortable: 'custom'
                    },
                    {
                        prop: 'email',
                        label: '邮箱',

                        minWidth: 80,
                        sortable: 'custom'
                    },
                    {
                        prop: 'remark',
                        label: '备注',

                        minWidth: 80,
                        sortable: 'custom'
                    },
                    {
                        prop: 'isdefault',
                        label: '是否默认',

                    },
                    {
                        prop: 'createTime',
                        label: '创建时间',

                        minWidth: 100,
                        sortable: 'custom'
                    },
                ],
                url: {
                    list: '/sys/supplierAccountant/list',
                    getbyid: '/sys/supplierAccountant/queryById',
                    delbyid: '/sys/supplierAccountant/delete',
                    setDefault: '/sys/supplierAccountant/setDefault',
                    qxdefault: '/sys/supplierAccountant/qxDefault',
                    add: '/sys/supplierAccountant/add',
                    edit: '/sys/supplierAccountant/edit'
                }
            }
        },

        methods: {
            handlSetToDefault(row) {
                this.$confirm(`确定将${row.name}设为默认吗`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'success',
                    closeOnClickModal: false,
                }).then(() => {
                    putAction(this.url.setDefault, {id: row.id}).then(res => {
                        this.$message({
                            type: 'success',
                            message: res.message
                        })
                        this.handleSearch()
                    })
                }).catch(err=>{
                  this.$message.error(err.message);
                })
            },
            handlqxDefault(row) {
                this.$confirm(`确定将${row.name}取消默认吗`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'success',
                    closeOnClickModal: false,
                }).then(() => {
                    putAction(this.url.qxdefault, {id: row.id}).then(res => {
                        this.$message({
                            type: 'success',
                            message: res.message
                        })
                        this.handleSearch()
                    })
                }).catch(err=>{
                  this.$message.error(err.message);
                })
            },
            handleDel(row) {
                this.$confirm(`确定删除${row.name}`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'success',
                    closeOnClickModal: false,
                }).then(() => {
                    deleteAction(this.url.delbyid, {id: row.id}).then(res => {
                        this.$message({
                            type: 'success',
                            message: res.message
                        })
                        this.handleSearch()
                    })
                }).catch(err=>{
                  this.$message.error(err.message);
                })
            },
            handleAdd() {
                this.form = {};
                this.dialogFormVisible = true;
                this.btnDisabled = false;
            },
            handleEdit(row) {
              this.btnDisabled = false;
                this.form=JSON.parse(JSON.stringify(row));
                //显示弹窗，给对象赋值
                this.dialogFormVisible = true
            },
            //新增或修改
            saveContact() {
                //给客户id赋值
              this.btnDisabled = true;
                this.form.supplierId = this.$route.query.id;
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        if (this.form.id) {
                            //有id，编辑
                            putAction(this.url.edit, this.form)
                                .then(res => {
                                    if (res.success) {
                                        this.$message.success(res.message);
                                        //关闭弹窗
                                        this.dialogFormVisible = false;
                                        this.handleQuery();
                                    }else {
                                      this.btnDisabled = false;
                                    }
                                }).catch(err=>{
                              this.btnDisabled = false;
                            })
                        } else {
                            //新增
                            postAction(this.url.add, this.form)
                                .then(res => {
                                    if (res.success) {
                                        this.$message.success(res.message);
                                        this.dialogFormVisible = false;
                                        this.handleQuery();
                                    }else {
                                      this.btnDisabled = false
                                    }
                                }).catch(err=>{
                              this.btnDisabled = false;
                            })
                        }
                    }else {
                      this.btnDisabled = false;
                    }
                })
            },
            init() {
                let id = this.$route.query.id;
                this.queryForm.supplierId = id;
            }
        }
        ,created() {
            this.init();
        }
    }
</script>

<style lang="scss" scoped>

</style>
