<template>
  <el-dialog
      :title="row.id ? '编辑费用类型' : '新增费用类型'"
      :visible="visible"
      width="560px"
      custom-class="c-custom-dialog"
      :close-on-click-modal="false"
      :before-close="handleClose">
    <el-form :model="addForm" :rules="rules" ref="addForm" label-width="120px" size="medium">
      <el-row>
        <el-col :span="24">
          <el-form-item prop="chineseName" label="费用类型中文名">
            <el-input v-model="addForm.chineseName" placeholder="请输入费用类型中文名" maxlength="120" show-word-limit></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item prop="code" label="费用编码">
            <el-input v-model="addForm.code" placeholder="请输入费用编码" maxlength="120" show-word-limit></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item prop="englishName" label="费用类型英文名">
            <el-input v-model="addForm.englishName" placeholder="请输入费用类型英文名" maxlength="120" show-word-limit></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item prop="apportionType" label="费用分摊方式">
            <el-select v-model="addForm.apportionType" placeholder="请选择费用分摊方式" maxlength="20" show-word-limit>
              <el-option
                  v-for="item in optionsapportionType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item prop="createBy" label="创建人">
            <el-input v-model="addForm.createBy" placeholder="请输入创建人" maxlength="20" show-word-limit></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item prop="type" label="费用类型">
            <el-select v-model="addForm.type" placeholder="请输入费用类型" maxlength="20" show-word-limit>
              <el-option
                  v-for="item in optionsType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item prop="isPre" label="是否是预估">
            <el-select v-model="addForm.isPre" filterable clearable>
             <el-option value="0" label="否"></el-option>
             <el-option value="1" label="是"></el-option>
            </el-select>
          </el-form-item>
        </el-col>

      </el-row>
    </el-form>
    <span slot="footer">
            <el-button size="medium" @click="handleClose">取消</el-button>
            <el-button type="primary" size="medium" @click="handleSubmit">确定</el-button>
        </span>
  </el-dialog>
</template>

<script>
import { httpAction } from '@/api/manage'

export default {
  name: 'FeeTypeDialog',

  props: {
    visible: {
      type: Boolean
    },
    row: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {

      addForm: {},
      rules: {
        chineseName: [
          { required: true, message: '请输入费用类型中文名', trigger: 'change' },
        ],
        code: [
          { required: true, message: '请输入费用编码', trigger: 'change' },
        ],
        englishName: [
          { required: true, message: '请输入费用类型英文名', trigger: 'change' },
        ]

      },
      url: {
        add: '/sys/feetype/add',
        edit: '/sys/feetype/edit'
      },
      optionsType: [{
        value: '1', label: '海运费用类型'
      }, {
        value: '2', label: '快递费用类型'
      }],
      optionsapportionType: [{
        value: '1', label: '实际重量'
      }, {
        value: '2', label: '实际体积'
      },{
        value: '3', label: '国外打托数'
      }]
    }
  },
  watch: {
    visible(val) {
      if(val && this.row.id) {
        const { id, chineseName,code, englishName,apportionType,createBy,type} = this.row
        this.addForm = { id, chineseName,code, englishName,apportionType,createBy,type}
      } else {
        this.addForm = {}
      }
    },

  },
  created(){
    // this.countrylist
  },
  methods: {


    handleSubmit() {
      let url = this.url.add
      let method = 'POST'
      if(this.row.id) {
        url = this.url.edit
        method = 'PUT'
      }

      this.$refs.addForm.validate((valid) => {
        if (valid) {
          httpAction(url, method, this.addForm).then((res)=>{
            this.$message({
              type: 'success',
              message: res.message
            })
            localStorage.removeItem("dict:bd_fee_type");
            this.handleClose()
            this.$emit('ok')
          })
        }
      })
    },
    handleClose() {
      this.$emit('update:visible', false)
      this.$refs.addForm.resetFields()
    },

  }
}
</script>

<style lang="scss" scoped>

</style>
