<template>
    <div>
        <el-card class="box-card">
            <el-form class="multiline_select" ref="form" :model="queryForm" label-width="80px" size="medium">
                <el-card class="box-card">
                    <el-row  class="inputs_row">
                        <el-col :xs="24" :sm="12" :md="12" :lg="6">
                            <el-form-item label="开单编号">
                                <el-input v-model="queryForm.receiptsNo" placeholder="多个单号需要完整单号" type="textarea"
                                          @keyup.enter.native="handleSearch"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="12" :md="12" :lg="6">
                            <el-form-item label="订单号">
                                <el-input v-model="queryForm.orderNo" placeholder="完整单号" type="textarea"
                                          @keyup.enter.native="handleSearch"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="12" :md="12" :lg="6">
                            <el-form-item label="排队号">
                                <el-input v-model="queryForm.queueNo" placeholder="多个排队号需要完整单号" type="textarea"
                                          @keyup.enter.native="handleSearch"></el-input>
                            </el-form-item>
                        </el-col>

                        <el-col  :xs="24" :sm="12" :md="12" :lg="6">
                            <el-form-item label="车辆性质">
                                <f-select :dict="'warehouse_receipts_use_car_type'" v-model="queryForm.useCarTypeList" :multiple="true"
                                          :isNeed="queryForm.useCarTypeList" @keyup.enter.native="handleSearch"></f-select>
                            </el-form-item>
                        </el-col>


                        <el-col  :xs="24" :sm="12" :md="12" :lg="7">
                            <el-form-item label="开单时间">
                                <div class="f-flex">
                                    <el-date-picker
                                            class="f-flex-1"
                                            v-model="queryForm.createTimeStart"
                                            type="datetime"
                                            size="small"
                                            placeholder="开始时间"
                                            default-time="00:00:00"
                                            value-format="yyyy-MM-dd HH:mm:ss"
                                            @keyup.enter.native="handleSearch"
                                    >
                                    </el-date-picker>
                                    <span class="f-p-h-2">~</span>
                                    <el-date-picker
                                            class="f-flex-1"
                                            v-model="queryForm.createTimeEnd"
                                            type="datetime"
                                            size="small"
                                            placeholder="结束时间"
                                            default-time="23:59:59"
                                            value-format="yyyy-MM-dd HH:mm:ss"
                                            @keyup.enter.native="handleSearch"
                                    >
                                    </el-date-picker>
                                </div>
                            </el-form-item>
                        </el-col>

                        <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="6">
                            <el-form-item label="付款方式">
                                <f-select :dict="'warehouse_receipts_pay_type'" v-model="queryForm.payTypeList" :multiple="true"
                                          :isNeed="queryForm.payTypeList" @keyup.enter.native="handleSearch"></f-select>
                            </el-form-item>
                        </el-col>

                        <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="6">
                            <el-form-item label="车型">
                                <f-select :dict="'reserved_model'" v-model="queryForm.carTypeList" :multiple="true"
                                          :isNeed="queryForm.carTypeList" @keyup.enter.native="handleSearch"></f-select>
                            </el-form-item>
                        </el-col>

                        <el-col :xs="24" :sm="12" :md="12" :lg="4">
                            <el-form-item label-width="30px">
                                <el-button
                                        type="primary"
                                        icon="el-icon-search"
                                        @click="handleSearch"
                                >查询
                                </el-button
                                >
                                <el-button plain @click="handleReset">重置</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>


                </el-card>
            </el-form>
            <div class="bbb">
                <el-button
                        type="primary"
                        size="medium"
                        icon="el-icon-download"
                        @click="handleAdd"
                        class="margin_right_10"
                        :disabled="selectedRowKeys.length !== 1"
                        v-if="buttonShowList.xzkd">下载开单
                </el-button>

                <el-button type="primary" size="medium" @click="exportExcelPlus(url.downloadExcelUrl,'仓储部开单记录数据')"
                           v-if="buttonShowList.dc">导出
                </el-button>
            </div>
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="73vh"
            >
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'action'">
                        <el-button type="text" @click="handleEdit(scope.row)">编辑</el-button>
                        <el-button type="text" @click="handleBack(scope.row)">退回</el-button>
                    </template>

                    <template v-else-if="scope.column.property === 'receiptsNo'">
                        <a style="color: #00a0e9" @click.prevent="showDetail(scope.row)">{{ scope.row.receiptsNo }}</a>
                    </template>


                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange">
                </el-pagination>
            </el-row>
        </el-card>
        <!--        <add-warehouse-receipts :visible.sync="dialogFormVisible" :orderNos="selectedOrderNos" @ok="handleSearch"></add-warehouse-receipts>-->
        <warehouse-receipts-detail :visible.sync="showDetailVisible" :selectedRow="form"
                                   @ok="handleSearch"></warehouse-receipts-detail>
        <update-one-warehouse-receipts :visible.sync="updateOneVisible" :selectedRow="row"
                                       @ok="handleSearch"></update-one-warehouse-receipts>
        <edit-back-nos :visible.sync="backVisible" :row="backForm" @ok="handleSearch" :title="backTitle"></edit-back-nos>
    </div>
</template>

<script>
    import QueryMixins from "@/mixins/QueryMixins.js";
    import {httpAction, putAction} from "@/api/manage";
    // import AddWarehouseReceipts from "@/views/warehouse2.0/modules/AddWarehouseReceipts";
    import warehouseReceiptsDetail from "@/views/warehouse2.0/modules/warehouseReceiptsDetail";
    import updateOneWarehouseReceipts from "@/views/warehouse2.0/modules/updateOneWarehouseReceipts";
    import editBackNos from "@/views/warehouse2.0/modules/editBackNos.vue";

    export default {
        name: "WarehouseReceipts",
        mixins: [QueryMixins],
        components: {warehouseReceiptsDetail, updateOneWarehouseReceipts, editBackNos},
        data() {
            return {
                row: {},
                isUsePost: true,
                dialogFormVisible: false,
                showDetailVisible: false,
                updateOneVisible: false,
                formLabelWidth: "120px",
                //表单校验

                visible: false,
                //维护 派送费 价格模板计算 （费用名称，最小值，最大值，单位，fbacode，成本，（美元转cny汇率），基数，公式展示（(成本*汇率)/基数），计算出来的单价），结算对象
                columns: [
                    {
                        type: "selection",
                    },

                    {
                        prop: "receiptsNo",
                        label: "开单编号",
                    },
                    {
                        prop: "orderNos",
                        label: "订单号集合",
                    },
                    {
                        prop: "queueNo",
                        label: "排队号",
                    },
                    {
                        prop: "useCarTypeDictText",
                        label: "车辆性质",
                    },
                    {
                        prop: "carTypeDictText",
                        label: "车型",
                    },
                    {
                        prop: "payTypeDictText",
                        label: "付款方式",
                    },
                    {
                        prop: "isOvertimeDictText",
                        label: "是否加班",
                    },
                    {
                        prop: "isMixgoodsDictText",
                        label: "是否混货",
                    },
                    {
                        prop: "printNum",
                        label: "打单份数",
                    },
                    {
                        prop: "licencePlate",
                        label: "车牌号",
                    },
                    {
                        prop: "driverPhone",
                        label: "手机号",
                    },
                    {
                        prop: "arriveFee",
                        label: "入仓费",
                    },
                    {
                        prop: "overtimeFee",
                        label: "加班费",
                    },
                    {
                        prop: "printFee",
                        label: "打单费",
                    },
                    {
                        prop: "mixgoodsFee",
                        label: "混货分货费",
                    },
                    {
                        prop: "createBy",
                        label: "录入人",
                    },
                    {
                        prop: "createTime",
                        label: "开单时间",
                    },
                    {
                        prop: "action",
                        label: "操作",
                    },

                ],
                url: {
                    list: "/warehouse/receipt/queryWarehouseReceiptsList",
                    downloadExcelUrl: '/file/export/excel/downloadWarehouseReceiptsExcel',
                    //下载开单
                    getWarehouseReceipts: '/file/jasper/getWarehouseReceipts',
                    //退回
                },
                selectedOrderNos: '',
                backVisible : false,
                backForm : '',
                backTitle : '',

            };
        },
        methods: {
            handleBack(row) {
                this.backTitle = "退回开单：" + row.receiptsNo + "(单号不填写就是全部退回)";
                this.backVisible = true;
                this.backForm = {
                  id : row.id,
                  orderNos : row.orderNos
                };
            },

            showDetail(row) {
                this.form = JSON.parse(JSON.stringify(row));

                this.showDetailVisible = true;
            },
            handleAdd() {
                let no = this.selectionRows.map(s => s.receiptsNo).join(',')
                this.preview(this.url.getWarehouseReceipts, {id: this.selectedRowKeys.join(',')}, no + '-开票凭证')
            },
            handleEdit(row) {
                this.row = JSON.parse(JSON.stringify(row));

                this.updateOneVisible = true;
            },

            saveImporter() {
                this.$refs["form"].validate((valid) => {
                    if (valid) {
                        let url = this.url.addOneSettle;
                        let method = 'POST';
                        if (this.form.id) {
                            url = this.url.updateOneSettle;
                            method = "PUT";
                        }
                        httpAction(url, method, this.form)
                            .then((res) => {
                                if (res.success) {
                                    this.$message.success(res.message);
                                    this.dialogFormVisible = false;
                                    this.handleQuery();
                                }
                            });
                    }
                });
            },
        },
      computed:{
        buttonShowList() {
          return {
            'dc':this.$btnIsShow('warehouse2.0/warehousoReceipts','0','导出'),
            'xzkd':this.$btnIsShow('warehouse2.0/warehouseReceipts','0','下载开单'),
          }
        }
      }
    };
</script>

<style lang="scss" scoped>
    .bbb {
        display: flex;
    }

    .dialog-footer {
        text-align: right !important;
    }

    .margin_right_10 {
        margin-right: 10px;
    }
</style>
