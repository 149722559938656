<template>
  <div>
    <el-form class="input_top" label-width="70px" size="medium">
      <el-card class="box-card">
        <el-row class="inputs_row">
          <el-col :xs="24" :sm="12" :md="12" :lg="5">
            <el-form-item label="卡号" :label-width="formLabelWidth">
              <el-input v-model="queryForm.cardNo" @keyup.enter.native="handleSearch"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label="客户名" :label-width="formLabelWidth">
              <el-input v-model="queryForm.customer" @keyup.enter.native="handleSearch"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="5">
            <el-form-item label="单号" :label-width="formLabelWidth">
              <el-input v-model="queryForm.orderNo" @keyup.enter.native="handleSearch"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="5">
            <el-form-item label="业务员" :label-width="formLabelWidth">
              <f-select v-model="queryForm.salesmanId" :dict="'sys_user_id'" :is-need="queryForm.salesmanId" @keyup.enter.native="handleSearch"></f-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="5">
            <el-form-item label="卡券类型" :label-width="formLabelWidth">
              <el-select v-model="queryForm.type" filterable clearable @keyup.enter.native="handleSearch">
                <el-option v-for="(item,index) in cardTypeList" :value="item.value" :label="item.label"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="5">
            <el-form-item label="是否下过单" :label-width="formLabelWidth">
              <el-select v-model="queryForm.isOrder" filterable clearable @keyup.enter.native="handleSearch">
                <el-option value="1" label="是"></el-option>
                <el-option value="0" label="否"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="5">
            <el-form-item label="是否已使用" :label-width="formLabelWidth">
              <el-select v-model="queryForm.isUse" filterable clearable @keyup.enter.native="handleSearch">
                <el-option value="1" label="是"></el-option>
                <el-option value="0" label="否"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="有效时间" :label-width="formLabelWidth">
              <div class="f-flex">
                <el-date-picker
                    class="f-flex-1"
                    v-model="queryForm.stime"
                    type="datetime"
                    size="small"
                    placeholder="开始时间"
                    default-time="00:00:00"
                    value-format="yyyy-MM-dd HH:mm:ss"
                >
                </el-date-picker>
                <span class="f-p-h-2">~</span>
                <el-date-picker
                    class="f-flex-1"
                    v-model="queryForm.etime"
                    type="datetime"
                    size="small"
                    placeholder="结束时间"
                    default-time="23:59:59"
                    value-format="yyyy-MM-dd HH:mm:ss"
                >
                </el-date-picker>
              </div>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="3">
            <el-form-item label-width="30px">
              <el-button
                  type="primary"
                  icon="el-icon-search"
                  @click="handleSearch"
              >查询
              </el-button
              >
              <el-button plain @click="handleReset">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
    </el-form>
    <f-table
        v-loading="loading"
        ref="GTable"
        border
        size="medium"
        row-key="id"
        class="c-th-has-bgcolor"
        :columns="columns"
        :data="tableData"
        :show-operation="true"
        :cell-style="{ 'text-align': 'center' }"
        tableHeight="62vh"
        @selection-change="handleSelectionChange"
        @sort-change="handleTableChange"
    >
      <template v-slot="scope">
        <template v-if="scope.column.property === 'action'">
          <el-button v-if="!scope.row.cusId && scope.row.type ==='4'" type="primary" size="small" @click="getNewCustomer(scope.row)">绑定客户</el-button>
        </template>
        <template v-else-if="scope.column.property === 'type'">
          <span>{{cardTypeMap[scope.row.type]}}</span>
        </template>
        <template v-else-if="scope.column.property === 'isUse'">
          <span v-if="scope.row.isUse==='1'" style="color:red">已使用</span>
          <span v-else style="color:green">未使用</span>
        </template>

      </template>
    </f-table>
    <el-row class="f-text-right f-p-v-8">
      <el-pagination
          v-bind="ipagination"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange">
      </el-pagination>
    </el-row>
    <el-dialog :title="'绑定客户'" :visible.sync="dialogFormVisible" :close-on-click-modal="false" :before-close="handleClose" v-if="dialogFormVisible" append-to-body>
      <el-form class="addEditBox" :model="form" :rules="rules" ref="form">
        <el-select v-model="form.cusId" clearable filterable>
          <el-option v-for="(item,index) in cusList" :key="index" :value="item.id" :label="item.username"></el-option>
        </el-select>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="medium" @click="handleClose">取 消</el-button>
        <el-button type="primary" size="medium" @click="handleSubmit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import QueryMixins from "../../mixins/QueryMixins";
import {getAction, postAction, putAction} from "../../api/manage";
export default {
  name : 'CustomerCoupon',
  mixins: [QueryMixins],
  data() {
    return {
      dialogFormVisible:false,
      formLabelWidth:'80px',
      form:{},
      isUsePost:true,
      rules:{
        cusId:[
          {required:true,message:'请选择客户',trigger:'change'}
        ],
      },
      cusList:[],
      cardTypeList:[
        {value :"1",label:'主卡'},
        {value :"2",label:'副卡'},
        {value :"3",label:'返券'},
        {value :"4",label:'通用券'},
      ],
      cardTypeMap:{
        "1":"主卡",
        "2":"副卡",
        "3":"返券",
        "4":"通用券",
      },
      url:{
        list : '/customer/coupon/getCardPage',
        getNewCustomer:'/customer/coupon/getNewCustomerByDate',
        bindCus:'/customer/coupon/bindCusId',
      },
      columns: [
        {
          prop: "action",
          label: "操作",
        },
        {
          prop: "cardNo",
          label: "卡号",
          sortable: "custom",
        },
        {
          prop: "stime",
          label: "有效开始时间",
          sortable: "custom",
        },
        {
          prop: "etime",
          label: "有效结束时间",
          sortable: "custom",
        },
        {
          prop: "customer",
          label: "绑定的客户",
          sortable: "custom",
        },
        {
          prop: "salesman",
          label: "绑定的销售",
          sortable: "custom",
        },
        {
          prop: "orderNo",
          label: "绑定的单号",
          sortable: "custom",
        },
        {
          prop: "orderCreateTime",
          label: "下单时间",
          sortable: "custom",
        },
        {
          prop: "pno",
          label: "绑定的主卡",
        },
        {
          prop: "type",
          label: "卡券类型",
          sortable: "custom",
        },
        {
          prop: "isUse",
          label: "使用状态",
          sortable: "custom",
          headerType:'remark',
          detail:'在入仓之后录入负数的海运费标识为已使用',
        },
        {
          prop: "beforeMoney",
          label: "额度",
          sortable: "custom",
        },
        // {
        //     prop: "canReturn",
        //     label: "使用之后是否返券",
        //     sortable: "custom",
        // },
        // {
        //     prop: "afterMoney",
        //     label: "返回的额度",
        // },
        {
          prop: "createByStr",
          label: "创建人",
          sortable: "custom",

        },
        {
          prop: "createTime",
          label: "创建时间",
          sortable: "custom",

        },
      ],
    }
  },
  methods:{
    handleClose(){
      this.dialogFormVisible = false;
    },
    getNewCustomer(row) {
      this.form.id = row.id;
      this.$set(this.form,'cusId','')
      postAction(this.url.getNewCustomer,row)
          .then(res=>{
            if (res.result && res.result.length > 0 ) {
              this.cusList = res.result;
              this.dialogFormVisible = true;
            }else {
              this.$message.warning('暂未有新注册的正常客户！');
              return false;
            }
          })
    },
    handleSubmit(){
      this.$refs['form'].validate(valid=>{
        if (valid) {
          putAction(this.url.bindCus,this.form)
              .then(res=>{
                this.$message.success(res.message);
                this.dialogFormVisible = false;
                this.handleQuery();
              })
        }
      })
    },
  }
}
</script>
<style></style>
