<template>

    <div class="main-full-content">
        <el-form ref="form" :model="queryForm" label-width="120px" size="medium">
            <el-card class="box-card">
                <el-row>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="仓库名称">
                            <el-input v-model="queryForm.warehouse"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="3">
                        <el-form-item label-width="30px" style="width: 400px">
                            <el-button size="medium" type="primary" icon="el-icon-search" @click="handleCheck">查询</el-button>
                            <!-- <el-divider direction="vertical"></el-divider> -->
                            <el-button size="medium" plain @click="handleReset">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>

        </el-form>
        <el-card class="box-card last_card">
            <!--            <div class="f-p-b-15">-->
            <!--                <el-button size="medium" type="primary" @click="noConvertBill">非折合账单</el-button>-->
            <!--                <el-button size="medium" type="primary" @click="convertBill">折合账单</el-button>-->
            <!--            </div>-->
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    :cell-style="{ 'text-align': 'center' }"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange">
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'action'">
                        <el-button type="text">取消出库</el-button>
                    </template>
                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange">
                </el-pagination>
            </el-row>
        </el-card>

    </div>


</template>
<script>
    import QueryMixins from "../../mixins/QueryMixins"
    import {getAction, postAction} from "@/api/manage";


    export default {
        mixins: [QueryMixins],
        components: {

        },
        data() {
            return {

                editStartOptions: {
                    disabledDate: time => {
                        if (!this.form.etime) {
                            // time = new Date(time.replace(/-/g, '/'));
                            return time.getTime() < new Date(1970 - 1 - 1).getTime();   //禁止选择1970年以前的日期
                        } else {
                            return time.getTime() > new Date(this.form.etime);
                        }
                    }
                },
                editStopOptions: {
                    disabledDate: time => {
                        // time = new Date(time.replace(/-/g, '/'));
                        return (
                            time.getTime() < new Date(this.form.stime) ||
                            time.getTime() < new Date(1970 - 1 - 1).getTime()    //禁止选择1970年以前的日期
                        );
                    }
                },
                selectedRowKeys: [],
                selectionRows: [],
                selectedNos:[],
                loading: false,
                form: {},
                columns: [
                    // {
                    //     type: 'selection',
                    //     width: 50
                    // },
                    // {
                    //     prop: 'action',
                    //     label: '操作',
                    // },
                    {
                        prop: 'warehouse',
                        label: '仓库名称',
                    },
                    {
                        prop: 'realAgent',
                        label: '区域',
                    },
                    {
                        prop: 'agent',
                        label: '区域编码',
                    },
                    {
                        prop: 'feename',
                        label: '区域描述',
                    },

                ],

                url: {
                    list: '/shipping/warehouseOrderLog/list',
                },

            };
        },
        computed: {

        },
        created() {
        },
        methods: {
            handleSelectionChange(selection) { // 多选
                console.log(selection)
                let arr = []
                let nos = []
                this.nameList = []
                this.cnamelist = []
                this.emailList = []
                for (let i = 0; i < selection.length; i++) {
                    arr.push(selection[i]['id'])
                    nos.push(selection[i]['orderNo'])
                    this.nameList.push(selection[i]['username'])
                    this.cnamelist.push(selection[i]['cname'])
                    this.emailList.push(selection[i]['email'])
                }
                this.selectionRows = selection
                this.selectedRowKeys = [...arr]
                this.selectedNos = [...nos]
            },
            handleReceive(){
                console.log("申请收款以下id的费用",this.selectedRowKeys)
            },
            handleReset() {
                this.queryForm = {}
                this.otime = ''
                this.stime = ''
                this.ipagination.currentPage = 1
                this.handleQuery()
            },
            handleCheck() {
                this.loading = true
                if (this.otime.length>1){
                    this.queryForm.createTimeS = this.otime[0]
                    this.queryForm.createTimeE = this.otime[1]
                }
                if (this.stime.length>1) {
                    this.queryForm.sCreateTimeS = this.stime[0]
                    this.queryForm.sCreateTimeE = this.stime[1]
                }
                let form = {...this.queryForm, ...this.filterForm}
                for (let item in form) {
                    if (typeof form[item] === 'string') {
                        form[item] = form[item].trim()
                        if (form[item] === '') {
                            form[item] = null
                        }
                    }
                }
                const {prop, order} = this.isorter
                // 传入的参数
                const params = {
                    ...form,
                    column: prop,
                    order: order.indexOf('asc') !== -1 ? 'asc' : 'desc',
                }
                //判断 是否要查询startTime

                if (!params.column) {
                    if (this.queryForm.column === 'startTime') {
                        this.$set(params, 'column', 'startTime')
                    } else {
                        this.$set(params, 'column', 'createTime')
                    }
                    //提货中查询
                    if (this.queryForm.column === 'distributionTime') {
                        this.$set(params, 'column', 'distributionTime')
                    }
                }
                if (this.ipagination) {
                    params.pageNo = this.ipagination.currentPage
                    params.pageSize = this.ipagination.pageSize
                }
                console.log("查询对象", params)

                getAction(this.url.list, params).then((res) => {
                    if (res.success) {
                        const {records, total} = res.result
                        this.tableData = records || res.result;

                        this.ipagination && (this.ipagination.total = total || res.result.length)
                    }
                }).finally(() => {
                    this.loading = false
                })
            },
            noConvertBill(){
                console.log("申请收款以下id的费用",this.selectedRowKeys)
                if (this.selectedRowKeys.length === 0){
                    this.$message.warning("请勾选费用")
                    return
                }

                let countCur = [];
                this.selectionRows.forEach(item => {
                    countCur.push(item.moneyType)
                });
                let curArr = Array.from(new Set(countCur));
                console.log('curArr->',curArr)
                if(curArr.length > 1){
                    this.$message.warning("所选费用的费用币种不一致！")
                    return
                }

                let count = [];
                this.selectionRows.forEach(item => {
                    count.push(item.settleId)
                });
                let arr = Array.from(new Set(count));
                if(arr.length > 1){
                    this.$message.warning("所选费用的结算对象不一致！")
                    return
                }
                this.$refs.noConvert.edit(this.selectedRowKeys,this.selectedNos)
            },
            convertBill(){
                console.log("申请收款以下id的费用",this.selectedRowKeys)
                if (this.selectedRowKeys.length === 0){
                    this.$message.warning("请勾选费用")
                    return
                }
                let count = [];
                this.selectionRows.forEach(item => {
                    count.push(item.settleId)
                });
                console.log(Array.from(new Set(count)))
                let arr = Array.from(new Set(count));
                if(arr.length > 1){
                    this.$message.warning("所选费用的结算对象不一致！")
                    return
                }
                this.$refs.convert.edit(this.selectedRowKeys,this.selectedNos)
            },
        },
    };
</script>
<style scoped>
    .el-form-item {
        margin-bottom: 22px;
    }
</style>
