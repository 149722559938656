<!--待锁单-->
<template>
    <div class="main-full-content">
        <el-form ref="form" :model="queryForm" label-width="80px" size="medium">
            <div class="f-p-b-15" style="display:flex;">
              <el-card class="box-card">
                <el-row :gutter="24" class="inputs_row">
                  <el-col :md="4" :sm="24">
                    <el-form-item label="订单号">
                      <el-input
                          class="textarea_table"
                          v-model="queryForm.orderNo"
                          @keyup.enter.native="handleSearch"
                          type="textarea"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :md="4" :sm="24">
                      <el-form-item label="周数">
                          <el-input v-model="queryForm.week" @keyup.enter.native="handleSearch"></el-input>
                      </el-form-item>
                  </el-col>
                  <el-col :md="4" :sm="24">
                      <el-form-item label="客户名">
                          <el-input v-model="queryForm.customer" @keyup.enter.native="handleSearch"></el-input>
                      </el-form-item>
                  </el-col>
                  <el-col :md="4" :sm="24">
                      <el-form-item label="柜号">
                          <el-input v-model="queryForm.containerNo" @keyup.enter.native="handleSearch"
                          type="textarea" placeholder="多个柜号精确查询"></el-input>
                      </el-form-item>
                  </el-col>
                  <el-col :md="4" :sm="24">
                    <el-form-item label="进口商类型">
                      <f-select :dict="'order_importer'"
                                v-model="queryForm.importerType"
                                :isNeed="queryForm.importerType"
                      >
                      </f-select>
                    </el-form-item>
                  </el-col>
                  <el-col :md="4" :sm="24">
                    <el-form-item label="是否包税">
                      <f-select
                          v-model="queryForm.isHasTax"
                          :isNeed="queryForm.isHasTax"
                          :dict="'is_default'">
                      </f-select>
                    </el-form-item>
                  </el-col>
                  <el-col :md="4" :sm="24">
                      <el-form-item label="目的地">
                          <f-select :dict="'bd_country'" v-model="queryForm.shipmentCountry"
                                    :is-need="queryForm.shipmentCountry"></f-select>
                      </el-form-item>
                  </el-col>
                  <el-col :md="4" :sm="24">
                    <el-form-item label="渠道名称">
                      <f-select
                          v-model="queryForm.transportStatus"
                          :isNeed="queryForm.transportStatus"
                          :dict="'transportStatus'">
                      </f-select>
                    </el-form-item>
                  </el-col>
                  <el-col :md="4" :sm="24">
                    <el-form-item label="销售">
                      <f-select
                          v-model="queryForm.salesmanId"
                          :isNeed="queryForm.salesmanId"
                          :dict="'sys_user_id'">
                      </f-select>
                    </el-form-item>
                  </el-col>
                  <el-col  :md="4" :sm="24">
                    <el-form-item label="客服">
                      <f-select
                          v-model="queryForm.docId"
                          :isNeed="queryForm.docId"
                          :dict="'sys_user_id'">
                      </f-select>
                    </el-form-item>
                  </el-col>
                  <el-col :md="4" :sm="24">
                    <el-form-item label="排柜操作">
                      <f-select
                          v-model="queryForm.operateId"
                          :isNeed="queryForm.operateId"
                          :dict="'sys_user_id'">
                      </f-select>
                    </el-form-item>
                  </el-col>
                    <el-col :md="4" :sm="24">
                        <el-form-item label="派送操作">
                            <f-select
                                    v-model="queryForm.endOperatorId"
                                    :isNeed="queryForm.endOperatorId"
                                    :dict="'sys_user_id'">
                            </f-select>
                        </el-form-item>
                    </el-col>
                  <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="7">
                  <el-form-item label="入仓时间">
                      <div class="f-flex">
                        <el-date-picker
                          class="f-flex-1"
                          v-model="queryForm.cargoArrivedStart"
                          type="datetime"
                          size="small"
                          placeholder="开始时间"
                          default-time="00:00:00"
                          value-format="yyyy-MM-dd HH:mm:ss"
                        >
                        </el-date-picker>
                        <span class="f-p-h-2">~</span>
                        <el-date-picker
                          class="f-flex-1"
                          v-model="queryForm.cargoArrivedEnd"
                          type="datetime"
                          size="small"
                          placeholder="结束时间"
                          default-time="23:59:59"
                          value-format="yyyy-MM-dd HH:mm:ss"
                        >
                        </el-date-picker>
                      </div>
                    </el-form-item>
                  </el-col>
<!--                  <el-col  :xs="24" :sm="12" :md="12" :lg="4" v-if="showLoadingSearch">-->
<!--                    <el-form-item label="清关属性">-->
<!--                      <f-select-->
<!--                          :dict="'qg_prop'"-->
<!--                          v-model="queryForm.qgProp"-->
<!--                          :is-need="queryForm.qgProp"-->
<!--                      ></f-select>-->
<!--                    </el-form-item>-->
<!--                  </el-col>-->
<!--                  <el-col  :xs="24" :sm="12" :md="12" :lg="4" v-if="showLoadingSearch">-->
<!--                    <el-form-item label="海外仓中转服务">-->
<!--                      <f-select-->
<!--                          :dict="'haiwai_service'"-->
<!--                          v-model="queryForm.haiwaiService"-->
<!--                          :is-need="queryForm.haiwaiService"-->
<!--                      ></f-select>-->
<!--                    </el-form-item>-->
<!--                  </el-col>-->
                  <el-col  :xs="24" :sm="12" :md="12" :lg="4" v-if="showLoadingSearch">
                    <el-form-item label="锁国外仓租柜租">
                      <f-select
                          :dict="'is_default'"
                          v-model="queryForm.lockCangzu"
                          :is-need="queryForm.lockCangzu"
                      ></f-select>
                    </el-form-item>
                  </el-col>
                  <el-col  :xs="24" :sm="12" :md="12" :lg="4" v-if="showLoadingSearch">
                    <el-form-item label="锁国外查验">
                      <f-select
                          :dict="'is_default'"
                          v-model="queryForm.lockChayan"
                          :is-need="queryForm.lockChayan"
                      ></f-select>
                    </el-form-item>
                  </el-col>
                  <el-col  :xs="24" :sm="12" :md="12" :lg="4" v-if="showLoadingSearch">
                    <el-form-item label="三锁服务">
                      <f-select
                          :dict="'is_default'"
                          v-model="queryForm.sanLock"
                          :is-need="queryForm.sanLock"
                      ></f-select>
                    </el-form-item>
                  </el-col>
                  <el-col  :xs="24" :sm="12" :md="12" :lg="4" v-if="showLoadingSearch">
                    <el-form-item label="地址类型">
                      <f-select
                          :dict="'address_type'"
                          v-model="queryForm.shipmentType"
                          :is-need="queryForm.shipmentType"
                      ></f-select>
                    </el-form-item>
                  </el-col>
                  <el-col  :xs="24" :sm="12" :md="12" :lg="4" v-if="showLoadingSearch">
                    <el-form-item label="航线名称">
                      <f-select
                          :dict="'fba_solicitation'"
                          v-model="queryForm.solicitation"
                          :is-need="queryForm.solicitation"
                      ></f-select>
                    </el-form-item>
                  </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4" v-if="showLoadingSearch">
                        <el-form-item label="是否上传pod">
                            <el-select v-model="queryForm.isPod" clearable filterable>
                                <el-option value="1" label="是"></el-option>
                                <el-option value="0" label="否"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4" v-if="showLoadingSearch">
                        <el-form-item label="是否录入派送费">
                            <el-select v-model="queryForm.isPaisongFee" clearable filterable>
                                <el-option value="1" label="是"></el-option>
                                <el-option value="0" label="否"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                  <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="4">
                      <el-form-item label-width="30px">
                          <el-button
                                  type="primary"
                                  icon="el-icon-search"
                                  @click="handleSearch"
                          >查询
                          </el-button
                          >
                          <el-button plain @click="handleReset">重置</el-button>
                        <el-button type="text" mini @click="showLoadingSearch=!showLoadingSearch"><i
                            :class="showLoadingSearch ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"
                            style="margin-right:3px"></i>{{showLoadingSearch ? '收起' :'更多'}}
                        </el-button>
                      </el-form-item>
                  </el-col>
                </el-row>
              </el-card>
            </div>
        </el-form>
        <el-card class="box-card">

            <div class="f-p-b-15">
                <el-button type="primary" size="medium" icon="el-icon-plus" @click="beatchLock"
                           :disabled="this.selectedRowOrderNos.length===0 "  v-if="buttonShowList.plsd"
                           >批量锁单
                </el-button>
                <el-button
                    type="primary"
                    size="medium"
                    v-if="this.queryForm.isPaisongFee=='0'"
                    @click="downloadExcelWait(url.download, '无派送费数据',queryForm)"
            >导出无派送费数据</el-button>
                <el-upload
                        class="upload-demo"
                        action=""
                        :http-request="uploadPODFile"
                        :show-file-list="false"
                        ref="add"
                        :multiple="true"
                        :before-upload="beforeFile"
                        :disabled="selectionRows.length === 0"
                        v-if="buttonShowList.pod"
                        style="margin-left: 10px">
                    <el-button :disabled="selectionRows.length === 0" type="primary" size="small" >批量上传POD</el-button>
                </el-upload>

                <!--      <el-button type="primary" size="medium" icon="el-icon-plus" @click="handleAdd">批量锁应收</el-button>
                      <el-button type="primary" size="medium" icon="el-icon-plus" @click="handleAdd">批量锁应付</el-button>
                      <el-button type="primary" size="medium" icon="el-icon-plus" @click="handleAdd">批量解锁应收</el-button>
                      <el-button type="primary" size="medium" icon="el-icon-plus" @click="handleAdd">批量解锁应付</el-button>-->
            </div>
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    :isorter="isorter"
                    :show-operation="true"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="60vh"
                    :isRecordTableScroll="true"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange"
                    @queryChange="handleTableQueryChange">
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'action'">
                        <el-button type="text" @click="lock(scope.row)" v-if="buttonShowList.sd"
                        >锁单</el-button>
                        <!--     <el-button type="text" @click="handleEdit(scope.row)">锁应收</el-button>
                             <el-button type="text" @click="handleDele(scope.row)">解锁应收</el-button>
                             <el-button type="text" @click="handleEdit(scope.row)">锁应付</el-button>
                             <el-button type="text" @click="handleDele(scope.row)">解锁应付</el-button>-->
                        <el-button type="text" @click="lockActivity(scope.row)" v-if="buttonShowList.sdsp"
                        >发起锁单审批</el-button>
                    </template>
                    <template v-else-if="scope.column.property === 'realCtn'"> <!--拼接件重体-->
                        <span>{{scope.row.realCtn +'/'+scope.row.realKg+'/'+scope.row.realCmb}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'isHasTax'">
                      <span v-if="scope.row.isHasTax === '1'">包税</span>
                      <span v-else>不包税</span>
                    </template>

                    <template v-else-if="scope.column.property === 'importerType'">
                        <span>{{parseDict(scope.row.importerType,'order_importer')}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'shipmentCountry'">
                        <span v-if="scope.row.shipmentType ==='0'">{{scope.row.shipmentCountry}}&nbsp;&nbsp;{{scope.row.shipmentCode}}</span>
                        <span v-else>{{scope.row.shipmentCountry}}&nbsp;&nbsp;{{scope.row.shipmentZip}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'orderNo'">
                        <a
                                style="color: #00a0e9"
                                @click.prevent="$intoDocPage(scope.row.orderNo)"
                        >{{ scope.row.orderNo }}</a>
                        <br>
                        <span v-if="scope.row.channelName &&scope.row.channelName.includes('海+快')"> </span>
                        <span v-else-if="scope.row.isPod === '1'" style="color:green;">已上传POD</span>
                        <span v-else-if="scope.row.isPod ==='0'" style="color: red">未上传POD</span>
                    </template>
                    <template v-else-if="scope.column.property === 'customer'">
                        <a type="text" @click.prevent="$intoCustomerEditPage(scope.row.cusId)" style="color:#57a3f3;">
                            {{scope.row.customer}}
                        </a>
                    </template>
                    <template v-else-if="scope.column.property === 'week'">
                        <a style="color: #00a0e9" @click.prevent="infomation(scope.row.containerId)">{{ scope.row.week }}</a>
                        <!-- 换成a标签，支持复制 -->
                        <!-- <el-button type="text" @click="infomation(scope.row.id)">{{scope.row.week}}</el-button> -->
                    </template>

                    <template v-else-if="scope.column.property === 'isPaisongFeeCount'">
                        <span v-if="scope.row.isPaisongFee ==='1'" style="color: green">有派送费</span>
                        <span v-else style="color: red" >没有派送费</span>
                        <span v-if="scope.row.isOverGuizu === '1'" style="color: red"><br>超柜租</span>
                    </template>
                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                >
                </el-pagination>
            </el-row>
        </el-card>
        <el-dialog
                title="锁单流程申请"
                :visible.sync="diaFormlockActivity"
        >
            <el-form ref="addCabinetForm">
                <el-card class="box-card">
                    <div slot="header" class="clearfix">
                        <span>订单信息</span>
                    </div>
                    <el-row>
                        <el-col :span="10">
                            <el-form-item label="订单号：" :label-width="formLabelWidth" prop="week">
                                {{activityForm.orderNo}}
                            </el-form-item>
                        </el-col>
                        <el-col :span="10">
                            <el-form-item label="业务员：" :label-width="formLabelWidth" prop="week">
                                <!--                                <f-select v-model="activityForm.salesmanId" :dict="'sys_user'"></f-select>-->
                                {{activityForm.salesman}}
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="10">
                            <el-form-item v-if="receivableList!=null" label="应收账单金额：" :label-width="formLabelWidth" prop="week">
                                <!--                            <a v-for="(item, i) in receivableList" style="margin-left: 20px;">{{item.moneyType}}:{{item.total}}</a>-->
                                <div v-for="(item, i) in receivableList" :key="i">
                                    <a style="margin-left: 10px;">{{item.moneyType}}:{{item.total}}</a>
                                    <br v-if="i!= 0 && i%2==0">
                                </div>
                            </el-form-item>
                        </el-col>
                        <el-col :span="10">
                            <el-form-item v-if="payList!=null" label="应付账单金额：" :label-width="formLabelWidth" prop="week">
                                <div v-for="(item, i) in payList" :key="i">
                                    <a style="margin-left: 10px;">{{item.moneyType}}:{{item.total}}</a>
                                    <br v-if="i!= 0 && i%2==0">
                                </div>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-form-item label="账单利润差:" :label-width="formLabelWidth" prop="week" v-if="osContainerSettle1 !=null">
                            利润：{{osContainerSettle1.profit}} RMB
                        </el-form-item>
                    </el-row>
                </el-card>
                <!--                <el-row style="width: 100%">-->
                <!--                    <el-card class="box-card" style="width: 100%">-->
                <!--                        <div slot="header" class="clearfix" s>-->
                <!--                            <span>审批流转意见</span>-->
                <!--                        </div>-->
                <!--                        <template v-for="o in 4" >-->
                <!--                            <div  v-if="o == 1" class="text item">-->
                <!--                                <a >审批人{{o}}：列表内容1111111111 </a>-->
                <!--                            </div >-->
                <!--                            <div v-if="o > 1"  class="text item" style="margin-top: 10px !important;">-->
                <!--                                <a v-if="o > 1" >审批人{{o}}：列表内容1111111111 </a>-->
                <!--                            </div>-->
                <!--                        </template>-->
                <!--                    </el-card>-->
                <!--                </el-row>-->

                <!--                <el-row style="width: 100%">-->
                <!--                    <el-card class="box-card" style="width: 100%">-->
                <!--                        <div slot="header" class="clearfix" s>-->
                <!--                            <span>当前节点审批意见</span>-->
                <!--                        </div>-->
                <!--                        <div>-->
                <!--                            <el-input type="textarea" v-model="activityForm.desc" rows="5"></el-input>-->
                <!--                        </div>-->
                <!--                    </el-card>-->
                <!--                </el-row>-->
            </el-form>
            <div slot="footer" class="dialog-footer" style="text-align: center">
                <el-button @click="diaFormlockActivity = false"
                >取 消
                </el-button
                >
                <el-button type="primary" @click="startLockActivity" v-if="buttonShowList.fpsp"
                           >发起审批</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {getAction, postAction, putAction} from "@/api/manage";
    import QueryMixins from "../../../mixins/QueryMixins";

    // import Tempcclate from "../../isystem/Dict";
    import { setUpRouteUtil } from '@/utils/util';
    import OssClientMixins from "../../../mixins/OssClientMixins";
    import qaqFile from "../../../mixins/qaqFile";
    export default {
        name: "Potential",
        // components: {Tempcclate},
        mixins: [QueryMixins,OssClientMixins,qaqFile],
        data() {
            return {
              isUsePost:true,
              dictCodes: ['is_default', 'transportStatus', 'order_importer', 'bd_port', 'sys_user_id'],
                ids: [],
                row: {},
                form: {},
                showLoadingSearch:false,
                //控制弹框显示
                dialogFormVisible: false,
                diaFormlockActivity: false,
                activityForm: {},
                payList: [],
                receivableList: [],
                osContainerSettle1: {},
                formLabelWidth: "120px",
                columns: [
                    {
                        type: "selection",
                        width: 50,
                    },
                    {
                        prop: "action",
                        label: "操作",
                        minWidth: 150,
                    },
                    {
                        prop: "orderNo",
                        label: "订单号",
                        minWidth: 100,
                    },
                    {
                        prop: "isPaisongFeeCount",
                        label: "是否录入派送费",
                        minWidth: 100,
                    },

                    {
                        prop: "customer",
                        label: "客户名",
                        minWidth: 100,
                    },
                    {
                        prop: "week",
                        label: "周数",
                        minWidth: 80,
                    },
                    {
                        prop: "containerNo",
                        label: "柜号",
                        minWidth: 100,
                    },
                    {
                        prop: "importerType",
                        label: "进口商类型",
                        minWidth: 50,
                    },
                    {
                        prop: "isHasTax",
                        label: "是否包税",
                        minWidth: 50,
                    },
                    {
                        prop: "realCtn",
                        label: "实件/重/体",
                        minWidth: 100,
                    },
                    {
                        prop: "cargoArrived",
                        label: "入仓时间",
                        sortable: "custom",
                        minWidth: 100,
                    },
                    {
                        prop: "shipmentCountry",
                        label: "目的地",
                        minWidth: 100,
                    },
                    {
                        prop: "channelName",
                        label: "渠道名称",
                        minWidth: 100,
                    },
                    {
                        prop: "salesman",
                        label: "销售",
                        minWidth: 80,
                    },
                    {
                        prop: "doc",
                        label: "客服",
                        minWidth: 80,
                    },
                    {
                        prop: "operator",
                        label: "排柜操作",
                        minWidth: 80,
                    },
                    {
                        prop: "endOperator",
                        label: "派送操作",
                        minWidth: 80,
                    },
                  {
                    prop: "reverseNo",
                    label: "预约号",
                  },
                  {
                    prop: "kacheCom",
                    label: "卡车公司",
                  },
                  {
                    prop: "disWarehouse",
                    label: "拆柜仓库",
                  },
                  {
                    prop: "arriveWarehouseTime",
                    label: "到仓时间",
                  },
                ],
                url: {
                    list: "/operation/container/waitLockList", //待锁单列表
                    beatchLock: "/operation/container/updateBeatchLock",
                    lockActivity: '/operation/container/lockActivity',
                    download: "/operation/container/downloadExcel",
                    startLockActivity: '/activiti/operationActiviti/startLockActivity'
                },
                selectedRowOrderNos: [],
            };
        },
        methods: {
            infomation(id) {
                // 对于路由不在左侧菜单中的选中菜单处理
                setUpRouteUtil(this.$route.path, '/operation/cabinet/Edit');
                this.$router.push("/operation/cabinet/Edit?id=" + id);
                console.log(id);
            },


            startLockActivity() {
                this.activityForm.processDefinitionKey = 'lockActivity';
                this.activityForm.instanceName = this.activityForm.orderNo + '-操作申请流程';
                getAction(this.url.startLockActivity, this.activityForm).then((res) => {
                    if (res.success) {
                        this.$message.success(res.message);
                        this.diaFormlockActivity = false;
                    } else {
                        this.$message.error("暂未找到该订单号的费用，请确定是否已经存在该订单的费用");
                    }
                });


            },
            lockActivity(row) {
                console.log(row)
                this.diaFormlockActivity = true
                this.activityForm.salesman = row.salesman
                this.activityForm.orderNo = row.orderNo
                getAction(this.url.lockActivity, {orderNo: row.orderNo}).then((res) => {
                    if (res.success) {
                        console.log(res)
                        this.payList = res.result.payList
                        this.receivableList = res.result.receivableList
                        this.osContainerSettle1 = res.result.osContainerSettle1
                    } else {
                        this.$message.error("暂未找到该订单号的费用，请确定是否已经存在该订单的费用");
                    }
                });

            },
            intoDocpage(orderNo) {
                // 对于路由不在左侧菜单中的选中菜单处理
                setUpRouteUtil(this.$route.path, '/docpage/Edit');
                this.$router.push("/docpage/Edit?orderNo=" + orderNo);

            },
            beatchLock() {
                getAction(this.url.beatchLock + '?orderNos=' + this.selectedRowOrderNos.join(",")).then((res) => {
                    if (res.success) {
                        this.$message.success(res.message);
                        //关闭弹窗
                        this.dialogFormVisible = false;
                        this.handleQuery();
                    }
                });
            },
            lock(row) {
                getAction(this.url.beatchLock + '?orderNos=' + row.orderNo).then((res) => {
                    if (res.success) {
                        this.$message.success(res.message);
                        //关闭弹窗
                        this.dialogFormVisible = false;
                        this.handleQuery();
                    }
                });
            },
          downloadExcelWait(url, fileName, params) {
            if ((params.orderNo == '' || params.orderNo == undefined)
                && (params.customer == '' || params.customer == undefined)
                && (params.containerNo == '' || params.containerNo == undefined)
                && (params.operateId == '' || params.operateId == undefined)
                && (params.endOperatorId == '' || params.endOperatorId == undefined)) {
              this.$message.warning('导出excel必须添加查询条件（订单号/客户名/柜号/排柜操作）')
              return
            }
            let that = this;
            getAction(url, params, {responseType: 'blob'})
                .then(response => {
                  if (!response) {
                    return
                  }
                  if (response.type === 'application/json') {
                    const reader = new FileReader()
                    reader.onload = function () {
                      const message = JSON.parse(reader.result).message
                      // that.$message.error(message);// 将错误信息显示出来
                    }
                    reader.readAsText(response)
                    return false;
                  } else {
                    const blob = new Blob([response])
                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                      navigator.msSaveBlob(blob, fileName)
                    } else {
                      let u = window.URL.createObjectURL(response)
                      let aLink = document.createElement('a')
                      aLink.style.display = 'none'
                      aLink.href = u
                      aLink.setAttribute('download', fileName + '.xls')
                      document.body.appendChild(aLink)
                      aLink.click()
                      document.body.removeChild(aLink)
                      window.URL.revokeObjectURL(u)
                    }
                  }

                })
          },
            handleReset() {
                this.queryForm = {};
                this.filterForm = {};
                this.ipagination.currentPage = 1;
                if (this.$refs['GTable']){
                    this.$refs['GTable'].handleReset();
                }
                this.handleQuery();
            },
        },
        created() {
        },
      computed:{
          buttonShowList() {
            return {
              'plsd':this.$btnIsShow('operation/cabinet/WaitingLock','0','批量锁单'),
              'fpsp':this.$btnIsShow('operation/cabinet/WaitingLock','1','发起审批'),
              'pod':this.$btnIsShow('operation/cabinet/WaitForHandOut','0','批量上传POD'),
              'sd':this.$btnIsShow('operation/cabinet/WaitingLock','1','锁单'),
              'sdsp':this.$btnIsShow('operation/cabinet/WaitingLock','1','发起锁单审批'),
            }
          }
      }
    };
</script>

<style lang="scss" scoped>
</style>
