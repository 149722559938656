<template>
  <el-dialog :title="type == 'log' ? '日志' : (row.id ? '编辑商检费设置' : '新增商检费设置')" :visible="visible" width="560px"
    custom-class="c-custom-dialog" :close-on-click-modal="false" :before-close="handleClose">
    <f-table v-if="type == 'log'" v-loading="loading" ref="GTable" border size="medium" row-key="id"
      class="c-th-has-bgcolor" :columns="columns" :data="tableData"
      :cell-style="{ 'text-align': 'center' }" tableHeight="66vh" @selection-change="handleSelectionChange"
      @sort-change="handleTableChange">
    </f-table>
    <el-form v-else :model="addForm" :rules="rules" ref="addForm" label-width="150px" size="medium">
      <el-row>
        <el-col :span="24">
          <el-form-item prop="shangJianType" label="商检类型">
            <f-select v-model="addForm.shangJianType" :is-need="addForm.shangJianType" :dict="'shang_jian_type'"  placeholder="请选择商检类型">
            </f-select>

          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item prop="startPortNameList" label="起运港">
            <f-select
                v-model="addForm.startPortNameList" :is-need="addForm.startPortNameList" :dict="'bd_start_port'" :multiple="true"  placeholder="请选择起运港"></f-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item prop="moneyType" label="币种">
            <f-select v-model="addForm.moneyType" :is-need="addForm.moneyType" :dict="'bd_cur'"  placeholder="请选择币种"></f-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="costPrice" label="销售结算单价">
            <el-input
                v-model="addForm.costPrice"  placeholder="请输入销售结算单价"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="recPrice" label="应收单价">
            <el-input
                v-model="addForm.recPrice"  placeholder="请输入应收单价"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item prop="minPrice" label="最低值">
            <el-input
                v-model="addForm.minPrice"  placeholder="请输入最低值"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="maxPrice" label="最高值">
            <el-input
                v-model="addForm.maxPrice"  placeholder="请输入最高值"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
            <el-form-item label="计费单位" prop="fjfUnit">
                <f-select :dict="'sjf_unit'"  v-model="addForm.fjfUnit" :is-need="addForm.fjfUnit" placeholder="请选择计费单位"></f-select>
            </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer">
      <el-button size="medium" @click="handleClose">取消</el-button>
      <el-button v-if="type !== 'log'" type="primary" size="medium" @click="handleSubmit">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { httpAction } from '@/api/manage'
import QueryMixins from "@/mixins/QueryMixins.js";
import {validateCmb} from "../../../utils/validator";



export default {
  name: 'EditShangJianFeeSetDialog',
  mixins: [QueryMixins],

  props: {
    visible: {
      type: Boolean
    },
    row: {
      type: Object,
      default: () => { }
    },
    // 弹窗类型
    type: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      disableMixinMounted: true,
      columns: [
        {
          prop: "content",
          label: "详情",
        },
        {
          prop: "createTime",
          label: "创建时间",
        },
        {
          prop: "createBy",
          label: "创建人",
        },
      ],
      addForm: {},
      rules: {
        shangJianType: [
          { required: true, message: '请选择商检类型', trigger: 'change' },
        ],
        startPortList: [
          { required: true, message: '请选择起运港', trigger: 'change' },
        ],
        costPrice: [
          { required: true, message: '请输入销售结算单价', trigger: 'change' },
          {validator:validateCmb,trigger: 'change'}
        ],
        moneyType: [
          { required: true, message: '请选择币种', trigger: 'blur' },
        ],
        recPrice: [
          { required: true, message: '请输入应收单价', trigger: 'blur' },
          {validator:validateCmb,trigger: 'change'}
        ],
        minPrice: [
          {validator:validateCmb,trigger: 'change'}
        ],
        maxPrice: [
          {validator:validateCmb,trigger: 'change'}
        ],
        fjfUnit: [
          { required: true, message: '请输入计费单位', trigger: 'blur' },
        ],
      },
      url: {
        add: '/sys/shangJianFeeSet/addOne',
        edit: '/sys/shangJianFeeSet/editOne',
        list: '/sys/shangJianFeeSet/getLogByBusId',
      },

    }
  },
  watch: {
    visible(val) {
      if (val && this.row.id) {
        if (this.type == 'log') {
          this.initData = {
            busId: this.row.id,
          }
          this.handleReset();
        } else {
          this.addForm = JSON.parse(JSON.stringify(this.row));
        }
      } else {
        this.addForm = {startPortList:[]};
      }
    },

  },
  created() {
  },
  methods: {
    handleSubmit() {
      let url = this.url.add
      if (this.row.id) {
        url = this.url.edit
      }
      if (this.addForm.minPirce && this.addForm.maxPrice && Number(this.addForm.minPirce) > Number(this.addForm.maxPrice)) {
        this.$message.warning('最低值不能大于最高值');
        return false;
      }else {
        this.$refs.addForm.validate((valid) => {
          if (valid) {
            httpAction(url, 'POST', this.addForm).then((res) => {
              this.$message({
                type: 'success',
                message: res.message
              })
              this.handleClose()
              this.$emit('ok')
            })
          }
        })
      }

    },
    handleClose() {
      this.$emit('update:visible', false)
      this.$refs.addForm && this.$refs.addForm.resetFields()
    },
  }
}
</script>

<style lang="scss" scoped></style>
