<!--潜在合作-->
<template>
  <div class="main-full-content">
    <el-form
      class="input_top"
      ref="form"
      :model="queryForm"
      label-width="120px"
      size="medium"
      @submit.native.prevent
    >
      <el-card class="box-card">
        <el-row>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="供应商">
              <el-input v-model="queryForm.contractorName" @keyup.enter.native="handleSearch"></el-input>
            </el-form-item>
          </el-col>

          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label-width="30px">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="handleSearch"
                >查询</el-button
              >
              <el-button plain @click="handleReset">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
    </el-form>
    <el-card class="box-card last_card">
      <div class="f-p-b-15">
        <el-button
          type="primary"
          size="medium"
          icon="el-icon-plus"
          @click="handleEdit({})"
          >新增</el-button
        >
      </div>

      <f-table
        v-loading="loading"
        style="width: 100%"
        ref="GTable"
        border
        size="medium"
        row-key="id"
        class="c-th-has-bgcolor"
        :columns="columns"
        :data="tableData"
        :cell-style="{ 'text-align': 'center' }"
        tableHeight="66vh"
        @selection-change="handleSelectionChange"
        @sort-change="handleTableChange"
      >
        <template v-slot="scope">
          <template v-if="scope.column.property === 'action'">
            <el-button type="text" @click="handleEdit(scope.row)"
              >编辑</el-button
            >
            <el-divider direction="vertical" />
            <el-button type="text" @click="handleDelete(scope.row.id)"
              >删除</el-button
            >
          </template>
          <!--                <template v-else-if="scope.column.property === 'solicitation'">-->
          <!--                    <span>{{scope.row.solicitation_dictText}}</span>-->
          <!--                </template>-->
          <!--                <template v-else-if="scope.column.property === 'transportStatus'">-->
          <!--                    <span>{{scope.row.transportStatus_dictText}}</span>-->

          <!--                </template>-->
          <template v-else-if="scope.column.property === 'orderNo'">
            <a
                    style="color: #00a0e9"
                    @click.prevent="$intoDocPage(scope.row.orderNo)"
            >{{ scope.row.orderNo }}</a>
          </template>
          <template v-else-if="scope.column.property === 'userName'">
            <a type="text" @click.prevent="$intoCustomerEditPage(scope.row.cusId)" style="color:#57a3f3;">{{scope.row.userName}}</a>
          </template>
        </template>

      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination
          v-bind="ipagination"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </el-row>
    </el-card>
    <!--推送地址-->
    <edit-contractor ref="modalForm" @ok="handleSearch"></edit-contractor>
  </div>
</template>

<script>
import {
  deleteAction,
  getAction,
  getallusers,
  postAction,
  putAction,
} from "@/api/manage";
import QueryMixins from "../../../mixins/QueryMixins";
import EditContractor from "./modules/EditContractor";
import LabelSplit from "./modules/LabelSplit";
import CheckAndPlace from "./modules/CheckAndPlace";
import { setUpRouteUtil } from '@/utils/util';

export default {
  name: "Potential",
  mixins: [QueryMixins],
  components: {
    EditContractor,
    LabelSplit,
    CheckAndPlace,
  },
  data() {
    return {
      ids: [],
      row: {},
      form: {},
      formLabelWidth: "100px",
      //控制弹框显示
      dialogFormVisible: false,
      dialogFormVisibleEmail: false,
      columns: [
        {
          type: "selection",
          width: 50,
          fixed: 'left'
        },
        {
          prop: "action",
          label: "操作",
          width: 120,
          fixed: 'left'
        },
        {
          prop: "contractorName",
          label: "供应商名称",
          sortable: "custom",
          width: 120,
          fixed: 'left'
        },
        {
          prop: "account",
          label: "账号",
          width: 100,
          fixed: 'left'
        },
        {
          prop: "password",
          label: "密码",
          sortable: "custom",
          width: 100,
        },
        {
          prop: "code1",
          label: "编码1",
          width: 100,
        },
        {
          prop: "code2",
          label: "编码2",
        },
        {
          prop: "code3",
          label: "编码3",
        },
        {
          prop: "shipperNumber",
          label: "shipperNumber",
          width: 150,
        },
        {
          prop: "shipperAddressline1",
          label: "地址行1",
          width: 150,
        },
        {
          prop: "shipperAddressline2",
          label: "地址行2",
          width: 150,
        },
        {
          prop: "shipperCity",
          label: "发货城市",
          width: 150,
        },
        {
          prop: "shipperStateProvinceCode",
          label: "发货州",
        },
        {
          prop: "shipperPostalCode",
          label: "发货邮编",
          width: 100,
        },
        {
          prop: "shipperCountryCode",
          label: "国家",
        },
        {
          prop: "shipperName",
          label: "发货公司",
          width: 100,
        },
        {
          prop: "shipperAttentionName",
          label: "收货联系人",
          width: 100,
        },
        {
          prop: "shipperPhone",
          label: "联系电话",
        },
        {
          prop: "remark",
          label: "备注",
          width: 200,
        },
      ],
      url: {
        list: "/interface/contractor/list",
        delete: "/interface/contractor/delete",
      },
      emailList: [],
      userlist: [],
      OrderNo: null,
    };
  },
  methods: {

    intoDocpage(orderNo) {
      // 对于路由不在左侧菜单中的选中菜单处理
      setUpRouteUtil(this.$route.path, '/docpage/Edit');
      this.$router.push("/docpage/Edit?orderNo=" + orderNo);

    },
    handleSelectionChange(selection) {
      // 多选
      let arr = [];
      this.emailList = [];
      for (let i = 0; i < selection.length; i++) {
        arr.push(selection[i]["id"]);
        this.emailList.push(selection[i]["email"]);
      }
      this.selectionRows = selection;
      this.selectedRowKeys = [...arr];
    },

    init() {
      this.queryForm.cooperationType = "4";
    },
    handleEdit(row) {
      this.$refs.modalForm.edit(row);
      this.$refs.modalForm.title = "编辑";
      this.$refs.modalForm.disableSubmit = false;
    },
    handleDelete(id) {
      this.$confirm("确定删除该供应商?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
        closeOnClickModal: false,
      })
        .then(() => {
          deleteAction(this.url.delete, { id }).then((res) => {
            this.$message({
              type: "success",
              message: res.message,
            });
            this.handleSearch();
          });
        })
        .catch(() => {});
    },
    //点击编辑，显示弹窗
    handleAdd() {
      this.dialogFormVisible = true;
    },
    handleUpsOrder: function (record) {
      this.OrderNo = record.orderNo;
      this.$refs.modalFormOrder.edit(record);
      this.$refs.modalFormOrder.title = "拆单";
      this.$refs.modalFormOrder.disableSubmit = false;
    },
    handleRecovery(record) {
      this.OrderNo = record.orderNo;
      this.$refs.aboutPrice.edit(record);
      this.$refs.aboutPrice.title = "报价清单";
      this.$refs.aboutPrice.disableSubmit = false;
    },
    handleReset() {
      this.queryForm = {};
      this.init();
      this.ipagination.currentPage = 1;
      this.handleQuery();
    },
  },
  created() {
    this.init();
    this.queryForm.initUser = 1;
  },
};
</script>

<style lang="scss" scoped>
</style>
