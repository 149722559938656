<template>
    <el-dialog
        :title="title"
        :visible="visible"
        width="560px"
        custom-class="c-custom-dialog"
        :before-close="handleClose"
        :close-on-click-modal="false"
        v-if="visible">
        <el-form :model="form" :rules="rules" ref="form" label-width="80px" size="medium">
            <el-row>
                <el-col :span="24">
                    <!-- 批量客服设置支持多选 -->
                    <el-form-item v-if="multiple" prop="orderTypeList" label="订单类型" :rules="rules.orderTypeList">
                        <f-select v-model="form.orderTypeList"
                                :isNeed="form.orderTypeList"
                                :multiple="multiple"
                                @change="getCusType"
                                :dict="'customer_order_type2'">
                        </f-select>
                    </el-form-item>
                    <!-- 客服设置不支持多选 -->
                    <el-form-item v-else prop="orderType" label="订单类型" :rules="rules.orderType">
                        <f-select
                                v-model="form.orderType"
                                :isNeed="form.orderType"
                                @change="getCusType"
                                :dict="'customer_order_type2'">
                        </f-select>
                    </el-form-item>
                </el-col>

                <el-col :span="24">
                    <el-form-item prop="docId" label="客服人员">
                        <f-select
                                v-model="form.docId"
                                :isNeed="form.docId"
                                :dict="'sys_user_id'">
                        </f-select>
                    </el-form-item>
                </el-col>
              <el-col :span="24">
                <el-form-item prop="billmanId" label="收款人员">
                  <f-select
                      v-model="form.billmanId"
                      :isNeed="form.billmanId"
                      :dict="'sys_user_id'">
                  </f-select>
                </el-form-item>
              </el-col>
            </el-row>
        </el-form>
        <span slot="footer">
            <el-button size="medium" @click="handleClose">取消</el-button>
            <el-button type="primary" size="medium" @click="handleSubmit">确定</el-button>
        </span>
    </el-dialog>
</template>

<script>
import { putAction,getAction,getallsalesmans,getallusers,getallrealtion } from '@/api/manage'


export default {
    name: 'SetDocDialog',
    props: {
        visible: {
            type: Boolean
        },
        title: {
            type: String,
            default: '分配'
        },
        ids: {
            type: Array,
            default: () => []
        },
        multiple: {
            type: Boolean
        },
      userName:{
        type :String
      }
    },
    data() {

        return {

            form: {},
            rules: {
                // salesmanId: [
                //     {required: true, message: '请选择业务员', trigger: 'change' },
                // ],
                orderTypeList: [
                    { required: true, message: '请选择类型', trigger: 'change' },
                ],
                orderType: [
                    { required: true, message: '请选择类型', trigger: 'change' },
                ],
                docId: [
                    { required: true, message: '请选择客服人员', trigger: 'change' },
                ],

            },
            url: {
                setDoc: '/customer/detail/setDoc',
                setDocBatch: '/customer/detail/setDocBatch',
                getDocByCusIdAndOrderType: '/customer/detail/getDocByCusIdAndOrderType',


            },


        }
    },
    methods: {
        getCusType(val){
          console.log(val,'val')
            if (this.title.indexOf("批量")==-1) {
                getAction(this.url.getDocByCusIdAndOrderType,{cusId:this.ids.join(','),orderType:val})
                    .then(res=>{
                        if (res.result){
                            this.$set(this.form,'docId',res.result.docId);
                        }else {
                            this.$set(this.form,'docId','');
                        }
                    })
            }

        },
        handleSubmit() {
            this.$refs.form.validate((valid) => {

                if (valid) {



                    let url = this.url.setDocBatch
                    let params = { ids: this.ids }

                    this.form.ids = params.ids;
                    putAction(url, this.form).then((res)=>{
                        this.$message({
                            type: 'success',
                            message: res.message
                        })
                        this.handleClose();
                        this.$parent.handleSearch();
                    })
                }
            })
        },
        handleClose() {
            this.form = {}
            this.$emit('update:visible', false)
            this.$refs.form.resetFields()
        },

    },
    created(){

    }
}
</script>

<style lang="scss" scoped>

</style>
