<template>
    <div>
        <div class="f-p-b-15">
            <el-button type="primary" size="small" icon="el-icon-plus" @click="handleAdd({})">新增</el-button>
        </div>
        <f-table
                v-loading="loading"
                ref="GTable"
                border
                size="medium"
                row-key="id"
                class="c-th-has-bgcolor"
                :columns="columns"
                :data="tableData"
                :isorter="isorter"
                :options="typelist"
                :show-operation="true"
                :cell-style="{ 'text-align': 'center' }"
                @selection-change="handleSelectionChange"
                @sort-change="handleTableChange"
                @queryChange="handleTableQueryChange">
            <template v-slot="scope">
                <template v-if="scope.column.property === 'action'">
                    <el-button type="text" @click="handleEdit(scope.row)">编辑</el-button>
                    <el-button type="text" @click="handleJy(scope.row)" v-if="scope.row.status =='0'">禁用</el-button>
                    <el-button type="text" @click="handleQy(scope.row)" v-if="scope.row.status =='1'">启用</el-button>
                    <!--          <el-button type="text" @click="handlSetToDefault(scope.row)">设为默认</el-button>-->
                </template>
                <template v-else-if="scope.column.property === 'accountType'">
                    <span v-if="scope.row.accountType === '1'">私账</span>
                    <span v-else>公账</span>
                </template>
                <template v-else-if="scope.column.property === 'status'">
                    <span v-if="scope.row.status === '1'">禁用</span>
                    <span v-else>正常</span>
                </template>
            </template>
        </f-table>
        <el-dialog :title="form.id?'编辑账户':'新增账户'" :visible.sync="dialogFormVisible" width="30%" center
                   :close-on-click-modal="false" v-if="dialogFormVisible">

            <el-form :model="form" :rules="rules" ref="form">
                <el-form-item label="账户名" prop="accountName" :label-width="formLabelWidth">
                    <el-input v-model="form.accountName" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="账号" prop="account" :label-width="formLabelWidth">
                    <el-input v-model="form.account" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="开户行" prop="bankName" :label-width="formLabelWidth">
                    <el-input v-model="form.bankName" autocomplete="off"></el-input>
                </el-form-item>

                <el-form-item label="币种" prop="cur" :label-width="formLabelWidth">
                    <f-select
                            v-model="form.cur"
                            :dict="'bd_cur'"
                            :is-need="form.cur"
                    >
                    </f-select>
                </el-form-item>
                <el-form-item label="国家" prop="country" :label-width="formLabelWidth">
                    <f-select :dict="'bd_country'"
                              v-model="form.country"
                              :is-need="form.country"
                    >
                    </f-select>
                </el-form-item>
                <el-form-item label="收款人地址" prop="billmanAddress" :label-width="formLabelWidth">
                    <el-input v-model="form.billmanAddress" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="收款人邮编" prop="billmanZipCode" :label-width="formLabelWidth">
                    <el-input v-model="form.billmanZipCode" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="银行编码类型" prop="bankCodeType" :label-width="formLabelWidth">
                    <el-input v-model="form.bankCodeType" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="账户类型" prop="accountType" :label-width="formLabelWidth">
                    <f-select :dict="'account_type'"
                              v-model="form.accountType"
                              :is-need="form.accountType"
                    >
                    </f-select>
                </el-form-item>
                <el-form-item label="Swift Code" prop="swiftCode" :label-width="formLabelWidth">
                    <el-input v-model="form.swiftCode" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="Sort Code" :label-width="formLabelWidth">
                    <el-input v-model="form.sortCode" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="IBAN" :label-width="formLabelWidth">
                    <el-input v-model="form.iban" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="注册地址" prop="registeredAddress" :label-width="formLabelWidth">
                    <el-input v-model="form.registeredAddress" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="州/省" prop="state" :label-width="formLabelWidth">
                    <el-input v-model="form.state" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="城市" prop="city" :label-width="formLabelWidth">
                    <el-input v-model="form.city" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="ABA no/Routing no" prop="routingNo" :label-width="formLabelWidth">
                    <el-input v-model="form.routingNo" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveAccount" :disabled="btnDisabled">确 定</el-button>
            </div>

        </el-dialog>


    </div>
</template>

<script>
    import QueryMixins from "@/mixins/QueryMixins.js"
    import {getAction, postAction, putAction} from "@/api/manage";

    export default {
        name: 'AccountModule',
        mixins: [QueryMixins],
        components: {},
        data() {
            return {

                form: {},
                btnDisabled: false,
                dialogFormVisible: false,
                formLabelWidth: '120px',
                typelist: [
                    {value: '0', label: '公账'},
                    {value: '1', label: '私账'}
                ],
                curlist: [],
                countrylist: [],
                //表单校验
                rules: {
                    accountName: [
                        {required: true, message: '请输入账户名', trigger: 'blur'},
                        {max: 255, message: '长度不能超过255', trigger: 'change'},
                    ],
                    account: [
                        {required: true, message: '请输入账号', trigger: 'blur'},
                        {max: 100, message: '长度不能超过100', trigger: 'change'},
                    ],
                    bankName: [
                        {required: true, message: '请输入开户行', trigger: 'blur'},
                        {max: 255, message: '长度不能超过255', trigger: 'change'},
                    ],
                    cur: [
                        {required: true, message: '请选择币种', trigger: 'change'},

                    ],
                    country: [
                        {required: true, message: '请选择国家', trigger: 'change'},

                    ],
                    routingNo: [
                        {required: true, message: '请输入ABA no/Routing no', trigger: 'change'},
                    ],
                    accountType: [
                        {required: true, message: '请选择账户类型', trigger: 'change'},

                    ],
                    swiftCode: [
                        {required: true, message: '请输入Swift Code', trigger: 'change'},

                    ],
                    registeredAddress: [
                        {required: true, message: '请输入开户注册地址', trigger: 'change'},

                    ],


                },

                row: {},
                visible: false,
                columns: [
                    {
                        prop: 'action',
                        label: '操作',

                    },
                    {
                        prop: 'accountName',
                        label: '账户名',

                        minWidth: 80
                    },
                    {
                        prop: 'account',
                        label: '账号',

                        sortable: 'custom',
                        minWidth: 100
                    },
                    {
                        prop: 'bankName',
                        label: '开户行',
                        minWidth: 80,
                        sortable: 'custom'
                    },
                    {
                        prop: 'cur',
                        label: '币种',
                        minWidth: 80,
                        sortable: 'custom'
                    },
                    {
                        prop: 'country',
                        label: '国家',
                        minWidth: 80,
                        sortable: 'custom'
                    },
                    {
                        prop: 'accountType',
                        label: '账户类型',
                        minWidth: 80,
                        sortable: 'custom'
                    },
                    {
                        prop: 'swiftCode',
                        label: 'Swift Code',
                        minWidth: 80,
                        sortable: 'custom'
                    },
                    {
                        prop: 'routingNo',
                        label: 'ABA NO/Routing No',
                        sortable: 'custom'
                    },
                    {
                        prop: 'iban',
                        label: 'IBAN',
                        minWidth: 80,
                        sortable: 'custom'
                    },
                    {
                        prop: 'registeredAddress',
                        label: '注册地址',
                        minWidth: 80,
                        sortable: 'custom'
                    },
                    {
                        prop: 'status',
                        label: '状态',
                        minWidth: 80,
                        sortable: 'custom'
                    },
                ],
                url: {
                    list: '/sys/supplierAccount/list',
                    getbyid: '/sys/supplierAccount/queryById',
                    jinyong: '/sys/supplierAccount/disable',
                    qiyong: '/sys/supplierAccount/qiyong',
                    add: '/sys/supplierAccount/add',
                    edit: '/sys/supplierAccount/edit'
                }
            }
        },
        mounted() {
            this.handleSearch()
        },
        methods: {


            handleQy(row) {
                this.$confirm(`确定启用账户${row.accountName}`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'success',
                    closeOnClickModal: false,
                }).then(() => {
                    getAction(this.url.qiyong, {id: row.id}).then(res => {
                        this.$message({
                            type: 'success',
                            message: res.message
                        })
                        this.handleSearch()
                    })
                }).catch(err => {
                    this.$message.error(err.message);
                })
            },
            handleJy(row) {
                this.$confirm(`确定禁用账户${row.accountName}`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'success',
                    closeOnClickModal: false,
                }).then(() => {
                    getAction(this.url.jinyong, {id: row.id}).then(res => {
                        this.$message({
                            type: 'success',
                            message: res.message
                        })
                        this.handleSearch()
                    })
                }).catch(err => {
                    this.$message.error(err.message);
                })
            },
            handleAdd() {
                // let total = this.ipagination.total;
                // if (total >= 10) {
                //     this.$message.warning('最多添加十个银行账号');
                //     return false;
                // }
                this.form = {};
                this.dialogFormVisible = true;
                this.btnDisabled = false;
            },
            handleEdit(row) {
                getAction(this.url.getbyid, {id: row.id})
                    .then(res => {
                        this.form = res.result;
                    })
                //显示弹窗，给对象赋值
                this.dialogFormVisible = true
                this.btnDisabled = false;
            },
            //新增或修改
            saveAccount() {
                //给客户id赋值
                this.btnDisabled = true;
                this.form.supplierId = this.$route.query.id;
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        if (this.form.id) {
                            //有id，编辑
                            putAction(this.url.edit, this.form)
                                .then(res => {
                                    if (res.success) {
                                        this.$message.success(res.message);
                                        //关闭弹窗
                                        this.dialogFormVisible = false;
                                        this.handleQuery();
                                    } else {
                                        this.btnDisabled = false;
                                    }
                                }).catch(err => {
                                this.$message.error(err.message);
                                this.btnDisabled = false;
                            })
                        } else {
                            //新增
                            postAction(this.url.add, this.form)
                                .then(res => {
                                    if (res.success) {
                                        this.$message.success(res.message);
                                        this.dialogFormVisible = false;
                                        this.handleQuery();
                                    } else {
                                        this.btnDisabled = false;
                                    }
                                }).catch(err => {
                                this.btnDisabled = false;
                            })
                        }
                    } else {
                        this.btnDisabled = false;
                    }
                })
            },
            init() {
                let id = this.$route.query.id;
                this.queryForm.supplierId = id;


            }
        },

        created() {

            this.init();
        }
    }
</script>

<style lang="scss" scoped>

</style>
