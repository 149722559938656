<template>
    <div>
        <el-card class="box-card">
            <el-form class="multiline_select" ref="form" :model="queryForm" label-width="80px" size="medium">
                <el-card class="box-card">
                    <el-row class="inputs_row">
                        <el-col :xs="24" :sm="12" :md="12" :lg="6">
                            <el-form-item label="FBA单号">
                                <el-input  v-model="queryForm.fbaOrderNo" type="textarea"
                                           @keyup.enter.native="handleSearch"></el-input>
                            </el-form-item>
                        </el-col>
                      <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="转运单号">
                          <el-input  v-model="queryForm.transferOrderNo" type="textarea"
                                     @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="柜号">
                          <el-input  v-model="queryForm.containerNo" type="textarea"
                                     @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="客户名">
                          <el-input  v-model="queryForm.customer" type="textarea"
                                     @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="贴标状态">
                          <el-select  v-model="queryForm.statusList"
                                     clearable
                                      filterable
                                     multiple
                                      type="textarea"
                                     @keyup.enter.native="handleSearch">
                            <el-option value="0" label="未贴标"></el-option>
                            <el-option value="1" label="待贴标"></el-option>
                            <el-option value="2" label="贴标中"></el-option>
                            <el-option value="3" label="已贴标"></el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                        <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="7">
                            <el-form-item label="下单时间">
                                <div class="f-flex">
                                    <el-date-picker
                                            class="f-flex-1"
                                            v-model="queryForm.createTimeStart"
                                            type="datetime"
                                            size="small"
                                            placeholder="开始时间"
                                            default-time="00:00:00"
                                            value-format="yyyy-MM-dd HH:mm:ss"
                                            @keyup.enter.native="handleSearch"
                                    >
                                    </el-date-picker>
                                    <span class="f-p-h-2">~</span>
                                    <el-date-picker
                                            class="f-flex-1"
                                            v-model="queryForm.createTimeEnd"
                                            type="datetime"
                                            size="small"
                                            placeholder="结束时间"
                                            default-time="23:59:59"
                                            value-format="yyyy-MM-dd HH:mm:ss"
                                            @keyup.enter.native="handleSearch"
                                    >
                                    </el-date-picker>
                                </div>
                            </el-form-item>
                        </el-col>



                        <el-col :xs="24" :sm="12" :md="12" :lg="4">
                            <el-form-item label-width="30px">
                                <el-button
                                        type="primary"
                                        icon="el-icon-search"
                                        @click="handleSearch"
                                >查询
                                </el-button
                                >
                                <el-button plain @click="handleReset">重置</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>


                </el-card>
            </el-form>

            <el-button type="primary" siz="small"  @click="add"
                       :disabled="selectedRowKeys.length === 0"
                       v-if="buttonShowList.sh"
            >审核</el-button>
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="73vh"
            >
                <template v-slot="scope">
                  <template v-if="scope.column.property === 'fbaOrderNo'">
                    <a
                        style="color: #00a0e9"
                        @click.prevent="$intoDocPage(scope.row.fbaOrderNo)"
                    >{{ scope.row.fbaOrderNo }}</a
                    >
                  </template>
                  <template v-else-if="scope.column.property === 'customer'">
                    <a
                        style="color: #00a0e9"
                        @click.prevent="$intoCustomerEditPage(scope.row.cusId)"
                    >{{ scope.row.customer }}</a
                    >
                  </template>
                  <template v-else-if="scope.column.property === 'containerNo'">
                    <a
                        style="color: #00a0e9"
                        @click.prevent="$intoOperationCabinetEditPage(scope.row.containerId)"
                    >{{ scope.row.containerNo }}</a>
                  </template>
                  <template v-else-if="scope.column.property === 'status'">
<!--                    0创建 1贴标中 2已贴标 3已排车 4已出库-->
                    <span v-if="scope.row.status === '0'">未贴标</span>
                    <span v-if="scope.row.status === '1'">待贴标</span>
                    <span v-if="scope.row.status === '2'" style="color:#d7bf49">贴标中</span>
                    <span v-if="scope.row.status === '3'" style="color:#497fd7">已贴标</span>

                  </template>
                  <template v-else-if="scope.column.property === 'addressType'">
                    <!--                    地址类型 0fba仓 1海外仓 2私人地址-->
                    <span v-if="scope.row.status === '0'">FBA仓</span>
                    <span v-if="scope.row.status === '1'">海外仓</span>
                    <span v-if="scope.row.status === '2'">私人地址</span>
                  </template>
                  <template v-else-if="scope.column.property === 'deliveryType'">
                    <!--                  1快递 2亚马逊卡车自提 3大森林卡车派 4UPS自提-->

                    <span v-if="scope.row.deliveryType === '1'">卡车自提</span>
                    <span v-if="scope.row.deliveryType === '2'">快递自提</span>
                    <span v-if="scope.row.deliveryType === '3'">大森林卡派</span>
                    <span v-if="scope.row.deliveryType === '4'">大森林快递派</span>
                  </template>
                  <template v-else-if="scope.column.property === 'stickType'">
<!--                    `'贴标类型 多选 ,隔开 1产品标签 2fba标签 3ups标签 数据字典olc_stick_type',-->
                    <span>{{parseStickType(scope.row.stickType)}}</span>
                  </template>
                  <template v-else-if="scope.column.property === 'cusFbaMarkUrl'">
                    <a
                        style="color: #00a0e9"
                        :href="scope.row.cusFbaMarkUrl"
                    >{{ scope.row.cusFbaMarkFileName }}</a>
                  </template>
                  <template v-else-if="scope.column.property === 'cusBolMarkUrl'">
                    <a
                        style="color: #00a0e9"
                        :href="scope.row.cusBolMarkUrl"
                    >{{ scope.row.cusBolMarkFileName }}</a>
                  </template>
                  <template v-else-if="scope.column.property === 'cusPalletMarkUrl'">
                    <a
                        style="color: #00a0e9"
                        :href="scope.row.cusPalletMarkUrl"
                    >{{ scope.row.cusPalletMarkFileName }}</a>
                  </template>
                  <template v-else-if="scope.column.property === 'orderStatus'">
                    <span>{{parseDict(scope.row.orderStatus,'os_message_log')}}</span>
                  </template>
                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange">
                </el-pagination>
            </el-row>
        </el-card>
    </div>
</template>

<script>
    import QueryMixins from "@/mixins/QueryMixins.js";
    import {deleteAction, postAction,httpAction} from "@/api/manage";
    export default {
        name: "StickOrder",
        mixins: [QueryMixins],
        components: {},
        data() {
            return {
                isUsePost:true,
                dictCodes:['os_message_log'],
                columns: [
                    {
                        type: "selection",
                    },

                  {
                    prop: "stickMarkNo",
                    label: "贴标编码",
                    minWidth:120,
                  },
                    {
                        prop: "fbaOrderNo",
                        label: "FBA订单号",
                    },
                  {
                    prop: "transferOrderNo",
                    label: "转运单号",
                  },
                  {
                    prop: "customer",
                    label: "客户",
                  },
                  {
                    prop: "containerNo",
                    label: "柜号",
                  },
                  {
                    prop: "stickType",
                    label: "贴标类型",
                  },
                  {
                    prop: "addressType",
                    label: "派送地址类型",
                  },
                  {
                    prop: "deliveryType",
                    label: "派送方式",
                  },
                  {
                    prop: "cusBolMarkUrl",
                    label: "BOL",
                  },
                  {
                    prop: "cusFbaMarkUrl",
                    label: "FBA标签",
                  },
                  {
                    prop: "cusPalletMarkUrl",
                    label: "托盘标",
                  },

                  {
                    prop: "status",
                    label: "贴标状态",
                  },
                  {
                    prop: "orderStatus",
                    label: "订单状态",
                  },
                  {
                    prop: "oldSku",
                    label: "原产品sku",
                  },
                  {
                    prop: "newSku",
                    label: "新产品sku",
                  },
                  {
                    prop: "quantity",
                    label: "贴标箱数",
                  },
                  {
                    prop: "expressCompany",
                    label: "快递公司",
                  },
                  {
                    prop: "expressNo",
                    label: "跟踪单号",
                  },
                  {
                    prop: "amazonShipmentCode",
                    label: "Shipment ID",
                  },
                  {
                    prop: "amazonReferenceCode",
                    label: "Reference ID",
                  },
                  {
                    prop: "address",
                    label: "派送地址",
                  },
                  {
                    prop: "cusRemark",
                    label: "操作流程",
                  },
                  {
                    prop: "createTime",
                    label: "创建时间",
                  },
                  {
                    prop: "labelTime",
                    label: "审核时间",
                  },
                  {
                    prop: "labelingTime",
                    label: "贴标中时间",
                  },
                  {
                    prop: "labeledTime",
                    label: "贴标完成时间",
                  },

                ],
                url: {
                    //查询列表的接口
                    list: "/interface/fbaToOlc/stick/list",

                    //审核
                    add: '/interface/fbaToOlc/stick/add',
                },
                stickTypeMap:{
                  '1':'产品标签',
                  '2':'fba标签',
                  '3':'ups标签',
                },


            };
        },
        methods: {
          parseStickType(val) {
            let s = "";
            if (val && val.length > 0) {
              let sp = val.split(",");
              for (let i = 0; i < sp.length; i++) {
                s+= this.stickTypeMap[sp[i]];
                if (i !== sp.length - 1) {
                  s+= ",";
                }
              }
            }
            return s;
          },
          downloadFile(url){
            window.open(url);
          },
          add(){
            postAction(this.url.add,this.selectionRows)
              .then(res=>{
                this.$message({type:'success',message:res.message});
                this.handleSearch();
              })
          },
        },
      computed:{
        buttonShowList(){
          return {
            'sh':this.$btnIsShow('customer/modules/InvoiceModule','0','审核'),
          }
        }
      }
    };
</script>

<style lang="scss" scoped>
    .bbb {
        display: flex;
    }

    .dialog-footer {
        text-align: right !important;
    }

    .margin_right_10 {
        margin-right: 10px;
    }
</style>
