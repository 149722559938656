<template>
  <div class="main-full-content">
    <el-form class="input_top" ref="form" :model="queryForm" label-width="80px" size="medium">
        <el-card class="box-card">
            <el-row>
        <el-col :xs="24" :sm="12" :md="12" :lg="6">
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="6">
          <el-form-item label="操作人">
            <el-select
                clearable
                filterable
                class="f-full-width"
                @click.native="initUserList"
                v-model="queryForm.operator"
                placeholder="请选择">
              <el-option
                  v-for="item in userlist"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="8">
          <el-form-item label="操作时间">
            <div class="f-flex">
              <el-date-picker
                  class="f-flex-1"
                  v-model="queryForm.startTime"
                  type="datetime"
                  size="small"
                  placeholder="开始时间"
                  default-time="00:00:00"
                  value-format="yyyy-MM-dd HH:mm:ss">
              </el-date-picker>
              <span class="f-p-h-2">~</span>
              <el-date-picker
                  class="f-flex-1"
                  v-model="queryForm.endTime"
                  type="datetime"
                  size="small"
                  placeholder="结束时间"
                  default-time="23:59:59"
                  value-format="yyyy-MM-dd HH:mm:ss">
              </el-date-picker>
            </div>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="6">
          <el-form-item label="操作类型">
            <el-select
                clearable
                filterable
                class="f-full-width"
                v-model="queryForm.operatorType"
                placeholder="请选择">
              <el-option
                  v-for="item in caozuolist"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="3">
          <el-form-item label-width="30px">
            <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
            <el-button plain @click="handleReset">重置</el-button>
          </el-form-item>
        </el-col>
      </el-row>
        </el-card>

    </el-form>
    <!--    <div class="f-p-b-15">-->
    <!--      <el-button type="primary" size="small" @click="handleBatApproval" :disabled="selectionRows.length === 0">批量解冻</el-button>-->
    <!--      <el-button type="primary" size="small" class="c-theme-error-button" @click="handleBatDel" :disabled="selectionRows.length === 0">批量删除</el-button>-->
    <!--    </div>-->
    <el-card class="box-card last_card">
        <div class="f-p-b-15">
        <el-button size="medium" type="primary" icon="el-icon-download" @click="handleExportXls">导出</el-button>
    </div>
    <div class="c-alert f-m-b-10">
      已选择 <span class="stress">{{this.selectedRowKeys.length}}</span> 项
      <span class="stress f-m-l-12 f-cursor-pointer" @click="handleClearSelection">清空</span>
    </div>
    <f-table
        v-loading="loading"
        ref="GTable"
        border
        size="medium"
        row-key="id"
        class="c-th-has-bgcolor"
        :columns="columns"
        :data="tableData"
        :cell-style="{ 'text-align': 'center' }"
        @selection-change="handleSelectionChange"
        @sort-change="handleTableChange">
      <template v-slot="scope">
        <template v-if="scope.column.property === 'action'">
          <el-button type="text" @click="handleThaw(scope.row)">解冻</el-button>
          <el-button type="text" @click="handleDel(scope.row)">删除</el-button>
        </template>
<!--        {'value':'0','label':'新增'},
        {'value':'1','label':'审核'},
        {'value':'2','label':'删除'},
        {'value':'3','label':'编辑'},
        {'value':'4','label':'暂停合作'},
        {'value':'5','label':'问题合作'},
        {'value':'6','label':'问题暂停合作恢复'},
        {'value':'7','label':'子账户操作'},
        {'value':'8','label':'银行账户操作'},
        {'value':'9','label':'联系人操作'},
        {'value':'10','label':'文件操作'},-->
        <template v-else-if="scope.column.property === 'operatorType'">
          <span v-if="scope.row.operatorType === '0'">新增</span>
          <span v-else-if="scope.row.operatorType === '1'">审核通过</span>
          <span v-else-if="scope.row.operatorType === '2'">删除</span>
          <span v-else-if="scope.row.operatorType === '3'">编辑</span>
          <span v-else-if="scope.row.operatorType === '4'">暂停合作</span>
          <span v-else-if="scope.row.operatorType === '5'">问题合作</span>
          <span v-else-if="scope.row.operatorType === '6'">问题暂停合作恢复</span>
          <span v-else-if="scope.row.operatorType === '7'">子账户操作</span>
          <span v-else-if="scope.row.operatorType === '8'">银行账户操作</span>
          <span v-else-if="scope.row.operatorType === '9'">联系人操作</span>
          <span v-else-if="scope.row.operatorType === '10'">文件操作</span>
          <span v-else>审核拒绝</span>
        </template>
      </template>
    </f-table>
    <el-row class="f-text-right f-p-v-8">
      <el-pagination
          v-bind="ipagination"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange">
      </el-pagination>
    </el-row>
    </el-card>

  </div>
</template>

<script>
import { putAction, deleteAction,getallusers } from '@/api/manage'
import QueryMixins from "@/mixins/QueryMixins.js"
import Tempcclate from "../../isystem/Dict";
export default {
  name: 'Frozen',
  mixins: [QueryMixins],
  components: {
    Tempcclate

  },
  data() {
    return {
      visible: false,
      row: {},
      props:{
        userName:{
          type :String
        }
      },
      columns: [
        {
          type: 'selection',
          width: 50
        },

        // {
        //   prop: 'action',
        //   label: '操作',
        //   width: 140,
        // },
        {
          prop: 'operator',
          label: '操作人',
          sortable: 'custom',
        },
        {
          prop: 'operatorType',
          label: '操作类型',
          sortable: 'custom',
        },
        {
          prop: 'createTime',
          label: '操作时间',
          sortable: 'custom',
        },
        {
          prop: 'remarks',
          label: '操作描述',
          sortable: 'custom',
        },
      ],
      url: {
        list: '/sys/supplierOperatorHis/list',
        deleteBatch: '',
        jiedong: '/customer/unfreeze',
        batchjiedong: '/customer/unfreezeBatch',
        exportXlsUrl: '/sys/supplierOperatorHis/exportXls',


      },
      userlist:[],
      //操作类型数据操作类型（0：潜在合作审核，1：跟进中审核，2：暂停合作，3：删除，4：基本信息修改）
      // 0:新增，1:审核，2:删除，3:编辑，4:暂停合作，5:问题合作，6:问题暂停合作恢复
      caozuolist:[
        {'value':'0','label':'新增'},
        {'value':'1','label':'审核通过'},
        {'value':'11','label':'审核拒绝'},
        {'value':'2','label':'删除'},
        {'value':'3','label':'编辑'},
        {'value':'4','label':'暂停合作'},
        {'value':'5','label':'问题合作'},
        {'value':'6','label':'问题暂停合作恢复'},
        {'value':'7','label':'子账户操作'},
        {'value':'8','label':'银行账户操作'},
        {'value':'9','label':'联系人操作'},
        {'value':'10','label':'文件操作'},
      ]
    }
  },
  methods: {
    handleEdit(row) {
      this.row = row
      this.visible = true
    },
    initUserList(){
      getallusers().then(
          res=>{
            if (res.success){
              for(var i=0;i<res.result.records.length;i++){
                var obj = {};
                obj.value = res.result.records[i].id;
                obj.label = res.result.records[i].username;
                this.userlist.push(obj);
              }
            }
          }
      )
    },
    handleDel() {
      // this.$confirm(`确定批量删除共?`, '提示', {
      //         confirmButtonText: '确定',
      //         cancelButtonText: '取消',
      //         type: 'success',
      //         closeOnClickModal: false,
      // }).then(() => {
      //     deleteAction(this.url.deleteBatch, {id}).then(res => {
      //         this.$message({
      //             type: 'success',
      //             message: res.message
      //         })
      //         this.handleQuery()
      //     })
      // }).catch(() => {

      // })
    },
    handleBatApproval() {
      var cname = this.selectedRowKeys;
      this.$confirm(`确定将【${cname}】解冻?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'success',
        closeOnClickModal: false,
      }).then(() => {
        deleteAction(this.url.batchjiedong+"?ids="+this.selectedRowKeys.join(',')).then(res => {
          this.$message({
            type: 'success',
            message: res.message
          })
          this.handleQuery()
        })
      }).catch(err=>{
        this.$message.error(err.message);
      })
    },
    handleBatDel() {
      this.$confirm('确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error',
        closeOnClickModal: false,
      }).then(() => {
        // deleteAction(this.url.deleteBatch, {ids: this.selectedRowKeys.join(',')}).then(res => {
        //     this.$message({
        //         type: 'success',
        //         message: res.message
        //     })
        //     this.handleSearch()
        // })
      }).catch(err=>{
        this.$message.error(err.message);
      })
    },
    handleThaw(row) {
      this.$confirm(`确定解冻${row.name}？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error',
        closeOnClickModal: false,
      }).then(() => {
        deleteAction(this.url.jiedong, {id: row.id}).then(res => {
          this.$message({
            type: 'success',
            message: res.message
          })
          this.handleSearch()
        })
      }).catch(err=>{
        this.$message.error(err.message);
      })
    },
    init(){
      let id = this.$route.query.id;
      this.queryForm.supplierId = id;

    },
    handleReset(){
      this.queryForm = {};
      this.init();
      this.ipagination.currentPage = 1
      this.handleQuery()
    },

  },
  created() {
    this.init();
  },
  mounted() {
    this.initUserList();
  }
}
</script>

<style lang="scss" scoped>
.input_top .el-form-item {
    margin-bottom: 2px;
}
</style>
