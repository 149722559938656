<template>
    <el-dialog
            :title="title"
            :visible="visible"
            width="70%"
            custom-class="c-custom-dialog"
            :close-on-click-modal="false"
            :destroy-on-close="true"
            :before-close="handleClose">
        <el-form :model="billForm" ref="billForm" :rules="rules">
            <el-row>
                <el-col :span="23">
                    <el-form-item prop="settleAccountId" label="银行账户">
                        <el-select v-model="billForm.settleAccountId"
                                   placeholder="根据币种选择银行"
                                   clearable
                                   filterable
                                   class="f-full-width">
                            <el-option v-for="item in banks" :value="item.id" :label="item.bankName+' - '+ item.account" :key="item.id">
                                {{item.bankName + '-' + item.accountName + '-' + item.account + '-' + item.cur }}
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col>
                    <el-form-item prop="salesMoneyCur" label="结算币种">
                        <f-select v-model="billForm.salesMoneyCur"
                                  :dict="'bd_cur'"
                                  placeholder="请选择"
                                  clearable
                                  filterable
                                  :is-need="billForm.salesMoneyCur"
                                  @change="curChangeBank"
                                  style="width: 50%"
                                  class="f-full-width"></f-select>
                    </el-form-item>
                </el-col>
                <el-col>
                    <el-form-item prop="salesMoney" label="结算金额">
                        <el-input v-model="billForm.salesMoney" style="width: 50%" oninput="value=value.match(/[-]?\d*\.?\d{0,3}/)">
                        </el-input>
                    </el-form-item>
                </el-col>
<!--                <el-col>-->
<!--                    <el-form-item prop="payCur" label="应付币种">-->
<!--                        <el-input v-model="billForm.payCur" disabled style="width: 50%">-->
<!--                        </el-input>-->
<!--                    </el-form-item>-->
<!--                </el-col>-->
<!--                <el-col>-->
<!--                    <el-form-item prop="realMoney" label="应付金额">-->
<!--                        <el-input v-model="billForm.realMoney" disabled style="width: 50%">-->
<!--                        </el-input>-->
<!--                    </el-form-item>-->
<!--                </el-col>-->
                <el-col>
                    <el-row v-for="(item, index) in billForm.allPaySum" :key="index" :span="24" style="display: flex;margin-left: 0px">
                        <el-form-item label-width="100px" label="实付币种">
                            <el-input disabled="disabled" type="text" v-model="item.cur" oninput="value=value.match(/\d+\.?\d{0,3}/)"></el-input>
                        </el-form-item>
                        <el-form-item label-width="100px" label="实付金额">
                            <el-input disabled="disabled" type="text" v-model="item.total"  oninput="value=value.match(/\d+\.?\d{0,2}/)"></el-input>
                        </el-form-item>
                        <el-form-item label-width="100px" label="结算对象">
                            <el-tag type="info" size="small" v-for="(s, indexs) in item.settlement" :key="indexs" >{{s}}</el-tag>
                        </el-form-item>
                    </el-row>
                </el-col>
                <el-col>
                    <el-form-item prop="removeTime" label="销账时间">
                        <el-date-picker
                                class="f-flex-1"
                                v-model="billForm.removeTime"
                                type="datetime"
                                size="small"
                                range-separator="至"
                                placeholder="开始日期"
                                :picker-options="editStartOptions"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                format="yyyy-MM-dd HH:mm:ss"
                                unlink-panels
                        >
                        </el-date-picker>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <el-button style="float: right;margin-bottom: 10px" type="primary" size="medium" @click="handleSubmit" :disabled="ifDis">确定</el-button>
        <f-table
            v-loading="loading"
            ref="GTable"
            border
            size="medium"
            row-key="id"
            class="c-th-has-bgcolor"
            :columns="columns"
            :data="billForm.settleList"
            :cell-style="{ 'text-align': 'center' }"
            style="margin-top: 15px"
            @selection-change="handleSelectionChange"
        >
            <template v-slot="scope">
                <template v-if="scope.column.property === 'action'">

                    <el-tooltip class="item" effect="dark" content="点击退回至待开账单" placement="top">
                        <el-button type="text">删除</el-button>
                    </el-tooltip>
                </template>


            </template>

        </f-table>
<!--        <span slot="footer">-->
<!--            <el-button type="primary" size="medium" @click="handleSubmit" :disabled="ifDis">确定</el-button>-->
<!--        </span>-->
    </el-dialog>
</template>

<script>
    import {getAction, postAction} from '@/api/manage'
    import QueryMixins from "../../../../mixins/QueryMixins";
    import { setUpRouteUtil } from '@/utils/util';

    export default {
        name: 'noConvertBill',
        mixins: [QueryMixins],

        data() {
            const validateFileList = (rule, value, callback) => {
                if (this.billForm.uploadFileIds.length === 0) {
                    callback(new Error('请上传账单'));
                }
                callback();
            };
            return {
                editStartOptions: {
                    disabledDate(date) {
                        //disabledDate 文档上：设置禁用状态，参数为当前日期，要求返回 Boolean
                        return date.getTime() < new Date(1970 - 1 - 1).getTime();
                    }
                },
                // id:'',
                flag: false,
                visible: false,
                isChecked: true,
                ifDis: false,
                allKeys: [],
                treeData: [],
                banks:[],
                defaultProps: {
                    children: 'children',
                    label: 'title'
                },
                fileList: [],
                billForm: {
                    settleAccountId:'',
                    removeTime:'',
                },
                removeTime:'',
                url: {
                    list: '/sys/bdCountry/list',
                    curBanks: '/shipping/hySettleReceive/curGetBank',
                    uploadInvoice: '/shipping/hySettlePay/uploadInvoice',
                    delFile: '/shipping/hySettlePay/delFile',
                    // doRemove: '/shipping/hySettlePay/doRemove',
                    mergeBatch: "/shipping/hySettlePay/mergeBatch",
                    getSettlesByApplyNos: '/shipping/hySettlePay/getSettlesByApplyNos',

                },
                rules: {
                    settleAccountId: [
                        {required: true, message: '请选择实付银行', trigger: 'change'},
                    ],
                    removeTime: [
                        {required: true, message: '请选择销账时间', trigger: 'change'},
                    ],
                    salesMoney:[
                        {required: true, message: '请填写结算金额', trigger: 'change'},
                    ],
                    salesMoneyCur:[
                        {required: true, message: '请选择结算币种', trigger: 'change'},
                    ],
                },
                title: '批量销账',
                settleIds: [],
                columns: [
                    // {
                    //     type: 'selection',
                    //     width: 50
                    // },
                    // {
                    //     prop: 'action',
                    //     label: '操作',
                    //     width: 70
                    // },
                    {
                        prop: 'orderNo',
                        label: '工作单号',
                        width: 140
                    },
                    {
                        prop: 'applyNo',
                        label: '申请单号',
                        width: 140
                    },
                    {
                        prop: 'customer',
                        label: '客户',
                    },
                    {
                        prop: 'feename',
                        label: '费用中文',
                    },
                    {
                        prop: 'moneyType',
                        label: '应收币种',
                    },
                    {
                        prop: 'total',
                        label: '应收金额',
                    },
                    {
                        prop: 'realMoneyType',
                        label: '折合币种',
                    },
                    {
                        prop: 'exchangeTotal',
                        label: '折合金额',
                    },
                    {
                        prop: 'createBy',
                        label: '申请人',
                    },
                    {
                        prop: 'createTime',
                        label: '申请时间',
                    },
                    {
                        prop: 'salesman',
                        label: '业务员',
                    },
                    {
                        prop: 'operator',
                        label: '操作',
                    },

                ],
            }
        },
        methods: {

            //进入客服页
            intoDocpage(orderNo) {
                if (orderNo.indexOf("KD")!==-1){
                    // 对于路由不在左侧菜单中的选中菜单处理
                    setUpRouteUtil(this.$route.path, '/kdorder/Edit');
                    this.$router.push("/kdorder/Edit?orderNo=" + orderNo);
                }else {
                    // 对于路由不在左侧菜单中的选中菜单处理
                    setUpRouteUtil(this.$route.path, '/docpage/Edit');
                    this.$router.push("/docpage/Edit?orderNo=" + orderNo);
                }

            },
            handleSubmit() {
                this.$refs.billForm.validate((valid) => {
                    if (valid) {
                        this.ifDis = true
                        this.enabled()

                        postAction(this.url.mergeBatch,this.billForm).then((res) => {
                            //检测是同一个结算对象 才进行下载
                            if(res.success){


                                this.visible=false
                                this.$emit('ok');
                            }
                        })


                    }
                })
            },
            //上传文件到oss
            handleUpload(data) {
                const formData = new FormData();
                formData.append("file", data.file);

                postAction(this.url.uploadInvoice, formData).then(res => {
                    // console.log(res)
                    if (res.success) {
                        //上传成功,返回结果赋值给form对象的文件集合
                        this.billForm.uploadFileIds.push(res.result.id);
                        // console.log(this.billForm.uploadFileIds)
                        this.fileList.push(res.result);
                        this.$message.success('上传成功');
                    }else{

                    }
                })
            },
            handleRemove(file) {
                this.fileList.map((item, index) => {
                    if (item.uid == file.uid) {
                        //向服务器发送要删除的文件的路径，删除oss上的文件
                        // console.log(item)
                        postAction(this.url.delFile,{osOrderFileId:item.id, url:item.url}).then((res)=>{
                            // console.log(res)
                            //页面的上的文件也要删除
                            this.fileList.splice(index, 1)
                            this.billForm.uploadFileIds.splice(this.billForm.uploadFileIds.indexOf(item.id),1);
                        })

                    }
                })
            },
            //文件上传之前校验文件合法
            beforeUpload(file) {
                // console.log(file)
                //对文件大小进行限制
                const isLt2M = file.size / 1024 / 1024 < 15
                if (!isLt2M) {
                    this.$message.warning('文件大小不能超过15MB')
                }
                const isFile = file.name.indexOf('.') !== -1;
                if (!isFile) {
                    this.$message.warning('该文件名不合法')
                }
                return isLt2M && isFile;
            },
            handleClose() {
                // this.$emit('update:visible', false)
                // this.$refs.form.resetFields()
                this.visible = false;

            },
            enabled() {
                setTimeout(() => {
                    this.ifDis = false;
                }, 2000)

            },
            edit(ids, nos, cur, allPay) {
                // console.log('订单销账')
                this.$set(this.billForm,'settleList',[])
                // console.log('nos',ids);
                // console.log('nos',nos);
                // console.log('payCur',cur);
                // console.log('payMoney',allPay);

                // this.billForm.payCur = cur;
                // this.billForm.realMoney = allPay;
                this.billForm.applyNos = nos;
                this.billForm.ids = ids;
                // this.billForm.salesMoneyCur = cur
                // this.$set(this.billForm,'salesMoneyCur',JSON.parse(JSON.stringify(cur)))
                // this.$set(this.billForm,'salesMoney',JSON.parse(JSON.stringify(allPay)))
                // console.log('赋值币种',this.billForm.salesMoneyCur)
                this.billForm.allPaySum= allPay

                // this.billForm = row
                this.billForm.removeTimeBankId = '';

                // this.curChangeBank(cur);
                // console.log('billForm',this.billForm);

                let param  = {
                    applyNos:nos
                }
                postAction(this.url.getSettlesByApplyNos,param).then((res)=>{
                    // console.log(res)
                    if(res.success){
                        this.$set(this.billForm,'settleList',res.result.settleList)
                        this.visible = true
                    }else{
                        this.$message.error(res.message)
                        this.visible = true
                    }

                })



            },
            curChangeBank(val){
                // console.log(val)
                getAction(this.url.curBanks,{cur:val}).then((res)=>{
                    // console.log(res)
                    this.banks = res.result
                    this.billForm.settleAccountId = ''
                })
            },
        }
    }
</script>

<style lang="scss" scoped>
    .btn-wrap {
        text-align: right;
        padding: 10px 30px;
    }

    .content {
        height: 620px;
        padding: 6px 0;
        overflow: auto;
    }

</style>
