<template>
    <el-dialog
            title="生成出库数据"
            :visible="visible"
            width="30%"
            custom-class="c-custom-dialog"
            append-to-body
            :before-close="handleClose"
            :close-on-click-modal="false">
        <el-form ref="form" :model="form" :rules="rules">
            <el-table :data="form.sortRuleDtoList">
                <el-table-column prop="lclPlaceName" label="拼柜仓库">
                    <template slot-scope="scope" align="center">
                        <el-form-item :prop="'sortRuleDtoList.'+scope.$index+'.lclPlaceName'">
                            <span v-text="scope.row.lclPlaceName"></span>
                        </el-form-item>
                    </template>
                </el-table-column>
                <el-table-column prop="loadingTime" label="装柜时间">
                    <template slot-scope="scope" align="center">
                        <el-form-item :prop="'sortRuleDtoList.'+scope.$index+'.loadingTime'"
                                      :rules="rules.loadingTime">
                            <el-date-picker
                                    class="f-flex-1"
                                    v-model="scope.row.loadingTime"
                                    type="datetime"
                                    size="small"
                                    placeholder="装柜时间"
                                    default-time="00:00:00"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                            ></el-date-picker>
                        </el-form-item>
                    </template>
                </el-table-column>
                <el-table-column prop="isLimit" label="是否受限制">
                    <template slot-scope="scope" align="center">
                        <el-form-item :prop="'sortRuleDtoList.'+scope.$index+'.isLimit'"
                                      :rules="rules.isLimit">
                            <f-select :dict="'unit_whether'" :isNeed="scope.row.isLimit"
                                      v-model="scope.row.isLimit"></f-select>
                        </el-form-item>
                    </template>
                </el-table-column>
            </el-table>
        </el-form>
        <span slot="footer">
            <el-button size="medium" @click="handleClose">取消</el-button>
            <el-button type="primary" size="medium" @click="handleSubmit">确定</el-button>
        </span>
    </el-dialog>
</template>
<script>
    import {postAction} from "@/api/manage";

    export default {
        name: 'produceOutOrderlyDialog',
        props: {
            visible: {
                type: Boolean
            },
            sortRuleDtoList: {
                type: Array,
                default: () => []
            },
            paiguiOssId: {
                type: String,
                default: ''
            },
        },
        data() {
            return {

                form: {
                    sortRuleDtoList: [
                        {
                            lclPlaceId: '',
                            lclPlaceName: '',
                            isLimit: '',
                            loadingTime: '',
                        }
                    ]
                },
                //校验规则
                rules: {
                    loadingTime: [
                        {required: true, message: "请选择装柜时间", trigger: "change"}
                    ],
                    isLimit: [
                        {required: true, message: "请选择是否受限制", trigger: "change"}
                    ],
                },
                url: {
                    uploadPaigui: "/operation/container/uploadPaigui", //排柜表上传
                },
            }
        },
        watch: {
            visible(val) {
                if (val && this.sortRuleDtoList) {
                    //勾选的订单号进行赋值
                    this.form.sortRuleDtoList = JSON.parse(JSON.stringify(this.sortRuleDtoList));
                    //默认受限制
                    this.form.sortRuleDtoList.forEach(s => {
                        this.$set(s, 'isLimit', '1')
                    })
                }
            },
        },
        methods: {

            handleSubmit() {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        let url = this.url.uploadPaigui;
                        let containerId = this.$route.query.id;
                        //如果是 柜子的id
                        if (!containerId) {
                            //更换url
                        }
                        postAction(url, {
                            id: containerId,
                            sortRuleDtoList: this.form.sortRuleDtoList,
                            paiguiOssId: this.paiguiOssId
                        }).then((res) => {
                            //这里做上传之后的操作
                            if (res.success) {
                                this.$alert(`生成的装柜顺序是:<br><span style="color: green">${res.message}</span>`, '装柜出库结果', {
                                    dangerouslyUseHTMLString: true
                                });
                                this.handleClose();
                            }
                        })


                    }
                })
            },
            handleClose() {
                this.$emit('update:visible', false);
                this.$refs['form'].resetFields();
            }
        },
        created() {
        }
    }
</script>
<style lang="scss" scoped>
    ::v-deep
    .el-dialog__body {
        padding: 20px 40px 20px 6px;
    }

    .addEditBox {
        max-height: 615px;
        overflow: scroll;
    }
</style>
