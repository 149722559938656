<template>
    <el-dialog
            :title="title"
            :visible="visible"
            width="50%"
            custom-class="c-custom-dialog"
            :close-on-click-modal="false"
            :destroy-on-close="true"
            :before-close="handleClose">
        <el-form :model="billForm" ref="billForm" :rules="rules">
            <el-row>
<!--                <el-col :span="23">-->
<!--                    <el-form-item prop="cur" label="折合币种">-->
<!--                        <el-input v-model="billForm.realCur" disabled></el-input>-->
<!--                    </el-form-item>-->
<!--                </el-col>-->

<!--                <el-col>-->
<!--                    <el-form-item prop="realMoney" label="实付金额">-->
<!--                        <el-input disabled="disabled" type="text" v-model="billForm.realMoney" style="width: 250px" oninput="value=value.match(/\d+\.?\d{0,3}/)"></el-input>-->
<!--                    </el-form-item>-->
<!--                </el-col>-->
                <el-col>
                    <el-row v-for="(item, index) in billForm.allPaySum" :key="index" :span="24" style="display: flex">
                            <el-form-item label-width="100px" label="实付币种">
                                <el-input disabled="disabled" type="text" v-model="item.cur"></el-input>
                            </el-form-item>
                            <el-form-item label-width="100px" label="实付金额">
                                <el-input disabled="disabled" type="text" v-model="item.total"  oninput="value=value.match(/\d+\.?\d{0,2}/)"></el-input>
                            </el-form-item>
                            <el-form-item label-width="100px" label="结算对象">
                                <el-tag type="info" size="small" v-for="(s, indexs) in item.settlement" :key="indexs" >{{s}}</el-tag>
                            </el-form-item>
                    </el-row>
                </el-col>

            </el-row>
        </el-form>
        <span slot="footer">
            <el-button type="primary" size="medium" @click="handleSubmit" :disabled="ifDis">确定付款</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import {getAction, postAction} from '@/api/manage'

    export default {
        name: 'mergeDialog',

        data() {
            return {
                // id:'',
                flag: false,
                visible: false,
                isChecked: true,
                ifDis: false,
                allKeys: [],
                treeData: [],
                banks:[],
                defaultProps: {
                    children: 'children',
                    label: 'title'
                },
                fileList: [],
                billForm: {
                    ids:[],
                    settleAccountId:'',
                    realMoney:'',
                },
                url: {
                    // list: '/sys/bdCountry/list',
                    curBanks: '/shipping/hySettleReceive/curGetBank',
                    mergeBatch: '/shipping/hySettlePay/mergeBatch',
                    verifyBatch: "/shipping/hySettlePay/verifyBatch",
                },
                rules: {
                    // settleAccountId: [
                    //     {required: true, message: '请选择付款银行', trigger: 'blur'},
                    // ],
                    realMoney: [
                        {required: true, message: '请输入实付金额', trigger: 'blur'},
                    ],

                },
                title: '批量审核付款',
                settleIds: [],
            }
        },
        methods: {
            handleSubmit() {
                this.$refs.billForm.validate((valid) => {
                    if (valid) {
                        this.ifDis = true
                        this.enabled()

                        postAction(this.url.verifyBatch,this.billForm).then((res)=>{
                            // console.log(res)
                            if (res.success === true){
                                this.$message.success("审核成功");
                                this.visible = false;
                                this.$emit('ok');
                            }
                        })
                    }
                })
            },
            handleClose() {
                // this.$emit('update:visible', false)
                // this.$refs.form.resetFields()
                this.visible = false;

            },
            enabled() {
                setTimeout(() => {
                    this.ifDis = false;
                }, 2000)
            },
            // edit(ids,cur,allPay) { 改多币种多结算对象审核
            //     this.banks = []
            //     this.billForm.realCur = cur;
            //     this.billForm.realMoney = allPay;
            //     this.billForm.ids = ids;
            //     // this.curChangeBank(cur);
            //     this.visible = true
            //
            //     console.log(ids)
            //     console.log(cur)
            // },
            edit(ids,cur,allPay) {
                this.banks = []
                // this.billForm.realCur = cur;
                // this.billForm.realMoney = allPay;
                this.billForm.allPaySum = allPay
                this.billForm.ids = ids;
                // this.curChangeBank(cur);
                this.visible = true

                // console.log(ids)
                // console.log(cur)
            },
            curChangeBank(val){
                // console.log(val)
                getAction(this.url.curBanks,{cur:val}).then((res)=>{
                    // console.log(res)
                    this.banks = res.result

                })
            },
        }
    }
</script>

<style lang="scss" scoped>
    .btn-wrap {
        text-align: right;
        padding: 10px 30px;
    }

    .content {
        height: 620px;
        padding: 6px 0;
        overflow: auto;
    }

    .el-input__inner {
        font-weight: bold;
    }

</style>
