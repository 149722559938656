<template>
    <div class="main-full-content">
        <el-form class="input_top" ref="form" :model="queryForm" label-width="80px" size="medium">
            <el-card class="box-card">
                <el-row>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="条件名称">
                            <el-input v-model="queryForm.conditionName" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="条件类型">
                            <el-select v-model="queryForm.conditionType">
                                <el-option label="是否包税" value="0"></el-option>
                                <el-option label="渠道" value="1"></el-option>
                                <el-option label="揽货方式" value="2"></el-option>
                                <el-option label="国家、预报重量和方数的比值" value="3"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label-width="30px">
                            <el-button type="primary" icon="el-icon-search" @click="handleSearch"

                            >查询</el-button>
                            <el-button plain @click="handleReset"

                            >重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>

        </el-form>
        <el-card class="box-card last_card">
            <div class="f-p-b-15">
                <el-button size="medium" type="primary" icon="el-icon-plus" @click="handleEdit({})"
                           v-if="buttonShowList.add"
                >新增
                </el-button>
<!--                <el-button size="medium" type="primary" class="c-theme-error-button" @click="handleBatDel"-->
<!--                           :disabled="selectionRows.length === 0"-->
<!--                           v-if="$btnIsShow('isystem/Dict','0','批量删除')">批量删除-->
<!--                </el-button>-->
            </div>
            <f-table
                v-loading="loading"
                border
                ref="GTable"
                size="medium"
                row-key="id"
                class="c-th-has-bgcolor"
                :columns="columns"
                :data="tableData"
                :isorter="isorter"
                :show-operation="true"
                :cell-style="{ 'text-align': 'center' }"
                tableHeight="66vh"
                @selection-change="handleSelectionChange"
                @sort-change="handleTableChange"
                @queryChange="handleTableQueryChange">
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'status'">
                        <span v-if="scope.row.status === '0'">启用</span>
                        <span class="dot-badge" v-else>不启用</span>
                    </template>
                    <template v-else-if="scope.column.property === 'conditionType'">
                        <span v-if="scope.row.conditionType === '0'">包税</span>
                        <span v-if="scope.row.conditionType === '1'">渠道</span>
                        <span v-if="scope.row.conditionType === '2'">揽货方式</span>
                        <span v-if="scope.row.conditionType === '3'">国家、预报重量和方数的比值</span>
                    </template>
                    <template v-else-if="scope.column.property === 'action'">
                        <el-button type="text" @click="handleEdit(scope.row)"
                                   v-if="buttonShowList.bj">编辑</el-button>
                        <el-button type="text" @click="handleDel(scope.row)"
                                   v-if="buttonShowList.sc">删除</el-button>
                    </template>
                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                    v-bind="ipagination"
                    layout="total, sizes, prev, pager, next, jumper"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange">
                </el-pagination>
            </el-row>
        </el-card>
        <weight-mark-dialog :visible.sync="visible" :row="row" @ok="handleSearch"></weight-mark-dialog>
    </div>
</template>

<script>
import {deleteAction} from '@/api/manage'
import QueryMixins from "@/mixins/QueryMixins.js"
import WeightMarkDialog from './modules/WeightMarkDialog'

export default {
    name: 'Tempcclate',
    mixins: [QueryMixins],
    components: {
        WeightMarkDialog,
    },
    data() {
        return {
            row: {},
            visible: false,
            configureVisible: false,
            columns: [
                {
                    type: 'selection',
                    width: 50
                },
                {
                    label: '#',
                    type: 'index',
                    width: 50
                },
                {
                    prop: 'action',
                    label: '操作',
                    width: 200,
                },
                {
                    prop: 'conditionName',
                    label: '条件名称',
                },
                {
                    prop: 'conditionType',
                    label: '条件类型',
                },
                {
                    prop: 'status',
                    label: '是否启用',
                },
                {
                    prop: 'country',
                    label: '国家',
                },
                {
                    prop: 'ratio',
                    label: '预报重量和方数比值',
                },
                {
                    prop: 'remark',
                    label: '备注',
                },
                {
                    prop: 'creator',
                    label: '创建人',
                },
                {
                    prop: 'createTime',
                    label: '创建时间',
                },


            ],
            url: {
                list: '/shipping/weightMarkCondition/list',
                delete: '/shipping/weightMarkCondition/delete',
                edit: '/shipping/weightMarkCondition/edit',
            }
        }
    },
    created() {
        this.$initBtnPermission();
    },
    methods: {
        handleEdit(row) {
            this.row = row
            this.visible = true
        },
        handleConfigure(row) {
            this.row = row;
            // consoloe.log('小彪子缓存', this.row);
            this.configureVisible = true
        },
        handleDel({id, conditionName}) {
            this.$confirm(`确定将【${conditionName}】删除?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'error',
                closeOnClickModal: false,
            }).then(() => {
                deleteAction(this.url.delete, {id}).then(res => {
                    this.$message({
                        type: 'success',
                        message: res.message
                    })
                    this.handleSearch()
                })
            }).catch(() => {

            })
        },
        handleBatDel() {
            this.$confirm(`确定批量删除共${this.selectedRowKeys.length}项？`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'error',
                closeOnClickModal: false,
            }).then(() => {
                deleteAction(this.url.deleteBatch, {ids: this.selectedRowKeys.join(',')}).then(res => {
                    this.$message({
                        type: 'success',
                        message: res.message
                    })
                    this.handleSearch()
                })
            }).catch(() => {

            })
        },
    },
  computed:{
    buttonShowList(){
      return {
        'add':this.$btnIsShow('isystem/Dict','0','新增'),
        'bj':this.$btnIsShow('isystem/weightMark','1','编辑'),
        'sc':this.$btnIsShow('isystem/weightMark','1','删除'),
      }
    }
  }
}
</script>
