<template>
  <div class="main-full-content">
    <el-form class="input_top" label-width="80px" size="medium" @submit.native.prevent>
      <el-card class="box-card">
        <el-row>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="起运港" label-width="120px">
              <f-select v-model="queryForm.startPortId" :is-need="queryForm.startPortId" :get-dict-url="url.getPortDict" @keyup.enter="handleSearch"></f-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="目的港" label-width="120px">
              <f-select v-model="queryForm.endPortId" :is-need="queryForm.endPortId" :get-dict-url="url.getPortDict" @keyup.enter="handleSearch"></f-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label-width="30px">
              <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询
              </el-button>
              <el-button plain @click="handleReset">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
    </el-form>
    <el-card class="box-card last_card">
      <div class="f-p-b-15">
        <el-button type="primary" size="medium" icon="el-icon-plus" @click="handleEdit({})"
                   v-if="buttonShowList['add']">新增
        </el-button>
        <el-button :disabled="selectionRows.length === 0" type="primary" size="medium" icon="el-icon-plus"
          @click="handleBatDel()" v-if="buttonShowList['del']">批量删除
        </el-button>
         <el-upload v-if="buttonShowList['importFile']" action="/" :show-file-list="false"
          :http-request="uploadSectionFile" accept=".xls,.xlsx" style="margin:0 10px;display:inline">
          <el-button size="medium" type="primary">导入</el-button>
        </el-upload>
        <el-button v-if="buttonShowList['downloadModel']" type="primary" size="medium"
          @click="downloadModel(url.downloadModel, '海运费设置导入模板')">模板下载
        </el-button>
      </div>
      <f-table v-loading="loading" ref="GTable" border size="medium" row-key="id" class="c-th-has-bgcolor"
        :columns="columns" :data="tableData" :show-operation="true" :cell-style="{ 'text-align': 'center' }"
        tableHeight="66vh" @selection-change="handleSelectionChange" @sort-change="handleTableChange">
        <template v-slot="scope">
          <template v-if="scope.column.property === 'action'">
            <el-button type="text" @click="handleEdit(scope.row)"
              v-if="buttonShowList['edit']">编辑
            </el-button>
            <el-button type="text" @click="handleChangeCanUse(scope.row,'0')"
              v-if="buttonShowList['disabled'] && scope.row.isCanUse === '1'">禁用
            </el-button>
            <el-button type="text" @click="handleChangeCanUse(scope.row,'1')"
                       v-if="buttonShowList['enabled'] && scope.row.isCanUse === '0'">启用
            </el-button>
            <el-button type="text" @click="handleEdit(scope.row, 'log')"
              v-if="buttonShowList['log']">日志
            </el-button>
          </template>
        </template>
      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination v-bind="ipagination" layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange" @current-change="handleCurrentChange">
        </el-pagination>
      </el-row>
    </el-card>

    <edit-ocean-fee-set-dialog :visible.sync="addVisible" :type="type" :row="row" @ok="handleSearch"></edit-ocean-fee-set-dialog>
  </div>
</template>

<script>
import { deleteAction, postAction } from "@/api/manage";
import QueryMixins from "@/mixins/QueryMixins.js";
import EditOceanFeeSetDialog from "./modules/EditOceanFeeSetDialog.vue";
export default {
  name: "OceanFeeSet",
  mixins: [QueryMixins],
  components: {
    EditOceanFeeSetDialog
  },
  data() {
    return {
      loading: false,
      addVisible: false,
      authVisible: false,
      type: '', 
      isUsePost: true,
      row: {},
      options: [],
      id: "",
      columns: [
        {
          type: "selection",
          width: 50,
        },

        {
          prop: "startPortName",
          label: "起运港",
        },
        {
          prop: "endPortName",
          label: "目的港",
        },
        {
          prop: "cabinetType",
          label: "柜型",
        },
        {
          prop: "routeName",
          label: "航线",
        },
        {
          prop: "shipSi",
          label: "船司",
        },
        {
          prop: "feeName",
          label: "费用类别",
        },
        {
          prop: "price",
          label: "价格",
        },
        {
          prop: "moneyType",
          label: "币种",
        },
        {
          prop: "timeStr",
          label: "有效期",
        },
        {
          prop: "createTime",
          label: "创建时间",
        },
        {
          prop: "remark",
          label: "备注",
        },
        {
          prop: "action",
          label: "操作",
          width: 150,
        },
      ],
      url: {
        list: "/sys/ocean/fee/set/queryOceanFeeSetIPage",
        deleteBatch: '/sys/ocean/fee/set/delList',
        downloadModel: '/file/download/model/oceanFeeSet',
        importUrl: '/file/read/excel/importOceanFeeSet',
        edit: '/sys/ocean/fee/set/editOne',
        getCabinetDict: '/sys/bd/cabinetType/getCabinetTypeDictList',
        getDepartDict:'/sys/bd/departure/getDepartureDictList',
        getPortDict:'/sys/bdPort/getBdPortDictList',
      },
    };
  },
  methods: {
    uploadSectionFile(param) {
      //file就是当前添加的文件
      var fileObj = param.file;
      //FormData 对象
      var form = new FormData();
      //文件对象，key是后台接受的参数名称
      form.append("file", fileObj);
      //执行上传方法
      console.log("执行上传方法");
      postAction(this.url.importUrl, form)
        .then((res) => {
          //这里做上传之后的操作
          if (res.success) {
            this.$message.success(res.message);
            this.handleQuery();
          } else {
            this.handleQuery();
          }
        })
    },
    handleDel(id) {
      this.$confirm("确定删除该海运费设置?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
        closeOnClickModal: false,
      })
        .then(() => {
          postAction(this.url.deleteBatch, [id]).then((res) => {
            this.$message({
              type: "success",
              message: res.message,
            });
            this.handleQuery();
          });
        })
        .catch(() => {
        });
    },
    handleBatDel() {
      this.$confirm("确定删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
        closeOnClickModal: false,
      })
        .then(() => {
          postAction(this.url.deleteBatch, this.selectedRowKeys).then((res) => {
            this.$message({
              type: "success",
              message: res.message,
            });
            this.handleQuery();
          });
        })
        .catch(() => { });
    },
    handleAuth(id) {
      this.id = id;
      this.authVisible = true;
    },
    handleEdit(row, type) {
      this.type = type;
      this.row = row;
      this.addVisible = true;
    },
    handleChangeCanUse(row,isCanUse) {
      let params = JSON.parse(JSON.stringify(row));
      params.isCanUse = isCanUse;
      postAction(this.url.edit,params)
          .then(res=>{
            this.$message({
              type: "success",
              message: res.message,
            });
            this.handleQuery();
          })
    }
  },
  computed : {
    buttonShowList() {
      return {
        'add':this.$btnIsShow('channel/OceanFeeSet', '0', '新增'),
        'del':this.$btnIsShow('channel/OceanFeeSet', '0', '批量删除'),
        'importFile':this.$btnIsShow('channel/OceanFeeSet', '0', '导入海运费设置'),
        'downloadModel':this.$btnIsShow('channel/OceanFeeSet', '0', '下载海运费设置导入模板'),
        'edit':this.$btnIsShow('channel/OceanFeeSet', '0', '编辑'),
        'disabled':this.$btnIsShow('channel/OceanFeeSet', '0', '禁用'),
        'enabled':this.$btnIsShow('channel/OceanFeeSet', '0', '启用'),
        'log':this.$btnIsShow('channel/OceanFeeSet', '0', '查看日志'),
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
