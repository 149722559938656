<template>
    <div class="main-full-content">
        <el-form class="input_top" label-width="80px" size="medium" @submit.native.prevent>
            <el-card class="box-card">
                <el-row>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="FBA CODE" label-width="120px">
                            <el-input v-model.trim="queryForm.fbaCode" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="国家" label-width="120px">
                            <el-input v-model.trim="queryForm.country" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="州" label-width="120px">
                            <el-input v-model.trim="queryForm.state" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                  <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <el-form-item label="城市" label-width="120px">
                      <el-input v-model.trim="queryForm.city" @keyup.enter.native="handleSearch"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <el-form-item label="地址" label-width="120px">
                      <el-input v-model.trim="queryForm.address" @keyup.enter.native="handleSearch"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <el-form-item label="邮编" label-width="120px">
                      <el-input v-model.trim="queryForm.zip" @keyup.enter.native="handleSearch"></el-input>
                    </el-form-item>
                  </el-col>

                  <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="6">
                    <el-form-item label="创建时间">
                      <div class="f-flex">
                        <el-date-picker
                            class="f-flex-1"
                            v-model="queryForm.createTimeStart"
                            type="datetime"
                            size="small"
                            placeholder="开始时间"
                            default-time="00:00:00"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            @keyup.enter.native="handleSearch"
                        >
                        </el-date-picker>
                        <span class="f-p-h-2">~</span>
                        <el-date-picker
                            class="f-flex-1"
                            v-model="queryForm.createTimeEnd"
                            type="datetime"
                            size="small"
                            placeholder="结束时间"
                            default-time="23:59:59"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            @keyup.enter.native="handleSearch"
                        >
                        </el-date-picker>
                      </div>
                    </el-form-item>
                  </el-col>
                  <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="6">
                    <el-form-item label="修改时间">
                      <div class="f-flex">
                        <el-date-picker
                            class="f-flex-1"
                            v-model="queryForm.updateTimeStart"
                            type="datetime"
                            size="small"
                            placeholder="开始时间"
                            default-time="00:00:00"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            @keyup.enter.native="handleSearch"
                        >
                        </el-date-picker>
                        <span class="f-p-h-2">~</span>
                        <el-date-picker
                            class="f-flex-1"
                            v-model="queryForm.updateTimeEnd"
                            type="datetime"
                            size="small"
                            placeholder="结束时间"
                            default-time="23:59:59"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            @keyup.enter.native="handleSearch"
                        >
                        </el-date-picker>
                      </div>
                    </el-form-item>
                  </el-col>
                    <el-col>
                        <el-form-item label-width="30px">
                            <el-button
                                type="primary"
                                icon="el-icon-search"
                                @click="handleSearch"
                            >查询
                            </el-button
                            >
                            <el-button plain @click="handleReset">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>
        </el-form>
        <el-card class="box-card last_card">
            <div class="f-p-b-15">
                <el-button
                    type="primary"
                    size="medium"
                    icon="el-icon-plus"
                    @click="handleEdit({})"

                    v-if="buttonShowList.add"
                >新增
                </el-button
                >
            </div>
            <f-table
                v-loading="loading"
                ref="GTable"
                border
                size="medium"
                row-key="id"
                class="c-th-has-bgcolor"
                :columns="columns"
                :data="tableData"
                :show-operation="true"
                :cell-style="{ 'text-align': 'center' }"
                tableHeight="66vh"
                @selection-change="handleSelectionChange"
                @sort-change="handleTableChange"
                :cell-class-name="changeCellStyle"
            >
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'action'">
                        <el-button type="text" @click="handleEdit(scope.row)"

                                   v-if="buttonShowList.bj"
                        >编辑
                        </el-button
                        >
                        <el-button type="text" @click="handleDel(scope.row.id)"

                                   v-if="buttonShowList.sc"
                        >删除
                        </el-button
                        >
                    </template>
                    <template v-else-if="scope.column.property === 'kdIsRemote'">
                        <span v-if="scope.row.kdIsRemote ==='0'">否</span>
                        <span v-else  style="color: red">是</span>
                    </template>
                  <template v-else-if="scope.column.property === 'showColor'">
                    <span :style="getColorByName(scope.row.showColor)">{{parseDict(scope.row.showColor,'fba_addr_show_color')}}</span>
                  </template>
                  <template v-else-if="scope.column.property === 'suggest'">
                    <div style="text-align: left" v-html="scope.row.suggest && scope.row.suggest.replace(/\n/g, '<br>')"></div>
                  </template>
                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                    v-bind="ipagination"
                    layout="total, sizes, prev, pager, next, jumper"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                >
                </el-pagination>
            </el-row>
        </el-card>

        <fbacode-dialog
            :visible.sync="addVisible"
            :row="row"
            :address-type="addressType"
            @ok="handleSearch"
        ></fbacode-dialog>
    </div>
</template>

<script>
import {deleteAction} from "@/api/manage";
import QueryMixins from "@/mixins/QueryMixins.js";
import FbacodeDialog from "../modules/FbacodeDialog";

export default {
    name: "FbacodeManage",
    mixins: [QueryMixins],
    components: {
        FbacodeDialog,
    },
    data() {
        return {
          dictCodes:['fba_addr_show_color'],
            loading: false,
            addVisible: false,
            authVisible: false,
            row: {},
            addressType:'1',
            options: [],
            id: "",
            columns: [
                {
                    prop: "action",
                    label: "操作",
                    width: 90,
                },
                {
                    type: "index",
                    label: "#",
                },
                {
                    prop: "country",
                    label: "国家",
                    width: 100,
                },
                {
                    prop: "fbaCode",
                    label: "fba编码",
                    width: 100,
                },
                {
                    prop: "state",
                    label: "州",
                },
                {
                    prop: "city",
                    label: "城市",
                },
                {
                    prop: "address",
                    label: "地址行1",
                    width: 160,
                },
                {
                    prop: "address2",
                    label: "地址行2",
                    width: 160,
                },
                {
                    prop: "zip",
                    label: "邮编",
                },
                {
                    prop: "createTime",
                    label: "创建时间",
                    width: 120,
                },
              {
                prop: "createBy",
                label: "创建人",
                width: 80,
              },
              {
                prop: "updateTime",
                label: "修改时间",
              },
              {
                prop: "updateBy",
                label: "修改人",
                width: 80,
              },
                {
                    prop: "kdIsRemote",
                    label: "快递是否偏远",
                    width: 80,
                },
              {
                prop: "showColor",
                label: "优先状态",
                width: 60,
              },

                {
                  prop: "suggest",
                  label: "派送建议",
                  width: 150
                },
            ],
            url: {
                list: "/sys/fbacode/list",
                delete: "/sys/fbacode/delete",
            },
        };
    },
    methods: {
      changeCellStyle (row, column, rowIndex, columnIndex) {
        let colorName = this.parseDict(row.row.showColor,'fba_addr_show_color');
        // 应收-是否已销账添加背景色
        return this.getColorByName(colorName);

      },
        handleDel(id) {
            this.$confirm("确定删除该FBA CODE?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "error",
                closeOnClickModal: false,
            })
                .then(() => {
                    deleteAction(this.url.delete, {id}).then((res) => {
                        this.$message({
                            type: "success",
                            message: res.message,
                        });
                        this.handleSearch();
                    });
                })
                .catch(() => {
                });
        },
        handleSearch() {

            // this.ipagination.currentPage = 1
            this.handleQuery()
        },
      init(){
        this.queryForm = {};
        this.$set(this.queryForm,'addressType','1');
      },
        handleReset(){
          this.init();
          this.handleSearch();
        },
        handleAuth(id) {
            this.id = id;
            this.authVisible = true;
        },
        handleEdit(row) {
            this.row = row;
            this.addVisible = true;
        },
    },
    created() {
      this.init();
      this.handleQuery();
    },
  computed:{
    buttonShowList() {
      return {
        'add':this.$btnIsShow('isystem/BaseData/FbacodeManage','0','新增'),
        'bj':this.$btnIsShow('isystem/BaseData/FbacodeManage','1','编辑'),
        'sc':this.$btnIsShow('isystem/BaseData/FbacodeManage','1','删除'),
      }
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
