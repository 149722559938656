<template>
  <div class="lossAndRevive_box">
    <el-tabs v-model="activeName" @tab-click="handlerChange">
      <el-tab-pane
        v-for="(tab, index) in tabs"
        :key="index"
        :label="tab.label"
        :lazy="true"
        :name="tab.component"
      >
        <component
          :is="tab.component"
          v-if="tab.show"
          :ref="tab.component"
        ></component>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import LossAndReviveCustomer from "./LossAndReviveCustomer";
import LossAndReviveDetail from "./LossAndReviveDetail";
export default {
  components: {
    LossAndReviveCustomer,
    LossAndReviveDetail,
  },
  data() {
    return {
      activeName: "LossAndReviveCustomer",
      tabs: [
        { label: "汇总报表", component: "LossAndReviveCustomer", show: true },
        { label: "明细报表", component: "LossAndReviveDetail", show: true },
      ],
    };
  },
  watch: {},
  created() {
    this.handlerChange();
  },
  methods: {
    handlerChange() {
      this.$nextTick(() => {
        this.$refs[this.activeName][0] &&
          this.$refs[this.activeName][0].handleQuery();
      });
    },
  },
};
</script>

<style lang="scss">
.lossAndRevive_box {
  .el-tabs--top > .is-top {
    left: 150px;
  }
}
</style>