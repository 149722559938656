<template>
  <div class="main-full-content">
    <el-form ref="form" :model="queryForm" label-width="80px" size="medium">
      <el-row>
        <el-col :xs="24" :sm="12" :md="12" :lg="6"></el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="6">
          <el-form-item label-width="50px">
            <el-button type="primary"  @click="handleAdd">新增</el-button>
            <el-upload
                    action="/"
                    :show-file-list="false"
                    :http-request="uploadSectionFile"
                    :on-preview="handlePreview"
                    :on-remove="handleRemove"
                    accept=".xls,.xlsx"
                    style="display: inline-flex;margin:10px;"
                >
              <el-button size="small" type="primary"

              >导入</el-button>
            </el-upload>
            <el-button type="primary"  @click="downloadModel(url.downloadModel,'客户付款发票模板')">模板下载</el-button>

          </el-form-item>
        </el-col>
      </el-row>
    </el-form>



    <el-dialog :title="form.id?'编辑付款发票':'新增付款发票'"
               v-if="dialogFormVisible"
               :visible.sync="dialogFormVisible" width="30%" center :close-on-click-modal="false">

      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item label="账户名" prop="accountName" :label-width="formLabelWidth" >
          <el-input v-model="form.accountName" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item label="账号" prop="account" :label-width="formLabelWidth">
          <el-input v-model="form.account" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="开户行" prop="bank" :label-width="formLabelWidth">
          <el-input v-model="form.bank" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="账户类型" prop="accountType" :label-width="formLabelWidth">
          <el-radio v-model="form.accountType" label="1">公账</el-radio>
          <el-radio v-model="form.accountType" label="0">私账</el-radio>
        </el-form-item>
        <el-form-item label="发票类型" prop="invoiceType" :label-width="formLabelWidth">
          <el-radio v-model="form.invoiceType" label="1">普票</el-radio>
          <el-radio v-model="form.invoiceType" label="0">专票</el-radio>
        </el-form-item>
        <el-form-item label="发票抬头" prop="invTt" :label-width="formLabelWidth" :rules="form.accountType =='1'?rules.invTt:[]">
          <el-input v-model="form.invTt" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="税号" prop="dutyNo" :label-width="formLabelWidth" :rules="form.accountType =='1'?rules.dutyNo:[]">
          <el-input v-model="form.dutyNo" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="电话" prop="phone" :label-width="formLabelWidth" >
          <el-input v-model="form.phone" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="接收发票邮箱" prop="mailAddress" :label-width="formLabelWidth" >
          <el-input v-model="form.mailAddress" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="地址信息" prop="addressInfo" :label-width="formLabelWidth">
          <el-input v-model="form.addressInfo" autocomplete="off" type="textarea"></el-input>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveInfo" :disabled="btnDisable">确 定</el-button>
      </div>

    </el-dialog>

    <div class="c-alert f-m-b-10">
      已选择 <span class="stress">{{this.selectedRowKeys.length}}</span> 项
      <span class="stress f-m-l-12 f-cursor-pointer" @click="handleClearSelection">清空</span>
    </div>
    <f-table
        v-loading="loading"
        ref="GTable"
        border
        size="medium"
        row-key="id"
        class="c-th-has-bgcolor"
        :columns="columns"
        :data="tableData"
        :cell-style="{ 'text-align': 'center' }"
        @selection-change="handleSelectionChange"
        @sort-change="handleTableChange">
      <template v-slot="scope">
        <template v-if="scope.column.property === 'action'">
          <el-button type="text" @click="handleEdit(scope.row)">编辑</el-button>
          <el-button type="text" @click="handleDel(scope.row)">删除</el-button>
          <el-button type="text" @click="handleDefault(scope.row)" v-if="scope.row.isDefault ==='0'">设为默认</el-button>
          <el-button type="text" @click="handleQxDefault(scope.row)" v-if="scope.row.isDefault ==='1'">取消默认</el-button>
        </template>
        <template v-else-if="scope.column.property === 'accountType'">
          <span v-if="scope.row.accountType === '0'">私账</span>
          <span v-else>公账</span>
        </template>
        <template v-else-if="scope.column.property === 'invoiceType'">
          <span v-if="scope.row.invoiceType === '0'">专票</span>
          <span v-else>普票</span>
        </template>
        <template v-else-if="scope.column.property === 'isDefault'">
          <span v-if="scope.row.isDefault === '0'">否</span>
          <span v-else>是</span>
        </template>
      </template>
    </f-table>
    <el-row class="f-text-right f-p-v-8">
      <el-pagination
          v-bind="ipagination"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange">
      </el-pagination>
    </el-row>
  </div>
</template>

<script>
import { putAction, deleteAction,getallusers,getAction,postAction } from '@/api/manage'
import QueryMixins from "@/mixins/QueryMixins.js"
import {mobilePattern,emailPattern} from "@/utils/pattern";
export default {
  name: 'InvoiceModule',
  mixins: [QueryMixins],
  components: {

  },
  data() {
    return {
      // 导入按钮的文本
      importDataText: '导入',
      // 导入按钮的图标
      importDataIcon: 'el-icon-upload2',
      // 导入按钮是否被禁用
      importDisabled: false,
      visible: false,
      formLabelWidth:'120px',
      row: {},
      form:{},
      btnDisable:false,
      //控制弹框显示
      dialogFormVisible:false,
      //表单校验
      rules:{
        accountName :[
          {required : true,message: '请输入账户名',trigger:'blur'},
          { max: 50, message: '长度不能超过50', trigger: 'change' },
        ],
        account:[
          {required:true,message:'请输入账号',trigger:'blur'},
          {max:50,message:'长度不能超过50',trigger:'change'},
        ],
        bank:[
          {required:true,message:'请输入开户行',trigger:'blur'},
          {max:50,message:'请输入开户行',trigger:'change'}
        ],
        accountType:[
          {required:true,message:'请选择账户类型',trigger:'change'}
        ],
        invoiceType:[
          {required:true,message:'请选择发票类型',trigger:'change'}
        ],
        invTt:[
          {required:true,message:'请输入发票抬头',trigger:'blur'}
        ],
        dutyNo :[
          {required : true,message: '请输入税号',trigger:'blur'},
          { max: 50, message: '长度不能超过50', trigger: 'change' }
        ],
        phone :[
          {required : true,message: '请输入手机号',trigger:'blur'},
          // { pattern: mobilePattern, message: '请输入正确的手机号', trigger: 'blur' }
        ],
        mailAddress:[
          {required : true,message: '请输入接收发票的邮箱',trigger:'blur'},
          {pattern:emailPattern,message:'请输入正确的邮箱',trigger:'change'}
        ],
      },
      columns: [
        {
          type: 'selection',
          width: 50
        },
        {
          prop: 'action',
          label: '操作',
          width: 140,
        },
        {
          prop: 'accountName',
          label: '账户名',
          sortable: 'custom',
          width: 130

        },
        {
          prop: 'account',
          label: '账号',
          sortable: 'custom',
          minWidth: 140

        },
        {
          prop: 'bank',
          label: '开户行',
          sortable: 'custom',
          width: 130
        },
        {
          prop: 'invoiceType',
          label: '发票类型',
          sortable: 'custom',
          formatter:function (text){
            if (text.invoiceType === '1'){
              return '普票'
            }else {
              return '专票'
            }
          },
          width: 140
        },
        {
          prop: 'invTt',
          label: '发票抬头',
          sortable: 'custom',
          width: 140
        },
        {
          prop: 'dutyNo',
          label: '税号',
          sortable: 'custom',
          minWidth: 140

        },
        {
          prop: 'addressInfo',
          label: '地址信息',
          sortable: 'custom',
          width: 140
        },
        {
          prop: 'phone',
          label: '电话',
          sortable: 'custom',
        },
        {
          prop: 'isDefault',
          label: '是否默认',
          // headerType: 'string',
          minWidth: 140,
          sortable: 'custom'
        },
        {
          prop: 'mailAddress',
          label: '接收发票邮箱',
          sortable: 'custom',
          width: 160
        },
        {
          prop: 'createTime',
          label: '创建时间',
          sortable: 'custom',
          width: 140
        },

      ],
      url: {
        list: '/customer/invoiceInformation/list',
        deleteBatch: '',
        delByid: '/customer/invoiceInformation/delete',
        setDefault: '/customer/invoiceInformation/setDefault',
        qxDefault: '/customer/invoiceInformation/qxDefault',
        exportXlsUrl: '/customer/log/exportXls',
        add:'/customer/invoiceInformation/add',
        edit:'/customer/invoiceInformation/edit',
        getById:'/customer/invoiceInformation/queryById',
        downloadModel:'/customer/invoiceInformation/downloadExcel',
        importUrl:'/customer/invoiceInformation/import' + '?cusId=' + this.$route.query.id,


      },
      userlist:[],
      //操作类型数据
      caozuolist:[
        {'value':'0','label':'解冻客户'},
        {'value':'1','label':'设置跟进人'},
        {'value':'2','label':'转让客户'},
        {'value':'3','label':'退回客户'},
        {'value':'4','label':'删除客户'},
        {'value':'5','label':'审批通过客户'},

      ]
    }
  },
  methods: {
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    beforeUpload(data){
      return false;
    },

    async uploadSectionFile(param){
      //file就是当前添加的文件
      var fileObj = param.file;
      //FormData 对象
      var form = new FormData();
      //文件对象，key是后台接受的参数名称
      form.append("file",fileObj);
      form.append("dir",'osOrder');
      //执行上传方法
      console.log("执行上传方法")
      postAction(this.url.importUrl,form)
      .then(res=>{
        //这里做上传之后的操作
        if(res.success){
          this.$message.success(res.message);
          this.handleQuery();
        }
      })
    },
    // onError() {
    //   this.importDataText = '导入数据';
    //   this.importDataIcon = 'el-icon-upload2';
    //   this.importDisabled = false;
    //   this.handleQuery();
    //   this.$message.success("导入失败！");
    // },
    // // 导入文件成功后回调
    onSuccess() {
      // 成功后文本修改为原来的导入数据
      this.importDataText = '导入数据';
      // 图标修改
      this.importDataIcon = 'el-icon-upload2';
      // 将上传组件改为允许使用
      this.importDisabled = false;
      // 调用刷新数据的方法
      this.handleQuery();
      // message 弹出消息
      this.$message.success("导入成功！");
    },
    // // 上传文件调用
    // beforeUpload() {
    //   // 将文本修改为正在导入
    //   this.importDataText = '正在导入';
    //   // 修改其图标
    //   this.importDataIcon = 'el-icon-loading';
    //   // 将其上传组件暂时禁用
    //   this.importDisabled = true;
    // },
    handleAdd(){
      //点击新增，弹框显示
      this.form={};
      this.dialogFormVisible = true;
      this.btnDisable = false;
    },
    //点击编辑，根据id查询地址对象，赋值
    //校验通过才提交

    handleEdit(row) {
      this.btnDisable = false;
      this.row = row
      this.form.id = row.id;
      getAction(this.url.getById,{id:row.id})
      .then(res=>{
        if (res.success){
          this.form = res.result;
        }
      })
      this.dialogFormVisible = true
    },
    handleImport(){},

    initUserList(){
      getallusers().then(
          res=>{
            if (res.success){
              for(var i=0;i<res.result.records.length;i++){
                var obj = {};
                obj.value = res.result.records[i].id;
                obj.label = res.result.records[i].username;
                this.userlist.push(obj);
              }
            }
          }
      )
    },
    handleDel(row) {
      this.$confirm(`确定删除${row.accountName}`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'success',
        closeOnClickModal: false,
      }).then(() => {
        deleteAction(this.url.delByid, {id:row.id}).then(res => {
          this.$message({
            type: 'success',
            message: res.message
          })
          this.handleQuery()
        })
      }).catch(err=>{
        this.$message.error(err.message);
      })
    },
    handleDefault(row){
      this.$confirm(`确定将${row.accountName}设为默认？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'success',
        closeOnClickModal: false,
      }).then(() => {
        putAction(this.url.setDefault, {id:row.id}).then(res => {
          this.$message({
            type: 'success',
            message: res.message
          })
          this.handleQuery()
        })
      }).catch(err=>{
        this.$message.error(err.message);
      })
    },
    handleQxDefault(row){
      this.$confirm(`确定将${row.accountName}取消默认？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'success',
        closeOnClickModal: false,
      }).then(() => {
        putAction(this.url.qxDefault, {id:row.id}).then(res => {
          this.$message({
            type: 'success',
            message: res.message
          })
          this.handleQuery()
        })
      }).catch(err=>{
        this.$message.error(err.message);
      })
    },
    handleBatApproval() {
      var cname = this.selectedRowKeys;
      this.$confirm(`确定将【${cname}】解冻?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'success',
        closeOnClickModal: false,
      }).then(() => {
        deleteAction(this.url.batchjiedong+"?ids="+this.selectedRowKeys.join(',')).then(res => {
          this.$message({
            type: 'success',
            message: res.message
          })
          this.handleQuery()
        })
      }).catch(err=>{
        this.$message.error(err.message);
      })
    },
    handleBatDel() {
      this.$confirm('确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error',
        closeOnClickModal: false,
      }).then(() => {
        deleteAction(this.url.deleteBatch, {ids: this.selectedRowKeys.join(',')}).then(res => {
          this.$message({
            type: 'success',
            message: res.message
          })
          this.handleSearch()
        })
      }).catch(err=>{
        this.$message.error(err.message);
      })
    },
    handleThaw(row) {
      this.$confirm(`确定解冻${row.name}？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error',
        closeOnClickModal: false,
      }).then(() => {
        deleteAction(this.url.jiedong, {id: row.id}).then(res => {
          this.$message({
            type: 'success',
            message: res.message
          })
          this.handleSearch()
        })
      }).catch(err=>{
        this.$message.error(err.message);
      })
    },
    saveInfo(){
      //获取页面的id
      //如果有id,就编辑
      this.btnDisable = true;
      var cusId = this.$route.query.id;
      this.form.cusId = cusId;
      this.$refs['form'].validate((valid)=>{
        if (valid){
          if (this.form.id){
            //如果有就是编辑
            putAction(this.url.edit,this.form)
                .then(res=>{
                  if (res.success){
                    this.$message.success(res.message);
                    //关闭弹窗，刷新页面
                    this.dialogFormVisible =false;
                    this.handleQuery();
                  }else {
                    this.btnDisable = false;
                  }
                }).catch(err=>{
              this.btnDisable = false;
            })
          }else {
            //新增
            postAction(this.url.add,this.form)
                .then(res=>{
                  if (res.success){
                    this.$message.success(res.message);
                    this.dialogFormVisible=false;
                    this.handleQuery();
                  }else {
                    this.btnDisable = false;
                  }}).catch(err=>{
              this.btnDisable = false;
            })
          }
        }else {
          this.btnDisable = false;
        }
      })

    },
    handleReset(){
      this.queryForm = {}
      this.queryForm.cusId = this.$route.query.id
      this.ipagination.currentPage = 1

      this.handleQuery()
    },
  },
  created() {
    this.initUserList();
    this.queryForm.cusId = this.$route.query.id
  }
}
</script>

<style lang="scss" scoped>

</style>
