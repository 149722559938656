<template>
    <div>
        <el-card class="box-card">
            <el-form class="multiline_select" ref="form" :model="queryForm" label-width="80px" size="medium">
                <el-card class="box-card">
                    <el-row>
                        <el-col :xs="24" :sm="12" :md="12" :lg="6">
                            <el-form-item label="费用名称">
                                <f-select :dict="'ocean_fee'" v-model="queryForm.feeId"
                                          :isNeed="queryForm.feeId"></f-select>
                            </el-form-item>
                        </el-col>

                        <el-col :xs="24" :sm="12" :md="12" :lg="6">
                            <el-form-item label="仓库类型">
                                <f-select :dict="'ocean_warehouse_type'" v-model="queryForm.warehouseType"
                                          :isNeed="queryForm.warehouseType"></f-select>
                            </el-form-item>
                        </el-col>

                        <el-col :xs="24" :sm="12" :md="12" :lg="6">
                            <el-form-item label="国家">
                                <f-select :dict="'ocean_country'" v-model="queryForm.country"
                                          :isNeed="queryForm.country"></f-select>
                            </el-form-item>
                        </el-col>

                        <el-col :xs="24" :sm="12" :md="12" :lg="6">
                            <el-form-item label="订单号">
                                <el-input v-model="queryForm.orderNo"></el-input>
                            </el-form-item>
                        </el-col>

                        <el-col :xs="24" :sm="12" :md="12" :lg="4">
                            <el-form-item label-width="30px">
                                <el-button
                                        type="primary"
                                        icon="el-icon-search"
                                        @click="handleSearch"
                                >查询
                                </el-button
                                >
                                <el-button plain @click="handleReset">重置</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>


                </el-card>
            </el-form>
            <div class="bbb">
                <el-button
                        type="primary"
                        size="medium"
                        icon="el-icon-plus"
                        @click="handleAdd"
                        class="margin_right_10"
                        v-if="buttonShowList.add">新增
                </el-button>
                <el-button
                        type="primary"
                        size="medium"
                        @click="handleDelDuo"
                        class="margin_right_10"
                        :disabled="selectionRows.length===0"
                        v-if="buttonShowList.plsc">批量删除
                </el-button>
                <el-upload
                        class="upload-demo margin_right_10"
                        action=""
                        :http-request="importOperation"
                        :show-file-list="false"
                        :before-upload="beforeUpload"
                        accept=".xls,.xlsx"
                >
                    <el-button type="primary" size="medium">导入</el-button>
                </el-upload>
                <el-button type="primary" size="medium" @click="downloadModel(url.downloadSettleExcel, '海外仓费用模板')">模板
                </el-button>
                <el-button type="primary" size="medium" @click="exportExcelPlus(url.downloadOceanOrderSettleExcel,'海外仓费用数据')">导出
                </el-button>
            </div>
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="73vh"
            >
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'action'">
                        <el-button type="text" @click="handleEdit(scope.row)">编辑</el-button>
                    </template>
                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange">
                </el-pagination>
            </el-row>
        </el-card>
        <el-dialog
                :title="form.id ? '编辑' : '新增'"
                v-if="dialogFormVisible"
                @close="dialogFormVisible = false"
                :visible.sync="dialogFormVisible"
                width="30%"
                center
                :close-on-click-modal="false">
            <el-form :model="form" :rules="rules" ref="form">
                <el-form-item label="单号" prop="orderNo" :label-width="formLabelWidth">
                    <el-input v-model="form.orderNo" maxlength="50" show-word-limit></el-input>
                </el-form-item>

                <el-form-item label="费用名" prop="feeId" :label-width="formLabelWidth">
                    <f-select :dict="'ocean_fee'" v-model="form.feeId" :isNeed="form.feeId"
                              @changet="setFormFeename"></f-select>
                </el-form-item>

                <el-form-item label="仓库类型" prop="warehouseType" :label-width="formLabelWidth">
                    <f-select :dict="'ocean_warehouse_type'" v-model="form.warehouseType"
                              :isNeed="form.warehouseType"></f-select>
                </el-form-item>

                <el-form-item label="仓库名" prop="warehouseName" :label-width="formLabelWidth">
                    <el-input v-model="form.warehouseName" maxlength="100" show-word-limit></el-input>
                </el-form-item>

                <el-form-item label="国家" prop="endType" :label-width="formLabelWidth">
                    <f-select :dict="'ocean_country'" v-model="form.country" :isNeed="form.country"></f-select>
                </el-form-item>

                <el-form-item label="币种" prop="moneyType" :label-width="formLabelWidth">
                    <f-select :dict="'bd_cur'" v-model="form.moneyType" :isNeed="form.moneyType"></f-select>
                </el-form-item>

                <el-form-item label="金额" prop="total" :label-width="formLabelWidth">
                    <el-input v-model="form.total" type="number" @change="caculateExchangeTotal"></el-input>
                </el-form-item>
                <el-form-item label="汇率" prop="rate" :label-width="formLabelWidth" v-if="form.id">
                    <el-input v-model="form.rate" type="number" @change="caculateExchangeTotal"></el-input>
                </el-form-item>
                <el-form-item label="折合金额（CNY）" prop="exchangeTotal" :label-width="formLabelWidth" v-if="form.id">
                    <el-input v-model="form.exchangeTotal" type="number"></el-input>
                </el-form-item>

            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="medium" @click="dialogFormVisible = false">取 消</el-button>
                <el-button size="medium" type="primary" @click="saveImporter">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import QueryMixins from "@/mixins/QueryMixins.js";
    import {deleteAction, postAction,httpAction} from "@/api/manage";
    export default {
        name: "OceanOrderSettle",
        mixins: [QueryMixins],
        components: {},
        data() {
            return {
                isUsePost:true,
                dialogFormVisible: false,
                formLabelWidth: "120px",
                //表单校验
                rules: {
                    feeId: [
                        {required: true, message: "请选择费用", trigger: "change"},
                    ],
                    orderNo: [
                        {required: true, message: "请输入单号", trigger: "change"},
                    ],
                    country: [
                        {required: true, message: "请选择国家", trigger: "change"},
                    ],
                    moneyType: [
                        {required: true, message: "请选择币种", trigger: "change"},
                    ],
                    total: [
                        {required: true, message: "请输入金额", trigger: "change"},
                    ],
                    warehouseType: [
                        {required: true, message: "请选择仓库", trigger: "change"},
                    ],
                },
                visible: false,
                //维护 派送费 价格模板计算 （费用名称，最小值，最大值，单位，fbacode，成本，（美元转cny汇率），基数，公式展示（(成本*汇率)/基数），计算出来的单价），结算对象
                columns: [
                    {
                        type: "selection",
                    },
                    {
                        prop: "action",
                        label: "操作",
                    },
                    {
                        prop: "orderNo",
                        label: "单号",
                    },
                    {
                        prop: "feeName",
                        label: "费用名",
                    },
                    {
                        prop: "warehouseTypeDictText",
                        label: "仓库类型",
                    },
                    {
                        prop: "warehouseName",
                        label: "仓库名",
                    },
                    {
                        prop: "country",
                        label: "国家",
                    },
                    {
                        prop: "moneyType",
                        label: "币种",
                    },
                    {
                        prop: "total",
                        label: "金额",
                    },
                    {
                        prop: "rate",
                        label: "汇率",
                    },
                    {
                        prop: "exchangeTotal",
                        label: "折合金额（CNY）",
                    },
                    {
                        prop: "createByDictText",
                        label: "录入人",
                    },
                    {
                        prop: "createTime",
                        label: "录入时间",
                    },

                ],
                url: {
                    list: "/interface/ocean/settle/querySettleList",
                    addOneSettle: '/interface/ocean/settle/addOneSettle',
                    updateOneSettle: '/interface/ocean/settle/updateOneSettle',
                    delSettleBatch: '/interface/ocean/settle/delSettleBatch',
                    downloadSettleExcel: '/interface/settle/ocean/downloadSettleExcel',
                    importSettle: '/interface/ocean/settle/importSettle',
                    downloadOceanOrderSettleExcel: '/file/export/excel/downloadOceanOrderSettleExcel',
                },


            };
        },
        methods: {
            //汇率 金额 改变 自动计算 折合金额
            caculateExchangeTotal() {
                if (this.form.rate && this.form.total) {
                    let rate = this.form.rate;
                    let total = this.form.total;
                    let exchangeTotal = (parseFloat(rate) * parseFloat(total)).toFixed(2);
                    this.$set(this.form, 'exchangeTotal', exchangeTotal)
                }
            },
            //给费用中文名赋值
            setFormFeename(val) {
                this.$set(this.form, 'feeName', val);
            },
            //导入操作绑定
            importOperation(data) {
                console.log("上传文件", data);
                const formData = new FormData();
                formData.append("file", data.file);
                postAction(this.url.importSettle, formData)
                    .then((res) => {
                        console.log("产品返回数据", res);
                        if (res.success) {
                            this.$message.success(res.message);
                            this.handleQuery();
                        }
                    })
            },
            beforeUpload(file) {
                //对文件大小进行限制
                const isLt2M = file.size / 1024 / 1024 < 15;
                if (!isLt2M) {
                    this.$message.warning("文件大小不能超过15MB");
                }
                const isFile = file.name.indexOf(".") !== -1;
                if (!isFile) {
                    this.$message.warning("该文件名不合法");
                }
                return isLt2M && isFile;
            },
            handleDelDuo() {
                this.$confirm(`确定删除吗`, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "success",
                    closeOnClickModal: false,
                }).then(() => {
                    deleteAction(this.url.delSettleBatch+"?ids="+this.selectedRowKeys).then((res) => {
                        this.$message({
                            type: "success",
                            message: res.message,
                        });
                        this.handleSearch();
                    });
                });
            },
            handleAdd() {
                this.form = {};
                this.dialogFormVisible = true;
            },
            handleEdit(row) {
                for (var name in row) {
                    this.$set(this.form, name, row[name]);
                }
                this.dialogFormVisible = true;
            },
            saveImporter() {
                this.$refs["form"].validate((valid) => {
                    if (valid) {
                        let url = this.url.addOneSettle;
                        let method = 'POST';
                        if (this.form.id) {
                            url = this.url.updateOneSettle;
                            method = "PUT";
                        }
                        httpAction(url, method, this.form)
                            .then((res) => {
                                if (res.success) {
                                    this.$message.success(res.message);
                                    this.dialogFormVisible = false;
                                    this.handleQuery();
                                }
                            });
                    }
                });
            },
        },
      computed:{
        buttonShowList(){
          return {
            'add':this.$btnIsShow('ocean/OceanOrderSettle','0','新增'),
            'plsc':this.$btnIsShow('ocean/OceanOrderSettle','0','批量删除'),

          }
        }
      }
    };
</script>

<style lang="scss" scoped>
    .bbb {
        display: flex;
    }

    .dialog-footer {
        text-align: right !important;
    }

    .margin_right_10 {
        margin-right: 10px;
    }
</style>
