<template>
    <!-- 拼箱杂费标准 -->
    <div class="main-full-content">
        <el-form class="input_top" label-width="80px" size="medium" @submit.native.prevent>
            <el-card class="box-card">
                <el-row>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="费用名称">
                            <el-input v-model.trim="queryForm.feeName" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label-width="30px">
                            <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
                            <el-button plain @click="handleReset">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>
        </el-form>

        <div lass="box-card last_card">
            <div class="f-p-b-15">
                <el-upload action="/" :show-file-list="false" :http-request="uploadSectionFile" accept=".xls,.xlsx"
                    v-if="buttonShowList.dr" style="margin: 0 10px; display: inline">
                    <el-button size="medium" type="primary">导入拼箱杂费</el-button>
                </el-upload>
                <el-button v-if="buttonShowList.add" type="primary" size="medium"
                    @click="downloadModel(url.downloadSpliceBoxCostStandard, '拼箱杂费标准模板')">模板下载
                </el-button>
                <el-button v-if="buttonShowList.del" type="primary" size="medium" :disabled="!selectedRowKeys.length"
                    @click="handleDelete">批量删除
                </el-button>
            </div>
            <f-table v-loading="loading" ref="GTable" border size="medium" row-key="id" class="c-th-has-bgcolor"
                :columns="columns" :data="tableData" @selection-change="handleSelectionChange"
                @sort-change="handleTableChange" :cell-style="{ 'text-align': 'center' }" tableHeight="73vh">
                <template v-slot="scope">
                    <!-- <template v-if="scope.column.property === 'action'">

                    </template> -->
                </template>
            </f-table>
        </div>

        <el-row class="f-text-right f-p-v-8">
            <el-pagination v-bind="ipagination" layout="total, sizes, prev, pager, next, jumper"
                @size-change="handleSizeChange" @current-change="handleCurrentChange">
            </el-pagination>
        </el-row>
    </div>
</template>

<script>
import QueryMixins from "@/mixins/QueryMixins.js";
import { getAction, postAction, deleteAction } from "@/api/manage";
export default {
    mixins: [QueryMixins],
    data() {
        return {
            columns: [
                {
                    type: "selection",
                },
                {
                  prop: "country",
                  label: "国家",
                },
                {
                    prop: "serveType",
                    label: "服务类型",
                },
                {
                    prop: "feeType",
                    label: "费用类型",
                },
                {
                    prop: "feeManageDept",
                    label: "费用管理部门",
                },
                {
                    prop: "feeName",
                    label: "费用中文名",
                },
                {
                    prop: "receivable",
                    label: "应收",
                },
                {
                    prop: "settlement",
                    label: "销售结算",
                },
                {
                    prop: "currency",
                    label: "币种",
                },
                {
                    prop: "unit",
                    label: "单位",
                },
                {
                    prop: "minAmount",
                    label: "低消",
                },
                {
                    prop: "remark",
                    label: "备注",
                },
                {
                    prop: "withoutTax",
                    label: "不含税",
                },
                {
                    prop: "withTax",
                    label: "含税",
                },
                {
                    prop: "rates",
                    label: "收费标准/说明",
                },
                {
                    prop: "receivableInputPerson",
                    label: "应收录入人",
                },
                {
                    prop: "receivableInputTime",
                    label: "应收录入时效",
                },
                {
                    prop: "receivableInputRemark",
                    label: "应收录入备注",
                },
                {
                    prop: "settlementInputPerson",
                    label: "销售结算录入人",
                    width: '120px'
                },
                {
                    prop: "settlementSyncReceivable",
                    label: "销售结算是否同步应收",
                },
                {
                    prop: "settlementInputTime",
                    label: "销售结算录入时效",
                },
                {
                    prop: "settlementInputAdjustRemark",
                    label: "销售结算录入调整备注",
                    width: '150px'
                },
                {
                    prop: "needPayInputPerson",
                    label: "应付录入人",
                },
                {
                    prop: "needPaySyncInput",
                    label: "应付是否同步录入",
                },
                {
                    prop: "needPayInputTime",
                    label: "应付录入时效",
                },
                {
                    prop: "effectiveDate",
                    label: "生效时间",
                },
                {
                    prop: "updateDate",
                    label: "修改时间",
                },
                {
                  prop: "createTime",
                  label: "导入时间",
                },
                {
                  prop: "createBy",
                  label: "导入人",
                }
            ],
            url: {
                list: "/sys/product/common/getSpliceBoxCostStandardList",
                batchDeleteSpliceBoxCostStandard: "/sys/product/common/batchDeleteSpliceBoxCostStandard",
                downloadSpliceBoxCostStandard: "/file/download/model/downloadSpliceBoxCostStandard",
                importSpliceBoxCostStandard: "/sys/product/common/importSpliceBoxCostStandard"
            },
            isUsePost: true,
        };
    },
    methods: {
        handleDelete() {
            postAction(this.url.batchDeleteSpliceBoxCostStandard, this.selectedRowKeys).then((res) => {
                this.$message({
                    type: "success",
                    message: res.message,
                });
                this.handleSearch();
            });
        },
        uploadSectionFile(param) {
            //file就是当前添加的文件
            var fileObj = param.file;
            //FormData 对象
            var form = new FormData();
            //文件对象，key是后台接受的参数名称
            form.append("file", fileObj);
            //执行上传方法
            console.log("执行上传方法");
            postAction(this.url.importSpliceBoxCostStandard, form)
                .then((res) => {
                    //这里做上传之后的操作
                    if (res.success) {
                        this.$message.success(res.message);
                        this.handleQuery();
                    } else {
                        this.handleQuery();
                    }
                })
        },
    },
    computed: {
        buttonShowList() {
            return {
                'add': this.$btnIsShow('product/LCLStandard', '0', '模板下载'),
                'del': this.$btnIsShow('product/LCLStandard', '0', '批量删除'),
                'dr': this.$btnIsShow('product/LCLStandard', '0', '导入'),
            }
        }
    }
};
</script>

<style></style>
