<template>
  <div class="main-full-content">

    <el-form class="multiline_select" ref="form" :model="queryForm" size="medium" label-width="auto">
      <el-card class="box-card">
        <el-row class="inputs_row">
          <el-col :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label="派送操作">
              <f-select v-model="queryForm.endOperatorId" :is-need="queryForm.endOperatorId" :data-list="queryDictCodeListMap['sys_user_id']"
                        @keyup.enter.native="handleSearch"></f-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label="国家">
              <f-select v-model="queryForm.country" :is-need="queryForm.country" :data-list="queryDictCodeListMap['bd_country']"
                        @keyup.enter.native="handleSearch"></f-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label="目的港">
              <f-select v-model="queryForm.podEnd" :is-need="queryForm.podEnd" :data-list="queryDictCodeListMap['bd_port']"
                        @keyup.enter.native="handleSearch"></f-select>
            </el-form-item>
          </el-col>

          <el-col :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label="专线拆柜">
              <f-select v-model="queryForm.cabinetCategoryList" :is-need="queryForm.cabinetCategoryList"
                        :data-list="queryDictCodeListMap['cabinet_category']"
                        @keyup.enter.native="handleSearch" :multiple='true'></f-select>
            </el-form-item>
          </el-col>

          <el-col :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label="发提柜邮件">
              <f-select v-model="queryForm.isSendHoldCabinetEmail" :is-need="queryForm.isSendHoldCabinetEmail"
                        :dict="'unit_whether'"
                        @keyup.enter.native="handleSearch"></f-select>
            </el-form-item>
          </el-col>

          <el-col :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label="清关状态">
              <f-select v-model="queryForm.customsClearanceStatusList" :is-need="queryForm.customsClearanceStatusList"
                        :data-list="queryDictCodeListMap['container_customs_clearance_status']"
                        @keyup.enter.native="handleSearch" :multiple='true'></f-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="8">
            <el-form-item label="周期">
              <el-input v-model="queryForm.week"
                        @keyup.enter.native="handleSearch"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label="船名航次">
              <el-input v-model="queryForm.shipNo"
                        @keyup.enter.native="handleSearch"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label="拆柜仓库">
              <f-select v-model="queryForm.dismantleAgentId" :is-need="queryForm.dismantleAgentId"
                        :data-list="queryDictCodeListMap['dismantleAgent_id']"
                        @keyup.enter.native="handleSearch"></f-select>
            </el-form-item>
          </el-col>

          <el-col :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label="发派送邮件">
              <f-select v-model="queryForm.isSendDeliveryEmail" :is-need="queryForm.isSendDeliveryEmail"
                        :dict="'unit_whether'"
                        @keyup.enter.native="handleSearch"></f-select>
            </el-form-item>
          </el-col>

          <el-col :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label="提柜状态">
              <el-select v-model="queryForm.isHoldCabinet" filterable clearable

                         @keyup.enter.native="handleSearch">
                <el-option value="0" label="未提柜"></el-option>
                <el-option value="1" label="已提柜"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="8">
            <el-form-item label="柜号">
              <el-input v-model="queryForm.containerNo" type="textarea" class="textarea_table"
                        @keyup.enter.native="handleSearch"></el-input>
            </el-form-item>
          </el-col>
          <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="8">
            <el-form-item label="ETA">
              <div class="f-flex">
                <el-date-picker
                    class="f-flex-1"
                    v-model="queryForm.etaStart"
                    type="datetime"
                    size="small"
                    placeholder="开始时间"
                    default-time="00:00:00"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    @keyup.enter.native="handleSearch"
                >
                </el-date-picker>
                <span class="f-p-h-2">~</span>
                <el-date-picker
                    class="f-flex-1"
                    v-model="queryForm.etaEnd"
                    type="datetime"
                    size="small"
                    placeholder="结束时间"
                    default-time="23:59:59"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    @keyup.enter.native="handleSearch"
                >
                </el-date-picker>
              </div>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label="更新放货邮件">
              <f-select v-model="queryForm.isSendReleaseEmail" :is-need="queryForm.isSendReleaseEmail"
                        :dict="'unit_whether'"
                        @keyup.enter.native="handleSearch"></f-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label="还柜状态">
              <el-select v-model="queryForm.isHuangui" filterable clearable

                         @keyup.enter.native="handleSearch">
                <el-option value="0" label="未还柜"></el-option>
                <el-option value="1" label="已还柜"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="8">
            <el-form-item label="订单号">
              <el-input v-model="queryForm.orderNo" type="textarea" class="textarea_table"
                        @keyup.enter.native="handleSearch"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="8">
            <el-form-item label="到港日期">
              <div class="f-flex">
                <el-date-picker
                    class="f-flex-1"
                    v-model="queryForm.arrivalAtPortTimeStart"
                    type="datetime"
                    size="small"
                    placeholder="开始时间"
                    default-time="00:00:00"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    @keyup.enter.native="handleSearch"
                >
                </el-date-picker>
                <span class="f-p-h-2">~</span>
                <el-date-picker
                    class="f-flex-1"
                    v-model="queryForm.arrivalAtPortTimeEnd"
                    type="datetime"
                    size="small"
                    placeholder="结束时间"
                    default-time="23:59:59"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    @keyup.enter.native="handleSearch"
                >
                </el-date-picker>
              </div>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label="分税状态">
              <f-select v-model="queryForm.taxSharingStatusList" :is-need="queryForm.taxSharingStatusList"
                        :data-list="queryDictCodeListMap['tax_sharing_status']"
                        @keyup.enter.native="handleSearch" :multiple='true'></f-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label="派送状态">
              <f-select v-model="queryForm.deliveryStatusList" :is-need="queryForm.deliveryStatusList"
                        :data-list="queryDictCodeListMap['container_delivery_status']"
                        @keyup.enter.native="handleSearch"  :multiple='true'></f-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="8">
            <el-form-item label="ETD">
              <div class="f-flex">
                <el-date-picker
                    class="f-flex-1"
                    v-model="queryForm.etdStart"
                    type="datetime"
                    size="small"
                    placeholder="开始时间"
                    default-time="00:00:00"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    @keyup.enter.native="handleSearch"
                >
                </el-date-picker>
                <span class="f-p-h-2">~</span>
                <el-date-picker
                    class="f-flex-1"
                    v-model="queryForm.etdEnd"
                    type="datetime"
                    size="small"
                    placeholder="结束时间"
                    default-time="23:59:59"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    @keyup.enter.native="handleSearch"
                >
                </el-date-picker>
              </div>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="8">
            <el-form-item label="提柜日期">
              <div class="f-flex">
                <el-date-picker
                    class="f-flex-1"
                    v-model="queryForm.holdCabinetTimeStart"
                    type="datetime"
                    size="small"
                    placeholder="开始时间"
                    default-time="00:00:00"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    @keyup.enter.native="handleSearch"
                >
                </el-date-picker>
                <span class="f-p-h-2">~</span>
                <el-date-picker
                    class="f-flex-1"
                    v-model="queryForm.holdCabinetTimeEnd"
                    type="datetime"
                    size="small"
                    placeholder="结束时间"
                    default-time="23:59:59"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    @keyup.enter.native="handleSearch"
                >
                </el-date-picker>
              </div>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label="到港状态">
              <el-select v-model="queryForm.isArrived" filterable clearable

                         @keyup.enter.native="handleSearch">
                <el-option value="0" label="未到港"></el-option>
                <el-option value="1" label="已到港"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label="复核分税">
              <f-select v-model="queryForm.isNormalTax"
                        :is-need="queryForm.isNormalTax"
                        :dict="'unit_normal'"
                         @keyup.enter.native="handleSearch">
              </f-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label="POD状态">
              <f-select v-model="queryForm.podStatusList" :is-need="queryForm.podStatusList" :data-list="queryDictCodeListMap['container_pod_status']"
                        @keyup.enter.native="handleSearch" :multiple='true'></f-select>
            </el-form-item>
          </el-col>

          <el-col :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label-width="30px">
              <el-button
                  type="primary"
                  icon="el-icon-search"
                  @click="handleSearch"
              >查询
              </el-button
              >
              <el-button plain @click="handleReset">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
    </el-form>
    <el-card class="box-card">

      <div class="f-p-b-15">
        <el-button
            v-if="buttonShowList.ftgyj"
            type="primary"
            size="medium"
            @click="openEmailDialog('1','发送提柜邮件')"
            :disabled="selectionRows.length !== 1 "
        >发提柜邮件
        </el-button>

        <el-button
            v-if="buttonShowList.fpsyj"
            type="primary"
            size="medium"
            @click="openEmailDialog('2','发送派送邮件')"
            :disabled="selectionRows.length !== 1 "
        >发派送邮件
        </el-button>
        <!--          todo 更新放货邮件-->
        <el-button
            v-if="buttonShowList.gxfhyj"
            type="primary"
            size="medium"
            @click="openEmailDialog('3','更新放货邮件')"
            :disabled="selectionRows.length !== 1 "
        >更新放货邮件
        </el-button>
         <el-button type="primary" @click="batchClick('0')"  :disabled="!selectionRows.length"  v-if="buttonShowList.pldzzg">批量到中转港
        </el-button>
        <el-button type="primary" @click="batchClick('1')" :disabled="!selectionRows.length"  v-if="buttonShowList.plshc">批量上火车
        </el-button>
        <el-button
            v-if="buttonShowList.pldg"
            type="primary"
            size="medium"
            @click="handArrivalAtPort(null)"
            :disabled="selectionRows.length === 0 "
        >批量到港
        </el-button>
        <el-button
            v-if="buttonShowList.kb"
            type="primary"
            size="medium"
            @click="handKaoBo()"
            :disabled="selectionRows.length === 0 "
        >批量靠泊
        </el-button>
        <el-button
            v-if="buttonShowList.plxc"
            type="primary"
            size="medium"
            @click="handXieChuan()"
            :disabled="selectionRows.length === 0 "
        >批量卸船
        </el-button>

        <el-button type="primary" :disabled="selectionRows.length === 0"
                   v-if="buttonShowList.pltg" @click="handMentionArkForm(null)">批量提柜
        </el-button>
        <el-button @click="pilianggengxin(null,'3')"
                   type="primary" :disabled="selectionRows.length === 0"
                   v-if="buttonShowList.hg">
          批量还柜
        </el-button>
        <el-button type="primary" :disabled="selectionRows.length === 0"
                   v-if="buttonShowList.tx" @click="tieReminder">特别提醒
        </el-button>
        <el-button type="primary"
                   v-if="buttonShowList.dcbb" @click="exportExcelPlus(url.exportUrl,'派送柜子报表')">导出报表
        </el-button>
      </div>
      <div class="c-alert f-m-b-10">
        已选择 <span class="stress">{{ this.selectedRowKeys.length }}</span> 项
        <span
            class="stress f-m-l-12 f-cursor-pointer"
            @click="handleClearSelection"
        >清空</span
        >
      </div>

      <f-table
          v-loading="loading"
          ref="GTable"
          border
          size="medium"
          row-key="id"
          class="c-th-has-bgcolor"
          :columns="columns"
          :data="tableData"
          @selection-change="handleSelectionChange"
          @sort-change="handleTableChange"
          :cell-style="{ 'text-align': 'center' }"
          tableHeight="73vh"
      >
        <template v-slot="scope">
          <template v-if="scope.column.property === 'action'">
            <el-button type="text" @click="handArrivalAtPort(scope.row)" v-if="buttonShowList.dg">到港</el-button>
            <el-button type="text" @click="handXieChuan(scope.row.id)" v-if="buttonShowList.xc">卸船</el-button>
            <el-button type="text" @click="handMentionArkForm(scope.row)"
                       v-if="scope.row.circulationState === '4' && buttonShowList.tg"
            >提柜
            </el-button>
            <el-button @click="pilianggengxin(scope.row,'3')"
                       type="text"
                       v-if="buttonShowList.hg">
              还柜
            </el-button>
            <el-button type="text" @click="handCustomsClearance(scope.row)" v-if="buttonShowList.gwqg">国外清关
            </el-button>
            <el-button type="text" @click="handClearanceResults(scope.row)"
                       v-if="scope.row.customsClearanceLocation === '0' || scope.row.customsClearanceType === '0' && buttonShowList.gwqgjg">
              国外清关结果
            </el-button>
            <el-button type="text" @click="handleOpenWithinRemark(scope.row)"
                       v-if="buttonShowList.bz">
              备注
            </el-button>
            <el-button
                type="text"
                v-if="buttonShowList.fssdyj && ['美国','澳大利亚'].indexOf(scope.row.country) !== -1"
                @click="fasongshuidan(scope.row)"
            >
              {{
                (scope.row.isSendShuidanEmail == '1' && scope.row.country == '澳大利亚') ? '重发税单邮件' : '发送税单邮件'
              }}
            </el-button>

            <el-button type="text" @click="openReviewTaxDialog(scope.row)"
                       v-if="buttonShowList.fhfs">
              复核分税
            </el-button>
            <el-button
                type="text"
                v-if="buttonShowList.cksdyj && ['美国','澳大利亚'].indexOf(scope.row.country) !== -1"
                @click="chakanyoujian(scope.row)"
            >查看税单邮件
            </el-button>

            <el-button type="text" @click="$intoOperationCabinetPayPage(scope.row.id)"
                       v-if="buttonShowList.yfzdxzpswj">
              应付账单
            </el-button>
            <el-button type="text"
                       @click="downloadExcelPost(url.exportDeliveryDocTwo, scope.row.containerNo + '-派送文件',{ids:[scope.row.id]})"
                       v-if="buttonShowList.下载派送文件2">
              下载派送文件2
            </el-button>
          </template>
          <template v-else-if="scope.column.property === 'containerNo'">
            <a
                style="color: #00a0e9"
                @click.prevent="tiaojinHandout(scope.row)"
            >{{ scope.row.containerNo }}</a
            >
          </template>
          <template v-else-if="scope.column.property === 'holdStatus'">
            <span
                :style="scope.row.holdStatus && scope.row.holdStatus.indexOf('部分') !==-1 ?' color: red' : ''">{{
                scope.row.holdStatus
              }}</span>
          </template>
          <template v-else-if="scope.column.property === 'week'">
            <span style="color: #00a0e9"
                  @click="$intoOperationCabinetEditPage(scope.row.id)">{{ scope.row.week }}
          </span>
          </template>
          <template v-else-if="scope.column.property === 'withinRemark'">
            <span v-if="!checkStrOverLength(scope.row.withinRemark,20)">{{ scope.row.withinRemark }}</span>

              <el-popover
                  v-else
                  placement="top-start"
                  width="200"
                  trigger="hover"
                  :content="scope.row.withinRemark">
                <span slot="reference">{{subStrFromLength(scope.row.withinRemark,20)}}</span>
              </el-popover>

          </template>
          <template v-else-if="scope.column.property === 'taxSharingStatusStr'">
            <el-tag :type="$getTaxSharingStatusStrElTagTypeByStr(scope.row.taxSharingStatusStr)">{{scope.row.taxSharingStatusStr}}</el-tag>
          </template>
          <template v-else-if="scope.column.property === 'isNormalTax'">
            <span> {{parseDict2(scope.row.isNormalTax, 'unit_normal')}} </span>
          </template>
        </template>
      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination
            v-bind="ipagination"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange">
        </el-pagination>
      </el-row>
    </el-card>
    <el-dialog
        :title="(isBatchType == 'PickUpCabinet' ? '批量' : '') + '提柜'"
        :visible.sync="dialogAddFormMentionArkVisible"
        :close-on-click-modal="false"
        append-to-body
    >
      <el-form :model="PickUpCabinetForm" ref="PickUpCabinetForm" :rules="tiguiRules">
        <el-row>
          <el-col :span="24">
            <el-form-item
                label="提柜时间"

                prop="holdCabinetTime"
            >
              <el-date-picker
                  class="f-flex-1"
                  v-model="PickUpCabinetForm.holdCabinetTime"
                  type="datetime"
                  size="small"
                  placeholder="开始时间"
                  default-time="00:00:00"
                  value-format="yyyy-MM-dd HH:mm:ss"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <!--                    <el-col :span="9">-->
          <!--                        <el-form-item label="清关查验预估时间(周)" :label-width="formLabelWidth" prop="checkWeek" v-if="this.isCheekWeek" >-->
          <!--                            <el-input v-model="PickUpCabinetForm.checkWeek" type="number" :disabled="true"></el-input>-->
          <!--                        </el-form-item>-->
          <!--                    </el-col>-->
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="medium" @click="dialogAddFormMentionArkVisible = false"
        >取 消
        </el-button
        >
        <el-button size="medium" type="primary" @click="handMentionArk">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog :title="(isBatchType == 'ArrivalAtPort' ? '批量' : '') + '到港'" :visible.sync="ArrivalAtPortFromVisible"
               :close-on-click-modal="false" append-to-body>
      <el-form :model="ArrivalAtPortFrom" ref="ArrivalAtPortFrom" :rules="rules">
        <el-form-item v-if="isBatchType !== 'ArrivalAtPort'" label="ETA" :label-width="formLabelWidth" prop="eta">
          <el-date-picker
              class="f-flex-1"
              v-model="ArrivalAtPortFrom.eta"
              type="date"
              size="small"
              placeholder="请选择"
              default-time="00:00:00"
              value-format="yyyy-MM-dd HH:mm:ss"
              :disabled="true"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item label="到港 OR 延误" :label-width="formLabelWidth" prop="arrivalAtPortType">
          <el-select
              filterable
              class="f-full-width"
              v-model="ArrivalAtPortFrom.arrivalAtPortType"
              placeholder="请选择"
          >
            <el-option key="0" label="到港" value="0"></el-option>
            <el-option key="1" label="中转延误" value="1"></el-option>
            <el-option key="2" label="目的港延误" value="2"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="港口" :label-width="formLabelWidth" prop="arriveType"
                      v-if="ArrivalAtPortFrom.arrivalAtPortType == 0">
          <template>
            <el-radio-group v-model="ArrivalAtPortFrom.arriveType">
              <el-radio :label="'0'">中转港</el-radio>
              <el-radio :label="'1'">目的港</el-radio>
            </el-radio-group>
          </template>
        </el-form-item>

        <el-form-item label="二程开船时间" :label-width="formLabelWidth" prop="secondSailTime"
                      v-if="ArrivalAtPortFrom.arrivalAtPortType == 0 && ArrivalAtPortFrom.arriveType==0">
          <el-date-picker
              class="f-flex-1"
              v-model="ArrivalAtPortFrom.secondSailTime"
              type="datetime"
              size="small"
              placeholder="开始时间"
              default-time="00:00:00"
              value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="二程到港时间" :label-width="formLabelWidth" prop="secondEtaTime"
                      v-if="ArrivalAtPortFrom.arrivalAtPortType == 0 && ArrivalAtPortFrom.arriveType==0">
          <el-date-picker
              class="f-flex-1"
              v-model="ArrivalAtPortFrom.secondEtaTime"
              type="datetime"
              size="small"
              placeholder="开始时间"
              default-time="00:00:00"
              value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item
            label="清关地点"
            :label-width="formLabelWidth"
            prop="customsClearanceLocation"
            v-if="ArrivalAtPortFrom.arrivalAtPortType == 0 && ArrivalAtPortFrom.arriveType==1"
        >
          <el-select
              filterable
              class="f-full-width"
              v-model="ArrivalAtPortFrom.customsClearanceLocation"
              placeholder="请选择"
          >
            <el-option key="0" label="港口" value="0"></el-option>
            <el-option key="1" label="监管仓" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
            label="实际到港时间"
            :label-width="formLabelWidth"
            prop="arrivalAtPortTime"
            v-if="ArrivalAtPortFrom.arrivalAtPortType == 0 && ArrivalAtPortFrom.arriveType==1"
        >
          <el-date-picker
              class="f-flex-1"
              v-model="ArrivalAtPortFrom.arrivalAtPortTime"
              type="datetime"
              size="small"
              placeholder="开始时间"
              default-time="00:00:00"
              value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item
            label="卸船时间"
            :label-width="formLabelWidth"
            prop="unShipTime"
            v-if="ArrivalAtPortFrom.arrivalAtPortType == 1"
        >
          <el-date-picker
              class="f-flex-1"
              v-model="ArrivalAtPortFrom.unShipTime"
              type="datetime"
              size="small"
              placeholder="开始时间"
              default-time="00:00:00"
              value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item
            label="延误原因"
            :label-width="formLabelWidth"
            prop="customsClearanceLocation"
            v-if="ArrivalAtPortFrom.arrivalAtPortType == 1"
        >

          <f-select
              v-model="ArrivalAtPortFrom.customsClearanceLocation"
              :data-list="queryDictCodeListMap['yanchiReason']"
              :isNeed="ArrivalAtPortFrom.customsClearanceLocation"
          >
          </f-select>
        </el-form-item>
        <el-form-item
            label="上火车周数"
            :label-width="formLabelWidth"
            prop="trainWeek"
            v-if="ArrivalAtPortFrom.arrivalAtPortType == 1"
        >
          <el-input v-model="ArrivalAtPortFrom.trainWeek"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="medium" @click="ArrivalAtPortFromVisible = false"
        >取 消
        </el-button>
        <el-button size="medium" type="primary" @click="saveArrivalAtPort('1')"
        >确 定
        </el-button>
      </div>
    </el-dialog>
    <el-dialog :title="(isBatchType == 'XieChuan' ? '批量' : '') + '卸船'" :visible.sync="XieChuanFromVisible"
               :close-on-click-modal="false" append-to-body>
      <el-form :model="form" ref="xieChuanFrom" :rules="rules">
        <el-form-item label="卸船时间" :label-width="formLabelWidth" prop="shipUnloading">
          <el-date-picker
              class="f-flex-1"
              v-model="form.shipUnloading"
              type="datetime"
              size="small"
              placeholder="开始时间"
              default-time="00:00:00"
              value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="medium" @click="XieChuanFromVisible = false">取 消</el-button>
        <el-button size="medium" type="primary" @click="xieChuanSave()">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog :title="isBatchType === 'kbb' ? '批量' : '' + '靠泊'" :visible.sync="KaoBoFromVisible"
               :close-on-click-modal="false" append-to-body>
      <el-form :model="form" ref="kaoBoFrom" :rules="rules">
        <el-form-item label="船靠泊时间" :label-width="formLabelWidth" prop="berthingTime">
          <el-date-picker
              class="f-flex-1"
              v-model="form.berthingTime"
              type="datetime"
              size="small"
              placeholder="开始时间"
              default-time="00:00:00"
              value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="medium" @click="KaoBoFromVisible = false">取 消</el-button>
        <el-button size="medium" type="primary" @click="kaoBoSave()">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="国外清关" :visible.sync="CustomsClearanceFromVisible" :close-on-click-modal="false"
               :before-close="handleBeforeClose" append-to-body>
      <el-form
          :model="ArrivalAtPortFrom"
          ref="guowaiForm"
          :rules="rules"
      >
        <el-form-item
            label="清关类型"
            :label-width="formLabelWidth"
            prop="customsClearanceType"
        >
          <el-select
              filterable
              class="f-full-width"
              v-model="ArrivalAtPortFrom.customsClearanceType"
              placeholder="请选择"
          >
            <el-option key="0" label="预清关出税（到港前清关）" value="0">
            </el-option>
            <el-option key="1" label="国外清关（到港后清关）" value="1">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
            label="清关地点"
            :label-width="formLabelWidth"
            prop="customsClearanceLocation"
            v-if="ArrivalAtPortFrom.customsClearanceType == '1'"
        >
          <el-select
              filterable
              class="f-full-width"
              v-model="ArrivalAtPortFrom.customsClearanceLocation"
              placeholder="请选择"
          >
            <el-option key="0" label="港口" value="0"></el-option>
            <el-option key="1" label="监管仓" value="1"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="medium" @click="CustomsClearanceFromVisible = false"
        >取 消
        </el-button>
        <el-button size="medium" type="primary" :loading="CustomsClearanceLoad" @click="saveArrivalAtPort('3')"
        >{{ CustomsClearanceLoad ? '正在处理' : '确 定' }}
        </el-button>
      </div>
    </el-dialog>

    <el-dialog :title="(isBatchType == 'ClearanceResults' ? '批量' : '') + '国外清关结果'"
               :visible.sync="ClearanceResultsFrowVisible" :close-on-click-modal="false" append-to-body>
      <el-form :model="ArrivalAtPortFrom" ref="clearanceResultsFrom" :rules="rules">
        <el-form-item label="清关结果" :label-width="formLabelWidth" prop="customsClearanceResult">
          <f-select
              v-model="ArrivalAtPortFrom.customsClearanceResult"
              :data-list="queryDictCodeListMap['clearAgent_result']"
              :is-need="ArrivalAtPortFrom.customsClearanceResult"
          ></f-select>
        </el-form-item>
        <el-form-item label="清关放行时间" :label-width="formLabelWidth" prop="clearTime"
                      v-if="ArrivalAtPortFrom.customsClearanceResult == '4' ">
          <el-date-picker
              class="f-flex-1"
              v-model="ArrivalAtPortFrom.clearTime"
              type="datetime"
              size="small"
              placeholder="开始时间"
              default-time="00:00:00"
              value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="查验类型" :label-width="formLabelWidth" prop="checkTheType"
                      v-if="ArrivalAtPortFrom.customsClearanceResult == '5'">
          <el-select
              filterable
              class="f-full-width"
              v-model="ArrivalAtPortFrom.checkTheType"
              placeholder="请选择"
          >
            <el-option key="0" label="文件审查" value="0"></el-option>
            <el-option key="1" label="X光查验" value="1"></el-option>
            <el-option key="2" label="人工查验" value="2"></el-option>
            <el-option key="3" label="其它" value="3"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item
            label="清关查验预估时间(周)"
            :label-width="formLabelWidth"
            prop="checkWeek"
            v-if="ArrivalAtPortFrom.customsClearanceResult == '5'"
        >
          <el-input v-model="ArrivalAtPortFrom.checkWeek" type="number"></el-input>
        </el-form-item>
        <el-form-item
            label="查验单"
            :label-width="formLabelWidth"
            prop="checkedFileList"
            v-if="ArrivalAtPortFrom.customsClearanceResult == '5'"
        >
          <f-upload
              class="f-full-width"
              v-model="ArrivalAtPortFrom.checkedFileList"
              :isUseOss="true"
          ></f-upload>
        </el-form-item>

        <el-form-item label="查验结果" :label-width="formLabelWidth" prop="abroadClearResult"
                      v-if="ArrivalAtPortFrom.customsClearanceResult=='6'">
          <f-select
              v-model="ArrivalAtPortFrom.abroadClearResult"
              :data-list="queryDictCodeListMap['abroad_clear_result']"
              :is-need="ArrivalAtPortFrom.abroadClearResult"
          ></f-select>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="medium" @click="ClearanceResultsFrowVisible = false"
        >取 消
        </el-button>
        <el-button size="medium" type="primary" @click="saveArrivalAtPort('2')"
        >确 定
        </el-button>
      </div>
    </el-dialog>

    <send-shuidan-email-log
        :visible="emailVisible"
        :container-id="containerId"
        :country-tax-type="countryTaxType"
        :week="week"
        :initRow="row"
        @ok="close"
        @handleReSend="handleReSend"
    ></send-shuidan-email-log>

    <review-tax-dialog
        :visible="reviewTaxDialogVisible"
        :container-id="reviewTaxDialogContainerId"
        :week="reviewTaxDialogWeek"
        :importer-dict-list="queryDictCodeListMap['order_importer']"
        @ok="closeReviewTaxDialog"
    ></review-tax-dialog>

    <delivery-container-email-dialog
        :visible="sendEmailVisible"
        :container-id="sendEmailContainerId"
        :container-no="sendEmailContainerNo"
        :type="sendEmailType"
        :title="sendEmailTitle"
        :father-form="sendEmailForm"
        @close="closeEmailDialog"
    ></delivery-container-email-dialog>
    <el-dialog
        :title="'发送税单邮件-' + (isReTaxEmail ? (row && row.orderNo) : (row && row.week))"
        :visible="sendTaxEmailVisible"
        width="30%"
        custom-class="c-custom-dialog"
        :before-close="handleClose"
    >

      <el-form ref="taxEmailForm" :model="taxEmailForm" label-width="80px" size="medium" :rules="tTaxEmailRules">
        <el-row class="inputs_row">
          <el-col :span="24">
            <el-form-item label="海运费（AUD）" prop="totalDeclared">
              <el-input v-model="taxEmailForm.totalDeclared" type="number" :disabled="true"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="保费" prop="ons">
              <el-input v-model="taxEmailForm.ons" type="number" :disabled="true"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="汇率" prop="excRate">
              <el-input v-model="taxEmailForm.excRate" type="number"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="medium" @click="sendTaxEmailVisible = false">取 消</el-button>
        <el-button size="medium" type="primary" @click="handleTaxEmail">发 送</el-button>
      </div>
    </el-dialog>

    <el-dialog title="特别提醒"
               :visible.sync="dialogTieReminderVisible"
               :close-on-click-modal="false"
               v-if="dialogTieReminderVisible">
      <el-form :model="tieForm" ref="tieForm" :rules="tiRules">
        <el-form-item label="标题" :label-width="formLabelWidth" prop="title">
          <el-input v-model="tieForm.title"></el-input>
        </el-form-item>
        <el-form-item label="提醒事项" :label-width="formLabelWidth" prop="remark">
          <el-input v-model="tieForm.remark" type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="文件类型" :label-width="formLabelWidth" prop="fileType">
          <f-select :data-list="queryDictCodeListMap['os_order_filelist']" v-model="tieForm.fileType"
                    :isNeed="tieForm.fileType"></f-select>
        </el-form-item>
        <el-form-item label="提醒文件" :label-width="formLabelWidth" prop="fileList">
          <f-upload v-model="tieForm.fileList" :dir="'osOrder'" :isUseOss="true"></f-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogTieReminderVisible = false">取 消</el-button>
        <el-button type="primary" @click="sendTieReminderEmail">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog
        title="备注"
        :visible.sync="remarkVisible"
        :close-on-click-modal="false"
        append-to-body
    >
      <el-form :model="remarkForm" ref="remarkForm" :rules="remarkRules">
        <el-row>
          <el-col :span="24">
            <el-form-item
                label="备注"

                prop="withinRemark"
            >
              <el-input
                  type="textarea"
                  maxlength="500"
                  show-word-limit
                  v-model="remarkForm.withinRemark"
              ></el-input>
            </el-form-item>
          </el-col>

        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="medium" @click="remarkVisible = false"
        >取 消
        </el-button
        >
        <el-button size="medium" type="primary" @click="handleRemark">确 定</el-button>
      </div>
    </el-dialog>



    <el-dialog
        :title="batchTitle"
        :visible.sync="visible"
        :close-on-click-modal="false"
        append-to-body
        width="40%"
    >
      <el-form :model="batchForm" ref="batchForm" :rules="batchFormRules">
        <el-row>
          <el-col :span="24">
            <el-form-item
                label="预计到港时间"
                prop="eta"
                v-if="batchTitle == '批量上火车'"
            >
            <el-date-picker
                                        class="f-flex-1"
                                        v-model="batchForm.eta"
                                        type="datetime"
                                        size="small"
                                        default-time="00:00:00"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                        placeholder="请选择时间"
                                >
                                </el-date-picker>
            </el-form-item>
              <el-form-item
                label="上火车时间"
                prop="trainTime"
               v-if="batchTitle == '批量上火车'"
            >
            <el-date-picker
                                        class="f-flex-1"
                                        v-model="batchForm.trainTime"
                                        type="datetime"
                                        size="small"
                                        default-time="00:00:00"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                        placeholder="请选择时间"
                                >
                                </el-date-picker>
            </el-form-item>
                <el-form-item
                label="实际到中转港时间"
                prop="actualTransitPortTime"
                v-if="batchTitle == '批量到中转港'"
            >
            <el-date-picker
                                        class="f-flex-1"
                                        v-model="batchForm.actualTransitPortTime"
                                        type="datetime"
                                        size="small"
                                        default-time="00:00:00"
                                        placeholder="请选择时间"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                >
                                </el-date-picker>
            </el-form-item>
          </el-col>

        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="medium" @click="BatchClose"
        >取 消
        </el-button
        >
        <el-button size="medium" type="primary" @click="handleBatch">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import QueryMixins from "@/mixins/QueryMixins.js";
import {postAction, getAction, putAction, httpAction} from "@/api/manage";
import sendShuidanEmailLog from "./cabinet/SendShuidanEmailLog.vue";
import reviewTaxDialog from "./cabinet/ReviewTaxDialog.vue";
import deliveryContainerEmailDialog from "./cabinet/deliveryContainerEmailDialog.vue";
export default {
  name: "DispatchVerification",
  mixins: [QueryMixins],
  components: {sendShuidanEmailLog,reviewTaxDialog,deliveryContainerEmailDialog},
  data() {
    return {
      needQueryDictCodes : [
          'sys_user_id','bd_country','bd_port','cabinet_category','container_customs_clearance_status',
          'dismantleAgent_id','tax_sharing_status','container_delivery_status','container_pod_status','yanchiReason',
          'clearAgent_result','abroad_clear_result','os_order_filelist','order_importer'
      ],
      isUsePost: true,
      queryForm: {
        boardTimes: [],
        arriveTimes: []
      },
      columns: [
        {
          type: "selection",
          width: 50,
        },
        {
          prop: "action",
          label: "操作",
          minWidth: 150
        },
        {
          prop: "withinRemark",
          label: "备注",
          minWidth: 150,
        },
        {
          prop: "holdStatusStr",
          label: "扣放货",
        },
        {
          prop: "week",
          label: "周期",
        },
        {
          prop: "containerNo",
          label: "柜号",
        },
        {
          prop: "bookNo",
          label: "SO号",
        },
        {
          prop: "cabinetCategoryStr",
          label: "专线or拆柜",
        },
        {
          prop: "country",
          label: "国家",
        },
        {
          prop: "podEndStr",
          label: "目的港",
        },
        {
          prop: "etdStr",
          label: "ETD",
        },
        {
          prop: "etaStr",
          label: "ETA",
        },
        {
          prop: "endOperatorStr",
          label: "派送操作",
        },
        {
          prop: "isSendHoldCabinetEmailStr",
          label: "发提柜邮件",
        },
        {
          prop: "isSendDeliveryEmailStr",
          label: "发派送邮件",
        },
        {
          prop: "isSendReleaseEmailStr",
          label: "更新放货邮件",
        },
        {
          prop: "taxSharingStatusStr",
          label: "分税状态",
        },
        {
          prop: "isArrived",
          label: "到港状态",
        },
        {
          prop: "arrivedTimeStr",
          label: "到港日期",
        },
        {
          prop: "customsClearanceStatusStr",
          label: "清关状态",
        },
        {
          prop: "isHoldCabinet",
          label: "提柜状态",
        },

        {
          prop: "holdCabinetTimeStr",
          label: "提柜时间",
        },
        {
          prop: "isHuangui",
          label: "还柜状态",
        },

        {
          prop: "huanguiTimeStr",
          label: "还柜时间",
        },

        {
          prop: "deliveryStatusStr",
          label: "派送状态",
        },
        {
          prop: "podStatusStr",
          label: "POD状态",
        },
        {
          prop: "isNormalTax",
          label: "复核分税",
        },

      ],
      url: {
        //查询列表的接口
        list: "/operation/container/delivery/page",
        getArrivalAtPort: "/operation/container/getArrivalAtPort",
        addArrivalAtPort: "/operation/container/addArrivalAtPort",
        batchUpdatedTransitPort: '/operation/container/batchUpdatedTransitPort',   //批量卸船/到港-中转港
        osContainerTransitPort: "/operation/container/osContainerTransitPort", //记录柜子的中转信息
        getOneAudShuidanProp: '/operation/container/getOneAudShuidanProp',
        sendAustraliaEmail: '/operation/container/sendAustraliaEmail',  //发送税单邮件接口
        sendOneAustraliaEmailByOrderNo: '/operation/container/sendOneAustraliaEmailByOrderNo',  //重新发送税单邮件接口
        sendUsShuidanEmailBatch: "/operation/container/sendUsShuidanEmailBatch",
        updateOsContainerIsMakeForecast: "/operation/container/updateOsContainerIsMakeForecast",
        updateOsContainerReleaseStatus: "/operation/container/updateOsContainerReleaseStatus",
        updateOsContainerReturnCondition: "/operation/container/updateOsContainerReturnCondition",
        checkWeek: "/operation/container/checkWeek",
        updateCabinetAudit: "/operation/container/mentionArk",
        batchMentionArk: '/operation/container/batchMentionArk',
        queryContainerOrderNo: "operation/container/getOrderNos",
        tieReminder: "/file/sendEmail/tieReminder",  //特别提醒
        exportUrl: '/file/export/excel/OperationExcel/deliveryContainer',
        updateRemark: '/operation/container/delivery/updateWithinRemark',
        queryEmailModelByIdAndType: 'operation/container/delivery/queryEmailBOByIdAndType',
        exportDeliveryDocTwo:"/file/export/excel/container/exportDeliveryDocTwo",
        updateTrainStatus:"/operation/container/updateTrainStatus"

      },
      dialogAddFormMentionArkVisible: false,
      addForm: {},
      selectRow: {},
      tiRules: {
        title: [
          {required: true, message: "请输入标题", trigger: "change"},
        ],
        remark: [
          {required: true, message: "请输入提醒事项", trigger: "change"},
        ],
      },
      remarkRules: {
        withinRemark: [
          {required: true, message: "请输入备注", trigger: "change"},
        ],
      },
      tiguiRules: {
        holdCabinetTime: [
          {required: true, message: "请选择提柜时间", trigger: "change"},
        ],
        unloadShip: [
          {required: true, message: "请选择卸船时间", trigger: "change"},
        ],
      },
      rules: {
        deliveryAgentId: [
          {required: true, message: "请选择送货代理", trigger: "change"},
        ],
        secondEtaTime: [
          {required: true, message: "请选择二程到港", trigger: "change"},
        ],
        secondSailTime: [
          {required: true, message: "请选择二程开船", trigger: "change"},
        ],
        arriveType: [
          {required: true, message: "请选择港口", trigger: "change"},
        ],
        berthingTime: [
          {required: true, message: "请选择靠泊时间", trigger: "change"},
        ],
        shipUnloading: [
          {required: true, message: "请选择卸船时间", trigger: "change"},
        ],
        arrivalAtPortType: [
          {required: true, message: "请选择到港or延误", trigger: "change"},
        ],
        customsClearanceLocation: [
          {required: true, message: "请选择清关地点", trigger: "change"},
        ],
        arrivalAtPortTime: [
          {required: true, message: "请选择实际到港时间", trigger: "change"},
        ],
        unShipTime: [
          {required: true, message: "请选择卸船时间", trigger: "change"},
        ],
        reasonDelay: [
          {required: true, message: "请选择延误原因", trigger: "change"},
        ],
        customsClearanceType: [
          {required: true, message: "请选择清关类型", trigger: "change"},
        ],
        customsClearanceResult: [
          {required: true, message: "请选择清关结果", trigger: "change"},
        ],
        clearTime: [
          {required: true, message: "请选择放行时间", trigger: "change"},
        ],
        checkTheType: [
          {required: true, message: "请选择查验类型", trigger: "change"},
        ],
        trainWeek: [
          {required: true, message: "请输入上火车周数", trigger: "change"},
          {
            validator: (rule, value, callback) => {
              if (/^(?:[1-9]\d*|0)$/.test(value) == false) {
                callback(new Error("请输入正整数"));
              } else {
                callback();
              }
            },
            trigger: "change",
          },
        ],
        checkWeek: [
          {
            required: true,
            message: "请输入国外清关查验周数",
            trigger: "change",
          },
          {
            validator: (rule, value, callback) => {
              if (/^(?:[1-9]\d*|0)$/.test(value) == false) {
                callback(new Error("请输入正整数"));
              } else {
                callback();
              }
            },
            trigger: "change",
          },
        ],
      },
      formLabelWidth: "150px",
      isBatchType: '',
      ArrivalAtPortFrom: {
        arriveType:'1'
      },
      ArrivalAtPortFromVisible: false,
      XieChuanFromVisible: false,
      form: {},
      KaoBoFromVisible: false,
      CustomsClearanceFromVisible: false,
      ClearanceResultsFrowVisible: false,
      CustomsClearanceLoad: false,
      row: {},
      week: '',
      containerId: '',
      countryTaxType: '',
      emailVisible: false,
      taxEmailForm: {},
      sendTaxEmailVisible: false,
      totalDeclared: 2250.00,
      isReTaxEmail: false,  // 是否是重发税单
      tTaxEmailRules: {
        totalDeclared: [
          {required: true, message: '请输入海运费', trigger: 'blur'},
        ],
        ons: [
          {required: true, message: '请输入保费', trigger: 'blur'},
        ],
        excRate: [
          {required: true, message: '请输入汇率', trigger: 'blur'},
        ],
      },
      PickUpCabinetForm: {},
      //特别提醒订单列表
      //特别提醒标识
      dialogTieReminderVisible: false,
      tieReminderOrderListNos: null,
      tieForm: {fileList: []},
      remarkVisible: false,
      remarkForm: {id: '', withinRemark: ''},
      reviewTaxDialogVisible:false,
      reviewTaxDialogContainerId:'',
      reviewTaxDialogImporterDictList:'',
      reviewTaxDialogWeek:'',
      sendEmailContainerId:'',
      sendEmailTitle:'',
      sendEmailType:'',
      sendEmailVisible:false,
      sendEmailContainerNo:'',
      sendEmailForm:{},
      visible:false,
      batchForm:{},
      batchFormRules:{
          eta: [
          {required: true, message: "请选择时间", trigger: "change"},
        ],
          trainTime: [
          {required: true, message: "请选择时间", trigger: "change"},
        ],
        actualTransitPortTime:[
          {required: true, message: "请选择时间", trigger: "change"},
        ]
      },
      batchTitle:''
    };
  },
  methods: {

    openEmailDialog(type,title){
      this.sendEmailContainerId = this.selectedRowKeys[0];
      this.sendEmailContainerNo = this.selectionRows[0].containerNo;
      this.sendEmailTitle = title;
      this.sendEmailType = type;
      getAction(this.url.queryEmailModelByIdAndType, {id: this.sendEmailContainerId, type: this.sendEmailType})
          .then(res => {
            this.sendEmailForm = res.result;
            this.sendEmailVisible = true;
          })


    },
    closeEmailDialog(){
      this.sendEmailVisible = false;
    },
    closeReviewTaxDialog(){
      this.reviewTaxDialogVisible = false;
    },
    openReviewTaxDialog(row){
      this.reviewTaxDialogContainerId = row.id;
      this.reviewTaxDialogWeek = row.week;
      this.reviewTaxDialogVisible = true;
    },
    checkStrOverLength(str, maxLength) {
      if (!str || str.length <= maxLength) {
        return false;
      }
      return true;
    },
    subStrFromLength(str,length){
      return str.substring(0,length) + "...";
    },
    handleRemark() {
      this.$refs['remarkForm'].validate(
          valid => {
            if (valid) {
              postAction(this.url.updateRemark, this.remarkForm)
                  .then(res => {
                    this.$message.success(res.message);
                    this.handleSearch();
                    this.remarkVisible = false;
                  })
            }
          }
      )
    },
    handleOpenWithinRemark(row) {
      let {id, withinRemark} = row;
      this.remarkForm.id = id;
      this.remarkForm.withinRemark = withinRemark;
      this.remarkVisible = true;
    },
    //发送特别提醒文件
    sendTieReminderEmail() {
      this.$refs.tieForm.validate(valid => {
        if (valid) {
          if (this.tieForm.fileList && this.tieForm.fileList.length && !this.tieForm.fileType) {
            this.$message.warning("有文件请选择文件类型！");
            return
          }
          this.tieForm.orderNos = this.tieReminderOrderListNos;
          postAction(this.url.tieReminder, this.tieForm)
              .then((res) => {
                if (res.success) {
                  this.$message.success(res.message);
                  this.dialogTieReminderVisible = false;
                }
              })
        }
      })


    },
    // 特别提醒按钮点击
    tieReminder() {
      this.tieForm = {};
      this.tieForm.fileList = [];
      let id = this.selectedRowKeys.join(',');
      getAction(this.url.queryContainerOrderNo, {containerId: id})
          .then((res) => {
            if (res.success) {
              this.tieReminderOrderListNos = res.result.join(",")
              console.log(this.tieReminderOrderListNos)
            }
          })
      this.dialogTieReminderVisible = true;
    },
    handMentionArkForm(row) {
      if (row == null) {
        let isError = false;
        try {
          this.selectionRows.forEach((item, index) => {
            if (item.circulationState !== '4') {
              isError = true;
              throw Error(); //满足条件，跳出循环
            }
          });
        } catch (e) {
        }
        if (isError) {
          this.$message.warning('已出港才可以进行提柜操作！');
          return;
        }
        this.isBatchType = 'PickUpCabinet';    //批量操作类型  PickUpCabinet 提柜  UnloadingShip 卸船
        this.dialogAddFormMentionArkVisible = true;
        this.PickUpCabinetForm = {};
      } else {
        this.isBatchType = '';
        this.selectRow = JSON.parse(JSON.stringify(row));
        //获取 该柜子曾经是否查验过 查验的周数
        getAction(this.url.checkWeek + '?id=' + row.id).then((res) => {
          if (res.success && res.result) {
            this.isCheekWeek = true;
            this.$set(this.PickUpCabinetForm, 'checkWeek', res.result.checkWeek)
          } else {
            this.isCheekWeek = false;
          }
        });
        this.dialogAddFormMentionArkVisible = true;
      }
    },
    handleClose() {
      this.sendTaxEmailVisible = false;
    },
    handleBeforeClose() {
      this.CustomsClearanceFromVisible = false;
      this.CustomsClearanceLoad = false;
    },
    fasongshuidan(row) {
      if (row) {
        this.row = JSON.parse(JSON.stringify(row));
      }

      if (row && row.country == '澳大利亚') {
        this.isReTaxEmail = false;
        if (row.isSendShuidanEmail == '1') {
          this.$store.commit("setFullscreenLoading", true);
          getAction(this.url.getOneAudShuidanProp + '?containerId=' + row.id).then(res => {
            if (res.success) {
            }
            this.$set(this.taxEmailForm, 'totalDeclared', "2250.00");
            this.$set(this.taxEmailForm, 'ons', "2.00");
            this.sendTaxEmailVisible = true;
            this.$store.commit("setFullscreenLoading", false);
          }).catch(err => {
            this.$store.commit("setFullscreenLoading", false);
          })
        } else {
          this.$set(this.taxEmailForm, 'totalDeclared', "2250.00");
          this.$set(this.taxEmailForm, 'ons', "2.00");
          this.sendTaxEmailVisible = true;
        }
      } else {
        let ids = [];

        if (row && row.id) {
          ids.push(row.id);
        } else {
          console.log("即将要发生的税单和邮件的柜子id", row, this.selectionRows, ids)
          this.selectionRows.forEach(s => ids.push(s.id));
        }

        this.$confirm(`确定发送税单邮件吗`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "success",
          closeOnClickModal: false,
        })
            .then(() => {
              postAction(this.url.sendUsShuidanEmailBatch, ids)
                  .then((res) => {
                    if (res.success) {
                      // this.$message.success(res.message);
                      // this.$alert(res.message, '发送邮件结果', {
                      //     dangerouslyUseHTMLString: true
                      // });
                      // this.$alert(`${res.message}`, '发送税单邮件结果', {
                      //     dangerouslyUseHTMLString: true,
                      //     showConfirmButton:false,
                      //     showCancelButton:true,
                      //     cancelButtonText:'关闭',
                      //     roundButton:true
                      // });
                      if (row && row.id) {
                        this.selectWeek = this.row.week;
                      }

                      this.sendEmailResult = res.result;
                      this.resultTitle = "发送税单邮件结果：";

                      this.alertVisible = true;
                      //关闭弹窗
                      this.handleQuery()
                    }
                  })
            })
      }
    },
    handleTaxEmail() {
      this.$refs['taxEmailForm'].validate((valid) => {
        if (valid) {
          let params = JSON.parse(JSON.stringify(this.taxEmailForm));
          params.containerId = this.row.id
          let url = this.isReTaxEmail ? this.url.sendOneAustraliaEmailByOrderNo : this.url.sendAustraliaEmail;
          // 是否是重新发送
          if (this.isReTaxEmail) {
            params.outShuidanType = '1';
            params.orderNo = this.row.orderNo;
            params.containerId = this.row.containerId;
          }
          //   this.$confirm(`确定发送税单邮件吗`, "提示", {
          this.$confirm(`税单预计发送时间为：1分钟；过程中请勿刷新页面，请耐心等待！！！`, "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
            closeOnClickModal: false,
          })
              .then(() => {
                postAction(url, params)
                    .then((res) => {
                      if (res.success) {
                        this.$message.success('税单发送成功');
                        this.sendTaxEmailVisible = false;
                        // this.$alert(res.message, '发送邮件结果', {
                        //     dangerouslyUseHTMLString: true
                        // });
                        // this.$alert(`${res.message}`, '发送税单邮件结果', {
                        //     dangerouslyUseHTMLString: true,
                        //     showConfirmButton:false,
                        //     showCancelButton:true,
                        //     cancelButtonText:'关闭',
                        //     roundButton:true
                        // });
                        // this.selectWeek = this.row.week;
                        // this.sendEmailResult = res.result;
                        // this.resultTitle = "发送税邮件结果：";

                        // this.alertVisible = true;
                        // //关闭弹窗
                        // this.handleQuery()
                      }
                    })
              })
        }
      });
    },
    handleReSend(row) {
      this.$set(this.taxEmailForm, 'totalDeclared', "2250.00");
      this.$set(this.taxEmailForm, 'ons', "2.00");
      row.isSendShuidanEmail = this.row.isSendShuidanEmail;
      this.row = JSON.parse(JSON.stringify(row));
      this.isReTaxEmail = true;
      if (row.isSendShuidanEmail == '1') {
        this.$store.commit("setFullscreenLoading", true);
        getAction(this.url.getOneAudShuidanProp + '?containerId=' + row.containerId).then(res => {
          if (res.success) {
            //   this.$set(this.taxEmailForm, 'totalDeclared', (res.result && res.result.totalDeclared) || '');
            //   this.$set(this.taxEmailForm, 'ons', (res.result && res.result.ons) || '');
            //   this.$set(this.taxEmailForm, 'excRate', (res.result && res.result.excRate) || '');
            this.sendTaxEmailVisible = true;
          }
          this.$store.commit("setFullscreenLoading", false);
        }).catch(err => {
          this.$store.commit("setFullscreenLoading", false);
        })
      } else {
        this.sendTaxEmailVisible = true;
      }
    },
    close() {
      this.emailVisible = false;
      this.handleSearch();
    },
    chakanyoujian(row) {
      this.row = JSON.parse(JSON.stringify(row));
      this.week = row.week;
      this.containerId = row.id;
      this.countryTaxType = row.country === '美国' ? '1' : '2';
      this.emailVisible = true;
    },
    handClearanceResults(row) {
      if (row == null) {

        this.$confirm(`确定批量国外清关结果?`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          closeOnClickModal: false,
        })
            .then(() => {
              let isError = false;
              try {
                this.selectionRows.forEach((item, index) => {
                  if (item.customsClearanceLocation === '0' || item.customsClearanceType === '0') {
                  } else {
                    isError = true;
                    throw Error(); //满足条件，跳出循环
                  }
                });
              } catch (e) {
              }
              if (isError) {
                this.$message.warning('请国外清关之后再国外清关结果！');
                return;
              }
              this.isBatchType = 'ClearanceResults';
              this.ArrivalAtPortFrom = {};
              this.ClearanceResultsFrowVisible = true;
            })
            .catch(() => {
            });

      } else {
        this.isBatchType = '';
        this.selectRow = JSON.parse(JSON.stringify(row));
        this.ArrivalAtPortFrom = {};
        getAction(this.url.getArrivalAtPort, {
          containerId: this.selectRow.id,
          complianceTime: this.selectRow.complianceTime,
        })
            .then((res) => {
              if (res.success) {
                console.log(res.result);
                if (res.result != null) {
                  this.ArrivalAtPortFrom = res.result;
                  if (res.result.customsClearanceType == null) {
                    this.$set(
                        this.ArrivalAtPortFrom,
                        "customsClearanceResult",
                        "4"
                    );
                  }
                }
              }
              this.ClearanceResultsFrowVisible = true;
            }).catch(err => {
          this.ClearanceResultsFrowVisible = true;
        })
      }
    },
    handCustomsClearance(row) {
      this.selectRow = JSON.parse(JSON.stringify(row));
      this.ArrivalAtPortFrom = {};
      getAction(this.url.getArrivalAtPort, {
        containerId: this.selectRow.id,
        complianceTime: this.selectRow.complianceTime,
      })
          .then((res) => {
            if (res.success) {
              console.log(res.result);
              if (res.result != null) {
                this.ArrivalAtPortFrom = res.result;
                if (res.result.customsClearanceType == null) {
                  this.$set(this.ArrivalAtPortFrom, "customsClearanceType", "0");
                }
              }
            }
            this.CustomsClearanceFromVisible = true;
          }).catch(err => {
        this.CustomsClearanceFromVisible = true;
      })
    },
    transferPort() {
      this.$refs["ArrivalAtPortFrom"].validate((valid) => {
        if (valid) {
          console.log(this.ArrivalAtPortFrom);
          this.ArrivalAtPortFrom.type = '1';
          let url = this.url.osContainerTransitPort;
          let params = {...this.ArrivalAtPortFrom};
          if (this.isBatchType == 'ArrivalAtPort') {
            url = this.url.batchUpdatedTransitPort;
            params = {
              containerIds: this.selectedRowKeys,
              secondSailTime: this.ArrivalAtPortFrom.secondSailTime,
              secondEtaTime: this.ArrivalAtPortFrom.secondEtaTime,
              type: '1'
            };
          }
          this.$store.commit("setFullscreenLoading", true);
          postAction(url, params)
              .then((res) => {
                this.$store.commit("setFullscreenLoading", false);
                if (res.success) {
                  this.$message.success(res.message);
                  this.ArrivalAtPortFromVisible = false;
                  this.CustomsClearanceFromVisible = false;
                  this.ClearanceResultsFrowVisible = false;
                  this.handleSearch();
                }
                this.isBatchType = '';
              }).catch(err => {
            this.$store.commit("setFullscreenLoading", false);
            this.isBatchType = '';
          })
        } else {
          return false;
        }
      });

    },
    kaoBoSave() {
      this.$refs["kaoBoFrom"].validate((valid) => {
        if (valid) {
          this.form.type = '2';
          postAction(this.url.osContainerTransitPort, this.form)
              .then((res) => {
                if (res.success) {
                  this.$message.success(res.message);
                  this.KaoBoFromVisible = false;
                }
              })
        } else {
          return false;
        }
      })

    },
    handKaoBo(row) {
      if (row) {
        this.form.containerId = row.id;
      } else {
        this.isBatchType = 'kbb';
        this.form.containerIds = this.selectedRowKeys;
      }
      this.KaoBoFromVisible = true;
    },
    xieChuanSave() {
      this.$refs["xieChuanFrom"].validate((valid) => {
        if (valid) {
          // let url = this.url.osContainerTransitPort;(批量单个都用批量接口)
          this.form.type = '3';
          let url = this.url.batchUpdatedTransitPort;
          let list = [];
          if (this.isBatchType == 'XieChuan') {
            list = this.selectedRowKeys;
          } else {
            list = [this.form.containerId];
          }
          let params = {containerIds: list, shipUnloading: this.form.shipUnloading, type: '3'};
          this.$store.commit("setFullscreenLoading", true);
          postAction(url, params)
              .then((res) => {
                this.$store.commit("setFullscreenLoading", false);
                if (res.success) {
                  this.$message.success(res.message);
                  this.XieChuanFromVisible = false;
                }
                this.isBatchType = '';
              }).catch(err => {
            this.$store.commit("setFullscreenLoading", false);
            this.isBatchType = '';
          })
        } else {
          return false;
        }
      })
    },
    handXieChuan(id) {
      this.$set(this.form, 'shipUnloading', '');
      if (id) {
        this.form.containerId = id;
        this.isBatchType = '';
        this.XieChuanFromVisible = true;
      } else {
        this.$confirm(`确定批量卸船？`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          closeOnClickModal: false,
        })
            .then(() => {
              this.form.containerId = '';
              this.isBatchType = 'XieChuan';
              this.XieChuanFromVisible = true;
            })
            .catch(() => {
            });

      }

    },
    saveArrivalAtPort(type) {
      //确定后台会发送什么样的短信信息
      //到港
      let refName = '';
      let url = this.url.addArrivalAtPort;
      if (type == "1") {
        refName = 'ArrivalAtPortFrom';
        //中转港
        if (this.ArrivalAtPortFrom.arrivalAtPortType == "0" && this.ArrivalAtPortFrom.arriveType == '0') {
          this.transferPort();
          return;
        } else {
          //目的港
          if (this.ArrivalAtPortFrom.arrivalAtPortType == "0") {
            this.ArrivalAtPortFrom.sendEmailType = "1";
          } else if (this.ArrivalAtPortFrom.arrivalAtPortType == "1") {
            this.ArrivalAtPortFrom.sendEmailType = "2";
          } else if (this.ArrivalAtPortFrom.arrivalAtPortType == "2") {
            this.ArrivalAtPortFrom.sendEmailType = "3";
          }
        }
      } else if (type == "2") {
        refName = 'clearanceResultsFrom';
        if (this.ArrivalAtPortFrom.customsClearanceResult == "4") {
          this.ArrivalAtPortFrom.sendEmailType = "6";
        } else if (this.ArrivalAtPortFrom.customsClearanceResult == "5") {
          this.ArrivalAtPortFrom.sendEmailType = "7";
        } else if (this.ArrivalAtPortFrom.customsClearanceResult == "6") {
          this.ArrivalAtPortFrom.sendEmailType = "8";
        }
        let list = this.ArrivalAtPortFrom.checkedFileList;
        if (list && list.length > 0 && this.ArrivalAtPortFrom.customsClearanceResult == '5') {
          postAction('/order/os/file/saveFileListWithContainerId?containerId=' + this.ArrivalAtPortFrom.containerId + "&checkedFileIds=" + this.ArrivalAtPortFrom.checkedFileIds + "&type=38", list)
              .then(res => {
              })
          this.ArrivalAtPortFrom.checkedFileIds = list.map(l => l.id).join(",");
          // 需要把他重新清空
        }
      } else if (type == "3") {
        refName = 'guowaiForm';
        if (this.ArrivalAtPortFrom.customsClearanceType == "0") {
          this.ArrivalAtPortFrom.sendEmailType = "4";
        } else if (this.ArrivalAtPortFrom.customsClearanceType == "1") {
          this.ArrivalAtPortFrom.sendEmailType = "5";
          this.ArrivalAtPortFrom.arrivalAtPortType = "0";
        }
      }

      this.ArrivalAtPortFrom.containerId = this.selectRow.id;
      // if (this.ArrivalAtPortFrom.customsClearanceType == "0") {
      //     this.ArrivalAtPortFrom.customsClearanceLocation = "0";
      // }
      console.log('国外清关', this.ArrivalAtPortFrom);
      console.log('this.selectRow', this.selectRow);
      this.$refs[refName].validate((valid) => {
        if (valid) {
          console.log(this.isBatchType, 'isBatchType')
          //   debugger
          this.CustomsClearanceLoad = true;
          console.log(this.ArrivalAtPortFrom);
          this.ArrivalAtPortFrom.complianceTime = this.selectRow.complianceTime;
          let params = {...this.ArrivalAtPortFrom, containerIds: [this.ArrivalAtPortFrom.containerId]};
          delete params.containerId;
          if (this.isBatchType == 'ArrivalAtPort' || this.isBatchType == 'ClearanceResults') {
            params.containerIds = this.selectedRowKeys;
          }
          console.log(params, 'params')
          this.$store.commit("setFullscreenLoading", true);
          putAction(this.url.addArrivalAtPort, params)
              .then((res) => {
                this.$store.commit("setFullscreenLoading", false);
                if (res.success) {
                  this.CustomsClearanceLoad = false;
                  this.$message.success(res.message);
                  this.ArrivalAtPortFromVisible = false;
                  this.CustomsClearanceFromVisible = false;
                  this.ClearanceResultsFrowVisible = false;
                  this.handleSearch();
                } else {
                  this.CustomsClearanceLoad = false;
                }
                this.isBatchType = '';
              }).catch(err => {
            this.$store.commit("setFullscreenLoading", false);
            this.isBatchType = '';
            this.CustomsClearanceLoad = false;
          })
        } else {
          return false;
        }
      });

    },
    handArrivalAtPort(row) {
      if (row == null) {
        this.$confirm(`确定进行批量到港操作?`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          closeOnClickModal: false,
        })
            .then(() => {
              this.isBatchType = 'ArrivalAtPort';
              this.ArrivalAtPortFrom = {
                 arriveType:'1'
              };
              this.ArrivalAtPortFromVisible = true;
            })
            .catch(() => {
            });

      } else {
        this.isBatchType = '';
        this.selectRow = JSON.parse(JSON.stringify(row));
        console.log(row);
        getAction(this.url.getArrivalAtPort, {
          containerId: this.selectRow.id,
          complianceTime: this.selectRow.complianceTime,
        })
            .then((res) => {
              if (res.success) {
                if (res.result != null) {
                  this.ArrivalAtPortFrom = res.result;
                  this.ArrivalAtPortFrom.pod = row.podCname;
                  this.ArrivalAtPortFrom.eta = row.eta;
                  this.$set(this.ArrivalAtPortFrom,'arriveType','1')
                  // this.ArrivalAtPortFrom.arriveType = '1'
                }
              }
              this.ArrivalAtPortFromVisible = true;
            }).catch(err => {
          this.ArrivalAtPortFromVisible = true;
        })
        this.ArrivalAtPortFrom.pod = row.podCname;
        this.ArrivalAtPortFrom.eta = row.eta;
        this.ArrivalAtPortFrom.arriveType = '1'
      }
    },
    //跳到待派送
    tiaojinHandout({containerNo}) {
      this.$router.push('/operation/cabinet/WaitForHandOut?containerNo=' + containerNo);
    },
    handMentionArk() {
      this.$refs["PickUpCabinetForm"].validate((valid) => {
        if (valid) {
          this.selectRow.holdCabinetTime = this.PickUpCabinetForm.holdCabinetTime;
          let form = JSON.parse(JSON.stringify(this.selectRow));
          // form.actionType = 'mentionArk'
          form.circulationState = "7";
          form.sendEmailType = "9";
          console.log(form);
          let url = this.url.updateCabinetAudit;
          let params = {...form};
          let method = 'put';
          if (this.isBatchType == 'PickUpCabinet') {
            url = this.url.batchMentionArk;
            params = {
              holdCabinetTime: this.PickUpCabinetForm.holdCabinetTime,
              containerIds: this.selectedRowKeys,
              circulationState: '7',
            }
            this.$store.commit("setFullscreenLoading", true);
            method = 'post'
          }

          this.$store.commit("setFullscreenLoading", true);
          httpAction(url, method, params).then((res) => {
            this.$store.commit("setFullscreenLoading", false);
            if (res.success) {
              this.$message({
                type: "success",
                message: res.message,
              });
              this.dialogAddFormMentionArkVisible = false;
              this.handleSearch();
            }
            this.isBatchType = '';
          }).catch(err => {
            this.$store.commit("setFullscreenLoading", false);
            this.isBatchType = '';
          });
        }
      })
    },

    pilianggengxin(row, type) {
      let message = '';
      let url = '';
      let params = [];
      if (row) {
        params.push(row.id);
      } else {
        params = this.selectedRowKeys;
      }

      if (type === '1') {
        message = '更新已发预报';
        url = this.url.updateOsContainerIsMakeForecast;
      } else if (type === '2') {
        message = '更新放货状态';
        url = this.url.updateOsContainerReleaseStatus;
      } else if (type === '3') {
        message = '更新还柜状态';
        url = this.url.updateOsContainerReturnCondition;
      }
      this.$confirm(`确定${message}?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
        closeOnClickModal: false,
      }).then(() => {
        postAction(url, params)
            .then(res => {
              this.$message.success(res.message);
              this.handleQuery();
            })
      })
    },
    batchClick(type) {
      if(type=='0') {
        this.batchTitle='批量到中转港'
      }else {
        this.batchTitle='批量上火车'
      }
      this.visible=true
    },
    BatchClose() {
       this.visible=false
       this.batchForm={}
    },
    handleBatch() {
        this.$refs['batchForm'].validate((valid) => {
        if (valid) {
          let params={
            ...this.batchForm,
            type:this.batchTitle=='批量到中转港'?'15':'16',
            containerIdList:this.selectedRowKeys
          }
          postAction(this.url.updateTrainStatus, params)
              .then((res) => {
                if (res.success) {
                  this.$message.success(res.message);
                  this.BatchClose()
                  this.handleSearch();
                }
              })
        }
      })
    }
  },
  computed: {
    buttonShowList() {
      return {
        'yb': this.$btnIsShow('operation/DispatchVerification', '0', '发预报'),
        'fh': this.$btnIsShow('operation/DispatchVerification', '0', '更新放货'),
        'hg': this.$btnIsShow('operation/DispatchVerification', '0', '还柜'),
        'tg': this.$btnIsShow('operation/DispatchVerification', '1', '提柜'),
        'ftgyj': this.$btnIsShow('operation/DispatchVerification', '0', '发提柜邮件'),
        'fpsyj': this.$btnIsShow('operation/DispatchVerification', '0', '发派送邮件'),
        'gxfhyj': this.$btnIsShow('operation/DispatchVerification', '0', '更新放货邮件'),
        'dcbb': this.$btnIsShow('operation/DispatchVerification', '0', '导出报表'),
        'bz': this.$btnIsShow('operation/DispatchVerification', '1', '备注'),
        'fhfs': this.$btnIsShow('operation/DispatchVerification', '1', '复核分税'),
        'yfzdxzpswj': this.$btnIsShow('operation/DispatchVerification', '1', '应付账单'),
        'dg': this.$btnIsShow('operation/cabinet/WaitingToPort', '1', '到港'),
        'pldg': this.$btnIsShow('operation/cabinet/WaitingToPort', '0', '批量到港'),
        'plxc': this.$btnIsShow('operation/cabinet/WaitingToPort', '0', '批量卸船'),
        'kb': this.$btnIsShow('operation/cabinet/WaitingToPort', '1', '靠泊'),
        'xc': this.$btnIsShow('operation/cabinet/WaitingToPort', '1', '卸船'),
        'gwqg': this.$btnIsShow('operation/cabinet/WaitingToPort', '1', '国外清关'),
        'gwqgjg': this.$btnIsShow('operation/cabinet/WaitingToPort', '1', '国外清关结果'),
        'cksdyj': this.$btnIsShow('operation/cabinet/PreQingguan', '1', '查看税单邮件'),
        'fssdyj': this.$btnIsShow('operation/cabinet/PreQingguan', '1', '发送税单邮件'),
        'pltg': this.$btnIsShow('operation/cabinet/WaitingToMentionArk', '0', '批量提柜'),
        'tx': this.$btnIsShow('docpage/AllOrderModule', '0', '特别提醒'),
        '下载派送文件2': this.$btnIsShow('operation/cabinet/Edit','0','下载派送文件2'),
        'pldzzg': this.$btnIsShow('operation/cabinet/Edit','0','批量到中转港'),
        'plshc': this.$btnIsShow('operation/cabinet/Edit','0','批量上火车'),
      }
    }
  },
  created() {
    // 赋值默认登录人
    this.$set(this.queryForm, 'endOperatorId', this.getLoginUserId());
  }
};
</script>

<style lang="scss" scoped>
.bbb {
  display: flex;
}

.dialog-footer {
  text-align: right !important;
}

.margin_right_10 {
  margin-right: 10px;
}
</style>
