<template>
    <div>
        <el-dialog :title="form.id?'修改':'新增'+'业务详情'" :visible.sync="dialogFormVisible" :close-on-click-modal="false"
                   v-if="dialogFormVisible">
            <el-form
                    :model="form"
                    :rules="rules"
                    ref="form"
                    label-width="110px"
                    size="medium"
                    :validate-on-rule-change="false"
            >
                <el-form-item prop="type" label="订单类型">
                    <f-select
                            v-model="form.type"
                            :isNeed="form.type"
                            :dict="'customer_order_type'"
                            @change="getCusType"
                    >
                    </f-select>
                </el-form-item>
                <el-form-item prop="salesmanId" label="业务员">
                    <f-select
                            v-model="form.salesmanId"
                            :isNeed="form.salesmanId"
                            :dict="'sys_user_id'"
                    >
                    </f-select>
                </el-form-item>
                <el-form-item prop="docId" label="客服">
                    <f-select
                            v-model="form.docId"
                            :isNeed="form.docId"
                            :dict="'sys_user_id'"
                    ></f-select>
                </el-form-item>
                <el-form-item prop="billmanId" label="收款人">
                    <f-select
                            v-model="form.billmanId"
                            :isNeed="form.billmanId"
                            :dict="'sys_user_id'"
                    ></f-select>
                </el-form-item>
                <el-form-item prop="operatorId" label="操作">
                    <f-select
                            v-model="form.operatorId"
                            :isNeed="form.operatorId"
                            :dict="'sys_user_id'"
                    ></f-select>
                </el-form-item>
              <el-form-item prop="followList" label="跟进人">
                <f-select
                    :multiple="true"
                    v-model="form.followList"
                    :isNeed="form.followList"
                    :dict="'sys_user_id'"
                ></f-select>
              </el-form-item>
                <el-form-item prop="payType" label="付款方式">
                    <el-select
                            filterable clearable
                            :dict="'pay_type'"
                            :disabled="getPayTypeIsDisabled()"
                            v-model="form.payType"
                            placeholder="请选择"
                            @change="getCusType"
                            @click.native="initBdPayTypeList"
                    >
                        <el-option v-for="(item,index) in bdPayTypeList" :key="index" :value="item.type"
                                   :label="item.typeName" ></el-option>
                    </el-select>
                </el-form-item>
                <div v-if="form.description && form.description.length>0" class="message_box">
                    <span>{{form.description}}</span>
                </div>
                <el-form-item v-if="form.description && form.description.indexOf('day1')!==-1" label="day1" prop="day1"
                :rules="getPayTypeIsDisabled() ? [] : rules.day1">
                    <el-input v-model="form.day1" type="number" onkeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"
                              :disabled="getPayTypeIsDisabled()"></el-input>
                </el-form-item>
                <el-form-item v-if="form.description && form.description.indexOf('day2')!==-1" label="day2" prop="day2"
                              :rules="getPayTypeIsDisabled() ? [] : rules.day2">
                    <el-input v-model="form.day2" type="number" onkeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"
                              :disabled="getPayTypeIsDisabled()"></el-input>
                </el-form-item>
                <el-form-item v-if="form.description && form.description.indexOf('day3')!==-1" label="day3" prop="day3"
                              :rules="getPayTypeIsDisabled() ? [] : rules.day3">
                    <el-input v-model="form.day3" type="number" onkeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"
                              :disabled="getPayTypeIsDisabled()"></el-input>
                </el-form-item>
                <el-form-item v-if="form.description && form.description.indexOf('day4')!==-1" label="day4" prop="day4"
                              :rules="getPayTypeIsDisabled() ? [] : rules.day4">
                    <el-input v-model="form.day4" type="number" onkeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"
                              :disabled="getPayTypeIsDisabled()"></el-input>
                </el-form-item>
                <el-form-item v-if="form.description && form.description.indexOf('day5')!==-1" label="day5" prop="day5"
                              :rules="getPayTypeIsDisabled() ? [] : rules.day5">
                    <el-input v-model="form.day5" type="number" onkeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"
                              :disabled="getPayTypeIsDisabled()"></el-input>
                </el-form-item>
                <el-form-item v-if="form.description && form.description.indexOf('day6')!==-1" label="day6" prop="day6"
                              :rules="getPayTypeIsDisabled() ? [] : rules.day6">
                    <el-input v-model="form.day6" type="number" onkeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"
                              :disabled="getPayTypeIsDisabled()"></el-input>
                </el-form-item>
                <el-form-item v-if="form.description && form.description.indexOf('edu1')!==-1" label="edu1" prop="edu1"
                              :rules="getPayTypeIsDisabled() ? [] : rules.edu1">
                    <el-input v-model="form.edu1" type="number" :disabled="getPayTypeIsDisabled()"></el-input>
                </el-form-item>
                <el-form-item v-if="form.description && form.description.indexOf('edu2')!==-1" label="edu2" prop="edu2"
                              :rules="getPayTypeIsDisabled() ? [] : rules.edu2">
                    <el-input v-model="form.edu2" type="number" :disabled="getPayTypeIsDisabled()"></el-input>
                </el-form-item>
                <el-form-item v-if="form.description && form.description.indexOf('edu3')!==-1" label="edu3" prop="edu3"
                              :rules="getPayTypeIsDisabled() ? [] : rules.edu3">
                    <el-input v-model="form.edu3" type="number" :disabled="getPayTypeIsDisabled()"></el-input>
                </el-form-item>
                <el-form-item v-if="form.description && form.description.indexOf('payNumber')!==-1" label="payNumber" prop="payNumber" >
                    <el-input v-model="form.payNumber" type="number" onkeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"
                              :disabled="getPayTypeIsDisabled()"></el-input>
                </el-form-item>
                <el-form-item v-if="form.description && form.description.indexOf('isWorkDay')!==-1" label="isWorkDay" prop="isWorkDay"
                              :rules="getPayTypeIsDisabled() ? [] : rules.isWorkDay">
                    <el-radio-group v-model="form.isWorkDay" :disabled="getPayTypeIsDisabled()">
                        <el-radio :label="'0'">否</el-radio>
                        <el-radio :label="'1'">是</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-col :span="spanValue">
                    <el-form-item prop="havaContract" label="有无合同">
                        <el-radio-group v-model="form.havaContract">
                            <el-radio :label="'0'">无合同</el-radio>
                            <el-radio :label="'1'">有合同</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
                <el-col :span="spanValue" v-if="form.havaContract == '1'">
                    <el-form-item
                            prop="contractBegin"
                            label="合同开始时间"
                            :rules="form.havaContract == '1' ? otherRules.contractBegin : []"
                    >
                        <el-date-picker
                                class="c-full-width"
                                v-model="form.contractBegin"
                                type="datetime"
                                default-time="00:00:00"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                format="yyyy-MM-dd HH:mm:ss"
                                :picker-options="editStartOptions"
                                placeholder="请选择时间"
                        >
                        </el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="spanValue" v-if="form.havaContract == '1'">
                    <el-form-item
                            prop="contractEnd"
                            label="合同结束时间"
                            :rules="form.havaContract == '1' ? otherRules.contractEnd : []"
                    >
                        <el-date-picker
                                class="c-full-width"
                                v-model="form.contractEnd"
                                type="datetime"
                                default-time="23:59:59"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                format="yyyy-MM-dd HH:mm:ss"
                                :picker-options="editStopOptions"
                                placeholder="请选择时间"
                        >
                        </el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="spanValue" v-if="form.havaContract == '1'">
                    <el-form-item
                            prop="contract"
                            label="合同附件"
                    >
                        <f-upload v-model="form.fileList" :isUseOss="true"></f-upload>
<!--                        <el-upload-->
<!--                                class="upload-demo"-->
<!--                                action=""-->
<!--                                :http-request="handleUpload"-->
<!--                                :file-list="form.fileList"-->
<!--                                ref="upload"-->
<!--                                multiple-->
<!--                                :before-upload="beforeUpload"-->
<!--                                :on-remove="removeFile"-->
<!--                                style="float: left; margin-right: 20px"-->
<!--                        >-->
<!--                            <el-button type="success" plain size="small">上传</el-button>-->
<!--                        </el-upload>-->
                    </el-form-item>
                </el-col>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" size="medium" icon="el-icon-s-promotion" @click="handleSave"
                           v-if="buttonShowList.save"
                >保存</el-button>
                <el-button size="medium" plain @click="handleReset()">重置</el-button>
            </div>
        </el-dialog>
        <el-card class="box-card last_card">
            <div class="f-p-b-15">
                <el-button type="primary" size="medium" icon="el-icon-plus" @click="handleAdd"
                >新增</el-button>
            </div>
            <f-table
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="66vh"
                    @sort-change="handleTableChange">
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'action'">
                        <el-button type="text" @click="handleEdit(scope.row)">编辑</el-button>
                        <el-button type="text" @click="handleDele(scope.row)">删除</el-button>
                    </template>
                    <!--        订单类型-->
                    <template v-else-if="scope.column.property === 'type'">
                        <span>{{parseDict(scope.row.type,'customer_order_type')}}</span>
                    </template>
                    <!--        订单状态-->
                    <template v-else-if="scope.column.property === 'orderStatus'">
                        <span>{{parseDict(scope.row.orderStatus,'all_os_order_status')}}</span>
                    </template>

                    <template v-else-if="scope.column.property === 'followList'">
                        <span>{{parseDict(scope.row.followList,'all_user_id')}}</span>
                    </template>

                    <template v-else-if="scope.column.property === 'havaContract'">
                        <span v-if="scope.row.havaContract ==='0'">无合同</span>
                        <span v-else>有合同</span>
                    </template>
                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange">
                </el-pagination>
            </el-row>
        </el-card>
    </div>

</template>

<script>
    import {getAction,postAction,deleteAction} from "@/api/manage";
    import QueryMixins from "../../../mixins/QueryMixins";
    import {doublepattern, numberPattern} from "../../../utils/pattern";
    export default {
        name: "CustomerDetailForm",
        mixins: [QueryMixins],
        data: function () {
            return {
                spanValue: 12,
                dictCodes:['customer_order_type','all_user_id'],
                dialogFormVisible:false,
                form:{},
                rules:{
                    payNumber: [{required: true, trigger: "blur",message:'请输入payNumber'}],
                    day1: [{required: true, trigger: "blur",message:'请输入day1'}],
                    day2: [{required: true, trigger: "blur",message:'请输入day2'}],
                    day3: [{required: true, trigger: "blur",message:'请输入day3'}],
                    day4: [{required: true, trigger: "blur",message:'请输入day4'}],
                    day5: [{required: true, trigger: "blur",message:'请输入day5'}],
                    day6: [{required: true, trigger: "blur",message:'请输入day6'}],
                    edu1: [{required: true, trigger: "blur",message:'请输入edu1'}],
                    edu2: [{required: true, trigger: "blur",message:'请输入edu2'}],
                    edu3: [{required: true, trigger: "blur",message:'请输入edu3'}],
                    type: [{required: true, trigger: "change",message:'请选择类型'}],
                    isWorkDay: [{required: true, trigger: "change",message:'请选择isWorkDay'}],
                    salesmanId: [{required: true, trigger: "change",message:'请选择业务员'}],
                    // docId: [{required: true, trigger: "change",message:'请选择客服'}],
                },
                url:{
                    getCustomerDetailByCusId:'/customer/detail/getDetailByCusIdAndType',
                    updateCustomerDetail:'/customer/detail/updateDetail',
                    list:'/customer/detail/getPageList',
                    delOne:'/customer/detail/delOne',
                    getOneByOrderTypeAndType:'/sys/payType/getOneByOrderTypeAndType',
                    getAllBdList:'/sys/payType/getAllBdList',

                },
                editStartOptions: {
                    disabledDate: (time) => {
                        if (!this.form.contractEnd) {
                            // time = new Date(time.replace(/-/g, '/'));
                            return time.getTime() < new Date(1970 - 1 - 1).getTime(); //禁止选择1970年以前的日期
                        } else {
                            return time.getTime() > new Date(this.form.contractEnd);
                        }
                    },
                },
                editStopOptions: {
                    disabledDate: (time) => {
                        // time = new Date(time.replace(/-/g, '/'));
                        return (
                            time.getTime() < new Date(this.form.contractBegin) ||
                            time.getTime() < new Date(1970 - 1 - 1).getTime() //禁止选择1970年以前的日期
                        );
                    },
                },
                otherRules: {
                    contractBegin: [
                        {required: true, message: "请选择合同开始时间", trigger: "change"},
                    ],
                    contractEnd: [
                        {required: true, message: "请选择合同结束时间", trigger: "change"},
                    ],
                },
                columns: [
                    {
                        prop: 'action',
                        label: '操作',
                    },
                    {
                        prop: 'type',
                        label: '类型',
                        sortable: 'custom',
                    },
                    {
                        prop: 'salesman',
                        label: '业务员',
                        sortable: 'custom',
                    },
                    {
                        prop: 'doc',
                        label: '客服',
                        sortable: 'custom',
                    },
                    {
                        prop: 'operator',
                        label: '操作',
                        sortable: 'custom',
                    },

                    {
                        prop: 'billman',
                        label: '收款人',
                        sortable: 'custom',
                    },
                    {
                        prop: 'followList',
                        label: '跟进人',
                        sortable: 'custom',
                    },
                    {
                        prop: 'payTypeName',
                        label: '付款方式',
                        sortable: 'custom',
                    },
                    {
                        prop: 'detail',
                        label: '付款方式详情',
                        sortable: 'custom',
                        overFlow:true,
                    },
                    {
                        prop: 'havaContract',
                        label: '有无合同',
                        sortable: 'custom',

                    },
                    {
                        prop: 'contractBegin',
                        label: '合同开始时间',
                        sortable: 'custom',

                    },
                    {
                        prop: 'contractEnd',
                        label: '合同结束时间',
                        sortable: 'custom',

                    },
                    /*{
                      prop: 'action',
                      label: '操作',

                    },*/
                ],
                bdPayTypeList:[],
            }
        },
        methods: {
          getPayTypeIsDisabled(){
            //判断是否拥有修改付款方式的权限
            let flag = this.buttonShowList.canEditPay;
            if (flag){
              console.log('有付款修改权限',false);
              return false;
            }
            console.log('没有付款修改权限',true);
            return true;

          },
            handleDele(row) {
                this.$confirm(`确定删除吗？`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'success',
                    closeOnClickModal: false,
                }).then(() => {
                    deleteAction(this.url.delOne, {id: row.id}).then(res => {
                        this.$message({
                            type: 'success',
                            message: res.message
                        })
                        this.handleQuery()
                    })
                }).catch(() => {

                })
            },
            initBdPayTypeList(){
                if (this.form.type) {
                    getAction(this.url.getAllBdList,{orderType:this.form.type,payType:this.form.payType})
                        .then(res=>{
                            this.bdPayTypeList = res.result;
                        })
                }

            },
            handleEdit(row){
                this.form=JSON.parse(JSON.stringify(row));
                this.dialogFormVisible=true;
                this.initBdPayTypeList();
                this.getCusType();
            },
            handleAdd(){
                if (this.tableData && this.tableData.length == 5) {
                    this.$message.warning('最多添加5种');
                    return false;
                }
                this.form = {};
                this.dialogFormVisible = true;
            },
            getCusType(){
                if (this.form.type && this.form.payType) {
                    getAction(this.url.getOneByOrderTypeAndType,{orderType:this.form.type,type:this.form.payType})
                        .then(res=>{
                            if (res.result){
                                this.$set(this.form,'description',res.result.detail);
                            }else {
                                this.$set(this.form,'description','');
                            }
                        })
                }

            },
            handleSave(){
                this.$refs['form'].validate(valid=>{
                    this.form.cusId = this.$route.query.id;
                    if (valid){
                      console.log(this.form)
                        postAction(this.url.updateCustomerDetail,this.form)
                        .then(res=>{
                            this.$message.success(res.message);
                            this.dialogFormVisible = false;
                            // this.initForm();
                            this.handleQuery();
                        })
                    }
                })
            },
            handleReset(){
                this.$refs['form'].resetFields();
            },
            initForm(){
                if (!this.form.type){
                    this.$set(this.form,'type',"0");
                }
                // this.getCusType(this.form.type);
            },
        },
        created() {
            // this.initForm();
            this.queryForm.cusId = this.$route.query.id;
        },
      computed : {
        buttonShowList() {
          return {
            'save':this.$btnIsShow('customer/Edit','0','保存'),
            'canEditPay':this.$btnIsShow('isystem/RoleManage','0','是否可以修改付款方式'),

          }
        }
      }
    };
</script>

<style lang="scss" scoped>
.message_box {
  margin-left: 110px;
  margin-bottom: 20px;
  font-weight: bold;
}
</style>
