<template>
  <el-dialog
      title="派送详情"
      :visible="visible"
      width="30%"
      custom-class="c-custom-dialog"
      :before-close="handleClose"
      append-to-body
      :close-on-click-modal="false">
    <el-form class="addEditBox" :model="form" :rules="rules" ref="form" label-width="100px" size="medium">
      <el-button @click="addFee" type="primary" size="medium">新增一行</el-button>
      <el-table :data="tableData" class="c-th-has-bgcolor" style="width: 100%">
        <el-table-column
            prop="action"
            label="操作"
            minWidth="120"
        >
          <template slot-scope="scope">
            <el-button type="text" @click="delTakeFee(scope.$index)">删除</el-button>
          </template>
        </el-table-column>

        <el-table-column label="供应商" width="180px">
          <template slot-scope="scope">
            <f-select v-model="scope.row.supplierId" :is-need="scope.row.supplierId" dict="supplier_id"></f-select>
          </template>
        </el-table-column>
        <el-table-column label="提货费" width="">
          <template slot-scope="scope">
            <el-input v-model="scope.row.takeFee" type="number"></el-input>
          </template>
        </el-table-column>
      </el-table>

      <el-form-item prop="useCarType" label="派车类型" :label-width="formLabelWidth">
        <f-select v-model="form.useCarType" :is-need="form.useCarType"
                  dict="warehouse_take_order_use_car_type"></f-select>
      </el-form-item>

      <el-form-item prop="workType" label="车辆作业类型" :label-width="formLabelWidth">
        <f-select v-model="form.workType" :is-need="form.workType" dict="warehouse_take_order_work_type"></f-select>
      </el-form-item>

      <el-form-item prop="driverId" label="司机姓名" :label-width="formLabelWidth"
                    v-if="['1','3'].indexOf(form.useCarType) !== -1 ">
        <el-select clearable filterable v-model="form.driverId" @change="handleChangeDriverName">
          <el-option v-for="(i,d) in driverInfoList" :value="i.value" :label="i.label" :key="d"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item prop="driverInfo" label="司机信息" :label-width="formLabelWidth">
        <el-input v-model="form.driverInfo" maxlength="300" show-word-limit type="textarea"></el-input>
      </el-form-item>
      <el-form-item prop="carType" label="车辆性质" :label-width="formLabelWidth">
        <f-select v-model="form.carType" :is-need="form.carType" dict="reserved_model"></f-select>
      </el-form-item>


    </el-form>
    <span slot="footer">
            <el-button size="medium" @click="handleClose">取消</el-button>
            <el-button type="primary" size="medium" @click="handleSubmit">确认</el-button>
        </span>
  </el-dialog>
</template>

<script>
import {getAction, postAction} from '@/api/manage'

export default {
  name: 'editDriverModule',
  props: {
    visible: {
      type: Boolean
    },
    takeIds: {
      type: String,
      default: ''
    },
    hiDriverInfo: {
      type: String,
      default: ''
    },

  },
  data() {
    return {
      form: {},
      formList:[],
      formLabelWidth: '100px',
      rules: {
        useCarType: [
          {required: true, message: '请选择派车类型', trigger: 'change'},
        ],
        workType: [
          {required: true, message: '请选择车辆作业性质', trigger: 'change'},
        ],
        driverName: [
          {required: true, message: '请输入司机姓名', trigger: 'change'},
        ],
        driverInfo: [
          {required: true, message: '请输入司机信息', trigger: 'change'},
        ],
        carType: [
          {required: true, message: '请选择车辆类型', trigger: 'change'},
        ],
        takeFee: [
          {required: true, message: '请输入提货费', trigger: 'change'},
        ],
        outNo: [
          {required: true, message: '请选择出库编码', trigger: 'change'},
        ],

      },
      url: {
        addOrUpdateDriverInfo: "/warehouse/takeDelivery/addOrUpdateDriverInfo",
        getDriverInfoAndTakeFeeById: '/warehouse/takeDelivery/getDriverInfoAndTakeFeeById',
        delTakeFeeById: "/warehouse/takeDelivery/delTakeFeeById",
      },
      driverInfoMap: {},
      driverInfoList: [],
      tableData: [],
    }
  },

  watch: {
    visible(val) {
      if (val && this.takeIds) {
        //勾选的订单号进行赋值
        this.$set(this.form, 'takeIds', this.takeIds);
        this.$set(this.form, 'outNoList', []);

        getAction(this.url.getDriverInfoAndTakeFeeById, {id:this.takeIds}).then(res => {
          if (res && res.result) {
            let vo = res.result;
            this.form = JSON.parse(JSON.stringify(vo));
            this.driverInfoList = this.form.warehouseDriverDropDownVoList;
            this.driverInfoMap = this.listToMap(this.driverInfoList, 'value');
            let tableData = this.form.warehouseTakeFeeEntityList;
            if (tableData && tableData.length > 0) {
              this.tableData = tableData;
            }

          }
        })
      }
    },
  },
  methods: {
    addFee() {
      this.tableData.push({
        id: '',
        takeFee: '',
        cur: '',
        supplierId: '',
        supplierName: '',

      })
    },
    delTakeFee(index) {
      this.$confirm(`确定删除第${index + 1}行吗`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "success",
        closeOnClickModal: false,
      })
          .then(() => {
            let id = this.tableData[index].id;
            if (id) {
              postAction(this.url.delTakeFeeById + "?id=" + id)
                  .then(res => {
                    this.$message.success(res.message);
                  })
            }
            this.tableData.splice(index, 1);
          }).catch(() => {

      })
    },
    handleChangeDriverName(val) {
      let obj = this.driverInfoMap[val];
      if (obj) {
        let driverInfo = obj['driverInfo'];
        if (this.hiDriverInfo !== '') {
          driverInfo = this.hiDriverInfo + "\n" + driverInfo
        }
        this.$set(this.form, 'driverInfo', driverInfo);
        this.$set(this.form, 'driverName', obj['name']);
      }

    },
    handleSubmit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.form.warehouseTakeFeeEntityList = this.tableData;
          postAction(this.url.addOrUpdateDriverInfo, this.form).then((res) => {
            this.$message({
              type: 'success',
              message: res.message
            })
            this.form = {outNoList: []};
            this.handleClose();
            this.$emit('ok');
          })
        }
      })
    },
    //将集合转为map
    listToMap(list, name) {
      var map = {};
      for (let i = 0; i < list.length; i++) {
        map[list[i][name]] = list[i];
      }
      return map;
    },
    handleClose() {
      this.form = {};
      this.$emit('update:visible', false);
    }
  },
  created() {
  }
}
</script>

<style lang="scss" scoped>
::v-deep
.el-dialog__body {
  padding: 20px 40px 20px 6px;
}

.addEditBox {
  max-height: 615px;
  overflow: scroll;
}
</style>
