<!--待锁单-->
<template>
    <div class="main-full-content">
        <el-form class="multiline_select" ref="form" :model="queryForm" label-width="80px" size="medium">
            <el-card class="box-card">
                <el-row class="inputs_row">
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="订单号">
                            <el-input type="textarea" v-model="queryForm.orderNo" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="周数">
                            <el-input  v-model="queryForm.week" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="柜号">
                            <el-input  v-model="queryForm.containerNo" @keyup.enter.native="handleSearch"
                            type="textarea" placeholder="多个柜号精确查询"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="客户名">
                            <el-input v-model="queryForm.customer" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :md="4" :sm="24">
                      <el-form-item label="操作员">
                        <f-select
                            v-model="queryForm.operateId"
                            :isNeed="queryForm.operateId"
                            :dict="'sys_user_id'">
                        </f-select>
                      </el-form-item>
                    </el-col>
                    <el-col :md="4" :sm="24">
                      <el-form-item label="客服">
                        <f-select
                            v-model="queryForm.docId"
                            :isNeed="queryForm.docId"
                            :dict="'sys_user_id'">
                        </f-select>
                      </el-form-item>
                    </el-col>
                    <el-col :md="4" :sm="24">
                      <el-form-item label="渠道名称">
                        <f-select
                            v-model="queryForm.transportStatus"
                            :isNeed="queryForm.transportStatus"
                            :dict="'transportStatus'">
                        </f-select>
                      </el-form-item>
                    </el-col>
                    <el-col :md="4" :sm="24">
                      <el-form-item label="是否包税">
                        <f-select
                            v-model="queryForm.hasTaxType"
                            :isNeed="queryForm.hasTaxType"
                            :dict="'is_default'">
                        </f-select>
                      </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :md="4" :sm="24">
                      <el-form-item label="目的地">
                        <f-select
                            v-model="queryForm.podEnd"
                            :isNeed="queryForm.podEnd"
                            :dict="'sys_port'">
                        </f-select>
                      </el-form-item>
                    </el-col>
<!--                  <el-col  :xs="24" :sm="12" :md="12" :lg="4" v-if="showLoadingSearch">-->
<!--                    <el-form-item label="清关属性">-->
<!--                      <f-select-->
<!--                          :dict="'qg_prop'"-->
<!--                          v-model="queryForm.qgProp"-->
<!--                          :is-need="queryForm.qgProp"-->
<!--                      ></f-select>-->
<!--                    </el-form-item>-->
<!--                  </el-col>-->
<!--                  <el-col  :xs="24" :sm="12" :md="12" :lg="4" v-if="showLoadingSearch">-->
<!--                    <el-form-item label="海外仓中转服务">-->
<!--                      <f-select-->
<!--                          :dict="'haiwai_service'"-->
<!--                          v-model="queryForm.haiwaiService"-->
<!--                          :is-need="queryForm.haiwaiService"-->
<!--                      ></f-select>-->
<!--                    </el-form-item>-->
<!--                  </el-col>-->
                  <el-col  :xs="24" :sm="12" :md="12" :lg="4" v-if="showLoadingSearch">
                    <el-form-item label="锁国外仓租柜租">
                      <f-select
                          :dict="'is_default'"
                          v-model="queryForm.lockCangzu"
                          :is-need="queryForm.lockCangzu"
                      ></f-select>
                    </el-form-item>
                  </el-col>
                  <el-col  :xs="24" :sm="12" :md="12" :lg="4" v-if="showLoadingSearch">
                    <el-form-item label="锁国外查验">
                      <f-select
                          :dict="'is_default'"
                          v-model="queryForm.lockChayan"
                          :is-need="queryForm.lockChayan"
                      ></f-select>
                    </el-form-item>
                  </el-col>
                  <el-col  :xs="24" :sm="12" :md="12" :lg="4" v-if="showLoadingSearch">
                    <el-form-item label="三锁服务">
                      <f-select
                          :dict="'is_default'"
                          v-model="queryForm.sanLock"
                          :is-need="queryForm.sanLock"
                      ></f-select>
                    </el-form-item>
                  </el-col>
                  <el-col  :xs="24" :sm="12" :md="12" :lg="4" v-if="showLoadingSearch">
                    <el-form-item label="地址类型">
                      <f-select
                          :dict="'address_type'"
                          v-model="queryForm.shipmentType"
                          :is-need="queryForm.shipmentType"
                      ></f-select>
                    </el-form-item>
                  </el-col>
                  <el-col  :xs="24" :sm="12" :md="12" :lg="4" v-if="showLoadingSearch">
                    <el-form-item label="航线名称">
                      <f-select
                          :dict="'fba_solicitation'"
                          v-model="queryForm.solicitation"
                          :is-need="queryForm.solicitation"
                      ></f-select>
                    </el-form-item>
                  </el-col>
                    <el-col class="no_margin"  :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label-width="30px">
                            <el-button
                                    type="primary"
                                    icon="el-icon-search"
                                    @click="handleSearch"
                            >查询
                            </el-button
                            >
                            <el-button plain @click="handleReset">重置</el-button>
                          <el-button type="text" mini @click="showLoadingSearch=!showLoadingSearch"><i
                              :class="showLoadingSearch ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"
                              style="margin-right:3px"></i>{{showLoadingSearch ? '收起' :'更多'}}
                          </el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>
        </el-form>
        <el-card class="box-card">
            <div>
                <el-button
                    type="primary"
                    size="medium"
                    icon="el-icon-plus"
                    @click="beatHandcustomsClearanceResult"
                    :disabled="this.selectedRowOrderNos.length===0"
                    v-if="buttonShowList.plgwqg"
                >批量国外清关结果
                </el-button>
            </div>
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    :isorter="isorter"
                    :show-operation="true"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="68vh"
                    :isRecordTableScroll="true"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange"
                    @queryChange="handleTableQueryChange">
                >
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'action'">
                        <el-button type="text" @click="handcustomsClearanceResult(scope.row)"
                                   v-if="buttonShowList.gwqg"
                        >国外清关结果</el-button>
                    </template>
                    <template v-else-if="scope.column.property === 'week'">
                        <a style="color: #00a0e9"
                           @click.prevent="infomation(scope.row.containerId)"
                        >{{ scope.row.week}}</a>
                        <!--            <span>{{ parseDict(scope.row.containerId, "os_container") }}</span>-->
                    </template>
                    <template v-else-if="scope.column.property === 'operateId'">
                        <span>{{parseDict(scope.row.operateId,'sys_user_id')}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'docId'">
                        <span>{{parseDict(scope.row.docId,'sys_user_id')}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'cusId'">
                        <a type="text" @click.prevent="$intoCustomerEditPage(scope.row.cusId)" style="color:#57a3f3;">
                        <span>{{scope.row.customer}}</span></a>
                    </template>
                    <template v-else-if="scope.column.property === 'podEnd'">
                        <span>{{parseDict(scope.row.podEnd,'sys_port')}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'importerType'">
                        <span>{{parseDict(scope.row.importerType,'order_importer')}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'transportStatus'">
                        <span>{{parseDict(scope.row.transportStatus,'transportStatus')}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'customsBrokerResult'">
                       <!-- <span>{{parseDict(scope.row.customsBrokerResult,'clearAgent_result')}}</span>-->
                          <span v-if="scope.row.customsBrokerResult == '0'">无</span>
                          <span v-if="scope.row.customsBrokerResult == '1'">国内报关放行</span>
                          <span v-if="scope.row.customsBrokerResult == '2'">国内查验通知</span>
                          <span v-if="scope.row.customsBrokerResult == '3'">国内查验未放行</span>
                          <span v-if="scope.row.customsBrokerResult == '4'">国内查验已放行</span>
                          <span v-if="scope.row.customsBrokerResult == '5'">国内查验查验退关</span>
                    </template>
                    <template v-else-if="scope.column.property === 'isVirtualCabinet'">
                        <span v-if="scope.row.isVirtualCabinet == '0'">自拼</span>
                        <span v-if="scope.row.isVirtualCabinet == '1'">非自拼</span>
                    </template>
                    <template v-else-if="scope.column.property === 'ctn'">
                        <span>{{scope.row.ctn}}/{{scope.row.kg}}/{{scope.row.cmb}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'realCtn'">
                        <span>{{scope.row.realCtn}}/{{scope.row.realKg}}/{{scope.row.realCmb}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'invoiceStatus'">
                        <span v-if="scope.row.invoiceStatus === '1'">已确认</span>
                        <span v-if="scope.row.invoiceStatus !== '1'">未确认</span>
                    </template>
                    <template v-else-if="scope.column.property === 'hasTax'">
                        <span>{{scope.row.hasTax === '1' ? '已包税' : '未包税'}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'complianceState'">
                                    <span>{{
                                      parseDict(scope.row.complianceState, "compliance_state")
                                    }}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'orderNo'">
                        <a
                                style="color: #00a0e9"
                                @click.prevent="$intoDocPage(scope.row.orderNo)"
                        >{{ scope.row.orderNo }}</a>
                    </template>

                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                >
                </el-pagination>
            </el-row>
        </el-card>

        <el-dialog
                title="国外清关结果"
                :visible.sync="dialogFormVisible"
                :close-on-click-modal="false"
        >
            <el-form :model="saveForm" ref="saveForm" :rules="rules">
                <el-form-item label="订单号" :label-width="formLabelWidth">
                    <el-input v-model="saveForm.orderNo"></el-input>
                </el-form-item>
                <el-form-item label="清关结果" :label-width="formLabelWidth" prop="customsClearanceResults">
                    <f-select
                            v-model="saveForm.customsClearanceResults"
                            :is-need="saveForm.customsClearanceResults"
                            :dict="'customsClearanceResu'"
                            placeholder="请选择">
                    </f-select>
                </el-form-item>
                <el-form-item label="清关放行时间" :label-width="formLabelWidth" prop="clearTime"
                              v-if="saveForm.customsClearanceResults == 4">
                    <el-date-picker
                            class="f-flex-1"
                            v-model="saveForm.clearTime"
                            type="datetime"
                            size="small"
                            placeholder="清关放行时间"
                            default-time="23:59:59"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            format="yyyy-MM-dd HH:mm:ss"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="扣留原因" :label-width="formLabelWidth" prop="detainedReason"
                              v-if="saveForm.customsClearanceResults == 2">
                    <f-select
                            v-model="saveForm.detainedReason"
                            :is-need="saveForm.detainedReason"
                            :dict="'detainedReason'"
                            placeholder="请选择">
                    </f-select>
                </el-form-item>
                <el-form-item label="监管部门查验" :label-width="formLabelWidth" prop="departmentOfInspection"
                              v-if="saveForm.customsClearanceResults == 1">
                    <f-select
                            v-model="saveForm.departmentOfInspection"
                            :is-need="saveForm.departmentOfInspection"
                            :dict="'departmentOfInspecti'"
                            placeholder="请选择">
                    </f-select>
                </el-form-item>
                <el-form-item label="最终结果" :label-width="formLabelWidth" prop="finalResult"
                              v-if="saveForm.customsClearanceResults == 3">
                    <f-select
                            v-model="saveForm.finalResult"
                            :is-need="saveForm.finalResult"
                            :dict="'finalResult'"
                            placeholder="请选择">
                    </f-select>
                </el-form-item>
                <el-form-item
                        label="查验类型"
                        :label-width="formLabelWidth"
                        prop="checkTheType"
                        v-if="saveForm.customsClearanceResults == '5'"
                >
                    <el-select
                            filterable
                            class="f-full-width"
                            v-model="saveForm.checkTheType"
                            placeholder="请选择"
                    >
                        <el-option key="0" label="文件审查" value="0"></el-option>
                        <el-option key="1" label="X光查验" value="1"></el-option>
                        <el-option key="2" label="人工查验" value="2"></el-option>
                        <el-option key="3" label="其它" value="3"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                        label="清关查验周数"
                        :label-width="formLabelWidth"
                        prop="checkWeek"
                        v-if="saveForm.customsClearanceResults == '5'"
                ><el-input v-model="saveForm.checkWeek"></el-input>
                </el-form-item>
              <el-form-item
                  label="清关查验文件"
                  :label-width="formLabelWidth"
                  prop="checkedFileList"
                  v-if="saveForm.customsClearanceResults == '5' || saveForm.customsClearanceResults == '6' || saveForm.customsClearanceResults == '2'"
              >
                <f-upload
                    class="f-full-width"
                    v-model="saveForm.checkedFileList"
                    :isUseOss="true"
                ></f-upload>
              </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="save">确 定</el-button>
            </div>
        </el-dialog>

    </div>
</template>

<script>
    import {getAction, postAction, putAction} from "@/api/manage";
    import QueryMixins from "../../../mixins/QueryMixins";
    import { setUpRouteUtil } from '@/utils/util';
    // import Tempcclate from "../../isystem/Dict";
    export default {
        name: "Potential",
        // components: {Tempcclate},
        mixins: [QueryMixins],
        data() {
            return {
              showLoadingSearch: false,
                rules: {
                    customsClearanceResults: [
                        {required: true, message: '请选择清关结果', trigger: 'change'},
                    ],
                    detainedReason: [
                        {required: true, message: '请选择扣留原因', trigger: 'change'},
                    ],
                    departmentOfInspection: [
                        {required: true, message: '请选择监管部门查验', trigger: 'change'},
                    ],
                    finalResult: [
                        {required: true, message: '请选择最终结果', trigger: 'change'},
                    ],
                    checkTheType: [
                        {required: true, message: "请选择查验类型", trigger: "change"},
                    ],
                    clearTime: [
                        {required: true, message: "请选择清关放行时间", trigger: "change"},
                    ],
                    checkWeek: [
                        {
                            required: true,
                            message: "请输入国外清关查验周数",
                            trigger: "change",
                        },
                        {
                            validator: (rule, value, callback) => {
                                if (/^(?:[1-9]\d*|0)$/.test(value) == false) {
                                    callback(new Error("请输入正整数"));
                                } else {
                                    callback();
                                }
                            },
                            trigger: "change",
                        },
                    ],
                },
                dictCodes: ['sys_user_id', 'customer_id', 'sys_port', 'order_importer', 'transportStatus', 'clearAgent_result','compliance_state','os_container'],
                ids: [],
                row: {},
                form: {},
                saveForm: {},
                //控制弹框显示
                dialogFormVisible: false,
                diaFormlockActivity: false,
                activityForm: {},
                payList: [],
                receivableList: [],
                osContainerSettle1: {},
                formLabelWidth: "120px",
                columns: [
                    {
                        type: "selection",
                        width: 50,
                        fixed: 'left'
                    },
                    {
                        prop: 'action',
                        label: '操作',
                        width: 120,
                        fixed: 'left'
                    },
                    {
                        prop: 'orderNo',
                        label: '订单号',
                        sortable: 'orderNo',
                        width: 120,
                        fixed: 'left'
                    },
                    {
                        prop: 'importerType',
                        label: '进口商类型',
                        sortable: 'importerType',
                        width: 100
                    },
                    {
                        prop: 'customsBrokerResult',
                        label: '清关结果',
                        sortable: 'customsBrokerResult',
                        width: 100
                    },
                    {
                        prop: 'cusId',
                        label: '客户名',
                        sortable: 'cusId',
                        width: 140,
                        fixed: 'left'
                    },
                    {
                        prop: 'week',
                        label: '周数',
                        width: 140,
                        fixed: 'left'
                    },
                    {
                        prop: 'containerNo',
                        label: '柜号',

                        width: 140,
                        fixed: 'left'
                    },
                    {
                        prop: 'hasTax',
                        label: '是否包税',
                        sortable: 'hasTax',
                        width: 120
                    },
                    {
                        prop: 'complianceState',
                        label: '关务是否审核',
                        sortable: 'complianceState',
                        width: 120
                    },
                    {
                        prop: 'ctn',
                        label: '件/重/体',
                        sortable: 'ctn',
                        width: 120
                    },
                    {
                        prop: 'realCtn',
                        label: '实件/重/体',
                        sortable: 'realCtn',
                        width: 120
                    },
                    {
                        prop: 'operateId',
                        label: '操作员',
                        sortable: 'operateId',
                        width: 120
                    },
                    {
                        prop: 'docId',
                        label: '客服',
                        sortable: 'docId',
                        width: 120
                    },
                    {
                        prop: 'podEnd',
                        label: '目的地',
                        sortable: 'podEnd',
                        width: 160
                    },
                    {
                        prop: 'transportStatus',
                        label: '渠道名称',
                        sortable: 'transportStatus',
                        width: 140
                    }, {
                        prop: "isVirtualCabinet",
                        label: "是否自拼",
                        minWidth: 140,
                        sortable: "isVirtualCabinet",
                    },
                ],
                url: {
                    list: "/operation/container/getClearanceOrderList", //待清关列表
                    startLockActivity: '/activiti/operationActiviti/startLockActivity',
                    clearanceResult: '/operation/container/clearanceResult'
                },
                selectedRowOrderNos: [],
              isUsePost:true
            };
        },
        methods: {
            infomation(id) {
                // 对于路由不在左侧菜单中的选中菜单处理
                setUpRouteUtil(this.$route.path, '/operation/cabinet/Edit');
                this.$router.push("/operation/cabinet/Edit?id=" + id);
                console.log(id);
            },

            save() {
                this.$refs['saveForm'].validate((valid) => {
                    if (valid) {
                      this.$store.commit("setFullscreenLoading", true);
                      let list = this.saveForm.checkedFileList;
                      if (list && list.length>0){
                        let orderNoList=[];
                        postAction("/order/os/file/saveClearFileList?checkedFileIds="+this.saveForm.checkedFileIds+"&orderNo="+this.saveForm.orderNo+"&type=38",list).then(res=>{

                        }).catch()
                        this.saveForm.checkedFileIds = list.map(l=>l.id).join(',')
                      }
                        console.log(this.saveForm)
                        postAction(this.url.clearanceResult, this.saveForm)
                            .then((res) => {
                                this.$store.commit("setFullscreenLoading", false);
                                if (res.success) {
                                    this.$message.success(res.message);
                                    this.dialogFormVisible = false;
                                    this.handleSearch()

                                }
                            }).catch(err => {
                              this.$store.commit("setFullscreenLoading", false);
                            })
                    } else {
                        return false;
                    }
                })
            },
            handcustomsClearanceResult(row) {
                console.log(row)
                this.selectRow = JSON.parse(JSON.stringify(row));
                getAction("/operation/container/getOneClearanceByOrderNo",{orderNo:row.orderNo})
              .then(res=>{
                this.saveForm=res.result;
                this.dialogFormVisible = true;
              })

            },
            //批量国外清关结果
            beatHandcustomsClearanceResult(){
                this.saveForm.orderNo = this.selectedRowOrderNos.join(",");
                this.saveForm.checkedFileList = [];
                this.dialogFormVisible = true;
            },

            intoDocpage(orderNo) {
                // 对于路由不在左侧菜单中的选中菜单处理
                setUpRouteUtil(this.$route.path, '/docpage/Edit');
                this.$router.push("/docpage/Edit?orderNo=" + orderNo);

            },
            handleSelectionChange(selection) { // 多选
                let arr = []
                for (let i = 0; i < selection.length; i++) {
                    arr.push(selection[i]['orderNo'])
                }
                this.selectedRowOrderNos = [...arr];
            },


            handleReset() {
                this.queryForm = {};
                this.filterForm = {}
                this.ipagination.currentPage = 1;
                    if (this.$refs['GTable']){
                        this.$refs['GTable'].handleReset();
                    }

                this.handleQuery();
            },
        },
        created() {
        },
      computed :{
          buttonShowList() {
            return {
              'gwqg':this.$btnIsShow('operation/cabinet/WaitingClearance','1','国外清关结果'),
              'plgwqg':this.$btnIsShow('operation/cabinet/WaitingClearance','0','批量国外清关结果'),
            }
          }
      }
    };
</script>

<style lang="scss" scoped>
</style>
