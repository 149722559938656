<template>
    <el-dialog
        :title="title"
        :visible="visible"
        width="560px"
        custom-class="c-custom-dialog"
        :close-on-click-modal="false"
        :before-close="handleClose">
        <el-form :model="addForm" :rules="rules" ref="addForm" label-width="120px" size="medium">
            <el-row>
                <el-col :span="24">
                    <el-form-item prop="country" label="国家">
                        <el-select

                                clearable
                                filterable
                                class="f-full-width"
                                v-model="addForm.country"

                                placeholder="获取国家基础数据"
                                @click.native="initCountryList">
                            <el-option
                                    v-for="(item,index) in countrylist"
                                    :key="index"
                                    :label="item.label"
                                    :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item prop="code" :label="codeTitle">
                        <el-input v-model="addForm.fbaCode" placeholder="请输入FBA CODE" maxlength="10" show-word-limit></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item prop="state" label="州">
                        <!--<el-select
                                clearable
                                filterable
                                @change="initCityList"
                                class="f-full-width"
                                v-model="addForm.state"
                                placeholder="获取州/省数据">
                            <el-option
                                    v-for="(item,index) in statelist"
                                    :key="index"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>-->
                      <el-input v-model="addForm.state" placeholder="请输入州" maxlength="30" show-word-limit></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item prop="city" label="城市">
                        <!--<el-select
                                clearable
                                filterable
                                class="f-full-width"
                                v-model="addForm.city"
                                placeholder="获取城市基础数据">
                            <el-option
                                    v-for="(item,index) in citylist"
                                    :key="index"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>-->
                      <el-input v-model="addForm.city" placeholder="请输入城市" maxlength="30" show-word-limit></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item prop="address" label="地址行1">
                        <el-input v-model="addForm.address" placeholder="请输入地址" maxlength="250" type="textarea" show-word-limit></el-input>
                    </el-form-item>
                </el-col>
              <el-col :span="24">
                <el-form-item prop="address2" label="地址行2">
                  <el-input v-model="addForm.address2" placeholder="请输入地址" maxlength="250" show-word-limit></el-input>
                </el-form-item>
              </el-col>
                <el-col :span="24">
                    <el-form-item prop="zip" label="邮编">
                        <el-input v-model="addForm.zip" placeholder="请输入邮编" maxlength="20" show-word-limit></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item label="快递是否偏远" :label-width="formLabelWidth" prop="kdIsRemote">
                        <el-radio-group v-model="addForm.kdIsRemote">
                            <el-radio label="0">否</el-radio>
                            <el-radio label="1">是</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
              <el-col :span="24">
                <el-form-item label="优先状态" :label-width="formLabelWidth" prop="showColor">
                 <f-select v-model="addForm.showColor" :is-need="addForm.showColor" :dict="'fba_addr_show_color'"></f-select>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item prop="suggest" label="派送建议">
                  <el-input v-model="addForm.suggest" placeholder="派送建议" maxlength="100" show-word-limit type="textarea"></el-input>
                </el-form-item>
              </el-col>
                <!--<el-col :span="24">
                    <el-form-item prop="longitude" label="经度">
                        <el-input v-model="addForm.longitude" placeholder="请输入经度"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item prop="latitude" label="纬度">
                        <el-input v-model="addForm.latitude" placeholder="请输入纬度"></el-input>
                    </el-form-item>
                </el-col>-->

            </el-row>
        </el-form>
        <span slot="footer">
            <el-button size="medium" @click="handleClose">取消</el-button>
            <el-button type="primary" size="medium" @click="handleSubmit">确定</el-button>
        </span>
    </el-dialog>
</template>

<script>
import { httpAction } from '@/api/manage'
import {getallcountries,getcityList,getstateList} from "@/api/manage";


export default {
    name: 'FbacodeDialog',

    props: {
        visible: {
            type: Boolean
        },
        row: {
            type: Object,
            default: () => {}
        },
        addressType:{
          type:String,
          default:''
        }
    },
    data() {
        return {
            formLabelWidth:'100px',
            addForm: {},
            countrylist:[],
            citylist:[],
            statelist:[],
            recMap: {},
            rules: {
                country: [
                    { required: true, message: '请选择国家', trigger: 'change' },
                ],
                state: [
                    { required: true, message: '请输入州/省', trigger: 'change' },
                ],
                city: [
                    { required: true, message: '请输入城市', trigger: 'change' },
                ],
              fbaCode: [
                    { required: true, message: '请输入fbaCode', trigger: 'change' },
                ],
                address:[
                    { required: true, message: '请输入地址', trigger: 'change' },
                ],
                longitude:[
                    { pattern: /(^([0-9]+.?)*[0-9]$)/ ,message: "只能由数字及小数点组成", trigger: 'change'}
                ],
                latitude:[
                    { pattern: /(^([0-9]+.?)*[0-9]$)/ ,message: "只能由数字及小数点组成", trigger: 'change'}
                ],
                kdIsRemote: [
                    { required: true, message: '请选择快递是否偏远', trigger: 'change' },
                ],
            },
            url: {
                add: '/sys/fbacode/add',
                edit: '/sys/fbacode/edit'
            },
          title:'',
          codeTitle:'',
        }
    },
    watch: {
        visible(val) {
          let tt = "新增";
            if(val && this.row.id) {
              tt = "编辑";
                const { id, country, state, city, fbaCode, address,address2, zip,countryNum,kdIsRemote,showColor,suggest} = this.row
                this.addForm = { id, country, state, city, fbaCode, address,address2, zip,countryNum,kdIsRemote,showColor,suggest }
            } else {
                this.addForm = {}
            }
            if (this.addressType === '1') {
              this.codeTitle = 'FBA CODE';
            }else {
              this.codeTitle = 'AWD CODE';
            }
          tt += this.codeTitle;
            this.title = tt;
        },

    },
    created(){
        // this.countrylist
    },
    methods: {
        initCountryList(){
            if (this.countrylist.length <=0){
                getallcountries().then(res=>{
                    if (res.success){
                        for (var i=0;i<res.result.length;i++){
                            let obj = {};
                            obj.value = res.result[i].cnName;
                            obj.label = res.result[i].twoCode + "-" + res.result[i].cnName;
                            this.countrylist.push(obj);
                            this.recMap = {};
                            this.recMap = this.listToMap(res.result, "cnName");

                        }
                    }
                })
            }

        },

        handleSubmit() {
            let url = this.url.add
            let method = 'POST'
            if(this.row.id) {
                url = this.url.edit
                method = 'PUT'
            }
            this.$set(this.addForm,'addressType',this.addressType);
            this.$refs.addForm.validate((valid) => {
                if (valid) {
                  if(this.row.id) {

                  }else {
                    let val = this.addForm.country;
                  /*  this.$set(this.addForm, 'country', this.recMap[val]["cnName"]);*/
                    this.$set(this.addForm, 'countryNum', this.recMap[val]["countryNum"]);
                  }
                    httpAction(url, method, this.addForm).then((res)=>{
                        this.$message({
                            type: 'success',
                            message: res.message
                        })
                        this.handleClose()
                        this.$emit('ok')
                    })
                }
            })
        },
        handleClose() {
            this.$emit('update:visible', false)
            this.$refs.addForm.resetFields()
        },
        listToMap(list, name) {
            var map = {};
            for (var index in list) {
                map[list[index][name]] = list[index];
            }
            return map;
        },

    }
}
</script>

<style lang="scss" scoped>

</style>
