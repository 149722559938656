<template>
    <div class="main-full-content">
        <el-dialog
            title="预览"
            append-to-body
            :visible="VoucherVisible"
            custom-class="dialogClass"
            top="50px"
            :before-close="() => { VoucherVisible = false }">
        </el-dialog>
        <el-form class="multiline_select" ref="form" :model="queryForm" label-width="80px" size="medium">
            <el-card class="box-card">
                <el-row class="inputs_row">
                    <el-col :xs="24" :sm="12" :md="12" :lg="5">
                        <el-form-item label="业务编号">
                            <el-input  v-model="queryForm.no" @keyup.enter.native="handleCheck"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="5">
                        <el-form-item label="流水号">
                            <el-input  v-model="queryForm.billNo" @keyup.enter.native="handleCheck"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="5">
                        <el-form-item label="申请编号">
                            <el-input  v-model="queryForm.applyNo" @keyup.enter.native="handleCheck"></el-input>
                        </el-form-item>

                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="5">
                        <el-form-item label="结算对象">
                            <el-input v-model="queryForm.traderName" @keyup.enter.native="handleCheck"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="费用币种">
                            <f-select v-model="queryForm.curry" dict="bd_cur" :isNeed="queryForm.curry"></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :md="6" :sm="24">
                        <el-form-item label="申请时间">
                            <div class="f-flex">
                                <el-date-picker
                                        class="f-flex-1"
                                        v-model="queryForm.applyTimeStart"
                                        type="datetime"
                                        size="small"
                                        placeholder="开始时间"
                                        default-time="00:00:00"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                >
                                </el-date-picker>
                                <span class="f-p-h-2">~</span>
                                <el-date-picker
                                        class="f-flex-1"
                                        v-model="queryForm.applyTimeEnd"
                                        type="datetime"
                                        size="small"
                                        placeholder="结束时间"
                                        default-time="23:59:59"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                >
                                </el-date-picker>
                            </div>
                        </el-form-item>
                    </el-col>
                    <el-form-item label="凭证期间">
                        <el-date-picker v-model="queryForm.FVoucherDate" type="month" placeholder="选择月"
                                         value-format="yyyy-MM">

                        </el-date-picker>
                    </el-form-item>
                    <!--<el-col :xs="24" :sm="12" :md="12" :lg="5">-->
                        <!--<el-form-item label="业务类型">-->
                            <!--<el-select v-model="queryForm.agentPayType">-->
                                <!--<el-option value="0" label="海运"></el-option>-->
                                <!--<el-option value="2" label="快递"></el-option>-->
                            <!--</el-select>-->
                        <!--</el-form-item>-->
                    <!--</el-col>-->
                    <el-form-item label="凭证类型">
                        <el-select v-model="queryForm.voucherType">
                            <el-option  value="1" label="收入凭证"></el-option>
                            <el-option  value="2" label="成本凭证"></el-option>
                            <el-option  value="3" label="收款凭证"></el-option>
                            <el-option  value="4" label="付款凭证"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="推送状态">
                        <el-select v-model="queryForm.status">
                            <el-option  value="0" label="待抛转"></el-option>
                            <el-option  value="1" label="已抛转"></el-option>
                            <el-option  value="2" label="已生成"></el-option>
                            <el-option  value="3" label="已撤回"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="业务类型">
                        <el-select v-model="queryForm.feeType">
                            <el-option value="海运" label="海运"></el-option>
                            <el-option value="快递" label="快递"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="3">
                        <el-form-item label-width="30px" style="width: 500px">
                            <el-button type="primary" icon="el-icon-search" @click="handleSearch" >查询 </el-button >
                            <el-button plain @click="reset">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>
        </el-form>

        <el-card class="box-card last_card">
            <div class="f-p-b-15">
                <el-button size="medium" type="primary" @click="pushData" >推送数据到金蝶</el-button>
                <el-button size="medium" type="primary" @click="saveMonth" >生成整月凭证</el-button>
                  <div class="f-flex">
                    <el-date-picker v-model="queryForm.byMonth" type="month" placeholder="选择月"
                                    value-format="yyyy-MM"></el-date-picker>

                  </div>
                <el-upload
                        action="/"
                        :show-file-list="false"
                        :http-request="importTemp"
                        accept=".xls,.xlsx"
                        style="margin:0 10px;display:inline">
                    <el-button size="medium" type="primary">导入绑定应收交易日期</el-button>
                </el-upload>
            </div>
            <f-table
                v-loading="loading"
                ref="GTable"
                border
                size="medium"
                row-key="id"
                class="c-th-has-bgcolor"
                :columns="columns"
                :data="tableData"
                :cell-style="{ 'text-align': 'center' }"
                tableHeight="61vh"
                :show-operation="true"
                @selection-change="handleSelectionChange"
                @sort-change="handleTableChange"
            >
                <!--  `status` int(1) DEFAULT '0' COMMENT '凭证状态，0-待抛转 1-已抛转 2-已生成 3-已撤回',-->

                <template v-slot="scope">
                    <template v-if="scope.column.property === 'status'">
                        <span v-if="scope.row.status=='0'">待抛转</span>
                        <span v-if="scope.row.status=='1'">已抛转</span>
                        <span v-if="scope.row.status=='2'">已生成</span>
                        <span v-if="scope.row.status=='3'">已撤回</span>
                    </template>
                    <template v-else-if="scope.column.property === 'voucherType'">
                        <span v-if="scope.row.voucherType=='1'">收入</span>
                        <span v-if="scope.row.voucherType=='2'">成本</span>
                        <span v-if="scope.row.voucherType=='3'">收款</span>
                        <span v-if="scope.row.voucherType=='4'">付款</span>
                    </template>
                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                    v-bind="ipagination"
                    layout="total, sizes, prev, pager, next, jumper"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                >
                </el-pagination>
            </el-row>
        </el-card>

    </div>
</template>
<script>
import {getAction, postAction} from "@/api/manage";
import QueryMixins from "@/mixins/QueryMixins.js"
export default {
    name:'waitPushRecord',
    mixins:[QueryMixins],
    data() {
        return {
            dictCodes:['sys_create_by'],
            queryForm:{agentPayType:'0'},


            selectedRowKeys: [],
            selectionRows: [],
            selectedNos: [],
            loading: false,
            form: {},
            columns: [
                {
                  type: "selection",
                  width: 50,
                },
                {
                    prop: "status",
                    label: "凭证状态",
                    width: 150,
                },
                {
                    prop: "voucherNo",
                    label: "凭证证书号",
                },
                {
                    prop: "fVoucherDateStr",
                    label: "凭证交易期间",
                },
                {
                    prop: "note",
                    label: "摘要",
                    width: 250,
                },
                {
                    prop: "billNo",
                    label: "流水单号",
                },
                {
                    prop: "applyNo",
                    label: "申请编号",
                    width: 110,
                },
                {
                    prop: "traderName",
                    label: "结算对象",
                },

                {
                    prop: "voucherType",
                    label: "凭证类型",
                },
                {
                    prop: "curry",
                    label: "币种",
                },
                {
                    prop: "amountFor",
                    label: "原币金额",
                },
                {
                    prop: "variance",
                    label: "差异额",
                },
                {
                    prop: "kingdeeMsg",
                    label: "日志",
                },
                {
                    prop: "bankCode",
                    label: "银行科目",
                },
                {
                    prop: "applyTime",
                    label: "申请时间",
                },
                {
                    prop: "outletsName",
                    label: "网点",
                    width: 110,
                },
                {
                    prop: "feeName",
                    label: "费用名称",
                },
                // {
                //     prop: "no",
                //     label: "凭证编号",
                // },
            ],
            VoucherVisible: false,
            url: {
                list: "/finance/voucher/list",
                saveIncomeAndCostData: "/finance/voucher/saveIncomeAndCostData",
                pushData: "/finance/voucher/batchPushData",
                importTemp: "/finance/voucher/importTemp",
            },
            rules: {},
            applyNos:[],/*传递给弹窗的 申请编号*/
            billnos:'',//传递给弹窗的 流水号
        };
    },
    computed: {},
    created() {
      this.$set(this.queryForm,'orderType','0')
    },
    methods: {
        cancelRemove(row){

        },

        queryRecord(){
            getAction(this.url.list, this.form)
                .then(res=>{
                    if(res & res.result){
                        const {records, total} = res.result
                        this.tableData = records || res.result;
                    }
                })
        },

        reset(){
            this.queryForm = [];
        },

        // /**
        //  *  生成月度收入/成本凭证
        //  * /
        saveMonth() {
            if (!this.queryForm.voucherType) {
                this.$message.warning('请勾选凭证类型');
                return false;
            }
          if (!this.queryForm.byMonth) {
            this.$message.warning('请勾选生成月份');
            return false;
          }
            if (["1","2"].indexOf(this.queryForm.voucherType)===-1) {
                this.$message.warning('只有收款和成本才可以生成月度凭证');
                return false;
            }
          this.$confirm(`确定生成凭证吗`, "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "success",
            closeOnClickModal: false,
          })
              .then(() => {
                let param =  {
                  voucherType: this.queryForm.voucherType,
                  month: this.queryForm.byMonth,
                }
                postAction(this.url.saveIncomeAndCostData, param)
                    .then(res=>{
                      this.handleSearch();
                    })
              })

        },
        importTemp(param) {
            //file就是当前添加的文件
            var fileObj = param.file;
            //FormData 对象
            var form = new FormData();
            //文件对象，key是后台接受的参数名称
            form.append("file", fileObj);
            //执行上传方法
            console.log("执行上传方法");
            postAction(this.url.importTemp, form)
                .then((res) => {
                    //这里做上传之后的操作
                    if (res.success) {
                        this.$message.success(res.message);
                        this.handleQuery();
                    } else {
                        this.handleQuery();
                    }
                })

        },

        /**
         * 抛转数据到中间库
         */
        pushData() {
            if (this.selectedRowKeys.length === 0) {
                this.$message.warning("请勾选需要生成凭证的数据");
                return;
            }

            this.selectionRows.forEach((item) => {

            });
            let count = [];
            let isWaitPush = true;
            this.selectionRows.forEach((item) => {
                count.push(item.voucherType);
                if(item.status != 0) {
                    isWaitPush = false;
                }
            });
            if(!isWaitPush){
                this.$message.warning("请选择待抛转的数据推送！");
                return;
            }
            let cusArr = Array.from(new Set(count));
            if (cusArr.length > 1) {
                this.$message.warning("不同类型的凭证请分开进行推送！");
                return;
            }

          this.$confirm(`确定抛转数据到金蝶吗`, "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "success",
            closeOnClickModal: false,
          })
              .then(() => {
                let applyNos = this.selectedRowKeys;
                applyNos && applyNos.length > 0 && postAction(this.url.pushData, applyNos)
                    .then(res=>{
                      //请求成功
                      console.log("查询申请编号的流水情况", res);
                        this.handleSearch();
                    }).catch(e=>{
                      this.$confirm(e.message, {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning',
                      })
                    })
              })

            },


        },
};
</script>
<style scoped>
.el-button {
    user-select: unset;
}
.addEditBox {
  height: 780px;
  overflow: scroll;
}
</style>
