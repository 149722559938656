<template>

    <div class="main-full-content">
        <el-form ref="form" :model="queryForm" label-width="120px" size="medium">
            <el-card class="box-card">
                <el-row>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="工作单号">
                            <el-input v-model="queryForm.orderNo" @keyup.enter.native="handleQuery" type="textarea"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="客户">
                            <el-input v-model="queryForm.cusName" @keyup.enter.native="handleQuery" ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item prop="checkStatus" label="是否取消">
                            <el-select v-model="queryForm.status" clearable filterable>
                                <el-option label="否" value="0"></el-option>
                                <el-option label="是" value="2"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="3">
                        <el-form-item label-width="30px" style="width: 400px">
                            <el-button size="medium" type="primary" icon="el-icon-search" @click="handleQuery">查询</el-button>
                            <!-- <el-divider direction="vertical"></el-divider> -->
                            <el-button size="medium" plain @click="handleReset">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>

        </el-form>
        <el-card class="box-card last_card">

            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="72vh"
                    :isRecordTableScroll="true"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange">
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'action'">
                        <el-button type="text" @click="cancelIssue(scope.row)" v-if="scope.row.cancel ==='0' && scope.row.status !== '1'">确认收货</el-button>
                        <el-button type="text" @click="qxyuyue(scope.row)" v-if="scope.row.cancel==='0'">取消预约</el-button>
                    </template>
                  <template v-else-if="scope.column.property === 'status'">
                    <span v-if="scope.row.status === '0'">已预约</span>
                    <span v-else-if="scope.row.status === '2'" style="color:red">已取消</span>
                    <span v-else-if="scope.row.status === '1'" style="color:red">已收货</span>
                  </template>

                  <template v-else-if="scope.column.property === 'reservedModel'">
                    <span >{{parseDict(scope.row.reservedModel,'reserved_model')}}</span>
                  </template>
                    <template v-else-if="scope.column.property === 'orderNo'">
                        <a
                                style="color: #00a0e9"
                                @click.prevent="$intoDocPage(scope.row.orderNo)"
                        >{{ scope.row.orderNo }}</a
                        >
                    </template>
                    <template v-else-if="scope.column.property === 'reservedModel'">
                        <span>{{parseDict(scope.row.reservedModel,'reserved_model')}}</span>
                    </template>
                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange">
                </el-pagination>
            </el-row>
        </el-card>

    </div>


</template>
<script>
    import QueryMixins from "@/mixins/QueryMixins"
    import {postAction,deleteAction} from "@/api/manage";


    export default {
        mixins: [QueryMixins],
        components: {

        },
        data() {
            return {
                editStartOptions: {
                    disabledDate: time => {
                        if (!this.form.etime) {
                            // time = new Date(time.replace(/-/g, '/'));
                            return time.getTime() < new Date(1970 - 1 - 1).getTime();   //禁止选择1970年以前的日期
                        } else {
                            return time.getTime() > new Date(this.form.etime);
                        }
                    }
                },
                editStopOptions: {
                    disabledDate: time => {
                        // time = new Date(time.replace(/-/g, '/'));
                        return (
                            time.getTime() < new Date(this.form.stime) ||
                            time.getTime() < new Date(1970 - 1 - 1).getTime()    //禁止选择1970年以前的日期
                        );
                    }
                },
              dictCodes:['reserved_model'],
                selectedRowKeys: [],
                selectionRows: [],
                selectedNos:[],
                loading: false,
                form: {},
                columns: [
                    // {
                    //     type: 'selection',
                    //     width: 50
                    // },
                    {
                        prop: 'action',
                        label: '操作',
                    },
                    {
                        prop: 'status',
                        label: '预约状态',
                    },
                    {
                        prop: 'orderNo',
                        label: '工作单号',
                    },
                    {
                        prop: 'cusName',
                        label: '客户',
                    },

                    {
                        prop: 'arrivalTime',
                        label: '到仓时间',
                    },
                    {
                        prop: 'pickUpTime',
                        label: '提货时间',
                    },
                    {
                        prop: 'ctn',
                        label: '箱数',
                    },
                    {
                        prop: 'kg',
                        label: '重量',
                    },
                    {
                        prop: 'cmb',
                        label: '体积',
                    },
                  {
                    prop: 'reservedModel',
                    label: '预约车型',
                  },
                    {
                        prop: 'reservedCarNumber',
                        label: '预约车牌号',
                    },
                  {
                    prop: 'driverName',
                    label: '司机姓名',
                  },
                    {
                        prop: 'driverPhone',
                        label: '司机手机号',
                    },
                ],

                url: {
                    list: '/interface/warehouse/getReversedWarehouseIPage',
                    updateOneStatus:'/interface/warehouse/updateOrderStatus3',
                    cancel:'/interface/warehouse/delReversedWarehouseCancel',
                },
                isUsePost : true,

            };
        },
        computed: {

        },
        created() {
        },
        methods: {
          cancelIssue(row){
            this.$confirm(`确定将${row.orderNo}确认收货吗，状态将变为已到仓？`, "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
              closeOnClickModal: false,
            }).then(res=>{
              postAction(this.url.updateOneStatus+'?orderNo='+row.orderNo)
                  .then(res=>{
                    this.$message.success(res.message);
                    this.handleQuery();
                  })
            })
          },
          qxyuyue(row){
            this.$confirm(`确定将${row.orderNo}取消预约吗？`, "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
              closeOnClickModal: false,
            }).then(res=>{
              deleteAction(this.url.cancel+'?id='+row.id)
                  .then(res=>{
                    this.$message.success(res.message);
                    this.handleQuery();
                  })
            })
          },
        },
    };
</script>
<style scoped>
    .el-form-item {
        margin-bottom: 22px;
    }
</style>
