<template>
  <div class="main-full-content">
    <el-form
        class="input_top"
        ref="form"
        :model="queryForm"
        label-width="80px"
        size="medium"
        :label-width="formLabelWidth"
    >
      <el-card class="box-card">
        <el-row>

          <el-col :xs="24" :sm="12" :md="12" :lg="7">
            <el-form-item label="任务名称">
              <el-input v-model="queryForm.title" @keyup.enter.native="handleSearch"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="7">
            <el-form-item label="需求编号">
              <el-input v-model="queryForm.needCode" @keyup.enter.native="handleSearch" type="textarea" class="padding_right_area"></el-input>
            </el-form-item>
          </el-col>

          <el-col :xs="24" :sm="12" :md="12" :lg="7">
            <el-form-item label="是否紧急">
              <f-select v-model="queryForm.isEme" @keyup.enter.native="handleSearch" dict="unit_whether"></f-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="7">
            <el-form-item label="任务负责人">
              <!--<el-input v-model="queryForm.fzPersion" @keyup.enter.native="handleSearch"></el-input>-->
              <f-select
                  v-model="queryForm.fzPersion"
                  :is-need="queryForm.fzPersion"
                  :data-list="queryDictCodeListMap['sys_user_id']"
              ></f-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="7">
            <el-form-item label="实际提出人">
              <f-select
                  v-model="queryForm.sjPersion"
                  :is-need="queryForm.sjPersion"
                  :data-list="queryDictCodeListMap['sys_user_id']"
              ></f-select>
              <!-- <el-input v-model="queryForm.sjPersion" @keyup.enter.native="handleSearch"></el-input>-->
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="7">
            <el-form-item prop="urgency" label="紧急程度">
              <el-select
                  clearable
                  filterable
                  v-model="queryForm.urgency"
                  placeholder="请选择"
                  style="width: 100%"
              >
                <el-option
                    v-for="item in optionsUrgency"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="7" v-if="buttonShowList['setEme']">
            <el-form-item prop="emeType" label="评定的紧急程度">
              <el-select
                  clearable
                  filterable
                  v-model="queryForm.emeType"
                  placeholder="请选择"
                  style="width: 100%"
              >
                <el-option
                    v-for="item in optionsUrgency"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="7">
            <el-form-item prop="taskType" label="任务类型">
              <el-select
                  clearable
                  filterable
                  class="f-full-width"
                  v-model="queryForm.taskType"
                  placeholder="请选择"
                  style="width: 100%"
              >
                <el-option
                    v-for="item in optionsTaskType"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="7">
            <el-form-item prop="status" label="状态">
              <f-select
                  :data-list="queryDictCodeListMap['sys_need_status']"
                  v-model="queryForm.status"
                  :is-need="queryForm.status"
                  placeholder="请选择"
                  style="width: 100%"
              >
              </f-select>
            </el-form-item>
          </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="7">
            <el-form-item prop="depart" label="部门">
                        <f-select
                            :isNeed="queryForm.depart"
                            v-model="queryForm.depart"
                            :data-list="queryDictCodeListMap['sys_depart']"
                        ></f-select>
                    </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="7">
                <el-form-item prop="depart" label="创建人">
                    <f-select
                        :isNeed="queryForm.cjPersion"
                        v-model="queryForm.cjPersion"
                        :data-list="queryDictCodeListMap['sys_user_id']"
                    ></f-select>
                </el-form-item>
            </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="3">
            <el-form-item label-width="30px">
              <el-button
                  type="primary"
                  icon="el-icon-search"
                  @click="handleSearch"
              >查询
              </el-button
              >
              <el-button plain @click="reset">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
    </el-form>
    <el-card class="box-card last_card">
      <div class="f-p-b-15">
        <el-button
            type="primary"
            size="medium"
            icon="el-icon-plus"
            @click="handleAdd"
            v-if="buttonShowList['add']"
        >新增
        </el-button
        >
<!--        <el-button-->
<!--            type="primary"-->
<!--            size="medium"-->
<!--            class="c-theme-error-button"-->
<!--            @click="handleBatDel"-->
<!--            :disabled="selectionRows.length === 0"-->
<!--            v-if="buttonShowList['del']"-->
<!--        >批量删除-->
<!--        </el-button>-->
        <el-button
            type="primary"
            size="medium"
            @click="handleBatNoBegin"
            :disabled="selectionRows.length === 0"
            v-if="buttonShowList['setNoStart']"
        >批量设置未开始
        </el-button>
        <el-button
            type="primary" size="medium" class="c-theme-error-button"
            @click="handleBatchEme"
            :disabled="selectionRows.length === 0"
            v-if="buttonShowList['setEme']"
        >批量设置为紧急需求
        </el-button>
        <el-button
            type="primary"
            size="medium"
            @click="handleBatIng"
            :disabled="selectionRows.length === 0"
            v-if="buttonShowList['setLoading']"
        >批量设置进行中
        </el-button>
        <el-button
            type="primary"
            size="medium"
            @click="handleBatFinish"
            :disabled="selectionRows.length === 0"
            v-if="buttonShowList['批量设置已完成']"
        >批量设置已完成
        </el-button>
        <el-button
            type="primary"
            size="medium"
            @click="handleBatRefuse"
            :disabled="selectionRows.length === 0"
            v-if="buttonShowList['批量设置已拒绝']"
        >批量设置已拒绝
        </el-button>
        <el-button
            type="primary"
            size="medium"
            @click="downloadModel(url.downloadNeedsModel, '需求导入模板下载')"
            v-if="buttonShowList['downloadModel']"
        >需求导入模板下载
        </el-button>
        <el-upload
            action="/"
            :show-file-list="false"
            :http-request="uploadSectionFile"
            accept=".xls,.xlsx"
            style="margin:0 10px;display:inline"
        >
          <el-button size="medium" type="primary"
                     :loading="btnLoading"
                     v-if="buttonShowList['importFile']"
          >批量需求导入
          </el-button>
        </el-upload>
        <el-button
            type="primary"
            size="medium"
            icon="el-icon-download"
            @click="handleExportXls('需求信息')"
            v-if="buttonShowList['daochu']"
        >导出</el-button>
      </div>
      <f-table
          v-loading="loading"
          border
          ref="GTable"
          size="medium"
          row-key="id"
          class="c-th-has-bgcolor"
          :columns="buttonShowList['setEme'] ? columns2 : columns"
          :data="tableData"
          :isorter="isorter"
          :cell-style="{ 'text-align': 'center' }"
          tableHeight="66vh"
          @selection-change="handleSelectionChange"
          @sort-change="handleTableChange"
          @queryChange="handleTableQueryChange"
      >
        <template v-slot="scope">

          <template v-if="scope.column.property === 'urgency'">
            <span v-if="scope.row.urgency =='1'" style="color: red">特急</span>
            <span v-else-if="scope.row.urgency =='2'" style="color: #226be8">紧急</span>
            <span v-else-if="scope.row.urgency =='3'" style="color: #010815">常规</span>
          </template>
          <template v-else-if="scope.column.property === 'emeType'">
            <span v-if="scope.row.emeType =='1'" style="color: red">特急</span>
            <span v-else-if="scope.row.emeType =='2'" style="color: #226be8">紧急</span>
            <span v-else-if="scope.row.emeType =='3'" style="color: #010815">常规</span>
          </template>
          <template v-else-if="scope.column.property === 'taskType'">
            <span v-if="scope.row.taskType =='1'" style="color: #7da8f8">新增功能</span>
            <span v-else-if="scope.row.taskType =='2'" style="color: #2b846f">优化改进</span>
            <span v-else-if="scope.row.taskType =='3'" style="color: red">bug修复</span>
          </template>
          <template v-else-if="scope.column.property === 'status'">
            <span>{{ parseDict2(scope.row.status, "sys_need_status") }}</span>
          </template>
          <template v-else-if="scope.column.property === 'depart'">
            <span>{{ parseDict2(scope.row.depart, "sys_depart") }}</span>
          </template>
          <template v-else-if="scope.column.property === 'sjPersion'">
            <span>{{ parseDict2(scope.row.sjPersion, "sys_user_id") }}</span>
          </template>
          <template v-else-if="scope.column.property === 'fzPersion'">
            <span>{{ parseDict2(scope.row.fzPersion, "sys_user_id") }}</span>
          </template>
          <template v-else-if="scope.column.property === 'cjPersion'">
            <span>{{ parseDict2(scope.row.cjPersion, "sys_user_id") }}</span>
          </template>
          <template v-else-if="scope.column.property === 'title'">
            <span style="color: red" v-if="scope.row.isEme === '1'" v-text="scope.row.title"></span>
            <span v-else v-text="scope.row.title"></span>
          </template>
          <template v-else-if="scope.column.property === 'action'">
            <el-button
                type="text"
                @click="handleEdit(scope.row)"
                :disabled="setEditDis(scope.row)"
            >编辑</el-button>
            <el-button
                type="text"
                @click="wenzhang(scope.row)"
            >详情</el-button>
            <el-button
                type="text"
                @click="xiazai(scope.row)"
            >附件下载</el-button>
            <el-button
                type="text"
                @click="seeLog(scope.row)"
            >日志</el-button>
          </template>

        </template>
      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination
            v-bind="ipagination"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        >
        </el-pagination>
      </el-row>
    </el-card>

    <el-dialog
        :title="form.id ? '编辑' : '新增'"
        :visible.sync="edVis"
        width="60%"
        custom-class="c-custom-dialog"
        :close-on-click-modal="false"
        top="30px"
        v-if="edVis"
        append-to-body
    >
      <el-form
          :model="form"
          :rules="rules"
          ref="form"
          label-width="100px"
          size="medium"
          class="addEditBox"
      >
        <el-row class="inputs_row">
          <el-col :span="8">
            <el-form-item prop="urgency" label="紧急程度" :label-width="formLabelWidth">
              <el-select
                  clearable
                  filterable
                  v-model="form.urgency"
                  placeholder="请选择"
                  style="width: 100%"
              >
                <el-option
                    v-for="item in optionsUrgency"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8" v-if="buttonShowList['setEme']">
            <el-form-item prop="emeType" label="评定的紧急程度" :label-width="formLabelWidth">
              <el-select
                  clearable
                  filterable
                  v-model="form.emeType"
                  placeholder="请选择"
                  style="width: 100%"
              >
                <el-option
                    v-for="item in optionsUrgency"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop="taskType" label="任务类型" :label-width="formLabelWidth">
              <el-select
                  clearable
                  filterable
                  class="f-full-width"
                  v-model="form.taskType"
                  placeholder="请选择"
                  style="width: 100%"
              >
                <el-option
                    v-for="item in optionsTaskType"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop="status" label="状态" :label-width="formLabelWidth">
              <f-select
                  :data-list="queryDictCodeListMap['sys_need_status']"
                  v-model="form.status"
                  :is-need="form.status"
              ></f-select>

            </el-form-item>
          </el-col>
          <el-col :span="8" v-if="buttonShowList['setEme']">
            <el-form-item label="任务负责人" prop="fzPersion" :label-width="formLabelWidth">
              <!--  <el-input
                    class="two_texarea_limit"
                    v-model="form.fzPersion"
                    maxlength="50"
                    show-word-limit
                ></el-input>-->
              <f-select clearable filterable class="f-full-width"
                        :data-list="queryDictCodeListMap['sys_user_id']"
                        v-model="form.fzPersion"
                        placeholder="请选择" style="width:100%" :is-need="queryForm.fzPersion">
              </f-select>

            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop="depart" label="所属部门" :label-width="formLabelWidth">
              <tree-dropdown :filterable="true" v-model="form.depart" :data="optionsDepart" :value="form.depart"></tree-dropdown>
              <!--<el-input v-model="form.depart" maxlength="255" show-word-limit></el-input>-->
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="预期完成时间" :label-width="formLabelWidth" prop="yqTime">

              <el-date-picker
                  class="f-flex-1"
                  style="width:100%"
                  v-model="form.yqTime"
                  type="datetime"
                  size="small"
                  placeholder="请选择"
                  default-time="00:00:00"
                  value-format="yyyy-MM-dd HH:mm:ss">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="实际发布时间" :label-width="formLabelWidth" prop="sjfbTime">

              <el-date-picker
                  class="f-flex-1"
                  style="width:100%"
                  v-model="form.sjfbTime"
                  :picker-options="editStartOptions"
                  type="datetime"
                  size="small"
                  placeholder="请选择"
                  default-time="00:00:00"
                  value-format="yyyy-MM-dd HH:mm:ss">
              </el-date-picker>

            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="预计发布时间" :label-width="formLabelWidth" prop="yjfbTime">

              <el-date-picker
                  class="f-flex-1"
                  style="width:100%"
                  v-model="form.yjfbTime"
                  :picker-options="editStartOptions"
                  type="datetime"
                  size="small"
                  placeholder="请选择"
                  default-time="00:00:00"
                  value-format="yyyy-MM-dd HH:mm:ss">
              </el-date-picker>

            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="开始时间" :label-width="formLabelWidth" prop="ksTime">

              <el-date-picker
                  class="f-flex-1"
                  v-model="form.ksTime"
                  type="datetime"
                  size="small"
                  :picker-options="editStartOptions"
                  placeholder="请选择"
                  default-time="00:00:00"
                  value-format="yyyy-MM-dd HH:mm:ss"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <!--<el-col :span="8">
            <el-form-item label="创建时间" :label-width="formLabelWidth" prop="createTime">

              <el-date-picker
                  class="f-flex-1"
                  v-model="form.createTime"
                  type="datetime"
                  size="small"
                  :picker-options="editStartOptions"
                  placeholder="请选择"
                  default-time="00:00:00"
                  value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>-->
          <el-col :span="8">
            <el-form-item label="实际提出人" prop="sjPersion" :label-width="formLabelWidth">
              <!--<el-input
                  class="two_texarea_limit"
                  v-model="form.sjPersion"
                  maxlength="50"
                  show-word-limit
              ></el-input>-->
              <f-select clearable filterable class="f-full-width"
                        :data-list="queryDictCodeListMap['sys_user_id']"
                        v-model="form.sjPersion"
                        placeholder="请选择" style="width:100%" :is-need="form.sjPersion">
              </f-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop="needsQuality" label="需求质量" :label-width="formLabelWidth">
              <f-select
                  :data-list="queryDictCodeListMap['sys_need_quality']"
                  v-model="form.needsQuality"
                  :is-need="form.needsQuality"
              ></f-select>
            </el-form-item>
          </el-col>
          <el-col :span="16">
            <el-form-item label="备注" :label-width="formLabelWidth">
              <el-input
                  class="two_texarea_limit"
                  v-model="form.remark"
                  maxlength="50"
                  show-word-limit

              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item prop="title" label="标题" :label-width="formLabelWidth">
              <el-input
                  class="two_texarea_limit"
                  v-model="form.title"
                  maxlength="150"
                  show-word-limit

              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item prop="workPlace" label="工作场景" :label-width="formLabelWidth">
              <el-input
                  class="padding_right_area"
                  v-model="form.workPlace"
                  maxlength="255"
                  show-word-limit
                  placeholder="描述工作进行时处理的相关信息、节点，是否涉及其他部门的工作。"
                  type="textarea"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item prop="ren" label="人员" :label-width="formLabelWidth">
              <el-input

                  v-model="form.ren"
                  maxlength="100"
                  show-word-limit
                  auto-size
                  placeholder="明确提出的需求是涉及什么岗位、涉及人数有多少"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item prop="workProcess" label="工作流程" :label-width="formLabelWidth">
              <el-input
                  class="padding_right_area"
                  type="textarea"
                  v-model="form.workProcess"
                  maxlength="255"
                  auto-size
                  show-word-limit
                  placeholder="描述当前工作的背景及在系统页面是如何操作"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item prop="mayProblem" label="出现的问题及影响" :label-width="formLabelWidth">
              <el-input
                  class="padding_right_area"
                  type="textarea"
                  v-model="form.mayProblem"
                  maxlength="255"
                  auto-size
                  show-word-limit
                  placeholder="问题-例如重复的机械动作、数据信息错误、工作冲突等；影响-例如导致支出了额外的时间成本、容易发生信息误判等"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item prop="completeDesc" label="需要实现的效果" :label-width="formLabelWidth">
              <el-input
                  class="padding_right_area"
                  type="textarea"
                  v-model="form.completeDesc"
                  maxlength="255"
                  auto-size
                  show-word-limit
                  placeholder="描述想要解决问题的形式，例如在页面增加审批的按钮、导出的报表增加字段等。涉及复杂技术的，研发部会按照进行技术评估与提出需求的同事沟通确认。"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item prop="shejiPages" label="涉及的页面" :label-width="formLabelWidth">
              <el-input
                  class="padding_right_area"
                  type="textarea"
                  v-model="form.shejiPages"
                  maxlength="255"
                  auto-size
                  show-word-limit
                  placeholder="在哪个页面上修改、或者需要重新设计一个功能模块"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item prop="excuseOther" label="是否影响其他部门" :label-width="formLabelWidth">
              <el-input

                  v-model="form.excuseOther"
                  maxlength="100"
                  show-word-limit
                  placeholder="如果需求会影响其他部门的工作，需要该部门相关同事和部门主管共同进行确认"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item prop="communicateRemark" label="沟通备注" :label-width="formLabelWidth">
              <el-input
                  class="padding_right_area"
                  type="textarea"
                  v-model="form.communicateRemark"
                  maxlength="255"
                  auto-size
                  show-word-limit
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item prop="pauseRemark" label="暂停备注" :label-width="formLabelWidth">
              <el-input
                  class="padding_right_area"
                  type="textarea"
                  v-model="form.pauseRemark"
                  maxlength="255"
                  auto-size
                  show-word-limit
              ></el-input>
            </el-form-item>
          </el-col>


          <!--<el-col :span="24" v-if="form.pushPort.indexOf('1') !== -1">
            <el-form-item prop="introduction" label="简介">
              <el-input
                v-model="form.introduction"
                maxlength="255"
                show-word-limit
                type="textarea"
                style="padding: 0 58px 0 0"
              ></el-input>
            </el-form-item>
          </el-col>-->
          <el-col :span="24">
            <el-form-item prop="text" label="内容" ref="text2">
              <!-- <el-input v-model="form.text" type="textarea" rows="5"></el-input> -->
              <!-- 富文本编辑 -->
              <quill-editor
                  ref="text"
                  v-model="form.text"
                  class="myQuillEditor"
                  :options="quillOption"
              />
              <!-- ueditor方式 -->
              <!-- <vue-ueditor-wrap v-model="form.text" :config="myConfig" @beforeInit="addCustomButtom"></vue-ueditor-wrap> -->
            </el-form-item>
          </el-col>
          <!--<el-col :span="12" class="no_margin">
            <el-form-item prop="coverRef" label="封面" ref="coverUrl">
              <f-upload
                v-model="form.coverFile"
                list-type="picture-card"
                ref="coverRef"
                :limit="1"
                accept=".jpg,.jpeg,.img,.png,.pneg,.gif"
                :on-exceed="tishi"
                :dir="'content'"
                :isDetail="form.id ? false : true"
              ></f-upload>
            </el-form-item>
          </el-col>-->
          <el-col :span="12" class="no_margin">
            <el-form-item prop="attachRef" label="附件">
              <!-- <f-upload v-model="fileList"></f-upload> -->
              <f-upload v-model="form.attachFile" :isUseOss="true" ref="attachRef" :dir="'content'"
                        :isDetail="!form.id"></f-upload>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer">
        <el-button size="medium" @click="edVis = false">取消</el-button>
        <el-button type="primary" size="medium" @click="handleSubmit"
        >确定</el-button
        >
      </span>
    </el-dialog>
    <!--        <add-dialog :visible.sync="visible" :row="row" @ok="handleSearch"></add-dialog>-->
    <NeedsPreview :visible.sync="visible" :form="form" @handleSearch="handleSearch"></NeedsPreview>
    <needs-log :visible.sync="logVisible" :row="selectedRow" ></needs-log>
  </div>
</template>

<script>
import {
  deleteAction,
  getAction,
  getAllArticle,
  postAction,
  putAction,
} from "@/api/manage";
import QueryMixins from "../../mixins/QueryMixins";
import AddDialog from "./modules/AddDialog";
import Preview from "./modules/NeedsPreview";
import {httpAction} from "@/api/manage";
import {quillEditor} from "vue-quill-editor";
import quillConfig from "../../utils/quill-config";
import VueUeditorWrap from "vue-ueditor-wrap";
import TreeDropdown from '@/views/isystem/modules/TreeDropdown'
import NeedsPreview from "@/views/content/modules/NeedsPreview";
import NeedsLog from "./modules/NeedsLog.vue";

const form = {
  title: "", // 文章标题
  yqTime: "", // 发布端口
  sjTime: "", // 文章类别
  ksTime: "", // 文章类别id
  text: "",
  cjPersion: '', // 封面
  createTime: "", // 封面id
  fzPersion: "",
  sjPersion: "",
  urgency: "",
  taskType: "",
  status: "",
  remark: "",
  fileList: [], // 附件
  attachFileId: [], // 附件id
};
export default {
  name: "NeedsManege",
  mixins: [QueryMixins],
  components: {
    NeedsPreview,
    AddDialog,
    Preview,
    quillEditor,
    VueUeditorWrap,
    TreeDropdown,
    NeedsLog
  },
  data() {
    return {
      logVisible:false,
      selectedRow:{},
      editStartOptions: {
        disabledDate: time => {
          if (!this.queryForm.createTimeEnd) {
            // time = new Date(time.replace(/-/g, '/'));
            return time.getTime() < new Date(1970 - 1 - 1).getTime();   //禁止选择1970年以前的日期
          } else {
            return time.getTime() > new Date(this.queryForm.createTimeEnd);
          }
        }
      },
      edVis: false,
      // 富文本配置
      content: "<h2>hello quill-editor</h2>",
      quillOption: quillConfig,
      //                 editorOption: {
      //                     modules: {
      //                         ImageExtend: {
      //                             loading: true,
      //                             name: "img",
      //                             action: "",
      //                             response: (res) => {
      //                                 console.log(res);
      //                                 return res.info;
      //                             },
      //                         },
      //                         toolbar: {
      //                             container: container,
      //                             handlers: {
      //                                 image: function () {
      //                                     QuillWatch.emit(this.quill.id);
      //                                 },
      //                             },
      //                         },
      //                     },
      //                 },
      optionsUrgency: [
        {
          value: "1",
          label: "特急",
        },
        {
          value: "2",
          label: "紧急",
        },
        {
          value: "3",
          label: "常规",
        },
      ],
      optionsTaskType: [
        {
          value: "1",
          label: "新增功能",
        },
        {
          value: "2",
          label: "优化改进",
        },
        {
          value: "3",
          label: "bug修复",
        },
      ],
      needQueryDictCodes : ['sys_need_status','sys_user_id','sys_depart','sys_need_quality'],
      rules: {
        title: [
          {required: true, message: "请输入标题", trigger: "change"},
          {max: 255, message: "长度不能超过255", trigger: "change"},
        ],
        // fzPersion: [
        //   {required: true, message: "请填写任务负责人", trigger: "change"},
        // ],
        // sjPersion: [
        //   {required: true, message: "请填写实际提出人", trigger: "change"},
        // ],
        workPlace: [
          {required: true, message: "请输入工作场景", trigger: "change"},
        ],
        ren: [
          {required: true, message: "请输入人员", trigger: "change"},
        ],
        workProcess: [
          {required: true, message: "请输入工作流程", trigger: "change"},
        ],
        completeDesc: [
          {required: true, message: "请输入实现的效果", trigger: "change"},
        ],
        emeType: [
          {required: true, message: "请评定紧急程度", trigger: "change"},
        ],
        urgency: [
          {required: true, message: "请选择紧急程度", trigger: "change"},
        ],
        taskType: [
          {required: true, message: "请选择任务类型", trigger: "change"},
        ],
        status: [
          {required: true, message: "请选择状态", trigger: "change"},
        ],
        text: [{required: true, message: "请输入内容", trigger: "blur"}],
        /*articleTypeId: [
          { required: true, message: "请输入文章类别", trigger: "change" },
        ],*/
        coverRef: [
          // {
          //   validator: (rule, value, callback) => {
          //     if (this.form.coverFile.length <= 0) {
          //       callback(new Error("请上传封面"));
          //     } else {
          //       callback();
          //     }
          //   },
          //   message: "请上传封面",
          //   trigger: "change",
          // },
        ],
        // coverUrl: [
        //   { required: true, message: "请上传封面", trigger: "change" },
        // ],
        coverId: [{required: true, message: "请上传封面", trigger: "change"}],
      },
      visible: false,
      types: "",

      allArticleTypeList: [],
      columns: [
        {
          type: "selection",
          width: 50,
        },
        {
          prop: "needCode",
          label: "需求编号",
          minWidth: 60,
        },
        {
          prop: "action",
          label: "操作",
          minWidth: 120,
        },
        {
          prop: "title",
          label: "任务名称",
          sortable: "custom",
          minWidth: 150,
        },
        {
          prop: "depart",
          label: "部门",
          sortable: "custom",
        },
        {
          prop: "sjPersion",
          label: "实际提出人",
          sortable: "custom",
        },
        {
          prop: "cjPersion",
          label: "创建人",
          sortable: "custom",
        },
        {
          prop: "urgency",
          label: "紧急程度",
          sortable: "custom",
        },
        {
          prop: "taskType",
          label: "任务类型",
          sortable: "custom",
        },
        {
          prop: "status",
          label: "状态",
          sortable: "custom",
        },
        {
          prop: "remark",
          label: "备注",
          sortable: "custom",
        },
        {
          prop: "yqTime",
          label: "预期完成时间",
          sortable: "custom",
        },
        {
          prop: "yjfbTime",
          label: "预计发布时间",
          sortable: "custom",
        },
        {
          prop: "sjfbTime",
          label: "实际发布时间",
          sortable: "custom",
        },
        {
          prop: "ksTime",
          label: "开始时间",
          sortable: "custom",
        },
        {
          prop: "createTime",
          label: "创建时间",
          sortable: "custom",
        },

      ],
      columns2: [
        {
          type: "selection",
          width: 50,
        },
        {
          prop: "needCode",
          label: "需求编号",
          minWidth: 60,
        },
        {
          prop: "action",
          label: "操作",
          minWidth: 120,
        },
        {
          prop: "title",
          label: "任务名称",
          sortable: "custom",
          minWidth: 150,
        },
        {
          prop: "fzPersion",
          label: "任务负责人",
          sortable: "custom",
        },
        {
          prop: "depart",
          label: "部门",
          sortable: "custom",
        },
        {
          prop: "sjPersion",
          label: "实际提出人",
          sortable: "custom",
        },
        {
          prop: "cjPersion",
          label: "创建人",
          sortable: "custom",
        },
        {
          prop: "urgency",
          label: "紧急程度",
          sortable: "custom",
        },
        {
          prop: "emeType",
          label: "评定的紧急程度",
          sortable: "custom",
        },
        {
          prop: "taskType",
          label: "任务类型",
          sortable: "custom",
        },
        {
          prop: "status",
          label: "状态",
          sortable: "custom",
        },
        {
          prop: "remark",
          label: "备注",
          sortable: "custom",
        },
        {
          prop: "yqTime",
          label: "预期完成时间",
          sortable: "custom",
        },
        {
          prop: "yjfbTime",
          label: "预计发布时间",
          sortable: "custom",
        },
        {
          prop: "sjfbTime",
          label: "实际发布时间",
          sortable: "custom",
        },
        {
          prop: "ksTime",
          label: "开始时间",
          sortable: "custom",
        },
        {
          prop: "createTime",
          label: "创建时间",
          sortable: "custom",
        },

      ],

      form: {
        title: "", // 文章标题
        pushPort: "", // 发布端口
        articleType: "", // 文章类别
        articleTypeId: "", // 文章类别id
        text: "",
        coverUrl: '', // 封面
        coverId: "", // 封面id
        fileList: [], // 附件
        attachFileId: [], // 附件id
        coverFile: [],
        attachFile: [],
        optionsDepart: [],
        allList: [],
        allListMap: {},
      },
      url: {
        list: "/sys/needsManage/queryList",
        importNeeds: "/sys/needsManage/importNeedsManage",
        downloadNeedsModel: '/order/os/settle/Hy/in/downloadNeedsModel',
        getAllList: "/sys/needsManage/getAllList",
        isTop: "/sys/needsManage/isTop",
        delete: "/sys/needsManage/delete",
        deleteBatch: "/sys/needsManage/deleteBatch",
        noBeginBatch: "/sys/needsManage/noBeginBatch",
        emeBatch: "/sys/needsManage/emeBatch",
        ingBatch: "/sys/needsManage/ingBatch",
        finishBatch: "/sys/needsManage/finishBatch",
        refuseBatch: "/sys/needsManage/refuseBatch",
        add: "/sys/needsManage/add",
        edit: "/sys/needsManage/edit",
        queryById: "/sys/needsManage/queryById",
        batchDownload: "/sys/oss/file/batchDownload",
        vagrantsNum: "/sys/needsManage/vagrantsNum",
        exportXlsUrl: "/sys/needsManage/exportXQXls",// 导出排柜协同 暂时放在已排柜这里
      },
      isFind: true,
      attachFile: [],
      btnLoading: false,
      selectedRowKeys: [],
      formLabelWidth: '140px'
    };
  },
  methods: {
    setEditDis(row) {
      let ss = localStorage.getItem("USERINFO");
      if (ss) {
        let user = JSON.parse(ss);
        let flag = this.buttonShowList['updateNeed'];
        if (flag) {
          return false;
        } else if(['2', '3'].indexOf(row.status) !== -1) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    // 重置清空文章类别数组再调用公共的清空组件
    reset() {
      this.allArticleTypeList = [];
      this.handleReset();
    },
    tishi() {
      this.$message.warning("亲,一个文章只能有一张封面哦，不能贪心");
    },
    selectPushPort(val) {
      console.log("端口类型", val, typeof val, 'typeof val');
      const params = {
        pushPort: (typeof val == 'string') ? val : val.join(','),
      };
      if (val != null && val != "" && val != undefined) {
        getAllArticle(params).then((response) => {
          //给allArticleTypeList数组赋值
          this.allArticleTypeList = response.result;
          console.log("文章类别数组", this.allArticleTypeList);
        });
      }
    },
    handleAdd() {
      this.form = {
        title: "", // 文章标题
        pushPort: "", // 发布端口
        articleType: "", // 文章类别
        text: "",
        coverUrl: '', // 封面
        coverId: "", // 封面id
        fileList: [], // 附件
        attachFileId: [], // 附件id
        coverFile: [],
        attachFile: [],
        emeType:'',
        workPlace:'',
        ren:'',
        mayProblem:'',
        completeDesc:'',
        shejiPages:'',


      };
      this.edVis = true;
    },
    // 编辑/新增
    handleEdit(row) {
      console.log("这一行数据", row);
      // 如果是编辑，就钻取这一行的值，是新增就清空

      getAction(this.url.queryById, {id: row.id}).then((res) => {
        this.form = res.result;
        if (this.form.attachFile) {
          let flag = true;
          for (var i = 0; i < this.form.attachFile.length; i++) {
            if (this.form.attachFile[i]) {
              flag = false;
            }
          }
          if (flag) {
            this.form.attachFile = [];
          }
        } else {
          this.form.attachFile = [];
        }
        if (!this.form.coverFile) {
          this.form.coverFile = [];
        } else {
          let flag = true;
          for (var i = 0; i < this.form.coverFile.length; i++) {
            if (this.form.coverFile[i]) {
              flag = false;
            }
          }
          if (flag) {
            this.form.coverFile = [];
          }
        }

        //this.selectPushPort(this.form.pushPort);
        this.edVis = true;
      });
      //初始化下拉框
      //回显附件名字操作
    },
    // handleTop({id, title}) {
    //     this.$confirm(`确定删除置顶【${title}】?`, '提示', {
    //         confirmButtonText: '确定',
    //         cancelButtonText: '取消',
    //         type: 'success',
    //         closeOnClickModal: false,
    //     }).then(() => {
    //         getAction(this.url.isTop, {id}).then(res => {
    //             this.$message({
    //                 type: 'success',
    //                 message: res.message
    //             })
    //             this.handleSearch()
    //         })
    //     }).catch(() => {

    //     })
    // },
    // 批量海运应付导入
    uploadSectionFile(param) {
      this.btnLoading = true;
      //file就是当前添加的文件
      var fileObj = param.file;
      //FormData 对象
      var form = new FormData();
      //文件对象，key是后台接受的参数名称
      form.append("file", fileObj);
      //执行上传方法
      console.log("执行上传方法");
      postAction(this.url.importNeeds, form)
          .then((res) => {
            //这里做上传之后的操作
            if (res.success) {
              this.$message.success(res.message);
              this.initSettleList();
              this.init();
            }

          }).finally(() => {
        this.btnLoading = false;
        this.handleReset();
      })

    },
    downloadModel(url, fileName) {
      let params = {...this.queryForm, ...this.filterForm}
      postAction(url, params, {responseType: 'blob'})
          .then(response => {
            if (!response) {
              return
            }
            const blob = new Blob([response])
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              navigator.msSaveBlob(blob, fileName)
            } else {
              let u = window.URL.createObjectURL(response)
              let aLink = document.createElement('a')
              aLink.style.display = 'none'
              aLink.href = u
              aLink.setAttribute('download', fileName + '.xls')
              document.body.appendChild(aLink)
              aLink.click()
              document.body.removeChild(aLink)
              window.URL.revokeObjectURL(u)
            }

          })
    },
    handleSubmit() {
      let url = this.url.add;
      let method = "POST";

      if (this.form.id) {
        url = this.url.edit;
        method = "PUT";
        this.form.coverUrl == null ? this.form.coverId = '' : ''
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          console.log("上传获取数据", this.form);

          //将上传的集合 转为字符串
          let urllist = [];
          console.log("封面地址", this.form.coverFile);
          if (this.form.coverFile) {
            for (var i = 0; i < this.form.coverFile.length; i++) {
              if (i == 0) {
                this.form.coverId = this.form.coverFile[i].id;
                this.form.coverUrl = this.form.coverFile[i].url;
              }
            }
            if (this.$refs["coverUrl"]) {
              this.$refs["coverUrl"].clearValidate();
            }
          }

          let attchlist = [];
          if (this.form.attachFile) {
            for (var i = 0; i < this.form.attachFile.length; i++) {
              let id = this.form.attachFile[i].id;
              attchlist.push(id);
            }
          }

          var nowDate = new Date();
          var endDate = Date.parse('2023-02-01 00:00:00');
          // //条件为未开始的需求 才判断这个 或者 如果新增
          // if(nowDate < endDate && this.form.status === '1' || (!this.form.id)) {
          //   if (this.form.yjfbTime == null || this.form.yjfbTime == undefined || this.form.yjfbTime == '') {
          //     this.$message.warning("请选择预计发布日期，年前将现有需求清零，年前暂不排期！");
          //     return;
          //   }
          //   var s1 = Date.parse(this.form.yjfbTime);
          //
          //
          //   if (s1 < endDate) {
          //     this.$message.warning("请选择2023-02-01以后的发布日期，年前将现有需求清零，年前暂不排期！");
          //     return;
          //   }
          // }

          this.form.pushPort = typeof this.form.pushPort == 'object' ? this.form.pushPort.join(',') : this.form.pushPort;
          this.form.articleType = this.parseArticleType(this.form.articleTypeId);
          this.form.attachFileIds = attchlist.join(",");
          console.log("校验通过，填充的对象", this.form);
          httpAction(url, method, this.form).then((res) => {
            this.$message({
              type: "success",
              message: res.message,
            });
            this.edVis = false;
            this.handleSearch();
          });
        }
      });
    },
    handleDel({id, title}) {
      this.$confirm(`确定删除${title}?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        closeOnClickModal: false,
      })
          .then(() => {
            deleteAction(this.url.delete, {id}).then((res) => {
              this.$message({
                type: "success",
                message: res.message,
              });
              this.handleSearch();
            });
          })
          .catch(() => {
          });
    },
    //文章预览
    wenzhang(row) {
      getAction(this.url.queryById, {id: row.id}).then((res) => {
        this.form = res.result;
        if (res.success) {
          console.log('浏览量')
          getAction(this.url.vagrantsNum, {id: row.id}).then((res) => {
            if (res.success) {
              console.log('浏览量+1')
              // this.handleSearch();
            }
          });
        }
        if (this.form.attachFile) {
          let flag = true;
          for (var i = 0; i < this.form.attachFile.length; i++) {
            if (this.form.attachFile[i]) {
              this.form.attachFile[i].name = this.form.attachFile[i].fileName;
              flag = false;
            }
          }
          if (flag) {
            this.form.attachFile = [];
          }
        } else {
          this.form.attachFile = [];
        }
        if (!this.form.coverFile) {
          this.form.coverFile = [];
        } else {
          let flag = true;
          for (var i = 0; i < this.form.coverFile.length; i++) {
            if (this.form.coverFile[i]) {
              flag = false;
            }
          }
          if (flag) {
            this.form.coverFile = [];
          }
        }
        this.visible = true;
      });
    },
    //查看日志
    seeLog(row){
      this.selectedRow = row;
      this.logVisible = true;
    },
    handleBatDel() {
      this.$confirm("确定删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
        closeOnClickModal: false,
      })
          .then(() => {
            deleteAction(this.url.deleteBatch, {
              ids: this.selectedRowKeys.join(","),
            }).then((res) => {
              this.$message({
                type: "success",
                message: res.message,
              });
              this.handleSearch();
            });
          })
          .catch(() => {
          });
    },
    handleBatchEme() {
      this.$confirm("确定设置为紧急需求？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "success",
        closeOnClickModal: false,
      })
          .then(() => {
            postAction(this.url.emeBatch,this.selectedRowKeys).then((res) => {
              this.$message({
                type: "success",
                message: res.message,
              });
              this.handleSearch();
            });
          })
          .catch(() => {
          });
    },
    handleBatNoBegin() {
      this.$confirm("确定设置为未开始？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "success",
        closeOnClickModal: false,
      })
          .then(() => {
            putAction(this.url.noBeginBatch + '?ids=' + this.selectedRowKeys.join(',')).then((res) => {
              this.$message({
                type: "success",
                message: res.message,
              });
              this.handleSearch();
            });
          })
          .catch(() => {
          });
    },

    handleBatIng() {
      this.$confirm("确定设置为进行中？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "success",
        closeOnClickModal: false,
      })
          .then(() => {
            putAction(this.url.ingBatch + '?ids=' + this.selectedRowKeys.join(',')).then((res) => {
              this.$message({
                type: "success",
                message: res.message,
              });
              this.handleSearch();
            });
          })
          .catch(() => {
          });
    },

    handleBatFinish() {
      this.$confirm("确定设置为已完成？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "success",
        closeOnClickModal: false,
      })
          .then(() => {
            putAction(this.url.finishBatch + '?ids=' + this.selectedRowKeys.join(',')).then((res) => {
              this.$message({
                type: "success",
                message: res.message,
              });
              this.handleSearch();
            });
          })
          .catch(() => {
          });
    },

    handleBatRefuse() {
      this.$confirm("确定设置为已拒绝？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "success",
        closeOnClickModal: false,
      })
          .then(() => {
            putAction(this.url.refuseBatch + '?ids=' + this.selectedRowKeys.join(',')).then((res) => {
              this.$message({
                type: "success",
                message: res.message,
              });
              this.handleSearch();
            });
          })
          .catch(() => {
          });
    },

    async handleGetDepartData() {
      await getAction('/sys/sysDepart/queryTreeList').then(res => {
        console.log('获取所属部门', res)
        this.optionsDepart = res.result;
      })
    },
    //获取所有文章类别
    initAllArticle() {
      getAllArticle()
          .then((res) => {
            if (res.success) {
              for (var i = 0; i < res.result.length; i++) {
                var obj = {};
                obj.value = res.result[i].id;
                obj.label = res.result[i].articleType;
                this.allArticleTypeList.push(obj);
              }
            }
          })
          .catch((err) => {
            this.$message.error(err);
          });
    },
    //附件下载
    xiazai(row) {
      this.attachFile = [];
      getAction(this.url.queryById, {id: row.id}).then((res) => {
        if (res.success) {
          this.attachFile = res.result.attachFile;
          if (null == this.attachFile || this.attachFile.length <= 0) {
            this.$message.warning("暂无附件");
          } else {
            postAction(this.url.batchDownload, this.attachFile, {
              responseType: "blob",
            }).then((response) => {
              let u = window.URL.createObjectURL(response);
              let aLink = document.createElement("a");
              aLink.style.display = "none";
              aLink.href = u;
              aLink.setAttribute("download", row.title + "的附件.zip");
              document.body.appendChild(aLink);
              aLink.click();
              document.body.removeChild(aLink);
              window.URL.revokeObjectURL(u);
            });
          }
        }
      });
    },
  },
  created() {
    this.quillOption.placeholder = '工作场景：描述工作进行时处理的相关信息、节点，是否涉及其他部门的工作。\n' +
        '人员：明确提出的需求是涉及什么岗位、涉及人数有多少。\n' +
        '工作流程：描述当前工作的背景及在系统页面是如何操作。\n' +
        '出现的问题及影响：问题-例如重复的机械动作、数据信息错误、工作冲突等；影响-例如导致支出了额外的时间成本、容易发生信息误判等。\n' +
        '需要实现的效果：描述想要解决问题的形式，例如在页面增加审批的按钮、导出的报表增加字段等。涉及复杂技术的，研发部会按照进行技术评估与提出需求的同事沟通确认。\n' +
        '涉及的页面：在哪个页面上修改、或者需要重新设计一个功能模块。\n' +
        '是否影响其他部门：如果需求会影响其他部门的工作，需要该部门相关同事和部门主管共同进行确认。'
    this.handleGetDepartData();
    this.isorter.prop = 'createTime';
    this.isorter.order = 'desc';
  },
  computed: {
    coverFile() {
      return this.form.coverFile;
    },
    text() {
      return this.form.text;
    },
    buttonShowList(){
      return {
        'add':this.$btnIsShow('content/NeedsManege','0','新增'),
        'del':this.$btnIsShow('content/NeedsManege','0','批量删除'),
        'setNoStart':this.$btnIsShow('content/NeedsManege','0','批量设置未开始'),
        'setEme':this.$btnIsShow('content/NeedsManege','0','批量设置为紧急需求'),
        'setLoading':this.$btnIsShow('content/NeedsManege','0','批量设置进行中'),
        'setFinish':this.$btnIsShow('content/NeedsManege','0','批量设置已完成'),
        'setRefuse':this.$btnIsShow('content/NeedsManege','0','批量设置已完成'),
        'downloadModel':this.$btnIsShow('content/NeedsManege','0','需求导入模板下载'),
        'importFile':this.$btnIsShow('content/NeedsManege','0','批量需求导入'),
        'daochu':this.$btnIsShow('content/NeedsManege','0','导出'),
        'updateNeed':this.$btnIsShow('content/NeedsManege','0','修改需求'),

      }

    }
  },
  watch: {
    coverFile(newV) {
      console.log("选择的封面url", newV);
      if (newV && newV.length >= 1) {
        if (this.$refs["coverUrl"]) {
          this.$refs["coverUrl"].clearValidate();
        }
      }
    },
    text(newV) {
      console.log("选择的文本内容", newV);
      if (newV && newV.length >= 1) {
        if (this.$refs["text2"]) {
          this.$refs["text2"].clearValidate();
        }
      }
    },
  },
};
</script>
<style lang="scss" scope>
.input_top .el-form-item {
  margin-bottom: 2px;
}

.addEditBox {
  max-height: 740px;
  overflow: scroll;
}

.f-flex-1 {
  width: 100% !important;
}
</style>
