<template>
    <el-dialog
            :title="title"
            :visible="visible"
            width="70%"
            custom-class="c-custom-dialog"
            :close-on-click-modal="false"
            :destroy-on-close="true"
            :before-close="handleClose">
        <el-form :model="billForm" ref="billForm" :rules="rules">
            <el-row>
                <el-col :span="23">
                    <el-form-item prop="settleAccountId" label="银行账户">
                        <el-select v-model="billForm.settleAccountId"
                                   placeholder="根据币种选择银行"
                                   clearable
                                   filterable
                                   class="f-full-width">
                            <el-option v-for="(item,index) in banks" :value="item.id" :label="item.bankName" :key="item.id">
                                {{item.bankName + '-' + item.accountName + '-' + item.account + '-' + item.cur }}
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col>
                    <el-form-item prop="salesMoneyCur" label="结算币种">
                        <f-select v-model="billForm.salesMoneyCur"
                                  :dict="'bd_cur'"
                                  placeholder="请选择"
                                  clearable
                                  filterable
                                  :is-need="billForm.salesMoneyCur"
                                  @change="curChangeBank"
                                  style="width: 50%"
                                  class="f-full-width"></f-select>
                    </el-form-item>
                </el-col>
                <el-col>
                    <el-form-item prop="salesMoney" label="结算金额">
                        <el-input v-model="billForm.salesMoney" style="width: 50%" oninput="value=value.match(/[-]?\d*\.?\d{0,3}/)">
                        </el-input>
                    </el-form-item>
                </el-col>
                <el-col>
                    <el-form-item prop="payCur" label="应付币种">
                        <el-input v-model="billForm.payCur" disabled style="width: 50%">
                        </el-input>
                    </el-form-item>
                </el-col>
                <el-col>
                    <el-form-item prop="realMoney" label="应付金额">
                        <el-input v-model="billForm.realMoney" disabled style="width: 50%">
                        </el-input>
                    </el-form-item>
                </el-col>
                <el-col>
                    <el-form-item prop="removeTime" label="销账时间">
                        <el-date-picker
                                class="f-flex-1"
                                v-model="billForm.removeTime"
                                type="datetime"
                                size="small"
                                range-separator="至"
                                placeholder="开始日期"
                                :picker-options="editStartOptions"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                format="yyyy-MM-dd HH:mm:ss"
                                unlink-panels
                        >
                        </el-date-picker>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <el-button style="float: right;margin-bottom: 10px" type="primary" size="medium" @click="handleSubmit" :disabled="ifDis">确定</el-button>
        <f-table
            v-loading="loading"
            ref="GTable"
            border
            size="medium"
            row-key="id"
            class="c-th-has-bgcolor"
            :columns="columns"
            :data="billForm.containerSettleList"
            :cell-style="{ 'text-align': 'center' }"
            style="margin-top: 15px"
            @selection-change="handleSelectionChange"
        >
            <template v-slot="scope">
                <template v-if="scope.column.property === 'action'">

                    <el-tooltip class="item" effect="dark" content="点击退回至待开账单" placement="top">
                        <el-button type="text" @click="">删除</el-button>
                    </el-tooltip>
                </template>


            </template>

        </f-table>
<!--        <span slot="footer">-->
<!--            <el-button type="primary" size="medium" @click="handleSubmit" :disabled="ifDis">确定</el-button>-->
<!--        </span>-->
    </el-dialog>
</template>

<script>
    import {getAction, postAction} from '@/api/manage'
    import QueryMixins from "../../../../mixins/QueryMixins";
    import { setUpRouteUtil } from '@/utils/util';

    export default {
        name: 'noConvertBill',
        mixins: [QueryMixins],

        data() {
            const validateFileList = (rule, value, callback) => {
                if (this.billForm.uploadFileIds.length === 0) {
                    callback(new Error('请上传账单'));
                }
                callback();
            };
            return {
                editStartOptions: {
                    disabledDate(date) {
                        //disabledDate 文档上：设置禁用状态，参数为当前日期，要求返回 Boolean
                        // return date.getTime() < Date.now() - 24 * 60 * 60 * 1000;
                        return date.getTime() < new Date(1970 - 1 - 1).getTime();
                    }
                },
                // id:'',
                flag: false,
                visible: false,
                isChecked: true,
                ifDis: false,
                allKeys: [],
                treeData: [],
                banks:[],
                defaultProps: {
                    children: 'children',
                    label: 'title'
                },
                fileList: [],
                billForm: {
                    settleAccountId:'',
                    removeTime:'',
                },
                removeTime:'',
                url: {
                    list: '/sys/bdCountry/list',
                    curBanks: '/shipping/hySettleReceive/curGetBank',
                    uploadInvoice: '/shipping/hySettlePay/uploadInvoice',
                    delFile: '/shipping/hySettlePay/delFile',
                    // doRemove: '/shipping/hySettlePay/doRemove',
                    mergeBatch: "/shipping/hySettlePay/mergeBatch",
                    getContainerSettlesByApplyNos: '/shipping/hySettlePay/getContainerSettlesByApplyNos',

                },
                rules: {
                    settleAccountId: [
                        {required: true, message: '请选择实付银行', trigger: 'change'},
                    ],
                    removeTime: [
                        {required: true, message: '请选择销账时间', trigger: 'change'},
                    ],
                    salesMoney:[
                        {required: true, message: '请填写结算金额', trigger: 'change'},
                    ],
                    salesMoneyCur:[
                        {required: true, message: '请选择结算币种', trigger: 'change'},
                    ],
                },
                title: '柜子批量销账',
                settleIds: [],
                columns: [
                    {
                        type: "selection",
                        width: 50,
                    },
                    {
                        prop: "createTime",
                        label: "录入时间",
                    },
                    {
                        prop: "week",
                        label: "周期",
                        width: 150,
                    },
                    {
                        prop: "containerNo",
                        label: "柜号",
                    },
                    {
                        prop: "settlementStr",
                        label: "结算对象",
                    },
                    {
                        prop: "chineseName",
                        label: "费用中文",
                    },
                    {
                        prop: "englishName",
                        label: "费用英文",
                    },
                    {
                        prop: "moneyType",
                        label: "费用币种",
                    },
                    {
                        prop: "total",
                        label: "合计",
                    },
                    {
                        prop: "createBy",
                        label: "录入人",
                    },
                    {
                        prop: "remark",
                        label: "备注",
                    },


                ],
            }
        },
        methods: {

            //进入客服页
            intoDocpage(orderNo) {
                if (orderNo.indexOf("KD")!==-1){
                    // 对于路由不在左侧菜单中的选中菜单处理
                    setUpRouteUtil(this.$route.path, '/kdorder/Edit');
                    this.$router.push("/kdorder/Edit?orderNo=" + orderNo);
                }else {
                    // 对于路由不在左侧菜单中的选中菜单处理
                    setUpRouteUtil(this.$route.path, '/docpage/Edit');
                    this.$router.push("/docpage/Edit?orderNo=" + orderNo);
                }

            },
            handleSubmit() {
                this.$refs.billForm.validate((valid) => {
                    if (valid) {
                        this.ifDis = true
                        this.enabled()

                        postAction(this.url.mergeBatch,this.billForm).then((res) => {
                            //检测是同一个结算对象 才进行下载
                            if(res.success){


                                this.visible=false
                                this.$emit('ok');
                            }
                        })


                    }
                })
            },
            //上传文件到oss
            handleUpload(data) {
                const formData = new FormData();
                formData.append("file", data.file);

                postAction(this.url.uploadInvoice, formData).then(res => {
                    // console.log(res)
                    if (res.success) {
                        //上传成功,返回结果赋值给form对象的文件集合
                        this.billForm.uploadFileIds.push(res.result.id);
                        // console.log(this.billForm.uploadFileIds)
                        this.fileList.push(res.result);
                        this.$message.success('上传成功');
                    }else{

                    }
                })
            },
            handleRemove(file) {
                this.fileList.map((item, index) => {
                    if (item.uid == file.uid) {
                        //向服务器发送要删除的文件的路径，删除oss上的文件
                        // console.log(item)
                        postAction(this.url.delFile,{osOrderFileId:item.id, url:item.url}).then((res)=>{
                            // console.log(res)
                            //页面的上的文件也要删除
                            this.fileList.splice(index, 1)
                            this.billForm.uploadFileIds.splice(this.billForm.uploadFileIds.indexOf(item.id),1);
                        })

                    }
                })
            },
            //文件上传之前校验文件合法
            beforeUpload(file) {
                // console.log(file)
                //对文件大小进行限制
                const isLt2M = file.size / 1024 / 1024 < 15
                if (!isLt2M) {
                    this.$message.warning('文件大小不能超过15MB')
                }
                const isFile = file.name.indexOf('.') !== -1;
                if (!isFile) {
                    this.$message.warning('该文件名不合法')
                }
                return isLt2M && isFile;
            },
            handleClose() {
                // this.$emit('update:visible', false)
                // this.$refs.form.resetFields()
                this.visible = false;

            },
            enabled() {
                setTimeout(() => {
                    this.ifDis = false;
                }, 2000)

            },
            edit(ids, nos, cur, allPay) {
                // console.log('柜子销账')
                // console.log('nos',ids);
                // console.log('nos',nos);
                // console.log('payCur',cur);
                // console.log('payMoney',allPay);

                this.billForm.payCur = cur;
                this.billForm.realMoney = allPay;
                this.billForm.applyNos = nos;
                this.billForm.ids = ids;
                // this.billForm.salesMoneyCur = JSON.parse(JSON.stringify(cur))

                this.$set(this.billForm,'salesMoneyCur',JSON.parse(JSON.stringify(cur)))
                this.$set(this.billForm,'salesMoney',JSON.parse(JSON.stringify(allPay)))
                // console.log('赋值币种',this.billForm.salesMoneyCur)
                // this.billForm = row
                this.billForm.removeTimeBankId = '';

                this.curChangeBank(cur);
                // console.log('billForm',this.billForm);

                let param  = {
                    applyNos:nos
                }
                postAction(this.url.getContainerSettlesByApplyNos,param).then((res)=>{
                    // console.log(res)
                    // this.billForm.settleList = res.result.settleList
                    this.$set(this.billForm,'containerSettleList',res.result.containerSettleList)
                })

                this.visible = true

            },
            curChangeBank(val){
                // console.log(val)
                getAction(this.url.curBanks,{cur:val}).then((res)=>{
                    // console.log(res)
                    this.banks = res.result
                    this.billForm.settleAccountId = ''
                })
            },
        }
    }
</script>

<style lang="scss" scoped>
    .btn-wrap {
        text-align: right;
        padding: 10px 30px;
    }

    .content {
        height: 620px;
        padding: 6px 0;
        overflow: auto;
    }

</style>
