<template>
    <div class="main-full-content">
        <el-form class="input_top" ref="form" :model="queryForm" label-width="120px" size="medium"
                 @submit.native.prevent>
            <el-card class="box-card">
                <el-row>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="原始币种">
                            <el-input v-model="queryForm.originCur" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="兑换后币种">
                            <el-input v-model="queryForm.convertCur" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label-width="30px">
                            <el-button
                                type="primary"
                                icon="el-icon-search"
                                @click="handleSearch"
                            >查询
                            </el-button
                            >
                            <el-button plain @click="handleReset">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>
        </el-form>
        <el-card class="box-card last_card">
            <div class="f-p-b-15">
                <el-button
                    type="primary"
                    size="medium"
                    icon="el-icon-plus"
                    @click="handleAdd"
                    :disabled="$btnIsDisabled('hyFinanceManage/modules/receive/invoiceRate','0','新增')"
                    v-if="buttonShowList.add"
                >新增
                </el-button
                >
            </div>
            <f-table
                v-loading="loading"
                ref="GTable"
                border
                size="medium"
                row-key="id"
                class="c-th-has-bgcolor"
                :columns="columns"
                :data="tableData"
                :cell-style="{ 'text-align': 'center' }"
                @selection-change="handleSelectionChange"
                @sort-change="handleTableChange"
            >
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'action'">
                        <el-button type="text" @click="handleEdit(scope.row)"

                                   v-if="buttonShowList.bj"
                        >编辑
                        </el-button
                        >
                        <el-button type="text" @click="handleDele(scope.row)"

                                   v-if="buttonShowList.sc"
                        >删除
                        </el-button
                        >
                    </template>
                    <template v-else-if="scope.column.property === 'amout'">
                        <span>{{ parseDict(scope.row.amout, 'bd_cur') }}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'amoutTo'">
                        <span>{{ parseDict(scope.row.amoutTo, 'bd_cur') }}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'createBy'">
                        <span>{{ parseDict(scope.row.createBy, 'sys_create_by') }}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'updateBy'">
                        <span>{{ parseDict(scope.row.updateBy, 'sys_create_by') }}</span>
                    </template>
                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                    v-bind="ipagination"
                    layout="total, sizes, prev, pager, next, jumper"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                >
                </el-pagination>
            </el-row>
        </el-card>

        <el-dialog
            :title="form.id ? '编辑币种汇率' : '新增币种汇率'"
            :visible.sync="dialogFormVisible"
            :close-on-click-modal="false"
            v-if="dialogFormVisible"
        >
            <el-form :model="form" :rules="rules" ref="form">
                <el-form-item
                    label="原始币种"
                    :label-width="formLabelWidth"
                    prop="originCur"
                >
                    <!-- <el-input v-model="form.amout" autocomplete="off"></el-input>-->
                    <f-select
                        :isNeed="form.originCur"
                        v-model="form.originCur"
                        :dict="'bd_cur'"
                    ></f-select>
                </el-form-item>
                <el-form-item label="汇率" :label-width="formLabelWidth" prop="rate">
                    <el-input v-model="form.rate" autocomplete="off" oninput="value=value.match(/\d+\.?\d{0,5}/)"></el-input>
                </el-form-item>
                <el-form-item
                    label="兑换后的币种"
                    :label-width="formLabelWidth"
                    prop="convertCur"
                >
                    <!-- :isNeed="scope.row.amoutTo" <el-input v-model="form.amoutTo" autocomplete="off"></el-input>-->
<!--                    <f-select-->
<!--                        v-model="form.convertCur"-->
<!--                        :isNeed="form.convertCur"-->
<!--                        :dict="'bd_cur'"-->
<!--                    ></f-select>-->
                    <el-input v-model="form.convertCur" disabled></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="medium" @click="dialogFormVisible = false">取 消</el-button>
                <el-button size="medium" type="primary" @click="handleSave">确 定</el-button>
            </div>
        </el-dialog>
        <el-divider></el-divider>
<!--        <invoice-rate-module ref="fee" @ok="init" :type="'1'"></invoice-rate-module>-->
    </div>
</template>

<script>
import {deleteAction, postAction, putAction} from "@/api/manage";
import QueryMixins from "@/mixins/QueryMixins";
// import invoiceRateModule from "@/views/hyFinanceManage/modules/receive/invoiceRateModule";

export default {
    name: "Importer",
    mixins: [QueryMixins],
    components: {
        // invoiceRateModule,
    },
    data() {
        return {
            dictCodes: ["bd_cur", 'sys_create_by'],
            rules: {
                originCur: [
                    {required: true, message: "请输入原始币种", trigger: "change"},
                    {max: 50, message: "长度不能超过50", trigger: "change"},
                ],
                rate: [{required: true, message: "请输入汇率", trigger: "change"}],
                convertCur: [
                    {required: true, message: "请输入兑换后的币种", trigger: "change"},
                    {max: 50, message: "长度不能超过50", trigger: "change"},
                ],
            },
            ids: [],
            row: {},
            form: {
                convertCur: 'CNY'
            },
            formLabelWidth: "110px",
            //控制弹框显示
            dialogFormVisible: false,
            columns: [
                {
                    prop: "action",
                    label: "操作",
                },
                {
                    prop: "originCur",
                    label: "原始币种",
                },
                {
                    prop: "rate",
                    label: "汇率",
                },
                {
                    prop: "convertCur",
                    label: "兑换后币种",
                },

                {
                    prop: "creator",
                    label: "创建人",
                },
                {
                    prop: "createTime",
                    label: "创建时间",
                },
                {
                    prop: "updateBy",
                    label: "修改人",
                },
                {
                    prop: "updateTime",
                    label: "修改时间",
                },

            ],
            url: {
                list: "/shipping/invoiceRateLog/list",
                add: "/shipping/invoiceRateLog/add",
                edit: "/shipping/invoiceRateLog/edit",
                getById: "/shipping/invoiceRateLog/queryById",
                delete: "/shipping/invoiceRateLog/delete",
            },
        };
    },
    methods: {
        handleSelectionChange(selection) {
            // 多选
            let arr = [];
            this.emailList = [];
            for (let i = 0; i < selection.length; i++) {
                arr.push(selection[i]["id"]);
                this.emailList.push(selection[i]["email"]);
            }
            this.selectionRows = selection;
            this.selectedRowKeys = [...arr];
        },
        handleAdd() {
            this.form = {
                convertCur :'CNY'
            };
            this.form.convertCur = 'CNY'
            this.dialogFormVisible = true;
        },
        init() {
            // console.log('币种操作记录', this.$refs['fee']);
            if (this.$refs['fee']) {
                this.$refs['fee'].initVue();
            }
        },
        handleEdit(row) {
            //将对象转为json字符串再转回对象
            //this.form = row; 复制的是指针 仍然指向这个对象row
            this.form = JSON.parse(JSON.stringify(row));

            this.$refs.form = this.form
            this.dialogFormVisible = true;
        },
        handleSave() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    //编辑
                    if (this.form.id) {
                        putAction(this.url.edit, this.form).then((res) => {
                            if (res.success) {
                                this.$message.success(res.message);
                                //关闭弹窗
                                this.dialogFormVisible = false;
                                // localStorage.removeItem("dict:sys_cur_rate");
                                this.handleQuery();
                                this.init();
                            }
                        });
                    } else {
                        postAction(this.url.add, this.form).then((res) => {
                            if (res.success) {
                                this.$message.success(res.message);
                                //关闭弹窗
                                this.dialogFormVisible = false;
                                // localStorage.removeItem("dict:sys_cur_rate");
                                this.handleQuery();
                                this.init();
                            }
                        });
                    }
                }
            });
        },
        handleDele(row) {
            this.$confirm(`确定删除该币种吗？`, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "success",
                closeOnClickModal: false,
            })
                .then(() => {
                    deleteAction(this.url.delete, {id: row.id}).then((res) => {
                        this.$message({
                            type: "success",
                            message: res.message,
                        });
                        localStorage.removeItem("dict:sys_cur_rate");
                        this.handleQuery();
                    });
                })
                .catch(() => {
                });
        },
        handleBatDel() {
            // console.log("获取到的id：" + this.selectedRowKeys),
                this.$confirm("确定删除?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "error",
                    closeOnClickModal: false,
                })
                    .then(() => {
                        deleteAction(this.url.deleteBatch, {
                            ids: this.selectedRowKeys.join(","),
                        }).then((res) => {
                            this.$message({
                                type: "success",
                                message: res.message,
                            });
                            this.handleSearch();
                        });
                    })
                    .catch(() => {
                    });
        },
        handleReset() {
            this.queryForm = {};
            this.ipagination.currentPage = 1;
            this.handleQuery();
        },
    },
    created() {
    },
    computed:{
      buttonShowList() {
        return {
          'add':this.$btnIsShow('hyFinanceManage/modules/receive/invoiceRate','0','新增'),
          'bj':this.$btnIsShow('hyFinanceManage/modules/receive/invoiceRate','1','编辑'),
          'sc':this.$btnIsShow('hyFinanceManage/modules/receive/invoiceRate','1','删除'),
        }
      }
    }
};
</script>

<style lang="scss" scoped>
</style>
