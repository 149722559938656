<template>
    <el-dialog
            title="开单"
            :visible="visible"
            width="30%"
            custom-class="c-custom-dialog"
            :before-close="handleClose"
            append-to-body
            :close-on-click-modal="false">
        <el-form class="addEditBox" :model="form" :rules="rules" ref="form" label-width="100px" size="medium">

            <el-form-item prop="warehouseId" label="仓库" :label-width="formLabelWidth">
                <f-select v-model="form.warehouseId" :is-need="form.warehouseId" @changet="getWarehouseName"
                          dict="delivery_point" :data-list="deliveryPointList"></f-select>
            </el-form-item>
             <el-form-item prop="createTime" label="开单时间" :label-width="formLabelWidth">
                <el-date-picker
                    class="f-flex-1"
                    v-model="form.createTime"
                    type="datetime"
                    size="small"
                    placeholder="开单时间"
                    value-format="yyyy-MM-dd HH:mm:ss"
                >
                </el-date-picker>
            </el-form-item>
            <el-form-item prop="useCarType" label="车辆性质" :label-width="formLabelWidth">
                <f-select v-model="form.useCarType" :is-need="form.useCarType"
                          dict="warehouse_receipts_use_car_type" :disabled="form.selectedBook==='1'"></f-select>
            </el-form-item>

            <el-form-item prop="payType" label="付款方式" :label-width="formLabelWidth">
                <f-select v-model="form.payType" :is-need="form.payType" dict="warehouse_receipts_pay_type"></f-select>
            </el-form-item>


            <el-form-item prop="isOvertime" label="是否加班" :label-width="formLabelWidth">
                <el-radio-group v-model="form.isOvertime">
                    <el-radio :label="'1'">是</el-radio>
                    <el-radio :label="'0'">否</el-radio>
                </el-radio-group>
            </el-form-item>


            <el-form-item prop="isMixgoods" label="是否混货" :label-width="formLabelWidth">
                <el-radio-group v-model="form.isMixgoods">
                    <el-radio :label="'1'">是</el-radio>
                    <el-radio :label="'0'">否</el-radio>
                </el-radio-group>
            </el-form-item>


            <el-form-item prop="printNum" label="打单份数" :label-width="formLabelWidth">
                <el-input v-model="form.printNum" type="number"
                          onkeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))">
                    <i slot="suffix" style="font-style: normal; margin-right: 1px">份</i>
                </el-input>
            </el-form-item>


            <el-form-item prop="carType" label="车型" :label-width="formLabelWidth">
                <f-select v-model="form.carType" :is-need="form.carType" dict="reserved_model"></f-select>
            </el-form-item>


            <el-form-item prop="licencePlate" label="车牌号" :label-width="formLabelWidth">
                <el-input v-model="form.licencePlate" maxlength="20" show-word-limit></el-input>
            </el-form-item>


            <el-form-item prop="driverPhone" label="手机号" :label-width="formLabelWidth">
                <el-input v-model="form.driverPhone" maxlength="20" show-word-limit></el-input>
            </el-form-item>


            <el-form-item prop="orderNos" label="订单号" :label-width="formLabelWidth">
                 <span v-for="(item,index) in orderNoList" :key="index">
                    <span v-if="item.hasQianzhiDadan === '1'" style="color: red">{{item.orderNo}}(快递已打单)<span v-if="index!==orderNoList.length-1">,</span></span>
                    <span v-else>{{item.orderNo}}<span v-if="index!==orderNoList.length-1">,</span></span>
                    </span>
                <!-- <span>{{form.orderNos}}</span> -->
            </el-form-item>
              <el-form-item prop="takeNoL" label="用车编号" :label-width="formLabelWidth">
                 <span v-for="(item,index) in takeNoList" :key="index">
                    <span v-if="index!==takeNoList.length-1">{{item}},</span>
                    <span v-else>{{item}}</span>
                    </span>
            </el-form-item>
            <div v-if="ishasQianzhiDadan" style="color: red">提醒:此批次开单订单有UPS前置打单，请收货组检查对应订单是否已贴UPS面单</div>

        </el-form>
        <span slot="footer">
            <el-button size="medium" @click="handleClose">取消</el-button>
            <el-button type="primary" size="medium" @click="handleSubmit">开单</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import {getAction,postAction} from '@/api/manage'
    import QueryMixins from "@/mixins/QueryMixins";
    import {validatePrintNum} from "@/utils/validator";

    export default {
        name: 'addWarehouseReceipts',
        mixins: [QueryMixins],
        props: {
          warehouseId:{
            type:String,
            default:""
          },
            visible: {
                type: Boolean
            },
            orderNos: {
                type: String,
                default: ''
            },
            selectedBook: {
                type: String,
                default: ''
            },
            selectedPhone: {
                type: String,
                default: ''
            },
            selectedCarNumber: {
                type: String,
                default: ''
            },
            selectionRowsDialog:{
                 type: Array,
                default: ()=>[]
            }
        },
        data() {
            return {
                disableMixinMounted: true,
                form: {
                    createTime:''
                },
              deliveryPointList:[],
                formLabelWidth: '100px',
                rules: {
                    useCarType: [
                        {required: true, message: '请选择车辆性质', trigger: 'change'},
                    ],
                    payType: [
                        {required: true, message: '请选择付款方式', trigger: 'change'},
                    ],
                    isOvertime: [
                        {required: false, message: '请选择是否加班', trigger: 'change'},
                    ],
                    isMixgoods: [
                        {required: false, message: '请选择是否混货', trigger: 'change'},
                    ],
                    printNum: [
                        {required: true, message: '请输入打单份数', trigger: 'change'},
                        {validator:validatePrintNum,trigger:'change'}
                    ],
                    carType: [
                        {required: false, message: '请选择车型', trigger: 'change'},
                    ],
                    warehouseId: [
                        {required: true, message: '请选择仓库', trigger: 'change'},
                    ],
                    createTime: [
                        {required: true, message: '请选择开单时间', trigger: 'change'},
                    ],
                },
                url: {
                    add: '/warehouse/receipt/addReceiptOrder',
                    // getWarehouseReceipts: '/file/jasper/getWarehouseReceipts',
                    getWarehouseReceipts: '/file/jasper/recordPrintSingle',
                    getWarehoueIdAndWarehouseList:'/warehouse/receipt/queryAcquiesceStash',
                },
                ishasQianzhiDadan:false,
                takeNoList:[],
                orderNoList:[]

            }
        },
        watch: {
            visible(val) {
                if (val && this.orderNos) {
                  this.$set(this.form, 'orderNos', this.orderNos);
                  if (this.selectedBook === '1') {
                    this.$set(this.form, 'useCarType', '2');
                  } else {
                    this.$set(this.form, 'useCarType', '4');
                  }
                   this.takeNoList = []
                    this.orderNoList = this.selectionRowsDialog.filter((item, index, self) => {
    return self.findIndex(t => t.orderNo === item.orderNo) === index;
});
                this.ishasQianzhiDadan = this.selectionRowsDialog.some(item=>item.hasQianzhiDadan === '1')
                this.selectionRowsDialog.forEach(item=> {
                    if(item.takeNo && !this.takeNoList.includes(item.takeNo)) {
                     this.takeNoList.push(item.takeNo)
                    }
                })
                console.log( this.takeNoList,' this.takeNoList')
                  this.$set(this.form, 'selectedBook', this.selectedBook);
                  this.$set(this.form, 'payType', "2");
                  this.$set(this.form, 'printNum', "0");
                   this.$set(this.form, 'takeNos', this.takeNoList);
                  getAction(this.url.getWarehoueIdAndWarehouseList)
                  .then(res=>{
                    if (res && res.result) {
                      this.$set(this.form,'warehouseId',res.result.warehouseId);
                      this.deliveryPointList = res.result.deliveryPointList;
                    }

                  })
                let now = new Date();
let year = now.getFullYear();
let month = String(now.getMonth() + 1).padStart(2, '0');
let day = String(now.getDate()).padStart(2, '0');
let hours = String(now.getHours()).padStart(2, '0');
let minutes = String(now.getMinutes()).padStart(2, '0');
let seconds = String(now.getSeconds()).padStart(2, '0');

this.form.createTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
                    //勾选的订单号进行赋值

                    // this.$set(this.form,'licencePlate',this.selectedCarNumber || '');
                    // this.$set(this.form,'driverPhone',this.selectedPhone || '');

                }
            },
        },
        methods: {
            getWarehouseName(val) {
                this.$set(this.form, 'warehouseName', val);
            },
            handleSubmit() {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        postAction(this.url.add, this.form).then((res) => {
                            this.$message({
                                type: 'success',
                                message: res.message
                            })
                            getAction(this.url.getWarehouseReceipts, {id: res.result.id}).then((res) => {
                            })
                            this.form = {};
                            this.handleClose();
                            this.$emit('ok');
                        })
                    }
                })
            },
            handleClose() {
                this.form={
                    createTime:''
                }
                this.$emit('update:visible', false)
            }
        },
        created() {
        }
    }
</script>

<style lang="scss" scoped>
    ::v-deep
    .el-dialog__body {
        padding: 20px 40px 20px 6px;
    }

    .addEditBox {
        max-height: 615px;
        overflow: scroll;
    }
</style>
