<template>
  <el-dialog
    title="新增转仓"
    :visible="visible"
    width="50%"
    custom-class="c-custom-dialog"
    :before-close="handleClose"
    append-to-body
    :close-on-click-modal="false"
  >
    <el-form
      class="addEditBox"
      :model="form"
      :rules="rules"
      ref="form"
      size="medium"
    >
      <el-row class="flex_center" :gutter="30">
        <el-col style="margin-bottom: 10px">
          <div>
            <span style="margin-left: 80px">合计</span>
            <span style="margin-left: 114px">件数：{{ totalNumber }}</span>
            <span style="margin-left: 108px">重量：{{ totalWeight }}</span>
            <span style="margin-left: 104px">体积：{{ totalVolume }}</span>
          </div>
          <el-table
            :data="selectOrderList"
            border
            max-height="200"
            ref="innerTable"
          >
            <el-table-column prop="orderNo" label="订单号">
              <template slot-scope="scope">
                <span>{{ scope.row.orderNo }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="totalNum" label="实际件数">
              <template slot-scope="scope">
                <span>{{ scope.row.totalNum }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="totalVolume" label="实际体积">
              <template slot-scope="scope">
                <span>{{ scope.row.totalVolume }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="totalWeight" label="实际重量">
              <template slot-scope="scope">
                <span>{{ scope.row.totalWeight }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="deliveryPointName" label="交货仓库">
              <template slot-scope="scope">
                <span>{{ scope.row.deliveryPointName }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="lclPlaceName" label="拼柜仓库">
              <template slot-scope="scope">
                <span>{{ scope.row.lclPlaceName }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="volumeLocation" label="货物所在仓">
              <template slot-scope="scope">
                <span>{{ scope.row.volumeLocation }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="createBy" label="录入人">
              <template slot-scope="scope">
                <span>{{ scope.row.createBy }}</span>
              </template>
            </el-table-column>
            <el-table-column>
              <template slot-scope="scope">
                <el-button type="text" @click="removeOneLine(scope.$index)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </el-col>

        <!--            转仓类型-->
        <el-col :span="13">
          <el-form-item
            prop="rollType"
            label="转仓类型"
            key="rollType"
            :label-width="formLabelWidth"
          >
            <el-select
              v-model="form.rollType"
              :is-need="form.rollType"
              clearable
              filterable
            >
              <el-option value="内转费" label="内转费"></el-option>
              <el-option value="中转费" label="中转费"></el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="13">
          <el-form-item
            prop="originatingWarehouseId"
            label="起运仓库"
            :label-width="formLabelWidth"
            key="originatingWarehouseId"
          >
            <f-select
              v-model="form.originatingWarehouseId"
              :is-need="form.originatingWarehouseId"
              :data-list="selectWarehouseList"
              @change="
                changeWarehouseInfoByIdAndName(
                  form.originatingWarehouseId,
                  'originatingWarehouseInfo'
                )
              "
            ></f-select>
            <el-input
              v-model="form.originatingWarehouseAddress"
              type="textarea"
              :rows="2"
              style="margin-top: 5px"
              maxlength="155"
              show-word-limit
            ></el-input>
            <!-- <div>{{ form.originatingWarehouseInfo }}</div> -->
          </el-form-item>
        </el-col>

        <el-col :span="13">
          <el-form-item
            prop="arriveAtWarehouseId"
            label="到达仓库"
            :label-width="formLabelWidth"
            key="arriveAtWarehouseId"
          >
            <f-select
              v-model="form.arriveAtWarehouseId"
              :is-need="form.arriveAtWarehouseId"
              :data-list="selectWarehouseList"
              @change="
                changeWarehouseInfoByIdAndName(
                  form.arriveAtWarehouseId,
                  'arriveAtWarehouseInfo'
                )
              "
            ></f-select>
            <el-input
              v-model="form.arriveAtWarehouseAddress"
              type="textarea"
              :rows="2"
              style="margin-top: 5px"
              maxlength="155"
              show-word-limit
            ></el-input>
            <!-- <div>{{ form.arriveAtWarehouseInfo }}</div> -->
          </el-form-item>
        </el-col>

        <el-col :span="13">
          <el-form-item
            prop="originatingWarehouseTime"
            label="起运仓时间"
            :label-width="formLabelWidth"
            key="originatingWarehouseTime"
          >
            <el-date-picker
              id="getOriginatingWarehouseTime"
              v-model="form.originatingWarehouseTime"
              type="datetime"
              :format="'yyyy-MM-dd HH:mm'"
              placeholder="选择时间"
              style="width: 100%"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>

        <el-col :span="13">
          <el-form-item
            prop="arriveAtWarehouseTime"
            label="到达仓时间"
            :label-width="formLabelWidth"
            key="arriveAtWarehouseTime"
          >
            <el-date-picker
              id="getArriveAtWarehouseTime"
              v-model="form.arriveAtWarehouseTime"
              type="datetime"
              :format="'yyyy-MM-dd HH:mm'"
              placeholder="选择时间"
              style="width: 100%"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>

        <el-col :span="13">
          <el-form-item
            prop="palletNum"
            label="托盘数"
            :label-width="formLabelWidth"
            key="palletNum"
          >
            <el-input v-model="form.palletNum" type="number"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="13">
          <el-form-item
            prop="remark"
            label="备注"
            :label-width="formLabelWidth"
            key="remark"
          >
            <el-input
              v-model="form.remark"
              maxlength="500"
              show-word-limit
              type="textarea"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer">
      <el-button size="medium" @click="handleClose">取消</el-button>
      <el-button type="primary" size="medium" @click="handleSubmit"
        >确认</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { postAction, getAction } from "@/api/manage";
import { validateCtn } from "../../../utils/validator";
import { baoliuwei } from "../../../utils/caculate";

export default {
  name: "addWarehouseRollDialog",
  props: {
    visible: {
      type: Boolean,
    },
    rows: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      totalNumber: 0,
      totalWeight: 0,
      totalVolume: 0,
      form: {},
      formLabelWidth: "110px",
      selectOrderList: [],
      selectWarehouseList: [],
      selectWarehouseMap: {},
      rules: {
        rollType: [
          { required: true, message: "请选择转仓类型", trigger: "change" },
        ],
        originatingWarehouseId: [
          { required: true, message: "请选择起运仓库", trigger: "change" },
        ],
        arriveAtWarehouseId: [
          { required: true, message: "请选择到达仓库", trigger: "change" },
        ],
        originatingWarehouseTime: [
          { required: true, message: "请选择起运仓时间", trigger: "change" },
        ],
        arriveAtWarehouseTime: [
          { required: true, message: "请选择到达仓时间", trigger: "change" },
        ],
        palletNum: [{ validator: validateCtn, trigger: "change" }],
      },
      url: {
        //生成其他出库数据
        addWarehouseRoll: "/warehouse/takeDelivery/addRoll",
        getWarehouseSelectVOs: "/warehouse/takeDelivery/getWarehouseSelectVOs",
      },
    };
  },
  watch: {
    visible(val) {
      if (val) {
        this.selectOrderList = this.rows;
        getAction(this.url.getWarehouseSelectVOs).then((res) => {
          if (res && res.success && res.result) {
            this.selectWarehouseList = res.result.vos;
            this.selectWarehouseMap = res.result.voMap;
          }
        });
        this.caculateNumberAndWeightAndVolume();
      }
    },
  },
  methods: {
    caculateNumberAndWeightAndVolume() {
      this.totalNumber = 0; //实际件数
      this.totalWeight = 0; //实际重量
      this.totalVolume = 0; //实际体积
      if (this.selectOrderList && this.selectOrderList.length > 0) {
        this.selectOrderList.forEach((item) => {
          if (item.totalNum) {
            this.totalNumber += Number(item.totalNum);
          }
          if (item.totalWeight) {
            this.totalWeight += Number(item.totalWeight);
          }
          if (item.totalVolume) {
            this.totalVolume += Number(item.totalVolume);
          }
        });
      }
      this.totalWeight = baoliuwei(this.totalWeight, 3);
      this.totalVolume = baoliuwei(this.totalVolume, 3);
    },
    removeOneLine(index) {
      this.selectOrderList.splice(index, 1);
      this.caculateNumberAndWeightAndVolume();
    },
    //根据 仓库id 赋值 详情
    changeWarehouseInfoByIdAndName(val, name) {
      if (this.selectWarehouseMap && this.selectWarehouseMap[val]) {
        this.$set(this.form, name, this.selectWarehouseMap[val]["info"]);
        if (name === "originatingWarehouseInfo") {
          this.$set(
            this.form,
            "originatingWarehouseAddress",
            this.selectWarehouseMap[val]["info"]
          );
        } else if (name === "arriveAtWarehouseInfo") {
          this.$set(
            this.form,
            "arriveAtWarehouseAddress",
            this.selectWarehouseMap[val]["info"]
          );
        }
      }
    },
    handleSubmit() {
      if (!this.selectOrderList || this.selectOrderList.length === 0) {
        this.$message.warning("需要转仓的订单列表不能为空");
        return false;
      }
      this.form.innerDataList = this.selectOrderList;
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.form.originatingWarehouseTime = this.$momonet(
            new Date(this.form.originatingWarehouseTime)
          ).format("yyyy-MM-DD HH:mm:ss");
          this.form.arriveAtWarehouseTime = this.$momonet(
            new Date(this.form.arriveAtWarehouseTime)
          ).format("yyyy-MM-DD HH:mm:ss");
          postAction(this.url.addWarehouseRoll, this.form).then((res) => {
            this.$message.success(res.message);
            this.handleClose();
          });
        }
      });
    },
    handleClose() {
      this.form = {};
      this.selectOrderList = [];
      this.selectWarehouseMap = {};
      this.totalWeight = 0;
      this.totalVolume = 0;
      this.totalNumber = 0;
      this.$emit("ok");
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding: 20px 40px 20px 6px;
}

.addEditBox {
  max-height: 615px;
  overflow: scroll;
}
</style>
