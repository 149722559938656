<template>
    <el-dialog
        :title="'字典配置：' + ruleName + ':' + ruleCode"
        :visible.sync="visible"
        width="800px"
        top="100px"
        custom-class="c-custom-dialog"
        :destroy-on-close="true"
        :close-on-click-modal="false"

        :before-close="handleClose">
        <el-form :model="form"  ref="form" label-width="auto" size="medium"  >
            <el-row :gutter="20">

                <el-col :span="24">
                    <p class="title">自定义类型</p>
                    <div class="f-p-b-10">
                        <el-button type="primary" size="mini" @click="handleEdit({})">新增</el-button>
                      <el-button size="mini" type="primary" class="c-theme-error-button" @click="handleDel"
                                 :disabled="selectIds.length === 0"
                                >批量删除
                      </el-button>
                    </div>
                    <f-table
                        ref="GTable"
                        border
                        height="290"
                        size="small"
                        row-key="id"
                        class="c-th-has-bgcolor"
                        :highlight-current-row="true"
                        :columns="columns"
                        :data="tableData"
                        @selection-change="handleSelectionChange"
                        :cell-style="{ 'text-align': 'center' }">
                        <template v-slot="scope">
                            <template v-if="scope.column.property === 'action'">
                                <el-button type="text" @click="handleEdit(scope.row)">编辑</el-button>
                            </template>
                            <template v-else-if="scope.column.property === 'busType'">
                              <span v-if="scope.row.busType === 'ExpensesBasicInfo'">费用类-自动录入</span>
                              <span v-else-if="scope.row.busType === 'FeeSyncBasicInfo'">费用类-自动同步</span>
                              <span v-else-if="scope.row.busType === 'AmazonSend'">SEND配置</span>
                            </template>
                        </template>
                    </f-table>
                </el-col>
            </el-row>
        </el-form>


        <ac-dict-item-dialog :visible.sync="itemVisible" :row="itemRow" :dict-id="dictId" @ok="handleGetTableData" ></ac-dict-item-dialog>
    </el-dialog>
</template>

<script>
import { getAction, deleteAction, putAction } from '@/api/manage'
import acDictItemDialog from "./acDictItemDialog.vue";

import { codemirror } from 'vue-codemirror'
import 'codemirror/addon/scroll/simplescrollbars.js'
import 'codemirror/lib/codemirror.css'
import 'codemirror/addon/scroll/simplescrollbars.css'
import {postAction} from "@/api/manage";



export default {
    name: 'acDictItem',
    components: {
        codemirror,
        acDictItemDialog
    },
    props: {
        visible: {
            type: Boolean
        },
        dictId: {
            type: Number,
            default: 0
        },
      ruleName: {
        type: String,
        default: ''
      },
      ruleCode: {
        type: String,
        default: ''
      },

    },
    data() {
        return {
            itemRow: {},
            itemVisible: false,
            tableData: [],
            form: { },

            options: {
                line: true,
                lineNumbers: true,
                scrollbarStyle: 'simple',
            },
            placeholder: '请在此输入x-sql代码',
            columns: [
              {
                type: 'selection',
                width: 50
              },
                {
                    type: 'index',
                    label: '#'
                },
                {
                    prop: 'fieldId',
                    label: '文字'
                },
                {
                    prop: 'option',
                    label: '数值'
                },
                {
                    prop: 'busType',
                    label: '类型'
                },

                {
                    prop: 'action',
                    label: '操作'
                },
            ],
            url: {

                delete: '/finance/fieldOption/del',
              listItem: '/finance/fieldOption/getList',

            },
            selectIds:[],
        }
    },
    watch: {
        visible(val) {
            if(val) {

                this.handleGetTableData()
            }
        }
    },
    methods: {
      handleSelectionChange(selection) { // 多选
        // consoloe.log();
        let arr = []
        this.selectIds = []

        for (let i = 0; i < selection.length; i++) {
          arr.push(selection[i]['id'])
        }
        this.selectIds = [...arr];


      },
        handleDel() {
             this.$confirm(`确定删除?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'error',
                    closeOnClickModal: false,
            }).then(() => {
                postAction(this.url.delete, this.selectIds).then(res => {
                    this.$message({
                        type: 'success',
                        message: res.message
                    })
                    this.handleGetTableData()
                })
            }).catch(() => {

            })
        },
        handleEdit(row) {
            if (row) {
              this.itemRow = row;
            }
            this.itemVisible = true
        },
        handleGetTableData() {
            const params = {
              fieldDictId: this.dictId,
            }
            getAction(this.url.listItem, params).then(res => {
                this.tableData = res.result
            })
        },

        handleClose() {
            this.$emit('update:visible', false)
            this.form = { }
            this.$refs.form.resetFields()
        }
    }
}
</script>

<style lang="scss">
.CodeMirror-wrap {
    height: 180px;
    border: 1px solid #dddddd;
    .vue-codemirror {
        height: 100%;
        .CodeMirror {
            height: 100% !important;
            font-size: 16px;
            font-family: inherit;
        }
    }
}
</style>

<style lang="scss" scoped>
::v-deep
    .el-dialog__body {
        padding: 20px;
    }
.title {
    margin-bottom: 10px;
    font-weight: bold;
}

td {
    .el-button {
        padding: 6px 0;
    }
}
</style>
