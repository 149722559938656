<template>
    <div>
        <el-card class="box-card">
            <el-form class="multiline_select" ref="form" :model="queryForm" label-width="80px" size="medium">
                <el-card class="box-card">
                    <el-row  class="inputs_row">
                        <el-col :xs="24" :sm="12" :md="12" :lg="6">
                            <el-form-item label="仓库名称" :label-width="formLabelWidth">
                                <el-input v-model="queryForm.lclPlaceName"
                                          @keyup.enter.native="handleSearch"></el-input>
                            </el-form-item>
                        </el-col>


                        <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="6">
                            <el-form-item label="拼柜仓库" :label-width="formLabelWidth">
                                <f-select v-model="queryForm.lclPlaceIdList" :isNeed="queryForm.lclPlaceIdList" :dict="'lclPlace'"
                                          @keyup.enter.native="handleSearch" :multiple="true"></f-select>
                            </el-form-item>
                        </el-col>


                        <el-col :xs="24" :sm="12" :md="12" :lg="4">
                            <el-form-item label-width="30px" >
                                <el-button
                                        type="primary"
                                        icon="el-icon-search"
                                        @click="handleSearch"
                                >查询
                                </el-button
                                >
                                <el-button plain @click="handleReset">重置</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>


                </el-card>
            </el-form>
            <div class="bbb">
                <el-button
                        type="primary"
                        size="small"
                        icon="el-icon-plus"
                        @click="handleAdd"
                        class="margin_right_10"
                        style="float:left;margin-right: 5px;"
                        v-if="buttonShowList.add">新增
                </el-button>
                <el-button
                        type="primary"
                        size="small"
                        @click="handleDel"
                        class="margin_right_10"
                        style="float:left;margin-right: 5px;"
                        :disabled="selectedRowKeys.length === 0"
                        v-if="buttonShowList.sc">删除
                </el-button>
            </div>
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="73vh"
            >
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'action'">
                        <el-button type="text" @click="handleUpdate(scope.row)"
                                   v-if="buttonShowList.bj"
                        >编辑</el-button>
                    </template>
                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange">
                </el-pagination>
            </el-row>
        </el-card>
        <lcl-place-max-container-load-length-dialog :visible.sync="dialogFormVisible" :row="selectedRow"
                                @ok="handleSearch"></lcl-place-max-container-load-length-dialog>
    </div>
</template>

<script>
    import QueryMixins from "@/mixins/QueryMixins.js";
    import {postAction} from "@/api/manage";
    import lclPlaceMaxContainerLoadLengthDialog from "@/views/warehouse2.0/modules/lclPlaceMaxContainerLoadLengthDialog";

    export default {
        name: "LclPlaceMaxContainerLoadLength",
        mixins: [QueryMixins],
        components: {lclPlaceMaxContainerLoadLengthDialog},
        data() {
            return {
                isUsePost: true,
                dialogFormVisible: false,
                formLabelWidth: "120px",
                //表单校验

                visible: false,
                //维护 派送费 价格模板计算 （费用名称，最小值，最大值，单位，fbacode，成本，（美元转cny汇率），基数，公式展示（(成本*汇率)/基数），计算出来的单价），结算对象
                columns: [
                    {
                        type: "selection",
                    },
                    {
                        prop: "action",
                        label: "操作",
                    },
                    {
                        prop: "lclPlaceName",
                        label: "仓库名称",
                    },
                    {
                        prop: "nineLength",
                        label: "9点可装柜子个数",
                    },
                    {
                        prop: "elevenLength",
                        label: "11点可装柜子个数",
                    },
                    {
                        prop: "thirteenLength",
                        label: "13点可装柜子个数",
                    },
                    {
                        prop: "fifteenLength",
                        label: "15点可装柜子个数",
                    },
                    {
                        prop: "seventeenLength",
                        label: "17点可装柜子个数",
                    },
                    {
                        prop: "createBy",
                        label: "录入人",
                    },
                    {
                        prop: "createTime",
                        label: "录入时间",
                    },

                ],
                url: {
                    list: "/warehouse/outOrderly/queryPage",
                    del: "/warehouse/outOrderly/del",


                },
                selectedRow:{}


            };
        },
        methods: {

            //新增
            handleAdd() {
                this.selectedRow = {};
                this.dialogFormVisible = true;
            },
            //修改
            handleUpdate(row) {
                this.selectedRow = JSON.parse(JSON.stringify(row));
                this.dialogFormVisible = true;
            },
            //删除
            handleDel(){
                this.$confirm(`确定删除?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'success',
                    closeOnClickModal: false,
                }).then(()=>{
                    postAction(this.url.del+"?ids=" + this.selectedRowKeys.join(','))
                        .then(res=>{
                            this.$message({
                                type: 'success',
                                message: res.message
                            });
                            this.handleQuery();
                        })
                })
            },
        },
      computed:{
        buttonShowList() {
          return {
            'bj':this.$btnIsShow('warehouse2.0/LclPlaceMaxContainerLoadLength','1','编辑'),
            'sc':this.$btnIsShow('warehouse2.0/LclPlaceMaxContainerLoadLength','0','删除'),
            'add':this.$btnIsShow('warehouse2.0/LclPlaceMaxContainerLoadLength','0','新增'),
          }
        }
      }
    };
</script>

<style lang="scss" scoped>
    .bbb {
        display: flex;
    }

    .dialog-footer {
        text-align: right !important;
    }

    .margin_right_10 {
        margin-right: 10px;
    }
</style>
