<template>
  <el-dialog :title="row.title + '：日志'" :visible="visible" width="70%"
    custom-class="c-custom-dialog" :close-on-click-modal="false" :before-close="handleClose">
    <f-table  v-loading="loading" ref="GTable" border size="medium" row-key="id"
      class="c-th-has-bgcolor" :columns="columns" :data="tableData"
      :cell-style="{ 'text-align': 'center' }" tableHeight="66vh" @selection-change="handleSelectionChange"
      @sort-change="handleTableChange">
    </f-table>
    <el-row class="f-text-right f-p-v-8">
      <el-pagination
          v-bind="ipagination"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
      >
      </el-pagination>
    </el-row>
    <span slot="footer">
      <el-button size="medium" @click="handleClose">取消</el-button>
      <el-button v-if="type !== 'log'" type="primary" size="medium" @click="handleSubmit">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { httpAction } from '@/api/manage'
import QueryMixins from "@/mixins/QueryMixins.js";
import {validateCmb} from "../../../utils/validator";



export default {
  name: 'NeedsLog',
  mixins: [QueryMixins],

  props: {
    visible: {
      type: Boolean
    },
    row: {
      type: Object,
      default: () => { }
    },
  },
  data() {
    return {
      disableMixinMounted: true,
      columns: [
        {
          prop: "fieldName",
          label: "属性名称",
        },
        {
          prop: "originValue",
          label: "原始数据",
        },
        {
          prop: "newValue",
          label: "新数据",
        },
        {
          prop: "createTime",
          label: "创建时间",
        },
        {
          prop: "createBy",
          label: "创建人",
        },
      ],

      url: {
        list: '/sys/needsManage/getNeedsLogPage',
      },

    }
  },
  watch: {
    visible(val) {
      if (val && this.row.id) {
        this.initData = {
          busId: this.row.id,
        }
        this.handleReset();
      }
    },
  },
  created() {
  },
  methods: {

    handleClose() {
      this.$emit('update:visible', false)
    },
  }
}
</script>

<style lang="scss" scoped></style>
