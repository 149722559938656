<template>
  <wait-audit/>
</template>

<script>
import waitAudit from "@/views/sale/loseMoney/waitAudit";
export default {
  name: "WaitAuditModule",
  components: {waitAudit},

};
</script>

<style lang="scss" scoped>
</style>