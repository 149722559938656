<template>
  <el-dialog
    :title="row.id ? '编辑营销线索' : '新增营销线索'"
    :visible="visible"
    width="560px"
    custom-class="c-custom-dialog"
    :close-on-click-modal="false"
    :before-close="handleClose"
  >
    <el-form
      :model="addForm"
      :rules="rules"
      ref="addForm"
      label-width="80px"
      size="medium"
    >
      <el-row>
        <el-col>
          <el-form-item prop="leadsCustomer" label="客户名">
            <el-input
              v-model="addForm.leadsCustomer"
              :is-need="addForm.leadsCustomer"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item prop="leadsContacts" label="联系人">
            <el-input
              v-model="addForm.leadsContacts"
              :is-need="addForm.leadsContacts"
            ></el-input>
          </el-form-item>
        </el-col>

        <!-- <el-col>
          <el-form-item label="公司名" prop="leadsCompanyName">
            <el-input v-model="addForm.leadsCompanyName"> </el-input>
          </el-form-item>
        </el-col> -->

        <el-col>
          <el-form-item label="微信号" prop="wechatNumber">
            <el-input v-model="addForm.wechatNumber"> </el-input>
          </el-form-item>
        </el-col>

        <el-col>
          <el-form-item label="联系电话" prop="leadsPhone">
            <el-input v-model="addForm.leadsPhone"> </el-input>
          </el-form-item>
        </el-col>

        <el-col>
          <el-form-item prop="leadsEmail" label="邮箱">
            <el-input
              v-model="addForm.leadsEmail"
              maxlength="50"
              show-word-limit
              style="padding: 0 45px 0 0"
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col>
          <el-form-item label="客户地址" prop="companyAddress">
            <el-input v-model="addForm.companyAddress"> </el-input>
          </el-form-item>
        </el-col>

        <el-col>
          <el-form-item label="客户需求" prop="cusDemandList">
            <f-select
              v-model="addForm.cusDemandList"
              :dict="'cus_demand'"
              :is-need="addForm.cusDemandList"
              multiple
            >
            </f-select>
          </el-form-item>
        </el-col>

        <el-col>
          <el-form-item label="线索来源" prop="cusSource">
            <f-select
              v-model="addForm.cusSource"
              :dataList="cusSourceList"
              :is-need="addForm.cusSource"
              @click.native="fillCusSourceList"
            >
            </f-select>
          </el-form-item>
        </el-col>

        <el-col v-if="row.id">
          <el-form-item label="线索状态" prop="leadsStatus">
            <f-select
              v-model="addForm.leadsStatus"
              :dict="'marketing_lead_status'"
              :is-need="addForm.leadsStatus"
            >
            </f-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer">
      <el-button size="medium" @click="handleClose">取消</el-button>
      <el-button type="primary" size="medium" @click="handleSubmit"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { httpAction, getAction } from "@/api/manage";
import { getallcountries, getcityList, getstateList } from "@/api/manage";
import { mobilePattern, emailPattern } from "@/utils/pattern";

export default {
  name: "EditSuccessClearedCaseDialog",

  props: {
    visible: {
      type: Boolean,
    },
    row: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      cusSourceList: [],
      formLabelWidth: "100px",
      addForm: {},
      countrylist: [],
      citylist: [],
      statelist: [],
      recMap: {},
      rules: {
        cusDemandList: [
          { required: true, message: "请选择客户需求", trigger: "change" },
        ],
        leadsStatus: [
          { required: true, message: "请选择线索状态", trigger: "change" },
        ],
        cusSource: [
          { required: true, message: "请选择线索来源", trigger: "change" },
        ],
        leadsEmail: [
          { required: false, message: "请输入接收发票的邮箱", trigger: "blur" },
          {
            pattern: emailPattern,
            message: "请输入正确的邮箱",
            trigger: "change",
          },
        ],
      },
      url: {
        add: "/sys/bdMarketingLeads/add",
        edit: "/sys/bdMarketingLeads/edit",
      },
      isWarehouse: null,
    };
  },
  watch: {
    visible(val) {
      if (val) {
        this.addForm = { ...this.row };
        console.log(this.row, "this.row");
      }
    },
  },
  created() {
    // this.fillCusSourceList();
  },
  methods: {
    initCountryList() {
      if (this.countrylist.length <= 0) {
        this.countrylist = [];
        getallcountries()
          .then((res) => {
            if (res.success) {
              for (var i = 0; i < res.result.length; i++) {
                let obj = {};
                obj.value = res.result[i].cnName;
                obj.label = res.result[i].twoCode + "-" + res.result[i].cnName;
                this.countrylist.push(obj);
              }
            }
          })
          .catch((err) => {
            this.$message.error(err);
          });
      }
    },
    fillCusSourceList() {
      getAction("/sys/bdCusSource/getAllCusSourceList").then((res) => {
        if (res.result) {
          this.cusSourceList = res.result;
        }
      });
    },
    handleSubmit() {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          if (!this.addForm.leadsCustomer && !this.addForm.leadsContacts) {
            this.$message({
              type: "warning",
              message: "客户名、联系人至少填写一项，请完善信息",
            });
            return;
          }

          if (
            !this.addForm.wechatNumber &&
            !this.addForm.leadsPhone &&
            !this.addForm.leadsEmail &&
            !this.addForm.companyAddress
          ) {
            this.$message({
              type: "warning",
              message: "微信号、电话、邮箱、客户地址至少填写一项，请完善信息",
            });
            return;
          }
          let url = this.url.add;
          let method = "POST";
          if (this.row.id) {
            url = this.url.edit;
            method = "PUT";
          }
          const params = JSON.parse(JSON.stringify(this.addForm));
          httpAction(url, method, params).then((res) => {
            this.$message({
              type: "success",
              message: res.message,
            });
            this.handleClose();
            this.$emit("ok");
          });
        }
      });
    },
    showItemForm(e) {
      if (e === "仓库代码" && this.addForm.shipmentZip) {
        this.isWarehouse = true;
      } else if (e === "FbaCode" && this.addForm.shipmentCode) {
        this.isWarehouse = false;
      } else {
        this.isWarehouse = null;
      }
    },
    handleClose() {
      this.isWarehouse = null;
      this.$emit("update:visible", false);
      this.$refs.addForm.resetFields();
    },
    changetChannelId(e) {
      if (e && e.itemText) {
        this.addForm.channelName = e.itemText;
      } else {
        this.addForm.channelName = "";
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
