<template>
    <div>

        <el-dialog
            title="修改币种及添加账户"
            border
            :visible="changeCurVisible"
            :close-on-click-modal="false"
            :destroy-on-close="true"
            :before-close="curBankDialogClose">
            <el-form :rules="rules">
                <el-row>
                    <el-col :span="23">
                        <el-form-item prop="cur" label="折合币种(部分销账的费用不能更改折合币种)">
                            <f-select v-model="billForm.cur"
                                      :dict="'bd_cur'"
                                      placeholder="请选择折合币种"
                                      :disabled="curChangeDis"
                                      clearable
                                      filterable
                                      :father-val="billForm.cur"
                                      :is-need="billForm.cur"
                                      @change="curChangeBank"
                                      class="f-full-width"></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="23">
                        <el-form-item prop="bank" label="银行账户">
                            <el-select v-model="billForm.bankId"
                                       placeholder="根据币种选择银行"
                                       clearable
                                       filterable
                                       :father-val="billForm.bankId"
                                       :is-need="billForm.bankId"
                                       class="f-full-width">
                                <el-option v-for="(item) in banks" :value="item.id" :label="item.bankName"
                                           :key="item.id">
                                    {{item.bankName + '-' + item.accountName + '-' + item.account + '-' + item.cur }}
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer">
            <el-button type="primary" size="medium" @click="changeCurSubmit">确定</el-button>
        </span>
        </el-dialog>

        <el-dialog
            title="添加费用"
            border
            :visible="addFeeDialogVisible"
            :close-on-click-modal="false"
            :destroy-on-close="true"
            :before-close="handleAddFeeDialogClose">
            <el-form>
                <el-row>

                    <el-col>
                        <el-form-item label="单号">
                            <el-input v-model="feeOrderNo" style="width: 25%"></el-input>
                        </el-form-item>
                        <el-button type="primary" @click="addFeesAgain">查找</el-button>
                    </el-col>

                </el-row>
            </el-form>
            <f-table
                :columns="addColumns"
                :cell-style="{ 'text-align': 'center' }"
                :data="feesAgain"
                @selection-change="addSelectionChange"
            ></f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                    v-bind="ipagination"
                    layout="total, sizes, prev, pager, next, jumper"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                >
                </el-pagination>
            </el-row>
            <span slot="footer">
            <el-button type="primary" size="medium" @click="addSubmit">确定</el-button>
        </span>
        </el-dialog>
        <!-- 图片预览弹窗-->
        <el-dialog
                width="800px"
                title="预览"
                append-to-body
                :visible="visiblePicture"
                custom-class="c-custom-dialog"
                :before-close="() => { visiblePicture = false }">
            <img :src="imgUrl" width="100%" >
        </el-dialog>
        <el-dialog
            :title="title"
            :visible="visible"
            width="70%"
            custom-class="c-custom-dialog"
            :close-on-click-modal="false"
            :destroy-on-close="true"
            :before-close="handleClose"
        >
            <el-form :model="billForm" :rules="rules" ref="billForm" :inline="true" :label-position="right"
                     label-width="109px">
                <div class="flex_center_start">
                <el-row style="width: 50%">
                    <el-col>
                        <el-form-item label="上传水单" v-if="showOnly">
                            <el-upload
                                class="upload-demo"
                                :action="url.uploadInvoice"
                                accept=".jpg,.png,.pdf,.xls,.xlsx"
                                :http-request="handleUpload"
                                :on-preview="handlePicPreview"
                                :before-upload="beforeUpload"
                                :on-remove="handleRemove"
                                :file-list="fileList"
                                multiple
                                list-type="picture">
                                <el-button size="small" type="primary">点击上传</el-button>
                                <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
                            </el-upload>
                        </el-form-item>
                    </el-col>

                    <el-col>
                        <el-form-item prop="payMan" label="付款人">
                            <el-input v-model="billForm.payMan" style="width: 300px" :disabled="!showOnly" maxlength="50" show-word-limit></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col>
                        <el-form-item prop="amount" label="账单金额合计">
                            <!--                            <el-input v-model="billForm.amount" style="width: 300px" @blur="changeAmount" :disabled="!showOnly"></el-input>-->
                            <el-input v-model="billForm.amount"
                                      style="width: 300px" @blur="changeAmount" disabled
                                      oninput="value=value.match(/\d+\.?\d{0,2}/)"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col>
                        <el-form-item prop="billMoney" label="水单金额">
                            <el-input v-model="billForm.billMoney" style="width: 300px" :disabled="!showOnly"
                                      oninput="value=value.match(/[-]?\d*\.?\d{0,3}/)"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col>
                        <el-form-item label="银行流水号">
                            <el-input v-model="billForm.billBankNo" style="width: 300px"
                                      :disabled="!showOnly"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col>
                        <el-form-item label="水单付款人">
                            <el-input v-model="billForm.billPayerName" style="width: 300px"
                                      :disabled="!showOnly"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col>
                        <el-form-item label="水单开户银行">
                            <el-input v-model="billForm.billPayerBank" style="width: 300px"
                                      :disabled="!showOnly"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col>
                        <el-form-item label="水单交易日期">
                            <el-input v-model="billForm.billTradingDate" style="width: 300px"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col>
                        <el-form-item prop="amount" label="实际到账金额">
                            <!--                            <el-input v-model="billForm.amount" style="width: 300px" @blur="changeAmount" :disabled="!showOnly"></el-input>-->
                            <el-input v-model="billForm.salesmanMoney"
                                      style="width: 300px" :disabled="!showOnly"
                                      oninput="value=value.match(/^[-]?\d*\.?\d{0,3}/)"></el-input>
                        </el-form-item>
                        <el-form-item prop="amount" label="实际到账币种">
                            <!--                            <el-input v-model="billForm.amount" style="width: 300px" @blur="changeAmount" :disabled="!showOnly"></el-input>-->
<!--                            <span>{{billForm.salesmanMoneyCur}}</span>-->
                            <f-select v-model="billForm.salesmanMoneyCur"
                                      :dict="'bd_cur'"
                                      placeholder="请选择实际到账币种"
                                      clearable
                                      :disabled="!showOnly"
                                      filterable
                                      :father-val="billForm.salesmanMoneyCur"
                                      :is-need="billForm.salesmanMoneyCur"
                                      class="f-full-width"></f-select>

                        </el-form-item>
                    </el-col>

                    <el-col v-if="showRec">
                        <el-form-item prop="amount" label="总差异额">
                            <!--                            <el-input v-model="billForm.amount" style="width: 300px" @blur="changeAmount" :disabled="!showOnly"></el-input>-->
                            <el-input v-model="billForm.variance"
                                      style="width: 300px" @blur="changeAmount" disabled
                                      oninput="value=value.match(/\d+\.?\d{0,2}/)"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col>
                        <el-form-item label="银行交易日期">
                            <el-date-picker
                                class="f-flex-1"
                                v-model="billForm.fVoucherDate"
                                type="datetime"
                                size="small"
                                placeholder="请选择时间"
                                default-time="00:00:00" value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col>
                        <el-form-item label="备ㅤ注">
<!--                            <el-input v-model="billForm.remark" style="width: 300px" :disabled="!showOnly"></el-input>-->
                            <el-input type="textarea" v-model="billForm.remark" style="width: 300px"
                                      maxlength="500"
                                      show-word-limit></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                    <el-row style="width: 50%">
                        <el-col>
                            <div class="payApplyRow" v-if="sumSettlement.length > 0" style="float: left;width: 100%">
                                <div class="f-m-b-5" style="font-weight: bold">结算概览：</div>
                                <div class="payApplyRow payApplyRowItem flex_wrap_start" v-for="(item,index) in sumSettlement" :key="index">
                                    <div style="font-weight: bold;">{{ item.type + '：' }}</div>
                                    <div v-if="item.settlement" style="margin-left: 10px">{{ item.settlement }}</div>
                                    <div v-if="item.finalTotal" style="margin-left: 10px">{{ item.finalTotal }}</div>
                                    <div v-if="item.realMoneyType" style="margin-left: 10px">{{ item.realMoneyType }}</div>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                </div>
                <el-row>
                    <el-col>

                        <el-button plain @click="addFee" v-if="showOnly">添加费用</el-button>
                        <el-button plain @click="changeCurBank" v-if="showOnly">修改币种及添加账户</el-button>
                    </el-col>
                </el-row>

                <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="billForm.settleList"
                    :cell-style="{ 'text-align': 'center' }"
                    style="margin-top: 15px"
                    @selection-change="handleSelectionChange"
                >
                    <template v-slot="scope">

                        <template v-if="scope.column.property === 'billMoney'">
                            <el-form-item v-if="showOnly"
                                          :rules="[{required: true, message: '水单不能为空', trigger: 'blur'}]">
                                <el-input type="text" v-model="scope.row.billMoney"
                                          @input="changeAmount" :placeholder="scope.row.exchangeTotal"
                                          oninput="value=value.match(/[-]?\d*\.?\d{0,3}/)"></el-input>
                            </el-form-item>
                            <div v-if="!showOnly" style="text-align: center;">
                                <span>{{ scope.row.billMoney }}</span>
                            </div>

                        </template>
                        <template v-else-if="scope.column.property === 'action' && showOnly && billForm.settleList.length > 1 && scope.row.partialWriteOff !=='1'">

                            <el-tooltip class="item" effect="dark" content="是否从申请编号中删除，并退回到已开账单？" placement="top">
                                <el-button type="text" @click="delFee(scope.row)">删除</el-button>
                            </el-tooltip>
                        </template>
                        <template v-else-if="scope.column.property === 'orderNo'">
                            <a style="color: #00a0e9"
                               @click.prevent="goOrderNo(scope.row.orderNo)">{{ scope.row.orderNo }}</a>
                        </template>
                        <template v-else-if="scope.column.property === 'customer'">
                          <a style="color: #00a0e9"
                             @click.prevent="initCusPage(scope.row.settleId)">{{ scope.row.customer}}</a>
                        </template>
<!--                        <template v-else-if="scope.column.property === 'exchangeTotal'">-->
<!--                            <span-->
<!--                                v-if="scope.row.moneyType===scope.row.realMoneyType && scope.row.exchangeTotal!==scope.row.total"-->
<!--                                style="text-shadow:0px 0px 10px #000;">-->
<!--                                {{ scope.row.exchangeTotal }}-->
<!--                            </span>-->
<!--                        </template>-->
<!--                        <template v-else-if="scope.column.property === 'payed'">-->
<!--                            <span v-if="scope.row.partialWriteOff === null || scope.row.partialWriteOff === '0'">-->
<!--                                {{ ((scope.row.payed===undefined||scope.row.payed===null)?0:scope.row.payed).toFixed(3)}}</span>-->
<!--                            <span v-if="scope.row.partialWriteOff === '1'">{{(Number(scope.row.exchangeTotal)-Number(scope.row.waitPay===null?0:scope.row.waitPay)).toFixed(3)}}</span>-->
<!--                        </template>-->
                        <template v-else-if="scope.column.property === 'partialWriteOff'">
                            <span
                                v-if="scope.row.partialWriteOff === null || scope.row.partialWriteOff === '0'">否</span>
                            <span v-if="scope.row.partialWriteOff === '1'">是</span>
                        </template>
                    </template>

                </f-table>
            </el-form>
            <el-form :inline="true" :label-position="right" label-width="109px">
                <el-row :gutter="24" class="payApplyRow">
                    <el-col>
                        <span>合计：</span>
                    </el-col>
                    <el-col v-for="(item,index) in sumFee" :key="index" :span="4" style="color: red">
                        <el-form-item :label="item.cur" :key="index" class="payApplyItem">
                            <span>{{ item.total }}</span>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer">
            <el-button v-if="showOnly" type="primary" size="medium" @click="handleSubmit"
                       :disabled="ifDis">确定修改</el-button>
            <el-button
                type="primary"
                @click="addRemark"
                v-if="!showOnly && buttonShowList.xgbz"
            >修改备注</el-button>
        </span>

            <el-tooltip placement="top">
                <div slot="content">水单上传详细日志<br/></div>
                <el-button>水单上传详细日志</el-button>
            </el-tooltip>
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="logColumns"
                    :data="billForm.uploadedFileList"
                    :cell-style="{ 'text-align': 'center' }"
                    style="margin-top: 15px"
                    @selection-change="handleSelectionChange">
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'name'">
                        <span style="cursor: pointer;color:#57a3f3;" @click="handlePicPreview(scope.row)">{{scope.row.name}}</span>
                    </template>
                </template>
            </f-table>
        </el-dialog>
    </div>
</template>

<script>
import {deleteAction, getAction, postAction} from '@/api/manage'
import { setUpRouteUtil } from '@/utils/util';
//引入水单日志
// import QueryMixins from "../../../../mixins/QueryMixins";

export default {
    name: 'receiveApplyUpdate',
    // mixins: [QueryMixins],
    data() {
        return {
            visiblePicture:false,

            // id:'',
            addColumns: [
                {
                    type: "selection",
                    width: 50,
                },
                {
                    prop: "orderNo",
                    label: "工作单号",
                },

                {
                    prop: "realAgent",
                    label: "客户",
                },
                {
                    prop: "agent",
                    label: "结算对象",
                },
                {
                    prop: "feename",
                    label: "费用中文",
                },
                {
                    prop: "feenameUs",
                    label: "费用英文",
                },
                {
                    prop: "price",
                    label: "价格",
                },
                {
                    prop: "quantity",
                    label: "数量",
                },
                {
                    prop: "moneyType",
                    label: "费用币种",
                },
                {
                    prop: "total",
                    label: "合计",
                },
                {
                    prop: "realMoneyType",
                    label: "折合币种",
                },
                {
                    prop: "exchangeRate",
                    label: "汇率",
                },
                {
                    prop: "exchangeTotal",
                    label: "折合金额",
                },
                {
                    prop: "createBy",
                    label: "录入人",
                },
                {
                    prop: "screateTime",
                    label: "录入时间",
                },
                {
                    prop: "createTime",
                    label: "下单时间",
                },

            ],
            addFeeDialogVisible: false,
            loading: false,
            right: 'right',
            remark: '',
            imgUrl: '',
            labelPosition: 'right',
            flag: false,
            visible: false,
            changeCurVisible: false,
            isChecked: true,
            ifDis: false,
            allKeys: [],
            fileList: [],
            defaultProps: {
                children: 'children',
                label: 'title'
            },
            billForm: {
                applyNo: '',
                billType: 'noConvert',
                billTitle: '',
                payMan: '',
                amount: '',//实付
                remark: '',
                ids: [],
                orderNos: [],
                cur: '',//更改的币种
                bankId: '',
                settleList: [],
                uploadFileIds: [],//上传文件的id，保存时增加申请单号
            },
            curNow:'',//当前币种
            curNowIsChange: false,//当前币种
            url: {
                list: "/file/hyFinance/billedListPost",
                deleteBatch: '/sys/bdCountry/deleteBatch',
                openBill: '/file/jasper/openReceiveBill',
                checkBill: '/shipping/hySettleReceive/checkReceiveSettleId',
                curGetBank: '/shipping/hySettleReceive/curGetBank',
                editApply: '/shipping/hySettleReceive/editApply',
                editApplyRemark: '/shipping/hySettleReceive/editApplyRemark',
                getSettlesByApplyNo: '/shipping/hySettleReceive/getSettlesByApplyNo',
                uploadInvoice: '/shipping/hySettlePay/uploadInvoice',
                readBillPic: '/shipping/hySettlePay/readBillPic',
                getRateMoney: '/shipping/hySettleReceive/getRateMoney',
                delFile: '/shipping/hySettlePay/delWaterBillFile',
            },
            rules: {
                payMan: [
                    {required: true, message: '请输入付款人', trigger: 'blur'},
                ],
                amount: [
                    {required: true, message: '请输入金额', trigger: 'blur'},
                ],
                billMoney: [
                    {required: true, message: '请输入金额', trigger: 'blur'},
                ],
                realMoneyType: [
                    {required: true, message: '请选择币种', trigger: 'blur'},
                ],
                bankId: [
                    {required: true, message: '请选择银行', trigger: 'blur'},
                ],


            },
            title: '收款申请',
            settleIds: [],
            showOnly: true,
            noChoose: [],
            logColumns:[
                {
                    prop: 'name',
                    label: '水单名称',
                },
                {
                    prop: 'createBy',
                    label: '上传人',
                },
                {
                    prop: "createTime",
                    label: "上传时间",
                },
            ],
            columns: [
                // {
                //     type: 'selection',
                //     width: 50
                // },
                {
                    prop: 'action',
                    label: '操作',
                    width: 70
                },
                {
                    prop: 'orderNo',
                    label: '工作单号',
                },
                {
                    prop: "createTime",
                    label: "下单时间",
                },
                {
                    prop: "sCreateTime",
                    label: "费用时间",
                },
                {
                    prop: 'customer',
                    label: '客户',
                },
                {
                    prop: "settlement",
                    label: "结算对象",
                },
                {
                    prop: 'partialWriteOff',
                    label: '是否部分销账',
                },
                {
                    prop: 'feename',
                    label: '费用中文',
                },
                {
                    prop: 'moneyType',
                    label: '费用币种',
                },
                {
                    prop: 'total',
                    label: '费用金额',
                },
                {
                    prop: 'realMoneyType',
                    label: '折合币种',
                },
                {
                    prop: 'exchangeTotal',
                    label: '折合金额',
                },
                {
                    prop: 'payed',
                    label: '已销账金额',
                },
                {
                    prop: 'realPay',
                    label: '实际到账金额',
                    headerType: 'remark',
                    width:'110',
                    show:this.showRec,
                    detail: '实收金额 = 此次销账的金额<br>' +
                        '只需输入此次实收的金额，点击【确认修改】后自动计算总实收'
                },
                {
                    prop: 'variance',
                    label: '差异额',
                    show:this.showRec,
                },
                {
                    prop: 'billMoney',
                    label: '账单金额',

                },
                {
                    prop: 'billBy',
                    label: '开账单人员',
                },
                {
                    prop: 'billTime',
                    label: '账单时间',
                },
                {
                    prop: 'salesman',
                    label: '业务员',
                },
                {
                    prop: 'operator',
                    label: '操作人员',
                },

            ],
            selectionRows: [],
            addSelectRows: [],
            banks: [],
            sumFee: [],
            currentSettlementId: '',//当前在页面上的结算对象id
            currentSettleIds: [],//当前在页面显示的费用id
            feesAgain: [],
            ipagination: {
                currentPage: 1,
                pageSize: 50,
                pageSizes: [10, 20, 50, 100],
                total: 0
            },
            feeOrderNo: '',//添加费用时的查询条件
            curChangeDis: true,
            bankIdNow:'',
            tempFilesId:[],//新上传的文件，离开页面没有点保存时删除
            showRec:false,
            orderType:'',
            sumSettlement:[],
        }
    },
    methods: {
        doSum(rows) {
            this.sumFee = [];
            let allCur = []
            rows.forEach(row => {
                allCur.push(row.moneyType)
            })
            let curNum = Array.from(new Set(allCur))
            //遍历每个币种，计算总和后放入sumFee中
            curNum.forEach(cur => {
                let curRow = rows.filter(row => {
                    return row.moneyType === cur
                })
                let total = Number(0);
                curRow.forEach(i => {
                    total = total + Number(i.total)
                })
                this.sumFee.push({cur: cur, total: total.toFixed(2)})
            })

        },
        //进入客服页
        intoDocpage(orderNo) {
            if (orderNo.indexOf("KD") !== -1) {
                // 对于路由不在左侧菜单中的选中菜单处理
                setUpRouteUtil(this.$route.path, '/kdorder/Edit');
                this.$router.push("/kdorder/Edit?orderNo=" + orderNo);
            } else {
                // 对于路由不在左侧菜单中的选中菜单处理
                setUpRouteUtil(this.$route.path, '/docpage/Edit');
                this.$router.push("/docpage/Edit?orderNo=" + orderNo);
            }

        },
        //编辑客户
      initCusPage(settleId) {
        this.visible = false;
        if (settleId) {
          // 对于路由不在左侧菜单中的选中菜单处理
          setUpRouteUtil(this.$route.path, '/customer/Edit');
          this.$router.push(`/customer/Edit?id=${settleId}`)
        }
      },
        //上传文件到oss
        handleUpload(data) {

            const formData = new FormData();
            formData.append("file", data.file);
            formData.append("isNeedToText", 1);

            postAction(this.url.uploadInvoice, formData).then(res => {
                // console.log(res)
                if (res.success) {
                    //上传成功,返回结果赋值给form对象的文件集合
                    this.billForm.uploadFileIds.push(res.result.osOrderFile.id);
                    this.tempFilesId.push(res.result.osOrderFile.id)//上传为临时文件后但未提交的文件，需要删除
                    this.fileList.push(res.result.osOrderFile);
                    this.billForm.payMan = res.result.PayerName;
                    this.$set(this.billForm,'billMoney',res.result.Amount);
                    this.$message.success('上传成功');
                } else {

                }
            })
            //写 另外一个 异步接口
            postAction(this.url.readBillPic, formData).then(res => {
                // console.log(res)
                if (res.success) {
                    //上传成功,返回结果赋值给form对象的文件集合
                    if (this.billForm.billMoney == null) {
                        this.$set(this.billForm, 'billMoney', res.result.Amount);
                    }
                    this.$set(this.billForm, 'billBankNo', res.result.BankSlipNo);
                    this.$set(this.billForm, 'billPayerName', res.result.PayerName);
                    this.$set(this.billForm, 'billPayerBank', res.result.PayerBank);
                    this.$set(this.billForm, 'billTradingDate', res.result.ReferenceRemitDate);

                }
            })
        },
        beforeUpload(file) {
            //对文件大小进行限制
            const isLt2M = file.size / 1024 / 1024 < 15;
            if (!isLt2M) {
                this.$message.warning("文件大小不能超过15MB");
            }
            const isFile = file.name.indexOf(".") !== -1;
            if (!isFile) {
                this.$message.warning("该文件名不合法");
            }
            const len = file.name.length < 100;
            if (!len) {
                this.$message.warning("该文件名太长，请修改文件名最多100个字符");
            }
            return isLt2M && isFile && len;
        },
        changeAmount() {
            let sumPay = 0;
            this.billForm.settleList.forEach(item => {
                sumPay = Number(sumPay) + Number(item.billMoney)
            });
            this.billForm.amount = sumPay.toFixed(3);
        },
        toS(v) {
            return String(v)
        },
        delFee(row) {
            this.billForm.settleList.splice(this.billForm.settleList.indexOf(row),1);
            this.currentSettleIds.splice(this.currentSettleIds.indexOf(row.id), 1);

        },
        changeCurBank() {
            this.changeCurVisible = true;
            // this.billForm.bankId = this.bankIdNow
        },
        curChangeBank(val,currentBankId) {
            //如果币种有变化才进行查找相关币种的银行账号
            if(currentBankId==undefined){
                currentBankId = this.bankIdNow
            }
            // console.log('currentBankId',currentBankId)
            // console.log('传过来的币种',val)
            // console.log('当前的币种',this.curNow.toString())
            let that = this
            that.bankIdNow = currentBankId
            if(val!==this.curNow || this.curNowIsChange){
                this.banks = []
                getAction(this.url.curGetBank, {cur: val,useType: 1}).then((res) => {
                    // console.log('请求之后的currentBankId',currentBankId)
                    // console.log(res)
                    if (res.result.length === 0) {
                        this.$message.warning("该币种下无相应账号！")
                        this.$set(this.billForm, 'bankId', '');
                    } else {
                        this.banks = res.result
                        // let aaa = this.billForm.bankId
                        if(currentBankId!== ''||currentBankId!==undefined){
                            // console.log('现在赋值银行id', that.bankIdNow)
                            this.$set(this.billForm, 'bankId', that.bankIdNow);
                        }else {
                            this.$set(this.billForm, 'bankId', '');
                        }
                        // this.curNow = val
                    }
                })
                this.curNowIsChange = true;
            }

        },
        addSelectionChange(selection) { // 多选
            this.addSelectRows = selection;
        },
        addFee() {
            //获取当前结算对象的相关费用
            this.feesAgain = [];
            this.feeOrderNo = ''
            // console.log('this.currentSettlementId',this.currentSettlementId)
            postAction(this.url.list, {
                settlementId: this.currentSettlementId,
                currentSettleIds: this.currentSettleIds,
                realMoneyType:this.billForm.cur,
                orderType:this.orderType
            }).then((res) => {
                if (res.result.records.length > 0) {
                    this.feesAgain = res.result.records
                    this.addFeeDialogVisible = true;
                    this.ipagination.currentPage = res.result.current;
                    this.ipagination.pageSize = res.result.size;
                    this.ipagination.total = res.result.total;
                } else {
                    this.$message.warning("无相同结算对象的费用")
                    this.addFeeDialogVisible = false;
                }
            })
            // this.addFeeDialogVisible = true;
        },
        addRemark(){
            if (this.billForm.remark==='' || this.billForm.remark===undefined) {
                this.billForm.remark=''
            }
            let obj = {
                applyNo:this.billForm.applyNo,
                remark:this.billForm.remark
            }
            postAction(this.url.editApplyRemark, obj).then((res) => {
                if (res.success === true) {
                    this.visible = false
                    this.$emit('ok')
                    this.$message.success("修改备注成功")
                }
            })
        },
        addSubmit() {
            // this.settleList.concat(this.addSelectRows)
            // this.addSelectRows = []
            for (let i = 0; i < this.addSelectRows.length; i++) {
                this.addSelectRows[i].customer = this.addSelectRows[i].realAgent
                this.addSelectRows[i].billMoney = JSON.parse(JSON.stringify(this.addSelectRows[i].waitPay))
                this.billForm.settleList.push(this.addSelectRows[i])

                this.currentSettleIds.push(this.addSelectRows[i].id)//判断当前申请费用的id 用作排除添加费用时已用的费用
                this.noChoose.splice(this.noChoose.indexOf(this.addSelectRows[i]))
            }

            //如果是折合金额就不用修改
            if(!this.curChangeDis){
                this.getRateMoney(this.billForm.settleList, this.billForm.cur)
            }
            this.addFeeDialogVisible = false;
            this.addSelectRows = []

        },
        changeCurSubmit() {
            // console.log('提交-检查银行id',this.billForm.bankId)
            this.bankIdNow = this.billForm.bankId
            //TODO 修改原tableData中的币种和银行
            // console.log('修改原tableData中的币种和银行')
            this.billForm.settleList.forEach(data => {
                // if(this.billForm.cur!==this.curNow){
                //     data.realMoneyType = this.billForm.cur
                // }
                data.bankId = this.billForm.bankId
            })
            // console.log(this.billForm.cur+'--'+this.curNow)
            // if(this.billForm.cur!==this.curNow){
            if(this.curChangeDis){
                this.getRateMoney(this.billForm.settleList, this.billForm.cur)
            }
            this.curNow = {...this.billForm.cur}
            // console.log(this.billForm.bankId)
            this.curBankDialogClose()
        },
        getRateMoney(settleList, cur) {
            const obj = {
                settleList: settleList,
                cur: cur
            }
            postAction(this.url.getRateMoney, obj).then((res) => {
                if (res.success === true) {
                    this.billForm.settleList = res.result
                    this.billForm.settleList.forEach(item => {
                        //赋值水单金额
                        if(item.billMoney==='' || item.billMoney ===null || item.billMoney===undefined){
                            // item.billMoney = item.exchangeTotal
                            item.billMoney = JSON.parse(JSON.stringify(item.exchangeTotal))
                            // console.log('赋值'+JSON.parse(JSON.stringify(item.exchangeTotal)))
                        }
                    })
                    this.changeAmount()
                } else {
                    this.$message.warning("请求失败，请联系管理员")
                }

            })
        },
        handleSubmit() {
            // if (Number(this.billForm.amount) === 0) {
            //     this.$message.warning("金额不能为零！")
            //     return;
            // }
            this.billForm.bankId = this.bankIdNow
            // console.log('确定修改检查银行id',this.billForm.bankId)
            if (this.billForm.bankId === '' || this.billForm.bankId === undefined) {
                this.$message.warning("请选择收款银行！")
                return;
            }
            this.changeAmount();
            let sumPayed = 0;
            this.billForm.settleList.forEach(item => {
                if(item.partialWriteOff === '1'){
                    // sumPayed = Number(sumPayed) + Number(item.exchangeTotal) - Number(item.waitPay)
                    sumPayed = Number(sumPayed) + Number(item.payed)
                }

            });
            sumPayed = sumPayed.toFixed(2);

            let message = '此次账单金额为：' + this.billForm.amount + '，加上已销账部分金额：' + sumPayed + '，合计：' +
                (Number(this.billForm.amount) + Number(sumPayed)).toFixed(2) + '。请确认是否无误？'

            this.$confirm(message, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                closeOnClickModal: false,
            }).then(() => {
                this.billForm.amount = (Number(this.billForm.amount) + Number(sumPayed)).toFixed(2);
                this.$refs.billForm.validate((valid) => {
                    if (valid) {
                        postAction(this.url.editApply, this.billForm).then((res) => {
                            if (res.success === true) {
                                this.visible = false
                                this.$emit('ok')
                            }
                        })
                    }
                })
            }).catch(() => {

            })


        },
        handleClose() {
            // this.$refs.form.resetFields()
            //批量删除上传的文件
            this.handleDelOssFileBatch()
            this.$nextTick(() => {
                this.visible = false;
            })

        },
        handleDelOssFileBatch(){
            let delIdsStr = this.tempFilesId.join(",");
            // console.log('删除的文件id',delIdsStr)
            deleteAction(this.url.deleteBatch,{ids:delIdsStr}).then((res)=>{
                // console.log(res)
                //页面的上的文件也要删除
                // this.fileList.splice(index, 1)
                // this.billForm.uploadFileIds.splice(this.billForm.uploadFileIds.indexOf(item.id));
                this.fileList = []
                this.billForm.uploadFileIds = []
            })
        },
        curBankDialogClose() {
            // this.$refs.form.resetFields()
            this.changeCurVisible = false;

        },
        handleAddFeeDialogClose() {
            // this.$emit('update:visible', false)
            // this.$refs.form.resetFields()
            this.addFeeDialogVisible = false;

        },
        enabled() {
            setTimeout(() => {
                this.ifDis = false;
            }, 2000)
        },
        edit(selectionRows, arr, columns) {
            // console.log('进入edit')
            this.billForm.settleList = JSON.parse(JSON.stringify(selectionRows))
            this.noChoose = JSON.parse(JSON.stringify(arr));
            this.addColumns = columns;
            this.showOnly = true;
            this.title = '收款申请'
            this.visible = true
        },
        showDetail(row) {
            // console.log('进入showDetail')
            this.showRec = false
            this.columns.filter((item) => item.label === "实际到账金额")[0].show = false;
            this.columns.filter((item) => item.label === "差异额")[0].show = false;
            let bankIdByRes = ''
            this.tempFilesId = []
            this.billForm.uploadFileIds = []
            this.fileList =[]
            getAction(this.url.getSettlesByApplyNo, {applyNo: row.applyNo}).then((res) => {
// console.log('返回结果',res)
                this.currentSettleIds = [];
                this.billForm.settleList = res.result.settleList;
                this.billForm.uploadedFileList = JSON.parse(JSON.stringify(res.result.uploadedFiles));
                this.orderType = this.billForm.settleList[0].orderType
                this.currentSettlementId = JSON.parse(JSON.stringify(res.result.settleList[0].settleId));//当前的settlementId
                this.billForm.billBankNo = res.result.billBankNo;
                this.billForm.billPayerName = res.result.billPayerName;
                this.billForm.billPayerBank = res.result.billPayerBank;
                this.billForm.billTradingDate = res.result.billTradingDate;
                this.billForm.fVoucherDate = res.result.fVoucherDate;
                let feeRows = JSON.parse(JSON.stringify(res.result.settleList))
                feeRows.forEach(fee => {
                    this.currentSettleIds.push(fee.id)
                    // console.log(fee.payed)
                })

                this.billForm.payMan = res.result.payMan;
                // this.billForm.amount = res.result.amount;
                this.$set(this.billForm,'amount',res.result.amount);
                this.billForm.remark = res.result.remark;
                this.billForm.cur = res.result.payCur;
                // this.billForm.salesmanMoney = res.result.salesmanMoney;
                this.$set(this.billForm,'salesmanMoney',res.result.salesmanMoney);
                this.billForm.salesmanMoneyCur = res.result.salesmanMoneyCur;
                bankIdByRes = res.result.bankId
                this.curChangeBank(this.billForm.cur,bankIdByRes)
                //在获取银行列表之后再赋值银行id
                this.curNow = res.result.payCur;
                this.billForm.applyNo = row.applyNo;
                // this.billForm.billMoney = JSON.parse(JSON.stringify(res.result.billMoney));
                this.$set(this.billForm,'billMoney',res.result.billMoney);
                res.result.uploadedFiles.forEach(i => {
                    this.billForm.uploadFileIds.push(i.id)
                });
                //判断是否部分销账
                let curStatus = 0;
                this.billForm.settleList.forEach(item => {
                    if (item.partialWriteOff === '1') {
                        curStatus = Number(curStatus) + Number(1)
                    }
                });
                let v = 0
                this.billForm.settleList.forEach(settle=>{
                    v = (Number(v) + Number(settle.variance)).toFixed(2)
                })
                this.billForm.variance = v;

                this.curChangeDis = curStatus > 0;
                let sumPay = 0;

                this.billForm.settleList.forEach(item => {
                    sumPay = Number(sumPay) + Number(item.billMoney)
                    if(item.billMoney==='' || item.billMoney ===null || item.billMoney===undefined){
                        // item.billMoney = item.exchangeTotal
                        item.billMoney = JSON.parse(JSON.stringify(item.exchangeTotal))
                    }

                });
                this.billForm.amount = sumPay.toFixed(2);
                // if(row.){}
                this.fileList = res.result.uploadedFiles;
                this.doSum(this.billForm.settleList);//计算合计
                this.changeAmount();
            })
            this.title = '修改收款申请【'+row.applyNo+'】'
            // this.showOnly = false;
            this.visible = true
        },
        onlyShowDetail(row) {
            this.showRec = true
            this.columns.filter((item) => item.label === "实际到账金额")[0].show = true;
            this.columns.filter((item) => item.label === "差异额")[0].show = true;
            getAction(this.url.getSettlesByApplyNo, {applyNo: row.applyNo}).then((res) => {
                // console.log(res)
                // res.result.settleList.forEach(i=> {
                //     i.payed = Number(i.exchangeTotal) - Number(i.waitPay)
                //     console.log('payed',i.payed)
                // })
                this.billForm.settleList = res.result.settleList;
                this.billForm.payMan = res.result.payMan;
                this.billForm.amount = res.result.amount;
                this.billForm.remark = res.result.remark;
                this.billForm.billMoney = res.result.billMoney;
                this.billForm.applyNo = row.applyNo;
                this.billForm.salesmanMoney = res.result.salesmanMoney;
                this.billForm.salesmanMoneyCur = res.result.salesmanMoneyCur;
                this.billForm.billBankNo = res.result.billBankNo;
                this.billForm.billPayerName = res.result.billPayerName;
                this.billForm.billPayerBank = res.result.billPayerBank;
                this.billForm.billTradingDate = res.result.billTradingDate;
                this.billForm.fVoucherDate = res.result.fVoucherDate;
                let v = 0
                this.billForm.settleList.forEach(settle=>{
                    v = (Number(v) + Number(settle.variance)).toFixed(2)
                })
                this.billForm.variance = v;

                this.fileList = res.result.uploadedFiles;
                this.doSum(res.result.settleList);//计算合计

                this.sumSettlement = [];
                let feeRows = JSON.parse(JSON.stringify(res.result.settleList))
                let settlementArr = [];
                let settlements = [];
                let orderTypeArr = [];
                let orderTypes = [];
                feeRows.forEach(fee => {
                    this.currentSettleIds.push(fee.id)
                    orderTypeArr.push(fee.orderType)
                    settlementArr.push(fee.settlement)
                })
                orderTypes = Array.from(new Set(orderTypeArr))
                settlements = Array.from(new Set(settlementArr))
                orderTypes.forEach(type => {
                    let feesByType = feeRows.filter(row=>{
                        return row.orderType === type
                    })
                    settlements.forEach(settlement=>{
                        let finalFee = feesByType.filter(fee=>{
                            return fee.settlement === settlement
                        })
                        let total = 0
                        finalFee.forEach(f=>{
                            total = f.exchangeTotal + total
                        })
                        if(type==='0'){type = '海运'}else if(type==='1'){type = '空运'}else if(type==='2'){type = '快递'}else if(type==='9'){type = '柜子'}
                        this.sumSettlement.push({type:type,settlement:settlement,finalTotal:total.toFixed(3),realMoneyType:finalFee[0].realMoneyType})
                    })
                })
                let lastTotal  =0
                this.sumSettlement.forEach(s=>{
                    lastTotal = lastTotal+s.finalTotal
                })
                this.sumSettlement.push({type:'合计',finalTotal:Number(lastTotal).toFixed(3),realMoneyType:this.sumSettlement[0].realMoneyType})
                // console.log(this.sumSettlement)

            })
            this.title = '收款申请详情'

            this.showOnly = false;
            this.visible = true
        },
        handleRemove(file, fileList) {
            this.fileList.map((item, index) => {
                if (item.uid == file.uid) {
                    //向服务器发送要删除的文件的路径，删除oss上的文件
                    postAction(this.url.delFile, {osOrderFileId: item.id, url: item.url}).then((res) => {
                        // console.log('删除文件返回',res)
                        //页面的上的文件也要删除
                        this.fileList.splice(index, 1)
                        this.billForm.uploadFileIds.splice(this.billForm.uploadFileIds.indexOf(item.id),1);
                    })

                }
            })
        },
        handlePicPreview(file) {
            // debugger/**/
            // console.log("下载文件",file);
            if (!file.url) {
                this.$message.error('下载失败')
                return
            }

            const type = file.url.substring(file.url.lastIndexOf('.')+1);
            if (type === 'jpg' || type === 'jpeg' || type ==='img' || type === 'gif' || type === 'png' || type === 'pneg'){
                this.imgUrl = file.url
                this.visiblePicture = true
            }
            // 判断文件类型
            else if (type === 'doc' || type === 'docx' || type === 'xlsx' || type === 'xls' || type === 'ppt' || type === 'pptx') {
                // 在当前浏览器直接下载
                document.location.href = file.url
            } else {
                // 图片在浏览器打开 新的页面展示
                window.open(file.url, 'hello')
            }
        },
        handleSelectionChange(selection) { // 多选
        },
        handleSizeChange(val) {
            this.ipagination.currentPage = 1
            this.ipagination.pageSize = val
            this.addFeesAgain()
        },
        handleCurrentChange(val) {
            this.ipagination.currentPage = val
            this.addFeesAgain()
        },
        addFeesAgain() {
            let orderNo = JSON.parse(JSON.stringify(this.feeOrderNo));
            if (orderNo.trim() === '') {
                orderNo = null
            }
            postAction(this.url.list, {
                orderNo: orderNo,
                settlementId: this.currentSettlementId,
                currentSettleIds: this.currentSettleIds,
                realMoneyType:this.billForm.cur,
                orderType:this.orderType,
                pageNo: this.ipagination.currentPage,
                pageSize: this.ipagination.pageSize,
                dataCreateTime:0
            }).then((res) => {
                if (res.success) {
                    if (res.result) {
                        const {records, total} = res.result
                        this.feesAgain = records || res.result;

                        this.ipagination && (this.ipagination.total = total || res.result.length)
                    } else {

                        this.feesAgain = [];

                        this.ipagination = 0;
                    }

                }
                // if (res.result.records.length > 0){
                //     this.feesAgain = res.result.records
                //
                // }else{
                //     this.$message.warning("无相同结算对象的费用")
                //
                // }
            })
        },
        goOrderNo(orderNo) {
          this.visible = false;
          this.$intoDocPage(orderNo);
        },
    },
  computed:{
      buttonShowList() {
        return {
          'xgbz':this.$btnIsShow('hyFinanceManage/modules/receive/removed','0','修改备注'),
        }
      }
  }
}
</script>

<style lang="scss" scoped>
.btn-wrap {
    text-align: right;
    padding: 10px 30px;
}

.content {
    height: 620px;
    padding: 6px 0;
    overflow: auto;
}

</style>
