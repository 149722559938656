<template>
  <div>


    <el-card class="box-card">
      <el-form :model="hbl" ref="hbl" :rules="rules">
        <el-form-item label="提单号" :label-width="formLabelWidth2">
          <el-input
                  v-model="hbl.blNo"
                  maxlength="50"
                  show-word-limit
                  class="padding_right_area"
          ></el-input>
        </el-form-item>
        <el-form-item label="发货人（SHIPPER）" :label-width="formLabelWidth2">
          <el-input
            type="textarea"
            v-model="hbl.shipper"
            maxlength="500"
            show-word-limit
            class="padding_right_area"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="收货人（CONSIGNEE）"
          :label-width="formLabelWidth2"
        >
          <el-input
            type="textarea"
            v-model="hbl.consignee"
            maxlength="500"
            show-word-limit
            class="padding_right_area"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="通知人（NOTIFY PARTY）"
          :label-width="formLabelWidth2"
        >
          <el-input
            type="textarea"
            v-model="hbl.notifyParty"
            maxlength="500"
            show-word-limit
            class="padding_right_area"
          ></el-input>
        </el-form-item>
        <el-form-item label="HBL（备注）" :label-width="formLabelWidth2">
          <el-input
            type="textarea"
            v-model="hbl.remark"
            maxlength="500"
            show-word-limit
            class="padding_right_area"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="f-p-b-15" style="text-align: center">
        <el-button
            size="medium"
            type="primary"
            @click="savebl"
            v-if="buttonShowList.a"

          >保存</el-button>
        <el-button type="text" class="f-m-l-10" @click="hblone"
        >提单PDF</el-button>
        <el-button type="text" class="f-m-l-10" @click="hbloneexcel"
        >提单EXCEL</el-button>
        <el-button type="text" class="f-m-l-10" @click="telexReleasePdf"
        >电放提单PDF</el-button>
        <el-button type="text" class="f-m-l-10" @click="telexReleaseStripPdf"
        >电放条形章PDF</el-button>
        <el-button type="text" class="f-m-l-10" @click="telexReleaseFixPdf"
        >电放+条形章PDF</el-button>
        <el-button type="text" class="f-m-l-10" @click="hbloneexcel2"
        >到货通知下载</el-button>
      </div>
    </el-card>
  </div>
</template>

<script>
import { getAction, postAction } from "@/api/manage";


export default {
  name: "HBLModule",
  props:{
    isShow:{
      type:Boolean,
      default:false,
    }
  },
  components: {},
  data() {
    return {
      isf: {},
      hbl: {},
      formLabelWidth2: "160px",
      url: {
        savehbl: "/interface/ttOrder/addOrUpdateHblByOrderNo",
        gethbl: "/interface/ttOrder/getHblByOrderNo",
        hblone: "/file/jasper/downloadHBL",
        hbloneexcel: "/file/jasper/downloadHBL?t=excel",
      },
      //表单校验
      rules: {
        name: [{ required: true, message: "请输入文件名", trigger: "blur" }],
        type: [
          { required: true, message: "请选择文件类型", trigger: "change" },
        ],
      },
    };
  },
  created() {
    this.$set(this.hbl, "orderNo", this.$route.query.orderNo);
    this.initbl();
  },
  mounted() {},
  methods: {
    downloadExcel(url, fileName, params, fileType) {
      getAction(url, params, {responseType: 'blob'})
          .then(response => {
            if (!response) {
              return
            }
            const blob = new Blob([response])
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              navigator.msSaveBlob(blob, fileName)
            } else {
              let u = window.URL.createObjectURL(response)
              let aLink = document.createElement('a')
              aLink.style.display = 'none'
              aLink.href = u
              aLink.setAttribute('download', fileName + (fileType || '.xls'))
              document.body.appendChild(aLink)
              aLink.click()
              document.body.removeChild(aLink)
              window.URL.revokeObjectURL(u)
            }
          })
    },
    //预览pdf
    preview(url, params, fileName) {
      getAction(url, params, {responseType: 'blob'})
          .then(res => {
            var binaryData = [];
            binaryData.push(res);
            let urlencoded = window.URL.createObjectURL(new Blob(binaryData,
                {"type": "application/pdf"}));
            if(fileName) {
              var dllink = document.createElement('a');
              dllink.href = urlencoded;
              dllink.setAttribute("download", fileName + ".pdf"); // Added Line
              dllink.click();
            } else {
              window.open(urlencoded)
            }
            // 打开之后再下载
            // window.open("/static/pdf/web/viewer.html?filename=" + fileName + "&file=" + encodeURIComponent(urlencoded));
          })
    },
    hblone() {
      let params = {orderNo: this.$route.query.orderNo, t: 'pdf', isHbl: '1'};
      this.preview(
          this.url.hblone,
          params,
          'HBL提单PDF-' + this.$route.query.orderNo
      );
    },
    hbloneexcel() {
      let params = {orderNo: this.$route.query.orderNo, isHbl: '1'};
      this.downloadExcel(
          this.url.hbloneexcel,
          "HBL提单Excel-" + this.$route.query.orderNo,
          params
      );
    },
    telexReleasePdf() {
      let params = {orderNo: this.$route.query.orderNo, t: 'pdf', isDian: '1', isHbl: '1'};
      this.preview(
          this.url.hblone,
          params,
          "电放提单-" + this.$route.query.orderNo
      );
    },
    telexReleaseStripPdf() {
      let params = {orderNo: this.$route.query.orderNo, t: 'pdf', isTiao: '1', isHbl: '1'};
      this.preview(
          this.url.hblone,
          params,
          "电放条形章-" + this.$route.query.orderNo
      );
    },
    telexReleaseFixPdf() {
      let params = {orderNo: this.$route.query.orderNo, t: 'pdf', isDian: '1', isMerge: '1', isHbl: '1'};
      this.preview(
          this.url.hblone,
          params,
          "电放+条形章-" + this.$route.query.orderNo
      );
    },
    hbloneexcel2() {
      this.downloadExcel(
          this.url.hbloneexcel + "&orderNo=" + this.$route.query.orderNo +"&isD=1",
          "到货通知"+ this.$route.query.orderNo,
          {}
      );
    },
    initbl() {
      getAction(this.url.gethbl, { orderNo: this.$route.query.orderNo,type:'0'}).then(
        (res) => {
          if (res.success) {
            if (res.result.id) {
              this.hbl = res.result;
            }
          }
        }
      );
    },
    savebl() {
      this.$set(this.hbl, "orderNo", this.$route.query.orderNo || '');
      this.$set(this.hbl, "type", "0");
      postAction(this.url.savehbl, this.hbl).then((res) => {
        this.$message.success(res.message);
        this.initbl();
      });
    },

  },
  computed : {
    buttonShowList() {
      return {
        'a':this.$btnIsShow('docpage/modules/HBlModule','0','保存hbl'),
      }
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
