<template>
    <el-dialog
            title="复制信息"
            :visible="visible"
            width="30%"
            custom-class="c-custom-dialog"
            append-to-body
            :before-close="handleClose"
            :close-on-click-modal="false">
        <el-form class="addEditBox" :model="form" label-width="100px" size="medium">


            <el-form-item prop="takeNo" label="提货序号" :label-width="formLabelWidth">
                <span v-text="form.takeNo"></span>
            </el-form-item>

            <el-form-item prop="doc" label="海运客服" :label-width="formLabelWidth">
                <span v-text="form.doc"></span>
            </el-form-item>

            <el-form-item prop="statusStr" label="派车状态" :label-width="formLabelWidth">
                <span v-text="form.statusStr"></span>
            </el-form-item>

            <el-form-item prop="useCarTypeStr" label="派车类型" :label-width="formLabelWidth">
                <span style="margin-left: 20px" v-text="form.useCarTypeStr"></span>
            </el-form-item>

            <el-form-item prop="takeTime" label="提货时间" :label-width="formLabelWidth">
                <span v-text="form.takeTime"></span>
            </el-form-item>

            <el-form-item prop="warehouseName" label="交货仓库" :label-width="formLabelWidth">
                <span v-text="form.warehouseName"></span>
            </el-form-item>
            <el-form-item prop="warehouseName" label="拼柜仓库" :label-width="formLabelWidth">
                <span v-text="form.warehouseName"></span>
            </el-form-item>

            <el-form-item prop="address" label="提货信息" :label-width="formLabelWidth">
                <span v-text="form.address"></span>
            </el-form-item>

            <el-form-item prop="contact" label="联系人" :label-width="formLabelWidth">
                <span v-text="form.contact"></span>
            </el-form-item>

            <br>
            <br>
            <el-form-item prop="remark" label="需求备注" :label-width="formLabelWidth">
                <span v-for="(item,index) in form.orderList" :key="index">
                    <span>{{item.orderNo}}</span>
                    <span style="margin-left: 10px">{{item.ctn}}件</span>
                    <span style="margin-left: 10px">{{item.kg}}kg</span>
                    <span style="margin-left: 10px">{{item.cmb}}方</span>
                    <span style="margin-left: 10px">{{item.channelName}}</span>
                    <br>
                </span>
            </el-form-item>


        </el-form>
        <span slot="footer">
            <el-button size="medium" @click="handleClose">取消</el-button>
            <el-button
                    size="small"
                    class="search-button"
                    type="primary"
                    @click="clickCopy()"
            >复制</el-button>

        </span>
    </el-dialog>
</template>

<script>
    import {getAction, postAction} from "@/api/manage";
    import BindingKJBRelation from "../../operation/baoxian/BindingKJBRelation";

    export default {
        name: 'dispatchToCompleteModule',
        components: {BindingKJBRelation},
        props: {
            visible: {
                type: Boolean
            },
            takeIds: {
                type: String,
                default: ''
            },
        },
        data() {
            return {
                form: {orderList: []},
                formLabelWidth: '100px',
                url: {
                    copyTakeRecordUrl: "/warehouse/takeDelivery/copyTakeRecord",
                    //查询详情
                    getTakeOrderDetailById: '/warehouse/takeDelivery/getTakeOrderDetailById'
                },

            }
        },
        watch: {
            visible(val) {
                if (val && this.takeIds) {
                    //勾选的订单号进行赋值
                    getAction(this.url.getTakeOrderDetailById, {takeIds: this.takeIds}).then((res) => {
                        console.log(res);
                        if (res.success) {
                            this.form = res.result;
                        }
                    });
                }
            },
        },
        methods: {
            doCopy(val) {
                let _this = this;
                this.$copyText(val).then(() => {
                    _this.$message({
                        message: '复制成功，可以使用ctrl+v快捷键粘贴',
                        type: 'success'
                    });
                    this.handleClose();
                }).catch(e => {
                    _this.$message.error('复制失败');
                })
            },
            clickCopy() {
                let takeNo = this.form.takeNo || '';
                let doc = this.form.doc || '';
                let statusStr = this.form.statusStr || '';
                let useCarTypeStr = this.form.useCarTypeStr || '';
                let takeTime = this.form.takeTime || '';
                let warehouseName = this.form.warehouseName || '';
                let address = this.form.address || '';
                let contact = this.form.contact || '';
                let content = '提货序号：' + takeNo + '\n'
                    + '海运客服：' + doc + '\n'
                    + '派车状态：' + statusStr + '\n'
                    + '派车类型：' + useCarTypeStr + '\n'
                    + '提货时间：' + takeTime + '\n'
                    + '交货仓库：' + warehouseName + '\n'
                    + '拼柜仓库：' + warehouseName + '\n'
                    + '提货信息：' + address + '\n'
                    + '联系人：' + contact + '\n'
                    + '需求备注：';
                let list = this.form.orderList;
                if (list && list.length > 0) {
                    for (let i = 0; i < list.length; i++) {
                        let obj = list[i];
                        content = content +
                            +obj.orderNo + ' ' + obj.ctn + '件 ' + obj.kg + 'kg ' + obj.cmb + '方 ' + obj.channelName + '\n'
                    }
                }
                this.doCopy(content);
            },
            handleSubmit() {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        postAction(this.url.add, this.form).then((res) => {
                            this.$message({
                                type: 'success',
                                message: res.message
                            })
                            this.handleClose();
                            this.$emit('ok');
                        })
                    }
                })
            },
            handleClose() {
                this.$emit('update:visible', false)
            }
        },
        created() {
        }
    }
</script>

<style lang="scss" scoped>
    ::v-deep
    .el-dialog__body {
        padding: 20px 40px 20px 6px;
    }

    .addEditBox {
        max-height: 615px;
        overflow: scroll;
    }
</style>
