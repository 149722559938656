<template>
    <div>
        <el-card class="box-card">
            <el-form class="multiline_select" ref="form" :model="queryForm" label-width="80px" size="medium">
                <el-card class="box-card">
                    <el-row>
                        <el-col :xs="24" :sm="12" :md="12" :lg="5">
                            <el-form-item label="国家" :label-width="formLabelWidth">
                                <f-select  v-model="queryForm.country" :dict="'bd_country'" :is-need="queryForm.country"
                                           @keyup.enter.native="handleSearch"></f-select>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="12" :md="12" :lg="5">
                            <el-form-item label="航线名称" :label-width="formLabelWidth">
                                <f-select  v-model="queryForm.solicitation" :dict="'fba_solicitation'"
                                           :is-need="queryForm.solicitation"
                                           @keyup.enter.native="handleSearch"></f-select>
                            </el-form-item>
                        </el-col>
                      <el-col :xs="24" :sm="12" :md="12" :lg="5">
                        <el-form-item label="海关编码前缀" :label-width="formLabelWidth">
                          <el-input  v-model="queryForm.preHscode"

                                     @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                      </el-col>
                        <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="10">
                            <el-form-item label="录入时间">
                                <div class="f-flex">
                                    <el-date-picker
                                            class="f-flex-1"
                                            v-model="queryForm.createTimeStart"
                                            type="datetime"
                                            size="small"
                                            placeholder="开始时间"
                                            default-time="00:00:00"
                                            value-format="yyyy-MM-dd HH:mm:ss"
                                            @keyup.enter.native="handleSearch"
                                    >
                                    </el-date-picker>
                                    <span class="f-p-h-2">~</span>
                                    <el-date-picker
                                            class="f-flex-1"
                                            v-model="queryForm.createTimeEnd"
                                            type="datetime"
                                            size="small"
                                            placeholder="结束时间"
                                            default-time="23:59:59"
                                            value-format="yyyy-MM-dd HH:mm:ss"
                                            @keyup.enter.native="handleSearch"
                                    >
                                    </el-date-picker>
                                </div>
                            </el-form-item>
                        </el-col>



                        <el-col :xs="24" :sm="12" :md="12" :lg="4">
                            <el-form-item label-width="30px">
                                <el-button
                                        type="primary"
                                        icon="el-icon-search"
                                        @click="handleSearch"
                                >查询
                                </el-button
                                >
                                <el-button plain @click="handleReset">重置</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>


                </el-card>
            </el-form>
            <div class="bbb">
                <el-button type="primary" size="medium" @click="addOrEdit"
                           v-if="buttonShowList.add">新增
                </el-button>

                <el-button type="danger" size="medium" @click="delDuo" :disabled="selectedRowKeys.length === 0"
                           v-if="buttonShowList.plsc">批量删除
                </el-button>
            </div>

            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="73vh"
            >
            <template v-slot="scope">
                <template v-if = "scope.column.property === 'action'">
                    <el-button type="text" size="medium" @click="addOrEdit(scope.row)" icon="el-icon-edit"
                               v-if="buttonShowList.bj">
                    </el-button>
<!--                    <el-button type="primary" icon="el-icon-edit" circle></el-button>-->

                </template>
            </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange">
                </el-pagination>
            </el-row>
        </el-card>
        <mingan-goods-rules-dialog :visible.sync="visible" @ok="handleSearch" :row="selectedRow"></mingan-goods-rules-dialog>
    </div>
</template>

<script>
    import QueryMixins from "@/mixins/QueryMixins.js";
    import {postAction} from "@/api/manage";
    import MinganGoodsRulesDialog from "@/views/isystem/BaseData/modules/MinganGoodsRulesDialog.vue";
    export default {
        name: "MinganGoodsRules",
        mixins: [QueryMixins],
        components: {MinganGoodsRulesDialog},
        data() {
            return {
                formLabelWidth:'100px',
                isUsePost:true,
                visible:false,
                selectedRow:{},
                columns: [
                    {
                        type: "selection",
                    },
                    {
                        prop: "action",
                        label: "操作",
                    },
                    {
                        prop: "country",
                        label: "国家",
                    },
                    {
                        prop: "solicitationText",
                        label: "航线名称",
                    },
                    {
                        prop: "preHscode",
                        label: "海关编码前缀",
                    },
                    {
                        prop: "createTime",
                        label: "创建时间",
                    },
                    {
                        prop: "createBy",
                        label: "创建人",
                    },


                ],
                url: {
                    //查询列表的接口
                    list: "/sys/minGanGoodsRules/getIpage",


                    delDuo:'/sys/minGanGoodsRules/delDuo',
                },


            };
        },
        methods: {
            delDuo(){
                this.$confirm(`确定批量删除?`, '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'success',
                        closeOnClickModal: false,
                }).then(()=>{
                    postAction(this.url.delDuo,this.selectedRowKeys)
                        .then(res=>{
                            this.$message({type:'success',message:res.message});
                            this.handleSearch();
                        })
                })
            },
            addOrEdit(row){
                if (row) {
                    this.selectedRow = JSON.parse(JSON.stringify(row));
                }else {
                    this.row = {};
                }
                this.visible = true;
            },
        },
      computed:{
        buttonShowList() {
          return {
            'add':this.$btnIsShow('isystem/BaseData/MinganGoodsRules','0','新增'),
            'plsc':this.$btnIsShow('isystem/BaseData/MinganGoodsRules','0','批量删除'),
            'bj':this.$btnIsShow('isystem/BaseData/MinganGoodsRules','1','编辑'),
          }
        }
      }
    };
</script>

<style lang="scss" scoped>
    .bbb {
        display: flex;
    }

    .dialog-footer {
        text-align: right !important;
    }

    .margin_right_10 {
        margin-right: 10px;
    }
</style>
