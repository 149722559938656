<template>
  <div class="main-full-content">
    <el-form
      class="input_top"
      label-width="80px"
      size="medium"
      @submit.native.prevent
    >
      <el-card class="box-card">
        <el-row>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="优惠类型">
              <el-input
                v-model.trim="queryForm.type"
                @keyup.enter.native="handleSearch"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label-width="30px">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="handleSearch"
                >查询
              </el-button>
              <el-button plain @click="handleReset">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
    </el-form>

    <div lass="box-card last_card">
      <div class="f-p-b-15">
        <el-button
          v-if="buttonShowList.add"
          type="primary"
          size="medium"
          icon="el-icon-plus"
          @click="actionBtn(null, '4')"
          >新增
        </el-button>

        <el-button
          v-if="buttonShowList.delB"
          type="primary"
          size="medium"
          icon="el-icon-plus"
          :disabled="!selectedRowKeys.length"
          @click="actionBtn(null, '5')"
          >批量删除
        </el-button>
      </div>
      <f-table
        v-loading="loading"
        ref="GTable"
        border
        size="medium"
        row-key="id"
        class="c-th-has-bgcolor"
        :columns="columns"
        :data="tableData"
        @selection-change="handleSelectionChange"
        @sort-change="handleTableChange"
        :cell-style="{ 'text-align': 'center' }"
        tableHeight="73vh"
      >
        <template v-slot="scope">
          <template v-if="scope.column.property === 'action'">
            <el-button type="text" @click="actionBtn(scope.row, '1')"
              v-if="buttonShowList.edit"
              >编辑</el-button
            >
            <el-button type="text" @click="actionBtn(scope.row, '2')"
              v-if="buttonShowList.del"
              >删除</el-button
            >
            <el-button type="text" @click="actionBtn(scope.row, '3')"
              v-if="buttonShowList.log"
              >日志</el-button
            >
          </template>
        </template>
      </f-table>
    </div>

    <el-row class="f-text-right f-p-v-8">
      <el-pagination
        v-bind="ipagination"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </el-row>
    <edit-discount-type-dialog :visible.sync="visible" :type="type" :row="typeForm" @ok="handleSearch"></edit-discount-type-dialog>
  </div>
</template>

<script>
import QueryMixins from "@/mixins/QueryMixins.js";
import { getAction, postAction, deleteAction } from "@/api/manage";
import EditDiscountTypeDialog from "./modules/EditDiscountTypeDialog";
export default {
  mixins: [QueryMixins],
  components: {
    EditDiscountTypeDialog,
  },
  data() {
    return {
      columns: [
        {
          type: "selection",
        },
        {
          prop: "action",
          label: "操作",
          width: 150,
        },
        {
          prop: "type",
          label: "优惠类型",
        },
        {
          prop: "createBy",
          label: "添加人",
        },
        {
          prop: "updateTime",
          label: "更新时间",
        },
      ],
      url: {
        list: "/sys/bdDiscountTypes/getTypePage", //查询列表的接口
        deleteType: "/sys/bdDiscountTypes/deleteType", //删除
      },
      visible: false,
      typeForm: {},
      type: '',
    };
  },
  methods: {
    //  type 1-编辑  2-删除 3-日志 4-新增优惠类型 5-批量删除
    actionBtn(row, type) {
      if (type === "1") {
        this.type = '';
        this.visible = true;
        this.typeForm = { ...row };
      } else if (type === "2") {
        this.$confirm(`确定删除`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          closeOnClickModal: false,
        })
          .then(() => {
            postAction(this.url.deleteType, [row.id]).then((res) => {
              this.$message({
                type: "success",
                message: res.message,
              });
              this.handleQuery();
            });
          })
          .catch(() => {});
      } else if (type === "3") {
        this.visible = true;
        this.type = 'log';
        this.typeForm = { ...row };
      } else if (type === "4") {
        this.type = '';
        this.typeForm = {};
        this.visible = true;
      } else if (type === "5") {
        this.$confirm(`确定删除`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          closeOnClickModal: false,
        })
          .then(() => {
            postAction(this.url.deleteType, this.selectedRowKeys).then((res) => {
              this.$message({
                type: "success",
                message: res.message,
              });
              this.handleQuery();
            });
          })
          .catch(() => {});
      }
    },
  },
  computed:{
    buttonShowList() {
      return {
        'add':this.$btnIsShow('product/DiscountType','0','新增'),
        'delB':this.$btnIsShow('product/DiscountType','0','批量删除'),

        'edit':this.$btnIsShow('product/DiscountType','1','编辑'),
        'del':this.$btnIsShow('product/DiscountType','1','删除'),
        'log':this.$btnIsShow('product/DiscountType','1','日志'),
      }
    }
  }
};
</script>

<style>
</style>