<template>
  <div>
    <el-dialog
        :title="'发送排柜邮件详情：' + week"
        :visible="visible"
        width="75%"
        custom-class="c-custom-dialog"
        :close-on-click-modal="false"
        :before-close="handleClose"
        append-to-body
    >
      <el-form class="multiline_select" ref="form" :model="queryForm" label-width="80px" size="medium">
        <el-card class="box-card">
          <el-row class="inputs_row">
              <el-form-item label="收款人">
                  <f-select v-model="queryForm.receiverId" :is-need="queryForm.receiverId" :dict="'sys_user_id'"></f-select>
              </el-form-item>

              <el-form-item label="成功">
                  <el-select v-model="queryForm.result" clearable filterable>
                      <el-option value = "成功" label="成功"></el-option>
                      <el-option value = "失败" label="失败"></el-option>
                  </el-select>
              </el-form-item>
            <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="3">
              <el-form-item label-width="30px">
                <el-button
                    type="primary"
                    icon="el-icon-search"
                    @click="handleSearch"
                    :disabled="disabledFlag"
                >查询
                </el-button
                >
                <el-button plain @click="handleReset">重置</el-button>
                <!-- <el-button type="text" mini @click="showLoadingSearch=!showLoadingSearch"><i :class="showLoadingSearch ? 'el-icon-arrow-up' : 'el-icon-arrow-down'" style="margin-right:3px"></i>{{showLoadingSearch ? '收起' :'更多'}}</el-button> -->
              </el-form-item>
            </el-col>
          </el-row>
        </el-card>
      </el-form>
      <el-card class="box-card">
        <f-table
            v-loading="loading"
            ref="GTable"
            border
            size="medium"
            row-key="id"
            class="c-th-has-bgcolor"
            :columns="columns"
            :data="tableData"
            :cell-style="{ 'text-align': 'center' }"
        >
            <template v-slot="scope">
                <template v-if="scope.column.property === 'action'">

                </template>
                <template v-else-if="scope.column.property === 'result'">
                    <span :style="scope.row.result === '成功' ? 'color:green' : 'color : red'">{{scope.row.result}}</span>
                </template>
                <template v-else-if="scope.column.property === 'fileName'">
                    <a v-if="scope.row.url" type="text" @click.prevent="xiazaiShuidan(scope.row.url)" style="color:#57a3f3;">{{scope.row.fileName}}</a>
                    <v-else><span></span></v-else>
                </template>
                <template v-else-if="scope.column.property === 'sendTimes'">
                    <el-popover trigger="hover" placement="top" >
                        <!-- 鼠标移动需要提示的其他字段信息（showMessage字段传递对应要展示的字段名称） : 自己的字段超出的鼠标移动展示  -->
                        <span v-for="(item,index) in scope.row.paiguiEmailSendTimesLogs" :key="index">
                            发送时间： {{item.createTime}} -- 发送人：{{item.createBy}} -- 邮箱： {{item.email}}

                            -- 发送结果：<span :style="item.result === '成功' ? 'color:green' : 'color : red'">{{item.result}}</span>
                            -- 税单：
                            <a v-if="item.url" type="text" @click.prevent="xiazaiShuidan(item.url)" style="color:#57a3f3;">{{item.fileName}}</a>
                            <br>
                        </span>
                        <div slot="reference" class="name-wrapper tag-center">
                            <el-tag size="medium" class="tag-ellipsis">{{ scope.row.sendTimes }}</el-tag>
                        </div>
                    </el-popover>
                </template>
            </template>
        </f-table>
        <el-row class="f-text-right f-p-v-8">
          <el-pagination
              v-bind="ipagination"
              layout="total, sizes, prev, pager, next, jumper"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
          >
          </el-pagination>
        </el-row>
      </el-card>
    </el-dialog>


  </div>
</template>

<script>
import QueryMixins from "@/mixins/QueryMixins.js";
import {postAction} from "@/api/manage";


export default {
  name: "SendPaiguiEmailLog",
  mixins: [QueryMixins],
  components: {
  },
  props:{
    week:{
      type:String
    },
    containerId:{
      type:String
    },
    visible:{
      type:Boolean
    }
  },
  data() {
    return {
      disabledFlag:false,
      showLoadingSearch: false,
      //联系人类型0公司负责人 1报价对接人 2操作联系人 3财务联系人',
      btnDisable: false,
      form: {},
      dialogFormVisible: false,
      formLabelWidth: "120px",
      row: {},
      proVisible: false,
        isUsePost:true,
      url: {
        list: "/operation/container/getOsContainerSendPaiguiEmailLogIPage",


      },

      columns: [
        {
          type: "index",
          label:'序号',
          width: 33,
        },
          // {
          //     prop: "action",
          //     label: "操作",
          // },
        {
          prop: "title",
          label: "标题",
            minWidth:120,
        },
          {
              prop: "receiver",
              label: "收款人",
              minWidth:60,
          },

        {
          prop: "email",
          label: "邮箱",
            minWidth:100,
        },
        {
          prop: "fileName",
          label: "税单",
            minWidth:90,
        },
        {
          prop: "result",
          label: "成功",
            minWidth:30,
        },
        {
          prop: "createBy",
          label: "创建人",
        },
        {
          prop: "createTime",
          label: "创建时间",
        },
        {
          prop: "sendTimes",
          label: "发送次数",
            // width:60
        },
          // {
          //     prop: "sendDetail",
          //     label: "发送详情",
          //     minWidth: 250
          // },


      ],
      disableMixinMounted: true,
    };
  },
  watch: {
    visible(val) {
      if(val && this.containerId) {
        this.$set(this.queryForm,'containerId',this.containerId);
       this.handleQuery();
      } else {

      }
    }
  },
  created() {
      this.$set(this.queryForm,'containerId',this.containerId);
  },
  methods: {
    xiazaiShuidan(url){
        window.open(url);
    },
    handleClose() {
      this.$emit("ok");
    },

    handleReset() {
      this.queryForm = {};
      this.filterForm = {};
      this.ipagination.currentPage = 1;
      if (this.$refs['GTable']){
        this.$refs['GTable'].handleReset();
      }
      this.$set(this.queryForm,'containerId',this.containerId);
      this.handleQuery();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
