<!--待处理问题件-->
<template>
    <div class="main-full-content">
        <el-form ref="form" :model="queryForm" label-width="80px" size="medium">
            <div style="display:flex;">
                <el-card class="box-card">
                  <el-row :gutter="24" class="inputs_row">
                    <el-col :md="4" :sm="24">
                  <el-form-item label="订单号">
                    <el-input v-model="queryForm.orderNo" @keyup.enter.native="handleSearch" type="textarea"
                    ></el-input>
                  </el-form-item>
                  </el-col>
                  <el-col :md="4" :sm="24">
                      <el-form-item label="问题件处理人" label-width="100px">
                          <f-select :dict="'sys_user'"
                                  v-model="queryForm.handelBy"
                                  :is-need="queryForm.handelBy"
                        >
                        </f-select>
                      </el-form-item>
                  </el-col>
                  <el-col :md="4" :sm="24">
                      <el-form-item label="客户名">
                          <el-input v-model.trim="queryForm.customer" @keyup.enter.native="handleSearch"></el-input>
                      </el-form-item>
                  </el-col>
                  <el-col :md="4" :sm="24">
                    <el-form-item label="付款类型">
                      <f-select :dict="'pay_type'"
                                v-model="queryForm.payType"
                                :is-need="queryForm.payType"
                      >
                      </f-select>
                    </el-form-item>
                  </el-col>
                  <el-col :md="4" :sm="24">
                    <el-form-item label="运输方式">
                      <f-select :dict="'os_send_type'"
                                v-model="queryForm.sendType"
                                :is-need="queryForm.sendType"
                      >
                      </f-select>
                    </el-form-item>
                  </el-col>
                  <el-col :md="4" :sm="24">
                    <el-form-item label="订单状态">
                      <f-select
                          v-model="queryForm.orderStatus"
                          :is-need="queryForm.orderStatus"
                          :dict="'orderStatus'">
                      </f-select>
                    </el-form-item>
                  </el-col>
                  <el-col :md="4" :sm="24">
                    <el-form-item label="业务员">
                      <f-select
                              v-model="queryForm.salesmanId"
                              :is-need="queryForm.salesmanId"
                              :dict="'salesmanId'">
                      </f-select>
                    </el-form-item>
                  </el-col>
                  <el-col :md="4" :sm="24">
                    <el-form-item label="起运港">
                      <f-select
                          v-model="queryForm.pol"
                          :is-need="queryForm.pol"
                          :dict="'bd_port'">
                      </f-select>
                    </el-form-item>
                  </el-col>
                  <el-col :md="4" :sm="24">
                    <el-form-item label="目的港">
                      <f-select
                          v-model="queryForm.podEnd"
                          :is-need="queryForm.podEnd"
                          :dict="'bd_port'">
                      </f-select>
                    </el-form-item>
                  </el-col>
                  <el-col :md="4" :sm="24">
                    <el-form-item label="船公司">
                      <f-select
                          v-model="queryForm.shipCompanyId"
                          :is-need="queryForm.shipCompanyId"
                          :dict="'ship_company'">
                      </f-select>
                    </el-form-item>
                  </el-col>
                  <el-col :md="4" :sm="24">
                    <el-form-item label="客服">
                      <f-select
                              v-model="queryForm.docId"
                              :is-need="queryForm.docId"
                              :dict="'docId'">
                      </f-select>
                    </el-form-item>
                  </el-col>
                  <el-col :md="4" :sm="24">
                    <el-form-item label="操作人">
                      <f-select
                              v-model="queryForm.operateId"
                              :is-need="queryForm.operateId"
                              :dict="'operationsId'">
                      </f-select>
                    </el-form-item>
                  </el-col>
                  <el-col class="no_margin" :md="4" :sm="24">
                    <el-form-item label="派送操作">
                      <f-select
                              v-model="queryForm.endOperatorId"
                              :is-need="queryForm.endOperatorId"
                              :dict="'operationsId'">
                      </f-select>
                    </el-form-item>
                  </el-col>
                  <el-col class="no_margin" :md="4" :sm="24">
                    <el-form-item label="SHIPMENT ID" label-width="100px">
                      <el-input v-model="queryForm.shipmentId" @keyup.enter.native="handleSearch"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col class="no_margin" :md="6" :sm="24">
                    <el-form-item label="下单时间">
                      <div class="f-flex">
                        <el-date-picker
                            class="f-flex-1"
                            v-model="queryForm.createTimeStart"
                            type="datetime"
                            size="small"
                            placeholder="开始时间"
                            default-time="00:00:00"
                            value-format="yyyy-MM-dd HH:mm:ss"
                        >
                        </el-date-picker>
                        <span class="f-p-h-2">~</span>
                        <el-date-picker
                            class="f-flex-1"
                            v-model="queryForm.createTimeEnd"
                            type="datetime"
                            size="small"
                            placeholder="结束时间"
                            default-time="23:59:59"
                            value-format="yyyy-MM-dd HH:mm:ss"
                        >
                        </el-date-picker>
                      </div>
                    </el-form-item>
                  </el-col>
                  <el-col :span="4">
                    <el-button
                        size="medium"
                        type="primary"
                        icon="el-icon-search"
                        @click="handleSearch"
                    >查询</el-button
                    >
                    <el-button
                        size="medium"
                        type="primary"
                        @click="handleReset"
                        icon="reload"
                        style="margin-left: 8px"
                    >重置</el-button
                    >
                  </el-col>
                </el-row>
              </el-card>
            </div>
        </el-form>
        <el-card class="box-card last_card">
            <div>
                <el-button type="primary" size="medium" icon="el-icon-plus" @click="handleExportXls('待处理问题件报表')">导出</el-button>
            </div>
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="63vh"
                    :isRecordTableScroll="true"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange"
                    @queryChange="handleTableQueryChange"
            >
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'bussinessType'">
                        <span>{{getBusType(scope.row.containerType,scope.row.bussinessType)}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'transportStatus'">
                        <span>{{parseDict(scope.row.transportStatus,'transportStatus')}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'orderStatus'">
                        <span>{{parseDict(scope.row.orderStatus,'orderStatus')}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'podEnd'">
                        <span>{{parseDict(scope.row.podEnd,'bd_port')}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'payType'">
                        <span>{{parseDict(scope.row.payType,'pay_type')}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'sendType'">
                        <span>{{parseDict(scope.row.sendType,'os_send_type')}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'ctn'">
                        <span>{{scope.row.ctn}}/{{scope.row.kg}}/{{scope.row.cmb}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'realCtn'">
                        <span>{{scope.row.realCtn}}/{{scope.row.realKg}}/{{scope.row.realCmb}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'orderNo'">
                        <template v-if="scope.row.isProblem === '1'">
                            <a style="color: #e9100e" @click.prevent="$intoDocPage(scope.row.orderNo)"
                            >{{ scope.row.orderNo }} (问题件)</a>
                        </template>
                        <template v-else>
                            <a style="color: #00a0e9" @click.prevent="$intoDocPage(scope.row.orderNo)"
                            >{{ scope.row.orderNo }}</a>
                        </template>
                    </template>
                    <template v-else-if="scope.column.property === 'customer'">
                        <a type="text" @click.prevent="$intoCustomerEditPage(scope.row.cusId)" style="color:#57a3f3;">{{scope.row.customer}}</a>
                    </template>
                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                >
                </el-pagination>
            </el-row>
        </el-card>
    </div>
</template>

<script>
    import {deleteAction, postAction, putAction} from "@/api/manage";
    import QueryMixins from "../../mixins/QueryMixins";
    import { setUpRouteUtil } from '@/utils/util';

    export default {
        name: "Potential",
        mixins: [QueryMixins],
        data() {
            return {
                dictCodes: ['is_default', 'transportStatus', 'order_importer', 'bd_port', 'orderStatus', 'pay_type','os_send_type'],
                ids: [],
                row: {},
                form: {},
                formLabelWidth: "100px",
                //控制弹框显示
                dialogFormVisible: false,
                columns: [
                    {
                        type: "selection",
                        width: 50,
                        fixed: 'left'
                    },
                    {
                        prop: "orderNo",
                        label: "订单号",
                        minWidth: 100,
                        fixed: 'left'
                    },
                    {
                        prop: "handelBy",
                        label: "问题件处理人",
                        minWidth: 80,
                        fixed: 'left'
                    },
                    {
                        prop: "customer",
                        label: "客户名",
                        minWidth: 100,
                        fixed: 'left'
                    },
                    {
                        prop: "payType",
                        label: "付款类型",
                        minWidth: 80,
                    },
                    {
                        prop: "createTime",
                        label: "下单时间",
                        sortable: "custom",
                        minWidth: 100,
                    },
                    {
                        prop: "endPlace",
                        label: "目的地",
                        minWidth: 100,
                    },
                    {
                        prop: "bussinessType",
                        label: "业务类型",
                        minWidth: 100,
                    },
                    {
                        prop: "sendType",
                        label: "运输方式",
                        minWidth: 50,
                    },
                    {
                        prop: "orderStatus",
                        label: "订单状态",
                        minWidth: 50,
                    },
                    {
                        prop: "ctn",
                        label: "预计件重体",
                    },
                    {
                        prop: "realCtn",
                        label: "实际件重体",
                    },
                    {
                        prop: "salesman",
                        label: "业务员",
                        minWidth: 80,
                    },
                    {
                        prop: "pol",
                        label: "起运港",
                        minWidth: 100,
                    },
                    {
                        prop: "podEnd",
                        label: "目的港",
                        minWidth: 100,
                    },
                    {
                        prop: "shipCompanyId",
                        label: "船公司",
                        minWidth: 100,
                    },
                    {
                        prop: "containerNo",
                        label: "柜号",
                    },
                    {
                        prop: "etd",
                        label: "ETD",
                        sortable: "custom",
                        minWidth: 100,
                    },
                    {
                        prop: "eta",
                        label: "ETA",
                        sortable: "custom",
                        minWidth: 100,
                    },
                    {
                        prop: "doc",
                        label: "客服",
                        minWidth: 80,
                    },
                    {
                        prop: "operator",
                        label: "操作人",
                        minWidth: 80,
                    },
                    {
                        prop: "endOperator",
                        label: "派送操作",
                        minWidth: 80,
                    },
                    {
                        prop: "shipmentId",
                        label: "SHIPMENT ID",
                        minWidth: 80,
                    },
                    {
                        prop: "froRemark",
                        label: "客户备注",
                        minWidth: 100,
                    },
                    {
                        prop: "remark",
                        label: "内部备注",
                        minWidth: 100,
                    },
                ],
                url: {
                    list: "/interface/sales/getSaleDepartmentList",
                    //exportXlsUrl: "/order/sale/problemExportXls",
                    exportXlsUrl: "/file/export/excel/SalesExcel/downloadSaleDepartmentExportXls",
                },
            };
        },
        methods: {
            getBusType(containerType, type) {
                let str = "";
              if (!type){
                return "";
              }
                if (containerType == "1") {
                    //fcl

                    if (type.indexOf("0") !== -1) {
                        str += "订舱,";
                    }
                    if (type.indexOf("1") !== -1) {
                        str += "拖车,";
                    }
                    if (type.indexOf("2") !== -1) {
                        str += "报关,";
                    }
                    if (type.indexOf("3") !== -1) {
                        str += "清关,";
                    }
                    if (type.indexOf("4") !== -1) {
                        str += "派送到门,";
                    }
                }
                if (containerType == "0") {
                    //fcl

                    if (type.indexOf("0") !== -1) {
                        str += "提货,";
                    }
                    if (type.indexOf("1") !== -1) {
                        str += "报关,";
                    }
                    if (type.indexOf("2") !== -1) {
                        str += "到港,";
                    }
                    if (type.indexOf("3") !== -1) {
                        str += "清关,";
                    }
                    if (type.indexOf("4") !== -1) {
                        str += "派送,";
                    }
                }
                return str;
            },
            intoDocpage(orderNo) {
                // 对于路由不在左侧菜单中的选中菜单处理
                setUpRouteUtil(this.$route.path, '/docpage/Edit');
                this.$router.push("/docpage/Edit?orderNo=" + orderNo);
            },
            initCusPage({cusId}) {
                if (cusId){
                  // 对于路由不在左侧菜单中的选中菜单处理
                  setUpRouteUtil(this.$route.path, '/customer/Edit');
                  this.$router.push(`/customer/Edit?id=${cusId}`)
                }
            },
            handleReset() {
                this.queryForm = {};
                this.filterForm = {};
                this.ipagination.currentPage = 1;
                //this.queryForm.isWhether='0'
                this.queryForm.isWhetherProblem='0'
                if (this.$refs['GTable']){
                    this.$refs['GTable'].handleReset();
                }
                this.handleQuery();
            },
        },
        created() {
            //this.queryForm.isWhether='0'
            this.queryForm.isWhetherProblem='0'
        },
    };
</script>

<style lang="scss" scoped>
</style>
