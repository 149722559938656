<template>
    <div class="main-full-content">
        <el-dialog
            title="选择开票信息"
            :visible="invoiceInfoVisible"
            width="680px"
            custom-class="c-custom-dialog"
            :close-on-click-modal="false"
            :destroy-on-close="true"
            :before-close="invoiceInfoHandleClose"
        >
            <el-form>
                <el-form-item label="开票信息">
                    <el-select v-model="invoiceInfoOrderNo" style="width: 70%">
                        <el-option
                            v-for="(item, index) in invoiceInfoList"
                            :label="item.accountName"
                            :value="item.id"
                            :key="index"
                        >
                            {{item.bankName + '-' + item.accountName + '-' + item.account + '-' + item.cur }}
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <span slot="footer">
        <el-button
            type="primary"
            size="medium"
            @click="invoiceInfoHandleSubmit"
            :disabled="ifDis">
            确定申请开票
        </el-button>
      </span>
        </el-dialog>

        <!--        <el-form class="multiline_select" ref="form" :model="queryForm" label-width="80px" size="medium">-->
        <!--            <el-card class="box-card">-->
        <!--                <el-row class="inputs_row">-->
        <!--                    <el-col :xs="24" :sm="12" :md="12" :lg="6">-->
        <!--                        <el-form-item label="工作单号">-->
        <!--                            <el-input v-model="queryForm.orderNo" @keyup.enter.native="handleCheck"></el-input>-->
        <!--                        </el-form-item>-->
        <!--                    </el-col>-->
        <!--                    <el-col :xs="24" :sm="12" :md="12" :lg="6">-->
        <!--                      <el-form-item label="发票编号">-->
        <!--                        <el-input v-model="queryForm.invoiceNo" @keyup.enter.native="handleCheck"></el-input>-->
        <!--                      </el-form-item>-->
        <!--                    </el-col>-->
        <!--                    <el-col :xs="24" :sm="12" :md="12" :lg="6">-->
        <!--                        <el-form-item label="客户">-->
        <!--                            <el-input v-model="queryForm.username" @keyup.enter.native="handleCheck"></el-input>-->
        <!--                        </el-form-item>-->
        <!--                    </el-col>-->
        <!--                    <el-col :xs="24" :sm="12" :md="12" :lg="6">-->
        <!--                        <el-form-item label="业务员">-->
        <!--                            <f-select-->
        <!--                                    clearable-->
        <!--                                    filterable-->
        <!--                                    class="f-full-width"-->
        <!--                                    :dict="'salesmanId'"-->
        <!--                                    v-model="queryForm.salesmanId"-->
        <!--                                    :fatherVal="queryForm.salesmanId"-->
        <!--                                    placeholder="请选择"-->
        <!--                                    style="width: 100%"-->
        <!--                            >-->
        <!--                            </f-select>-->
        <!--                        </el-form-item>-->
        <!--                    </el-col>-->
        <!--                    <el-col :xs="24" :sm="12" :md="12" :lg="6">-->
        <!--                        <el-form-item label="操作员" label-width="80px">-->
        <!--                            <f-select-->
        <!--                                :dict="'operationsId'"-->
        <!--                                v-model="queryForm.operateId"-->
        <!--                                :father-val="queryForm.operateId"-->
        <!--                                :is-need="queryForm.operateId"-->
        <!--                                placeholder="请选择"-->
        <!--                            ></f-select>-->
        <!--                        </el-form-item>-->
        <!--                    </el-col>-->

        <!--                    <el-col :xs="24" :sm="12" :md="12" :lg="6">-->
        <!--                        <el-form-item label="费用币种">-->
        <!--                            <f-select-->
        <!--                                    v-model="queryForm.moneyType"-->
        <!--                                    :fatherVal="queryForm.moneyType"-->
        <!--                                    dict="bd_cur"-->
        <!--                            ></f-select>-->
        <!--                        </el-form-item>-->
        <!--                    </el-col>-->
        <!--&lt;!&ndash;                    <el-col :xs="24" :sm="12" :md="12" :lg="6">&ndash;&gt;-->
        <!--&lt;!&ndash;                        <el-form-item label="收款员">&ndash;&gt;-->
        <!--&lt;!&ndash;                            <f-select&ndash;&gt;-->
        <!--&lt;!&ndash;                                    placeholder="请选择收款人"&ndash;&gt;-->
        <!--&lt;!&ndash;                                    v-model="queryForm.billman"&ndash;&gt;-->
        <!--&lt;!&ndash;                                    :fatherVal="queryForm.billman"&ndash;&gt;-->
        <!--&lt;!&ndash;                                    dict="operations"&ndash;&gt;-->
        <!--&lt;!&ndash;                            >&ndash;&gt;-->
        <!--&lt;!&ndash;                            </f-select>&ndash;&gt;-->
        <!--&lt;!&ndash;                        </el-form-item>&ndash;&gt;-->
        <!--&lt;!&ndash;                    </el-col>&ndash;&gt;-->
        <!--                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="8">-->
        <!--                        <el-form-item label="费用时间">-->
        <!--                            <el-date-picker-->
        <!--                                    class="f-flex-1"-->
        <!--                                    v-model="stime"-->
        <!--                                    type="datetimerange"-->
        <!--                                    size="small"-->
        <!--                                    range-separator="至"-->
        <!--                                    start-placeholder="开始日期"-->
        <!--                                    end-placeholder="结束日期"-->
        <!--                                    :picker-options="editStartOptions"-->
        <!--                                    :default-time="['00:00:00', '23:59:59']"-->
        <!--                                    value-format="yyyy-MM-dd HH:mm:ss"-->
        <!--                                    format="yyyy-MM-dd HH:mm:ss"-->
        <!--                                    unlink-panels-->
        <!--                            >-->
        <!--                            </el-date-picker>-->
        <!--                        </el-form-item>-->
        <!--                    </el-col>-->
        <!--                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="8">-->
        <!--                        <el-form-item label="下单时间">-->
        <!--                            <el-date-picker-->
        <!--                                    class="f-flex-1"-->
        <!--                                    v-model="otime"-->
        <!--                                    type="datetimerange"-->
        <!--                                    size="small"-->
        <!--                                    range-separator="至"-->
        <!--                                    start-placeholder="开始日期"-->
        <!--                                    end-placeholder="结束日期"-->
        <!--                                    :picker-options="editStopOptions"-->
        <!--                                    :default-time="['00:00:00', '23:59:59']"-->
        <!--                                    value-format="yyyy-MM-dd HH:mm:ss"-->
        <!--                                    format="yyyy-MM-dd HH:mm:ss"-->
        <!--                                    unlink-panels-->
        <!--                            >-->
        <!--                            </el-date-picker>-->
        <!--                        </el-form-item>-->
        <!--                    </el-col>-->
        <!--                    <el-col :xs="24" :sm="12" :md="12" :lg="6">-->
        <!--                        <el-form-item label="订单类型">-->
        <!--                            <f-select-->
        <!--                                v-model="queryForm.orderType"-->
        <!--                                :isNeed="queryForm.orderType"-->
        <!--                                dict="all_order_type"></f-select>-->
        <!--                        </el-form-item>-->
        <!--                    </el-col>-->
        <!--                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="6">-->
        <!--                        <el-form-item label-width="30px" style="width: 400px">-->
        <!--                            <el-button-->
        <!--                                    size="medium"-->
        <!--                                    type="primary"-->
        <!--                                    icon="el-icon-search"-->
        <!--                                    @click="handleCheck"-->
        <!--                            >查询-->
        <!--                            </el-button-->
        <!--                            >-->
        <!--                            <el-button size="medium" plain @click="handleReset">重置</el-button>-->
        <!--                        </el-form-item>-->
        <!--                    </el-col>-->
        <!--                </el-row>-->
        <!--            </el-card>-->
        <!--        </el-form>-->
        <el-card class="box-card">
            <div class="flex_wait_box" >
                <span>未开账单功能区：</span>
                <el-button size="medium" type="primary" @click="noConvertBill"
                           v-if="buttonShowList.hkfzhzd"
                >海外仓非折合账单</el-button>
                <el-button size="medium" type="primary" @click="convertBill"
                           v-if="buttonShowList.hkzhzd"
                >海外仓运折合账单</el-button>
                <!--                <el-select class="select_width" v-model="kdBillType" placeholder="请选择快递账单类型">-->
                <!--                    <el-option value="0" label="导出中文账单-EXCEL"></el-option>-->
                <!--                    <el-option value="1" label="导出中文账单-PDF"></el-option>-->
                <!--                    <el-option value="2" label="导出英文账单"></el-option>-->
                <!--                </el-select>-->
                <!--                <el-button size="medium" type="primary" @click="kdBill"-->
                <!--                           v-if="$btnIsShow('hyFinanceManage/modules/receive/waitBill','0','快递账单')"-->
                <!--                >快递账单</el-button>-->
                <el-button size="medium" type="primary" @click="delSettle"
                           v-if="buttonShowList.plsc"
                >批量删除</el-button>
                <el-divider direction="vertical"></el-divider>
                <f-select
                    class="margin_5"
                    style="width: 100px"
                    :dict="'bd_cur'"
                    v-model="exchangeCur"
                    :isNeed="exchangeCur"
                    @change="curChangeBank"
                    placeholder="请选择折合币种"
                >
                </f-select>
                <el-select v-model="bankId"
                           placeholder="根据币种选择银行"
                           clearable
                           filterable
                           style="width: 250px;margin-left: -9px">
                    <el-option v-for="item in banks" :key="item.id" :value="item.id" :label="item.bankName">
                        {{item.bankName + '-' + item.accountName + '-' + item.account + '-' + item.cur }}
                    </el-option>
                </el-select>
                <el-button type="primary" style="margin-left: 10px" @click="setSettlesExchangCur">
                    设置折合
                </el-button>
            </div>
            <div class="f-m-t-5 flex_wait_box" >
                <span>已开账单功能区：</span>
                <el-button size="medium" type="primary" @click="batchOpenInvoice"
                           v-if="buttonShowList.plkpsq"
                >批量开票申请</el-button>
                <el-button size="medium" type="primary" @click="receiveApply"
                           v-if="buttonShowList.kpsq"
                >收款申请</el-button>
                <el-button size="medium" type="primary" @click="collectionLetter"
                           v-if="buttonShowList.ckh"
                >催款函</el-button>
                <el-divider direction="vertical"></el-divider>

                <el-button size="medium" type="primary" @click="downloadBillAgain()"
                           v-if="buttonShowList.xzhkzd"
                >下载海外仓已开的账单
                </el-button>
                <el-button size="medium" type="primary" @click="sendBackBatch()"
                           v-if="buttonShowList.plth"
                >批量退回
                </el-button>
                <!--                <el-select class="select_width" v-model="kdBillType" placeholder="请选择快递账单类型" v-if="$btnIsShow('hyFinanceManage/modules/receive/billed','0','下载快递账单')">-->
                <!--                    <el-option value="0" label="导出中文账单-EXCEL"></el-option>-->
                <!--                    <el-option value="1" label="导出中文账单-PDF"></el-option>-->
                <!--                    <el-option value="2" label="导出英文账单"></el-option>-->
                <!--                </el-select>-->
                <!--                <el-button size="medium" type="primary" @click="kdBillAgain"-->
                <!--                           v-if="$btnIsShow('hyFinanceManage/modules/receive/billed','0','下载快递账单')"-->
                <!--                >下载快递账单</el-button>-->
            </div>
            <div class="payApplyRow" v-if="sumFee.length > 0">
                <span>折合金额合计：</span>
                <div class="payApplyRow payApplyRowItem" v-for="(item,index) in sumFee" :key="index" style="color: red">
                    <span>{{ item.cur + ' ' + item.total }}</span>
                </div>
            </div>
            <f-table
                v-loading="loading"
                ref="GTable"
                border
                size="medium"
                row-key="id"
                class="c-th-has-bgcolor"
                :columns="columns"
                :data="tableData"
                :show-operation="true"
                :cell-style="{ 'text-align': 'center' }"
                @selection-change="handleSelectionChange"
                @sort-change="handleTableChange"
                tableHeight="61vh"
                :isRecordTableScroll="true"
                :reserveSelection="true"
            >
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'action'">
                        <el-button type="text" @click="yiBillApply(scope.row)"
                                   v-if="scope.row.invoiceStatus === '0' && buttonShowList.kpsq"
                        >开票申请
                        </el-button
                        >
                        <el-button type="text" @click="downloadApply(scope.row)"
                                   v-if="scope.row.invoiceStatus === '1' && buttonShowList.xzfp" style="color: green"
                        >下载发票
                        </el-button
                        >
                        <el-divider direction="vertical" v-if="scope.row.status==='0' && scope.row.billTime"></el-divider>
<!--                        <el-popconfirm-->
<!--                            title="是否确认进行取消操作？"-->
<!--                            confirm-button-text="确认"-->
<!--                            cancel-button-text="不用了"-->
<!--                            icon="el-icon-info"-->
<!--                            icon-color="red"-->
<!--                            @confirm="sendBack(scope.row)"-->
<!--                            v-if="scope.row.status==='0'"-->
<!--                        >-->
                            <el-button slot="reference" type="text" @click="sendBack(scope.row)"
                                       v-if="buttonShowList.th && scope.row.status==='0' && scope.row.billTime"
                            >退回</el-button>
<!--                        </el-popconfirm>-->

                    </template>
                    <template v-else-if="scope.column.property === 'orderNo'">
                        <a
                            style="color: #00a0e9"
                            @click.prevent="$intoDocPage(scope.row.orderNo)"
                        >{{ scope.row.orderNo }}</a
                        >
                    </template>
                    <template v-else-if="scope.column.property === 'realAgent'">
                        <a type="text" @click.prevent="$intoCustomerEditPage(scope.row.cusId)" style="color:#57a3f3;">{{scope.row.realAgent}}</a>
                    </template>
                    <template v-else-if="scope.column.property === 'status'">
                        {{parseDict(scope.row.status,'settle_status')}}
                    </template>

                    <template v-else-if="scope.column.property === 'waitPay'">
                            <span
                                v-if="scope.row.partialWriteOff === null || scope.row.partialWriteOff === '0'">{{scope.row.exchangeTotal}}</span>
                        <span v-if="scope.row.partialWriteOff === '1'">{{(scope.row.waitPay)}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'billStatus'">
                        <span v-if="scope.row.billTime==null || scope.row.billTime==undefined" style="color: red;font-weight: bold">未开账单</span>
                        <span v-if="scope.row.billTime" style="color: green;font-weight: bold">已开账单</span>
                    </template>
                    <!--                <template v-else-if="scope.column.property === 'solicitation'">-->
                    <!--                    <span>{{parseDict(scope.row.solicitation,'fba_solicitation')}}</span>-->
                    <!--                </template>-->
                    <!--                <template v-else-if="scope.column.property === 'transportStatus'">-->
                    <!--                    <span>{{parseDict(scope.row.transportStatus,'transportStatus')}}</span>-->

                    <!--                </template>-->
                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                    v-bind="ipagination"
                    layout="total, sizes, prev, pager, next, jumper"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                >
                </el-pagination>
            </el-row>
        </el-card>

        <receive-apply ref="apply" @ok="()=>{this.$emit('ok')}"></receive-apply>
        <email-dialog :visible.sync="dialogFormVisibleEmail" :row="form" @ok="dialogFormVisible = false" :emails="emails"></email-dialog>
        <convert-bill ref="convert" @ok="()=>{this.$emit('ok')}"></convert-bill>
        <no-convert-bill ref="noConvert" @ok="()=>{this.$emit('ok')}"></no-convert-bill>
    </div>
</template>
<script>
import QueryMixins from "../../../../mixins/QueryMixins";
import {deleteAction, getAction, postAction} from "@/api/manage";
import receiveApply from "./receiveApply";
import EmailDialog from "./EmailDialog";
import { setUpRouteUtil } from '@/utils/util';
import convertBill from "./convertBill"
import noConvertBill from "./noConvertBill"

export default {
    mixins: [QueryMixins],
    components: {
        receiveApply,EmailDialog,convertBill,noConvertBill
    },
    data() {
        return {
            disableMixinMounted: true,
            dictCodes: ['settle_status','cus_pay_type'],
            invoiceInfoList: [],
            editStartOptions: {
                disabledDate: (time) => {
                    if (!this.form.etime) {
                        // time = new Date(time.replace(/-/g, '/'));
                        return time.getTime() < new Date(1970 - 1 - 1).getTime(); //禁止选择1970年以前的日期
                    } else {
                        return time.getTime() > new Date(this.form.etime);
                    }
                },
            },
            editStopOptions: {
                disabledDate: (time) => {
                    // time = new Date(time.replace(/-/g, '/'));
                    return (
                        time.getTime() < new Date(this.form.stime) ||
                        time.getTime() < new Date(1970 - 1 - 1).getTime() //禁止选择1970年以前的日期
                    );
                },
            },
            selectedRowKeys: [],
            selectionRows: [],
            selectedNos: [],
            loading: false,
            form: {},
            dialogFormVisibleEmail:false,
            dialogFormVisible:false,
            emails:[],
            kdBillType:'',
            columns: [
                {
                    type: "selection",
                    width: 50,
                    fixed: 'left'
                },
                {
                    prop: "action",
                    label: "操作",
                    width: 120,
                    fixed: 'left'
                },
                {
                    prop: "orderNo",
                    label: "工作单号",
                    width: 140,
                    fixed: 'left'
                },
                {
                    prop: "billStatus",
                    label: "账单状态",
                    fixed: 'left'
                },
                {
                    prop: 'shipmentBoard',
                    label: '开船时间',
                    width: 140,
                },
                {
                    prop: 'runOrderNo',
                    label: '运单号',
                    width: 140,
                },
                {
                    prop: "realAgent",
                    label: "客户",
                    width: 140,
                    fixed: 'left'
                },
                {
                    prop: "payType",
                    label: "付款方式",
                    width: 110,
                    fixed: 'left'
                },
                {
                    prop: "logistics",
                    label: "最新物流",
                    width: 140,
                    fixed: 'left'
                },
                {
                    prop: "invoiceNo",
                    label: "发票编号",
                    width: 110,
                    fixed: 'left'
                },
                {
                    prop: "agent",
                    label: "结算对象",
                    width: 140,
                },
                {
                    prop: "feename",
                    label: "费用中文",
                },
                {
                    prop: "feenameUs",
                    label: "费用英文",
                },
                {
                    prop: "status",
                    label: "费用状态",
                },
                {
                    prop: "price",
                    label: "价格",
                },
                {
                    prop: "quantity",
                    label: "数量",
                },
                {
                    prop: "moneyType",
                    label: "费用币种",
                },
                {
                    prop: "total",
                    label: "合计",
                },
                {
                    prop: "realMoneyType",
                    label: "折合币种",
                },
                {
                    prop: "exchangeRate",
                    label: "汇率",
                },
                {
                    prop: "exchangeTotal",
                    label: "折合金额",
                },
                {
                    prop: "payed",
                    label: "已收金额",
                },
                {
                    prop: "waitPay",
                    label: "待收金额",
                    headerType:'remark',
                    detail:'提示：待收余额将在销账之后更新',
                    width:100
                },
                {
                    prop: "createBy",
                    label: "录入人",
                },
                {
                    prop: "screateTime",
                    label: "录入时间",
                },
                {
                    prop: "createTime",
                    label: "下单时间",
                },
            ],
            isUsePost:true,
            url: {
                list: "/file/hyFinance/billedListPost",
                invoice: "/shipping/hySettleReceive/getInvoiceInfo",
                sendBack: "/shipping/hySettleReceive/billSendBack",
                sendBackBatch: "/shipping/hySettleReceive/billSendBackBatch",
                billApply:"/shipping/hySettleReceive/takeBillApply",
                downloadApply:"/shipping/hySettleReceive/downloadApply",
                batchInvoiceApply:"/shipping/hySettleReceive/batchInvoiceApply",
                getEmailsByCusId:"/shipping/hySettleReceive/getEmailsByCusId",
                openKdOrderReceiveBill:'/file/jasper/openKdOrderReceiveBill',
                openKdOrderReceiveBillExcel:'/file/jasper/openKdOrderReceiveBillExcel',
                checkBill: '/shipping/hySettleReceive/checkReceiveSettleId',
                openBill: '/file/jasper/openReceiveBill',
                delSettleBatch: '/shipping/hySettleReceive/delSettleBatch',
                curGetBank:'/shipping/hySettleReceive/curGetBank',
                setExchange: '/shipping/hySettleReceive/setExchange',
            },

            rules: {
                realCtn: [
                    {required: true, message: "请输入实际总件数", trigger: "blur"},
                    {pattern: /^[1-9]\d*$/, message: "只能输入整数", trigger: "blur"},
                ],
                realKg: [
                    {required: true, message: "请输入实际总重量", trigger: "blur"},
                    {
                        pattern:
                            /^(?=([1-9]{1,10}$|[0-9]{1,7}\.))(0|[1-9][0-9]*)(\.[0-9]{1,3})?$/,
                        message: "只能输入最多三位小数的数字",
                        trigger: "change",
                    },
                ],
            },
            stime: [],
            otime: [],
            ifDis: false,
            invoiceInfoVisible: false,
            invoiceInfoOrderNo: "",
            sumFee: [],
            exchangeCur:'',//折合币种
            banks:'',//币种对应的银行
            bankId:'',//选中的银行id
        };
    },
    computed: {
      buttonShowList() {
        return {
          'hkfzhzd':this.$btnIsShow('hyFinanceManage/modules/receive/waitBill','0','海运空运非折合账单'),
          'hkzhzd':this.$btnIsShow('hyFinanceManage/modules/receive/waitBill','0','海运空运折合账单'),
          'plsc':this.$btnIsShow('hyFinanceManage/modules/receive/waitBill','0','批量删除'),
          'plkpsq':this.$btnIsShow('hyFinanceManage/modules/receive/billed','0','批量开票申请'),
          'sksq':this.$btnIsShow('hyFinanceManage/modules/receive/billed','0','收款申请'),
          'ckh':this.$btnIsShow('hyFinanceManage/modules/receive/billed','0','催款函'),
          'xzhkzd':this.$btnIsShow('hyFinanceManage/modules/receive/billed','0','下载海空运账单'),
          'plth':this.$btnIsShow('hyFinanceManage/modules/receive/billed','0','批量退回'),
          'kpsq':this.$btnIsShow('hyFinanceManage/modules/receive/billed','1','开票申请'),
          'xzfp':this.$btnIsShow('hyFinanceManage/modules/receive/billed','1','下载发票'),
          'th':this.$btnIsShow('hyFinanceManage/modules/receive/billed','1','退回'),
        }
      }
    },
    created() {
    },
    methods: {
        setSettlesExchangCur(){
            if (this.selectionRows.length === 0){
                this.$message.warning("请勾选费用")
                return
            }
            let count = [];
            let billedCount = [];

            this.selectionRows.forEach(item => {
                // console.log('item.realMoneyType',item.realMoneyType)
                count.push(item.settleId)
                if(item.realMoneyType!==null && item.realMoneyType!==undefined && item.realMoneyType!==''){
                    billedCount.push(item.settleId)
                }
            });
            let arr = Array.from(new Set(count));
            let billedArr = Array.from(new Set(billedCount));
            if(billedArr.length > 0){
                this.$message.warning("只能对未开账单的费用进行设置折合！")
                return
            }

            if (this.exchangeCur==='' || this.exchangeCur===undefined || this.exchangeCur===null){
                this.$message.warning("请选择币种")
                return
            }
            if (this.bankId==='' || this.bankId===undefined || this.bankId===null){
                this.$message.warning("请选择银行")
                return
            }
            let billTypeCount = [];
            this.selectionRows.forEach(item => {
                if('1'===item.locker){
                    billTypeCount.push(item.id)
                }

            });
            // console.log('billTypeArr',Array.from(new Set(billTypeCount)))
            let billTypeArr = Array.from(new Set(billTypeCount));
            if(billTypeArr.length > 0){
                this.$message.warning("已锁定的费用不能修改！")
                return
            }
            // this.selectionRows.forEach(settle=>{
            //     settle.realMoneyType = this.exchangeCur;
            //     settle.bankId = this.bankId;
            //     console.log('exchangeCur',this.exchangeCur)
            //     console.log('bankId',this.bankId)
            // })
            let obj = {
                // settleList:this.selectionRows,
                settleIdsList:this.selectedRowKeys,
                bankId:this.bankId,
                cur:this.exchangeCur
            }

            postAction(this.url.setExchange,obj).then((res)=>{
                if(res.success){
                    this.$message.success(res.message)
                    this.$emit('ok')
                }else{
                    this.$message.error(res.message)
                }
            })
        },
        curChangeBank(val){
            // console.log(val)
            this.bankId = ''
            this.banks = []
            this.selectionRows.forEach(settle=>{
                if(settle.locker==0){
                    settle.exchangeRate = '';
                }
            })
            getAction(this.url.curGetBank,{cur:val}).then((res)=>{
                if (res.result.length === 0){
                    this.$message.warning("该币种下无相应账号！")
                    this.bankId = ''
                    this.banks = []
                }else {
                    this.banks = res.result
                    this.bankId = ''
                }

            })
        },
        flashFather(){
            this.$emit('ok')
        },
        //开票申请
        yiBillApply(obj){
            // console.log(obj);
            this.$confirm('确定前往易票云进行开票？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'info',
                closeOnClickModal: false,
            }).then(() => {

                postAction(this.url.billApply,obj).then((res) => {
                    // console.log(res);
                    //this.invoiceInfoList = res.result;   this.tableData
                    if (res.code === 200) {
                        this.$message.success("开票成功！");
                        // this.handleQuery();
                        this.$emit('ok')
                    }
                })
            })
        },
        //下载发票
        downloadApply(obj){
            // console.log(obj);
            var newWindow = window.open();//新开一个窗口，避免被放在ajax中被拦截
            postAction(this.url.downloadApply, obj).then((res) => {
                // console.log(res);
                //this.invoiceInfoList = res.result;
                let applypdfurl=res.result;
                // window.open(applypdfurl,"_blank");
                newWindow.location.href =applypdfurl;
            });
        },

        downloadBillAgain(){
            // console.log("申请收款以下id的费用",this.selectedRowKeys)
            if (this.selectedRowKeys.length === 0){
                this.$message.warning("请勾选费用")
                return
            }
            if(this.judgeNoBill(this.selectionRows)){
                this.$message.warning("未开账单的费用请使用【未开账单】的功能！")
                return
            }
            let preArr = this.getOrderPre()
            if(preArr.length > 1){
                this.$message.warning("请选择同一账单类型的费用进行开账单！")
                return
            }
            if(preArr[0]==='KD'){
                this.$message.warning("快递单费用请选择快递账单")
                return
            }
            let countCur = [];
            this.selectionRows.forEach(item => {
                countCur.push(item.realMoneyType)
            });
            let curArr = Array.from(new Set(countCur));
            // console.log('curArr->',curArr)
            if(curArr.length > 1){
                this.$message.warning("所选费用的折合币种币种不一致！")
                return
            }
            let count = [];
            this.selectionRows.forEach(item => {
                count.push(item.settleId)
            });
            // console.log(Array.from(new Set(count)))
            let arr = Array.from(new Set(count));
            if(arr.length > 1){
                this.$message.warning("所选费用的结算对象不一致！")
                return
            }
            this.$refs.convert.openAgain(this.selectedRowKeys,this.selectedNos,this.selectionRows[0].realAgent,this.selectionRows[0].realMoneyType,'3')

        },
        initCusPage({cusId}) {
            if (cusId){
                // 对于路由不在左侧菜单中的选中菜单处理
                setUpRouteUtil(this.$route.path, '/customer/Edit');
                this.$router.push(`/customer/Edit?id=${cusId}`)
            }

        },
        //进入客服页
        intoDocpage(orderNo) {
            if (orderNo.indexOf("KD")!==-1){
                // 对于路由不在左侧菜单中的选中菜单处理
                setUpRouteUtil(this.$route.path, '/kdorder/Edit');
                this.$router.push("/kdorder/Edit?orderNo=" + orderNo);
            }else {
                // 对于路由不在左侧菜单中的选中菜单处理
                setUpRouteUtil(this.$route.path, '/docpage/Edit');
                this.$router.push("/docpage/Edit?orderNo=" + orderNo);
            }

        },
        sendBack(row) {
            this.$confirm(`是否确认进行取消操作？`, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "success",
                closeOnClickModal: false,
            }).then(()=> {
                let obj = {};
                obj.id = row.id;
                obj.orderNo = row.orderNo;
                postAction(this.url.sendBack, obj).then((res) => {
                    // console.log(res);
                    if (res.code === 200) {
                        this.$message.success("退回成功！");
                        // this.handleCheck();
                        this.$emit('ok')
                    }
                });
            })
        },
        sendBackBatch(){
            if (this.selectedRowKeys.length === 0){
                this.$message.warning("请勾选费用")
                return
            }
            if(this.judgeNoBill(this.selectionRows)){
                this.$message.warning("未开账单的费用请使用【未开账单】的功能！")
                return
            }
            if(this.selectionRows.length > 0){
                let partOff = 0
                for (var i=0;i<this.selectionRows.length;i++){
                    // console.log('老实点查询字典列表',list[i].dictCode);
                    if(this.selectionRows[i].partialWriteOff==='1' || this.selectionRows[i].status!=='0'){
                        partOff++
                    }
                }
                if(partOff>0){
                    this.$message.warning("已部分销账的费用不能退回！")
                    return
                }
            }

            postAction(this.url.sendBackBatch,this.selectedRowKeys).then((res)=>{
                if (res.code === 200) {
                    this.$message.success("批量退回成功！");
                    // this.handleCheck();
                    this.$emit('ok')
                }
            })
        },
        invoiceInfoHandleSubmit() {
        },
        invoiceApply(orderNo) {
            // console.log(orderNo);
            getAction(this.url.invoice, {orderNo: orderNo}).then((res) => {
                // console.log(res);
                this.invoiceInfoList = res.result;
            });

            this.invoiceInfoVisible = true;
        },
        receiveApply() {
            if (this.selectionRows.length === 0) {
                this.$message.warning("请勾选费用再进行申请收款");
                return;
            }
            if(this.judgeNoBill(this.selectionRows)){
                this.$message.warning("未开账单的费用请使用【未开账单】的功能！")
                return
            }
            //判断费用状态只有未申请和部分销账的可以申请收款
            let statusCount = [];
            this.selectionRows.forEach((item) => {
                statusCount.push(item.status);
            });
            let statusArr = Array.from(new Set(statusCount));
            if (statusArr.indexOf("1") !== -1 || statusArr.indexOf("9") !== -1) {
                this.$message.warning("已销账和已申请收款的费用不能重复申请！");
                return;
            }

            let count = [];
            this.selectionRows.forEach((item) => {
                count.push(item.realAgent);
            });
            let cusArr = Array.from(new Set(count));
            if (cusArr.length > 1) {
                this.$message.warning("不同客户的费用请分开进行收款！");
                return;
            }

            let orderTypeCount = [];
            this.selectionRows.forEach((item) => {
                orderTypeCount.push(item.orderType);
            });
            let orderTypArr = Array.from(new Set(orderTypeCount));
            if (orderTypArr.length > 1) {
                this.$message.warning("海运费用、快递费用请分开进行申请收款！");
                return;
            }

            let countCur = [];
            this.selectionRows.forEach((item) => {
                countCur.push(item.realMoneyType);
            });
            let curArr = Array.from(new Set(countCur));
            // console.log("curArr->", curArr);
            if (curArr.length > 1) {
                this.$message.warning("所选费用的折合币种不一致！");
                return;
            }

            // let bankCount = [];
            // this.selectionRows.forEach((item) => {
            //     if(item.bankId ===null || item.bankId === '' || item.bankId === undefined){
            //         item.bankId = ''
            //     }
            //     bankCount.push(item.bankId);
            // });
            // let bankArr = Array.from(new Set(bankCount));
            // if (bankArr.length > 1) {
            //     this.$message.warning("所选费用的收款银行不一致！");
            //     return;
            // }

            // console.log("要传送过去的rows", this.selectionRows);

            let arr = this.tableData.filter((item) => {
                return this.selectionRows.indexOf(item) === -1;
            });
            // console.log("arr--", arr);
            this.$refs.apply.edit(this.selectionRows, arr, this.columns);
        },
        collectionLetter(){
            if (this.selectionRows.length === 0) {
                this.$message.warning("请勾选费用再进行发送催款函");
                return;
            }
            if(this.judgeNoBill(this.selectionRows)){
                this.$message.warning("未开账单的费用请使用【未开账单】的功能！")
                return
            }
            this.$message.warning("此功能后续开放！");
        },
        handleSelectionChange(selection) {
            // 多选
            // console.log(selection);
            this.sumFee = [];
            let allCur = []
            selection.forEach(row => {
                if(row.realMoneyType!==null && row.realMoneyType!==undefined && row.realMoneyType!==''){
                    allCur.push(row.realMoneyType)
                }

            })
            let curNum = Array.from(new Set(allCur))
            //遍历每个币种，计算总和后放入sumFee中
            curNum.forEach(cur => {
                let curRow = selection.filter(row => {
                    return row.realMoneyType === cur
                })
                let total = Number(0);
                curRow.forEach(i => {
                    total = total + Number(i.exchangeTotal)
                })
                this.sumFee.push({cur: cur, total: total.toFixed(3)})
            })

            let arr = [];
            let nos = [];
            this.nameList = [];
            this.cnamelist = [];
            this.emailList = [];
            for (let i = 0; i < selection.length; i++) {
                arr.push(selection[i]["id"]);
                nos.push(selection[i]["orderNo"]);
                this.nameList.push(selection[i]["username"]);
                this.cnamelist.push(selection[i]["cname"]);
                this.emailList.push(selection[i]["email"]);
            }
            this.selectionRows = selection;
            this.selectedRowKeys = [...arr];
            this.selectedNos = [...nos];
        },
        handleReceive() {
            // console.log("申请收款以下id的费用", this.selectedRowKeys);
        },
        handleReset() {
            this.queryForm = {};
            this.otime = "";
            this.stime = "";
            this.ipagination.currentPage = 1;
            // this.handleCheck();
            this.$emit('ok')
        },
        // isClearSelection是否清空多选框，默认是清空，分页还有选择页码大小的时候不清空
        handleCheck(queryFormParent,isClearSelection=true) {
            // console.log('wwwwwooosss',queryFormParent)

            this.loading = true;
            // if (this.otime && this.otime.length > 1) {
            //     this.queryForm.createTimeS = this.otime[0];
            //     this.queryForm.createTimeE = this.otime[1];
            // }else{
            //     this.queryForm.createTimeS = '';
            //     this.queryForm.createTimeE = '';
            // }
            // if (this.stime && this.stime.length > 1) {
            //     this.queryForm.sCreateTimeS = this.stime[0];
            //     this.queryForm.sCreateTimeE = this.stime[1];
            // }else{
            //     this.queryForm.sCreateTimeS = '';
            //     this.queryForm.sCreateTimeE = '';
            // }
            let form = {...queryFormParent, ...this.filterForm};
            for (let item in form) {
                if (typeof form[item] === "string") {
                    form[item] = form[item].trim();
                    if (form[item] === "") {
                        form[item] = null;
                    }
                }
            }
            const {prop, order} = this.isorter;
            // 传入的参数
            const params = {
                ...form,
                column: prop,
                order: order.indexOf("asc") !== -1 ? "asc" : "desc",
            };
            //判断 是否要查询startTime

            if (!params.column) {
                if (this.queryForm.column === "startTime") {
                    this.$set(params, "column", "startTime");
                } else {
                    this.$set(params, "column", "createTime");
                }
                //提货中查询
                if (this.queryForm.column === "distributionTime") {
                    this.$set(params, "column", "distributionTime");
                }
            }
            if (this.ipagination) {
                params.pageNo = this.ipagination.currentPage;
                params.pageSize = this.ipagination.pageSize;
            }

            isClearSelection ? this.$refs.GTable.handleClearSelection() : '';

            postAction(this.url.list, params)
                .then((res) => {
                    if (res.success) {
                        this.$refs.GTable && this.$refs.GTable.handleScrollTop && this.$refs.GTable.handleScrollTop();
                        const {records, total} = res.result;
                        this.tableData = records || res.result;

                        this.ipagination &&
                        (this.ipagination.total = total || res.result.length);
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        batchOpenInvoice() {
            // console.log("申请收款以下id的费用", this.selectionRows);
            if (this.selectedRowKeys.length === 0){
                this.$message.warning("请勾选费用")
                return
            }

            if(this.judgeNoBill(this.selectionRows)){
                this.$message.warning("未开账单的费用请使用【未开账单】的功能！")
                return
            }
            // this.$refs.noConvert.edit(this.selectedRowKeys, this.selectedNos);

            //批量开票申请
            this.$confirm('确定前往易票云进行开票？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'info',
                closeOnClickModal: false,
            }).then(() => {

                postAction(this.url.batchInvoiceApply,this.selectionRows).then((res) => {
                    // console.log(res);
                    //this.invoiceInfoList = res.result;   this.tableData
                    if (res.code === 200) {
                        this.$message.success("开票成功！");
                        // this.handleCheck();
                        this.$emit("ok")
                    }
                })
            }).catch(res => {
                this.$message.error(res.message);
            })

        },
        noConvertBill(){

            // console.log("申请收款以下id的费用",this.selectedRowKeys)
            if (this.selectedRowKeys.length === 0){
                this.$message.warning("请勾选费用")
                return
            }
            let preArr = this.getOrderPre()
            if(preArr.length > 1){
                this.$message.warning("请选择同一账单类型的费用进行开账单！")
                return
            }
            if(preArr[0]==='KD'){
                this.$message.warning("快递单费用请选择快递账单")
                return
            }

            //非折合支持不同币种申请收款
            // let countCur = [];
            // this.selectionRows.forEach(item => {
            //     countCur.push(item.moneyType)
            // });
            // let curArr = Array.from(new Set(countCur));
            // console.log('curArr->',curArr)
            // if(curArr.length > 1){
            //     this.$message.warning("所选费用的费用币种不一致！")
            //     return
            // }

            let count = [];
            let billedCount = [];

            this.selectionRows.forEach(item => {
                count.push(item.settleId)
                if(item.realMoneyType!==null && item.realMoneyType!==undefined && item.realMoneyType!==''){
                    billedCount.push(item.settleId)
                }
            });
            let arr = Array.from(new Set(count));
            let billedArr = Array.from(new Set(billedCount));
            if(billedArr.length > 0){
                this.$message.warning("已开账单的费用请使用已开账单的功能！")
                return
            }
            if(arr.length > 1){
                this.$message.warning("所选费用的结算对象不一致！")
                return
            }
            this.$refs.noConvert.edit(this.selectedRowKeys,this.selectedNos,this.selectionRows[0].realAgent,'3')
        },
        convertBill(){

            // console.log("申请收款以下id的费用",this.selectedRowKeys)
            if (this.selectedRowKeys.length === 0){
                this.$message.warning("请勾选费用")
                return
            }
            let preArr = this.getOrderPre()
            if(preArr.length > 1){
                this.$message.warning("请选择同一账单类型的费用进行开账单！")
                return
            }
            if(preArr[0]==='KD'){
                this.$message.warning("快递单费用请选择快递账单")
                return
            }
            let count = [];
            let billedCount = [];
            this.selectionRows.forEach(item => {
                count.push(item.settleId)
                if(item.realMoneyType!==null && item.realMoneyType!==undefined && item.realMoneyType!==''){
                    billedCount.push(item.settleId)
                }
            });
            let arr = Array.from(new Set(count));
            let billedArr = Array.from(new Set(billedCount));
            // console.log('billedArr',billedArr)
            if(billedArr.length > 0){
                this.$message.warning("已开账单的费用请使用已开账单的功能！")
                return
            }
            if(arr.length > 1){
                this.$message.warning("所选费用的结算对象不一致！")
                return
            }
            this.$refs.convert.edit(this.selectedRowKeys,this.selectedNos,this.selectionRows[0].realAgent,'3')
        },
        getMail() {
            /*   console.log("申请收款以下id的费用", this.selectedRowKeys);
               if (this.selectedRowKeys.length === 0) {
                   this.$message.warning("请勾选费用");
                   return;
               }*/
            let aa = this.selectionRows.map(item => {return item.cusId}).join(",").split(',')
            // console.log("申请收款以下id的费用", aa);
            postAction(this.url.getEmailsByCusId,aa)
                .then((res) => {
                    // console.log("申请收款以下id的费用:"+res.result);
                    this.emails=res.result;
                    this.dialogFormVisibleEmail = true;
                })
                .finally(() => {
                    this.loading = false;
                });
            //给邮箱赋值
            //使用$set
            /* this.$set(this.form,'emails',this.emailList.join(','))
              this.form.emails = this.emailList.join(',');
              console.log("发送邮件",this.form);*/
        },
        kdBill(){
            if(this.kdBillType === ''){
                this.$message.warning('请选择快递账单类型之后再进行下载')
                return
            }

            // console.log("申请收款以下id的费用",this.selectedRowKeys)
            if (this.selectedRowKeys.length === 0){
                this.$message.warning("请勾选费用")
                return
            }
            let preArr = this.getOrderPre()
            if(preArr.length > 1){
                this.$message.warning("请选择同一账单类型的费用进行开账单！")
                return
            }
            if(preArr[0]!=='KD'){
                this.$message.warning("海运空运的费用请选择海运空运账单")
                return
            }
            let count = [];
            let curCount = [];
            this.selectionRows.forEach(item => {
                count.push(item.settleId)
                curCount.push(item.moneyType)
            });
            // console.log(Array.from(new Set(count)))
            let arr = Array.from(new Set(count));
            let curArr = Array.from(new Set(curCount));
            if(arr.length > 1){
                this.$message.warning("所选费用的结算对象不一致！")
                return
            }
            if(curArr.length > 1){
                this.$message.warning("所选费用的币种不一致！")
                return
            }
            // console.log("curArr",curArr[0])
            let obj = {
                ids:this.selectedRowKeys,
                orderNos:this.selectedNos,
                billTitle:'5',
                billType:'2',
                realMoneyType:curArr[0]
            }
            let nos = this.selectedNos.join('-')
            let filename = this.selectionRows[0].realAgent +'-'+ this.getDateStr()+ '快递账单' + nos

            let url = this.url.openBill
            let type = {"type": "application/pdf"}
            if(this.kdBillType==='0'){
                url = this.url.openKdOrderReceiveBillExcel
                type = {type: 'application/vnd.ms-excel'}
            }
            if(this.kdBillType==='1'){
                url = this.url.openKdOrderReceiveBill
            }
          let that = this;
            postAction(this.url.checkBill,this.selectedRowKeys).then((res) => {
                //检测是同一个结算对象 才进行下载
                if(res.success){
                    // console.log("oobj",obj)
                    postAction(url, obj, {responseType: 'blob'})
                        .then(res => {
                          if (res && res.type === 'application/json') {
                            const reader = new FileReader()
                            reader.onload = function () {
                              const message = JSON.parse(reader.result).message
                              that.$message.error(message);// 将错误信息显示出来
                            }
                            reader.readAsText(res)
                            return false;
                          }else {
                            // console.log(res)
                            if(res.size ===0){
                              this.$message.error('导出失败，请稍后再试或联系管理员')
                              return
                            }
                            var binaryData = [];
                            binaryData.push(res);
                            if(this.kdBillType==='0'){
                              let url = window.URL.createObjectURL(new Blob(binaryData, {type: 'application/vnd.ms-excel'}));
                              let link = document.createElement('a');
                              link.style.display = 'none';
                              link.href = url;
                              link.setAttribute('download', filename + '.xls');
                              document.body.appendChild(link);
                              link.click();
                              document.body.removeChild(link);//下载完成移除元素
                              window.URL.revokeObjectURL(url) //释放掉blob对象
                            }else{
                              let urlencoded = window.URL.createObjectURL(new Blob(binaryData,type));
                              window.open("/static/pdf/web/viewer.html?filename=" + filename + "&file=" + encodeURIComponent(urlencoded));
                            }


                            // let file = new Blob(binaryData)
                            // file.lastModifiedDate = new Date();
                            // file.name = "mailAtt.pdf"
                            // let formData = new FormData();
                            // formData.append("file", file);
                            // formData.append("orderNo", this.selectedNos[0])
                            // //发送账单邮件给客户
                            // postAction(this.url.sendBillMail,formData).then((res)=>{
                            //     console.log(res)
                            // })
                            this.visible=false
                            let that = this
                            setTimeout(()=>{   //设置延迟执行
                              that.$message.success("开账单成功！")
                              that.$emit('ok')
                              // this.handleCheck();
                            },3000)
                          }

                        })

                }
            })
        },
        invoiceInfoHandleClose() {
            // this.$emit('update:visible', false)
            // this.$refs.form.resetFields()
            this.invoiceInfoVisible = false;
        },
        kdBillAgain(){
            if(this.kdBillType === ''){
                this.$message.warning('请选择快递账单类型之后再进行下载')
                return
            }

            // console.log("申请收款以下id的费用",this.selectedRowKeys)
            if (this.selectedRowKeys.length === 0){
                this.$message.warning("请勾选费用")
                return
            }
            let preArr = this.getOrderPre()
            if(preArr.length > 1){
                this.$message.warning("请选择同一账单类型的费用进行开账单！")
                return
            }
            if(preArr[0]!=='KD'){
                this.$message.warning("海运空运的费用请选择海运空运账单")
                return
            }
            let count = [];
            let curCount = [];
            this.selectionRows.forEach(item => {
                count.push(item.settleId)
                curCount.push(item.moneyType)
            });
            // console.log(Array.from(new Set(count)))
            let arr = Array.from(new Set(count));
            let curArr = Array.from(new Set(curCount));
            if(arr.length > 1){
                this.$message.warning("所选费用的结算对象不一致！")
                return
            }
            if(curArr.length > 1){
                this.$message.warning("所选费用的币种不一致！")
                return
            }
            let obj = {
                ids:this.selectedRowKeys,
                orderNos:this.selectedNos,
                billTitle:'5',
                billType:'4',//2正常快递账单  4再次开快递账单
                realMoneyType:curArr[0]
            }

            let nos = Array.from(new Set(this.selectedNos)).join('-')
            let filename = this.selectionRows[0].realAgent +'-'+ this.getDateStr()+ '快递账单' + nos

            let url = this.url.openBill
            let type = {"type": "application/pdf"}
            if(this.kdBillType==='0'){
                url = this.url.openKdOrderReceiveBillExcel
                type = {type: 'application/vnd.ms-excel'}
            }
            if(this.kdBillType==='1'){
                url = this.url.openKdOrderReceiveBill
            }

            postAction(this.url.checkBill,this.selectedRowKeys).then((res) => {
                //检测是同一个结算对象 才进行下载
                if(res.success){
                    postAction(url, obj, {responseType: 'blob'})
                        .then(res => {
                            // console.log(res)
                            if(res.size ===0){
                                this.$message.error('导出失败，请稍后再试或联系管理员')
                                return
                            }
                            var binaryData = [];
                            binaryData.push(res);
                            if(this.kdBillType==='0'){
                                let url = window.URL.createObjectURL(new Blob(binaryData, {type: 'application/vnd.ms-excel'}));
                                let link = document.createElement('a');
                                link.style.display = 'none';
                                link.href = url;
                                link.setAttribute('download', filename + '.xlsx');
                                document.body.appendChild(link);
                                link.click();
                                document.body.removeChild(link);//下载完成移除元素
                                window.URL.revokeObjectURL(url) //释放掉blob对象
                            }else{
                                let urlencoded = window.URL.createObjectURL(new Blob(binaryData,type));
                                window.open("/static/pdf/web/viewer.html?filename=" + filename + "&file=" + encodeURIComponent(urlencoded));
                            }

                            // let file = new Blob(binaryData)
                            // file.lastModifiedDate = new Date();
                            // file.name = "mailAtt.pdf"
                            // let formData = new FormData();
                            // formData.append("file", file);
                            // formData.append("orderNo", this.selectedNos[0])
                            // //发送账单邮件给客户
                            // postAction(this.url.sendBillMail,formData).then((res)=>{
                            //     console.log(res)
                            // })
                            this.visible=false
                            setTimeout(()=>{   //设置延迟执行
                                this.$message.success("开账单成功！")
                                this.$emit('ok')
                                // this.handleCheck();
                            },3000)
                        })

                }
            })
        },
        getOrderPre(){
            let count = [];
            this.selectionRows.forEach(item => {
                let pre = this.deleteNum(item.orderNo)
                if(pre==='KD'){
                    count.push(pre)
                }else{
                    count.push('OS')
                }
                // count.push(this.deleteNum(item.orderNo))
            });
            // console.log(Array.from(new Set(count)))
            let arr = Array.from(new Set(count));
            // console.log('账单前缀',arr)

            return arr
        },
        deleteNum(str){
            let reg = /[0-9]+/g;
            return str.replace(reg, "");

        },
        handleSizeChange(val) {
            this.ipagination.currentPage = 1
            this.ipagination.pageSize = val
            // this.handleCheck()
            this.$emit('ok', {isClearSelection: false})
        },
        handleCurrentChange(val) {
            this.ipagination.currentPage = val
            // this.handleCheck()
            this.$emit('ok', {isClearSelection: false})
        },
        judgeNoBill(rows){
            //判断是否勾选了未开账单的费用，是的话返回true
            let billedCount = [];
            this.selectionRows.forEach(item => {
                if(item.realMoneyType===null || item.realMoneyType===undefined || item.realMoneyType===''){
                    billedCount.push(item.settleId)
                }
            });
            let billedArr = Array.from(new Set(billedCount));
            // console.log('billedArr',billedArr)
            if(billedArr.length > 0){
                // this.$message.warning("已开账单的费用请使用已开账单的功能！")
                return true
            }else{
                return false
            }
        },
        delSettle(){
            if (this.selectedRowKeys.length === 0){
                this.$message.warning("请勾选费用")
                return
            }
            let billedCount = [];
            this.selectionRows.forEach(item => {
                if(item.realMoneyType!==null && item.realMoneyType!==undefined && item.realMoneyType!==''){
                    billedCount.push(item.settleId)
                }
            });
            let billedArr = Array.from(new Set(billedCount));
            if(billedArr.length > 0){
                this.$message.warning("已开账单的费用请使用已开账单的功能！")
                return
            }

            let ids = this.selectedRowKeys.join(",")
            this.$confirm(`确定删除费用?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'success',
                closeOnClickModal: false,
            }).then(() => {
                deleteAction(this.url.delSettleBatch, {ids}).then(res => {

                    this.$message.success("删除费用成功");
                    //关闭弹窗
                    // this.handleCheck()
                    this.$emit('ok')

                })
            }).catch(() => {
                // this.handleCheck()
                this.$emit('ok')
            })
        },
    },
};
</script>
<style lang="scss" scoped>
.flex_wait_box {
    display: flex;
    align-items: center;
    .select_width {
        width: 200px;
        margin: 0 10px;
    }

}
</style>
