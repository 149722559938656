<!--站内消息模板设置-->
<template>
  <div class="main-full-content">
    <!--        <el-form label-width="80px" size="medium">-->
    <!-- <el-card class="box-card"> -->
    <!--            <el-row>-->
    <!--                <el-col :xs="24" :sm="12" :md="12" :lg="6">-->
    <!--                    <el-form-item label="FBA CODE" label-width="120px">-->
    <!--                        <el-input v-model="queryForm.code"></el-input>-->
    <!--                    </el-form-item>-->
    <!--                </el-col>-->
    <!--                <el-col :xs="24" :sm="12" :md="12" :lg="6">-->
    <!--                    <el-form-item label-width="30px">-->
    <!--                        <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>-->
    <!--                        <el-button plain @click="handleReset">重置</el-button>-->
    <!--                    </el-form-item>-->
    <!--                </el-col>-->
    <!--            </el-row>-->
    <!-- </el-card> -->

    <!--        </el-form>-->
    <el-card class="box-card">
      <!-- <div class="f-p-b-15">
                <el-button type="primary" size="medium" icon="el-icon-plus" @click="dialogFormVisible = true">新增</el-button>
            </div> -->
      <f-table
        v-loading="loading"
        ref="GTable"
        border
        size="medium"
        row-key="id"
        class="c-th-has-bgcolor"
        :columns="columns"
        :data="tableData"
        :show-operation="true"
        :cell-style="{ 'text-align': 'center' }"
        tableHeight="79vh"
        @selection-change="handleSelectionChange"
        @sort-change="handleTableChange"
      >
        <template v-slot="scope">
          <template v-if="scope.column.property === 'action'">
            <el-button type="text" @click="handleEdit(scope.row)"
                       v-if="buttonShowList.bj">编辑</el-button>
            <!--                    <el-button type="text" @click="handleDel(scope.row.id)">删除</el-button>-->
          </template>
          <template v-else-if="scope.column.property === 'type'">
            <span>{{parseDict(scope.row.type,'sys_msg_set')}}</span>
          </template>
        </template>
      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination
          v-bind="ipagination"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </el-row>
    </el-card>

    <el-dialog
      title="站内信息模板修改"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
    >
      <el-form :model="addForm" :rules="rules" ref="addForm">
        <el-form-item label="信息类型" :label-width="formLabelWidth" prop="type">
          <f-select v-model="addForm.type" :disabled="true" :dict="'sys_msg_set'" ></f-select>
        </el-form-item>
        <el-form-item label="信息内容" :label-width="formLabelWidth" prop="cnMsg">
          <el-input v-model="addForm.cnMsg"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="(dialogFormVisible = false), (addForm = {})"
          >取 消</el-button
        >
        <el-button type="primary" @click="addFormData">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { deleteAction, putAction } from "@/api/manage";
import QueryMixins from "@/mixins/QueryMixins.js";

export default {
  name: "FbacodeManage",
  mixins: [QueryMixins],
  components: {},
  data() {
    return {
      dictCodes: ['sys_msg_set'],
      rules: {
        type: [
          { required: true, message: "请输入信息类型", trigger: "change" },
        ],
        cnMsg: [{ required: true, message: "请输入内容", trigger: "change" }],
      },
      loading: false,
      addVisible: false,
      authVisible: false,
      dialogFormVisible: false,
      addForm: {},
      formLabelWidth: "120px",
      row: {},
      options: [],
      id: "",
      columns: [
        {
          prop: "action",
          label: "操作",
          width: 50,
        },
        {
          prop: "type",
          label: "信息类型",
          width: 150,
        },
        {
          prop: "cnMsg",
          label: "信息内容",
        },
      ],
      url: {
        list: "/sys/sysMsgSet/list",
        edit: "/sys/sysMsgSet/edit",
      },
    };
  },
  methods: {
    addFormData() {
      this.$refs["addForm"].validate((valid) => {
        if (valid) {
          putAction(this.url.edit, this.addForm)
            .then((res) => {
              if (res.success) {
                this.$message.success(res.message);
                this.handleQuery();
                this.dialogFormVisible = false;
                this.addForm = {};
              }
            })
        } else {
          return false;
        }
      });
    },
    handleEdit(row) {
      this.addForm = JSON.parse(JSON.stringify(row));
      this.dialogFormVisible = true;
    },
    handleDel(id) {
      this.$confirm("确定删除该条数据吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
        closeOnClickModal: false,
      })
        .then(() => {
          deleteAction(this.url.delete, { id: id }).then((res) => {
            this.$message({
              type: "success",
              message: res.message,
            });
            this.handleSearch();
          });
        })
        .catch(() => {});
    },
    handleAuth(id) {
      this.id = id;
      this.authVisible = true;
    },
  },
  computed:{
    buttonShowList() {
      return {
        'bj':this.$btnIsShow('isystem/BaseData/SysMsgSet','1','编辑'),
      }
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
