/*富文本编辑图片上传地址*/
import {postAction} from "../api/manage";

const uploadConfig = {
    action:'/sys/oss/file/upload', //必填参数 图片上传地址
    methods:'POST', //必填参数 图片上传方式
    token: localStorage.getItem('TOKEN'), //可选参数，如果需要token验证，假设你的token有存放在sessionStorage
    name:'file',//必填参数 文件的参数名
    size: 10240, //可选参数 图片大小，单位为kb，1M=1024Kb
    accept:'image/png, image/gif, image/jpeg, image/bmp, image/x-icon', //可选 可上传的图片格式
};
// toolbar工具栏的工具选项（默认展示全部）
const toolOptions = [
    ['bold','italic','underline','strike'],
    ['blockquote','code-block'],
    [{'header':1},{'header':2}],
    [{'list':'ordered'},{'list':'bullet'}],
    [{'script':'sub'},{'script':'super'}],
    [{'indent':'-1'},{'indent':'+1'}],
    [{'direction':'rtl'}],
    [{'size':['small',false,'large',false]}],
    [{'header':[1,2,3,4,5,6,false]}],
    [{'color':[]},{'background':[]}],
    [{'font':[]}],
    [{'align':[]}],
    ['clean'],
    ['link','image','video']

];
const handlers = {
    image:function image() {
        var self = this;
        var fileInput = this.container.querySelector('input.ql-image[type=file]');
        if (fileInput == null) {
            fileInput = document.createElement('input');
            fileInput.setAttribute("type",'file');
            fileInput.setAttribute('accept', uploadConfig.accept);
            //设置图片参数名
            if (uploadConfig.name){
                fileInput.setAttribute('name',uploadConfig.name);
            }
            //可设置上传图片的格式
            fileInput.classList.add('ql-image');
            //监听选择文件
            fileInput.addEventListener('change',function () {
                //创建formData
                var formData = new FormData();
                formData.append(uploadConfig.name,fileInput.files[0]);
                postAction(uploadConfig.action, formData).then((res) => {
                    //如果传递参数为合同，则保留的是合同的id
                    //这里很重要，你图片上传成功后，img的src需要在这里添加
                    let length = self.quill.getSelection(true).index;
                    self.quill.insertEmbed(length,'image',res.result.url);
                    self.quill.setSelection(length+1);
                    self.container.appendChild(fileInput);
                })
            })

        }
        fileInput.click();
    }
};
export default {
    placeholder:'',
    theme:'snow',//主题
    modules:{
        toolbar:{
            container:toolOptions,
            handlers:handlers //时间重写
        }
    }
}