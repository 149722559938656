<template>
  <el-dialog
      title="派送详情"
      :visible="visible"
      width="30%"
      custom-class="c-custom-dialog"
      :before-close="handleClose"
      append-to-body
      :close-on-click-modal="false">
    <div v-for="(item,index) in formList">
      第{{ index + 1 }}辆
      <el-form class="addEditBox" :model="formList[index]" :rules="rules" :ref="'form' + index" label-width="100px"
               size="medium">
        <el-form-item prop="supplierId" label="供应商" :label-width="formLabelWidth">
          <f-select v-model="formList[index].supplierId" :is-need="formList[index].supplierId"
                    dict="supplier_id"></f-select>
        </el-form-item>

        <el-form-item prop="takeFee" label="提货费" :label-width="formLabelWidth">
          <el-input v-model="formList[index].takeFee" type="number"></el-input>
        </el-form-item>

<!--        <el-form-item prop="useCarType" label="派车类型" :label-width="formLabelWidth">-->
<!--          <f-select v-model="formList[index].useCarType" :is-need="formList[index].useCarType"-->
<!--                    dict="warehouse_take_order_use_car_type"></f-select>-->
<!--        </el-form-item>-->

<!--        <el-form-item prop="workType" label="车辆作业类型" :label-width="formLabelWidth">-->
<!--          <f-select v-model="formList[index].workType" :is-need="formList[index].workType"-->
<!--                    dict="warehouse_take_order_work_type"></f-select>-->
<!--        </el-form-item>-->

        <el-form-item prop="driverId" label="选择内部司机" :label-width="formLabelWidth">
          <el-select clearable filterable v-model="formList[index].driverId"
                     @change="handleChangeDriverName(formList[index].driverId,index)">
            <el-option v-for="(i,d) in driverInfoList" :value="i.value" :label="i.label" :key="d"></el-option>
          </el-select>
        </el-form-item>

        
        <el-form-item prop="driverName" label=" " :label-width="formLabelWidth">
          <el-input v-model="formList[index].driverInfoStr" type="textarea" placeholder="请输入司机姓名、手机号、车牌；回车后自动识别" @keydown.enter.native="handleDistinguish(index)
"></el-input>
        </el-form-item>
        <el-form-item prop="driverName" label="司机姓名" :label-width="formLabelWidth">
          <el-input v-model="formList[index].driverName" maxlength="5" show-word-limit></el-input>
        </el-form-item>

        <el-form-item prop="driverPhone" label="司机电话" :label-width="formLabelWidth">
          <el-input v-model="formList[index].driverPhone" maxlength="11" show-word-limit></el-input>
        </el-form-item>

        <el-form-item prop="driverCarNumber" label="车号" :label-width="formLabelWidth">
          <el-input v-model="formList[index].driverCarNumber" maxlength="10" show-word-limit></el-input>
        </el-form-item>
<!--        <el-form-item prop="outNoList" label="出库编码" :label-width="formLabelWidth"-->
<!--                      :rules="formList[index].workType==='1'?rules.outNoList:[]">-->
<!--          <f-select v-model="formList[index].outNoList" :is-need="formList[index].outNoList" dict="warehouse_out_no"-->
<!--                    :multiple="true"></f-select>-->
<!--        </el-form-item>-->


      </el-form>
    </div>

    <span slot="footer">
            <el-button size="medium" @click="handleClose">取消</el-button>
            <el-button type="primary" size="medium" @click="handleSubmit">确认</el-button>
        </span>
  </el-dialog>
</template>

<script>
import {postAction} from '@/api/manage'
import {mobilePattern} from "../../../utils/pattern";

export default {
  name: 'shoudongComplete',
  props: {
    visible: {
      type: Boolean
    },
    takeIds: {
      type: String,
      default: ''
    },
    hiDriverInfo: {
      type: String,
      default: ''
    },


  },
  data() {
    return {
      form: {},
      formList: [],
      formLabelWidth: '100px',
      rules: {
        supplierId: [
          {required: true, message: '请选择供应商', trigger: 'change'},
        ],
        // useCarType: [
        //   {required: true, message: '请选择派车类型', trigger: 'change'},
        // ],
        // workType: [
        //   {required: true, message: '请选择车辆作业性质', trigger: 'change'},
        // ],

        // carType: [
        //   {required: true, message: '请选择车辆类型', trigger: 'change'},
        // ],
        takeFee: [
          {required: true, message: '请输入提货费', trigger: 'change'},
        ],
        outNo: [
          {required: true, message: '请选择出库编码', trigger: 'change'},
        ],

      },
      url: {
        addOrUpdateDriverInfo: "/warehouse/takeDelivery/shoudongComplete",
        getTakeDeliveryDetailsByIds: '/warehouse/takeDelivery/getTakeDeliveryDetailsByIds',
        delTakeFeeById: "/warehouse/takeDelivery/delTakeFeeById",
        shoudongComplete: '/warehouse/takeDelivery/shoudongComplete',
      },
      driverInfoMap: {},
      driverInfoList: [],
      tableData: [],
    }
  },

  watch: {
    visible(val) {
      if (val && this.takeIds) {
        //勾选的订单号进行赋值
        this.$set(this.form, 'takeIds', this.takeIds);
        this.$set(this.form, 'outNoList', []);
        let list = this.takeIds.split(",");
        postAction(this.url.getTakeDeliveryDetailsByIds, list).then(res => {
          if (res && res.result) {
            let vo = res.result;
            this.formList = JSON.parse(JSON.stringify(vo.warehouseTakeDeliveryEntityList));
            this.driverInfoList = vo.warehouseDriverDropDownVoList;
            this.driverInfoMap = this.listToMap(this.driverInfoList, 'value');

          }
        })
      }
    },
  },
  methods: {
    handleChangeDriverName(val, index) {

      let obj = this.driverInfoMap[val];
      console.log('选择的司机', val, index, obj);
      if (obj) {
        let driverInfo = obj['driverInfo'];
        this.$set(this.formList[index], 'driverInfo', driverInfo);
        this.$set(this.formList[index], 'driverName', obj['name']);
        this.$set(this.formList[index], 'driverPhone', obj['phone']);
        this.$set(this.formList[index], 'driverCarNumber', obj['carNumber']);
        this.$set(this.formList[index], 'canEditDriver', true);
      }else {
        this.$set(this.formList[index], 'canEditDriver', false);
      }

    },
    addFee() {
      this.tableData.push({
        id: '',
        takeFee: '',
        cur: '',
        supplierId: '',
        supplierName: '',

      })
    },
    delTakeFee(index) {
      this.$confirm(`确定删除第${index + 1}行吗`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "success",
        closeOnClickModal: false,
      })
          .then(() => {
            let id = this.tableData[index].id;
            if (id) {
              postAction(this.url.delTakeFeeById + "?id=" + id)
                  .then(res => {
                    this.$message.success(res.message);
                  })
            }
            this.tableData.splice(index, 1);
          }).catch(() => {

      })
    },

    handleSubmit() {
      //校验
      let avalid = false;
      for (let i = 0; i < this.formList.length; i++) {
        let key = 'form' + i;
        console.log('this.$refs[key]',this.$refs[key])
        this.$refs[key] && this.$refs[key][0].validate((valid) => {
          avalid = valid;
        });
      }
      if (avalid) {
        postAction(this.url.addOrUpdateDriverInfo, this.formList).then((res) => {
          this.$message({
            type: 'success',
            message: res.message
          })
          this.form = {outNoList: []};
          this.handleClose();
          this.$emit('ok');
        })
      }


    },
    //将集合转为map
    listToMap(list, name) {
      var map = {};
      for (let i = 0; i < list.length; i++) {
        map[list[i][name]] = list[i];
      }
      return map;
    },
    handleClose() {
      this.formList = [];
      this.$emit('ok');
    },
    
    handleDistinguish(index) {
      if(this.formList[index] && this.formList[index].driverInfoStr) {
        console.log('触发自动识别');
        let driverInfoStr = this.formList[index].driverInfoStr.replace(/[, ，、\n]/g, '');
        console.log(driverInfoStr, 'driverInfoStr')
        const pattern = /[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽赣贵粤青藏川宁琼使领][A-Z][A-Z0-9]{4}[A-Z0-9挂学警港澳]{1}/g
        let driverCarNumberList = driverInfoStr.match(pattern) || []
        let driverCarNumber = (driverCarNumberList && driverCarNumberList.length && driverCarNumberList[0]) || ''
        let indexN = driverInfoStr.indexOf(driverCarNumber)
        if(driverCarNumber && indexN !== -1) {
          let driverCarNumber2 = driverInfoStr.charAt(indexN + driverCarNumber.length);
          if(driverCarNumber2 && driverCarNumber2 !== '1' && !(/[\u4e00-\u9fff]/.test(driverCarNumber2))) {
            driverCarNumber = driverCarNumber + driverCarNumber2
          }
          console.log(driverInfoStr.charAt(indexN + driverCarNumber.length), '匹配车牌的下一位字符')
        }
        driverCarNumber && (this.formList[index].driverCarNumber = driverCarNumber);
        driverInfoStr = driverInfoStr.replace(new RegExp(driverCarNumber, 'g'), "");
        console.log('车牌是',driverCarNumber)
        const phoneRegex = /(1[3|4|5|7|8]\d{9})/g;
        let driverPhoneList = driverInfoStr.match(phoneRegex) || []
        let driverPhone = (driverPhoneList && driverPhoneList.length && driverPhoneList[0]) || ''
        driverPhone && (this.formList[index].driverPhone = driverPhone);
        console.log('手机号是', driverPhone)
        driverInfoStr = driverInfoStr.replace(new RegExp(driverPhone, 'g'), "");
        driverInfoStr && driverCarNumber && driverPhone && (this.formList[index].driverName = driverInfoStr);
        console.log('名称是', driverInfoStr)
      }
    }
  },
  created() {
  }
}
</script>

<style lang="scss" scoped>
::v-deep
.el-dialog__body {
  padding: 20px 40px 20px 6px;
}

.addEditBox {
  max-height: 615px;
  overflow: scroll;
}
</style>
