<template>
    <el-dialog
            title="分摊结果"
            :visible="visible"
            width="50%"
            custom-class="c-custom-dialog"
            append-to-body
            :before-close="handleClose"
            :close-on-click-modal="false">
      <f-table
          ref="GTable"
          border
          size="medium"
          row-key="id"
          class="c-th-has-bgcolor"
          :columns="columns"
          :data="splitVo.takeFeeSplitResultList"
          :cell-style="{ 'text-align': 'center' }"
          tableHeight="60vh"

      ></f-table>
        <span slot="footer">
            <el-button type="primary" size="medium" @click="submit()">确认</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import {postAction} from "@/api/manage";

    export default {
        name: 'splitTakeFeeResultDialog',
        props: {
            visible: {
                type: Boolean
            },

          splitVo:{
            type:Object,
            default:()=>{}
          },

        },
        data() {
            return {
                form: {},
                formLabelWidth:'100px',
                columns:[

                  {
                    prop: 'takeNo',
                    label: '用车编号',
                  },
                  {
                    prop: 'allTotal',
                    label: '总金额',
                  },
                  {
                    prop: 'orderNo',
                    label: '订单号',
                  },
                  {
                    prop: 'settlement',
                    label: '结算对象',
                  },
                  {
                    prop: 'feename',
                    label: '费用名称',
                  },

                  {
                    prop: 'price',
                    label: '单价',
                  },
                  {
                    prop: 'quantity',
                    label: '数量',
                  },
                  {
                    prop: 'moneyType',
                    label: '币种',
                  },
                  {
                    prop: 'total',
                    label: '金额',
                  },
                  {
                    prop: 'isCost',
                    label: '是否录入成本',
                  },
                ],
              url:{
                addTakeFeeByVo: '/warehouse/takeDelivery/addTakeFeeByVo',
              }
            }
        },
        watch: {

        },
        methods: {

            handleClose() {
                this.$emit('ok')
            },
          submit(){
              postAction(this.url.addTakeFeeByVo,this.splitVo)
                  .then(res=>{
                    this.$message.success(res.message);
                    this.handleClose();
                  })
          },

        },
        created() {
        }
    }
</script>

<style lang="scss" scoped>
    ::v-deep
    .el-dialog__body {
        padding: 20px 40px 20px 6px;
    }
    .addEditBox {
      max-height: 615px;
      overflow: scroll;
    }
</style>
