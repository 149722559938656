<template>
  <div class="main-full-content">
            <el-form label-width="80px" size="medium">
     <el-card class="box-card">
                <el-row>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="中文描述" label-width="120px">
                            <el-input v-model="queryForm.cn"></el-input>
                        </el-form-item>
                    </el-col>
                  <el-col :xs="24" :sm="12" :md="12" :lg="4">
                    <el-form-item label="英文描述" label-width="120px">
                      <el-input v-model="queryForm.en1"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="12" :md="12" :lg="4">
                    <el-form-item label="信息头" label-width="120px">
                      <el-input v-model="queryForm.typeName"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="12" :md="12" :lg="4">
                    <el-form-item label="是否发邮件" label-width="120px">
                      <el-radio-group v-model="queryForm.isEmail">
                        <el-radio label="0">否</el-radio>
                        <el-radio label="1">是</el-radio>
                      </el-radio-group>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="12" :md="12" :lg="4">
                    <el-form-item label="是否异常" label-width="120px">
                      <el-radio-group v-model="queryForm.isError">
                        <el-radio label="0">否</el-radio>
                        <el-radio label="1">是</el-radio>
                      </el-radio-group>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="12" :md="12" :lg="4">
                    <el-form-item label="客户端是否可见" label-width="120px">
                      <el-radio-group v-model="queryForm.isClient">
                        <el-radio label="0">否</el-radio>
                        <el-radio label="1">是</el-radio>
                      </el-radio-group>
                    </el-form-item>
                  </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label-width="30px">
                            <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
                            <el-button plain @click="handleReset">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
     </el-card>

            </el-form>
    <el-card class="box-card">
      <!-- <div class="f-p-b-15">
                <el-button type="primary" size="medium" icon="el-icon-plus" @click="dialogFormVisible = true">新增</el-button>
            </div> -->
      <f-table
        v-loading="loading"
        ref="GTable"
        border
        size="medium"
        row-key="id"
        class="c-th-has-bgcolor"
        :columns="columns"
        :data="tableData"
        :show-operation="true"
        :cell-style="{ 'text-align': 'center' }"
        tableHeight="79vh"
        @selection-change="handleSelectionChange"
        @sort-change="handleTableChange"
      >
        <template v-slot="scope">
          <template v-if="scope.column.property === 'action'">
            <el-button type="text" @click="handleEdit(scope.row)"
                       v-if="buttonShowList.bj"
              >编辑</el-button>
            <!-- <el-button type="text" @click="handleDel(scope.row.id)">删除</el-button>-->
          </template>
          <template v-else-if="scope.column.property === 'apportionType'">
            <span>{{
              parseDict(scope.row.apportionType, "apportion_type")
            }}</span>
          </template>
          <template v-else-if="scope.column.property === 'isEmail'">
            <span v-if="scope.row.isEmail ==='1'">是</span>
            <span v-else>否</span>
          </template>
          <template v-else-if="scope.column.property === 'isError'">
            <span v-if="scope.row.isError ==='1'">是</span>
            <span v-else>否</span>
          </template>
          <template v-else-if="scope.column.property === 'isClient'">
            <span v-if="scope.row.isClient ==='1'">是</span>
            <span v-else>否</span>
          </template>
        </template>
      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination
          v-bind="ipagination"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </el-row>
    </el-card>

    <el-dialog
      title="物流信息修改"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
    >
      <el-form :model="addForm" :rules="rules" ref="addForm">
        <el-form-item
          label="信息头"
          :label-width="formLabelWidth"
          prop="typeName"
        >
          <el-input v-model="addForm.typeName"></el-input>
        </el-form-item>
        <el-form-item label="信息内容" :label-width="formLabelWidth" prop="cn">
          <el-input v-model="addForm.cn" type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="英文版1" :label-width="formLabelWidth" prop="en1">
          <el-input v-model="addForm.en1" type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="英文版2" :label-width="formLabelWidth" prop="en2">
          <el-input v-model="addForm.en2" type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="英文版3" :label-width="formLabelWidth" prop="en3">
          <el-input v-model="addForm.en3" type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="英文版4" :label-width="formLabelWidth" prop="en4">
          <el-input v-model="addForm.en4" type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="是否发送邮件" :label-width="formLabelWidth" prop="isEmail">
          <el-radio-group v-model="addForm.isEmail">
            <el-radio label="0">否</el-radio>
            <el-radio label="1">是</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="是否异常" :label-width="formLabelWidth" prop="isError">
          <el-radio-group v-model="addForm.isError">
            <el-radio label="0">否</el-radio>
            <el-radio label="1">是</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="客户端是否可见" :label-width="formLabelWidth" prop="isClient">
          <el-radio-group v-model="addForm.isClient">
            <el-radio label="0">否</el-radio>
            <el-radio label="1">是</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="(dialogFormVisible = false), (addForm = {})"
          >取 消</el-button
        ><el-button type="primary" @click="addFormData">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { deleteAction, putAction } from "@/api/manage";
import QueryMixins from "@/mixins/QueryMixins.js";

export default {
  name: "FbacodeManage",
  mixins: [QueryMixins],
  components: {},
  data() {
    return {
      rules: {
        typeName: [
          { required: true, message: "请输入信息头", trigger: "change" },
        ],
        cn: [{ required: true, message: "请输入内容", trigger: "change" }],
        en1: [{ required: true, message: "请输入英文版1", trigger: "change" }],
        isEmail: [{ required: true, message: "请选择是否发送邮件", trigger: "change" }],
        // en2: [{ required: true, message: "请输入英文版2", trigger: "change" }],
        // en3: [{ required: true, message: "请输入英文版3", trigger: "change" }],
        // en4: [{ required: true, message: "请输入英文版4", trigger: "change" }],
      },
      loading: false,
      addVisible: false,
      authVisible: false,
      dialogFormVisible: false,
      addForm: {},
      formLabelWidth: "120px",
      row: {},
      options: [],
      id: "",
      columns: [
        {
          prop: "action",
          label: "操作",

        },
        {
          prop: "typeName",
          label: "信息头",
          /*sortable: "typeName",*/

        },
        {
          prop: "cn",
          label: "中文描述",
          minWidth:250,
          /*sortable: "cn",*/

        },
        {
          prop: "en1",
          label: "英文版1",
          minWidth:220,
          /*sortable: "en1",*/

        },
        // {
        //   prop: "en2",
        //   label: "英文版2",
        //   /*sortable: "en2",*/
        //   width: 250,
        // },
        // {
        //   prop: "en3",
        //   label: "英文版3",
        //   /*sortable: "en3",*/
        //
        // },
        // {
        //   prop: "en3",
        //   label: "英文版3",
        //   /*sortable: "en3",*/
        //
        // },
        {
          prop: "isEmail",
          label: "是否发送邮件",
          /*sortable: "en3",*/

        },
        {
          prop: "isError",
          label: "是否异常",
          /*sortable: "en3",*/

        },
        {
          prop: "isClient",
          label: "客户端是否可见",
          /*sortable: "en3",*/

        },
      ],
      url: {
        list: "/sys/messageLog/list",
        // save: '/sys/feeType/save',
        edit: "/sys/messageLog/edit",
        // delete: '/sys/feeType/delete'
      },
    };
  },
  methods: {
    addFormData() {
      this.$refs["addForm"].validate((valid) => {
        if (valid) {
          putAction(this.url.edit, this.addForm)
            .then((res) => {
              if (res.success) {
                this.$message.success(res.message);
                this.handleQuery();
                this.dialogFormVisible = false;
                this.addForm = {};
              }
            })
        } else {
          return false;
        }
      });
    },
    handleEdit(row) {
      console.log("jinlai1l1");
      this.addForm = row;
      this.dialogFormVisible = true;
    },
    handleDel(id) {
      this.$confirm("确定删除该条数据吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
        closeOnClickModal: false,
      })
        .then(() => {
          deleteAction(this.url.delete, { id: id }).then((res) => {
            this.$message({
              type: "success",
              message: res.message,
            });
            this.handleSearch();
          });
        })
        .catch(() => {});
    },
    handleAuth(id) {
      this.id = id;
      this.authVisible = true;
    },
  },
  computed:{
    buttonShowList() {
      return {
        'bj':this.$btnIsShow('isystem/BaseData/MessageLogManage','1','编辑'),
      }
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
