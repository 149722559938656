<template>
  <div class="main-full-content">
    <el-form
      class="input_top"
      ref="form"
      :model="queryForm"
      label-width="120px"
      size="medium"
    >
      <el-card class="box-card">
        <el-row>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="船公司">
              <f-select
                v-model="queryForm.shippingCompanyId"
                :is-need="queryForm.shippingCompanyId"
                :dict="'ship_company'"
              ></f-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="起运港" :label-width="formLabelWidth">
              <f-select
                v-model="queryForm.startPort"
                :is-need="queryForm.startPort"
                :dict="'bd_port'"
              >
              </f-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="目的港" :label-width="formLabelWidth">
              <f-select
                v-model="queryForm.endPort"
                :is-need="queryForm.endPort"
                :dict="'bd_port'"
              >
              </f-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="3">
            <el-form-item label-width="30px">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="handleSearch"
                >查询</el-button
              >
              <el-button plain @click="handleReset">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
    </el-form>
    <el-card class="box-card last_card">
      <div class="f-p-b-15">
        <el-button
          type="primary"
          size="medium"
          icon="el-icon-plus"
          @click="handleAdd"
          v-if="buttonShowList.add"
        >新增</el-button
        >
        <el-button
          type="primary"
          size="medium"
          @click="downloadModel(url.downloadModel, '供应商报价导入模板')"
          v-if="buttonShowList.mbxz"

        >模板下载
        </el-button>
        <el-upload
          action="/"
          :show-file-list="false"
          :http-request="uploadSectionFile"
          accept=".xls,.xlsx"
          style="display: inline-flex; margin: 10px"
        >
          <el-button size="medium" type="primary"
                     v-if="buttonShowList.im"

          >导入</el-button>
        </el-upload>
        <el-button type="primary" size="medium" @click="handleExportXls"
                   v-if="buttonShowList.dc"

        >导出</el-button
        >
      </div>
      <f-table
        v-loading="loading"
        ref="GTable"
        border
        size="medium"
        row-key="id"
        class="c-th-has-bgcolor"
        :columns="columns"
        :data="tableData"
        :cell-style="{ 'text-align': 'center' }"
        tableHeight="64vh"
        @selection-change="handleSelectionChange"
        @sort-change="handleTableChange"
      >
        <template v-slot="scope">
          <template v-if="scope.column.property === 'action'">
            <el-button type="text" @click="handleEdit(scope.row)"
                       v-if="buttonShowList.bj"

            >编辑</el-button
            >
            <el-button type="text" @click="handleDele(scope.row)"
                       v-if="buttonShowList.sc"

            >删除</el-button
            >
          </template>
        </template>
      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination
          v-bind="ipagination"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </el-row>
    </el-card>
    <el-dialog
      :title="form.id ? '编辑报价' : '新增报价'"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      v-if="dialogFormVisible"
    >
      <el-form class="addEditBox" :model="form" :rules="rules" ref="form">
        <el-row class="inputs_row">
          <el-col :span="24">
            <el-form-item
              label="船公司"
              :label-width="formLabelWidth"
              prop="shippingCompanyId"
            >
              <f-select
                v-model="form.shippingCompanyId"
                :dict="'ship_company'"
                :is-need="form.shippingCompanyId"
                @changet="getMd"
              ></f-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item
              label="起运港"
              :label-width="formLabelWidth"
              prop="startPortId"
            >
              <f-select
                v-model="form.startPortId"
                :dict="'bd_port'"
                :is-need="form.startPortId"
                @changet="getStartPort"
              >
              </f-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item
              label="目的港"
              :label-width="formLabelWidth"
              prop="endPortId"
            >
              <f-select
                v-model="form.endPortId"
                :dict="'bd_port'"
                :is-need="form.endPortId"
                @changet="getEndPort"
              >
              </f-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="有效期开始时间"
              :label-width="formLabelWidth"
              prop="startTime"
            >
              <el-date-picker
                class="f-flex-1"
                v-model="form.startTime"
                type="datetime"
                size="small"
                placeholder="请选择"
                default-time="00:00:00"
                :picker-options="editStartOptions"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="有效期截止时间"
              :label-width="formLabelWidth"
              prop="endTime"
            >
              <el-date-picker
                class="f-flex-1"
                v-model="form.endTime"
                type="datetime"
                size="small"
                placeholder="请选择"
                :picker-options="editStopOptions"
                default-time="23:59:59"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd "
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="直达/中转"
              :label-width="formLabelWidth"
              prop="arriveType"
            >
              <template>
                <el-radio-group v-model="form.arriveType">
                  <el-radio :label="'0'">直达</el-radio>
                  <el-radio :label="'1'">中转</el-radio>
                </el-radio-group>
              </template>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="中转港"
              :label-width="formLabelWidth"
              prop="transferPortId"
              v-if="form.arriveType === '1'"
            >
              <f-select
                v-model="form.transferPortId"
                :dict="'bd_port'"
                :is-need="form.transferPortId"
                @changet="getTransferPort"
              >
              </f-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="航程/天"
              :label-width="formLabelWidth"
              prop="voyage"
            >
              <el-input
                v-model="form.voyage"
                autocomplete="off"
                type="number"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="20'GP"
              :label-width="formLabelWidth"
              prop="twentyGp"
            >
              <el-input
                v-model="form.twentyGp"
                autocomplete="off"
                type="number"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="40'GP"
              :label-width="formLabelWidth"
              prop="fortyGp"
            >
              <el-input
                v-model="form.fortyGp"
                autocomplete="off"
                type="number"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="40'HQ"
              :label-width="formLabelWidth"
              prop="fortyHq"
            >
              <el-input
                v-model="form.fortyHq"
                autocomplete="off"
                type="number"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="45'HQ"
              :label-width="formLabelWidth"
              prop="fortyFiveHq"
            >
              <el-input
                v-model="form.fortyFiveHq"
                autocomplete="off"
                type="number"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="截关日期"
              :label-width="formLabelWidth"
              prop="cutOffTime"
            >
              <el-date-picker
                class="f-flex-1"
                v-model="form.cutOffTime"
                type="datetime"
                size="small"
                placeholder="请选择"
                default-time="00:00:00"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd"
                :picker-options="editCutOffTimeOptions"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="开船日期"
              :label-width="formLabelWidth"
              prop="sailTime"
            >
              <el-date-picker
                class="f-flex-1"
                v-model="form.sailTime"
                type="datetime"
                size="small"
                placeholder="请选择"
                default-time="00:00:00"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd"
                :picker-options="editSailTimeOptions"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备注" :label-width="formLabelWidth" prop="remark">
              <el-input v-model="form.remark" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="medium" @click="dialogFormVisible = false"
          >取 消</el-button
        >
        <el-button size="medium" type="primary" @click="handleSave"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { deleteAction, postAction, putAction } from "@/api/manage";
import QueryMixins from "../../mixins/QueryMixins";
import Tempcclate from "../isystem/Dict";
import { getBdPortList } from "../../api/manage";
import { zhengshuPattern, zhengshuxiaoshuPartten } from "../../utils/pattern";

export default {
  name: "Potential",
  components: { Tempcclate },
  mixins: [QueryMixins],
  data() {
    return {
      // 校验开始时间不能大于结束时间
      editStartOptions: {
        disabledDate: (time) => {
          if (!this.form.endTime) {
            // time = new Date(time.replace(/-/g, '/'));
            return time.getTime() < new Date(1970 - 1 - 1).getTime(); //禁止选择1970年以前的日期
          } else {
            return time.getTime() > new Date(this.form.endTime);
          }
        },
      },
      editStopOptions: {
        disabledDate: (time) => {
          // time = new Date(time.replace(/-/g, '/'));
          return (
            time.getTime() + 86400000 < new Date(this.form.startTime) ||
            time.getTime() < new Date(1970 - 1 - 1).getTime() //禁止选择1970年以前的日期
          );
        },
      },
      // 校验截关时间不能大于开船时间
      editCutOffTimeOptions: {
        disabledDate: (time) => {
          if (!this.form.sailTime) {
            // time = new Date(time.replace(/-/g, '/'));
            return time.getTime() < new Date(1970 - 1 - 1).getTime(); //禁止选择1970年以前的日期
          } else {
            return time.getTime() > new Date(this.form.sailTime);
          }
        },
      },
      editSailTimeOptions: {
        disabledDate: (time) => {
          // time = new Date(time.replace(/-/g, '/'));
          return (
            time.getTime() + + 86400000 < new Date(this.form.cutOffTime) ||
            time.getTime() < new Date(1970 - 1 - 1).getTime() //禁止选择1970年以前的日期
          );
        },
      },
      rules: {
        shippingCompanyId: [
          { required: true, message: "请选择船公司名称", trigger: "change" },
        ],
        startPortId: [
          { required: true, message: "请选择起运港", trigger: "change" },
        ],
        endPortId: [
          { required: true, message: "请选择目的港", trigger: "change" },
        ],
        startTime: [
          { required: true, message: "请选择有效期", trigger: "change" },
        ],
        endTime: [
          { required: true, message: "请选择有效期", trigger: "change" },
        ],
        arriveType: [
          { required: true, message: "请选择直达/中转", trigger: "change" },
        ],
        //todo:选了中转  才需要出现中转港，并且必填
        /* transferPort: [
                         {required: true, message: '请选择中转港', trigger: 'change'},
                     ],*/
        voyage: [
          { required: true, message: "请填写航程(天)", trigger: "change" },
          {
            pattern: zhengshuPattern,
            message: "请输入正整数",
            trigger: "change",
          },
        ],
        twentyGp: [
          {
            pattern: zhengshuxiaoshuPartten,
            message: "请输入正整数或最多三位小数",
            trigger: "change",
          },
        ],
        fortyGp: [
          {
            pattern: zhengshuxiaoshuPartten,
            message: "请输入正整数或最多三位小数",
            trigger: "change",
          },
        ],
        fortyHq: [
          {
            pattern: zhengshuxiaoshuPartten,
            message: "请输入正整数或最多三位小数",
            trigger: "change",
          },
        ],
        fortyFiveHq: [
          {
            pattern: zhengshuxiaoshuPartten,
            message: "请输入正整数或最多三位小数",
            trigger: "change",
          },
        ],
        remark: [{ max: 255, message: "长度不能超过255", trigger: "change" }],
      },
      emailList: [],
      ids: [],
      row: {},
      form: {},
      formLabelWidth: "140px",
      //控制弹框显示
      dialogFormVisible: false,
      dialogFormVisibleEmail: false,
      columns: [
        {
          type: "selection",
          width: 50,
          fixed: 'left'
        },
        {
          prop: "action",
          label: "操作",
          width: 120,
          fixed: 'left'
        },
        {
          prop: "shippingCompany",
          label: "船公司",
          sortable: "custom",
          width: 140,
          fixed: 'left'
        },
        {
          prop: "startPort",
          label: "起运港",
          sortable: "custom",
          width: 130,
        },
        {
          prop: "endPort",
          label: "目的港",
          sortable: "custom",
          width: 130,
        },
        {
          prop: "transferPort",
          label: "中转港",
          sortable: "custom",
          width: 130,
        },
        {
          prop: "voyage",
          label: "航程(天)",
          sortable: "custom",
          width: 120,
        },
        {
          prop: "twentyGp",
          label: "20GP",
          sortable: "custom",
          width: 100,
        },
        {
          prop: "fortyGp",
          label: "40GP",
          sortable: "custom",
          width: 100,
        },
        {
          prop: "fortyHq",
          label: "40HQ",
          sortable: "custom",
          width: 100,
        },
        {
          prop: "fortyFiveHq",
          label: "45HQ",
          sortable: "custom",
          width: 100,
        },
        {
          prop: "startTime",
          label: "生效日期",
          sortable: "custom",
          width: 110,
        },
        {
          prop: "endTime",
          label: "截止日期",
          sortable: "custom",
          width: 110,
        },
        {
          prop: "cutOffTime",
          label: "截关日期",
          sortable: "custom",
          width: 110,
        },
        {
          prop: "sailTime",
          label: "开船日期",
          sortable: "custom",
          width: 110,
        },
        {
          prop: "remark",
          label: "备注提醒",
          sortable: "custom",
          width: 140,
        },
      ],
      url: {
        list: "/sys/supplierOceanshippingPrice/list",
        add: "/sys/supplierOceanshippingPrice/add",
        edit: "/sys/supplierOceanshippingPrice/edit",
        getById: "/sys/supplierOceanshippingPrice/queryById",
        delete: "/sys/supplierOceanshippingPrice/delete",
        exportXlsUrl: "/sys/supplierOceanshippingPrice/exportXls", //导出
        importExcel: "/sys/supplierOceanshippingPrice/importExcel", //导入
        downloadModel: "/sys/supplierOceanshippingPrice/downloadExcel", //模板下载
      },
    };
  },
  methods: {
    getStartPort(val) {
      this.form.startPort = val;
    },
    getEndPort(val) {
      this.form.endPort = val;
    },
    getTransferPort(val) {
      this.form.transferPort = val;
    },
    initBdPortList() {
      if (this.allBdportList.length <= 0) {
        this.allBdportList = [];
        getBdPortList()
          .then((res) => {
            if (res.success) {
              for (var i = 0; i < res.result.length; i++) {
                var obj = {};
                obj.value = res.result[i].cnName;
                obj.label = res.result[i].code + "-" + res.result[i].cnName;
                this.allBdportList.push(obj);
              }
              this.bdPortList = this.allBdportList.slice(0, 100);
            }
          })
          .catch((err) => {
            this.$message.error(err);
          });
      }
    },
    //日期隐藏时分秒显示
    formatterTime(data) {
      if (data) {
        return data.substring(0, 10);
      } else {
        return data;
      }
    },
    handleSelectionChange(selection) {
      // 多选
      let arr = [];
      this.emailList = [];
      for (let i = 0; i < selection.length; i++) {
        arr.push(selection[i]["id"]);
        this.emailList.push(selection[i]["email"]);
      }
      this.selectionRows = selection;
      this.selectedRowKeys = [...arr];
    },
    handleAdd() {
      this.$nextTick(() => {
        console.log(this.$refs);
        this.$refs.form.resetFields(); //等弹窗里的form表单的dom渲染完在执行this.$refs.staffForm.resetFields()，去除验证
      });
      this.form = {};
      this.dialogFormVisible = true;
    },
    handleEdit(row) {
      this.form = JSON.parse(JSON.stringify(row));
      this.dialogFormVisible = true;
    },
    handleSave() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          //编辑
          if (this.form.id) {
            putAction(this.url.edit, this.form).then((res) => {
              if (res.success) {
                this.$message.success(res.message);
                //关闭弹窗
                this.dialogFormVisible = false;
                this.handleQuery();
              }
            });
          } else {
            postAction(this.url.add, this.form).then((res) => {
              if (res.success) {
                this.$message.success(res.message);
                //关闭弹窗
                this.dialogFormVisible = false;
                this.handleQuery();
              }
            });
          }
        }
      });
    },
    handleDele(row) {
      this.$confirm(`确定删除报价`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        closeOnClickModal: false,
      })
        .then(() => {
          deleteAction(this.url.delete, { id: row.id }).then((res) => {
            this.$message({
              type: "success",
              message: res.message,
            });
            this.handleQuery();
          });
        })
        .catch(() => {});
    },
    handleReset() {
      this.queryForm = {};
      this.filterForm = {};
      this.ipagination.currentPage = 1;
      this.queryForm.column = "startTime";
      this.handleQuery();
    },
    //导入
    async uploadSectionFile(param) {
      //file就是当前添加的文件
      var fileObj = param.file;
      //FormData 对象
      var form = new FormData();
      //文件对象，key是后台接受的参数名称
      form.append("file", fileObj);
      //执行上传方法
      console.log("执行上传方法");
      postAction(this.url.importExcel, form)
        .then((res) => {
          //这里做上传之后的操作
          if (res.success) {
            this.$message.success(res.message);
            this.handleQuery();
          }
        })
    },
    getMd(val) {
      this.form.shippingCompany = val;
    },
  },
  created() {
    this.queryForm.initBdPort = true;
    this.queryForm.column = "startTime";
  },
  computed:{
    buttonShowList() {
      return {
        'dc':this.$btnIsShow('supplier/OceanShippingPrice','0','导出'),
        'im':this.$btnIsShow('supplier/OceanShippingPrice','0','导入'),
        'mbxz':this.$btnIsShow('supplier/OceanShippingPrice','0','模板下载'),
        'add':this.$btnIsShow('supplier/OceanShippingPrice','0','新增'),
        'sc':this.$btnIsShow('supplier/OceanShippingPrice','1','删除'),
        'bj':this.$btnIsShow('supplier/OceanShippingPrice','1','编辑'),
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.addEditBox {
  max-height: 625px;
  overflow: scroll;
}
</style>
