import {httpActionWithParams} from "@/api/manage";

export function downloadJasperExcelByMethodAndByUrlAndParamsAndFileName(method,url, params, filename,that) {
    //判断文件类型
    let fileTypeMime = ""; //文件 mime类型
    let suffix = "";
    if (filename.lastIndexOf(".") !== -1) {
        suffix = filename.substr(filename.lastIndexOf(".")+1);
        switch (suffix) { // switch获取后缀对应的mime 可参考上文链接
            case 'png': fileTypeMime = 'image/png'; break;
            case 'pdf': fileTypeMime = 'application/pdf'; break;
            case 'doc': fileTypeMime = 'application/msword'; break;
            case 'docx': fileTypeMime = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'; break;
            case 'jpg': case 'jpeg': fileTypeMime = 'image/jpeg'; break;
            case 'gif': fileTypeMime = 'image/gif'; break;
            case 'svg': fileTypeMime = 'image/svg+xml'; break;
            case 'tif': case 'tiff': fileTypeMime = 'image/tiff'; break;
            case 'txt': fileTypeMime = 'text/plain'; break;
            case 'ppt': fileTypeMime = 'application/vnd.ms-powerpoint'; break;
            case 'pptx': fileTypeMime = 'application/vnd.openxmlformats-officedocument.presentationml.presentation'; break;
            case 'xls': fileTypeMime = 'application/vnd.ms-excel'; break;
            case 'xlsx': fileTypeMime = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'; break;
            case 'zip': fileTypeMime = 'application/zip'; break;
            case '7z': fileTypeMime = 'application/x-7z-compressed'; break;
        }
    }
    console.log("要下载的文件类型",suffix + ":" + fileTypeMime)
    httpActionWithParams(url,method, params, {responseType: 'blob'}).then(response => {
        console.log("下载文件返回的",response);
        if (response && response.type === 'application/json') {
            const reader = new FileReader()
            reader.onload = function () {
                const message = JSON.parse(reader.result).message
                // that.$message.error(message);// 将错误信息显示出来
            }
            reader.readAsText(response)
            return false;
        } else {
            var binaryData = [];
            binaryData.push(response);
            let u = window.URL.createObjectURL(new Blob(binaryData,{"type":fileTypeMime}));
            let aLink = document.createElement('a');
            aLink.style.display = 'none';
            aLink.href = u;
            aLink.setAttribute('download', filename);
            document.body.appendChild(aLink);
            aLink.click();
            document.body.removeChild(aLink);
            window.URL.revokeObjectURL(u);
            that.$emit('ok');
        }
    })
}

// 直接打印后台返回的pdf
export function printPdfDoc(url, params, method='get') {
    //判断文件类型
    httpActionWithParams(url, method, params, {responseType: 'blob'}).then(response => {
        console.log("下载文件返回的",response);
        if (response && response.type === 'application/json') {
            const reader = new FileReader()
            reader.onload = function () {
                const message = JSON.parse(reader.result).message
                // that.$message.error(message);// 将错误信息显示出来
            }
            reader.readAsText(response)
            return false;
        } else {
            var binaryData = [];
            binaryData.push(response);
            let blob = window.URL.createObjectURL(new Blob(binaryData,{"type":  'application/pdf'}));
            var date = (new Date()).getTime()
            var ifr = document.createElement('iframe')
            ifr.style.frameborder = 'no'
            ifr.style.display = 'none'
            ifr.style.pageBreakBefore = 'always'
            ifr.setAttribute('id', 'printPdf' + date)
            ifr.setAttribute('name', 'printPdf' + date)
            ifr.src = blob
            document.body.appendChild(ifr)
            var ordonnance = document.getElementById('printPdf' + date).contentWindow
            setTimeout(() => {
                ordonnance.print()
            }, 100)
            window.URL.revokeObjectURL(ifr.src) // 释放URL 对象
        }
    })
}


export function previewHtmlUrl(url){
    var xhr = new XMLHttpRequest();
    xhr.onreadystatechange = function () {
        //服务器返回值的处理函数，此处使用匿名函数进行实现
        if (xhr.readyState == 4 && xhr.status == 200) {
            var respnseText = xhr.responseText;
            var binaryData = [];
            binaryData.push(respnseText);
            let urlencoded = window.URL.createObjectURL(new Blob(binaryData,
                {"type": "text/html"}));
            window.open(urlencoded)
        }
    };
    xhr.open("GET",url,true);
    xhr.send(null);
}
