<template>
    <div class="main-full-content">
        <!-- 待恢复 -->
        <el-card class="box-card">
            <f-table
                v-loading="loading"
                ref="GTable"
                border
                size="medium"
                row-key="id"
                class="c-th-has-bgcolor"
                :columns="columns"
                :data="tableData"
                :cell-style="{ 'text-align': 'center' }"
                tableHeight="79vh"
                :isRecordTableScroll="true"
                @selection-change="handleSelectionChange"
                @sort-change="handleTableChange">
            <template v-slot="scope">

                <template v-if="scope.column.property === 'action'">
                    <el-button type="text" @click="handleDel(scope.row)"
                               v-if="buttonShowList.qxhf"
                    >取消恢复</el-button>
                </template>
                <template v-else-if="scope.column.property === 'ossIds'">
                    <el-button type="text" @click="handleSee(scope.row)"
                               v-if="buttonShowList.see"
                    >查看</el-button>
                </template>
                <template v-else-if="scope.column.property === 'problemType'">
                    <span>{{parseDict(scope.row.problemType,'problem_type')}}</span>
                </template>
                <template v-else-if="scope.column.property === 'orderNo'">
                    <a style="color: #00a0e9" @click.prevent="handleEdit(scope.row.orderNo)">{{scope.row.orderNo}}</a>
                </template>
            </template>
        </f-table>
        </el-card>
        <el-dialog
                title="问题件"
                :visible="visible"
                width="50%"
                append-to-body
                custom-class="c-custom-dialog"
                :before-close="handleClose2">
            <el-form :model="form" :rules="rules" ref="form" label-width="94px" size="medium">
                <el-row>
                    <el-col :span="24">
                        <el-form-item prop="remarks" label="问题描述">
                            <el-input v-model="form.remarks" maxlength="2000" show-word-limit
                                      type="textarea"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item prop="problemType" label="问题类型">
                            <f-select v-model="form.problemType"  :dict="'problem_type'"
                                      :is-need="form.problemType">
                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item prop="fileList" label="附件上传">
                            <f-upload v-model="form.fileList" :dir="'kdOrder'"></f-upload>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer">
            <el-button size="medium" @click="handleClose2">取消</el-button>
            <el-button type="primary" size="medium" @click="handleSubmit">确定</el-button>
        </span>
        </el-dialog>
        <el-row class="f-text-right f-p-v-8">
            <el-pagination
                    v-bind="ipagination"
                    layout="total, sizes, prev, pager, next, jumper"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange">
            </el-pagination>
        </el-row>
    </div>
</template>

<script>
    import {getAction, putAction} from '@/api/manage'
    import QueryMixins from "@/mixins/QueryMixins.js"
    import { setUpRouteUtil } from '@/utils/util';

    export default {
        name: 'ProblemModule',
        mixins: [QueryMixins],
        components: {},
        data() {
            return {
                dialogFormVisible: false,
                visible: false,
                form: {fileList: []},
                fenpei: {},
                dictCodes: ['problem_type'],
                rules: {
                    remarks: [
                        {required: true, message: '请输入问题描述', trigger: 'blur'},
                        {max: 1000, message: '长度不能超过1000', trigger: 'change'},
                    ],
                    problemType: [
                        {required: true, message: '请选择问题类型', trigger: 'change'},
                    ],
                    handlerBy: [
                        {required: true, message: '请选择人员', trigger: 'change'}
                    ],
                },
                columns: [
                    {
                        prop: 'action',
                        label: '操作',
                    },
                    {
                        prop: 'orderNo',
                        label: '订单号',
                        over: true,
                    },
                    {
                        prop: 'handlerBy',
                        label: '处理人',
                        over: true,
                    },
                    {
                        prop: 'cusName',
                        label: '客户名',
                        over: true,
                    },
                    {
                        prop: 'salesman',
                        label: '业务员',
                        over: true,
                    },
                    {
                        prop: 'doc',
                        label: '港前操作',
                        over: true,
                    },
                    {
                        prop: 'remarks',
                        label: '问题描述',
                        over: true,
                    },
                    {
                        prop: 'problemType',
                        label: '问题类型',
                        over: true,
                    },
                    {
                        prop: 'createBy',
                        label: '录入人',
                        over: true,
                    },
                    {
                        prop: 'createTime',
                        label: '录入时间',
                        sortable: 'custom',
                        over: true,
                    },
                    {
                        prop: 'recoverTime',
                        label: '恢复时间',
                        sortable: 'custom',
                        over: true,
                    },
                    {
                        prop: 'ossIds',
                        label: '附件',
                        over: true,
                    },
                ],
                url: {
                    list: '/order/KdOrderProblem/list',
                    qxhuifu: '/order/KdOrderProblem/qxhuifu',
                    edit: '/order/KdOrderProblem/edit',
                    queryById: '/order/KdOrderProblem/queryById',
                },

            }
        },
        methods: {
            handleSubmit() {
                this.$refs.form.validate((valid) => {
                    if (valid) {
                        if (this.form.fileList) {
                            this.form.ossIds = this.form.fileList.map(function (e) {
                                return e.id
                            }).join(",");
                        }
                        putAction(this.url.edit, this.form)
                            .then(res => {
                                this.$message.success(res.message);
                                this.visible = false;
                                this.handleQuery();
                            })
                    }
                })


            },
            //进入编辑页面
            handleEdit(val) {
                // 对于路由不在左侧菜单中的选中菜单处理
                setUpRouteUtil(this.$route.path, '/kdorder/Edit');
                this.$router.push('/kdorder/Edit?orderNo=' + val);
            },
            handleSee(row) {
                getAction(this.url.queryById, {id: row.id})
                    .then(res => {
                        this.form = res.result;
                        if (!this.form.fileList) {
                            this.form.fileList = [];
                        }
                        this.visible = true;
                    })


            },
            handleDel({id, orderNo}) {
                this.$confirm(`确定恢复${orderNo}?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'error',
                    closeOnClickModal: false,
                }).then(() => {
                    putAction(this.url.qxhuifu, {id, orderNo}).then(res => {
                        this.$message({
                            type: 'success',
                            message: res.message
                        })
                        this.handleSearch()
                    })
                }).catch(() => {

                })
            },
            handleClose2() {
                this.visible = false;
                if (this.$refs['form']) {
                    this.$refs['form'].clearValidate();
                }
                this.handleQuery();
            },
        },
        created() {

            this.queryForm.datalevel = '1';
        },
      computed:{
        buttonShowList(){
          return {
            'qxhf':this.$btnIsShow('kdorder/kdproblem/ProblemEnd','1','取消恢复'),
            'see':this.$btnIsShow('kdorder/kdproblem/ProblemEnd','1','查看'),
          }
        }
      }
    }
</script>

<style lang="scss" scoped>

</style>
