<template>
  <el-dialog
    :title="row.id ? '编辑edd' : '新增edd'"
    :visible="visible"
    width="560px"
    custom-class="c-custom-dialog"
    :close-on-click-modal="false"
    :before-close="handleClose"
  >
    <el-form
      :model="addForm"
      :rules="rules"
      ref="addForm"
      label-width="150px"
      size="medium"
    >
      <el-row>
        <el-col :span="24" v-if="!addForm.shipmentCode">
          <el-form-item prop="shipmentZip" label="仓库代码">
            <f-select
              :dict="'warehouse_code'"
              v-model="addForm.shipmentZip"
              :is-need="addForm.shipmentZip"
              @change="showItemForm('仓库代码')"
            ></f-select>
          </el-form-item>
        </el-col>

        <el-col :span="24" v-if="!isWarehouse">
          <el-form-item prop="shipmentCode" label="FbaCode">
            <f-select
              :dict="'fba_code_edd'"
              v-model="addForm.shipmentCode"
              :is-need="addForm.shipmentCode"
              @change="showItemForm('FbaCode')"
            ></f-select>
          </el-form-item>
        </el-col>

        <el-col :span="24">
          <el-form-item prop="channelId" label="渠道名称">
            <f-select
              :dict="'transportStatus_enable'"
              v-model="addForm.channelId"
              :is-need="addForm.channelId"
              @changetAllData="changetChannelId"
            ></f-select>
          </el-form-item>
        </el-col>
        <el-col :span="24" v-if="!isWarehouse">
          <el-form-item prop="afns" label="AFNS">
            <el-input
              v-model.trim="addForm.afns"
              placeholder="请输入预估天数"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item prop="ed" label="ED/VDNS">
            <el-input
              v-model.trim="addForm.ed"
              placeholder="请输入预估天数"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24" v-if="!isWarehouse">
          <el-form-item prop="vans" label="VANS">
            <el-input
              v-model.trim="addForm.vans"
              placeholder="请输入预估天数"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24" v-if="!isWarehouse">
          <el-form-item prop="x4fn" label="X4FN">
            <el-input
              v-model.trim="addForm.x4fn"
              placeholder="请输入预估天数"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24" v-if="!isWarehouse">
          <el-form-item prop="k1aj" label="K1AJ">
            <el-input
              v-model.trim="addForm.k1aj"
              placeholder="请输入预估天数"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer">
      <el-button size="medium" @click="handleClose">取消</el-button>
      <el-button type="primary" size="medium" @click="handleSubmit"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { httpAction } from "@/api/manage";
import { getallcountries, getcityList, getstateList } from "@/api/manage";
import { zhengshuPatternNew } from "@/utils/validator";

export default {
  name: "FbacodeDialog",

  props: {
    visible: {
      type: Boolean,
    },
    row: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      formLabelWidth: "100px",
      addForm: {},
      countrylist: [],
      citylist: [],
      statelist: [],
      recMap: {},
      rules: {
        channelId: [
          { required: true, message: "请选择渠道", trigger: "change" },
        ],
        afns: [
          { required: true, message: "请输入预估天数", trigger: "change" },
          { validator: zhengshuPatternNew, trigger: "change" },
        ],
        ed: [
          { required: true, message: "请输入预估天数", trigger: "change" },
          { validator: zhengshuPatternNew, trigger: "change" },
        ],
        vans: [
          { required: true, message: "请输入预估天数", trigger: "change" },
          { validator: zhengshuPatternNew, trigger: "change" },
        ],
        x4fn: [
          { required: true, message: "请输入预估天数", trigger: "change" },
          { validator: zhengshuPatternNew, trigger: "change" },
        ],
        k1aj: [
          { required: true, message: "请输入预估天数", trigger: "change" },
          { validator: zhengshuPatternNew, trigger: "change" },
        ],

      },
      url: {
        add: "/sys/edd/add",
        edit: "/sys/edd/edit",
      },
      isWarehouse: null,
    };
  },
  watch: {
    visible(val) {
      console.log(this.isWarehouse === false, "this.isWarehouse");
      if (val && this.row.id) {
        const {
          id,
          shipmentCode,
          shipmentZip,
          channelName,
          channelId,
          ed,
          afns,
          vans,
          k1aj,
          x4fn,
          edd,
          isEffective,
        } = this.row;
        this.addForm = {
          id,
          shipmentCode,
          shipmentZip,
          channelName,
          channelId,
          ed,
          afns,
          vans,
          k1aj,
          x4fn,
          edd,
          isEffective,
        };
      } else {
        this.addForm = {};
      }
    },
  },
  created() {},
  methods: {
    handleSubmit() {
      let url = this.url.add;
      let method = "POST";
      if (this.row.id) {
        url = this.url.edit;
        method = "PUT";
      }
      const params = JSON.parse(JSON.stringify(this.addForm));
      if (this.addForm.shipmentZip) {
        delete params.afns;
        delete params.vans;
        delete params.x4fn;
        delete params.k1aj;
        delete params.shipmentCode;
      } else if (this.addForm.shipmentCode) {
        delete params.shipmentZip;
      }
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          httpAction(url, method, params).then((res) => {
            this.$message({
              type: "success",
              message: res.message,
            });
            this.handleClose();
            this.$emit("ok");
          });
        }
      });
    },
    showItemForm(e) {
      if (e === "仓库代码" && this.addForm.shipmentZip) {
        this.isWarehouse = true;
      } else if (e === "FbaCode" && this.addForm.shipmentCode) {
        this.isWarehouse = false;
      } else {
        this.isWarehouse = null;
      }
    },
    handleClose() {
      this.isWarehouse = null;
      this.$emit("update:visible", false);
      this.$refs.addForm.resetFields();
    },
    changetChannelId(e) {
      if (e && e.itemText) {
        this.addForm.channelName = e.itemText;
      } else {
        this.addForm.channelName = "";
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
