<template>
  <!-- 新增编辑活动配置弹窗 -->
  <div>
    <el-dialog :title="editType + '绩效规则'" :visible.sync="visible" :close-on-click-modal="false"
               :destroy-on-close="destroyOnClose" :before-close="handleClose" append-to-body>
      <el-form :model="form" :rules="rules" ref="firstForm" label-width="auto" style="height: 100%;">
        <el-card class="box-card">
          <el-row  class="inputs_row">
            <el-col :span="8">
              <el-form-item class="flex_form_item" prop="finalRuleName" label="配置规则名">
                <el-input v-model="form.finalRuleName" maxlength="50" show-word-limit type="textarea"
                          :autosize="{ minRows: 2, maxRows: 4 }">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">

              <el-form-item class="flex_form_item" prop="firstRuleId" label="规则1">
                <f-select v-model="form.firstRuleId" :is-need="form.firstRuleId" :data-list="firstRuleDictList"
                          @changet="handleChangeFirstRuleId">
                </f-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item class="flex_form_item" prop="configType" label="类型">
                <el-select v-model="form.configType" clearable filterable>
                  <el-option value="1" label="海运"></el-option>
                  <el-option value="2" label="快递"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-card>
      </el-form>
      <el-card class="box-card">
        <sales-per-field-config-table
            :select-base-vo="selectBaseVo"
            :select-list="firstDictList"
            :can-edit="false"
        ></sales-per-field-config-table>
      </el-card>
      <el-card class="box-card">
        <div slot="header">
          <span>设置规则2</span>
          <el-button type="primary" class="f-m-l-10" @click="handleAdd">新增一项</el-button>
        </div>
      </el-card>

      <el-card class="box-card" v-for="(item, index) in form.secondConfigList">
        <el-form :model="item" :rules="rules" :ref="`secondForm${index}`" label-width="auto" style="height: 100%;">
          <el-row :gutter="20" class="inputs_row">
            <el-col :span="8">
              <el-form-item class="flex_form_item" prop="ruleName" label="规则名称">
                <el-input v-model="item.ruleName" maxlength="50" show-word-limit type="textarea"
                          :autosize="{ minRows: 2, maxRows: 4 }">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item class="flex_form_item" prop="isEnable" label="是否启用">
                <el-switch
                    v-model="item.isEnable"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                    :active-value="'1'"
                    :inactive-value="'0'"
                    active-text="是"
                    inactive-text="否"
                >
                </el-switch>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-button type="primary" class="f-m-l-10" @click="handleAdd2(index)">新增一行</el-button>
              <el-button type="primary" class="f-m-l-10" @click="handleDel2(index)">删除</el-button>
              <el-button type="primary" class="f-m-l-10" @click="handleEditMatchResult(index)">编辑匹配结果</el-button>
              <el-tooltip  placement="top">
                <div slot="content">
                  标准提成比例：{{item.commissionRate}} <br>
                  个人承担比例：{{item.personRespRatio}} <br>
                  补助：{{item.subsidy}} <br>
                  最终比例：{{item.lastRatio}}
                </div>
                <i class="el-icon-question" style="font-size: 18px; margin-left: 10px;"></i>
              </el-tooltip>
            </el-col>
          </el-row>
      </el-form>


        <sales-per-field-config-table
            :select-base-vo="selectBaseVo"
            :select-list="item.dictList"
            @validate="handleValidate"
            :select-index="index"
            :ref="`thirdForm${index}`"
        ></sales-per-field-config-table>

        <sales-per-match-result-dialog
            :select-form="item"
            :visible="item.editVisible"
            :select-index="index"
            @ok="handleSetEditMatchResult"
            @validate="handleValidate2"
            :ref="`fourthForm${index}`"
        ></sales-per-match-result-dialog>
      </el-card>


      <template #footer>
        <el-button size="small" @click="handleClose">取消</el-button>
        <el-button type="primary" size="small" @click="validateAllForms">
          确定
        </el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import {postAction} from '@/api/manage';
import {getAction} from '@/api/manage';
import {validateUnitValue4, validateOuhao} from "@/utils/validator";
import salesPerFieldConfigTable from "@/views/salePerConfig/modules/salesPerFieldConfigTable.vue";
import salesPerMatchResultDialog from "@/views/salePerConfig/modules/salesPerMatchResultDialog.vue";
import salesPerConfigDialog from "@/views/salePerConfig/modules/salesPerConfigDialog.vue";

export default {
  name: 'salesPerConfigSecondDialog',
  components:
      {
        salesPerConfigDialog,
        salesPerFieldConfigTable,
        salesPerMatchResultDialog
      },
  props: {

    visible: {
      type: Boolean,
    },
    // 当关闭 Dialog 时，销毁其中的元素
    destroyOnClose: {
      type: Boolean,
      default: true,
    },

    selectForm: {
      type: Object,
      default: () => {
      }
    },
    selectBaseVo: {
      type: Object,
      default: () => {
      }
    },
    editType: {
      type: String,
    },
    firstRuleDictList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      rules: {
        finalRuleName: [
          {required: true, message: '请输入配置规则名', trigger: "change"},
        ],
        firstRuleId: [
          {required: true, message: '请选择规则1', trigger: "change"},
        ],
        configType: [
          {required: true, message: '请选择类型', trigger: "change"},
        ],
        ruleName: [
          {required: true, message: '请输入规则名称', trigger: "change"},
        ],
        isEnable: [
          {required: true, message: '请选择是否启用', trigger: "change"},
        ],
      },
      form: {},
      url: {
        getById: "/shipping/sales/salesPerConfigFirst/getById",
        add: "/shipping/sales/salesPerFinalRules/insertConfigItem",
        edit: "/shipping/sales/salesPerFinalRules/updateConfig",
      },

      tips: '',
      firstDictList: [
        {
          id: '',
          dictId: '',
          operatorSymbol: '',
          ruleType: '',
          ruleCode: '',
          dictName: '',
          dictValue: '',
          fieldValue: '',
          fieldValueList: [],
          dictValueInt: '',
          dictValueDouble: '',
          dictValueList: [],
        }
      ],
      isValid : ''
    }
  },
  watch: {
    visible(val) {
      if (val && this.selectForm && this.selectForm.id) {
        for (let key in this.selectForm) {
          this.$set(this.form,key,this.selectForm[key]);
        }
        this.handleChangeFirstRuleId(this.form.firstRuleId);
      } else {
        this.initForm();
        this.firstDictList = [];
      }
    },
    // 监听 规则1的变化
    'form.firstRuleId': {
      handler:function(name) {
       this.handleChangeFirstRuleId(this.form.firstRuleId);

      },
      deep: true,
      // immediate: true
    }

  },
  created() {
  },
  mounted() {
  },
  computed: {},
  methods: {
    initForm() {
      this.form = {
        id: '',
        finalRuleName: '',
        firstRuleId: '',
        firstDictList: [],
        secondConfigList: [
          {
            id: '',
            ruleName: '',
            isEnable: '1',
            personRespRatio: 0,
            subsidy: 0,
            lastRatio: 0,
            commissionRate: 0,
            editVisible: false,
            dictList: [

            ]
          }
        ]
      }
    },
    // 根据规则组合 渲染规则详情
    handleChangeFirstRuleId(val) {
      if (val) {
        postAction(this.url.getById, {id: this.form.firstRuleId})
            .then(res => {
              this.firstDictList = res.result.valueList;
            })
      } else {
        this.firstDictList = [];
      }

    },
    handleAdd() {
      console.log('新增一项',this.form);
      this.form.secondConfigList.push(
          {
            id: '',
            ruleName: '',
            isEnable: '1',
            personRespRatio: 0,
            subsidy: 0,
            lastRatio: 0,
            commissionRate: 0,
            dictList: [
              {
                id: '',
                dictId: '',
                operatorSymbol: '',
                ruleType: '',
                ruleCode: '',
                dictName: '',
                dictValue: '',
                fieldValue: '',
                fieldValueList: [],
                dictValueInt: '',
                dictValueDouble: '',
                dictValueList: [],
              }
            ]
          }
      )
    },
    handleAdd2(index) {
      this.$refs['thirdForm' + index][0].handleAdd();
    },
    handleDel2(index) {
      this.form.secondConfigList.splice(index, 1);
    },
    handleEditMatchResult(index) {
      // 打开编辑框
      this.$set(this.form.secondConfigList[index],'editVisible',true);
    },
    // 处理完之后 赋值
    handleSetEditMatchResult(index, form) {
      // 给编辑匹配结果赋值
      this.form.secondConfigList[index].commissionRate = form.commissionRate;
      this.form.secondConfigList[index].lastRatio = form.lastRatio;
      this.form.secondConfigList[index].subsidy = form.subsidy;
      this.form.secondConfigList[index].personRespRatio = form.personRespRatio;
      // 关闭弹窗
      this.form.secondConfigList[index].editVisible = false;
    },
    handleDelete(row, index) {
      if (this.form.list.length <= 1) {
        this.$message.warning('列表数据至少保留一项');
        return
      }
      this.form.list.splice(index, 1);
    },

    handleValidate(validTable, list, index) {
      console.log("触发了配置table的校验", validTable, list, index)
        if (validTable) {
          this.form.secondConfigList[index].dictList = list;
        }
    },
    handleValidate2(validTable, form, index) {
      console.log("触发了配置比例的校验", form, index)
      if (validTable) {
        this.form.secondConfigList[index].commissionRate = form.commissionRate;
        this.form.secondConfigList[index].lastRatio = form.lastRatio;
        this.form.secondConfigList[index].subsidy = form.subsidy;
        this.form.secondConfigList[index].personRespRatio = form.personRespRatio;
      }
    },
    async validateAllForms() {

      const forms = [this.$refs.firstForm].concat(
          this.form.secondConfigList.map((_, index) => this.$refs[`secondForm${index}`][0]),
          this.form.secondConfigList.map((_, index) => this.$refs[`thirdForm${index}`][0])
      );

      try {
        // 创建一个数组来保存所有的校验Promise
        const validationPromises = forms.map(form => {
          if (form && typeof form.validateForm === 'function') {
            return form.validateForm(); // 将任何拒绝的Promise转换为resolve(false)
          }else if (form && typeof form.validate === 'function') {
            // 如果有validate方法并且是函数，则调用并返回Promise
            return form.validate(); // 将任何拒绝的Promise转换为resolve(false)
          }else {
            return Promise.resolve(true); // 对于没有validate方法的表单，直接resolve为true
          }
        });

        // 等待所有校验完成
        const results = await Promise.all(validationPromises);
        let url = this.url.add;
        if (this.selectForm && this.selectForm.id) {
          url = this.url.edit;
        }
        postAction(url, this.form)
            .then(res => {
              this.$message.success(res.message);
              this.handleClose();
              this.$emit("ok");
            })
      }catch (error) {
        console.log("存在没通过验证的表单", error)
      }
    },
    handleClose() {
      this.initForm();
      this.$emit("update:visible", false);
    },
  }
}
</script>
<style lang="scss" scoped>

</style>
