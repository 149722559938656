<!--待收货-->
<template>
    <div class="main-full-content">
        <el-form
                class="input_top"
                ref="form"
                :model="queryForm"
                label-width="120px"
                size="medium"
        >
            <el-card class="box-card">
                <el-row>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="渠道名称">
                            <el-input v-model="queryForm.channelName" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="促销标签">
                            <el-input v-model="queryForm.nameLable" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label-width="30px">
                            <el-button
                                    type="primary"
                                    icon="el-icon-search"
                                    @click="handleSearch"
                            >查询
                            </el-button
                            >
                            <el-button plain @click="handleReset">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>
        </el-form>
        <el-card class="box-card last_card">
            <div class="f-p-b-15">
                <el-button
                        type="primary"
                        size="medium"
                        icon="el-icon-plus"
                        @click="handleAdd"
                        v-if="buttonShowList.add"
                >新增</el-button>
                <el-button
                        type="primary"
                        size="medium"
                        icon="el-icon-plus"
                        @click="handleDeleteBeatch"
                        v-if="buttonShowList.plsc"
                        :disabled="this.selectedRowKeys.length==0"
                >批量删除</el-button>
            </div>
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="66vh"
                    :isRecordTableScroll="true"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange"
            >
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'action'">
                        <el-button type="text" @click="handleEdit(scope.row)"
                                   v-if="buttonShowList.bj"
                        >编辑</el-button>
                        <el-button type="text" @click="handleDeleteRow(scope.row)"
                                   v-if="buttonShowList.sc"
                        >删除</el-button>

                    </template>
                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                >
                </el-pagination>
            </el-row>
        </el-card>
        <el-dialog
                :title="form.id ? '编辑' : '新增'"
                :visible.sync="dialogFormVisible"
                :close-on-click-modal="false"
                v-if="dialogFormVisible"
        >
            <el-form :model="form" :rules="rules" ref="form">
                <el-form-item label="促销标签" :label-width="formLabelWidth" prop="nameLable">
                    <el-input v-model="form.nameLable" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="渠道名称" :label-width="formLabelWidth" prop="channelName">
                    <el-input v-model="form.channelName" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="出仓时间" :label-width="formLabelWidth" prop="outOfHouseTime">
                    <el-date-picker
                            class="f-flex-1"
                            v-model="form.outOfHouseTime"
                            type="datetime"
                            size="small"
                            placeholder="出仓时间"
                            default-time="23:59:59"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            format="yyyy-MM-dd HH:mm:ss"
                            style="width: 100%"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="航班时间" :label-width="formLabelWidth" prop="departureTime">
                    <el-date-picker
                            class="f-flex-1"
                            v-model="form.departureTime"
                            type="datetime"
                            size="small"
                            placeholder="航班时间"
                            default-time="23:59:59"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            format="yyyy-MM-dd HH:mm:ss"
                            style="width: 100%"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="最低单价" :label-width="formLabelWidth" prop="floorPrice">
                    <el-input v-model="form.floorPrice" autocomplete="off" type="number"></el-input>
                </el-form-item>
                <el-form-item label="仓位方数" :label-width="formLabelWidth" prop="volume">
                    <el-input v-model="form.volume" autocomplete="off" type="number"></el-input>
                </el-form-item>
                <el-form-item label="剩余仓位" :label-width="formLabelWidth" prop="surplusVolume">
                    <el-input v-model="form.surplusVolume" autocomplete="off" type="number"></el-input>
                </el-form-item>

                <el-card class="box-card last_card">
                    <el-button size="small" type="primary" @click="AddListRow()">添加起运地</el-button>
                    <el-form :model="form" ref="form" class="form no_content" :rules="rules">
                        <el-table :data="form.depatureList" :label-width="formLabelWidth">
                            <el-table-column fixed label="操作" width="50">
                                <template slot-scope="scope">
                                    <el-button
                                            class=""
                                            size="small"
                                            type="primary"
                                            @click="handleDelete(scope.$index)"
                                            style="margin: 4px 10px;"
                                    >删除
                                    </el-button
                                    >
                                </template>
                            </el-table-column>
                            <el-table-column label="起运地" width="90">
                                <template slot-scope="scope">
                                    <el-form-item class="el_form_item" :prop="'depatureList.' + scope.$index + '.pod'" :rules="rules.pod">
                                        <el-input v-model="scope.row.pod"></el-input>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column label="截货日期" width="90">
                                <template slot-scope="scope">
                                    <el-form-item
                                            class="el_form_item"
                                            :prop="'depatureList.' + scope.$index + '.podTime'"
                                            :rules="rules.podTime"
                                    >
                                        <el-date-picker
                                                class="f-flex-1"
                                                v-model="scope.row.podTime"
                                                type="datetime"
                                                size="small"
                                                placeholder="截货日期"
                                                default-time="23:59:59"
                                                value-format="yyyy-MM-dd HH:mm:ss"
                                                format="yyyy-MM-dd HH:mm:ss"
                                                style="width: 100%"
                                        >
                                        </el-date-picker>
                                    </el-form-item>
                                </template>
                            </el-table-column>

                        </el-table>
                    </el-form>
                </el-card>

                <el-card class="box-card last_card">
                    <el-button size="small" type="primary" @click="AddListRow2()">添加费用备注</el-button>
                    <el-form :model="form" ref="form" class="form no_content" :rules="rules">
                        <el-table :data="form.priceList" :label-width="formLabelWidth">
                            <el-table-column fixed label="操作" width="50">
                                <template slot-scope="scope">
                                    <el-button
                                            class=""
                                            size="small"
                                            type="primary"
                                            @click="handleDelete2(scope.$index)"
                                            style="margin: 4px 10px;"
                                    >删除
                                    </el-button
                                    >
                                </template>
                            </el-table-column>
                            <el-table-column label="价格备注" width="90">
                                <template slot-scope="scope">
                                    <el-form-item class="el_form_item" :prop="'priceList.' + scope.$index + '.priceRemark'" :rules="rules.priceRemark">
                                        <el-input v-model="scope.row.priceRemark"></el-input>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column label="备注" width="90">
                                <template slot-scope="scope">
                                    <el-form-item
                                            class="el_form_item"
                                            :prop="'priceList.' + scope.$index + '.remark'"
                                            :rules="rules.remark"
                                    >
                                        <el-input v-model="scope.row.remark"></el-input>
                                    </el-form-item>
                                </template>
                            </el-table-column>

                        </el-table>
                    </el-form>
                </el-card>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="medium" @click="dialogFormVisible = false"
                >取 消
                </el-button
                >
                <el-button type="primary" size="medium" @click="handleSave"
                >确 定
                </el-button
                >
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {getAction, postAction, putAction,deleteAction} from "@/api/manage";
    import QueryMixins from "../../../mixins/QueryMixins";
    import {validateCmb} from "../../../utils/validator";

    export default {
        name: "Potential",
        mixins: [QueryMixins],
        data() {
            return {
                dictCodes: ['tariff_advance'],
                rules: {
                    nameLable: [
                        {required: true, message: "请输入促销标签", trigger: "change"},
                        {max: 50, message: "长度不能超过50", trigger: "change"},
                    ],
                    channelName: [
                        {required: true, message: "请输入渠道名称", trigger: "change"},
                        {max: 50, message: "长度不能超过50", trigger: "change"},
                    ],
                    outOfHouseTime: [
                        {required: true, message: "请输入出仓时间", trigger: "change"},
                    ],
                    departureTime: [
                        {required: true, message: "请输入直飞时间", trigger: "change"}
                        ],
                    floorPrice: [
                        {required: true, message: "请输入最低单价", trigger: "change"},
                        {validator: validateCmb, trigger: 'change'}
                    ],
                    volume: [
                        {required: true, message: "请输入仓位方数", trigger: "change"},
                        {validator: validateCmb, trigger: 'change'}
                    ],
                    surplusVolume: [
                        {required: true, message: "请输入剩余仓位", trigger: "change"},
                        {validator: validateCmb, trigger: 'change'}
                    ],
                },
                depatureList: [],
                priceList: [],
                ids: [],
                row: {},
                form: {},
                formLabelWidth: "120px",
                //控制弹框显示
                dialogFormVisible: false,
                columns: [
                    {
                        type: "selection",
                        width: 50,
                    },
                    {
                        prop: "action",
                        label: "操作",
                    },
                    {
                        prop: "nameLable",
                        label: "促销标签",
                    },
                    {
                        prop: "channelName",
                        label: "渠道名称",
                    },
                    {
                        prop: "outOfHouseTime",
                        label: "出仓时间",
                    },
                    {
                        prop: "departureTime",
                        label: "直飞时间",
                    },
                    {
                        prop: "volume",
                        label: "柜子方数",
                    },
                    {
                        prop: "surplusVolume",
                        label: "剩余方数",
                        sortable: "custom",
                    },
                    {
                        prop: "floorPrice",
                        label: "最低单价",
                        sortable: "custom",
                    },
                    {
                        prop: "createTime",
                        label: "创建时间",
                        sortable: "custom",
                    },
                ],
                url: {
                    list: "/order/kdSpecialPromotion/list",
                    add: "/order/kdSpecialPromotion/add",
                    edit: "/order/kdSpecialPromotion/edit",
                    getById: "/order/kdSpecialPromotion/queryById",
                    delete: "/order/kdSpecialPromotion/delete",
                },
            };
        },
        methods: {
            //点击加号添加一行数据
            AddListRow() {
                console.log(this.form)
                this.form.depatureList.push({
                    pod: '',
                    podTime: '',
                });
            },
            AddListRow2() {
                this.form.priceList.push({
                    priceRemark: '',
                    remark: '',
                });
            },
            handleDelete(index) {
                this.form.depatureList.splice(index, 1);
            },
            handleDelete2(index) {
                this.form.priceList.splice(index, 1);
            },
            handleSelectionChange(selection) {
                // 多选
                let arr = [];
                this.emailList = [];
                for (let i = 0; i < selection.length; i++) {
                    arr.push(selection[i]["id"]);
                    this.emailList.push(selection[i]["email"]);
                }
                this.selectionRows = selection;
                this.selectedRowKeys = [...arr];
            },
            handleAdd() {
                this.form = {};
                this.form.depatureList=[];
                this.form.priceList=[];
                this.dialogFormVisible = true;
            },
            handleEdit(row) {
                getAction(this.url.getById +'?id=' + row.id).then((res) => {
                    if (res.success) {
                        this.form =res.result;
                        this.form.depatureList = res.result.depatureList;
                        this.form.priceList = res.result.priceList;
                        this.dialogFormVisible = true;
                    }
                });
            },
            handleDeleteRow(row){
                this.$confirm(`确定删除${row.nameLable}`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'success',
                    closeOnClickModal: false,
                }).then(() => {
                    deleteAction(this.url.delete, {id: row.id}).then(res => {
                        this.$message({
                            type: 'success',
                            message: res.message
                        })
                        this.handleQuery();
                    })
                }).catch(() => {

                })
            },
            handleDeleteBeatch(){
                this.$confirm(`确定删除`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'success',
                    closeOnClickModal: false,
                }).then(() => {
                    deleteAction(this.url.delete, {id: this.selectedRowKeys.join(",")}).then(res => {
                        this.$message({
                            type: 'success',
                            message: res.message
                        })
                        this.handleQuery();
                    })
                }).catch(() => {

                })
            },
            handleSave() {
                this.$refs.form.validate((valid) => {
                    if (valid) {
                        //编辑
                        if (this.form.id) {
                            putAction(this.url.edit, this.form).then((res) => {
                                if (res.success) {
                                    this.$message.success(res.message);
                                    //关闭弹窗
                                    this.dialogFormVisible = false;
                                    this.handleQuery();
                                }
                            });
                        } else {
                            postAction(this.url.add, this.form).then((res) => {
                                if (res.success) {
                                    this.$message.success(res.message);
                                    //关闭弹窗
                                    this.dialogFormVisible = false;
                                    this.handleQuery();
                                }
                            });
                        }
                    }
                });
            },
            handleReset() {
                this.queryForm = {};
                this.ipagination.currentPage = 1;
                this.handleQuery();
            },
        },
        created() {
        },
      computed:{
        buttonShowList(){
          return {
            'add':this.$btnIsShow('kdorder/kdset/SpecialPromotion','0','新增'),
            'plsc':this.$btnIsShow('kdorder/kdset/SpecialPromotion','0','批量删除'),
            'bj':this.$btnIsShow('kdorder/kdset/SpecialPromotion','1','编辑'),
            'sc':this.$btnIsShow('kdorder/kdset/SpecialPromotion','1','删除'),
          }
        }
      }
    };
</script>

<style lang="scss" scoped>
</style>
