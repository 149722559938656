<template>
    <div class="main-full-content">
        <el-form class="input_top" ref="form" :model="queryForm" label-width="80px" size="medium">
            <el-card class="box-card">
                <el-row>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="仓库名">
                            <el-input v-model="queryForm.warehouseCode"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="国家">
                            <el-input v-model="queryForm.country"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="州">
                            <el-input v-model="queryForm.state"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="城市">
                            <el-input v-model="queryForm.city"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="拆柜代理">
                            <!--待提交 已拒绝-->
                            <f-select
                                    :isNeed="queryForm.supplierId"
                                    v-model="queryForm.supplierId"
                                    :fatherVal="queryForm.supplierId"
                                    :dict="'dismantleAgent_id'"></f-select>
                        </el-form-item>
                    </el-col>


                </el-row>
            </el-card>

        </el-form>
        <el-card class="box-card last_card">
            <el-button plain type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
            <el-button plain type="primary" @click="handleReset">重置</el-button>
            <el-button plain type="primary" @click="handleAdd">新增</el-button>
            <el-button plain type="primary"  @click="handleDelMore" :disabled="selectedRowKeys.length===0">批量删除</el-button>
            <div class="c-alert f-m-b-10">
                已选择 <span class="stress">{{this.selectedRowKeys.length}}</span> 项
                <span class="stress f-m-l-12 f-cursor-pointer" @click="handleClearSelection">清空</span>
            </div>
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="58vh"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange">
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'action'">
                        <el-button type="text" @click="handleEdit(scope.row)">编辑</el-button>
                        <el-button type="text" @click="handleDel(scope.row)">删除</el-button>

                    </template>
                    <template v-else-if="scope.column.property === 'orderNo'">
                        <a style="color: #00a0e9" @click.prevent="$intoDocPage(scope.row.orderNo)">{{ scope.row.orderNo
                            }}</a>
                    </template>
                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange">
                </el-pagination>
            </el-row>
        </el-card>
        <el-dialog :title="form.id?'编辑关系':'新增关系'" :visible.sync="addVisible" :close-on-click-modal="false" append-to-body>
            <el-form class="addEditBox" :model="form" :rules="rules" ref="form">
                <el-row class="inputs_row">
                    <el-col>
                        <el-form-item label="仓库编码" :label-width="formLabelWidth" prop="warehouseCode">
                            <el-input v-model="form.warehouseCode" autocomplete="off" maxlength="100"
                                      show-word-limit></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col>
                        <el-form-item label="拆柜代理" :label-width="formLabelWidth" prop="supplierId" >
                            <f-select
                                    :isNeed="form.supplierId"
                                    v-model="form.supplierId"
                                    :dict="'dismantleAgent_id'"></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col>
                        <el-form-item label="国家" :label-width="formLabelWidth" prop="country">
                            <el-input v-model="form.country" autocomplete="off" maxlength="50"
                                      show-word-limit></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col>
                        <el-form-item label="州" :label-width="formLabelWidth" prop="state">
                            <el-input v-model="form.state" autocomplete="off" maxlength="50" show-word-limit></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col>
                        <el-form-item label="城市" :label-width="formLabelWidth" prop="city">
                            <el-input v-model="form.city" autocomplete="off" maxlength="50" show-word-limit></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col>
                        <el-form-item label="地址" :label-width="formLabelWidth" prop="state">
                            <el-input v-model="form.address" autocomplete="off" maxlength="250" show-word-limit
                                      type="textarea"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="medium" @click="addVisible = false">取 消</el-button>
                <el-button type="primary" size="medium" @click="handleSave">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import QueryMixins from "@/mixins/QueryMixins.js"
    import {postAction} from "../../api/manage";

    export default {
        name: 'FbaToOlcWare',
        mixins: [QueryMixins],
        components: {},
        data() {
            return {
                visible: false,
                columns: [
                    {
                        prop: 'action',
                        label: '操作',
                    },
                    {
                        prop: 'warehouseCode',
                        label: '海外仓编码',
                    },
                    {
                        prop: 'supplierName',
                        label: "拆柜代理",
                    },
                    {
                        prop: 'coutry',
                        label: '国家',
                    },
                    {
                        prop: 'state',
                        label: '州',
                    },
                    {
                        prop: 'city',
                        label: '城市',
                    },
                    {
                        prop: 'fbaCode',
                        label: 'FBA CODE',
                    },
                    {
                        prop: 'address',
                        label: '地址',
                    },
                ],
                url: {
                    list: '/interface/fbaToOlc/queryFbaToOlcWareDataList',
                    add: '/interface/fbaToOlc/addFbaToOlcWareData',
                    edit: '/interface/fbaToOlc/editFbaToOlcWareData',
                    del: '/interface/fbaToOlc/delFbaToOlcWareData',
                    delMore: '/interface/fbaToOlc/delMoreFbaToOlcWareData',
                },
                isUsePost: true,
                addVisible: false,
                formLabelWidth: '100px',
                //校验规则
                rules: {
                    warehouseCode: [
                        {required: true, message: "请输入仓库编码", trigger: "change"},
                    ],
                    supplierId: [
                        {required: true, message: "请选择拆柜代理", trigger: "change"},
                    ],
                },
                form:{},
            }
        },
        methods: {
            handleAdd(){
                this.form = {};
                this.addVisible = true;
            },
            handleEdit(row) {
                this.form = JSON.parse(JSON.stringify(row));
                this.addVisible = true;
            },
            handleDel(row){
                this.$confirm(`确定删除${row.warehouseCode}?`, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "success",
                    closeOnClickModal: false,
                }).then(()=>{
                    postAction(this.url.del,row)
                        .then(res=>{
                        this.$message.success(res.message);
                            this.handleQuery();
                    })
                })
            },
            handleDelMore(){
                this.$confirm(`确定删除选中的数据吗?`, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "success",
                    closeOnClickModal: false,
                }).then(()=>{
                    postAction(this.url.del,row)
                        .then(res=>{
                            this.$message.success(res.message);
                            this.handleQuery();
                        })
                })
            },

            handleSave(){
                this.$refs['form'].validate(valid=>{
                    if (valid) {
                        let url = this.url.add;
                        if (this.form && this.form.id) {
                            url = this.url.edit;
                        }
                        postAction(url,this.form)
                        .then(res=>{
                            this.$message.success(res.message);
                            this.handleQuery();
                            this.addVisible = false;
                        })
                    }
                })
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>
