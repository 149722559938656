<template>
    <div>
        <el-card class="box-card">
            <el-form class="multiline_select" ref="form" :model="queryForm" label-width="80px" size="medium">
                <el-card class="box-card">
                    <el-row>
                        <el-col :xs="24" :sm="12" :md="12" :lg="5">
                            <el-form-item label="订单号">
                                <el-input  v-model="queryForm.orderNo" type="textarea" placeholder="多订单号"
                                           @keyup.enter.native="handleSearch"></el-input>
                            </el-form-item>
                        </el-col>
                      <el-col :xs="24" :sm="12" :md="12" :lg="5">
                        <el-form-item label="用车编号">
                          <el-input  v-model="queryForm.takeNo" type="textarea" placeholder="退仓用车编号"
                                     @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :xs="24" :sm="12" :md="12" :lg="5">
                        <el-form-item label="起运仓库">
                          <f-select  v-model="queryForm.originatingWarehouseId" :is-need="queryForm.originatingWarehouseId"
                                     :dict="'delivery_point'"
                                     @keyup.enter.native="handleSearch"></f-select>
                        </el-form-item>
                      </el-col>
                      <el-col :xs="24" :sm="12" :md="12" :lg="5">
                        <el-form-item label="送货地址">
                          <el-input  v-model="queryForm.shipToAddress"
                                     placeholder="模糊查询"
                                     @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :xs="24" :sm="12" :md="12" :lg="5">
                        <el-form-item label="创建人">
                          <f-select  v-model="queryForm.createBy"
                                     :is-need="queryForm.createBy"
                                     :dict="'sys_user'"
                                     @keyup.enter.native="handleSearch"></f-select>
                        </el-form-item>
                      </el-col>
                      <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="10">
                        <el-form-item label="创建时间">
                          <el-date-picker
                              class="f-flex-1"
                              v-model="queryForm.createTimes"
                              type="datetimerange"
                              size="small"
                              range-separator="至"
                              start-placeholder="开始日期"
                              end-placeholder="结束日期"
                              :default-time="['00:00:00', '23:59:59']"
                              :picker-options="editStopOptions"
                              value-format="yyyy-MM-dd HH:mm:ss"
                              format="yyyy-MM-dd HH:mm:ss"
                              unlink-panels
                          >
                          </el-date-picker>
                        </el-form-item>
                      </el-col>
                      <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="10">
                        <el-form-item label="退仓用车时间">
                          <el-date-picker
                              class="f-flex-1"
                              v-model="queryForm.originatingWarehouseTimes"
                              type="datetimerange"
                              size="small"
                              range-separator="至"
                              start-placeholder="开始日期"
                              end-placeholder="结束日期"
                              :default-time="['00:00:00', '23:59:59']"
                              :picker-options="editStopOptions"
                              value-format="yyyy-MM-dd HH:mm:ss"
                              format="yyyy-MM-dd HH:mm:ss"
                              unlink-panels
                          >
                          </el-date-picker>
                        </el-form-item>
                      </el-col>

                      <el-col :xs="24" :sm="12" :md="12" :lg="5">
                        <el-form-item label="车牌/司机/电话" label-width="100px">
                          <el-input  v-model="queryForm.driverInfo"
                                     @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                      </el-col>
                        <el-col :xs="24" :sm="12" :md="12" :lg="4">
                            <el-form-item label-width="30px">
                                <el-button
                                        type="primary"
                                        icon="el-icon-search"
                                        @click="handleSearch"
                                >查询
                                </el-button
                                >
                                <el-button plain @click="handleReset">重置</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>


                </el-card>
            </el-form>
          <div class="bbb">
            <el-button type="primary" size="medium" @click="openRollDialog"
                       v-if="buttonShowList.addtc">新增退仓
            </el-button>
            <el-button type="primary" size="medium" @click="cancelRoll(null,selectedRowKeys)" :disabled="selectedRowKeys.length === 0"
                       v-if="buttonShowList.qx">取消
            </el-button>
          </div>

            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="73vh"
            >
                <template v-slot="scope">
                  <template v-if="scope.column.property === 'action'">
                   <el-button type="text" @click="cancelRoll(scope.row,null)" v-if="buttonShowList.qx">取消</el-button>
                  </template>
                  <template v-else-if="scope.column.property === 'orderNo'">
                  <span v-for="(i,d) in scope.row.innerVos" :key="d">
                           <a style="color: #00a0e9;" @click.prevent="$intoDocPage(i.ttOrderNo)">{{
                               i.ttOrderNo
                             }}</a>
                    <span style="margin-left: 5px">{{i.number}}/</span>
                    <span style="margin-left: 3px">{{i.weight}}/</span>
                    <span style="margin-left: 3px">{{i.volume}}</span> <br>
                        </span>
                  </template>
                  <template v-else-if="scope.column.property === 'shipToAddress'">
                    <span>公司：{{scope.row.shipToCompany}}</span> <br>
                    <span>地址：{{scope.row.shipToAddress}}</span> <br>
                    <span>联系人：{{scope.row.shipToContact}}</span> <br>
                    <span>联系电话：{{scope.row.shipToPhone}}</span>
                  </template>
                  <template v-else-if="scope.column.property === 'takeNo'">
                    <a style="color: #00a0e9"  @click.prevent="opentcDetail(scope.row.id)">{{
                        scope.row.takeNo
                      }}</a>
                  </template>
                </template>

            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange">
                </el-pagination>
            </el-row>
        </el-card>
      <add-tuicang-dialog :visible="rollVisible" @ok="closeRollDialog"></add-tuicang-dialog>
      <tc-order-detail :visible="tcOrderDetailVisible" @ok="closetcDetail" :take-ids="selectedtcId"></tc-order-detail>
    </div>
</template>

<script>
    import QueryMixins from "@/mixins/QueryMixins.js";
    import { postAction} from "@/api/manage";
    import addTuicangDialog from "./modules/addTuicangDialog.vue";
    import tcOrderDetail from "./modules/tcOrderDetail.vue";
    export default {
        name: "Tuicang",
        mixins: [QueryMixins],
        components: {addTuicangDialog,tcOrderDetail},
        data() {
            return {
              tcOrderDetailVisible:false,
              selectedtcId:'',
              editStopOptions: {
                disabledDate: (time) => {
                  // time = new Date(time.replace(/-/g, '/'));
                  return (
                      time.getTime() < new Date(this.form.stime) ||
                      time.getTime() < new Date(1970 - 1 - 1).getTime() //禁止选择1970年以前的日期
                  );
                },
              },
              //新增转仓的弹窗
              rollVisible:false,
                isUsePost:true,
                queryForm:{
                  createTimes:[],
                  originatingWarehouseTimes:[],
                },
                columns: [
                    {
                        type: "selection",
                    },
                    {
                        prop: "action",
                        label: "操作",
                    },
                    {
                        prop: "driverInfo",
                        label: "车牌/司机/电话",
                    },
                    {
                      prop: "takeFee",
                      label: "国内送货费",
                    },

                    {
                        prop: "takeNo",
                        label: "退仓用车编号",
                    },
                  {
                    prop: "orderNo",
                    label: "订单号-件重体",
                    minWidth: 200
                  },

                  {
                    prop: "ctnStr",
                    label: "件重体",
                  },
                  {
                    prop: "originatingWarehouseName",
                    label: "起运仓库",
                  },
                  {
                    prop: "originatingWarehouseTime",
                    label: "退仓用车时间",
                  },
                  {
                    prop: "shipToAddress",
                    label: "送货地址",
                    minWidth:160,
                  },


                  {
                    prop: "remark",
                    label: "备注",
                  },
                  {
                    prop: "createBy",
                    label: "发起人",
                  },
                  {
                    prop: "createTime",
                    label: "创建时间",
                  },

                ],
                url: {
                    //查询列表的接口
                    list: "/warehouse/takeDelivery/getWarehouseTuicangIPage",
                    cancelRollBatch: "/warehouse/takeDelivery/cancelRollBatch",
                },


            };
        },
        methods: {
          //打开新增转仓的弹窗
          opentcDetail(id){
            this.tcOrderDetailVisible = true;
            this.selectedtcId = id;
          },
          closetcDetail(){
            this.tcOrderDetailVisible = false;
            this.handleQuery();
          },
          //关闭新增转仓的弹窗
          closeRollDialog(){
            this.rollVisible = false;
            this.handleQuery();
          },
          //打开新增转仓的弹窗
          openRollDialog(){
            this.rollVisible = true;
          },
          //取消
          cancelRoll(row,rows){
            let list = [];
            if (row) {
              list.push(row.id);
            }
            if (rows && rows.length > 0) {
              list = rows;
            }
            this.$confirm(`确定取消`, "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "success",
              closeOnClickModal: false,
            }).then(()=>{
              postAction(this.url.cancelRollBatch,list)
              .then(res=>{
                this.$message.success(res.message);
                this.handleQuery();
              })
            })
          }
        },
      computed:{
        buttonShowList() {
          return {
            'qx':this.$btnIsShow('warehouse2.0/Tuicang','0','取消'),
            'addtc':this.$btnIsShow('warehouse2.0/Tuicang','0','新增退仓'),
          }
        }
      }
    };
</script>

<style lang="scss" scoped>
    .bbb {
        display: flex;
    }

    .dialog-footer {
        text-align: right !important;
    }

    .margin_right_10 {
        margin-right: 10px;
    }
</style>
