<template>
    <div class="header-nav f-flex f-row-between" ref="headerTopBox">
        <span class="collapse-wrap" @click="$emit('toogle-collapse')">
            <i :class="isCollapse ? 'el-icon-s-unfold' : 'el-icon-s-fold'"></i>
        </span>
        <right-operator></right-operator>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import RightOperator from './RightOperator'
    export default {
        name: "HearderNav",
        props: {
            isCollapse: Boolean
        },
        components: {
           RightOperator
        },
        computed: {
          ...mapState(["navHeight"]),
        },
        mounted() {
          //窗口尺寸改变
          window.onresize = () => {
            return (() => {
              this.$nextTick(() => {
                this.$store.commit("setNavHeight", {headerTopBox: (this.$refs.headerTopBox && this.$refs.headerTopBox.offsetHeight) || 40,headerTwoBox: this.navHeight.headerTwoBox})
                console.log(this.$refs.headerTopBox.offsetHeight,this.navHeight, '顶部导航栏高度')
              })
            })();
          };
          this.$nextTick(() => {
            this.$store.commit("setNavHeight", {headerTopBox: (this.$refs.headerTopBox && this.$refs.headerTopBox.offsetHeight) || 40,headerTwoBox: this.navHeight.headerTwoBox})
            console.log(this.$refs.headerTopBox.offsetHeight,this.navHeight, '顶部导航栏高度')
          })
        },
    }
</script>

<style lang="scss" scoped>
.header-nav {
    line-height: 40px;
    .collapse-wrap {
        > i {
            font-size: 20px;
            color: #606266;
        }
        padding: 0 12px;
        cursor: pointer;
        &:hover {
            background: rgba(0,0,0,.025);
        }
    }
}
</style>
