<template>
    <div class="main-full-content">
        <el-dialog
            :title="connectedBankTitle"
            :visible="connectedBankAddVisible"
            width="780px"
            custom-class="c-custom-dialog"
            :close-on-click-modal="false"
            :destroy-on-close="true"
            :before-close="invoiceAddHandleClose"
        >
            <el-form :model="addForm" ref="addForm" :rules="addRules">
                <el-row class="inputs_row">
                    <el-col :span="12">
                        <el-form-item prop="accountNo" label="账户号" label-width="80px">
                            <el-input style="width: 90%" v-model="addForm.accountNo" placeholder="请输入账户名"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item prop="accountName" label="账户名" label-width="80px">
                            <el-input v-model="addForm.accountName" oninput="value=value.match(/\d+\.?\d{0,2}/)"></el-input>
                        </el-form-item>
                    </el-col>

                </el-row>
                <el-form-item prop="internetBankCusNo" label="网银客户号" label-width="80px">
                    <el-input v-model.trim="addForm.internetBankCusNo"></el-input>
                </el-form-item>
                <el-form-item prop="cusName" label="客户名称" label-width="80px">
                    <el-input v-model="addForm.cusName" placeholder="例如：大森林"></el-input>
                </el-form-item>
                <el-form-item prop="bankBelong" label="所属银行" label-width="80px">
                    <el-select v-model="queryForm.bankBelong">
                        <el-option value="1" label="中国银行（深圳）"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item prop="bankBelong" label="清算行行号" label-width="80px">
                    <el-input v-model="addForm.clearingBankNo"></el-input>
                </el-form-item>

            </el-form>
            <span slot="footer">
        <el-button
            type="primary"
            size="medium"
            @click="connectedBankAddSubmit">
            确定
        </el-button>
      </span>
        </el-dialog>

        <el-form class="multiline_select" ref="form" :model="queryForm" label-width="80px" size="medium">
            <el-card class="box-card">
                <el-row class="inputs_row">

                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="账户号">
                            <el-input v-model="queryForm.accountNo" @keyup.enter.native="handleCheck" maxlength="20" show-word-limit></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="3">
                        <el-form-item label="所属银行">
                            <el-select v-model="queryForm.bankBelong">
                                <el-option value="1" label="中国银行（深圳）"></el-option>

                            </el-select>
                        </el-form-item>
                    </el-col>

                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label-width="30px" style="width: 400px">
                            <el-button
                                size="medium"
                                type="primary"
                                icon="el-icon-search"
                                @click="handleCheck"
                            >查询
                            </el-button
                            >
                            <el-button size="medium" plain @click="handleReset">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>
        </el-form>
        <el-card class="box-card">
            <div class="f-p-b-15 flex_wait_box">


<!--                <el-button size="medium" type="primary" @click="addOne()"-->
<!--                           v-if="$btnIsShow('connectedBank/connectedBankPage','0','新增对接银行信息')"-->
<!--                >新增-->
<!--                </el-button>-->

<!--                <el-button size="medium" type="primary" @click="exportDetail()"-->
<!--                           v-if="$btnIsShow('hyFinanceManage/modules/receive/billed','0','导出申请详情excel')"-->
<!--                >导出申请详情excel-->
<!--                </el-button>-->

            </div>

            <f-table
                v-loading="loading"
                ref="GTable"
                border
                size="medium"receiveApplyUpdate
                row-key="id"
                class="c-th-has-bgcolor"
                :columns="columns"
                :data="tableData"
                :show-operation="true"
                :cell-style="{ 'text-align': 'center' }"
                @selection-change="handleSelectionChange"
                @sort-change="handleTableChange"
                tableHeight="61vh"
                :isRecordTableScroll="true"
            >
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'action'">
                        <el-button slot="reference" type="text" @click="getAmount"
                        >查看今日余额
                        </el-button>
                    </template>
                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                    v-bind="ipagination"
                    layout="total, sizes, prev, pager, next, jumper"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                >
                </el-pagination>
            </el-row>
        </el-card>

        <amount-dialog ref="amountDialog"></amount-dialog>

    </div>
</template>
<script>
import QueryMixins from "@/mixins/QueryMixins"
import {getAction, postAction, putAction} from "@/api/manage";
import amountDialog from "@/views/connectedBank/modules/amountDialog";


import {setUpRouteUtil} from '@/utils/util';


export default {
    mixins: [QueryMixins],
    components: {
        amountDialog
    },
    data() {
        return {
            dictCodes: [],
            invoiceInfoList: [],
            editStartOptions: {
                disabledDate: (time) => {
                    if (!this.form.etime) {
                        // time = new Date(time.replace(/-/g, '/'));
                        return time.getTime() < new Date(1970 - 1 - 1).getTime(); //禁止选择1970年以前的日期
                    } else {
                        return time.getTime() > new Date(this.form.etime);
                    }
                },
            },
            editStopOptions: {
                disabledDate: (time) => {
                    // time = new Date(time.replace(/-/g, '/'));
                    return (
                        time.getTime() < new Date(this.form.stime) ||
                        time.getTime() < new Date(1970 - 1 - 1).getTime() //禁止选择1970年以前的日期
                    );
                },
            },
            selectedRowKeys: [],
            selectionRows: [],
            selectedNos: [],
            loading: false,
            form: {},
            dialogFormVisibleEmail: false,
            dialogFormVisible: false,
            emails: [],
            connectedBankTitle:'',
            connectedBankAddVisible:false,
            addForm:{},
            columns: [
                {
                    type: "selection",
                    width: 50,
                },
                {
                    prop: 'accountNo',
                    label: '账户号',
                    width: 100,
                },
                {
                    prop: "action",
                    label: "操作",
                    width: 220,
                },
                {
                    prop: "accountName",
                    label: "账户名",
                    width: 140,
                },

                {
                    prop: 'cusName',
                    label: '客户名称',
                    width: 140,
                },
                {
                    prop: 'bankBelong',
                    label: '所属银行',
                    width: 140,
                },
                {
                    prop: "clearingBankNo",
                    label: "清算行行号",
                    width: 140,
                },
                {
                    prop: "unionBankNum",
                    label: "联行行号",
                    width: 140,
                },
                {
                    prop: "accountNative",
                    label: "银行性质",
                    width: 140,
                },
                {
                    prop: "termid",
                    label: "企业前置机编号",
                    width: 140,
                },
                {
                    prop: "custid",
                    label: "网银客户编码",
                    width: 140,
                },
                {
                    prop: "cusopr",
                    label: "操作员代码",
                    width: 140,
                },
                {
                    prop: "cusoprpwd",
                    label: "操作员密码",
                    width: 140,
                },
                {
                    prop: "usbKeyNo",
                    label: "usbKey编号",
                    width: 140,
                },
                {
                    prop: "remark",
                    label: "备注",
                    width: 140,
                },


                {
                    prop: "createBy",
                    label: "创建人",
                    width: 140,
                },
                {
                    prop: "createTime",
                    label: "创建时间",
                    width: 140,
                },


            ],

            url: {
                list: "/finance/connectedBankInfoList",
                add: "/shipping/invoiceApply/add",
                edit: "/shipping/invoiceApply/edit",
                getAmount:'/finance/bocAmount',

            },

            rules: {

            },
            addRules:{

            },

        };
    },
    computed: {},
    created() {
    },
    methods: {
        getAmount(){
            getAction(this.url.getAmount).then((res)=>{
                console.log("this is res")
                console.log(res)
                this.$refs.amountDialog.showAmount(res.result.result)
            })
        },

        connectedBankAddSubmit() {
            // console.log('进入invoiceInfoHandleSubmit')
            // console.log(this.addForm.customerId)
            this.$refs.addForm.validate((valid) => {
                // console.log('valid',valid)
                if (valid) {
                    //编辑
                    if (this.addForm.id) {
                        putAction(this.url.edit, this.addForm).then((res) => {
                            if (res.success) {
                                this.$message.success(res.message);
                                //关闭弹窗
                                this.invoiceAddVisible = false;
                                this.handleQuery();
                            }
                        });
                    } else {
                        let one= this.invoiceTitles.filter(i=>{
                            return i.id === this.addForm.invoiceTitleId;
                        })
                        // console.log(one)
                        // console.log(one[0].invTt)
                        this.addForm.invoiceTitle = one[0].invTt
                        // console.log('addForm',this.addForm)
                        postAction(this.url.add, this.addForm).then((res) => {

                            if (res.success) {
                                this.$message.success(res.message);
                                //关闭弹窗
                                this.invoiceAddVisible = false;
                                this.handleQuery();
                            }
                        });
                    }
                }
            });


        },



        handleSelectionChange(selection) {
            // 多选
            // console.log(selection);
            this.sumFee = [];
            let allCur = []
            selection.forEach(row => {
                allCur.push(row.realMoneyType)
            })
            let curNum = Array.from(new Set(allCur))
            //遍历每个币种，计算总和后放入sumFee中
            // curNum.forEach(cur => {
            //     let curRow = selection.filter(row => {
            //         return row.realMoneyType === cur
            //     })
            //     let total = Number(0);
            //     curRow.forEach(i => {
            //         total = total + Number(i.exchangeTotal)
            //     })
            //     this.sumFee.push({cur: cur, total: total.toFixed(3)})
            // })

            let arr = [];
            let nos = [];
            this.nameList = [];
            this.cnamelist = [];
            this.emailList = [];
            for (let i = 0; i < selection.length; i++) {
                arr.push(selection[i]["id"]);
                nos.push(selection[i]["orderNo"]);
                this.nameList.push(selection[i]["username"]);
                this.cnamelist.push(selection[i]["cname"]);
                this.emailList.push(selection[i]["email"]);
            }
            this.selectionRows = selection;
            this.selectedRowKeys = [...arr];
            this.selectedNos = [...nos];
        },

        handleReset() {
            this.queryForm = {};
            this.ipagination.currentPage = 1;
            this.handleQuery();
        },
        handleCheck() {
            this.queryForm.ids = []
            this.loading = true;

            let form = {...this.queryForm, ...this.filterForm};
            for (let item in form) {
                if (typeof form[item] === "string") {
                    form[item] = form[item].trim();
                    if (form[item] === "") {
                        form[item] = null;
                    }
                }
            }
            const {prop, order} = this.isorter;
            // 传入的参数
            const params = {
                ...form,
                column: prop,
                order: order.indexOf("asc") !== -1 ? "asc" : "desc",
            };
            //判断 是否要查询startTime

            if (!params.column) {
                if (this.queryForm.column === "startTime") {
                    this.$set(params, "column", "startTime");
                } else {
                    this.$set(params, "column", "createTime");
                }
                //提货中查询
                if (this.queryForm.column === "distributionTime") {
                    this.$set(params, "column", "distributionTime");
                }
            }
            if (this.ipagination) {
                params.pageNo = this.ipagination.currentPage;
                params.pageSize = this.ipagination.pageSize;
            }
            // console.log("查询对象", params);

            getAction(this.url.list, params)
                .then((res) => {
                    if (res.success) {
                        this.$refs.GTable && this.$refs.GTable.handleScrollTop && this.$refs.GTable.handleScrollTop();
                        const {records, total} = res.result;
                        this.tableData = records || res.result;

                        this.ipagination &&
                        (this.ipagination.total = total || res.result.length);
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        addOne(){
            this.addForm = Object.assign({}, '')
            this.usernameSearch = ''

            this.connectedBankTitle='添加对接银行'
            this.connectedBankAddVisible = true
            this.$nextTick(()=>{
                this.$refs.addForm.clearValidate()
            })
        },

        invoiceInfoHandleClose() {
            // this.$emit('update:visible', false)
            // this.$refs.form.resetFields()
            // this.invoiceInfoVisible = false;
        },
        invoiceAddHandleClose() {
            // this.$emit('update:visible', false)
            // this.$refs.form.resetFields()
            // this.invoiceAddVisible = false;
        },



        handleSizeChange(val) {
            this.ipagination.currentPage = 1
            this.ipagination.pageSize = val
            this.handleCheck()
        },
        handleCurrentChange(val) {
            this.ipagination.currentPage = val
            this.handleCheck()
        },

    },
};
</script>
<style lang="scss" scoped>
.flex_wait_box {
    display: flex;

    .select_width {
        width: 200px;
        margin: 0 10px;
    }
}
</style>
