<template>
  <div class="main-full-content">
    <el-form class="input_top" label-width="80px" size="medium" @submit.native.prevent>
      <el-card class="box-card">
        <el-row>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="查询类型" label-width="120px">
              <el-select v-model="queryForm.queryType"  @keyup.enter="handleSearch">
                <el-option value="1" label="海运费设置"></el-option>
                <el-option value="2" label="拖车费设置"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="起运地" label-width="120px">
              <f-select v-model="queryForm.departureId" :is-need="queryForm.departureId" :get-dict-url="url.getDepartDict" @keyup.enter="handleSearch"></f-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="起运港" label-width="120px">
              <f-select v-model="queryForm.startPortId" :is-need="queryForm.startPortId" :get-dict-url="url.getPortDict" @keyup.enter="handleSearch"></f-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="目的港" label-width="120px">
              <f-select v-model="queryForm.endPortId" :is-need="queryForm.endPortId" :get-dict-url="url.getPortDict" @keyup.enter="handleSearch"></f-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="柜型" label-width="120px">
              <f-select v-model="queryForm.cabinetTypeId" :is-need="queryForm.cabinetTypeId" :get-dict-url="url.getCabinetDict" @keyup.enter="handleSearch"></f-select>
            </el-form-item>
          </el-col>
          <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="10">
            <el-form-item label="有效时间">
              <el-date-picker
                  class="f-flex-1"
                  v-model="queryForm.startTimes"
                  type="daterange"
                  size="small"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :picker-options="editStopOptions"
                  value-format="yyyy-MM-dd"
                  format="yyyy-MM-dd"
                  unlink-panels
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="航线" label-width="120px">
              <el-input v-model.trim="queryForm.routeName" @keyup.enter="handleSearch"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="起运地拖车" label-width="120px">
              <f-select v-model="queryForm.isTrailer" :is-need="queryForm.isTrailer" :dict="'unit_whether'" @keyup.enter="handleSearch"></f-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="船司" label-width="120px">
              <el-input v-model.trim="queryForm.shipSi" @keyup.enter="handleSearch"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label-width="30px">
              <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询
              </el-button>
              <el-button plain @click="handleReset">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
    </el-form>
    <el-card class="box-card last_card">
      <div class="f-p-b-15">
        <el-button size="medium" type="primary" icon="el-icon-download"
                   @click="exportExcelPlus(url.downloadExcel,'整柜报价')"
                   v-if="buttonShowList['daochu']">导出</el-button>

      </div>
      <f-table v-loading="loading" ref="GTable" border size="medium" row-key="id" class="c-th-has-bgcolor"
        :columns="columns" :data="tableData" :show-operation="true" :cell-style="{ 'text-align': 'center' }"
        tableHeight="66vh" @selection-change="handleSelectionChange" @sort-change="handleTableChange">
        <template v-slot="scope">
          <template v-if="scope.column.property === 'additionalFeeStr'">
            <a style="color: #00a0e9" @click.prevent="handleEdit(scope.row,'additional','附加费详情')">{{ scope.row.additionalFeeStr }}</a>
          </template>
          <template v-else-if="scope.column.property === 'totalValueStr'">
            <a style="color: #00a0e9" @click.prevent="handleEdit(scope.row,'total','总价详情')">{{ scope.row.totalValueStr }}</a>
          </template>
        </template>
      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination v-bind="ipagination" layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange" @current-change="handleCurrentChange">
        </el-pagination>
      </el-row>
    </el-card>

    <edit-additional-fee-set-dialog :visible.sync="addVisible" :type="type" :row="row" :title="title" @ok="handleSearch"></edit-additional-fee-set-dialog>
  </div>
</template>

<script>
import { deleteAction, postAction } from "@/api/manage";
import QueryMixins from "@/mixins/QueryMixins.js";
import EditAdditionalFeeSetDialog from "./modules/EditAdditionalFeeSetDialog.vue";
export default {
  name: "FullContainerPrice",
  mixins: [QueryMixins],
  components: {
    EditAdditionalFeeSetDialog
  },
  data() {
    return {
      loading: false,
      addVisible: false,
      authVisible: false,
      type: '',
      title:'',
      isUsePost: true,
      row: {},
      options: [],
      id: "",
      columns: [
        {
          type: "selection",
          width: 50,
        },
        {
          prop: "lineName",
          label: "路径信息",
        },
        {
          prop: "routeName",
          label: "航线",
        },
        {
          prop: "shipSi",
          label: "船司",
        },
        {
          prop: "cabinetType",
          label: "柜型",
        },
        {
          prop: "trailerFeeStr",
          label: "起运地拖车费",
        },
        {
          prop: "oceanFeeStr",
          label: "海运费",
        },
        {
          prop: "additionalFeeStr",
          label: "附加费",
        },
        {
          prop: "totalValueStr",
          label: "总价",
        },
        {
          prop: "timeStr",
          label: "有效期",
        },

      ],
      url: {
        list: "/sys/ocean/fee/set/queryFullContainerPriceIPage",
        downloadExcel:'/file/export/excel/downloadFullContainerPriceExcel',
        getCabinetDict: '/sys/bd/cabinetType/getCabinetTypeDictList',
        getDepartDict:'/sys/bd/departure/getDepartureDictList',
        getPortDict:'/sys/bdPort/getBdPortDictList',
      },
    };
  },
  created(){
    this.$set(this.queryForm,'isTrailer','1');
    this.$set(this.queryForm,'queryType','1');
  },
  methods: {

    handleEdit(row, type,title) {
      this.type = type;
      this.row = row;
      this.title = title;
      this.addVisible = true;
    },
    handleChangeCanUse(row,isCanUse) {
      let params = JSON.parse(JSON.stringify(row));
      params.isCanUse = isCanUse;
      postAction(this.url.edit,params)
          .then(res=>{
            this.$message({
              type: "success",
              message: res.message,
            });
            this.handleQuery();
          })
    }
  },
  computed:{
    buttonShowList() {
      return {
        'daochu':this.$btnIsShow('channel/FullContainerPrice','0','导出整柜报价'),
      }
    }

  }
};
</script>

<style lang="scss" scoped></style>
