<template>
  <div>
    <el-card class="box-card">
      <el-form :model="isf" :rules="rules" ref="isf">
        <el-form-item
          label="制造商名称和地址 Manufacturer name and address:"
          :label-width="formLabelWidth"
        >
          <el-input
            type="textarea"
            v-model="isf.manNameAdd"
            maxlength="500"
            show-word-limit
            class="padding_right_area"
          ></el-input>
        </el-form-item>
        <el-form-item
                label="销售商名称和地址 Seller name and address:"
                :label-width="formLabelWidth"
        >
          <el-input
                  type="textarea"
                  v-model="isf.sellNameAdd"
                  maxlength="500"
                  show-word-limit
                  class="padding_right_area"
                  rows="5"
          ></el-input>
        </el-form-item>
        <el-form-item
                label="集装箱及装箱地点 Container stuffing location:"
                :label-width="formLabelWidth"
        >
          <el-input
                  type="textarea"
                  v-model="isf.conLocation"
                  maxlength="250"
                  show-word-limit
                  class="padding_right_area"
                  rows="5"
          ></el-input>
        </el-form-item>
        <el-form-item
                label="集装箱拼箱人名称和地址 Consolidator name and address:"
                :label-width="formLabelWidth"
        >
          <el-input
                  type="textarea"
                  v-model="isf.conNameAdd"
                  maxlength="500"
                  show-word-limit
                  class="padding_right_area"
                  rows="5"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="进口申报人/保税区的海运备案登记代码 EIN of importer of Record:"
          :label-width="formLabelWidth" >
        <!--  <el-input type="textarea" v-model="isf.importerEin" maxlength="250" show-word-limit class="padding_right_area"
          ></el-input>-->
          <el-select
                  v-model="isf.importerEin"
                  filterable
                  clearable
                  @click.native="initImporter"
                  style="width: 100%"
                  @change="changeImport"
          >
            <el-option
                    v-for="(item, index) in importerlist"
                    :value="item.value"
                    :label="item.label"
                    :key="index"
            ></el-option>
          </el-select>



        </el-form-item>
        <el-form-item
                label="购买方名称和地址 Buyer name and address:"
                :label-width="formLabelWidth"
        >
          <el-input
                  type="textarea"
                  v-model="isf.buyNameAdd"
                  maxlength="500"
                  show-word-limit
                  class="padding_right_area"
                  rows="7"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="收货人的海关备案登记代码 EIN of Consignee:"
          :label-width="formLabelWidth"
        >
          <el-input
            type="textarea"
            v-model="isf.consigneeEin"
            maxlength="250"
            show-word-limit
            class="padding_right_area"
          ></el-input>
        </el-form-item>
        <el-form-item
                label="货物送达名称和地址 Ship to name and address:"
                :label-width="formLabelWidth"
        >
          <el-input
                  type="textarea"
                  v-model="isf.shipNameAdd"
                  maxlength="500"
                  show-word-limit
                  class="padding_right_area"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注 Remark:" :label-width="formLabelWidth">
          <el-input
            type="textarea"
            v-model="isf.remark"
            maxlength="500"
            show-word-limit
            class="padding_right_area"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="f-p-b-15 flex_center" style="text-align: center">
        <el-button class="big_buttom" size="medium" type="primary" @click="saveisf"
          >保存ISF</el-button
        >
      </div>
    </el-card>


  </div>
</template>

<script>
import { getAction, postAction } from "../../../api/manage";


export default {
  name: "ISFandHblModule",
  props:{
    isShow:{
      type:Boolean,
      default:false,
    }
  },
  components: {},
  data() {
    return {
      isf: {},
      hbl: {},
      importerDict:[],
      importerlist: [], //进口商
      importerlistMap: {},
      formLabelWidth: "360px",
      formLabelWidth2: "120px",
      url: {
        getisf: "/operation/container/getOneISFByContainerId",
        saveisf: "/interface/ttOrder/addOrUpdateIsf",
        getSysImporterList: "/sys/importer/getAvailableImportedList", //获取系统进口商
      },
      //表单校验
      rules: {
        name: [{ required: true, message: "请输入文件名", trigger: "blur" }],
        type: [
          { required: true, message: "请选择文件类型", trigger: "change" },
        ],
      },
    };
  },
  created() {
    this.$set(this.isf, "orderNo", this.$route.query.orderNo);
    this.$set(this.hbl, "orderNo", this.$route.query.orderNo);
    this.initIsf();
    this.initImporter();
  },
  mounted() {},
  methods: {
    initIsf() {
      getAction(this.url.getisf, {containerId : this.$route.query.id || ''}).then(
              (res) => {
                if (res.success) {
                  if (res.result.id) {
                    console.log("res.result",res.result);
                    this.isf = res.result;
                  }
                }
              }
      );
    },


    saveisf() {
      // this.$set(this.isf, "orderNo", this.$route.query.orderNo || '');
      this.$set(this.isf, "containerId", this.$route.query.id);
      postAction(this.url.saveisf, this.isf).then((res) => {
        this.$message.success(res.message);
        this.initIsf();
      });
    },
    initImporter() {
      this.importerlist=[];
      this.importerDict = []
      getAction(this.url.getSysImporterList).then((res) => {
                if (res.success) {
                  let list = res.result;
                  for (var i = 0; i < list.length; i++) {
                    let value = list[i].id;
                    // let label = list[i].name + '-' +list[i].tax;
                    let label = list[i].name + "(" + list[i].country + ")" + list[i].tax
                    this.importerlist.push({value, label});
                    this.importerDict.push({value,label: list[i].name})
                  }
                  this.importerlistMap={};
                  this.importerlistMap = this.listToMap(res.result, "id");
                } else {
                  this.$message.warning("获取大森林进口商列表失败");
                }
              })
    },
    changeImport(val){
      console.log("val",val)
      if (this.importerlistMap[val]) {
        this.isf.buyNameAdd = this.importerlistMap[val]["name"] +'\n'+
                 this.importerlistMap[val]["vat"]+'\n'+this.importerlistMap[val]["tax"]+'\n'+this.importerlistMap[val]["address"];
      }
      this.$set(this.isf, "importerEin", this.importerlistMap[val]["name"]);
      this.$set(this.isf, "importerId", val);
    },
    //将集合转为map
    listToMap(list, name) {
      var map = {};
      for (var index in list) {
        map[list[index][name]] = list[index];
      }
      return map;
    }
  },
  watch:{
    'isf.importerEin':function (newName,oldName){
      this.importerDict.forEach(item=>{
        if(item.label == newName){
          this.$set(this.isf, "importerId", item.value);
        }
      })
    },

  },
};
</script>

<style lang="scss" scoped>
.big_buttom {
  padding: 10px 20px;
}
</style>
