<!--快递间隔半年下单-->
<template>
    <div class="main-full-content">
             <el-form class="multiline_select no_margin" ref="form" :model="queryForm" label-width="80px" size="medium">
            <el-card class="box-card">
                <el-row class="inputs_row">
                    <el-col  :span="6">
                        <el-form-item label="销售组别" label-width="100px">
                              <f-select
                    v-model="queryForm.deptIdList"
                    :dict="'sys_depart'"
                    :isNeed="queryForm.deptIdList"
                    :multiple="true"
                >
                </f-select>
                        </el-form-item>
                    </el-col>
                       <el-button
                                    type="primary"
                                    icon="el-icon-search"
                                    @click="handleSearch"
                                    style="margin-left: 15px;"
                            >查询
                            </el-button
                            >
                            <el-button plain @click="handleReset">重置</el-button>
                </el-row>
            </el-card>
        </el-form>
        <el-card class="box-card last_card">
            <div class="f-p-b-15">
                <el-button type="primary" size="medium" icon="el-icon-plus" @click="exportExcelPlus(url.downloadExcelUrl,'快递间隔半年下单报表')">导出</el-button>

            </div>
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="73vh"
                    :isRecordTableScroll="true"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange"
                    @queryChange="handleTableQueryChange"
            >
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'orderNo'">
                        <a style="color: #00a0e9" @click.prevent="$intoDocPage(scope.row.orderNo)"
                        >{{ scope.row.orderNo }}</a>
                    </template>
                    <template v-else-if="scope.column.property === 'customer'">
                        <a type="text" @click.prevent="$intoCustomerEditPage(scope.row.cusId)" style="color:#57a3f3;">{{scope.row.customer}}</a>
                    </template>
                     <template v-else-if="scope.column.property === 'deptId'">
                       <span>{{parseDict(scope.row.deptId,'sys_depart')}}</span>
                    </template>
                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                >
                </el-pagination>
            </el-row>
        </el-card>
    </div>
</template>

<script>
    import {deleteAction, postAction, putAction,getAction} from "@/api/manage";
    import QueryMixins from "../../../mixins/QueryMixins";
    import { setUpRouteUtil } from '@/utils/util';

    export default {
        name: "Potential",
        mixins: [QueryMixins],
        data() {
            return {
                dictCodes: ['is_default','sys_depart'],
                ids: [],
                row: {},
                form: {},
                formLabelWidth: "100px",
                columns: [
                    {
                        prop: "customer",
                        label: "客户名",
                        minWidth: 200,
                    },
                    {
                        prop: "orderNo",
                        label: "订单号",
                        minWidth: 200,
                    },
                    {
                        prop: "createTime",
                        label: "下单时间",
                        minWidth: 200,
                    },

                    {
                        prop: "salesman",
                        label: "下单时业务员",
                        minWidth: 200,
                    },

                    {
                        prop: "salesmanNow",
                        label: "客户当前业务员",
                        minWidth: 200,
                    },
                     {
                        prop: "deptId",
                        label: "销售组别",
                        minWidth: 200,
                    },
                    {
                        prop: "lastCreateTime",
                        label: "上一次下单时间",
                        minWidth: 200,
                    },
                    {
                        prop: "lastOrderNo",
                        label: "上一次下单单号",
                        minWidth: 200,
                    },
                ],
                url: {
                    list: "/order/kdOrderReport/kdBanNianList",
                    //优化的导出
                    downloadExcelUrl: "/file/export/excel/downloadExpressHalfYearSubmitOrderAgainExcel",
                },
                isUsePost:true
                
            };
        },
        methods: {

            intoDocpage(orderNo) {
                // 对于路由不在左侧菜单中的选中菜单处理
                setUpRouteUtil(this.$route.path, '/kdorder/Edit');
                this.$router.push('/kdorder/Edit?orderNo=' + orderNo);
            },
            handleReset() {
                this.queryForm = {};
                this.filterForm = {};
                this.ipagination.currentPage = 1;
                this.handleQuery();
            },
        },
        created() {
        },
    };
</script>

<style lang="scss" scoped>
</style>
