<template>
    <el-dialog
        :title="row.id ? '编辑入仓单设置（入仓单显示过磅提示条件）' : '新增入仓单设置（入仓单显示过磅提示条件）'"
        :visible="visible"
        width="560px"
        custom-class="c-custom-dialog"
        :close-on-click-modal="false"
        :before-close="handleClose">
        <el-form :model="form" :rules="rules" ref="form" label-width="80px" size="medium">
            <el-row>
                <el-col :span="24">
                    <el-form-item prop="conditionName" label="条件名称">
                        <el-input v-model="form.conditionName" maxlength="20" show-word-limit></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item prop="conditionType" label="条件类型">
                        <el-select v-model="form.conditionType">
                            <el-option label="包税" value="0"></el-option>
                            <el-option label="渠道" value="1"></el-option>
                            <el-option label="揽货方式" value="2"></el-option>
                            <el-option label="国家、预报重量和方数的比值" value="3"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="24" v-if="form.conditionType==='1'">
                    <el-form-item prop="description" label="渠道">
                        <f-select
                            v-model="form.relationId"
                            :isNeed="form.relationId"
                            :dict="'channel_set'"
                        />
                    </el-form-item>
                </el-col>
                <el-col :span="24" v-if="form.conditionType==='2'">
                    <el-form-item prop="description" label="揽货方式">
                        <f-select
                            v-model="form.relationId"
                            :isNeed="form.relationId"
                            :dict="'fba_solicitation'"
                        />
                    </el-form-item>
                </el-col>
                <el-col :span="24" v-if="form.conditionType==='3'">
                    <el-form-item prop="country" label="国家二字码">
                        <el-input v-model="form.country" rows="5"  maxlength="2" show-word-limit></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="24" v-if="form.conditionType==='3'">
                    <el-form-item prop="ratio" label="预报重量和方数的比值">
                        <el-input v-model="form.ratio" rows="5"  maxlength="10" show-word-limit></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item prop="status" label="是否启用">
                        <el-select v-model="form.status">
                            <el-option label="启用" value="0"></el-option>
                            <el-option label="不启用" value="1"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item prop="description" label="备注">
                        <el-input v-model="form.remark" rows="5"  maxlength="50" show-word-limit></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <span slot="footer">
            <el-button size="medium" @click="handleClose">取消</el-button>
            <el-button type="primary" size="medium" @click="handleSubmit">确定</el-button>
        </span>
    </el-dialog>
</template>

<script>
import { httpAction } from '@/api/manage'
import {zhengshuxiaoshuPartten} from "@/utils/pattern";
export default {
    name: 'AddRoleDialog',
    props: {
        visible: {
            type: Boolean
        },
        row: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            form: {},
            rules: {
                conditionName: [
                    { required: true, message: '请输入条件名称', trigger: 'change' },
                ],
                conditionType: [
                    { required: true, message: '请选择条件类型', trigger: 'change' },
                ],
                ratio: [
                    {required: true, message: "请输入预防重量和方数的比值", trigger: "change"},
                    {
                        pattern: zhengshuxiaoshuPartten,
                        message: "请输入正整数或最多三位小数",
                        trigger: "change",
                    },
                ],
                country: [
                    { required: true, message: '请输入国家英文二字码', trigger: 'change' },
                    {pattern: /[A-Z]{2}/, message: '请重新输入两位大写英文字母的国家二字码', trigger: 'change'}
                ],
                status: [
                    { required: true, message: '请选择是否启用', trigger: 'change' },
                ],
            },
            url: {
                add: '/shipping/weightMarkCondition/add',
                edit: '/shipping/weightMarkCondition/edit',
            }
        }
    },
    watch: {
        visible(val) {
            if(val) {
               this.row.id && (this.form = { ...this.row })
            }
        }
    },
    methods: {
        handleSubmit() {
            let url = this.url.add
            let method = 'POST'
            if(this.row.id) {
                url = this.url.edit
                method = 'PUT'
            }
            this.$refs.form.validate((valid) => {
                if (valid) {
                    httpAction(url, method, this.form).then((res)=>{
                        this.$message({
                            type: 'success',
                            message: res.message
                        })
                        this.handleClose()
                        this.$emit('ok')
                    })
                }
            })
        },
        handleClose() {
            this.$emit('update:visible', false)
            this.form = {}
            this.$refs.form.resetFields()
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
