<template>
    <div>
        <el-form ref="form" :model="queryForm" label-width="80px" size="medium" @submit.native.prevent>
            <el-card class="box-card">
                <el-row class="inputs_row">
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="订单号">
                            <el-input v-model="queryForm.orderNo" @keyup.enter.native="handleSearch" type="textarea"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="交货仓库">
                            <f-select
                                    :dict="'delivery_point'"
                                    v-model="queryForm.deliveryPoint"
                                    :isNeed="queryForm.deliveryPoint"
                            ></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="拼柜仓库">
                            <f-select
                                    :dict="'lcl_place'"
                                    v-model="queryForm.lclPlace"
                                    :isNeed="queryForm.lclPlace"
                            ></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="货物所在仓">
                            <f-select
                                    :dict="'delivery_point'"
                                    v-model="queryForm.suozaiCangkuId"
                                    :isNeed="queryForm.suozaiCangkuId"
                            ></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="库位">
                            <f-select
                                    :dict="'warehouse_location_type'"
                                    v-model="queryForm.locationCodes"
                                    :isNeed="queryForm.locationCodes"
                            ></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="国家">
                            <f-select v-model="queryForm.shipmentCountry"
                                      :dict="'bd_country'"
                                      :isNeed="queryForm.shipmentCountry"></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="渠道名称">
                            <f-select :dict="'transportStatus'"
                                      v-model="queryForm.channelId"
                                      :isNeed="queryForm.channelId"
                            ></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="航线名称" label-width="120px">
                            <f-select :dict="'fba_solicitation'" v-model="queryForm.solicitation"
                                      :is-need="queryForm.solicitation"></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="FBA CODE" label-width="90px">
                            <el-input v-model="queryForm.fbaCode" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="ZIP" label-width="90px">
                            <el-input v-model="queryForm.zip" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="排柜操作">
                            <f-select :dict="'sys_user_id'"
                                      v-model="queryForm.operatorId"
                                      :isNeed="queryForm.operatorId"
                            ></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="客服">
                            <f-select :dict="'sys_user_id'"
                                      v-model="queryForm.docId"
                                      :isNeed="queryForm.docId"
                            ></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col  :xs="24" :sm="12" :md="12" :lg="8">
                        <el-form-item label="入仓时间">
                            <div class="f-flex">
                                <el-date-picker
                                        class="f-flex-1"
                                        v-model="queryForm.cargoArrivedStart"
                                        type="datetime"
                                        size="small"
                                        placeholder="开始时间"
                                        default-time="00:00:00"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                >
                                </el-date-picker>
                                <span class="f-p-h-2">~</span>
                                <el-date-picker
                                        class="f-flex-1"
                                        v-model="queryForm.cargoArrivedEnd"
                                        type="datetime"
                                        size="small"
                                        placeholder="结束时间"
                                        default-time="23:59:59"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                >
                                </el-date-picker>
                            </div>
                        </el-form-item>
                    </el-col>

                    <el-col  :xs="24" :sm="12" :md="12" :lg="8">
                        <el-form-item label="材积录入时间" label-width="100px">
                            <div class="f-flex">
                                <el-date-picker
                                        class="f-flex-1"
                                        v-model="queryForm.volumeTimeStart"
                                        type="datetime"
                                        size="small"
                                        placeholder="开始时间"
                                        default-time="00:00:00"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                >
                                </el-date-picker>
                                <span class="f-p-h-2">~</span>
                                <el-date-picker
                                        class="f-flex-1"
                                        v-model="queryForm.volumeTimeEnd"
                                        type="datetime"
                                        size="small"
                                        placeholder="结束时间"
                                        default-time="23:59:59"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                >
                                </el-date-picker>
                            </div>
                        </el-form-item>
                    </el-col>

                    <el-col  :xs="24" :sm="12" :md="12" :lg="8">
                        <el-form-item label="发票确认时间" label-width="100px">
                            <div class="f-flex">
                                <el-date-picker
                                        class="f-flex-1"
                                        v-model="queryForm.invoiceConfirmTimeStart"
                                        type="datetime"
                                        size="small"
                                        placeholder="开始时间"
                                        default-time="00:00:00"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                >
                                </el-date-picker>
                                <span class="f-p-h-2">~</span>
                                <el-date-picker
                                        class="f-flex-1"
                                        v-model="queryForm.invoiceConfirmTimeEnd"
                                        type="datetime"
                                        size="small"
                                        placeholder="结束时间"
                                        default-time="23:59:59"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                >
                                </el-date-picker>
                            </div>
                        </el-form-item>
                    </el-col>

                    <el-col  :xs="24" :sm="12" :md="12" :lg="8">
                        <el-form-item label="报关资料上传时间" label-width="120px">
                            <div class="f-flex">
                                <el-date-picker
                                        class="f-flex-1"
                                        v-model="queryForm.declarationTimeStart"
                                        type="datetime"
                                        size="small"
                                        placeholder="开始时间"
                                        default-time="00:00:00"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                >
                                </el-date-picker>
                                <span class="f-p-h-2">~</span>
                                <el-date-picker
                                        class="f-flex-1"
                                        v-model="queryForm.declarationTimeEnd"
                                        type="datetime"
                                        size="small"
                                        placeholder="结束时间"
                                        default-time="23:59:59"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                >
                                </el-date-picker>
                            </div>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="是否问题件"  prop="isProblem" label-width="100px">
                            <f-select :is-need="queryForm.isProblem"
                                      v-model="queryForm.isProblem"
                                      :fatherVal="queryForm.isProblem"
                                      placeholder="请选择" filterable clearable :dict="'is_default'">
                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="3">
                        <el-form-item label-width="30px" style="width: 400px">
                            <el-button size="medium" type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
                            <!-- <el-divider direction="vertical"></el-divider> -->
                            <el-button size="medium" plain @click="handleReset">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>

        </el-form>
      <el-card class="box-card">
          <div class="f-p-b-10">
              <el-button size="medium" type="primary" icon="el-icon-download"
                         @click="exportExcelPlus(url.exportTT,'导出报表')" :loading="exportLoading" v-if="buttonShowList.dc">导出报表</el-button>
              <el-button type="primary" @click="tongjiCmb">统计方数</el-button>
              <el-button type="primary" @click="noLocationOrder">无库位订单</el-button>
              <el-button type="primary" @click="ploblemOrder">异常件订单</el-button>
              <el-button type="primary" @click="warehouseProblem">货物所在仓异常订单</el-button>

          </div>
          <div class="c-alert f-m-b-10">
              <span class="f-m-l-20" style="font-weight: bold">统计&nbsp;<span style="font-weight: 500">{{this.calculateTotal}}</span>&nbsp;单&nbsp;</span>
              <span>预计体积：</span>
              <span style="color: red">{{tongji.cmb?tongji.cmb:0}}</span>
              <span>CBM</span>
              <span class="f-m-l-15">实际体积：</span>
              <span style="color: red">{{tongji.realCmb?tongji.realCmb:0}}</span> <span>CBM</span>
          </div>
              <f-table
            v-loading="loading"
            ref="GTable"
            border
            size="medium"
            row-key="orderNo"
            class="c-th-has-bgcolor"
            :columns="columns"
            :data="tableData"
            :isorter="isorter"
            :show-operation="true"
            :cell-style="{ 'text-align': 'center' }"
            tableHeight="65vh"
            :isRecordTableScroll="true"
            @selection-change="handleSelectionChange"
            @sort-change="handleTableChange"
            @queryChange="handleTableQueryChange"

        >
          <template v-slot="scope">
            <!--                    <template v-if="scope.column.property === 'action'">-->
            <!--                        <el-button type="text" @click="WaitingCabinet(scope.row)"-->
            <!--                        >排柜-->
            <!--                        </el-button-->
            <!--                        >-->
            <!--                    </template>-->
            <template v-if="scope.column.property === 'orderNo'">
              <a
                  style="color: #00a0e9"
                  @click.prevent="$intoDocPage(scope.row.orderNo)"
              >{{ scope.row.orderNo }}</a>
            </template>
            <template v-else-if="scope.column.property === 'declareType'">
              <span>{{ parseDict(scope.row.declareType, "os_declare_type") }}</span>
            </template>
              <template v-else-if="scope.column.property === 'lclPlace'">
                  <span>{{parseDict(scope.row.lclPlace,'lcl_place')}}</span>
              </template>
              <template v-else-if="scope.column.property === 'deliveryPoint'">
                  <span>{{parseDict(scope.row.deliveryPoint,'delivery_point')}}</span>
              </template>
              <template v-else-if="scope.column.property === 'suozaiCangkuId'">
                  <span>{{parseDict(scope.row.suozaiCangkuId,'delivery_point')}}</span>
              </template>
            <template v-else-if="scope.column.property === 'invoiceStatus'">
            <span>{{
                parseDict(scope.row.invoiceStatus, "invoice_status")
              }}</span>
            </template>
            <template v-else-if="scope.column.property === 'transportStatus'">
            <span>{{
                parseDict(scope.row.transportStatus, "transportStatus")
              }}</span>
            </template>
            <template v-else-if="scope.column.property === 'isDeclaration'">
              <span v-if="scope.row.isDeclaration === '0'">否</span>
              <span v-if="scope.row.isDeclaration === '1'">是</span>
            </template>
            <template v-else-if="scope.column.property === 'isProblem'">
              <span v-if="scope.row.isProblem === '0'">否</span>
              <span v-if="scope.row.isProblem === '1'">是</span>
            </template>
          </template>
        </f-table>
        <el-row class="f-text-right f-p-v-8">
          <el-pagination
              v-bind="ipagination"
              layout="total, sizes, prev, pager, next, jumper"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
          >
          </el-pagination>
        </el-row>
      </el-card>
    </div>
</template>

<script>
    import QueryMixins from "@/mixins/QueryMixins.js";
    import { deleteAction, postAction, putAction,getAction } from "@/api/manage";


    export default {
        name: "ContactModule",
        mixins: [QueryMixins],
        components: {},
        data() {
            return {
                calculateTotal:0,
                tongji: {},
                queryForm:{
                    orderNo:null,
                    deliveryPoint:null
                },
                // rulesForm: {
                //     priceExplainName: [
                //         {required: true, message: '请填写排柜', trigger: 'change'},
                //     ],
                // },

                rules: {
                    airRoute: [
                        {required: true, message: "请选择航线", trigger: "change"},
                    ],
                    lclPlaceId: [
                        {required: true, message: "请选择拼柜仓库", trigger: "change"},
                    ],
                    bdPortId: [
                        {required: true, message: "请选择港口", trigger: "change"},
                    ],
                    cabinetCategory: [
                        {required: true, message: "请选择专线/拆柜", trigger: "change"},
                    ],
                    week: [{required: true, message: "请填写周数", trigger: "change"}],
                    size: [{required: true, message: "请选择柜型", trigger: "change"}],
                    weekStatus: [
                        {required: true, message: "请选择柜子用途", trigger: "change"},
                    ],
                    isVirtualCabinet: [
                        {required: true, message: "请选择是否虚拟柜", trigger: "change"},
                    ],
                },
                //联系人类型0公司负责人 1报价对接人 2操作联系人 3财务联系人',
                dictCodes: [
                    "os_declare_type",
                    "invoice_status",
                    "delivery_point",
                    "lcl_place"

                ],
                columns: [
                    {
                        prop: "orderNo",
                        label: "订单号",
                        minWidth: 100,
                        // sortable: "orderNo",
                        fixed: 'left'
                    },
                    {
                        prop: "week",
                        label: "周期",
                        minWidth: 100,
                        // sortable: "orderNo",
                        fixed: 'left'
                    },
                    {
                        prop: "deliveryPoint",
                        label: "交货仓库",
                        minWidth: 150,
                        // sortable: "transportStatus",
                    },
                    {
                        prop: "lclPlace",
                        label: "拼柜仓库",
                        minWidth: 150,
                        // sortable: "transportStatus",
                    },
                    {
                        prop: "suozaiCangkuId",
                        label: "货物所在仓",
                        minWidth: 150,
                        // sortable: "transportStatus",
                    },
                    {
                        prop: "location",
                        label: "库位",
                        minWidth: 150,
                        // sortable: "transportStatus",
                    },
                    {
                        prop: "shipmentCountry",
                        label: "国家",
                        minWidth: 150,
                        // sortable: "transportStatus",
                    },
                    {
                        prop: "channelName",
                        label: "渠道名称",
                      /*  headerType: "select",
                        dict: "customer",*/
                        minWidth: 150,
                        // sortable: "username",
                        fixed: 'left'
                    },
                    {
                        prop: "solicitationText",
                        label: "航次名称",
                        // headerType: "string",
                        minWidth: 100,
                        // sortable: "custom",
                    },
                    {
                        prop: "fbaCode",
                        label: "fbaCode",
                        minWidth: 80,
                        // sortable: "grade",
                    },
                    {
                        prop: "zip",
                        label: "邮编",
                        minWidth: 100,
                        // sortable: "custom",
                    },
                    {
                        prop: "ctn",
                        label: "预报件数",
                        minWidth: 50,
                        // sortable: "custom",
                    },
                    {
                        prop: "cmb",
                        label: "预报体积",
                        minWidth: 50,
                        // sortable: "custom",
                    },
                    {
                        prop: "realCtn",
                        label: "实际件数",
                        minWidth: 50,
                        // sortable: "shipmentCode",
                    },
                    {
                        prop: "realCmb",
                        label: "实体",
                        minWidth: 50,
                        // sortable: "shipmentZip",
                    },
                    {
                        prop: "operator",
                        label: "排柜操作",
                        // headerType: "rang",
                        minWidth: 100,
                        // sortable: "realCtn",
                    },
                    {
                        prop: "doc",
                        label: "客服",
                        headerType: "rang",
                        minWidth: 100,
                        // sortable: "ctn",
                    },
                    {
                        prop: "cargoArrivedTime",
                        label: "到仓时间",
                        minWidth: 100,
                        // sortable: "kg",
                    },
                    {
                        prop: "volumeTime",
                        label: "材积录入时间",
                        minWidth: 100,
                        // sortable: "cmb",
                    },
                    {
                        prop: "invoiceConfirmTime",
                        label: "发票确认时间",
                        // headerType: "rang",
                        minWidth: 100,
                        // sortable: "realCmb",
                    },
                    {
                        prop: "declarationTime",
                        label: "报关资料上传时间",
                        headerType: "rang",
                        minWidth: 100,
                        // sortable: "custom",
                    },
                    {
                        prop: "isDeclaration",
                        label: "报关资料是否上传",
                        headerType: "rang",
                        minWidth: 100,
                        // sortable: "custom",
                    },
                    {
                        prop: "isProblem",
                        label: "是否问题件",
                        headerType: "rang",
                        minWidth: 100,
                        // sortable: "custom",
                    },

                    {
                        prop: "problemCreateTime",
                        label: "问题件创建时间",
                        minWidth: 100,
                        // sortable: "custom",
                    },
                ],
                url: {
                    list: "/interface/warehouse/getInWarehouseIPage",
                    caculateCmb: "/interface/warehouse/caculateCmb",
                    exportTT: "/file/export/excel/downloadWarehouseOrderExcel",


                },
              isUsePost:true,
            };
        },
        created() {
        },
        methods: {
            //货物所在仓异常
            warehouseProblem(){
                this.queryForm.warehouseProblem = '1'
                this.handleQuery()
            },
            //异常件订单
            ploblemOrder(){
                this.queryForm.problemOrder = '1'
                this.handleQuery()
            },
            // 无库位订单
            noLocationOrder(){
                this.queryForm.noLocation = '1'
                this.handleQuery()
            },
            tongjiCmb() {
                this.calculateTotal = this.ipagination.total
                for (let item in this.queryForm) {
                    if (typeof this.queryForm[item] === "string") {
                        this.queryForm[item] = this.queryForm[item].trim();
                        if (this.queryForm[item] === "") {
                            this.queryForm[item] = null;
                        }
                    }
                }
                postAction(this.url.caculateCmb, this.queryForm).then((res) => {
                    if (res.result) {
                        this.tongji = res.result;
                    } else {
                        this.tongji = { cmb: 0, realCmb: 0 };
                    }
                });
            },
            handleReset() {
                this.filterForm = {};
                this.queryForm = {};
                this.ipagination.currentPage = 1;
                if (this.$refs['GTable']){
                    this.$refs['GTable'].handleReset();
                }
                this.handleQuery();
            },
        },
      computed:{
        buttonShowList() {
          return {
            'dc':this.$btnIsShow('docpage/AllOrderModule','0','导出报表'),
          }
        }
      }
    };
</script>

<style lang="scss" scoped>
    .elrow {
        padding-left: 60px;
    }

    .elrow-a {
        padding-left: 15px;
    }
</style>
