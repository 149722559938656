import {
    getAction,
    postAction,
    putAction,
    deleteAction
} from "../api/manage";

export default {
    data() {
        return {
            queryForm: {},
            loading: false,
            ipagination: {
                currentPage: 1,
                pageSize: 10,
                pageSizes: [10, 20, 50, 100],
                total: 0
            },
            isorter: {
                prop: '',
                order: ''
            },
        }
    },
    created: function () {
        //把所有基础数据清空

    },
    mounted() {
    },
    methods: {
        handleSizeChange(val) {
            this.ipagination.currentPage = 1
            this.ipagination.pageSize = val
            this.handleQuery()
        },
        handleCurrentChange(val) {
            this.ipagination.currentPage = val
            this.handleQuery()
        },
        handleReset() {
            this.queryForm = {}
            this.ipagination.currentPage = 1
            this.handleQuery()
        },
        handleResetFilter() { // 清空表格过滤器
            this.$refs.GTable.handlePureClear()
            this.filterForm = {}
        },
        handleResetAll() {
            this.handleResetFilter()
            this.handleReset()
        },
        handleSearch() {
            this.ipagination.currentPage = 1
            this.handleQuery()
        },
    }
}
