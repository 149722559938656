<template>
    <div class="main-full-content">
        <el-form ref="form" class="multiline_select" :model="queryForm" label-width="86px" size="medium">
            <el-card class="box-card">
                <el-row class="inputs_row">
                     <el-col :xs="24" :sm="12" :md="12" :lg="6" v-if="getIsHegui">
                        <el-form-item label="客户名称">
                            <el-input v-model="queryForm.username" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="产品名称">
                            <el-input v-model="queryForm.cname" placeholder="中文/英文" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="产品编码">
                            <el-input v-model="queryForm.hscode" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="国家">
                            <f-select
                                    clearable
                                    filterable
                                    class="f-full-width"
                                    v-model="queryForm.country"
                                    :isNeed="queryForm.country"
                                    :dict="'bd_country'"
                                    placeholder="请选择">
                            </f-select>
                        </el-form-item>
                    </el-col>
<!--                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="6">-->
<!--                        <el-form-item label="是否备案">-->
<!--                            <f-select-->
<!--                                    :dict="'is_record'"-->
<!--                                    class="f-full-width"-->
<!--                                    v-model="queryForm.tag"-->
<!--                                    :isNeed="queryForm.tag"-->
<!--                                    placeholder="请选择">-->
<!--                            </f-select>-->
<!--                        </el-form-item>-->
<!--                    </el-col>-->
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="是否在301">
                            <f-select
                                    :dict="'is_default'"
                                    v-model="queryForm.section301"
                                    :isNeed="queryForm.section301"
                                    placeholder="请选择">
                            </f-select>
                        </el-form-item>
                    </el-col>
                    <!--                <el-col :xs="24" :sm="12" :md="12" :lg="6">-->
                    <!--                    <el-form-item label="是否敏感">-->
                    <!--                        <f-select-->
                    <!--                                :dict="isSenseDict"-->
                    <!--                                v-model="queryForm.sense"-->
                    <!--                                placeholder="请选择">-->
                    <!--                        </f-select>-->
                    <!--                    </el-form-item>-->
                    <!--                </el-col>-->
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label-width="20px">
                            <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
                            <el-button plain @click="handleReset">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>

        </el-form>
        <el-card class="box-card last_card">
            <div class="f-p-b-15">
                <el-button type="primary" size="medium" icon="el-icon-download" @click="handleExportXls('所有产品')"

                           v-if="buttonShowList.dc"

                >导出
                </el-button>
                <!--            <el-button type="primary" size="small" @click="handleBatchApproval" :disabled="selectionRows.length === 0 ">批量添加至敏感库</el-button>-->
                <!--            <el-button type="primary" size="small" @click="handleBatchTuihui" :disabled="selectionRows.length === 0 ">批量退回至正常库</el-button>-->
                <!--            <el-button type="primary" size="small" @click="handleImport">导入清关要求</el-button>-->
            </div>
            <div class="c-alert f-m-b-10">
                已选择 <span class="stress">{{this.selectedRowKeys.length}}</span> 项
                <span class="stress f-m-l-12 f-cursor-pointer" @click="handleClearSelection">清空</span>
            </div>
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="getIsHegui() ?columns:columns2"
                    :data="tableData"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="57vh"
                    :isRecordTableScroll="true"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange">
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'action'">
                        <el-button type="text" @click="handleEdit(scope.row)"

                                   v-if="buttonShowList.edit"
                        >编辑</el-button>
                        <el-button type="text" @click="handleDel(scope.row)"

                                   v-if="buttonShowList.del"
                        >删除</el-button>
                        <el-button type="text" @click="handleApproval(scope.row)"

                                   v-if="scope.row.sense ==='0' && buttonShowList.addM"
                                   >添加至敏感库
                        </el-button>
                        <el-button type="text" @click="handleTuihuiBatch(scope.row)"

                                   v-if="scope.row.sense ==='1' && buttonShowList.backN"
                        >
                            退回至正常库
                        </el-button>
                    </template>
                    <template v-else-if="scope.column.property === 'tag'">
                      <span v-if="scope.row.tag === '0'">未备案</span>
                      <span v-else>已备案</span>
                    </template>
                    <template v-else-if="scope.column.property === 'sense'">
                        <span v-if="scope.row.sense === '0'">否</span>
                        <span v-else>否</span>
                    </template>
                    <template v-else-if="scope.column.property === 'section301'">
                      <span v-if="scope.row.section301 === '0'">否</span>
                      <span v-else>否</span>
                    </template>
                    <template v-else-if="scope.column.property === 'taxRate'">
                        <span>{{showRate(scope.row.taxRate)}}</span>
                    </template>
                  <template v-else-if="scope.column.property === 'brandType'">
                    <span>{{ parseDict(scope.row.brandType, "brand_type") }}</span>
                  </template>
                  <template v-else-if="scope.column.property === 'exportBenefits'">
                    <span>{{ parseDict(scope.row.exportBenefits, "export_benefits") }}</span>
                  </template>

                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange">
                </el-pagination>
            </el-row>
        </el-card>


        <product-dialog :visible.sync="visible" :row="row" @ok="handleSearch"></product-dialog>
    </div>
</template>

<script>
    import {deleteAction, putAction} from '@/api/manage';
    import QueryMixins from "../../mixins/QueryMixins";
    import ProductDialog from './modules/ProductDialog';
    import { setUpRouteUtil } from '@/utils/util';

    export default {
        name: 'NormalProducts',
        mixins: [QueryMixins],
        components: {
            ProductDialog
        },
        data() {
            return {
                dictCodes: ['is_record', 'is_sense', 'is_default', 'brand_type','export_benefits'],
                visible: false,
                row: {},
                sens301list: [
                    {"value": "1", "label": "是"},
                    {"value": "0", "label": "否"},
                ],
                taglist: [
                    {"value": "1", "label": "是"},
                    {"value": "0", "label": "否"},
                ],
              columns: [
                {
                  type: "selection",
                  width: 33,
                },
                {
                  prop: "action",
                  label: "操作",
                },
                {
                    prop: "username",
                    label: "客户名称",
                    sortable: "custom",
                },
                {
                  prop: "cname",
                  label: "中文名",
                  sortable: "custom",
                },
                {
                  prop: "ename",
                  label: "英文名",
                  sortable: "custom",
                },

                {
                  prop: "country",
                  label: "国家",
                  sortable: "custom",
                },
                {
                  prop: "hscode",
                  label: "海关编码",
                  sortable: "custom",
                },
                  {
                      prop: "addtionalCode",
                      label: "301编码",
                  },
                {
                  prop: "taxRate",
                  label: "税率",
                  sortable: "custom",
                },

                {
                  prop: "materialRate",
                  label: "材质比例",
                },

                {
                  prop: "demand",
                  label: "清关要求",
                },
                {
                  prop: "material",
                  label: "材质",
                  sortable: "custom",
                },
                {
                  prop: "purpose",
                  label: "用途",
                },
                {
                  prop: "remark",
                  label: "备注",
                  sortable: "custom",
                },
                {
                  prop: "maker",
                  label: "厂家",
                  sortable: "custom",
                },
                {
                  prop: "makerAdd",
                  label: "厂家地址",
                },
                {
                  prop: "brand",
                  label: "品牌",
                },
                {
                  prop: "model",
                  label: "产品型号",
                },
                {
                  prop: "size",
                  label: "产品尺寸",
                  sortable: "custom",
                },
                {
                  prop: "brand",
                  label: "品牌",
                  sortable: "custom",
                },
                {
                  prop: "brandType",
                  label: "品牌类型",
                },
                {
                  prop: "exportBenefits",
                  label: "出口享惠情况",
                },
                {
                  prop: "recordTime",
                  label: "备案时间",
                  sortable: "custom",
                },
                {
                  prop: "recorder",
                  label: "备案人",
                  sortable: "custom",
                },
                {
                  prop: "tag",
                  label: "备案状态",
                  sortable: "custom",
                },
                {
                  prop: "section301",
                  label: "是否在301",
                  sortable: "custom",
                  //width: 120
                },
                {
                  prop: "sense",
                  label: "是否敏感",
                  sortable: "custom",
                },
              ],
              columns2: [
                {
                  type: "selection",
                  width: 33,
                },
                {
                  prop: "action",
                  label: "操作",
                },
                // {
                //   prop: "username",
                //   label: "客户名称",
                //   sortable: "custom",
                // },
                {
                  prop: "cname",
                  label: "中文名",
                  sortable: "custom",

                },
                {
                  prop: "ename",
                  label: "英文名",
                  sortable: "custom",

                },

                {
                  prop: "country",
                  label: "国家",
                  sortable: "custom",
                },
                {
                  prop: "hscode",
                  label: "海关编码",
                  sortable: "custom",
                },
                  {
                      prop: "addtionalCode",
                      label: "301编码",
                  },
                {
                  prop: "taxRate",
                  label: "税率",
                  sortable: "custom",

                },
                {
                  prop: "material",
                  label: "材质",
                  sortable: "custom",


                },
                {
                  prop: "materialRate",
                  label: "材质比例",


                },
                {
                  prop: "prupose",
                  label: "用途",
                },
                {
                  prop: "demand",
                  label: "清关要求",
                  sortable: "custom",
                },
                {
                  prop: "remark",
                  label: "备注",
                  sortable: "custom",
                },
                {
                  prop: "maker",
                  label: "厂家",
                  sortable: "custom",
                },
                {
                  prop: "makerAdd",
                  label: "厂家地址",
                },
                {
                  prop: "size",
                  label: "产品尺寸",
                  sortable: "custom",
                },
                {
                  prop: "brand",
                  label: "品牌",
                  sortable: "custom",
                },
                {
                  prop: "brandType",
                  label: "品牌类型",
                },
                {
                  prop: "exportBenefits",
                  label: "出口享惠情况",

                },
                {
                  prop: "recordTime",
                  label: "备案时间",
                  sortable: "custom",

                },
                {
                  prop: "recorder",
                  label: "备案人",
                  sortable: "custom",

                },
                {
                  prop: "tag",
                  label: "备案状态",
                  sortable: "custom",
                },
                {
                  prop: "section301",
                  label: "是否在301",
                  sortable: "custom",

                },
                {
                  prop: "sense",
                  label: "是否敏感",
                  sortable: "custom",
                },

              ],
                url: {
                    list: '/customer/product/list',
                    exportXlsUrl: '/customer/product/exportXls',
                    delete: '/customer/product/delete',
                    sensitive: '/customer/product/setSense/',
                    sensitiveBatch: '/customer/product/setSenseBatch/',
                    tuihui: '/customer/product/qxSense/',
                    tuihuiBatch: '/customer/product/qxSenseBatch',
                },
                isUsePost:true
            }
        },
        created() {
          //默认查询已备案的
          this.$set(this.queryForm,'tag','1');
          console.log('查询条件',this.queryForm)
        },
        methods: {
            getIsHegui(){
              let str= localStorage.getItem('USERINFO');
              if (!str){
                return false;
              }
              let obj = JSON.parse(str);
              if (!obj){
                return false;
              }
              if (obj.depStr ==='合规部'){
                return true;
              }
              if (obj.username === 'admin') {
                return true;
              }
              return false;
            },

            showRate(val) {
                if (val == null) {
                    return '';
                }
                if (val && val.indexOf('%') !== -1) {
                    return val;
                } else if (val) {
                    return val + "%";
                }
            },
            addPercent(val) {
                return val + '%'
            },
            handleEdit(row) {
                this.row = row
                this.visible = true
            },
            handleImport() {

            },
            handleDel({id, cname}) {
                this.$confirm(`确定删除【${cname}】?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'error',
                    closeOnClickModal: false,
                }).then(() => {
                    deleteAction(this.url.delete, {id}).then(res => {
                        this.$message({
                            type: 'success',
                            message: res.message
                        })
                        this.handleSearch()
                    })
                }).catch(() => {

                })
            },
            handleApproval({id, cname}) {
                this.$confirm(`确定将【${cname}】添加到敏感库?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    closeOnClickModal: false,
                }).then(() => {
                    putAction(this.url.sensitive + id).then(res => {
                        this.$message({
                            type: 'success',
                            message: res.message
                        })
                        this.handleSearch()
                    })
                }).catch(() => {

                })
            },
            handleTuihuiBatch({id, cname}) {
                this.$confirm(`确定将【${cname}】退回到正常库?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    closeOnClickModal: false,
                }).then(() => {
                    putAction(this.url.tuihui + id).then(res => {
                        this.$message({
                            type: 'success',
                            message: res.message
                        })
                        this.handleSearch()
                    })
                }).catch(() => {

                })
            },
            handleBatchApproval() {
                this.$confirm(`确定将${this.cnamelist.join(',')}添加到敏感库?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    closeOnClickModal: false,
                }).then(() => {
                    putAction(this.url.sensitiveBatch + "?ids=" + this.selectedRowKeys.join(',')).then(res => {
                        this.$message({
                            type: 'success',
                            message: res.message
                        })
                        this.handleSearch()
                    })
                }).catch(() => {

                })
            },
            handleBatchTuihui() {
                this.$confirm(`确定将${this.cnamelist.join(',')}退回至正常库?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    closeOnClickModal: false,
                }).then(() => {
                    putAction(this.url.sensitiveBatch + "?ids=" + this.selectedRowKeys.join(',')).then(res => {
                        this.$message({
                            type: 'success',
                            message: res.message
                        })
                        this.handleSearch()
                    })
                }).catch(() => {

                })
            }
        },
      computed:{
        buttonShowList() {
          return {
            'dc':this.$btnIsShow('conformance/AllProducts','0','导出'),
            'edit':this.$btnIsShow('conformance/AllProducts','1','编辑'),
            'del':this.$btnIsShow('conformance/AllProducts','1','删除'),
            'addM':this.$btnIsShow('conformance/AllProducts','1','添加至敏感库'),
            'backN':this.$btnIsShow('conformance/AllProducts','1','退回至正常库'),

          }
        }
      }
    }
</script>

<style lang="scss" scoped>

</style>
