<template>
    <div class="main-full-content">
        <el-form class="no_margin" ref="form" :model="queryForm" label-width="100px" size="small"
                 @submit.native.prevent>
            <el-card class="box-card">
                <el-row class="inputs_row">
                    <el-col :xs="24" :sm="12" :md="12" :lg="8">
                        <el-form-item label="House No">
                            <el-input v-model="queryForm.houseNo" @keyup.enter.native="queryHouseStatus"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label-width="30px">
                            <el-button type="primary" icon="el-icon-search" @click="queryHouseStatus">查询</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>
        </el-form>
        <el-card class="box-card last_card">
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    :cell-style="{ 'text-align': 'center' }"
                    style="margin-top: 15px"
                    @selection-change="handleSelectionChange"
            >
                <!--<template v-slot="scope">-->
                    <!--<template v-if="scope.column.property === 'action'">-->

                        <!--<el-tooltip class="item" effect="dark" content="点击退回至待开账单" placement="top">-->
                            <!--<el-button type="text" @click="delFee(scope.row)">删除</el-button>-->
                        <!--</el-tooltip>-->
                    <!--</template>-->

                    <!--<template v-else-if="scope.column.property === 'orderNo'">-->
                        <!--<a style="color: #00a0e9;cursor:pointer" @click.prevent="$intoDocPage(scope.row)">{{ scope.row.orderNo }}</a>-->
                    <!--</template>-->

                    <!--<template v-else-if="scope.column.property === 'customer'">-->
                        <!--<a style="color:#57a3f3;cursor:pointer" @click.prevent="$intoCustomerEditPage(scope.row.cusId)" >{{scope.row.customer}}</a>-->
                    <!--</template>-->
                    <!--<template v-else-if="scope.column.property === 'applyNo'">-->
                        <!--&lt;!&ndash;                            <el-button type="text" @click="showApplyDetail(scope.row)">{{scope.row.applyNo}}</el-button>&ndash;&gt;-->
                        <!--<a style="color: #00a0e9;cursor:pointer" @click.prevent="showApplyDetail(scope.row)">{{ scope.row.applyNo }}</a>-->
                    <!--</template>-->

                    <!--&lt;!&ndash;                        <template v-else-if="scope.column.property === 'realPay'">&ndash;&gt;-->
                    <!--&lt;!&ndash;                            <el-form-item&ndash;&gt;-->
                    <!--&lt;!&ndash;                                    :rules="[{required: true, message: '实付不能为空', trigger: 'blur'}]">&ndash;&gt;-->
                    <!--&lt;!&ndash;                                <el-input type="text" v-model="scope.row.realPay" @input="changeAmount" :placeholder="scope.row.exchangeTotal"></el-input>&ndash;&gt;-->
                    <!--&lt;!&ndash;                            </el-form-item>&ndash;&gt;-->

                    <!--&lt;!&ndash;                        </template>&ndash;&gt;-->

                <!--</template>-->

            </f-table>

        </el-card>
    </div>
</template>

<script>
    import {deleteAction, postAction, putAction} from '@/api/manage'
    import {yingwenPattern} from '@/utils/pattern';
    import {getAction} from "../../../api/manage";

    export default {
        name: 'ams',
        data() {
            return {
                tableData:[],
                queryForm:{},
                dictCodes: [
                    "ams_ship_company", "ams_port", "api_ams_customer","api_ams_unit","ams_customer_country"
                ],
                options: [
                    {label: '是', value: '1'},
                    {label: '否', value: '0'},
                ],

                // dictCodes: ['apportion_type'],
                rules: {
                },
                ids: [],
                row: {},
                form: {},
                loading:false,
                dialogFormVisible: false,
                columns: [
                    {
                        type: 'selection',
                        width: 50
                    },
                    {
                        prop: 'houseNo',
                        label: 'house No',
                        sortable: 'custom',

                    },
                    {
                        prop: 'status',
                        label: 'Status',
                        sortable: 'custom',

                    },
                    {
                        prop: 'major',
                        label: 'Major',
                        sortable: 'custom',
                    },
                    {
                        prop: 'loadingPortValue',
                        label: 'loadingPort',
                        sortable: 'custom',
                    },
                    {
                        prop: 'dischargePortValue',
                        label: 'dischargePort',
                        sortable: 'custom',
                    },
                    {
                        prop: 'loadingPortDate',
                        label: 'etd',
                        sortable: 'custom',
                    },
                    {
                        prop: 'dischargePortDate',
                        label: 'eta',
                        sortable: 'custom',
                    },
                    {
                        prop: 'acceptDate',
                        label: 'acceptDate',
                        sortable: 'custom',
                    },
                    {
                        prop: 'oneYDate',
                        label: '1Y Date',
                        sortable: 'custom',
                    },
                    {
                        prop: 'threeZDate',
                        label: 'ISF Match',
                        sortable: 'custom',
                    },
                    {
                        prop: 'masterBillNo',
                        label: 'master B/L',
                        sortable: 'custom',
                    },
                    {
                        prop: 'packAge',
                        label: 'PKgs',
                        sortable: 'custom',
                    },
                    {
                        prop: 'grossWeight',
                        label: 'kg',
                        sortable: 'custom',
                    },
                    {
                        prop: 'cbm',
                        label: 'cbm',
                        sortable: 'custom',
                    },
                    {
                        prop: 'shipperPartyName',
                        label: 'Shipper',
                        sortable: 'custom',
                    },
                    {
                        prop: 'Shipper City',
                        label: 'shipperCity',
                        sortable: 'custom',
                    },

                    {
                        prop: 'consigneePartyName',
                        label: 'Consign',
                        sortable: 'custom',
                    },
                    {
                        prop: 'consigneeCity',
                        label: 'Consignee City',
                        sortable: 'custom',
                    },
                    {
                        prop: 'arrivalVesselValue',
                        label: 'Vessel Name',
                        sortable: 'custom',
                    },
                    {
                        prop: 'arrivalVesselVoyage',
                        label: 'Voyage',
                        sortable: 'custom',
                    },
                    // {
                    //     prop: 'action',
                    //     label: '操作',
                    //
                    // },
                ],
                url: {
                    queryHouseStatus: '/operation/linkams/queryHouseStatus',

                },
                ipagination: {
                    currentPage: 1,
                    pageSize: 20,
                    pageSizes: [10, 15, 20, 30],
                    total: 0
                },
            }
        },
        methods: {
            queryHouseStatus(){
            postAction(this.url.queryHouseStatus,this.queryForm).then(res=>{
                if(res.success){
                    this.tableData = res.result
                }
            })
            },
            listToMap(list, name) {
                var map = {};
                for (var index in list) {
                    map[list[index][name]] = list[index];
                }
                return map;
            },




            handleSelectionChange(selection) { // 多选
                let arr = []
                this.emailList = [];
                this.nameList = [];
                for (let i = 0; i < selection.length; i++) {
                    arr.push(selection[i]['id'])
                    this.emailList.push(selection[i]['email']);
                    this.nameList.push(selection[i]['name']);
                }
                this.selectionRows = selection
                this.selectedRowKeys = [...arr]
            },
            handleAdd() {
                this.form = {};
                this.$set(this.form, 'menuId', this.menuId);
                this.dialogFormVisible = true;
            },
            handleEdit(row) {
                //将对象转为json字符串再转回对象
                //this.form = row; 复制的是指针 仍然指向这个对象row
                this.vueId = row.id;
                this.path = row.path;
                this.visible = true;

            },
            handleDel(row) {
                this.$confirm('确定删除该页面:' + row.name + ',该操作会同时删除页面下的按钮与权限', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'error',
                    closeOnClickModal: false,
                }).then(() => {
                    deleteAction(this.url.del, {id: row.id}).then(res => {
                        this.$message({
                            type: 'success',
                            message: res.message
                        })

                        this.handleSearch()
                    })
                }).catch(err => {
                    this.$message.error(err.message);
                })
            },
            handleDelBatch() {
                this.$confirm('确定批量删除：' + this.nameList.join(',') + '这些页面吗,该操作会同时删除页面下的按钮与权限', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'error',
                    closeOnClickModal: false,
                }).then(() => {
                    deleteAction(this.url.delBatch, {ids: this.selectedRowKeys.join(',')}).then(res => {
                        this.$message({
                            type: 'success',
                            message: res.message
                        })

                        this.handleSearch()
                    })
                }).catch(err => {
                    this.$message.error(err.message);
                })
            },
            handleSave() {
                this.$refs.form.validate(valid => {
                    if (valid) {
                        //编辑
                        // this.$set(this.form,'menuId',this.menuId);
                        if (this.form.id) {
                            putAction(this.url.edit, this.form)
                                .then((res) => {
                                    if (res.success) {
                                        this.$message.success(res.message);
                                        //关闭弹窗
                                        this.dialogFormVisible = false;
                                        sessionStorage.removeItem("dict:sys_vue");
                                        this.handleQuery()
                                    }
                                })
                        } else {
                            postAction(this.url.add, this.form)
                                .then((res) => {
                                    if (res.success) {
                                        this.$message.success(res.message);
                                        //关闭弹窗
                                        this.dialogFormVisible = false;
                                        sessionStorage.removeItem("dict:sys_vue");
                                        this.handleQuery()
                                    }
                                })
                        }
                    }
                })

            },
            handleReset() {
                this.queryForm = {};
                this.handleQuery()
            }
        }, created() {
            // this.queryForm.menuId = this.menuId;
        }
    }
</script>

<style lang="scss" scoped>

</style>
