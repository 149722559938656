<template>

    <div class="main-full-content">
        <el-form ref="form" :model="queryForm" label-width="120px" size="medium" @submit.native.prevent>
            <el-card class="box-card">
                <el-row class="inputs_row">
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="工作单号">
                            <el-input v-model="queryForm.orderNo" @keyup.enter.native="handleCheck" type="textarea" placeholder="可输入多个单号"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="是否录入材积">
                            <el-select v-model="queryForm.isRealCmb" filterable clearable>
                                <el-option value="1" label="是"></el-option>
                                <el-option value="0" label="否"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                  <el-col class="no_margin" :md="6" :sm="24">
                    <el-form-item label="入仓时间">
                      <div class="f-flex">
                        <el-date-picker
                            class="f-flex-1"
                            v-model="queryForm.getsTimeStart"
                            type="datetime"
                            size="small"
                            placeholder="开始时间"
                            default-time="00:00:00"
                            value-format="yyyy-MM-dd HH:mm:ss"
                        >
                        </el-date-picker>
                        <span class="f-p-h-2">~</span>
                        <el-date-picker
                            class="f-flex-1"
                            v-model="queryForm.getsTimeEnd"
                            type="datetime"
                            size="small"
                            placeholder="结束时间"
                            default-time="23:59:59"
                            value-format="yyyy-MM-dd HH:mm:ss"
                        >
                        </el-date-picker>
                      </div>
                    </el-form-item>
                  </el-col>
                   <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="是否上传材积页">
                            <el-select v-model="queryForm.isImage" filterable clearable>
                                <el-option value="1" label="是"></el-option>
                                <el-option value="0" label="否"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="3">
                        <el-form-item label-width="30px" style="width: 400px">
                            <el-button size="medium" type="primary" icon="el-icon-search" @click="handleCheck">查询</el-button>
                            <!-- <el-divider direction="vertical"></el-divider> -->
                            <el-button size="medium" plain @click="handleReset">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>

        </el-form>
        <el-card class="box-card last_card">
            <!--            <div class="f-p-b-15">-->
            <!--                <el-button size="medium" type="primary" @click="noConvertBill">非折合账单</el-button>-->
            <!--                <el-button size="medium" type="primary" @click="convertBill">折合账单</el-button>-->
            <!--            </div>-->
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="72vh"
                    :isRecordTableScroll="true"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange">
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'action'">
                       <el-button type="text" @click="goPage(scope.row.orderNo)">录入材积</el-button>
                        <!-- <el-button type="text" @click="remarkProblem(scope.row)">标记问题件</el-button> -->
                    </template>
                    <template v-else-if="scope.column.property === 'orderNo'">
                        <a
                                style="color: #00a0e9"
                                @click.prevent="$intoDocPage(scope.row.orderNo)"
                        >{{ scope.row.orderNo }}</a
                        >
                    </template>
                    <template v-else-if="scope.column.property === 'transportStatus'">
                        <span>{{parseDict(scope.row.transportStatus,'transportStatus')}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'whType'">
                        <span v-if="scope.row.whType==='0'">自送货</span>
                        <span v-if="scope.row.whType==='1'">上门提货</span>

                    </template>
                  <template v-else-if="scope.column.property === 'hasCmb'">
                    <span v-if="scope.row.realCmbs>0" style="color: green">已入仓已录材积</span>
                    <span v-else style="color: red">已入仓未录材积</span>

                  </template>
                  <template v-else-if="scope.column.property === 'isWarning'">
                    <span v-if="scope.row.isWarning==='1'">是</span>
                    <span v-else>否</span>

                  </template>

                   <template v-else-if="scope.column.property === 'isImage'">
                    <span v-if="scope.row.isImage==='1'">是</span>
                    <span v-else>否</span>

                  </template>
                  <template v-else-if="scope.column.property === 'realCtns'">
                    <span style="color: red;font-weight:bolder" v-if="scope.row.realCtns < scope.row.ctnTotal "
                    >{{scope.row.realCtns}}</span>
                    <span v-else style="color: #00A329;font-weight:bolder">{{scope.row.realCtns}}</span>
                  </template>
                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange">
                </el-pagination>
            </el-row>
        </el-card>
        <add-problem-dialog
            :visible.sync="problemVisible"
            :row="row"
            @ok="handleQuery"
            v-if="problemVisible"
        >

        </add-problem-dialog>
    </div>


</template>
<script>
    import QueryMixins from "../../mixins/QueryMixins"
    import {getAction, postAction} from "@/api/manage";
    import AddProblemDialog from "../docpage/modules/AddProblemDialog";
    import { setUpRouteUtil } from '@/utils/util';

    export default {
        mixins: [QueryMixins],
        components: {
            AddProblemDialog
        },
        data() {
            return {
                row:{},
                problemVisible:false,
                dictCodes: ['transportStatus'],
                editStartOptions: {
                    disabledDate: time => {
                        if (!this.form.etime) {
                            // time = new Date(time.replace(/-/g, '/'));
                            return time.getTime() < new Date(1970 - 1 - 1).getTime();   //禁止选择1970年以前的日期
                        } else {
                            return time.getTime() > new Date(this.form.etime);
                        }
                    }
                },
                editStopOptions: {
                    disabledDate: time => {
                        // time = new Date(time.replace(/-/g, '/'));
                        return (
                            time.getTime() < new Date(this.form.stime) ||
                            time.getTime() < new Date(1970 - 1 - 1).getTime()    //禁止选择1970年以前的日期
                        );
                    }
                },
                selectedRowKeys: [],
                selectionRows: [],
                selectedNos:[],
                loading: false,
                form: {},
                columns: [
                    // {
                    //     type: 'selection',
                    //     width: 50
                    // },
                    {
                        prop: 'action',
                        label: '操作',
                    },
                    {
                        prop: 'orderNo',
                        label: '工作单号',
                        minWidth:90,
                    },
                  {
                    prop: 'hasCmb',
                    label: '材积是否录入',

                  },
                  {
                    prop: 'isImage',
                    label: '材积单',

                  },
                    {
                        prop: 'whType',
                        label: '收货类型',
                    },
                    {
                        prop: 'customer',
                        label: '客户',
                    },
                    {
                        prop: 'transportStatus',
                        label: '渠道名称',
                    },
                    {
                        prop: 'content',
                        label: '仓库名',
                    },
                    {
                        prop: 'createTime',
                        label: '下单时间',
                    },
                    {
                        prop: 'getsTime',
                        label: '入仓时间',
                    },
           /*         {
                        prop: 'ctn',
                        label: '提货预计件数',
                    },*/
                    {
                        prop: 'ctnTotal',
                        label: '订单预计件数',
                    },
                 /*   {
                        prop: 'kg',
                        label: '提货预计重量',
                    },*/
                    {
                        prop: 'kgTotal',
                        label: '订单预计重量',
                    },
                    {
                      prop: 'cmb',
                      label: '订单预计体积',
                    },
                  {
                    prop: "realCtns",
                    label: "实际件数",
                  },
                  {
                    prop: "realKgs",
                    label: "实际重量",
                  },
                  {
                    prop: "realCmbs",
                    label: "实际体积",
                  },
                  {
                    prop: "isWarning",
                    label: "是否问题件",
                  },
                    // {
                    //     prop: 'total',
                    //     label: '港前操作',
                    // },
                    // {
                    //     prop: 'createBy',
                    //     label: '操作',
                    // },
                    // {
                    //     prop: 'screateTime',
                    //     label: '销售',
                    // },
                ],

                url: {
                    list: '/interface/warehouse/getReceiveIPage',
                },

            };
        },
        computed: {

        },
        created() {
            this.$set(this.queryForm,'isRealCmb','0')
        },
        methods: {
          async handleQuery() {
            this.loading = true
            let form = {...this.queryForm, ...this.filterForm, ...this.initData}
            for (let item in form) {
              if (typeof form[item] === 'string') {
                form[item] = form[item].trim()
                if (form[item] === '') {
                  form[item] = null
                }
              }
            }
            const {prop, order} = this.isorter
            // 传入的参数
            const params = {
              ...form,
              column: prop,
              order: order.indexOf('asc') !== -1 ? 'asc' : 'desc',
            }
            //判断 是否要查询startTime

            if (!params.column) {
              if (this.queryForm.column === 'startTime') {
                this.$set(params, 'column', 'startTime')
              } else if (this.enabledCreatetime){
                this.$set(params, 'column', 'createTime')
              }
              //提货中查询
              if (this.queryForm.column === 'distributionTime') {
                this.$set(params, 'column', 'distributionTime')
              }
              //排序
              //提货中查询
              if (this.queryForm.column === 'sort') {
                this.$set(params, 'column', 'sort')
              }
            }
            if (this.ipagination) {
              params.pageNo = this.ipagination.currentPage
              params.pageSize = this.ipagination.pageSize
            }
            await postAction(this.url.list, params).then((res) => {
              console.log(res)
              if (res.success) {
                this.$refs.GTable && this.$refs.GTable.handleScrollTop && this.$refs.GTable.handleScrollTop();
                if (res.result) {
                  const {records, total} = res.result
                  this.tableData = records || res.result;

                  this.ipagination && (this.ipagination.total = total || res.result.length)
                } else {

                  this.tableData = [];

                  this.ipagination = 0;
                }

              }
            }).finally(() => {
              this.loading = false;
              this.initMoreFuc && this.ipagination.currentPage == 1 ? this.initMoreFuc() : ''; //用于初始的时候调用其他的方法
            })
          },
            remarkProblem(row) {
                this.$set(this.row, "orderNo", row.orderNo);
                this.problemVisible = true;
            },
            intoDocpage(orderNo) {
                if (orderNo.indexOf("KD")!==-1){
                    // 对于路由不在左侧菜单中的选中菜单处理
                    setUpRouteUtil(this.$route.path, '/kdorder/Edit');
                    this.$router.push("/kdorder/Edit?orderNo=" + orderNo);
                }else {
                    // 对于路由不在左侧菜单中的选中菜单处理
                    setUpRouteUtil(this.$route.path, '/docpage/Edit');
                    this.$router.push("/docpage/Edit?orderNo=" + orderNo);
                }

            },
            handleSelectionChange(selection) { // 多选
                console.log(selection)
                let arr = []
                let nos = []
                this.nameList = []
                this.cnamelist = []
                this.emailList = []
                for (let i = 0; i < selection.length; i++) {
                    arr.push(selection[i]['id'])
                    nos.push(selection[i]['orderNo'])
                    this.nameList.push(selection[i]['username'])
                    this.cnamelist.push(selection[i]['cname'])
                    this.emailList.push(selection[i]['email'])
                }
                this.selectionRows = selection
                this.selectedRowKeys = [...arr]
                this.selectedNos = [...nos]
            },
            handleReceive(){
                console.log("申请收款以下id的费用",this.selectedRowKeys)
            },
            handleReset() {
                this.queryForm = {}
                this.otime = ''
                this.stime = ''
                this.ipagination.currentPage = 1
                this.handleQuery()
            },
            handleCheck() {
                this.loading = true
                /*if (this.otime.length>1){
                    this.queryForm.createTimeS = this.otime[0]
                    this.queryForm.createTimeE = this.otime[1]
                }*/
                /*if (this.stime.length>1) {
                    this.queryForm.sCreateTimeS = this.stime[0]
                    this.queryForm.sCreateTimeE = this.stime[1]
                }*/
                let form = {...this.queryForm, ...this.filterForm}
                for (let item in form) {
                    if (typeof form[item] === 'string') {
                        form[item] = form[item].trim()
                        if (form[item] === '') {
                            form[item] = null
                        }
                    }
                }
                const {prop, order} = this.isorter
                // 传入的参数
                const params = {
                    ...form,
                    column: prop,
                    order: order.indexOf('asc') !== -1 ? 'asc' : 'desc',
                }
                //判断 是否要查询startTime

                if (!params.column) {
                    if (this.queryForm.column === 'startTime') {
                        this.$set(params, 'column', 'startTime')
                    } else {
                        this.$set(params, 'column', 'createTime')
                    }
                    //提货中查询
                    if (this.queryForm.column === 'distributionTime') {
                        this.$set(params, 'column', 'distributionTime')
                    }
                }
                if (this.ipagination) {
                    params.pageNo = this.ipagination.currentPage
                    params.pageSize = this.ipagination.pageSize
                }
                if (this.queryForm.orderNo){
                    params.pageNo = 1;
                }
                console.log("查询对象", params)

                postAction(this.url.list, params).then((res) => {
                    if (res.success) {
                        this.$refs.GTable && this.$refs.GTable.handleScrollTop && this.$refs.GTable.handleScrollTop();
                        const {records, total} = res.result
                        this.tableData = records || res.result;

                        this.ipagination && (this.ipagination.total = total || res.result.length)
                    }
                }).finally(() => {
                    this.loading = false
                })
            },
            noConvertBill(){
                console.log("申请收款以下id的费用",this.selectedRowKeys)
                if (this.selectedRowKeys.length === 0){
                    this.$message.warning("请勾选费用")
                    return
                }

                let countCur = [];
                this.selectionRows.forEach(item => {
                    countCur.push(item.moneyType)
                });
                let curArr = Array.from(new Set(countCur));
                console.log('curArr->',curArr)
                if(curArr.length > 1){
                    this.$message.warning("所选费用的费用币种不一致！")
                    return
                }

                let count = [];
                this.selectionRows.forEach(item => {
                    count.push(item.settleId)
                });
                let arr = Array.from(new Set(count));
                if(arr.length > 1){
                    this.$message.warning("所选费用的结算对象不一致！")
                    return
                }
                this.$refs.noConvert.edit(this.selectedRowKeys,this.selectedNos)
            },
            convertBill(){
                console.log("申请收款以下id的费用",this.selectedRowKeys)
                if (this.selectedRowKeys.length === 0){
                    this.$message.warning("请勾选费用")
                    return
                }
                let count = [];
                this.selectionRows.forEach(item => {
                    count.push(item.settleId)
                });
                console.log(Array.from(new Set(count)))
                let arr = Array.from(new Set(count));
                if(arr.length > 1){
                    this.$message.warning("所选费用的结算对象不一致！")
                    return
                }
                this.$refs.convert.edit(this.selectedRowKeys,this.selectedNos)
            },
            goPage(orderNo){
   this.$router.push({
                path: '/warehouse/Main',
                query: {
                  'orderNo': orderNo
                }
              })
            }
        },
    };
</script>
<style scoped>
    .el-form-item {
        margin-bottom: 22px;
    }
</style>
