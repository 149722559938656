<template>
  <div class="main-full-content">
    <el-form
        class="input_top"
        :model="queryForm"
        label-width="120px"
        size="medium"
    >
      <el-card class="box-card">
        <el-row>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="国家名称">
              <el-input
                  v-model="queryForm.country"
                  @keyup.enter.native="handleSearch"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label-width="30px">
              <el-button
                  type="primary"
                  icon="el-icon-search"
                  @click="handleSearch"
              >查询
              </el-button
              >
              <el-button plain @click="handleReset">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
    </el-form>
    <el-card class="box-card last_card">
      <div class="f-p-b-15">
        <el-button
            type="primary"
            size="medium"
            icon="el-icon-plus"
            @click="handleAdd"
            v-if="buttonShowList.add"
        >新增
        </el-button
        >

        <el-button type="primary" size="medium" @click="deleteBatches"
                   v-if="buttonShowList.plsc"
        >批量删除
        </el-button
        >
      </div>
      <f-table
          v-loading="loading"
          ref="GTable"
          border
          size="medium"
          row-key="id"
          class="c-th-has-bgcolor"
          :columns="columns"
          :data="tableData"
          :cell-style="{ 'text-align': 'center' }"
          tableHeight="66vh"
          @selection-change="handleSelectionChange"
          @sort-change="handleTableChange"
      >
        <template v-slot="scope">
          <template v-if="scope.column.property === 'fileName'">
            <a
                style="color: #00a0e9"
                @click.prevent="downloadFile(scope.row.fileUrl,scope.row.fileName)"
            >{{ scope.row.fileName }}</a
            >
          </template>
          <template v-else-if="scope.column.property === 'action'">
            <el-button
                type="text"
                @click="handleEdit(scope.row)"
                v-if="buttonShowList.bj"
            >编辑
            </el-button
            >
            <el-button type="text" @click="handleDele(scope.row)"
                       v-if="buttonShowList.sc"
            >删除
            </el-button
            >
          </template>
          <template v-else-if="scope.column.property === 'hasTax'">
            <span>{{ scope.row.hasTax === "1" ? "是" : "否" }}</span>
          </template>
          <template v-else-if="scope.column.property === 'fileType'">
            <span>{{ parseDict(scope.row.fileType,'bd_file_type')}}</span>
          </template>
        </template>
      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination
            v-bind="ipagination"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        >
        </el-pagination>
      </el-row>
    </el-card>
    <el-dialog
        :title="form.id ? '编辑分税类型' : '新增分税类型'"
        :visible.sync="dialogFormVisible"
        :close-on-click-modal="false"
        v-if="dialogFormVisible"
    >
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item
            label="模版类型"
            :label-width="formLabelWidth"
            prop="fileType"
        >
          <f-select
              v-model="form.fileType"
              :is-need="form.fileType"
              :dict="'bd_file_type'"
              :disabled="form.id && form.id.length > 0"
              autocomplete="off"
          ></f-select>
        </el-form-item>
        <el-form-item
            v-if="form.fileType === '0'"
            label="国家名称"
            :label-width="formLabelWidth"
            prop="country"
        >
          <el-input
              v-model="form.country"
              autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
            v-if="form.fileType === '0'"
            label="币种名称"
            :label-width="formLabelWidth"
            prop="currencyName"
        >
          <el-input v-model="form.currencyName" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
            v-if="form.fileType === '0'"
            label="币种英文名称"
            :label-width="formLabelWidth"
            prop="currencyEnShortName"
        >
          <el-input
              v-model="form.currencyEnShortName"
              autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
            v-if="form.fileType === '0'"
            label="是否包税"
            :label-width="formLabelWidth"
            prop="hasTax"
        >
          <el-radio-group v-model="form.hasTax">
            <el-radio :label="'1'">是</el-radio>
            <el-radio :label="'0'">否</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item
            label="上传文件模板"
            :label-width="formLabelWidth"
            prop="ossId"
        >
          <f-upload v-model="fileList" :dir="'channel'" :isUseOss="true" :limit="1"></f-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="medium" @click="dialogFormVisible = false"
        >取 消
        </el-button
        >
        <el-button size="medium" type="primary" @click="handleSave"
        >确 定
        </el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {deleteAction, postAction, putAction} from "@/api/manage";
import QueryMixins from "../../../mixins/QueryMixins";

import OssClientMixins from "../../../mixins/OssClientMixins";

export default {
  name: "BdTaxDistributionSlip",
  mixins: [QueryMixins,OssClientMixins],
  data() {
    return {
      dictCodes:['bd_file_type'],
      ossClient: null,
      fileList: [],
      ossId: null,
      rules: {
        fileType: [
          {required: true, message: "请输入国家名称", trigger: "change"},
        ],
        country: [
          {required: true, message: "请输入国家名称", trigger: "change"},
          {max: 50, message: "长度不能超过50", trigger: "change"},
        ],
        currencyName: [
          {required: true, message: "币种名称", trigger: "change"},
          {max: 50, message: "长度不能超过50", trigger: "change"},
        ],
        currencyEnShortName: [
          {required: true, message: "币种英文名称", trigger: "change"},
          {max: 50, message: "长度不能超过50", trigger: "change"},
        ],
        hasTax: [
          {required: true, message: "请选择是否包税", trigger: "change"},
        ],
      },
      ids: [],
      row: {},
      form: {},
      formLabelWidth: "110px",
      //控制弹框显示
      dialogFormVisible: false,
      ossFile: {},
      columns: [
        {
          type: "selection",
          width: 50,
        },
        {
          prop: "fileType",
          label: "模版类型",
          sortable: "custom",
        },
        {
          prop: "country",
          label: "国家名称",
          sortable: "custom",
        },
        {
          prop: "currencyName",
          label: "币种名称",
          sortable: "custom",
        },
        {
          prop: "currencyEnShortName",
          label: "币种英文名称",
          sortable: "custom",
        },
        {
          prop: "hasTax",
          label: "是否包税",
        },

        {
          prop: "fileName",
          label: "模板文件",
        },
        {
          prop: "createTime",
          label: "创建时间",
        },
        {
          prop: "action",
          label: "操作",
        },
      ],
      url: {
        list: "/sys/tax/pagelist",
        add: "/sys/tax/add",
        edit: "/sys/tax/edit",
        getById: "/sys/tax/queryById",
        delete: "/sys/tax/delete",
        uploadDataSave: "/sys/oss/file/uploadDataSave",
        upload: "/sys/oss/file/upload", //文件上传
        // 批量删除
        deleteBatch: "/sys/tax/deleteBatch",
        addTemplate: "/sys/tax/addTemplate",
        downloadFile: '/sys/oss/file/download',
      },
    };
  },
  created() {

  },
  methods: {
    downloadFile: function (url, name) {
      window.open(url);
      // postAction(this.url.downloadFile, {url: url, name: name}, {responseType: 'blob'})
      //     .then(response => {
      //       let u = window.URL.createObjectURL(response)
      //       let aLink = document.createElement('a')
      //       aLink.style.display = 'none'
      //       aLink.href = u;
      //       aLink.setAttribute('download', name)
      //       document.body.appendChild(aLink)
      //       aLink.click()
      //       document.body.removeChild(aLink)
      //       window.URL.revokeObjectURL(u);
      //       this.handleQuery();
      //     })
    },

    handDel(row) {
      this.$confirm(`删除${row.fileName}?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "success",
        closeOnClickModal: false,
      })
          .then(() => {
            // 删除文件，并且删除os_order_file中的文件
            putAction(this.url.edit, row).then((res) => {
              this.$message({
                type: "success",
                message: res.message,
              });
              this.handleQuery();
            });
          })
          .catch((err) => {
            if (err.messag) {
              this.$message.error(err.message);
            }
          });
    },
    handleSelectionChange(selection) {
      // 多选
      let arr = [];
      this.emailList = [];
      for (let i = 0; i < selection.length; i++) {
        arr.push(selection[i]["id"]);
        this.emailList.push(selection[i]["email"]);
      }
      this.selectionRows = selection;
      this.selectedRowKeys = [...arr];
    },
    handleAdd() {
      this.fileList = [];
      this.form = {};
      this.dialogFormVisible = true;
    },
    // 批量删除
    deleteBatches() {
      this.$confirm(`确定删除所选数据吗`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
        closeOnClickModal: false,
      }).then(() => {
        deleteAction(this.url.deleteBatch, {
          ids: this.selectedRowKeys.join(","),
        }).then((res) => {
          this.$message({
            type: "success",
            message: res.message,
          });
          this.handleSearch();
        });
      });
    },
    handleEdit(row) {
      this.fileList = [];

      this.fileList.push({id: row.ossId, url: row.fileUrl, name: row.fileName});

      //将对象转为json字符串再转回对象
      //this.form = row; 复制的是指针 仍然指向这个对象row
      this.form = JSON.parse(JSON.stringify(row));
      this.dialogFormVisible = true;
    },
    handleSave() {
      if (this.fileList && this.fileList.length > 0) {
        this.form.ossId = this.fileList[0].id;
        this.form.fileName = this.fileList[0].name;
        this.form.fileUrl = this.fileList[0].url;
      }else {
        this.$message.warning('请上传文件模板');
        return false;
      }
      this.$refs.form.validate((valid) => {
        if (valid) {

          // 编辑;
          if (this.form.id) {
            putAction(this.url.edit, this.form).then((res) => {
              if (res.success) {
                this.$message.success(res.message);
                //关闭弹窗
                this.dialogFormVisible = false;
                localStorage.removeItem("dict:bd_cur");
                this.handleQuery();
              }
            });
          } else {
            postAction(this.url.add, this.form).then((res) => {
              if (res.success) {
                this.$message.success(res.message);
                this.dialogFormVisible = false;
                // localStorage.removeItem("dict:bd_cur");
                this.handleQuery();
              }
            });
          }
        }
      });
    },

    //文件上传之前校验文件合法
    beforeUpload(file) {
      let name = "";
      if (file && file.name) {
        name = file.name;
      }
      const isFile = name.indexOf(".") !== -1;
      if (!isFile) {
        this.$message.warning("该文件名不合法");
      }
      return isFile;
    },
    generateUUID(length) {
      var d = new Date().getTime();
      let uuid = d.toString() + Math.random().toString().substr(3, length);
      return uuid;
    },

    handleDele(row) {
      this.$confirm(`确定删除该币种吗？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "success",
        closeOnClickModal: false,
      })
          .then(() => {
            deleteAction(this.url.delete, {id: row.id}).then((res) => {
              this.$message({
                type: "success",
                message: res.message,
              });
              this.handleQuery();
            });
          })
          .catch(() => {
          });
    },
    handleReset() {
      this.queryForm = {};
      this.filterForm = {};
      this.ipagination.currentPage = 1;
      this.handleQuery();
    },
  },
  computed:{
    buttonShowList() {
      return {
        'add':this.$btnIsShow('isystem/BaseData/BdTaxDistributionSlip', '1', '新增'),
        'plsc':this.$btnIsShow('isystem/BaseData/BdTaxDistributionSlip', '1', '批量删除'),
        'bj':this.$btnIsShow('isystem/BaseData/BdTaxDistributionSlip', '1', '编辑'),
        'sc':this.$btnIsShow('isystem/BaseData/BdTaxDistributionSlip', '1', '删除'),
      }
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
