<template>
    <el-dialog
            title="问题件"
            :visible="visible"
            width="50%"
            append-to-body
            custom-class="c-custom-dialog"
            :close-on-click-modal="false"
            :before-close="handleClose">
        <el-form :model="form" :rules="rules" ref="form" label-width="94px" size="medium">
            <el-row>
                <el-col :span="24">
                    <el-form-item prop="remarks" label="问题描述">
                        <el-input class="padding_right_area_four" v-model="form.remarks" maxlength="2000" show-word-limit type="textarea"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item prop="problemType" label="问题类型">
                        <f-select
                                v-model="form.problemType"
                                :is-need="form.problemType"
                                :dict="'problem_type'"
                        >
                        </f-select>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item prop="fileList" label="附件上传">
                        <f-upload v-model="form.fileList" :dir="'kdOrder'"></f-upload>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <span slot="footer">
            <el-button size="medium" @click="handleClose">取消</el-button>
            <el-button type="primary" size="medium" @click="handleSubmit">确定</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import {getAction, httpAction} from '@/api/manage'

    export default {
        name: 'AddProblemDialog',
        props: {
            visible: {
                type: Boolean
            },
            row: {
                type: Object,
                default: () => {
                }
            },
            userName: {
                type: String
            }
        },
        data() {
            return {
                form: {},
                options: [],
                rules: {
                    remarks: [
                        {required: true, message: '请输入问题描述', trigger: 'blur'},
                        {max: 2000, message: '长度不能超过2000', trigger: 'change'},
                    ],
                    problemType: [
                        {required: true, message: '请选择问题类型', trigger: 'change'},
                    ],

                },
                url: {
                    add: '/order/KdOrderProblem/add',
                    edit: '/order/KdOrderProblem/edit',
                    getById: '/order/KdOrderProblem/getById',
                }
            }
        },
        watch: {
            visible(val) {
                if (val && this.row.id) {
                    getAction(this.url.getById, {id: this.row.id})
                        .then(res => {
                            this.form = res.result;
                        })
                } else {
                    this.form = {fileList: []}
                }
            }
        },
        methods: {
            handleSubmit() {
                let url = this.url.add
                let method = 'POST'
                if (this.row.id) {
                    url = this.url.edit
                    method = 'PUT'
                }
                this.$refs.form.validate((valid) => {
                    if (valid) {
                        /*将filelist的id取出来拼成ids给form*/
                        if (this.form.fileList) {
                            this.$set(this.form, 'ossIds', this.form.fileList.map(function (e) {
                                return e.id
                            }).join(","));
                        }
                        if (!this.form.orderNo) {
                            this.$set(this.form, 'orderNo', this.row.orderNo);
                        }
                        httpAction(url, method, this.form).then((res) => {
                            this.$message({
                                type: 'success',
                                message: res.message
                            })
                            this.handleClose()
                            this.$emit('ok')
                        })
                    }
                })
            },
            handleClose() {
                this.$emit('update:visible', false);
                this.$emit('ok');
                this.$refs.form.clearValidate()
            }
        }
    }
</script>

<style lang="scss" scoped>
    ::v-deep
    .el-dialog__body {
        padding: 20px 30px 20px 24px;
    }
</style>
