<template>
  <div class="main-full-content">
    <el-form class="input_top" ref="form" :model="queryForm" label-width="100px" size="medium">
        <el-card class="box-card">
            <el-row class="inputs_row">

          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="国家">
             <el-input v-model="queryForm.country"></el-input>
            </el-form-item>
          </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="6">
                  <el-form-item label="FBA CODE">
                      <el-input v-model="queryForm.fbaCode"></el-input>
                  </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="6">
                  <el-form-item label="订单号">
                      <el-input v-model="queryForm.orderNo" type="textarea" placeholder="支持多个单号精确查询"></el-input>
                  </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="6">
                <el-form-item label="转运单号">
                  <el-input v-model="queryForm.transferNo" type="textarea" placeholder="支持多个转运单号精确查询"></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="6">
                <el-form-item label="柜号">
                  <el-input v-model="queryForm.containerNo" type="textarea" placeholder="支持多个柜号精确查询"></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="6">
                <el-form-item label="需要贴标">
                  <f-select v-model="queryForm.isStick" :is-need="queryForm.isStick" :dict="'unit_whether'"></f-select>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="6">
                <el-form-item label="是否已同步">
                  <f-select v-model="queryForm.isTransfer" :is-need="queryForm.isTransfer" :dict="'unit_whether'"></f-select>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="6">
                <el-form-item label="派送操作">
                  <f-select v-model="queryForm.endOperateId" :is-need="queryForm.endOperateId" :dict="'sys_user_id'"></f-select>
                </el-form-item>
              </el-col>
              <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="6">
                <el-form-item label="ETD开始时间" >
                  <div class="f-flex">
                    <el-date-picker
                        class="f-flex-1"
                        v-model="queryForm.shipmentBoardStart"
                        type="datetime"
                        size="small"
                        placeholder="开始时间"
                        default-time="00:00:00"
                        value-format="yyyy-MM-dd HH:mm:ss"
                    >
                    </el-date-picker>
                  </div>
                </el-form-item>
              </el-col>
              <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="6">
                <el-form-item label="ETD结束时间" >
                  <div class="f-flex">
                    <el-date-picker
                        class="f-flex-1"
                        v-model="queryForm.shipmentBoardEnd"
                        type="datetime"
                        size="small"
                        placeholder="结束时间"
                        default-time="23:59:59"
                        value-format="yyyy-MM-dd HH:mm:ss"
                    >
                    </el-date-picker>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="6">
                <el-form-item label="客户名">
                  <el-input v-model="queryForm.customer" ></el-input>
                </el-form-item>
              </el-col>
               <el-col :xs="24" :sm="12" :md="12" :lg="6">
                <el-form-item label="是否自拼">
                 <el-select v-model="queryForm.isVirtualCabinet" filterable clearable
                         @keyup.enter.native="handleSearch">
                <el-option value="0" label="自拼"></el-option>
                <el-option value="1" label="非自拼"></el-option>
              </el-select>
                </el-form-item>
              </el-col>
               <el-col :xs="24" :sm="12" :md="12" :lg="6">
                <el-form-item label="周期">
                  <el-input v-model="queryForm.week" ></el-input>
                </el-form-item>
              </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="4">
          <el-form-item label-width="30px">
            <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
            <el-button plain @click="handleReset">重置</el-button>
          </el-form-item>
        </el-col>
      </el-row>
        </el-card>

    </el-form>
    <el-card class="box-card last_card">
        <div class="f-p-b-15">
          <el-button plain type="primary"  @click="handleStickMore" :disabled="selectedRowKeys.length===0">变更为贴标单</el-button>
          <el-button plain type="primary"  @click="handlePushMore" :disabled="selectedRowKeys.length===0">批量同步</el-button>

        </div>
    <div class="c-alert f-m-b-10">
<!--        <span style="color: red">已开船的 并且应付的结算对象有FOREST SHIPPING INTL LTD( LAX OFFICE)，大森林美东分公司 或者要贴标的订单</span> <br>-->
      已选择 <span class="stress">{{this.selectedRowKeys.length}}</span> 项
      <span class="stress f-m-l-12 f-cursor-pointer" @click="handleClearSelection">清空</span>
    </div>
    <f-table
        v-loading="loading"
        ref="GTable"
        border
        size="medium"
        row-key="id"
        class="c-th-has-bgcolor"
        :columns="columns"
        :data="tableData"
        :cell-style="{ 'text-align': 'center' }"
        tableHeight="58vh"
        @selection-change="handleSelectionChange"
        @sort-change="handleTableChange">
      <template v-slot="scope">
        <template v-if="scope.column.property === 'action'">
          <el-button type="text" @click="handleThaw(scope.row)">同步海外仓转运单</el-button>

        </template>
          <template v-else-if="scope.column.property === 'orderNo'">
              <a style="color: #00a0e9" @click.prevent="$intoDocPage(scope.row.orderNo)">{{ scope.row.orderNo }}</a>
          </template>
        <template v-else-if="scope.column.property === 'isStick'">
            <span v-if="scope.row.isStick === '1'" style="color:red;">需要贴标</span>
            <span v-else style="color: green">不用贴标</span>
        </template>
          <template v-else-if="scope.column.property === 'delivery'">
              <span v-if="['1','2','5'].indexOf(scope.row.delivery) !== -1"
                    style="background-color: orange;color: white">{{$parseOrderDelivery(scope.row.delivery, scope.row.isDeliveryOperStatus)}}</span>
              <span v-if="['0','3','4','6'].indexOf(scope.row.delivery) !== -1"
                    style="background-color: green;color: white">{{$parseOrderDelivery(scope.row.delivery, scope.row.isDeliveryOperStatus)}}</span>
              <br>
          </template>
        <template v-else-if="scope.column.property === 'isTransfer'">
          <span v-if="!scope.row.transferNo && scope.row.failedReason" style="color: red">同步失败</span>
          <span v-else-if="!scope.row.transferNo && !scope.row.failedReason" style="color: red">未同步</span>
          <span v-else style="color: green">已同步</span>
        </template>
        <template v-else-if="scope.column.property === 'isVirtualCabinet'">
          <span>{{ scope.row.isVirtualCabinet == '0'?'自拼':'非自拼' }}</span>
        </template>
          <template v-else-if="scope.column.property==='week'">
              <span><a
                  style="color: #00a0e9"
                  @click.prevent="infomation(scope.row.containerId)"
              >{{ scope.row.week }}</a></span>
            </template>
      </template>


    </f-table>
    <el-row class="f-text-right f-p-v-8">
      <el-pagination
          v-bind="ipagination"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange">
      </el-pagination>
    </el-row>
    </el-card>

  </div>
</template>

<script>
import { putAction, deleteAction,getallusers } from '@/api/manage'
import QueryMixins from "@/mixins/QueryMixins.js"
import {getAction, postAction} from "../../api/manage";
import { setUpRouteUtil } from '@/utils/util';
export default {
  name: 'Frozen',
  mixins: [QueryMixins],
  components: {

  },
  data() {
    return {
      visible: false,
      row: {},
      props:{
        userName:{
          type :String
        }
      },
      columns: [
        {
          type: "selection",
        },
          {
              prop: 'action',
              label: '操作',

          },
        {
          prop: 'orderNo',
          label: '订单号',

        },
          {
          prop: 'week',
          label: '周期',
        },
        {
          prop: 'containerNo',
          label: '柜号',

        },
        {
          prop: 'transferNo',
          label:"转运单号",

        },

        {
          prop: 'isStick',
          label:"需要贴标",

        },
          {
              prop: 'delivery',
              label:"hold货状态",

          },
        {
          prop: 'isTransfer',
          label:"是否已同步",

        },
        {
          prop: 'failedReason',
          label:"失败原因",

        },
           {
          prop: 'isVirtualCabinet',
          label: '是否自拼',
        },
        {
          prop: 'customer',
          label: '客户名',

        },
        {
          prop: 'country',
          label: '国家',

        },
          {
              prop: 'fbaCode',
              label: 'FBA CODE',

          },
        {
          prop: 'carStatus',
          label: '排车状态',

        },
        {
          prop: 'orderStatus',
          label: '海外仓订单状态',

        },
        {
          prop: 'carTime',
          label: '排车时间',
        },
        {
          prop: 'reverseNo',
          label: '预约号',

        },


        {
          prop: 'reversedTime',
          label: '预计派送时间',

        },
        {
          prop: 'reversedCarTime',
          label: '预计发车时间',

        },
        {
          prop: 'outWarehouseTime',
          label: '出仓时间',

        },
        {
          prop: 'outBoundTime',
          label: '出库时间',

        },
      ],
      url: {
          list: '/interface/fbaToOlc/queryFbaToOlcDataList',
          createTransferOrder: '/interface/fbaToOlc/createTransferOrder',
          changeToStick: '/interface/fbaToOlc/stick/updateOrderToStick',
          pushMore:'/interface/fbaToOlc/updateDuoFbaDataToOlc',
      },
        userlist:[],
        isUsePost:true,
dictCodes: ["is_default"],
    }
  },
  methods: {
      handlePushMore(){
          if (!this.selectionRows || this.selectionRows.length === 0) {
              this.$message.warning("请勾选订单在同步");
              return false;
          }
          let list = this.selectionRows.map(s=>s.orderNo);
          postAction(this.url.pushMore,list)
              .then(res=>{
                  this.$alert(`${res.message}`, '批量同步结果', {
                      dangerouslyUseHTMLString: true
                  });
                  this.handleSearch();
              })
      },
    handleStickMore(){
      this.$confirm(`确定变更为贴标订单吗?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "success",
        closeOnClickModal: false,
      }).then(()=>{
        postAction(this.url.changeToStick,this.selectionRows)
            .then(res=>{
              this.$message.success(res.message);
              this.handleQuery();
            })
      })
    },

    handleThaw(row) {
      this.$confirm(`确定同步订单${row.orderNo}到海外仓系统吗，该操作将会在海外仓系统生成转运单？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error',
        closeOnClickModal: false,
      }).then(() => {
        postAction(this.url.pushMore,[row.orderNo])
            .then(res=>{
              this.$alert(`${res.message}`, '同步结果', {
                dangerouslyUseHTMLString: true
              });
              this.handleSearch();
            })
      }).catch(err=>{
        this.$message.error(err.message);
        this.handleSearch();
      })
    },
    handleReset(){
      this.queryForm = {}
      // this.queryForm.cusId = this.$route.query.id
      this.ipagination.currentPage = 1

      this.handleQuery()
    },
     infomation(id) {
      // 对于路由不在左侧菜单中的选中菜单处理
        if(id) {
          setUpRouteUtil(this.$route.path, '/operation/cabinet/Edit');
          this.$router.push("/operation/cabinet/Edit?id=" + id);
        }

    },
  },
}
</script>

<style lang="scss" scoped>

</style>
