<!--快递待发提成-->
<template>
    <div class="main-full-content">
        <el-form class="multiline_select no_margin" ref="form" :model="queryForm" label-width="80px" size="medium">
            <el-card class="box-card">
                <el-row class="inputs_row">
                    <el-col :md="4" :sm="24">
                        <el-form-item label="订单号">
                            <el-input
                                    class="textarea_table"
                                    v-model="queryForm.orderNo"
                                    @keyup.enter.native="handleSearch"
                                    type="textarea"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="销账时间">
                            <el-date-picker
                                    class="input time"
                                    v-model="queryForm.settleTime"
                                    type="month"
                                    size="small"
                                    default-time="00:00:00"
                                    placeholder="开始时间"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    style="width: 100%"
                                    @click.stop.native
                            ></el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label-width="30px">
                            <el-button
                                    type="primary"
                                    icon="el-icon-search"
                                    @click="handleSearch"
                            >查询
                            </el-button
                            >
                            <el-button plain @click="handleReset">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>
        </el-form>
        <el-card class="box-card last_card">
            <div class="f-p-b-15">
                <el-button type="primary" size="medium" icon="el-icon-plus" @click="handleExportXls('快递报表')">导出</el-button>
            </div>
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="73vh"
                    :isRecordTableScroll="true"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange"
                    @queryChange="handleTableQueryChange"
            >
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'orderNo'">
                        <a style="color: #00a0e9" @click.prevent="$intoDocPage(scope.row.orderNo)"
                        >{{ scope.row.orderNo }}</a>
                    </template>
                    <template v-else-if="scope.column.property === 'customer'">
                        <a type="text" @click.prevent="$intoCustomerEditPage(scope.row.cusId)" style="color:#57a3f3;">{{scope.row.customer}}</a>
                    </template>
                    <template v-else-if="scope.column.property === 'commission'">
                        <span>{{parseDict(scope.row.commission,'commission')}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'cusUserType'">
                        <span>{{parseDict(scope.row.cusUserType,'user_type1')}}</span>
                    </template>

                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                >
                </el-pagination>
            </el-row>
        </el-card>
    </div>
</template>

<script>
    import {deleteAction, postAction, putAction} from "@/api/manage";
    import QueryMixins from "../../../mixins/QueryMixins";
    import { setUpRouteUtil } from '@/utils/util';

    export default {
        name: "Potential",
        mixins: [QueryMixins],
        data() {
            return {
                dictCodes: ['is_default','commission','user_type1'],
                ids: [],
                row: {},
                form: {},
                formLabelWidth: "100px",
                columns: [
                    {
                        type: "selection",
                        width: 50,
                        fixed: 'left'
                    },
                    {
                        prop: "salesman",
                        label: "业务",
                        minWidth: 200,
                        fixed: 'left'
                    },
                    {
                        prop: "customer",
                        label: "客户名",
                        minWidth: 200,
                        fixed: 'left'
                    },
                    {
                        prop: "orderNo",
                        label: "订单号",
                        minWidth: 200,
                        fixed: 'left'
                    },
                    {
                        prop: "endContry",
                        label: "目的国",
                        minWidth: 200,
                    },
                    {
                        prop: "houseCode",
                        label: "仓库代码",
                        minWidth: 200,
                    },
                    {
                        prop: "channel",
                        label: "渠道",
                        minWidth: 200,
                    },
                    {
                        prop: "ctn",
                        label: "件数",
                        minWidth: 200,
                        sortable: "custom",
                    },
                    {
                        prop: "realKg",
                        label: "收费重量",
                        minWidth: 200,
                        sortable: "custom",
                    },
                    {
                        prop: "shoukaunTime",
                        label: "确认收款日期",
                        minWidth: 200,
                        sortable: "custom",
                    },
                    {
                        prop: "deliverGoodsTime",
                        label: "发货时间",
                        minWidth: 200,
                        sortable: "custom",
                    },
                    {
                        prop: "commission",
                        label: "提成性质",
                        minWidth: 200,
                    },
                    {
                        prop: "cusUserType",
                        label: "客户类型",
                        minWidth: 200,
                    },
                ],
                url: {
                    list: "/order/kdOrderReport/kdList",
                    exportXlsUrl: "/file/kd1/dckdHasCommissionExcelVO",
                },
                 isUsePost:true
            };
        },
        methods: {

            intoDocpage(orderNo) {
                // 对于路由不在左侧菜单中的选中菜单处理
                setUpRouteUtil(this.$route.path, '/kdorder/Edit');
                this.$router.push('/kdorder/Edit?orderNo=' + orderNo);
            },
            handleReset() {
                this.queryForm = {};
                this.filterForm = {};
                this.queryForm.isWhether = '1';
                this.ipagination.currentPage = 1;
                this.handleQuery();
            },
        },
        created() {
            this.queryForm.isWhether = '1'
        },
    };
</script>

<style lang="scss" scoped>
</style>
