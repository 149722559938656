<template>
  <a-card>
    <a-table
      ref="table"
      size="middle"
      bordered
      rowKey="id"
      :columns="columns"
      filterMultiple="filterMultiple"
      :dataSource="opertList"
      :pagination="ipagination"
    >

    </a-table>
  </a-card>
</template>

<script>

  import { getAction,httpAction } from '@/api/manage'

  export default {
    name: "OperaRecordModal",
    data () {
      return {
        title: "操作记录",
        width: 600,
        visible: false,
        confirmLoading: false,
        opertList: [],
        url:{
          list: '/fbaOsOrder/getMoneyOutLogs',
        },
        columns:[
          {
            title:'描述',
            align:"center",
            width: 120,
            dataIndex: 'content',
            ellipsis: true,
          },
          {
            title:'操作人',
            align:"center",
            width: 120,
            dataIndex: 'creator',
            ellipsis: true,
          },
          {
            title: '时间',
            align: "center",
            width: 150,
            dataIndex: 'createTime'
          }
        ]
      }
    },
    methods: {
      /**
       * @description: 关闭modal
       */
      handleCancel() {
        this.visible = false;
      },
      /**
       * @description: 展示modal显示数据
       * @param {object} modalDatas 表单选中的数据
       */
      showMoneyOutDatas(modalDatas) {
        this.model = Object.assign({}, modalDatas);

        this.$nextTick(() => {
          getAction(this.url.list, {
            orderNo: modalDatas.orderNo
          })
            .then((res) => {
              this.opertList = res.result;
            })
            .catch((err) => {
              this.opertList = [];
            })
        })
      }
    },
    created () {

    },
  }
</script>
<style scoped>
.ope-content {
  max-height: 500px;
  min-height: 200px;
  overflow: auto;
  padding: 10px 0;
}
</style>
