<template>
  <el-dialog :title="title" :visible="visible" :width="dialogWidth" append-to-body
             custom-class="c-custom-dialog" :close-on-click-modal="false" :before-close="handleClose">
    <f-table v-if="type == 'log'" v-loading="loading" ref="GTable" border size="medium" row-key="id"
             class="c-th-has-bgcolor" :columns="columns" :data="tableData"
             :cell-style="{ 'text-align': 'center' }" tableHeight="66vh" @selection-change="handleSelectionChange"
             @sort-change="handleTableChange">
    </f-table>
    <f-table v-else-if="type == 'additional'" v-loading="loading" ref="GTable" border size="medium" row-key="id"
             class="c-th-has-bgcolor" :columns="additionalColumns" :data="additionalTableData"
             :cell-style="{ 'text-align': 'center' }" tableHeight="66vh" @selection-change="handleSelectionChange"
             @sort-change="handleTableChange">
    </f-table>
    <f-table v-else-if="type == 'total'" v-loading="loading" ref="GTable" border size="medium" row-key="id"
             class="c-th-has-bgcolor" :columns="totalColumns" :data="totalTableData"
             :cell-style="{ 'text-align': 'center' }" tableHeight="66vh" @selection-change="handleSelectionChange"
             @sort-change="handleTableChange">
    </f-table>
    <el-form v-else :model="addForm" :rules="rules" ref="addForm" label-width="150px" size="medium">
      <el-row>
        <el-col :span="24">
          <el-form-item prop="routeName" label="航线名称">
            <el-input v-model="addForm.routeName" maxlength="20" show-word-limit
                      placeholder="请输入航线名称"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item prop="cabinetTypeId" label="柜型">
            <f-select v-model="addForm.cabinetTypeId" :is-need="addForm.cabinetTypeId" :data-list="cabinetTypeDictList"
                      placeholder="请选择柜型"></f-select>
          </el-form-item>
        </el-col>

        <el-col :span="24">
          <el-form-item prop="feeId" label="费用类别">
            <f-select v-model="addForm.feeId" :is-need="addForm.feeId" :dict="'bd_fee_type'"
                      placeholder="请选择费用"></f-select>
          </el-form-item>
        </el-col>

        <el-col :span="24">
          <el-form-item prop="moneyType" label="币种">
            <f-select v-model="addForm.moneyType" :is-need="addForm.moneyType" :dict="'bd_cur'"
                      placeholder="请选择币种"></f-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item prop="price" label="价格">
            <el-input v-model.trim="addForm.price" type="number" placeholder="请输入价格"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="24">
          <el-form-item prop="remark" label="备注">
            <el-input v-model.trim="addForm.remark" placeholder="请输入备注" type="textarea" maxlength="350"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer">
      <el-button size="medium" @click="handleClose">取消</el-button>
      <el-button v-if="type !== 'log'" type="primary" size="medium" @click="handleSubmit">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {httpAction, getAction, postAction} from '@/api/manage'
import QueryMixins from "@/mixins/QueryMixins.js";
import {validateTrailerFee} from "../../../utils/validator";


export default {
  name: 'EditAdditionalFeeSetDialog',
  mixins: [QueryMixins],

  props: {
    visible: {
      type: Boolean
    },
    row: {
      type: Object,
      default: () => {
      }
    },
    // 弹窗类型
    type: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      disableMixinMounted: true,
      additionalTableData: [],
      totalTableData: [],
      dialogWidth: '',
      columns: [
        {
          prop: "detail",
          label: "详情",
        },
        {
          prop: "createTime",
          label: "创建时间",
        },
        {
          prop: "createBy",
          label: "创建人",
        },
      ],
      additionalColumns: [
        {
          prop: "routeName",
          label: "航线名称",
        },

        {
          prop: "cabinetType",
          label: "柜型",
        },
        {
          prop: "feeName",
          label: "费用类别",
        },
        {
          prop: "price",
          label: "价格",
        },
        {
          prop: "moneyType",
          label: "币种",
        },

        {
          prop: "createTime",
          label: "创建时间",
        },
        {
          prop: "remark",
          label: "备注",
        },
      ],
      totalColumns: [
        {
          prop: "feeName",
          label: "费用类别",
        },
        {
          prop: "departureName",
          label: "起运地",
        },
        {
          prop: "startPortName",
          label: "起运港",
        },
        {
          prop: "endPortName",
          label: "目的港",
        },
        {
          prop: "routeName",
          label: "航线",
        },
        {
          prop: "cabinetType",
          label: "柜型",
        },
        {
          prop: "price",
          label: "价格",
        },
        {
          prop: "moneyType",
          label: "币种",
        },
        {
          prop: "startTime",
          label: "有效开始时间",
        },
        {
          prop: "endTime",
          label: "有效结束时间",
        },
        {
          prop: "remark",
          label: "备注",
        },
        {
          prop: "createTime",
          label: "创建时间",
        },
      ],
      addForm: {},
      rules: {

        routeName: [
          {required: true, message: '请输入航线名称', trigger: 'change'},
        ],
        cabinetTypeId: [
          {required: true, message: '请选择柜型', trigger: 'change'},
        ],
        feeId: [
          {required: true, message: '请选择费用', trigger: 'change'},
        ],
        moneyType: [
          {required: true, message: '请选择币种', trigger: 'blur'},
        ],
        price: [
          {required: true, message: '请输入价格', trigger: 'blur'},
          {validator: validateTrailerFee, trigger: 'change'}
        ],

      },
      url: {
        add: '/sys/additional/fee/set/addOne',
        edit: '/sys/additional/fee/set/editOne',
        list: '/sys/additional/fee/set/queryLogsById',
        getAdditionalList: '/sys/additional/fee/set/getListByCabinetTypeAndRouteName',
        getTotalList: '/sys/ocean/fee/set/getTotalListByRow',
        getOneAdditionalFeeSetVoById: '/sys/additional/fee/set/getOneAdditionalFeeSetVoById',
      },
      cabinetTypeDictList: []
    }
  },
  watch: {
    visible(val) {
      this.dialogWidth = '560px';
      if (val && this.row.id) {
        if (this.type === 'log') {
          this.initData = {
            id: this.row.id,
          }
          this.dialogWidth = '50%';
          this.handleReset();

        } else if (this.type === 'additional') {
          this.dialogWidth = '60%'
          getAction(this.url.getAdditionalList, {cabinetType: this.row.cabinetType,routeName:this.row.routeName})
              .then(res => {
                this.additionalTableData = res.result;
              })
        } else if (this.type === 'total') {
          this.dialogWidth = '80%'
          postAction(this.url.getTotalList, this.row)
              .then(res => {
                this.totalTableData = res.result;
              })
        } else {
          getAction(this.url.getOneAdditionalFeeSetVoById, {id: this.row.id})
              .then(res => {
                this.addForm = res.result;

                this.cabinetTypeDictList = res.result.cabinetTypeDictList || [];

              })
        }
      } else {
        getAction(this.url.getOneAdditionalFeeSetVoById, {id: '没有id'})
            .then(res => {
              this.addForm = {};

              this.cabinetTypeDictList = res.result.cabinetTypeDictList || [];

            })
      }
    },

  },
  created() {
  },
  methods: {
    handleSubmit() {
      if (['log', 'additional', 'total'].indexOf(this.type) !== -1) {
        this.handleClose()
        this.$emit('ok')
      } else {
        let url = this.url.add
        if (this.row.id) {
          url = this.url.edit
        }
        this.$refs.addForm.validate((valid) => {
          if (valid) {
            httpAction(url, 'POST', this.addForm).then((res) => {
              this.$message({
                type: 'success',
                message: res.message
              })
              this.handleClose()
              this.$emit('ok')
            })
          }
        })
      }

    },
    handleClose() {
      this.$emit('update:visible', false)
      this.$refs.addForm && this.$refs.addForm.resetFields()
    },
  }
}
</script>

<style lang="scss" scoped></style>
