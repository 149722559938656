import Vue from 'vue'
import VueRouter from 'vue-router'
import BasicLayout from '@/components/Layout/BasicLayout'
import RouteView from "../components/Layout/RouteView";

import Login from '../views/login/Login.vue'
import AddActivitiBpmn from  "../views/isystem/activity/AddActivitiBpmn"
import ViewsActivitiBpmn from   "../views/isystem/activity/ViewsActivitiBpmn"
import ProcessDesignerIndex from  "../views/isystem/activity/ProcessDesignerIndex"
import store from '@/store'
import NProgress from 'nprogress'
import '@/style/nprogress.scss'
import { setPermissionListAll, isStaticRoute } from '@/utils/util';
import TypicalCase from "@/views/isystem/BaseData/TypicalCase";
import versionTood from '@/utils/versionUpdate'

Vue.use(VueRouter)
import { Message } from 'element-ui';
const routes = [
  {
    name: 'Login',
    path: '/login',
    component: Login
  },
  {
    path: '/',
    component: BasicLayout,
    children: [
      {
        path: '',
        name: 'Home',
        component: () => import(/* webpackChunkName: "home" */ '@/views/dashboard/Home'),
        meta: {
          title: '首页',
          keepAlive: true,
          path: '/'
        }
      },

      {
        path: '/dashboard/fileManagement',
        name: 'fileManagement',
        component: () => import(/* webpackChunkName: "home" */ '@/views/dashboard/fileManagement'),
        meta: {
          title: '文件管理',
          keepAlive: true,
          path: '/dashboard/fileManagement'
        }
      },
    ]
  },
  {
    name: 'AddActivitiBpmn',
    path: '/AddActivitiBpmn',
    // component: AddActivitiBpmn,
    component: ProcessDesignerIndex,
  },
  {
    name: 'ViewsActivitiBpmn',
    path: '/ViewsActivitiBpmn',
    component: ViewsActivitiBpmn,
  },
  {
    name: 'isystem',
    path: '/isystem',
    component: BasicLayout,
    children: [
      {
        name: 'PersonInfo',
        path: '/isystem/PersonInfo',
        component: () => import(/* webpackChunkName: "MenuManage" */ '@/views/isystem/PersonInfo'),
        meta: {
          title: '个人信息',
          keepAlive: true,
          path: '/isystem/PersonInfo'
        },
      },
      {
        name: 'TypicalCase',
        path: '/isystem/BaseData/TypicalCase',
        component: () => import(/* webpackChunkName: "MenuManage" */ '@/views/isystem/BaseData/TypicalCase'),
        meta: {
          title: '经典案例',
          keepAlive: true,
          path: '/isystem/BaseData/TypicalCase'
        },
      }
    ]
  },
  // 不在菜单中的路由
  {
    name: 'customer',
    path: '/customer',
    component: BasicLayout,
    children: [
      {
        name: 'customerEdit',
        path: '/customer/Edit',
        component: () => import(/* webpackChunkName: "MenuManage" */ '@/views/customer/Edit'),
        meta: {
          title: '客户编辑',
          keepAlive: true,
          path: '/customer/Edit'
        },
      }
    ]
  },
  {
    name: 'docpage',
    path: '/docpage',
    component: BasicLayout,
    children: [
      {
        name: 'Edit',
        path: '/docpage/Edit',
        component: () => import(/* webpackChunkName: "MenuManage" */ '@/views/docpage/Edit'),
        meta: {
          title: '订单编辑-客服',
          keepAlive: true,
          path: '/docpage/Edit'
        },
      },
      // {
      //   name: 'EditSale',
      //   path: '/docpage/EditSale',
      //   component: () => import(/* webpackChunkName: "MenuManage" */ '@/views/docpage/EditSale'),
      //   meta: {
      //     title: '订单编辑-销售',
      //     keepAlive: true,
      //     path: '/docpage/EditSale'
      //   },
      // },
      // {
      //   name: 'EditFCL',
      //   path: '/docpage/EditFCL',
      //   component: () => import(/* webpackChunkName: "MenuManage" */ '@/views/docpage/EditFCL'),
      //   meta: {
      //     title: '非拼订单-客服',
      //     keepAlive: true,
      //     path: '/docpage/EditFCL'
      //   },
      // },
      // {
      //   name: 'EditSaleFCL',
      //   path: '/docpage/EditSaleFCL',
      //   component: () => import(/* webpackChunkName: "MenuManage" */ '@/views/docpage/EditSaleFCL'),
      //   meta: {
      //     title: '非拼订单-销售',
      //     keepAlive: true,
      //     path: '/docpage/EditSaleFCL'
      //   },
      // }
    ]
  },
  {
    name: 'operation',
    path: '/operation',
    component: BasicLayout,
    children: [
      {
        name: 'Edit',
        path: '/operation/cabinet/Edit',
        component: () => import(/* webpackChunkName: "MenuManage" */ '@/views/operation/cabinet/Edit'),
        meta: {
          title: '编辑',
          keepAlive: true,
          path: '/operation/cabinet/Edit'
        },
      },

    ]
  },
  {
    name: 'kdorder',
    path: '/kdorder',
    component: BasicLayout,
    children: [
      {
        name: 'Edit',
        path: '/kdorder/Edit',
        component: () => import(/* webpackChunkName: "MenuManage" */ '@/views/kdorder/Edit'),
        meta: {
          title: '快递订单编辑',
          keepAlive: true,
          path: '/kdorder/Edit'
        },
      }
    ]
  },
  {
    name: 'supplier',
    path: '/supplier',
    component: BasicLayout,
    children: [
      {
        name: 'Edit',
        path: '/supplier/Edit',
        component: () => import(/* webpackChunkName: "MenuManage" */ '@/views/supplier/Edit'),
        meta: {
          title: '编辑',
          keepAlive: true,
          path: '/supplier/Edit'
        },
      }
    ]
  },
  {
    name: 'invoiceEdit',
    path: '/kdorder/invoiceEdit',
    component: () => import('@/views/kdorder/invoiceEdit'),
    meta: {
      title: '发票编辑',
      keepAlive: true,
      path: '/kdorder/invoiceEdit'
    },
  },
  {
    name: 'systemUpdates',
    path: '/systemUpdates',
    component: () => import('@/views/systemUpdates')
  },
]
/**
 * 重写路由的push方法
 * 解决，相同路由跳转时，报错
 * 添加，相同路由跳转时，触发watch (针对el-menu，仅限string方式传参，形如"view?id=5")
 */
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace (location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch(err => err)
}

const router = new VueRouter({
    mode:'history',
    routes
});
//跳转或加载路由时报错 Loading chunk {n} failed
router.onError((error)=>{
  console.log('路由加载报错', error);
  // 前端代码发布导致文件无法找到需要重新触发刷新
  const patternRelease = /Loading chunk chunk-(.*)+ failed/g;
  const patternReleasea = /Loading chunk (.*)+ failed/g;
  const isChunkLoadFailedRelease = error.message.match(patternRelease) || error.message.match(patternReleasea);
  if (isChunkLoadFailedRelease) {
    Message({
      type: "warning",
      message: '系统已升级，正在刷新本地存储，请稍候...',
    });
    location.reload();
  }
  //当捕获到加载模块错误时，重新渲染该目标模块
  const pattern = /Loading chunk (\d) + failed/g;
  const isChunkLoadFailed = error.message.match(pattern);
  const targetPath = router.history.pending.fullPath;
  if (isChunkLoadFailed) {
    router.replace(targetPath);
  }
})

router.beforeEach(async (to, from, next) => {
  NProgress.start()
  const hasToken = localStorage.getItem('TOKEN')
  console.log(hasToken,'token是否存在')
  store.commit('setUpRoute', '');
  // 已登录
  if (hasToken) {
      console.log('测试按钮权限',location.pathname.substring(1))
      if (to.path === '/login') { // 重定向至首页
        console.log('重新定向到首页')
          next('/')
          NProgress.done()
      } else {
          console.log('重新定向到其他页面', store.state.upRoute);
          // 对于路由不在左侧菜单中的选中菜单处理
          if(localStorage.getItem('menuUpRoute')) {
            let menuUpRoute = JSON.parse(localStorage.getItem('menuUpRoute'));
            menuUpRoute.forEach(element => {
              if(element.to == to.path) {
                store.commit('setUpRoute', element.from);
              }
            });
          }
          if (store.state.permissionList.length === 0) {
            console.log(store.state.permissionList.length,'permissionList长度为0')
              try {
                  // 当前用户可以访问的路由
                  setPermissionListAll().then(res => {
                    // 继续路由切换，确保addRoutes完成
                    console.log(router.routes,'route');
                    isStaticRoute(to.path, routes) ? next() : next({ ...to, replace: true });
                    NProgress.done();
                  }).catch(async err => {
                    // 出错需重置令牌并重新登录（令牌过期、网络错误等原因）
                    await store.dispatch('user/resetToken')
                    next(`/login?redirect=${to.path}`)
                  });
              } catch (error) {
                 console.log(error,'捕获错误')
                // 出错需重置令牌并重新登录（令牌过期、网络错误等原因）
                await store.dispatch('user/resetToken')
                next(`/login?redirect=${to.path}`)
              }
          } else {
            console.log(store.state.permissionList.length,'permissionList长度不为0')
              next()
              NProgress.done()
          }
      }
  } else { // 未登录
      if (from.path === '/login') {
          next(false)
          NProgress.done()
      } else if (to.path === '/login') {
          next()
          NProgress.done()
      } else {
          next(`/login?redirect=${to.path}`)
          NProgress.done()
      }
  }
})
router.afterEach((to, from) => {
  //判断当前代码版本是否与服务器中代码版本一致，如不一致则刷新页面获取最新
  versionTood.isNewVersion();
  // 页面跳转, 动态tab的生成
  const { path, fullPath, meta } = to
  if ((path && path !== '/login')) {
      // 优化每点击一个菜单就新增一个排列，需刷新菜单标签才显示正常的bug
      // if (!meta._blank && !store.state.routesMap[fullPath]) {
          store.dispatch('addRoute', to)
      // }
  }
  NProgress.done()
})
export default router
