<template>
    <div class="main-full-content">
        <el-form class="input_top" label-width="80px" size="medium" @submit.native.prevent>
            <el-card class="box-card">
                <el-row>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="名字" label-width="120px">
                            <el-input v-model="queryForm.name"
                                      @keyup.enter="handleSearch">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="7">
                        <el-form-item label="有效期">
                            <div class="f-flex">
                                <el-date-picker
                                        class="f-flex-1"
                                        v-model="queryForm.startTime"
                                        type="datetime"
                                        size="small"
                                        placeholder="开始时间"
                                        default-time="00:00:00"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                >
                                </el-date-picker>
                                <span class="f-p-h-2">~</span>
                                <el-date-picker
                                        class="f-flex-1"
                                        v-model="queryForm.endTime"
                                        type="datetime"
                                        size="small"
                                        placeholder="结束时间"
                                        default-time="23:59:59"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                >
                                </el-date-picker>
                            </div>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label-width="30px">
                            <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询
                            </el-button>
                            <el-button plain @click="handleReset">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>
        </el-form>
        <el-card class="box-card last_card">
            <div class="f-p-b-15">
                <el-button type="primary" size="medium" icon="el-icon-plus" @click="handleEdit({})"
                           v-if="buttonShowList['新增']">新增
                </el-button>
                <el-button :disabled="selectionRows.length === 0" type="primary" size="medium" icon="el-icon-plus"
                           @click="handleBatDel()" v-if="buttonShowList['批量删除']">批量删除
                </el-button>

            </div>
            <f-table v-loading="loading" ref="GTable" border size="medium" row-key="id" class="c-th-has-bgcolor"
                     :columns="columns" :data="tableData" :show-operation="true"
                     :cell-style="{ 'text-align': 'center' }"
                     tableHeight="66vh" @selection-change="handleSelectionChange" @sort-change="handleTableChange">
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'action'">
                        <el-button type="text" @click="handleEdit(scope.row)"
                                   v-if="buttonShowList['编辑']">编辑
                        </el-button>
                    </template>
                    <template v-else-if="scope.column.property === 'url'">
                        <img class="company_img" @click="() => { imgUrl = scope.row.url; visibleFile = true; }"
                             v-if="scope.row.url" :src="scope.row.url" alt="">
                    </template>
                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination v-bind="ipagination" layout="total, sizes, prev, pager, next, jumper"
                               @size-change="handleSizeChange" @current-change="handleCurrentChange">
                </el-pagination>
            </el-row>
        </el-card>

        <edit-client-login-image-dialog :visible.sync="addVisible"
                                        :type="type"
                                        :row="row"
                                        @ok="handleSearch">
        </edit-client-login-image-dialog>
        <preview-img :visible="visibleFile" :imgUrl="imgUrl"
                     @setImgVisible="(val) => { imgUrl = ''; visibleFile = val }"></preview-img>
    </div>
</template>

<script>
    import {postAction} from "@/api/manage";
    import QueryMixins from "@/mixins/QueryMixins.js";
    import EditClientLoginImageDialog from "./components/EditClientLoginImageDialog";

    export default {
        name: "ClientLoginImage",
        mixins: [QueryMixins],
        components: {
            EditClientLoginImageDialog
        },
        data() {
            return {
                visibleFile: false,
                imgUrl:'',
                loading: false,
                addVisible: false,
                authVisible: false,
                type: '',
                isUsePost: true,
                row: {},
                options: [],
                id: "",
                columns: [
                    {
                        type: "selection",
                        width: 50,
                    },
                    {
                        prop: "name",
                        label: "名字",
                    },
                    {
                        prop: "url",
                        label: "图片",
                    },

                    {
                        prop: "startTime",
                        label: "有效开始时间",
                    },
                    {
                        prop: "endTime",
                        label: "有效结束时间",
                    },
                    {
                        prop: "createTime",
                        label: "创建时间",
                    },
                    {
                        prop: "action",
                        label: "操作",
                        width: 80,
                    },
                ],
                url: {
                    list: "/sys/ClientLoginImage/getIPage",
                    deleteBatch: '/sys/ClientLoginImage/delList',
                },
            };
        },
        methods: {

            handleBatDel() {
                this.$confirm("确定删除？", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "error",
                    closeOnClickModal: false,
                })
                    .then(() => {
                        postAction(this.url.deleteBatch, this.selectedRowKeys).then((res) => {
                            this.$message({
                                type: "success",
                                message: res.message,
                            });
                            this.handleQuery();
                        });
                    })
                    .catch(() => {
                    });
            },

            handleEdit(row, type) {
                this.type = type;
                this.row = row;
                this.addVisible = true;
            },
        },
        computed: {
            buttonShowList() {
                return {
                    '批量删除': this.$btnIsShow('forestMarket/ClientLoginImage', '0', '批量删除'),
                    '新增': this.$btnIsShow('forestMarket/ClientLoginImage', '0', '新增'),
                    '编辑': this.$btnIsShow('forestMarket/ClientLoginImage', '1', '编辑'),
                }
            }
        },
    };
</script>

<style lang="scss" scoped>
.company_img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    cursor: pointer;
}
</style>
