<template>

  <div class="main-full-content">
    <el-form ref="form" :model="queryForm" label-width="120px" size="medium" @submit.native.prevent>
      <el-card class="box-card">
        <el-row>
          <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="工作单号">
              <el-input v-model="queryForm.orderNo" @keyup.enter.native="handleQuery" type="textarea"></el-input>
            </el-form-item>
          </el-col>
          <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="3">
            <el-form-item label-width="30px" style="width: 400px">
              <el-button size="medium" type="primary" icon="el-icon-search" @click="handleQuery">查询
              </el-button>
              <!-- <el-divider direction="vertical"></el-divider> -->
              <el-button size="medium" plain @click="handleReset">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>

    </el-form>
    <el-card class="box-card last_card">
      <!--            <div class="f-p-b-15">-->
      <!--                <el-button size="medium" type="primary" @click="noConvertBill">非折合账单</el-button>-->
      <!--                <el-button size="medium" type="primary" @click="convertBill">折合账单</el-button>-->
      <!--            </div>-->
      <f-table
          v-loading="loading"
          ref="GTable"
          border
          size="medium"
          row-key="id"
          class="c-th-has-bgcolor"
          :columns="columns"
          :data="tableData"
          :cell-style="{ 'text-align': 'center' }"
          tableHeight="72vh"
          :isRecordTableScroll="true"
          @selection-change="handleSelectionChange"
          @sort-change="handleTableChange">
        <template v-slot="scope">
          <!--                    <template v-if="scope.column.property === 'action'">-->
          <!--                        <el-button type="text">取消入库</el-button>-->
          <!--                    </template>-->
          <template v-if="scope.column.property === 'orderNo'">
            <a
                style="color: #00a0e9"
                @click.prevent="$intoDocPage(scope.row.orderNo)"
            >{{ scope.row.orderNo }}</a
            >
          </template>
        </template>
      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination
            v-bind="ipagination"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange">
        </el-pagination>
      </el-row>
    </el-card>

  </div>


</template>
<script>
import QueryMixins from "../../mixins/QueryMixins"
import { setUpRouteUtil } from '@/utils/util';


export default {
  mixins: [QueryMixins],
  components: {},
  data() {
    return {

      editStartOptions: {
        disabledDate: time => {
          if (!this.form.etime) {
            // time = new Date(time.replace(/-/g, '/'));
            return time.getTime() < new Date(1970 - 1 - 1).getTime();   //禁止选择1970年以前的日期
          } else {
            return time.getTime() > new Date(this.form.etime);
          }
        }
      },
      editStopOptions: {
        disabledDate: time => {
          // time = new Date(time.replace(/-/g, '/'));
          return (
              time.getTime() < new Date(this.form.stime) ||
              time.getTime() < new Date(1970 - 1 - 1).getTime()    //禁止选择1970年以前的日期
          );
        }
      },
      selectedRowKeys: [],
      selectionRows: [],
      selectedNos: [],
      loading: false,
      form: {},
      columns: [
        // {
        //     type: 'selection',
        //     width: 50
        // },
        // {
        //     prop: 'action',
        //     label: '操作',
        // },
        {
          prop: 'orderNo',
          label: '工作单号',
          minWidth: 90,
        },
        {
          prop: 'customer',
          label: '客户',

        },
        {
          prop: 'location',
          label: '库位',

        },

        {
          prop: 'warehouse',
          label: '仓库名',

        },
        {
          prop: 'operationCount',
          label: '入库件数/卡板',

        },
        {
          prop: 'cmb',
          label: '入库体积',

        },
        {
          prop: 'createTime',
          label: '入库时间',

        },
        {
          prop: 'createBy',
          label: '录入人',

        },
        {
          prop: 'doc',
          label: '客服',

        },
        {
          prop: 'operator',
          label: '操作',

        },
        {
          prop: 'salesman',
          label: '销售',

        },
      ],

      url: {
        list: '/interface/warehouse/getFbaWarehouseInLogIPage',
      },
      isUsePost:true,

    };
  },
  computed: {},
  created() {
  },
  methods: {

  },
};
</script>
<style scoped>
.el-form-item {
  margin-bottom: 22px;
}
</style>