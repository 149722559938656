<template>
  <div>
    <el-card class="box-card">
      <el-form class="multiline_select" ref="form" :model="queryForm" label-width="80px" size="medium">
        <el-card class="box-card">
          <el-row>
            <el-col :xs="24" :sm="12" :md="12" :lg="6">
              <el-form-item label="标题">
                <el-input v-model="queryForm.title"
                          @keyup.enter.native="handleSearch"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="6">
              <el-form-item label="发布人">
                <el-input v-model="queryForm.createBy"
                          @keyup.enter.native="handleSearch"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="6" class="no_margin">
              <el-form-item label="部门">
                <f-select v-model="queryForm.publishDepartment" :is-need="queryForm.publishDepartment" :dict="'sys_depart'" @keyup.enter.native="handleSearch"></f-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="4">
              <el-form-item label-width="30px">
                <el-button
                    type="primary"
                    icon="el-icon-search"
                    @click="handleSearch"
                >查询
                </el-button
                >
                <el-button plain @click="handleReset">重置</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-card>
      </el-form>
    </el-card>
    <!--            <div class="bbb">-->

    <!--                <el-button type="primary" size="medium" @click="exportExcelPlus(url.exportExcelUrl,'扫码记录')"-->
    <!--                           v-if="$btnIsShow('warehouse2.0/ScanLog','0','导出扫码记录')">导出-->
    <!--                </el-button>-->
    <!--            </div>-->
    <el-card>
      <el-button plain type="primary" @click="handleAdd">发布</el-button>
      <f-table
          v-loading="loading"
          ref="GTable"
          border
          size="medium"
          row-key="id"
          class="c-th-has-bgcolor"
          :columns="columns"
          :data="tableData"
          @selection-change="handleSelectionChange"
          @sort-change="handleTableChange"
          :cell-style="{ 'text-align': 'center' }"
          tableHeight="73vh"
      >
        <template v-slot="scope">
          <template v-if="scope.column.property === 'isSee'">
            <span style="color: red" v-if="scope.row.isSee === '0'">未读</span>
            <span style="color: green" v-else>已读</span>
          </template>
          <template v-else-if="scope.column.property === 'action'">
            <el-button type="text" @click="handleUpdate(scope.row)"
                       v-if="buttonShowList.bj">编辑
            </el-button>
            <el-button type="text" style="color: red" @click="delCase(scope.row.id,scope.row.title)"
                       v-if="buttonShowList.sc">删除
            </el-button>
          </template>
          <template v-else-if="scope.column.property === 'title'">
            <a style="color: #00a0e9" @click.prevent="handleBB(scope.row.id)"
            >{{ scope.row.title }}</a>
          </template>
          <template v-else-if="scope.column.property === 'publishDepartment'">
            <span>{{parseDict(scope.row.publishDepartment,'sys_depart')}}</span>
          </template>
        </template>
      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination
            v-bind="ipagination"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange">
        </el-pagination>
      </el-row>
    </el-card>
    <el-dialog
        title="发布"
        :visible="addVis"
        width="50%"
        append-to-body
        custom-class="c-custom-dialog"
        :close-on-click-modal="false"
        :before-close="addClo">
      <el-form :model="form" :rules="rules" ref="form" label-width="94px" size="medium">
        <el-row>
          <el-col :span="24">
            <el-form-item prop="title" label="标题">
              <el-input v-model="form.title" maxlength="50" show-word-limit
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24">
            <el-form-item label="部门">
              <f-select v-model="form.publishDepartment" :is-need="form.publishDepartment" :dict="'sys_depart'"></f-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item prop="content" label="内容">
              <quill-editor
                  ref="cc"
                  v-model="form.content"
                  class="myQuillEditor"
                  :options="quillOption"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer">
            <el-button size="medium" @click="addClo">取消</el-button>
            <el-button type="primary" size="medium" @click="add">确定</el-button>
        </span>
    </el-dialog>

    <el-dialog
        title="详情"
        :visible="bbVis"
        width="50%"
        append-to-body
        custom-class="c-custom-dialog"
        :close-on-click-modal="false"
        :before-close="bbClo">


      <el-card>

        <p>
          <span style="float: left;margin-left: 10px;font-weight: bold" >发布人：</span>
          <span style="margin-left: 21px" v-text="bbForm.createBy" ></span>
        </p>

        <p>
          <span style="float: left;margin-left: 10px;font-weight: bold" >发布时间：</span>
          <span style="margin-left: 10px" v-text="bbForm.createTime" ></span>
        </p>
        <p>
          <span style="float: left;margin-left: 10px;font-weight: bold" >标题：</span>
          <span style="margin-left: 32px" v-text="bbForm.title" ></span>

        </p>
        <p>
          <span style="float: left;margin-left: 10px;font-weight: bold" >内容：</span>
          <span style="float:left;margin-left: 32px" v-html="bbForm.content" ></span>

        </p>

          <el-col :span="24" v-if="yaobuyaobb">
            <quill-editor
                ref="bbInfo"
                v-model="bbForm.bbInfo"
                class="myQuillEditor"
                :options="quillOption"
            />
            <el-button type="primary" @click="addTitle" style="float: right">发布评论</el-button>
          </el-col>
          <el-col v-else style="width: 100%;display: flex;justify-content: flex-end;align-items: center;">

            <el-tooltip  class="item" effect="dark" content="点赞" placement="top-start" >
              <el-button class="no_margin"  type="text" size="medium" @click="addZz">
                <img class="save_img" src="@/assets/zz2.jpeg" alt=""></el-button>
            </el-tooltip>
            <span v-text="bbForm.zzCount" style="float: right"></span>
            <el-button type="primary" @click="shibushiyaobb" style="margin-left: 10px">评论</el-button>
          </el-col>


          <br>
      </el-card>

      <p style="color: #0000cc">交流评论区：</p>

      <el-card v-for="(i,d) in bbList">
        <span v-text="i.createBy" style="margin-right: 10px"></span> :
        <span v-html="i.content"></span>
        <span v-text="i.createTime" style="float: right"></span>
        <br>
      </el-card>

      <span slot="footer">
            <el-button size="medium" @click="bbClo">取消</el-button>
        </span>
    </el-dialog>
  </div>
</template>

<script>
import QueryMixins from "@/mixins/QueryMixins.js";
import {getAction, postAction} from "@/api/manage";
import {quillEditor} from "vue-quill-editor";
import quillConfig from "@/utils/quill-config";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

export default {
  name: "TypicalCase",
  mixins: [QueryMixins],
  components: {quillEditor},
  data() {
    return {
      dictCodes: ["sys_depart"],
      isUsePost: true,
      quillOption: quillConfig,
      columns: [
        // {
        //     type: "selection",
        // },
        // {
        //     prop: "action",
        //     label: "操作",
        // },
        {
          prop: "isSee",
          label: "是否阅读",
        },
        {
          prop: "createTime",
          label: "发布时间",
        },
        {
          prop: "title",
          label: "标题",
        },
        {
          prop: "seeCount",
          label: "浏览次数",
        },
        {
          prop: "bbCount",
          label: "评论次数",
        },
        {
          prop: "zzCount",
          label: "点赞次数",
        },
        {
          prop:"publishDepartment",
          label: "发布部门",
        },
        {
          prop: "createBy",
          label: "发布人",
        },
        {
          prop: "action",
          label: "操作",
        },


      ],
      url: {
        //查询列表的接口
        list: "/sys/typicalCases/getCaseIPage",
        //
        // //导出excel的接口
        // exportExcelUrl: '/file/export/excel/warehouse/scanLogExcel',
        add: '/sys/typicalCases/addTitle',
        addTalk: '/sys/typicalCases/addTalk',
        seeDetail: '/sys/typicalCases/seeDetail',
        getTalkList: '/sys/typicalCases/getTalkList',
        delCase: '/sys/typicalCases/delCase',
        updateCase: '/sys/typicalCases/updateCase',
        getOneCase: '/sys/typicalCases/getOneCase',
        addZz: '/sys/typicalCases/addZz',
        getZzCount: '/sys/typicalCases/getZzCount',
      },
      addVis: false,
      bbVis: false,
      rules: {
        title: [
          {required: true, message: '请输入标题', trigger: 'change'}
        ],
        content: [
          {required: true, message: '请输入内容', trigger: 'change'}
        ],
      },
      bbRules: {

        bbInfo: [
          {required: true, message: '请输入评论内容', trigger: 'change'}
        ],
      },
      bbForm: {},
      bbList: [],
      selectedId: '',
      yaobuyaobb: false,
    };
  },
  methods: {
    getZz(){
      getAction(this.url.getZzCount,{id:this.bbForm.id})
        .then(res=>{
          this.bbForm.zzCount = res.result;
        })
    },
    addZz(){
      postAction(this.url.addZz,this.bbForm)
        .then(res=>{
          this.$message({type: 'success', message: res.message});
          this.getZz();
        })
    },
    handleUpdate(row) {
      getAction(this.url.getOneCase, {id: row.id})
          .then(res => {
            this.form = res.result;
            this.addVis = true;
          })

    },
    delCase(id, title) {
      this.$confirm(`确定删除${title}`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "success",
        closeOnClickModal: false,
      }).then(() => {
        postAction(this.url.delCase + "?id=" + id)
            .then(res => {
              this.$message({type: 'success', message: res.message});
              this.handleSearch();
            })
      })
    },
    addTitle() {
      if (!this.bbForm.bbInfo || this.bbForm.bbInfo.length <= 0) {
        this.$message({type: 'warning', message: '请输入内容'});
        return false;
      } else {
        postAction(this.url.addTalk + "?caseId=" + this.selectedId, {
          caseId: this.selectedId,
          content: this.bbForm.bbInfo
        }).then(res => {
              this.yaobuyaobb = false;
              // this.handleBB(this.selectedId);
              this.getTalkList(this.selectedId);
            })
      }


    },
    getTalkList(id) {
      getAction(this.url.getTalkList, {caseId: id})
          .then(res => {
            this.bbList = res.result;
          })
    },
    shibushiyaobb() {
      this.yaobuyaobb = !this.yaobuyaobb;
      this.bbForm.bbInfo = '';
    },
    handleBB(id) {
      this.selectedId = id;
      this.yaobuyaobb = false;
      getAction(this.url.seeDetail, {id: id})
          .then(res => {
            this.bbForm = res.result && res.result.typicalCases;
            this.bbList = res.result && res.result.typicalCasesCommentList;
          })
      this.bbVis = true;
    },
    bbClo() {
      this.bbVis = false;
      this.handleSearch();
    },
    handleAdd() {
      this.form = {};
      this.addVis = true;
    },
    addClo() {
      this.addVis = false;
    },
    add() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          let url = this.url.add;
          if (this.form.id) {
            url = this.url.updateCase;
          }
          postAction(url, this.form)
              .then(res => {
                this.$message({type: 'success', message: res.message});
                this.addVis = false;
                this.handleSearch();
              })
        }
      })

    },
  },
  computed:{
    buttonShowList() {
      return {
        'bj':this.$btnIsShow('isystem/BaseData/TypicalCase','1','编辑'),
        'sc':this.$btnIsShow('isystem/BaseData/TypicalCase','1','删除'),
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.bbb {
  display: flex;
}

.dialog-footer {
  text-align: right !important;
}

.margin_right_10 {
  margin-right: 10px;
}

.ql-editor {
  height: 200px;
}

.ql-container {
  height: 400px;
}
.save_img {
  width: 40px;
  height: 44px;
}
.woshifuqin{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
</style>
