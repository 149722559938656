<template>
    <div class="main-full-content">

        <el-form class="multiline_select" ref="form" :model="queryForm" label-width="80px" size="medium">
            <el-card class="box-card">
                <el-row class="inputs_row">
                    <el-col :xs="24" :sm="12" :md="12" :lg="8">
                        <el-form-item label="订单号">
                            <el-input v-model="queryForm.orderNo" @keyup.enter.native="handleCheck" type="textarea"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="8">
                        <el-form-item label="客户">
                            <el-input v-model.trim="queryForm.username" @keyup.enter.native="handleCheck"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="8">
                        <el-form-item label="费用币种">
                            <f-select v-model="queryForm.moneyType" dict="bd_cur"></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="8">
                        <el-form-item label="下单时间">
                            <el-date-picker
                                class="f-flex-1"
                                v-model="otime"
                                type="datetimerange"
                                size="small"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                :picker-options="editStopOptions"
                                :default-time="['00:00:00', '23:59:59']"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                format="yyyy-MM-dd HH:mm:ss"
                                unlink-panels
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="8">
                        <el-form-item label="录入时间">
                            <el-date-picker
                                class="f-flex-1"
                                v-model="stime"
                                type="datetimerange"
                                size="small"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                :picker-options="editStartOptions"
                                :default-time="['00:00:00', '23:59:59']"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                format="yyyy-MM-dd HH:mm:ss"
                                unlink-panels
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="8">
                        <el-form-item label="发货时间">
                            <el-date-picker
                                class="f-flex-1"
                                v-model="kdDeliveryTime"
                                type="datetimerange"
                                size="small"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                :picker-options="editStartOptions"
                                :default-time="['00:00:00', '23:59:59']"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                format="yyyy-MM-dd HH:mm:ss"
                                unlink-panels
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>

                </el-row>
                <el-row>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="业务员">
                            <f-select v-model="queryForm.salesmanId" :dict="'sys_user_id'"></f-select>
                        </el-form-item>
                    </el-col>
                  <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="4">
                    <el-form-item label="客服">
                      <f-select v-model="queryForm.docId" :is-need="queryForm.docId" :dict="'sys_user_id'"></f-select>
                    </el-form-item>
                  </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="3">
                        <el-form-item label-width="30px" style="width: 500px">
                            <el-button
                                type="primary"
                                icon="el-icon-search"
                                @click="handleCheck"
                            >查询
                            </el-button
                            >
                            <el-button plain @click="handleReset">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>
        </el-form>

        <el-card class="box-card last_card">
            <el-row style="display: inline">
<!--                <div style="width: 15%">-->
<!--                        <el-select v-model="queryForm.statementType">-->
<!--                            <el-option value="0" label="个人客户欠款账单">-->
<!--                            </el-option>-->
<!--                            <el-option value="1" label="月客户欠款账单">-->
<!--                            </el-option>-->
<!--                            <el-option value="2" label="账单明细excel导出">-->
<!--                            </el-option>-->
<!--                        </el-select>-->
<!--                    <f-select v-model="queryForm.statementType"-->
<!--                              :is-need="queryForm.statementType"-->
<!--                              :dict="'customerBillType'"-->
<!--                              placeholder="请选择">-->
<!--                    </f-select>-->
<!--                </div>-->
                <div>
                    <el-col>
                        <div class="f-p-b-15">
                            <el-button
                                size="medium"
                                type="primary"
                                @click="exportExcel('0')"
                                v-if="buttonShowList.dckhqkzd"
                            >导出客户欠款账单</el-button>

                            <el-button
                                size="medium"
                                type="primary"
                                @click="exportExcel('1')"
                                v-if="buttonShowList.dcykhqkzd"
                            >导出月客户欠款账单</el-button>

                            <el-button size="medium" type="primary" @click="exportExcel('2')"
                                       v-if="buttonShowList.dckhqkzdfymx"
                            >导出客户欠款账单费用明细</el-button>
                        </div>
                    </el-col>
                </div>


            </el-row>

            <f-table
                v-loading="loading"
                ref="GTable"
                border
                size="medium"
                row-key="id"
                class="c-th-has-bgcolor"
                :columns="columns"
                :data="tableData"
                :cell-style="{ 'text-align': 'center' }"
                tableHeight="57vh"
                :isRecordTableScroll="true"
                @selection-change="handleSelectionChange"
                @sort-change="handleTableChange"
            >
                <template v-slot="scope">

                    <template v-if="scope.column.property === 'checkStatus'">
                        <span v-if="scope.row.checkStatus === '3'">已销账</span>
                    </template>
                    <template v-else-if="scope.column.property === 'locker'">
                        <span v-if="scope.row.locker === '1'" style="color: #ce8483">费用已锁</span>
                        <span v-else-if="scope.row.locker === '0' || scope.row.locker === null" style="color: #398439">未锁定</span>
                    </template>
                    <template v-else-if="scope.column.property === 'type'">
                        <span v-if="scope.row.type === '1'">应付</span>
                        <span v-else-if="scope.row.type === '0'">应收</span>
                    </template>
                    <template v-else-if="scope.column.property === 'orderNo'">
                        <a style="color: #00a0e9" @click.prevent="$intoDocPage(scope.row.orderNo)">{{ scope.row.orderNo }}</a>
                    </template>
                    <template v-else-if="scope.column.property === 'realAgent'">
                        <a type="text" @click.prevent="$intoCustomerEditPage(scope.row.cusId)" style="color:#57a3f3;">{{scope.row.realAgent}}</a>
                    </template>

                </template>


            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                    v-bind="ipagination"
                    layout="total, sizes, prev, pager, next, jumper"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                >
                </el-pagination>
            </el-row>
        </el-card>

    </div>
</template>
<script>

import {getAction, postAction} from "@/api/manage";
import QueryMixins from "@/mixins/QueryMixins";
import { setUpRouteUtil } from '@/utils/util';


export default {
    mixins: [QueryMixins],
    components: {
    },
    data() {
        return {
            // tableData:[],
            // queryForm:{},
            // disableMixinMounted:true,
            editStartOptions: {
                disabledDate: (time) => {
                    if (!this.form.etime) {
                        // time = new Date(time.replace(/-/g, '/'));
                        return time.getTime() < new Date(1970 - 1 - 1).getTime(); //禁止选择1970年以前的日期
                    } else {
                        return time.getTime() > new Date(this.form.etime);
                    }
                },
            },
            editStopOptions: {
                disabledDate: (time) => {
                    // time = new Date(time.replace(/-/g, '/'));
                    return (
                        time.getTime() < new Date(this.form.stime) ||
                        time.getTime() < new Date(1970 - 1 - 1).getTime() //禁止选择1970年以前的日期
                    );
                },
            },
            boatStatus: true,
            selectedRowKeys: [],
            selectionRows: [],
            selectedNos: [],
            loading: false,
            form: {},
            columns: [
                {
                    type: "selection",
                    width: 50,
                },
                // {
                //     prop: "action",
                //     label: "操作",
                //     width: 130,
                // },
                {
                    prop: "orderNo",
                    label: "工作单号",
                    width: 140,
                },
                {
                    prop: "orderCreateTime",
                    label: "下单时间",
                },
                {
                    prop: "settleCreateTime",
                    label: "费用时间",
                },
                {
                    prop: "kdDeliveryTime",
                    label: "发货时间",
                },
                {
                    prop: "settleCreateBy",
                    label: "录入人",
                },
                {
                    prop: "salesman",
                    label: "业务",
                },
                {
                    prop: "doc",
                    label: "客服",
                },
                {
                    prop: "customer",
                    label: "客户",
                    width: 140,
                },
                {
                    prop: "settlement",
                    label: "结算对象",
                    width: 140,
                },
                {
                    prop: "feename",
                    label: "费用中文",
                },
                {
                    prop: "price",
                    label: "价格",
                },
                {
                    prop: "quantity",
                    label: "数量",
                },
                {
                    prop: "moneyType",
                    label: "费用币种",
                },
                {
                    prop: "total",
                    label: "合计",
                },
                {
                    prop: "realMoneyType",
                    label: "折合币种",
                },
                {
                    prop: "exchangeRate",
                    label: "汇率",
                },
                {
                    prop: "exchangeTotal",
                    label: "折合总价",
                },
                {
                    prop: "realPay",
                    label: "已收金额",
                },
                {
                    prop: "waitPay",
                    label: "待收金额",
                    headerType:'remark',
                    detail:'提示：待收余额将在销账之后更新',
                    width:100
                },
                {
                    prop: "deliveryAddress",
                    label: "送货地址",
                },
                {
                    prop: "logistics",
                    label: "最新物流",
                },
                {
                    prop: "remark",
                    label: "备注",
                    width: 150
                },


            ],
            imgVisible: false,
            imgUrl: '',
            url: {
                list: "/shipping/customerBill/kdList",
                exportCustomerBill: "/shipping/customerBill/exportCustomerKdBill",
                exportCustomerApplyInBill: "/shipping/customerBill/exportCustomerApplyInKdBill",
            },

            rules: {
                realCtn: [
                    {required: true, message: "请输入实际总件数", trigger: "blur"},
                    {pattern: /^[1-9]\d*$/, message: "只能输入整数", trigger: "blur"},
                ],
                realKg: [
                    {required: true, message: "请输入实际总重量", trigger: "blur"},
                    {
                        pattern:
                            /^(?=([1-9]{1,10}$|[0-9]{1,7}\.))(0|[1-9][0-9]*)(\.[0-9]{1,3})?$/,
                        message: "只能输入最多三位小数的数字",
                        trigger: "change",
                    },
                ],
            },
            stime: [],
            otime: [],
            kdDeliveryTime: [],
            btime: [],  //开船时间
            ltime: [],  //锁定时间
            rtime: [],  //销账时间
        };
    },
    computed: {
      buttonShowList() {
        return {
          'dckhqkzd':this.$btnIsShow('hyFinanceManage/modules/financialStatements/customerBill','0','导出客户欠款账单'),
          'dcykhqkzd':this.$btnIsShow('hyFinanceManage/modules/financialStatements/customerBill','0','导出月客户欠款账单'),
          'dckhqkzdfymx':this.$btnIsShow('hyFinanceManage/modules/financialStatements/customerBill','0','导出客户欠款账单费用明细'),
        }
      }
    },
    created() {
        // this.queryForm.statementType = '0'
        this.$set(this.queryForm,'statementType','2')
    },
    methods: {
        exportExcel(val){
            this.queryForm.statementType = val
          console.log('queryForm',this.queryForm)
            if((this.queryForm.orderNo==='' || this.queryForm.orderNo===undefined)
                && (this.queryForm.username==='' || this.queryForm.username===undefined)
                && (this.queryForm.moneyType==='' || this.queryForm.moneyType===undefined)
                && (this.queryForm.salesmanId==='' || this.queryForm.salesmanId===undefined)
                && (this.queryForm.docId==='' || this.queryForm.docId===undefined)
                && (this.stime===null || this.stime.length===0)
                && (this.otime===null || this.otime.length===0)){
                if (!this.selectedRowKeys || this.selectedRowKeys.length === 0) {
                    this.$message.warning("导出账单时，须填写一个或多个查询条件");
                    return;
                }
                return;
            }

            this.loading = true;

            if (this.stime && this.stime.length > 1) {
                this.queryForm.settleCreateTimeS = this.stime[0];
                this.queryForm.settleCreateTimeE = this.stime[1];
            }
            if (this.otime && this.otime.length > 1) {
                this.queryForm.orderCreateTimeS = this.otime[0];
                this.queryForm.orderCreateTimeE = this.otime[1];
            }
            if (this.kdDeliveryTime && this.kdDeliveryTime.length > 1) {
                this.queryForm.kdDeliveryTimeS = this.kdDeliveryTimeS[0];
                this.queryForm.kdDeliveryTimeE = this.kdDeliveryTimeS[1];
            }

            let form = {...this.queryForm, ...this.filterForm};
            let fileName = '客户欠款账单_'+ new Date().toLocaleDateString();
            form.selectIds = this.selectedRowKeys;
            // console.log('导出的条件',form)
            let url = this.url.exportCustomerBill
            if(this.queryForm.statementType==='2'){
                fileName= '客户欠款账单明细_'+ new Date().toLocaleDateString();
                url = this.url.exportCustomerApplyInBill
            }
            postAction(url, form, {responseType: 'blob'}).then((data) => {
                if (typeof window.navigator.msSaveBlob !== 'undefined') {
                    window.navigator.msSaveBlob(new Blob([data], {type: 'application/vnd.ms-excel'}), fileName + '.xls')
                } else {
                    let url = window.URL.createObjectURL(new Blob([data], {type: 'application/vnd.ms-excel'}));
                    let link = document.createElement('a');
                    link.style.display = 'none';
                    link.href = url;
                    link.setAttribute('download', fileName + '.xls');
                    document.body.appendChild(link);
                    link.click()
                    document.body.removeChild(link);//下载完成移除元素
                    window.URL.revokeObjectURL(url) //释放掉blob对象
                    this.loading = false;
                }
            })
        },

        orderTypeChange(){
            this.boatStatus = !(this.queryForm.orderType === '0');
        },
        boatTimeChange(){
            this.boatStatus = !(this.queryForm.orderType === '0');
        },
        setProof() {
            console.log("应收-生成凭证以下id的费用", this.selectionRows);
            if (this.selectedRowKeys.length === 0) {
                this.$message.warning("请勾选付款申请");
                return;
            }
            let count = [];
            this.selectionRows.forEach((item) => {
                count.push(item.id);
            });

            let arr = Array.from(new Set(count));
            console.log('', arr)
        },
        showApplyDetail(row) {
            // this.$refs.apply.showDetail(row);
            this.$refs.apply.onlyShowDetail(row);
        },
        //进入客服页
        intoDocpage(orderNo) {
            if (orderNo.indexOf("KD")!==-1){
                // 对于路由不在左侧菜单中的选中菜单处理
                setUpRouteUtil(this.$route.path, '/kdorder/Edit');
                this.$router.push("/kdorder/Edit?orderNo=" + orderNo);
            }else {
                // 对于路由不在左侧菜单中的选中菜单处理
                setUpRouteUtil(this.$route.path, '/docpage/Edit');
                this.$router.push("/docpage/Edit?orderNo=" + orderNo);
            }

        },
        handleCheckBill(row) {
            console.log(row);
            this.$refs.removeDialog.edit(row);
        },
        handleSelectionChange(selection) {
            // 多选
            console.log(selection);
            let arr = [];
            let nos = [];
            this.nameList = [];
            this.cnamelist = [];
            this.emailList = [];
            for (let i = 0; i < selection.length; i++) {
                arr.push(selection[i]["id"]);
                nos.push(selection[i]["orderNo"]);
                this.nameList.push(selection[i]["username"]);
                this.cnamelist.push(selection[i]["cname"]);
                this.emailList.push(selection[i]["email"]);
            }
            this.selectionRows = selection;
            this.selectedRowKeys = [...arr];
            this.selectedNos = [...nos];
        },
        handleReceive() {
            console.log("申请收款以下id的费用", this.selectedRowKeys);
        },
        handleReset() {
            this.queryForm = {};
            this.otime = "";
            this.stime = "";
            this.btime = "";
            this.ltime = "";
            this.rtime = "";
            this.kdDeliveryTime = "";
            this.ipagination.currentPage = 1;
            this.boatStatus = true
            this.queryForm.statementType = '0'
            this.handleQuery();
        },
        handleCheck() {
            this.loading = true;

            if (this.stime && this.stime.length > 1) {
                this.queryForm.settleCreateTimeS = this.stime[0];
                this.queryForm.settleCreateTimeE = this.stime[1];
            }else{
                this.queryForm.settleCreateTimeS = '';
                this.queryForm.settleCreateTimeE = '';
            }
            if (this.otime && this.otime.length > 1) {
                this.queryForm.orderCreateTimeS = this.otime[0];
                this.queryForm.orderCreateTimeE = this.otime[1];
            }else{
                this.queryForm.orderCreateTimeS = '';
                this.queryForm.orderCreateTimeE = '';
            }
            if (this.kdDeliveryTime && this.kdDeliveryTime.length > 1) {
                this.queryForm.kdDeliveryTimeS = this.kdDeliveryTime[0];
                this.queryForm.kdDeliveryTimeE = this.kdDeliveryTime[1];
            }else{
                this.queryForm.kdDeliveryTimeS = '';
                this.queryForm.kdDeliveryTimeE = '';
            }
            // if (this.btime && this.btime.length > 1) {
            //     this.queryForm.boatTimeS = this.btime[0];
            //     this.queryForm.boatTimeE = this.btime[1];
            // }else{
            //     this.queryForm.boatTimeS = '';
            //     this.queryForm.boatTimeE = '';
            // }
            // if (this.ltime && this.ltime.length > 1) {
            //     this.queryForm.lockTimeS = this.ltime[0];
            //     this.queryForm.lockTimeE = this.ltime[1];
            // }else{
            //     this.queryForm.lockTimeS = '';
            //     this.queryForm.lockTimeE = '';
            // }
            // if (this.rtime && this.rtime.length > 1) {
            //     this.queryForm.removeTimeS = this.rtime[0];
            //     this.queryForm.removeTimeE = this.rtime[1];
            // }else{
            //     this.queryForm.removeTimeS = '';
            //     this.queryForm.removeTimeE = '';
            // }
            let form = {...this.queryForm, ...this.filterForm};
            for (let item in form) {
                if (typeof form[item] === "string") {
                    form[item] = form[item].trim();
                    if (form[item] === "") {
                        form[item] = null;
                    }
                }
            }
            const {prop, order} = this.isorter;
            // 传入的参数
            const params = {
                ...form,
                column: prop,
                order: order.indexOf("asc") !== -1 ? "asc" : "desc",
            };
            //判断 是否要查询startTime

            if (!params.column) {
                if (this.queryForm.column === "startTime") {
                    this.$set(params, "column", "startTime");
                } else {
                    this.$set(params, "column", "createTime");
                }
                //提货中查询
                if (this.queryForm.column === "distributionTime") {
                    this.$set(params, "column", "distributionTime");
                }
            }
            if (this.ipagination) {
                params.pageNo = this.ipagination.currentPage;
                params.pageSize = this.ipagination.pageSize;
            }
            console.log("查询对象", params);

            getAction(this.url.list, params)
                .then((res) => {
                    if (res.success) {
                        this.$refs.GTable && this.$refs.GTable.handleScrollTop && this.$refs.GTable.handleScrollTop();
                        const {records, total} = res.result;
                        this.tableData = records || res.result;

                        this.ipagination &&
                        (this.ipagination.total = total || res.result.length);
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
};
</script>
<style scoped>
.el-button {
    user-select: unset;
}
</style>
