<template>
  <all-order-module></all-order-module>
</template>

<script>
import AllOrderModule from "@/views/docpage/modules/AllOrderModule";
export default {
  name: "ConAllOrderModule",

  components: {AllOrderModule},

};
</script>

<style lang="scss" scoped>
</style>
