<template>
  <div class="main-full-content">
    <el-form class="input_top" ref="form" :model="queryForm" label-width="120px" size="medium">
        <el-card class="box-card">
            <el-row>
        <el-col :xs="24" :sm="12" :md="12" :lg="6">
          <el-form-item label="名称">
            <el-input v-model="queryForm.typeName" @keyup.enter.native="handleSearch"></el-input>
          </el-form-item>
        </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="6">
                <el-form-item label="订单类型">
                  <f-select
                          v-model="queryForm.orderType"
                          :isNeed="queryForm.orderType"
                          @keyup.enter.native="handleSearch"
                          :dict="'customer_order_type'">

                  </f-select>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="6">
                <el-form-item label="是否启用">
                  <f-select
                      v-model="queryForm.isEnabled"
                      :isNeed="queryForm.isEnabled"
                      @keyup.enter.native="handleSearch"
                      :dict="'unit_whether'">

                  </f-select>
                </el-form-item>
              </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="6">
          <el-form-item label-width="30px">
            <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
            <el-button plain @click="handleReset">重置</el-button>
          </el-form-item>
        </el-col>
      </el-row>
        </el-card>

    </el-form>
    <el-card class="box-card last_card">
        <div class="f-p-b-15">
        <el-button type="primary" size="medium" icon="el-icon-plus" @click="handleAdd"
        >新增</el-button>
      </div>
      <f-table
        v-loading="loading"
        ref="GTable"
        border
        size="medium"
        row-key="id"
        class="c-th-has-bgcolor"
        :columns="columns"
        :data="tableData"
        :cell-style="{ 'text-align': 'center' }"
        tableHeight="66vh"
        @selection-change="handleSelectionChange"
        @sort-change="handleTableChange">
      <template v-slot="scope">
        <template v-if="scope.column.property === 'action'">
          <el-button type="text" @click="handleEdit(scope.row)">编辑</el-button>
          <el-button type="text" @click="handleDele(scope.row)">删除</el-button>
        </template>
<!--        订单类型-->
        <template v-else-if="scope.column.property === 'orderType'">
          <span>{{parseDict(scope.row.orderType,'customer_order_type')}}</span>
        </template>
        <template v-else-if="scope.column.property === 'isEnabled'">
          <span>{{scope.row.isEnabled === '1' ? '是' : '否'}}</span>
        </template>
<!--        订单状态-->
        <template v-else-if="scope.column.property === 'orderStatus'">
          <span>{{parseDict(scope.row.orderStatus,'all_os_order_status')}}</span>
        </template>
      </template>
    </f-table>
    <el-row class="f-text-right f-p-v-8">
      <el-pagination
          v-bind="ipagination"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange">
      </el-pagination>
    </el-row>
    </el-card>
    <el-dialog :title="form.id?'修改':'新增'+'费用类型'" :visible.sync="dialogFormVisible" :close-on-click-modal="false"
               v-if="dialogFormVisible">
      <el-form :model="form" :rules="rules" ref="form">

        <el-form-item label="数值" :label-width="formLabelWidth" prop="type">
          <el-input v-model="form.type" autocomplete="off" maxlength="3" show-word-limit onkeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"></el-input>
        </el-form-item>
        <el-form-item label="名称" :label-width="formLabelWidth" prop="typeName">
          <el-input v-model="form.typeName" autocomplete="off" maxlength="30" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="订单类型" :label-width="formLabelWidth" prop="orderType">
          <f-select
                  v-model="form.orderType"
                  :isNeed="form.orderType"
                  :dict="'customer_order_type'"
                  autocomplete="off">
          </f-select>
        </el-form-item>
        <el-form-item label="是否启用" :label-width="formLabelWidth" prop="orderType">
          <f-select
              v-model="form.isEnabled"
              :isNeed="form.isEnabled"
              :dict="'unit_whether'"
              autocomplete="off">
          </f-select>
        </el-form-item>
        <el-form-item label="详细描述" :label-width="formLabelWidth" prop="detail">
          <el-input v-model="form.detail" autocomplete="off" type="textarea" maxlength="500" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="订单状态" :label-width="formLabelWidth" prop="orderStatusList">
          <el-select v-model="form.orderStatusList" autocomplete="off" multiple filterable clearable @click.native="initAllOsOrderStatusList">
            <el-option v-for="(item,index) in orderStatusList" :key="index" :value="item.value" :label="item.text"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="medium" @click="dialogFormVisible = false">取 消</el-button>
        <el-button size="medium" type="primary" @click="handleSave">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import {deleteAction, postAction, putAction} from '@/api/manage'
import QueryMixins from "../../../mixins/QueryMixins";
import {yingwenPattern} from '@/utils/pattern'
import {getAction} from "../../../api/manage";
import Tempcclate from "../Dict";
import {updatedDict} from "../../../utils/util";

export default {
  name: 'Importer',
  components: {Tempcclate},
  mixins: [QueryMixins],
  data() {
    return {
      dictCodes: ['customer_order_type','all_os_order_status'],
      orderStatusList:[],
      rules: {
        type: [
          {required: true, message: '请输入类型', trigger: 'blur'},
          // {max: 50, message: '长度不能超过50', trigger: 'change'},
        ],
        isEnabled: [
          {required: true, message: '请选择是否启用', trigger: 'change'},
          // {max: 50, message: '长度不能超过50', trigger: 'change'},
        ],
        typeName: [
          {required: true, message: '请输入名称', trigger: 'blur'},
          // {max: 50, message: '长度不能超过50', trigger: 'change'},
          // {pattern: yingwenPattern, message: '英文名格式有误', trigger: 'change'},
        ],
        orderType:[
          {required:true, message: '请选择订单类型',trigger:'change'},
        ],
        // orderStatusList:[
        //   {required:true, message: '请选择订单状态',trigger:'change'},
        // ],
        detail:[
          // {required:true, message: '请输入描述',trigger:'blur'},
        ],
      },
      ids: [],
      row: {},
      form: {},
      formLabelWidth: '110px',
      //控制弹框显示
      dialogFormVisible: false,
      columns: [
        {
          prop: 'action',
          label: '操作',
        },
        {
          prop: 'type',
          label: '付款方式类型',
          sortable: 'custom',
        },
        {
          prop: 'typeName',
          label: '名称',
          sortable: 'custom',
        },
        {
          prop: 'orderType',
          label: '订单类型',
          sortable: 'custom',
        },
        {
          prop: 'isEnabled',
          label: '是否启用',
          sortable: 'custom',
        },
        {
          prop: 'detail',
          label: '描述',
          sortable: 'custom',
          overFlow:true,
        },
        {
          prop: 'orderStatus',
          label: '订单状态节点',
          sortable: 'custom',
        },
        {
          prop: 'createBy',
          label: '创建人',
          sortable: 'custom',
        },
        {
          prop: 'createTime',
          label: '创建时间',
          sortable: 'custom',
        },
        {
          prop: 'updateBy',
          label: '修改人',
          sortable: 'custom',
        },
        {
          prop: 'updateTime',
          label: '修改时间',
          sortable: 'custom',
        },
        /*{
          prop: 'action',
          label: '操作',

        },*/
      ],
      url: {
        list: '/sys/payType/getPage',
        addOrUpdate: '/sys/payType/addOrUpdate',
        delOne: '/sys/payType/delOne',
        delBatch: '/sys/payType/delBatch',
        getAllOsOrderStatusList:'/sys/dict/getDictItems/all_os_order_status',
      },
    }
  },
  methods: {
    initAllOsOrderStatusList(){
      getAction(this.url.getAllOsOrderStatusList)
      .then(res=>{
        this.orderStatusList = res.result;
      })
    },
    handleSelectionChange(selection) { // 多选
      let arr = []
      this.emailList = [];
      for (let i = 0; i < selection.length; i++) {
        arr.push(selection[i]['id'])
        this.emailList.push(selection[i]['email']);
      }
      this.selectionRows = selection
      this.selectedRowKeys = [...arr]
    },
    handleAdd() {
      this.form = {orderStatusList:[]};
      this.dialogFormVisible = true;
    },
    handleEdit(row) {
      //将对象转为json字符串再转回对象
      //this.form = row; 复制的是指针 仍然指向这个对象row
      this.form = JSON.parse(JSON.stringify(row));
      if (this.form.orderStatus) {
        this.$set(this.form,'orderStatusList',this.form.orderStatus.split(','))
        // this.form.orderStatusList = this.form.orderStatus.split(',');
      }
      this.initAllOsOrderStatusList();
      this.dialogFormVisible = true;
    },
    handleSave() {
      this.$refs.form.validate(valid => {
        if (valid) {
          //编辑
          // if (this.form.id) {
          //   putAction(this.url.edit, this.form)
          //       .then((res) => {
          //         if (res.success) {
          //           this.$message.success(res.message);
          //           //关闭弹窗
          //           this.dialogFormVisible = false;
          //           sessionStorage.removeItem("dict:bd_cur");
          //           this.handleQuery()
          //         }
          //       })
          // } else {
          let list = this.form.orderStatusList;
          if (list && list.length > 0) {
            this.form.orderStatus = list.join(',');
          }
            postAction(this.url.addOrUpdate, this.form)
                .then((res) => {
                  if (res.success) {
                    this.$message.success(res.message);
                    //关闭弹窗
                    this.dialogFormVisible = false;
                    updatedDict('pay_type');
                    this.handleQuery()
                  }
                })
          // }
        }
      })

    },
    handleDele(row) {
      this.$confirm(`确定删除吗？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'success',
        closeOnClickModal: false,
      }).then(() => {
        deleteAction(this.url.delOne, {id: row.id}).then(res => {
          this.$message({
            type: 'success',
            message: res.message
          })
          updatedDict('pay_type');
          this.handleQuery()
        })
      }).catch(() => {

      })
    },
    handleReset() {
      this.queryForm = {};
      this.filterForm = {};
      this.ipagination.currentPage = 1
      this.handleQuery()
    }
  }, created() {

  }
}
</script>

<style lang="scss" scoped>

</style>
