<template>
    <el-dialog
        title="查询余额"
        :visible="visible"
        width="50%"
        custom-class="c-custom-dialog"
        :close-on-click-modal="false"
        :before-close="handleClose">
        <el-form :model="form" ref="form" label-width="94px" size="medium">
            <el-row>
                <el-col :span="24">
                    <el-form-item prop="actacn" label="账户号码">
                        <el-input disabled v-model="form.actacn"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item prop="actname" label="账户名称">
                        <el-input disabled v-model="form.actname"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item prop="esTitle" label="查询日期">
                        <el-input disabled v-model="form.queryDate"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="24">
                    <el-form-item prop="esTitle">
                        <span slot="label">账面余额
                            <el-tooltip placement="top" popper-class="icon-tooltip">
                                <template v-slot:content>
                                    账面余额 = (有效余额＋圈存金额＋冻结金额)
                                </template>
                                    <i class="el-icon-question"></i>
                            </el-tooltip>
                        </span>
                        <el-input disabled v-model="form.bokbal"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item prop="esTitle" label="有效余额">
                        <el-input disabled v-model="form.avabal"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item prop="esTitle" label="币种">
                        <el-input disabled v-model="form.curcde"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item prop="esTitle" label="透支限额">
                        <el-input disabled v-model="form.ovramt"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item prop="esTitle" label="冻结金额">
                        <el-input disabled v-model="form.frzamt"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item prop="esTitle" label="圈存金额">
                        <el-input disabled v-model="form.stpamt"></el-input>
                    </el-form-item>
                </el-col>
              <el-col :span="24">
              </el-col>

            </el-row>
        </el-form>
        <span slot="footer">
            <el-button size="medium" @click="handleClose">取消</el-button>
<!--            <el-button type="primary" size="medium" @click="handleSubmit">确定</el-button>-->
        </span>
    </el-dialog>
</template>

<script>
import { httpAction } from '@/api/manage'

export default {
    name: 'AmountDialog',
    data() {
        return {
            form: {},
            url: {
                add: '/sys/role/add',
                edit: '/sys/role/edit',


            },
            visible:false,

        }
    },

    watch: {
    },
    computed:{
    },

  methods: {

        handleClose() {
            this.$refs.form.resetFields()
            this.visible = false
        },
        showAmount(val){
            this.form.actacn = val.actacn
            this.form.actname = val.actname
            this.form.queryDate = val.queryDate
            this.form.bokbal = val.bokbal
            this.form.avabal = val.avabal
            this.form.curcde = val.curcde
            this.form.ovramt = val.ovramt
            this.form.frzamt = val.frzamt
            this.form.stpamt = val.stpamt
            this.visible = true
        },
    },

}
</script>

<style lang="scss" scoped>
::v-deep
    .el-dialog__body {
        padding: 20px 30px 20px 24px;
    }
</style>
