<template>
  <customer-bill></customer-bill>
</template>

<script>
import customerBill from "@/views/hyFinanceManage/modules/financialStatements/customerBill";
export default {
  name: "DocCustomerBill",
  components: {customerBill},
};
</script>

<style lang="scss" scoped>
</style>
