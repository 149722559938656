<template>
    <div class="main-full-content">
        <div class="f-p-b-15">
            <el-button type="primary"  icon="el-icon-download" @click="handleExportXls" size="small">导出</el-button>
        </div>
        
        <f-table
                v-loading="loading"
                ref="GTable"
                border
                size="medium"
                row-key="id"
                class="c-th-has-bgcolor"
                :columns="columns"
                :data="tableData"
                :cell-style="{ 'text-align': 'center' }"
                @selection-change="handleSelectionChange"
                @sort-change="handleTableChange">
        </f-table>
        <el-row class="f-text-right f-p-v-8">
            <el-pagination
                    v-bind="ipagination"
                    layout="total, sizes, prev, pager, next, jumper"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange">
            </el-pagination>
        </el-row>
    </div>
</template>

<script>
    import QueryMixins from "@/mixins/QueryMixins.js"

    export default {
        name: 'LogModule',
        mixins: [QueryMixins],
        props: {
            order: {
                type: Object,
                default: () => {
                }
            }
        },
        components: {},
        data() {
            return {
                columns: [
                    {
                        type: 'selection',
                        width: 50
                    },
                    {
                        prop: 'createBy',
                        label: '操作人',
                        sortable: 'custom',
                        minWidth:120,
                    },
                    {
                        prop: 'createTime',
                        label: '操作时间',
                        sortable: 'custom',
                        minWidth:160,
                    },
                    {
                        prop: 'content',
                        label: '描述',
                        sortable: 'custom',
                        minWidth:220,
                    },
                ],
                url: {
                    list: '/order/kdOrderLog/list',
                    exportXlsUrl: '/order/kdOrderLog/exportXls',
                },

            }
        },
        methods: {
            initVue(){
                this.queryForm.orderNo = this.order.orderNo;
                console.log('created,log初始化....');
                this.handleQuery();
            },
        },
        created() {
            if(this.order){
                this.queryForm.orderNo = this.order.orderNo;
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
