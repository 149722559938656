<template>
  <div class="main-full-content">
    <el-form
      class="multiline_select"
      ref="form"
      :model="queryForm"
      label-width="80px"
      size="medium"
    >
      <el-card class="box-card">
        <el-row class="inputs_row">
          <el-col :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label="工作单号">
              <el-input
                type="textarea"
                v-model="queryForm.orderNo"
                @keyup.enter.native="handleCheck"
                placeholder="请用逗号与空格隔开"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label="客户">
              <el-input
                v-model="queryForm.username"
                @keyup.enter.native="handleCheck"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label="结算对象">
              <el-input
                v-model="queryForm.settlement"
                @keyup.enter.native="handleCheck"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label="业务员">
              <f-select
                class="f-full-width"
                :dict="'sys_user_id'"
                v-model="queryForm.salesmanId"
                :is-need="queryForm.salesmanId"
                placeholder="请选择"
                style="width: 100%"
              >
              </f-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label="费用币种">
              <f-select
                v-model="queryForm.moneyType"
                dict="bd_cur"
                :is-need="queryForm.moneyType"
              ></f-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label="费用名称">
              <f-select
                v-model="queryForm.feeId"
                dict="bd_fee_type"
                :is-need="queryForm.feeId"
              ></f-select>
            </el-form-item>
          </el-col>
          <!--          <el-col :xs="24" :sm="12" :md="12" :lg="6">-->
          <!--            <el-form-item label="收款员">-->
          <!--              <f-select-->
          <!--                placeholder="请选择收款人"-->
          <!--                v-model="queryForm.billman"-->
          <!--                :is-need="queryForm.billman"-->
          <!--                dict="operations"-->
          <!--              >-->
          <!--              </f-select>-->
          <!--            </el-form-item>-->
          <!--          </el-col>-->
          <el-col :xs="24" :sm="12" :md="12" :lg="8">
            <el-form-item label="录入时间">
              <el-date-picker
                class="f-flex-1"
                v-model="stime"
                type="datetimerange"
                size="small"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :picker-options="editStartOptions"
                :default-time="['00:00:00', '23:59:59']"
                value-format="yyyy-MM-dd HH:mm:ss"
                format="yyyy-MM-dd HH:mm:ss"
                unlink-panels
                style="width: 100%"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="8">
            <el-form-item label="下单时间">
              <el-date-picker
                class="f-flex-1"
                v-model="otime"
                type="datetimerange"
                size="small"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :picker-options="editStopOptions"
                :default-time="['00:00:00', '23:59:59']"
                value-format="yyyy-MM-dd HH:mm:ss"
                format="yyyy-MM-dd HH:mm:ss"
                unlink-panels
                style="width: 100%"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label="订单类型">
              <f-select
                v-model="queryForm.orderType"
                :is-need="queryForm.orderType"
                :clearable="false"
                dict="all_order_type"
              ></f-select>
            </el-form-item>
          </el-col>
          <el-col
            class="no_margin"
            :xs="24"
            :sm="12"
            :md="12"
            :lg="6"
            v-if="queryForm.orderType === '2'"
          >
            <el-form-item label="运单号">
              <el-input
                type="textarea"
                v-model="queryForm.runOrderNo"
                placeholder="请用逗号与空格隔开"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="8">
            <el-form-item label="周期数">
              <el-input
                type="textarea"
                v-model="queryForm.weekNum"
                placeholder="请用逗号与空格隔开"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="8">
            <el-form-item label="柜号">
              <el-input
                type="textarea"
                v-model="queryForm.containerNo"
                placeholder="请用逗号与空格隔开"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="录入人">
              <f-select
                class="textarea_table"
                v-model="queryForm.createBy"
                :is-need="queryForm.createBy"
                :dict="'sys_user_id'"
                @changet="sysUserStr"
                @keyup.enter.native="handleSearch"
              ></f-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="数据时间">
              <f-select
                  class="textarea_table"
                  v-model="queryForm.dataCreateTime"
                  :is-need="queryForm.dataCreateTime"
                  :dict="'data_create_time'"
                  @keyup.enter.native="handleSearch"
              ></f-select>
            </el-form-item>
          </el-col>
          <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="3">
            <el-form-item label-width="30px">
              <el-button
                size="medium"
                type="primary"
                icon="el-icon-search"
                @click="handleCheck"
                >查询
              </el-button>
              <el-button plain @click="handleReset">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
    </el-form>
    <el-card class="box-card">
      <div class="f-p-b-15" style="display: flex">
        <el-button
          size="medium"
          type="primary"
          @click="applyPay"
          v-if="buttonShowList.sqfk"
          >申请付款</el-button
        >

        <el-upload
          class="upload-demo"
          :action="url.importSettle"
          :http-request="handleUpload"
          :before-upload="beforeUpload"
          :show-file-list="false"
          accept=".xls,.xlsx"
          :file-list="fileList"
          style="margin-left: 10px"
        >
          <el-button
            size="medium"
            type="primary"
            v-if="buttonShowList.drdddz"
            >导入订单对账</el-button
          >
        </el-upload>

        <el-button
          style="margin-left: 10px"
          size="medium"
          type="primary"
          @click="downloadModel(url.downloadTemplate, '对账导入模板')"
          v-if="buttonShowList.xzdzmb"
          >下载对账模板</el-button
        >
        <!--                <el-upload-->
        <!--                    class="upload-demo"-->
        <!--                    :action="url.importSettleContainer"-->
        <!--                    :http-request="handleContainerUpload"-->
        <!--                    :before-upload="beforeUpload"-->
        <!--                    :show-file-list = "false"-->
        <!--                    accept=".xls,.xlsx"-->
        <!--                    :file-list="fileList"-->
        <!--                    style="margin-left: 10px">-->
        <!--                    <el-button size="medium" type="primary">导入柜子对账</el-button>-->
        <!--                </el-upload>-->

        <!--                <el-button style="margin-left: 10px" size="medium" type="primary" @click="downloadModel(url.downloadTemplate, '对账导入模板')">下载对账模板</el-button>-->
      </div>
      <div class="payApplyRow" v-if="sumFee.length > 0">
        <span>合计：</span>
        <div
          class="payApplyRow payApplyRowItem"
          v-for="(item, index) in sumFee"
          :key="index"
          style="color: red"
        >
          <span>{{ item.cur + " " + item.total }}</span>
        </div>
      </div>
      <f-table
        v-loading="loading"
        ref="GTable"
        border
        size="medium"
        row-key="id"
        class="c-th-has-bgcolor"
        :columns="columns"
        :data="tableData"
        :cell-style="{ 'text-align': 'center' }"
        tableHeight="61vh"
        :isRecordTableScroll="true"
        @selection-change="handleSelectionChange"
        @sort-change="handleTableChange"
      >
        <template v-slot="scope">
          <template v-if="scope.column.property === 'orderNo'">
            <a
              class="f-cursor-pointer"
              style="color: #00a0e9"
              @click.prevent="$intoDocPage(scope.row.orderNo)"
              >{{ scope.row.orderNo }}</a
            >
          </template>
          <template v-else-if="scope.column.property === 'realAgent'">
            <a
              class="f-cursor-pointer"
              type="text"
              @click.prevent="$intoCustomerEditPage(scope.row.cusId)"
              style="color: #57a3f3"
              >{{ scope.row.realAgent }}</a
            >
          </template>

          <template v-else-if="scope.column.property === 'week'">
            <a
              class="f-cursor-pointer"
              style="color: #00a0e9"
              @click="infomation(scope.row.containerId)"
              >{{ scope.row.week }}
            </a>
          </template>
        </template>
      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination
          v-bind="ipagination"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </el-row>
    </el-card>

    <pay-apply-dialog ref="payApply" @ok="this.handleQuery"></pay-apply-dialog>
    <reconciliation-dialog
      ref="reconciliation"
      @ok="this.handleQuery"
    ></reconciliation-dialog>
  </div>
</template>
<script>
import QueryMixins from "../../../../mixins/QueryMixins";
import { getAction, postAction } from "@/api/manage";
import payApplyDialog from "./payApplyDialog";
import reconciliationDialog from "@/views/hyFinanceManage/modules/pay/reconciliationDialog";
import { setUpRouteUtil } from "@/utils/util";

export default {
  mixins: [QueryMixins],
  components: {
    payApplyDialog,
    reconciliationDialog,
  },
  data() {
    return {
      ipagination: {
        currentPage: 1,
        pageSize: 50,
        pageSizes: [50, 100, 200, 300, 400, 500,1000,2000],
        total: 0
      },
      queryForm: { orderType: "0" },
      editStartOptions: {
        disabledDate: (time) => {
          if (!this.form.etime) {
            // time = new Date(time.replace(/-/g, '/'));
            return time.getTime() < new Date(1970 - 1 - 1).getTime(); //禁止选择1970年以前的日期
          } else {
            return time.getTime() > new Date(this.form.etime);
          }
        },
      },
      editStopOptions: {
        disabledDate: (time) => {
          // time = new Date(time.replace(/-/g, '/'));
          return (
            time.getTime() < new Date(this.form.stime) ||
            time.getTime() < new Date(1970 - 1 - 1).getTime() //禁止选择1970年以前的日期
          );
        },
      },
      fileList: [],
      selectedRowKeys: [],
      selectionRows: [],
      selectedNos: [],
      loading: false,
      form: {},
      columns: [
        {
          type: "selection",
          width: 50,
        },
        {
          prop: "orderNo",
          label: "工作单号",
          width: 150,
        },
        {
          prop: "realAgent",
          label: "客户",
        },
        {
          prop: "createTime",
          label: "下单时间",
        },
        {
          prop: "week",
          label: "周期",
          width: 150,
        },
        {
          prop: "containerNo",
          label: "柜号",
        },
        {
          prop: "agent",
          label: "结算对象",
        },
        {
          prop: "feename",
          label: "费用中文",
        },
        {
          prop: "feenameUs",
          label: "费用英文",
        },
        {
          prop: "price",
          label: "价格",
        },
        {
          prop: "quantity",
          label: "数量",
        },
        {
          prop: "moneyType",
          label: "费用币种",
        },
        {
          prop: "total",
          label: "合计",
        },
        {
          prop: "createBy",
          label: "录入人",
        },
        {
          prop: "screateTime",
          label: "录入时间",
        },
        {
          prop: "salesman",
          label: "业务",
        },
        {
          prop: "operator",
          label: "操作",
        },
        {
          prop: "doc",
          label: "客服",
        },
      ],

      url: {
        list: "/shipping/hySettlePay/waitPayApplyList",
        importSettle: "/shipping/hySettlePay/importPaySettle",
        importSettleContainer: "/shipping/hySettlePay/importPaySettleContainer",
        downloadTemplate: "/shipping/hySettlePay/getTemplate",
      },

      rules: {
        realCtn: [
          { required: true, message: "请输入实际总件数", trigger: "blur" },
          { pattern: /^[1-9]\d*$/, message: "请输入整数", trigger: "blur" },
        ],
        realKg: [
          { required: true, message: "请输入实际总重量", trigger: "blur" },
          {
            pattern:
              /^(?=([1-9]{1,10}$|[0-9]{1,7}\.))(0|[1-9][0-9]*)(\.[0-9]{1,3})?$/,
            message: "只能输入最多三位小数的数字",
            trigger: "change",
          },
        ],
      },
      stime: [],
      otime: [],
      sumFee: [],
    };
  },
  computed: {
    buttonShowList(){
      return {
        'sqfk':this.$btnIsShow('hyFinanceManage/modules/pay/waitApplyPay', '0', '申请付款'),
        'drdddz':this.$btnIsShow('hyFinanceManage/modules/pay/waitApplyPay', '0', '导入订单对账'),
        'xzdzmb':this.$btnIsShow('hyFinanceManage/modules/pay/waitApplyPay', '0', '下载对账模板'),


      }
    }
  },
  created() {
    this.$nextTick(() => {
      this.$set(this.queryForm, "orderType", "0");
    });
    this.$set(this.queryForm,'dataCreateTime','3');
  },
  methods: {
    sysUserStr(val) {
      if (val) this.queryForm.createBy = val;
    },
    handleUpload(data) {
      // if (data.file.name.length> 20){
      //     this.$message.warning("文件名过长，自动截取前50个字符");
      //     let index = data.file.name.lastIndexOf(".");
      //     let suffix = data.file.name.substr(index+1);
      //     console.log(suffix)
      //     data.file = new File([data.file], data.file.name.substring(0, 10) + '...' + suffix)
      // }

      const formData = new FormData();
      formData.append("file", data.file);

      postAction(this.url.importSettle, formData).then((res) => {
        // console.log(res)
        if (res.result.success) {
          //上传成功,返回结果赋值给form对象的文件集合
          // this.billForm.uploadFileIds.push(res.result.id);
          // console.log(this.billForm.uploadFileIds)
          // this.fileList.push(res.result);
          this.$message.success("上传成功");
          this.fileList = [];
          if (res.result.result.errorResult !== null) {
            // this.$refs.reconciliation.edit(res.result.result.errorResult,'0');
            this.$refs.reconciliation.edit(res.result.result, "0");
          } else {
            this.$refs.reconciliation.editNoProblem(res.result.result);
          }
        } else {
          this.$message.error(res.result.message);
        }
      });
    },
    handleContainerUpload(data) {
      // if (data.file.name.length> 20){
      //     this.$message.warning("文件名过长，自动截取前50个字符");
      //     let index = data.file.name.lastIndexOf(".");
      //     let suffix = data.file.name.substr(index+1);
      //     console.log(suffix)
      //     data.file = new File([data.file], data.file.name.substring(0, 10) + '...' + suffix)
      // }

      const formData = new FormData();
      formData.append("file", data.file);

      postAction(this.url.importSettleContainer, formData).then((res) => {
        // console.log('对账上传',res)
        if (res.result.success) {
          //上传成功,返回结果赋值给form对象的文件集合
          // this.billForm.uploadFileIds.push(res.result.id);
          // console.log(this.billForm.uploadFileIds)
          // this.fileList.push(res.result);
          this.$message.success("上传成功");
          this.fileList = [];
          if (res.result.result.errorResult !== null) {
            this.$refs.reconciliation.edit(
              res.result.result,
              res.result.result.errorType
            );
          } else {
            this.$refs.reconciliation.editNoProblem(res.result.result);
          }
        } else {
          this.$message.error(res.result.message);
        }
      });
    },
    beforeUpload(file) {
      //对文件大小进行限制
      const isLt2M = file.size / 1024 / 1024 < 15;
      if (!isLt2M) {
        this.$message.warning("文件大小不能超过15MB");
      }
      const isFile = file.name.indexOf(".") !== -1;
      if (!isFile) {
        this.$message.warning("该文件名不合法");
      }
      const len = file.name.length < 100;
      if (!len) {
        this.$message.warning("该文件名太长，请修改文件名最多100个字符");
      }
      return isLt2M && isFile && len;
    },

    infomation(id) {
      // 对于路由不在左侧菜单中的选中菜单处理
      setUpRouteUtil(this.$route.path, "/operation/cabinet/Edit");
      this.$router.push("/operation/cabinet/Edit?id=" + id);
      // console.log(id);
    },
    //进入客服页
    intoDocpage(orderNo) {
      if (orderNo.indexOf("KD") !== -1) {
        // 对于路由不在左侧菜单中的选中菜单处理
        setUpRouteUtil(this.$route.path, "/kdorder/Edit");
        this.$router.push("/kdorder/Edit?orderNo=" + orderNo);
      } else {
        // 对于路由不在左侧菜单中的选中菜单处理
        setUpRouteUtil(this.$route.path, "/docpage/Edit");
        this.$router.push("/docpage/Edit?orderNo=" + orderNo);
      }
    },
    downloadReconciliationTemplate() {
      // console.log('下载对账模板')
      downloadModel(this.url.downTemplate, "散货报价导入模板");
    },
    importBill() {
      this.$message.warning("此功能后续开放！");
    },
    applyPay(selection) {
      // console.log("申请付款以下id的费用", this.selectionRows);
      if (this.selectedRowKeys.length === 0) {
        this.$message.warning("请勾选费用");
        return;
      }
      let settleIdCount = [];
      let settleNameCount = [];
      this.selectionRows.forEach((item) => {
        settleIdCount.push(item.settleId);
        settleNameCount.push(item.settlement);
      });
      let idArr = Array.from(new Set(settleIdCount));
      let nameArr = Array.from(new Set(settleNameCount));
      if (idArr.length > 1) {
        this.$message.warning("所选费用的结算对象不一致！");
        return;
      }
      if (nameArr.length > 1) {
        this.$message.warning("所选费用的结算对象不一致！");
        return;
      }

      let arr = this.tableData.filter((item) => {
        return this.selectionRows.indexOf(item) === -1;
      });
      // console.log("arr--", arr);

      let countCur = [];
      this.selectionRows.forEach((item) => {
        countCur.push(item.moneyType);
      });
      let curArr = Array.from(new Set(countCur));
      // console.log("curArr->", curArr);
      if (curArr.length > 1) {
        this.$message.warning("所选费用的费用币种不一致！");
        return;
      }

      this.$refs.payApply.edit(this.selectionRows, arr, this.columns);
    },

    handleSelectionChange(selection) {
      // 多选
      // console.log('多选',selection);

      this.sumFee = [];
      let allCur = [];
      selection.forEach((row) => {
        allCur.push(row.moneyType);
      });
      let curNum = Array.from(new Set(allCur));
      //遍历每个币种，计算总和后放入sumFee中
      curNum.forEach((cur) => {
        let curRow = selection.filter((row) => {
          return row.moneyType === cur;
        });
        let total = Number(0);
        curRow.forEach((i) => {
          total = total + Number(i.total);
        });
        this.sumFee.push({ cur: cur, total: total.toFixed(3) });
      });

      let arr = [];
      let nos = [];
      this.nameList = [];
      this.cnamelist = [];
      this.emailList = [];
      for (let i = 0; i < selection.length; i++) {
        arr.push(selection[i]["id"]);
        nos.push(selection[i]["orderNo"]);
        this.nameList.push(selection[i]["username"]);
        this.cnamelist.push(selection[i]["cname"]);
        this.emailList.push(selection[i]["email"]);
      }
      this.selectionRows = selection;
      this.selectedRowKeys = [...arr];
      this.selectedNos = [...nos];
    },
    handleReceive() {
      // console.log("申请收款以下id的费用", this.selectedRowKeys);
    },
    handleReset() {
      this.queryForm = {};
      this.$set(this.queryForm, "orderType", "0");
      this.otime = "";
      this.stime = "";
      this.ipagination.currentPage = 1;
      this.handleQuery();
    },
    handleCheck() {
      this.loading = true;
      // console.log(this.otime)
      if (this.otime != null && this.otime.length > 1) {
        this.queryForm.createTimeS = this.otime[0];
        this.queryForm.createTimeE = this.otime[1];
      } else {
        this.queryForm.createTimeS = "";
        this.queryForm.createTimeE = "";
      }
      if (this.stime != null && this.stime.length > 1) {
        this.queryForm.sCreateTimeS = this.stime[0];
        this.queryForm.sCreateTimeE = this.stime[1];
      } else {
        this.queryForm.sCreateTimeS = "";
        this.queryForm.sCreateTimeE = "";
      }
      let form = { ...this.queryForm, ...this.filterForm };
      for (let item in form) {
        if (typeof form[item] === "string") {
          form[item] = form[item].trim();
          if (form[item] === "") {
            form[item] = null;
          }
        }
      }
      const { prop, order } = this.isorter;
      // 传入的参数
      const params = {
        ...form,
        column: prop,
        order: order.indexOf("asc") !== -1 ? "asc" : "desc",
      };
      //判断 是否要查询startTime

      if (!params.column) {
        if (this.queryForm.column === "startTime") {
          this.$set(params, "column", "startTime");
        } else {
          this.$set(params, "column", "createTime");
        }
        //提货中查询
        if (this.queryForm.column === "distributionTime") {
          this.$set(params, "column", "distributionTime");
        }
      }
      if (this.ipagination) {
        params.pageNo = this.ipagination.currentPage;
        params.pageSize = this.ipagination.pageSize;
      }
      // console.log("查询对象", params);

      postAction(this.url.list, params)
        .then((res) => {
          if (res.success) {
            this.$refs.GTable &&
              this.$refs.GTable.handleScrollTop &&
              this.$refs.GTable.handleScrollTop();
            const { records, total } = res.result;
            this.tableData = records || res.result;

            this.ipagination &&
              (this.ipagination.total = total || res.result.length);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    noConvertBill() {
      // console.log("申请收款以下id的费用", this.selectedRowKeys);
      if (this.selectedRowKeys.length === 0) {
        this.$message.warning("请勾选费用");
        return;
      }

      let countCur = [];
      this.selectionRows.forEach((item) => {
        countCur.push(item.moneyType);
      });
      let curArr = Array.from(new Set(countCur));
      // console.log("curArr->", curArr);
      if (curArr.length > 1) {
        this.$message.warning("所选费用的费用币种不一致！");
        return;
      }

      let settleIdCount = [];
      let settleNameCount = [];
      this.selectionRows.forEach((item) => {
        settleIdCount.push(item.settleId);
        settleNameCount.push(item.settlement);
      });
      let idArr = Array.from(new Set(settleIdCount));
      let nameArr = Array.from(new Set(settleNameCount));
      if (idArr.length > 1) {
        this.$message.warning("所选费用的结算对象不一致！");
        return;
      }
      if (nameArr.length > 1) {
        this.$message.warning("所选费用的结算对象不一致！");
        return;
      }
      this.$refs.noConvert.edit(this.selectedRowKeys, this.selectedNos);
    },
    async handleQuery() {
      // console.log('自己的handleQuery')
      this.loading = true;
      let form = { ...this.queryForm, ...this.filterForm, ...this.initData };
      for (let item in form) {
        if (typeof form[item] === "string") {
          form[item] = form[item].trim();
          if (form[item] === "") {
            form[item] = null;
          }
        }
      }
      const { prop, order } = this.isorter;
      // 传入的参数
      const params = {
        ...form,
        column: prop,
        order: order.indexOf("asc") !== -1 ? "asc" : "desc",
      };
      //判断 是否要查询startTime

      if (!params.column) {
        if (this.queryForm.column === "startTime") {
          this.$set(params, "column", "startTime");
        } else if (this.enabledCreatetime) {
          this.$set(params, "column", "createTime");
        }
        //提货中查询
        if (this.queryForm.column === "distributionTime") {
          this.$set(params, "column", "distributionTime");
        }
        //排序
        //提货中查询
        if (this.queryForm.column === "sort") {
          this.$set(params, "column", "sort");
        }
      }
      if (this.ipagination) {
        params.pageNo = this.ipagination.currentPage;
        params.pageSize = this.ipagination.pageSize;
      }
      // console.log('params',params)
      await postAction(this.url.list, params)
        .then((res) => {
          // console.log(res)
          if (res.success) {
            this.$refs.GTable &&
              this.$refs.GTable.handleScrollTop &&
              this.$refs.GTable.handleScrollTop();
            if (res.result) {
              const { records, total } = res.result;
              this.tableData = records || res.result;

              this.ipagination &&
                (this.ipagination.total = total || res.result.length);
            } else {
              this.tableData = [];

              this.ipagination = 0;
            }
          }
        })
        .finally(() => {
          this.loading = false;
          this.initMoreFuc && this.ipagination.currentPage == 1
            ? this.initMoreFuc()
            : ""; //用于初始的时候调用其他的方法
        });
    },
  },
};
</script>
<style scoped>
.el-button {
  user-select: unset;
}
.bottom-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 140px;
}
</style>
<style lang="scss" scoped>
.payApplyRow {
  display: flex;
  align-items: center;
}
.payApplyRowItem + .payApplyRowItem {
  margin-left: 15px;
}
</style>
