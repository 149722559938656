<!--潜在合作-->
<template>
  <div class="main-full-content">
    <el-form
        class="multiline_select"
        ref="form"
        :model="queryForm"
        label-width="120px"
        size="medium"
    >
      <el-card class="box-card">
        <el-row class="inputs_row">
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="工作单号">
              <el-input
                  v-model="queryForm.orderNo"
                  @keyup.enter.native="handleSearch"
                  type="textarea"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="客户">
              <el-input
                  v-model="queryForm.customerName"
                  @keyup.enter.native="handleSearch"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="运输方式">
              <!--                        <el-input v-model="queryForm.transportStatus"></el-input>-->
              <f-select
                  class="f-full-width"
                  :dict="'transportStatus'"
                  v-model="queryForm.transportStatus"
                  :is-need="queryForm.transportStatus"
                  placeholder="请选择"
                  style="width: 100%"
              >
              </f-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="航线名称">
              <f-select
                  v-model="queryForm.solicitation"
                  :is-need="queryForm.solicitation"
                  dict="fba_solicitation"
              ></f-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="FBA CODE">
              <f-select
                  v-model="queryForm.fbaCode"
                  :is-need="queryForm.fbaCode"
                  dict="fba_code_list"
              ></f-select>
            </el-form-item>
          </el-col>

          <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="操作员">
              <f-select
                  placeholder="请选择操作员"
                  v-model="queryForm.operateId"
                  :is-need="queryForm.operateId"
                  dict="operationsId"
              >
              </f-select>
            </el-form-item>
          </el-col>
          <!-- <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="入仓">
              <el-select
                class="full_width"
                v-model="queryForm.cargoArrivedType"
                placeholder="请选择"
              >
                <el-option value="1" label="未入仓"> 未入仓</el-option>
                <el-option value="2" label="已入仓"> 已入仓</el-option>
              </el-select>
            </el-form-item>
          </el-col> -->

          <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label-width="30px">
              <el-button
                  type="primary"
                  icon="el-icon-search"
                  @click="handleSearch"
              >查询
              </el-button>
              <el-button plain @click="handleReset">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
    </el-form>

    <el-card class="box-card last_card">
      <f-table
          v-loading="loading"
          ref="GTable"
          border
          size="medium"
          row-key="id"
          class="c-th-has-bgcolor"
          :columns="columns"
          :data="tableData"
          :cell-style="{ 'text-align': 'center' }"
          tableHeight="67vh"
          :isRecordTableScroll="true"
          @selection-change="handleSelectionChange"
          @sort-change="handleTableChange"
      >
        <template v-slot="scope">
          <template v-if="scope.column.property === 'action'">
            <el-button type="text" @click="handleEdit(scope.row)"
            >推送地址
            </el-button>
             <el-button type="text" @click="handleUpsOrder(scope.row)" v-if="scope.row.hasQianzhi == '1' && buttonShowList.qzdd"
            >前置打单
            </el-button>
            <el-button type="text" @click="handleUpsOrder(scope.row)" v-else
            >拆单
            </el-button>
            <el-button type="text" @click="handleRecovery(scope.row)"
            >打单
            </el-button>
             <el-button type="text" @click="forecastingBox(scope.row)" v-if="scope.row.hasQianzhi == '1' && buttonShowList.ybxg"
            >预报箱规
            </el-button>
          </template>
          <template v-else-if="scope.column.property === 'solicitation'">
            <span>{{
                parseDict(scope.row.solicitation, "fba_solicitation")
              }}</span>
          </template>
          <template v-else-if="scope.column.property === 'transportStatus'">
            <span>{{
                parseDict(scope.row.transportStatus, "transportStatus")
              }}</span>
          </template>
          <template v-else-if="scope.column.property === 'orderNo'">
            <a
                style="color: #00a0e9"
                @click.prevent="$intoDocPage(scope.row.orderNo)"
            >{{ scope.row.orderNo }}</a
            >
          </template>
          <template v-else-if="scope.column.property === 'username'">
            <a
                type="text"
                @click.prevent="$intoCustomerEditPage(scope.row.cusId)"
                style="color: #57a3f3"
            >{{ scope.row.username }}</a
            >
          </template>
        </template>
      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination
            v-bind="ipagination"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        >
        </el-pagination>
      </el-row>
    </el-card>
    <!--推送地址-->
    <ups-push-address
        :row="upsPushRow"
        :visible="upsPushVisible"
        @update:visible="
        (val) => {
          upsPushVisible = val;
        }
      "
        @ok="handleSearch"
    ></ups-push-address>

    <!--拆单-->
    <label-split
        :row="checkAndRow"
        :visible="checkAndPlace"
        @update:visible="
        (val) => {
          checkAndPlace = val;
        }
      "
        @ok="handleSearch"
    ></label-split>

    <!--打单-->
    <check-and-place
        :row="labelSplitRow"
        :visible="labelSplitVisible"
        @update:visible="
        (val) => {
          labelSplitVisible = val;
        }
      "
        @ok="handleSearch"
    ></check-and-place>

     <el-dialog
        title="预报箱规数据"
        :visible="gaugeVisible"
        width="50%"
        custom-class="c-custom-dialog"
        :close-on-click-modal="false"
        :before-close="gaugeClose"
        append-to-body
    >
     <el-button
                type="primary"
                size="medium"
                @click="handleBatch('0')"
            >保存
            </el-button>
             <el-button
                type="primary"
                size="medium"
               @click="handleBatch('1')"
            >新增一行
            </el-button>
             <el-upload
          action="/"
          :show-file-list="false"
          :http-request="uploadSectionFile"
          accept=".xls,.xlsx"
          style="margin: 0 10px; display: inline"
        >
          <el-button size="medium" type="primary">导入</el-button>
        </el-upload>
            <el-button
                type="primary"
                size="medium"
               @click="downExcel"
            >导出
            </el-button>
             <el-button
                type="primary"
                size="medium"
                @click="downloadModel('/jeecg-cloud-file-biz/file/download/model/downloadYubaoCtn', '预报箱规数据模板')"
            >下载预报箱规数据模板
            </el-button>
      <el-form class="gaugeForm" ref="gaugeForm" :model="gaugeForm" label-width="60px" size="medium" :rules="gaugeRules">
      <f-table
                ref="GTable"
                border
                size="medium"
                row-key="procudeId"
                class="c-th-has-bgcolor"
                :columns="gaugeColumns"
                :data="gaugeForm.gaugeData"
                :cell-style="{ 'text-align': 'center' }"
                  @selection-change="handleSelectionChangeSelf"
            >
              <template v-slot="scope">
              <template v-if="scope.column.property === 'action'">
                 <el-button
                type="text"
              @click="handleBatch('2',scope.row,scope.$index)"
            >删除
            </el-button>
                </template>
                 <template v-else-if="scope.column.property === 'length'">
                  <el-form-item  :prop="'gaugeData.' + scope.$index + '.length'" :rules="gaugeRules.length">
                  <el-input v-model="scope.row.length"></el-input>
                </el-form-item>
                </template>
                  <template v-else-if="scope.column.property === 'width'">
                  <el-form-item  :prop="'gaugeData.' + scope.$index + '.width'" :rules="gaugeRules.width">
                  <el-input v-model="scope.row.width"></el-input>
                </el-form-item>
                </template>
                  <template v-else-if="scope.column.property === 'height'">
                  <el-form-item  :prop="'gaugeData.' + scope.$index + '.height'" :rules="gaugeRules.height">
                  <el-input v-model="scope.row.height"></el-input>
                </el-form-item>
                </template>
                  <template v-else-if="scope.column.property === 'weight'">
                  <el-form-item  :prop="'gaugeData.' + scope.$index + '.weight'" :rules="gaugeRules.weight">
                  <el-input v-model="scope.row.weight"></el-input>
                </el-form-item>
                </template>
                  <template v-else-if="scope.column.property === 'ctn'">
                  <el-form-item  :prop="'gaugeData.' + scope.$index + '.ctn'" :rules="gaugeRules.ctn">
                  <el-input v-model="scope.row.ctn"></el-input>
                </el-form-item>
                </template>
                   <template v-else-if="scope.column.property === 'index'">
                 {{scope.$index+=1}}
                </template>
                
              </template>
            </f-table>
      </el-form>
      

    </el-dialog>
  </div>
</template>

<script>
import {getAction, postAction} from "@/api/manage";
import QueryMixins from "../../../mixins/QueryMixins";
import upsPushAddress from "./modules/UpsPushAddress";
import LabelSplit from "./modules/LabelSplit";
import CheckAndPlace from "./modules/CheckAndPlace";
import {setUpRouteUtil} from "@/utils/util";
import {zhengshuPatternNew,validateSettlePrice} from "@/utils/validator";
import {downloadJasperExcelByMethodAndByUrlAndParamsAndFileName} from "@/utils/downloadJasper";
export default {
  name: "Potential",
  mixins: [QueryMixins],
  components: {
    upsPushAddress,
    LabelSplit,
    CheckAndPlace,
  },
  data() {
    return {
      dictCodes: ["transportStatus", "fba_solicitation"],
      ids: [],
      row: {},
      form: {},
      formLabelWidth: "100px",
      //控制弹框显示
      // dialogFormVisible: false,
      // dialogFormVisibleEmail: false,
      columns: [
        {
          type: "selection",
          width: 50,
        },
        {
          prop: "orderNo",
          label: "工作单号",
          sortable: true,
        },
        {
          prop: "customerName",
          label: "客户",
          sortable: true,
        },
        {
          prop: "fbaCode",
          label: "FBA CODE",
        },
        {
          prop: "lb",
          label: "重量（LBS）",
          sortable: true,
        },
        {
          prop: "ctn",
          label: "件数（CTN）",
          sortable: true,
        },
        {
          prop: "solicitation",
          label: "航线名称",
        },
        {
          prop: "transportStatus",
          label: "运输方式",
        },
        {
          prop: "action",
          label: "操作",
        },
      ],
      url: {
        // list: "/interface/label/list",
        list: "/interface/labelCenter/list",
        // edit: "/interface/label/edit",
        // getById: "/interface/label/queryById",
        // delete: "/interface/label/delete",
        // recovery: "/interface/label/recovery",
        // sendEmail: "/interface/label/sendEmail",
        // getPrintingStatus: "/interface/label/getPrintingStatus",
        getPrintingStatus: "/interface/labelCenter/getOrderByCenter",
        getOneLabelListVoByNo: "/interface/labelCenter/getOneLabelListVoByNo",
        insertCtnList:"/interface/yubaoCtns/insertCtnList",
        page:"/interface/yubaoCtns/page",
        deleteCtnList:"/interface/yubaoCtns/deleteCtnList",
        importYubaoCtnList:"/file/read/excel/importYubaoCtnList",
        downloadYubaoCtn:"/file/export/excel/downloadYubaoCtn"
      },
      // optionsSettlementType: [
      //   {
      //     value: "0",
      //     label: "票结",
      //   },
      //   {
      //     value: "1",
      //     label: "周结",
      //   },
      //   {
      //     value: "2",
      //     label: "月结",
      //   },
      //   {
      //     value: "3",
      //     label: "预付",
      //   },
      // ],
      // optionssupplierType: [
      //   {
      //     value: "1",
      //     label: "订舱代理",
      //   },
      //   {
      //     value: "2",
      //     label: "拖车代理",
      //   },
      //   {
      //     value: "3",
      //     label: "报关行",
      //   },
      //   {
      //     value: "4",
      //     label: "保险公司",
      //   },
      //   {
      //     value: "5",
      //     label: "清关代理",
      //   },
      //   {
      //     value: "6",
      //     label: "送货代理",
      //   },
      //   {
      //     value: "7",
      //     label: "船公司",
      //   },
      //   {
      //     value: "8",
      //     label: "拆柜代理",
      //   },
      // ],
      emailList: [],
      // userlist: [],
      // OrderNo: null,
      //推送地址弹框
      upsPushVisible: false,
      upsPushRow: {},
      //打单弹框
      labelSplitVisible: false,
      labelSplitRow: {},
      //拆单弹框
      checkAndPlace: false,
      checkAndRow: {},
      gaugeVisible:false,
      gaugeColumns:[
        
          {
          type: "selection",
          width: 50,
        },
          {
          prop: "action",
          label: "操作",
          width: 80,
        },
        {
          prop: "index",
          label: "编号",
             width: 60,
        },
         {
          prop: "length",
          label: "长cm",
        },
         {
          prop: "width",
          label: "宽cm",
        },
         {
          prop: "height",
          label: "高cm",
        },
         {
          prop: "weight",
          label: "单箱毛重KGS",
        },
         {
          prop: "ctn",
          label: "箱数",
        },
      ],
      gaugeForm:{
      gaugeData:[]
      },
      selectionRowsSelf:[],
      gaugeRules:{
          length: [
                        {required: true, message: "请填写", trigger: "change"},
                        {validator: validateSettlePrice, trigger: "change"}
                    ],
                     weight: [
                        {required: true, message: "请填写", trigger: "change"},
                        {validator: validateSettlePrice, trigger: "change"}
                    ],
                     width: [
                        {required: true, message: "请填写", trigger: "change"},
                        {validator: validateSettlePrice, trigger: "change"}
                    ],
                     height: [
                        {required: true, message: "请填写", trigger: "change"},
                        {validator: validateSettlePrice, trigger: "change"}
                    ],
                     ctn: [
                        {required: true, message: "请填写", trigger: "change"},
                        {validator: zhengshuPatternNew, trigger: "change"}
                    ],

      },
      gaugeRow:{}
    };
  },
  methods: {
    initCusPage({cusId}) {
      if (cusId) {
        // 对于路由不在左侧菜单中的选中菜单处理
        setUpRouteUtil(this.$route.path, "/customer/Edit");
        this.$router.push(`/customer/Edit?id=${cusId}`);
      }
    },
    // intoDocpage(orderNo) {
    //   // 对于路由不在左侧菜单中的选中菜单处理
    //   setUpRouteUtil(this.$route.path, "/docpage/Edit");
    //   this.$router.push("/docpage/Edit?orderNo=" + orderNo);
    // },
    handleSelectionChange(selection) {
      // 多选
      let arr = [];
      this.emailList = [];
      for (let i = 0; i < selection.length; i++) {
        arr.push(selection[i]["id"]);
        this.emailList.push(selection[i]["email"]);
      }
      this.selectionRows = selection;
      this.selectedRowKeys = [...arr];
    },

    init() {
      this.queryForm.cooperationType = "4";
    },
    // 点击推送地址
    handleEdit(row) {
      getAction(this.url.getOneLabelListVoByNo, {orderNo: row.orderNo})
          .then(res => {
            this.upsPushRow = res.result;
            this.upsPushVisible = true;
          })
      // this.upsPushVisible = true;
      // this.upsPushRow = row;
      //   this.OrderNo = row.orderNo;
      //   this.$refs.modalForm.edit(row);
      //   this.$refs.modalForm.title = "编辑";
      //   this.$refs.modalForm.disableSubmit = false;
    },
    // handleAdd() {
    //   this.dialogFormVisible = true;
    // },
    //点击拆单
    handleUpsOrder(record) {
      postAction(this.url.getPrintingStatus, {orderNo: record.orderNo}).then(
          (res) => {
            if (res.success) {
              this.checkAndPlace = true;
              this.checkAndRow = record;
            } else {
              this.$message.error(res.message);
            }
          }
      );
    },
    // 点击打单
    handleRecovery(record) {
      //判断是否正在打单
      postAction(this.url.getPrintingStatus, {orderNo: record.orderNo}).then(
          (res) => {
            if (res.success) {
              this.labelSplitVisible = true;
              this.labelSplitRow = record;
              // this.OrderNo = record.orderNo;
              // this.$refs.aboutPrice.edit(record);
              // this.$refs.aboutPrice.title = "报价清单";
              // this.$refs.aboutPrice.disableSubmit = false;
            } else {
              this.$message.error(res.message);
            }
          }
      );
    },
    handleReset() {
      this.queryForm = {};
      this.init();
      this.ipagination.currentPage = 1;
      this.handleQuery();
    },
    forecastingBox(row) {
      this.gaugeRow={...row}
this.initforecastingBox()
     this.gaugeVisible=true
    },
    handleBatch(type,row,index) {
       if(type == '0') {
               this.$refs["gaugeForm"].validate((valid) => {
                    if (valid) {
                      this.gaugeForm.gaugeData.forEach(item=>{
                        if(!item.orderNo){
                            item.orderNo = this.gaugeRow.orderNo
                        }
                      })
                         this.$store.commit("setFullscreenLoading", true);
                        postAction(this.url.insertCtnList, this.gaugeForm.gaugeData).then((res) => {
                            if (res.success) {
                                this.$message({
                                    type: "success",
                                    message: res.message,
                                });
                                 this.$store.commit("setFullscreenLoading", false);
                                this.gaugeClose()
                            }
                        }).catch(err => {
                            this.$store.commit("setFullscreenLoading", false);
                        });
                     }
                })
       }else if(type == '1') {
             this.gaugeForm.gaugeData.push({
              length:"",
              width:"",
              height:"",
              weight:"",
              ctn:"",
              index:this.gaugeForm.gaugeData.length
             })
       }else if(type == '2') {
        if(row.id) {
 this.$store.commit("setFullscreenLoading", true);
                        postAction(this.url.deleteCtnList, [row.id]).then((res) => {
                            if (res.success) {
                               postAction(this.url.page, {orderNo:this.gaugeRow.orderNo}).then((res) => {
                            if (res.success) {
                               this.gaugeForm.gaugeData.splice(index,1)
                                // this.gaugeForm.gaugeData = res.result.records
                            }
                        }).catch(err => {
                            this.$store.commit("setFullscreenLoading", false);
                        });
                            }
                        }).catch(err => {
                            this.$store.commit("setFullscreenLoading", false);
                        });
        }else {
          this.gaugeForm.gaugeData.splice(index,1)
        }
       }
    },
    handleSelectionChangeSelf(selection){
      this.selectionRowsSelf = selection;
    },
    gaugeClose() {
      
                                 this.gaugeVisible=false
                                 this.gaugeForm = {
                                    gaugeData:[],
                                 }
    },
      uploadSectionFile(param) {
      //file就是当前添加的文件
      var fileObj = param.file;
      //FormData 对象
      var form = new FormData();
      //文件对象，key是后台接受的参数名称
      form.append("file", fileObj);
      //执行上传方法
      console.log("执行上传方法");
      postAction(this.url.importYubaoCtnList+'?orderNo='+this.gaugeRow.orderNo,form).then((res) => {
        //这里做上传之后的操作
        if (res.success) {
          this.$message.success(res.message);
          this.initforecastingBox();
        } else {
          this.initforecastingBox();
        }
      });
    },
    initforecastingBox() {
        this.$store.commit("setFullscreenLoading", true);
       postAction(this.url.page, {orderNo:this.gaugeRow.orderNo}).then((res) => {
                            if (res.success) {
                              if(res.result.records && res.result.records.length) {
                                this.gaugeForm.gaugeData = res.result.records
                                 this.gaugeForm.gaugeData.forEach((item,index)=>{
                                  item.index = index
                                 })
                              }else {
            //                        this.gaugeForm.gaugeData.push({
            //   length:"",
            //   width:"",
            //   height:"",
            //   weight:"",
            //   ctn:"",
            //   index:this.gaugeForm.gaugeData.length
            //  })
                              }
                                this.$store.commit("setFullscreenLoading", false);
                               
                            }
                        }).catch(err => {
                            this.$store.commit("setFullscreenLoading", false);
                        });
    },
    downExcel() {
      downloadJasperExcelByMethodAndByUrlAndParamsAndFileName('POST',this.url.downloadYubaoCtn,{
                orderNo:this.gaugeRow.orderNo
               },'预报箱规数据.xls',this)
    }
    
  },
  created() {
    this.init();
    this.queryForm.initUser = 1;
  },
  computed:{
        buttonShowList() {
          return {
            'qzdd':this.$btnIsShow('operation/PrintLabelNew/WaitPrint','1','前置打单'),
             'ybxg':this.$btnIsShow('operation/PrintLabelNew/WaitPrint','1','预报箱规'),
            
          }
        }
      }
};
</script>

<style lang="scss" scoped>
::v-deep {
.gaugeForm {
.el-form-item__content {
  margin-left: 0 !important;
}
.el-form-item {
  // margin-bottom: 0 !important;
}
}
}
</style>
