<template>
    <div>
        <el-dialog
            :title="title"
            :visible="visible"
            width="70%"
            custom-class="c-custom-dialog"
            :close-on-click-modal="false"
            :destroy-on-close="true"
            :before-close="handleClose"
        >
            <div style="text-align: center"><span v-if="dataRight" style="font-size: 55px" class="rightFont">对账结果：无误</span></div>
            <el-button @click="applyAndDownload" v-if="dataRight">确定添加费用</el-button>
            <el-button @click="applyErrorAndDownload" v-if="!dataRight" :disabled="buttonDis">下载付款对账差异并将对账正确的费用添加到申请编号中</el-button>
            <el-form :model="billForm" :rules="rules" ref="billForm" :inline="true" :label-position="right"
                     label-width="109px" v-if="dataWrong">
                <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="billForm.settleList"
                    :cell-style="{ 'text-align': 'center' }"
                    style="margin-top: 15px"
                    @selection-change="handleSelectionChange"
                >
                    <template v-slot="scope">
                        <template v-if="scope.column.property === 'action'">

                            <el-tooltip class="item" effect="dark" content="点击退回至待开账单" placement="top">
                                <el-button type="text" @click="delFee(scope.row)">删除</el-button>
                            </el-tooltip>
                        </template>

                        <template v-else-if="scope.column.property === 'orderNo'">
                            <a style="color: #00a0e9;cursor:pointer" @click.prevent="$intoDocPage(scope.row)">{{ scope.row.orderNo }}</a>
                        </template>

                        <template v-else-if="scope.column.property === 'customer'">
                            <a style="color:#57a3f3;cursor:pointer" @click.prevent="$intoCustomerEditPage(scope.row.cusId)" >{{scope.row.customer}}</a>
                        </template>
                        <template v-else-if="scope.column.property === 'applyNo'">
                            <!--                            <el-button type="text" @click="showApplyDetail(scope.row)">{{scope.row.applyNo}}</el-button>-->
                            <a style="color: #00a0e9;cursor:pointer" @click.prevent="showApplyDetail(scope.row)">{{ scope.row.applyNo }}</a>
                        </template>

                        <!--                        <template v-else-if="scope.column.property === 'realPay'">-->
                        <!--                            <el-form-item-->
                        <!--                                    :rules="[{required: true, message: '实付不能为空', trigger: 'blur'}]">-->
                        <!--                                <el-input type="text" v-model="scope.row.realPay" @input="changeAmount" :placeholder="scope.row.exchangeTotal"></el-input>-->
                        <!--                            </el-form-item>-->

                        <!--                        </template>-->

                    </template>

                </f-table>
            </el-form>

        </el-dialog>
<!--        <apply-detail-dialog ref="applyDetailDialog"></apply-detail-dialog>-->

    </div>
</template>

<script>
import {getAction, postAction} from '@/api/manage'
// import applyDetailDialog from "@/views/hyFinanceManage/modules/pay/applyDetailDialog";
import { setUpRouteUtil } from '@/utils/util';
// import QueryMixins from "../../../../mixins/QueryMixins";

export default {
    name: 'reconciliationImportSettleDialog',
    // mixins: [QueryMixins],
    components:{
        // applyDetailDialog
    },

    data() {
        return {
            // id:'',
            buttonDis:false,
            addColumns: [],
            dataWrong: false,
            dataRight: false,
            addFeeDialogVisible: false,
            loading: false,
            right: 'right',
            remark: '',
            imgUrl: '',
            labelPosition: 'right',
            flag: false,
            visible: false,
            changeCurVisible: false,
            isChecked: true,
            ifDis: false,
            allKeys: [],
            fileList: [],
            defaultProps: {
                children: 'children',
                label: 'title'
            },
            billForm: {
                billType: 'noConvert',
                billTitle: '',
                payMan: '',
                amount: '',//实付
                remark: '',
                ids: [],
                orderNos: [],
                cur: '',//更改的币种
                bank: '',
                settleList: [],
                uploadFileIds:[],//上传文件的id，保存时增加申请单号
            },
            url: {
                // list: '/sys/bdCountry/list',
                deleteBatch: '/sys/bdCountry/deleteBatch',
                openBill: '/file/jasper/openReceiveBill',
                checkBill: '/shipping/hySettleReceive/checkReceiveSettleId',
                curGetBank: '/shipping/hySettleReceive/curGetBank',
                addApply: '/shipping/hySettleReceive/addApply',
                getSettlesByMergeNo: "/shipping/hySettlePay/getSettlesByMergeNo",
                uploadInvoice: '/shipping/hySettlePay/uploadInvoice',
                updateApplyAndDownload: '/file/jasper/updateApplyAndDownload',
                downloadErrorAndUpdate: '/shipping/hySettlePay/downloadErrorAndUpdate',
            },
            rules: {
                payMan: [
                    {required: true, message: '请输入付款人', trigger: 'blur'},
                ],
                amount: [
                    {required: true, message: '请输入金额', trigger: 'blur'},
                ],
                realPay: [
                    {required: true, message: '请输入金额', trigger: 'blur'},
                ],
                realMoneyType: [
                    {required: true, message: '请选择币种', trigger: 'blur'},
                ],
                bankId: [
                    {required: true, message: '请选择银行', trigger: 'blur'},
                ],


            },
            title: '收款申请',
            settleIds: [],
            showOnly: true,
            noChoose: [],
            columns: [
                // {
                //     type: 'selection',
                //     width: 50
                // },
                // {
                //     prop: 'action',
                //     label: '操作',
                //     width: 70
                // },
                {
                    prop: 'applyNo',
                    label: '付款申请编号',
                    width: 140
                },
                {
                    prop: 'remark',
                    label: '备注',
                    width: 140
                },
                {
                    prop: 'orderNo',
                    label: '工作单号/柜号',
                    width: 140
                },
                {
                    prop: 'operator',
                    label: '操作',
                    width: 140
                },
                {
                    prop: 'cur',
                    label: '币种',
                },

                {
                    prop: 'settlement',
                    label: '结算对象',
                },
                {
                    prop: 'amount',
                    label: '文档中金额',
                },
                {
                    prop: 'totalError',
                    label: '系统中金额',
                },
                {
                    prop: 'containerNo',
                    label: '柜号',
                },


            ],
            selectionRows: [],
            addSelectRows: [],
            containerFeesIDs:[],
            osFeesIDs:[],
            kdFeesIDs:[],
            errorType:'',
            applyNo:'',
        }
    },
    methods: {

        showApplyDetail(row) {
            this.$refs.applyDetailDialog.showDetail(row);
        },
        applyAndDownload(){
            // console.log('containerFeesIDs',this.containerFeesIDs)
            // console.log('osFeesIDs',this.osFeesIDs)
            // console.log('kdFeesIDs',this.kdFeesIDs)
            const obj = {
                resultFeesIdsContainer:this.containerFeesIDs,
                resultFeesIdsOs:this.osFeesIDs,
                resultFeesIdsKd:this.kdFeesIDs,
                applyNo:this.applyNo,
            }
            let that = this;
            // console.log('进入下载付款申请')
            postAction(this.url.updateApplyAndDownload, obj, {responseType: 'blob'})
                .then(res => {
                  if (res && res.type === 'application/json') {
                    const reader = new FileReader()
                    reader.onload = function () {
                      const message = JSON.parse(reader.result).message
                      that.$message.error(message);// 将错误信息显示出来
                    }
                    reader.readAsText(res)
                    return false;
                  }else {
                    // console.log(res)
                    if(res.size ===0){
                      this.$message.error('导出失败，请稍后再试或联系管理员')
                      return
                    }
                    var binaryData = [];
                    binaryData.push(res);
                    let filename = this.getDateStr()+ '导入对账付款申请'

                    let url = window.URL.createObjectURL(new Blob(binaryData, {type: 'application/vnd.ms-excel'}));
                    let link = document.createElement('a');
                    link.style.display = 'none';
                    link.href = url;
                    link.setAttribute('download', filename + '.xls');
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);//下载完成移除元素
                    window.URL.revokeObjectURL(url) //释放掉blob对象
                    // else{
                    //     let urlencoded = window.URL.createObjectURL(new Blob(binaryData,type));
                    //     window.open("/static/pdf/web/viewer.html?filename=" + filename + "&file=" + encodeURIComponent(urlencoded));
                    // }
                    this.buttonDis = true
                    // this.visible=false
                    setTimeout(()=>{   //设置延迟执行
                      this.$message.success("导出成功！")
                      this.$emit('ok')
                      // this.handleCheck();
                      this.visible = false
                    },3000)
                  }

                })

        },
        applyErrorAndDownload(){


            // console.log('进入下载付款对账存疑')
            let obj = {
                errorResult:this.billForm.settleList,
                errorType:'3',//柜子和订单费用混合
                resultFeesIdsOs:this.osFeesIDs,
                resultFeesIdsKd: this.kdFeesIDs,
                resultFeesIdsContainer:this.containerFeesIDs,
                applyNo:this.applyNo,
            }
            postAction(this.url.downloadErrorAndUpdate, obj, {responseType: 'blob'})
                .then(res => {
                    // console.log(res)
                    if(res.size ===0){
                        this.$message.error('导出失败，请稍后再试或联系管理员')
                        return
                    }
                    var binaryData = [];
                    binaryData.push(res);
                    let filename = this.getDateStr()+ '导入对账付款对账存疑'

                    let url = window.URL.createObjectURL(new Blob(binaryData, {type: 'application/vnd.ms-excel'}));
                    let link = document.createElement('a');
                    link.style.display = 'none';
                    link.href = url;
                    link.setAttribute('download', filename + '.xls');
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);//下载完成移除元素
                    window.URL.revokeObjectURL(url) //释放掉blob对象
                    // else{
                    //     let urlencoded = window.URL.createObjectURL(new Blob(binaryData,type));
                    //     window.open("/static/pdf/web/viewer.html?filename=" + filename + "&file=" + encodeURIComponent(urlencoded));
                    // }
                    this.buttonDis = true
                    // this.visible=false
                    setTimeout(()=>{   //设置延迟执行
                        this.$message.success("导出成功！")
                        // this.$emit('ok')
                        // this.handleCheck();
                    },3000)
                })

        },
        //进入客服页
        intoDocpage(row) {
            if (row.orderType==='2'){
                // 对于路由不在左侧菜单中的选中菜单处理
                setUpRouteUtil(this.$route.path, '/kdorder/Edit');
                this.$router.push("/kdorder/Edit?orderNo=" + row.orderNo);
            }else {
                // 对于路由不在左侧菜单中的选中菜单处理
                setUpRouteUtil(this.$route.path, '/docpage/Edit');
                this.$router.push("/docpage/Edit?orderNo=" + row.orderNo);
            }

        },
        //上传文件到oss
        handleUpload(data) {
            // if (data.file.name.length> 20){
            //     this.$message.warning("文件名过长，自动截取前50个字符");
            //     let index = data.file.name.lastIndexOf(".");
            //     let suffix = data.file.name.substr(index+1);
            //     console.log(suffix)
            //     data.file = new File([data.file], data.file.name.substring(0, 10) + '...' + suffix)
            // }

            const formData = new FormData();
            formData.append("file", data.file);

            postAction(this.url.uploadInvoice, formData).then(res => {
                // console.log(res)
                if (res.success) {
                    //上传成功,返回结果赋值给form对象的文件集合
                    this.billForm.uploadFileIds.push(res.result.id);
                    // console.log(this.billForm.uploadFileIds)
                    this.fileList.push(res.result);
                    this.$message.success('上传成功');
                }else{

                }
            })
        },
        beforeUpload(file) {
            //对文件大小进行限制
            const isLt2M = file.size / 1024 / 1024 < 15;
            if (!isLt2M) {
                this.$message.warning("文件大小不能超过15MB");
            }
            const isFile = file.name.indexOf(".") !== -1;
            if (!isFile) {
                this.$message.warning("该文件名不合法");
            }
            const len = file.name.length < 100;
            if (!len) {
                this.$message.warning("该文件名太长，请修改文件名最多100个字符");
            }
            return isLt2M && isFile && len;
        },
        changeAmount() {
            let sumPay = 0;
            this.billForm.settleList.forEach(item => sumPay = Number(sumPay) + Number(item.realPay));
            this.billForm.amount = sumPay.toFixed(3);
        },
        toS(v) {
            return String(v)
        },
        delFee(row) {
            // console.log(row)
            this.billForm.settleList.splice(this.billForm.settleList.indexOf(row));
        },
        changeCurBank() {
            this.changeCurVisible = true;
        },
        curChangeBank(val) {
            // console.log(val)
            getAction(this.url.curGetBank, {cur: val}).then((res) => {
                // console.log(res)
                this.banks = res.result
            })
        },
        addSelectionChange(selection) { // 多选
            // console.log(selection)
            this.addSelectRows = selection;
        },
        addFee() {
            this.addFeeDialogVisible = true;
        },
        addSubmit() {
            // this.settleList.concat(this.addSelectRows)
            // this.addSelectRows = []
            for (let i = 0; i < this.addSelectRows.length; i++) {
                this.billForm.settleList.push(this.addSelectRows[i])
                this.noChoose.splice(this.noChoose.indexOf(this.addSelectRows[i]))
            }
            this.addFeeDialogVisible = false;
            this.addSelectRows = []

        },
        changeCurSubmit() {
            // console.log(this.billForm)
            // console.log(this.billForm.settleList)
            // 修改原tableData中的币种和银行
            this.billForm.settleList.forEach(data => {
                data.realMoneyType = this.billForm.cur
                data.bank = this.billForm.bank
            })


            this.curBankDialogClose()
        },
        handleSubmit() {
            this.changeAmount();
            // if(Number(this.billForm.amount)===0){
            //     this.$message.warning("金额不能为零！")
            //     return;
            // }
            this.$refs.billForm.validate((valid) => {
                if (valid) {
                    postAction(this.url.addApply, this.billForm).then((res) => {
                        // console.log(res)
                        if (res.success === true) {
                            this.visible = false
                            this.$emit('ok')
                        }
                    })
                }
            })
        },
        handleClose() {
            // this.$refs.form.resetFields()
            this.visible = false;

        },
        curBankDialogClose() {
            // this.$refs.form.resetFields()
            this.changeCurVisible = false;

        },
        handleAddFeeDialogClose() {
            // this.$emit('update:visible', false)
            // this.$refs.form.resetFields()
            this.addFeeDialogVisible = false;

        },
        enabled() {
            setTimeout(() => {
                this.ifDis = false;
            }, 2000)
        },
        edit(result,applyNo) {
            // console.log("对账的rows", result.errorResult)
            this.applyNo = applyNo
            this.billForm.settleList = JSON.parse(JSON.stringify(result.errorResult))
            this.showOnly = true;
            this.title = '添加费用-导入对账存疑详情'
            this.visible = true
            this.dataRight = false
            this.dataWrong = true
            this.errorType = '3'
            this.osFeesIDs=result.resultFeesIdsOs
            this.kdFeesIDs=result.resultFeesIdsKd
            this.containerFeesIDs=result.resultFeesIdsContainer
            // console.log('osFeesIDs',this.osFeesIDs)
            // console.log('kdFeesIDs',this.kdFeesIDs)
            // console.log('containerFeesIDs',this.containerFeesIDs)
            this.buttonDis = false
        },
        editNoProblem(result,applyNo){
            // console.log('editNoProblem',result)
            this.applyNo = applyNo
            this.containerFeesIDs = result.resultFeesIdsContainer
            this.osFeesIDs = result.resultFeesIdsOs
            this.kdFeesIDs = result.resultFeesIdsKd
            this.showOnly = true;
            this.title = '添加费用-导入对账详情'
            this.visible = true
            this.dataRight = true
            this.dataWrong = false
        },
        showDetail(row) {
            // console.log('申请对象->', row);
            getAction(this.url.getSettlesByMergeNo, {mergeNo: row.mergeNo}).then((res) => {
                // console.log(res)
                this.billForm.settleList = res.result.settleList;
                this.billForm.payMan = res.result.payMan;
                this.billForm.amount = res.result.amount;
                this.billForm.invoiceRemark = res.result.invoiceRemark;
                this.billForm.invoiceNo = res.result.invoiceNo;
                // this.fileList = res.result.uploadedFiles;
            })
            this.title = '合并申请详情'
            this.showOnly = false;
            this.visible = true
        },

        handleRemove(file, fileList) {
            this.fileList.map((item, index) => {
                if (item.uid == file.uid) {
                    //向服务器发送要删除的文件的路径，删除oss上的文件
                    // console.log(item)
                    postAction(this.url.delFile,{osOrderFileId:item.id, url:item.url}).then((res)=>{
                        // console.log(res)
                        //页面的上的文件也要删除
                        this.fileList.splice(index, 1)
                        this.billForm.uploadFileIds.splice(this.billForm.uploadFileIds.indexOf(item.id),1);
                    })

                }
            })
        },
        handlePicPreview(file) {
            // console.log(file)
            // console.log('新窗口打开')
            window.open(file.url,'_blank')
        },
        handleSelectionChange(selection) { // 多选
            // console.log(selection)
        },
        getDateStr(){
            let date = new Date();
            let seperator = '-';
            let year = date.getFullYear();
            let month = date.getMonth() + 1;
            let day = date.getDate()
            if(month >= 1 && month <= 9){
                month = '0' + month
            }
            if(day >= 0 && day <= 9){
                day = '0' + day
            }
            return year + seperator + month + seperator + day
        }
    }
}
</script>

<style lang="scss" scoped>
.btn-wrap {
    text-align: right;
    padding: 10px 30px;
}

.content {
    height: 620px;
    padding: 6px 0;
    overflow: auto;
}

.rightFont {
    font-size: large !important;
}

</style>
