<!--待发货-->
<template>
    <div class="main-full-content">
        <el-form class="multiline_select" ref="form" :model="queryForm" label-width="120px" size="medium">
            <el-card class="box-card">
                <el-row style="display: flex;flex-wrap: wrap;">
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="订单号">
                            <el-input class="textarea_table" v-model="queryForm.orderNo" type="textarea"
                                      @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="运单号">
                            <el-input class="textarea_table" v-model="queryForm.runOrderNo" type="textarea"
                                      @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="跟踪号">
                            <el-input class="textarea_table" v-model="queryForm.logNo" type="textarea"
                                      @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="渠道名称">
                            <f-select :multiple="true" v-model="selectChannelList" :dict="'kd_channel'"
                                      :isNeed="selectChannelList" ></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="服务商">
                            <el-select
                                    v-model="queryForm.supId"
                                    filterable
                                    clearable
                                    @change="getSupplierChannel"
                                    style="width: 100%"
                            >
                                <el-option
                                        v-for="(item, index) in allSupplierList"
                                        :value="item.value"
                                        :label="item.label"
                                        :key="index"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="服务商渠道">
                            <el-select
                                    v-model="queryForm.supChannelId"
                                    filterable
                                    clearable
                                    style="width: 100%"
                            >
                                <el-option
                                        v-for="(item, index) in supplierChannelList"
                                        :value="item.value"
                                        :label="item.label"
                                        :key="index"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>

                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="下单开始时间">
                            <el-date-picker
                                    class="f-flex-1"
                                    v-model="queryForm.createTime"
                                    type="datetime"
                                    size="small"
                                    placeholder="下单开始时间"
                                    default-time="23:59:59"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    format="yyyy-MM-dd HH:mm:ss"
                                    style="width: 100%"
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="下单结束时间">
                            <el-date-picker
                                    class="f-flex-1"
                                    v-model="queryForm.createTimeEnd"
                                    type="datetime"
                                    size="small"
                                    placeholder="下单结束时间"
                                    default-time="23:59:59"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    format="yyyy-MM-dd HH:mm:ss"
                                    style="width: 100%"
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="录材积开始时间">
                            <el-date-picker
                                    class="f-flex-1"
                                    v-model="queryForm.volumeSureTime"
                                    type="datetime"
                                    size="small"
                                    placeholder="录材积开始时间"
                                    default-time="00:00:00"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    format="yyyy-MM-dd HH:mm:ss"
                                    style="width: 100%;"
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="录材积结束时间">
                            <el-date-picker
                                    class="f-flex-1"
                                    v-model="queryForm.volumeSureTimeEnd"
                                    type="datetime"
                                    size="small"
                                    placeholder="录材积结束时间"
                                    default-time="23:59:59"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    format="yyyy-MM-dd HH:mm:ss"
                                    style="width: 100%"
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="发货开始时间">
                            <el-date-picker
                                    class="f-flex-1"
                                    v-model="queryForm.deliverGoodsTime"
                                    type="datetime"
                                    size="small"
                                    placeholder="发货开始时间"
                                    default-time="00:00:00"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    format="yyyy-MM-dd HH:mm:ss"
                                    style="width: 100%;"
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="发货结束时间">
                            <el-date-picker
                                    class="f-flex-1"
                                    v-model="queryForm.deliverGoodsTimeEnd"
                                    type="datetime"
                                    size="small"
                                    placeholder="发货结束时间"
                                    default-time="23:59:59"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    format="yyyy-MM-dd HH:mm:ss"
                                    style="width: 100%"
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>

                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="货物状态">
                            <f-select v-model="queryForm.orderStatus" :dict="'kd_order_status'"
                                      :isNeed="queryForm.orderStatus"></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="客户名">
                            <el-input class="textarea_table" v-model="queryForm.cusName"
                                      @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="业务员">
                            <f-select v-model="queryForm.salesmanIdList" :dict="'sys_user_id'"
                                      :isNeed="queryForm.salesmanIdList" :multiple="true"></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col  :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="客服">
                            <f-select v-model="queryForm.docIdList" :multiple="true" :dict="'sys_user_id'" :isNeed="queryForm.docIdList"></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="货运类型">
                            <f-select v-model="queryForm.goodType" :dict="'kd_good_type'" :isNeed="queryForm.goodType"></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="付款状态">
                            <f-select v-model="queryForm.payStatusList" :dict="'os_pay_status'" :isNeed="queryForm.payStatusList" :multiple="true"></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="收款状态">
                            <f-select v-model="queryForm.recStatusList" :dict="'kd_order_rec_status'" :isNeed="queryForm.recStatusList" :multiple="true"></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="国家">
                            <f-select v-model="queryForm.countryList" :multiple="true" :dict="'bd_country'" :isNeed="queryForm.countryList"></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="业务提成">
                            <f-select v-model="queryForm.salesmanCommission"  :dict="'unit_whether'" :isNeed="queryForm.salesmanCommission"></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                       <el-form-item label="交货仓库" prop="deliveryWarehouse">
                            <f-select  clearable filterable
                                      :dict="'delivery_point'"
                                      v-model="queryForm.deliveryWarehouse"
                                      :isNeed="queryForm.deliveryWarehouse"
                                      placeholder="请选择">
                            </f-select>
                        </el-form-item>
                    </el-col>

                     <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="送货/提货开始时间">
                          <el-date-picker
                                    class="f-flex-1"
                                    v-model="queryForm.deliveryStartTime"
                                    type="datetime"
                                    size="small"
                                    placeholder="送货/提货开始时间"
                                    default-time="00:00:00"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    format="yyyy-MM-dd HH:mm:ss"
                                    style="width: 100%;"
                            >
                            </el-date-picker>
                        </el-form-item>
                     </el-col>


                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                      <el-form-item label="送货/提货结束时间">
                        <el-date-picker
                                    class="f-flex-1"
                                    v-model="queryForm.deliveryEndTime"
                                    type="datetime"
                                    size="small"
                                    placeholder="送货/提货结束时间"
                                    default-time="00:00:00"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    format="yyyy-MM-dd HH:mm:ss"
                                    style="width: 100%;"
                        >
                        </el-date-picker>
                      </el-form-item>
                    </el-col>

                  <el-col :xs="24" :sm="12" :md="12" :lg="4">
                    <el-form-item label="销售组别">
                      <f-select
                          v-model="queryForm.deptIdList"
                          :dict="'sys_depart'"
                          :isNeed="queryForm.deptIdList"
                          :multiple="true"
                      >
                      </f-select>
                    </el-form-item>
                  </el-col>

                     <el-col :xs="24" :sm="12" :md="12" :lg="4">
                    <el-form-item label="自主飞">
                      <f-select
                          v-model="queryForm.isAutoFly"
                          :dict="'is_default'"
                          :isNeed="queryForm.isAutoFly"
                      >
                      </f-select>
                    </el-form-item>
                  </el-col>


                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label-width="30px">
                            <el-button type="primary" icon="el-icon-search" @click="handleSearchM">查询</el-button>
                            <el-button plain @click="handleReset">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>
        </el-form>
        <el-card class="box-card last_card">
            <div class="f-p-b-15">
                <el-button type="primary" size="medium" @click="handleExportXlsM"
                           v-if="buttonShowList.dc">导出
                </el-button>
                <el-button type="primary" size="medium" @click="kdCount"
                           v-if="buttonShowList.tj">统计
                </el-button>
              <el-button
                  type="primary"
                  size="medium"
                  @click="downloadModel(url.downloadHyYfModel, '快递应付批量导入模板下载')"
                  v-if="buttonShowList.xzmb"
              >快递应付批量导入模板下载
              </el-button>
              <el-upload
                    class="upload-demo"
                    action="/"
                    :http-request="addUploadOssClirnt"
                    :show-file-list="false"
                    ref="add"
                    :multiple="true"
                    :before-upload="$beforeUpload"
                    accept=".xls,.xlsx"
                    style="margin:0 5px;display:inline"
                    >
                <el-button size="medium" type="primary" :loading="btnLoading"
                v-if="buttonShowList.up"
                >上传账单文件</el-button>
            </el-upload>
              <el-upload
                  action="/"
                  :show-file-list="false"
                  :http-request="uploadSectionFile"
                  accept=".xls,.xlsx"
                  style="margin:0 10px;display:inline"
              >
                <el-button size="medium" type="primary"
                           :loading="btnLoading"
                           v-if="buttonShowList.im"
                >批量快递应付导入
                </el-button>
              </el-upload>
              <el-button
                  type="primary"
                  size="medium"
                  @click="downloadModel(url.downloadHyYsModel, '快递应收批量导入模板下载')"
                  v-if="buttonShowList.xzmb2"
              >快递应收批量导入模板下载
              </el-button>
              <el-upload
                  action="/"
                  :show-file-list="false"
                  :http-request="uploadSectionFileYs"
                  accept=".xls,.xlsx"
                  style="margin:0 10px;display:inline"
              >
                <el-button size="medium" type="primary"
                           :loading="btnLoadingYs"
                           v-if="buttonShowList.im2"
                >批量快递应收导入
                </el-button>
              </el-upload>

                <el-button type="primary" size="medium" @click="syncDoc" v-if="buttonShowList.tbkf">同步客服
                </el-button>
                <el-button type="primary" size="medium" @click="updateDoc" v-if="buttonShowList.xgkf" :disabled="selectedRowOrderNos.length === 0">修改客服
                </el-button>
                <el-button type="primary" size="medium" @click="handleExportXlsStatus"
                           v-if="buttonShowList.dcwlxx">导出物流信息
                </el-button>
                 <el-button type="primary" size="medium" @click="handleIsAutoFly" :disabled="!selectionRows.length"
                           v-if="buttonShowList.zzf">批量修改自主飞
                </el-button>
                   <el-button
          type="primary"
          :disabled="selectionRows.length === 0"
          @click="tieReminder"
           v-if="buttonShowList.tbtx"
          >特别提醒
        </el-button>
          <el-button
          type="primary"
          :disabled="selectionRows.length === 0"
          @click="batchUpdate"
           v-if="buttonShowList.plgxtc"
          >批量更新业务员提成
        </el-button>
            </div>
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="67vh"
                    :isRecordTableScroll="true"
                    :show-operation="true"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange">
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'declareType'">
                        <span style="color: red">{{parseDict(scope.row.declareType,'kd_declare_type')}}</span>
                    </template>
<!--                    <template v-else-if="scope.column.property === 'channel'">-->
<!--                        <span>{{parseDict(scope.row.channel,'kd_channel')}}</span>-->
<!--                    </template>-->
                    <template v-else-if="scope.column.property === 'orderNo'">
                        <a style="color: #00a0e9"
                           @click.prevent="handleEdit(scope.row.orderNo)">{{scope.row.orderNo}}</a>
                    </template>
                    <template v-else-if="scope.column.property === 'salesman'">
                        <span>{{scope.row.salesman}}</span> <br>
                        <span v-if="scope.row.salesmanCommission === '1'" style="color: #00a0e9">已发提成</span>
                        <span v-else style="color: red">未发提成</span>
                    </template>
                    <template v-else-if="scope.column.property === 'cusName'">
                        <a type="text" @click.prevent="$intoCustomerEditPage(scope.row.cusId)" style="color:#57a3f3;">{{scope.row.cusName}}</a>
                    </template>
                    <template v-else-if="scope.column.property === 'supId'">
                        <span>{{parseDict(scope.row.supId,'supplier_id')}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'supChannelId'">
                        <span>{{parseDict(scope.row.supChannelId,'supplier_channel_id')}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'orderStatus'">
<!--                        <span>{{parseDict(scope.row.orderStatus,'kd_order_status')}}</span>-->
                        <span>{{$parseKdOrderStatus(scope.row.orderStatus)}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'receiveCountry'">
                        <span v-if="scope.row.isRemote==null || scope.row.isRemote=='0'">{{(scope.row.receiveCountry)}}</span>
                        <span v-else="scope.row.isRemote" style="color: red;font-weight: bold">{{(scope.row.receiveCountry)}}</span>
                        <span v-if="scope.row.receiveFbaCode"> - {{scope.row.receiveFbaCode}}</span>
                        <span v-if="scope.row.zipCode"> - {{scope.row.zipCode}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'isBattery'">
                        <span>{{(scope.row.isBattery == '1' ? '是' : '否')}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'isCi'">
                        <span>{{(scope.row.isCi == '1' ? '是' : '否')}}</span>
                    </template>
                    
                    <template v-else-if="scope.column.property === 'goodType'">
                        <span>{{parseDict(scope.row.goodType,'kd_good_type')}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'deliveryWarehouse'">
                        <span>{{parseDict(scope.row.deliveryWarehouse,'delivery_point')}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'deptId'">
                        <span>{{parseDict(scope.row.deptId,'sys_depart')}}</span>
                    </template>
                      <template v-else-if="scope.column.property === 'isAutoFly'">
                        <span>{{parseDict(scope.row.isAutoFly,'is_default')}}</span>
                    </template>
<!--                    `rec_status` varchar(1) DEFAULT NULL COMMENT '收款状态 0无应收 1未收款 2部分收款 3已支付',
  `pay_status` varchar(1) DEFAULT NULL COMMENT '付款状态 0无应付 1未付款 2部分付款 3已支付',-->
                    <template v-else-if="scope.column.property === 'payStatus'">
                        <span>付款状态：{{parsePayStatus(scope.row.payStatus)}}</span> <br>
                        <span>收款状态：{{parseRecStatus(scope.row.recStatus)}}</span>
                    </template>
                </template>
            </f-table>
            <el-row class="f-p-v-8 bottom_flex">
                <el-col :span="12" class="total_price">
                    <span>{{'总票数：' + (countList.total || '0')}}</span>
                    <span>{{'总件数：' + (countList.totalCtn || '0')}}</span>
                    <span>{{'总计费重：' + (countList.totalUpdateFeeWeight || '0')}}</span>
                    <span>{{'总实重：' + (countList.totalActualWeight || '0')}}</span>
                    <span>{{'总出货重：' + (countList.totalShipmentWeight || '0')}}</span>
                    <br/>
                    <span>{{'总应收金额：' + (totalRecMoney || '0')}}</span>
                    <span>{{'总已收金额：' + (totalRecedMoney || '0')}}</span>
                    <span>{{'预计总重量：' + (countList.totalKg || '0')}}</span>
                    <span>{{'预计总体积：' + (countList.totalCmb || '0')}}</span>
                </el-col>
                <el-col class="f-text-right" :span="12">
                    <el-pagination
                            v-bind="ipagination"
                            layout="total, sizes, prev, pager, next, jumper"
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange">
                    </el-pagination>
                </el-col>
            </el-row>
        </el-card>
        <el-dialog title="统计数据显示" :visible.sync="dialogKdCountVisible" :close-on-click-modal="false"
                   v-if="dialogKdCountVisible">
            <f-table
                    ref="kdCountTb"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columnsCount"
                    :data="kdCountNum"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="60vh"
            >
            </f-table>
        </el-dialog>

        <el-dialog title="修改客服" :visible.sync="dialogUpdateDocVisible" :close-on-click-modal="false"
                   v-if="dialogUpdateDocVisible">
            <el-form :model="form" :rules="rules" ref="form">

                <el-form-item label="单号" :label-width="formLabelWidth" prop="orderNo">
                    <el-input v-model="form.orderNo" :disabled="true" ></el-input>
                </el-form-item>
                <el-form-item label="客服" :label-width="formLabelWidth" prop="docId">
                    <f-select v-model="form.docId" :dict="'sys_user_id'" @changet="getDoc"></f-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogUpdateDocVisible = false">取 消</el-button>
                <el-button type="primary" @click="updateDocSure()">确 定</el-button>
            </div>
        </el-dialog>


          <el-dialog title="特别提醒"
                   :visible.sync="dialogTieReminderVisible"
                   :close-on-click-modal="false"
                   v-if="dialogTieReminderVisible">
            <el-form :model="tieForm" ref="tieForm" :rules="rules">
                <el-form-item label="标题" :label-width="formLabelWidth" prop="title">
                    <el-input v-model="tieForm.title"></el-input>
                </el-form-item>
                <el-form-item label="提醒事项" :label-width="formLabelWidth" prop="remark">
                    <el-input v-model="tieForm.remark" type="textarea"></el-input>
                </el-form-item>
                <el-form-item label="文件类型" :label-width="formLabelWidth" prop="fileType">
                    <f-select :dict="'kd_file_type'" v-model="tieForm.fileType"
                              :isNeed="tieForm.fileType"></f-select>
                </el-form-item>
                <el-form-item label="提醒文件" :label-width="formLabelWidth" prop="fileList">
                    <f-upload v-model="tieForm.fileList" :dir="'osOrder'" :isUseOss="true"></f-upload>
                </el-form-item>
                <el-form-item label="是否推送邮件" :label-width="formLabelWidth" prop="isSendEmail">

                    <template>
                        <el-radio-group v-model="tieForm.isSendEmail">
                            <el-radio label="0">否</el-radio>
                            <el-radio label="1">是</el-radio>
                        </el-radio-group>
                    </template>
                </el-form-item>
                <el-form-item label="是否异常动态" :label-width="formLabelWidth" prop="isRemind">
                    <el-radio-group v-model="tieForm.isRemind">
                        <el-radio label="0">否</el-radio>
                        <el-radio label="1">是</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogTieReminderVisible = false">取 消</el-button>
                <el-button type="primary" @click="sendTieReminderEmail">确 定</el-button>
            </div>
        </el-dialog>

        <auto-fly-dialog  :visible="autoFlyVis" :autoFlyList='autoFlyList'
      @update:visible="
        (val) => {
          autoFlyVis = val;
        }
      "
      @ok="handleQuery"/>

    </div>
</template>

<script>
    import QueryMixins from "../../mixins/QueryMixins";
    import {getAction, getAllSupplier, postAction} from '@/api/manage'
    import {setUpRouteUtil} from '@/utils/util';
    import OssClientMixins from "@/mixins/OssClientMixins";
     import AutoFlyDialog from "./modules/AutoFlyDialog.vue";

    export default {
        mixins: [QueryMixins,OssClientMixins],
        components:{
            AutoFlyDialog
        },
        data() {
            return {
                isUsePost:true,
                rules: {
                    supId: [
                        {required: true, message: '请选择服务商', trigger: 'change'},
                    ],
                    supChannelId: [
                        {required: true, message: '请选择服务商渠道', trigger: 'change'},
                    ],
                    logNo: [
                        {max: 80, message: '长度不能超过80', trigger: 'change'},
                    ],
                    doc: [
                        {required: true, message: '请选择客服', trigger: 'change'},
                    ],
                     title: [
          { required: true, message: "请填写标题", trigger: "change" },
          { max: 100, message: "长度不能超过100", trigger: "change" },
        ],
        remark: [
          { required: true, message: "请填写提醒事项", trigger: "change" },
          { max: 1000, message: "长度不能超过1000", trigger: "change" },
        ],
                },
                dictCodes: ['kd_declare_type', 'is_default', 'supplier_id', 'supplier_channel_id','kd_good_type','delivery_point','sys_depart'],
                ids: [],
                row: {},
                form: {},
                btnLoading: false,
                btnLoadingYs: false,
                proVisible: false,
                formLabelWidth: '100px',
                //控制弹框显示
                dialogFormVisible: false,
                dialogUpdateDocVisible: false,
                columns: [
                    {
                        type: 'selection',
                        width: 50
                    },
                    {
                        prop: 'createTime',
                        label: '下单时间',
                        sortable: 'custom',
                        width: 120,
                    },
                    {
                        prop: 'orderNo',
                        label: '订单号',
                    },
                      {
                        prop: 'deliveryWarehouse',
                        label: '交货仓',
                    },
                      {
                        prop: 'deliveryTime',
                        label: '送货/提货时间',
                        width: 120
                    },
                    {
                        prop: 'runOrderNo',
                        label: '运单号',
                    },
                    {
                        prop: 'logNo',
                        label: '跟踪号',
                    },
                    {
                        prop: 'orderStatus',
                        label: '货物状态',
                    },
                    {
                        prop: 'cusName',
                        label: '客户名',
                    },
                    {
                        prop: 'deptId',
                        label: '销售组别',
                    },
                    {
                        prop: 'salesman',
                        label: '业务员',
                    },
                    {
                        prop: 'doc',
                        label: '客服',
                    },
                    {
                        prop: 'channelName',
                        label: '渠道名称',
                    },
                    {
                        prop: 'declareType',
                        label: '报关类型',
                    },
                      {
                        prop: 'orderGoodsName',
                        label: '货物品名',
                    },
                     {
                        prop: 'receiveCountry',
                        label: '目的地',
                    },
                  
                    {
                        prop: 'isAutoFly',
                        label: '自主飞',
                    },
                    {
                        prop: 'isBattery',
                        label: '是否带电池',
                        width: 65
                    },
                    {
                        prop: 'isCi',
                        label: '是否带磁',
                        width: 60
                    },
                    {
                        prop: 'ctn',
                        label: '预计件数',
                        sortable: 'custom',
                        width: 80
                    },
                    {
                        prop: 'kg',
                        label: '预计重量',
                        sortable: 'custom',
                        width: 80
                    },
                    {
                        prop: 'cmb',
                        label: '预计体积',
                        sortable: 'custom',
                        width: 80
                    },
                    {
                        prop: 'actualCtn',
                        label: '件数',
                        sortable: 'custom',
                        width: 60
                    },
                    {
                        prop: 'actualWeight',
                        label: '实重',
                        sortable: 'custom',
                        width: 60
                    },
                    {
                        prop: 'actualCmbWeight',
                        label: '材积重',
                        sortable: 'custom',
                        width: 80
                    },
                    {
                        prop: 'updateFeeWeight',
                        label: '计费重',
                        sortable: 'custom',
                        width: 80
                    },
                    {
                        prop: 'shipmentWeight',
                        label: '出货重',
                        sortable: 'custom',
                        width: 80
                    },
                    {
                        prop: 'guanShuiMoney',
                        label: '关税预收',
                        width: 80
                    },
                    {
                        prop: 'shouldRecMoney',
                        label: '总金额',
                        width: 80
                    },
                    {
                        prop: 'noRecMoney',
                        label: '未支付',
                        width: 80
                    },
                    {
                        prop: 'realRecMoney',
                        label: '已支付',
                        width: 80
                    },
                    {
                        prop: 'recBur',
                        label: '应收币种',
                        width: 70
                    },
                    {
                        prop: 'payBur',
                        label: '应付币种',
                        width: 70
                    },
                    {
                        prop: 'payStatus',
                        label: '付款状态',
                        width: 120
                    },
                    {
                        prop: 'payType',
                        label: '付款类型',
                        width: 100
                    },
                    {
                        prop: 'supId',
                        label: '服务商',
                    },
                    {
                        prop: 'supChannelId',
                        label: '服务商渠道',
                    },
                    {
                        prop: 'volumeSureTime',
                        label: '材积录入时间',
                        sortable: 'custom',
                        width: 100
                    },
                    {
                        prop: 'deliverGoodsTime',
                        label: '发货时间',
                        sortable: 'custom',
                    },
                    {
                        prop: 'goodType',
                        label: '货运类型',
                        sortable: 'custom',
                    },
                  {
                    prop: 'insideRemark',
                    label: '内部备注',
                  },
                  {
                    prop: 'cusRemark',
                    label: '客户备注',
                  },
                ],
                columnsCount: [
                    {
                        prop: 'receivable',
                        label: '应收',
                    },
                    {
                        prop: 'realPay',
                        label: '实收',
                    },
                    {
                        prop: 'payable',
                        label: '应付',
                    },
                ],
                url: {
                    list: '/order/kdOrder/allOrderList',
                    queryBysupId: '/sys/supplierChannel/queryBysupId', // 根据供应商ID 获取供应商渠道
                    listCount: "/order/kdOrder/listCount", //获取total-总票数,totalCtn-总件数,totalUpdateFeeWeight-总计费重
                    exportXlsUrl: '/file/kd1/dckdOrderEExcelVO2',
                    kdSettleCount: "/order/kdOrder/kdSettleCount",
                    importHySettles: "/order/kdOrderSettle/importKdSettles",
                    importKdYsSettles: "/order/kdOrderSettle/importKdYsSettles",
                    downloadHyYsModel: '/order/os/settle/Hy/in/downloadKdYsExcel',
                    downloadHyYfModel: '/order/os/settle/Hy/in/downloadKdYfExcel',
                    syncDoc: "/order/kdOrder/syncDoc", //同步客服
                    updateDoc: "/order/kdOrder/updateDoc", //修改客服
                     tieReminder: "/file/sendEmail/tieReminder", //特别提醒
                    updateBatchSalesmanComission:"/order/kdOrder/updateBatchSalesmanComission"

                   
                },
                allSupplierList: [],   //快递供应商
                supplierChannelList: [], //快递供应商的渠道
                selectChannelList: [],  //筛选渠道列表
                countList: {},  //统计总值
                dialogKdCountVisible: false,
                kdCountNum: {},  //快递统计总数
                totalRecMoney:'',
                totalRecedMoney:'',
                orderNos:[],
                autoFlyVis:false,
                autoFlyList:[],
                dialogTieReminderVisible:false,
                tieForm: { fileList: [] },
            }
        },
        methods: {
            async addUploadOssClirnt(param) {
                var fileObj = param.file;
                let uuid = this.generateUUID(6);
                this.kdHandleUploadOss(param,fileObj, uuid,this.selectedRowOrderNos,"kdOrder","bill");
            },
                // 获取uuid
            generateUUID(length) {
                var d = new Date().getTime();
                let uuid = d.toString() + Math.random().toString().substr(3, length);
                return uuid;
            },

            /**<!--                    `rec_status` varchar(1) DEFAULT NULL COMMENT '收款状态 0无应收 1未收款 2部分收款 3已支付',
             `pay_status` varchar(1) DEFAULT NULL COMMENT '付款状态 0无应付 1未付款 2部分付款 3已支付',-->*/
            parsePayStatus(val){
                if (val === '0'){
                    return '无应付'
                }else if (val === '1') {
                    return '未付款'
                }else if (val === '2') {
                    return '部分付款'
                }else if (val === '3'){
                    return '已支付'
                }else {
                    return '无'
                }
            },
            parseRecStatus(val){
                if (val === '0'){
                    return '无应收'
                }else if (val === '1') {
                    return '未收款'
                }else if (val === '2') {
                    return '部分收款'
                }else if (val === '3'){
                    return '已支付'
                }else {
                    return '无'
                }
            },
            initMoreFuc() {
                this.getListCount();
            },
            getListCount() {
                postAction(this.url.listCount, this.queryForm)
                    .then(res => {
                        if (res.result) {
                            this.countList = res.result;
                        } else {
                            this.countList = {
                                total: 0,
                                totalCtn: 0,
                                totalUpdateFeeWeight: 0,
                                totalActualWeight: 0,
                                totalShipmentWeight: 0
                            }
                        }
                    })
            },
            handleExportXlsM() {
              this.queryForm.channelIds = this.selectChannelList;
              this.url.exportXlsUrl = '/file/kd1/dckdOrderEExcelVO2'
              this.handleExportXls('快递全部订单');
            },
            handleExportXlsStatus() {
                this.queryForm.channelIds = this.selectChannelList;
                this.url.exportXlsUrl = '/order/kdOrder/exportKdOrderStatusXls'
                this.handleExportXls('导出物流信息');
            },
            handleIsAutoFly() {
                 this.autoFlyList = this.selectionRows.map((item) => item.orderNo)
               this.autoFlyVis = true
              
            },
            handleSearchM() {
                this.queryForm.channelIds = this.selectChannelList;
                this.handleSearch();
            },
            handleSearch() {
                this.ipagination.currentPage = 1
                this.handleQuery()
            },
            initCusPage({cusId}) {
                if (cusId) {
                    // 对于路由不在左侧菜单中的选中菜单处理
                    setUpRouteUtil(this.$route.path, '/customer/Edit');
                    this.$router.push(`/customer/Edit?id=${cusId}`)
                }

            },
            // 批量快递应付导入
            uploadSectionFile(param) {
              this.btnLoading = true;
              //file就是当前添加的文件
              var fileObj = param.file;
              //FormData 对象
              var form = new FormData();
              //文件对象，key是后台接受的参数名称
              form.append("file", fileObj);
              //执行上传方法
              console.log("执行上传方法8888888888888");
              postAction(this.url.importHySettles, form)
                  .then((res) => {
                    //这里做上传之后的操作
                    if (res.success) {
                      this.$message.success(res.message);
                      this.initSettleList();
                      this.init();
                    }

                  }).finally(() => {
                this.btnLoading = false;

              })

            },
          // 批量快递应收导入
          uploadSectionFileYs(param) {
            this.btnLoadingYs = true;
            //file就是当前添加的文件
            var fileObj = param.file;
            //FormData 对象
            var form = new FormData();
            //文件对象，key是后台接受的参数名称
            form.append("file", fileObj);
            //执行上传方法
            console.log("执行上传方法8888888888888");
            postAction(this.url.importKdYsSettles, form)
                .then((res) => {
                  //这里做上传之后的操作
                  if (res.success) {
                    this.$message.success(res.message);
                    this.initSettleList();
                    this.init();
                  }

                }).finally(() => {
              this.btnLoadingYs = false;

            })

          },
            //获取所有的 快递供应商
            initAllSupplier() {
                this.allSupplierList = [];
                getAllSupplier("2").then(res => {
                    if (res.success) {
                        for (var i = 0; i < res.result.length; i++) {
                            var obj = {};
                            obj.value = res.result[i].id;   //供应商ID
                            obj.label = res.result[i].chinaseName;  //供应商中文名
                            this.allSupplierList.push(obj);
                            // console.log("allSupplierList", this.allSupplierList);
                        }
                    }
                }).catch(err => {
                    this.$message.error(err)
                })
            },
            //获取供应商的渠道
            getSupplierChannel(code) {
                this.supplierChannelList = [];
                let url = code ? this.url.queryBysupId + "?supId=" + code : this.url.queryBysupId + "?supId=";
                getAction(url)
                    .then((res) => {
                        if (res.success) {
                            for (var i = 0; i < res.result.length; i++) {
                                var obj = {};
                                obj.value = res.result[i].id;   //供应商ID
                                obj.label = res.result[i].channelName;  //供应商中文名
                                this.supplierChannelList.push(obj);
                            }
                        }
                    })

            },
            //进入编辑页面
            handleEdit(val) {
                // 对于路由不在左侧菜单中的选中菜单处理
                setUpRouteUtil(this.$route.path, '/kdorder/Edit');
                this.$router.push('/kdorder/Edit?orderNo=' + val);
            },
            handleReset() {
                this.queryForm = {};
                this.selectChannelList = [];
                this.ipagination.currentPage = 1;
                this.getSupplierChannel();
                this.handleQuery()
            },
            kdCount() {
                this.kdCountNum = [];
                postAction(this.url.kdSettleCount, this.queryForm)
                    .then(res => {
                        if (res.result) {
                            if (res.result['实收']) {
                                res.result['实收'].forEach((item, index) => {
                                    if (this.kdCountNum[index]) {
                                        this.kdCountNum[index].realPay = item || '';
                                    } else {
                                        this.kdCountNum.push({
                                            realPay: item || '',
                                        })
                                    }
                                })
                            }
                            if (res.result['应付']) {
                                res.result['应付'].forEach((item, index) => {
                                    if (this.kdCountNum[index]) {
                                        this.kdCountNum[index].payable = item || '';
                                    } else {
                                        this.kdCountNum.push({
                                            payable: item || '',
                                        })
                                    }
                                })
                            }
                            if (res.result['应收']) {
                                res.result['应收'].forEach((item, index) => {
                                    if (this.kdCountNum[index]) {
                                        this.kdCountNum[index].receivable = item || '';
                                    } else {
                                        this.kdCountNum.push({
                                            receivable: item || '',
                                        })
                                    }
                                })
                            }
                            this.dialogKdCountVisible = true;
                        }
                    })
            },
            syncDoc(){
                getAction(this.url.syncDoc).then(res => {
                        if (res.result) {
                            this.$message.success(res.message);
                        }
                    })

            },
            updateDoc(){
                this.form = {};
                this.form.orderNo = this.selectedRowOrderNos.join(',');
                this.form.id = this.selectedRowKeys.join(',');
                this.dialogUpdateDocVisible = true;
            },
            getDoc(val) {
                if(val){
                    this.$set(this.form,'doc',val);
                }
            },
            updateDocSure() {
                this.$refs.form.validate((valid) => {
                    if (valid) {
                        getAction(this.url.updateDoc, this.form).then(res => {
                            if (res.result) {
                                this.$message.success(res.message);
                                this.handleQuery();
                                this.dialogUpdateDocVisible = false;
                            }
                        })
                    }
                })
            },
            getTotalRec(){
                let rec = 0;
                let reced = 0;
                this.tableData.forEach(row => {
                    rec = Number(rec) + Number(row.shouldRecMoney)
                    reced = Number(reced) + Number(row.realRecMoney)
                })
                this.totalRecMoney = rec.toFixed(3)
                this.totalRecedMoney = reced.toFixed(3)

            },
            async handleQuery() {
                this.loading = true
                let form = {...this.queryForm, ...this.filterForm, ...this.initData}
                // for (let item in form) {
                //     if (typeof form[item] === 'string') {
                //         form[item] = form[item].trim()
                //         if (form[item] === '') {
                //             form[item] = null
                //         }
                //     }
                // }
                //清空字符串的前后空格
                this.trimParamsStr(form);
                const {prop, order} = this.isorter
                // 传入的参数
                const params = {
                    ...form,
                    column: prop,
                    order: order.indexOf('asc') !== -1 ? 'asc' : 'desc',
                }
                //判断 是否要查询startTime

                if (!params.column) {
                    if (this.queryForm.column === 'startTime') {
                        this.$set(params, 'column', 'startTime')
                    } else if (this.enabledCreatetime){
                        this.$set(params, 'column', 'createTime')
                    }
                    //提货中查询
                    if (this.queryForm.column === 'distributionTime') {
                        this.$set(params, 'column', 'distributionTime')
                    }
                    //排序
                    //提货中查询
                    if (this.queryForm.column === 'sort') {
                        this.$set(params, 'column', 'sort')
                    }
                }
                if (this.ipagination) {
                    params.pageNo = this.ipagination.currentPage
                    params.pageSize = this.ipagination.pageSize
                }
                // let method = "GET";
                // if (this.isUsePost) {
                //     method = "POST";
                // }
                if (!this.isUsePost) {
                    getAction(this.url.list, params).then((res) => {
                        console.log(res)
                        if (res.success) {
                            this.$refs.GTable && this.$refs.GTable.handleScrollTop && this.$refs.GTable.handleScrollTop();
                            if (res.result) {
                                const {records, total} = res.result
                                this.tableData = records || res.result;
                                this.ipagination && (this.ipagination.total = total || res.result.length)
                            } else {

                                this.tableData = [];

                                this.ipagination = {
                                    currentPage: 1,
                                    pageSize: 50,
                                    pageSizes: [50, 100, 200, 300, 400, 500],
                                    total: 0
                                };
                            }

                        }
                        this.loading = false;
                        this.initMoreFuc && this.ipagination.currentPage == 1 ? this.initMoreFuc() : ''; //用于初始的时候调用其他的方法
                    }).catch(err => {
                        this.loading = false;
                        // this.initMoreFuc && this.ipagination.currentPage == 1 ? this.initMoreFuc() : ''; //用于初始的时候调用其他的方法
                    })
                }else {
                    await postAction(this.url.list, params).then((res) => {
                        console.log(res)
                        if (res.success) {
                            this.$refs.GTable && this.$refs.GTable.handleScrollTop && this.$refs.GTable.handleScrollTop();
                            if (res.result) {
                                const {records, total} = res.result
                                this.tableData = records || res.result;

                                this.ipagination && (this.ipagination.total = total || res.result.length)
                            } else {

                                this.tableData = [];

                                this.ipagination = {
                                    currentPage: 1,
                                    pageSize: 50,
                                    pageSizes: [50, 100, 200, 300, 400, 500],
                                    total: 0
                                };
                            }

                        }
                        this.loading = false;
                        this.initMoreFuc && this.ipagination.currentPage == 1 ? this.initMoreFuc() : ''; //用于初始的时候调用其他的方法
                    }).catch(err => {
                        this.loading = false;
                        // this.initMoreFuc && this.ipagination.currentPage == 1 ? this.initMoreFuc() : ''; //用于初始的时候调用其他的方法
                    }).finally(()=>{
                        this.getTotalRec()
                    })
                }

            },
             tieReminder() {
      this.tieForm = {};
      this.tieForm.fileList = [];
      this.$set(this.tieForm,"haiyunOrKd",'1')
      this.dialogTieReminderVisible = true;
    },
     //发送特别提醒文件
    sendTieReminderEmail() {
      this.$refs.tieForm.validate((valid) => {
        if (valid) {
          if (
            this.tieForm.fileList &&
            this.tieForm.fileList.length &&
            !this.tieForm.fileType
          ) {
            this.$message.warning("有文件请选择文件类型！");
            return;
          }
          this.tieForm.orderNos = this.selectedRowOrderNos.join(",");
          postAction(this.url.tieReminder, this.tieForm).then((res) => {
            if (res.success) {
              this.$message.success(res.message);
              this.dialogTieReminderVisible = false;
            }
          });
        }
      });
    },
    batchUpdate() {
    postAction(this.url.updateBatchSalesmanComission, {
        orderNoList: this.selectionRows.map((item) => item.orderNo),
      }).then((res) => {
        if (res.success) {
          this.$message.success(res.message);
          this.handleQuery();
        }
      });
    },
        },
        created() {
            this.initAllSupplier(); //获取所有的快递供应商
            this.getSupplierChannel();

        },
      computed:{
        buttonShowList(){
          return {
            'dc':this.$btnIsShow('kdorder/AllKdOrder','0','导出'),
            'tj':this.$btnIsShow('kdorder/AllKdOrder','0','统计'),
            'xzmb':this.$btnIsShow('kdorder/AllKdOrder','0','快递应付批量导入模板下载'),
            'up':this.$btnIsShow('kdorder/AllKdOrder','0','上传账单文件'),
            'im':this.$btnIsShow('kdorder/AllKdOrder','0','批量快递应付导入'),
            'xzmb2':this.$btnIsShow('kdorder/AllKdOrder','0','快递应收批量导入模板下载'),
            'im2':this.$btnIsShow('kdorder/AllKdOrder','0','批量快递应收导入'),
            'tbkf':this.$btnIsShow('kdorder/AllKdOrder','0','同步客服'),
            'xgkf':this.$btnIsShow('kdorder/AllKdOrder','0','修改客服'),
            'dcwlxx':this.$btnIsShow('kdorder/AllKdOrder','0','导出物流信息'),
            'zzf':this.$btnIsShow('kdorder/AllKdOrder','0','批量修改自主飞'),
            'tbtx':this.$btnIsShow('kdorder/AllKdOrder', '0', '特别提醒'),
            'plgxtc':this.$btnIsShow('kdorder/AllKdOrder', '0', '批量更新业务员提成'),
          }
        }
      }

    }
</script>

<style lang="scss" scoped>

</style>
