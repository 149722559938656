<template>
  <div>

    <el-form class="multiline_select" ref="form" :model="queryForm" label-width="80px" size="medium">
      <el-card class="box-card">
        <el-row class="inputs_row">
          <el-col :xs="24" :sm="12" :md="12" :lg="8">
            <el-form-item label="订单号">
              <el-input v-model="queryForm.orderNo"  type="textarea"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="8">
            <el-form-item label="柜号">
              <el-input v-model="queryForm.containerNo"  type="textarea"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="8">
            <el-form-item label="开船日期" label-width="80px">
              <div class="f-flex">
                <el-date-picker
                    class="f-flex-1"
                    v-model="queryForm.loadTimeStart"
                    type="datetime"
                    size="small"
                    placeholder="开始时间"
                    default-time="00:00:00"
                    value-format="yyyy-MM-dd HH:mm:ss"
                >
                </el-date-picker>
                <span class="f-p-h-2">~</span>
                <el-date-picker
                    class="f-flex-1"
                    v-model="queryForm.loadTimeEnd"
                    type="datetime"
                    size="small"
                    placeholder="结束时间"
                    default-time="23:59:59"
                    value-format="yyyy-MM-dd HH:mm:ss"
                >
                </el-date-picker>
              </div>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="8">
            <el-form-item label="周期">
              <el-input v-model="queryForm.week" type="textarea"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="柜子状态">
              <f-select :dict="'circulation_state'"
                        v-model="queryForm.circulationState"
                        :isNeed="queryForm.circulationState"
              ></f-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="费用名">
              <f-select :dict="'bd_fee_type'"
                        v-model="queryForm.feeId"
                        :isNeed="queryForm.feeId"
              ></f-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="结算对象">
              <f-select :dict="'os_contact'"
                        v-model="queryForm.settlement"
                        :isNeed="queryForm.settlement"
              ></f-select>
            </el-form-item>
          </el-col>
          <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="3">
            <el-form-item label-width="30px" style="width: 500px">
              <!--                            <el-button-->
              <!--                                type="primary"-->
              <!--                                icon="el-icon-search"-->
              <!--                                @click="handleCheck"-->
              <!--                            >查询-->
              <!--                            </el-button-->
              <!--                            >-->
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button plain @click="handleReset">重置</el-button>
<!--              <el-button type="primary" size="medium" icon="el-icon-plus" @click="handleExportXls('柜子分摊记录')">导出</el-button>-->
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
    </el-form>

      <el-card class="box-card">
        <f-table
            v-loading="loading"
            ref="GTable"
            border
            size="medium"
            row-key="id"
            class="c-th-has-bgcolor"
            :columns="columns"
            :data="tableData"
            :cell-style="{ 'text-align': 'center' }"
            @selection-change="handleSelectionChange"
        >
          <template v-slot="scope">
            <template v-if="scope.column.property==='circulationState'">
              <span>{{parseDict(scope.row.circulationState,'circulation_state')}}</span>
            </template>
            <template v-else-if="scope.column.property==='week'">
              <span><a
                  style="color: #00a0e9"
                  @click.prevent="infomation(scope.row.containerId)"
              >{{ scope.row.week }}</a></span>
            </template>
            <template v-else-if="scope.column.property==='containerNo'">
              <span><a
                  style="color: #00a0e9"
                  @click.prevent="infomation(scope.row.containerId)"
              >{{ scope.row.containerNo }}</a></span>
            </template>
            <template v-else-if="scope.column.property==='orderNo'">
              <span><a
                  style="color: #00a0e9"
                  @click.prevent="intoDocpage(scope.row.orderNo)"
              >{{ scope.row.orderNo }}</a></span>
            </template>
          </template>
        </f-table>
        <el-row class="f-text-right f-p-v-8">
          <el-pagination
              v-bind="ipagination"
              layout="total, sizes, prev, pager, next, jumper"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
          >
          </el-pagination>
        </el-row>
      </el-card>

  </div>
</template>

<script>
import QueryMixins from "@/mixins/QueryMixins.js";
import { setUpRouteUtil } from '@/utils/util';


export default {
  name: "ShareRecord",
  mixins: [QueryMixins],
  components: {
  },

  data() {
    return {
      dictCodes:['circulation_state'],
      showLoadingSearch: false,
      //联系人类型0公司负责人 1报价对接人 2操作联系人 3财务联系人',
      btnDisable: false,
      form: {},
      dialogFormVisible: false,
      formLabelWidth: "120px",
      row: {},
      proVisible: false,
      url: {
        list: "/order/profit/pay/list",
        exportXlsUrl: '/order/profit/pay/export',

      },
      issalesman: "0",
      columns: [

        {
          type: "index",
          label:'序号',
          width: 33,
        },

        {
          prop: "containerNo",
          label: "柜号",
        },
        {
          prop: "week",
          label: "周数",
        },
        {
          prop: "circulationState",
          label: "柜子状态",
        },
        {
          prop: "orderNo",
          label: "订单号",

        },
        {
          prop: "feename",
          label: "费用名",

        },
        {
          prop: "settlement",
          label: "结算对象",

        },
        {
          prop: "shareMoneyType",
          label: "币种",

        },
        {
          prop: "shareMoney",
          label: "金额",
        },
        {
          prop: "rate",
          label: "转成cny汇率",
        },
        {
          prop: "realPay",
          label: "折合cny",
        },
      ],
      ss:'',
      totalcny : 0,
      totalusd : 0,
      totaleur : 0,
      totalhkd : 0,
      totalgbp : 0,
      totaljpy : 0,
      totalaud : 0,
      totalkrw : 0,
      totalcad : 0,
      totalinr : 0,
      totalnzd : 0,
      totalnok : 0,
      totaltwd : 0,
      totalsgd : 0,
      totalvnd : 0,
      totalcnyTotal: 0,
    };
  },

  created() {


  },
  methods: {

    infomation(id) {
      // 对于路由不在左侧菜单中的选中菜单处理
        if(id) {
          setUpRouteUtil(this.$route.path, '/operation/cabinet/Edit');
          this.$router.push("/operation/cabinet/Edit?id=" + id);
        }

    },

    //进入客服页
    intoDocpage(orderNo) {
      // if (this.issalesman == "1") {
      //   this.$router.push("/docpage/EditSale?orderNo=" + orderNo);
      // } else {
        // 对于路由不在左侧菜单中的选中菜单处理
        setUpRouteUtil(this.$route.path, '/docpage/Edit');
        this.$router.push("/docpage/Edit?orderNo=" + orderNo);
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
