<template>
  <div>
    <el-dialog
      title="二维码"
      :width="isPhone ? '100%' : '30%'"
      class="code_dialog"
      :visible="visible"
      :before-close="handleClose"
    >
      <div class="flex_center">
        <!-- 二维码容器 -->
        <div ref="qrCodeContainer" class="qrCodeContainer">
          <vue-qr
            :text="qrDownloadUrl"
            :callback="qrCallBack"
            :size="300"
            :margin="10"
            colorDark="black"
            colorLight="white"
            :logoSrc="imgSrc"
            :logoScale="0.2"
            :dotScale="0.7"
          >
          </vue-qr>
        </div>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button type="text" @click="downloadQrcode">下载图片</el-button>
        <el-button @click="handleClose">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// vue2
import vueQr from "vue-qr";
import imgSrc from "@/assets/codeLogo.jpg";

export default {
  props: {
    visible: {
      type: Boolean,
    },
    codeInfo: {
      type: Object,
      default: {},
    },
  },
  components: {
    vueQr,
  },
  data() {
    return {
      qrDownloadUrl: "",
      imgSrc: imgSrc,
      downUrl: "",
      isPhone: false,
    };
  },
  watch: {
    visible(val) {
      if (val) {
        let api = "http://120.25.172.133:9800"; //测试环境
        if (process.env.VUE_APP_API_URL == "http://8.135.121.105:9000") {
          // 线上环境
          api = "https://do.do2do.com";
        }
        let miniUrl = `${api}/login/registerPhone?realname=${encodeURI(
          this.codeInfo.realname
        )}&workNo=${this.codeInfo.workNo}`;
        this.qrDownloadUrl = miniUrl;

        // 手机端样式适配
        this.isPhone = this.isMobileDevice();
      }
    },
  },
  methods: {
    downloadQrcode() {
      let a = document.createElement("a");
      a.download = `${this.codeInfo.realname}-专属二维码`;
      a.href = this.downUrl;
      a.click();
    },
    qrCallBack(data) {
      this.downUrl = data;
    },
    handleClose() {
      this.$emit("update:visible", false);
    },
    isMobileDevice() {
      //判断当前设备是否为移动端
      const ua = navigator.userAgent.toLowerCase();
      const t1 =
        /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
          ua
        );
      return t1;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .code_dialog {
  .el-dialog__body {
    height: 400px;
  }
}
</style>