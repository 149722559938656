<template>
  <el-dialog
      title="提货加货"
      :visible="visible"
      width="50%"
      custom-class="c-custom-dialog"
      append-to-body
      :before-close="handleClose"
      :close-on-click-modal="false">
    <!--:disabled="selectedRowKeys.length === 0"-->
    <el-form class="multiline_select" ref="form" :model="queryForm" label-width="80px" size="medium">
      <el-row :gutter="20" class="flex_center">
        <el-col :span="10">
          <el-form-item label="订单号">
            <el-input v-model="queryForm.orderNo" placeholder="多订单号" type="textarea"
                      @keyup.enter.native="handleQuery"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item label-width="30px">
            <el-button type="primary" icon="el-icon-search" @click="handleQuery">查询</el-button>
          </el-form-item>
        </el-col>

      </el-row>
    </el-form>

    <f-table
        v-loading="loading"
        ref="GTable"
        border
        size="medium"
        row-key="id"
        class="c-th-has-bgcolor"
        :columns="columns"
        :data="tableData"
        @selection-change="handleSelectionChange"
        @sort-change="handleTableChange"
        :cell-style="{ 'text-align': 'center' }"
        tableHeight="50vh"
    >
      <template v-slot="scope">
        <template v-if="scope.column.property === 'orderNo'">
          <a style="color: #00a0e9" @click.prevent="$intoDocPage(scope.row.orderNo)">{{ scope.row.orderNo }}</a>
        </template>
        <template v-else-if="scope.column.property === 'isWeiban'">
          <span v-if="scope.row.isWeiban === '1'">是</span>
          <span v-else-if="scope.row.isWeiban === '0'">否</span>
        </template>
        <template v-else-if="scope.column.property === 'isBanyun'">
          <span v-if="scope.row.isBanyun === '1'">是</span>
          <span v-else-if="scope.row.isBanyun === '0'">否</span>
        </template>
      </template>
    </f-table>
    <el-row class="f-text-right f-p-v-8">
      <el-pagination
          v-bind="ipagination"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange">
      </el-pagination>
    </el-row>
    <span slot="footer">
            <el-button size="medium" @click="handleClose">取消</el-button>
            <el-button type="primary" size="medium" @click="handleAddOrder">确认加货</el-button>
        </span>
  </el-dialog>
</template>

<script>
import {putAction} from '@/api/manage'
import QueryMixins from "@/mixins/QueryMixins";

export default {
  name: 'AddTakeOrderDetailListModule',
  mixins: [QueryMixins],
  props: {
    visible: {
      type: Boolean
    },
    takeId: {
      type: String,
      default: ''
    },
 

  },
  data() {
    return {
      isUsePost: true,
      disableMixinMounted:true,
      columns: [
        {
          type: "selection",
        },

        {
          prop: "orderNo",
          label: "订单号",
        },
        {
          prop: "ctn",
          label: "箱数",
        },
        {
          prop: "kg",
          label: "重量",
        },
        {
          prop: "cmb",
          label: "体积",
        },
        {
          prop: "address",
          label: "提货地址信息",
        },
        {
          prop: "isBanyun",
          label: "是否需要搬运",
        },
        {
          prop: "isWeiban",
          label: "是否需要尾板车",
        },
        {
          prop: "remark",
          label: "备注",
        },

      ],
      form: {},
      formLabelWidth: '100px',

      url: {
        addTakeOrders: '/warehouse/takeDelivery/addGoods',

        //查询
        list: '/warehouse/takeDelivery/getAddTakeGoodsIPage',
      },

    }
  },
  watch: {
    visible(val) {
      if (val && this.takeId) {
        //勾选的订单号进行赋值
        this.queryForm.takeId = this.takeId;
        this.handleQuery();
      }
    },
  },
  methods: {

    handleAddOrder() {
      //加货
      putAction(this.url.addTakeOrders, this.selectionRows).then((res) => {
        this.$message({
          type: 'success',
          message: res.message
        })
        this.handleClose();
        this.$emit('ok');
      })

    },


    handleClose() {
      this.$emit('ok');
    }
  },
  created() {
  }
}
</script>

<style lang="scss" scoped>
::v-deep
.el-dialog__body {
  padding: 20px 40px 20px 6px;
}

.addEditBox {
  max-height: 615px;
  overflow: scroll;
}
</style>
