<template>
  <!-- 打单弹框 -->
  <el-dialog
    title="选择渠道"
    width="1000"
    :visible="visible"
    @cancel="handleCancel"
    :before-close="handleCancel"
    :close-on-click-modal="false"
  >
    <el-form :form="form" ref="form">
      <el-card :bordered="false">
        <!-- 查价成功 -->
        <div class="title">查价成功</div>
        <f-table
          ref="table"
          v-loading="loading"
          :columns="columns"
          :data="tableData"
          @selection-change="handleSelectionChangeOwn"
          @sort-change="handleTableChange"
          tableHeight="40vh"
          :cell-style="{ 'text-align': 'center' }"
          class="c-th-has-bgcolor"
        >
          <template #default="scope">
            <template v-if="scope.column.property === 'finalPrice'">
              <span :class="scope.row.isShowLabel ? 'green' : ''">{{
                scope.row.finalPrice
              }}</span>
            </template>
          </template>
        </f-table>

        <!-- 查价失败 -->
        <div class="title" style="margin-top: 9.796px">查价失败</div>
        <f-table
          v-loading="loading"
          :columns="columnsFailing"
          :data="tableDataFailing"
          tableHeight="40vh"
          :cell-style="{ 'text-align': 'center' }"
          class="c-th-has-bgcolor"
        >
          <template #default="scope">
            <template v-if="scope.column.property === 'message'">
              <span class="red">{{ scope.row.message }}</span>
            </template>
          </template>
        </f-table>
      </el-card>
    </el-form>
    <template #footer>
      <el-button
        type="primary"
        size="small"
        @click="handleSubmit"
        :loading="btnLoading"
      >
        提交订单
      </el-button>
    </template>
  </el-dialog>
</template>
<script>
import { postAction } from "@/api/manage";
import QueryMixins from "@/mixins/QueryMixins";

export default {
  name: "CheckAndPlace",
  mixins: [QueryMixins],
  props: {
    visible: {
      type: Boolean,
    },
    row: {
      type: Object,
      default: () => {},
    },
  },
  components: {},
  data() {
    return {
      confirmLoading: false,
      form: {},
      tableData: [],
      // 查价失败
      tableDataFailing: [],
      loading: false,
      btnLoading: true,
      columns: [
        {
          type: "selection",
          width: 50,
        },
        //操作
        // {
        //   prop: "action",
        //   label: this.$t("system.operation"),
        // },
        {
          prop: "address",
          label: "发货地址",
        },
        {
          prop: "channelCode",
          label: "渠道",
        },
        {
          prop: "finalPrice",
          label: "价格",
        },
        {
          prop: "cur",
          label: "币种",
        },
      ],
      columnsFailing: [
        {
          prop: "address",
          label: "发货地址",
        },
        {
          prop: "channelCode",
          label: "渠道",
        },
        {
          prop: "cur",
          label: "币种",
        },
        {
          prop: "message",
          label: "失败原因",
        },
      ],

      orderNo: String,
      numMin: {},
      //所有渠道
      supplierList: [],
      isSingleChoice: true,
      allList: [],
      //   价格
      url: {
        // 查询打单中心所有渠道
        getLabelCenterChannel: "/interface/labelCenter/getLabelCenterChannel",
        // 查价
        priceOrder: "/interface/labelCenter/priceOrder",
        // 下单
        placeOrder: "/interface/labelCenter/placeOrder",
      },
    };
  },
  watch: {
    visible(val) {
      if (val) {
        this.edit();
      }
    },
    allList: {
      deep: true,
      handler() {
        if (this.supplierList.length == this.allList.length) {
          this.btnLoading = false;
        } else {
          this.btnLoading = true;
        }
      },
      immediate: true,
    },
    tableData: {
      deep: true,
      handler() {
        if (this.tableData.length >= 1) {
          if (this.tableData.length == 1) {
            this.numMin = this.tableData[0];
          }
          for (let i = 0; i < this.tableData.length; i++) {
            if (
              Number(this.tableData[i].finalPrice) <
              Number(this.numMin.finalPrice)
            ) {
              this.numMin = this.tableData[i];
            }
            if (i == this.tableData.length - 1) {
              for (let j = 0; j < this.tableData.length; j++) {
                if (this.tableData[j].finalPrice == this.numMin.finalPrice) {
                  this.tableData[j].isShowLabel = true;
                } else {
                  this.tableData[j].isShowLabel = false;
                }
              }
            }
          }
        }
      },
      immediate: true,
    },
  },
  mounted() {},
  created() {},
  methods: {
    edit() {
      const record = this.row;
      this.orderNo = record.orderNo;
      this.orderMainModel = Object.assign({}, record);
      this.$nextTick(() => {
        let url = this.url.getLabelCenterChannel;
        this.confirmLoading = true;
        // 获取所有渠道
        postAction(url, { orderNo: this.row.orderNo })
          .then((res) => {
            if (res.success) {
              this.supplierList = res.result.map((item) => item.channelCode);
              this.allList = [];
              if (this.supplierList.length) {
                this.getList();
              }
            } else {
              this.$message.warning(res.message);
            }
          })
          .finally(() => {
            this.confirmLoading = false;
          });
      });
    },
    // 查价
    getList() {
      this.loading = true;
      for (let i = 0; i < this.supplierList.length; i++) {
        let params = {
          orderNo: this.orderNo,
          channelCode: this.supplierList[i],
        };
        if (i < this.supplierList.length - 1) {
          postAction(this.url.priceOrder, params, { ClosePrompt: true })
            .then((res) => {
              if (res.success) {
                let flag = res.result.some((item) => item.success == false);
                if (!flag) {
                  let obj;
                  res.result.forEach((item) => {
                    if (obj && obj.finalPrice) {
                      // 价格相加
                      obj.finalPrice += item.finalPrice;
                      // 拓展字段
                      if (obj.extendedField && item.extendedField) {
                        obj.extendedField = `${obj.extendedField},${item.extendedField}`;
                      } else {
                        obj.extendedField = item.extendedField;
                      }
                    } else {
                      obj = item;
                    }
                  });
                  obj.finalPrice = obj.finalPrice.toFixed(2);
                  let repetitiveFlag = this.tableData.some(
                    (tabItem) => tabItem.channelCode == obj.channelCode
                  );
                  if (!repetitiveFlag) {
                    this.tableData.push(obj);
                  }
                } else {
                  res.result.forEach((item) => {
                    if (!item.success) {
                      this.tableDataFailing.push(item);
                    }
                  });
                }
                this.allList.push("success");
              }
            })
            .catch((ree) => {
              // this.loading = false;
              this.allList.push(["error"]);
            });
        } else if (i == this.supplierList.length - 1) {
          postAction(this.url.priceOrder, params)
            .then((res) => {
              if (res.success) {
                let flag = res.result.some((item) => item.success == false);
                if (!flag) {
                  let obj;
                  res.result.forEach((item) => {
                    if (obj && obj.finalPrice) {
                      obj.finalPrice += item.finalPrice;
                      if (obj.extendedField && item.extendedField) {
                        obj.extendedField = `${obj.extendedField},${item.extendedField}`;
                      } else {
                        obj.extendedField = item.extendedField;
                      }
                    } else {
                      obj = item;
                    }
                  });
                  obj.finalPrice = obj.finalPrice.toFixed(2);
                  let repetitiveFlag = this.tableData.some(
                    (tabItem) => tabItem.channelCode == obj.channelCode
                  );
                  if (!repetitiveFlag) {
                    this.tableData.push(obj);
                  }
                } else {
                  res.result.forEach((item) => {
                    if (!item.success) {
                      this.tableDataFailing.push(item);
                    }
                  });
                }
                this.allList.push("success");
                this.loading = false;
              } else {
                this.allList.push("error");
                this.loading = false;
              }
              this.$message.success("查价完成");
            })
            .catch((ree) => {
              this.loading = false;
              this.allList.push(["error"]);
            });
        }
      }
    },
    close() {
      // this.$emit("close");
      // this.visible = false;
    },
    handleCancel() {
      this.tableData = [];
      this.tableDataFailing = [];
      this.orderMainModel = {};
      this.$emit("update:visible", false);
    },
    // 下单
    handleSubmit() {
      if (this.selectionRows.length == 0) {
        this.$message.warning("请至少勾选一条数据");
        return;
      }
      const params = { ...this.orderMainModel };
      params.extendedField = this.selectionRows[0].extendedField || "";
      params.channelCode = this.selectionRows[0].channelCode;
      params.hasQianzhi = this.row.hasQianzhi
      this.loading = true;
      postAction(this.url.placeOrder, params)
        .then((res) => {
          if (res.success) {
            this.$message.success(res.message);
            this.handleCancel();
          } else {
            this.$message.error(res.message);
          }
        })
        .finally((err) => {
          this.loading = false;
        });
    },
    handleSelectionChangeOwn(e) {
      this.selectionRows = [];
      if (this.isSingleChoice) {
        this.selectionRows = e;
        if (e && e.length > 1) {
          this.$refs.table.$refs.table.clearSelection();
          this.$refs.table.$refs.table.toggleRowSelection(e.pop());
        }
      }
    },
    handleClick() {},
  },
};
</script>

<style scoped lang="scss">
::v-deep {
  .el-tabs--top > .is-top {
    position: relative !important;
    top: 0 !important;
    left: 0 !important;
    width: 100%;
  }
  .el-tabs--top > .el-tabs__content {
    top: 0;
  }
}

.green {
  color: #2d8cf0;
}
.red {
  color: red;
}
</style>
