<template>
    <div style="font-size: 16px;">
        <div ref="fixTop">
            <el-card class="box-card">
                <div style="display: flex;flex-wrap:wrap;color: #666666;">
                    <div class="grid-content bg-purple margin_15">周数 {{infomation.week }}</div>
                    <div class="grid-content bg-purple margin_15">预计件数 {{infomation.ctn == null ?0:infomation.ctn}}</div>
                    <div class="grid-content bg-purple margin_15">总件数 {{infomation.ctn == null ?0:infomation.realCtn}}</div>
                    <div class="grid-content bg-purple margin_15">预计重量 {{infomation.kg == null ?0:infomation.kg}}</div>
                    <div class="grid-content bg-purple margin_15">总重量 {{infomation.realKg == null ?0:infomation.realKg}}</div>
                    <div class="grid-content bg-purple margin_15">预计体积 {{infomation.cmb == null ?0:infomation.cmb}}</div>
                    <div class="grid-content bg-purple margin_15">总体积 {{infomation.realCmb == null ?0:infomation.realCmb}}</div>
                  <div class="grid-content bg-purple margin_15">
                    柜子类型：
                    <span  v-if="addCabinetForm && addCabinetForm.weekStatus==='0'">自拼</span>
                    <span  v-if="addCabinetForm && addCabinetForm.weekStatus==='1'">非拼/整柜</span>
                    <span  v-if="addCabinetForm && addCabinetForm.weekStatus==='2'">传统物流</span>
                  </div>
                  <div class="grid-content bg-purple margin_15">
                    是否线上仓位：
                    <span>{{addCabinetForm.isOnlineWarehouse === '1' ? '是' : '否'}}</span>
                  </div>
                </div>
                <div style="display: flex;flex-wrap:wrap;">
                    <div style="display: flex;color: #666666;" v-for="(item, i) in infomationList" :key="i">
                        <div class="grid-content bg-purple margin_2">{{item.content}}</div>
                        <div v-if="i!==infomationList.length-1" class="grid-content bg-purple margin_2">-------></div>
                    </div>
                </div>
            </el-card>
        </div>

        <div ref="fixMain" :style="{overflow: 'auto', height: divHeight}">
            <el-row style="">
                <el-card class="box-card">
                    <div slot="header" class="clearfix">
                        <span>应付账单</span>
                        <!--                    <el-button style="float: right; padding: 8px 10px" type="primary" >保存-->
                        <!--                    </el-button>-->
                    </div>
                    <div class="text item">
                        <div slot="header" class="clearfix botton_height" style="display: flex">
                            <el-button type="primary" @click="dialogFormAddSettleVisible=true">
                                新增应付
                            </el-button>
                            <el-button type="primary" @click="copy">复制柜子费用</el-button>
                            <el-button type="primary" @click="BatchLock('1')">批量锁定</el-button>
                            <el-button type="primary" @click="BatchLock('0')" v-if="buttonShowList.pljs">批量解锁</el-button>
                            <el-button type="primary" @click="delDuo" v-if="buttonShowList.scfy" :disabled="selectedRowKeys.length === 0">批量删除</el-button>
                            <el-button type="primary" @click="addAttach">添加附属费用</el-button>
                            <el-button type="primary" @click="applyPay">申请付款</el-button>
                            <el-upload
                                    :action="'/operation/container/taxUpload'"
                                    :file-list="fileList"
                                    :data="infomation"
                                    :http-request="handleUploadPay"
                                    :on-preview="handlePreview"
                                    :on-remove="handleRemove"
                                    :before-upload="beforeUpload"
                                    :on-exceed="onexceed"
                                    :limit="1"
                                    class="inline_left"
                            >
                                <template slot="trigger">
                                    <template>
                                        <el-button type="primary">导入应付分税</el-button>
                                    </template>
                                </template>
                            </el-upload>
                          <el-button
                              type="primary"
                              size="medium"
                              class="inline_left"
                              @click="downloadModel(url.downloadSettleModel, '柜子应付导入费用模板下载')"
                              v-if="buttonShowList.xzmb"
                          >柜子应付导入费用模板下载
                          </el-button>
                          <el-upload
                              action="/"
                              :show-file-list="false"
                              :http-request="uploadSectionFileYs"
                              accept=".xls,.xlsx"
                              class="inline_left"
                          >
                            <el-button size="medium" type="primary"
                                       :loading="btnLoadingYs"
                                       v-if="buttonShowList.dr"
                            >导入柜子应付费用
                            </el-button>
                          </el-upload>
                          <el-upload
                              v-if="buttonShowList.tb"
                              :action="'/express/test/syncInvoiceNo'"
                              :file-list="fileList"
                              :data="infomation"
                              :http-request="handleImportInvoiceCode"
                              :before-upload="beforeUpload"
                              :show-file-list="false"
                              class="inline_left"
                          >
                            <template slot="trigger">
                              <template>
                                <el-button type="primary">同步发票编号</el-button>
                              </template>
                            </template>
                          </el-upload>
                        </div>
                        <f-table
                                v-loading="loading"
                                ref="GTable"
                                border
                                size="medium"
                                row-key="id"
                                class="c-th-has-bgcolor"
                                :columns="columns"
                                :data="tableData"
                                :cell-style="{ 'text-align': 'center' }"
                                isTableBgType="dealMoudule"
                                :selectedRowKeys="selectedRowKeys"
                                @selection-change="handleSelectionChange"
                                @sort-change="handleTableChangePay">
                            <template v-slot="scope">
                                <template v-if="scope.column.property === 'action'">
                                    <el-button type="text" v-if="scope.row.locker == '1' && buttonShowList.js"
                                        :disabled="scope.row.isCloseAccount == '1'"
                                               @click="handleLock(scope.row,'0')">解锁
                                    </el-button>
                                    <el-button type="text" v-if="scope.row.locker != '1'"
                                        :disabled="scope.row.isCloseAccount == '1'"
                                               @click="handleLock(scope.row,'1')">锁定
                                    </el-button>
                                    <el-button type="text" v-if="scope.row.locker != '1' && scope.row.isShare=='0'"
                                               @click="handleEdit(scope.row)" :disabled="scope.row.payStatus == '0' ? false : true">编辑
                                    </el-button>
                                     <el-button type="text" @click="handledel(scope.row)" :disabled="scope.row.locker == '1' || scope.row.payStatus !== '0'">删除</el-button>


                                </template>
                                <template v-else-if="scope.column.property === 'settlement'">
                                    <span>{{parseDict(scope.row.settlement,'os_contact')}}</span>
                                </template>
                                <template v-else-if="scope.column.property === 'locker'">
                                    <span v-if="scope.row.locker == '1'">已锁定</span>
                                    <span v-if="scope.row.locker != '1'">未锁定</span>
                                </template>
                              <template v-else-if="scope.column.property === 'payStatus'">
                                <span v-if="scope.row.payStatus === '1'" style="color: red">已申请待销账</span>
                                <span v-else-if="scope.row.payStatus === '2'" style="color: red">已销账</span>
                                <span v-else style="color: green">未付</span>
                              </template>
                                <template v-else-if="scope.column.property === 'isShare'">
                                    <span v-if="scope.row.isShare == '1'">已分摊</span>
                                    <span v-if="scope.row.isShare != '1'">未分摊</span>
                                </template>
                                <template v-else-if="scope.column.property === 'invoiceCheck'">
                                    <span v-if="scope.row.invoiceCheck == '1'">已分摊</span>
                                    <span v-if="scope.row.invoiceCheck != '1'">未分摊</span>
                                </template>
                            </template>
                        </f-table>
                        <el-row class="f-text-right f-p-v-8">
                            <el-pagination
                                    v-bind="ipagination"
                                    layout="total, sizes, prev, pager, next, jumper"
                                    @size-change="handleSizeChange"
                                    @current-change="handleCurrentChange">
                            </el-pagination>
                        </el-row>
                    </div>
                </el-card>
            </el-row>
            <el-row style="" :gutter="0">
                <el-col :span="12">
                    <div class="grid-content bg-purple">
                        <el-card class="box-card">
                            <div slot="header" class="clearfix">
                                <span>代理应付统计</span>
                            </div>
                            <div class="text item">
                                <f-table
                                        v-loading="loading"
                                        ref="GTable"
                                        border
                                        height="450px"
                                        size="medium"
                                        row-key="id"
                                        class="c-th-has-bgcolor"
                                        :columns="payColumns"
                                        :data="payTableData"
                                        :cell-style="{ 'text-align': 'center' }"
                                        @selection-change="handleSelectionChange"
                                        @sort-change="handleTableChange">
                                    <template v-slot="scope">
                                        <template v-if="scope.column.property === 'settlement'">
                                            <span>{{parseDict(scope.row.settlement,'os_contact')}}</span>
                                        </template>
                                    </template>
                                </f-table>

                            </div>
                        </el-card>
                    </div>
                </el-col>
                <el-col :span="12">
                    <div class="grid-content bg-purple">
                        <el-card class="box-card last_card">
                            <div slot="header" class="clearfix">
                                <span>费用修改记录</span>
                            </div>
                            <div class="text item">
                                <f-table
                                        v-loading="loading"
                                        ref="logTableData"
                                        border
                                        height="400px"
                                        size="medium"
                                        row-key="id"
                                        class="c-th-has-bgcolor"
                                        :columns="logColumns"
                                        :data="logTableData"
                                        :cell-style="{ 'text-align': 'center' }">
                                    <template v-slot="scope">
                                        <template v-if="scope.column.property === 'feeId'">
                                            <span>{{parseDict(scope.row.feeId,'bd_fee_type')}}</span>
                                        </template>
                                    </template>
                                </f-table>
                                <el-row class="f-text-right f-p-v-8">
                                    <el-pagination
                                            v-bind="logIpagination"
                                            layout="total, sizes, prev, pager, next, jumper"
                                            @size-change="handleLogSizeChange"
                                            @current-change="handleLogCurrentChange">
                                    </el-pagination>
                                </el-row>
                            </div>
                        </el-card>
                    </div>
                </el-col>
            </el-row>
            <el-row style="height: 300px;padding-top: 20px;"
                    >
                <el-row>
                    <el-col :span="4">
                        <div v-if="receivableList!=null" class="grid-content bg-purple" style="font-size: 17px;">应收费用：
                            <a v-for="(item, i) in receivableList" :key="i"
                               style="margin-left: 20px;">{{item.moneyType}}:{{item.total}}</a>
                        </div>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="4">
                        <div v-if="payList!=null" class="grid-content bg-purple" style="font-size: 17px;padding-top: 5px;">应付费用：
                            <a v-for="(item, i) in payList" :key="i"
                               style="margin-left: 20px;">{{item.moneyType}}:{{item.total}}</a>
                        </div>
                    </el-col>
                </el-row>
                <el-row v-if="buttonShowList.klr">
                    <el-col :span="4">
                        <div class="grid-content bg-purple" style="font-size: 17px;padding-top: 5px;"
                             v-if="osContainerSettle1 !=null">
                            利润：{{osContainerSettle1.profit}} RMB
                        </div>
                    </el-col>
                </el-row>
            </el-row>
        </div>
        <el-dialog
                :title="settleForm.id?'编辑应付':'新增应付'"
                :visible.sync="dialogFormAddSettleVisible"
                :close-on-click-modal="false"
                :before-close="handleClose"
        >
            <el-form :model="settleForm" ref="settleForm" :rules="rules">
                <el-form-item label="结算对象" :label-width="formLabelWidth" prop="settlement">
                    <f-select
                            v-model="settleForm.settlement"
                            :is-need="settleForm.settlement"
                            :data-list="settlementSelectedList"
                            @click.native="initSettlementSelectedList"
                            ></f-select>
                </el-form-item>
                <el-form-item label="费用中文" :label-width="formLabelWidth" prop="feeId">
                    <el-select v-model="settleForm.feeId" filterable placeholder="请选择">
                        <el-option
                                @click.native="getfeenameOptions(item)"
                                v-for="(item,index) in fressList"
                                :key="index"
                                :label="item.chineseName"
                                :value="item.id"/>
                    </el-select>
                </el-form-item>
                <el-form-item label="费用英文" :label-width="formLabelWidth" prop="feenameUs">
                    <el-input v-model="settleForm.feenameUs"></el-input>
                </el-form-item>
                <el-form-item label="价格" :label-width="formLabelWidth" prop="total">
                    <el-input v-model="settleForm.total" oninput="value=value.match(/[-]?\d*\.?\d{0,2}/)"></el-input>
                </el-form-item>
                <el-form-item label="交易币种" :label-width="formLabelWidth" prop="moneyType">
                    <f-select
                            v-model="settleForm.moneyType"
                            :is-need="settleForm.moneyType"
                            :dict="'bd_cur'"></f-select>
                </el-form-item>

                <el-form-item label="发票号" :label-width="formLabelWidth" prop="invoiceNoThird">
                    <el-input v-model="settleForm.invoiceNoThird"></el-input>
                </el-form-item>

              <el-form-item label="备注" :label-width="formLabelWidth" prop="remark">
                <el-input v-model="settleForm.remark" type="textarea" maxlength="500" show-word-limit></el-input>
              </el-form-item>
<!--              柜子费用记录-->
<!--              <f-table-->
<!--                  v-loading="loading"-->
<!--                  ref="containerSettleInvoceTable"-->
<!--                  border-->
<!--                  size="medium"-->
<!--                  row-key="id"-->
<!--                  class="c-th-has-bgcolor"-->
<!--                  :columns="containerSettleInvoceColumns"-->
<!--                  :data="baseContainerSettleInvoiceList"-->
<!--                  :cell-style="{ 'text-align': 'center' }"-->
<!--                  @selection-change="handleSelectionChangeContainerSettleInvoce"-->
<!--              >-->
<!--                <template slot-scope="scope">-->
<!--                  <template v-if="scope.column.property==='feeMoney'">-->
<!--                    <el-form-item>-->
<!--                      <el-input v-model="scope.row.feeMoney" type="number"></el-input>-->
<!--                    </el-form-item>-->
<!--                  </template>-->
<!--                </template>-->
<!--              </f-table>-->
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="medium" @click="dialogFormAddSettleVisible = false ; settleForm={}">取 消</el-button>
                <el-button type="primary" size="medium" @click="save">确 定</el-button>
            </div>
        </el-dialog>

        <el-dialog
                title="复制柜子费用"
                :visible.sync="dialogFormCopyVisible"
                :close-on-click-modal="false"
                :before-close="handleClose"
        >
            <el-form :model="settleCopyForm" ref="settleCopyForm" :rules="settleCopyFormRules">
                <el-form-item label="柜子周期数" :label-width="formLabelWidth" prop="containerId">
                    <f-select v-model="settleCopyForm.containerId" :dict="'os_container'"
                              @change="initSettlementList"></f-select>
<!--                  <el-input v-model="settleCopyForm.containerId" ></el-input>-->
                </el-form-item>
                <el-form-item label="结算对象" :label-width="formLabelWidth" prop="settleIds">
                    <el-select v-model="settleCopyForm.settleIds" multiple filterable clearable style="width:100%"
                               placeholder="请选择" @click.native="initSettlementList">
                        <el-option
                                v-for="(item,index) in settlementList"
                                :key="index"
                                :label="item.chineseName"
                                :value="item.settlement"/>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="medium" @click="dialogFormCopyVisible = false ; settleCopyForm={}">取 消</el-button>
                <el-button type="primary" size="medium" @click="saveSettleCopyForm">确 定</el-button>
            </div>
        </el-dialog>
<!--        <el-dialog-->
<!--                title="费用分摊"-->
<!--                :visible.sync="apportionVisible"-->
<!--                :close-on-click-modal="false"-->
<!--                :before-close="handleClose"-->
<!--        >-->
<!--            <el-form :model="apportionForm" ref="apportionForm" :rules="apportionFormRuler">-->
<!--                <el-form-item label="分摊方式" :label-width="formLabelWidth">-->
<!--                    <el-radio-group v-model="apportionForm.apportionType" @change="updateApportionType">-->
<!--                        <el-radio label="1">当前柜子分摊</el-radio>-->
<!--                        <el-radio label="0">多订单号分摊</el-radio>-->
<!--                    </el-radio-group>-->
<!--                </el-form-item>-->
<!--                <el-form-item label="结算对象" :label-width="formLabelWidth" prop="settlement"-->
<!--                              v-if="apportionForm.apportionType == 0">-->
<!--                    <f-select v-model="apportionForm.settlement" :data-list="settlementSelectedList" @click.native="initSettlementSelectedList" :is-need="apportionForm.settlement"></f-select>-->
<!--                </el-form-item>-->
<!--                <el-form-item label="费用中文" :label-width="formLabelWidth" prop="feeId"-->
<!--                              v-if="apportionForm.apportionType == 0">-->
<!--&lt;!&ndash;                    <el-select v-model="apportionForm.feeId" filterable placeholder="请选择">&ndash;&gt;-->
<!--&lt;!&ndash;                        <el-option&ndash;&gt;-->
<!--&lt;!&ndash;                                @click.native="getfeenameOptions(item)"&ndash;&gt;-->
<!--&lt;!&ndash;                                v-for="(item,index) in fressList"&ndash;&gt;-->
<!--&lt;!&ndash;                                :key="index"&ndash;&gt;-->
<!--&lt;!&ndash;                                :label="item.chineseName"&ndash;&gt;-->
<!--&lt;!&ndash;                                :value="item.id"/>&ndash;&gt;-->
<!--&lt;!&ndash;                    </el-select>&ndash;&gt;-->
<!--                  <f-select v-model="apportionForm.feeId" :is-need="apportionForm.feeId" :dict="'bd_fee_type'"></f-select>-->
<!--                </el-form-item>-->
<!--                <el-form-item label="费用英文" :label-width="formLabelWidth" prop="englishName"-->
<!--                              v-if="apportionForm.apportionType == 0">-->
<!--                    <el-input v-model="apportionForm.englishName"></el-input>-->
<!--                </el-form-item>-->
<!--                <el-form-item label="交易币种" :label-width="formLabelWidth" prop="moneyType"-->
<!--                              v-if="apportionForm.apportionType == 0">-->
<!--                    <f-select v-model="apportionForm.moneyType" :is-need="apportionForm.moneyType" :dict="'bd_cur'"></f-select>-->
<!--                </el-form-item>-->
<!--                <el-form-item label="分摊费用" :label-width="formLabelWidth" prop="total"-->
<!--                              v-if="apportionForm.apportionType == 0">-->
<!--                    <el-input v-model="apportionForm.total" ></el-input>-->
<!--                </el-form-item>-->
<!--                <el-row v-if="apportionForm.apportionType == 0">-->
<!--                    <el-col :span="12">-->
<!--                        <el-form-item label="订单号" :label-width="formLabelWidth">-->
<!--                            <el-input-->
<!--                                    type="textarea"-->
<!--                                    :rows="2"-->
<!--                                    placeholder="请输入内容"-->
<!--                                    v-model="settleCopyForm.orderNos">-->
<!--                            </el-input>-->
<!--                        </el-form-item>-->
<!--                    </el-col>-->
<!--                    <el-col :span="2">-->
<!--                        <el-button round style="margin-left: 20px;" @click="searchValue">搜索</el-button>-->
<!--                    </el-col>-->
<!--                </el-row>-->
<!--                <el-row style="padding-left: 50px;padding-right: 50px;">-->
<!--                    <f-table-->
<!--                            v-loading="loading"-->
<!--                            ref="GTable"-->
<!--                            border-->
<!--                            height="400px"-->
<!--                            size="medium"-->
<!--                            row-key="id"-->
<!--                            class="c-th-has-bgcolor"-->
<!--                            :columns="apportionColumns"-->
<!--                            :data="apportionTableData"-->
<!--                            :cell-style="{ 'text-align': 'center' }"-->
<!--                            @selection-change="handleSelectionChange"-->
<!--                            @queryChange="handleTableQueryChange"-->
<!--                            @sort-change="handleTableChangeSort">-->
<!--                        >-->
<!--                        <template v-slot="scope">-->
<!--                            <template v-if="scope.column.property === 'podEnd'">-->
<!--                                <span>{{parseDict(scope.row.podEnd,'sys_port')}}</span>-->
<!--                            </template>-->
<!--                        </template>-->
<!--                    </f-table>-->
<!--                </el-row>-->
<!--            </el-form>-->
<!--            <div slot="footer" class="dialog-footer">-->
<!--                <el-button size="medium" @click="apportionVisible = false ; apportionForm={}">取 消</el-button>-->
<!--                <el-button type="primary" size="medium" @click="saveSettle">确 定</el-button>-->
<!--            </div>-->
<!--        </el-dialog>-->
      <pay-apply-container-dialog ref="payApply" @ok="this.handleQuery"></pay-apply-container-dialog>
      <import-file-dialog :visible="importFileVisible" :import-file-data="importFileData" @ok="importFileVisible = false"></import-file-dialog>
    </div>
</template>

<script>


    import QueryMixins from "@/mixins/QueryMixins";
    import {getAction, putAction, deleteAction,postAction} from "@/api/manage";
    import {sanweixiaoshu} from "../../../utils/pattern";
    import { setUpRouteUtil } from '@/utils/util';
    import payApplyContainerDialog from "../../hyFinanceManage/modules/pay/payApplyContainerDialog";
    import importFileDialog from "../../channel/modules/importFileDialog.vue";

    export default {
        name: 'BasicModule',
        mixins: [QueryMixins],
        components: {payApplyContainerDialog,importFileDialog},
        data() {
            var checkTotal = (rule, value, callback) => {

                if (!sanweixiaoshu.test(value)) {
                    return callback(new Error('只能输入整数或者只能三位小数'))
                } else {
                    callback()
                }
                // if (value.replace(/^(\-)*(\d+)\.(\d\d\d).*$/, '$1$2.$3')) {
                //     //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
                //     return callback(new Error('只能输入整数或者只能三位小数'))
                // }
            };
            return {
              importFileData : [],
              importFileVisible : false,
                fileList: [],
                divHeight: '',
                settleCopyFormRules: {
                    containerId: [
                        {required: true, message: '请选择柜子周期数', trigger: 'change'},
                    ],
                    settleIds: [
                        {required: true, message: '请选择结算对象', trigger: 'change'},
                    ],
                },
                rules: {
                    settlement: [
                        {required: true, message: '请填写结算对象', trigger: 'change'},
                    ],
                    feeId: [
                        {required: true, message: '请选择费用中文', trigger: 'change'},
                    ],
                    feenameUs: [
                        {required: true, message: '请填写费用英文', trigger: 'change'},
                    ],
                    total: [
                        {required: true, message: '请填写价格', trigger: 'change'},
                     /*   {validator: checkTotal, trigger: "blur"},*/
                    ],
                    moneyType: [
                        {required: true, message: '请选择交易币种', trigger: 'change'},
                    ],
                    invoiceNoThird : [
                        {max: 255, message: '长度不能超过255', trigger: 'change'},
                    ],

                },
                apportionFormRuler: {
                    settlement: [
                        {required: true, message: '请填写结算对象', trigger: 'change'},
                    ],
                    feeId: [
                        {required: true, message: '请选择费用中文', trigger: 'change'},
                    ],
                    englishName: [
                        {required: true, message: '请填写费用英文', trigger: 'change'},
                    ],
                    total: [
                        {required: true, message: '请填写价格', trigger: 'change'},
                    ],
                    moneyType: [
                        {required: true, message: '请选择交易币种', trigger: 'change'},
                    ]
                },
                queryForm: {},
                row: {},
                loading: false,
                dictCodes: ['sys_user_id', 'customer_id', 'sys_port', 'order_importer', 'transportStatus', 'os_contact', 'bd_fee_type'],
                infomation: {},
                infomationList: [],
                settleForm: {},
                settleCopyForm: {settleIds: []},
                settlementList: [],
                fressList: [],
                settlementSelectedList: [],
                formLabelWidth: '100px',
                disableMixinMounted: true,
                dialogFormAddSettleVisible: false,
                apportionVisible: false,
                apportionForm: {},
                dialogFormCopyVisible: false,
                payTableData: [],
                payList: [],
                receivableList: [],
                osContainerSettle1: {},
                logIpagination: {
                    currentPage: 1,
                    pageSize: 10,
                    pageSizes: [10, 20, 50, 100],
                    total: 0
                },
                logTableData: [],
                apportionTableData: [],
                apportionColumns: [
                    {
                        type: 'selection',
                        width: 50
                    },
                    {
                        prop: 'orderNo',
                        label: '订单号',
                        sortable: 'orderNo',
                        width: 200
                    }, {
                        prop: 'podEnd',
                        label: '目的地',
                        sortable: 'podEnd',
                        width: 200
                    }, {
                        prop: 'realCtn',
                        label: '件数',
                        sortable: 'realCtn',
                        width: 100
                    }, {
                        prop: 'realCmb',
                        label: '体积',
                        sortable: 'realCmb',
                        width: 100
                    }, {
                        prop: 'oceanPkgs',
                        label: '国外打托数',
                        sortable: 'oceanPkgs',
                        width: 100
                    }, {
                        prop: 'realKg',
                        label: '重量',
                        sortable: 'realKg',
                        width: 100
                    }
                ],
                logColumns: [
                    {
                        prop: 'feeId',
                        label: '费用名称',
                      /*  sortable: 'feeId',*/
                    }, {
                        prop: 'afterMoney',
                        label: '修改前价格',
                       /* sortable: 'afterMoney',*/
                    }, {
                        prop: 'beforeMoney',
                        label: '修改后价格',
                     /*   sortable: 'beforeMoney',*/
                    }, {
                        prop: 'createBy',
                        label: '操作人',
                        /*sortable: 'createBy',*/
                    }, {
                        prop: 'createTime',
                        label: '操作时间',
                        /*sortable: 'createTime',*/
                        width: 200
                    },
                ],
                payColumns: [
                    {
                        prop: 'settlement',
                        label: '结算对象',
                      /*  sortable: 'settlement',*/
                    },
                    {
                        prop: 'total',
                        label: '金额',
                      /*  sortable: 'total',*/
                    },
                    {
                        prop: 'moneyType',
                        label: '币种',
                       /* sortable: 'moneyType',*/
                    },
                ],
                columns: [
                    {
                        type: 'selection',
                        width: 50
                    },
                    {
                        prop: 'action',
                        label: '操作',
                        width: 200,
                    },
                    {
                        prop: 'locker',
                        label: '锁定状态',
                        sortable: 'locker',
                    },
                  {
                    prop: 'payStatus',
                    label: '付款状态',
                  },
                  {
                    prop: 'applyNo',
                    label: '申请编号',
                  },
                  {
                    prop: 'removeTime',
                    label: '销账时间',
                  },
                    {
                        prop: 'settlement',
                        label: '结算对象',
                        sortable: 'settlement',
                        width: 140
                    },
                    {
                        prop: 'chineseName',
                        label: '费用中文',
                        sortable: 'chineseName',
                        width: 120
                    },
                    {
                        prop: 'englishName',
                        label: '费用英文',
                        sortable: 'englishName',
                        width: 140
                    },
                    {
                        prop: 'total',
                        label: '价格',
                        sortable: 'total',
                        width: 140
                    },
                    {
                        prop: 'moneyType',
                        label: '交易币种',
                        sortable: 'moneyType',
                        width: 160
                    },
                    {
                        prop: 'isShare',
                        label: '是否已分摊费用',
                        sortable: 'isShare',
                        width: 100
                    },
                    // {
                    //     prop: 'apportionTotal',
                    //     label: '已分摊费用',
                    //     sortable: 'apportionTotal',
                    //     width: 100
                    // },
                  {
                    prop: 'remark',
                    label: '备注',
                    sortable: 'remark',
                    over: true
                  },
                    {
                        prop: 'invoiceNoThird',
                        label: '发票号',
                        sortable: 'remark',
                        over: true
                    },
                    {
                        prop: 'createBy',
                        label: '录入人',
                        sortable: 'createBy',
                        width: 160
                    },
                    {
                        prop: 'createTime',
                        label: '创建时间',
                        sortable: 'createTime',
                        width: 160
                    }
                ],
              containerSettleInvoceColumns: [
                {
                  type: "selection",
                  width: 50,
                },
                {
                  prop: 'invoceNo',
                  label: '单号',
                },
                {
                  prop: 'feeMoney',
                  label: '费用金额',
                },
              ],
                url: {
                  list2: "/shipping/hySettlePay/waitPayApplyContainerList",
                    list: '/operation/container/getOrderList',
                    infomation: '/operation/container/infomation',
                    updateCirculationState: '/operation/container/updateCirculationState',
                    orderUpdate: '/operation/container/orderUpdate',
                    freesList: '/operation/container/getFreesList',
                    settlementSelectedList: '/operation/container/querySettlementSelectedListByContainerId',
                    saveSettle: '/operation/container/addContainerSettel',
                    containerSettelList: '/operation/container/getContainerSettelList',
                    updateSettel: '/operation/container/updateSettel',
                    updateSettelBybatch: '/operation/container/updateSettelBybatch',
                    delSettel: '/operation/container/delSettel',
                    delDuoSettel: '/operation/container/delDuoSettel',
                    apportionList: '/operation/container/apportionList',
                    settleUpdateLog: '/operation/container/settleUpdateLog',
                    profitInformation: '/operation/container/profitInformation',
                    settleCopyFormList: '/operation/container/settleCopyFormList',
                    savesettleCopyForm: '/operation/container/savesettleCopyForm',
                    getAllOrder:'/operation/container/getAllOrder',
                    getContainerSettleById:'/operation/container/getContainerSettleById',
                    upload: "/operation/container/taxUpload",
                    downloadSettleModel: "/file/read/excel/downloadContainerSettleExcel",
                    importHySettleYF: "/operation/container/importHySettleYF",
                    importInvoiceCode: "/express/test/importInvoiceNo",
                    checkCanPay:'/operation/container/checkContainerCanPay',
                },
                btnDisabled: false,
                isAttach:'0',
                baseContainerSettleInvoiceList:[],
                selectedOrderNos:[],
                selectedContainerSettleInvoiceList:[],
                btnLoadingYs:false,
                addCabinetForm: {},
                isUsePost:true
            }
        },
        watch: {
            infomationList() {
                //使用$nextTick方法
                let that = this;
                that.$nextTick(() => {
                    // console.log('元素', that.$refs.fixTop.offsetHeight)
                    that.divHeight = 748 - that.$refs.fixTop.offsetHeight + 'px';
                    // console.log('元素', that.divHeight)
                });
            }
        },
        created() {
            this.initInfomation()
            this.initFreesList()
            this.containerSettelList()
            this.initLogList()
            //费用
            this.initProfitInformation()
        },
        mounted() {
        },
        methods: {
          uploadSectionFileYs(param) {
            this.btnLoadingYs = true;
            //file就是当前添加的文件
            var fileObj = param.file;
            //FormData 对象
            var form = new FormData();
            //文件对象，key是后台接受的参数名称
            form.append("file", fileObj);
            //执行上传方法
            console.log("执行上传方法");
            postAction(this.url.importHySettleYF, form)
                .then((res) => {
                  //这里做上传之后的操作
                  if (res.success) {
                    this.importFileVisible = true;
                    this.importFileData = res.result;
                    this.initInfomation()
                    this.initFreesList()
                    this.containerSettelList()
                    this.initLogList()
                    //费用
                    this.initProfitInformation()
                  }

                }).finally(() => {
              this.btnLoadingYs = false;

            })

          },
          //导入应付
          handleUploadPay(data) {
              const formData = new FormData();
              // data.file.name = data.file.name.replace(/^[ ,，(\n" ")\t]+$/,'');
              formData.append("file", data.file);
              formData.append("containerId", this.$route.query.id);
              formData.append("type", '1');
              postAction(this.url.upload, formData).then((res) => {
                  if (res.success) {
                      this.$message.warning("应付分税正在导入，请耐心等待，稍后请到消息中心查看！");
                      // this.sailForm.fileId = res.result.id;
                      res.result && res.result.id ? this.fileList.push(res.result) : '';
                  }
              });
          },
          handleImportInvoiceCode(data) {
            const formData = new FormData();
            // data.file.name = data.file.name.replace(/^[ ,，(\n" ")\t]+$/,'');
            formData.append("file", data.file);
            formData.append("containerId", this.$route.query.id);
            // formData.append("type", '1');
            postAction(this.url.importInvoiceCode, formData).then((res) => {
              if (res.success) {
                this.$message.success(res.message);
                // this.sailForm.fileId = res.result.id;
                // res.result && res.result.id ? this.fileList.push(res.result) : '';
              }
            });
          },
          handlePreview(file) {
              console.log("文件的url", file.url);

              if (!file.url) {
                  this.$message.error("下载失败");
                  return;
              }

              const type = file.url.substring(file.url.lastIndexOf(".") + 1);
              console.log("type", type);
              if (
                  type === "jpg" ||
                  type === "jpeg" ||
                  type === "img" ||
                  type === "gif" ||
                  type === "png" ||
                  type === "pneg"
              ) {
                  console.log("是图片预览", file.url);
                  this.imgUrl = file.url;
                  this.visible = true;
              }
              // 判断文件类型
              else if (
                  type === "doc" ||
                  type === "docx" ||
                  type === "xlsx" ||
                  type === "xls" ||
                  type === "ppt" ||
                  type === "pptx"
              ) {
                  console.log("不是图片下载", file.url);
                  // 在当前浏览器直接下载
                  document.location.href = file.url;
              } else {
                  // 图片在浏览器打开 新的页面展示
                  window.open(file.url, "hello");
              }
          },
          handleRemove(file) {
              console.log(file);
              if (file.id) {
                  const index = this.fileList.indexOf(file);
                  const {id} = this.fileList[index];
                  deleteAction("/sys/oss/file/delete", {id})
                      .then((res) => {
                          if (res.success) {
                              this.$message.success(res.message);
                          }
                      })
                      .catch((err) => {
                          this.$emit("delete", index);
                      });
                  //如果传递参数为合同，则删除合同
              }
          },
          beforeUpload(file) {
              //对文件大小进行限制
              const isLt2M = file.size / 1024 / 1024 < 15;
              if (!isLt2M) {
                  this.$message.warning("文件大小不能超过15MB");
              }
              const isFile = file.name.indexOf(".") !== -1;
              if (!isFile) {
                  this.$message.warning("该文件名不合法");
              }
              return isLt2M && isFile;
          },
          onexceed() {
              this.$message.warning("最多上传1个文件哦,亲");
          },
          applyPay(selection) {
            console.log("申请付款以下id的费用", this.selectionRows);
            if (this.selectedRowKeys.length === 0) {
              this.$message.warning("请勾选费用");
              return;
            }
            let lockArr=[];
            this.selectionRows.forEach((item) => {
              if (item.locker ==='1'){
                lockArr.push(item.locker);
              }
            });
            if (lockArr.length>0) {
              this.$message.warning("所选费用的已锁定，不允许申请付款！");
              return;
            }
            let payArr=[];
            this.selectionRows.forEach((item) => {
              if (item.payStatus !=='0'){
                payArr.push(item.payStatus);
              }
            });
            console.log('已申请付款的订单',payArr)
            if (payArr.length>0) {
              this.$message.warning("所选费用已有已申请付款过的，请重新选择！");
              return;
            }
            let shareArr=[];
            this.selectionRows.forEach((item) => {
              if (item.isShare ==='1'){
                shareArr.push(item.isShare);
              }
            });

            if (shareArr.length>0) {
              this.$message.warning("所选费用有已经分摊的费用，请重新选择！");
              return;
            }
            let settleCount = [];
            this.selectionRows.forEach((item) => {
              settleCount.push(item.settlement);
            });

            let settleArr = Array.from(new Set(settleCount));
            if (settleArr.indexOf(null) !== -1) {
              this.$message.warning("所选费用的结算对象ID有误，请联系管理员！");
              return;
            }
            if (settleArr.length > 1) {
              this.$message.warning("所选费用的结算对象不一致！");
              return;
            }
            let arr = this.tableData.filter((item) => {
              return this.selectionRows.indexOf(item) === -1;
            });
            console.log("arr--", arr);

            let countCur = [];
            this.selectionRows.forEach((item) => {
              countCur.push(item.moneyType);
            });
            let curArr = Array.from(new Set(countCur));
            console.log("curArr->", curArr);
            if (curArr.length > 1) {
              this.$message.warning("所选费用的费用币种不一致！");
              return;
            }
            console.log('打开申请弹窗')
            //加一层判断
            let that = this;
            postAction(this.url.checkCanPay,this.selectionRows)
            .then(res=>{
              if (res && res.success){
                that.$refs.payApply.edit(this.selectionRows, arr, this.columns);
              }else {
                that.$message({type:'warning',message:res.message})
              }
            })

          },
          handleSelectionChangeContainerSettleInvoce(selection) {
            // 多选
            let arr = [];
            for (let i = 0; i < selection.length; i++) {
              arr.push(selection[i]["invoceNo"]);
            }
            this.selectedOrderNos = [...arr];

          },
          addCabinetSettle(){
            //查询出当前柜子id的所有单号 渲染出表格数据
            this.baseContainerSettleInvoiceList =[];
            this.selectedContainerSettleInvoiceList =[];
            this.settleForm={};
            getAction(this.url.getAllOrder,{containerId:this.$route.query.id}).then(res=>{
              if (res.success){
                let list = res.result;

                if (list && list.length>0) {
                  list.forEach(l=>{
                    this.baseContainerSettleInvoiceList.push({feeMoney:'',invoceNo:l})
                  })
                }
              }
              console.log('柜子下的单号：',this.baseContainerSettleInvoiceList);
              this.dialogFormAddSettleVisible=true;
            }).catch(err=>{
              this.dialogFormAddSettleVisible=true;
            })
          },
          listToMap(list, name) {
            var map = {};
            for (var index in list) {
              map[list[index][name]] = list[index];
            }
            return map;
          },
          editCabinetSettle(row){
            //查询出当前柜子id的所有单号 渲染出表格数据
            this.baseContainerSettleInvoiceList =[];
            this.selectedContainerSettleInvoiceList =[];
            this.settleForm ={};
            getAction(this.url.getContainerSettleById,{id:row.id}).then(res=>{
              if (res.success){
                let obj = res.result;
                if (obj) {

                  this.settleForm=obj;
                  this.settleForm.feenameUs = row.englishName
                  this.settleForm.afterTotal = row.total
                  let list = obj.containerSettleInvoceList;
                  let ss = '';
                  let map = {};
                  if (list && list.length>0 && Array.isArray(list)){
                    ss = list.map(l=>l.invoceNo).join(',');
                    map = this.listToMap(list,'invoceNo')
                    this.huixiangouxuan(list);
                  }
                  let list2 = obj.orderNoList;
                  if (list2 && list2.length>0 && Array.isArray(list2)) {
                    list2.forEach(l=>{
                      if (ss.indexOf(l)!==-1 && map && map[l]) {
                        this.baseContainerSettleInvoiceList.push({feeMoney:map[l].feeMoney,invoceNo:l});

                      }else {
                        this.baseContainerSettleInvoiceList.push({feeMoney:'',invoceNo:l})
                      }
                    })
                  }
                }
              }
              // containerSettleInvoceTable

              this.dialogFormAddSettleVisible=true;
            }).catch(err=>{
              this.dialogFormAddSettleVisible=true;
            })
          },
          //回显勾选
          huixiangouxuan(arr){
            let that = this;
            that.$nextTick(() => {
              if (that.$refs.containerSettleInvoceTable) {
                that.$refs.containerSettleInvoceTable.huixianselected(that.baseContainerSettleInvoiceList,arr,'invoceNo');
              }
            })
          },
            addAttach(){
                this.isAttach = '1';
                this.dialogFormAddSettleVisible = true;
            },
            intoDocpage(orderNo) {
                // 对于路由不在左侧菜单中的选中菜单处理
                setUpRouteUtil(this.$route.path, '/docpage/Edit');
                this.$router.push("/docpage/Edit?orderNo=" + orderNo);

            },
            // saveSettle() {
            //     if (this.selectionRows.length > 0) {
            //         let orderNos = "";
            //         for (let i = 0; i < this.selectionRows.length; i++) {
            //             console.log(this.selectionRows[i])
            //             orderNos += this.selectionRows[i].orderNo + ","
            //         }
            //         this.apportionForm.orderNos = orderNos.substring(0, orderNos.length - 1)
            //
            //
            //         console.log(this.apportionForm)
            //         this.$refs['apportionForm'].validate((valid) => {
            //             if (valid) {
            //                 this.apportionForm.containerId = this.$route.query.id;
            //                 console.log(this.apportionForm)
            //                 putAction(this.url.apportion, this.apportionForm)
            //                     .then((res) => {
            //
            //                         if (res.success) {
            //                             this.$message.success(res.message);
            //                             this.containerSettelList();
            //                             this.initInfomation();
            //                             this.apportionVisible = false;
            //                         }
            //                     })
            //
            //             } else {
            //                 return false;
            //             }
            //         })
            //     } else {
            //         this.$message.error("请至少选择一条数据！");
            //     }
            // },
            searchValue() {
                this.apportionForm.orderNos = this.settleCopyForm.orderNos
                this.apportionForm.searchType = '1'
                this.initApportionTableData(this.apportionForm)
            },
            updateApportionType() {
                this.$forceUpdate();//在这里，强制刷新
                if (this.apportionForm.apportionType == 0) {
                    console.log(this.apportionForm)
                    // this.apportionForm.orderNos="";
                    // JSON.parse(JSON.stringify(this.apportionForm))
                    this.apportionTableData = [];
                } else if (this.apportionForm.apportionType == 1) {
                    this.apportionForm.orderNos = null
                    this.initApportionTableData(this.apportionForm)
                }
            },
            saveSettleCopyForm() {
                this.$refs['settleCopyForm'].validate((valid) => {
                    if (valid) {
                        let id = this.$route.query.id;

                                    let form = {};

                                    form.containerId = id
                                    form.locker = '0'
                                    form.isShare = '0'
                                    form.settlementList = this.settleCopyForm.settleIds;
                                    form.oldContainerId = this.settleCopyForm.containerId;
                                    putAction(this.url.savesettleCopyForm, form)
                                        .then((res) => {
                                            if (res.success) {
                                                this.$message.success(res.message);
                                                this.containerSettelList();
                                                this.initInfomation()
                                                this.initLogList()
                                                this.dialogFormCopyVisible = false;
                                            }
                                        })


                    } else {
                        return false;
                    }
                })
            },
            initSettlementList() {
              this.settlementList = [];

              getAction(this.url.settleCopyFormList, {containerId: this.settleCopyForm.containerId})
                    .then(res => {
                        if (res.success) {
                            this.settlementList = res.result
                        }
                    }).catch(e=>{
                })


            },
            copy() {
                this.dialogFormCopyVisible = true
            },
            initProfitInformation() {
                let params = {}
                let id = this.$route.query.id;
                params.containerId = id;
                getAction(this.url.profitInformation, params)
                    .then(res => {
                        if (res.success) {
                            console.log(res)
                            this.payList = res.result.payList
                            this.receivableList = res.result.receivableList
                            this.osContainerSettle1 = res.result.osContainerSettle1
                        }
                    })
            },
            handleLogSizeChange(val) {
                this.logIpagination.currentPage = 1
                this.logIpagination.pageSize = val
                this.initLogList()
            },
            handleLogCurrentChange(val) {
                this.logIpagination.currentPage = val
                this.initLogList()
            },
            initLogList() {
                let params = {}
                let id = this.$route.query.id;
                params.containerId = id;
                if (this.logIpagination) {
                    params.pageNo = this.logIpagination.currentPage
                    params.pageSize = this.logIpagination.pageSize
                }
                getAction(this.url.settleUpdateLog, params)
                    .then(res => {
                        if (res.success) {

                            this.logTableData = []
                            const {records, total} = res.result
                            this.logTableData = records || res.result;
                            this.$refs.logTableData = JSON.parse(JSON.stringify(res.result.records))
                            this.logIpagination && (this.logIpagination.total = total || res.result.length)

                        }
                    })
            },
            handleEdit(row) {
                console.log(row)
                this.settleForm = JSON.parse(JSON.stringify(row));
                this.settleForm.feenameUs = row.englishName
                this.settleForm.afterTotal = row.total
                this.dialogFormAddSettleVisible = true
            },
            apportion(row) {
                this.apportionVisible = true;
                this.apportionForm = row
                this.apportionForm.apportionType = '1'
                this.initApportionTableData(this.apportionForm)


            },

            initApportionTableData(row) {

                if (this.apportionForm.apportionType == 0) {
                    this.apportionForm.containerId = null
                } else {
                    let id = this.$route.query.id;
                    this.apportionForm.containerId = id
                }
                if (row.orderNos != null) {
                    row.orderNos = row.orderNos.replace("，", ",")
                }
                console.log(row)
                this.loading = true
                putAction(this.url.apportionList, row)
                    .then((res) => {

                        if (res.success) {
                            this.apportionTableData = res.result;
                            this.loading = false
                        }
                    })
            },
            BatchLock(type) {

                let select = JSON.parse(JSON.stringify(this.selectionRows))
                let isCloseAccount = false, message = '';
                for (let i = 0; i < select.length; i++) {
                    if(select[i].isCloseAccount == '1') {
                        isCloseAccount = true;
                        message = type == '1' ? '费用已关账不允许锁定' : '费用已关账不允许解锁'
                        return;
                    }
                    select[i].locker = type;
                }
                if(isCloseAccount) {
                    this.$message.warning(message);
                    return;
                }

                putAction(this.url.updateSettelBybatch, select)
                    .then((res) => {
                        if (res.success) {
                            this.$message.success(res.message);
                            this.containerSettelList();
                            // this.dialogFormAddSettleVisible = false;
                        }
                    })
            },
            handleLock(row, type) {
                let rowupdate = JSON.parse(JSON.stringify(row))
                rowupdate.locker = type;
                putAction(this.url.updateSettel, rowupdate)
                    .then((res) => {
                        if (res.success) {
                            this.$message.success(res.message);
                            this.containerSettelList();
                            // this.dialogFormAddSettleVisible = false;
                        }
                    })
            },
            containerSettelList() {
                let id = this.$route.query.id;
                this.settleForm.containerId = id;
                getAction(this.url.containerSettelList, {containerId: id})
                    .then(res => {
                        if (res.success) {
                            const {records, total} = res.result
                            this.tableData = records || res.result;
                            console.log("表单数据", this.tableData);
                            this.ipagination && (this.ipagination.total = total || res.result.length)
                        }
                    })
            },
            save() {

                let id = this.$route.query.id;
                this.settleForm.containerId = id;
                console.log("dddd333d")
                this.$refs['settleForm'].validate((valid) => {
                    if (valid) {
                      // let list = this.selectedOrderNos;
                      // let baseList = this.baseContainerSettleInvoiceList;
                      // console.log('勾选中的费用列表',list);
                      // if (!list || list.length===0){
                      //   this.$message.warning('请至少勾选一条单号');
                      //   return false;
                      // }
                      // let ss = list.join(',');
                      // let noss = [];
                      // let selectedList = [];
                      // let total=0;
                      // for (var i=0;i<baseList.length;i++){
                      //   let a = baseList[i];
                      //   if (a){
                      //     let feeMoney = a.feeMoney;
                      //     let invoceNo = a.invoceNo;
                      //
                      //     if (ss.indexOf(invoceNo)!== -1){
                      //       console.log('勾选的单号',feeMoney,invoceNo,ss);
                      //       if (!feeMoney){
                      //         this.$message.warning('勾选的单号的费用需要填写才能保存！');
                      //         return false;
                      //       }
                      //       total+=parseFloat(feeMoney)
                      //       selectedList.push(a);
                      //     }else {
                      //       noss.push(invoceNo);
                      //     }
                      //   }
                      // }
                      // //判断 勾选的费用合计 是否等于 当前价格
                      // let settleFormToal = parseFloat(this.settleForm.total);
                      // total = parseFloat(total);
                      // console.log('柜子的费用,勾选单号的费用',settleFormToal,total)
                      // if (settleFormToal !== total) {
                      //   this.$message.warning('勾选的单号的费用之和需要等于当前柜子费用！');
                      //   return false;
                      // }
                      // this.settleForm.containerSettleInvoceList = selectedList;
                      // this.settleForm.noSelectedOrderNoList = noss;
                        if (this.settleForm.id != null) {
                            console.log("jinlail")
                            putAction(this.url.updateSettel+"?isAttach="+this.isAttach, this.settleForm)
                                .then((res) => {
                                    if (res.success) {
                                        this.$message.success(res.message);
                                        this.containerSettelList();
                                        this.initInfomation();
                                        this.initLogList();
                                        this.initProfitInformation();
                                        this.settleForm = {}
                                        this.isAttach = '0';
                                        this.dialogFormAddSettleVisible = false;
                                    }
                                }).catch(err => {
                                this.isAttach = '0';
                            })
                        } else {
                            console.log("jinlail22")
                            putAction(this.url.saveSettle+"?isAttach="+this.isAttach, this.settleForm)
                                .then((res) => {
                                    if (res.success) {
                                        this.$message.success(res.message);
                                        this.containerSettelList();
                                        this.initInfomation();
                                        this.initLogList()
                                        this.settleForm = {}
                                        this.dialogFormAddSettleVisible = false;
                                    }
                                })
                        }
                    } else {
                        console.log(valid);
                        this.isAttach = '0';
                        return false;
                    }
                })
            },
            getfeenameOptions(item) {
                this.$set(this.settleForm, 'feenameUs', item.englishName)
                this.$set(this.settleForm, 'feename', item.chineseName)
                this.$set(this.settleForm, 'feecode', item.code)
            },
            initFreesList() {
                getAction(this.url.freesList)
                    .then(res => {
                        if (res.success) {
                            this.fressList = res.result
                        }
                    })
            },
          initSettlementSelectedList() {
            getAction(this.url.settlementSelectedList+"?containerId=" + this.$route.query.id)
                .then(res => {
                  if (res.success) {
                    this.settlementSelectedList = res.result;
                  }
                })
          },
            initInfomation() {
                let id = this.$route.query.id;
                let that = this;

                if (id) {
                    console.log(id)
                    getAction(this.url.infomation, {containerId: this.$route.query.id})
                        .then(res => {
                            console.log(res)
                            if (res.success) {
                                if (res.result['order'] != null) {
                                    that.infomation = res.result['order']
                                } else {
                                    that.infomation = {}
                                }

                                if (res.result['list'] != null) {
                                    that.infomationList = res.result['list']
                                } else {
                                    that.infomationList = []
                                }
                                if (res.result['payListCount'] != null) {
                                    that.payTableData = res.result['payListCount']
                                } else {
                                    that.payTableData = []
                                }
                                if (res.result['osContainer'] != null) {
                                    that.addCabinetForm = res.result['osContainer']
                                    that.addCabinetFormTwo = res.result['osContainer']
                                }
                                that.settlementSelectedList = res.result.settlementSelectedList;

                            }
                        })
                }
            },
            handledel(row) {
              this.$confirm("确定删除该费用,该操作将会把该费用已分摊的订单里的未锁定的费用也一起删除", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "error",
                closeOnClickModal: false,
              }).then(() => {
                deleteAction(this.url.delSettel, {id:row.id})
                    .then(res => {
                        if (res.success) {
                            this.$message.success(res.message);
                            this.containerSettelList();
                            this.initInfomation();
                            this.initLogList();
                            this.initProfitInformation();
                        }
                    })
               }).catch(() => {
              })
            },
          delDuo() {
            this.$confirm("确定删除费用?", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "error",
              closeOnClickModal: false,
            }).then(() => {
              postAction(this.url.delDuoSettel, this.selectedRowKeys)
                  .then(res => {
                    if (res.success) {
                      this.$message.success(res.message);
                      this.containerSettelList();
                      this.initInfomation();
                      this.initLogList();
                      this.initProfitInformation();
                    }
                  })
            }).catch(() => {
            })
          },
            handleClose() {
                this.containerSettelList();
                this.initInfomation()
                this.initLogList();
                this.initProfitInformation();
                this.settleForm = {}
                this.settleCopyForm = {}
                this.dialogFormAddSettleVisible = false;
                this.dialogFormCopyVisible = false;
                this.apportionVisible = false
            },
            handleQuery(url) {
                console.log(url)
                let id = this.$route.query.id;
                this.loading = true;
                let form = {...this.queryForm, ...this.filterForm};
                for (let item in form) {
                    if (typeof form[item] === "string") {
                        form[item] = form[item].trim();
                        if (form[item] === "") {
                            form[item] = null;
                        }
                    }
                }
                const {prop, order} = this.isorter;
                console.log(this.isorter)
                // 传入的参数
                const params = {
                    ...form,
                    column: prop,
                    order: order.indexOf("asc") !== -1 ? "asc" : "desc",
                };
                //判断 是否要查询startTime

                if (!params.column) {
                    if (this.queryForm.column === "startTime") {
                        this.$set(params, "column", "startTime");
                    } else {
                        this.$set(params, "column", "createTime");
                    }
                    //提货中查询
                    if (this.queryForm.column === "distributionTime") {
                        this.$set(params, "column", "distributionTime");
                    }
                }
                if (this.ipagination) {
                    params.pageNo = this.ipagination.currentPage;
                    params.pageSize = this.ipagination.pageSize;
                }
                console.log("查询对象", params);
                params.containerId = id;
                getAction(url, params)
                    .then((res) => {
                        if (res.success) {
                            const {records, total} = res.result;
                            this.tableData = records || res.result;

                            this.ipagination &&
                            (this.ipagination.total = total || res.result.length);
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
          handleQueryApportionList(url) {
            console.log(url)
            let id = this.$route.query.id;
            this.loading = true;
            let form = {...this.queryForm, ...this.filterForm};
            for (let item in form) {
              if (typeof form[item] === "string") {
                form[item] = form[item].trim();
                if (form[item] === "") {
                  form[item] = null;
                }
              }
            }
            const {prop, order} = this.isorter;
            console.log(this.isorter)
            // 传入的参数
            const params = {
              ...form,
              column: prop,
              order: order.indexOf("asc") !== -1 ? "asc" : "desc",
            };
            //判断 是否要查询startTime

            if (!params.column) {
              if (this.queryForm.column === "startTime") {
                this.$set(params, "column", "startTime");
              } else {
                this.$set(params, "column", "createTime");
              }
              //提货中查询
              if (this.queryForm.column === "distributionTime") {
                this.$set(params, "column", "distributionTime");
              }
            }
            if (this.ipagination) {
              params.pageNo = this.ipagination.currentPage;
              params.pageSize = this.ipagination.pageSize;
            }
            console.log("查询对象", params);
            params.containerId = id;
            putAction(url, params)
                .then((res) => {
                  if (res.success) {
                    const {records, total} = res.result;
                    this.apportionTableData = records || res.result;

                    this.ipagination &&
                    (this.ipagination.total = total || res.result.length);
                  }
                })
                .finally(() => {
                  this.loading = false;
                });
          },
            handleTableChangePay({prop, order}) {
                this.$set(this.isorter, 'prop', prop)
                this.$set(this.isorter, 'order', order)
                this.handleQuery(this.url.containerSettelList)
            },
          handleTableChangeSort({prop, order}){
            this.$set(this.isorter, 'prop', prop)
            this.$set(this.isorter, 'order', order)
            this.handleQueryApportionList(this.url.apportionList)
          }
        },
      computed:{
        buttonShowList(){
          return {
            'pljs':this.$btnIsShow('operation/cabinet/DealMoudule','0','批量解锁'),
            'scfy':this.$btnIsShow('operation/cabinet/DealMoudule','0','删除费用'),
            'xzmb':this.$btnIsShow('operation/cabinet/DealMoudule','0','柜子应付导入费用模板下载'),
            'dr':this.$btnIsShow('operation/cabinet/DealMoudule','0','导入柜子应付费用'),
            'tb':this.$btnIsShow('operation/cabinet/DealMoudule','0','同步发票编号'),
            'js':this.$btnIsShow('operation/cabinet/DealMoudule','1','解锁'),
            'klr':this.$btnIsShow('operation/cabinet/DealMoudule','0','是否可以查看利润'),

          }
        }
      }
    }
</script>

<style lang="scss" scoped>
    .el-col {
        white-space: nowrap;
        text-align: left;
        font-size: 14px;
        font-weight: 400;
        font-style: normal;
        text-decoration: none;
        font-family: "Segoe UI";
        color: rgb(102, 102, 102);
    }
    .margin_15 {
      margin: 0 15px 0 2px;
      line-height: 25px;
    }
    .margin_2 {
      margin: 0 2px;
      line-height: 25px;
    }
</style>
<style lang="scss">
.botton_height {
  .el-button {
    height: 22px;
  }
}
</style>
