<template>
  <!-- 新增或编辑供应商索赔 -->
  <el-dialog
    title="批量审核"
    :visible="visible"
    width="38%"
    center
    :before-close="closeCheckDialog"
    :close-on-click-modal="false"
  >
    共{{ checkCount }}单确认已赔付，批量审核
    <div slot="footer" class="dialog-footer">
      <el-button size="medium" @click="closeCheckDialog">取 消</el-button>
      <el-button size="medium" type="primary" @click="checkBatch"
        >确 定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { postAction } from "@/api/manage";
import checkList from "@/views/sale/noCost/checkList";
export default {
  name: "CheckBatchDialog",

  components: {
    // newFSelect,
  },
  props: {
    visible: {
      type: Boolean,
    },
    checkCount: {
      type: Number,
      default: 0,
    },
    checkList: {
      type: Array,
      default: () => [],
    },
    selectRows: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      url: {
        // todo : 批量审核接口
        // checkBatch: "/file/claim/checkBatch",
        batchUpdateCheck: "/file/claim/batchUpdateCheck",
      },

      disableOrderNo: false,
      checkBatchIds: [],
    };
  },
  methods: {
    //todo : 关闭弹窗
    closeCheckDialog() {
      this.$emit("closeCheckDialog");
    },
    //todo 批量审核接口实现
    checkBatch() {
      this.checkBatchIds = [];
      // this.selectRows.forEach((select)=>{
      //   this.checkBatchIds.push({
      //     id:select.id,
      //   })
      // })
      console.log(this.checkList, "123");
      this.checkList.forEach((item) => {
        this.checkBatchIds.push({
          id: item.id,
          claimMoney: item.claimMoney || "",
          realClaimMoney: item.realClaimMoney,
          settleId: item.orderSettleId,
        });
      });
      //  postAction(this.url.checkBatch,this.checkList)
      postAction(this.url.batchUpdateCheck, this.checkBatchIds).then((res) => {
        this.$message.success(res.message);
        this.$emit("closeCheckDialog");
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .el-form-item__label {
    width: 70px !important;
  }
  .el-form-item__content {
    margin-left: 70px !important;
  }
  .el-table--scrollable-x .el-table__body-wrapper {
    overflow-x: hidden;
  }
  .el-table__fixed::before,
  .el-table__fixed-right::before {
    width: 0;
  }
  // .el-table__fixed,
  // .el-table__fixed-right {
  //   overflow-y: scroll !important;
  // }
  .el-table__fixed-body-wrapper {
    height: 240px !important;
    overflow: scroll !important;
  }
  .el-table {
    height: 300px !important;
    // overflow-x: scroll !important;

    // min-width: 55px !important;

    // &::before {
    //   height: 0;
    // }
  }

  // .el-table {
  //   height: 200px !important;
  //   // overflow-x: scroll !important;

  //   // min-width: 55px !important;
  // }
  // .el-table__fixed-body-wrapper {
  //   height: 150px;
  //   // overflow-x: hidden;
  //   // overflow-y: scroll;
  //   overflow: scroll;
  //   // overflow-y: scroll !important;
  // }
}
</style>
