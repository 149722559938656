
<template>
  <div class="main-full-content">
    <el-form class="input_top" ref="form" :model="queryForm" label-width="120px" size="medium" @submit.native.prevent>
      <el-card class="box-card">
        <el-row>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="流程名称">
              <el-input v-model="queryForm.flowName" @keyup.enter.native="handleSearch"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label-width="30px">
              <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
              <el-button plain @click="handleReset">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>

    </el-form>
    <el-card class="box-card">
      <div class="f-p-b-15">
        <el-button type="primary" size="medium" icon="el-icon-plus" @click="handleAdd"
        >新增</el-button>
      </div>
      <f-table
          v-loading="loading"
          ref="GTable"
          border
          size="medium"
          row-key="id"
          class="c-th-has-bgcolor"
          :columns="columns"
          :data="tableData"
          :cell-style="{ 'text-align': 'center' }"
          tableHeight="66vh"
          @selection-change="handleSelectionChange"
          @sort-change="handleTableChange">
        <template v-slot="scope">
          <template v-if="scope.column.property === 'action'">
            <el-button type="text" @click="handleEdit(scope.row)"
            >编辑</el-button>
            <el-button type="text" @click="handleDele(scope.row)"

            >删除</el-button>
          </template>
        </template>
      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination
            v-bind="ipagination"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange">
        </el-pagination>
      </el-row>
    </el-card>

    <el-dialog width="40%" class="editNodeBox" :title="form.id?'编辑流程节点信息':'新增流程节点信息'" :visible.sync="dialogFormVisible" :close-on-click-modal="false" v-if="dialogFormVisible">


      <el-form class="demo-form-inline"  :model="form" :rules="rules" ref="form">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            流程信息
          </div>
          <el-form-item label="流程名称" :label-width="formLabelWidth" prop="flowName">
            <el-input v-model="form.flowName" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="流程的key" :label-width="formLabelWidth" prop="flowKey">
            <el-input v-model="form.flowKey" autocomplete="off"></el-input>
          </el-form-item>
        </el-card>
        <el-card class="box-card">
          <div slot="header" class="clearfix">
              节点信息
          </div>
          <div class="node_box">
            <el-button size="medium" type="primary" @click="handleAddRow">新增一行</el-button>
          </div>
          <el-table v-loading="loading"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor node_box"
                    :data="form.data"
                    max-height="600">

            <el-table-column prop="indexP" label="编号" width="50">
              <template slot-scope="scope">
                <span>{{ scope.$index + 1 }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="node" label="node值" width="200">
              <!-- 标头需要加必填标识 -->
              <template slot="header">
                <template>
                  <span class="is_require_form">node值</span>
                </template>
              </template>
              <template slot-scope="scope">
                <el-form-item label=""
                  :prop="'data.'+scope.$index+'.node'"
                  :rules="rules.node">
                  <el-input type="number" v-model="scope.row.node" autocomplete="off" />
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column prop="userName" label="节点人" width="200">
              <template slot-scope="scope">
                <el-input v-model="scope.row.userName" autocomplete="off" />
              </template>
            </el-table-column>
            <el-table-column prop="name" label="操作">
              <template slot-scope="scope">
                <el-button type="text" @click="handleDel(scope.row.key)">删除</el-button>
              </template>
            </el-table-column>

          </el-table>
        </el-card>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="medium" @click="dialogFormVisible = false">取 消</el-button>
        <el-button size="medium" type="primary" @click="handleSave">确 定</el-button>
      </div>
    </el-dialog>


  </div>
</template>

<script>
import {deleteAction, postAction, putAction} from '@/api/manage'
import QueryMixins from "../../mixins/QueryMixins";
import {zhengshuPattern} from "@/utils/pattern";
//import {mobilePattern} from '@/utils/pattern'

export default {
  name: 'Importer',
  mixins: [QueryMixins],
  data() {
    return {
      rules: {
        flowName: [
          {required: true, message: '请输入流程名称', trigger: 'change'},
          {max: 100, message: '长度不能超过100', trigger: 'change'},
        ],
        flowKey: [
          {required: false, message: '请输入流程key', trigger: 'change'},
          {max: 200, message: '长度不能超过50', trigger: 'change'},
        ],
        node: [
          {required: true, message: '请输入node值', trigger: 'change'},
        ]

      },
      emailList: [],
      ids: [],
      flowKeys: [],
      data:[],
      nodeLists:[],
      row: {},
      form: {},
      formLabelWidth: '110px',
      //控制弹框显示
      dialogFormVisible: false,
      columns: [
        {
          prop: 'action',
          label: '操作',

        },
        {
          prop: 'flowName',
          label: '流程名称',
          /*sortable: 'custom',*/

        },
        {
          prop: 'flowKey',
          label: '流程key',
          /*sortable: 'custom',*/

        },

        {
          prop: 'nodePersion',
          label: '流程节点信息',
          /*sortable: 'custom',*/

        },
        {
          prop: 'createBy',
          label: '创建人',
          /*sortable: 'custom',*/

        },
        {
          prop: 'createTime',
          label: '创建时间',
          // sortable: 'custom',

        },

      ],
      url: {
        list: '/sys/activityNode/queryList',
        add: '/sys/activityNode/add',
        edit: '/sys/activityNode/edit',
        getById: '/sys/activityNode/queryById',
        delete: '/sys/activityNode/delete',
      },
    }
  },
  methods: {
    handleSelectionChange(selection) { // 多选
      let arr = []
      this.emailList = [];
      for (let i = 0; i < selection.length; i++) {
        arr.push(selection[i]['id'])
        this.emailList.push(selection[i]['email']);
      }
      this.selectionRows = selection
      this.selectedRowKeys = [...arr]
    },
    handleAdd() {
      this.form = { data: [] };
      this.dialogFormVisible = true;
    },
    handleEdit(row) {
      //将对象转为json字符串再转回对象
      //this.form = row; 复制的是指针 仍然指向这个对象row
      this.form = JSON.parse(JSON.stringify(row));
      this.flowKeys = this.form.nodePersion
      var jsonObj  = JSON.parse(this.flowKeys)
      let arr1=[];
      for(let i in jsonObj){
        jsonObj[i].key = Number(i) + 1;
        arr1.push(jsonObj[i]);
      }
      this.form.data = arr1
      console.log("得到的是什么",this.form.data)
      this.dialogFormVisible = true;
    },
    edit(key) {
      const newData = [...this.form.data];

      const target = newData.filter(item => key === item.key)[0];
      console.log("表单长度",target)
      this.editingKey = key;
      if (target) {
        target.editable = true;
        this.form.data = newData;
      }
    },
    // 这里新增只有一行
    /*handleAddRow() {
      if (this.form.data.length === 0) {
        let nkey = 1
        this.form.data.push({
          key: nkey,
          node: '',
          userName: '',

        });
        this.keyTotal = nkey
        this.edit(nkey)

      } else {
        let num = this.form.data.length
        let nextKey = num + 1
        this.form.data.push({
          key: nextKey,
          node: '',
          userName: '',
        });

        this.edit(nextKey)
      }
      let num = this.form.data.length
      this.keyTotal = num + '条'
    },*/
    handleAddRow() {
      let num = this.form.data.length
      let nextKey = num + 1
      this.form.data.push({
        key: nextKey,
        node: '',
        userName: '',
      });
      console.log(this.form.data,'this.form.data')
    },
    handleDel(key) {
      let that = this
      console.log("得到的是什么key",key)
      that.form.data.splice(key-1, 1);
      that.form.data.forEach((element, index) => {
        element.key = index + 1;
      });
    },
    handleSave() {
      this.$refs.form.validate(valid => {
        this.form.nodeLists=this.form.data
        console.log("编辑的节点信息",this.form)
        if (valid) {

          //编辑
          if (this.form.id) {
            putAction(this.url.edit, this.form)
                .then((res) => {
                  if (res.success) {
                    this.$message.success(res.message);
                    //关闭弹窗
                    this.dialogFormVisible = false;
                    this.handleQuery()
                  }
                })
          } else {
            postAction(this.url.add, this.form)
                .then((res) => {
                  if (res.success) {
                    this.$message.success(res.message);
                    //关闭弹窗
                    this.dialogFormVisible = false;
                    this.handleQuery()
                  }
                })
          }
        }
      })

    },
    handleDele(row) {
      this.$confirm(`确定删除该流程？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'success',
        closeOnClickModal: false,
      }).then(() => {
        deleteAction(this.url.delete, {id: row.id}).then(res => {
          this.$message({
            type: 'success',
            message: res.message
          })
          this.handleQuery()
        })
      }).catch(() => {

      })
    },
    handleReset() {
      this.queryForm = {};
      this.ipagination.currentPage = 1
      this.handleQuery()

    }
  }, created() {
  }
}
</script>

<style lang="scss" scoped>
.editNodeBox {
  .node_box {
    margin: 0 20px 10px 20px;
    box-sizing: border-box;
  }
  .c-th-has-bgcolor {
    width: 80%;
  }
}
</style>
