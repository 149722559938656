<template>
    <div>
        <supplier-form ref="child"></supplier-form>
        <div class="f-text-right">
            <el-button size="medium" type="primary" icon="el-icon-s-promotion" @click="handleAdd" :disabled="btnDisabled">保存
            </el-button>
            <el-button size="medium" plain @click="hadleReset">重置</el-button>
        </div>
    </div>
</template>

<script>
    import SupplierForm from "./SupplierForm";
    import {httpAction} from "@/api/manage";
    import {updatedDict} from "../../../utils/util";

    export default {
        name: 'BasicModule',
        components: {
            SupplierForm
        },
        data() {
            return {
                row: {}
                ,
                url: {
                    add: '/sys/supplier/add',
                    edit: '/sys/supplier/edit',
                },
                btnDisabled: false,
            }
        },
        mounted() {

        },
        methods: {
            handleAdd() {
                //获取表单提交的数据
                //this.btnDisabled = true;
                var form = this.$refs.child.form;
                form.id = this.$route.query.id;
                console.log("供应商对象", form);
                if (form.supplierType && form.supplierType.includes("7") && (form.shortname == null || form.shortname == '') ){
                  this.$message.warning('当前供应商类型中有船公司，请输入船公司简称或者船代码！');
                  return ;
                }

                let flag = this.$refs.child.validateForm();
                if (flag) {
                    console.log("校验通过");
                    let url = this.url.add;
                    let method = "post";
                    if (form.id) {
                        url = this.url.edit;
                        method = "put";
                    }
                    httpAction(url, method, form)
                        .then(res => {
                            if (res.success) {
                                this.$message.success(res.message);
                                //清处缓存
                                updatedDict('ship_company,bookAgent_id,twoAgent_id,clearAgent_id,declareAgent_id,dismantleAgent_id,deliveryAgent_id,os_contact,supplierId,supplier_id,supplier_channel_id,hy_supplier_list,bookAgent_id_chinese');
                                this.$router.go(-1);
                            } else {
                                this.btnDisabled = false;
                            }

                        }).catch(err => {
                        this.btnDisabled = false;
                    })
                } else {
                    this.btnDisabled = false;
                }
                // }else {
                //   this.$message.error('表单校验不通过');
                // }
            },
            hadleReset() {
                this.$refs.child.resetForm();
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
