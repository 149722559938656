<template>
  <div>
    <el-dialog
        :title="'发送邮件详情：' + title"
        :visible="visible"
        width="75%"
        custom-class="c-custom-dialog"
        :close-on-click-modal="false"
        :before-close="handleClose"
        append-to-body
    >
      <el-form class="multiline_select" ref="form" :model="queryForm" label-width="80px" size="medium">
        <el-card class="box-card">
          <el-row class="inputs_row">
              <el-col :xs="24" :sm="12" :md="12" :lg="5">
                  <el-form-item label="邮箱">
                      <el-input
                              v-model="queryForm.email"
                              @keyup.enter.native="handleSearch"
                      ></el-input>
                  </el-form-item>
              </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="5">
              <el-form-item label="发送结果">
                <el-select v-model="queryForm.result" clearable filterable>
                  <el-option value="成功" label="成功"></el-option>
                  <el-option value="失败" label="失败"></el-option>
                  <el-option value="未发" label="未发"></el-option>
                  <el-option value="无需推送" label="无需推送"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="8">
              <el-form-item label="发送时间" label-width="100px">
                <div class="f-flex">
                  <el-date-picker
                      class="f-flex-1"
                      v-model="queryForm.createTimeStart"
                      type="datetime"
                      size="small"
                      placeholder="开始时间"
                      default-time="00:00:00"
                      value-format="yyyy-MM-dd HH:mm:ss"
                  >
                  </el-date-picker>
                  <span class="f-p-h-2">~</span>
                  <el-date-picker
                      class="f-flex-1"
                      v-model="queryForm.createTimeEnd"
                      type="datetime"
                      size="small"
                      placeholder="结束时间"
                      default-time="23:59:59"
                      value-format="yyyy-MM-dd HH:mm:ss"
                  >
                  </el-date-picker>
                </div>
              </el-form-item>
            </el-col>
            <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="3">
              <el-form-item label-width="30px">
                <el-button
                    type="primary"
                    icon="el-icon-search"
                    @click="handleSearch"
                >查询
                </el-button
                >
                <el-button plain @click="handleReset">重置</el-button>
                <!-- <el-button type="text" mini @click="showLoadingSearch=!showLoadingSearch"><i :class="showLoadingSearch ? 'el-icon-arrow-up' : 'el-icon-arrow-down'" style="margin-right:3px"></i>{{showLoadingSearch ? '收起' :'更多'}}</el-button> -->
              </el-form-item>
            </el-col>
          </el-row>
        </el-card>
      </el-form>
      <el-card class="box-card">
        <f-table
            v-loading="loading"
            ref="GTable"
            border
            size="medium"
            row-key="id"
            class="c-th-has-bgcolor"
            :columns="columns"
            :data="tableData"
            :cell-style="{ 'text-align': 'center' }"
        >
            <template v-slot="scope">

                <template v-if="scope.column.property === 'result'">
                    <span :style="scope.row.result === '成功' ? 'color:green' : 'color : red'">{{scope.row.result}}</span>
                </template>
            </template>
        </f-table>
        <el-row class="f-text-right f-p-v-8">
          <el-pagination
              v-bind="ipagination"
              layout="total, sizes, prev, pager, next, jumper"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
          >
          </el-pagination>
        </el-row>
      </el-card>
    </el-dialog>


  </div>
</template>

<script>
import QueryMixins from "@/mixins/QueryMixins.js";
import {postAction} from "@/api/manage";


export default {
  name: "sendYingxiaoEmailLog",
  mixins: [QueryMixins],
  components: {
  },
  props:{
    busId:{
      type:String
    },
    title:{
      type:String
    },
    visible:{
      type:Boolean
    },
  },
  data() {
    return {
        isUsePost:true,
      url: {
        list: "/express/emailSendTemplate/getEmailSendDetailIPage",
      },
      columns: [

        {
          prop: "title",
          label: "标题",
          minWidth:120,
        },
        {
            prop: "content",
            label: "邮箱",
            minWidth:60,
        },
        {
          prop: "result",
          label: "结果",
            minWidth:40,
        },
        {
          prop: "failedMsg",
          label: "失败原因",
            minWidth:100,
        },
        {
          prop: "createTime",
          label: "发送时间",
            minWidth:90,
        },
        {
          prop: "sendNum",
          label: "发送次数",
            minWidth:30,
        },
      ],
      disableMixinMounted: true,
    };
  },
  watch: {
    visible(val) {
      if(val && this.busId) {
        this.$set(this.queryForm,'busId',this.busId);
        this.handleQuery();
      } else {

      }
    }
  },
  created() {
      this.$set(this.queryForm,'busId',this.busId);
  },
  methods: {
    handleClose() {
      this.$emit("ok");
    },
    handleReset(){
      this.queryForm = {busId:this.busId};
      this.filterForm = {};
      this.ipagination.currentPage = 1
      this.handleQuery()
    }
  },
};
</script>

<style lang="scss" scoped>
</style>
