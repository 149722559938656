<template>
    <div>
        <el-dialog
                title="添加费用"
                border
                :visible="addFeeDialogVisible"
                :close-on-click-modal="false"
                :destroy-on-close="true"
                :before-close="handleAddFeeDialogClose">
            <el-form>
                <el-row>
                    <el-col>
                        <el-form-item label="单号">
                            <el-input v-model="feeOrderNo" style="width: 25%"></el-input>
                        </el-form-item>
                        <el-button type="primary" @click="addFeesAgain">查找</el-button>
                    </el-col>

                </el-row>
            </el-form>

            <f-table
                    :columns="addColumns"
                    :cell-style="{ 'text-align': 'center' }"
                    :data="feesAgain"
                    @selection-change="addSelectionChange"
            ></f-table>
            <el-row class="f-text-right f-p-v-8">
                <div class="payApplyRow" v-if="sumAddFee.length > 0" style="float: left">
                    <span style="">合计：</span>
                    <div class="payApplyRow payApplyRowItem" v-for="(item,index) in sumAddFee" :key="index" style="color: red">
                        <span style="float: left">{{item.cur}}</span>
                        <span style="margin-left: 10px">{{item.total }}</span>
                    </div>
                </div>
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                >
                </el-pagination>
            </el-row>
            <span slot="footer">
            <el-button type="primary" size="medium" @click="addSubmit">确定</el-button>
        </span>
        </el-dialog>

        <el-dialog
                :title="title"
                :visible="visible"
                width="70%"
                custom-class="c-custom-dialog"
                :close-on-click-modal="false"
                :destroy-on-close="true"
                :before-close="handleClose"
        >
            <el-form :model="billForm" :rules="rules" ref="billForm" :inline="true" :label-position="right"
                     label-width="109px">

                <el-row>
                    <el-col>
                        <el-form-item label="供应商">
                            <el-input v-model="billForm.payMan" style="width: 300px" disabled></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col>
                        <el-form-item prop="cur" label="供应商收款币种">
                            <f-select v-model="billForm.cur"
                                      :dict="'bd_cur'"
                                      placeholder="请选择"
                                      clearable
                                      filterable
                                      :father-val="billForm.cur"
                                      :is-need="billForm.cur"
                                      @change="curChangeBank"
                                      disabled
                                      class="f-full-width"></f-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="23">
                        <el-form-item prop="bankId" label="供应商收款银行">
                            <el-select v-model="billForm.bankId"
                                       placeholder="根据币种选择银行"
                                       clearable
                                       filterable
                                       :father-val="billForm.bankId"
                                       :is-need="billForm.bankId"
                                       class="f-full-width"
                                        style="width: 500px">
                                <el-option v-for="(item,index) in banks" :value="item.id" :label="item.bankName" :key="index">
                                    {{item.bankName + '-' + item.accountName + '-' + item.account + '-' + item.cur }}
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col>
                        <el-form-item prop="invoiceNo" label="发票号">
                            <el-input v-model="billForm.invoiceNo" style="width: 300px" @blur="changeAmount" maxlength="255" show-word-limit></el-input>
                        </el-form-item>
                    </el-col>

<!--                    <el-col>-->
<!--                        <el-form-item label="自动识别水单文本">-->
<!--                                <el-button size="mini" v-for="str in ocrWords" style="float: left">{{ str }}</el-button>-->
<!--                        </el-form-item>-->
<!--                    </el-col>-->
                </el-row>
                <el-row>
                    <el-col>
                        <el-form-item label="发票备注">
                            <el-input v-model="billForm.invoiceRemark" style="width: 300px"></el-input>
                        </el-form-item>
                    </el-col>

                </el-row>
                <el-row>
                    <el-form-item label="最晚付款时间">
                        <el-date-picker
                                class="f-flex-1"
                                v-model="billForm.lastPayTime"
                                type="datetime"
                                size="small"
                                range-separator="至"
                                placeholder="开始日期"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                unlink-panels
                        >
                        </el-date-picker>
                    </el-form-item>
                </el-row>
                <el-row>
                    <el-col>
                        <el-form-item label="上传账单" v-if="showOnly">
                            <el-upload
                                    class="upload-demo"
                                    :action="url.uploadInvoice"
                                    :http-request="handleUpload"
                                    accept=".jpg,.png,.pdf,.xlsx,.xls,.doc,.docx"
                                    :on-preview="handlePicPreview"
                                    :on-remove="handleRemove"
                                    :before-upload="beforeUpload"
                                    :file-list="fileList"
                                    list-type="picture">
                                <el-button size="small" type="primary">点击上传</el-button>
                                <!--                                <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>-->
                            </el-upload>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col>
                        <el-button plain @click="addFee" v-if="showOnly">添加费用</el-button>

                    </el-col>
                </el-row>

                <f-table
                        v-loading="loading"
                        ref="GTable"
                        border
                        size="medium"
                        row-key="id"
                        class="c-th-has-bgcolor"
                        :columns="columns"
                        :data="billForm.settleList"
                        :cell-style="{ 'text-align': 'center' }"
                        style="margin-top: 15px"
                        @selection-change="handleSelectionChange"
                >
                    <template v-slot="scope">
                        <template v-if="scope.column.property === 'action'">
                            <el-popconfirm
                                    title="将删出该条费用，是否确认？"
                                    confirm-button-text="确认"
                                    cancel-button-text="不用了"
                                    icon="el-icon-info"
                                    icon-color="red"
                                    @confirm="delFee(scope.row)"
                            >
                                <el-button slot="reference" type="text">删除</el-button>
                            </el-popconfirm>
<!--                            <el-button type="text" @click="delFee(scope.row)">删除</el-button>-->
                        </template>
                        <template v-else-if="scope.column.property === 'realPay'">
                            <el-form-item
                                    :rules="[{required: true, message: '实付不能为空', trigger: 'blur'}]">
                                <el-input type="text" v-model="scope.row.realPay" @input="changeAmount" oninput="value=value.match(/\d+\.?\d{0,2}/)"></el-input>
                            </el-form-item>

                        </template>
                      <template v-else-if="scope.column.property === 'orderNo'">
                        <a style="color: #00a0e9" @click.prevent="visible = false;$intoDocPage(scope.row.orderNo)">{{ scope.row.orderNo }}</a>
                      </template>

                      <template v-else-if="scope.column.property === 'realAgent'">
                        <a style="color:#57a3f3" @click.prevent="$intoCustomerEditPage(scope.row.cusId)" >{{scope.row.realAgent}}</a>
                      </template>

                    </template>

                </f-table>
            </el-form>
            <el-form :inline="true" :label-position="right" label-width="109px">
                        <el-row :gutter="24" class="payApplyRow">
                            <el-col>
                                <span>合计：</span>
                            </el-col>
                            <el-col v-for="(item,index) in sumFee" :key="index" :span="4" style="color: red">
                                <el-form-item  :label="item.cur" :key="index" class="payApplyItem">
                                    <span>{{item.total}}</span>
                                </el-form-item>
                            </el-col>
                        </el-row>
            </el-form>
            <span slot="footer">
            <el-button v-if="showOnly" type="primary" size="medium" @click="handleSubmit"
                       :disabled="ifDis">确定申请</el-button>
        </span>
        </el-dialog>
    </div>
</template>

<script>
    import {getAction, postAction} from '@/api/manage';
    import { setUpRouteUtil } from '@/utils/util';
    // import QueryMixins from "../../../../mixins/QueryMixins";

    export default {
        name: 'receiveApply',
        // mixins: [QueryMixins],

        data() {
            const validateFileList = (rule, value, callback) => {
                if (this.billForm.uploadFileIds.length === 0) {
                    callback(new Error('请上传账单'));
                }
                callback();
            }

            return {
                sumFee:[
                ],
                editStartOptions: {
                    disabledDate(date) {
                        //disabledDate 文档上：设置禁用状态，参数为当前日期，要求返回 Boolean
                        return date.getTime() < Date.now() - 24 * 60 * 60 * 1000;
                    }
                },
                // id:'',
                lastPayTime: '',
                addColumns: [],
                addFeeDialogVisible: false,
                loading: false,
                right: 'right',
                remark: '',
                imgUrl: '',
                labelPosition: 'right',
                flag: false,
                visible: false,
                changeCurVisible: false,
                isChecked: true,
                ifDis: false,
                allKeys: [],
                fileList: [],
                ocrWords: [],
                defaultProps: {
                    children: 'children',
                    label: 'title'
                },
                billForm: {
                    billTitle: '',
                    payMan: '',
                    invoiceNo: '00000000',//单票号
                    invoiceRemark: '',
                    ids: [],
                    orderNos: [],
                    cur: '',//更改的币种
                    bankId: '',
                    settleList: [],
                    uploadFileIds: [],
                },
                url: {
                    list: "/shipping/hySettlePay/waitPayApplyList",
                    deleteBatch: '/sys/bdCountry/deleteBatch',
                    checkBill: '/shipping/hySettleReceive/checkReceiveSettleId',
                    curGetBank: '/shipping/hySettleReceive/curGetBank',
                    curGetContactBank: '/shipping/hySettlePay/curGetContactBank',
                    addApply: '/shipping/hySettlePay/payApply',
                    // getSettlesByApplyNo: '/shipping/hySettleReceive/getSettlesByApplyNo',
                    uploadInvoice: '/shipping/hySettlePay/uploadInvoice',
                    delFile: '/shipping/hySettlePay/delFile',
                },
                rules: {
                    payMan: [
                        {required: true, message: '请输入供应商在·', trigger: 'change'},
                    ],
                    invoiceNo: [
                        {required: true, message: '请输入发票号，无则输入八个零', trigger: 'change'},
                    ],
                    cur: [
                        {required: true, message: '请选择币种', trigger: 'change'},
                    ],
                    bankId: [
                        {required: true, message: '请选择银行', trigger: 'change'},
                    ],
                    // fileList: [
                    //     {validator: validateFileList, trigger: 'blur'}
                    // ],


                },
                title: '收款申请',
                currentSettlementId:'',//当前在页面上的结算对象id
                currentSettleIds:[],//当前在页面显示的费用id
                settleIds: [],
                showOnly: true,
                noChoose: [],
                feesAgain: [],
                columns: [
                    // {
                    //     type: 'selection',
                    //     width: 50
                    // },
                    {
                        prop: 'action',
                        label: '操作',
                        width: 70
                    },
                    {
                        prop: 'orderNo',
                        label: '工作单号',
                    },
                    {
                        prop: 'createTime',
                        label: '下单时间',
                    },
                    {
                        prop: 'realAgent',
                        label: '客户',
                    },
                    {
                        prop: 'feename',
                        label: '费用中文',
                    },
                    {
                        prop: 'moneyType',
                        label: '费用币种',
                    },
                    {
                        prop: 'total',
                        label: '费用金额',
                    },
                    {
                        prop: 'salesman',
                        label: '业务员',
                    },
                    {
                        prop: 'operator',
                        label: '操作人员',
                    },
                    {
                        prop: 'doc',
                        label: '客服',
                    },

                ],
                selectionRows: [],
                addSelectRows: [],
                banks: [],
                feeOrderNo:'',//添加费用时的查询条件
                ipagination: {
                    currentPage: 1,
                    pageSize: 50,
                    pageSizes: [10, 20, 50, 100],
                    total: 0
                },
                sumAddFee:'',
            }
        },
        methods: {
            //上传文件到oss
            handleUpload(data) {
                const formData = new FormData();
                formData.append("file", data.file);
                this.ifDis=true;
                postAction(this.url.uploadInvoice, formData).then(res => {
                    // console.log(res)
                    if (res.success) {
                        //上传成功,返回结果赋值给form对象的文件集合
                        this.billForm.uploadFileIds.push(res.result.osOrderFile.id);
                        // console.log(this.billForm.uploadFileIds)
                        this.fileList.push(res.result.osOrderFile);
                        this.ocrWords = res.result.ocrWords
                        this.$message.success('上传成功');
                        this.$refs["billForm"].clearValidate();//重置校验
                        this.ifDis=false;
                    }
                })
            },
          // 进入客户详情

          //进入客服页
          intoDocpage(orderNo) {
            if (orderNo.indexOf("KD")!==-1){
              // 对于路由不在左侧菜单中的选中菜单处理
              setUpRouteUtil(this.$route.path, '/kdorder/Edit');
              this.$router.push("/kdorder/Edit?orderNo=" + orderNo);
            }else {
              // 对于路由不在左侧菜单中的选中菜单处理
              setUpRouteUtil(this.$route.path, '/docpage/Edit');
              this.$router.push("/docpage/Edit?orderNo=" + orderNo);
            }

          },
            changeAmount() {
                let sumPay = 0;
                this.billForm.settleList.forEach(item => sumPay = Number(sumPay) + Number(item.realPay));
                this.billForm.amount = sumPay.toFixed(3);
            },
            toS(v) {
                return String(v)
            },
            delFee(row) {
                // console.log(row)
                this.billForm.settleList.splice(this.billForm.settleList.indexOf(row),1);
                // this.noChoose.push(row)
                // this.feesAgain.splice(this.feesAgain.indexOf(row),1);
                this.currentSettleIds.splice(this.currentSettleIds.indexOf(row.id),1);
                this.doSum(this.billForm.settleList)

            },
            curChangeBank(val) {
                // console.log(val)
                // console.log(this.billForm.settleId)
                this.banks = [];
                this.billForm.bankId = ''
                getAction(this.url.curGetContactBank,{cur:val,contactId:this.billForm.settleId}).then((res)=>{
                    if (res.result.length === 0){
                        this.$message.warning("此供应商在该币种下无相应账号！")

                    }else {
                        // console.log(this.banks)
                        this.banks = res.result
                        this.billForm.bankId = ''
                    }

                })
            },
            addSelectionChange(selection) { // 新增费用多选
                // console.log('1',selection)
                this.addSelectRows = JSON.parse(JSON.stringify(selection));
                // console.log('2',this.addSelectRows)
                this.doAddSum(this.addSelectRows)
            },
            addFee() {
                this.sumAddFee = []
                //获取当前结算对象的相关费用
                // console.log(this.currentSettlementId)
                // console.log(this.currentSettleIds)
                this.feesAgain = [];
                let orderType = this.billForm.settleList[0].orderType
                postAction(this.url.list,{
                    settlementId:this.currentSettlementId,
                    currentSettleIds:this.currentSettleIds,
                    moneyType:this.billForm.cur,
                    orderType:orderType,
                    pageNo:this.ipagination.currentPage,
                    pageSize:this.ipagination.pageSize,
                }).then((res)=>{
                    // console.log(res)
                    if (res.result.records.length > 0){
                        this.feesAgain = res.result.records
                        this.addFeeDialogVisible = true;
                    }else{
                        this.$message.warning("无相同结算对象的费用")
                        this.addFeeDialogVisible = false;
                    }

                    this.ipagination && (this.ipagination.total = res.result.total || res.result.length)
                })

            },
            addSubmit() {
                // this.settleList.concat(this.addSelectRows)
               let str = this.addSelectRows.map(function (e) {
                   return e.id;
               }).join(',');

                for (let i = 0; i < this.addSelectRows.length; i++) {
                    this.billForm.settleList.push(this.addSelectRows[i])
                    this.currentSettleIds.push(this.addSelectRows[i].id)
                }
                // this.noChoose = this.noChoose.filter(val => str.indexOf(val.id)===-1);

                this.addFeeDialogVisible = false;
                this.addSelectRows = []
                this.doSum(this.billForm.settleList)
                // console.log('temp',this.addSelectRows)
                // console.log('nochoose',this.noChoose)
            },
            changeCurSubmit() {
                // console.log(this.billForm)
                // console.log(this.billForm.settleList)
                //TODO 修改原tableData中的币种和银行
                this.billForm.settleList.forEach(data => {
                    data.realMoneyType = this.billForm.cur
                    data.bank = this.billForm.bank
                })


                this.curBankDialogClose()
            },
            handleSubmit() {
                this.$refs.billForm.validate((valid) => {
                    if (valid) {
                        this.changeAmount();
                        postAction(this.url.addApply, this.billForm).then((res) => {
                            // console.log(res)
                            if (res.success === true) {
                                this.visible = false
                                this.fileList = [];
                                this.billForm.uploadFileIds = [];
                                this.$emit('ok')
                            }
                        })
                    }
                })
            },
            handleClose() {
                // this.$emit('update:visible', false)
                // this.$refs.form.resetFields()
                this.visible = false;
                this.$nextTick(()=>{
                    // console.log('开始reset')
                    this.$refs.billForm.resetFields();

                })

            },
            curBankDialogClose() {
                // this.$emit('update:visible', false)
                // this.$refs.form.resetFields()
                this.changeCurVisible = false;

            },
            handleAddFeeDialogClose() {
                // this.$emit('update:visible', false)
                // this.$refs.form.resetFields()
                this.addFeeDialogVisible = false;

            },
            enabled() {
                setTimeout(() => {
                    this.ifDis = false;
                }, 2000)
            },
            edit(selectionRows, arr, columns) {
                this.currentSettlementId = JSON.parse(JSON.stringify(selectionRows[0].settleId));//当前的settlementId
                let feeRows = JSON.parse(JSON.stringify(selectionRows))
                this.currentSettleIds=[]
                feeRows.forEach(fee=>{
                    this.currentSettleIds.push(fee.id)
                })


                // console.log("申请收款的selectionRows", selectionRows)
                this.billForm.settleList = JSON.parse(JSON.stringify(selectionRows))
                this.billForm.payMan = this.billForm.settleList[0].agent
                // this.noChoose = JSON.parse(JSON.stringify(arr));
                this.addColumns = JSON.parse(JSON.stringify(columns));
                this.showOnly = true;
                this.title = '付款申请'
                this.doSum(selectionRows);//计算合计
                this.visible = true
                //删除上次离开页面未清理的文件
                this.removeUnusedFiles();
                this.fileList = [];
                this.billForm.uploadFileIds = [];
                this.$nextTick(()=>{
                    // console.log('开始reset')
                    this.$refs.billForm.resetFields();
                    this.billForm.settleList = JSON.parse(JSON.stringify(selectionRows))
                    this.billForm.payMan = this.billForm.settleList[0].agent
                    this.billForm.settleId = this.billForm.settleList[0].settleId
                    this.billForm.cur = this.billForm.settleList[0].moneyType
                    this.curChangeBank(this.billForm.cur)

                })
            },
            doSum(rows){
                this.sumFee = [];
                let allCur = []
                rows.forEach(row => {
                    allCur.push(row.moneyType)
                })
                let curNum = Array.from(new Set(allCur))
                // console.log('币种->',curNum)
                //遍历每个币种，计算总和后放入sumFee中
                curNum.forEach(cur => {
                    let curRow = rows.filter(row =>{
                        return row.moneyType === cur
                    })
                    // console.log('币种row->',curRow)
                    let total = Number(0);
                    curRow.forEach(i=>{
                        total = total + Number(i.total)
                    })
                    // console.log('总计',total)
                    this.sumFee.push({cur:cur, total:total.toFixed(3)})
                })

            },
            doAddSum(rows){
                this.sumAddFee = [];
                let allCur = []
                rows.forEach(row => {
                    allCur.push(row.moneyType)
                })
                let curNum = Array.from(new Set(allCur))
                // console.log('币种->',curNum)
                //遍历每个币种，计算总和后放入sumFee中
                curNum.forEach(cur => {
                    let curRow = rows.filter(row =>{
                        return row.moneyType === cur
                    })
                    // console.log('币种row->',curRow)
                    let total = Number(0);
                    curRow.forEach(i=>{
                        total = total + Number(i.total)
                    })
                    // console.log('总计',total)
                    this.sumAddFee.push({cur:cur, total:total.toFixed(3)})
                })
            },

            showDetail(row) {
                // console.log('申请对象->', row);
                getAction(this.url.getSettlesByApplyNo, {applyNo: row.applyNo}).then((res) => {
                    // console.log(res)
                    this.billForm.settleList = res.result.settleList;
                    this.billForm.payMan = res.result.payMan;
                    this.billForm.amount = res.result.amount;
                    this.billForm.remark = res.result.remark;


                })
                this.title = '收款申请详情'
                this.showOnly = false;
                this.visible = true
            },
            handleRemove(file) {
                this.fileList.map((item, index) => {
                    if (item.uid == file.uid) {
                        //向服务器发送要删除的文件的路径，删除oss上的文件
                        // console.log(item)
                        postAction(this.url.delFile, {osOrderFileId: item.id, url: item.url}).then((res) => {
                            // console.log(res)
                            //页面的上的文件也要删除
                            this.fileList.splice(index, 1)
                            this.billForm.uploadFileIds.splice(this.billForm.uploadFileIds.indexOf(item.id),1);
                        })

                    }
                })
            },
            removeUnusedFiles() {
                // console.log('删除上次遗留的无用文件',this.fileList)
                this.fileList.forEach((item, index) => {
                    //向服务器发送要删除的文件的路径，删除oss上的文件
                    // console.log(item)
                    postAction(this.url.delFile, {osOrderFileId: item.id, url: item.url}).then((res) => {
                        // console.log(res)
                        //页面的上的文件也要删除
                        this.fileList.splice(index, 1)
                        this.billForm.uploadFileIds.splice(this.billForm.uploadFileIds.indexOf(item.id),1);
                    })
                })
            },
            handlePicPreview(file) {
                // console.log(file)
                // console.log('新窗口打开')
                window.open(file.url,'_blank')
            },
            handleSelectionChange(selection) { // 多选
                // console.log(selection)
            },
            //文件上传之前校验文件合法
            beforeUpload(file) {
                // console.log(file)
                //对文件大小进行限制
                const isLt2M = file.size / 1024 / 1024 < 15
                if (!isLt2M) {
                    this.$message.warning('文件大小不能超过15MB')
                }
                const isFile = file.name.indexOf('.') !== -1;
                if (!isFile) {
                    this.$message.warning('该文件名不合法')
                }
                return isLt2M && isFile;
            },
            handleSizeChange(val) {
                this.ipagination.currentPage = 1
                this.ipagination.pageSize = val
                this.addFeesAgain()
            },
            handleCurrentChange(val) {
                this.ipagination.currentPage = val
                this.addFeesAgain()
            },
            addFeesAgain(){
                let orderNo = JSON.parse(JSON.stringify(this.feeOrderNo));
                if (orderNo.trim()===''){
                    orderNo = null
                }
                let orderType = this.billForm.settleList[0].orderType
                postAction(this.url.list,{
                    orderNo:orderNo,
                    settlementId:this.currentSettlementId,
                    currentSettleIds:this.currentSettleIds,
                    moneyType:this.billForm.cur,
                    pageNo:this.ipagination.currentPage,
                    pageSize:this.ipagination.pageSize,
                    orderType:orderType,
                }).then((res)=>{
                    // console.log(res)
                    if (res.success) {
                        if (res.result) {
                            const {records, total} = res.result
                            this.feesAgain = records || res.result;
                            this.ipagination && (this.ipagination.total = total || res.result.length)
                        } else {
                            this.feesAgain = [];
                            this.ipagination = 0;
                        }

                    }
                    // if (res.result.records.length > 0){
                    //     this.feesAgain = res.result.records
                    //
                    // }else{
                    //     this.$message.warning("无相同结算对象的费用")
                    //
                    // }
                })
            },
            handleQuery() {
                this.loading = true
                let form = {...this.queryForm, ...this.filterForm}
                for (let item in form) {
                    if (typeof form[item] === 'string') {
                        form[item] = form[item].trim()
                        if (form[item] === '') {
                            form[item] = null
                        }
                    }
                }
                const {prop, order} = this.isorter
                // 传入的参数
                const params = {
                    ...form,
                    column: prop,
                    order: order.indexOf('asc') !== -1 ? 'asc' : 'desc',
                }
                //判断 是否要查询startTime

                if (!params.column) {
                    if (this.queryForm.column === 'startTime') {
                        this.$set(params, 'column', 'startTime')
                    } else {
                        this.$set(params, 'column', 'createTime')
                    }
                    //提货中查询
                    if (this.queryForm.column === 'distributionTime') {
                        this.$set(params, 'column', 'distributionTime')
                    }
                    //排序
                    //提货中查询
                    if (this.queryForm.column === 'sort') {
                        this.$set(params, 'column', 'sort')
                    }
                }
                if (this.ipagination) {
                    params.pageNo = this.ipagination.currentPage
                    params.pageSize = this.ipagination.pageSize
                }


                postAction(this.url.list, params).then((res) => {
                    // console.log(res)
                    if (res.success) {
                        if (res.result) {
                            const {records, total} = res.result
                            this.tableData = records || res.result;

                            this.ipagination && (this.ipagination.total = total || res.result.length)
                        } else {

                            this.tableData = [];

                            this.ipagination = 0;
                        }

                    }
                }).finally(() => {
                    this.loading = false
                })
            },
        }
    }
</script>

<style lang="scss" scoped>
    .btn-wrap {
        text-align: right;
        padding: 10px 30px;
    }

    .content {
        height: 620px;
        padding: 6px 0;
        overflow: auto;
    }

</style>

<style>
    .payApplyItem .el-form-item__label{
        color: red;
        display: inherit;
        flex-wrap: nowrap;
    }

    .payApplyRow{
        display: inherit;
        flex-wrap: nowrap;
    }
</style>
