<template>
    <el-dialog
            title="编辑"
            :visible="visible"
            width="30%"
            custom-class="c-custom-dialog"
            append-to-body
            :before-close="handleClose"
            append-to-body
            :close-on-click-modal="false">
        <el-form class="addEditBox" :model="form" :rules="rules" ref="form" label-width="100px" size="medium">
            <el-form-item prop="payType" label="付款方式" :label-width="formLabelWidth">
                <f-select dict="warehouse_receipts_pay_type" v-model="form.payType" :is-need="form.payType"></f-select>
            </el-form-item>

            <el-form-item prop="arriveFee" label="入仓费" :label-width="formLabelWidth">
                <el-input type="number" v-model="form.arriveFee" ></el-input>
            </el-form-item>



            <el-form-item prop="overtimeFee" label="加班费" :label-width="formLabelWidth">
                <el-input type="number" v-model="form.overtimeFee" ></el-input>
            </el-form-item>

            <el-form-item prop="printFee" label="打单费" :label-width="formLabelWidth">
                <el-input type="number" v-model="form.printFee" ></el-input>
            </el-form-item>

            <el-form-item prop="mixgoodsFee" label="混货分货费" :label-width="formLabelWidth">
                <el-input type="number" v-model="form.mixgoodsFee" ></el-input>
            </el-form-item>

        </el-form>
        <span slot="footer">
            <el-button size="medium" @click="handleClose">取消</el-button>
            <el-button type="primary" size="medium" @click="handleSubmit">确定</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import {putAction} from '@/api/manage'

    export default {
        name: 'updateOneWarehouseReceipts',
        props: {
            visible: {
                type: Boolean
            },
            selectedRow:{
                type:Object,
                default:()=>{}
            },
        },
        data() {
            return {
                form: {},
                formLabelWidth:'100px',
                rules: {
                    payType: [
                        {required: true, message: '请选择付款方式', trigger: 'change'},
                    ],
                    overtimeFee: [
                        {required: true, message: '请输入加班费', trigger: 'change'},
                    ],
                    arriveFee: [
                        {required: true, message: '请输入入仓费', trigger: 'change'},
                    ],
                    printFee: [
                        {required: true, message: '请输入打单费', trigger: 'change'},
                    ],
                    mixgoodsFee: [
                        {required: true, message: '请输入混货分货费', trigger: 'change'},
                    ],
                },
                url: {
                    updateOneReceiptsDetail: '/warehouse/receipt/updateOneReceiptsDetail',
                },

            }
        },
        watch: {
            visible(val) {
               if (val && this.selectedRow) {
                   //勾选的订单号进行赋值
                   this.$set(this,'form',JSON.parse(JSON.stringify(this.selectedRow)));
               }
            },
        },
        methods: {
            handleSubmit() {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        putAction(this.url.updateOneReceiptsDetail, this.form).then((res) => {
                            this.$message({
                                type: 'success',
                                message: res.message
                            })
                            this.handleClose();
                            this.$emit('ok');
                        })
                    }
                })
            },
            handleClose() {
                this.$emit('update:visible', false)
                this.$refs.form.resetFields()
            }
        },
        created() {
        }
    }
</script>

<style lang="scss" scoped>
    ::v-deep
    .el-dialog__body {
        padding: 20px 40px 20px 6px;
    }
    .addEditBox {
      max-height: 615px;
      overflow: scroll;
    }
</style>
