<template>
    <div class="main-full-content">
        <el-form ref="form" :model="queryForm" label-width="80px" size="medium">
            <div class="f-p-b-15" style="display:flex;">
              <el-card class="box-card">
                <el-row :gutter="24" class="inputs_row">
                  <el-col :md="4" :sm="24">
                <el-form-item label="订单号">
                  <el-input
                      class="textarea_table"
                      v-model="queryForm.orderNo"
                      type="textarea"
                      @keyup.enter.native="handleSearch"
                  ></el-input>
                </el-form-item>
                </el-col>
                    <el-col :md="4" :sm="24">
                        <el-form-item label="客户名">
                            <el-input v-model="queryForm.customerName" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :md="4" :sm="24">
                        <el-form-item label="材积通知">
                            <el-select v-model="queryForm.isEmail" clearable fliterable>
                                <el-option value="0" label="未通知"></el-option>
                                <el-option value="1" label="已通知"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :md="4" :sm="24">
                        <el-form-item label="是否入仓">
                            <el-select v-model="queryForm.isRealCmb" clearable fliterable>
                                <el-option value="1" label="是"></el-option>
                                <el-option value="0" label="否"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                  <el-col :md="4" :sm="24">
                    <el-form-item label="拼柜仓库">
                      <f-select :dict="'lclPlace'"
                                v-model="queryForm.lclPlace"
                                :isNeed="queryForm.lclPlace"
                      >
                      </f-select>
                    </el-form-item>
                  </el-col>
                  <el-col :md="4" :sm="24">
                    <el-form-item label="开单仓库">
                      <f-select :dict="'delivery_point_kaidan'"
                                v-model="queryForm.suozaiCangkuId"
                                :isNeed="queryForm.suozaiCangkuId"
                      >
                      </f-select>
                    </el-form-item>
                  </el-col>
                  <el-col :md="4" :sm="24">
                    <el-form-item label="业务员">
                      <f-select :dict="'sys_user_id'"
                                v-model="queryForm.salesId"
                                :isNeed="queryForm.salesId"
                      >
                      </f-select>
                    </el-form-item>
                  </el-col>

                  <el-col :md="4" :sm="24">
                    <el-form-item label="客服">
                      <f-select
                          v-model="queryForm.docId"
                          :isNeed="queryForm.docId"
                          :dict="'sys_user_id'">
                      </f-select>
                    </el-form-item>
                  </el-col>
                    <el-col :md="4" :sm="24">
                        <el-form-item label="操作">
                            <f-select
                                    v-model="queryForm.operateId"
                                    :isNeed="queryForm.operateId"
                                    :dict="'sys_user_id'">
                            </f-select>
                        </el-form-item>
                    </el-col>
                  <el-col class="no_margin" :md="6" :sm="24">
                    <el-form-item label="入仓时间" label-width="100px">
                      <div class="f-flex">
                        <el-date-picker
                            class="f-flex-1"
                            v-model="queryForm.rucangTimeStart"
                            type="datetime"
                            size="small"
                            placeholder="开始时间"
                            default-time="00:00:00"
                            value-format="yyyy-MM-dd HH:mm:ss"
                        >
                        </el-date-picker>
                        <span class="f-p-h-2">~</span>
                        <el-date-picker
                            class="f-flex-1"
                            v-model="queryForm.rucangTimeEnd"
                            type="datetime"
                            size="small"
                            placeholder="结束时间"
                            default-time="23:59:59"
                            value-format="yyyy-MM-dd HH:mm:ss"
                        >
                        </el-date-picker>
                      </div>
                    </el-form-item>
                  </el-col>

                  <el-col class="no_margin" :md="4" :sm="24">
                    <el-form-item label="发票是否确认" label-width="100px">
                      <el-select v-model="queryForm.invoiceStatus" clearable filterable>
                        <el-option value="1" label="是"></el-option>
                        <el-option value="0" label="否"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>

<!--                    <el-col class="no_margin" :md="4" :sm="24">-->
<!--                        <el-form-item label="材积是否确认" label-width="100px">-->
<!--                            <el-select v-model="queryForm.isRealCmb" filterable clearable>-->
<!--                                <el-option value="1" label="是"></el-option>-->
<!--                                <el-option value="0" label="否"></el-option>-->
<!--                            </el-select>-->
<!--                        </el-form-item>-->
<!--                    </el-col>-->
                    <el-col class="no_margin" :md="4" :sm="24">
                        <el-form-item label="是否问题件" label-width="100px">
                            <el-select v-model="queryForm.isWarning" filterable clearable>
                                <el-option value="1" label="是"></el-option>
                                <el-option value="0" label="否"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :md="4" :sm="24">
                        <el-form-item label="渠道名称" prop="channelIds" label-width="100px" >
                            <f-select :dict="'discount_list'"  :is-need="queryForm.channelIdList"
                                   :multiple="true"
                                   v-model="queryForm.channelIdList" autocomplete="off"></f-select>
                        </el-form-item>
                    </el-col>

                    <el-col class="no_margin" :md="4" :sm="24">
                        <el-form-item label="计费方式" prop="fjfUnit" >
                            <el-select v-model="queryForm.fjfUnit" clearable filterable>
                                <el-option value="KG" label="KG"></el-option>
                                <el-option value="CBM" label="CBM"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :md="4" :sm="24">
                        <el-form-item label="是否包税" prop="hasTax" label-width="100px">
                            <el-select v-model="queryForm.hasTax" clearable filterable>
                                <el-option value="1" label="是"></el-option>
                                <el-option value="0" label="否"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :md="4" :sm="24">
                        <el-form-item label="超大件" prop="largeInfo" label-width="100px">
                            <f-select
                                    :multiple="true"
                                    v-model="queryForm.largeInfos" :is-need="queryForm.largeInfos" :dict="'volume_large_info'">

                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col class="" :md="4" :sm="24">
                        <el-form-item label="件数异常" prop="countException" label-width="100px">
                            <el-select v-model="queryForm.countException" clearable filterable>
                                <el-option value="1" label="是"></el-option>
                                <el-option value="0" label="否"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col  :md="4" :sm="24">
                        <el-form-item label="是否排柜" prop="isCabinet" label-width="100px">
                            <el-select v-model="queryForm.isCabinet" clearable filterable>
                                <el-option value="1" label="已排柜"></el-option>
                                <el-option value="0" label="未排柜"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <!--                  <el-col  class="no_margin" v-if="showLoadingSearch" :xs="24" :sm="12" :md="12" :lg="4">-->
<!--                    <el-form-item label="清关属性">-->
<!--                      <f-select-->
<!--                          :dict="'qg_prop'"-->
<!--                          v-model="queryForm.qgProp"-->
<!--                          :is-need="queryForm.qgProp"-->
<!--                      ></f-select>-->
<!--                    </el-form-item>-->
<!--                  </el-col>-->
<!--                  <el-col  :xs="24" :sm="12" :md="12" :lg="4" class="no_margin" v-if="showLoadingSearch">-->
<!--                    <el-form-item label="海外仓中转服务">-->
<!--                      <f-select-->
<!--                          :dict="'haiwai_service'"-->
<!--                          v-model="queryForm.haiwaiService"-->
<!--                          :is-need="queryForm.haiwaiService"-->
<!--                      ></f-select>-->
<!--                    </el-form-item>-->
<!--                  </el-col>-->
                  <el-col  :xs="24" :sm="12" :md="12" :lg="4" class="no_margin" v-if="showLoadingSearch">
                    <el-form-item label="锁国外仓租柜租">
                      <f-select
                          :dict="'is_default'"
                          v-model="queryForm.lockCangzu"
                          :is-need="queryForm.lockCangzu"
                      ></f-select>
                    </el-form-item>
                  </el-col>
                  <el-col  :xs="24" :sm="12" :md="12" :lg="4" class="no_margin" v-if="showLoadingSearch">
                    <el-form-item label="锁国外查验">
                      <f-select
                          :dict="'is_default'"
                          v-model="queryForm.lockChayan"
                          :is-need="queryForm.lockChayan"
                      ></f-select>
                    </el-form-item>
                  </el-col>
                  <el-col  :xs="24" :sm="12" :md="12" :lg="4" class="no_margin" v-if="showLoadingSearch">
                    <el-form-item label="三锁服务">
                      <f-select
                          :dict="'is_default'"
                          v-model="queryForm.sanLock"
                          :is-need="queryForm.sanLock"
                      ></f-select>
                    </el-form-item>
                  </el-col>
                  <el-col  :xs="24" :sm="12" :md="12" :lg="4" class="no_margin" v-if="showLoadingSearch">
                    <el-form-item label="地址类型">
                      <f-select
                          :dict="'address_type'"
                          v-model="queryForm.shipmentType"
                          :is-need="queryForm.shipmentType"
                      ></f-select>
                    </el-form-item>
                  </el-col>
                  <el-col  :xs="24" :sm="12" :md="12" :lg="4" class="no_margin" v-if="showLoadingSearch">
                    <el-form-item label="航线名称">
                      <f-select
                          :dict="'fba_solicitation'"
                          v-model="queryForm.solicitation"
                          :is-need="queryForm.solicitation"
                      ></f-select>
                    </el-form-item>
                  </el-col>
                  <el-col v-if="showLoadingSearch" :xs="24" :sm="12" :md="12" :lg="3">
                    <el-form-item label="能否排柜">
                      <el-select v-model="queryForm.isCanArrangeContainer" clearable filterable>
                        <el-option value="1" label="能"></el-option>
                        <el-option value="0" label="否"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="4">
                    <el-button
                        size="medium"
                        type="primary"
                        icon="el-icon-search"
                        @click="handleSearch"
                    >查询</el-button
                    >
                    <el-button
                        size="medium"
                        type="primary"
                        @click="handleReset"
                        icon="reload"
                        style="margin-left: 8px"
                    >重置</el-button
                    >
                    <el-button type="text" mini @click="showLoadingSearch=!showLoadingSearch"><i
                        :class="showLoadingSearch ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"
                        style="margin-right:3px"></i>{{showLoadingSearch ? '收起' :'更多'}}
                    </el-button>
                  </el-col>
                </el-row>
              </el-card>
            </div>
        </el-form>
        <el-card class="box-card">
            <div style="display: flex" class="margin_bottom_10">
              <el-button
                    type="primary"
                    size="medium"
                    @click="handleAdd"
                    :disabled="selectVolumeList.length === 0"
                    v-if="buttonShowList.cjyjtz"
            >材积邮件通知
            </el-button
            >
                <el-upload
                      class="upload-demo"
                      action=""
                      :http-request="importOperation"
                      :show-file-list="false"
                      :before-upload="beforeUpload"
                      accept=".xls,.xlsx"
              >
                  <el-button
                      type="primary"
                      size="medium"
                      style="margin-right: 10px"
                      :disabled="$btnIsDisabled('docpage/VolumeEmailModule','0','导入材积')"
                      v-if="buttonShowList.drcj"
                  >导入材积</el-button>
              </el-upload>
              <el-button
                      type="primary"
                      size="medium"
                      @click="downloadModel(url.download, '材积导入模板')"
              >材积导入模板
              </el-button>
                <el-button
                        type="primary"
                        size="medium"
                        @click="downloadModel(url.downloadKongque, '孔雀仓材积导入模板')"
                >孔雀仓材积导入模板
                </el-button
                >
                <el-upload
                        class="upload-demo"
                        action=""
                        :http-request="importKongqueOperation"
                        :show-file-list="false"
                        :before-upload="beforeUpload"
                        accept=".xls,.xlsx"
                >
                    <el-button
                        type="primary"
                        size="medium"
                        style="margin-right: 10px"
                        v-if="buttonShowList.kqcdrcj"
                    >东莞孔雀仓导入材积</el-button>
                </el-upload>
              <el-button
                      type="primary"
                      size="medium"
                      icon="el-icon-download"
                      @click="handleExportXls"
              >导出
              </el-button
              >
              <el-button
                  size="medium"
                  type="primary"
                  :disabled="selectVolumeList.length === 0"
                  @click="$intoDocPageBatch(selectedRowOrderNos)"
                  v-if="buttonShowList.pldkdd"
              >批量打开订单
              </el-button>
            </div>
            <div class="c-alert f-m-b-10">
                已选择 <span class="stress">{{ this.selectedRowKeys.length }}</span> 项
                <span
                        class="stress f-m-l-12 f-cursor-pointer"
                        @click="handleClearSelection"
                >清空</span
                >
            </div>
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="66vh"
                    :isRecordTableScroll="true"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange"
                     :show-operation="true"
            >
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'action'">
                        <el-button type="text" @click="handleEdit(scope.row)"
                        >编辑
                        </el-button
                        >
                        <el-button type="text" @click="handDel(scope.row)">删除</el-button>
                    </template>
                    <template v-else-if="scope.column.property === 'transportStatus'">
                        <span>{{ parseDict(scope.row.transportStatus, "transportStatus") }}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'isEmail'">
            <span style="color: red" v-if="scope.row.isEmail === '1'"
            >已通知</span
            >
                        <span v-else>未通知</span>
                    </template>
                    <template v-else-if="scope.column.property === 'orderNo'">
<!--                        <a-->
<!--                                style="color: #00a0e9"-->
<!--                                @click.prevent="$intoDocPage(scope.row.orderNo)"-->
<!--                        >{{ scope.row.orderNo }}-->
<!--&lt;!&ndash;                            <span style="color:#00a0e9 " v-if="Number(scope.row.actualTotalVolume)===0">（未入仓）</span>&ndash;&gt;-->
<!--&lt;!&ndash;                            <span style="color:red " v-if="Number(scope.row.actualTotalVolume)>0">（已入仓）</span>&ndash;&gt;-->
<!--                        </a>-->
                      <span  v-if="Number(scope.row.isWarning) === 0 || scope.row.isWarning == null ||scope.row.isWarning == '' || Number(scope.row.isWarning) === 2 ">
                        <a
                            :style="{ color: scope.row.isError === 1 ? 'red' : '#00a0e9' }"
                            @click.prevent="$intoDocPage(scope.row.orderNo)"
                        >{{ scope.row.orderNo }}
                          <!--                            <span style="color:#00a0e9 " v-if="Number(scope.row.actualTotalVolume)===0">（未入仓）</span>-->
                          <!--                            <span style="color:red " v-if="Number(scope.row.actualTotalVolume)>0">（已入仓）</span>-->
                        </a>
                         <el-tooltip class="item" effect="dark" content="复制材积" placement="top-start">
                                <img
          src="@/assets/copy.png"
          alt=""
          style="margin-left: 10px" @click="copyInfo(scope.row)"
        />
    </el-tooltip>

                      </span>
                      <span  v-if="Number(scope.row.isWarning) === 1">
                        <a
                            style="color: red"
                            @click.prevent="$intoDocPage(scope.row.orderNo)"
                        >{{ scope.row.orderNo }}
                          <!--                            <span style="color:#00a0e9 " v-if="Number(scope.row.actualTotalVolume)===0">（未入仓）</span>-->
                          <!--                            <span style="color:red " v-if="Number(scope.row.actualTotalVolume)>0">（已入仓）</span>-->
                        </a>
                             <img
          src="@/assets/copy.png"
          alt=""
          style="margin-left: 10px" @click="copyInfo(scope.row)"
        />
                      </span>
                    </template>
                    <template v-else-if="scope.column.property === 'largeInfos'">
                        <div style="display: flex;flex-direction: column">
                            <span v-for="item in scope.row.largeInfos">{{item}}</span>
                        </div>
                    </template>
                    <template v-else-if="scope.column.property === 'isRealCmb'">
                        <span style="color:#00a0e9 " v-if="Number(scope.row.actualTotalVolume)===0">(未入仓)</span>
                        <span style="color:red " v-if="Number(scope.row.actualTotalVolume)>0">(已入仓)</span>
                    </template>
                  <template v-else-if="scope.column.property === 'hasTax'">
                    <span style="color:#00a0e9 " v-if="Number(scope.row.hasTax)===0">未包税</span>
                    <span style="color:red " v-if="Number(scope.row.hasTax)===1">已包税</span>
                  </template>
                    <template v-else-if="scope.column.property === 'customerName'">
                        <a type="text" @click.prevent="$intoCustomerEditPage(scope.row.customerId)" style="color:#57a3f3;">{{scope.row.customerName}}</a>
                    </template>
                  <template v-else-if="scope.column.property === 'number'">
                    <span style="color: red;font-weight:bolder" v-if="scope.row.number !== scope.row.estimcatedTotalNumber ">{{scope.row.number}}</span>
                    <span v-else>{{scope.row.number}}</span>
                  </template>
                  <template v-else-if="scope.column.property === 'actualTotalNumber'">
                    <span style="color: red;font-weight:bolder" v-if="scope.row.actualTotalNumber !== scope.row.estimcatedTotalNumber">{{scope.row.actualTotalNumber}}</span>
                    <span v-else style="color: #00A329;font-weight:bolder">{{scope.row.actualTotalNumber}}</span>
                  </template>
                  <template v-else-if="scope.column.property === 'tijizhong'">
                    <span>{{scope.row.cbmKg ? (Number(scope.row.cbmKg)).toFixed(3) : 0}}</span>
                  </template>
                  <template v-else-if="scope.column.property === 'actualTotalVolume'">
                    <!-- <span style="color: red;font-weight:bolder" v-if="Number(scope.row.actualTotalVolume) !== Number(scope.row.estimcatedTotalVolume) "
                    >{{scope.row.actualTotalVolume}}</span>
                    <span v-else style="color: #00A329;font-weight:bolder">{{scope.row.actualTotalVolume}}</span> -->
                    <!-- （订单预报体积/订单实际体积 - 1）* 100%，结果的绝对值大于10%，标红 -->
                    <el-popover placement="top" trigger="hover">
                      <span>{{ '（订单预报体积/订单实际体积 - 1）* 100%: ' + ((Number(scope.row.estimcatedTotalVolume) / Number(scope.row.actualTotalVolume) - 1) * 100 ).toFixed(3)+ '%' }}</span>
                      <div slot="reference">
                        <span style="color: red;font-weight:bolder" v-if=" Math.abs((Number(scope.row.estimcatedTotalVolume) / Number(scope.row.actualTotalVolume) - 1)) >= 0.1  "
                        >{{scope.row.actualTotalVolume}}</span>
                        <span v-else style="color: #00A329;font-weight:bolder">{{scope.row.actualTotalVolume}}</span>
                      </div>
                    </el-popover>

                  </template>
                  <template v-else-if="scope.column.property === 'realKg'">
                    <!-- （订单预报重量/订单过磅重 - 1）* 100%，结果的绝对值大于3%，标红 -->
                    <el-popover placement="top" trigger="hover">
                      <span>{{ '（订单预报重量/订单过磅重 - 1）* 100%: ' + ((Number(scope.row.kg) / Number(scope.row.realKg) - 1) * 100).toFixed(3) + '%' }}</span>
                      <div slot="reference">
                        <span style="color: red;font-weight:bolder" v-if="Math.abs((Number(scope.row.kg) / Number(scope.row.realKg) - 1)) >= 0.03  "
                        >{{scope.row.realKg}}</span>
                        <span v-else>{{scope.row.realKg}}</span>
                      </div>
                    </el-popover>

                  </template>
                  <template v-else-if="scope.column.property === 'shipmentId'">
                    <el-popover
                        placement="top-start"
                        width="300"
                        trigger="hover"

                    >
                      <div>{{ scope.row.shipmentId }}</div>
                      <span slot="reference" v-if="scope.row.shipmentId && scope.row.shipmentId.length > 40">{{scope.row.shipmentId.substr(0, 40) + "..."}}</span>
                      <span slot="reference" v-else>{{scope.row.shipmentId}}</span>
                    </el-popover>
                  </template>
                    <template v-else-if="scope.column.property === 'declareType'">
            <span>{{parseDict(scope.row.declareType,'os_declare_type')}}</span>
          </template>
           <template v-else-if="scope.column.property === 'lclPlace'">
            <span>{{parseDict(scope.row.lclPlace,'lclPlace')}}</span>
          </template>

                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                >
                </el-pagination>
            </el-row>
        </el-card>
    </div>
</template>

<script>
    import {postAction,} from "../../api/manage";
    import QueryMixins from "../../mixins/QueryMixins";
    import Tempcclate from "../test/Tempcclate";
    import { setUpRouteUtil } from '@/utils/util';

    export default {
        name: "FileModule",
        mixins: [QueryMixins],
        components: {
            Tempcclate,
        },
        data() {
            return {
              showLoadingSearch: false,
                dictCodes: ["os_order_filelist", "isEmail","transportStatus",'lclPlace', 'os_declare_type'],
                ids: [],
                dialogFormVisible: false,
                form: {
                    url: "",
                    size: "",
                    fileType: "",
                    type: "",
                    id: "",
                    cusId: "",
                    name: "",
                    ossId: "",
                },
                btnDisable: false,
                //表单校验
                rules: {
                    name: [{required: true, message: "请输入文件名", trigger: "blur"}],
                    type: [
                        {required: true, message: "请选择文件类型", trigger: "change"},
                    ],
                },
                fileList: [],
                row: {},
                visible: false,
                assignVisible: false,
                msgVisible: false,
                emailVisible: false,
                followTitle: "",
                assignTitle: "",
                columns: [
                    {
                        type: "selection",
                        width: 50,
                    },
                    {
                        prop: "customerName",
                        label: "客户名",
                        // sortable: "custom",
                        // minWidth: 200,
                        // over: true,

                    },
                     {
          prop: "withinOrderRemark",
          label: "客户单号",
          width: 130,
        },
                    {
                        prop: "orderNo",
                        label: "订单号",
                        // sortable: "custom",
                        minWidth: 110,
                        // over: true,

                    },
                    {
                        prop: "isRealCmb",
                        label: "是否入仓",
                        // sortable: "custom",
                        minWidth: 50,

                        // over: true,
                    },
                    {
                        prop: "largeInfos",
                        label: "超大件",

                        minWidth: 80,
                        over: true,
                        headerType:'remark',
                        // detail:'渠道名称是海+快（普通）/海加快（美森/以星特快）/海+快（盐田特快）的跟踪单号如果有:<br>' +
                        //     '1.如果是UPS，则长度大于120cm或者宽度大于76cm或者（宽度+高度）大于330cm的提醒UPS超大件<br>' +
                        // '2.如果是DPD，则长度大于100cm或者宽度大于等于80cm或者（宽度+高度）大于300cm的提醒DPD超大件<br>' +
                        //     '3.如果是DHL，则长度大于120cm或者宽度小于等于60cm并且高度小于等于60cm提醒DHL超大件<br>',
                        detail:'订单的材积录入的时候 超大件选了啥就显示啥'
                    },
                    {
                        prop: "isEmail",
                        label: "材积通知",
                        // sortable: "custom",
                        minWidth: 50,
                        // over: true,
                    },
                    {
                        prop: "warehouseInTime",
                        label: "进仓时间",
                        // sortable: "custom",
                        minWidth: 70,
                        // over: true,
                    },
                    {
                        prop: "estimcatedTotalNumber",
                        label: "预计件数",
                        minWidth: 50,
                        // sortable: "custom",
                        // minWidth: 140,
                        // over: true,
                    },
                    {
                        prop: "kg",
                        label: "预计重量",
                        minWidth: 50,
                        // over: true,
                    },
                    {
                        prop: "estimcatedTotalVolume",
                        label: "预计体积",
                        // sortable: "custom",
                        minWidth: 50,
                        // over: true,
                    },
                    {
                        prop: "actualTotalNumber",
                        label: "实际件数",
                        // sortable: "custom",
                        minWidth: 50,
                        // over: true,
                    },
                    {
                        prop: "realKg",
                        label: "过磅重",
                        minWidth: 50,
                        // over: true,
                    },
                    {
                        prop: "actualTotalVolume",
                        label: "实际体积",
                        // sortable: "custom",
                        minWidth: 50,
                        // over: true,
                    },
                  {
                    prop: "tijizhong",
                    label: "体积重",
                    // sortable: "custom",
                        minWidth: 50,
                    // over: true,
                  },
                  {
                    prop: "countFeeNum",
                    label: "计费数量",
                        minWidth: 50,
                  },
                    {
                        prop: "number",
                        label: "总件数/卡板",
                        minWidth: 50,
                        // over: true,
                    },
                    {
                      prop: "allVolumeLocation",
                      label: "货物所在仓",
                    },
                      {
          prop: "lclPlace",
          label: "拼柜仓",
        },
         {
          prop: "declareType",
          label: "报关方式",
        },
                    {
                        prop: "channelName",
                        label: "渠道名称",
                        // sortable: "custom",
                        // minWidth: 140,
                        // over: true,
                    },
                    {
                      prop: "hasTax",
                      label: "是否包税",
                      // sortable: "custom",
                      // minWidth: 140,
                      // over: true,
                    },
                    {
                        prop: "storehouseRemark",
                        label: "仓库备注",
                        // sortable: "custom",
                        // minWidth: 1000,
                        // over: true,
                    },
                    {
                        prop: "fbaCode",
                        label: "fbaCode",

                        // sortable: "custom",
                        // minWidth: 140,
                        // over: true,
                    },
                  {
                    prop: "shipmentId",
                    label: "FBA号",

                    // sortable: "custom",
                    // minWidth: 140,
                    // over: true,
                  },
                    {
                        prop: "docName",
                        label: "客服",
                        // sortable: "custom",
                        // minWidth: 140,
                        // over: true,
                    },
                    {
                        prop: "operator",
                        label: "操作",
                        // sortable: "custom",
                        // minWidth: 140,
                        // over: true,
                    },
                    {
                        prop: "salesName",
                        label: "业务员",
                        // sortable: "custom",
                        // minWidth: 140,
                        // over: true,
                    },

                    {
                        prop: "createBy",
                        label: "材积录入人",
                        // sortable: "custom",
                        // minWidth: 140,
                        // over: true,
                    },

                    {
                        prop: "suozaiCangkuName",
                        label: "开单仓库",
                        // sortable: "custom",
                        // minWidth: 140,
                        // over: true,
                    },
                    {
                        prop: "remark",
                        label: "备注",
                        // sortable: "custom",
                        // minWidth: 200,
                        // minWidth: 200,
                        // over: true,
                    },
                ],
                url: {
                    list: "/interface/ttOrder/getFbaWarehouseOrderReceiveIPage",
                    exportXlsUrl: "/file/export/excel/downloadCaijiVoExcel",
                    sendCaijiEmail: "/interface/ttOrder/sendCaijiEmail",
                    import:'/file/read/excel/importCaiji',
                    importKongque:'/file/read/excel/importCaijiKongque',
                    download:'/file/read/excel/downloadCaijiExcel',
                    downloadKongque:'/file/read/excel/downloadCaijiKongqueExcel',
                },
                isUsePost:true,
                declareType: "",
                declareType_dictText: "",
                removeValue: "",
                selectVolumeList: [],
                channelIdStr: "",
            };
        },
        created() {
            // this.$set(this.queryForm,'isWarning','0');
            this.$set(this.queryForm,'isRealCmb','1');
        },
        mounted() {
        },
        methods: {
            importKongqueOperation(data) {
                console.log("上传文件", data);
                const formData = new FormData();
                formData.append("file", data.file);
                postAction(this.url.importKongque, formData)
                    .then((res) => {
                        console.log("产品返回数据", res);
                        if (res.success) {
                            //如果第一行的中文名为空则清空
                            // let list = [];
                            // for (var i=0;i<this.form.osOrderGoodsList.length;i++){
                            //   if(this.form.osOrderGoodsList[i].name){
                            //     list.push(this.form.osOrderGoodsList[i]);
                            //   }
                            // }
                            // this.form.osOrderGoodsList = list;
                            // //上传成功,返回结果赋值给form对象的文件集合
                            // this.form.osOrderGoodsList = this.form.osOrderGoodsList.concat(res.result);
                            // this.proMap = this.listToMap(this.form.airOrderGoodsList, 'name')
                            this.$message.success(res.message);
                            this.handleQuery();
                        }else {
                            this.$message.error(res.message);
                            this.handleQuery();
                        }
                    })
            },

            //导入操作绑定
            importOperation(data) {
                console.log("上传文件", data);
                const formData = new FormData();
                formData.append("file", data.file);
                postAction(this.url.import, formData)
                    .then((res) => {
                        console.log("产品返回数据", res);
                        if (res.success) {
                            //如果第一行的中文名为空则清空
                            // let list = [];
                            // for (var i=0;i<this.form.osOrderGoodsList.length;i++){
                            //   if(this.form.osOrderGoodsList[i].name){
                            //     list.push(this.form.osOrderGoodsList[i]);
                            //   }
                            // }
                            // this.form.osOrderGoodsList = list;
                            // //上传成功,返回结果赋值给form对象的文件集合
                            // this.form.osOrderGoodsList = this.form.osOrderGoodsList.concat(res.result);
                            // this.proMap = this.listToMap(this.form.airOrderGoodsList, 'name')
                            this.$message.success(res.message);
                            this.handleQuery();
                        }else {
                          this.$message.error(res.message);
                          this.handleQuery();
                        }
                    })
            },
            beforeUpload(file) {
                //对文件大小进行限制
                const isLt2M = file.size / 1024 / 1024 < 15;
                if (!isLt2M) {
                    this.$message.warning("文件大小不能超过15MB");
                }
                const isFile = file.name.indexOf(".") !== -1;
                if (!isFile) {
                    this.$message.warning("该文件名不合法");
                }
                return isLt2M && isFile;
            },

            handleSelectionChange(selection) {
                // 多选
                let arr = [];
                this.selectVolumeList = [];
                this.selectedRowOrderNos=[]
                for (let i = 0; i < selection.length; i++) {
                    // if (selection[i].isEmail !== "1" && selection[i].orderNo) {
                        arr.push(selection[i]["id"]);
                        this.selectVolumeList.push(selection[i]);
                        this.selectedRowOrderNos.push(selection[i]['orderNo'])
                    // }
                }
                this.selectVolumeList = selection
                this.selectedRowKeys = [...arr];
                console.log("this.selection", this.selectVolumeList);
            },
            checkboxClassName({row, column, rowIndex, columnIndex}) {
                if (row.isEmail === "1" || !row.orderNo) {
                    return "tb_cell";
                }
            },
            appendstr(){
                let chanelS = this.channelIds;
                for (let i = 0; i < chanelS.length; i++) {
                    if (chanelS[i] == "0") {
                        this.queryForm.channelIdStr = "";
                        return;
                    }

                }
                this.channelIdStr = this.channelIds.join(",");
                this.queryForm.channelIdStr = this.channelIdStr
            },
            handleReset() {
                this.queryForm = {};
                this.channelIds = [];
                this.ipagination.currentPage = 1
                this.handleQuery()
            },
            handleAdd() {
                postAction(this.url.sendCaijiEmail, this.selectVolumeList).then((res) => {
                    this.$message.success(res.message);
                    this.handleQuery();
                });
            },
             copyInfo(row) {
              let content = `客户单号：${row.withinOrderRemark?row.withinOrderRemark:''}  订单号：${row.orderNo}  实件：${row.actualTotalNumber}  过磅重：${row.realKg}  实体：${row.actualTotalVolume} 计费数量：${row.countFeeNum}  派送信息: ${row.countryRuleAddress}`
              let _this = this;
      this.$copyText(content)
        .then(() => {
          _this.$message({
            message: "复制成功，可以使用ctrl+v快捷键粘贴",
            type: "success",
          });
        })
        .catch((e) => {
          _this.$message.error("复制失败");
        });
            }
        },
      computed : {
        buttonShowList() {
          return {
            'cjyjtz':this.$btnIsShow('docpage/VolumeEmailModule','0','材积邮件通知'),
            'drcj':this.$btnIsShow('docpage/VolumeEmailModule','0','导入材积'),
            'kqcdrcj':this.$btnIsShow('docpage/VolumeEmailModule','0','孔雀仓导入材积'),
            'pldkdd':this.$btnIsShow('docpage/VolumeEmailModule','0','批量打开订单'),
          }
        }
      }
    };
</script>

<style lang="scss" scoped>
.upload-demo {
  margin-left: 10px;
}
.margin_bottom_10{
  margin-bottom: 10px;
}
</style>
