<template>
    <el-dialog
            title="出库详情"
            :visible="visible"
            width="50%"
            custom-class="c-custom-dialog"
            append-to-body
            :before-close="handleClose"
            append-to-body
            :close-on-click-modal="false">
        <!--:disabled="selectedRowKeys.length === 0"-->
<!--        <el-button-->
<!--                type="primary"-->
<!--                size="medium"-->
<!--                @click="handleAddOrder"-->
<!--                class="margin_right_10"-->

<!--                v-if="$btnIsShow('warehouse2.0/NormalOutWarehouse','0','加货')">加货</el-button>-->
        <f-table
                v-loading="loading"
                ref="GTable"
                border
                size="medium"
                row-key="id"
                class="c-th-has-bgcolor"
                :columns="columns"
                :data="tableData"
                @selection-change="handleSelectionChange"
                @sort-change="handleTableChange"
                :cell-style="{ 'text-align': 'center' }"
                tableHeight="73vh"
        >
            <template v-slot="scope">
                                    <template v-if="scope.column.property === 'action'">
                                        <el-button type="text" @click="hanleKnee(scope.$index)"
                                                   v-if="buttonShowList.tih"
                                        >踢货</el-button>
                                    </template>

                <template v-else-if="scope.column.property === 'orderNo'">
                    <a style="color: #00a0e9" @click.prevent="seeDetail(scope.row)">{{ scope.row.orderNo }}</a>
                </template>


            </template>
        </f-table>
        <el-row class="f-text-right f-p-v-8">
            <el-pagination
                    v-bind="ipagination"
                    layout="total, sizes, prev, pager, next, jumper"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange">
            </el-pagination>
        </el-row>
        <span slot="footer">
            <el-button size="medium" @click="handleClose">取消</el-button>
            <el-button type="primary" size="medium" @click="handleSubmit">确认</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import {postAction,getAction} from '@/api/manage'
    import QueryMixins from "@/mixins/QueryMixins";

    export default {
        name: 'warehouseOutOrderListModule',
        mixins: [QueryMixins],
        props: {
            visible: {
                type: Boolean
            },
            outId:{
                type:String,
                default:''
            },
        },
        data() {
            return {
                disableMixinMounted:true,
                columns: [
                    {
                        type: "selection",
                    },

                    {
                        prop: "orderNo",
                        label: "订单号",
                    },
                    {
                        prop: "channel",
                        label: "渠道名称",
                    },
                    {
                        prop: "ctnStr",
                        label: "件重体",
                    },

                    // {
                    //     prop: "action",
                    //     label: "操作",
                    // },

                ],
                form: {},
                formLabelWidth:'100px',
                url: {
                    //加货
                    add: '/warehouse/receipt/addReceiptOrder',
                    queryOrderListByWarehouseOutId: '/warehouse/stock/queryOrderListByWarehouseOutId',
                },

            }
        },
        watch: {
            visible(val) {
                if (val && this.outId) {
                    //勾选的订单号进行赋值
                    this.$set(this.form,'outId',this.outId);
                    getAction(this.url.queryOrderListByWarehouseOutId,{outId:this.outId})
                    .then(res=>{
                        this.tableData = res.result;
                    })
                }
            },
        },
        methods: {
            seeDetail({orderNo}){
                this.handleClose();
                this.$intoDocPage(orderNo);

            },
            handleAddOrder(){
                //加货
            },
            hanleKnee(index){
                this.tableData.splice(index,1);
            },
            handleSubmit() {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        postAction(this.url.add, this.form).then((res) => {
                            this.$message({
                                type: 'success',
                                message: res.message
                            })
                            this.handleClose();
                            this.$emit('ok');
                        })
                    }
                })
            },
            handleClose() {
                this.$emit('update:visible', false)
            }
        },
        created() {
        },
      computed:{
        buttonShowList() {
          return {
            'tih':this.$btnIsShow('warehouse2.0/NormalOutWarehouse','1','踢货'),

          }
        }
      }
    }
</script>

<style lang="scss" scoped>
    ::v-deep
    .el-dialog__body {
        padding: 20px 40px 20px 6px;
    }
    .addEditBox {
      max-height: 615px;
      overflow: scroll;
    }
</style>
