<!--海运报价-已发布-->
<template>
    <div class="main-full-content">
        <el-form  ref="form" :model="queryForm" label-width="120px" size="medium">
            <el-card class="box-card">
                <el-row>
                    <el-col :xs="24" :sm="12" :md="12" :lg="5">
                        <el-form-item label="船公司">
                            <f-select
                                    v-model="queryForm.shippingCompanyId"
                                    :is-need="queryForm.shippingCompanyId"
                                    :dict="'ship_company'"></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="5">
                        <el-form-item label="起运港" :label-width="formLabelWidth">

                            <f-select
                                    v-model="queryForm.startPortId"
                                    :is-need="queryForm.startPortId"
                                    :dict="'bd_port'"
                            >

                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="5">
                        <el-form-item label="目的港" :label-width="formLabelWidth">

                            <f-select
                                    v-model="queryForm.endPortId"
                                    :is-need="queryForm.endPortId"
                                    :dict="'bd_port'"
                            >

                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="5">
                        <el-form-item label="供应商名称">
                            <el-input v-model="queryForm.supplierChinaseName" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label-width="30px">
                            <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
                            <el-button plain @click="handleReset">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>
        </el-form>
        <el-card class="box-card last_card">
            <div class="f-p-b-15"></div>
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="fId"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="69vh"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange">
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'action'">
                        <el-button type="text" @click="backOff(scope.row)"

                                   v-if="buttonShowList.back"
                        >退回</el-button>  <!--已经被销售发布无法退回-->
                    </template>
                    <template v-else-if="scope.column.property === 'startTime'">
                        <span>{{formatterTime(scope.row.startTime)}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'endTime'">
                        <span>{{formatterTime(scope.row.endTime)}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'createBy'">
                        <span>{{parseDict(scope.row.createBy,'all_user_id')}}</span>
                    </template>
                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange">
                </el-pagination>
            </el-row>
        </el-card>
    </div>
</template>

<script>
    import {postAction} from '@/api/manage'
    import QueryMixins from "../../mixins/QueryMixins";
    import {getBdPortList} from "../../api/manage";

    export default {
        name: 'Potential',
        mixins: [QueryMixins],
        data() {
            return {
                dictCodes: ['all_user_id'],
                emailList: [],
                ids: [],
                row: {},
                form: {},
                formLabelWidth: '100px',
                columns: [
                    {
                        prop: 'action',
                        label: '操作',
                        width: 60

                    },
                    {
                        prop: 'shippingCompany',
                        label: '船公司',
                        sortable: 'custom',
                        width: 120
                    },
                    {
                        prop: 'supplierChinaseName',
                        label: '供应商名称',
                        sortable: 'custom',
                        width: 120
                    },
                    {
                        prop: 'startPort',
                        label: '起运港',
                        sortable: 'custom',
                        width: 110
                    },
                    {
                        prop: 'endPort',
                        label: '目的港',
                        sortable: 'custom',
                        width: 110
                    },
                    {
                        prop: 'transferPort',
                        label: '中转港',
                        sortable: 'custom',
                        width: 110
                    },
                    {
                        prop: 'voyage',
                        label: '航程(天)',
                        sortable: 'custom',
                        width: 120
                    },
                    {
                        prop: 'twentyGp',
                        label: '20GP',
                        sortable: 'custom',
                        width: 130
                    },
                    {
                        prop: 'fortyGp',
                        label: '40GP',
                        sortable: 'custom',
                        width: 100
                    },
                    {
                        prop: 'fortyHq',
                        label: '40HQ',
                        sortable: 'custom',
                        width: 100
                    },
                    {
                        prop: 'fortyFiveHq',
                        label: '45HQ',
                        sortable: 'custom',
                        width: 100

                    },
                    {
                        prop: 'startTime',
                        label: '生效日期',
                        sortable: 'custom',
                        width: 110

                    },
                    {
                        prop: 'endTime',
                        label: '截止日期',
                        sortable: 'custom',
                        width: 110
                    },
                    {
                        prop: 'remark',
                        label: '备注提醒',
                        sortable: 'custom',
                        width: 110

                    },
                    {
                        prop: 'createBy',
                        label: '发布人',
                        sortable: 'custom',
                        width: 110

                    },
                ],
                url: {
                    list: '/sys/channelPrice/channelPage',
                    backOff: '/sys/channelPrice/backOff',
                },
            }
        },
        methods: {
            initBdPortList() {
                if (this.allBdportList.length <= 0) {
                    this.allBdportList = [];
                    getBdPortList().then(res => {
                        if (res.success) {
                            for (var i = 0; i < res.result.length; i++) {
                                var obj = {};
                                obj.value = res.result[i].cnName;
                                obj.label = res.result[i].code + '-' + res.result[i].cnName;
                                this.allBdportList.push(obj);
                            }
                            this.bdPortList = this.allBdportList.slice(0, 100);
                        }
                    }).catch(err => {
                        this.$message.error(err)
                    })
                }

            },
            formatterTime(data) {
                return data.substring(0, 10)
            },
            init() {
                this.queryForm.channelType = "3";
                //初始化港口基础数据

            },
            handleSelectionChange(selection) { // 多选
                let arr = []
                this.emailList = [];
                for (let i = 0; i < selection.length; i++) {
                    arr.push(selection[i]['id'])
                    this.emailList.push(selection[i]['email']);
                }
                this.selectionRows = selection
                this.selectedRowKeys = [...arr]
            },
            handleReset() {
                this.queryForm = {};
                this.filterForm = {};
                this.queryForm.channelType = "3";
                this.ipagination.currentPage = 1
                this.handleQuery()
            },
            //退回
            backOff(row) {
                this.$confirm(`确定退回`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'info',
                    closeOnClickModal: false,
                }).then(() => {
                    postAction(this.url.backOff + "?id=" + row.id).then(res => {
                        this.$message({
                            type: 'success',
                            message: res.message
                        })
                        this.dialogFormVisible = false;
                        this.handleQuery()
                    })
                }).catch(() => {

                })
            },
        }, created() {
            this.init();
        },
      computed:{
          buttonShowList() {
            return {
              'back':this.$btnIsShow('channel/OceanShippingRelease','1','退回'),
            }
          }
      }
    }
</script>

<style lang="scss" scoped>

</style>
