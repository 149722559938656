<template>
  <!-- 新增编辑活动配置弹窗 -->
  <div>
    <el-dialog :title="editType + '规则组合'" :visible.sync="visible" :close-on-click-modal="false"
               :destroy-on-close="destroyOnClose" :before-close="handleClose" append-to-body>
      <el-form :model="form" :rules="rules" ref="form" label-width="auto" style="height: 100%;">
        <el-card class="box-card">

          <el-row :gutter="20" class="inputs_row">
            <el-col :span="8">
              <el-form-item class="flex_form_item" prop="ruleName" label="组合名称">
                <el-input v-model="form.ruleName" maxlength="50" show-word-limit>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item class="flex_form_item" prop="isShow" label="是否启用">
                <f-select v-model="form.isShow" :is-need="form.isShow" :dict="'unit_whether'">
                </f-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-card>
        <el-card class="box-card">
          <div slot="header">
            <span>设置规则</span>
            <el-button type="primary" class="f-m-l-10" @click="handleAdd">新增一行</el-button>
          </div>
          <sales-per-field-config-table
            :select-base-vo="selectBaseVo"
            :select-list="selectForm.valueList"
            @validate="handleValidate"
            ref="childTable"
          ></sales-per-field-config-table>
        </el-card>

      </el-form>

      <template #footer>
        <el-button size="small" @click="handleClose">取消</el-button>
        <el-button type="primary" size="small" @click="handleSubmit">
          确定
        </el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import {postAction} from '@/api/manage';
import {getAction} from '@/api/manage';
import {validateUnitValue4 , validateOuhao} from "@/utils/validator";
import salesPerFieldConfigTable from "@/views/salePerConfig/modules/salesPerFieldConfigTable.vue";

export default {
  name: 'salesPerConfigFirstDialog',
  components: {salesPerFieldConfigTable},
  props: {

    visible: {
      type: Boolean,
    },
    // 当关闭 Dialog 时，销毁其中的元素
    destroyOnClose: {
      type: Boolean,
      default: true,
    },

    selectForm: {
      type: Object,
      default: () => {
      }
    },
    selectBaseVo: {
      type: Object,
      default: () => {
      }
    },
    selectList: {
      type: Array,
      default: () => []
    },
    editType:{
      type : String,
    }
  },
  data() {
    return {
      rules: {
        ruleName: [
          {required: true, message: '请输入名称', trigger: "change"},
        ],
        isShow: [
          {required: true, message: '请选择是否启用', trigger: "change"},
        ],
      },
      form: { },
      url: {

        add: '/shipping/sales/salesPerConfigFirst/insertFirstRule',
        edit: '/shipping/sales/salesPerConfigFirst/updateFirstRule',
      },

      tips: '',
    }
  },
  watch: {
    visible(val) {
      if (val && this.selectForm.id) {
        this.form = {
          id : this.selectForm.id,
          ruleName : this.selectForm.ruleName,
          isShow : this.selectForm.isShow
        }
      }else {
        this.form = {};
      }

    },
  },
  created() {
  },
  mounted() {
  },
  computed: {},
  methods: {
    handleAdd() {
      this.$refs.childTable.handleAdd();
    },
    handleDelete(row, index) {
      if (this.form.list.length <= 1) {
        this.$message.warning('列表数据至少保留一项');
        return
      }
      this.form.list.splice(index, 1);
    },

    handleValidate(validTable, list){
      this.$refs.form.validate(valid => {
        if (valid) {
          if (validTable) {
            let params = JSON.parse(JSON.stringify(this.form));
            params.list = list;
            // 复制的时候清空ID
            let url = this.url.add;
            if (this.editType === '复制') {
              delete params.id;
            }else if (this.editType === '编辑') {
              url = this.url.edit;
            }
            postAction(url, params).then((res) => {
              this.$store.commit("setFullscreenLoading", false);
              this.$message.success(res.message);
              this.handleClose();
              this.$emit("ok");
            }).catch(err => {
              this.$store.commit("setFullscreenLoading", false);
            });
          }else {
            console.log("校验不通过")
          }
        }
      })

    },
    handleSubmit() {
      // 校验子表单
      this.$refs.childTable.validateForm();
    },
    handleClose() {
      this.form = {
        list: []
      };
      this.$emit("update:visible", false);
    },
  }
}
</script>
<style lang="scss" scoped>

</style>
