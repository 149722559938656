
<template>
    <div class="main-full-content">
        <el-form class="input_top" ref="form" :model="queryForm" label-width="120px" size="medium" @submit.native.prevent>
            <el-card class="box-card">
                <el-row>
                <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <el-form-item label="请输入公司名称">
                        <el-input v-model="queryForm.company" @keyup.enter.native="handleSearch"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <el-form-item label-width="30px">
                        <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
                        <el-button plain @click="handleReset">重置</el-button>
                    </el-form-item>
                </el-col>
            </el-row>
            </el-card>

        </el-form>
        <el-card class="box-card">
             <div class="f-p-b-15">
                <el-button type="primary" size="medium" icon="el-icon-plus" @click="handleAdd"
                           v-if="buttonShowList.add"
                >新增</el-button>
            </div>
            <f-table
                v-loading="loading"
                ref="GTable"
                border
                size="medium"
                row-key="id"
                class="c-th-has-bgcolor"
                :columns="columns"
                :data="tableData"
                :cell-style="{ 'text-align': 'center' }"
                @selection-change="handleSelectionChange"
                @sort-change="handleTableChange">
            <template v-slot="scope">
                <template v-if="scope.column.property === 'action'">
                    <el-button type="text" @click="handleEdit(scope.row)"
                               v-if="buttonShowList.bj"

                    >编辑</el-button>
                    <el-button type="text" @click="handleDele(scope.row)"
                               v-if="buttonShowList.sc"

                    >删除</el-button>
                </template>
            </template>
        </f-table>
        <el-row class="f-text-right f-p-v-8">
            <el-pagination
                    v-bind="ipagination"
                    layout="total, sizes, prev, pager, next, jumper"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange">
            </el-pagination>
        </el-row>
        </el-card>
        <el-dialog :title="form.id?'编辑海外仓公司':'新增海外仓公司'" :visible.sync="dialogFormVisible" :close-on-click-modal="false" v-if="dialogFormVisible">
            <el-form :model="form" :rules="rules" ref="form">
                <el-form-item label="公司名称" :label-width="formLabelWidth" prop="company">
                    <el-input v-model="form.company" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="排序号码" :label-width="formLabelWidth" prop="sortNum">
                  <el-input v-model="form.sortNum" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="描述" :label-width="formLabelWidth" prop="decription">
                  <el-input v-model="form.decription" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="国家读取规则" :label-width="formLabelWidth" prop="prefix">
                    <el-input v-model="form.prefix" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="medium" @click="dialogFormVisible = false">取 消</el-button>
                <el-button size="medium" type="primary" @click="handleSave">确 定</el-button>
            </div>
        </el-dialog>

    </div>
</template>

<script>
    import {deleteAction, postAction, putAction} from '@/api/manage'
    import QueryMixins from "../../../mixins/QueryMixins";
    import {zhengshuPattern} from "@/utils/pattern";
    //import {mobilePattern} from '@/utils/pattern'

    export default {
        name: 'Importer',
        mixins: [QueryMixins],
        data() {
            return {
                rules: {
                  company: [
                        {required: true, message: '请输入公司名称', trigger: 'change'},
                        {max: 64, message: '长度不能超过64', trigger: 'change'},
                    ],
                    sortNum: [
                        {max: 10, message: '长度不能超过10', trigger: 'change'},
                        {pattern: zhengshuPattern, message: '请输入正整数', trigger: 'change'}
                    ],
                  decription: [
                        {max: 500, message: '长度不能超过500', trigger: 'change'},
                    ],
                },
                emailList: [],
                ids: [],
                row: {},
                form: {},
                formLabelWidth: '110px',
                //控制弹框显示
                dialogFormVisible: false,
                columns: [
                    {
                        type: 'selection',
                        width: 50
                    },
                    {
                        prop: 'action',
                        label: '操作',

                    },
                    {
                        prop: 'company',
                        label: '公司名称',
                    },
                  {
                    prop: 'decription',
                    label: '描述',

                  },
                    {
                        prop: 'prefix',
                        label: '国家读取规则',

                    },
                  {
                    prop: 'sortNum',
                    label: '排序号码',
                  },
                  {
                    prop: 'createBy',
                    label: '创建人',

                  },
                  {
                    prop: 'createTime',
                    label: '创建时间',

                  },
                ],
                url: {
                    list: '/sys/thirdOverseaWarehouse/list',
                    add: '/sys/thirdOverseaWarehouse/add',
                    edit: '/sys/thirdOverseaWarehouse/edit',
                    getById: '/sys/thirdOverseaWarehouse/queryById',
                    delete: '/sys/thirdOverseaWarehouse/delete',
                },
            }
        },
        methods: {
            handleSelectionChange(selection) { // 多选
                let arr = []
                this.emailList = [];
                for (let i = 0; i < selection.length; i++) {
                    arr.push(selection[i]['id'])
                    this.emailList.push(selection[i]['email']);
                }
                this.selectionRows = selection
                this.selectedRowKeys = [...arr]
            },
            handleAdd() {
                this.form = {};
                this.dialogFormVisible = true;
            },
            handleEdit(row) {
                //将对象转为json字符串再转回对象
                //this.form = row; 复制的是指针 仍然指向这个对象row
                this.form = row;
                this.form.sortNum = this.form.sortNum.toString()
                this.dialogFormVisible = true;
            },
            handleSave() {
                this.$refs.form.validate(valid => {
                    if (valid) {
                        //编辑
                        if (this.form.id) {
                            putAction(this.url.edit, this.form)
                                .then((res) => {
                                    if (res.success) {
                                        this.$message.success(res.message);
                                        //关闭弹窗
                                        this.dialogFormVisible = false;
                                        this.handleQuery()
                                    }
                                })
                        } else {
                            postAction(this.url.add, this.form)
                                .then((res) => {
                                    if (res.success) {
                                        this.$message.success(res.message);
                                        //关闭弹窗
                                        this.dialogFormVisible = false;
                                        this.handleQuery()
                                    }
                                })
                        }
                    }
                })

            },
            handleDele(row) {
                this.$confirm(`确定删除该海外仓公司`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'success',
                    closeOnClickModal: false,
                }).then(() => {
                    deleteAction(this.url.delete, {id: row.id}).then(res => {
                        this.$message({
                            type: 'success',
                            message: res.message
                        })
                        this.handleQuery()
                    })
                }).catch(() => {

                })
            },
            handleReset() {
                this.queryForm = {};
                this.ipagination.currentPage = 1
                this.handleQuery()
            }
        }, created() {
        },
      computed:{
        buttonShowList() {
          return {
            'add':this.$btnIsShow('isystem/BaseData/ThirdPartyWareHouseCompany','0','新增'),
            'bj':this.$btnIsShow('isystem/BaseData/ThirdPartyWareHouseCompany','1','编辑'),
            'sc':this.$btnIsShow('isystem/BaseData/ThirdPartyWareHouseCompany','1','删除'),
          }
        }
      }
    }
</script>

<style lang="scss" scoped>

</style>
