<template>
    <div class="main-full-content">
        <el-form class="multiline_select" ref="form" :model="queryForm" label-width="80px" size="medium">
            <el-card class="box-card ">
                <el-row class="inputs_row">
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="客户名称">
                            <el-input v-model="queryForm.username" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="业务员">
                            <f-select v-model="queryForm.salesmanId" :isNeed="queryForm.salesmanId" :dict="'sys_user_id'"></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item prop="source" label="客户来源">
                            <f-select clearable filterable class="f-full-width" v-model="queryForm.source" :isNeed="queryForm.source" :dict="'cus_source'"></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="8">
                        <el-form-item label="注册时间">
                            <div class="f-flex">
                                <el-date-picker
                                        class="f-flex-1"
                                        v-model="queryForm.createTimeStart"
                                        type="datetime"
                                        size="small"
                                        placeholder="开始时间"
                                        default-time="00:00:00"
                                        value-format="yyyy-MM-dd HH:mm:ss">
                                </el-date-picker>
                                <span class="f-p-h-2">~</span>
                                <el-date-picker
                                        class="f-flex-1"
                                        v-model="queryForm.createTimeEnd"
                                        type="datetime"
                                        size="small"
                                        placeholder="结束时间"
                                        default-time="23:59:59"
                                        value-format="yyyy-MM-dd HH:mm:ss">
                                </el-date-picker>
                            </div>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label-width="30px">
                            <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
                            <el-button plain @click="handleReset">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>

        </el-form>
        <el-card class="">
            <div class="f-p-b-15">
                <el-button
                    type="primary"
                    size="medium"
                    icon="el-icon-download"
                    v-if="buttonShowList.dc"
                    @click="exportExcelPlus(url.exportExcelUrl,'客户跟进录入率')"
                >导出</el-button>
            </div>
            <div class="c-alert f-m-b-10">
                已选择 <span class="stress">{{this.selectedRowKeys.length}}</span> 项
                <span class="stress f-m-l-12 f-cursor-pointer" @click="handleClearSelection">清空</span>
            </div>
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="58vh"
                    :isRecordTableScroll="true"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange">
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'username'">
                        <a type="text" @click.prevent="$intoCustomerEditPage(scope.row.id)" style="color:#57a3f3;">{{scope.row.username}}</a>
                    </template>
                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange">
                </el-pagination>
            </el-row>
        </el-card>


    </div>
</template>

<script>
    import {deleteAction, getAction, postAction, putAction} from '@/api/manage'
    import QueryMixins from "@/mixins/QueryMixins.js"
    import AssignDialog from "@/views/customer/modules/AssignDialog";
    import {setUpRouteUtil} from '@/utils/util';


    export default {
        name: 'CusTransConRate',
        mixins: [QueryMixins],
        data() {
            return {
                columns: [
                    {
                        type: 'selection',
                        width: 50
                    },
                    {
                        prop: 'salesman',
                        label: '业务员',
                        sortable: 'custom',
                    },
                    {
                        prop: 'username',
                        label: '客户名',
                    },
                    {
                        prop: 'visitCount',
                        label: '拜访录入次数',
                    },
                    {
                        prop: 'recordRate',
                        label: '录入率',
                    },
                ],
                url: {
                    list:'/customer/statisticalReports/queryCusFolRecRateList',
                    exportExcelUrl:'/file/export/excel/downloadCusFolRecRateExcel',
                },
            }
        },
        methods: {

        },
      computed : {
        buttonShowList() {
          return {
            'dc':this.$btnIsShow('customer/Normal','0','导出'),

          }
        }
      }
    }
</script>

<style lang="scss" scoped>

</style>
