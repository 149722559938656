<template>
    <div>
        <el-card class="box-card">
            <el-form class="multiline_select" ref="form" :model="queryForm" label-width="80px" size="medium">
                <el-card class="box-card">
                    <el-row  class="inputs_row">
                        <el-col :xs="24" :sm="12" :md="12" :lg="6">
                            <el-form-item label="姓名">
                                <el-input  v-model="queryForm.workerId"
                                           @keyup.enter.native="handleSearch"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="7">
                            <el-form-item label="开单时间">
                                <div class="f-flex">
                                    <el-date-picker
                                            class="f-flex-1"
                                            v-model="queryForm.processTimeS"
                                            type="datetime"
                                            size="small"
                                            placeholder="开始时间"
                                            default-time="00:00:00"
                                            value-format="yyyy-MM-dd HH:mm:ss"
                                            @keyup.enter.native="handleSearch"
                                    >
                                    </el-date-picker>
                                    <span class="f-p-h-2">~</span>
                                    <el-date-picker
                                            class="f-flex-1"
                                            v-model="queryForm.processTimeE"
                                            type="datetime"
                                            size="small"
                                            placeholder="结束时间"
                                            default-time="23:59:59"
                                            value-format="yyyy-MM-dd HH:mm:ss"
                                            @keyup.enter.native="handleSearch"
                                    >
                                    </el-date-picker>
                                </div>
                            </el-form-item>
                        </el-col>

                        <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="6">
                            <el-form-item label="作业类型">
                                <f-select :dict="'warehouse_container_rec_and_arr'" v-model="queryForm.selectedTypeList" :isNeed="queryForm.selectedTypeList"
                                          @keyup.enter.native="handleSearch" :multiple="true"></f-select>
                            </el-form-item>
                        </el-col>

                        <el-col :xs="24" :sm="12" :md="12" :lg="4">
                            <el-form-item label-width="30px">
                                <el-button
                                        type="primary"
                                        icon="el-icon-search"
                                        @click="handleSearch"
                                >查询
                                </el-button
                                >
                                <el-button plain @click="handleReset">重置</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>


                </el-card>
            </el-form>
            <div class="bbb">

                <el-button type="primary" size="medium" @click="exportExcelPlus(url.exportExcelUrl,'收货卸货作业')"
                           v-if="buttonShowList.dc">导出
                </el-button>
            </div>
            <el-card >
                <el-button type="text" size="small" @click="caculate" style="float: left;margin-right: 15px">统计</el-button>
                <div style="float: left;margin-left: 15px" v-if="cacuVisible" >
                    <span style="float: left">方数：<span style="color: green">{{totalVolume}}<span style="margin-left: 5px">CBM</span> </span></span>
                </div>
            </el-card>
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="73vh"
            >
                <template v-slot="scope">
<!--                    <template v-if="scope.column.property === 'action'">-->
<!--                        <el-button type="text" @click="handleEdit(scope.row)">编辑</el-button>-->
<!--                    </template>-->
                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange">
                </el-pagination>
            </el-row>
        </el-card>
    </div>
</template>

<script>
    import QueryMixins from "@/mixins/QueryMixins.js";
    import {deleteAction, postAction,httpAction} from "@/api/manage";
    export default {
        name: "ReceiveAndArriveWork",
        mixins: [QueryMixins],
        components: {},
        data() {
            return {
                isUsePost:true,
                formLabelWidth: "120px",

                //维护 派送费 价格模板计算 （费用名称，最小值，最大值，单位，fbacode，成本，（美元转cny汇率），基数，公式展示（(成本*汇率)/基数），计算出来的单价），结算对象
                columns: [
                    {
                        type: "selection",
                    },
                    // {
                    //     prop: "action",
                    //     label: "操作",
                    // },
                    {
                        prop: "workerName",
                        label: "姓名",
                    },
                    {
                        prop: "volume",
                        label: "实际体积",
                    },
                    {
                        prop: "processTime",
                        label: "材积审核时间",
                    },
                    {
                        prop: "number",
                        label: "订单数量",
                    },
                    {
                        prop: "typeStr",
                        label: "作业类型",
                    },

                ],
                url: {
                    //查询列表的接口
                    list: "/warehouse/WorkRecord/queryPage",
                    //统计
                    cacula:'/warehouse/WorkRecord/queryTotalWorkVolume',
                    //导出excel的接口
                    exportExcelUrl: '/file/export/excel/downloadWarehouseWorkRecordExcel',
                },
                initData:{
                    typeList:['1','2']
                },
                cacuVisible:false,
                totalVolume:0,
            };
        },
        methods: {
            caculate(){
                this.cacuVisible = false;
                let params = {...this.queryForm, ...this.filterForm,...this.initData};
                postAction(this.url.cacula,params)
                    .then(res=>{
                        if (res.result) {
                            this.totalVolume = res.result
                        }
                        this.cacuVisible = true;

                    })
            },
        },
      computed:{
        buttonShowList() {
          return {
            'dc':this.$btnIsShow('warehouse2.0/ReceiveAndArriveWork','0','导出收货卸货作业'),
          }
        }
      }
    };
</script>

<style lang="scss" scoped>
    .bbb {
        display: flex;
    }

    .dialog-footer {
        text-align: right !important;
    }

    .margin_right_10 {
        margin-right: 10px;
    }
</style>
