<template>
    <div class="containers" ref="content">

        <el-row>
            <el-button @click="addDeploymentByString" v-if="actionType !='history'">部署</el-button>
            <el-button @click="downloadBpmn" v-if="actionType !='history'">保存BPMN</el-button>
            <el-button @click="downloadSvg" v-if="actionType !='history'">保存SVG</el-button>
            <!--            <el-button-->
            <!--                    :file-list="uploadBpmnFileList"-->
            <!--                    :before-upload="beforeUpload">-->
            <!--                导入BPMN-->
            <!--            </el-button>-->
            <el-button @click="$refs.refFile.click()" v-if="actionType !='history'">
                导入BPMN
                <input
                        type="file"
                        id="files"
                        ref="refFile"
                        style="display: none"
                        @change="loadXML"
                />
            </el-button>
            <el-button-group style="padding-left: 20px;" v-if="actionType !='history'">
                <el-button @click="handlerUndo" >撤销</el-button>
                <el-button @click="handlerRedo">恢复</el-button>
            </el-button-group>

            <el-button-group>
                <el-button @click="handlerZoom(0.1)">放大</el-button>
                <el-button @click="handlerZoom(-0.1)">缩小</el-button>
                <el-button @click="handlerZoom(0)">还原</el-button>
            </el-button-group>

            <el hidden ref="downloadLink"></el>
        </el-row>

        <div class="canvas" ref="canvas"></div>
        <div id="js-properties-panel" class="panel"></div>
    </div>

</template>

<script>

    // 左边工具栏以及编辑节点的样式
    import 'bpmn-js/dist/assets/diagram-js.css';
    import 'bpmn-js/dist/assets/bpmn-font/css/bpmn.css';
    import 'bpmn-js/dist/assets/bpmn-font/css/bpmn-codes.css';
    import 'bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css';

    // 右边工具栏样式
    import 'bpmn-js-properties-panel/dist/assets/bpmn-js-properties-panel.css';

    import BpmnModeler from 'bpmn-js/lib/Modeler';

    import propertiesPanelModule from 'bpmn-js-properties-panel';
    import propertiesProviderModule from 'bpmn-js-properties-panel/lib/provider/camunda'
    // import bpmnModdleDescriptor from 'bpmn-moddle/resources/bpmn/json/bpmn.json';

    // 一个描述的json
    import camundaModdleDescriptor from 'camunda-bpmn-moddle/resources/camunda'


    // BPMN国际化
    import customTranslate from '@/views/isystem/activity/i18n/customTranslate';
    // 自定义汉化模块
    var customTranslateModule = {
        translate: ['value', customTranslate]
    };

    import {xmlStr} from '@/views/isystem/activity/i18n/xmlStr';
    import {addDeploymentByString} from '@/api/activiti'
    import {getAction} from '@/api/manage'

    export default {
        components: {},
        data() {
            return {
                bpmnModeler: null,
                container: null,
                canvas: null,
                scale: 1,
                nodeList: [],
                url: {
                    view:'/activiti/view',
                    history:'/activiti/history'
                },
                actionType:'add'
            }
        },
        created() {

        },
        mounted() {
            this.init();
        },
        methods: {
            /**
             * 初始化流程设计器对象
             * @returns {Promise<void>}
             */
            async init() {
                // 获取到属性ref为“content”的dom节点
                this.container = this.$refs.content
                // 获取到属性ref为“canvas”的dom节点
                const canvas = this.$refs.canvas
                // 创建BpmnModeler
                this.bpmnModeler = new BpmnModeler({
                    container: canvas,
                    // 加入工具栏支持
                    propertiesPanel: {
                        parent: '#js-properties-panel'
                    },
                    additionalModules: [
                        // 左边工具栏以及节点
                        propertiesProviderModule,
                        // 右边的工具栏
                        propertiesPanelModule,
                        // 国际化
                        customTranslateModule
                    ],
                    moddleExtensions: {
                        // bpmn: bpmnModdleDescriptor,
                        camunda: camundaModdleDescriptor
                    }
                });

                //根据url 获取响应的动作
                let type = this.getQueryVariable("type")
                console.log(xmlStr)
                let xml = null;
                // 创建新流程
                if(type == 'add') {
                    await this.createNewDiagram(xmlStr);
                }else if(type == 'history'){
                    this.actionType = 'history'

                    let result =null;
                    await getAction(this.url.history, {processInstanceId:this.getQueryVariable("processInstanceId")}).then(res => {
                        if(res.success){
                            xml = res.result['xml'];
                             this.createNewDiagram(xml);
                            result = res.result

                        }
                    })

                    const canvas = this.bpmnModeler.get('canvas');
                    canvas.zoom('fit-viewport');
                    const elementRegistry = this.bpmnModeler.get('elementRegistry');
                    result['highLine'].forEach((e) => {
                        if (e) {
                            var elementToColor = elementRegistry.get(e);
                            console.log(elementToColor)
                            var modeling = this.bpmnModeler.get('modeling');
                            modeling.setColor([ elementToColor ], {
                                stroke: 'green',
                                 fill: 'green'
                            });

                        }
                    });
                    result['highPoint'].forEach((e) => {
                        if (e) {
                            var elementToColor = elementRegistry.get(e);
                            console.log(elementToColor)
                            var modeling = this.bpmnModeler.get('modeling');
                            modeling.setColor([ elementToColor ], {
                                stroke: 'gray',
                                fill: '#eae9e9'
                            });
                        }
                    });
                    result['waitingToDo'].forEach((e) => {
                        if (e) {
                            var elementToColor = elementRegistry.get(e);
                            console.log(elementToColor)
                            var modeling = this.bpmnModeler.get('modeling');
                            modeling.setColor([ elementToColor ], {
                                stroke: 'green',
                                fill: 'yellow'
                            });
                        }
                    });
                    result['iDo'].forEach((e) => {
                        if (e) {
                            var elementToColor = elementRegistry.get(e);
                            console.log(elementToColor)
                            var modeling = this.bpmnModeler.get('modeling');
                            modeling.setColor([ elementToColor ], {
                                stroke: 'green',
                                fill: '#a3d68e'
                            });
                        }
                    });
                }
                else {

                    getAction(this.url.view, {processInstanceId:this.getQueryVariable("processInstanceId")}).then(res => {
                       if(res.success){
                           xml = res.result['xml'];
                           this.createNewDiagram(xml);
                       }
                    })
                }
            },

            /**
             * 创建新流程
             * @param bpmn BPMN流程XML报文
             * @returns {Promise<void>}
             */
            async createNewDiagram(bpmn) {
                try {
                    const result = await this.bpmnModeler.importXML(bpmn);
                    const {warnings} = result;
                    console.log(warnings);
                    this.success();
                } catch (err) {
                    console.log(err.message, err.warnings);
                }
            },
            success() {
                this.addModelerListener();
                this.addEventBusListener();
            },
            addModelerListener() {
                const that = this;
                that.bpmnModeler.on("element.click", e => {
                    console.log("modelerListener", e);
                });
            },
            addEventBusListener() {
                const that = this;
                const eventBus = this.bpmnModeler.get("eventBus");
                eventBus.on("element.click", function (e) {
                    that.elementClick(e);
                });
            },
            elementClick(e) {
                const that = this;
                const modeling = this.bpmnModeler.get("modeling");
                if (e.element.businessObject.$type === "bpmn:StartEvent") {
                    console.log(
                        "这是一个开始节点",
                        e.element.businessObject.id,
                        e.element.businessObject.$type,
                        e.element.businessObject.name
                    );
                    // // 修改节点ID
                    // modeling.updateProperties(e.element, {
                    //     id: "StartEvent_ops_coffee"
                    // });
                }
                if (e.element.businessObject.$type === "bpmn:UserTask") {
                    console.log(
                        "这是一个用户节点",
                        e.element.businessObject.id,
                        e.element.businessObject.$type,
                        e.element.businessObject.name
                    );
                    // // 修改节点名称
                    // modeling.updateProperties(e.element, {
                    //     name: "ops-coffee.cn"
                    // });
                }
            },
            //获取url参数
            getQueryVariable(variable){
                var query = window.location.search.substring(1);
                console.log(query)
                var vars = query.split("&");
                for (var i=0;i<vars.length;i++) {
                    var pair = vars[i].split("=");
                    if(pair[0] == variable){return pair[1];}
                }
                return(false);
            },
            handlerRedo() {
                this.bpmnModeler.get("commandStack").redo();
            },
            handlerUndo() {
                this.bpmnModeler.get("commandStack").undo();
            },
            handlerZoom(radio) {
                const newScale = !radio ? 1.0 : this.scale + radio;
                this.bpmnModeler.get("canvas").zoom(newScale);
                this.scale = newScale;
            },
            async addDeploymentByString() {
                const result = await this.bpmnModeler.saveXML({format: true});
                const {xml} = result;
                addDeploymentByString('/activiti/addDeploymentByString', {"stringBPMN": xml}).then(res => {
                      console.log(res)
                    if(res.success){
                        this.$message({
                            type: 'success',
                            message: res.message
                        })
                    }
                })

            },
            async loadXML() {
                const that = this;
                const file = this.$refs.refFile.files[0];
                var reader = new FileReader();
                reader.readAsText(file);
                reader.onload = function () {
                    that.createNewDiagram(this.result);
                };
            },

            async downloadBpmn() {
                try {
                    const result = await this.bpmnModeler.saveXML({format: true});
                    const {xml} = result;
                    var xmlBlob = new Blob([xml], {
                        type: "application/bpmn20-xml;charset=UTF-8,"
                    });
                    var downloadLink = document.createElement("a");
                    downloadLink.download = "ops-coffee-bpmn.bpmn";
                    downloadLink.innerHTML = "Get BPMN SVG";
                    downloadLink.href = window.URL.createObjectURL(xmlBlob);
                    downloadLink.onclick = function (event) {
                        document.body.removeChild(event.target);
                    };
                    downloadLink.style.visibility = "hidden";
                    document.body.appendChild(downloadLink);
                    downloadLink.click();
                } catch (err) {
                    console.log(err);
                }
            },

            async downloadSvg() {
                try {
                    const result = await this.bpmnModeler.saveSVG();
                    const {svg} = result;
                    var svgBlob = new Blob([svg], {
                        type: "image/svg+xml"
                    });
                    var downloadLink = document.createElement("a");
                    downloadLink.download = "ops-coffee-bpmn.svg";
                    downloadLink.innerHTML = "Get BPMN SVG";
                    downloadLink.href = window.URL.createObjectURL(svgBlob);
                    downloadLink.onclick = function (event) {
                        document.body.removeChild(event.target);
                    };
                    downloadLink.style.visibility = "hidden";
                    document.body.appendChild(downloadLink);
                    downloadLink.click();
                } catch (err) {
                    console.log(err);
                }
            },
            getFilename(xml) {
                let start = xml.indexOf("process");
                let filename = xml.substr(start, xml.indexOf(">"));
                filename = filename.substr(filename.indexOf("id") + 4);
                filename = filename.substr(0, filename.indexOf('"'));
                return filename;
            },

        },
        computed: {}
    }
</script>
<style lang="scss" scoped>
    .highlight .djs-visual > :nth-child(1) {
        stroke: green !important;
        fill: rgba(0, 80, 0, 0.4) !important;
    }
    .highlightFlow .djs-visual > :nth-child(1) {
        stroke: green !important;
    }
    .highlightIDO .djs-visual > :nth-child(1) {
        stroke: rgb(255, 196, 0) !important;
        fill: rgba(255, 196, 0, 0.4) !important;
    }
    .highlightTODO .djs-visual > :nth-child(1) {
        stroke: rgb(255, 0, 0) !important;
        fill: rgba(255, 255, 255, 0.4) !important;
    }

    .containers {
        position: absolute;
        background-color: #ffffff;
        width: 100%;
        height: 100vh;
        padding: 5px;
    }

    .canvas {
        width: 100%;
        height: 100%;
    }

    .panel {
        position: absolute;
        right: 0;
        top: 0;
        width: 300px;
    }


</style>
