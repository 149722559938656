<template>
    <div>
        <div class="f-p-b-15">
            <el-button type="primary" size="small" icon="el-icon-plus" @click="handleAdd({})">新增</el-button>
        </div>
        <f-table
                v-loading="loading"
                ref="GTable"
                border
                size="medium"
                row-key="id"
                class="c-th-has-bgcolor"
                :columns="columns"
                :data="tableData"
                :isorter="isorter"
                :options="typelist"
                :show-operation="true"
                :cell-style="{ 'text-align': 'center' }"
                @selection-change="handleSelectionChange"
                @sort-change="handleTableChange"
                @queryChange="handleTableQueryChange">
            <template v-slot="scope">
                <template v-if="scope.column.property === 'action'">
                    <el-button type="text" @click="handleEdit(scope.row)">编辑</el-button>
                    <el-button type="text" @click="handleJy(scope.row)">禁用</el-button>
                    <el-button type="text" @click="handleQy(scope.row)">启用</el-button>
                    <!--          <el-button type="text" @click="handlSetToDefault(scope.row)">设为默认</el-button>-->
                </template>
                <template v-else-if="scope.column.property === 'settlementType'">
                    <span>{{parseDict(scope.row.settlementType,'sup_settle')}}</span>
                </template>
                <template v-else-if="scope.column.property === 'supplierType'">
                    <span>{{parseDict(scope.row.supplierType,'sup_type')}}</span>
                </template>
                <template v-else-if="scope.column.property === 'supplierNature'">
                    <span>{{parseDict(scope.row.supplierNature, "supplier_nature")}}</span>
                </template>
            </template>
        </f-table>
        <el-row class="f-text-right f-p-v-8">
            <el-pagination
                    v-bind="ipagination"
                    layout="total, sizes, prev, pager, next, jumper"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
            >
            </el-pagination>
        </el-row>

        <el-dialog :title="form.id?'编辑子账户':'新增子账户'" :visible.sync="dialogFormVisible" width="30%" center
                   v-if="dialogFormVisible"
                   :close-on-click-modal="false">
            <el-form :model="form" ref="form" :rules="rules">
                <el-form-item label="性质" :label-width="formLabelWidth" prop="supplierNatureList">
                    <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选
                    </el-checkbox>
                    <div style="margin: 15px 0;"></div>
                    <el-checkbox-group v-model="form.supplierNatureList" @change="handleCheckedCitiesChange">
                        <el-checkbox v-for="(item,index) in oldCities" :label="item.value" :key="index">{{item.text}}
                        </el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item label="供应商中文名" :label-width="formLabelWidth" prop="chinaseName">
                    <el-input v-model="form.chinaseName" autocomplete="off" maxlength="50" show-word-limit></el-input>
                </el-form-item>
                <el-form-item label="供应商英文名" :label-width="formLabelWidth" prop="englishName">
                    <el-input v-model="form.englishName" autocomplete="off" maxlength="50" show-word-limit></el-input>
                </el-form-item>
                <el-form-item label="结算方式" :label-width="formLabelWidth" prop="settlementType">
                    <f-select
                            v-model="form.settlementType"
                            :fatherVal="form.settlementType"
                            :dict="'sup_settle'"
                            :is-need="form.settlementType"
                            placeholder="请选择">

                    </f-select>
                </el-form-item>
                <el-form-item label="供应商类型" :label-width="formLabelWidth" prop="supplierType">
                    <el-select v-model="form.supplierTypeList" placeholder="请选择" multiple>
                        <el-option
                                v-for="item in optionssupplierType"
                                :key="item.value"
                                :label="item.text"
                                :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="联系人" :label-width="formLabelWidth" prop="contact">
                    <el-input v-model="form.contact" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="联系电话" :label-width="formLabelWidth" prop="tel">
                    <el-input v-model="form.tel" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="邮箱" :label-width="formLabelWidth" prop="email">
                    <el-input v-model="form.email" autocomplete="off"></el-input>
                </el-form-item>
                <el-row>
                    <el-col :span="24">
                        <el-form-item prop="" label="注册地址" :label-width="formLabelWidth">
                            <el-col :xs="24" :sm="12" :md="12" :lg="6">
                                <el-form-item prop="country" label="">
                                    <el-select
                                            clearable
                                            filterable
                                            class="f-full-width"
                                            v-model="form.country"
                                            @change="initStateList"
                                            placeholder="请选择国家"
                                            @click.native="initCountryList">
                                        <el-option
                                                v-for="(item,index) in countrylist"
                                                :key="index"
                                                :label="item.label"
                                                :value="item.value"
                                        >
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :xs="24" :sm="12" :md="12" :lg="6">
                                <el-form-item prop="state" label="">

                                    <el-select

                                            clearable
                                            filterable
                                            @change="initCityList"
                                            class="f-full-width"
                                            v-model="form.state"
                                            placeholder="请选择州/省">
                                        <el-option
                                                v-for="item in statelist"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :xs="24" :sm="12" :md="12" :lg="6">
                                <el-form-item prop="city" label="">
                                    <el-select
                                            clearable
                                            filterable
                                            class="f-full-width"
                                            v-model="form.city"
                                            placeholder="请选择城市">
                                        <el-option
                                                v-for="item in citylist"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value">
                                        </el-option>
                                    </el-select>

                                </el-form-item>
                            </el-col>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-form-item label="详细地址" :label-width="formLabelWidth" prop="registerAddress">
                    <el-input v-model="form.registerAddress" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="优势航线" :label-width="formLabelWidth" prop="advantageRoute">
                    <el-input v-model="form.advantageRoute" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="备注" :label-width="formLabelWidth">
                    <el-input v-model="form.remarks" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="供应商跟进人" :label-width="formLabelWidth" prop="salesmanId">
                    <f-select
                            v-model="form.salesmanId"
                            :fatherVal="form.salesmanId"
                            :dict="'sys_user_id'"
                            :isNeed="form.salesmanId"
                            placeholder="请选择">
                    </f-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveSonAccount" :disabled="btnDisable">确 定</el-button>
            </div>

        </el-dialog>
    </div>
</template>

<script>
    import QueryMixins from "@/mixins/QueryMixins.js"
    import {emailPattern, mobilePattern, yingwenPattern, zhognwenPattern} from "@/utils/pattern";
    import {getAction, postAction, putAction} from "@/api/manage";
    import {getallcountries, getcityList, getstateList} from "../../../api/manage";

    export default {
        name: 'SonAccountModule',
        mixins: [QueryMixins],
        components: {},
        data() {
            return {
                dictCodes: ['sup_type', 'supplier_nature', 'sup_settle'],
                countrylist: [],
                statelist: [],
                citylist: [],
                form: {},
                dialogFormVisible: false,
                formLabelWidth: '120px',
                btnDisable: false,
                typelist: [
                    {value: '0', label: '公账'},
                    {value: '1', label: '私账'}
                ],
                //表单校验
                rules: {
                    supplierNatureList: [
                        {required: true, message: '请选择供应商性质', trigger: 'change'},
                    ],
                    chinaseName: [
                        {required: true, message: '请输入中文名', trigger: 'blur'},
                       /* {pattern: zhognwenPattern, message: '请输入中文', trigger: 'change'},*/
                        {max: 50, message: '长度不能超过50', trigger: 'change'},
                    ],
                    englishName: [
                        {required: true, message: '请输入英文名', trigger: 'blur'},
                        // {pattern: yingwenPattern, message: '请输入英文', trigger: 'change'},
                        {max: 50, message: '长度不能超过50', trigger: 'change'},
                    ],
                    settlementType: [
                        {required: true, message: '请选择结算方式', trigger: 'change'}
                    ],
                    supplierTypeList: [
                        {required: true, message: '请选择供应商类型', trigger: 'change'}
                    ],
                    contact: [
                        {required: true, message: '请输入联系人', trigger: 'blur'},
                        {max: 50, message: '长度不能超过50', trigger: 'change'},
                    ],
                    tel: [
                        {required: true, message: '请输入电话', trigger: 'blur'},
                        // {pattern: mobilePattern, message: '手机号格式有误', trigger: 'blur'},
                    ],
                    email: [
                        {required: true, message: '请输入邮箱', trigger: 'blur'},
                        {pattern: emailPattern, message: '邮箱格式有误', trigger: 'blur'},
                    ],
                    country: [
                        {required: true, message: '请选择国家', trigger: 'change'}
                    ],
                    state: [
                        {required: true, message: '请选择州', trigger: 'change'}
                    ],
                    city: [
                        {required: true, message: '请选择城市', trigger: 'change'}
                    ],
                    address: [
                        {required: true, message: '请输入地址', trigger: 'blur'}
                    ],
                    registerAddress: [
                        {required: true, message: '请输入详细地址', trigger: 'blur'},
                        {max: 255, message: '长度不能超过255', trigger: 'change'},
                    ],
                    salesmanId: [
                        {required: true, message: '请选择跟进人', trigger: 'blur'},
                    ],
                },

                row: {},
                visible: false,
                columns: [
                    {
                        type: 'selection',
                        width: 50
                    },
                    {
                        prop: 'action',
                        label: '操作',
                        width: 140,
                    },
                    {
                        prop: 'supplierNature',
                        label: '性质',
                        sortable: 'custom',
                        width: 140
                    },
                    {
                        prop: 'chinaseName',
                        label: '供应商中文名',
                        sortable: 'custom',
                        width: 140
                    },
                    {
                        prop: 'englishName',
                        label: '供应商英文名',
                        sortable: 'custom',
                        width: 140
                    },
                    {
                        prop: 'settlementType',
                        label: '结算方式',
                        sortable: 'custom',
                        width: 130,

                    },
                    {
                        prop: 'supplierType',
                        label: '供应商类型',
                        sortable: 'custom',
                        width: 140,

                    },
                    {
                        prop: 'contact',
                        label: '联系人',
                        sortable: 'custom',
                        width: 120
                    },
                    {
                        prop: 'tel',
                        label: '联系电话',
                        sortable: 'custom',
                        width: 140
                    },
                    {
                        prop: 'email',
                        label: '邮箱',
                        sortable: 'custom',
                        width: 160
                    },
                    {
                        prop: 'country',
                        label: '国家',
                        sortable: 'custom',
                        width: 140
                    },
                    {
                        prop: 'registerAddress',
                        label: '详细地址',
                        sortable: 'custom',
                        width: 140
                    },
                    {
                        prop: 'advantageRoute',
                        label: '优势航线',
                        sortable: 'custom',
                        width: 140
                    },
                    {
                        prop: 'remarks',
                        label: '备注',
                        sortable: 'custom',
                        width: 120
                    },
                    {
                        prop: 'createTime',
                        label: '创建时间',
                        sortable: 'custom',
                        width: 140
                    },
                ],
                url: {
                    list: '/sys/supplier/list',
                    getbyid: '/sys/supplier/queryById',
                    jinyong: '/sys/supplier/disable',
                    add: '/sys/supplier/add',
                    edit: '/sys/supplier/edit',
                    qiyong: '/sys/supplier/enable'
                },
                isIndeterminate: false,
                checkAll: false,
                fsee: false,
                cities: [],
                oldCities: [],
                supplierNatureList: [],
                supplierTypeList: [],
                optionssupplierType: [],

            }
        },
        mounted() {
            this.handleSearch()
        },
        methods: {
            async initSupplierType() {
                if (sessionStorage.getItem('dict:sup_type')) {
                    this.optionssupplierType = JSON.parse(sessionStorage.getItem('dict:sup_type'));

                } else {
                    await getAction('/sys/dict/getDictItems/sup_type')
                        .then(res => {
                            this.optionssupplierType = res.result;
                        })
                }
                console.log("this.optionssupplierType", this.optionssupplierType);
            },
            async initCitites() {
                if (sessionStorage.getItem('dict:supplier_nature')) {
                    this.oldCities = JSON.parse(sessionStorage.getItem('dict:supplier_nature'));
                    this.cities = this.oldCities.map(function (e) {
                        return e.value;
                    });
                } else {
                    await getAction('/sys/dict/getDictItems/supplier_nature')
                        .then(res => {
                            this.oldCities = res.result;
                            this.cities = this.oldCities.map(function (e) {
                                return e.value;
                            });
                        })
                }
                console.log("this.oldCities", this.oldCities);
                console.log("this.cities", this.cities);
            },
            handleCheckAllChange(val) {
                this.form.supplierNatureList = val ? this.cities : [];
                this.isIndeterminate = false;
            },
            handleCheckedCitiesChange(value) {
                console.log('变化的单选', value);
                let checkedCount = value.length;
                this.checkAll = checkedCount === this.cities.length;
                this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length;
            },
            initCountryList() {
                if (this.countrylist.length <= 0) {
                    getallcountries().then(res => {
                        if (res.success) {
                            for (var i = 0; i < res.result.length; i++) {
                                let obj = {};
                                obj.value = res.result[i].cnName;
                                obj.label = res.result[i].twoCode + "-" + res.result[i].cnName;
                                this.countrylist.push(obj);
                            }
                        }
                    })
                }

            },
            initStateList(val) {
                this.statelist = [];
                this.citylist = [];
                if (val) {
                    getstateList({cnName: val})
                        .then((res) => {

                            if (res.success) {
                                for (var i = 0; i < res.result.records.length; i++) {
                                    let obj = {};
                                    obj.value = res.result.records[i].cname;
                                    obj.label = res.result.records[i].code + "-" + res.result.records[i].cname;
                                    this.statelist.push(obj);
                                }
                            }
                        })
                } else {
                    // this.form.city = '';
                    // this.form.state = '';
                    // this.form.bdState = '';
                    // this.form.bdCity = '';
                }

            },
            initCityList(val) {
                this.citylist = [];
                if (val) {
                    getcityList({cname: val})
                        .then((res) => {
                            if (res.success) {
                                for (var i = 0; i < res.result.records.length; i++) {
                                    let obj = {};
                                    obj.value = res.result.records[i].cname;
                                    obj.label = res.result.records[i].code + "-" + res.result.records[i].cname;
                                    this.citylist.push(obj);
                                }
                            }
                        })
                } else {

                }

            },
            handleJy(row) {
                this.$confirm(`确定禁用账户${row.chinaseName}`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'success',
                    closeOnClickModal: false,
                }).then(() => {
                    getAction(this.url.jinyong, {id: row.id}).then(res => {
                        this.$message({
                            type: 'success',
                            message: res.message
                        })
                        this.handleSearch()
                    })
                }).catch(err => {
                    this.$message.error(err.message);
                })
            },
            handleQy(row) {
                this.$confirm(`确定启用账户${row.chinaseName}`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'success',
                    closeOnClickModal: false,
                }).then(() => {
                    getAction(this.url.qiyong, {id: row.id}).then(res => {
                        this.$message({
                            type: 'success',
                            message: res.message
                        })
                        this.handleSearch()
                    })
                }).catch(err => {
                    this.$message.error(err.message);
                })
            },
            handleAdd() {
                // let total = this.ipagination.total;
                // if (total >= 5) {
                //     this.$message.warning('最多添加五个子账号')
                //     return false;
                // }
                this.form = {};
                this.$set(this.form, 'supplierNatureList', []);
                this.$set(this.form, 'supplierTypeList', []);
                // this.form.supplierNatureList=[];
                // this.form.supplierTypeList=[];
                this.dialogFormVisible = true;
                this.btnDisable = false;
            },
            handleEdit(row) {
                this.btnDisable = false;
                this.form = JSON.parse(JSON.stringify(row));
                this.$set(this.form, 'supplierNatureList', this.form.supplierNature.split(','));
                this.$set(this.form, 'supplierTypeList', this.form.supplierType.split(','));
                //显示弹窗，给对象赋值
                this.initStateList(this.form.country);
                this.initCityList(this.form.state);
                this.dialogFormVisible = true
            },
            //新增或修改
            saveSonAccount() {
                //给客户id赋值
                this.form.parentId = this.$route.query.id;
                this.btnDisable = true;

                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        this.form.supplierNature = this.form.supplierNatureList.join(',');
                        this.form.supplierType = this.form.supplierTypeList.join(',');
                        if (this.form.id) {
                            //有id，编辑
                            putAction(this.url.edit, this.form)
                                .then(res => {
                                    if (res.success) {
                                        this.$message.success(res.message);
                                        //关闭弹窗
                                        this.dialogFormVisible = false;
                                        this.handleQuery();
                                    } else {
                                        this.btnDisable = false;
                                    }
                                }).catch(err => {
                                this.btnDisable = false;
                            })
                        } else {
                            //新增
                            postAction(this.url.add, this.form)
                                .then(res => {
                                    if (res.success) {
                                        this.$message.success(res.message);
                                        this.dialogFormVisible = false;
                                        this.handleQuery();
                                    } else {
                                        this.btnDisable = false;
                                    }
                                }).catch(err => {
                                this.btnDisable = false;
                            })
                        }
                    } else {
                        this.btnDisable = false;
                    }
                })
            },
            init() {
                let id = this.$route.query.id;
                //根据id查询出 当前供应商的情况，赋值给form
                this.queryForm.parentId = id;
                this.initSupplierType();
                this.initCitites();
            }
        },
        created() {
            this.init();
            this.queryForm.initCountry = 1;

        }
    }
</script>

<style lang="scss" scoped>

</style>
