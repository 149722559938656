<!--待录订单-->
<template>
  <div class="main-full-content">
    <el-form
      class="multiline_select"
      ref="form"
      :model="queryForm"
      label-width="120px"
      size="medium"
    >
      <el-card class="box-card">
        <el-row class="inputs_row">
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="到仓时间">
              <div class="f-flex">
                <el-date-picker
                  class="f-flex-1"
                  v-model="queryForm.cargoArrivedTimeStart"
                  type="datetime"
                  size="small"
                  placeholder="开始时间"
                  default-time="00:00:00"
                  value-format="yyyy-MM-dd HH:mm:ss"
                >
                </el-date-picker>
                <span class="f-p-h-2">~</span>
                <el-date-picker
                  class="f-flex-1"
                  v-model="queryForm.cargoArrivedTimeEnd"
                  type="datetime"
                  size="small"
                  placeholder="结束时间"
                  default-time="23:59:59"
                  value-format="yyyy-MM-dd HH:mm:ss"
                >
                </el-date-picker>
              </div>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="是否上传入仓单">
              <f-select
                v-model="queryForm.uploadCaijiPic"
                :is-need="queryForm.uploadCaijiPic"
                :dict="'is_default'"
              ></f-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="订单号:">
              <el-input
                v-model="queryForm.orderNo"
                @keyup.enter.native="handleSearch"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="开单仓库">
              <f-select
                class="textarea_table"
                :dict="'delivery_point_kaidan'"
                v-model="queryForm.lastKaidanPointNameId"
                :isNeed="queryForm.lastKaidanPointNameId"
                @changetAllData="(val) => setName(val, 'lastKaidanPointName')"
              >
              </f-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="最新上传签收单人归属仓库" label-width="200px">
              <f-select
                :dict="'delivery_point_kaidan'"
                v-model="queryForm.uploadCaijiPicPointId"
                :isNeed="queryForm.uploadCaijiPicPointId"
                @changetAllData="(val) => setName(val, 'uploadCaijiPicPoint')"
              >
              </f-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="排柜状态">
              <f-select
                :dict="'rack_status'"
                v-model="queryForm.orderStatus"
                :isNeed="queryForm.orderStatus"
              >
              </f-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="渠道名称">
              <el-input
                v-model="queryForm.channelName"
                autocomplete="off"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="目的国" prop="shipmentCountry">
              <el-input v-model="queryForm.shipmentCountry"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="交货仓库">
              <f-select
                v-model="queryForm.deliveryPointId"
                :dict="'delivery_point'"
                :is-need="queryForm.deliveryPointId"
              ></f-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="拼柜仓库">
              <f-select
                v-model="queryForm.lclPlaceId"
                :dict="'lclPlace'"
                :is-need="queryForm.lclPlaceId"
              ></f-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label-width="30px">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="handleSearch"
                >查询
              </el-button>
              <el-button plain @click="handleReset">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
    </el-form>
    <el-card class="box-card last_card">
      <div class="f-p-b-15">
        <!-- <el-button type="primary" size="small" v-if="buttonShowList.ckB"
          >批量出库</el-button
        > -->
      </div>
      <f-table
        v-loading="loading"
        ref="GTable"
        border
        size="medium"
        row-key="id"
        class="c-th-has-bgcolor"
        :columns="columns"
        :data="tableData"
        :cell-style="{ 'text-align': 'center' }"
        tableHeight="61vh"
        :isRecordTableScroll="true"
        @selection-change="handleSelectionChange"
        @sort-change="handleTableChange"
      >
        <template v-slot="scope">
          <template v-if="scope.column.property === 'action'">
            <el-button
              type="text"
              @click="
                $router.push({
                  path: '/warehouse/Main',
                  query: {
                    orderNo: scope.row.orderNo,
                  },
                })
              "
              v-if="buttonShowList.lr"
              >录入</el-button
            >
          </template>
        </template>
      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination
          v-bind="ipagination"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import QueryMixins from "../../mixins/QueryMixins";

export default {
  name: "orderBacklog",
  mixins: [QueryMixins],
  data() {
    return {
      rules: {},
      form: {},
      columns: [
        {
          type: "selection",
          width: 50,
        },
        {
          prop: "action",
          label: "操作",
          // width: 170,
          // fixed: 'left'
        },
        {
          prop: "uploadCaijiPic",
          label: "是否上传入仓单",
        },
        {
          prop: "orderStatus",
          label: "排柜状态",
        },
        {
          prop: "orderNo",
          label: "订单号",
        },
        {
          prop: "ctn",
          label: "预报件数",
        },
        // {
        //   prop: "size",
        //   label: "实体",
        // },
        {
          prop: "cargoArrivedTime",
          label: "到仓时间",
        },
        {
          prop: "uploadCaijiPicTime",
          label: "上传入仓单时间",
        },
        {
          prop: "volumeTime",
          label: "材积最新创建时间",
        },
        {
          prop: "volumeCreateBy",
          label: "材积最新录入人",
        },
        {
          prop: "deliveryPointName",
          label: "交货仓库",
        },
        {
          prop: "lclPlaceName",
          label: "拼柜仓库",
        },
        {
          prop: "allVolumeLocation",
          label: "货物所在仓",
        },
        {
          prop: "remark",
          label: "内部备注",
        },
        {
          prop: "storehouseRemark",
          label: "仓库备注",
        },
        {
          prop: "doc",
          label: "客服",
        },
        {
          prop: "channelName",
          label: "渠道名称",
        },
        {
          prop: "salesman",
          label: "销售",
        },
        {
          prop: "operator",
          label: "排柜操作",
        },
      ],
      url: {
        list: "/warehouse/waitRecord/getPage",
      },
      isUsePost: true,
    };
  },
  methods: {
    setName(val, key) {
      if (val) {
        this.queryForm[key] = val.title;
      } else {
        this.queryForm[key] = "";
      }
    },
  },
  created() {},
  computed: {
    buttonShowList() {
      return {
        lr: this.$btnIsShow("warehouse2.0/orderBacklog", "1", "录入"),
      };
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
