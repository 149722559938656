<template>
  <!-- 批量下载入仓单 -->
  <div>
    <el-card class="box-card">
      <el-form class="multiline_select" ref="form" :model="queryForm" label-width="80px" size="medium" :rules="rules">
        <el-row :gutter="24" class="inputs_row">
          <el-col :md="12" :sm="24">
            <el-form-item label="柜号">
              <el-input class="textarea_table" v-model="queryForm.containerNo" type="textarea"
                 placeholder="请输入柜号多个逗号分隔" :rows="4"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-button size="medium" type="primary" icon="el-icon-download" @click="exportExcelPlus(url.containerSalesmanInfoExport,'柜子销售信息导出')" :loading="exportLoading">导出</el-button>
            <el-button plain @click="handleReset">重置</el-button>
          </el-col>
        </el-row>
      </el-form>
    </el-card>
  </div>
</template>
<script>
  import { printPdfDoc } from "@/utils/downloadJasper";
  import { getAction } from "../../api/manage";
  import QueryMixins from "@/mixins/QueryMixins.js";


  export default {
    components: {
    },
    mixins: [QueryMixins],
    props: {
    },
    data() {
      return {
        queryForm: {
          promotionNo: '',
        },
        rules: {
          orderNo: [
            { required: true, message: "请输入要下载入仓单的单号", trigger: "blur" },
          ],
        },
        url: {
          //下载入仓单
          containerSalesmanInfoExport: "/file/export/excel/containerSalesmanInfoExport",
        }
      }
    },
    watch: {
    },
    created() {
    },
    mounted() {
    },
    methods: {
      handleReset() {
        this.$set(this.queryForm, 'promotionNo', '');
      },
    }
  }
</script>
<style lang="scss" scoped>
</style>