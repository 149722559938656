<template>
    <div>
        <el-card class="box-card">
            <div class="f-p-b-15">
                <el-button type="primary" size="small" icon="el-icon-plus" @click="handleAdd({})"
                           v-if="buttonShowList.add"
                >新增</el-button>
            </div>
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    :isorter="isorter"
                    :options="typelist"
                    :show-operation="true"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="74vh"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange"
                    @queryChange="handleTableQueryChange">
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'action'">
                        <el-button type="text" @click="handleEdit(scope.row)"
                                   v-if="buttonShowList.edit"
                        >编辑</el-button>
                        <el-button type="text" @click="handleJy(scope.row)"
                                   v-if="scope.row.status =='0' && buttonShowList.dis"
                        >禁用</el-button>
                        <el-button type="text" @click="handleQy(scope.row)"
                                   v-if="scope.row.status =='1' && buttonShowList.en"
                        >启用</el-button>
                    </template>
                    <template v-else-if="scope.column.property === 'accountType'">
                        <span v-if="scope.row.accountType === '1'">私账</span>
                        <span v-else>公账</span>
                    </template>
                    <template v-else-if="scope.column.property === 'taxPropetry'">
                        <span v-if="scope.row.taxPropetry === '1'">小规模纳税人</span>
                        <span v-else>一般规模纳税人</span>
                    </template>

                    <template v-else-if="scope.column.property === 'status'">
                        <span v-if="scope.row.status === '1'">禁用</span>
                        <span v-else>正常</span>
                    </template>
                </template>
            </f-table>
        </el-card>
        <el-dialog :title="form.id?'编辑账户':'新增账户'" :visible.sync="dialogFormVisible" width="30%" center
                   :close-on-click-modal="false" v-if="dialogFormVisible">

            <el-form :model="form" :rules="rules" ref="form">
                <el-form-item label="开户行" prop="bankName" :label-width="formLabelWidth">
                    <el-input v-model="form.bankName" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="开户名" prop="accountName" :label-width="formLabelWidth">
                    <el-input v-model="form.accountName" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="开户账号" prop="account" :label-width="formLabelWidth">
                    <el-input v-model="form.account" autocomplete="off"></el-input>
                </el-form-item>

                <el-form-item label="币种" prop="cur" :label-width="formLabelWidth">
                    <f-select
                            v-model="form.cur"
                            :is-need="form.cur"
                            :dict="'bd_cur'">
                    </f-select>
                </el-form-item>

                <el-form-item label="账户类型" prop="accountType" :label-width="formLabelWidth">
                    <f-select
                            :is-need="form.accountType"
                            :dict="'account_type'"
                            v-model="form.accountType"
                    >
                    </f-select>
                </el-form-item>

                <el-form-item label="国税税务登记号" prop="taxNumber" :label-width="formLabelWidth">
                    <el-input v-model="form.taxNumber" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="纳税人规模" prop="taxPropetry" :label-width="formLabelWidth">
                    <f-select
                            :is-need="form.taxPropetry"
                            :dict="'tax_propetry'"
                            v-model="form.taxPropetry"
                    >
                    </f-select>
                </el-form-item>
                <el-form-item label="备注" prop="remark" :label-width="formLabelWidth">
                    <el-input v-model="form.remark" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveAccount" :disabled="btnDisabled">确 定</el-button>
            </div>

        </el-dialog>


    </div>
</template>

<script>
  import QueryMixins from "@/mixins/QueryMixins.js"
  import {getAction, postAction, putAction} from "@/api/manage";

  export default {
        name: 'CusAccountModule',
        mixins: [QueryMixins],
        components: {},
        data() {
            return {

                form: {},
                btnDisabled: false,
                dialogFormVisible: false,
                formLabelWidth: '120px',
                typelist: [
                    {value: '0', label: '公账'},
                    {value: '1', label: '私账'}
                ],

                //表单校验
                rules: {
                    bankName: [
                        {required: true, message: '请输入开户行', trigger: 'blur'},
                        {max: 60, message: '长度不能超过60', trigger: 'change'},
                    ],
                    accountName: [
                        {required: true, message: '请输入开户名', trigger: 'blur'},
                        {max: 20, message: '长度不能超过20', trigger: 'change'},
                    ],
                    account: [
                        {required: true, message: '请输入开户账号', trigger: 'blur'},
                        {max: 70, message: '长度不能超过70', trigger: 'change'},
                    ],
                    cur: [
                        {required: true, message: '请选择币种', trigger: 'change'},

                    ],
                    taxPropetry: [
                        {required: true, message: '请选择纳税人规模', trigger: 'change'},

                    ],
                    accountType: [
                        {required: true, message: '请选择账户类型', trigger: 'change'},

                    ],


                },

                row: {},
                visible: false,
                columns: [
                    {
                        prop: 'action',
                        label: '操作',

                    },
                    {
                        prop: 'bankName',
                        label: '开户行',

                        minWidth: 80
                    },
                    {
                        prop: 'accountName',
                        label: '开户名',
                        sortable: 'custom',
                        minWidth: 100
                    },
                    {
                        prop: 'account',
                        label: '开户账号',
                        minWidth: 80,
                        sortable: 'custom'
                    },
                    {
                        prop: 'cur',
                        label: '币种',
                        minWidth: 80,
                        sortable: 'custom'
                    },
                    {
                        prop: 'accountType',
                        label: '账户类型',
                        minWidth: 80,
                        sortable: 'custom'
                    },

                    {
                        prop: 'taxNumber',
                        label: '国税税务登记号',
                        sortable: 'custom'
                    },
                    {
                        prop: 'taxPropetry',
                        label: '纳税人规模',
                        minWidth: 80,
                        sortable: 'custom'
                    },
                    {
                        prop: 'remark',
                        label: '备注',
                        minWidth: 80,
                        sortable: 'custom'
                    },
                ],
                url: {
                    list: '/customer/customerBank/list',
                    getbyid: '/customer/customerBank/queryById',
                    jinyong: '/customer/customerBank/disable',
                    qiyong: '/customer/customerBank/qiyong',
                    add: '/customer/customerBank/add',
                    edit: '/customer/customerBank/edit'
                }

            }
        },
        mounted() {
            this.handleSearch()
        },
        methods: {


            handleQy(row) {
                this.$confirm(`确定启用账户${row.accountName}`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'success',
                    closeOnClickModal: false,
                }).then(() => {
                    getAction(this.url.qiyong, {id: row.id}).then(res => {
                        this.$message({
                            type: 'success',
                            message: res.message
                        })
                        this.handleSearch()
                    })
                }).catch(err => {
                    this.$message.error(err.message);
                })
            },
            handleJy(row) {
                this.$confirm(`确定禁用账户${row.accountName}`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'success',
                    closeOnClickModal: false,
                }).then(() => {
                    getAction(this.url.jinyong, {id: row.id}).then(res => {
                        this.$message({
                            type: 'success',
                            message: res.message
                        })
                        this.handleSearch()
                    })
                }).catch(err => {
                    this.$message.error(err.message);
                })
            },
            handleAdd() {
                // let total = this.ipagination.total;
                // if (total >= 10) {
                //     this.$message.warning('最多添加十个银行账号');
                //     return false;
                // }
                this.form = {};
                this.dialogFormVisible = true;
                this.btnDisabled = false;
            },
            handleEdit(row) {
                getAction(this.url.getbyid, {id: row.id})
                    .then(res => {
                        this.form = res.result;
                    })
                //显示弹窗，给对象赋值
                this.dialogFormVisible = true
                this.btnDisabled = false;
            },
            //新增或修改
            saveAccount() {
                //给客户id赋值
                this.btnDisabled = true;
                this.form.cusId = this.$route.query.id;
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        if (this.form.id) {
                            //有id，编辑
                            putAction(this.url.edit, this.form)
                                .then(res => {
                                    if (res.success) {
                                        this.$message.success(res.message);
                                        //关闭弹窗
                                        this.dialogFormVisible = false;
                                        this.handleQuery();
                                    } else {
                                        this.btnDisabled = false;
                                    }
                                }).catch(err => {
                                this.btnDisabled = false;
                            })
                        } else {
                            //新增
                            postAction(this.url.add, this.form)
                                .then(res => {
                                    if (res.success) {
                                        this.$message.success(res.message);
                                        this.dialogFormVisible = false;
                                        this.handleQuery();
                                    } else {
                                        this.btnDisabled = false;
                                    }
                                }).catch(err => {
                                this.btnDisabled = false;
                            })
                        }
                    } else {
                        this.btnDisabled = false;
                    }
                })
            },
            init() {
                let id = this.$route.query.id;
                this.queryForm.cusId = id;


            }
        },

        created() {

            this.init();
        },
    computed : {
      buttonShowList() {
        return {
          'add':this.$btnIsShow('customer/modules/CusAccountModule','0','新增'),
          'edit':this.$btnIsShow('customer/modules/CusAccountModule','1','编辑'),
          'dis':this.$btnIsShow('customer/modules/CusAccountModule','1','禁用'),
          'en':this.$btnIsShow('customer/modules/CusAccountModule','1','启用'),
        }
      }
    }
    }
</script>

<style lang="scss" scoped>

</style>
