<template>
  <el-dialog
      :title="title"
      :visible="visible"
      width="30%"
      custom-class="c-custom-dialog"
      :before-close="handleClose"
      append-to-body
      :close-on-click-modal="false">
    <el-form class="addEditBox" :model="form" :rules="rules" ref="form" label-width="100px" size="medium">
      <el-form-item prop="supplierId" label="供应商" :label-width="formLabelWidth">
        <f-select v-model="form.supplierId" :is-need="form.supplierId" dict="supplier_id"></f-select>
      </el-form-item>

      <el-form-item prop="takeFee" label="提货费" :label-width="formLabelWidth">
        <el-input v-model="form.takeFee" type="number"></el-input>
      </el-form-item>


      <el-form-item prop="driverId" label="选择内部司机" :label-width="formLabelWidth">
        <el-select clearable filterable v-model="form.driverId"
                   @change="handleChangeDriverName(form.driverId,index)">
          <el-option v-for="(i,d) in driverInfoList" :value="i.value" :label="i.label" :key="d"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item prop="driverName" label=" " :label-width="formLabelWidth">
        <el-input v-model="form.driverInfoStr" type="textarea" placeholder="请输入司机姓名、手机号、车牌；回车后自动识别" @keydown.enter.native="handleDistinguish
"></el-input>
      </el-form-item>

      <el-form-item prop="driverName" label="司机姓名" :label-width="formLabelWidth">
        <el-input v-model="form.driverName" maxlength="5" show-word-limit></el-input>
      </el-form-item>

      <el-form-item prop="driverPhone" label="司机电话" :label-width="formLabelWidth">
        <el-input v-model="form.driverPhone" maxlength="11" show-word-limit></el-input>
      </el-form-item>

      <el-form-item prop="driverCarNumber" label="车号" :label-width="formLabelWidth">
        <el-input v-model="form.driverCarNumber" maxlength="10" show-word-limit></el-input>
      </el-form-item>


    </el-form>
    <span slot="footer">
            <el-button size="medium" @click="handleClose">取消</el-button>
            <el-button type="primary" size="medium" @click="handleSubmit">确认</el-button>
        </span>
  </el-dialog>
</template>

<script>
import {postAction} from '@/api/manage'
import {mobilePattern} from "../../../utils/pattern";

export default {
  name: 'jiapai',
  props: {
    visible: {
      type: Boolean
    },
    takeIds: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: '派车完成'
    }
  },
  data() {
    return {
      form: {},
      formLabelWidth: '100px',
      rules: {
        supplierId: [
          {required: true, message: '请选择供应商', trigger: 'change'},
        ],
        takeFee: [
          {required: true, message: '请输入运费', trigger: 'change'},
        ],
        // useCarType: [
        //   {required: true, message: '请选择派车类型', trigger: 'change'},
        // ],
        // workType: [
        //   {required: true, message: '请选择车辆作业性质', trigger: 'change'},
        // ],
        // driverName: [
        //   {required: true, message: '请输入司机姓名', trigger: 'change'},
        // ],
        driverPhone: [
          {required: true, message: '请输入司机电话', trigger: 'change'},
          {pattern: mobilePattern, message: '手机格式不对', trigger: 'blur'}
        ],
        driverCarNumber: [
          {required: true, message: '请输入司机车号', trigger: 'change'},
        ],
        driverInfo: [
          {required: true, message: '请输入司机信息', trigger: 'change'},
        ],
        // carType: [
        //   {required: true, message: '请选择车辆类型', trigger: 'change'},
        // ],
        //
        // outNo: [
        //   {required: true, message: '请选择出库编码', trigger: 'change'},
        // ],
        // outNoList: [
        //   {required: true, message: '请选择出库编码', trigger: 'change'},
        // ],
      },
      url: {
        batchUpdateUrl: "/warehouse/takeDelivery/batchUpdate",
        queryDriverInfoDropDownList: '/warehouse/driverInfo/queryDriverInfoDropDownList',

      },
      driverInfoMap: {},
      driverInfoList: [],
    }
  },
  watch: {
    visible(val) {
      if (val && this.takeIds) {
        //勾选的订单号进行赋值
        this.$set(this.form, 'takeIds', this.takeIds);
        let list = this.takeIds.split(",");
        this.$set(this.form, 'outNoList', []);
        postAction(this.url.queryDriverInfoDropDownList, list)
            .then(res => {
              this.driverInfoList = res.result.list;
              this.driverInfoMap = this.listToMap(this.driverInfoList, 'value');
              if (res.result.one && this.title === '派车完成') {
                this.$set(this.form, 'supplierId', res.result.one.supplierId);
                this.$set(this.form, 'takeFee', res.result.one.takeFee);
              }

            })
      }
    },
  },
  methods: {
    handleChangeDriverName(val) {
      let obj = this.driverInfoMap[val];
      if (obj) {
        let driverInfo = obj['driverInfo'];
        this.$set(this.form, 'driverInfo', driverInfo);
        this.$set(this.form, 'driverName', obj['name']);
        this.$set(this.form, 'driverPhone', obj['phone']);
        this.$set(this.form, 'driverCarNumber', obj['carNumber']);
        this.$set(this.form, 'canEditDriver', true);
      }else {
        this.$set(this.form, 'canEditDriver', false);
      }

    },
    handleSubmit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          //派车完成 2

          this.$set(this.form, 'updateType', '2');
          this.$set(this.form, 'canEditDriver', false);
          if (this.title === '加派') {
            this.$set(this.form, 'updateType', 'add');
          }
          postAction(this.url.batchUpdateUrl, this.form).then((res) => {
            this.$message({
              type: 'success',
              message: res.message
            })
            this.form = {outNoList: []};
            this.handleClose();
            this.$emit('ok');
          })
        }
      })
    },
    //将集合转为map
    listToMap(list, name) {
      var map = {};
      for (let i = 0; i < list.length; i++) {
        map[list[i][name]] = list[i];
      }
      return map;
    },
    handleClose() {
      this.form = {};
      this.$emit('update:visible', false);
    },
    
    handleDistinguish() {
      if(this.form.driverInfoStr) {
        console.log('触发自动识别');
        let driverInfoStr = this.form.driverInfoStr.replace(/[, ，、\n]/g, '');
        console.log(driverInfoStr, 'driverInfoStr')
        const pattern = /[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽赣贵粤青藏川宁琼使领][A-Z][A-Z0-9]{4}[A-Z0-9挂学警港澳]{1}/g
        let driverCarNumberList = driverInfoStr.match(pattern) || []
        let driverCarNumber = (driverCarNumberList && driverCarNumberList.length && driverCarNumberList[0]) || ''
        let indexN = driverInfoStr.indexOf(driverCarNumber)
        if(driverCarNumber && indexN !== -1) {
          let driverCarNumber2 = driverInfoStr.charAt(indexN + driverCarNumber.length);
          if(driverCarNumber2 && driverCarNumber2 !== '1' && !(/[\u4e00-\u9fff]/.test(driverCarNumber2))) {
            driverCarNumber = driverCarNumber + driverCarNumber2
          }
          console.log(driverInfoStr.charAt(indexN + driverCarNumber.length), '匹配车牌的下一位字符')
        }
        driverCarNumber && (this.form.driverCarNumber = driverCarNumber);
        driverInfoStr = driverInfoStr.replace(new RegExp(driverCarNumber, 'g'), "");
        console.log('车牌是',driverCarNumber)
        const phoneRegex = /(1[3|4|5|7|8]\d{9})/g;
        let driverPhoneList = driverInfoStr.match(phoneRegex) || []
        let driverPhone = (driverPhoneList && driverPhoneList.length && driverPhoneList[0]) || ''
        driverPhone && (this.form.driverPhone = driverPhone);
        console.log('手机号是', driverPhone)
        driverInfoStr = driverInfoStr.replace(new RegExp(driverPhone, 'g'), "");
        driverInfoStr && driverCarNumber && driverPhone && (this.form.driverName = driverInfoStr);
        console.log('名称是', driverInfoStr)
      }
    }
  },
  created() {
  }
}
</script>

<style lang="scss" scoped>
::v-deep
.el-dialog__body {
  padding: 20px 40px 20px 6px;
}

.addEditBox {
  max-height: 615px;
  overflow: scroll;
}
</style>
