<template>
    <el-dialog
            title="装柜完成"
            :visible="visible"
            width="30%"
            custom-class="c-custom-dialog"
            append-to-body
            :before-close="handleClose"
            append-to-body
            :close-on-click-modal="false">
        <el-form class="addEditBox" :model="form" :rules="rules" ref="form" label-width="100px" size="medium">


            <el-form-item prop="ossFileList" label="装柜图片" :label-width="formLabelWidth">
                <f-upload v-model="form.ossFileList" list-type="picture-card" :dir="'warehouse'" :isUseOss="true" accept=".jpg,.jpeg,.png,.pneg,.gif,.bmp"></f-upload>
            </el-form-item>

            <el-form-item prop="remark" label="装柜备注" :label-width="formLabelWidth">
                <el-input v-model="form.remark" maxlength="500" show-word-limit type="textarea"></el-input>
            </el-form-item>

        </el-form>
        <span slot="footer">
            <el-button size="medium" @click="handleClose">取消</el-button>
            <el-button type="primary" size="medium" @click="handleSubmit" v-if="form.status==='2'">装柜完成</el-button>
            <el-button type="primary" size="medium" @click="updateWarehouseOutRemarkAndPic">修改</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import {putAction} from '@/api/manage'

    export default {
        name: 'editContainerLoadedDialog',
        props: {
            visible: {
                type: Boolean
            },
            id:{
                type:String,
                default:''
            },
            busNo:{
                type:String,
                default:''
            },
            row:{
                type:Object,
                default:()=>{}
            }
        },
        data() {
            return {
                form: {},
                formLabelWidth:'100px',
                rules: {
                    remark: [
                        {required: true, message: '请输入装柜备注', trigger: 'change'},
                    ],
                    ossFileList: [
                        {required: true, message: '请上传装柜图片', trigger: 'change'},
                    ],
                },
                url: {
                    //装柜完成
                    updateWarehouseOutToLoadedStatus:'/warehouse/stock/updateWarehouseOutToLoadedStatus',
                    updateWarehouseOutRemarkAndPic:'/warehouse/stock/updateWarehouseOutRemarkAndPic',
                },

            }
        },
        watch: {
            visible(val) {
                if (val && this.id) {
                    //勾选的订单号进行赋值
                    this.$set(this.form,'id',this.id);
                    this.$set(this.form,'outOrderly',this.busNo);

                    this.form = JSON.parse(JSON.stringify(this.row));
                    // this.$set(this.form,'ossFileList',[]);
                }
            },
        },
        methods: {
            handleSubmit() {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        putAction(this.url.updateWarehouseOutToLoadedStatus, this.form).then((res) => {
                            this.$message({
                                type: 'success',
                                message: res.message
                            })
                            this.handleClose();
                            this.$emit('ok');
                        })
                    }
                })
            },
            updateWarehouseOutRemarkAndPic() {
                putAction(this.url.updateWarehouseOutRemarkAndPic, this.form).then((res) => {
                    this.$message({
                        type: 'success',
                        message: res.message
                    })
                    this.handleClose();
                    this.$emit('ok');
                })
            },
            handleClose() {
                this.$emit('update:visible', false)
            }
        },
        created() {
        }
    }
</script>

<style lang="scss" scoped>
    ::v-deep
    .el-dialog__body {
        padding: 20px 40px 20px 6px;
    }
    .addEditBox {
      max-height: 615px;
      overflow: scroll;
    }
</style>
