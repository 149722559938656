<template>
  <!-- 活动配置-费用类的基础信息 -->
  <el-form :model="baseInfo" :rules="rules" ref="baseInfo" label-width="auto">
    <el-card class="box-card">
      <div slot="header">
        <span>基础信息</span>
      </div>
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item class="flex_form_item" prop="feeId" :rules="rules.feeId" label="费用名称">
            <f-select :dict="'bd_fee_type'" :father-val="baseInfo.feeId" :is-need="baseInfo.feeId" v-model="baseInfo.feeId" autocomplete="off" @changet="changeFee"></f-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item class="flex_form_item" prop="feeText" :rules="rules.feeText" label="费用描述">
            <el-input v-model.trim="baseInfo.feeText"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item class="flex_form_item" prop="source" :rules="rules.source" label="费用来源">
            <f-select :dataList="sourceList" v-model="baseInfo.source"
                      :isNeed="baseInfo.source">
            </f-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item class="flex_form_item" prop="target" :rules="rules.target" label="费用去向">
            <f-select :dataList="targetList" v-model="baseInfo.target"
                      :isNeed="baseInfo.target">
            </f-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item class="flex_form_item" prop="type" :rules="rules.type" label="存在同名费用后">
            <f-select :dataList="typeList" v-model="baseInfo.type"
                      :isNeed="baseInfo.type">
            </f-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item class="flex_form_item" :rules="rules.price" label="优惠金额">
            <el-input v-model.trim="baseInfo.price" type="number"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item class="flex_form_item" :rules="rules.mathOperations" label="优惠方式">
            <f-select :dataList="mathOperationsList" v-model="baseInfo.mathOperations"
                      :isNeed="baseInfo.mathOperations">
            </f-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item class="flex_form_item" prop="minPrice" :rules="rules.minPrice" label="低消">
            <el-input v-model.trim="baseInfo.minPrice" type="number"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item class="flex_form_item" prop="maxPrice" :rules="rules.maxPrice" label="最大">
            <el-input v-model.trim="baseInfo.maxPrice" type="number"></el-input>
          </el-form-item>
        </el-col>
          <el-col :span="8">
          <el-form-item class="flex_form_item" prop="feeCreateTime" :rules="rules.feeCreateTime" label="费用录入时间">
             <el-date-picker v-model="baseInfo.feeCreateTime" type="datetime" size="small" style="width: 100%;"
            value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss" placeholder="请选择时间">
          </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
    </el-card>
  </el-form>
</template>
<script>
import { getAction } from '@/api/manage';
export default {
  components: {
  },
  props: {
    baseInfoInit: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {
      baseInfo: {},
      rules: {
        feeId: [
          { required: true, message: '请选择费用名称', trigger: "blur" },
        ],
        feeUse: [
          { required: true, message: '请选择费用用途', trigger: "blur" },
        ],
        price: [
          { required: true, message: '请输入费用报价', trigger: "blur" },
        ],
        moneyType: [
          { required: true, message: '请选择币种', trigger: "change" },
        ],
        unit: [
          { required: true, message: '请输入单位', trigger: "blur" },
        ],
        writeDay: [
          { required: true, message: '请输入录入节点', trigger: "blur" },
        ],
        isBatch: [
          { required: true, message: '请选择是否分批', trigger: "change" },
        ],
        isForceChoose: [
          { required: true, message: '请选择是否强制计算费用', trigger: "change" },
        ],
      },
      //来源列表
      sourceList: [
        { label: '应收', value: '1'},
        { label: '成本', value: '2'},
        { label: '应付', value: '3'},
      ],
      //去向列表
      targetList: [
        { label: '同步到应收', value: '1'},
        { label: '同步到成本', value: '2'},
        { label: '同步到应付', value: '3'},
        { label: '同步到成本+应收', value: '4'},
      ],
      //存在同名费用怎么处理
      typeList: [
        { label: '新增', value: '1'},
        { label: '覆盖最近一行', value: '2'},
        { label: '覆盖所有', value: '3'},
        { label: '存在即忽略', value: '4'},
      ],
      //优惠方式
      mathOperationsList: [
        { label: '加+', value: '1'},
        { label: '减-', value: '2'},
        { label: '乘*', value: '3'},
        { label: '除/', value: '4'},
      ],
    }
  },
  watch: {
    baseInfoInit: {
      deep: true,
      immediate: true,
      handler(val) {
        this.baseInfo = val;
      }
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
    changeFee(val) {
      this.baseInfo.feeName = val;
    },
    handleSubmit() {
      const that = this;
      return new Promise(function (resolve, reject) {
        that.$refs.baseInfo.validate((valid) => {
          if (valid) {
            resolve(that.baseInfo);
          } else {
            reject();
          }
        })
      });
    },
  }
}
</script>
<style lang="scss" scoped>

</style>
