<!--渠道设置-->
<template>
    <div class="main-full-content">
        <el-form class="multiline_select " ref="form" :model="queryForm" label-width="120px" size="medium">
            <el-card class="box-card">
                <el-row class="inputs_row">
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="so">
                            <el-input v-model="queryForm.so" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="起运港">
                            <f-select v-model="queryForm.pol"
                                      :isNeed="queryForm.pol"
                                      placeholder="请选择" filterable clearable :dict="'bd_port'">
                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="目的港">
                            <f-select v-model="queryForm.podEnd"
                                      :isNeed="queryForm.podEnd"
                                      placeholder="请选择" filterable clearable :dict="'bd_port'">
                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="渠道">
                            <f-select v-model="queryForm.channelId"
                                      :isNeed="queryForm.channelId"
                                      placeholder="请选择" filterable clearable :dict="'channelId'">
                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="操作">
                            <f-select v-model="queryForm.operateId"
                                      :isNeed="queryForm.operateId"
                                      placeholder="请选择" filterable clearable :dict="'operationsId'">
                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="供应商">
                            <f-select v-model="queryForm.supplierId"
                                      :isNeed="queryForm.supplierId"
                                      placeholder="请选择" filterable clearable :dict="'bookAgent_id'">
                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="是否排柜">
                            <f-select v-model="queryForm.isContainerId"
                                      :isNeed="queryForm.isContainerId"
                                      placeholder="请选择" filterable clearable :dict="'is_default'">
                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label-width="30px">
                            <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
                            <el-button plain @click="handleReset">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>
        </el-form>
        <el-card class="box-card last_card">
            <div class="f-p-b-15">
                <el-button type="primary" size="medium" icon="el-icon-plus" @click="handleAdd" v-if="$btnIsShow('channel/ChannelBulkcargoPrice','0','渠道新增')">渠道新增
                </el-button>
            </div>
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="61vh"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange">
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'action'">
                        <el-button type="text" @click="handleEdit(scope.row)"
                                   v-if="buttonShowList['channelEdit']">渠道编辑
                        </el-button>
                        <el-button type="text" @click="handleOpeEdit(scope.row)"
                                   v-if="buttonShowList['opeEdit']">操作编辑
                        </el-button>
                        <el-button type="text" @click="handleDele(scope.row)"
                                   v-if="buttonShowList['del']">删除
                        </el-button>
                        <el-button type="text" @click="fenPei(scope.row)"
                                   v-if="buttonShowList['fenOpe']">分配操作
                        </el-button>
                        <el-button type="text" @click="quXiao(scope.row)"
                                   v-if="buttonShowList['cancelFen']">取消分配
                        </el-button>
                        <el-button type="text" @click="caoZuoSure(scope.row)"
                                   v-if="buttonShowList['opeConfirm']">操作确认
                        </el-button>
                        <el-button type="text" @click="caoZuoBack(scope.row)"
                                   v-if="buttonShowList['opeBack']">操作退回
                        </el-button>
                    </template>
                    <template v-else-if="scope.column.property === 'shipCompanyId'">
                        <span>{{parseDict(scope.row.shipCompanyId,'ship_company')}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'pol'">
                        <span>{{parseDict(scope.row.pol,'bd_port')}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'transferPort'">
                        <span>{{parseDict(scope.row.transferPort,'bd_port')}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'podEnd'">
                        <span>{{parseDict(scope.row.podEnd,'bd_port')}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'isFling'">
                        <span>{{parseDict(scope.row.isFling,'is_default')}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'isJump'">
                        <span>{{parseDict(scope.row.isJump,'is_default')}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'isAms'">
                        <span>{{parseDict(scope.row.isAms,'is_default')}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'isAmsMore'">
                        <span>{{parseDict(scope.row.isAmsMore,'is_default')}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'isCangdan'">
                        <span>{{parseDict(scope.row.isCangdan,'is_default')}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'operateId'">
                        <span>{{parseDict(scope.row.operateId,'operationsId')}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'operateSure'">
                        <span>{{parseDict(scope.row.operateSure,'is_default')}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'channelId'">
                        <span>{{parseDict(scope.row.channelId,'channelId')}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'supplierId'">
                        <span>{{parseDict(scope.row.supplierId,'bookAgent_id')}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'containerId'">
                        <span v-if="scope.row.containerId && scope.row.containerId.length>0">是-{{scope.row.week}} </span>
                        <span v-else>否</span>
                    </template>

                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange">
                </el-pagination>
            </el-row>
        </el-card>

        <el-dialog :title="form.id?'渠道编辑':'渠道新增'" :visible.sync="dialogFormVisible" :close-on-click-modal="false" :before-close="handleClose"
                   v-if="dialogFormVisible">
            <el-form class="addEditBox" :model="form" :rules="rules" ref="form">
                <el-row class="inputs_row">
                    <el-col :span="12">
                        <el-form-item label="渠道人员" :label-width="formLabelWidth" prop="channelId">
                            <f-select :is-need="form.channelId"
                                      v-model="form.channelId"
                                      placeholder="请选择" filterable clearable :dict="'channelId'">
                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="渠道名称" :label-width="formLabelWidth" prop="channelNameId">
                            <f-select :is-need="form.channelNameId"
                                      v-model="form.channelNameId"
                                      placeholder="请选择" filterable clearable :dict="'channel_set'"
                                      @changet="getChannelName"
                                    >
                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="so仓位号" :label-width="formLabelWidth" prop="so">
                            <el-input v-model="form.so" autocomplete="off" placeholder="多个仓位号请逗号或者空格隔开"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="柜号" :label-width="formLabelWidth" prop="containerNo">
                            <el-input v-model="form.containerNo" autocomplete="off"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="航线" :label-width="formLabelWidth" prop="osLine">
                            <el-input v-model="form.osLine" autocomplete="off"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="船司" :label-width="formLabelWidth" prop="shipCompanyId">
                            <f-select :is-need="form.shipCompanyId"
                                      v-model="form.shipCompanyId"
                                      placeholder="请选择" filterable clearable :dict="'ship_company'">
                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="起运港" :label-width="formLabelWidth" prop="pol">
                            <f-select :is-need="form.pol"
                                      v-model="form.pol"
                                      placeholder="请选择" filterable clearable :dict="'bd_port'">
                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="中转港" :label-width="formLabelWidth" prop="transferPort">
                            <f-select :is-need="form.transferPort"
                                      v-model="form.transferPort"
                                      placeholder="请选择" filterable clearable :dict="'bd_port'">
                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="目的港" :label-width="formLabelWidth" prop="podEnd">
                            <f-select :is-need="form.podEnd"
                                      v-model="form.podEnd"
                                      placeholder="请选择" filterable clearable :dict="'bd_port'">
                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="柜型" :label-width="formLabelWidth" prop="size">
                            <f-select :is-need="form.size"
                                      v-model="form.size"
                                      placeholder="请选择" filterable clearable :dict="'cabinet_type'">
                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="截SI" :label-width="formLabelWidth" prop="si">
                            <el-date-picker
                                    class="f-flex-1"
                                    v-model="form.si"
                                    type="datetime"
                                    size="small"
                                    placeholder="请选择时间"
                                    default-time="00:00:00"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    :picker-options="editStartOptions"
                                    format="yyyy-MM-dd HH:mm:ss"
                                    style="width: 100%;"
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="ETD" :label-width="formLabelWidth" prop="etd">
                            <el-date-picker
                                    class="f-flex-1"
                                    v-model="form.etd"
                                    type="datetime"
                                    size="small"
                                    placeholder="请选择时间"
                                    default-time="00:00:00"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    :picker-options="editStartOptions"
                                    format="yyyy-MM-dd HH:mm:ss"
                                    style="width: 100%;"
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="船名航次" :label-width="formLabelWidth" prop="shipNo">
                            <el-input v-model="form.shipNo" autocomplete="off"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="开港日期" :label-width="formLabelWidth" prop="startPortTime">
                            <el-date-picker
                                    class="f-flex-1"
                                    v-model="form.startPortTime"
                                    type="datetime"
                                    size="small"
                                    placeholder="请选择时间"
                                    default-time="00:00:00"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    :picker-options="editStartOptions"
                                    format="yyyy-MM-dd HH:mm:ss"
                                    style="width: 100%;"
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="延船日期" :label-width="formLabelWidth" prop="delayTime">
                            <el-date-picker
                                    class="f-flex-1"
                                    v-model="form.delayTime"
                                    type="datetime"
                                    size="small"
                                    placeholder="请选择时间"
                                    default-time="00:00:00"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    :picker-options="editStartOptions"
                                    format="yyyy-MM-dd HH:mm:ss"
                                    style="width: 100%;"
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="是否甩柜" :label-width="formLabelWidth" prop="isFling">
                            <f-select :is-need="form.isFling"
                                      v-model="form.isFling"
                                      placeholder="请选择" filterable clearable :dict="'is_default'">
                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="是否跳港" :label-width="formLabelWidth" prop="isJump">
                            <f-select :is-need="form.isJump"
                                      v-model="form.isJump"
                                      placeholder="请选择" filterable clearable :dict="'is_default'">
                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="供应商/代理" :label-width="formLabelWidth" prop="supplierId">
                            <f-select :is-need="form.supplierId"
                                      v-model="form.supplierId"
                                      :fatherVal="form.supplierId"
                                      placeholder="请选择" filterable clearable :dict="'bookAgent_id'">
                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="O-F(海运费)" :label-width="formLabelWidth" prop="of">
                            <el-input v-model="form.of" autocomplete="off"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="ams可否自报" :label-width="formLabelWidth" prop="isAms">
                            <f-select :is-need="form.isAms"
                                      v-model="form.isAms"
                                      :fatherVal="form.isAms"
                                      placeholder="请选择" filterable clearable :dict="'is_default'">
                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="ams可否报多个" :label-width="formLabelWidth" prop="isAmsMore">
                            <f-select :is-need="form.isAmsMore"
                                      v-model="form.isAmsMore"
                                      :fatherVal="form.isAmsMore"
                                      placeholder="请选择" filterable clearable :dict="'is_default'">
                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="舱单可否自报" :label-width="formLabelWidth" prop="isCangdan">
                            <f-select :is-need="form.isCangdan"
                                      v-model="form.isCangdan"
                                      :fatherVal="form.isCangdan"
                                      placeholder="请选择" filterable clearable :dict="'is_default'">
                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="合约" :label-width="formLabelWidth" prop="contract">
                            <f-select :is-need="form.contract"
                                      v-model="form.contract"
                                      :fatherVal="form.contract"
                                      placeholder="请选择" filterable clearable :dict="'contract'">
                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="签单方式" :label-width="formLabelWidth" prop="signAbill">
                            <f-select :is-need="form.signAbill"
                                      v-model="form.signAbill"
                                      :fatherVal="form.signAbill"
                                      placeholder="请选择" filterable clearable :dict="'signAbill_type'">
                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="免柜期" :label-width="formLabelWidth" prop="mianGuiQi">
                            <el-input v-model="form.mianGuiQi" autocomplete="off" type="number"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="换单费" :label-width="formLabelWidth" prop="huanDanFee">
                            <el-input v-model="form.huanDanFee" autocomplete="off" type="number"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="目的港费用" :label-width="formLabelWidth" prop="podEndFee">
                            <el-input v-model="form.podEndFee" autocomplete="off" type="number"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="押金" :label-width="formLabelWidth" prop="deposit">
                            <el-input v-model="form.deposit" autocomplete="off" type="number"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="亏舱费" :label-width="formLabelWidth" prop="kuiCangFee">
                            <el-input v-model="form.kuiCangFee" autocomplete="off" type="number"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="备注" :label-width="formLabelWidth" prop="remark">
                            <el-input v-model="form.remark" autocomplete="off"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="操作人员" :label-width="formLabelWidth" prop="operateId">
                            <f-select :is-need="form.operateId"
                                      v-model="form.operateId"
                                      :fatherVal="form.operateId"
                                      placeholder="请选择" filterable clearable :dict="'operationsId'">
                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="so附件" :label-width="formLabelWidth" prop="attachFileList">
                            <f-upload v-model="form.attachFileList" autocomplete="off"></f-upload>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="预约说明" :label-width="formLabelWidth" prop="yuyueInfo">
                            <el-input v-model="form.yuyueInfo" autocomplete="off" maxlength="255" show-word-limit type="textarea"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="medium" @click="handleClose()">取 消</el-button>
                <el-button type="primary" size="medium" @click="handleSave()">确 定</el-button>
            </div>
        </el-dialog>


        <el-dialog :title="'操作编辑'" :visible.sync="dialogFormOperEditVisible" :close-on-click-modal="false" v-if="dialogFormOperEditVisible" :before-close="handleCloseOperEdit">
            <el-form class="addEditBox" :model="form" :rules="rules" ref="form">
                <el-row class="inputs_row">
                    <el-col :span="12">
                        <el-form-item label="促销命名" :label-width="formLabelWidth" prop="name">
                            <el-input v-model="form.name" autocomplete="off"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="促销标签" :label-width="formLabelWidth" prop="nameLabel">
                            <el-input v-model="form.nameLabel" autocomplete="off"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="柜号" :label-width="formLabelWidth" prop="containerNo">
                            <el-input v-model="form.containerNo" autocomplete="off"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="截货日期" :label-width="formLabelWidth" prop="jieGoodTime">
                            <el-date-picker
                                    class="f-flex-1"
                                    v-model="form.jieGoodTime"
                                    type="datetime"
                                    size="small"
                                    placeholder="请选择时间(截货日期需早于开船日期)"
                                    default-time="00:00:00"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    :picker-options="cutStartOptions"
                                    format="yyyy-MM-dd HH:mm:ss"
                                    style="width: 100%;"
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="装柜日期" :label-width="formLabelWidth" prop="loadingTime">
                            <el-date-picker
                                    class="f-flex-1"
                                    v-model="form.loadingTime"
                                    type="datetime"
                                    size="small"
                                    placeholder="请选择时间"
                                    default-time="00:00:00"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    :picker-options="editStartOptions"
                                    format="yyyy-MM-dd HH:mm:ss"
                                    style="width: 100%;"
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="截SI" :label-width="formLabelWidth" prop="si">
                            <el-date-picker
                                    class="f-flex-1"
                                    v-model="form.si"
                                    type="datetime"
                                    size="small"
                                    placeholder="请选择时间"
                                    default-time="00:00:00"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    :picker-options="editStartOptions"
                                    format="yyyy-MM-dd HH:mm:ss"
                                    style="width: 100%;"
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="ETD" :label-width="formLabelWidth" prop="etd">
                            <el-date-picker
                                    class="f-flex-1"
                                    v-model="form.etd"
                                    type="datetime"
                                    size="small"
                                    placeholder="请选择时间"
                                    default-time="00:00:00"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    :picker-options="editStartOptions"
                                    format="yyyy-MM-dd HH:mm:ss"
                                    style="width: 100%;"
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="开港日期" :label-width="formLabelWidth" prop="startPortTime">
                            <el-date-picker
                                    class="f-flex-1"
                                    v-model="form.startPortTime"
                                    type="datetime"
                                    size="small"
                                    placeholder="请选择时间"
                                    default-time="00:00:00"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    :picker-options="editStartOptions"
                                    format="yyyy-MM-dd HH:mm:ss"
                                    style="width: 100%;"
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="延船日期" :label-width="formLabelWidth" prop="delayTime">
                            <el-date-picker
                                    class="f-flex-1"
                                    v-model="form.delayTime"
                                    type="datetime"
                                    size="small"
                                    placeholder="请选择时间"
                                    default-time="00:00:00"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    :picker-options="editStartOptions"
                                    format="yyyy-MM-dd HH:mm:ss"
                                    style="width: 100%;"
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="是否甩柜" :label-width="formLabelWidth" prop="isFling">
                            <f-select :is-need="form.isFling"
                                      v-model="form.isFling"
                                      :fatherVal="form.isFling"
                                      placeholder="请选择" filterable clearable :dict="'is_default'">
                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="是否跳港" :label-width="formLabelWidth" prop="isJump">
                            <f-select :is-need="form.isJump"
                                      v-model="form.isJump"
                                      :fatherVal="form.isJump"
                                      placeholder="请选择" filterable clearable :dict="'is_default'">
                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="柜子方数" :label-width="formLabelWidth" prop="volume">
                            <el-input v-model="form.volume" autocomplete="off" type="number"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="剩余方数" :label-width="formLabelWidth" prop="surplusSo">
                            <el-input v-model="form.surplusSo" autocomplete="off" type="number"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="目的港仓库" :label-width="formLabelWidth" prop="podEndHouse">
                            <el-input v-model="form.podEndHouse" autocomplete="off"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="船司" :label-width="formLabelWidth" prop="shipCompanyId">
                            <f-select :is-need="form.shipCompanyId"
                                      v-model="form.shipCompanyId"
                                      :fatherVal="form.shipCompanyId"
                                      placeholder="请选择" filterable clearable :dict="'ship_company'">
                            </f-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="medium" @click="handleCloseOperEdit()">取 消</el-button>
                <el-button type="primary" size="medium" @click="handleSave('operation')">确 定</el-button>
            </div>
        </el-dialog>

        <el-dialog :title="'分配操作'" :visible.sync="dialogFormFenpeiOperVisible" :close-on-click-modal="false"
                   v-if="dialogFormFenpeiOperVisible">
            <el-form class="addEditBox" :model="form" :rules="rules" ref="form">
                <el-row class="inputs_row">
                    <el-col :span="12">
                        <el-form-item label="操作人员" :label-width="formLabelWidth" prop="operateId">
                            <f-select :is-need="form.operateId"
                                      v-model="form.operateId"
                                      :fatherVal="form.operateId"
                                      placeholder="请选择" filterable clearable :dict="'operationsId'">
                            </f-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="medium" @click="dialogFormFenpeiOperVisible = false">取 消</el-button>
                <el-button type="primary" size="medium" @click="handleFenpeiSave()">确 定</el-button>
            </div>
        </el-dialog>

    </div>
</template>

<script>
    import QueryMixins from "../../mixins/QueryMixins";
    import {deleteAction, getAction, postAction, putAction} from '@/api/manage'
    import {validateCmb, validateShuliang} from "../../utils/validator";

    export default {
        name: 'Potential',
        mixins: [QueryMixins],
        data() {
            return {
                editStartOptions: {
                    disabledDate: time => {
                        if (!this.queryForm.createTimeEnd) {
                            // time = new Date(time.replace(/-/g, '/'));
                            return time.getTime() < new Date(1970 - 1 - 1).getTime();   //禁止选择1970年以前的日期
                        } else {
                            return time.getTime() > new Date(this.queryForm.createTimeEnd);
                        }
                    }
                },

                // 截货日期必须要早于etd日期（开船日期）
                cutStartOptions: {
                  disabledDate: (time) => {
                    if (!this.form.etd) {
                      return time.getTime() < new Date(1970 - 1 - 1).getTime(); //禁止选择1970年以前的日期
                    } else {
                      return time.getTime() > new Date(this.form.etd);
                    }
                  },
                },
                dictCodes: ['ship_company', 'bd_port', 'is_default', 'operationsId', 'channelId','bookAgent_id'],
                rules: {
                    channelId: [
                        {required: true, message: '请选择渠道人员', trigger: 'change'},
                    ],
                    name: [
                        {max: 200, message: '长度不能超过200', trigger: 'blur'},
                    ],
                    nameLabel: [
                        {max: 50, message: '长度不能超过50', trigger: 'blur'},
                    ],
                    so: [
                        {required: true, message: '请输入仓位号', trigger: 'blur'},
                        {max: 50, message: '长度不能超过50', trigger: 'blur'},
                    ],
                    containerNo: [
                        {max: 50, message: '长度不能超过50', trigger: 'blur'},
                    ],
                    osLine: [
                        {max: 100, message: '长度不能超过100', trigger: 'blur'},
                    ],
                    shipCompanyId: [
                        {required: true, message: '请选择船司', trigger: 'change'},
                    ],
                    pol: [
                        {required: true, message: '请选择起运港', trigger: 'change'},
                    ],
                    podEnd: [
                        {required: true, message: '请选择目的港', trigger: 'change'},
                    ],
                    size: [
                        {required: true, message: '请选择柜型', trigger: 'change'},
                    ],
                    si: [
                        {required: true, message: '请选择截SI', trigger: 'change'},
                    ],
                    etd: [
                        {required: true, message: '请选择ETD', trigger: 'change'},
                    ],
                    shipNo: [
                        {required: true, message: '请输入船名航次', trigger: 'blur'},
                        {max: 50, message: '长度不能超过50', trigger: 'blur'},
                    ],
                    supplierId: [
                        {required: true, message: '请选择订舱代理', trigger: 'change'},
                    ],
                    of: [
                        {required: true, message: '请输入OF(海运费)', trigger: 'blur'},
                        {max: 50, message: '长度不能超过50', trigger: 'blur'},
                    ],
                    isAms: [
                        {required: true, message: '请选择ams可否自报', trigger: 'change'},
                    ],
                    isAmsMore: [
                        {required: true, message: '请选择ams可否报多个', trigger: 'change'},
                    ],
                    signAbill: [
                        {required: true, message: '请选择签单方式', trigger: 'change'},
                    ],
                    mianGuiQi: [
                        {validator: validateShuliang, trigger: 'change'}
                    ],
                    huanDanFee: [
                        {validator: validateCmb, trigger: 'change'}
                    ],
                    podEndFee: [
                        {validator: validateCmb, trigger: 'change'}
                    ],
                    deposit: [
                        {validator: validateCmb, trigger: 'change'}
                    ],
                    kuiCangFee: [
                        {validator: validateCmb, trigger: 'change'}
                    ],
                    remark: [
                        {max: 255, message: '长度不能超过255', trigger: 'blur'},
                    ],
                    operateNeedQuantity: [
                        {required: true, message: '请输入数量', trigger: 'blur'},
                        {validator: validateShuliang, trigger: 'change'}
                    ],
                    goodOkayTime: [
                        {required: true, message: '请选择货好时间', trigger: 'change'},
                    ],
                    volume: [
                        {validator: validateCmb, trigger: 'change'}
                    ],
                    surplusSo: [
                        {validator: validateCmb, trigger: 'change'}
                    ],
                    podEndHouse: [
                        {max: 255, message: '长度不能超过255', trigger: 'blur'},
                    ],

                },

                ids: [],
                row: {},
                form: {},
                initForm: {},// 编辑的初始form值，用于点击取消的数据返回
                formLabelWidth: '150px',
                //渠道新增 编辑
                dialogFormVisible: false,
                //操作新增
                dialogFormOperAddVisible: false,
                //操作编辑
                dialogFormOperEditVisible: false,
                //分配操作
                dialogFormFenpeiOperVisible: false,
                columns: [
                    {
                        prop: 'action',
                        label: '操作',
                        width: 100,
                        fixed: 'left'
                    },
                    {
                        prop: 'name',
                        label: '促销命名',
                        width: 100,
                        fixed: 'left'
                    },
                    {
                        prop: 'so',
                        label: 'so仓位号',
                        width: 90,
                        fixed: 'left'
                    },
                    {
                        prop: 'containerNo',
                        label: '柜号',
                        width: 90
                    },
                    {
                        prop: 'osLine',
                        label: '航线',
                        width: 100
                    },
                    {
                        prop: 'shipCompanyId',
                        label: '船司',
                        width: 90
                    },
                    {
                        prop: 'pol',
                        label: '起运港',
                        width: 120
                    },
                    {
                        prop: 'transferPort',
                        label: '中转港',
                        width: 120
                    },
                    {
                        prop: 'podEnd',
                        label: '目的港',
                        width: 120
                    },
                    {
                        prop: 'size',
                        label: '柜型',
                        width: 50
                    },
                    {
                        prop: 'goodOkayTime',
                        label: '货好时间',
                        sortable: 'custom',
                        width: 120
                    },
                    {
                        prop: 'loadingTime',
                        label: '装柜日期',
                        sortable: 'custom',
                        width: 120
                    },
                    {
                        prop: 'jieGoodTime',
                        label: '截货日期',
                        sortable: 'custom',
                        width: 120
                    },
                    {
                        prop: 'si',
                        label: '截SI',
                        sortable: 'custom',
                        width: 120
                    },
                    {
                        prop: 'etd',
                        label: 'ETD',
                        sortable: 'custom',
                        width: 120
                    },
                    {
                        prop: 'shipNo',
                        label: '船名航次',
                        width: 90,
                    },
                    {
                        prop: 'startPortTime',
                        label: '开港日期',
                        sortable: 'custom',
                        width: 90
                    },
                    {
                        prop: 'delayTime',
                        label: '延船日期',
                        sortable: 'custom',
                        width: 120

                    },
                    {
                        prop: 'isFling',
                        label: '是否甩柜',
                        width: 60
                    },
                    {
                        prop: 'isJump',
                        label: '是否跳港',
                        width: 60
                    },
                    {
                        prop: 'supplierId',
                        label: '供应商/代理',
                        width: 100
                    },
                    {
                        prop: 'of',
                        label: 'O-F(海运费)',
                        sortable: 'custom',
                        width: 100
                    },
                    {
                        prop: 'isAms',
                        label: 'ams可否自报',
                        width: 60
                    },
                    {
                        prop: 'isAmsMore',
                        label: 'ams可否报多个',
                        width: 60
                    },
                    {
                        prop: 'isCangdan',
                        label: '舱单可否自报',
                        width: 50
                    },
                    {
                        prop: 'signAbill',
                        label: '签单方式',
                        width: 50
                    },
                    {
                        prop: 'contract',
                        label: '合约',
                        width: 50
                    },
                    {
                        prop: 'mianGuiQi',
                        label: '免柜期',
                        sortable: 'custom',
                        width: 70
                    },
                    {
                        prop: 'huanDanFee',
                        label: '换单费',
                        sortable: 'custom',
                        width: 70
                    },
                    {
                        prop: 'podEndFee',
                        label: '目的港费用',
                        sortable: 'custom',
                        width: 60
                    },
                    {
                        prop: 'deposit',
                        label: '押金',
                        sortable: 'custom',
                        width: 50
                    },
                    {
                        prop: 'kuiCangFee',
                        label: '亏舱费',
                        sortable: 'custom',
                        width: 50
                    },
                    {
                        prop: 'remark',
                        label: '备注',
                        width: 100
                    },
                    {
                        prop: 'channelId',
                        label: '渠道',
                        width: 100
                    },
                    {
                        prop: 'operateId',
                        label: '操作',
                        width: 100
                    },
                    {
                        prop: 'operateSure',
                        label: '操作是否确认',
                        width: 50
                    },
                    {
                        prop: 'containerId',
                        label: '是否排柜',
                        width: 100
                    },
                ],
                url: {
                    list: '/sys/channelShipManage/list',
                    add: '/sys/channelShipManage/add',
                    edit: '/sys/channelShipManage/edit',
                    getById: '/sys/channelShipManage/queryById',
                    delete: '/sys/channelShipManage/delete',
                    cancel: '/sys/channelShipManage/cancel',
                    back: '/sys/channelShipManage/back',
                    opeAdd: '/sys/channelShipManage/opeAdd',
                },
            }
        },
        methods: {
            //渠道新增
            handleAdd() {
                if (this.$refs['form']) {
                    this.$refs['form'].clearValidate();
                }
                if(this.dictMap['channelId'][JSON.parse(localStorage.getItem("USERINFO")).id]) {
                  this.$set(this, 'form', {
                    channelId: JSON.parse(localStorage.getItem("USERINFO")).id
                  });
                } else {
                  this.$set(this, 'form', {});
                }
                this.$set(this.form, 'attachFileList', []);
                this.dialogFormVisible = true;
            },
            //渠道编辑
            handleEdit(row) {
                if (row.operateSure && row.operateSure === '1') {
                    this.$message.warning("操作已确认，无法再编辑");
                    return;
                }

                //将对象转为json字符串再转回对象
                //this.form = row; 复制的是指针 仍然指向这个对象row
                // this.form = JSON.parse(JSON.stringify(row));
                getAction('/sys/channelShipManage/queryById',{id:row.id}).then(res=>{
                    this.form=res.result;
                    this.initForm = res.result;
                    this.dialogFormVisible = true;
                })
                //初始表单提交值的保存

            },
            //操作编辑
            handleOpeEdit(row) {
                if (row.operateSure && row.operateSure === '1') {
                    this.$message.warning("操作已确认，无法再编辑");
                    return;
                }
                //将对象转为json字符串再转回对象
                //this.form = row; 复制的是指针 仍然指向这个对象row
                this.form = JSON.parse(JSON.stringify(row));
                this.initForm = row;  //初始表单提交值的保存
                this.dialogFormOperEditVisible = true;
            },

            handleDele(row) {
                if (row.operateSure && row.operateSure === '1') {
                    this.$message.warning("操作已确认，无法再删除");
                    return;
                }
                this.$confirm(`确定删除`, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "success",
                    closeOnClickModal: false,
                })
                    .then(() => {
                        deleteAction(this.url.delete, {id: row.id}).then((res) => {
                            this.$message({
                                type: "success",
                                message: res.message,
                            });
                            this.handleQuery();
                        });
                    })
                    .catch(() => {
                    });
            },
            handleClose() {
              this.dialogFormVisible = false;
              this.form = this.initForm;
            },
            handleCloseOperEdit() {
              this.dialogFormOperEditVisible = false;
              this.form = this.initForm;
            },

            handleSave(type) {
                // 操作编辑设置截货日期需早于开船日期限制
                if(type == 'operation' && this.form.etd && this.form.jieGoodTime && this.form.etd < this.form.jieGoodTime) {
                    this.$message.warning('截货日期需早于开船日期,请重新核对编辑!');
                    return;
                }
                this.$refs.form.validate(valid => {
                    if (valid) {
                        //编辑
                        if (this.form.id) {
                            putAction(this.url.edit, this.form).then((res) => {
                                if (res.success) {
                                    this.$message.success(res.message);
                                    //关闭弹窗
                                    this.dialogFormVisible = false;
                                    this.dialogFormOperEditVisible = false;
                                    this.handleQuery();
                                }
                            });
                        } else {
                            postAction(this.url.add, this.form).then((res) => {
                                if (res.success) {
                                    this.$message.success(res.message);
                                    //关闭弹窗
                                    this.dialogFormVisible = false;
                                    this.handleQuery();
                                }
                            });
                        }
                    }
                });
            },
            //分配操作
            fenPei(row) {
                if (row.operateSure && row.operateSure === '1') {
                    this.$message.warning("操作已确认，无法再分配");
                    return;
                }
                this.form = JSON.parse(JSON.stringify(row));
                this.dialogFormFenpeiOperVisible = true;
            },
            //确认分配操作
            handleFenpeiSave() {
                putAction(this.url.edit, this.form).then((res) => {
                    if (res.success) {
                        this.$message.success(res.message);
                        //关闭弹窗
                        this.dialogFormFenpeiOperVisible = false;
                        this.handleQuery();
                    }
                });
            },
            //取消操作分配
            quXiao(row) {
                if (row.operateSure && row.operateSure === '1') {
                    this.$message.warning("操作已确认，无法再取消");
                    return;
                }
                this.$confirm(`确定取消吗`, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "success",
                    closeOnClickModal: false,
                })
                    .then(() => {
                        getAction(this.url.cancel + '?id=' + row.id).then((res) => {
                            if (res.success) {
                                this.$message.success(res.message);
                                //关闭弹窗
                                this.dialogFormOperVisible = false;
                                this.handleQuery();
                            }
                        });
                    })
                    .catch(() => {
                    });
            },
            //操作确认
            caoZuoSure(row) {
                this.$confirm(`是否确认该船期`, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "success",
                    closeOnClickModal: false,
                })
                    .then(() => {
                        this.form.id = row.id;
                        this.form.operateSure = '1';
                        putAction(this.url.edit, this.form).then((res) => {
                            if (res.success) {
                                this.$message.success(res.message);
                                //关闭弹窗
                                this.dialogFormOperVisible = false;
                                this.handleQuery();
                            }
                        });
                    })
                    .catch(() => {
                    });
            },
            //操作退回
            caoZuoBack(row) {
                if(row.containerId && row.containerId.length>0){
                    this.$message.warning('该仓号已被柜子绑定无法退回！');
                    return;
                }

                this.$confirm(`是否退回该船期`, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "success",
                    closeOnClickModal: false,
                })
                    .then(() => {
                        getAction(this.url.back + '?id=' + row.id).then((res) => {
                            if (res.success) {
                                this.$message.success(res.message);
                                //关闭弹窗
                                this.dialogFormOperVisible = false;
                                this.handleQuery();
                            }
                        });
                    })
                    .catch(() => {
                    });
            },
            getChannelName(val){
                this.form.channelName = val;
            }
        }, created() {
            this.queryForm.isSo='1';
        },
      computed:{
          buttonShowList(){
            return {
              'channelEdit':this.$btnIsShow('channel/ChannelBulkcargoPrice','1','渠道编辑'),
              'opeEdit':this.$btnIsShow('channel/ChannelBulkcargoPrice','1','操作编辑'),
              'del':this.$btnIsShow('channel/ChannelBulkcargoPrice','1','删除'),
              'fenOpe':this.$btnIsShow('channel/ChannelBulkcargoPrice','1','分配操作'),
              'cancelFen':this.$btnIsShow('channel/ChannelBulkcargoPrice','1','取消分配'),
              'opeConfirm':this.$btnIsShow('channel/ChannelBulkcargoPrice','1','操作确认'),
              'opeBack':this.$btnIsShow('channel/ChannelBulkcargoPrice','1','操作退回'),
            }
          }
      }
    }
</script>
<style lang="scss" scoped>
    .addEditBox {
        max-height: 625px;
        overflow: scroll;
    }
</style>
