<template>
  <el-dialog
    title="发起提货"
    :visible="visible"
    width="50%"
    custom-class="c-custom-dialog"
    :before-close="handleClose"
    append-to-body
    :close-on-click-modal="false"
  >
    <div v-if="!addTakeDeliveryInfoFlag">
      <el-button type="primary" @click="addFormItem('0')"
        >继续增加提货</el-button
      >
      <div
        v-for="(item, index) in bacthForm.formList"
        :key="index"
        class="form_item_box"
      >
        <el-button
          type="primary"
          @click="addFormItem('1', index)"
          v-if="index !== 0"
          style="margin: 8px 0 0 8px"
          >删除提货</el-button
        >
        <el-form
          class="addEditBox"
          :model="bacthForm"
          :rules="rules"
          :ref="'form' + index"
          size="medium"
        >
          <!-- 提货地址信息-->
          <el-row class="flex_center" :gutter="30">
            <el-col :span="13">
              <el-form-item
                label="提货地址信息"
                key="contactId"
                :prop="'formList.' + index + '.contactId'"
                :label-width="formLabelWidth"
              >
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                  "
                >
                  <f-select
                    v-model="item.contactId"
                    :is-need="item.contactId"
                    :data-list="selectSupplierList"
                    :clear-select="clearSelect"
                    @changet="updateThinfo('0', index)"
                    style="width: 82%"
                  ></f-select>
                  <el-button
                    type="primary"
                    icon="el-icon-search"
                    @click="addSupInfoDialog()"
                    >新增</el-button
                  >
                </div>
              </el-form-item>
            </el-col>

            <el-col :span="13">
              <el-form-item
                label="公司"
                :label-width="formLabelWidth"
                key="thSupplier"
                :prop="'formList.' + index + '.thSupplier'"
              >
                <el-input
                  v-model="item.thSupplier"
                  maxlength="50"
                  show-word-limit
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="13">
              <el-form-item
                label="联系人"
                :label-width="formLabelWidth"
                key="contact"
                :prop="'formList.' + index + '.contact'"
              >
                <el-input
                  v-model="item.contact"
                  maxlength="50"
                  show-word-limit
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="13">
              <el-form-item
                :prop="'formList.' + index + '.contactTel'"
                label="联系电话"
                :label-width="formLabelWidth"
                key="contactTel"
              >
                <el-input
                  v-model="item.contactTel"
                  maxlength="50"
                  show-word-limit
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="13">
              <el-form-item
                label="地址"
                :label-width="formLabelWidth"
                key="contactAdd"
                :prop="'formList.' + index + '.contactAdd'"
              >
                <el-input
                  v-model="item.contactAdd"
                  maxlength="255"
                  show-word-limit
                  type="textarea"
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="13">
              <el-form-item
                :prop="'formList.' + index + '.isNeedSendTakeDriverPhone'"
                label="提货司机信息短信推送"
                :label-width="formLabelWidth"
                key="contactAdd"
              >
                <el-radio
                  v-model="item.isNeedSendTakeDriverPhone"
                  :label="'1'"
                  @click.native.prevent="radioClick4('1')"
                  >是</el-radio
                >
                <el-radio
                  v-model="form.isNeedSendTakeDriverPhone"
                  :label="'0'"
                  @click.native.prevent="radioClick4('0')"
                  >否</el-radio
                >
              </el-form-item>
            </el-col>

            <el-col :span="13">
              <el-form-item
                label="提货时间"
                :label-width="formLabelWidth"
                key="getsTime"
                :rules="rules.getsTime"
                :prop="'formList.' + index + '.getsTime'"
              >
                <el-date-picker
                  id="getTakeTime"
                  v-model="item.getsTime"
                  type="datetime"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="选择时间"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="13">
              <el-form-item
                key="isWeiban"
                label="是否需要尾板车"
                :label-width="formLabelWidth"
                :rules="rules.isWeiban"
                :prop="'formList.' + index + '.isWeiban'"
              >
                <el-select v-model="item.isWeiban" clearable filterable>
                  <el-option value="1" label="是"></el-option>
                  <el-option value="0" label="否"></el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="13">
              <el-form-item
                key="isBanyun"
                label="是否需要搬运装车"
                :label-width="formLabelWidth"
                :rules="rules.isBanyun"
                :prop="'formList.' + index + '.isBanyun'"
              >
                <f-select
                  v-model="item.isBanyun"
                  :isNeed="item.isBanyun"
                  :dict="'is_default'"
                ></f-select>
              </el-form-item>
            </el-col>

            <el-col :span="13">
              <el-form-item
                label="托盘数"
                :label-width="formLabelWidth"
                key="palletNum"
                :prop="'formList.' + index + '.palletNum'"
              >
                <el-input v-model="item.palletNum" type="number"></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="13">
              <el-form-item
                label="备注"
                :label-width="formLabelWidth"
                key="remark"
                :prop="'formList.' + index + '.remark'"
              >
                <el-input
                  v-model="item.remark"
                  maxlength="500"
                  show-word-limit
                  type="textarea"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <f-table
            border
            row-key="id"
            size="medium"
            class="c-th-has-bgcolor"
            :columns="columns"
            :data="item.orderList"
            :cell-style="{ 'text-align': 'center' }"
          >
            <template v-slot="scope">
              <template v-if="scope.column.property === 'diffCtn'">
                <el-form-item
                  :prop="
                    'formList.' +
                    index +
                    '.orderList.' +
                    scope.$index +
                    '.diffCtn'
                  "
                  :rules="rules.diffCtn"
                >
                  <el-input v-model="scope.row.diffCtn"></el-input>
                </el-form-item>
              </template>
              <template v-else-if="scope.column.property === 'diffKg'">
                <el-form-item
                  :prop="
                    'formList.' +
                    index +
                    '.orderList.' +
                    scope.$index +
                    '.diffKg'
                  "
                  :rules="rules.diffKg"
                >
                  <el-input v-model="scope.row.diffKg"></el-input>
                </el-form-item>
              </template>
              <template v-else-if="scope.column.property === 'diffCmb'">
                <el-form-item
                  :prop="
                    'formList.' +
                    index +
                    '.orderList.' +
                    scope.$index +
                    '.diffCmb'
                  "
                  :rules="rules.diffCmb"
                >
                  <el-input v-model="scope.row.diffCmb"></el-input>
                </el-form-item>
              </template>
            </template>
          </f-table>
        </el-form>
      </div>
    </div>
    <el-form
      class="addEditBox"
      :model="form"
      :rules="rules"
      ref="form"
      size="medium"
      v-else
    >
      <!-- 提货地址信息-->
      <el-row class="flex_center" :gutter="30">
        <el-col :span="13">
          <el-form-item
            prop="orderNos"
            label="订单号"
            :label-width="formLabelWidth"
            key="contact"
          >
            <el-input
              @blur="queryDeliveryAddress"
              v-model="form.orderNos"
              maxlength="750"
              show-word-limit
              type="textarea"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="13">
          <el-form-item
            prop="contactId"
            label="提货地址信息"
            key="contactId"
            :label-width="formLabelWidth"
          >
            <div
              style="
                display: flex;
                flex-direction: row;
                justify-content: space-between;
              "
            >
              <f-select
                v-model="form.contactId"
                :is-need="form.contactId"
                :data-list="selectSupplierList"
                :clear-select="clearSelect"
                @changet="updateThinfo('1')"
                style="width: 82%"
              ></f-select>
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="addSupInfoDialog"
                >新增</el-button
              >
            </div>
          </el-form-item>
        </el-col>

        <el-col :span="13">
          <el-form-item
            prop="thSupplier"
            label="公司"
            :label-width="formLabelWidth"
            key="thSupplier"
          >
            <el-input
              v-model="form.thSupplier"
              maxlength="50"
              show-word-limit
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="13">
          <el-form-item
            prop="contact"
            label="联系人"
            :label-width="formLabelWidth"
            key="contact"
          >
            <el-input
              v-model="form.contact"
              maxlength="50"
              show-word-limit
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="13">
          <el-form-item
            prop="contactTel"
            label="联系电话"
            :label-width="formLabelWidth"
            key="contactTel"
          >
            <el-input
              v-model="form.contactTel"
              maxlength="50"
              show-word-limit
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="13">
          <el-form-item
            prop="contactAdd"
            label="地址"
            :label-width="formLabelWidth"
            key="contactAdd"
          >
            <el-input
              v-model="form.contactAdd"
              maxlength="255"
              show-word-limit
              type="textarea"
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="13">
          <el-form-item
            prop="getsTime"
            label="提货时间"
            :label-width="formLabelWidth"
            key="getsTime"
          >
            <el-date-picker
              id="getTakeTime"
              v-model="form.getsTime"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="选择时间"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>

        <el-col :span="13">
          <el-form-item
            prop="isWeiban"
            key="isWeiban"
            label="是否需要尾板车"
            :label-width="formLabelWidth"
          >
            <el-select v-model="form.isWeiban" clearable filterable>
              <el-option value="1" label="是"></el-option>
              <el-option value="0" label="否"></el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="13">
          <el-form-item
            prop="isBanyun"
            key="isBanyun"
            label="是否需要搬运装车"
            :label-width="formLabelWidth"
          >
            <f-select
              v-model="form.isBanyun"
              :isNeed="form.isBanyun"
              :dict="'is_default'"
            ></f-select>
          </el-form-item>
        </el-col>

        <el-col :span="13">
          <el-form-item
            prop="palletNum"
            label="托盘数"
            :label-width="formLabelWidth"
            key="palletNum"
          >
            <el-input v-model="form.palletNum" type="number"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="13">
          <el-form-item
            prop="pcs"
            label="pcs"
            :label-width="formLabelWidth"
            key="pcs"
          >
            <el-input v-model="form.pcs" type="number"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="13">
          <el-form-item
            prop="remark"
            label="备注"
            :label-width="formLabelWidth"
            key="remark"
          >
            <el-input
              v-model="form.remark"
              maxlength="500"
              show-word-limit
              type="textarea"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <span slot="footer">
      <el-button size="medium" @click="handleClose">取消</el-button>
      <el-button
        type="primary"
        size="medium"
        @click="addTakeDeliveryInfo"
        v-if="addTakeDeliveryInfoFlag"
        >确认</el-button
      >
      <el-button type="primary" size="medium" @click="handleSubmit" v-else
        >确认</el-button
      >
    </span>
    <el-dialog
      title="添加联系人"
      :visible.sync="supplierVisible"
      width="30%"
      center
      :append-to-body="true"
    >
      <el-form :model="supplier" ref="supplier">
        <el-form-item
          label="供应商名"
          prop="name"
          :label-width="formLabelWidth"
          key="suppliername"
        >
          <el-input v-model="supplier.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
          label="公司名"
          prop="company"
          :label-width="formLabelWidth"
          key="suppliercompany"
        >
          <el-input v-model="supplier.company" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
          label="联系人"
          prop="contact"
          :label-width="formLabelWidth"
          key="suppliercontact"
        >
          <el-input v-model="supplier.contact" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item
          label="手机号"
          prop="tel"
          :label-width="formLabelWidth"
          key="suppliertel"
        >
          <el-input v-model="supplier.tel" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
          label="国家"
          prop="country"
          :label-width="formLabelWidth"
          key="suppliercountry"
        >
          <el-select
            :loading="coloading"
            @click.native="initCountryList"
            v-model="supplier.country"
            placeholder="请选择"
            filterable
            clearable
            @change="initStateList"
          >
            <el-option
              v-for="(item, index) in countrylist"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="州/省"
          prop="state"
          :label-width="formLabelWidth"
          key="supplierstate"
        >
          <el-select
            :loading="stateloading"
            v-model="supplier.state"
            placeholder="请选择"
            filterable
            clearable
            @change="initCityList"
          >
            <el-option
              v-for="item in statelist"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="城市"
          prop="city"
          :label-width="formLabelWidth"
          key="suppliercity"
        >
          <el-select
            :loading="cityloading"
            v-model="supplier.city"
            placeholder="请选择"
            filterable
            clearable
          >
            <el-option
              v-for="item in citylist"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="地址"
          prop="address"
          :label-width="formLabelWidth"
          key="supplieraddress"
        >
          <el-input
            v-model="supplier.address"
            autocomplete="off"
            type="textarea"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="备注"
          prop="remark"
          :label-width="formLabelWidth"
          key="supplierremark"
        >
          <el-input
            v-model="supplier.remark"
            autocomplete="off"
            type="textarea"
            show-word-limit
            maxlength="255"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="success" plain @click="saveSupplier">确 定</el-button>
        <el-button type="success" plain @click="supplierVisible = false"
          >取 消</el-button
        >
      </div>
    </el-dialog>
  </el-dialog>
</template>

<script>
import { postAction } from "@/api/manage";
import { validateCtn, validateCmb } from "../../../utils/validator";
import {
  getallcountries,
  getcityList,
  getstateList,
  httpAction,
} from "../../../api/manage";

export default {
  name: "addTakeDeliveryInfoDialog",
  props: {
    visible: {
      type: Boolean,
    },
    addTakeDeliveryInfoFlag: {
      type: Boolean,
      default: false,
    },
    selectedTrailerIdList: {
      type: Array,
      default: () => [],
    },
    orderNo: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      coloading: false,
      cityloading: false,
      stateloading: false,
      countrylist: [],
      // 省基础数据
      statelist: [],
      // 市基础数据
      citylist: [],
      selectSupplierMap: {},
      supplier: {},
      supplierVisible: false,
      isEdit: false,
      form: {},
      formLabelWidth: "130px",
      selectSupplierList: [],
      clearSelect: true,
      selectOrderList: [],
      rules: {
        diffCtn: [{ required: true, message: "请输入", trigger: "change" }],
        contactId: [
          { required: true, message: "请选择提货地址信息", trigger: "change" },
        ],
        diffCmb: [{ required: true, message: "请输入", trigger: "change" }],
        diffKg: [{ required: true, message: "请输入", trigger: "change" }],
        getsTime: [
          { required: true, message: "请选择提货时间", trigger: "change" },
        ],
        isWeiban: [
          {
            required: true,
            message: "请选择是否需要尾板车",
            trigger: "change",
          },
        ],
        isBanyun: [
          {
            required: true,
            message: "请选择是否需要搬运装车",
            trigger: "change",
          },
        ],
        orderNos: [
          { required: true, message: "请输入订单号", trigger: "change" },
        ],
        palletNum: [{ validator: validateCtn, trigger: "change" }],
        pcs: [{ validator: validateCtn, trigger: "change" }],
      },
      url: {
        //生成其他出库数据
        realSendTake: "/warehouse/takeDelivery/updateRealSendTake",
        getTakeWarehouseInfoByIds:
          "/warehouse/takeDelivery/getTakeWarehouseInfoByIds",
        getTakeWarehouseInfoByOrderNos:
          "/warehouse/takeDelivery/getTakeWarehouseInfoByOrderNos",
        getCusIdByOrderNos: "/warehouse/takeDelivery/getCusIdByOrderNos",
        addTakeDeliveryInfo: "/warehouse/takeDelivery/addTakeDeliveryInfo",
        addSupplier: "/customer/supplier/add",
        sendTakeList:
          "/jeecg-cloud-warehouse/warehouse/takeDelivery/sendTakeList",
      },
      getsTime: "",
      bacthForm: {
        formList: [],
      },
      columns: [
        {
          prop: "orderNo",
          label: "订单号",
        },
        {
          prop: "diffCtn",
          label: "箱数",
        },
        {
          prop: "diffKg",
          label: "重量",
        },
        {
          prop: "diffCmb",
          label: "体积",
        },
      ],
    };
  },
  watch: {
    visible(val) {
      this.selectSupplierList = [];
      if (!this.addTakeDeliveryInfoFlag) {
        if (val && this.selectedTrailerIdList) {
          postAction(
            this.url.getTakeWarehouseInfoByIds,
            this.selectedTrailerIdList
          ).then((res) => {
            if (res && res.result) {
              let map = res.result;
              // this.selectOrderList = map["orderList"];
              this.$set(this.form, "orderList", map["orderList"]);
              this.selectSupplierList = map["supplierList"];
              this.selectSupplierMap = map["supplierMap"];
              this.$set(this.form, "isWeiban", map["isWeiban"]);
              this.$set(this.form, "isBanyun", map["isBanyun"]);
              this.$set(this.form, "palletNum", map["oceanPkgs"]);
              this.$set(this.form, "remark", map["remark"]);
              this.$set(this.form, "getsTime", map["takeTime"]);
              this.$set(this.form, "contactId", map["supplierId"]);
              this.bacthForm.formList.push(this.form);
              this.updateThinfo("0", 0);
            }
          });
        }
      } else {
        this.queryDeliveryAddress();
      }
    },
  },
  methods: {
    // 初始化国家基础数据
    initCountryList() {
      this.coloading = true;

      this.countrylist = [];
      getallcountries().then((res) => {
        if (res.success) {
          for (var i = 0; i < res.result.length; i++) {
            let obj = {};
            obj.value = res.result[i].cnName;
            obj.label = res.result[i].twoCode + "-" + res.result[i].cnName;
            this.countrylist.push(obj);
          }
          this.coloading = false;
        }
      });

      this.coloading = false;
    },
    // 初始化省数据
    initStateList(val) {
      this.stateloading = true;
      this.statelist = [];
      this.citylist = [];
      if (val) {
        getstateList({ cnName: val }).then((res) => {
          if (res.success) {
            for (var i = 0; i < res.result.records.length; i++) {
              let obj = {};
              obj.value = res.result.records[i].cname;
              obj.label =
                res.result.records[i].code + "-" + res.result.records[i].cname;
              this.statelist.push(obj);
            }
            this.stateloading = false;
          }
        });
      } else {
        this.stateloading = false;
      }
    },
    // 初始化城市数据
    initCityList(val) {
      this.cityloading = true;
      this.citylist = [];
      if (val) {
        getcityList({ cname: val }).then((res) => {
          if (res.success) {
            for (var i = 0; i < res.result.records.length; i++) {
              let obj = {};
              obj.value = res.result.records[i].cname;
              obj.label =
                res.result.records[i].code + "-" + res.result.records[i].cname;
              this.citylist.push(obj);
            }
            this.cityloading = false;
          }
        });
      } else {
        this.cityloading = false;
      }
    },
    saveSupplier() {
      this.supplierDisable = true;
      let url = this.url.addSupplier;
      let method = "post";
      this.$refs["supplier"].validate((valid) => {
        if (valid) {
          httpAction(url, method, this.supplier)
            .then((res) => {
              if (res.success) {
                this.$message.success(res.message);
                this.supplierVisible = false;
                this.supplier = {};
                //重新获取供应商集合
                this.queryDeliveryAddress();
              } else {
                this.supplierDisable = false;
                this.$message.warning(res.message);
              }
            })
            .catch((err) => {
              this.supplierDisable = false;
              this.$message.error(err.message);
            });
        } else {
          this.supplierDisable = false;
        }
      });
    },
    addSupInfoDialog() {
      let params = "";
      if (this.orderNo && !this.addTakeDeliveryInfoFlag) {
        params = this.orderNo;
      } else if (this.addTakeDeliveryInfoFlag) {
        params = this.form.orderNos;
      }
      postAction(this.url.getCusIdByOrderNos, {
        orderNos: params,
      }).then((res) => {
        if (res && res.result) {
          this.supplier.cusId = res.result.cusId;
          this.supplierVisible = true;
        }
      });
    },
    queryDeliveryAddress() {
      let params = "";
      if (this.orderNo && !this.addTakeDeliveryInfoFlag) {
        params = this.orderNo;
      } else if (this.addTakeDeliveryInfoFlag) {
        params = this.form.orderNos;
      }
      if (!params) return;
      postAction(this.url.getTakeWarehouseInfoByOrderNos, {
        orderNos: params,
      })
        .then((res) => {
          if (res && res.result) {
            let map = res.result;
            this.selectSupplierList = map["supplierList"];
            this.selectSupplierMap = map["supplierMap"];
          }
        })
        .catch(() => {
          this.selectSupplierList = [];
        });
    },
    radioClick4(e) {
      e === this.form.isNeedSendTakeDriverPhone
        ? (this.form.isNeedSendTakeDriverPhone = "")
        : (this.form.isNeedSendTakeDriverPhone = e);
    },
    updateThinfo(type, index) {
      let contactId =
        type == "1"
          ? this.form.contactId
          : (type == "0" && index == 0) || index
          ? this.bacthForm.formList[index].contactId
          : "";
      if (contactId) {
        let obj = this.selectSupplierMap && this.selectSupplierMap[contactId];
        if (obj && type == "1") {
          this.$set(this.form, "thSupplier", obj.name);
          this.$set(this.form, "contact", obj.contact);
          this.$set(this.form, "contactTel", obj.tel);
          this.$set(this.form, "contactAdd", obj.address);
          this.$set(
            this.form,
            "isNeedSendTakeDriverPhone",
            obj.isNeedSendTakeDriverPhone
          );
        } else if (obj && type == "0") {
          this.$set(this.bacthForm.formList[index], "thSupplier", obj.name);
          this.$set(this.bacthForm.formList[index], "contact", obj.contact);
          this.$set(this.bacthForm.formList[index], "contactTel", obj.tel);
          this.$set(this.bacthForm.formList[index], "contactAdd", obj.address);
          this.$set(
            this.bacthForm.formList[index],
            "isNeedSendTakeDriverPhone",
            obj.isNeedSendTakeDriverPhone
          );
        }
      }
    },
    addTakeDeliveryInfo() {
      if (!this.form.getsTime) {
        this.$message.warning("请选择提货时间");
        return false;
      }
      // this.form.getsTime = this.$momonet(new Date(this.form.getsTime)).format(
      //   "yyyy-MM-dd HH:mm:ss"
      // );
      this.$refs["form"].validate((valid) => {
        if (valid) {
          postAction(this.url.addTakeDeliveryInfo, this.form).then((res) => {
            if (res.success) {
              this.$message.success(res.message);
              this.handleClose();
              this.$emit("ok");
            }
          });
        }
      });
    },
    handleSubmit() {
        const that = this;
      this.bacthForm.formList.forEach((item) => {
        item.orderList.forEach((childItem) => {
          childItem.ctn = childItem.diffCtn;
          childItem.kg = childItem.diffKg;
          childItem.cmb = childItem.diffCmb;
          childItem.trailerId = childItem.id;
          delete childItem.id;
        });
        // item.getsTime = this.$momonet(new Date(this.form.getsTime)).format(
        //   "yyyy-MM-dd HH:mm:ss"
        // );
      });
      let PromiseAll = [];
      let countNum = -1;
      for (let item in this.$refs) {
        countNum++;
        if (this.$refs && this.$refs[item] && typeof this.$refs[item].validate === 'function') {
            PromiseAll.push("form" + countNum);
        }
      }
      const validates = (item) => {
        return new Promise((resolve, reject) => {
          if (!this.$refs[item]) {
            resolve();
            return false;
          }
          that.$refs[item][0].validate((valid) => {
            if (valid) {
              resolve();
            } else {
                console.log("验证失败");
              reject(new Error("验证不对"));
              // .moveToErr(this);
            }
          });
        });
      };
      Promise.all(PromiseAll.map((item) => validates(item))).then(() => {
        console.log(this.bacthForm, "bacthForm");
        postAction(this.url.sendTakeList, this.bacthForm.formList).then(
          (res) => {
            // this.$alert(
            //   `生成的用车编号是<br><span style="color: green">${res.message}</span>`,
            //   "提货结果",
            //   {
            //     dangerouslyUseHTMLString: true,
            //   }
            // );
            this.handleClose();
          }
        );
      });
    },
    handleClose() {
      this.form = {};
      this.bacthForm = {
        formList: [],
      };
      this.selectSupplierList = [];
      this.selectSupplierMap = {};
      // this.addTakeDeliveryInfoFlag = false;
      this.$emit("ok");
    },
    addFormItem(type, index) {
      if (type == "0") {
        let newItem = {
          orderList: [],
        };
        for (let key in this.bacthForm.formList[0]) {
          console.log(key, "key");
          if (key === "orderList") {
            newItem[key] = JSON.parse(
              JSON.stringify(this.bacthForm.formList[0][key]));
              // 清楚新增后的trailerId、cmb、ctn、kg
              newItem["orderList"].forEach(item=>{
                  item.id =''
                  item.diffCmb =''
                  item.diffCtn =''
                  item.diffKg =''
              })

            // newItem[key] = this.bacthForm.formList[0][key].map((item) => {
            //   const newItemCopy = { ...item };
            //   newItem.trailerId = newItemCopy.id;
            //   delete newItemCopy.id;
            //   return newItemCopy;
            // });
          } else {
            newItem[key] = this.bacthForm.formList[0][key];
          }
        }
        this.bacthForm.formList.push(newItem);
      } else if (type == "1") {
        this.bacthForm.formList.splice(index, 1);
        console.log(this.bacthForm.formList)
      }
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding: 20px 40px 20px 6px;
  max-height: 600px;
  overflow-y: scroll;
}
.addEditBox {
  padding-top: 10px;
  box-sizing: border-box;
  max-height: 615px;
  overflow: scroll;
}
.form_item_box {
  border: 1px solid #e8e8e8;
  margin-bottom: 10px;
  margin-top: 5px;
}
</style>
