<template>

    <div class="main-full-content">
        <el-form ref="form" :model="queryForm" label-width="120px" size="medium" @submit.native.prevent>
            <el-card class="box-card">
                <el-row>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="工作单号">
                            <el-input v-model="queryForm.orderNo" @keyup.enter.native="handleQuery" type="textarea"></el-input>
                        </el-form-item>
                    </el-col>
                  <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <el-form-item label="仓库">
                      <el-input  v-model="queryForm.warehouse"
                                 @keyup.enter.native="handleSearch"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <el-form-item label="扫码人">
                      <el-input  v-model="queryForm.createBy"
                                 @keyup.enter.native="handleSearch"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="7">
                    <el-form-item label="出仓时间">
                      <div class="f-flex">
                        <el-date-picker
                            class="f-flex-1"
                            v-model="queryForm.createTimeStart"
                            type="datetime"
                            size="small"
                            placeholder="开始时间"
                            default-time="00:00:00"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            @keyup.enter.native="handleSearch"
                        >
                        </el-date-picker>
                        <span class="f-p-h-2">~</span>
                        <el-date-picker
                            class="f-flex-1"
                            v-model="queryForm.createTimeEnd"
                            type="datetime"
                            size="small"
                            placeholder="结束时间"
                            default-time="23:59:59"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            @keyup.enter.native="handleSearch"
                        >
                        </el-date-picker>
                      </div>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <el-form-item label="出库类型">
                      <el-select  v-model="queryForm.outType" filterable clearable
                                  @keyup.enter.native="handleSearch">
                        <el-option value="0" label="正常出库"></el-option>
                        <el-option value="1" label="退仓"></el-option>
                        <el-option value="2" label="转仓"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="3">
                        <el-form-item label-width="30px" style="width: 400px">
                            <el-button size="medium" type="primary" icon="el-icon-search" @click="handleQuery">查询</el-button>
                            <!-- <el-divider direction="vertical"></el-divider> -->
                            <el-button size="medium" plain @click="handleReset">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>

        </el-form>
        <el-card class="box-card last_card">
            <div class="f-p-b-15">
                <el-button size="medium" type="primary" @click="batchOut">批量出库</el-button>
              <el-button type="primary" size="medium" @click="exportExcelPlus(url.exportExcelUrl,'扫码出库记录')">导出
              </el-button>
            </div>
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="66vh"
                    :isRecordTableScroll="true"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange">
                <template v-slot="scope">
                        <template v-if="scope.column.property === 'action'">
                            <el-button type="text" @click="qxck(scope.row)">取消出库</el-button>
                        </template>
                    <template v-else-if="scope.column.property === 'orderNo'">
                        <a
                                style="color: #00a0e9"
                                @click.prevent="$intoDocPage(scope.row.orderNo)"
                        >{{ scope.row.orderNo }}</a
                        >
                    </template>
                    <template v-else-if="scope.column.property === 'fbaWarehouse'">
                        <span>{{parseDict(scope.row.fbaWarehouse,'warehouse_name')}}</span>
                    </template>

                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange">
                </el-pagination>
            </el-row>
        </el-card>

        <import-batch-out-dialog ref="importDialog" @ok="handleQuery"></import-batch-out-dialog>
    </div>


</template>
<script>
    import QueryMixins from "../../mixins/QueryMixins"
    import {getAction, postAction} from "@/api/manage";
    import importBatchOutDialog from "./modules/importBatchOutDialog";
    import {deleteAction} from "../../api/manage";
    import { setUpRouteUtil } from '@/utils/util';

    export default {
        mixins: [QueryMixins],
        components: {
            importBatchOutDialog
        },
        data() {
            return {
                dictCodes:['warehouse_name'],
                editStartOptions: {
                    disabledDate: time => {
                        if (!this.form.etime) {
                            // time = new Date(time.replace(/-/g, '/'));
                            return time.getTime() < new Date(1970 - 1 - 1).getTime();   //禁止选择1970年以前的日期
                        } else {
                            return time.getTime() > new Date(this.form.etime);
                        }
                    }
                },
                editStopOptions: {
                    disabledDate: time => {
                        // time = new Date(time.replace(/-/g, '/'));
                        return (
                            time.getTime() < new Date(this.form.stime) ||
                            time.getTime() < new Date(1970 - 1 - 1).getTime()    //禁止选择1970年以前的日期
                        );
                    }
                },
                selectedRowKeys: [],
                selectionRows: [],
                selectedNos:[],
                loading: false,
                form: {},
                columns: [
                    // {
                    //     type: 'selection',
                    //     width: 50
                    // },
                    {
                        prop: 'action',
                        label: '操作',
                    },
                    {
                        prop: 'orderNo',
                        minWidth:90,
                        label: '工作单号',
                    },
                  {
                    prop: 'scanBy',
                    label: '扫码人',
                  },
                    {
                        prop: 'type',
                        minWidth:90,
                        label: '出库类型',
                    },
                    {
                        prop: 'customerName',
                        label: '客户',
                    },
                    {
                        prop: 'location',
                        label: '库位',
                    },
                    {
                        prop: 'fbaWarehouse',
                        label: '仓库名',
                    },
                    {
                        prop: 'warehouseOutNum',
                        label: '出库件数/卡板',
                    },
                    {
                        prop: 'cmb',
                        label: '出库体积',
                    },
                    {
                        prop: 'warehouseOutTime',
                        label: '出库时间',
                    },
                    {
                        prop: 'docName',
                        label: '港前操作',
                    },
                    {
                        prop: 'operateName',
                        label: '操作',
                    },
                    {
                        prop: 'salesName',
                        label: '销售',
                    },
                ],

                url: {
                    list: '/warehouse/test/getScanLogOutIPage',
                    qxck: '/interface/warehouse/updateQxckByOrderNoAndId',
                  //导出excel的接口
                  exportExcelUrl: '/file/export/excel/warehouse/scanLogOutExcel'
                },
              isUsePost:true,

            };
        },
        computed: {

        },
        created() {
        },
        methods: {
            qxck(row){
                this.$confirm('确定将该订单:' + row.orderNo + '取消出库吗', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    closeOnClickModal: false,
                }).then(() => {
                    deleteAction(this.url.qxck,{id:row.id,orderNo:row.orderNo})
                        .then(res=>{
                            this.$message.success(res.message);
                            this.handleQuery();
                        })
                }).catch(err=>{
                    this.$message.error(err.message);
                })




            },
            batchOut(){

                this.$refs.importDialog.edit();
            },

        },
    };
</script>
<style scoped>
    .el-form-item {
        margin-bottom: 22px;
    }
</style>
