<template>
    <el-dialog
        title="批量下载订单文件"
        :visible="visible"
        width="50%"
        append-to-body
        custom-class="c-custom-dialog"
        :close-on-click-modal="false"
        :before-close="handleClose">
        <el-form :model="form" :rules="rules" ref="form" label-width="94px" size="medium">
            <el-row>
              <el-col :span="24">
                <el-form-item prop="nos" label="选择的订单号">
                 <span>{{form.orderNos && form.orderNos.join("，")}}</span>
                </el-form-item>
              </el-col>
                <el-col :span="24">
                    <el-form-item prop="fileTypes" label="文件类型">
                        <f-select :data-list="queryDictCodeListMap['os_order_filelist']"
                                  v-model="form.fileTypes"
                                  :is-need="form.fileTypes"
                                  :multiple="true"
                                  >
                        </f-select>
                    </el-form-item>
                </el-col>

            </el-row>
        </el-form>
        <span slot="footer">
            <el-button size="medium" @click="handleClose">取消</el-button>
            <el-button type="primary" size="medium" @click="handleSubmit">确定</el-button>
        </span>
    </el-dialog>
</template>

<script>
import { postAction , getAction} from '@/api/manage'
import QueryMixins from "@/mixins/QueryMixins";
import containerDetailButtonClickFunctions from "@/mixins/containerDetailButtonClickFunctions";
export default {
    name: 'TtOrderFileDownloadDialog',
    props: {
        visible: {
            type: Boolean
        },
        orderNos : {
          type : Array,
          default : () => []
        },
        containerId : {
          type : String,
          default : ''
        }
    },
  mixins:[QueryMixins,containerDetailButtonClickFunctions],
    data() {
        return {
            form: { fileTypes : [] },

            rules: {
                fileTypes: [
                    { required: true, message: '请选择文件类型', trigger: 'change' },
                ],
            },
            url: {
                findDownloadUrl: '/interface/file/checkOsOrderFileUrls',
                findNosByContainerId: '/operation/container/findNosByContainerId',

            },
          needQueryDictCodes:['os_order_filelist'],
          disableMixinMounted : true,
          dialogTitle : '',
        }
    },
    watch: {
        visible(val) {
            this.form = {};
            this.$set(this.form,'orderNos',this.orderNos);
            this.dialogTitle = "批量下载订单文件：【" + this.orderNos.join("，") + "】";
            if (this.containerId && this.containerId.length > 0) {
              getAction(this.url.findNosByContainerId,{containerId : this.containerId})
                  .then(res => {
                    debugger
                    this.$set(this.form,'orderNos',res.result);
                    this.dialogTitle = "批量下载订单文件：【" + res.result.join("，") + "】";
                  })
            }
        }
    },
    methods: {
        handleSubmit() {
          this.$refs.form.validate((valid) => {
            if (valid) {
              postAction(this.url.findDownloadUrl,this.form)
                  .then(res => {
                    this.downloadAndZipFiles(res.result,'订单文件列表');
                  })
            }
          })
        },
        handleClose() {
            this.$emit('update:visible', false);
            this.$emit('ok');
        }
    }
}
</script>

<style lang="scss" scoped>
::v-deep
    .el-dialog__body {
        padding: 20px 30px 20px 24px;
    }
</style>
