<!--待提交-->
<template>
    <div class="main-full-content">
        <el-form class="multiline_select" ref="form" :model="queryForm" label-width="120px" size="medium">
            <el-card class="box-card">
                <el-row class="inputs_row">
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="周数">
                            <el-input v-model="queryForm.week" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="专线Or拆柜">
                            <!--待提交 已拒绝-->
                            <f-select
                                    v-model="queryForm.cabinetCategory"
                                    :fatherVal="queryForm.cabinetCategory"
                                    :dict="'cabinet_category'"
                            ></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="柜型">
                            <f-select
                                    v-model="queryForm.size"
                                    :isNeed="queryForm.size"
                                    :dict="'cabinet_type'"
                            ></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="订舱号">
                            <el-input v-model="queryForm.bookNo" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="柜号">
                            <el-input v-model="queryForm.containerNo" @keyup.enter.native="handleSearch"
                            type="textarea" placeholder="多个柜号精确查询"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="封条">
                            <el-input v-model="queryForm.originalNo" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="船名航次">
                            <el-input v-model="queryForm.shipNo" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="拼柜仓库">
                            <f-select v-model="queryForm.lclPlace" :isNeed="queryForm.lclPlace"
                                      :dict="'lcl_place'"></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="7">
                        <el-form-item label="装柜时间">
                            <div class="f-flex">
                                <el-date-picker
                                        class="f-flex-1"
                                        v-model="queryForm.loadTimeStart"
                                        type="datetime"
                                        size="small"
                                        placeholder="开始时间"
                                        default-time="00:00:00"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                >
                                </el-date-picker>
                                <span class="f-p-h-2">~</span>
                                <el-date-picker
                                        class="f-flex-1"
                                        v-model="queryForm.loadTimeEnd"
                                        type="datetime"
                                        size="small"
                                        placeholder="结束时间"
                                        default-time="23:59:59"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                >
                                </el-date-picker>
                            </div>
                        </el-form-item>
                    </el-col>

                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="操作">
                            <f-select v-model="queryForm.operateId"
                                      :isNeed="queryForm.operateId"
                                      placeholder="请选择" filterable clearable :dict="'operationsId'">
                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="柜子状态">
                            <el-select v-model="queryForm.currentStatus">
                                <el-option value="0" label="未排柜"></el-option>
                                <el-option value="8" label="已排柜"></el-option>
                                <el-option value="1" label="已装柜"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label-width="30px">
                            <el-button
                                    type="primary"
                                    icon="el-icon-search"
                                    @click="handleSearch"
                            >查询
                            </el-button
                            >
                            <el-button plain @click="handleReset">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>
        </el-form>
        <el-card class="box-card last_card">
            <el-button
                    type="primary"
                    size="medium"
                    @click="handledelBatch"
                    :disabled="selectionRows.length === 0 "
                    v-if="buttonShowList.sc"
                >批量删除
            </el-button>
            <!--  && scope.row.circulationState == '0' -->
            <el-button
                    type="primary"
                    size="medium"
                    @click="handleAlertCabinrtBatch"
                    :disabled="selectionRows.length === 0 "
                    v-if="buttonShowList.pg"
                 >批量排柜
            </el-button>
            <!-- && scope.row.circulationState == '8' -->
            <el-button
                    type="primary"
                    size="medium"
                    @click="isAureToSailCabinrt2"
                    :disabled="selectionRows.length === 0 "
                    v-if="buttonShowList.zg"
            >批量装柜
            </el-button>
            <!--scope.row.zhuangguiOssId && scope.row.zhuangguiOssId.length >0-->
            <el-button
                    type="primary"
                    size="medium"
                    @click="zhuangguiPicBatch"
                    :disabled="selectionRows.length === 0 "
                    v-if="buttonShowList.zgtp"
            >批量装柜图片
            </el-button>
            <el-button
                    type="primary"
                    size="medium"
                    @click="yanChuan"
                    :disabled="selectionRows.length === 0 "
                    v-if="buttonShowList.delay"
            >批量延船
            </el-button>
          <el-button
              type="primary"
              size="medium"
              @click="syncSales"
              :disabled="selectionRows.length === 0 "
              v-if="buttonShowList.syncSales">
            同步销售
          </el-button>
          <el-button
              type="primary"
              size="medium"
              @click="syncNoSales"
              :disabled="selectionRows.length === 0 "
              v-if="buttonShowList.nsyncSales">
            取消同步销售
          </el-button>
           <el-button
              type="primary"
              size="medium"
              @click="pullOut"
              :disabled="selectionRows.length === 0 "
              v-if="buttonShowList.khfc">
            卡航发车
          </el-button>


            <div class="f-p-b-15">
                <!--                <el-button type="primary" size="small" @click="handleExportXls('保险待处理记录表')">导出</el-button>-->
                <!--                <el-button type="primary" size="small" @click="daoru()">导入</el-button>-->
                <!--                <el-button type="primary" size="small" @click="updateStatus()">更新状态</el-button>-->
                <!--                <el-button type="primary" size="small" @click="beatchToubao()">批量投保</el-button>-->
            </div>
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="60vh"
                    :isRecordTableScroll="true"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange"
            >
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'action'">
                        <el-button type="text" @click="handleEdit(scope.row)"
                                   v-if="buttonShowList.xggz"
                        >修改柜子
                        </el-button>
                        <el-button type="text" @click="paiGui(scope.row)"
                                   v-if="buttonShowList.pgb"
                        >排柜表
                        </el-button>
                        <el-button type="text" @click="sureAddGood(scope.row)"
                                   v-if="Number(scope.row.sureAddGood)>0 && buttonShowList.confirmh">
                            确认加货
                        </el-button>
                        <el-button type="text" @click="sureTiHuo(scope.row)"
                                   v-if="Number(scope.row.sureTihuo)>0 && buttonShowList.confirmt">
                            确认踢货
                        </el-button>
                        <el-button type="text" @click="getSonList(scope.row)"
                                   v-if="scope.row.week && scope.row.week.indexOf('传统')!==-1"
                        >查看子柜子
                        </el-button>
                    </template>
                    <template v-else-if="scope.column.property === 'complianceState'">
            <span :style="{color: scope.row.complianceState == '2' ? 'red' : ''}">{{
              parseDict(scope.row.complianceState, "compliance_state")
            }}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'currentStatus'">
                      <span v-if="scope.row.circulationState === '0'" style="color: red">未排柜</span>
                      <span v-if="scope.row.circulationState === '8'" style="color: blue">已排柜</span>
                      <span v-if="scope.row.circulationState === '1'" style="color: green">已装柜</span>
<!--                      <span v-if="scope.row.isSalesSync === '1'" style="color:green"><br>(已同步销售)</span>-->
<!--                      <span v-else style="color:red"><br>(未同步销售)</span>-->
                    </template>
                    <template v-else-if="scope.column.property === 'cabinetCategory'">
            <span>{{
              parseDict(scope.row.cabinetCategory, "cabinet_category")
            }}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'bookAgentId'">
                        <span>{{ parseDict(scope.row.bookAgentId, "bookAgent_id_chinese") }}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'weekStatus'">
                        <span v-if="scope.row.weekStatus == '0'">自拼</span>
                        <span v-if="scope.row.weekStatus == '1'">非拼</span>
                        <span v-if="scope.row.weekStatus == '2'">传统物流</span>
                    </template>
                    <template v-else-if="scope.column.property === 'shipCompanyId'">
                        <span>{{ parseDict(scope.row.shipCompanyId, "ship_company") }}</span>
                    </template>


                    <template v-else-if="scope.column.property === 'week'">
                        <a style="color: #00a0e9" class="f-cursor-pointer" @click.prevent="infomation(scope.row.id)">{{
                            scope.row.week }}</a>
                        <!-- 换成a标签，支持复制 -->
                        <!-- <el-button type="text" @click="infomation(scope.row.id)">{{scope.row.week}}</el-button> -->
                    </template>
                    <template v-else-if="scope.column.property === 'orderNo'">
                        <a
                                style="color: #00a0e9"
                                @click.prevent="$intoDocPage(scope.row.orderNo)"
                        >{{ scope.row.orderNo }}</a>
                    </template>
                    <template v-else-if="scope.column.property === 'cmb'">
                        <span>{{scope.row.ctn}}/</span>
                        <!-- 在待装柜的列表页面，预计重量大于19.5吨 要标红 -->
                        <span :style="(scope.row.kg > 19500) ? 'color: red' : ''">{{scope.row.kg}}</span>
                        <span>/{{scope.row.cmb}}</span>
                    </template>
                    <!-- 实际超过68标红 -->
                    <template v-else-if="scope.column.property === 'realCmb'">
                        <span>{{scope.row.realCtn}}/</span>
                        <!-- 在待装柜的列表页面，实际重量大于19.5吨 要标红 -->
                        <span :style="(scope.row.realKg > 19500) ? 'color: red' : ''">{{scope.row.realKg}}</span>
                        <span>/</span>
                        <span :style="(scope.row.realCmb > 68) ? 'color: red' : ''">{{ scope.row.realCmb }}</span>
                    </template>
                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                >
                </el-pagination>
            </el-row>
        </el-card>

        <el-dialog
                title="已排柜"
                :visible.sync="dialogCirculationStateVisible"
                :close-on-click-modal="false"
                append-to-body
        >
            <el-form :model="updateAlertCabinrt" ref="form" :rules="rules">
                <el-form-item label="预计装柜时间" prop="loadTime">
                    <el-date-picker
                            class="f-flex-1"
                            v-model="updateAlertCabinrt.loadTime"
                            type="datetime"
                            size="small"
                            placeholder="开始时间"
                            default-time="00:00:00"
                            value-format="yyyy-MM-dd HH:mm:ss"
                    >
                    </el-date-picker>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="medium" @click="dialogCirculationStateVisible = false"
                >取 消
                </el-button
                >
                <el-button size="medium" type="primary" @click="updateHandleAlertCabinrt"
                >确 定
                </el-button
                >
            </div>
        </el-dialog>

        <el-dialog
                title="修改柜子"
                :visible.sync="dialogFormEditCabinetVisible"
                :close-on-click-modal="false"
        >
            <el-form :model="EditCabinetForm" :rules="rules" ref="EditCabinetForm">
                <el-form-item
                        label="专线/拆柜"
                        :label-width="formLabelWidth"
                        prop="cabinetCategory"
                >
                    <f-select
                            v-model="EditCabinetForm.cabinetCategory"
                            :fatherVal="EditCabinetForm.cabinetCategory"
                            :dict="'cabinet_category'"
                            :is-need="EditCabinetForm.cabinetCategory"
                    ></f-select>
                </el-form-item>
                <el-form-item label="周数" :label-width="formLabelWidth" prop="week">
                    <el-input v-model="EditCabinetForm.week"></el-input>
                </el-form-item>
                <el-form-item label="柜型" :label-width="formLabelWidth" prop="size">
                    <f-select
                            v-model="EditCabinetForm.size"
                            :fatherVal="EditCabinetForm.size"
                            :dict="'cabinet_type'"
                            :is-need="EditCabinetForm.size"
                    ></f-select>
                </el-form-item>
                <el-form-item
                        label="柜子用途"
                        :label-width="formLabelWidth"
                        prop="weekStatus"
                >
                    <el-select
                            filterable
                            class="f-full-width"
                            v-model="EditCabinetForm.weekStatus"
                            placeholder="请选择"
                    >
                        <el-option key="0" label="自拼" value="0"></el-option>
                        <el-option key="1" label="非自拼/整柜" value="1"></el-option>
                        <el-option key="2" label="传统物流" value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="订舱号" :label-width="formLabelWidth" prop="bookNo">
                    <f-select
                            v-model="EditCabinetForm.bookNo"
                            :isNeed="EditCabinetForm.bookNo"
                            :dict="'so'"
                    ></f-select>
                </el-form-item>
                <el-form-item label="拼柜仓库" :label-width="formLabelWidth" prop="lclPlace">
                    <f-select
                            v-model="EditCabinetForm.lclPlace"
                            :isNeed="EditCabinetForm.lclPlace"
                            :dict="'lcl_place'"
                    ></f-select>
                </el-form-item>
                <el-form-item label="运输方式" :label-width="formLabelWidth" prop="transportType">
                    <f-select
                            v-model="EditCabinetForm.transportType"
                            :isNeed="EditCabinetForm.transportType"
                            :dict="'os_send_type'"
                    ></f-select>
                </el-form-item>
                <el-form-item label="开港时间" :label-width="formLabelWidth" prop="departureTime">
                    <el-date-picker
                            class="f-flex-1"
                            v-model="EditCabinetForm.departureTime"
                            type="datetime"
                            size="small"
                            placeholder="请选择时间"
                            default-time="00:00:00"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            :picker-options="editStartOptions"
                            format="yyyy-MM-dd HH:mm:ss"
                            style="width: 100%;"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="起运港" :label-width="formLabelWidth" prop="pol">
                    <f-select
                            v-model="EditCabinetForm.pol"
                            :isNeed="EditCabinetForm.pol"
                            :dict="'bd_port'"
                    ></f-select>
                </el-form-item>
                <el-form-item label="目的港" :label-width="formLabelWidth" prop="podEnd">
                    <f-select
                            v-model="EditCabinetForm.podEnd"
                            :isNeed="EditCabinetForm.podEnd"
                            :dict="'bd_port'"
                    ></f-select>
                </el-form-item>
                <el-form-item label="船名航次" :label-width="formLabelWidth" prop="shipNo">
                    <el-input v-model="EditCabinetForm.shipNo"></el-input>
                </el-form-item>

            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="medium" @click="dialogFormEditCabinetVisible = false"
                >取 消
                </el-button
                >
                <el-button size="medium" type="primary" @click="EditCabinet">确 定</el-button>
            </div>
        </el-dialog>

        <el-dialog title="确认加货" :visible.sync="dialogFormVisibleAddGood" :close-on-click-modal="false">
            <div slot="footer" class="dialog-footer">

                <f-table
                        v-loading="loading"
                        ref="GTable"
                        border
                        size="medium"
                        row-key="id"
                        class="c-th-has-bgcolor"
                        :columns="columnAdd"
                        :data="tableDataAdd"
                        :cell-style="{ 'text-align': 'center' }"
                        @selection-change="handleSelectionChange"
                        @sort-change="handleTableChange"
                >
                    <template v-slot="scope">
                        <template v-if="scope.column.property === 'action'">
                            <el-button type="text" @click="sureOrderNo(scope.row)"
                                       :disabled="scope.row.cmb <= scope.row.alreadyCmb">
                                确认
                            </el-button>
                        </template>
                        <template v-else-if="scope.column.property === 'createBy'">
                            <span>{{parseDict(scope.row.createBy, "all_user_id")}}</span>
                        </template>
                        <template v-else-if="scope.column.property === 'sureBy'">
                            <span>{{parseDict(scope.row.sureBy, "all_user_id")}}</span>
                        </template>
                    </template>
                </f-table>
                <el-row class="f-text-right f-p-v-8">
                    <el-pagination
                            v-bind="ipaginationAdd"
                            layout="total, sizes, prev, pager, next, jumper"
                            @size-change="handleSizeChangeAdd"
                            @current-change="handleCurrentChangeAdd"
                    >
                    </el-pagination>
                </el-row>
            </div>
        </el-dialog>
        <el-dialog title="确认加货单号" :visible.sync="dialogFormVisibleAddGoodOrderNo" :close-on-click-modal="false">
            <div slot="footer" class="dialog-footer">
                申请加货的单号:
                <el-select clearable filterable class="f-full-width"
                           @change="getOrderNoListJoin"
                           v-model="form.orderNoList" @click.native="initAddGoodOrderNoList"
                           multiple
                           placeholder="请选择">
                    <el-option v-for="(item,index) in orderNoList" :key="index" :value="item"
                               :label="item"></el-option>
                </el-select>


                <el-button size="medium" @click="dialogFormVisibleAddGoodOrderNo = false">取 消</el-button>
                <el-button size="medium" type="primary" @click="sureAddGoodSure()">确 定</el-button>
            </div>
        </el-dialog>
        <el-dialog title="确认踢货" :visible.sync="dialogFormVisibleTihuo" :close-on-click-modal="false">
            <div slot="footer" class="dialog-footer">

                <f-table
                        v-loading="loading"
                        ref="GTable"
                        border
                        size="medium"
                        row-key="id"
                        class="c-th-has-bgcolor"
                        :columns="columnTihuo"
                        :data="tableDataTihuo"
                        :cell-style="{ 'text-align': 'center' }"
                        @selection-change="handleSelectionChange"
                        @sort-change="handleTableChange"
                >
                    <template v-slot="scope">
                        <template v-if="scope.column.property === 'action'">
                            <el-button type="text" @click="sureUpdate(scope.row)" :disabled="scope.row.sureBy != null">
                                确认
                            </el-button>
                        </template>
                        <template v-else-if="scope.column.property === 'createBy'">
                            <span>{{parseDict(scope.row.createBy, "all_user_id")}}</span>
                        </template>
                        <template v-else-if="scope.column.property === 'sureBy'">
                            <span>{{parseDict(scope.row.sureBy, "all_user_id")}}</span>
                        </template>
                    </template>
                </f-table>
                <el-row class="f-text-right f-p-v-8">
                    <el-pagination
                            v-bind="ipaginationTiHuo"
                            layout="total, sizes, prev, pager, next, jumper"
                            @size-change="handleSizeChangeAdd"
                            @current-change="handleCurrentChangeAdd"
                    >
                    </el-pagination>
                </el-row>
            </div>
        </el-dialog>
<!--        <el-dialog title="确认装柜" :visible.sync="dialogFormVisibleZhuangGui" :close-on-click-modal="false">-->
<!--            <div slot="footer" class="dialog-footer">-->
<!--                <div style="text-align: left;">装柜图片：</div>-->
<!--                <div v-for="(item, index) in this.zhuangGuiPicShow" :key="index">-->
<!--                    <img style="width: 100%;" :src="item.url" alt=""/>-->
<!--                </div>-->
<!--                <el-button size="medium" @click="dialogFormVisibleZhuangGui = false">取 消</el-button>-->
<!--                <el-button size="medium" type="primary" @click="updateHandleToSailCabinrt()">确 定</el-button>-->
<!--            </div>-->
<!--        </el-dialog>-->

        <el-dialog title="确认装柜" :visible.sync="dialogFormVisibleZhuangGui" :close-on-click-modal="false" append-to-body width="50%" height="100%">
<!--            <div>-->
<!--                <p>装柜备注：{{zhuangGuiRemark}}</p>-->
<!--                <p>装柜完成时间：{{zhuangGuiTime}}</p>-->
<!--            </div>-->
                <el-row>
                  <div v-for="(i,d) in zhuangguiList" :key="d" >
                      <el-col :span="8">
                          <p style="margin-right: 20px">拼柜仓库：{{i.lclPlaceName}}</p>
                          <p style="margin-right: 20px">装柜备注：{{i.remark}}</p>
                          <p style="margin-right: 20px">装柜完成时间：{{i.lockerTime}}</p>
                      </el-col>
                      <el-col :span="16">
                          <f-upload v-model="i.ossFileList" :disabled="true" :isShowButton="false" list-type="picture-card"></f-upload>
                      </el-col>
                  </div>
                </el-row>

<!--                <div >装柜图片：</div>-->
<!--                <div v-for="(item, index) in this.zhuangGuiPicShow" :key="index">-->
<!--                    <img style="width: 50%;height: 50%" :src="item.url" alt=""/>-->
<!--                </div>-->
                <div slot="footer" class="dialog-footer">
                  <el-button size="medium" @click="dialogFormVisibleZhuangGui = false">取 消</el-button>
                  <el-button size="medium" type="primary" @click="updateHandleToSailCabinrt()">确 定</el-button>
                </div>

        </el-dialog>


        <el-dialog title="延船" :visible.sync="dialogFormVisibleDelayShip" :close-on-click-modal="false">
            <div slot="footer" class="dialog-footer">
                <el-form :model="form" :rules="rules" ref="delayFrom">
                    <el-form-item label="预计开船时间" :label-width="formLabelWidth" prop="etd">
                        <el-date-picker
                                class="f-flex-1"
                                v-model="form.etd"
                                type="datetime"
                                size="small"
                                placeholder="请选择时间"
                                default-time="00:00:00"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                :picker-options="editStartOptions"
                                format="yyyy-MM-dd HH:mm:ss"
                                style="width: 100%;"
                        >
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="延船原因" :label-width="formLabelWidth" prop="delayShipReason">
                        <f-select
                                v-model="form.delayShipReason"
                                :is-need="form.delayShipReason"
                                :dict="'delay_ship'"
                                @change="delayShipReasonChange"
                        ></f-select>
                    </el-form-item>
                    <el-form-item label="其他原因" :label-width="formLabelWidth" prop="otherReason" v-if="this.isOther">
                        <el-input v-model="form.otherReason"></el-input>
                    </el-form-item>
                </el-form>
                <el-button size="medium" @click="dialogFormVisibleDelayShip = false">取 消</el-button>
                <el-button size="medium" type="primary" @click="yanChuanSendEmail()">发送邮件</el-button>
            </div>
        </el-dialog>
        <son-cabinet :container-id="rowContainerId" :visible.sync="sonVisible" :table-list="sonTableList" @close="close"></son-cabinet>
    </div>

</template>

<script>
    import {deleteAction, getAction, postAction, putAction} from "@/api/manage";
    import QueryMixins from "@/mixins/QueryMixins";
    import {setUpRouteUtil} from '@/utils/util';
    import SonCabinet from "@/views/operation/cabinet/SonCabinet.vue";


    export default {
        name: "Potential",
        mixins: [QueryMixins],
        components:{SonCabinet},
        data() {
            return {
                rowContainerId:'',
                editStartOptions: {
                    disabledDate: time => {
                        if (!this.queryForm.createTimeEnd) {
                            // time = new Date(time.replace(/-/g, '/'));
                            return time.getTime() < new Date(1970 - 1 - 1).getTime();   //禁止选择1970年以前的日期
                        } else {
                            return time.getTime() > new Date(this.queryForm.createTimeEnd);
                        }
                    }
                },
                rules: {
                    cabinetCategory: [
                        {required: true, message: "请选择专线/拆柜", trigger: "change"},
                    ],
                    week: [{required: true, message: "请填写周数", trigger: "change"}],
                    size: [{required: true, message: "请选择柜型", trigger: "change"}],
                    weekStatus: [
                        {required: true, message: "请选择柜子用途", trigger: "change"},
                    ],
                    loadTime: [{required: true, message: "请填写预计装柜时间", trigger: "change"}],
                    etd: [
                        {required: true, message: "请选择预计开船时间", trigger: "change"},
                    ],
                    delayShipReason: [
                        {required: true, message: "请选择延船原因", trigger: "change"},
                    ],
                    otherReason: [
                        {required: true, message: "请填写其他原因", trigger: "change"},
                        {max: 500, message: '长度不能超过500', trigger: 'change'},
                    ],
                },
                ids: [],
                row: {},
                form: {},
                EditCabinetForm: {},
                updateAlertCabinrt: {},
                dictCodes: ["compliance_state", "cabinet_category", "bookAgent_id_chinese", 'all_user_id', 'ship_company'],
                formLabelWidth: "120px",
                //控制弹框显示
                dialogFormVisible: false,
                dialogCirculationStateVisible: false,
                dialogFormEditCabinetVisible: false,
                dialogFormVisibleAddGood: false,
                dialogFormVisibleAddGoodOrderNo: false,
                dialogFormVisibleTihuo: false,
                dialogFormVisibleZhuangGui: false,
                dialogFormVisibleDelayShip: false,
                columns: [
                    {
                        type: "selection",
                        width: 50,
                    },
                    {
                        prop: "action",
                        label: "操作",
                        width: 110,
                        // fixed: 'left'
                    },
                    {
                        prop: "complianceState",
                        label: "关务审核",
                        sortable: "complianceState",
                        width:60,
                    },
                    {
                        prop: "currentStatus",
                        label: "当前状态",
                      minWidth: 60
                    },
                    {
                        prop: "week",
                        label: "周数",
                        sortable: "week",
                        minWidth: 200
                    },
                    {
                        prop: "cabinetCategory",
                        label: "专线or拆柜",
                        sortable: "cabinetCategory",
                        width: 80,
                    },
                    {
                        prop: "bookAgentId",
                        label: "订舱代理",
                        sortable: "bookAgentId",
                        minWidth: 240
                    },
                    {
                        prop: "size",
                        label: "柜型",
                        sortable: "size",
                        width: 50,
                    },
                    {
                        prop: "bookNo",
                        label: "订舱号",
                        sortable: "bookNo",
                        width: 120,
                    },
                    {
                        prop: "containerNo",
                        label: "柜号",
                        sortable: "containerNo",
                        width: 100,
                    },
                  {
                    prop: "preVolume",
                    label: "预排方数",
                    width: 40,

                  },
                    {
                        prop: "cmb",
                        label: "预计件重体",
                        minWidth: 130,
                        sortable: "cmb",
                    },
                    {
                        prop: "realCmb",
                        label: "实际件重体",
                        minWidth: 130,
                        sortable: "realCmb",
                    },
                    {
                        prop: "orderQuantity",
                        label: "票数",
                        width:40,
                        sortable: "orderQuantity",
                    },
                    {
                        prop: "shipNo",
                        label: "船名航次",
                        minWidth: 130,
                        sortable: "shipNo",
                    },
                    {
                        prop: "shipCompanyId",
                        label: "船司",
                        minWidth: 150,
                        sortable: "shipCompanyId",
                    },
                    {
                        prop: "loadTime",
                        label: "装柜时间",
                        sortable: "loadTime",
                          minWidth: 150,
                    },
                    {
                        prop: "warehouseRemark",
                        label: "仓库备注",
                         minWidth: 300
                    },
                    {
                        prop: "weekStatus",
                        label: "柜子用途",
                        minWidth: 40,
                        sortable: "weekStatus",
                    },

                ],
                columnAdd: [
                    {
                        prop: "action",
                        label: "操作",
                        // fixed: 'left'
                    },
                    {
                        prop: "createBy",
                        label: "加货人",
                        sortable: "custom",
                    },
                    {
                        prop: "createTime",
                        label: "加货时间",
                        sortable: "custom",
                          minWidth: 200,
                    },
                    {
                        prop: "cmb",
                        label: "加货体积",
                        sortable: "custom",
                    },
                    {
                        prop: "sureBy",
                        label: "确认加货人",
                        sortable: "custom",
                    },
                    {
                        prop: "sureTime",
                        label: "确认加货时间",
                        sortable: "custom",
                          minWidth: 200,
                    },
                    {
                        prop: "tihuoOrderNos",
                        label: "已加货单号",
                        sortable: "custom",
                    },
                    {
                        prop: "alreadyCmb",
                        label: "已加货体积（m³）",
                        sortable: "custom",
                    },
                ],
                columnTihuo: [
                    {
                        prop: "action",
                        label: "操作",
                    },
                    {
                        prop: "createBy",
                        label: "踢货人",
                        sortable: "custom",
                    },
                    {
                        prop: "createTime",
                        label: "踢货时间",
                        sortable: "custom",
                          minWidth: 200,
                    },
                    {
                        prop: "tihuoOrderNos",
                        label: "踢货单号",
                        sortable: "custom",
                    },
                    {
                        prop: "sureBy",
                        label: "确认踢货人",
                        sortable: "custom",
                    },
                    {
                        prop: "sureTime",
                        label: "确认踢货时间",
                        sortable: "custom",
                          minWidth: 200,
                    },
                ],
                url: {
                    list: "/operation/container/getwaittingLoadinglist",
                    updateCirculationState: "/operation/container/updateCabinet",
                    updateCirculationStateSendMessage: "/operation/container/updateCirculationState",
                    updateCirculationStateSendMessageBatch: "/operation/container/updateCirculationStateBatch",
                    delCabinet: "/operation/container/delCabinet",
                    delCabinetBatch: "/operation/container/delCabinetBatch",
                    getPaiGui: "/file/jasper/getPaiGui",
                    orderNolist: "/operation/container/orderNolist", //获取待排柜列表的所有单号
                    sureAddGood: "/shipping/osContainerAddgood/sureAddGood", //确定加货单号
                    addList: "/shipping/osContainerAddgood/list", // 加货列表
                    sureTihuo: "/shipping/osContainerAddgood/sureTihuo", // 确认踢货
                    downloadById: "/sys/oss/file/downloadById",
                    batchDownload: "/sys/oss/file/batchDownloadByIds",  //下载文件
                    getList: "/sys/oss/file/getList",  //下载文件
                    booking: "/file/jasper/downloadBooking", //下载订舱单
                    delayReason: "/operation/container/delayReason", //延船发送邮件
                    delayReasonBatch: "/operation/container/delayReasonBatch", //延船发送邮件
                    //校验是否可以装柜
                    checkCanLoadContainer:'/operation/container/checkCanLoadContainer',
                    checkCanLoadContainerBatch:'/operation/container/checkCanLoadContainerBatch',
                    getSonList:'/operation/container/getSonContainerListByContainerId',
                    salesSync:'/operation/container/updateOsContainerSalesIsSync',
                    salesNoSync:'/operation/container/updateOsContainerSalesNoSync',
                    updateRailwayDriveBatch:"/operation/container/updateRailwayDriveBatch"

                },
                sonVisible:false,
                sonTableList:[],
                countryList: [],
                addressList: [],
                orderNoList: [],
                tableDataAdd: [],
                tableDataTihuo: [],
                addGoddSize: '',
                tihuoSize: '',
                ipaginationAdd: {
                    currentPage: 1,
                    pageSize: 50,
                    pageSizes: [10, 20, 50, 100],
                    total: 0
                },
                ipaginationTiHuo: {
                    currentPage: 1,
                    pageSize: 50,
                    pageSizes: [10, 20, 50, 100],
                    total: 0
                },
                zhuangGuiPicShow: [],
                zhuangGuiRemark:'',
                zhuangGuiTime:'',
                zhuangguiList:[],
                isOther: false,
              isUsePost:true
            }
                ;
        },
        methods: {
          syncNoSales(){
            this.$confirm('是否取消同步销售，该操作之后销售部预排柜页面不可以看到该柜子?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
              closeOnClickModal: false,
            }).then(()=>{
              postAction(this.url.salesNoSync,this.selectedRowKeys)
                  .then(res=>{
                    this.$message.success(res.message);
                    this.handleQuery();
                  })
            })
          },
          syncSales(){
            this.$confirm('是否同步销售，该操作之后销售部预排柜页面可以看到该柜子?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
              closeOnClickModal: false,
            }).then(()=>{
              postAction(this.url.salesSync,this.selectedRowKeys)
                  .then(res=>{
                    this.$message.success(res.message);
                    this.handleQuery();
                  })
            })
          },
            getSonList(row){
                this.rowContainerId = row.id;
                postAction(this.url.getSonList,{id:row.id})
                    .then(res=>{
                        this.sonTableList = res.result;
                        this.sonVisible = true;
                    })
            },
            close(){
                this.sonVisible = false;
            },
            booking(id) {
                this.preview(
                    this.url.booking + "?containerId=" + id,
                    {}
                );
            },
            bookingExcel(id) {
                this.downloadExcel(
                    this.url.booking + "?containerId=" + id + "&t=excel", '订舱单下载',
                    {}
                );
            },
            intoDocpage(orderNo) {
                // 对于路由不在左侧菜单中的选中菜单处理
                setUpRouteUtil(this.$route.path, '/docpage/Edit');
                this.$router.push("/docpage/Edit?orderNo=" + orderNo);

            },
            infomation(id) {
                // 对于路由不在左侧菜单中的选中菜单处理
                setUpRouteUtil(this.$route.path, '/operation/cabinet/Edit');
                this.$router.push("/operation/cabinet/Edit?id=" + id);
                console.log(id);
            },
            handleAlertCabinrtBatch() {
                this.dialogCirculationStateVisible = true;
                //清空装柜时间
                this.updateAlertCabinrt= {};
                // this.updateAlertCabinrt = JSON.parse(JSON.stringify(row));
            },
            //批量删除-界面优化
            handledelBatch() {
                    let id = this.selectedRowKeys.join(',');
                    let queryForm  = {id}
                this.$confirm('是否删除柜子?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    closeOnClickModal: false,
                }).then(() => {
                    deleteAction(this.url.delCabinetBatch, queryForm)
                        .then((res) => {
                            if (res.success) {
                                this.$message.success(res.message);
                                this.handleQuery();
                            }
                        })
                })
            },
            handledel(row) {
                if(row.containerId){
                    let containerId = this.selectedRowKeys.join(',');
                    this.row = {containerId}
                }
                console.log(row,3)
                this.$confirm('是否删除柜子?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    closeOnClickModal: false,
                }).then(() => {
                    deleteAction(this.url.delCabinet, row)
                        .then((res) => {
                            if (res.success) {
                                this.$message.success(res.message);
                                this.handleQuery();
                            }
                        })
                })
            },
            handleAlertCabinrt(row) {
                console.log(row);
                this.dialogCirculationStateVisible = true;
                if (row) {
                    this.updateAlertCabinrt = JSON.parse(JSON.stringify(row));
                }
            },
            updateHandleAlertCabinrt() {
                // this.updateAlertCabinrt.circulationState = "8"; //已排柜待装柜
                // this.updateAlertCabinrt.complianceState = "1"; //未审核
                // this.updateAlertCabinrt.actionType = "complianceState";
                if (null == this.updateAlertCabinrt.loadTime) {
                    this.$message.error("预计装柜时间不为空");
                    return;
                }
                let list = JSON.parse(JSON.stringify(this.selectionRows));
                list.forEach((item) => {
                    item.circulationState = "8"; //已排柜待装柜
                    item.complianceState = "1"; //未审核
                    item.actionType = "complianceState";
                    item.loadTime = this.updateAlertCabinrt.loadTime;
                });
                console.log(this.updateAlertCabinrt);
                postAction(this.url.updateCirculationStateSendMessageBatch + "?button=排柜", list)
                    .then((res) => {
                        if (res.success) {
                            this.$message.success(res.message);
                            //关闭弹窗
                            this.dialogCirculationStateVisible = false;
                            this.handleQuery();
                        }
                    })
            },
            isAureToSailCabinrt(row) {

                this.updateAlertCabinrt = JSON.parse(JSON.stringify(row));
                //根据osId 获取 url
                getAction(this.url.getList + '?ids=' + row.zhuangguiOssId)
                    .then((res) => {
                        if (res.success) {
                            this.zhuangGuiPicShow = res.result;
                        }
                    });

                this.dialogFormVisibleZhuangGui = true;

            },
            //点已装柜
            isAureToSailCabinrt2(row) {

                this.updateAlertCabinrt = JSON.parse(JSON.stringify(row));
                //根据osId 获取 url
                // getAction(this.url.getList + '?ids=' + row.zhuangguiOssId)
                //     .then((res) => {
                //         if (res.success) {
                //             this.zhuangGuiPicShow = res.result;
                //         }
                //     });
                postAction(this.url.checkCanLoadContainerBatch, this.selectedRowKeys)
                .then(res=>{
                    this.zhuangguiList = res.result;
                    this.zhuangGuiTime = res.result[0] && res.result[0].lockerTime;
                    // this.zhuangGuiRemark = res.result.remark;
                    // this.zhuangGuiTime = res.result.lockerTime;
                    // this.zhuangGuiPicShow = res.result.ossFileList;
                    this.dialogFormVisibleZhuangGui = true;
                })




            },
            //确认装柜
            updateHandleToSailCabinrt() {
                this.$confirm("已核对并确认仓库装柜数据与系统数据一致，确定装柜？", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "info",
                    closeOnClickModal: false,
                }).then(() => {
                    this.updateAlertCabinrt.circulationState = "1";
                    this.updateAlertCabinrt.actionType = "circulationState";
                    this.updateAlertCabinrt.loadTime = this.zhuangGuiTime;
                    let list = JSON.parse(JSON.stringify(this.selectionRows));
                    list.forEach((item) => {
                        item.circulationState = "1";
                        item.actionType = "circulationState";
                        item.loadTime = this.zhuangGuiTime;
                    });
                    postAction(this.url.updateCirculationStateSendMessageBatch + "?button=装柜", list)
                        .then((res) => {
                            if (res.success) {
                                this.$message.success(res.message);
                                //关闭弹窗
                                this.dialogFormVisibleZhuangGui = false;
                                this.handleQuery();
                            }
                        })
                }).catch(() => {

                })

            },
            handleSelectionChange(selection) {
                // 多选
                console.log(selection);
                let arr = [];
                for (let i = 0; i < selection.length; i++) {
                    arr.push(selection[i]["id"]);
                }
                this.selectionRows = selection;
                this.selectedRowKeys = [...arr];
            },
            handleEdit(row) {
                console.log(row);
                this.EditCabinetForm = JSON.parse(JSON.stringify(row));
                console.log(this.EditCabinetForm);
                this.dialogFormEditCabinetVisible = true;
            },
            EditCabinet() {
                let volume = this.EditCabinetForm.volume;

                console.log(volume);
                this.EditCabinetForm.volume = 10000;
                /* if (this.EditCabinetForm.size == "20GP") {
                     this.EditCabinetForm.volume = 10000;
                 } else if (this.EditCabinetForm.size == "40GP") {
                     this.EditCabinetForm.volume = 10000;
                 } else if (this.EditCabinetForm.size == "40RH") {
                     this.EditCabinetForm.volume = 10000;
                 } else if (this.EditCabinetForm.size == "40HQ") {
                     this.EditCabinetForm.volume = 10000;
                 } else if (this.EditCabinetForm.size == "45HQ") {
                     this.EditCabinetForm.volume = 10000;
                 }*/
                if (Number(volume) > Number(this.EditCabinetForm.volume)) {
                    this.$message.error("不能改变成更小容积的柜型！");
                    this.EditCabinetForm.volume = volume;
                    return false;
                }

                putAction(this.url.updateCirculationState, this.EditCabinetForm)
                    .then((res) => {
                        if (res.success) {
                            this.$message.success(res.message);
                            //关闭弹窗
                            this.dialogFormEditCabinetVisible = false;
                            this.handleQuery();
                        }
                    })
            },
            saveEdit() {
                //编辑保存
                if (this.form.id) {
                    putAction(this.url.edit, this.form)
                        .then((res) => {
                            if (res.success) {
                                this.$message.success(res.message);
                                //关闭弹窗
                                this.dialogFormVisible = false;
                                this.handleQuery();
                            }
                        })
                }
            },
            handleReset() {
                this.queryForm = {};
                this.queryForm.pageType = "1";
                this.ipagination.currentPage = 1;
                this.handleQuery();
            },
            // 排柜表下载
            paiGui(row) {
                getAction(this.url.getPaiGui + '?containerId=' + row.id, {}, {responseType: 'blob'}).then(response => {
                    let u = window.URL.createObjectURL(response);
                    let aLink = document.createElement('a');
                    aLink.style.display = 'none';
                    aLink.href = u;
                    aLink.setAttribute('download', "排柜表-" + row.week + ".xls");
                    document.body.appendChild(aLink);
                    aLink.click();
                    document.body.removeChild(aLink);
                    window.URL.revokeObjectURL(u);
                })
            },
            //确认加货
            sureAddGood(row) {
                this.form = {};
                this.form.isCancel = '0';
                this.form.containerId = row.id;
                this.form.type = '0';
                this.jiahuoList();
            },

            //加货列表
            jiahuoList() {
                if (this.form.type == '0') {
                    if (this.ipaginationAdd) {
                        this.form.pageNo = this.ipaginationAdd.currentPage
                        this.form.pageSize = this.ipaginationAdd.pageSize
                    }
                } else {
                    if (this.ipaginationTiHuo) {
                        this.form.pageNo = this.ipaginationTiHuo.currentPage
                        this.form.pageSize = this.ipaginationTiHuo.pageSize
                    }
                }
                getAction(this.url.addList, this.form).then((res) => {
                    console.log(res)
                    if (res.success) {
                        if (res.result) {
                            const {records, total} = res.result
                            if (this.form.type == '0') {
                                this.tableDataAdd = records || res.result;
                                this.ipaginationAdd && (this.ipaginationAdd.total = total || res.result.length)
                            } else {
                                this.tableDataTihuo = records || res.result;
                                this.ipaginationTiHuo && (this.ipaginationTiHuo.total = total || res.result.length)
                            }

                        } else {
                            if (this.form.type == '0') {
                                this.tableDataAdd = [];
                                this.ipaginationAdd = 0;
                            } else {
                                this.tableDataTihuo = [];
                                this.ipaginationTiHuo = 0;
                            }
                        }
                        if (this.form.type == '0') {
                            this.dialogFormVisibleAddGood = true
                        } else {
                            this.dialogFormVisibleTihuo = true;
                        }
                    }
                })
            },
            sureOrderNo(row) {
                this.$set(this.form, 'orderNoList', [])
                this.form.id = row.id;
                this.form.cmb = row.cmb;
                this.form.sureBy = row.sureBy;
                this.form.tihuoOrderNos = row.tihuoOrderNos;
                this.form.alreadyCmb = row.alreadyCmb;
                this.form.containerId = row.containerId;
                this.dialogFormVisibleAddGoodOrderNo = true;
            },
            sureAddGoodSure() {
                //将选择的单号和柜子id绑定
                postAction(this.url.sureAddGood, this.form).then((res) => {
                    if (res.success) {
                        this.$message.success(res.message);
                        this.form.id = '';
                        this.form.cmb = '';
                        this.form.alreadyCmb = '';
                        this.form.tihuoOrderNos = '';
                        this.form.sureBy = '';
                        this.jiahuoList();  //柜子id
                        this.handleQuery();
                        this.dialogFormVisibleAddGoodOrderNo = false;
                    }
                })
            },
            //获取加货的单号
            initAddGoodOrderNoList() {
                getAction(this.url.orderNolist).then((res) => {
                    if (res.success) {
                        this.orderNoList = res.result;
                    }
                })
            },
            //将选择的单号转成逗号隔开
            getOrderNoListJoin(orderNoList) {
                if (orderNoList && orderNoList.length && Array.isArray(orderNoList)) {
                    this.$set(this.form, 'orderNos', orderNoList.join(','))
                }
            },
            //确认踢货
            sureTiHuo(row) {
                this.form = {};
                this.form.isCancel = '0';
                this.form.containerId = row.id;
                this.form.type = '1';
                this.jiahuoList();
            },
            //最终确认踢货（解绑单号和柜子）
            sureUpdate(row) {
                this.form.id = row.id;
                this.form.tihuoOrderNos = row.tihuoOrderNos;
                //将选择的单号和柜子id绑定
                postAction(this.url.sureTihuo, this.form).then((res) => {
                    if (res.success) {
                        this.$message.success(res.message);
                        this.form.id = '';
                        this.form.tihuoOrderNos = '';
                        this.jiahuoList();
                        this.handleQuery();
                        this.dialogFormVisibleAddGoodOrderNo = false;
                    }
                })
            },
            //装柜图片下载
            zhuangguiPic(row) {
                if (row.zhuangguiOssId) {
                    var arrstr = row.zhuangguiOssId.split(",");
                    if (null == arrstr || arrstr.length <= 0) {
                        this.$message.warning("暂无装柜图片");
                    } else {
                        postAction(this.url.batchDownload, arrstr, {
                            responseType: "blob",
                        }).then((response) => {
                            let u = window.URL.createObjectURL(response);
                            let aLink = document.createElement("a");
                            aLink.style.display = "none";
                            aLink.href = u;
                            aLink.setAttribute("download", row.week + "的装柜图片.zip");
                            document.body.appendChild(aLink);
                            aLink.click();
                            document.body.removeChild(aLink);
                            window.URL.revokeObjectURL(u);
                        });
                    }
                } else {
                    this.$message.warning("暂无装柜图片");
                }
            },
            //批量装柜图片下载
            zhuangguiPicBatch() {
                let list = [];
                if (!this.selectionRows || this.selectionRows.length == 0) {
                    this.$message.warning("暂无装柜图片");
                    return
                }

                this.selectionRows.forEach(r => {
                    if (r.zhuangguiOssId) {
                        var arrstr = r.zhuangguiOssId.split(",");
                        if (null == arrstr || arrstr.length <= 0) {
                            this.$message.warning("周数[" + r.week +"]暂无装柜图片");
                            throw new Error("周数[" + r.week +"]暂无装柜图片")
                        }
                        list = list.concat(arrstr);
                        // list.push(arrstr);
                    } else {
                        this.$message.warning("周数[" + r.week +"]暂无装柜图片");
                        throw new Error("周数[" + r.week +"]暂无装柜图片")
                    }

                })
                postAction(this.url.batchDownload, list, {responseType: "blob",
                }).then((response) => {
                    let u = window.URL.createObjectURL(response);
                    let aLink = document.createElement("a");
                    aLink.style.display = "none";
                    aLink.href = u;
                    aLink.setAttribute("download", "装柜图片.zip");
                    document.body.appendChild(aLink);
                    aLink.click();
                    document.body.removeChild(aLink);
                    window.URL.revokeObjectURL(u);
                });
            },
            //延船
            yanChuan(row) {
                this.form = {};
                this.form.id = row.id;
                this.isOther = false;
                this.dialogFormVisibleDelayShip = true;
            },
            //延船发送邮件
            yanChuanSendEmail() {
                this.$refs.delayFrom.validate(valid => {
                    if (valid) {
                        this.$store.commit("setFullscreenLoading", true);
                        if (this.form.delayShipReason == '其他原因') {
                            this.form.delayShipReason = this.form.otherReason;
                        }
                        postAction(this.url.delayReasonBatch + "?etd=" + this.form.etd + "&delayShipReason=" + this.form.delayShipReason, this.selectionRows).then((res) => {
                            this.$store.commit("setFullscreenLoading", false);
                            if (res.success) {
                                this.$message.success(res.message);
                                this.dialogFormVisibleDelayShip = false;
                            }

                        }).catch((err) => {
                            this.$store.commit("setFullscreenLoading", false);
                        });
                    }
                });
            },
            handleSizeChangeAdd(val) {
                if (this.form.type == '0') {
                    this.ipaginationAdd.currentPage = 1
                    this.ipaginationAdd.pageSize = val
                } else {
                    this.ipaginationTiHuo.currentPage = 1
                    this.ipaginationTiHuo.pageSize = val
                }
                this.jiahuoList();
            },
            handleCurrentChangeAdd(val) {
                if (this.form.type == '0') {
                    this.ipaginationAdd.currentPage = val
                } else {
                    this.ipaginationTiHuo.currentPage = val
                }
                this.jiahuoList();
            },
            //延船原因
            delayShipReasonChange() {
                if (this.form.delayShipReason == '其他原因') {
                    this.isOther = true;
                }else {
                    this.isOther = false;
                }
            },
            pullOut() {
                  this.$store.commit("setFullscreenLoading", true);
                 postAction(this.url.updateRailwayDriveBatch + "?button=卡航发车",this.selectionRows).then((res) => {
                            this.$store.commit("setFullscreenLoading", false);
                            if (res.success) {
                                this.$message.success(res.message);
                            }

                        }).catch((err) => {
                            this.$store.commit("setFullscreenLoading", false);
                        });
            }
        },
        created() {
            this.queryForm.pageType = "1";
        },
      computed:{
          buttonShowList() {
            return {
              'sc':this.$btnIsShow('operation/cabinet/WaitingStuffing','1','删除'),
              'pg':this.$btnIsShow('operation/cabinet/WaitingStuffing','1','排柜'),
              'zg':this.$btnIsShow('operation/cabinet/WaitingStuffing','1','装柜'),
              'zgtp':this.$btnIsShow('operation/cabinet/WaitingStuffing','1','装柜图片'),
              'delay':this.$btnIsShow('operation/cabinet/WaitingStuffing','1','延船'),
              'syncSales':this.$btnIsShow('operation/cabinet/WaitingStuffing','0','同步销售'),
              'nsyncSales':this.$btnIsShow('operation/cabinet/WaitingStuffing','0','取消同步销售'),
              'xggz':this.$btnIsShow('operation/cabinet/WaitingStuffing','1','修改柜子'),
              'pgb':this.$btnIsShow('operation/cabinet/WaitingStuffing','1','排柜表'),
              'confirmh':this.$btnIsShow('operation/cabinet/WaitingStuffing','1','确认加货'),
              'confirmt':this.$btnIsShow('operation/cabinet/WaitingStuffing','1','确认踢货'),
              'khfc':this.$btnIsShow('operation/cabinet/WaitingStuffing','0','卡航发车'),
            }
          }
      }
    };
</script>

<style lang="scss" scoped>
</style>
