<template>
<!--  X33691 freda 问题件列表-->
    <div>
        <el-card class="box-card">
            <el-form class="multiline_select" ref="form" :model="queryForm" label-width="80px" size="medium">
                <el-card class="box-card">
                    <el-row>
                        <el-col :xs="24" :sm="12" :md="12" :lg="6">
                            <el-form-item label="单号">
                                <el-input  v-model="queryForm.orderNo" type="textarea"
                                           @keyup.enter.native="handleSearch"></el-input>
                            </el-form-item>
                        </el-col>
                      <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="销售名">
                          <f-select  v-model="queryForm.salesmanIds" :is-need="queryForm.salesmanIds" :dict="'sys_user_id'"
                                     :multiple="true"
                                     @keyup.enter.native="handleSearch"></f-select>
                        </el-form-item>
                      </el-col>
                      <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="问题类型">
                          <f-select  v-model="queryForm.problemTypes" :is-need="queryForm.problemTypes" :dict="'problem_type'"
                                     :multiple="true"
                                     @keyup.enter.native="handleSearch"></f-select>
                        </el-form-item>
                      </el-col>


                        <el-col :xs="24" :sm="12" :md="12" :lg="4">
                            <el-form-item label-width="30px">
                                <el-button
                                        type="primary"
                                        icon="el-icon-search"
                                        @click="handleSearch"
                                >查询
                                </el-button
                                >
                                <el-button plain @click="handleReset">重置</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>


                </el-card>
            </el-form>
            <div class="bbb">

                <el-button type="primary" size="medium" @click="exportExcelPlus(url.exportExcelUrl,'问题件明细')"
                           v-if="buttonShowList.dc">导出
                </el-button>
            </div>

            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="73vh"
            >
                <template v-slot="scope">
                  <template v-if="scope.column.property === 'orderNo'">
                    <a
                        style="color: #00a0e9"
                        @click.prevent="$intoDocPage(scope.row.orderNo)"
                    >{{ scope.row.orderNo }}</a
                    >
                  </template>
                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange">
                </el-pagination>
            </el-row>
        </el-card>
    </div>
</template>

<script>
    import QueryMixins from "@/mixins/QueryMixins.js";
    import {deleteAction, postAction,httpAction} from "@/api/manage";
    export default {
        name: "ScanLog",
        mixins: [QueryMixins],
        components: {},
        data() {
            return {
                isUsePost:true,

                columns: [
                    // {
                    //     type: "selection",
                    // },
                    // {
                    //     prop: "action",
                    //     label: "操作",
                    // },

                  {
                    prop: "salesman",
                    label: "销售",
                  },
                  {
                    prop: "doc",
                    label: "客服",
                  },
                  {
                    prop: "operator",
                    label: "操作",
                  },
                    {
                        prop: "orderNo",
                        label: "订单号",
                    },
                  {
                    prop: "customer",
                    label: "客户名",
                  },
                  {
                    prop: "channelName",
                    label: "渠道",
                  },
                  {
                    prop: "hasContainerId",
                    label: "是否排柜",
                  },
                  {
                    prop: "delivery",
                    label: "订单状态",
                  },
                  {
                    prop: "problemType",
                    label: "问题类型",
                  },
                  {
                    prop: "problemDetail",
                    label: "问题详情",
                  },
                  {
                    prop: "problemMan",
                    label: "录入问题件人",
                  },
                  {
                    prop: "chuli",
                    label: "处理状态",
                  },
                    {
                        prop: "createTime",
                        label: "订单跳进问题件列表时间",
                    },

                ],
                url: {
                    //查询列表的接口
                    list: "/file/sale3/selectProblemIPage",

                    //导出excel的接口
                    exportExcelUrl: '/file/sale3/dcProblem',
                },


            };
        },
        methods: {

        },
      computed:{
        buttonShowList() {
          return {
            'dc':this.$btnIsShow('sale/Problem','0','导出问题件'),
          }
        }
      }
    };
</script>

<style lang="scss" scoped>
    .bbb {
        display: flex;
    }

    .dialog-footer {
        text-align: right !important;
    }

    .margin_right_10 {
        margin-right: 10px;
    }
</style>
