<template>
  <div class="main-full-content">
    <el-form
      class="input_top"
      ref="form"
      :model="queryForm"
      label-width="120px"
      size="medium"
    >
      <el-card class="box-card">
        <el-row>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="单号">
              <el-input v-model="queryForm.logNo"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-button
              style="margin-left: 15px"
              type="primary"
              size="medium"
              icon="el-icon-search"
              @click="handleSearch"
              >查询
            </el-button>
            <el-button plain size="medium" @click="handleReset">重置</el-button>
          </el-col>
        </el-row>
      </el-card>
    </el-form>

    <el-card class="box-card last_card">
      <f-table
        v-loading="loading"
        ref="GTable"
        border
        size="medium"
        row-key="id"
        class="c-th-has-bgcolor"
        :columns="columns"
        :data="tableData"
        :cell-style="{ 'text-align': 'center' }"
        tableHeight="72vh"
        :isRecordTableScroll="true"
        @selection-change="handleSelectionChange"
        @sort-change="handleTableChange"
      >
        <template v-slot="scope">
          <template v-if="scope.column.property === 'logNo'">
            <el-button type="text" @click="showInfo(scope.row)">{{
              scope.row.logNo
            }}</el-button>
          </template>
        </template>
      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination
          v-bind="ipagination"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </el-row>
    </el-card>

    <!-- 弹框 -->
    <track-dialog
      :visible="visible"
      :row="row"
      @update:visible="
        (val) => {
          visible = val;
        }
      "
    />
  </div>
</template>

<script>
import QueryMixins from "@/mixins/QueryMixins";
import trackDialog from "./component/trackDialog";

export default {
  name: "LogisticsTracking",
  mixins: [QueryMixins],
  components: { trackDialog },
  data() {
    return {
      loading: false,
      tableData: [],
      initData: {},
      visible: false,
      row: {},
      columns: [
        {
          type: "selection",
          width: 50,
        },
        //操作
        // {
        //   prop: "action",
        //   label: "操作",
        //   width: 100,
        // },

        {
          prop: "logNo",
          label: "跟踪单号",
        },
        {
          prop: "lastEvent",
          label: "最新轨迹",
        },
        {
          prop: "lastEventTime",
          label: "最新轨迹时间",
        },
        {
          prop: "orderNo",
          label: "订单号",
        },
      ],
      // 分页参数
      // type: "radio",
      url: {
        list: "/interface/ttOrder/getStatusTrackDetailsList",
      },
    };
  },
  created() {
    this.initData.orderNo = this.$route.query.orderNo;
    console.log(this.initData, "this.initData");
    console.log(this.$route, "this.$route");
  },
  methods: {
    showInfo(row) {
      console.log(row, "rowww");
      // this.row.trackIng=[{}]
      this.row = row;
      this.visible = true;
    },
  },
};
</script>
<style scoped>
</style>
