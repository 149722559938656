<template>
  <div class="main-full-content">
    <el-form class="no_margin" ref="form" :model="queryForm" label-width="100px" size="small" @submit.native.prevent>
      <el-card class="box-card">
        <el-row class="inputs_row">
          <el-col :xs="24" :sm="12" :md="12" :lg="8">
            <el-form-item label="页面名字">
              <el-input v-model="queryForm.name" @keyup.enter.native="handleSearch"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label-width="30px">
              <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
    </el-form>
    <el-card class="box-card last_card">
        <div class="f-p-b-15">
        <el-button type="primary" size="small" icon="el-icon-plus" @click="handleAdd"

                   v-if="buttonShowList.add"
        >新增</el-button>
        <el-button type="primary" size="small" icon="el-icon-plus" @click="handleDelBatch"
                   :disabled="selectedRowKeys.length===0"
                   v-if="buttonShowList.plsc"
        >批量删除</el-button>
<!--          <el-button type="primary" size="small" @click="addVueList" v-if="menuId && menuId.length > 0" >添加vue</el-button>-->
      </div>
      <el-dialog :title="'选择菜单:'+menu.name" :visible.sync="menuVisible" v-if="menuVisible"
                 :close-on-click-modal="false" append-to-body>
        <el-form :model="menu" ref="menu" :rules="rules">
          <el-form-item prop="menuId" label="所属菜单">
            <f-select :dict="'btn_menu'" v-model="menu.menuId" :is-need="menu.menuId" :father-val="menu.menuId"></f-select>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button size="small" @click="menuVisible = false">取 消</el-button>
          <el-button size="small" type="primary" @click="saveMenu()">确 定</el-button>
        </div>
      </el-dialog>
      <el-dialog :title="'添加页面'" :visible.sync="vueVis" v-if="vueVis"
                 :close-on-click-modal="false" append-to-body>
        <el-form :model="vueModel" ref="vueModel" :rules="rules">
          <el-form-item prop="vueIdList" label="vue页面">
            <el-select  v-model="vueModel.vueIdList"
            clearable filterable multiple
            >
              <el-option v-for="(item,index) in vueList" :label="item.text" :value="item.value" :key="index"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button size="small" @click="vueVis = false">取 消</el-button>
          <el-button size="small" type="primary" @click="saveVueList()">确 定</el-button>
        </div>
      </el-dialog>
      <f-table
        v-loading="loading"
        ref="GTable"
        border
        size="small"
        row-key="id"
        class="c-th-has-bgcolor"
        :columns="columns"
        :data="tableData"
        :cell-style="{ 'text-align': 'center' }"
        tableHeight="48vh"
        @selection-change="handleSelectionChange"
        @sort-change="handleTableChange">
      <template v-slot="scope">
        <template v-if="scope.column.property === 'action'">
          <el-button type="text" @click="handleEdit(scope.row)"

                     v-if="buttonShowList.bjal"
          >编辑按钮</el-button>
          <el-button type="text" @click="handleDel(scope.row)"

                     v-if="buttonShowList.sc"
          >删除</el-button>
          <el-button type="text" @click="addMenu(scope.row)">添加菜单</el-button>
        </template>

      </template>
    </f-table>
    <el-row class="f-text-right f-p-v-8">
      <el-pagination
          v-bind="ipagination"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange">
      </el-pagination>
    </el-row>
    </el-card>
    <el-dialog :title="'新增基础vue页面'" :visible.sync="dialogFormVisible" :close-on-click-modal="false"
               v-if="dialogFormVisible" append-to-body>
      <el-form :model="form" :rules="rules" ref="form">

        <el-form-item label="名字" :label-width="formLabelWidth" prop="name">
          <el-input v-model="form.name" autocomplete="off" type="textarea" autosize></el-input>
        </el-form-item>
        <el-form-item label="路径" :label-width="formLabelWidth" prop="path">
          <el-input v-model="form.path" autocomplete="off" type="textarea" autosize></el-input>
        </el-form-item>
        <el-form-item label="所属菜单" :label-width="formLabelWidth" prop="menuId">
          <f-select v-model="form.menuId" :dict="'btn_menu'" :is-need="form.menuId" :father-val="form.menuId"></f-select>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogFormVisible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="handleSave">确 定</el-button>
      </div>
    </el-dialog>
    <bd-btn :vue-id="vueId" :path="path" :visible="visible" @ok="ok" v-if="visible"></bd-btn>
  </div>
</template>

<script>
import {deleteAction, postAction, putAction} from '@/api/manage'
import QueryMixins from "../../../mixins/QueryMixins";
import {yingwenPattern} from '@/utils/pattern';
import BdBtn from "./BdBtn";
import {getAction} from "../../../api/manage";

export default {
  name: 'Importer',
  mixins: [QueryMixins],
  components:{BdBtn},
  props:{
    menuId:{
      type:String,
      default:'',
    }
  },
  data() {
    return {
      vueList:[],
      vueVis:false,
      vueModel:{vueIdList:[]},
      menuVisible:false,
      menu:{},
      // dictCodes: ['apportion_type'],
      rules: {
        name: [
          {required: true, message: '请输入页面名字', trigger: 'blur'},
          {max: 255, message: '长度不能超过50', trigger: 'change'},

        ],
        path: [
          {required: true, message: '请输入页面路径', trigger: 'blur'},
          {max: 255, message: '长度不能超过50', trigger: 'change'},
        ],
        menuId: [
          {required: true, message: '请选择所属菜单', trigger: 'change'},

        ],


      },
      ids: [],
      row: {},
      form: {},
      nameList:[],
      formLabelWidth: '110px',
      //控制弹框显示
      dialogFormVisible: false,
      columns: [
        {
          type: 'selection',
          width: 50
        },

        {
          prop: 'name',
          label: '页面名字',
          sortable: 'custom',

        },
        {
          prop: 'path',
          label: '页面路径',
          sortable: 'custom',

        },
        {
          prop: 'createBy',
          label: '创建人',
          sortable: 'custom',

        },
        {
          prop: 'createTime',
          label: '创建时间',
          sortable: 'custom',

        },
        {
          prop: 'action',
          label: '操作',

        },
      ],
      url: {
        list: '/sys/button/auth/vueList',
        add: '/sys/button/auth/addVue',
        edit: '/sys/button/auth/updateVue',
        del:'/sys/button/auth/delVue',
        delBatch:'/sys/button/auth/delVueBatch',
        getVueList:'/sys/button/auth/getVueList',
        addMenuWithVueId:'/sys/button/auth/addMenuWithVueId',
        initVueList:'/sys/dict/getDictItems/btn_vue',
      },
      visible:false,
      vueId:'',
      path:'',
    }
  },
  methods: {
    initGetVueList(){
      if (this.menuId && this.menuId.length) {
        getAction(this.url.initVueList).then(res => {
          this.vueList = res.result;
        })
      }

    },
    addVueList(){

      getAction(this.url.getVueList,{menuId: this.menuId})
      .then(res=>{
        if (res.success){
          if(res.result && Array.isArray(res.result)){
            this.$set(this.vueModel,'vueIdList',res.result||[]);
          }else {
            this.$set(this.vueModel,'vueIdList',[]);
          }
        }else {
          this.$set(this.vueModel,'vueIdList',res.result||[]);
        }
        this.vueVis = true;
      })
    },
    saveVueList(){
      this.$refs['vueModel'].validate(vaid=>{
        if (vaid){
          putAction('/sys/button/auth/addMenuWithVueId'+'?menuId=' + this.menuId,this.vueModel.vueIdList)
                  .then(res=>{
                    this.$message.success(res.message);
                    this.handleQuery();
                      this.vueVis = false;
                  })
        }
      })
    },
    saveMenu(){
      this.$refs['menu'].validate(vaid=>{
        if (vaid){
          putAction('/sys/button/auth/addVueMenuId'+'?vueId=' + this.menu.vueId + '&menuId='+this.menu.menuId)
          .then(res=>{
            this.$message.success(res.message);
            this.menuVisible = false;
            this.handleQuery();
          })
        }
      })
    },
    addMenu({id,menuId,name}){
      this.$set(this.menu,'vueId',id);
      this.$set(this.menu,'menuId',menuId);
      this.$set(this.menu,'name',name);
      this.menuVisible = true;
    },
    ok(){
      this.visible = false;
    },
    handleSelectionChange(selection) { // 多选
      let arr = []
      this.emailList = [];
      this.nameList = [];
      for (let i = 0; i < selection.length; i++) {
        arr.push(selection[i]['id'])
        this.emailList.push(selection[i]['email']);
        this.nameList.push(selection[i]['name']);
      }
      this.selectionRows = selection
      this.selectedRowKeys = [...arr]
    },
    handleAdd() {
      this.form = {};
      this.$set(this.form,'menuId',this.menuId);
      this.dialogFormVisible = true;
    },
    handleEdit(row) {
      //将对象转为json字符串再转回对象
      //this.form = row; 复制的是指针 仍然指向这个对象row
      this.vueId = row.id;
      this.path = row.path;
      this.visible = true;

    },
    handleDel(row) {
      this.$confirm('确定删除该页面:' + row.name + ',该操作会同时删除页面下的按钮与权限', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error',
        closeOnClickModal: false,
      }).then(() => {
        deleteAction(this.url.del, {id: row.id}).then(res => {
            this.$message({
                type: 'success',
                message: res.message
            })

            this.handleSearch()
        })
      }).catch(err=>{
        this.$message.error(err.message);
      })
    },
    handleDelBatch(){
      this.$confirm('确定批量删除：'+this.nameList.join(',') + '这些页面吗,该操作会同时删除页面下的按钮与权限', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error',
        closeOnClickModal: false,
      }).then(() => {
        deleteAction(this.url.delBatch, {ids: this.selectedRowKeys.join(',')}).then(res => {
          this.$message({
            type: 'success',
            message: res.message
          })

          this.handleSearch()
        })
      }).catch(err=>{
        this.$message.error(err.message);
      })
    },
    handleSave() {
      this.$refs.form.validate(valid => {
        if (valid) {
          //编辑
          // this.$set(this.form,'menuId',this.menuId);
          if (this.form.id) {
            putAction(this.url.edit, this.form)
                .then((res) => {
                  if (res.success) {
                    this.$message.success(res.message);
                    //关闭弹窗
                    this.dialogFormVisible = false;
                    sessionStorage.removeItem("dict:sys_vue");
                    this.handleQuery()
                  }
                })
          } else {
            postAction(this.url.add, this.form)
                .then((res) => {
                  if (res.success) {
                    this.$message.success(res.message);
                    //关闭弹窗
                    this.dialogFormVisible = false;
                    sessionStorage.removeItem("dict:sys_vue");
                    this.handleQuery()
                  }
                })
          }
        }
      })

    },
    handleReset() {
      this.queryForm = {};
      this.queryForm.menuId = this.menuId;
      this.filterForm = {};
      this.ipagination.currentPage = 1
      this.handleQuery()
    }
  }, created() {
    this.queryForm.menuId = this.menuId;
    this.initGetVueList();
  },
  computed:{
    buttonShowList() {
      return {
        'add':this.$btnIsShow('isystem/BaseData/BdVue','0','新增'),
        'plsc':this.$btnIsShow('isystem/BaseData/BdVue','0','批量删除'),
        'bjal':this.$btnIsShow('isystem/BaseData/BdVue','1','编辑按钮'),
        'sc':this.$btnIsShow('isystem/BaseData/BdVue','1','删除'),
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
