<template>
  <div class="main-full-content">
    <el-form ref="form" :model="queryForm" label-width="80px" size="medium">
      <el-col :span="4">
        <el-form-item label="海外仓地址:">
          <f-select v-model="queryForm.addressId" :is-need="queryForm.addressId" :dict="'slyc_address_id'"></f-select>
        </el-form-item>
      </el-col>
      <el-col :span="4">
        <el-form-item label="云仓编码:">
          <el-input v-model="queryForm.slycNo" ></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="4" class="f-m-l-20">
        <el-button
            size="medium"
            type="primary"
            @click="handleQuery"
        >查询</el-button>
      </el-col>
    </el-form>
      <el-card class="box-card">
        <el-button
          size="medium"
          icon="el-icon-plus"
          type="primary"
          @click="handleAdd"
          v-if="buttonShowList.add"
          >新增</el-button>
        <f-table
          v-loading="loading"
          ref="GTable"
          border
          size="medium"
          row-key="id"
          class="c-th-has-bgcolor"
          :columns="columns"
          :data="tableData"
          :cell-style="{ 'text-align': 'center' }"
          tableHeight="74vh"
          @selection-change="handleSelectionChange"
          @sort-change="handleTableChange"
        >
          <template v-slot="scope">
            <template v-if="scope.column.property === 'action'">
              <el-button type="text" @click="handleEdit(scope.row)" v-if="buttonShowList.edit">编辑</el-button>
              <el-button type="text" @click="handleDel(scope.row)" v-if="buttonShowList.del">删除</el-button>
            </template>
          </template>
        </f-table>
      </el-card>



    <el-dialog
      :title="form.id ? '编辑地址' : '新增地址'"
      v-if="dialogFormVisible"
      :visible.sync="dialogFormVisible"
      width="40%"
      center
      :close-on-click-modal="false"
    >
      <el-form :model="form" :rules="rules" ref="form">
        <el-row class="inputs_row" :gutter="15">
            <el-form-item
              label="海外仓地址："
              prop="addressId"
              :label-width="formLabelWidth"
              :rules="rules.addressId">
              <f-select v-model="form.addressId" :dict="'slyc_address_id'" :is-need="form.addressId" autocomplete="off"></f-select>
            </el-form-item>
            <el-form-item label="云仓编码：" prop="tel" :label-width="formLabelWidth">
              <el-input v-model="form.slycNo" autocomplete="off" maxlength="15" show-word-limit></el-input>
            </el-form-item>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="medium" @click="dialogFormVisible = false"
          >取 消</el-button
        >
        <el-button
          size="medium"
          type="primary"
          @click="saveAddress"
          >确 定</el-button
        >
      </div>
    </el-dialog>

    <!--    <el-row class="f-text-right f-p-v-8">-->
    <!--      <el-pagination-->
    <!--          v-bind="ipagination"-->
    <!--          layout="total, sizes, prev, pager, next, jumper"-->
    <!--          @size-change="handleSizeChange"-->
    <!--          @current-change="handleCurrentChange">-->
    <!--      </el-pagination>-->
    <!--    </el-row>-->
  </div>
</template>

<script>
import {deleteAction, postAction  } from "@/api/manage";
import { getUrlKey } from "@/utils/route";
import QueryMixins from "@/mixins/QueryMixins";

export default {
  name: "SlycModule",
  mixins: [QueryMixins],
  components: {},
  data() {
    return {
      visible: false,
      formLabelWidth: "140px",
      row: {},
      form: {},
      //控制弹框显示
      dialogFormVisible: false,
      //表单校验
      rules: {

        addressId: [
          { required: true, message: "请选择地址", trigger: "change" },
        ],
        slycNo: [
          { required: true, message: "请输入云仓编号", trigger: "blur" },
        ],
      },
      columns: [
        {
          prop: "action",
          label: "操作"
        },
        {
          prop: "username",
          label: "客户名",
        },
        {
          prop: "slycNo",
          label: "云仓编号",
        },
        {
          prop: "company",
          label: "公司名称",

        },
        {
          prop: "address",
          label: "详细地址",

        },
        {
          prop: "zipcode",
          label: "邮编",

        },
        {
          prop: "createTime",
          label: "创建时间",

        },
      ],
      url: {
        list: "/customer/ocean/cus/order/getSlycList",
        addOrUpdateSlyc: "/customer/ocean/cus/order/addOrUpdateSlyc",
        delOneSlyc: "/customer/ocean/cus/order/delOneSlyc",
        delDuoSlyc: "/customer/ocean/cus/order/delDuoSlyc",

      },
      span: {
        type: Number,
        default: 8,
      },
      countrylist: [],
      statelist: [],
      citylist: [],
    };
  },
  methods: {

    saveAddress() {
      //保存地址
      //获取页面上的客户id
      var cusId = getUrlKey("id");
      //form表单有id，则是编辑
      this.form.cusId = cusId;
      //校验通过才提交
      this.$refs["form"].validate((valid) => {
        if (valid) {
            postAction(this.url.addOrUpdateSlyc, this.form)
              .then((res) => {
                if (res.success) {
                  this.$message.success(res.message);
                  //关闭弹窗
                  this.dialogFormVisible = false;
                  this.handleQuery();
                }
              }).catch((err) => {
            });
          }
      });

      //反之是新增
    },

    handleEdit(row) {
      this.form = JSON.parse(JSON.stringify(row));
      this.dialogFormVisible = true;
    },
    //点击新增，显示弹窗
    handleAdd() {
      this.form = {};
      this.dialogFormVisible = true;
    },
    handleDel(row) {
      this.$confirm(`确定删除${row.slycNo}`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "success",
        closeOnClickModal: false,
      })
        .then(() => {
          deleteAction(this.url.delOneSlyc, { id: row.id }).then((res) => {
            this.$message({
              type: "success",
              message: res.message,
            });
            this.handleQuery();
          });
        });
    },

    handleBatDel() {
      this.$confirm("确定删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
        closeOnClickModal: false,
      })
        .then(() => {
          deleteAction(this.url.delDuoSlyc, {
            ids: this.selectedRowKeys.join(","),
          }).then((res) => {
            this.$message({
              type: "success",
              message: res.message,
            });
            this.handleSearch();
          });
        })
        .catch(() => {
          this.$message.error(err.message);
        });
    },

  },
  created() {
    this.queryForm.cusId = this.$route.query.id;
  },
  computed : {
    buttonShowList() {
      return {
        'add':this.$btnIsShow('customer/modules/SlycModule','0','新增'),
        'edit':this.$btnIsShow('customer/modules/SlycModule','1','编辑'),
        'del':this.$btnIsShow('customer/modules/SlycModule','1','删除'),

      }
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
