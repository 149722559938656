<!--理赔部-客户-->
<template>
  <div class="main-full-content">
    <el-form
      class="input_top"
      ref="form"
      :model="queryForm"
      label-width="120px"
    >
      <el-card class="box-card">
        <el-row>
          <el-row>
<!--            <el-col :span="4">-->
<!--              <el-form-item label="客户名:">-->
<!--                <f-select-->
<!--                  v-model="queryForm.cusId"-->
<!--                  :is-need="queryForm.cusId"-->
<!--                  :dict="'sys_cus_id'"-->
<!--                  placeholder="请选择"-->
<!--                  filterable-->
<!--                  clearable-->
<!--                >-->
<!--                </f-select>-->
<!--              </el-form-item>-->
<!--            </el-col>-->
            <el-col :span="4">
              <el-form-item label="客户名:">
                <el-input
                    v-model="queryForm.customer"
                    @keyup.enter.native="handleSearch"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="销售:">
                <f-select
                  v-model="queryForm.salesmanId"
                  :is-need="queryForm.salesmanId"
                  :dict="'salesmanId'"
                  placeholder="请选择"
                  filterable
                  clearable
                >
                </f-select>
              </el-form-item>
            </el-col>
<!--            <el-col :span="4">-->
<!--              <el-form-item label="销售:">-->
<!--                <el-input-->
<!--                    v-model="queryForm.salesman"-->
<!--                    @keyup.enter.native="handleSearch"-->
<!--                ></el-input>-->
<!--              </el-form-item>-->
<!--            </el-col>-->
            <el-col :span="4">
              <el-form-item label="客服:">
                <f-select
                  v-model="queryForm.docId"
                  :is-need="queryForm.docId"
                  :dict="'sys_user_id'"
                  placeholder="请选择"
                  filterable
                  clearable
                >
                </f-select>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="订单号:">
                <el-input
                  v-model="queryForm.orderNo"
                  @keyup.enter.native="handleSearch"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="账单状态:">
                <f-select
                  v-model="queryForm.billStatus"
                  :is-need="queryForm.billStatus"
                  :dict="'bill_status'"
                  placeholder="请选择"
                  filterable
                  clearable
                >
                </f-select>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="销账状态:">
                <f-select
                  v-model="queryForm.settleStatus"
                  :is-need="queryForm.settleStatus"
                  :dict="'claim_settle_status'"
                  placeholder="请选择"
                  filterable
                  clearable
                >
                </f-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row
            ><el-col :span="4">
              <el-form-item label="理赔单号:">
                <el-input
                  v-model="queryForm.claimNo"
                  @keyup.enter.native="handleSearch"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="币种:">
                <f-select
                  v-model="queryForm.moneyType"
                  :is-need="queryForm.moneyType"
                  :dict="'bd_cur'"
                  placeholder="请选择"
                  filterable
                  clearable
                >
                </f-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="录入时间:">
                <div class="f-flex">
                  <el-date-picker
                    style="width: 170px"
                    v-model="queryForm.createBeginTime"
                    type="datetime"
                    size="small"
                    placeholder="开始时间"
                    default-time="00:00:00"
                    value-format="yyyy-MM-dd HH:mm:ss"
                  >
                  </el-date-picker>
                  <span class="f-p-h-2">~</span>
                  <el-date-picker
                    style="width: 170px"
                    v-model="queryForm.createEndTime"
                    type="datetime"
                    size="small"
                    placeholder="结束时间"
                    default-time="23:59:59"
                    value-format="yyyy-MM-dd HH:mm:ss"
                  >
                  </el-date-picker>
                  <el-button
                    style="margin-left: 15px"
                    type="primary"
                    size="medium"
                    icon="el-icon-search"
                    @click="handleSearch"
                    >查询
                  </el-button>
                  <el-button plain size="medium" @click="handleReset"
                    >重置</el-button
                  >
                </div>
              </el-form-item>
            </el-col></el-row
          >

          <!-- <el-col :span="4">
            <el-form-item label-width="30px">
              <el-button
                type="primary"
                size="medium"
                icon="el-icon-search"
                @click="handleSearch"
                >查询
              </el-button>
              <el-button plain size="medium" @click="handleReset"
                >重置</el-button
              >
            </el-form-item>
          </el-col> -->
        </el-row>
      </el-card>
    </el-form>

    <el-card class="box-card last_card">
      <div class="f-p-b-15">
        <el-button
          size="medium"
          icon="el-icon-plus"
          type="primary"
          @click="handleAdd"
          v-if="buttonShowList.add"
          >新增
        </el-button>
        <el-button
          size="medium"
          type="primary"
          @click="multipleDelete"
          v-if="buttonShowList.delB"
          >删除
        </el-button>
        <el-button
          size="medium"
          plain
          @click="exportExcelPlus(url.downloadClaimList, '客户理赔明细')"
          v-if="buttonShowList.dc"
          >导出
        </el-button>
        <el-button
            size="medium"
            plain
            @click="importCustomerClaimVisible = true"
            v-if="buttonShowList.im"
        >导入
        </el-button>

        <el-dialog
            title="导入客户理赔"
            :visible.sync="importCustomerClaimVisible"
            :close-on-click-modal="false"
            :before-close="handleClose"
            width="600px"
            append-to-body
        >
<!--          <el-form :model="salesPriceForm" ref="salesPriceForm" :rules="rules">-->
<!--            <el-form-item-->
<!--                label="批量更改销售锁价服务"-->
<!--                :label-width="formSalesPriceLabelWidth"-->
<!--                prop="isSalesPriceName"-->
<!--            >-->
<!--              <el-radio-group v-model="salesPriceForm.isSalesPriceName">-->
<!--                <el-radio label="1">是</el-radio>-->
<!--                <el-radio label="0">否</el-radio>-->
<!--              </el-radio-group>-->
<!--            </el-form-item>-->
<!--          </el-form>-->
          <el-form  ref="form" :model="queryForm" label-width="120px" >
            <el-form-item   label="导入模板：">
              <el-button type="primary" size = "medium"  @click="downloadModel(url.downloadClaimCustomerTemplate, '模板下载')">模板下载.xls</el-button>
            </el-form-item>
            <el-form-item   label="上传文件：">
                <f-upload
                    v-model="form.fileList"
                    :dir="'osOrder'"
                    :is-use-oss="true"
                    :drag="true"
                ></f-upload>
<!--              <el-upload-->
<!--                  class="upload-demo"-->
<!--                  drag-->
<!--                  action="https://jsonplaceholder.typicode.com/posts/"-->
<!--                  multiple>-->
<!--                <i class="el-icon-upload"></i>-->
<!--                <div class="el-upload__text"-->
<!--                  style="display: flex;flex-direction: column"><div>将文件拖到此处，或<em>点击上传</em></div><span>支持扩展名: .xls  .xlsx</span></div>-->
<!--              </el-upload>-->
            </el-form-item>
          </el-form>

          <div slot="footer" class="dialog-footer">
            <el-button @click="handleClose"
            >取 消</el-button
            >
            <el-button type="primary" @click="saveImportTemplate">确 定</el-button>
          </div>
        </el-dialog>

        <el-button
          size="medium"
          plain
          @click="dataStatistics"
          v-if="buttonShowList.tj"
          >统计
        </el-button>
      </div>
      <f-table
        v-loading="loading"
        ref="GTable"
        border
        size="medium"
        row-key="id"
        class="c-th-has-bgcolor"
        :columns="columns"
        :data="tableData"
        :cell-style="{ 'text-align': 'center' }"
        tableHeight="65vh"
        @selection-change="handleSelectionChange"
        @sort-change="handleTableChange"
      >
        <template v-slot="scope">
          <template v-if="scope.column.property === 'action'">
            <el-button
              type="text"
              @click="handleEdit(scope.row)"
              :disabled="scope.row.settleStatus !== '0'"
              v-if="buttonShowList.edit"
              >编辑
            </el-button>
            <el-button
              type="text"
              @click="handleDele(scope.row)"
              :disabled="scope.row.settleStatus !== '0'"
              v-if="buttonShowList.del"
              >删除
            </el-button>
          </template>
          <template v-else-if="scope.column.property === 'responsibleParty'">
            <span>{{
              parseDict(scope.row.responsibleParty, "responsible_party")
            }}</span>
          </template>
          <template v-else-if="scope.column.property === 'claimType'">
            <span>{{ parseDict(scope.row.claimType, "claim_type") }}</span>
          </template>
          <template v-else-if="scope.column.property === 'billStatus'">
            <span>{{ parseDict(scope.row.billStatus, "bill_status") }}</span>
          </template>
          <template v-else-if="scope.column.property === 'settleStatus'">
            <span>{{
              parseDict(scope.row.settleStatus, "claim_settle_status")
            }}</span>
          </template>
          <template v-else-if="scope.column.property === 'orderNo'">
            <a
              style="color: #00a0e9"
              @click.prevent="$intoDocPage(scope.row.orderNo)"
              >{{ scope.row.orderNo }}</a
            >
          </template>
        </template>
      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination
          v-bind="ipagination"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </el-row>
    </el-card>

    <!-- 新增编辑客户理赔弹框 -->
    <add-customer-claims-dialog
      :visible="visible"
      :row="row"
      type="0"
      @update:visible="
        (val) => {
          visible = val;
        }
      "
      @ok="handleSearch"
    />
      <el-dialog title="统计数据显示"
                 :visible.sync="dialogKdCountVisible"
                 :close-on-click-modal="false"
                 v-if="dialogKdCountVisible">
          <f-table
                  ref="kdCountTb"
                  border
                  size="medium"
                  row-key="id"
                  class="c-th-has-bgcolor"
                  :columns="columnsCount"
                  :data="kdCountNum"
                  :cell-style="{ 'text-align': 'center' }"
                  tableHeight="60vh">
          </f-table>
      </el-dialog>
    <!-- 统计弹框 -->
    <statistics-dialog
      :visible="statisticsvisible"
      :params="queryForm"
      title="统计客户理赔"
      type="0"
      :queryParam="this.queryForm"
      @update:visible="
        (val) => {
          statisticsvisible = val;
        }
      "
      @ok="handleSearch"
    />
  </div>
</template>

<script>
import { setUpRouteUtil } from "@/utils/util";
import { deleteAction, getAction, postAction, putAction } from "@/api/manage";
import { zhognwenPattern } from "@/utils/pattern";
import QueryMixins from "@/mixins/QueryMixins.js";
import AddCustomerClaimsDialog from "./modules/AddCustomerClaimsDialog";
import StatisticsDialog from "./modules/StatisticsDialog";

export default {
  name: "customer",
  mixins: [QueryMixins],
  components: {
    AddCustomerClaimsDialog,
    StatisticsDialog,
  },
  data() {
    return {
      importCustomerClaimVisible:false,
      ipaginationSelect: {
        currentPage: 1,
        pageSize: 50,
        pageSizes: [10, 20, 50, 100],
        total: 0,
      },
      initData: { type: 0 },
      row: {},
      form: {fileList:[]},
      dialogFormVisibleShenhe: false,
      dialogFormVisibleSelect: false,
      chinaseName: "",
      isSelect: false,
      visible: false,
      statisticsvisible: false,
      columns: [
        {
          type: "selection",
          width: 35,
          fixed: "left",
        },
        {
          prop: "action",
          label: "操作",
          width: 80,
          fixed: "left",
        },
        {
          prop: "claimNo",
          label: "理赔单号",
          width: 110,
          fixed: "left",
        },
        {
          prop: "orderNo",
          label: "订单号",
          width: 110,
          fixed: "left",
        },
        {
          prop: "customer",
          label: "客户名",
        },
        {
          prop: "salesman",
          label: "销售",
          width: 110,
        },
        {
          prop: "doc",
          label: "客服",
          width: 110,
        },
        {
          prop: "containerNo",
          label: "柜号",
          width: 110,
        },
        {
          prop: "responsibleParty",
          label: "责任方",
          width: 60,
        },
        {
          prop: "claimType",
          label: "理赔类型",
          width: 70,
        },
        {
          prop: "claimMoney",
          label: "客户索赔金额",
          width: 80,
        },
        {
          prop: "realClaimMoney",
          label: "理赔金额",
          width: 60,
        },
        {
          prop: "moneyType",
          label: "币种",
          width: 70,
        },
          // {
          //     prop: "rate",
          //     label: "汇率",
          //     width: 70,
          // },
        {
          prop: "billStatus",
          label: "账单状态",
          width: 70,
        },
        {
          prop: "settleStatus",
          label: "销账状态",
          width: 70,
        },
        {
          prop: "applyNo",
          label: "审批编号",

        },
        {
          prop: "createBy",
          label: "录入人",

        },
        {
          prop: "createTime",
          label: "录入时间",

        },
      ],
      url: {
        list: "/file/claim/getClaimOrderPage",
        downloadClaimList: "/file/claim/customerDownloadClaimList",
        // 删除
        delete: "/file/claim/deleteClaimNoById",
          getStatusById:'/file/claim/getSettleStatusBySettleid',
          downloadClaimCustomerTemplate:'/file/claim/downloadClaimCustomerExcelTemplate',
          delOssBatch: '/sys/oss/file/deleteBatch',
        importClaimCustomer:'/file/claim/importClaimCustomer',
      },
      dictCodes: [
        "claim_type",
        "responsible_party",
        "bd_cur",
        "sys_user_id",
        "supplier_id",
        "bill_status",
        "claim_settle_status",
      ],
      deleteList: [],
      saveClaimTemplateList:[],
        dialogKdCountVisible:false,

    };
  },
  methods: {
    saveImportTemplate(){
      this.saveClaimTemplateList = [];
      this.form.fileList.forEach((item)=>{
        this.saveClaimTemplateList.push({
          uploadPath :item.url
        });

      });
      postAction(this.url.importClaimCustomer,this.saveClaimTemplateList)
          .then((res)=>{
            if (res.success){
              this.$message.success(res.message);
              this.form = {};
              this.form.fileList = [];
              this.importCustomerClaimVisible = false;
              this.handleQuery();
            }
          }).catch((err)=>{
        this.form = {};
        this.form.fileList = [];
        this.importCustomerClaimVisible = false;
      })
      .finally(()=>{})
      console.log(this.form,'112312312')

    },
    dataStatistics() {
      this.statisticsvisible = true;
    },
    //点击新增跳转页面
    handleAdd() {
      this.row = {};
      this.visible = true;
    },
    handleClose() {

      // console.log(this.form.fileList);
      // this.form = {  };
      // let ids = '';
      // this.form.fileList.forEach((item)=>{
      //   ids = ids + item.id+","
      // })
      // console.log(ids);
    //   deleteAction(this.url.delOssBatch, {ids:ids})
    // //  deleteAction(this.url.delOssBatch+"?ids="+ids)
    //       .then((res)=>{
    //         if (res.success){
    //             this.$message.success(res.message);
    //             this.form = {};
    //             this.form.fileList = [];
    //             this.importCustomerClaimVisible = false;
    //         }
    //       })
    //       .catch((err)=>{})
    //       .finally(()=>{})
      this.form = {};
      this.form.fileList = [];
      this.importCustomerClaimVisible = false;
      // this.form.fileList = [];
    },

    handleEdit(row) {
        getAction(this.url.getStatusById,{settleIds:row.orderSettleId})
            .then(res=>{
                let s = res.result;
                if (s !== '0'){
                    this.$message.warning('此费用已经在财务流程中，无法编辑');
                    return false;
                }else {
                    this.row = row;
                    console.log(row, "row");
                    this.visible = true;
                }
            })

    },
    multipleDelete() {
      if (this.selectionRows.length < 1) {
        this.$message.warning("请至少勾选一条数据");
        return;
      }
      this.$confirm("是否确认删除选中理赔?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        closeOnClickModal: false,
      })
        .then(() => {
          this.deleteList = [];
          this.selectionRows.forEach((select) => {
            this.deleteList.push({
              id: select.id,
              orderSettleId: select.orderSettleId,
            });
          });
          // console.log(row, "row6666");
          putAction(this.url.delete, this.deleteList).then((res) => {
            this.$message({
              type: "success",
              message: res.message,
            });
            this.handleQuery();
          });
        })

      console.log(this.selectionRows, "this.selectionRows");
    },
    handleDele(row) {
      this.$confirm("是否确认删除本次理赔", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        closeOnClickModal: false,
      })
        .then(() => {
          this.deleteList = [];
          this.deleteList.push({
            id: row.id,
            orderSettleId: row.orderSettleId,
          });
          console.log(row, "row6666");
            getAction(this.url.getStatusById,{settleIds:row.orderSettleId})
                .then(res=>{
                    let s = res.result;
                    if (s !== '0'){
                        this.$message.warning('此费用已经在财务流程中，无法删除');
                        return false;
                    }else {
                        putAction(this.url.delete, this.deleteList).then((res) => {
                            this.$message({
                                type: "success",
                                message: res.message,
                            });
                            this.handleQuery();
                        });
                    }
                })

        })

    },
    // handleSelect() {
    //   this.isSelect = false;
    //   this.tableDataSelect = [];
    //   this.chinaseName = "";
    //   this.dialogFormVisibleSelect = true;
    // },
    // sureSelect() {
    //   this.form.chinaseName = this.chinaseName;
    //   this.form.cooperationType = "";
    //   this.pageSelect();
    // },
    // 页码变化，重新获取数据
    // pageSelect() {
    //   if (this.ipaginationSelect) {
    //     this.form.pageNo = this.ipaginationSelect.currentPage;
    //     this.form.pageSize = this.ipaginationSelect.pageSize;
    //   }
    //   getAction(this.url.list, this.form).then((res) => {
    //     console.log(res);
    //     if (res.success) {
    //       if (res.result) {
    //         const { records, total } = res.result;
    //         this.tableDataSelect = records || res.result;

    //         this.ipaginationSelect &&
    //           (this.ipaginationSelect.total = total || res.result.length);
    //         this.isSelect = true;
    //       } else {
    //         this.tableDataSelect = [];
    //         this.ipaginationSelect = 0;
    //       }
    //     }
    //   });
    // },

    // handleSizeChangeSelect(val) {
    //   this.ipaginationSelect.currentPage = 1;
    //   this.ipaginationSelect.pageSize = val;
    //   this.pageSelect();
    // },
    // handleCurrentChangeSelect(val) {
    //   this.ipaginationSelect.currentPage = val;
    //   this.pageSelect();
    // },
    handleReset() {
      this.queryForm = {};
      this.ipagination.currentPage = 1;
      this.handleQuery();
    },
  },
  computed:{
    buttonShowList() {
      return {
        'add':this.$btnIsShow('claimDepartment/customer', '0', '新增'),
        'delB':this.$btnIsShow('claimDepartment/customer', '0', '删除'),
        'dc':this.$btnIsShow('claimDepartment/customer', '0', '导出'),
        'im':this.$btnIsShow('claimDepartment/customer', '0', '导入'),
        'tj':this.$btnIsShow('claimDepartment/customer', '0', '统计'),
        'edit':this.$btnIsShow('claimDepartment/customer', '1', '编辑'),
        'del':this.$btnIsShow('claimDepartment/customer', '1', '删除'),
      }
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
