<template>
    <el-dialog
            title="拆单"
            width="70%"
            :visible="visible"
            :confirmLoading="confirmLoading"
            :before-close="handleCancel"
            :close-on-click-modal="false"
    >
        <!-- <el-card class="box-card"> -->
            <h3>件数:{{order.ogCtn}} &emsp; 重量(LBS):{{order.ogKg}} &emsp;体积:{{order.ogCmb}}&emsp;过磅重:{{order.realKg}}</h3>
        <el-table
                ref="table"
                border
                rowKey="id"
                :data="volume.dataSource"
        >
            <el-table-column
                    prop="length"
                    label="长（IN）"
                    width="100">
            </el-table-column>
            <el-table-column
                    prop="width"
                    label="宽（IN）"
                    width="100">
            </el-table-column>
            <el-table-column
                    prop="height"
                    label="高（IN）"
                    width="100">
            </el-table-column>
            <el-table-column
                    prop="number"
                    label="总件数"
                    width="100">
            </el-table-column>
            <el-table-column
                    prop="volume"
                    label="实际体积"
                    width="100">
            </el-table-column>
            <el-table-column
                    prop="largePiecesRemark"
                    label="超大件"
                    width="100">
            </el-table-column>
        </el-table>
        <!-- </el-card> -->

        <!-- <el-divider></el-divider> -->
        <!-- <el-card class="box-card"> -->
            <el-tabs class="no_fixed_tab" v-model="activeName">
            <el-tab-pane label="详情信息" name="first">
                <h3>件数重:{{(order.ogCtn>0?order.ogCtn*10:0).toFixed(3)}} &emsp;
                    体积重:{{(order.ogCmb>0?order.ogCmb*167:0).toFixed(3)}}
                    &emsp; 单箱重:{{splitOrder.weight>0?splitOrder.weight:0}}(LB/CTN) &emsp;
                    推荐拆单票数：{{splitOrder.suggestNum}}({{(order.ogCtn/splitOrder.suggestNum).toFixed(3)}})</h3>
                <el-row>
                    <el-button @click="addDomain" size="small">添加子单</el-button>
                    <el-divider direction="vertical"/>
                    <el-button @click="resetForm('table1')" size="small">重置</el-button>
                    <el-divider direction="vertical" v-if="additionalList.length>0 || lareList.length>0"/>
                    <el-popover title="需要额外操作" v-if="additionalList.length>0"
                                style="margin-bottom: 10px;margin-top: 5px;background-color: #f1556c;border-color: #f1556c;"
                                trigger="hover"
                    >
                        <p>- 任何包裹长度(最长的边)超过47英寸.<br>
                            - 任何包裹宽度(第二长的边) 超过30英寸.<br>
                            - 包裹重量超过50磅.<br>
                            - 检测到以下行不满足要求
                        </p>
                        <p v-for="arr in additionalList">
                            第 {{Number(arr.substring(0,arr.indexOf(",")))+1}}
                            行数据中的{{arr.substring(arr.indexOf(",")+1,arr.length)}}</p>
                        <el-button type="danger" slot="reference" size="mini">
                            需要额外操作
                        </el-button>
                    </el-popover>
                    <el-divider direction="vertical" v-if="additionalList.length>0 && lareList.length>0"/>
                    <el-popover title="超尺寸" v-if="lareList.length>0"
                                style="margin-bottom: 10px;margin-top: 5px;background-color: #f1556c;border-color: #f1556c;"
                                trigger="hover"
                    >
                        <p>
                            – 针对本地发货, 当 长(包裹最长的边) + 周长[ (2x宽) + (2 x 高) ]加起来超过103英寸或者 最长边 超过48英寸, 包裹会被判定为“超大”.<br>
                            – 针对国际发货, 当 长(包裹最长的边) + 周长[ (2x宽) + (2 x 高) ]加起来超过130英寸, 包裹会被判定为“超大.<br>
                            - 检测到以下行不满足要求
                        </p>
                        <p v-for="arr in lareList">
                            第 {{Number(arr.substring(0,arr.indexOf(",")))+1}}
                            行数据中的{{arr.substring(arr.indexOf(",")+1,arr.length)}} </p>
                        <el-button type="danger" slot="reference" size="mini">
                            超尺寸
                        </el-button>
                    </el-popover>

                </el-row>
                <el-form :model="table1" status-icon ref="table1" :rules="rules" :orderNo=this.model.orderNo>


                    <el-row :gutter="5">
                        <el-col :span="1">
                            <h4 v-html=""
                                style="width: 100%;height: 5px;display: block;line-height: 5px;text-align: center;"></h4>
                        </el-col>

                        <el-col :span="2">
                            <el-form-item :label="'数量（件）'" :required=true>
                            </el-form-item>
                        </el-col>
                        <el-col :span="2">
                            <el-form-item :label="'Freight Class'">
                            </el-form-item>
                        </el-col>
                        <el-col :span="2">
                            <el-form-item :label="'长(IN)'" :required=true>
                            </el-form-item>
                        </el-col>
                        <el-col :span="2">
                            <el-form-item :label="'宽(IN)'" :required=true>
                            </el-form-item>
                        </el-col>
                        <el-col :span="2">
                            <el-form-item :label="'高(IN)'" :required=true>
                            </el-form-item>
                        </el-col>
                        <el-col :span="2">
                            <el-form-item :label="'重量(LB)'" :required=true>
                            </el-form-item>
                        </el-col>
                        <el-col :span="2">
                            <el-form-item :label="'参考号1'">
                            </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item :label="'参考号2'" :required=true>
                            </el-form-item>
                        </el-col>
                        <el-col :span="2">
                            <el-form-item :label="'SKU'">
                            </el-form-item>
                        </el-col>


                    </el-row>

                    <el-row :gutter="5"
                            v-for="(packageList, index) in table1.packageList"
                            :label="'表单项' + (index+1)"
                            :key="packageList.key"
                            :prop="'packageList.' + index + '.value'"
                    >
                        <el-col :span="1">
                            <h4 v-html="(index+1)"
                                style="width: 100%;height: 5px;display: block;line-height: 5px;text-align: center;"></h4>
                        </el-col>

                        <el-col :span="2">
                            <el-form-item :prop="'packageList.'+index+'.parcelNum'" :rules="[{
                        pattern: /^[1-9]\d*$/,
                        message: '只能输入整数',
                        trigger: 'blur'
                    },
                    {required: true, message: '货物数量不能为空', trigger: 'blur'},
                    {validator:checkNum,trigger:'blur'},{validator:checkParcelNum,trigger:'blur'}]">
                                <el-input v-model="packageList.parcelNum"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="2">
                            <el-form-item >
                                <el-select v-model="packageList.freight">
                                    <el-option v-for="item in freightClassOptions"
                                               :key="item.value"
                                               :label="item.label"
                                               :value="item.value">

                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="2">
                            <el-form-item :prop="'packageList.'+index+'.longParameter'" :rules="[{
                        pattern: /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,3})))$/,
                        message: '整数或三位小数',
                        trigger: 'blur'
                    },{required: true, message: '货物长不能为空', trigger: 'blur'},{validator:checkSide,trigger:'change'}]">
                                <el-input v-model="packageList.longParameter"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="2">
                            <el-form-item :prop="'packageList.'+index+'.width'" :rules="[{
                        pattern: /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,3})))$/,
                        message: '整数或三位小数',
                        trigger: 'blur'
                    },{required: true, message: '货物长不能为空', trigger: 'blur'},{validator:checkSide,trigger:'change'}]">
                                <el-input v-model="packageList.width"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="2">
                            <el-form-item :prop="'packageList.'+index+'.height'" :rules="[{
                        pattern: /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,3})))$/,
                        message: '整数或三位小数',
                        trigger: 'blur'
                    },{required: true, message: '货物高不能为空', trigger: 'blur'},{validator:checkSide,trigger:'change'}]">
                                <el-input v-model="packageList.height"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="2">
                            <el-form-item :prop="'packageList.'+index+'.weight'" :rules="[{
                        pattern: /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,3})))$/,
                        message: '整数或三位小数',
                        trigger: 'blur'
                    },{required: true, message: '货物重量不能为空', trigger: 'blur'},{validator:checkParcelNum,trigger:'change'},{validator:checkSide,trigger:'change'}]">
                                <el-input v-model="packageList.weight"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="2">
                            <el-form-item>
                                <el-input v-model="packageList.referenceNo1"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item :prop="'packageList.'+index+'.referenceNo2'" :rules="[
                    {required: true, message: '不能为空', trigger: 'change'}]">
                                <el-input v-model.trim="packageList.referenceNo2"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="2">
                            <el-form-item>
                                <el-input v-model.trim="packageList.sku"></el-input>
                            </el-form-item>
                        </el-col>
<!--                        <el-col :span="2">-->
<!--                            <el-form-item :label="'产品描述'">-->
<!--                                <el-input v-model.trim="packageList.description"></el-input>-->
<!--                            </el-form-item>-->
<!--                        </el-col>-->

                        <a class="remove-item" v-show="table1.packageList.length>1"
                           @click.prevent="removeDomain(packageList)">
                            <el-button class="el-icon-delete" size="small" style="margin-top: 4px">删除</el-button>
                        </a>

                    </el-row>

                </el-form>

                <h3 style="text-align:right;">合计总件数:{{totalNum.piece}} &emsp; 合计总重量:{{totalNum.weight}} </h3>

            </el-tab-pane>
        </el-tabs>
        <!-- </el-card> -->

        <template slot="footer">
            <el-button @click="handleCancel" size="medium" style="margin-left: 15px;">取 消</el-button>
            <el-button type="primary" size="medium" @click="handleOk">确 定</el-button>
        </template>

    </el-dialog>

</template>

<script>
    import {deleteAction, getAction, getallusers, postAction, putAction, httpAction} from '@/api/manage'
    import {addressEnum, checkAddress} from "../api/Ups";
    import pick from 'lodash/pick'
    import {FormTypes, VALIDATE_NO_PASSED, getRefPromise, validateFormAndTables} from "../api/JEditableTableUtil";
    import moment from 'moment'
    import { setUpRouteUtil } from '@/utils/util';


    export default {
        name: "labelSplit",
        components: {},
        data() {
            return {
                freightClassOptions: [ // 下拉选项
                    {title: '70', value: '70'},
                    {title: '77.5', value: '77.5'},
                    {title: '85', value: '85'},
                    {title: '92.5', value: '92.5'},
                    {title: '100', value: '110'},
                    {title: '150', value: '150'},
                    {title: '125', value: '125'},
                    {title: '175', value: '175'},
                    {title: '200', value: '200'},
                    {title: '250', value: '250'},
                    {title: '300', value: '300'},
                    {title: '400', value: '400'},
                    {title: '500', value: '500'}
                ],

                dialogWidth: 1800,
                activeName: 'first',
                form: {},
                order: {},
                confirmLoading: false,
                visible: false,
                url: {
                    add: '/interface/label/saveUpsDemolitionOrder',
                    edit: '/test/jeecgOrderMain/edit',
                    orderCustomerList: '/test/jeecgOrderMain/queryOrderCustomerListByMainId',
                    orderTicketList: '/test/jeecgOrderMain/queryOrderTicketListByMainId',
                    UpsDemolitionOrderList: "/interface/label/UpsDemolitionOrderList",
                    selectVolumeList: "/interface/label/selectVolumeList"
                },
                volume: {
                    loading: false,
                    dataSource: [],

                },
                additionalList: new Array(),
                lareList: new Array(),
                totalNum: {
                    piece: 0,
                    weight: 0
                },
                model: {},
                splitOrder: {},
                table1: {
                    loading: false,
                    dataSource: [],
                    packageList: [{
                        packageType: '02',
                        parcelNum: '',
                        freight: '70',
                        longParameter: '',
                        width: '',
                        height: '',
                        weight: '',
                        referenceNo1: 'AS8',
                        referenceNo2: '',
                        sku: '',
                        // description: '',
                    }],
                },
                orderNo: null,
                rowsNum: null,
                rules: {
                    shipperSelect: [{required: true, message: '发货账号不能为空', trigger: 'blur'}],
                    companySelect: [{required: true, message: '发货企业不能为空', trigger: 'blur'}],
                    countryCode: [{required: true, message: '国家不能为空', trigger: 'blur'}],
                    state: [{required: true, message: '州/县不能为空', trigger: 'blur'}],
                    city: [{required: true, message: '城市不能为空', trigger: 'blur'}],
                    phone: [{required: true, message: '电话不能为空', trigger: 'blur'}],
                    zip: [{required: true, message: '邮编不能为空', trigger: 'blur'}],
                    addressLine1: [{required: true, message: '地址1不能为空', trigger: 'blur'}],
                    [`packageList.longParameter`]: [{
                        pattern: /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/,
                        message: '整数或两位小数',
                        trigger: 'blur'
                    }],
                    [`packageList.width`]: [{
                        pattern: /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/,
                        message: '整数或两位小数',
                        trigger: 'blur'
                    }],
                    [`packageList.height`]: [{
                        pattern: /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/,
                        message: '整数或两位小数',
                        trigger: 'blur'
                    }],
                    [`packageList.referenceNumber2`]: [{
                        required: true,
                        message: '不能为空',
                        trigger: 'blur'
                    }],
                },
            }
        },
        created() {

        },
        mounted(){

        },
        methods: {
            intoDocpage(orderNo) {
                // 对于路由不在左侧菜单中的选中菜单处理
                setUpRouteUtil(this.$route.path, '/docpage/Edit');
                this.$router.push("/docpage/Edit?orderNo=" + orderNo);

            },
            /*重置表单*/
            resetForm(formName) {
                this.$refs[formName].resetFields();
                this.table1.packageList[0].referenceNo2 = this.model.orderNo + "-1"
            },
            /*增加表单项*/
            addDomain() {
                // if(this.rowsNum ==='1'){
                //     this.$message.error("该单号重量小于400或者件数少于500，只能拆成1单")
                //     return
                // }
              //console.log(this.model)
                let no = this.model.orderNo + "-"+(this.table1.packageList.length+1)
                let operateName =this.model.operateName
                let weight=0.0
                if (this.splitOrder.weight>0 && this.splitOrder.weight>50){
                     weight = this.splitOrder.weight =50
                }else {
                     weight =this.splitOrder.weight
                }
                this.table1.packageList.push({
                    packageType: '02',
                    parcelNum: '',
                    freight: '70',
                    longParameter: '',
                    width: '',
                    height: '',
                    weight: weight,
                    referenceNo1: 'AS8',
                    referenceNo2: no,
                    sku: operateName,
                    // description: '',
                });
            },
            /*删除表单项*/
            removeDomain(item) {
                var index = this.table1.packageList.indexOf(item)
                if (index !== -1) {
                    this.table1.packageList.splice(index, 1)
                }
                let piece = 0
                let weight = 0
                for (var i = 0; i < this.table1.packageList.length; i++) {
                    this.table1.packageList[i].referenceNo2 = this.model.orderNo + "-" + (i+1)
                    piece = piece + Number(this.table1.packageList[i].parcelNum===''?0:this.table1.packageList[i].parcelNum)
                    weight = weight + Number(this.table1.packageList[i].weight===''?0:this.table1.packageList[i].weight)
                }
                this.totalNum.piece = piece
                this.totalNum.weight = weight
            },
            checkNum(rule, num, callback) {
                if (num > 40) {
                    return callback(new Error('件数不可超过40件'))
                }
                return callback();

            },
            checkParcelNum(rule, num, callback) {
                let piece = 0
                let weight = 0
                for (var i = 0; i < this.table1.packageList.length; i++) {
                    piece = piece + Number(this.table1.packageList[i].parcelNum===''?0:this.table1.packageList[i].parcelNum)
                    weight = weight + Number(this.table1.packageList[i].weight===''?0:this.table1.packageList[i].weight)
                    // if (Number(this.table1.packageList[i].weight===''?0:this.table1.packageList[i].weight) > 40){
                    //     this.additionalList.push(i + "," + Number(this.table1.packageList[i].weight===''?0:this.table1.packageList[i].weight))
                    // }
                }
                this.totalNum.piece = piece
                this.totalNum.weight = weight
                return callback();
            },
            checkParcelNumAfterRender() {
                let piece = 0
                let weight = 0
                for (var i = 0; i < this.table1.packageList.length; i++) {
                    piece = Number(piece) + Number(this.table1.packageList[i].parcelNum===''?0:this.table1.packageList[i].parcelNum)
                    weight = (Number(weight) + Number(this.table1.packageList[i].weight===''?0:this.table1.packageList[i].weight)).toFixed(3)
                }
                this.totalNum.piece = piece
                this.totalNum.weight = weight

            },
            //检测边长方法
            checkSide(rule, num, callback) {
                this.additionalList = []
                this.lareList = []
                for (var i = 0; i < this.table1.packageList.length; i++) {
                    let p = this.table1.packageList[i];
                    let w = p.weight;
                    if (w >= 50){
                        this.additionalList.push(i + "," + w)
                    }
                    let arr = [p.longParameter, p.width, p.height];
                    if (arr[0] !== '' && arr[1] !== '' && arr[2] !== '') {
                        //js原本的sort有bug 改为以下
                        arr.sort(function (x, y) {
                            if (Number(x) < Number(y) ){
                                return -1;
                            }
                            if (Number(x) > Number(y) ) {
                                return 1;
                            }
                            return 0;
                        });

                        let one = arr[2];//最大边
                        let two = arr[1];
                        let three = arr[0];

                        let sum = Number(one) + Number(two) * 2 + Number(three) * 2

                        if (Number(one) > 47) {
                            this.additionalList.push(i + "," + one)
                        }
                        if (Number(two) > 30) {
                            this.additionalList.push(i + "," + two)
                        }
                        if (Number(three) > 30) {
                            this.additionalList.push(i + "," + three)
                        }
                        if (sum > 103) {
                            this.lareList.push(i + ",总边长=" + sum)
                        }

                    }

                }
                return callback();
            },
            childSetSize(type, num, title) {
                if (type == 'del') {
                    for (var i = 0; i < this.additionalList.length; i++) {
                        if (this.additionalList[i] == (num + "," + title)) {
                            this.additionalList.splice(i, 1);
                        }
                    }
                    for (var i = 0; i < this.lareList.length; i++) {
                        if (this.lareList[i] == num) {
                            this.lareList.splice(i, 1);
                        }
                    }
                } else if (type == 'Additional') {
                    this.additionalList.push((num + "," + title))

                } else if (type == 'Lare') {
                    this.lareList.push((num + "," + title))
                }

            },
            childSetValue(weight, parcelNum, type) {

                if (type == 'add') {
                    this.totalNum.piece = Number(this.totalNum.piece) + Number(parcelNum)
                    this.totalNum.weight = Number(this.totalNum.weight) + Number(weight)
                } else if (type == 'sum') {
                    this.totalNum.piece = parcelNum
                    this.totalNum.weight = weight
                } else {

                }

            },
            edit(record) {

                this.splitOrder.ctn = record.ctn;
                this.splitOrder.lb = record.lb;
                this.splitOrder.weight = Math.ceil(Number(record.lb) / Number(record.ctn))
                console.log(record.lb)
                console.log(record.ctn)
                console.log(Number(record.lb) / Number(record.ctn))
                //
                // // let LBNum =  Math.ceil(Number(record.lb)/850) 根据吉利要求，将限重改为400
                let LBNum = Math.ceil(Number(record.lb) / 400)   //单票限重400LB
                let CTNNum = Math.ceil(Number(record.ctn) / 50)  //单票限数50箱
                //
                this.splitOrder.suggestNum = LBNum > CTNNum ? LBNum : CTNNum
                // this.splitOrder.suss
                //
                //
                this.visible = true
                //
                this.activeKey = '1'
                this.form = {}
                this.model = Object.assign({}, record)

                this.$nextTick(() => {
                    // this.form.setFieldsValue(pick(this.model, 'orderCode', 'ctype', 'orderMoney', 'content'))
                    this.$refs['table1'].resetFields()
                    this.form.orderCode = this.model.orderCode;
                    this.form.ctype = this.model.ctype;
                    this.form.orderMoney = this.model.orderMoney;
                    this.form.content = this.model.content;
                    //时间格式化
                    // this.form.setFieldsValue({ orderDate: this.model.orderDate ? moment(this.model.orderDate) : null })
                    this.form.orderDate = moment(this.model.orderDate);
                })

                postAction(this.url.selectVolumeList, {'orderNo': record.orderNo}).then(res => {
                    // that.table1.dataSource = res.result.list
                    if (res.success) {
                        this.volume.dataSource = res.list
                        this.order = res.OsOrder;
                        console.log('ogkg_before',this.order.ogKg)
                        this.order.ogKg = (Number(this.order.ogKg) * Number(2.2046)).toFixed(3)
                        console.log('ogkg_after',this.order.ogKg)
                    }

                }).finally(() => {
                    // tab.loading = false
                    //当不满足下面两个条件时，只能新增一行
                    if (!(record.ctn > 500 || record.lb > 400) && this.order.ogCtn < 50) {
                        this.rowsNum = "1";
                    } else {
                        this.rowsNum = null;
                    }

                })

                // 加载子表数据
                if (record.orderNo) {
                    let params = {orderNo: record.orderNo}
                    this.requestTableData(this.url.UpsDemolitionOrderList, params, this.table1)
                }

            },
            /** 查询某个tab的数据 */
            requestTableData(url, params, tab) {
                let that = this;
                tab.loading = true
                postAction(url, params).then(res => {
                    // that.table1.dataSource = res.result.list
                    if (res.result.list.length>0){
                        that.table1.packageList=res.result.list
                    }else{
                      let operateName =this.model.operateName
                      let weight=0.0
                      if (this.splitOrder.weight>0 && this.splitOrder.weight>50){
                        weight = this.splitOrder.weight =50
                      }else {
                        weight =this.splitOrder.weight
                      }
                        that.table1.packageList= [{
                            packageType: '02',
                            parcelNum: '',
                            freight: '70',
                            longParameter: '',
                            width: '',
                            height: '',
                            weight: weight,
                            referenceNo1: 'AS8',
                            referenceNo2: '',
                            sku: operateName,
                            // description: '',
                        }];
                        that.table1.packageList[0].referenceNo2 = this.model.orderNo + "-1"
                    }

                }).finally(() => {
                    tab.loading = false
                    this.checkParcelNumAfterRender()
                })
            },
            close() {
                this.resetValue()

                this.visible = false
                this.$emit('close')
            },
            handleOk() {
                console.log(this.totalNum.weight)
                console.log(this.order.ogKg)
                if (this.totalNum.piece != this.order.ogCtn){
                    this.$message.warning('订单件数与拆单合计件数不一致！')
                    return
                }
             /*   if (this.totalNum.weight != this.order.ogKg){
                    this.$message.warning('订单重量与拆单合计重量不一致！')
                    return
                }*/
                this.resetValue()
                this.validateFields()
            },
            handleCancel() {
                this.resetValue()
                this.close()
            },
            resetValue() {
                this.totalNum.piece = 0
                this.totalNum.weight = 0
                this.additionalList = new Array()
                this.lareList = new Array()
            },
            /** 触发表单验证 */
            validateFields() {
                this.$refs.table1.validate((err, values) => {
                    if (err){
                        const u = {
                            username:this.model.username,
                            orderNo:this.model.orderNo,
                            transportStatus:this.model.transportStatus,
                            solicitation:this.model.solicitation,
                            ctn:this.model.ctn,
                            country:this.model.ctn,
                            state:this.model.ctn,
                            city:this.model.ctn,
                            address:this.model.ctn,
                            zip:this.model.ctn,
                            lb:this.model.lb,
                            fbacode:this.model.fbacode,
                            phone:this.model.phone,
                            orderNoList:this.table1.packageList

                        }
                        this.requestAddOrEdit(u)
                    }
                })

                // validateFormAndTables(this.form, this.table1.packageList)
                // .then(allValues => {
                //
                //     //  console.log(allValues)
                //     let formData = this.classifyIntoFormData(allValues)
                //     // 发起请求
                //     console.log(formData)
                //     return this.requestAddOrEdit(formData)
                // }).catch(e => {
                //     if (e.error === VALIDATE_NO_PASSED) {
                //         // 如果有未通过表单验证的子表，就自动跳转到它所在的tab
                //         this.activeKey = e.index == null ? this.activeKey : (e.index + 1).toString()
                //     } else {
                //         console.error(e)
                //     }
                // })
            },
            /** 整理成formData */
            classifyIntoFormData(allValues) {
                let orderMain = Object.assign(this.model, allValues.formValue)
                return {
                    ...orderMain, // 展开
                    orderNoList: allValues.tablesValue[0].values
                }
            },
            /** 发起新增或修改的请求 */
            requestAddOrEdit(formData) {
                console.log(formData)
                this.$emit('ok',formData)
                let url = this.url.add, method = 'post'
                // if (this.model.id) {
                //   url = this.url.edit
                //   method = 'put'
                // }
                this.confirmLoading = true

                postAction(url, formData).then((res) => {

                    if (res.success) {
                        this.$message.success(res.message)
                        this.$emit('ok',formData)
                        this.close()
                    }
                }).finally(() => {
                    this.confirmLoading = false
                })
            }

        }
    }
</script>

<style scoped>
    .ant-btn {
        padding: 0 10px;
        margin-left: 3px;
    }

    .ant-form-item-control {
        line-height: 0px;
    }

    /** 主表单行间距 */
    .ant-form .ant-form-item {
        margin-bottom: 10px;
    }

    /** Tab页面行间距 */
    .ant-tabs-content .ant-form-item {
        margin-bottom: 0px;
    }
</style>
