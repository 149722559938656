<template>
    <el-dialog
        :title="row.id ? '编辑字典' : '新增字典'"
        :visible="visible"
        appen-to-body
        width="560px"
        custom-class="c-custom-dialog"
        :close-on-click-modal="false"
        :before-close="handleClose">
        <el-form :model="form" :rules="rules" ref="form" label-width="80px" size="medium">
            <el-row>
              <el-col :span="24">
                <el-form-item prop="ruleType" label="类型">
                  <f-select v-model="form.ruleType" :is-need="form.ruleType" :dict="'sale_config_dict_rule_type'"></f-select>
                </el-form-item>
              </el-col>
                <el-col :span="24">
                    <el-form-item prop="tableName" label="表名">
                        <el-input v-model="form.tableName" maxlength="20" show-word-limit></el-input>
                    </el-form-item>
                </el-col>
              <el-col :span="24">
                <el-form-item prop="tableOtherName" label="表别名">
                  <el-input v-model="form.tableOtherName" maxlength="20" show-word-limit></el-input>
                </el-form-item>
              </el-col>
                <el-col :span="24">
                    <el-form-item prop="fieldId" label="编码">
                      <el-input v-model="form.fieldId" maxlength="20" show-word-limit></el-input>
                    </el-form-item>
                </el-col>
              <el-col :span="24">
                <el-form-item prop="fieldName" label="名称">
                  <el-input v-model="form.fieldName" maxlength="20" show-word-limit></el-input>
                </el-form-item>
              </el-col>

            </el-row>
        </el-form>
        <span slot="footer">
            <el-button size="medium" @click="handleClose">取消</el-button>
            <el-button type="primary" size="medium" @click="handleSubmit">确定</el-button>
        </span>
    </el-dialog>
</template>

<script>
import { httpAction } from '@/api/manage'
export default {
    name: 'acDialog',
    props: {
        visible: {
            type: Boolean
        },
        row: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            form: {},
            rules: {
                tableName: [
                    { required: true, message: '请输入表名', trigger: 'change' },
                ],
              tableOtherName: [
                { required: true, message: '请输入表别名', trigger: 'change' },
              ],
              fieldId: [
                { required: true, message: '请输入编码', trigger: 'change' },
              ],
              fieldName: [
                { required: true, message: '请输入名称', trigger: 'change' },
              ],
              ruleType: [
                { required: true, message: '请选择类型', trigger: 'change' },
              ],

            },
            url: {
                add: '/finance/fieldDict/save',
                edit: '/finance/fieldDict/update'
            }
        }
    },
    watch: {
        visible(val) {
            if(val) {
               this.row.id && (this.form = { ...this.row })
            }
        }
    },
    methods: {
        handleSubmit() {
            let url = this.url.add
            let method = 'POST'
            if(this.row.id) {
                url = this.url.edit
            }
            this.$refs.form.validate((valid) => {
                if (valid) {
                    httpAction(url, method, this.form).then((res)=>{
                        this.$message({
                            type: 'success',
                            message: res.message
                        })
                        this.handleClose()
                        this.$emit('ok')
                    })
                }
            })
        },
        handleClose() {
            this.$emit('update:visible', false)
            this.form = {}
            this.$refs.form.resetFields()
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
