<template>

    <div>
      <el-row :gutter="20" style="height: 100%;">
        <el-col :span="11" style="height: 100%;"><image-detail  ref="imageFolderModel" style="height: 100%;overflow: scroll;"></image-detail></el-col>
        <el-col :span="13" style="height: 100%;"><volume ref="volModel" @func="getMsg" @getImageMessage="getImageMessage" v-on="$listeners" style="height: 100%;overflow: scroll;"></volume></el-col>
      </el-row>
    </div>
</template>

<script>

  // import '@/assets/less/TableExpand.less'

  import splitPane from 'vue-splitpane'
  import volume from "./modules/volume";
  import imageDetail from "./modules/ImageDetail";

  import { getAction,httpAction } from '@/api/manage'



  export default {
    name: "ReceiveList",
    components: {
      splitPane,volume,imageDetail
    },
    data () {
      return {
          fromSon:'',
        id:'',
        bodyStyle:{
          padding: "0",
          height:(window.innerHeight-150)+"px"
        },
        modalWidth:800,
        description: '',

        url: {


        },
        dictOptions:{},
      }
    },
    computed: {
      importExcelUrl: function(){
        // return `${window._CONFIG['domianURL']}/${this.url.importExcelUrl}`;
      },


    },
    methods: {
        getMsg(obj){

            this.$emit('listen',obj)
        },
      initDictConfig(){
      },
      getOrderMain(id,record) {
        this.visible = true;
        this.id=id
          this.$refs.volModel.volumeDetail(id,record);
          this.$refs.imageFolderModel.warehouseImage(id);

      },
      getImageMessage(obj){
        this.$refs.imageFolderModel.warehouseImage(obj);
      }
    }
  }
</script>
<style >
</style>
