<template>
    <div class="main-full-content">
        <el-form class="input_top" ref="form" :model="queryForm" label-width="80px" size="medium">
            <el-card class="box-card">
                <el-row>
                    水单操作日志记录
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label-width="30px">
                            <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>

        </el-form>
        <el-card class="box-card last_card">
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="58vh">
                <template v-slot="scope">
                    <template v-if="scope.column.property ==='name'">
                        <a @click.prevent=downloadFile(scope.row.url)>{{scope.row.name}}</a>
                    </template>
                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
            </el-row>
        </el-card>

    </div>
</template>

<script>
    import QueryMixins from "@/mixins/QueryMixins.js"
    export default {
        name: 'SDLogModule',
        mixins: [QueryMixins],
        components: {},
        props:{
            orderNo:{
                type:'String',
                default : ''
            }
        },
        data() {
            return {
                columns: [
                    {
                        prop: 'createBy',
                        label: '操作人',

                    },
                    {
                        prop: 'name',
                        label: '文件名',

                    },
                    {
                        prop: 'createTime',
                        label: '上传时间',

                    },

                ],
                url: {
                    list: '/order/os/file/getSDlist',
                },



            }
        },
        methods: {
            downloadFile(url){
                if (!url) {
                    this.$message.warning('文件不存在');
                    return false;
                }else {
                    document.location.href = url;
                }

            },
        },
        created() {
            this.queryForm.ordeNo = this.orderNo
        }
    }
</script>

<style lang="scss" scoped>

</style>
