<template>
    <el-card class="box-card">
        <div style="margin-bottom: 20px">
            <span>
                拖车【{{orderNo}}】
            </span>
        </div>
        <el-row>
            <el-form ref="form" :model="form" label-width="120px" :rules="rules">
                <el-col :span="24">
                    <el-col :span="4">
                        <el-form-item label="截关日期" >
                            <el-input  v-model="declEndTime" disabled></el-input>
                        </el-form-item>

                    </el-col>
                    <el-col :span="4">
                        <el-form-item label="订舱单号" >
                            <el-input  v-model="bookNo" disabled></el-input>
                        </el-form-item>

                    </el-col>
                    <el-col :span="4">
                        <el-form-item label="订单号" >
                            <el-input  v-model="orderNo" disabled></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" style="margin-left: 100px">
                        <el-button icon="el-icon-plus" type="primary" size="small" @click="addTuo">新增拖车</el-button>
                        <el-button icon="el-icon-document-checked" type="primary" size="small" @click="saveAll">保存所有</el-button>
                    </el-col>

                </el-col>
<!--                遍历-->
                <el-card v-for="(item,index) in form.lalaList" :key="index">
<!--                    第一行数据-->
                    <el-col :span="24">
                        <el-col :span="4">
                            <el-form-item label="拖车联系人">
                                <el-input v-model="item.contact" maxlength="50" show-word-limit></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="9">
                            <el-form-item label="拖车地址">
                                <el-input type="textarea" v-model="item.contactAdd" maxlength="50" show-word-limit></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item label="拖车电话">
                                <el-input v-model="item.contactTel" maxlength="50" show-word-limit></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item label="件数">
                                <el-input v-model="item.ctn" type="number"
                                          onkeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"
                                ></el-input>
                            </el-form-item>
                        </el-col>
                    </el-col>
<!--                    第二行数据-->
                    <el-col :span="24">
                        <el-col :span="6">
                            <el-form-item label="取件时间">
                                <div class="f-flex">
                                    <el-date-picker
                                            class="f-flex-1"
                                            type="datetime"
                                            size="small"
                                            placeholder="开始时间"
                                            default-time="00:00:00"
                                            value-format="yyyy-MM-dd HH:mm:ss"
                                            v-model="item.getsTime"
                                    >
                                    </el-date-picker>
                                    <span class="f-p-h-2">~</span>
                                    <el-date-picker
                                            class="f-flex-1"
                                            type="datetime"
                                            size="small"
                                            placeholder="结束时间"
                                            default-time="23:59:59"
                                            value-format="yyyy-MM-dd HH:mm:ss"
                                            v-model="item.geteTime"
                                    >
                                    </el-date-picker>
                                </div>
                            </el-form-item>
                        </el-col>
                        <el-col :span="9">
                            <el-form-item label="备注">
                                <el-input type="textarea" v-model="item.remark" maxlength="50" show-word-limit></el-input>
                            </el-form-item>
                        </el-col>
                    </el-col>
<!--                    第三行数据-->
                    <el-col :span="24">
                        <el-col :span="4">
                            <el-form-item label="拖车公司">
                                <el-select v-model="item.trailerAgent" filterable clearable   @change="changeTwoAgent(item.trailerAgent,index)" @click.native="initTwoagent">
                                    <el-option v-for="(i,k) in twoAgentList" :key="k" :value="i.chinaseName" :label="i.chinaseName"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item label="车行联系人">
                                <el-input disabled v-model="item.trailerContact" maxlength="50" show-word-limit></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item label="车行电话">
                                <el-input disabled v-model="item.trailerTel" maxlength="50" show-word-limit></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item label="车型">
                                <f-select :dict="'trailerType'"
                                          :isNeed="item.trailerType"
                                          v-model="item.trailerType"></f-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item label="派车日期">
                                <el-date-picker
                                        class="f-flex-1"
                                        type="datetime"
                                        size="small"
                                        placeholder="开始时间"
                                        default-time="00:00:00"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                        v-model="item.sendTime"
                                >
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                    </el-col>
<!--                    第四行数据-->
                    <el-col :span="24">
                        <el-col :span="4">
                            <el-form-item label="是否需要司机本">
                                <el-select v-model="item.driverLicense" clearable filterable>
                                    <el-option v-for="(item,index) in isDefaulList" :key="index" :value="item.value" :label="item.label"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item label="带回报关资料">
                                <el-select clearable filterable v-model="item.getDeclareFile">
                                    <el-option v-for="(item,index) in isDefaulList" :key="index" :value="item.value" :label="item.label"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="特约事项">
                                <el-input type="textarea" v-model="item.specialRemark" maxlength="250" show-word-limit></el-input>
                            </el-form-item>
                        </el-col>
                    </el-col>
<!--                    第五行数据-->
                    <el-col :span="24">
                        <el-col :span="4">
                            <el-form-item label="司机">
                                <el-input v-model="item.driverName" maxlength="20" show-word-limit></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item label="司机电话">
                                <el-input v-model="item.driverTel" maxlength="20" show-word-limit></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item label="车牌号">
                                <el-input v-model="item.trailerNo" maxlength="50" show-word-limit></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item label="重量">
                                <el-input v-model="item.kg" type="number"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item label="体积">
                                <el-input v-model="item.cmb" type="number"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-col>
                    <el-col>
<!--                        删除保存按钮-->
                        <el-button icon="el-icon-document-delete" size="small" type="primary" @click="delTuo(item.id,index)">删除</el-button>
                        <el-button icon="el-icon-document-checked" size="small" type="primary" @click="onSubmit(index)">保存此条</el-button>
                    </el-col>
                </el-card>

        </el-form>
        </el-row>
    </el-card>
</template>

<script>
    import {getAction,postAction,deleteAction} from "@/api/manage";

    export default {
        name: "LalaModule",


        data() {
            return{
                //拖车单集合

                form:{
                    lalaList:[
                        {
                            //拖车联系人:
                            orderNo:'',
                            type:'0',
                            ctn:'',
                            realCtn:'',
                            kg:'',
                            cmb:'',
                            container_num:'',
                            contact:'',
                            contactAdd:'',
                            contactTel:'',
                            getsTime:'',
                            geteTime:'',
                            trailerAgentId:'',
                            trailerAgent:'',
                            trailerContact:'',
                            trailerTel:'',
                            trailerType:'',
                            sendTime:'',
                            driverLicense:'',
                            getDeclareFile:'',
                            trailerNo:'',
                            remark:'',
                            driverName:'',
                            driverTel:'',
                            specialRemark:'',
                        }
                    ],
                },
                declEndTime:'',
                bookNo:'',
                orderNo:this.$route.query.orderNo,
                isDefaulList:[
                    {value:'0',label:'否'},
                    {value:'1',label:'是'},
                ],
                //校验规则：
                rules:{

                },
                //拖车公司集合
                twoAgentList:[],
                twoAgentMap:{},
                url:{
                    getLalaList:'/order/os/lala/getList',
                    addOrUpdate:'/order/os/lala/addOrUpdate',
                    addBatch:'/order/os/lala/addBatch',
                    deleteById:'/order/os/lala/deleteById',
                    //获取托车行集合
                    getTuocheList:'/order/os/lala/getTuocheList',
                },
            }
        },
        methods:

            {
                listToMap(list, name) {
                    var map = {};
                    for (var index in list) {
                        map[list[index][name]] = list[index];
                    }
                    return map;
                },
                initTwoagent(){
                    getAction(this.url.getTuocheList).then(res=>{
                        this.twoAgentList = res.result;
                        this.twoAgentMap = this.listToMap(res.result,'chinaseName')
                    })
                },

                //两个参数 一个是下拉框集合的下标，一个是整个表单集合的下标
                changeTwoAgent(i,index){
                    if (this.twoAgentMap[i]){
                        let obj = this.twoAgentMap[i];
                        this.$set(this.form.lalaList[index],'trailerContact',obj.contact);
                        this.$set(this.form.lalaList[index],'trailerTel',obj.tel);
                    }
                },
                addTuo(){
                    this.form.lalaList.push(
                        {
                            orderNo:this.orderNo,
                            type:'0',
                            ctn:'',
                            realCtn:'',
                            kg:'',
                            cmb:'',
                            container_num:'',
                            contact:'',
                            contactAdd:'',
                            contactTel:'',
                            getsTime:'',
                            geteTime:'',
                            trailerAgentId:'',
                            trailerAgent:'',
                            trailerContact:'',
                            trailerTel:'',
                            trailerType:'',
                            sendTime:'',
                            driverLicense:'',
                            getDeclareFile:'',
                            trailerNo:'',
                            remark:'',
                            driverName:'',
                            driverTel:'',
                            specialRemark:'',
                        }
                    )
                },
                delTuo(id,index){
                    if (id){
                        //调用接口删除
                        deleteAction(this.url.deleteById,{id:id})
                        .then(res=>{
                            this.form.lalaList.splice(index,1);
                        })
                    }else {
                        this.form.lalaList.splice(index,1);
                    }

                },
                onSubmit(index){
                    let a = this.form.lalaList[index];
                    if (!a.trailerAgent) {
                        this.$message.warning("请选择正确的拖车的公司");
                        return false;
                    }
                    if (a.kg && isNaN(a.kg)) {
                        this.$message.warning("重量请输入数字");
                        return false;
                    }
                    if (a.cmb && isNaN(a.cmb)) {
                        this.$message.warning("体积请输入数字");
                        return false;
                    }
                    postAction(this.url.addOrUpdate,this.form.lalaList[index])
                    .then(res=>{
                        this.$message.success(res.message);
                        this.initLala();
                    })
                },
                initLala(){
                    getAction(this.url.getLalaList,{orderNo:this.orderNo}).then(res=>{
                        this.form.lalaList = res.result.list;
                        this.declEndTime = res.result.osOrder.declEndTime;
                        this.bookNo = res.result.osOrder.bookNo;
                    })
                },
                saveAll(){
                   let list = this.form.lalaList;
                    for (let i = 0; i < list.length; i++) {
                        let a = list[i];
                        if (!a.trailerAgent) {
                            this.$message.warning("第"+Number(i)+1+"条拖车的拖车公司未正确选择，请选择正确的拖车公司");
                            return false;
                        }
                        if (a.kg && isNaN(a.kg)) {
                            this.$message.warning("第"+Number(i)+1+"条拖车的重量请输入数字");
                            return false;
                        }
                        if (a.cmb && isNaN(a.cmb)) {
                            this.$message.warning("第"+Number(i)+1+"条拖车的体积请输入数字");
                            return false;
                        }
                    }
                    postAction(this.url.addBatch,list).then(res=>{
                        this.$message.success(res.message);
                        this.initLala();
                    })
                },
            },

        mounted() {

        },
        created() {
            this.initLala();
        },
    };
</script>
<style>

</style>
