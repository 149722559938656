<template>
<!--  X33691 freda 周汇报-->
    <div>
        <el-card class="box-card">
            <el-form class="multiline_select" ref="form" :model="queryForm" label-width="80px" size="medium">
                <el-card class="box-card">
                    <el-row>

                      <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="部门">
                          <f-select  v-model="queryForm.sysDepartIds" :is-need="queryForm.sysDepartIds" :dict="'sys_depart'"
                                     :multiple="true"
                                     @keyup.enter.native="handleSearch"></f-select>
                        </el-form-item>
                      </el-col>
                      <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="7">
                        <el-form-item label="下单时间">
                          <div class="f-flex">
                            <el-date-picker
                                class="f-flex-1"
                                v-model="queryForm.createTimeStart"
                                type="datetime"
                                size="small"
                                placeholder="开始时间"
                                default-time="00:00:00"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                @keyup.enter.native="handleSearch"
                            >
                            </el-date-picker>
                            <span class="f-p-h-2">~</span>
                            <el-date-picker
                                class="f-flex-1"
                                v-model="queryForm.createTimeEnd"
                                type="datetime"
                                size="small"
                                placeholder="结束时间"
                                default-time="23:59:59"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                @keyup.enter.native="handleSearch"
                            >
                            </el-date-picker>
                          </div>
                        </el-form-item>
                      </el-col>


                        <el-col :xs="24" :sm="12" :md="12" :lg="4">
                            <el-form-item label-width="30px">
                                <el-button
                                        type="primary"
                                        icon="el-icon-search"
                                        @click="handleSearch"
                                >查询
                                </el-button
                                >
                                <el-button plain @click="handleReset">重置</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>


                </el-card>
            </el-form>
            <div class="bbb">

                <el-button type="primary" size="medium" @click="exportExcelPlus(url.exportExcelUrl,'周汇报')"
                           >导出
                </el-button>
            </div>

            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="73vh"
            >

            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange">
                </el-pagination>
            </el-row>
        </el-card>
    </div>
</template>

<script>
    import QueryMixins from "@/mixins/QueryMixins.js";
    import {deleteAction, postAction,httpAction} from "@/api/manage";
    export default {
        name: "ScanLog",
        mixins: [QueryMixins],
        components: {},
        data() {
            return {
                isUsePost:true,

                columns: [
                    // {
                    //     type: "selection",
                    // },
                    // {
                    //     prop: "action",
                    //     label: "操作",
                    // },
                  {
                    prop: "salesman",
                    label: "销售",
                  },
                  {
                    prop: "pinCmb",
                    label: "拼柜货量",
                  },
                  {
                    prop: "zhengCmb",
                    label: "整柜柜量",
                  },
                  {
                    prop: "kdWeight",
                    label: "空运快递单量",
                  },
                    {
                        prop: "cusCount",
                        label: "新客户多少个",
                    },


                ],
                url: {
                    //查询列表的接口
                    list: "/file/sale3/selectWeekTjIPage",

                    //导出excel的接口
                    exportExcelUrl: '/file/sale3/dcWeekTj',
                },
                disableMixinMounted : true,

            };
        },
        methods: {

        },
      computed:{
        buttonShowList() {
          return {
            // 'dc':this.$btnIsShow('sale/WeekHb','0','导出周汇报'),
          }
        }
      }
    };
</script>

<style lang="scss" scoped>
    .bbb {
        display: flex;
    }

    .dialog-footer {
        text-align: right !important;
    }

    .margin_right_10 {
        margin-right: 10px;
    }
</style>
