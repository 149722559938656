<template>
  <!-- 新增或编辑供应商索赔 -->
  <el-dialog
    title="批量审核"
    :visible="visible"
    width="38%"
    center
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <div class="hint_text">
      您选择了{{ selectTotal }}单，有{{ practical }}单未录入实际赔付金额，请录入
    </div>
    <el-form :model="form" :rules="rules" ref="form">
      <f-table
        v-loading="loading"
        ref="GTable"
        border
        size="medium"
        row-key="id"
        class="c-th-has-bgcolor"
        :columns="columns"
        :data="tableData"
        :cell-style="{ 'text-align': 'center' }"
        @selection-change="handleSelectionChange"
        @sort-change="handleTableChange"
      >
        <template v-slot="scope">
          <template v-if="scope.column.property === 'cooperationType'">
            <span>{{
              parseDict(scope.row.cooperationType, "cooperation_type")
            }}</span>
          </template>
          <template v-else-if="scope.column.property === 'action'">
            <el-button
              type="text"
              @click="handleDele(scope.row)"
              :disabled="disableOrderNo"
              >删除
            </el-button>
          </template>
          <template v-else-if="scope.column.property === 'claimMoney'">
            <el-input v-model="scope.row.claimMoney"></el-input>
          </template>
          <template v-else-if="scope.column.property === 'realClaimMoney'">
            <el-input v-model="scope.row.realClaimMoney"></el-input>
          </template>
          <template v-else-if="scope.column.property === 'serialNumber'">
            <!-- {{ (index += 1) }} -->
          </template>
        </template>
      </f-table>
      <!-- <el-row class="f-text-right f-p-v-8">
        <el-pagination
          v-bind="ipaginationSelect"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChangeSelect"
          @current-change="handleCurrentChangeSelect"
        >
        </el-pagination>
      </el-row> -->
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button size="medium" @click="handleClose" v-if="!isNeedCheck"
        >取 消</el-button
      >
      <el-button size="medium" type="primary" @click="submit">确 定 审 核</el-button>
    </div>
  </el-dialog>
</template>

<script>
import QueryMixins from "@/mixins/QueryMixins.js";
import { postAction } from "@/api/manage";
export default {
  name: "UpdateCheckBatchDialog",
  mixins: [QueryMixins],
  props: {
    visible: {
      type: Boolean,
    },
    row: {
      type: Object,
      default: () => {},
    },
    //todo : 是否需要审核
    isNeedCheck: {
      type: Boolean,
      default: false,
    },
    checkList: {
      type: Array,
      default: () => [],
    },
    selectRows: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    visible(val) {
      if (val) {
        this.selectTotal = this.checkList.length;
        this.tableData = this.checkList.filter((item) => !item.realClaimMoney);
        this.practical = this.tableData.length;
      }
    },
  },
  data() {
    return {
      tableData: [],
      loading: false,
      columns: [
        {
          prop: "claimNo",
          label: "索赔单号",
          width: 140,
          fixed: "left",
        },
        {
          prop: "orderNo",
          label: "订单号",
          width: 140,
          fixed: "left",
        },
        {
          prop: "customer",
          label: "客户",
          width: 140,
          fixed: "left",
        },
        {
          prop: "claimMoney",
          label: "索赔金额",
          width: 140,
          fixed: "left",
        },
        {
          prop: "realClaimMoney",
          label: "实际赔付金额",
          width: 140,
          fixed: "left",
          headerType: "require",
        },
        {
          prop: "serialNumber",
          label: "序号",
          width: 140,
          fixed: "left",
        },
      ],
      rules: {},
      url: {
        batchUpdateCheck: "/file/claim/batchUpdateCheck",
      },
      verifyOne: true,
      verifyTwo: true,
      disableOrderNo: false,
      // 实际录入
      practical: 0,
      selectTotal: 0,
    };
  },
  methods: {
    handleClose() {
      this.tableData = [];
      this.$emit("update:visible", false);
      this.verifyOne = true;
      this.verifyTwo = true;
    },
    //删除
    handleDele(row) {
      this.tableData.forEach((tabItem, index) => {
        if (row.unqId == tabItem.unqId) {
          this.tableData.splice(index, 1);
        }
      });
      console.log(row, "rowww");
    },
    // 提交
    submit() {
      let flag = true;
      const params = [];
      this.tableData.forEach((item) => {
        if (!item.realClaimMoney) {
          flag = false;
        }
        this.changeClaimMoney(Number(item.realClaimMoney), "0");
        this.changeClaimMoney(Number(item.claimMoney), "1");
        params.push({
          id: item.id,
          settleId: item.orderSettleId,
          claimMoney: item.claimMoney,
          realClaimMoney: item.realClaimMoney,
        });
      });
      if (!flag) {
        this.$message.error("实际赔付金额不能为空");
        return;
      }
      if (!this.verifyOne || !this.verifyTwo) {
        this.$message.error(
          "金额只能为负数，最多7位整数2位小数，请检查输入的信息"
        );
        return;
      }
      this.checkList.forEach((checkItem) => {
        let flag = params.some((item) => item.id == checkItem.id);
        if (!flag) {
          params.push(checkItem);
        }
      });
      postAction(this.url.batchUpdateCheck, params).then((res) => {
        if (res.success) {
          this.loading = false;
          this.$message.success(res.message);
          this.handleClose();
          this.$emit("ok");
        } else {
          this.loading = false;
          this.$message.error(res.message);
        }
      });
    },
    changeClaimMoney(value, flag) {
      console.log(value, "value");
      if (flag == "0") {
        this.verifyOne = true;
      } else {
        this.verifyTwo = true;
      }
      if (value) {
        if (value > 0) {
          if (flag == "0") {
            this.verifyOne = false;
          } else {
            this.verifyTwo = false;
          }
          return;
        } else {
          let str = value.toString().split(".");
          if (str[0].length > 8) {
            this.isSend = false;
            if (flag == "0") {
              this.verifyOne = false;
            } else {
              this.verifyTwo = false;
            }
            return;
          } else if (str[1] && str[1].length > 2) {
            this.isSend = false;
            if (flag == "0") {
              this.verifyOne = false;
            } else {
              this.verifyTwo = false;
            }
            return;
          } else if (str[2]) {
            this.isSend = false;
            if (flag == "0") {
              this.verifyOne = false;
            } else {
              this.verifyTwo = false;
            }
            return;
          }
        }
      } else if (isNaN(value)) {
        console.log("不是数字");
        if (flag == "0") {
          this.verifyOne = false;
        } else {
          this.verifyTwo = false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.hint_text {
  color: red;
}
::v-deep {
  .el-table--scrollable-x .el-table__body-wrapper {
    overflow-x: hidden;
  }
  .el-table__fixed::before,
  .el-table__fixed-right::before {
    width: 0;
  }
  .el-table__fixed-body-wrapper {
    height: 240px !important;
    overflow: scroll !important;
  }
  .el-table {
    height: 300px !important;
  }
}
</style>
