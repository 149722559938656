<template>
  <mainplus></mainplus>
</template>

<script>
import mainplus from "@/views/warehouse/Main";
export default {
  name: "main2.0",
  components: {mainplus},
};
</script>

<style lang="scss" scoped>
</style>
