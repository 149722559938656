<template>
  <div class="main-full-content">
    <el-card class="box-card">
      <div class="f-p-b-15">
        <el-button
          type="primary"
          size="medium"
          icon="el-icon-plus"
          @click="handleAdd"
          v-if="buttonShowList.add"
          >新增</el-button
        >
        <el-button type="primary" size="medium" @click="handleExportXls"
                   v-if="buttonShowList.down"
          >导出</el-button
        >
      </div>
      <f-table
        v-loading="loading"
        ref="GTable"
        border
        size="medium"
        row-key="id"
        class="c-th-has-bgcolor"
        :columns="columns"
        :data="tableData"
        @selection-change="handleSelectionChange"
        @sort-change="handleTableChange"
        :cell-style="{ 'text-align': 'center' }"
        tableHeight="74vh"
      >
        <template v-slot="scope">
          <template v-if="scope.column.property === 'action'">
            <el-button type="text" @click="handleEdit(scope.row)"
                       v-if="buttonShowList.edit"
              >编辑</el-button
            >
            <el-button type="text" @click="handleDel(scope.row)"
                       v-if="buttonShowList.del"
              >删除</el-button
            >
            <el-button
              type="text"
              @click="handlSet(scope.row)"
              v-if="scope.row.recordTag === '0' && buttonShowList.ba"
              >备案</el-button
            >
            <el-button
              type="text"
              @click="handlQuxiaoSet(scope.row)"
              v-if="scope.row.recordTag === '1' && buttonShowList.caba"
              >取消备案</el-button
            >
          </template>
          <template v-else-if="scope.column.property === 'source'">
            <span>{{ parseDict(scope.row.source, "impo_source") }}</span>
          </template>
          <template v-else-if="scope.column.property === 'nature'">
            <span>{{ parseDict(scope.row.nature, "impo_nature") }}</span>
          </template>
          <template v-else-if="scope.column.property === 'status'">
            <span>{{ parseDict(scope.row.status, "is_normal") }}</span>
          </template>
          <template v-else-if="scope.column.property === 'recordTag'">
            <span>{{ parseDict(scope.row.recordTag, "is_record") }}</span>
          </template>
        </template>
      </f-table>
    </el-card>

    <el-dialog
      :title="form.id ? '编辑进口商' : '新增进口商'"
      v-if="dialogFormVisible"
      :visible.sync="dialogFormVisible"
      width="40%"
      center
      :close-on-click-modal="false"
    >
      <el-form class="" :model="form" :rules="rules" ref="form">
        <el-row class="inputs_row">
          <el-col :span="12">
            <el-form-item
              label="进口商名称"
              prop="name"
              :label-width="formLabelWidth"
            >
              <el-input type="textarea" class="padding_right_area" v-model="form.name" autocomplete="off" show-word-limit maxlength="255"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="国家" prop="country" :label-width="formLabelWidth">
              <f-select
                v-model="form.country"
                :dict="'bd_country'"
                :is-need="form.country"
                v-if="fsee"
              >
              </f-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="进口商税号/EORI" prop="tax" :label-width="formLabelWidth">
              <el-input v-model="form.tax" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="VAT" prop="vat" :label-width="formLabelWidth">
              <el-input v-model="form.vat" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="联系人"
              prop="contact"
              :label-width="formLabelWidth"
            >
              <el-input v-model="form.contact" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="电话"  :label-width="formLabelWidth">
              <el-input v-model="form.tel" autocomplete="off" maxlength="20" show-word-limit></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="购买来源"
              prop="source"
              :label-width="formLabelWidth"
            >
              <f-select
                :dict="'impo_source'"
                v-model="form.source"
                placeholder="请选择"
                :is-need="form.source"
                v-if="fsee"
              ></f-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="性质" prop="nature" :label-width="formLabelWidth">
              <f-select
                :dict="'impo_nature'"
                v-model="form.nature"
                placeholder="请选择"
                :is-need="form.nature"
                v-if="fsee"
              ></f-select>
            </el-form-item>
          </el-col>
          <el-col  :span="24">
            <el-form-item label="是否有效性" prop="status"  :label-width="formLabelWidth" :rules="rules.status" key="status">
              <el-radio-group v-model="form.status" >
                <el-radio label="1">是</el-radio>
                <el-radio label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="有效开始时间"
              prop="stime"
              :rules="form.country==='美国'?rules.stime:[]"
              :label-width="formLabelWidth"
            >
              <el-date-picker
                class="f-flex-1"
                v-model="form.stime"
                type="datetime"
                size="small"
                placeholder="开始时间"
                default-time="00:00:00"
                :picker-options="editStartOptions"
                value-format="yyyy-MM-dd HH:mm:ss"
                format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="有效结束时间"
              prop="etime"
              :rules="form.country==='美国'?rules.etime:[]"
              :label-width="formLabelWidth"
            >
              <el-date-picker
                class="f-flex-1"
                v-model="form.etime"
                type="datetime"
                size="small"
                placeholder="结束时间"
                default-time="23:59:59"
                :picker-options="editStopOptions"
                value-format="yyyy-MM-dd HH:mm:ss"
                format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="门牌号"  :label-width="formLabelWidth">
          <el-input v-model="form.houseNo" autocomplete="off"></el-input>
        </el-form-item>
          </el-col>
         
        <el-col :span="12">
                    <el-form-item label="城市"  :label-width="formLabelWidth">
          <el-input v-model="form.city" autocomplete="off"></el-input>
        </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="邮编"  :label-width="formLabelWidth">
          <el-input v-model="form.zipcode" autocomplete="off"></el-input>
        </el-form-item>
                </el-col>
       
          <el-col :span="12">
            <el-form-item label="地址" prop="address" :label-width="formLabelWidth">
              <el-input
                v-model="form.address"
                autocomplete="off"
                type="textarea"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="medium" @click="dialogFormVisible = false"
          >取 消</el-button
        >
        <el-button
          size="medium"
          type="primary"
          @click="saveImporter"
          :disabled="btnDisable"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import QueryMixins from "@/mixins/QueryMixins.js";
import { deleteAction, putAction, postAction, getAction } from "@/api/manage";
import { mobilePattern } from "@/utils/pattern";
export default {
  name: "ImporterModule",
  mixins: [QueryMixins],
  components: {},
  data() {
    return {
      fsee: false,
      dictCodes: ["impo_source", "impo_nature", "is_normal", "is_record"],
      natureDisc: "impo_nature",
      sourceDisc: "impo_source",
      editStartOptions: {
        disabledDate: (time) => {
          if (!this.form.etime) {
            // time = new Date(time.replace(/-/g, '/'));
            return time.getTime() < new Date(1970 - 1 - 1).getTime(); //禁止选择1970年以前的日期
          } else {
            return time.getTime() > new Date(this.form.etime);
          }
        },
      },
      editStopOptions: {
        disabledDate: (time) => {
          // time = new Date(time.replace(/-/g, '/'));
          return (
            time.getTime() < new Date(this.form.stime) ||
            time.getTime() < new Date(1970 - 1 - 1).getTime() //禁止选择1970年以前的日期
          );
        },
      },
      btnDisable: false,
      row: {},
      form: {},
      dialogFormVisible: false,
      formLabelWidth: "120px",
      countrylist: [],

      //表单校验
      rules: {
        name: [
          { required: true, message: "请输入进口商名称", trigger: "blur" },
          { max: 255, message: "长度不能超过255", trigger: "change" },
        ],
        country: [{ required: true, message: "请选择国家", trigger: "blur" }],
        tax: [{ required: true, message: "请输入税号", trigger: "change" }],
        vat: [{ required: true, message: "请输入VAT", trigger: "change" }],
        status: [{ required: true, message: "请选择有效性", trigger: "change" }],
        tel: [
          // { required: true, message: "请输入手机号", trigger: "blur" },
          // {
          //   pattern: mobilePattern,
          //   message: "请输入正确的手机号",
          //   trigger: "change",
          // },
        ],
        stime: [
          { required: true, message: "请输入有效开始时间", trigger: "blur" },
        ],
        etime: [
          { required: true, message: "请输入有效结束时间", trigger: "blur" },
        ],
        source: [
          { required: true, message: "请选择购买来源", trigger: "blur" },
        ],
        nature: [{ required: true, message: "请选择性质", trigger: "blur" }],
      },
      props: {
        userName: {
          type: String,
        },
      },
      visible: false,
      columns: [
        {
          prop: "action",
          label: "操作",
        },
        {
          prop: "name",
          label: "进口商名",
          sortable: "custom",
        },
        {
          prop: "country",
          label: "国家",
          sortable: "custom",
        },
        {
          prop: "source",
          label: "购买来源",
          sortable: "custom",
        },
        {
          prop: "nature",
          label: "性质",
          sortable: "custom",
        },
        {
          prop: "stime",
          label: "有效开始时间",
          sortable: "custom",
          width: 160,
        },
        {
          prop: "etime",
          label: "有效结束时间",
          sortable: "custom",
          width: 160,
        },
        {
          prop: "status",
          label: "有效状态",
        },
        {
          prop: "tax",
          label: "税号",
          sortable: "custom",
        },
        {
          prop: "vat",
          label: "VAT",
          sortable: "custom",
        },
        {
          prop: "recordTag",
          label: "备案状态",
          sortable: "custom",
        },
        {
          prop: "createTime",
          label: "创建时间",
          sortable: "custom",
        },
      ],
      url: {
        list: "/customer/importer/list",
        delByid: "/customer/importer/delete",
        setDefault: "/customer/importer/record",
        setQuxiaoDefault: "/customer/importer/recordCancel",
        exportXlsUrl: "/customer/importer/exportXls",
        getbyid: "/customer/importer/getById",
        add: "/customer/importer/add",
        edit: "/customer/importer/edit",
      },
    };
  },
  created() {
    this.queryForm.initCountry = 1;
    this.queryForm.cusId = this.$route.query.id;
  },
  methods: {
    handlSet(row) {
      this.$confirm(`确定将${row.name}备案吗`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "success",
        closeOnClickModal: false,
      })
        .then(() => {
          putAction(this.url.setDefault + "?ids=" + this.selectedRowKeys, {
            id: row.id,
          }).then((res) => {
            this.$message({
              type: "success",
              message: res.message,
            });
            this.handleSearch();
          });
        });
    },

    handlQuxiaoSet(row) {
      this.$confirm(`确定将${row.name}取消备案吗`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "success",
        closeOnClickModal: false,
      })
        .then(() => {
          putAction(
            this.url.setQuxiaoDefault + "?ids=" + this.selectedRowKeys,
            { id: row.id }
          ).then((res) => {
            this.$message({
              type: "success",
              message: res.message,
            });
            this.handleSearch();
          });
        });
    },
    handleDel(row) {
      this.$confirm(`确定删除${row.name}设吗`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "success",
        closeOnClickModal: false,
      })
        .then(() => {
          deleteAction(this.url.delByid + "?ids=" + this.selectedRowKeys, {
            id: row.id,
          }).then((res) => {
            this.$message({
              type: "success",
              message: res.message,
            });
            this.handleSearch();
          });
        });
    },
    handleAdd() {
      // let total = this.ipagination.total;
      // // console.log("总共有", total);
      // if (total >= 10) {
      //   this.$message.warning("最多添加十条数据呢亲");
      //   return false;
      // }
      this.fsee = false;
      this.form = {};
      this.fsee = true;
      // console.log("供应商表单对象", this.form);
      this.dialogFormVisible = true;
      this.btnDisable = false;
    },
    handleEdit(row) {
      this.fsee = false;
      this.btnDisable = false;
      getAction(this.url.getbyid, { id: row.id })
        .then((res) => {
          if (res.success) {
            this.form = res.result;
            this.fsee = true;
            // console.log("日期", this.form);
          }
        })

      this.dialogFormVisible = true;
    },
    saveImporter() {
      this.btnDisable = true;
      this.form.cusId = this.$route.query.id;
      // consoloe.log("进口商对象", this.form);
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.form.editSource = '1'
          if (this.form.id) {
            putAction(this.url.edit, this.form)
              .then((res) => {
                if (res.success) {
                  this.$message.success(res.message);
                  this.dialogFormVisible = false;
                  this.handleQuery();
                } else {
                  this.btnDisable = false;
                }
              })
              .catch((err) => {
                this.btnDisable = false;
              });
          } else {
            postAction(this.url.add, this.form)
              .then((res) => {
                if (res.success) {
                  this.$message.success(res.message);
                  this.dialogFormVisible = false;
                  this.handleQuery();
                } else {
                  this.btnDisable = false;
                }
              })
              .catch((err) => {
                this.btnDisable = false;
              });
          }
        } else {
          this.btnDisable = false;
        }
      });
    },
  },
  computed : {
    buttonShowList() {
      return {
        'add':this.$btnIsShow('customer/modules/ImporterModule','0','新增'),
        'down':this.$btnIsShow('customer/Edit','0','导出进口商'),
        'edit':this.$btnIsShow('customer/modules/ImporterModule','1','编辑'),
        'del':this.$btnIsShow('customer/modules/ImporterModule','1','删除'),
        'ba':this.$btnIsShow('customer/modules/ImporterModule','1','备案'),
        'caba':this.$btnIsShow('customer/modules/ImporterModule','1','取消备案'),
      }
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
