<template>
  <div>
    <el-form class="multiline_select" ref="form" :model="queryForm" label-width="80px" size="medium">
        <el-card class="box-card">
            <el-row class="inputs_row">
                <el-col :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="订单号">
                    <el-input
                        type="textarea"
                        class="textarea_table"
                        v-model="queryForm.orderNo"
                        @keyup.enter.native="handleSearch"
                    ></el-input>
                  </el-form-item>
                </el-col>
<!--                <el-col :xs="24" :sm="12" :md="12" :lg="4">-->
<!--                  <el-form-item label="订单类型">-->
<!--                    <f-select-->
<!--                      :dict="'order_os_type'"-->
<!--                      v-model="queryForm.isHaiyun"-->
<!--                      :is-need="queryForm.isHaiyun"-->
<!--                      :father-val="queryForm.isHaiyun"-->
<!--                    ></f-select>-->
<!--                  </el-form-item>-->
<!--                </el-col>-->
                <el-col :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="客户名">
                    <el-input v-model="queryForm.customer" @keyup.enter.native="handleSearch"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="客服">
                    <f-select
                      :dict="'sys_user_id'"
                      v-model="queryForm.docId"
                      :is-need="queryForm.docId"
                      :father-val="queryForm.docId"
                    ></f-select>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="业务员">
                    <f-select
                      :dict="'sys_user_id'"
                      v-model="queryForm.salesmanId"
                      :is-need="queryForm.salesmanId"
                      :father-val="queryForm.salesmanId"
                    ></f-select>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="国家">
                    <f-select
                      :dict="'bd_country'"
                      v-model="queryForm.shipmentCountry"
                      :is-need="queryForm.shipmentCountry"
                      :father-val="queryForm.shipmentCountry"
                    ></f-select>
                  </el-form-item>
                </el-col>
                <el-col :class="!showLoadingSearch ? 'no_margin' : ''" :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="仓库编码">
                    <el-input v-model="queryForm.shipmentCode" @keyup.enter.native="handleSearch"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :class="!showLoadingSearch ? 'no_margin' : ''" :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="渠道名称">
                    <f-select
                      :dict="'transportStatus'"
                      v-model="queryForm.transportStatus"
                      :is-need="queryForm.transportStatus"
                      :father-val="queryForm.transportStatus"
                    ></f-select>
                  </el-form-item>
                </el-col>
                <el-col :class="!showLoadingSearch ? 'no_margin' : ''" :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="报关类型">
                    <f-select
                      :dict="'os_declare_type'"
                      v-model="queryForm.declareType"
                      :is-need="queryForm.declareType"
                      :father-val="queryForm.declareType"
                    ></f-select>
                  </el-form-item>
                </el-col>
                <el-col :class="!showLoadingSearch ? 'no_margin' : ''" :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="派送地址类型" label-width="90px">
                    <f-select
                      :dict="'shipment_type'"
                      v-model="queryForm.shipmentType"
                      :is-need="queryForm.shipmentType"
                      :father-val="queryForm.shipmentType"
                    ></f-select>
                  </el-form-item>
                </el-col>
                <el-col :class="!showLoadingSearch ? 'no_margin' : ''" :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="交货方式">
                    <f-select
                      :dict="'wh_type'"
                      v-model="queryForm.whType"
                      :is-need="queryForm.whType"
                      :father-val="queryForm.whType"
                    ></f-select>
                  </el-form-item>
                </el-col>
                <el-col v-if="showLoadingSearch" :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="预计件/重/体" label-width="90px">
                    <el-input v-model="queryForm.yuji" @keyup.enter.native="handleSearch"></el-input>
                  </el-form-item>
                </el-col>
                <el-col class="no_margin" v-if="showLoadingSearch" :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="客户提醒">
                    <el-input v-model="queryForm.froRemark" @keyup.enter.native="handleSearch"></el-input>
                  </el-form-item>
                </el-col>
                <el-col class="no_margin" v-if="showLoadingSearch" :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="客户内部单号" label-width="90px">
                    <el-input v-model="queryForm.withinOrderRemark" @keyup.enter.native="handleSearch"></el-input>
                  </el-form-item>
                </el-col>
                <el-col class="no_margin" v-if="showLoadingSearch" :xs="24" :sm="12" :md="12" :lg="4">
                  <el-form-item label="是否购买保险" label-width="90px">
                    <f-select
                      :dict="'is_default'"
                      v-model="queryForm.insurance"
                      :is-need="queryForm.insurance"
                      :father-val="queryForm.insurance"
                    ></f-select>
                  </el-form-item>
                </el-col>
                <el-col class="no_margin" v-if="showLoadingSearch" :xs="24" :sm="12" :md="12" :lg="4">
                    <el-form-item label="是否问题件" label-width="90px">
                        <f-select
                                :dict="'is_default'"
                                v-model="queryForm.isWarning"
                                :is-need="queryForm.isWarning"
                                :father-val="queryForm.isWarning"
                        ></f-select>
                    </el-form-item>
                </el-col>
                <el-col v-if="showLoadingSearch" :xs="24" :sm="12" :md="12" :lg="4">
                    <el-form-item label="是否入仓" label-width="100px">
                        <f-select
                                :dict="'is_default'"
                                v-model="queryForm.isRealCmb"
                                :is-need="queryForm.isRealCmb"
                                :father-val="queryForm.isRealCmb"
                        ></f-select>
                    </el-form-item>
                </el-col>
                <el-col class="no_margin" v-if="showLoadingSearch" :xs="24" :sm="12" :md="12" :lg="7">
                  <el-form-item label="下单时间">
                    <div class="f-flex">
                      <el-date-picker
                        class="f-flex-1"
                        v-model="queryForm.createTimeStart"
                        type="datetime"
                        size="small"
                        placeholder="开始时间"
                        default-time="00:00:00"
                        value-format="yyyy-MM-dd HH:mm:ss"
                      >
                      </el-date-picker>
                      <span class="f-p-h-2">~</span>
                      <el-date-picker
                        class="f-flex-1"
                        v-model="queryForm.createTimeEnd"
                        type="datetime"
                        size="small"
                        placeholder="结束时间"
                        default-time="23:59:59"
                        value-format="yyyy-MM-dd HH:mm:ss"
                      >
                      </el-date-picker>
                    </div>
                  </el-form-item>
                </el-col>
                <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="4">
                    <el-form-item label-width="30px">
                        <el-button
                                type="primary"
                                icon="el-icon-search"
                                @click="handleSearch"
                        >查询
                        </el-button
                        >
                        <el-button plain @click="handleReset">重置</el-button>
                        <el-button type="text" mini @click="showLoadingSearch=!showLoadingSearch"><i :class="showLoadingSearch ? 'el-icon-arrow-up' : 'el-icon-arrow-down'" style="margin-right:3px"></i>{{showLoadingSearch ? '收起' :'更多'}}</el-button>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-card>
    </el-form>
    <el-card class="box-card">
      <f-table
        v-loading="loading"
        ref="GTable"
        border
        size="medium"
        row-key="id"
        class="c-th-has-bgcolor"
        :columns="columns"
        :data="tableData"
        :isorter="isorter"
        :options="typelist"
        :show-operation="true"
        :cell-style="{ 'text-align': 'center' }"
        @selection-change="handleSelectionChange"
        @sort-change="handleTableChange"
        @queryChange="handleTableQueryChange"
        :tableHeight="showLoadingSearch ? '65vh' : '68vh'"
        :isRecordTableScroll="true"
      >
        <template v-slot="scope">
          <template v-if="scope.column.property === 'action'">
            <el-button
                type="text"
                @click="remarkProblem(scope.row)"
                v-if="buttonShowList.bjwtj && scope.row.isWarning !=='1'"
              >标记问题件</el-button>
              <span v-if="scope.row.isWarning==='1'" style="color: red">问题件</span>
          </template>
          <template v-else-if="scope.column.property === 'orderNo'">
              <a v-if="scope.row.isWarning==='1'"
                 style="color: red"
                 @click.prevent="$intoDocPage(scope.row.orderNo)"
              >{{ scope.row.orderNo }}</a>
              <a v-else
                 style="color: #00a0e9"
                 @click.prevent="$intoDocPage(scope.row.orderNo)"
              >{{ scope.row.orderNo }}</a>
          </template>
          <template v-else-if="scope.column.property === 'type'">
            <span v-if="scope.row.type === '0'">公司负责人</span>
            <span v-else-if="scope.row.type === '1'">报价对接人</span>
            <span v-else-if="scope.row.type === '2'">操作联系人</span>
            <span v-else>财务联系人</span>
          </template>
<!--          <template v-else-if="scope.column.property === 'isHaiyun'">-->
<!--            <span v-if="scope.row.isHaiyun === '2'">空运</span>-->
<!--            <span v-else>海运</span>-->
<!--          </template>-->
          <template v-else-if="scope.column.property === 'shipmentType'">
            <span>{{
              parseDict(scope.row.shipmentType, "shipment_type")
            }}</span>
          </template>
<!--          <template v-else-if="scope.column.property === 'transportStatus'">-->
<!--            <span>{{-->
<!--              parseDict(scope.row.transportStatus, "transportStatus")-->
<!--            }}</span>-->
<!--          </template>-->
          <template v-else-if="scope.column.property === 'declareType'">
            <span>{{ parseDict(scope.row.declareType, "os_declare_type") }}</span>
          </template>
          <template v-else-if="scope.column.property === 'insurance'">
            <span>{{ parseDict(scope.row.insurance, "is_default") }}</span>
          </template>
          <template v-else-if="scope.column.property === 'whType'">
            <span>{{ parseDict(scope.row.whType, "wh_type") }}</span>
          </template>
          <template v-else-if="scope.column.property === 'isWeiban'">
            <span>{{ parseDict(scope.row.isWeiban, "is_default") }}</span>
          </template>
          <template v-else-if="scope.column.property === 'isBanyun'">
            <span>{{ parseDict(scope.row.isBanyun, "is_default") }}</span>
          </template>
          <!--<template v-else-if="scope.column.property === 'docId'">-->
            <!--<span>{{ parseDict(scope.row.docId, "all_user_id") }}</span>-->
          <!--</template>-->
          <!--<template v-else-if="scope.column.property === 'salesmanId'">-->
            <!--<span>{{ parseDict(scope.row.salesmanId, "all_user_id") }}</span>-->
          <!--</template>-->
          <template v-else-if="scope.column.property === 'customer'">
            <a type="text" @click.prevent="$intoCustomerEditPage(scope.row.cusId)" style="color:#57a3f3;">{{scope.row.customer}}</a>
          </template>
        </template>
      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination
          v-bind="ipagination"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </el-row>
    </el-card>
    <add-problem-dialog
      :visible.sync="proVisible"
      :row="row"
      @ok="handleQuery"
      v-if="proVisible"
    ></add-problem-dialog>
  </div>
</template>

<script>
import QueryMixins from "@/mixins/QueryMixins.js";
import { mobilePattern, emailPattern } from "@/utils/pattern";
import { deleteAction, postAction, putAction, getAction } from "@/api/manage";
import AddProblemDialog from "./modules/AddProblemDialog";
import { setUpRouteUtil } from '@/utils/util';


export default {
  name: "ContactModule",
  mixins: [QueryMixins],
  components: {
    AddProblemDialog,
  },
  data() {
    return {
      showLoadingSearch: false,
      dictCodes: [
        "is_haiyun",
        "os_declare_type",
        "transportStatus",
        "shipment_type",
        "wh_type",
        "is_default",'all_user_id'
      ],
      //联系人类型0公司负责人 1报价对接人 2操作联系人 3财务联系人',
      btnDisable: false,
      proVisible: false,
      typelist: [
        { value: "0", label: "公司负责人" },
        { value: "1", label: "报价对接人" },
        { value: "2", label: "操作联系人" },
        { value: "3", label: "财务联系人" },
      ],
      form: {},
      dialogFormVisible: false,
      formLabelWidth: "120px",
      visible: false,
      columns: [
        {
          prop: "action",
          label: "操作",
          minWidth: 100,
        },
        {
          prop: "orderNo",
          label: "订单号",
          minWidth: 90,
        },
        // {
        //   prop: "isHaiyun",
        //   label: "订单类型",
        //   minWidth: 50,
        // },
        {
          prop: "customer",
          label: "客户名",
          minWidth: 100,
        },
        {
          prop: "doc",
          label: "客服",
          minWidth: 80,
        },
        {
          prop: "salesman",
          label: "业务员",
          minWidth: 80,
        },
        {
          prop: "createTime",
          label: "下单时间",
          minWidth: 75,
          sortable: "custom",
        },
        {
          prop: "shipmentCountry",
          label: "国家",
          minWidth: 80,
        },
        {
          prop: "shipmentCode",
          label: "仓库编码",
          minWidth: 50,
        },
        {
          prop: "channelName",
          label: "渠道名称",
          minWidth: 50,
        },
        {
          prop: "declareType",
          label: "报关类型",
          minWidth: 50,
        },
        {
          prop: "shipmentType",
          label: "派送地址类型",
          minWidth: 50,
        },
        {
          prop: "whType",
          label: "交货方式",
          minWidth: 50,
        },
        {
          prop: "yuji",
          label: "预计件/重/体",
          minWidth: 60,
          sortable: "custom",
        },
        {
          prop: "froRemark",
          label: "客户提醒",
          minWidth: 100,
        },
        {
          prop: "withinOrderRemark",
          label: "客户内部单号",
          minWidth: 60,
        },
        {
          prop: "insurance",
          label: "是否购买保险",
          minWidth: 50,
        },
      ],
      url: {
        // list: "interface/ttOrder/getWaitWarehousingPage",
        list: "interface/ttOrder/getNoBookingPlacedOrderPage",
      },
      issalesman: "0",
      row: {},
      isUsePost:true
    };
  },
  created() {
    this.queryForm.pglx = "0";
    // this.queryForm.orderStatus = "1";

    this.issales();
  },
  methods: {
    handleReset() {
      this.queryForm = {};
      this.filterForm={};
      this.ipagination.currentPage = 1;
      this.queryForm.pglx = "0";
      // this.queryForm.orderStatus = "1";
      if (this.$refs['GTable']){
        this.$refs['GTable'].handleReset();
      }

      this.handleQuery();
    },

    remarkProblem(row) {
      this.$set(this.row, "orderNo", row.orderNo);
      this.proVisible = true;
    },
    //进入客服页
    intoDocpage(orderNo) {
      //判断是否是销售
      // if (this.issalesman == "1") {
      //   // 对于路由不在左侧菜单中的选中菜单处理
      //   setUpRouteUtil(this.$route.path, '/docpage/EditSaleFCL');
      //   this.$router.push("/docpage/EditSaleFCL?orderNo=" + orderNo);
      // } else {
        // 对于路由不在左侧菜单中的选中菜单处理
        setUpRouteUtil(this.$route.path, '/docpage/Edit');
        this.$router.push("/docpage/Edit?orderNo=" + orderNo);
      // }
    },

    async issales() {
      let ss = localStorage.getItem("USERINFO");
      var user = JSON.parse(ss);
      let result = await getAction("/sys/dict/getDictItems/salesmanId");
      var ids = result.result
        .map((e) => {
          return e.value;
        })
        .join(",");
      if (ids.indexOf(user.id) !== -1) {
        this.issalesman = "1";
      } else {
        this.issalesman = "0";
      }
      //测试
      //    this.issalesman = '1';
    },
  },
  computed : {
    buttonShowList() {
      return {
        'bjwtj':this.$btnIsShow('docpage/NoReceiveOsOrderFCL','1','标记问题件'),
      }
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
