<template>
    <div>
      <el-form ref="form" :model="queryForm" label-width="120px" size="medium">
        <el-card class="box-card">
          <el-row style="display: flex; flex-wrap: wrap">
            <el-col :xs="24" :sm="12" :md="12" :lg="6">
              <el-form-item label="订单号">
                <el-input
                    v-model="queryForm.orderNo" type="textarea" placeholder="多个用逗号或者空格隔开"
                    @keyup.enter.native="handleSearch"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="6">
              <el-form-item label="柜号">
                <el-input
                    v-model="queryForm.containerNo" type="textarea" placeholder="多个用逗号或者空格隔开"
                    @keyup.enter.native="handleSearch"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="3">
              <el-form-item label-width="30px">
                <el-button
                    type="primary"
                    icon="el-icon-search"
                    @click="handleSearch"
                >查询
                </el-button
                >
                <el-button plain @click="handleReset">重置</el-button>
              </el-form-item>
            </el-col>
          </el-row>

        </el-card>
      </el-form>
        <el-card class="box-card">
        <div class="bbb">

          <el-button type="primary" @click="handleDelDuo" size="medium" class="c-theme-error-button"
                     :disabled="selectionRows.length === 0">抵消费用</el-button>

        </div>
        <f-table
                v-loading="loading"
                ref="GTable"
                border
                size="medium"
                row-key="id"
                class="c-th-has-bgcolor"
                :columns="columns"
                :data="tableData"
                @selection-change="handleSelectionChange"
                @sort-change="handleTableChange"
                :cell-style="{ 'text-align': 'center' }"
                tableHeight="73vh"
        >
          <template v-slot="scope">
            <template v-if="scope.column.property ==='isEmailCount'">
              <span v-if="scope.row.isEmailCount>0" style="color:red">已抵消</span>
              <span v-else>未抵消</span>
            </template>
            <template v-else-if="scope.column.property === 'orderNo'">
              <a
                  style="color: #00a0e9"
                  @click.prevent="$intoDocPage(scope.row.orderNo)"
              >{{ scope.row.orderNo }}</a
              >
            </template>
            <template v-else-if="scope.column.property === 'containerNo'">
              <a style="color: #00a0e9" @click.prevent="infomation(scope.row.containerId)">{{scope.row.containerNo}}</a>
            </template>
          </template>
        </f-table>
        <el-row class="f-text-right f-p-v-8">
            <el-pagination
                    v-bind="ipagination"
                    layout="total, sizes, prev, pager, next, jumper"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
            >
            </el-pagination>
        </el-row>
        </el-card>
    </div>
</template>

<script>
    import QueryMixins from "@/mixins/QueryMixins.js";
    import {postAction} from "@/api/manage";
    import {setUpRouteUtil} from "../../utils/util";

    export default {
        name: "ZXFModule",
        mixins: [QueryMixins],
        components: {},
        data() {
            return {
                deliveryList: [],
                btnDisable: false,
                row: {},
                form: {},
                dialogFormVisible: false,
                formLabelWidth: "120px",
                countrylist: [],
                visible: false,
                columns: [
                  {
                    type: 'selection',
                    width: 33
                  },
                  {
                    prop: "isEmailCount",
                    label: "是否抵消",
                  },
                    {
                        prop: "orderNo",
                        label: "订单号",
                    },
                    {
                        prop: "containerNo",
                        label: "柜号",
                    },
                  {
                    prop: "feename",
                    label: "费用名",
                  },
                  {
                    prop: "feenameUs",
                    label: "英文名",
                  },
                    {
                        prop: "feecode",
                        label: "费用编码",
                    },
                    {
                        prop: "moneyType",
                        label: "币种",
                    },

                  {
                    prop: "total",
                    label: "金额",
                  },


                ],
              isUsePost:true,
                url: {
                    list: "/order/os/settle/getZXF",
                  dxzxf: "/order/os/settle/dxzxf",
                },

            };
        },
        created() {
        },
        methods: {
          infomation(id) {
            // 对于路由不在左侧菜单中的选中菜单处理
            if(id) {
              setUpRouteUtil(this.$route.path, '/operation/cabinet/Edit');
              this.$router.push("/operation/cabinet/Edit?id=" + id);
            }
          },




          handleDelDuo() {
            let flag = true;
            this.selectionRows.forEach(s=>{
              let count = s.isEmailCount;
              if (count > 0) {
                flag = false;
                return false;
              }
            })
            if (!flag){
              this.$message.warning('存在已经抵消了的费用，请重新选择');
              return false;
            }
            if (flag) {
              this.$confirm(`确定抵消费用吗，同时发消息给客服和销售`, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "success",
                closeOnClickModal: false,
              })
                  .then(() => {
                    postAction(this.url.dxzxf, this.selectionRows).then((res) => {
                      this.$message({
                        type: "success",
                        message: res.message,
                      });
                      this.handleSearch();
                    });
                  })
                  .catch(() => {

                  });
            }

          },


        },
    };
</script>

<style lang="scss" scoped>
    .bbb {
        display: flex;
    }

    .dialog-footer {
        text-align: right !important;
    }
    .margin_right_10 {
      margin-right: 10px;
    }
</style>
