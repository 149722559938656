<template>
    <div>
        <el-card class="box-card">
            <el-form class="multiline_select" ref="form" :model="queryForm" label-width="80px" size="medium">
                <el-card class="box-card">
                    <el-row>
                        <el-col :xs="24" :sm="12" :md="12" :lg="5">
                            <el-form-item label="单号">
                                <el-input  v-model="queryForm.orderNo" type="textarea"
                                           @keyup.enter.native="handleSearch"></el-input>
                            </el-form-item>
                        </el-col>
                      <el-col :xs="24" :sm="12" :md="12" :lg="5">
                        <el-form-item label="用车编号">
                          <el-input  v-model="queryForm.rollNo" type="textarea"
                                     @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :xs="24" :sm="12" :md="12" :lg="5">
                        <el-form-item label="起运仓库">
                          <f-select  v-model="queryForm.originatingWarehouseId" :is-need="queryForm.originatingWarehouseId"
                                     :dict="'delivery_point'"
                                     @keyup.enter.native="handleSearch"></f-select>
                        </el-form-item>
                      </el-col>
                      <el-col :xs="24" :sm="12" :md="12" :lg="5">
                        <el-form-item label="到达仓库">
                          <f-select  v-model="queryForm.arriveAtWarehouseId" :is-need="queryForm.arriveAtWarehouseId"
                                     :dict="'delivery_point'"
                                     @keyup.enter.native="handleSearch"></f-select>
                        </el-form-item>
                      </el-col>
                      <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="10">
                        <el-form-item label="创建时间">
                         <div class="f-flex">
                  <el-date-picker
                      class="f-flex-1"
                      v-model="queryForm.createTimeStart"
                      type="datetime"
                      size="small"
                      placeholder="开始时间"
                      default-time="00:00:00"
                      value-format="yyyy-MM-dd HH:mm:ss"
                  >
                  </el-date-picker>
                  <span class="f-p-h-2">~</span>
                  <el-date-picker
                      class="f-flex-1"
                      v-model="queryForm.createTimeEnd"
                      type="datetime"
                      size="small"
                      placeholder="结束时间"
                      default-time="23:59:59"
                      value-format="yyyy-MM-dd HH:mm:ss"
                  >
                  </el-date-picker>
                </div>
                        </el-form-item>
                      </el-col>
                      <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="10">
                        <el-form-item label="起运仓时间">
                          <div class="f-flex">
                  <el-date-picker
                      class="f-flex-1"
                      v-model="queryForm.originatingWarehouseTimeStart"
                      type="datetime"
                      size="small"
                      placeholder="开始时间"
                      default-time="00:00:00"
                      value-format="yyyy-MM-dd HH:mm:ss"
                  >
                  </el-date-picker>
                  <span class="f-p-h-2">~</span>
                  <el-date-picker
                      class="f-flex-1"
                      v-model="queryForm.originatingWarehouseTimeEnd"
                      type="datetime"
                      size="small"
                      placeholder="结束时间"
                      default-time="23:59:59"
                      value-format="yyyy-MM-dd HH:mm:ss"
                  >
                  </el-date-picker>
                </div>
                        </el-form-item>
                      </el-col>
                      <el-col :xs="24" :sm="12" :md="12" :lg="5">
                        <el-form-item label="创建人">
                          <f-select  v-model="queryForm.createBy" :is-need="queryForm.createBy"
                                     :dict="'sys_user'"
                                     @keyup.enter.native="handleSearch"></f-select>
                        </el-form-item>
                      </el-col>
                      <el-col :xs="24" :sm="12" :md="12" :lg="5">
                        <el-form-item label="车牌/司机/电话" label-width="100px">
                          <el-input  v-model="queryForm.driverInfo"
                                     @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                      </el-col>
                        <el-col :xs="24" :sm="12" :md="12" :lg="4">
                            <el-form-item label-width="30px">
                                <el-button
                                        type="primary"
                                        icon="el-icon-search"
                                        @click="handleSearch"
                                >查询
                                </el-button
                                >
                                <el-button plain @click="handleReset">重置</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>


                </el-card>
            </el-form>
          <div class="bbb">
            <el-button type="primary" size="medium" @click="openRollDialog"
                       v-if="buttonShowList.add">新增转仓
            </el-button>
            <el-button type="primary" size="medium" @click="cancelRoll(null,selectedRowKeys)" :disabled="selectedRowKeys.length === 0"
                       v-if="buttonShowList.qx">取消
            </el-button>
          </div>

            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="73vh"
            >
                <template v-slot="scope">
                  <template v-if="scope.column.property === 'action'">
                   <el-button type="text" @click="cancelRoll(scope.row,null)" v-if="buttonShowList.qx">取消</el-button>
                      <el-button type="text"  @click="handleExportXlsS(scope.row)"  v-if="buttonShowList.dc">
              导出转仓单
            </el-button>
                  </template>
                  <template v-else-if="scope.column.property === 'orderNo'">
                  <span v-for="(i,d) in scope.row.innerVos" :key="d">
                           <a style="color: #00a0e9;" @click.prevent="$intoDocPage(i.ttOrderNo)">{{
                               i.ttOrderNo
                             }}</a>
                    <span style="margin-left: 5px">{{i.number}}/</span>
                    <span style="margin-left: 3px">{{i.weight}}/</span>
                    <span style="margin-left: 3px">{{i.volume}}</span> <br>
                        </span>
                  </template>

                  <template v-else-if="scope.column.property === 'actualArrivalTime'">
                  <span v-for="(i,d) in scope.row.innerVos" :key="d">
                          {{i.actualArrivalTime}}<br>
                        </span>
                  </template>
                  <template v-else-if="scope.column.property === 'exitWarehouseTime'">
                  <span v-for="(i,d) in scope.row.innerVos" :key="d">
                          {{i.exitWarehouseTime}}<br>
                        </span>
                  </template>
                  <template v-else-if="scope.column.property === 'takeFeeStr'">
                    <span v-html="scope.row.takeFeeStr"></span>
                  </template>
                  <template v-else-if="scope.column.property === 'rollNo'">
                    <a style="color: #00a0e9"  @click.prevent="openzcDialog(scope.row.id,scope.row)">{{
                        scope.row.rollNo
                      }}</a>
                  </template>
                </template>

            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange">
                </el-pagination>
            </el-row>
        </el-card>
      <add-warehouse-roll-dialog :visible="rollVisible" @ok="closeRollDialog"></add-warehouse-roll-dialog>
      <zc-order-detail :visible="zcOrderDetailVisible" @ok="closezcDialog" :take-ids="selectedzcId" :row='selectedzcRow'></zc-order-detail>
    </div>
</template>

<script>
    import QueryMixins from "@/mixins/QueryMixins.js";
    import { postAction} from "@/api/manage";
    import addWarehouseRollDialog from "./modules/addWarehouseRollDialog";
    import zcOrderDetail from "./modules/zcOrderDetail.vue";
    export default {
        name: "WarehouseRoll",
        mixins: [QueryMixins],
        components: {addWarehouseRollDialog,zcOrderDetail},
        data() {
            return {
              zcOrderDetailVisible:false,
              selectedzcId:'',
              editStopOptions: {
                disabledDate: (time) => {
                  // time = new Date(time.replace(/-/g, '/'));
                  return (
                      time.getTime() < new Date(this.form.stime) ||
                      time.getTime() < new Date(1970 - 1 - 1).getTime() //禁止选择1970年以前的日期
                  );
                },
              },
              //新增转仓的弹窗
              rollVisible:false,
                isUsePost:true,
                // queryForm:{
                //   createTimes:[],
                //   originatingWarehouseTimes:[],
                // },
                columns: [
                    {
                        type: "selection",
                    },
                    {
                        prop: "action",
                        label: "操作",
                    },
                    {
                        prop: "driverInfo",
                        label: "车牌/司机/电话",
                    },
                    {
                      prop: "takeFee",
                      label: "运费",
                    },
                  {
                    prop: "takeFeeStr",
                    label: "运费分摊",
                  },
                    {
                        prop: "rollNo",
                        label: "用车编号",
                    },
                  {
                    prop: "orderNo",
                    label: "订单号-件重体",
                    minWidth: 200
                  },

                  {
                    prop: "ctnStr",
                    label: "件重体",
                  },
                  {
                    prop: "originatingWarehouseName",
                    label: "起运仓库",
                  },
                  {
                    prop: "originatingWarehouseTime",
                    label: "起运仓时间",
                  },
                  {
                    prop: "exitWarehouseTime",
                    label: "出仓时间",
                    minWidth:160,
                  },
                  {
                    prop: "arriveAtWarehouseName",
                    label: "到达仓库",
                  },
                  {
                    prop: "arriveAtWarehouseTime",
                    label: "到达仓时间",
                  },
                  {
                    prop: "actualArrivalTime",
                    label: "实际到仓时间",
                    minWidth:160,
                  },
                  {
                    prop: "remark",
                    label: "备注",
                  },
                  {
                    prop: "createBy",
                    label: "发起人",
                  },
                  {
                    prop: "createTime",
                    label: "创建时间",
                  },

                ],
                url: {
                    //查询列表的接口
                    list: "/warehouse/takeDelivery/getWarehouseRollIPage",
                    cancelRollBatch: "/warehouse/takeDelivery/cancelRollBatch",
                    getZCRecord:'/file/jasper/getZCRecord',
                     exportXlsUrl: "/file/jasper/getZCRecord",
                },
                selectedzcRow:{}
        

            };
        },
        methods: {
          closezcDialog(){
            this.zcOrderDetailVisible = false;
            this.handleQuery();
          },
          //打开新增转仓的弹窗
          openzcDialog(id,row){
            this.selectedzcRow=row
            this.zcOrderDetailVisible = true;
            this.selectedzcId = id;
           
          },
          //关闭新增转仓的弹窗
          closeRollDialog(){
            this.rollVisible = false;
            this.handleQuery();
          },
          //打开新增转仓的弹窗
          openRollDialog(){
            this.rollVisible = true;
          },
          //取消
          cancelRoll(row,rows){
            let list = [];
            if (row) {
              list.push(row.id);
            }
            if (rows && rows.length > 0) {
              list = rows;
            }
            this.$confirm(`确定取消`, "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "success",
              closeOnClickModal: false,
            }).then(()=>{
              postAction(this.url.cancelRollBatch,list)
              .then(res=>{
                this.$message.success(res.message);
                this.handleQuery();
              })
            })
          },
          handleExportXlsS(row){
            this.queryForm.takeId=row.id
             this.exportExcel('转仓单')
          }
        },
      computed:{
        buttonShowList() {
          return {
            'dc':this.$btnIsShow('warehouse2.0/WarehouseRoll','0','导出转仓单'),
            'qx':this.$btnIsShow('warehouse2.0/WarehouseRoll','0','取消'),
            'add':this.$btnIsShow('warehouse2.0/WarehouseRoll','0','新增转仓'),
          }
        }
      }
    };
</script>

<style lang="scss" scoped>
    .bbb {
        display: flex;
    }

    .dialog-footer {
        text-align: right !important;
    }

    .margin_right_10 {
        margin-right: 10px;
    }
</style>
