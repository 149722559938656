<template>
    <div class="main-full-content">
        <!-- <el-divider content-position="left"></el-divider> -->
        <el-tabs tab-position="left" @tab-click="handleClick">
            <el-tab-pane
                    v-for="(tab, index) in tabs"
                    :key="index"
                    :label="tab.label"
                    :lazy="true">
                <component  :is="tab.component" v-if="tab.show"></component>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
    import waitApplyPay from './modules/pay/waitApplyPay'
    import waitVerify from "./modules/pay/waitVerify";
    import waitMerge from "./modules/pay/waitMerge";
    import waitPay from "./modules/pay/waitPay";
    import waitRemove from "./modules/pay/waitRemove";
    import payRemoved from "./modules/pay/payRemoved";

    export default {
        name: 'PayMoney',
        components: {
            waitApplyPay,
            waitVerify,
            waitMerge,
            waitPay,
            waitRemove,
            payRemoved,
        },
        data() {
            return {
                tabs: [
                    { label: '待申请付款', component: 'waitApplyPay', show: true },
                    { label: '待审批', component: 'waitVerify', show: true },
                    { label: '待合并', component: 'waitMerge', show: true },
                    { label: '待付款', component: 'waitPay', show: true },
                    { label: '待销账', component: 'waitRemove', show: true },
                    { label: '已销账', component: 'payRemoved', show: true },
                ],
                //将页面的id传递至下一层
                pageId:'',
                url:{
                    getById:'/customer/getById',
                },
                //是否显示
                isShow: !!this.$route.query.id,
                parentId:'',
            }
        },
        methods:{
            initPageid() {
                var id = getUrlKey('id');
                if (id == null || id == '' || id == 'undefined'){
                    this.pageId = '';
                }else {
                    this.pageId = id;
                    //根据id获取客户名字
                    getAction(this.url.getById,{id:id})
                        .then(res=>{
                            if (res.success){
                                this.userName = res.result.name;
                                this.parentId = res.result.parentId;
                            }
                        })
                }
            },
            handleClick(tab){
                console.log('点击选中的tab',tab);

                this.tabs.map(t=>{
                    if (t.label !== tab.label){
                        t.show = false;
                    }else {
                        t.show = true;
                    }
                })
            },
        },
        created() {
            // this.initPageid();

        }
    }
</script>

<style lang="scss" scoped>
    .el-divider--horizontal {
        margin-top: 16px;
        margin-bottom: 20px;
        .el-divider__text {
            font-size: 20px;
        }
    }
</style>
