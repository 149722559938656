<!--渠道设置-->
<template>
    <div class="main-full-content">
        <el-form class="input_top" ref="form" :model="queryForm" label-width="120px" size="medium" @submit.native.prevent>
            <el-card class="box-card">
                <el-row>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="说明名称">
                            <el-input v-model="queryForm.priceExplainName" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="3">
                        <el-form-item label-width="30px">
                            <el-button
                                    type="primary"
                                    icon="el-icon-search"
                                    @click="handleSearch"
                            >查询
                            </el-button
                            >
                            <el-button plain @click="handleReset">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>
        </el-form>
        <el-card class="box-card last_card">
            <div class="f-p-b-15">
                <el-button
                        type="primary"
                        size="medium"
                        icon="el-icon-plus"
                        @click="handleAdd"

                        v-if=buttonShowList.add"
                >新增
                </el-button
                >
            </div>
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="66vh"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange"
            >
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'action'">
                        <el-button type="text" @click="handleEdit(scope.row)"

                                   v-if="buttonShowList.edt=it"
                        >编辑
                        </el-button
                        >
                        <el-button type="text" @click="handleDele(scope.row)"

                                   v-if="buttonShowList.del"
                        >删除
                        </el-button
                        >
                    </template>
                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                >
                </el-pagination>
            </el-row>
        </el-card>
        <el-dialog
                :title="form.id ? '编辑报价说明' : '新增报价说明'"
                :visible.sync="dialogFormVisible"
                :close-on-click-modal="false"
                v-if="dialogFormVisible"
        >
            <el-form :model="form" :rules="rules" ref="form">
                <el-form-item
                        label="说明名称"
                        :label-width="formLabelWidth"
                        prop="priceExplainName"
                >
                    <el-input
                            v-model="form.priceExplainName"
                            autocomplete="off"
                    ></el-input>
                </el-form-item>
                <el-form-item
                        label="说明内容"
                        :label-width="formLabelWidth"
                        prop="priceExplainContent"
                >
                    <el-input
                            v-model="form.priceExplainContent"
                            autocomplete="off"
                    ></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="medium" @click="dialogFormVisible = false"
                >取 消
                </el-button
                >
                <el-button type="primary" size="medium" @click="handleSave"
                >确 定
                </el-button
                >
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {deleteAction, postAction, putAction} from "@/api/manage";
    import QueryMixins from "../../mixins/QueryMixins";

    export default {
        name: "Potential",
        mixins: [QueryMixins],
        data() {
            return {
                rules: {
                    priceExplainName: [
                        {required: true, message: "请输入说明名称", trigger: "change"},
                        {max: 50, message: "长度不能超过50", trigger: "change"},
                    ],
                    priceExplainContent: [
                        {required: true, message: "请输入说明内容", trigger: "change"},
                        {max: 255, message: "长度不能超过255", trigger: "change"},
                    ],
                },
                emailList: [],
                ids: [],
                row: {},
                form: {},
                formLabelWidth: "100px",
                //控制弹框显示
                dialogFormVisible: false,
                columns: [
                    {
                        prop: "action",
                        label: "操作",
                    },
                    {
                        prop: "priceExplainName",
                        label: "说明名称",
                        sortable: "custom",
                    },
                    {
                        prop: "priceExplainContent",
                        label: "说明内容",
                        sortable: "custom",
                    },
                ],
                url: {
                    list: "/sys/priceExplain/list",
                    add: "/sys/priceExplain/add",
                    edit: "/sys/priceExplain/edit",
                    getById: "/sys/priceExplain/queryById",
                    delete: "/sys/priceExplain/delete",
                },
            };
        },
        methods: {
            handleSelectionChange(selection) {
                // 多选
                let arr = [];
                this.emailList = [];
                for (let i = 0; i < selection.length; i++) {
                    arr.push(selection[i]["id"]);
                    this.emailList.push(selection[i]["email"]);
                }
                this.selectionRows = selection;
                this.selectedRowKeys = [...arr];
            },
            handleAdd() {
                this.form = {};
                this.dialogFormVisible = true;
            },
            handleEdit(row) {
                //将对象转为json字符串再转回对象
                //this.form = row; 复制的是指针 仍然指向这个对象row
                this.form = JSON.parse(JSON.stringify(row));
                this.dialogFormVisible = true;
            },
            handleSave() {
                this.$refs.form.validate((valid) => {
                    if (valid) {
                        //编辑
                        if (this.form.id) {
                            putAction(this.url.edit, this.form).then((res) => {
                                if (res.success) {
                                    this.$message.success(res.message);
                                    //关闭弹窗
                                    this.dialogFormVisible = false;
                                    this.handleQuery();
                                }
                            });
                        } else {
                            postAction(this.url.add, this.form).then((res) => {
                                if (res.success) {
                                    this.$message.success(res.message);
                                    //关闭弹窗
                                    this.dialogFormVisible = false;
                                    this.handleQuery();
                                }
                            });
                        }
                    }
                });
            },
            handleDele(row) {
                this.$confirm(`确定删除报价说明`, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "success",
                    closeOnClickModal: false,
                })
                    .then(() => {
                        deleteAction(this.url.delete, {id: row.id}).then((res) => {
                            this.$message({
                                type: "success",
                                message: res.message,
                            });
                            this.handleQuery();
                        });
                    })
                    .catch(() => {
                    });
            },
            handleReset() {
                this.queryForm = {};
                this.filterForm = {};
                this.ipagination.currentPage = 1;
                this.handleQuery();
            },
        },
        created() {
        },
      computed : {
        buttonShowList() {
          return {
            'add' : this.$btnIsShow('channel/PriceExplain','0','新增'),
            'edit' : this.$btnIsShow('channel/PriceExplain','1','编辑'),
            'del' : this.$btnIsShow('channel/PriceExplain','1','删除'),

          }
        }
      }
    };
</script>

<style lang="scss" scoped>
</style>
