<template>

    <div class="main-full-content">
        <el-form ref="queryForm" :model="queryForm" label-width="120px" size="medium">
            <el-card class="box-card">
                <el-row>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="仓库名称">
                            <f-select v-model="queryForm.warehouseId" :dict="'delivery_point'" :is-need="queryForm.warehouseId" :father-val="queryForm.warehouseId"></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="3">
                        <el-form-item label-width="30px" style="width: 400px">
                            <el-button size="medium" type="primary" icon="el-icon-search" @click="handleQuery">查询</el-button>
                            <!-- <el-divider direction="vertical"></el-divider> -->
                            <el-button size="medium" plain @click="handleReset">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>

        </el-form>
        <el-card class="box-card last_card">
                        <div class="f-p-b-15">
                            <el-button size="medium" type="primary" @click="handleEdit({})"

                                       v-if="buttonShowList.add"
                            >新增</el-button>
                        </div>
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="66vh"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange">
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'action'">
                        <el-button type="text" @click="handleEdit(scope.row)"

                                   v-if="buttonShowList.bj"
                        >编辑</el-button>
                      <el-button type="text" @click="handleDel(scope.row.id)"

                                 v-if="buttonShowList.del"
                      >删除</el-button>
                    </template>
                    <template v-else-if="scope.column.property === 'isUse'">
                        <span type="text" v-if="scope.row.isUse === '0'">启用</span>
                        <span type="text" v-if="scope.row.isUse === '1'">未启用</span>
                    </template>
                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange">
                </el-pagination>
            </el-row>
        </el-card>
        <warehouse-location-edit-dialog ref="editDialog" @ok="this.handleQuery"></warehouse-location-edit-dialog>
    </div>


</template>
<script>
    import QueryMixins from "../../mixins/QueryMixins"
    import {deleteAction, getAction, postAction} from "@/api/manage";
    import WarehouseLocationEditDialog from "./modules/WarehouseLocationEditDialog";


    export default {
        mixins: [QueryMixins],
        components: {
            WarehouseLocationEditDialog
        },
        data() {
            return {
                editStartOptions: {
                    disabledDate: time => {
                        if (!this.form.etime) {
                            // time = new Date(time.replace(/-/g, '/'));
                            return time.getTime() < new Date(1970 - 1 - 1).getTime();   //禁止选择1970年以前的日期
                        } else {
                            return time.getTime() > new Date(this.form.etime);
                        }
                    }
                },
                editStopOptions: {
                    disabledDate: time => {
                        // time = new Date(time.replace(/-/g, '/'));
                        return (
                            time.getTime() < new Date(this.form.stime) ||
                            time.getTime() < new Date(1970 - 1 - 1).getTime()    //禁止选择1970年以前的日期
                        );
                    }
                },
                selectedRowKeys: [],
                selectionRows: [],
                selectedNos:[],
                loading: false,
                form: {},
                columns: [
                    // {
                    //     type: 'selection',
                    //     width: 50
                    // },
                    {
                        prop: 'action',
                        label: '操作',
                    },
                    {
                        prop: 'warehouseLocationCode',
                        label: '库位编码',
                    },
                    {
                        prop: 'warehouse',
                        label: '所属仓库',
                    },
                    // {
                    //     prop: 'agent',
                    //     label: '库位',
                    // },
                    // {
                    //     prop: 'feename',
                    //     label: '库位编码',
                    // },
                    {
                        prop: 'warehouseLocationCapacity',
                        label: '总容量',
                    },
                    {
                        prop: 'locationDesc',
                        label: '库位描述',
                    },
                    {
                        prop: 'isUse',
                        label: '是否启用',

                    },
                  {
                    prop: 'createTime',
                    label: '创建时间',

                  },
                ],
                url: {
                    list: '/interface/warehouse/getFbaWarehouseLocationIPage',
                    delete: '/interface/warehouse/delFbaWarehouseLocationById',
                },

            };
        },
        computed: {
          buttonShowList() {
            return {
              'add':this.$btnIsShow('warehouse/warehouseLocationSetting','0','新增'),
              'bj':this.$btnIsShow('warehouse/warehouseLocationSetting','1','编辑'),

              'del':this.$btnIsShow('warehouse/warehouseLocationSetting','1','删除'),

            }
          }
        },
        mounted() {
            },
        created() {
        },
        methods: {


          handleDel(id) {
            this.$confirm("确定删除该库位吗?", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "error",
              closeOnClickModal: false,
            })
                .then(() => {
                  deleteAction(this.url.delete, { id }).then((res) => {
                    this.$message({
                      type: "success",
                      message: res.message,
                    });
                    localStorage.removeItem("dict:is_disabled");
                    this.handleQuery();
                  });
                })
                .catch(() => {});
          },
            handleEdit(row){
                this.$refs.editDialog.edit(row);
            },

        },
    };
</script>
<style scoped>
    .el-form-item {
        margin-bottom: 22px;
    }
</style>
