<template>
    <el-dialog
            :title="title"
            :visible="visible"
            :before-close="handleCancel"
            :close-on-click-modal="false"
    >

        <template slot="footer">
            <el-button type="primary" size="medium" @click="checkAddress">校验地址</el-button>
            <el-button @click="handleCancel" size="medium" style="margin-left: 15px;">取消</el-button>
            <el-button type="primary" size="medium" style="margin-left: 15px;" @click="handleOk">确定
            </el-button>
        </template>


        <el-form :model="form" label-width="100px" ref="form" :rules="validatorRules" v-loading="confirmLoading">
            <!-- 主表单区域 -->
            <el-form-item
                    :labelCol="labelCol"
                    :wrapperCol="wrapperCol"
                    label="fba地址"
            >
                <el-input
                        type="textarea"
                        autosize
                        v-model="form.fbaAddress"
                        :auto-size="{ minRows: 3, maxRows: 5 }"
                />
            </el-form-item>
            <el-form-item
                    :labelCol="labelCol"
                    :wrapperCol="wrapperCol"
                    label="公司或名字"
                    prop="username">
                <!--                <el-auto-complete-->
                <!--                        :data-source="dataSource"-->
                <!--                        style="width: 200px"-->
                <!--                        @search="handleSearch"-->
                <!--                        @select="onSelect"-->
                <!--                        v-model="form.username"-->
                <!--                >-->
                <!--                    <el-input-->
                <!--                            placeholder="请输入地址库地址"-->
                <!--                            class="custom"-->
                <!--                            style="height: 50px"-->
                <!--                            @keypress="handleKeyPress"-->
                <!--                    />-->
                <!--                </el-auto-complete>-->
                <el-select clearable filterable v-model="form.username" allow-create @change="updateAddress()"
                           autocomplete="on">
                    <el-option v-for="(item, index) in CheckAddressList"
                               :label="item.userName"
                               :value="item.userName"
                               :index="item.userName"
                               :key="index"
                    >
                        {{item.userName}}
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item
                    :labelCol="labelCol"
                    :wrapperCol="wrapperCol"
                    label="接收方姓名"
            >
                <el-input
                        placeholder="请输入接收方姓名"
                        v-model="form.receiveName"
                />
            </el-form-item>
            <el-form-item
                    :labelCol="labelCol"
                    :wrapperCol="wrapperCol"
                    label="地址行1"
                    prop="address1">
                <el-input
                        placeholder="请输入地址行1"
                        v-model="form.address1"
                ></el-input>
            </el-form-item>
            <el-form-item
                    :labelCol="labelCol"
                    :wrapperCol="wrapperCol"
                    label="地址行2"
            >
                <el-input
                        placeholder="请输入地址行2"
                        v-model="form.address2"
                />
            </el-form-item>
            <el-form-item
                    :labelCol="labelCol"
                    :wrapperCol="wrapperCol"
                    label="电话"
                    prop="telephone"
            >
                <el-input
                        placeholder="请输入电话"
                        v-model="form.phone"
                />
            </el-form-item>
            <el-form-item
                    :labelCol="labelCol"
                    :wrapperCol="wrapperCol"
                    label="国家"
                    prop="country">
                <f-select placeholder="请选择国家"
                          :dict="'ups_country'"
                          @change="handleChange1"
                          filterable
                          v-model="form.country"
                          :fatherVal="form.country"
                          :isNeed="form.country"
                >
                </f-select>
            </el-form-item>
            <el-form-item
                    :labelCol="labelCol"
                    :wrapperCol="wrapperCol"
                    label="邮编"
                    prop="zip"
            >
                <!--                <el-auto-complete-->
                <!--                        :data-source="zipDataSource"-->
                <!--                        style="width: 200px"-->
                <!--                        @search="handleSearchZip"-->
                <!--                        @select="onSelectZip"-->
                <!--                        v-model="form.zip"-->
                <!--                >-->
                <!--                    <el-input-->
                <!--                            placeholder="请输入邮编"-->
                <!--                    />-->
                <!--                </el-auto-complete>-->
                <el-select clearable filterable allow-create v-model="form.zip">
                    <el-option v-for="(item, index) in zipMapList"
                               :index="item"
                               :label="item"
                               :value="item"
                               :key="index"
                    >

                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item
                    :labelCol="labelCol"
                    :wrapperCol="wrapperCol"
                    label="城市"
                    prop="city"
            >
                <!--                <el-auto-complete-->
                <!--                        :data-source="cityDataSource"-->
                <!--                        style="width: 200px"-->
                <!--                        @search="handleSearchCity"-->
                <!--                        v-model="form.city"-->
                <!--                >-->
                <!--                    <a-input-->
                <!--                            placeholder="请输入城市"-->
                <!--                    />-->
                <!--                </el-auto-complete>-->
                <el-select clearable filterable allow-create v-model="form.city">
                    <el-option v-for="(item, index) in cityMapList"
                               :index="item"
                               :label="item"
                               :value="item"
                               :key="index"
                    >

                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item
                    :labelCol="labelCol"
                    :wrapperCol="wrapperCol"
                    label="州/县"
                    prop="state"
            >
                <!--                <el-auto-complete-->
                <!--                        :data-source="stateDataSource"-->
                <!--                        style="width: 200px"-->
                <!--                        @search="handleSearchState"-->
                <!--                        v-model="form.state"-->
                <!--                >-->
                <!--                    <a-input-->
                <!--                            placeholder="请输入州/县"-->
                <!--                    />-->
                <!--                </el-auto-complete>-->
                <el-select clearable filterable allow-create v-model="form.state">
                    <el-option v-for="(item, index) in stateMapList"
                               :index="item"
                               :label="item"
                               :value="item"
                               :key="index"
                    >

                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item
                    :labelCol="labelCol"
                    :wrapperCol="wrapperCol"
                    label="建议地址"
                    v-if="deftshipStatus == 1"
            >
                <el-input type="textarea"
                        v-model="deftshipContent"
                        :auto-size="{ minRows: 2, maxRows: 4 }"
                />
            </el-form-item>
            <el-form-item :labelCol="labelCol"
                          :wrapperCol="wrapperCol">
                <el-button type="primary" v-if="deftshipStatus == 1"
                           @click="useDeftshipAddress">使用建议地址
                </el-button>
            </el-form-item>
        </el-form>
    </el-dialog>

</template>

<script>
    import {deleteAction, getAction, getallusers, postAction, putAction, httpAction} from '@/api/manage'
    import {addressEnum, checkAddress} from "../api/Ups";
    import pick from 'lodash/pick'
    import { setUpRouteUtil } from '@/utils/util';


    export default {
        name: "upsPushAddress",
        components: {},
        data() {
            return {
                title: "操作",
                visible: false,
                orderMainModel: {
                    jeecgOrderCustomerList: [{}],
                    jeecgOrderTicketList: [{}]
                },
                labelCol: {
                    xs: {span: 24},
                    sm: {span: 5},
                },
                wrapperCol: {
                    xs: {span: 24},
                    sm: {span: 16},
                },
                dataSource: [],
                zipDataSource: [],
                cityDataSource: [],
                stateDataSource: [],
                usernameValue: '',
                confirmLoading: false,
                form: {
                    zip: '',
                    country: '',
                    cargoArrivedType: '',
                    orderNo: '',
                    addressId:'',
                    address1: '',
                    address2: '',
                    transportStatus: '',
                    city: '',
                    operateId: '',
                    solicitation: '',
                    solicitation_dictText: '',
                    enLogno: '',
                    phone: '',
                    lb: '',
                    fbaAddress: '',
                    id: '',
                    state: '',
                    ctn: '',
                    operateName: null,
                    orderNoList: null,
                    upsShipStatus: null,
                    transportStatus_dictText: '',
                    username: '',
                    fbaCode: ''


                },
                address: [],
                selectData: [],
                Data: [],
                email: String,
                dict: String,
                orderNo: String,
                dictOptions: Array,
                selectedValue: String,
                fbaAddress: '',
                CheckAddressMap: [],
                CheckAddressList: [],
                zipMapList: [], //zip筛选后的数据
                cityMapList: [],
                stateMapList: [],
                deftshipStatus: 0,
                useDeftshipAddressData: [],
                deftshipContent: null,
                validatorRules: {
                    username: [
                        {required: true, message: '公司或名字不可为空', trigger: 'blur'},
                    ],
                    country: [
                        {required: true, message: '国家不可为空', trigger: 'blur'},
                        {pattern: /^[a-zA-Z]+$/, message: '国家只能输入二字码，请重新输入', trigger: 'change'}
                    ],
                    state: [
                        {required: true, message: '请选择州/省', trigger: 'blur'},
                    ],
                    city: [
                        {required: true, message: '城市不可为空', trigger: 'blur'},
                    ],
                    zip: [
                        {required: true, message: '邮编不可为空', trigger: 'blur'},
                    ],
                    address1: [
                        {required: true, message: '地址栏1不可为空', trigger: 'blur'},
                    ],

                },
                url: {
                    add: "/test/order/add",
                    edit: "/test/order/edit",
                    orderCustomerList: "/test/order/listOrderCustomerByMainId",
                    orderTicketList: "/test/order/listOrderTicketByMainId",
                    saveOrderAddress: '/interface/label/saveOrderAddress',
                    selecAddressList: '/interface/label/selecAddressList',
                    selecCheckAddress: '/interface/label/selecCheckAddress',
                    DeftShipCheckAddress: '/interface/label/DeftShipCheckAddress2'
                },
                tempAddress:'',
            }
        },
        created() {

        },
        methods: {
            intoDocpage(orderNo) {
                // 对于路由不在左侧菜单中的选中菜单处理
                setUpRouteUtil(this.$route.path, '/docpage/Edit');
                this.$router.push("/docpage/Edit?orderNo=" + orderNo);

            },
            validateCountry(rule, value, callback) {
                if (!value || new RegExp(/^[a-zA-Z]+$/).test(value)) {
                    callback();
                } else {
                    callback("国家只能输入二字码，请重新输入。");
                }
            },
            initCheckAddressList() {
                let that = this;
                let url = this.url.selecCheckAddress, method = 'post'.toString()
                postAction(url.toString(), {}).then((res) => {
                    if (res.success) {
                        var map = new Map();
                        for (var i = 0; i < res.result.list.length; i++) {
                            that.dataSource.push(res.result.list[i].userName)
                            map.set(res.result.list[i].userName, res.result.list[i])
                        }
                        that.CheckAddressMap = map;
                        that.CheckAddressList = res.result.list;
                        // cons
                    }
                })
            },

            handleSearch(value) {
                let that = this;
                that.dataSource = []
                for (let i = 0; i < that.CheckAddressList.length; i++) {
                    if (that.CheckAddressList[i].userName.indexOf(value) != -1) {
                        that.dataSource.push(that.CheckAddressList[i].userName)
                    }
                }

            },
            handleChange1(country) {
                let that = this;
                //如果字典项集合有数据
                addressEnum(country).then(res => {
                    if (res.success) {

                        var zipMap = new Map();
                        var cityMap = new Map();
                        var stateMap = new Map();

                        for (var i = 0; i < res.result.list.length; i++) {
                            if (!zipMap.has(res.result.list[i].zipText)) {
                                that.zipDataSource.push(res.result.list[i].zipText)
                                that.zipMapList.push(res.result.list[i].zipText)
                                zipMap.set(res.result.list[i].zipText, res.result.list[i].zipText)
                            }
                            //
                            if (!cityMap.has(res.result.list[i].cityText)) {
                                that.cityDataSource.push(res.result.list[i].cityText)
                                that.cityMapList.push(res.result.list[i].cityText)
                                cityMap.set(res.result.list[i].cityText, res.result.list[i].cityText)
                            }
                            if (!stateMap.has(res.result.list[i].stateText)) {
                                that.stateDataSource.push(res.result.list[i].stateText)
                                that.stateMapList.push(res.result.list[i].stateText)
                                stateMap.set(res.result.list[i].stateText, res.result.list[i].stateText)
                            }
                        }
                        this.Data = res.result.list
                    }
                })
            },
            add() {
                this.edit({});
            },
            edit(record) {
                const row = {...record}

                // console.log('row record',row)
                // this.form.addressId = row.addressId
                // this.form.fbaCode = row.fbaCode
                // this.form.username = row.username
                // this.form.phone = row.phone
                // this.form.country = row.country
                // this.$set(this.form,'address1',row.address)
                // this.form.zip = row.zip
                // this.form.city = row.city
                // this.form.state = row.state
                //
                let that = this;
                // //Amazon.com+ 地址+城市+州+邮编 + 国家二字码+ （fba编码）

                if(row.shipmentType === '0'){
                    that.form.fbaAddress = 'Amazon.com,' +
                        row.address +
                        ","+ (row.city===null?'':row.city) +
                        ","+ (row.state===null?'':row.state) +
                        ","+ (row.zip===null?'':row.zip)+
                        ","+ (row.country===null?'':row.country) +
                        "("+ (row.fbaCode===null?'':row.fbaCode)+")"
                }else{
                    that.form.fbaAddress = row.fbaAddress
                }

                that.form.orderNo = row.orderNo;

                that.dataSource = []
                that.CheckAddressMap = []
                that.CheckAddressList = []
                let url = this.url.selecAddressList, method = 'POST'
                postAction(url, {'orderNo': record.orderNo}).then((res) => {
                    console.log('selecAddressList',res)
                    if (res.success) {
                        if (res.result.list.length > 0) {
                            this.orderMainModel = Object.assign({}, res.result.list[0])
                            this.orderMainModel.fbaCode = row.fbaCode
                            this.orderMainModel.address = res.result.list[0].fbaAddress
                            this.form.address1 = this.orderMainModel.address1
                            this.form.address2 = this.orderMainModel.address2
                        } else {
                            that.orderMainModel = Object.assign({}, record);
                            let address = that.orderMainModel.address;
                            if (that.orderMainModel && that.orderMainModel.address && that.orderMainModel.address.length > 13) {  // 当地址行长度介于 大于13或者小于13时对地址的处理

                                that.form.address1 = address.substring(0, 13)
                                that.form.address2 = address.substring(13, address.length)
                            } else {
                                that.form.address1 = address
                            }
                        }
                    }

                    //初始化明细表数据
                    that.visible = true;
                    that.$nextTick(() => {
                        // this.$refs["form"].resetFields();
                        //判断是否国家为空，为空默认值为美国，并都初始化
                        if (that.orderMainModel.country != null) {
                        } else {
                            that.orderMainModel.country = 'US'
                        }
                        //初始化邮编城市，州/县
                        that.handleChange1(that.orderMainModel.country)
                        // if (this.orderMainModel.address !== null && this.orderMainModel.address1 ===null) { //判断地址是否为空
                        //     let address = that.orderMainModel.address;
                        //     // if (that.orderMainModel.city != null) {  //如果城市不为空，开始截取地址在该城市之前的所有字符
                        //     //     address = that.orderMainModel.address.substring(0, that.orderMainModel.address.indexOf(that.orderMainModel.city));
                        //     // }
                        //     // that.form.fbaAddress = (row.fbaCode===null?'':row.fbaCode) +
                        //     //     "\n" +row.address +
                        //     //     "\n"+ (row.city===null?'':row.city) +
                        //     //     "\n"+ (row.state===null?'':row.state) +
                        //     //     "\n"+ (row.country===null?'':row.country) +
                        //     //     "\n"+ (row.zip===null?'':row.zip)
                        //
                        //     if (that.orderMainModel.address.length > 13) {  // 当地址行长度介于 大于13或者小于13时对地址的处理
                        //
                        //         that.form.address1 = address.substring(0, 13)
                        //         that.form.address2 = address.substring(13, address.length)
                        //     } else {
                        //         that.form.address1 = address
                        //     }
                        // }else{
                        //     this.form.address1 = this.orderMainModel.address1
                        //     this.form.address2 = this.orderMainModel.address2
                        // }
                        console.log('this.orderMainModel',this.orderMainModel)
                        // 上面已经对地址1 2赋值了，此处不用再赋值
                        this.form.username = this.orderMainModel.username
                        this.form.phone = this.orderMainModel.phone
                        this.form.country = this.orderMainModel.country
                        this.form.zip = this.orderMainModel.zip
                        this.form.city = this.orderMainModel.city
                        this.form.state = this.orderMainModel.state

                        this.form.receiveName = this.orderMainModel.receiveName
                        // that.form = that.orderMainModel
                    });


                    this.initCheckAddressList();

                })
            },
            close() {
                this.$emit('close');
                this.useDeftshipAddressData = [];
                this.deftshipStatus = 0;
                this.visible = false;
            },
            useDeftshipAddress() {
                let that = this;
                if (that.useDeftshipAddressData != null) {
/*                    let ob = JSON.parse(that.useDeftshipAddressData);

                    that.orderMainModel.address1 = ob.address.addressLine
                    that.orderMainModel.address2 = ob.address.addressLine2
                    that.orderMainModel.zip = ob.address.postcodePrimaryLow + "-" + ob.address.postcodeExtendedLow
                    that.orderMainModel.country = ob.address.countryCode
                    that.orderMainModel.state = ob.address.politicalDivision1
                    that.orderMainModel.city = ob.address.politicalDivision2

                    // that.form.setFieldsValue(pick(that.orderMainModel, 'address1', 'address2', 'zip', 'country', 'state', 'city'))
                    // this.form.username = this.orderMainModel.username
                    // this.form.phone = this.orderMainModel.phone
                    this.form.country = this.orderMainModel.country
                    this.form.address1 = this.orderMainModel.address1
                    this.form.address2 = this.orderMainModel.address2
                    this.form.zip = this.orderMainModel.zip
                    this.form.city = this.orderMainModel.city
                    this.form.state = this.orderMainModel.state*/
                    let that = this ;
                    if(that.useDeftshipAddressData !=null){
                    let ob= that.useDeftshipAddressData;

                    console.log(ob.street_1)
                    console.log(ob.street_2)
                    console.log(ob.zip)
                    console.log(ob.country)
                    console.log(ob.state)
                    console.log(ob.city)
                    that.orderMainModel.address1 = ob.street_1
                    that.orderMainModel.address2 = ob.street_2
                    that.orderMainModel.zip = ob.zip
                    that.orderMainModel.country = ob.country
                    that.orderMainModel.state = ob.state
                    that.orderMainModel.city = ob.city

                   that.form.country = this.orderMainModel.country
                   that.form.address1 = this.orderMainModel.address1
                   that.form.address2 = this.orderMainModel.address2
                   that.form.zip = this.orderMainModel.zip
                   that.form.city = this.orderMainModel.city
                   that.form.state = this.orderMainModel.state
                    //that.form.setFieldsValue(pick(that.orderMainModel, 'address1', 'address2', 'zip', 'country','state','city'))

                  }

                }
            },
            checkAddress() {
                this.$refs['form'].validate((err, values) => {
                    if (err) {
                        if (this.form.zip.indexOf(",") != -1) {
                            this.form.zip = this.form.zip.substring(this.form.zip.indexOf(",") + 1, this.form.zip.length)
                        }
                        if (this.form.city.indexOf(",") != -1) {
                            this.form.city = this.form.city.replace(",", "")
                        }
                        if (this.form.state.indexOf(",") != -1) {
                            this.form.state = this.form.state.substring(this.form.state.indexOf(",") + 1, this.form.state.length)
                        }
                        let url = this.url.DeftShipCheckAddress, method = 'post'
                        this.confirmLoading = true

                        postAction(url, this.form,{timeout: 1000 * 60 * 2}).then((res) => {

                            if (res.success) {
                                console.log(res)
                                this.useDeftshipAddressData = res.result
                                let ob= res.result;
                                console.log(ob)
                                //let ob = JSON.parse(res.result);
                                if (ob.type == 'no_candidates') {
                                    this.$message.success("该地址暂无推荐。")
                                } else {
                                    if (ob.street_1 != null) {
                                        this.deftshipStatus = 1
                                      //this.deftshipContent = ob.address.addressLine + "， " + ob.address.region + "，  " + ob.address.countryCode
                                      this.deftshipContent = ob.street_1 +", " + ob.street_2 +", "+ob.zip + ", " +
                                          ob.city +", " + ob.state +", "+ob.country
                                    }
                                  /*  if (ob.candidates != null) {
                                        this.deftshipStatus = 1
                                        this.deftshipContent = ob.candidates[0].addressLine + "， " + ob.candidates[0].region + "，  " + ob.candidates[0].countryCode
                                    }*/

                                }
                                 this.$message.success("操作成功")
                            }else {
                              this.$message.error(res.message)
                            }
                        }).finally(() => {
                            this.confirmLoading = false

                        })
                    }
                })
            },

            handleOk() {
                const that = this;

                // 触发表单验证

                this.$refs['form'].validate((err, values) => {
                    if (err) {
                        if (that.form.zip.indexOf(",") != -1) {
                            that.form.zip = that.form.zip.substring(that.form.zip.indexOf(",") + 1, that.form.zip.length)
                        }
                        if (that.form.city.indexOf(",") != -1) {
                            that.form.city = that.form.city.substring(that.form.city.indexOf(",") + 1, that.form.city.length)
                        }
                        if (that.form.state.indexOf(",") != -1) {
                            that.form.state = that.form.state.substring(that.form.state.indexOf(",") + 1, that.form.state.length)
                        }

                        that.confirmLoading = true;
                        let orderMainData = this.form;

                        let url = this.url.saveOrderAddress, method = 'post'
                        this.confirmLoading = true

                        values.orderNo = this.orderNo
                        values.checkType = "2"

                        console.log(this.form)
                        postAction(url, that.form).then((res) => {
                            if (res.success) {
                                this.$message.success(res.message)
                                this.$emit('ok')
                                this.close()
                            }
                        }).finally(() => {
                            this.confirmLoading = false

                        })

                    }
                })

            },
            handleCancel() {
                this.close()
            },
            updateAddress(username) {
                let address = this.CheckAddressList.filter(item => item.userName === this.form.username)[0];
                if (address !== undefined) {
                    this.form.state = address.state;
                    this.form.city = address.city;
                    this.form.zip = address.postCode;
                    this.form.phone = address.telephone
                    this.form.address1 = address.address1

                }


            },
        }
    }
</script>

<style scoped>
    .ant-btn {
        padding: 0 10px;
        margin-left: 3px;
    }

    .ant-form-item-control {
        line-height: 0px;
    }

    /** 主表单行间距 */
    .ant-form .ant-form-item {
        margin-bottom: 10px;
    }

    /** Tab页面行间距 */
    .ant-tabs-content .ant-form-item {
        margin-bottom: 0px;
    }
</style>
