<template>
  <el-dialog
      title="预览"
      :visible="visible"
      width="50%"
      :before-close="handleClose"
      @close="handleClose"
  >
    <div class="addEditBox">
      <div class="top">
        <h4 >标题：{{form.title}}</h4>
        <div >
          <!-- style="text-align: center"  class="content preview_content" <span style="display: inline">发布端口：{{formatPushport(form.pushPort)}}</span>&nbsp;&nbsp;-->
          <span >发布版本：{{form.version}}</span>&nbsp;&nbsp;
          <span >{{form.createTime}}</span>
        </div>
      </div>
      <div  v-html="form.text">

      </div>
    </div>
  </el-dialog>
</template>
<script>
export default {
  name:'StorePublishPreview',
  props:{
    form:{
      type:Object
    },
    visible: {
      type: Boolean
    }

  },
  data(){
    return{

    }
  },
  methods:{
    format(val){
      return val.replace("\\","");
    },
    formatPushport(val){
      if (val == 0){
        return 'PC后台';
      }else if (val =='1'){
        return 'PC客户端';
      }else if (val == '2'){
        return 'Applets后台';
      }else {
        return 'Applets客户端'
      }
    },
    handleClose() {
      this.$emit('update:visible', false);

    }
  }
}
</script>
<style scoped>
img{
  width:860px
}
p{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.addEditBox {
  max-height: 625px;
  overflow: scroll;
}
</style>
