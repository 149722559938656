<template>

    <div class="main-full-content">
        <el-form ref="form" :model="queryForm" label-width="120px" size="medium">
            <el-card class="box-card">
                <el-row>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="仓库名">
<!--                            <el-input v-model="queryForm.orderNo"></el-input>-->
                                <f-select v-model="queryForm.warehouse" dict="warehouse_name"></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="3">
                        <el-form-item label-width="30px" style="width: 400px">
                            <el-button size="medium" type="primary" icon="el-icon-search" @click="handleQuery">查询</el-button>
                            <!-- <el-divider direction="vertical"></el-divider> -->
                            <el-button size="medium" plain @click="handleReset">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>

        </el-form>
        <el-card class="box-card last_card">
            <!--            <div class="f-p-b-15">-->
            <!--                <el-button size="medium" type="primary" @click="noConvertBill">非折合账单</el-button>-->
            <!--                <el-button size="medium" type="primary" @click="convertBill">折合账单</el-button>-->
            <!--            </div>-->
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="72vh"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange">

            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange">
                </el-pagination>
            </el-row>
        </el-card>

    </div>


</template>
<script>
    import QueryMixins from "../../mixins/QueryMixins"
    import {getAction} from "@/api/manage";


    export default {
        mixins: [QueryMixins],
        components: {

        },
        data() {
            return {
                dictCodes:['warehouse_name'],
                editStartOptions: {
                    disabledDate: time => {
                        if (!this.form.etime) {
                            // time = new Date(time.replace(/-/g, '/'));
                            return time.getTime() < new Date(1970 - 1 - 1).getTime();   //禁止选择1970年以前的日期
                        } else {
                            return time.getTime() > new Date(this.form.etime);
                        }
                    }
                },
                editStopOptions: {
                    disabledDate: time => {
                        // time = new Date(time.replace(/-/g, '/'));
                        return (
                            time.getTime() < new Date(this.form.stime) ||
                            time.getTime() < new Date(1970 - 1 - 1).getTime()    //禁止选择1970年以前的日期
                        );
                    }
                },
                selectedRowKeys: [],
                selectionRows: [],
                selectedNos:[],
                loading: false,
                form: {},
                columns: [
                    // {
                    //     type: 'selection',
                    //     width: 50
                    // },
                    {
                        prop: 'warehouse',
                        label: '仓库名',
                    },
                    {
                        prop: 'orderNums',
                        label: '出库总订单',
                    },
                    {
                        prop: 'cmb',
                        label: '出库总体积',
                    },
                    {
                        prop: 'ctn',
                        label: '出库总件数/卡板',
                    },
                ],

                url: {
                    // list: '/shipping/warehouseCount/list',
                    list: '/interface/warehouse/getFbaWarehouseOutCountIPage',
                },

            };
        },
        computed: {

        },
        created() {
        },
        methods: {

            handleReset() {
                this.queryForm = {
                    ckczfx:'0'
                }
                this.otime = ''
                this.stime = ''
                this.ipagination.currentPage = 1
                this.handleQuery()
            },

        },
    };
</script>
<style scoped>
    .el-form-item {
        margin-bottom: 22px;
    }
</style>
