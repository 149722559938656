<template>
    <el-dialog
        :title="'批量查账'"
        :visible="visible"
        width="1080px"
        custom-class="c-custom-dialog"
        :close-on-click-modal="false"
        :destroy-on-close="true"
        :before-close="handleClose">
        <el-card class="main-full-content box-card">
            <div class="f-p-b-15">
                <el-button @click="handleSubmit" type="primary"
                           v-if="buttonShowList.bc" size="medium">
                    保存
                </el-button>
            </div>

            <el-form :model="billBatchForm" ref="billBatchForm" :rules="rules">
                <el-table
                    :header-cell-style="{ 'text-align': 'center' }"
                    :cell-style="{padding:'0px',margin:'0px'}"
                    ref="goodtab"
                    :data="billBatchForm.agentPayList"
                    style="width: 99%"
                    class="customTable"
                    v-loading="goodsloading"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange"
                >
                    <el-table-column
                        type="selection"
                        width="30">
                    </el-table-column>
                    <el-table-column

                        prop="applyNo"
                        label="申请单号"
                        minWidth="60"
                        :show-overflow-tooltip="true"
                    >
                        <!-- 标头需要加必填标识 -->
                        <template slot="header">
                            <template>
                                <span class="is_require_form">申请单号</span>
                            </template>
                        </template>
                        <template slot-scope="scope">
                            <el-form-item class="el_form_item" :prop="'agentPayList.' + scope.$index + '.applyNo'"
                                          :rules="rules.applyNo">
                                <span>{{ scope.row.applyNo }}</span>

                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="settleName"
                        label="结算对象"
                        minWidth="60"
                    >
                        <!-- 标头需要加必填标识 -->
                        <template slot="header">
                            <template>
                                <span class="is_require_form">结算对象</span>
                            </template>
                        </template>
                        <template slot-scope="scope">
                            <el-form-item class="el_form_item" :prop="'agentPayList.' + scope.$index + '.settleName'"
                                          :rules="rules.settleName">
                                <span>{{ scope.row.settleName}}</span>
                            </el-form-item>
                        </template>
                    </el-table-column>

                    <el-table-column
                        prop="salesMoney"
                        label="实际到账金额"
                        minWidth="66"
                    >
                        <!-- 标头需要加必填标识 -->
                        <template slot="header">
                            <template>
                                <span class="is_require_form">实际到账金额</span>
                            </template>
                        </template>
                        <template slot-scope="scope">
                            <el-form-item class="el_form_item" :prop="'agentPayList.' + scope.$index + '.salesMoney'"
                                          :rules="rules.salesMoney">
                                <el-input v-model="scope.row.salesMoney" type="number"></el-input>
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="salesMoneyCur"
                        label="实际到账币种"
                        minWidth="60"
                    >
                        <template slot-scope="scope">
                            <el-form-item class="el_form_item" :prop="'agentPayList.' + scope.$index + '.salesMoneyCur'"
                                          :rules="rules.salesMoneyCur">
                                <f-select
                                    v-model="scope.row.salesMoneyCur"
                                    :isNeed="scope.row.salesMoneyCur"
                                    :dict="'bd_cur'"
                                    placeholder="请选择折合币种"
                                    clearable
                                    filterable
                                    class="f-full-width"></f-select>
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="checkBillTime"
                        label="查账时间"
                        minWidth="100"

                    >
                        <template slot-scope="scope">
                            <el-form-item class="el_form_item" :prop="'agentPayList.' + scope.$index + '.checkBillTime'"
                                          :rules="rules.checkBillTime">
                                <el-date-picker
                                    class="f-flex-1"
                                    v-model="scope.row.checkBillTime"
                                    type="datetime"
                                    size="small"
                                    placeholder="请选择时间"
                                    default-time="00:00:00" value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss">
                                </el-date-picker>
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="fVoucherDate"
                        label="银行到账日期"
                        minWidth="100"

                    >
                        <template slot-scope="scope">
                            <el-form-item class="el_form_item" :prop="'agentPayList.' + scope.$index + '.fVoucherDate'"
                                          :rules="rules.fVoucherDate">
                                <el-date-picker
                                    class="f-flex-1"
                                    v-model="scope.row.fVoucherDate"
                                    type="datetime"
                                    size="small"
                                    placeholder="请选择时间"
                                    default-time="00:00:00" value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss">
                                </el-date-picker>
                            </el-form-item>
                        </template>
                    </el-table-column>

                </el-table>

            </el-form>
            <el-divider></el-divider>
        </el-card>
    </el-dialog>
</template>

<script>

import {getAction, postAction} from '@/api/manage'
import QueryMixins from "../../../../mixins/QueryMixins";
import moment from 'moment';


export default {
    name: "checkBillBatch",
    mixins: [QueryMixins],
    components: {},
    props: {},
    data() {
        return {
            fenzuList: [],
            dictCodes: ['sys_create_by'],
            billBatchForm: {agentPayList: []},
            url: {
                checkBillBatch: '/shipping/hySettleReceive/checkBillBatch',
            },

            //校验
            rules: {
                salesMoney: [
                    {required: true, message: "请确认实际到账金额", trigger: "change"},
                ],
                salesMoneyCur: [
                    {required: true, message: "请确认实际到账币种", trigger: "change"},
                ],
                checkBillTime: [
                    {required: true, message: "请输入查账时间", trigger: "change"},
                ],

            },
            copy: {},
            goodsloading: false,
            visible: false,

        }
    },

    methods: {
        edit(rows) {
            this.visible = true;
            let rowList = [];
            let nowDate = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
            for(let i = 0; i < rows.length; i++) {
                let obj = {};
                obj.id = rows[i].id;
                obj.applyNo = rows[i].applyNo;
                obj.settleName = rows[i].settleName;
                obj.salesMoney = rows[i].billMoney;
                obj.salesMoneyCur = rows[i].payCur;
                obj.checkBillTime = nowDate;
                obj.fVoucherDate = rows[i].fVoucherDate;
                rowList.push(obj);
            }
            this.billBatchForm.agentPayList = rowList;
        },

        handleQuery2() {
            this.goodsloading = true

        },

        handleSubmit() {
            this.$refs.billBatchForm.validate((valid) => {
                if (valid) {
                    this.$confirm(`请确认批量查账结果`, "提示", {
                        confirmButtonText: "确认",
                        cancelButtonText: "取消",
                        type: "success",
                        closeOnClickModal: false,
                    }).then(() => {
                        postAction(this.url.checkBillBatch, this.billBatchForm.agentPayList).then((res) => {
                            if(res.success){
                                this.handleClose();
                                this.$emit('ok')
                            }
                        }).catch(() => {
                        });

                    });


                }
            })


        },


        handleClose() {
            this.billBatchForm = {};
            this.billBatchForm.agentPayList = [];
            this.visible = false;
        },


    },
    created() {
    },
    computed:{
      buttonShowList() {
        return {
          'bc':this.$btnIsShow('hyFinanceManage/modules/receive/waitCheck','0','批量查账')
        }
      }
    }

}
</script>

<style lang="scss" scoped>
.span-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 90px;
    line-height: 100%;
}

.margin_5 {
    margin: 10px 20px 5px 0;
}
</style>
