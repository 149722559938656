<template>
    <div class="main-full-content">
        <el-dialog
            title="选择开票信息"
            :visible="invoiceInfoVisible"
            width="680px"
            custom-class="c-custom-dialog"
            :close-on-click-modal="false"
            :destroy-on-close="true"
            :before-close="invoiceInfoHandleClose"
        >
            <el-form>
                <el-form-item label="开票信息">
                    <el-select v-model="invoiceInfoOrderNo" style="width: 70%">
                        <el-option
                            v-for="(item, index) in invoiceInfoList"
                            :label="item.accountName"
                            :value="item.id"
                            :key="index"
                        >
                            {{item.bankName + '-' + item.accountName + '-' + item.account + '-' + item.cur }}
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <span slot="footer">
        <el-button
            type="primary"
            size="medium"
            @click="invoiceInfoHandleSubmit"
            :disabled="ifDis">
            确定申请开票
        </el-button>
      </span>
        </el-dialog>

        <el-form class="multiline_select" ref="form" :model="queryForm" label-width="80px" size="medium">
            <el-card class="box-card">
                <el-row class="inputs_row">
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="工作单号">
                            <el-input v-model="queryForm.orderNo" @keyup.enter.native="handleCheck" type="textarea" placeholder="多个用逗号或者空格分隔"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="客户名">
                            <el-input v-model="queryForm.username" @keyup.enter.native="handleCheck"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="结算对象">
                            <el-input v-model="queryForm.settlement" @keyup.enter.native="handleCheck" placeholder="支持模糊查询"></el-input>
                        </el-form-item>
                    </el-col>


                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="业务员">
                            <f-select
                                clearable
                                filterable
                                class="f-full-width"
                                :dict="'sys_user_id'"
                                v-model="queryForm.salesmanId"
                                :is-need="queryForm.salesmanId"
                                placeholder="请选择"
                                style="width: 100%"
                            >
                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="客服" label-width="80px">
                            <f-select
                                :dict="'sys_user_id'"
                                v-model="queryForm.docId"
                                :is-need="queryForm.docId"
                                placeholder="请选择"
                            ></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="收款人" label-width="80px">
                            <f-select
                                :dict="'sys_user_id'"
                                v-model="queryForm.billmanId"
                                :is-need="queryForm.billmanId"
                                placeholder="请选择"
                            ></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="操作员" label-width="80px">
                            <f-select
                                :dict="'sys_user_id'"
                                v-model="queryForm.operateId"
                                :is-need="queryForm.operateId"
                                placeholder="请选择"
                            ></f-select>
                        </el-form-item>
                    </el-col>

                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="海运付款方式" v-if="queryForm.orderType!=='2'" key="payTypeList">
                            <f-select
                                v-model="queryForm.payTypeList"
                                :is-need="queryForm.payTypeList"
                                dict="pay_type"
                                :multiple="true"
                            ></f-select>
                        </el-form-item>
                        <el-form-item label="快递付款方式" v-if="queryForm.orderType==='2'" key="kdPayType">
                            <f-select
                                v-model="queryForm.payType"
                                :fatherVal="queryForm.payType"
                                :is-need="queryForm.payType"
                                dict="kd_pay_type"
                            ></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6" v-if="queryForm.orderType !== '2'">
                        <el-form-item label="费用名称"  key="feeIdList">
                            <!-- <el-input v-model="queryForm.feename" @keyup.enter.native="handleCheck" placeholder="支持模糊查询"></el-input> -->
                            <f-select
                                v-model="queryForm.feeIdList"
                                :fatherVal="queryForm.feeIdList"
                                :is-need="queryForm.feeIdList"
                                dict="bd_fee_type"
                                :multiple="true"
                            ></f-select>
                        </el-form-item>
                    </el-col>
                  <el-col :xs="24" :sm="12" :md="12" :lg="6" v-if="queryForm.orderType === '2'">
                    <el-form-item label="费用名称"  key="feename">
                       <el-input v-model="queryForm.feename" @keyup.enter.native="handleCheck" placeholder="支持模糊查询"></el-input>
                    </el-form-item>
                  </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="7">
                        <el-form-item label="费用时间">
                            <el-date-picker
                                class="f-flex-1"
                                v-model="stime"
                                type="datetimerange"
                                size="small"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                :picker-options="editStartOptions"
                                :default-time="['00:00:00', '23:59:59']"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                format="yyyy-MM-dd HH:mm:ss"
                                unlink-panels
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="7">
                        <el-form-item label="下单时间">
                            <el-date-picker
                                class="f-flex-1"
                                v-model="otime"
                                type="datetimerange"
                                size="small"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                :picker-options="editStopOptions"
                                :default-time="['00:00:00', '23:59:59']"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                format="yyyy-MM-dd HH:mm:ss"
                                unlink-panels
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="费用币种">
                            <f-select
                                v-model="queryForm.moneyType"
                                :fatherVal="queryForm.moneyType"
                                dict="bd_cur"
                            ></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="折合币种">
                            <f-select
                                v-model="queryForm.realMoneyType"
                                :fatherVal="queryForm.realMoneyType"
                                dict="bd_cur"
                            ></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="7">
                        <el-form-item label="开船时间">
                            <el-date-picker
                                class="f-flex-1"
                                v-model="btime"
                                type="datetimerange"
                                size="small"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                :picker-options="editStopOptions"
                                :default-time="['00:00:00', '23:59:59']"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                format="yyyy-MM-dd HH:mm:ss"
                                unlink-panels
                                :disabled="timeDisable"
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="7">
                        <el-form-item label="到港时间">
                            <el-date-picker
                                class="f-flex-1"
                                v-model="etaTime"
                                type="datetimerange"
                                size="small"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                :picker-options="editStopOptions"
                                :default-time="['00:00:00', '23:59:59']"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                format="yyyy-MM-dd HH:mm:ss"
                                unlink-panels
                                :disabled="timeDisable"
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="7">
                        <el-form-item label="完成时间">
                            <el-date-picker
                                class="f-flex-1"
                                v-model="wtime"
                                type="datetimerange"
                                size="small"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                :picker-options="editStopOptions"
                                :default-time="['00:00:00', '23:59:59']"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                format="yyyy-MM-dd HH:mm:ss"
                                unlink-panels
                                :disabled="timeDisable"
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>



                    <el-col :xs="24" :sm="12" :md="12" :lg="5">
                        <el-form-item label="账单状态">
                            <el-select filterable clearable v-model="queryForm.isBilled">
                                <el-option value="0" label="未开账单"></el-option>
                                <el-option value="1" label="已开账单"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="5">
                        <el-form-item label="是否包税">
                            <el-select filterable clearable v-model="queryForm.isTax">
                                <el-option value="0" label="否"></el-option>
                                <el-option value="1" label="是"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="发票编号">
                            <el-input v-model="queryForm.invoiceNo" @keyup.enter.native="handleCheck"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="国家">
                            <f-select
                                clearable
                                filterable
                                class="f-full-width"
                                v-model="queryForm.shipmentCountry"
                                :isNeed="queryForm.shipmentCountry"
                                :dict="'bd_country'"
                                placeholder="请选择"
                            >
                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6" v-if="queryForm.orderType == '0'">
                        <el-form-item label="客户端是否可见" label-width="100px">
                            <f-select :dict="'is_default'" :is-need="queryForm.isCostomerVisiabled" v-model="queryForm.isCostomerVisiabled" autocomplete="off"></f-select>
                        </el-form-item>
                    </el-col>
                  <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <el-form-item label="数据时间">
                      <f-select
                          class="textarea_table"
                          v-model="queryForm.dataCreateTime"
                          :is-need="queryForm.dataCreateTime"
                          :dict="'data_create_time'"
                          @keyup.enter.native="handleSearch"
                      ></f-select>
                    </el-form-item>
                  </el-col>
                    <!--                    <el-col :xs="24" :sm="12" :md="12" :lg="6">-->
                    <!--                        <el-form-item label="订单类型">-->
                    <!--                            <f-select-->
                    <!--                                v-model="queryForm.orderType"-->
                    <!--                                :isNeed="queryForm.orderType"-->
                    <!--                                dict="all_order_type"></f-select>-->
                    <!--                        </el-form-item>-->
                    <!--                    </el-col>-->
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="5">
                        <el-form-item label-width="30px" style="width: 400px">
                            <el-button
                                size="medium"
                                type="primary"
                                icon="el-icon-search"
                                @click="handleSearch"
                            >查询
                            </el-button
                            >
                            <el-button size="medium" plain @click="handleReset">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>

                    <el-col>
                        <div class="f-p-b-15">
                            <el-button size="medium" type="primary" @click="exportCustomerBillExcel('0')"
                                       v-if="buttonShowList.dckhqkzd"
                            >导出客户欠款账单</el-button>

                            <el-button size="medium" type="primary" @click="exportCustomerBillExcel('1')"
                                       v-if="buttonShowList.dcykhqkzd"
                            >导出月客户欠款账单</el-button>

                            <el-button size="medium" type="primary" @click="exportCustomerBillExcel('2')"
                                       v-if="buttonShowList.dcykhqkzdfymx"
                            >导出客户欠款账单费用明细</el-button>
                            <el-button size="medium" type="primary" @click="exportCustomerBillExcel('3')"
                                       v-if="buttonShowList.dckhfyzdmx && queryForm.orderType == '0'"
                            >导出客户费用账单明细</el-button>
                        </div>
                    </el-col>

                </el-row>
            </el-card>
        </el-form>
        <!--        <el-card class="box-card">-->
        <div class="tab_class_billed">
            <el-tabs class="height_tab_bill" default-active-key="os" type="card" v-model="queryForm.orderType" @tab-click="tabClick">
                <el-tab-pane label="海运" key="os" tab="海运" name="0">
                    <billed-os ref="billedOs" @ok="(e) => handleCheck({},e&&e.isClearSelection)" :ipaginationMy="ipagination" @changeIpagination="changeIpagination">海运</billed-os>
                </el-tab-pane>
                <el-tab-pane label="快递" key="kd" tab="快递" name="2">
                    <billed-kd ref="billedKd" @ok="(e) => handleCheck({},e&&e.isClearSelection)">快递</billed-kd>
                </el-tab-pane>
<!--                <el-tab-pane label="海外仓" key="wms" tab="海外仓" name="3">-->
<!--                    <billed-wms ref="billedWms" @ok="(e) => handleCheck({},e&&e.isClearSelection)">海外仓</billed-wms>-->
<!--                </el-tab-pane>-->
            </el-tabs>
        </div>



        <receive-apply ref="apply" @ok="handleCheck"></receive-apply>
        <email-dialog :visible.sync="dialogFormVisibleEmail" :row="form" @ok="dialogFormVisible = false" :emails="emails"></email-dialog>
        <convert-bill ref="convert" @ok="handleCheck"></convert-bill>
    </div>
</template>
<script>
import QueryMixins from "../../../../mixins/QueryMixins";
import {getAction, postAction} from "@/api/manage";
import receiveApply from "./receiveApply";
import EmailDialog from "./EmailDialog";
import { setUpRouteUtil } from '@/utils/util';
import convertBill from "./convertBill"
import billedOs from "@/views/hyFinanceManage/modules/receive/billedOs";
import billedKd from "@/views/hyFinanceManage/modules/receive/billedKd";
import billedWms from "@/views/hyFinanceManage/modules/receive/billedWms";

export default {
    mixins: [QueryMixins],
    components: {
        receiveApply,EmailDialog,convertBill,billedOs,billedKd,billedWms
    },
    data() {
        return {
            timeDisable: false,
            disableMixinMounted: true,
            queryForm:{
                orderType:'0'
            },
            dictCodes: ['settle_status','cus_pay_type'],
            invoiceInfoList: [],
            editStartOptions: {
                disabledDate: (time) => {
                    if (!this.form.etime) {
                        // time = new Date(time.replace(/-/g, '/'));
                        return time.getTime() < new Date(1970 - 1 - 1).getTime(); //禁止选择1970年以前的日期
                    } else {
                        return time.getTime() > new Date(this.form.etime);
                    }
                },
            },
            editStopOptions: {
                disabledDate: (time) => {
                    // time = new Date(time.replace(/-/g, '/'));
                    return (
                        time.getTime() < new Date(this.form.stime) ||
                        time.getTime() < new Date(1970 - 1 - 1).getTime() //禁止选择1970年以前的日期
                    );
                },
            },
            selectedRowKeys: [],
            selectionRows: [],
            selectedNos: [],
            loading: false,
            form: {},
            dialogFormVisibleEmail:false,
            dialogFormVisible:false,
            emails:[],
            kdBillType:'',
            columns: [
                {
                    type: "selection",
                    width: 50,
                    fixed: 'left'
                },
                {
                    prop: "action",
                    label: "操作",
                    width: 120,
                    fixed: 'left'
                },
                {
                    prop: "orderNo",
                    label: "工作单号",
                    width: 140,
                    fixed: 'left'
                },
                {
                    prop: 'shipmentBoard',
                    label: '开船时间',
                    width: 140,
                },
                {
                    prop: 'runOrderNo',
                    label: '运单号',
                    width: 140,
                },
                {
                    prop: "realAgent",
                    label: "客户",
                    width: 140,
                    fixed: 'left'
                },
                {
                    prop: "payType",
                    label: "付款方式",
                    width: 140,
                    fixed: 'left'
                },
                {
                    prop: "invoiceNo",
                    label: "发票编号",
                    width: 140,
                    fixed: 'left'
                },
                {
                    prop: "agent",
                    label: "结算对象",
                    width: 140,
                },
                {
                    prop: "feename",
                    label: "费用中文",
                },
                {
                    prop: "feenameUs",
                    label: "费用英文",
                },
                {
                    prop: "status",
                    label: "费用状态",
                },
                {
                    prop: "price",
                    label: "价格",
                },
                {
                    prop: "quantity",
                    label: "数量",
                },
                {
                    prop: "moneyType",
                    label: "费用币种",
                },
                {
                    prop: "total",
                    label: "合计",
                },
                {
                    prop: "realMoneyType",
                    label: "折合币种",
                },
                {
                    prop: "exchangeRate",
                    label: "汇率",
                },
                {
                    prop: "exchangeTotal",
                    label: "折合金额",
                },
                {
                    prop: "payed",
                    label: "已收金额",
                },
                {
                    prop: "waitPay",
                    label: "待收金额",
                    headerType:'remark',
                    detail:'提示：待收余额将在销账之后更新',
                    width:100
                },
                {
                    prop: "createBy",
                    label: "录入人",
                },
                {
                    prop: "screateTime",
                    label: "录入时间",
                },
                {
                    prop: "createTime",
                    label: "下单时间",
                },
            ],
            isUsePost : true,
            url: {
                list: "/file/hyFinance/billedListPost",
                invoice: "/shipping/hySettleReceive/getInvoiceInfo",
                sendBack: "/shipping/hySettleReceive/billSendBack",
                billApply:"/shipping/hySettleReceive/takeBillApply",
                downloadApply:"/shipping/hySettleReceive/downloadApply",
                batchInvoiceApply:"/shipping/hySettleReceive/batchInvoiceApply",
                getEmailsByCusId:"/shipping/hySettleReceive/getEmailsByCusId",
                openKdOrderReceiveBill:'/file/jasper/openKdOrderReceiveBill',
                openKdOrderReceiveBillExcel:'/file/jasper/openKdOrderReceiveBillExcel',
                checkBill: '/shipping/hySettleReceive/checkReceiveSettleId',
                openBill: '/file/jasper/openReceiveBill',
                exportCustomerBill: "/file/sale3/dcMonthCusBill",
                exportCustomerApplyInBill: "/file/hyFinance/dcCustomerApplyInBillExcelVO1",
                exportCustomerKdBill: "/shipping/customerBill/exportCustomerKdBill",
                exportCustomerApplyInKdBill: "/shipping/customerBill/exportCustomerApplyInKdBill",

                exportBillingDetails: '/file/export/excel/exportBillingDetails',
                downloadExportCustomerBill:'/file/jasper/downloadExportCustomerBill'
            },

            rules: {
                realCtn: [
                    {required: true, message: "请输入实际总件数", trigger: "blur"},
                    {pattern: /^[1-9]\d*$/, message: "只能输入整数", trigger: "blur"},
                ],
                realKg: [
                    {required: true, message: "请输入实际总重量", trigger: "blur"},
                    {
                        pattern:
                            /^(?=([1-9]{1,10}$|[0-9]{1,7}\.))(0|[1-9][0-9]*)(\.[0-9]{1,3})?$/,
                        message: "只能输入最多三位小数的数字",
                        trigger: "change",
                    },
                ],
            },
            stime: [],
            otime: [],
            btime: [],  //开船时间
            etaTime: [],
            wtime: [],
            ifDis: false,
            invoiceInfoVisible: false,
            invoiceInfoOrderNo: "",
            sumFee: [],
        };
    },
    computed: {
      buttonShowList(){
        return {
          'dckhqkzd':this.$btnIsShow('hyFinanceManage/modules/financialStatements/customerBill','0','导出客户欠款账单'),
          'dcykhqkzd':this.$btnIsShow('hyFinanceManage/modules/financialStatements/customerBill','0','导出月客户欠款账单'),
          'dcykhqkzdfymx':this.$btnIsShow('hyFinanceManage/modules/financialStatements/customerBill','0','导出客户欠款账单费用明细'),
          'dckhfyzdmx':this.$btnIsShow('hyFinanceManage/modules/financialStatements/customerBill','0','导出客户费用账单明细'),
        }
      }
    },
    created() {
        this.$nextTick(()=> {
            // this.tabClick()
        })
      this.$set(this.queryForm,'dataCreateTime','3');
    },
    methods: {

        tabClick(queryForm, isClearSelection,flag){
            // console.log('orderType',this.queryForm.orderType)
            // console.log(this.$refs.billedOs.ipagination.currentPage,'this.$refs.billedOs.currentPage');
            if(!flag){
 this.ipagination.currentPage = 1
            }
            
            console.log(flag,'flag');
            if(this.queryForm.orderType==='0'){
                this.$refs.billedOs.handleCheck(this.queryForm, isClearSelection);
                this.timeDisable = false;
            }else if (this.queryForm.orderType==='2'){
                this.$refs.billedKd.handleCheck(this.queryForm, isClearSelection);
                this.btime = "";
                this.etaTime = "";
                this.wtime = "";
                this.timeDisable = true;
            }else if (this.queryForm.orderType==='3'){
                this.$refs.billedWms.handleCheck(this.queryForm, isClearSelection);
                this.timeDisable = false;
            }
        },
        //开票申请
        yiBillApply(obj){
            // console.log(obj);
            this.$confirm('确定前往易票云进行开票？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'info',
                closeOnClickModal: false,
            }).then(() => {

                postAction(this.url.billApply,obj).then((res) => {
                    // console.log(res);
                    //this.invoiceInfoList = res.result;   this.tableData
                    if (res.code === 200) {
                        this.$message.success("开票成功！");
                        this.handleQuery();
                    }
                })
            }).catch(res => {
                this.$message.error(res.message);
            })
        },
        //下载发票
        downloadApply(obj){
            // console.log(obj);
            var newWindow = window.open();//新开一个窗口，避免被放在ajax中被拦截
            postAction(this.url.downloadApply, obj).then((res) => {
                // console.log(res);
                //this.invoiceInfoList = res.result;
                let applypdfurl=res.result;
                // window.open(applypdfurl,"_blank");
                newWindow.location.href =applypdfurl;
            });
        },

        downloadBillAgain(){
            // console.log("申请收款以下id的费用",this.selectedRowKeys)
            if (this.selectedRowKeys.length === 0){
                this.$message.warning("请勾选费用")
                return
            }
            let preArr = this.getOrderPre()
            if(preArr.length > 1){
                this.$message.warning("请选择同一账单类型的费用进行开账单！")
                return
            }
            if(preArr[0]==='KD'){
                this.$message.warning("快递单费用请选择快递账单")
                return
            }
            let countCur = [];
            this.selectionRows.forEach(item => {
                countCur.push(item.realMoneyType)
            });
            let curArr = Array.from(new Set(countCur));
            // console.log('curArr->',curArr)
            if(curArr.length > 1){
                this.$message.warning("所选费用的折合币种币种不一致！")
                return
            }
            let count = [];
            this.selectionRows.forEach(item => {
                count.push(item.settleId)
            });
            // console.log(Array.from(new Set(count)))
            let arr = Array.from(new Set(count));
            if(arr.length > 1){
                this.$message.warning("所选费用的结算对象不一致！")
                return
            }
            this.$refs.convert.openAgain(this.selectedRowKeys,this.selectedNos,this.selectionRows[0].realAgent,this.selectionRows[0].realMoneyType)

        },

        //进入客服页
        intoDocpage(orderNo) {
            if (orderNo.indexOf("KD")!==-1){
                // 对于路由不在左侧菜单中的选中菜单处理
                setUpRouteUtil(this.$route.path, '/kdorder/Edit');
                this.$router.push("/kdorder/Edit?orderNo=" + orderNo);
            }else {
                // 对于路由不在左侧菜单中的选中菜单处理
                setUpRouteUtil(this.$route.path, '/docpage/Edit');
                this.$router.push("/docpage/Edit?orderNo=" + orderNo);
            }

        },
        sendBack(row) {
            // console.log(row.id);
            let obj = {};
            obj.id = row.id;
            obj.orderNo = row.orderNo;
            postAction(this.url.sendBack, obj).then((res) => {
                // console.log(res);
                if (res.code === 200) {
                    this.$message.success("退回成功！");
                    this.handleQuery();
                }
            });
        },
        invoiceInfoHandleSubmit() {
        },
        invoiceApply(orderNo) {
            // console.log(orderNo);
            getAction(this.url.invoice, {orderNo: orderNo}).then((res) => {
                // console.log(res);
                this.invoiceInfoList = res.result;
            });

            this.invoiceInfoVisible = true;
        },
        receiveApply() {
            if (this.selectionRows.length === 0) {
                this.$message.warning("请勾选费用再进行申请收款");
                return;
            }
            //判断费用状态只有未申请和部分销账的可以申请收款
            let statusCount = [];
            this.selectionRows.forEach((item) => {
                statusCount.push(item.status);
            });
            let statusArr = Array.from(new Set(statusCount));
            if (statusArr.indexOf("1") !== -1 || statusArr.indexOf("9") !== -1) {
                this.$message.warning("已销账和已申请收款的费用不能重复申请！");
                return;
            }

            let count = [];
            this.selectionRows.forEach((item) => {
                count.push(item.realAgent);
            });
            let cusArr = Array.from(new Set(count));
            if (cusArr.length > 1) {
                this.$message.warning("不同客户的费用请分开进行收款！");
                return;
            }

            let orderTypeCount = [];
            this.selectionRows.forEach((item) => {
                orderTypeCount.push(item.orderType);
            });
            let orderTypArr = Array.from(new Set(orderTypeCount));
            if (orderTypArr.length > 1) {
                this.$message.warning("海运费用、快递费用请分开进行申请收款！");
                return;
            }

            let countCur = [];
            this.selectionRows.forEach((item) => {
                countCur.push(item.realMoneyType);
            });
            let curArr = Array.from(new Set(countCur));
            // console.log("curArr->", curArr);
            if (curArr.length > 1) {
                this.$message.warning("所选费用的折合币种不一致！");
                return;
            }

            // let bankCount = [];
            // this.selectionRows.forEach((item) => {
            //     if(item.bankId ===null || item.bankId === '' || item.bankId === undefined){
            //         item.bankId = ''
            //     }
            //     bankCount.push(item.bankId);
            // });
            // let bankArr = Array.from(new Set(bankCount));
            // if (bankArr.length > 1) {
            //     this.$message.warning("所选费用的收款银行不一致！");
            //     return;
            // }

            // console.log("要传送过去的rows", this.selectionRows);

            let arr = this.tableData.filter((item) => {
                return this.selectionRows.indexOf(item) === -1;
            });
            // console.log("arr--", arr);
            this.$refs.apply.edit(this.selectionRows, arr, this.columns);
        },
        collectionLetter(){
            if (this.selectionRows.length === 0) {
                this.$message.warning("请勾选费用再进行发送催款函");
                return;
            }
            this.$message.warning("此功能后续开放！");
        },
        handleSelectionChange(selection) {
            // 多选
            // console.log(selection);
            this.sumFee = [];
            let allCur = []
            selection.forEach(row => {
                allCur.push(row.realMoneyType)
            })
            let curNum = Array.from(new Set(allCur))
            //遍历每个币种，计算总和后放入sumFee中
            curNum.forEach(cur => {
                let curRow = selection.filter(row => {
                    return row.realMoneyType === cur
                })
                let total = Number(0);
                curRow.forEach(i => {
                    total = total + Number(i.exchangeTotal)
                })
                this.sumFee.push({cur: cur, total: total.toFixed(3)})
            })

            let arr = [];
            let nos = [];
            this.nameList = [];
            this.cnamelist = [];
            this.emailList = [];
            for (let i = 0; i < selection.length; i++) {
                arr.push(selection[i]["id"]);
                nos.push(selection[i]["orderNo"]);
                this.nameList.push(selection[i]["username"]);
                this.cnamelist.push(selection[i]["cname"]);
                this.emailList.push(selection[i]["email"]);
            }
            this.selectionRows = selection;
            this.selectedRowKeys = [...arr];
            this.selectedNos = [...nos];
        },
        handleReceive() {
            // console.log("申请收款以下id的费用", this.selectedRowKeys);
        },
        handleReset() {
            this.queryForm = {};
            this.otime = [];
            this.stime = [];
            this.btime = [];
            this.etaTime = [];
            this.wtime = [];
            this.ipagination.currentPage = 1;
            // this.queryForm.orderType='0'
            this.$set(this.queryForm,'orderType',"0")
            this.handleCheck();
        },
        handleSearch() {
        //   this.ipagination.currentPage = 1;
          this.handleCheck();
        },
        handleCheck(queryForm,isClearSelection,flag) {
            // console.log(queryForm,'queryForm');
            // this.loading = true;
            // if (this.otime && this.otime.length > 1) {
            //     this.queryForm.createTimeS = this.otime[0];
            //     this.queryForm.createTimeE = this.otime[1];
            // }else{
            //     this.queryForm.createTimeS = '';
            //     this.queryForm.createTimeE = '';
            // }
            // if (this.stime && this.stime.length > 1) {
            //     this.queryForm.sCreateTimeS = this.stime[0];
            //     this.queryForm.sCreateTimeE = this.stime[1];
            // }else{
            //     this.queryForm.sCreateTimeS = '';
            //     this.queryForm.sCreateTimeE = '';
            // }
            // if (this.btime && this.btime.length > 1) {
            //     this.queryForm.boatTimeS = this.btime[0];
            //     this.queryForm.boatTimeE = this.btime[1];
            // }else{
            //     this.queryForm.boatTimeS = '';
            //     this.queryForm.boatTimeE = '';
            // }
            // if (this.etaTime && this.etaTime.length > 1) {
            //     this.queryForm.etaTimeS = this.etaTime[0];
            //     this.queryForm.etaTimeE = this.etaTime[1];
            // }else{
            //     this.queryForm.etaTimeS = '';
            //     this.queryForm.etaTimeE = '';
            // }
            // if (this.wtime && this.wtime.length > 1) {
            //     this.queryForm.wtimeS = this.wtime[0];
            //     this.queryForm.wtimeE = this.wtime[1];
            // }else{
            //     this.queryForm.wtimeS = '';
            //     this.queryForm.wtimeE = '';
            // }
          this.handleBuildQueryForm();
            // this.tabClick(this.queryForm)
            console.log(this.queryForm,'this.queryForm');
            this.tabClick(this.queryForm, isClearSelection,flag)
            // let form = {...this.queryForm, ...this.filterForm};
            // for (let item in form) {
            //     if (typeof form[item] === "string") {
            //         form[item] = form[item].trim();
            //         if (form[item] === "") {
            //             form[item] = null;
            //         }
            //     }
            // }
            // const {prop, order} = this.isorter;
            // // 传入的参数
            // const params = {
            //     ...form,
            //     column: prop,
            //     order: order.indexOf("asc") !== -1 ? "asc" : "desc",
            // };
            // //判断 是否要查询startTime
            //
            // if (!params.column) {
            //     if (this.queryForm.column === "startTime") {
            //         this.$set(params, "column", "startTime");
            //     } else {
            //         this.$set(params, "column", "createTime");
            //     }
            //     //提货中查询
            //     if (this.queryForm.column === "distributionTime") {
            //         this.$set(params, "column", "distributionTime");
            //     }
            // }
            // if (this.ipagination) {
            //     params.pageNo = this.ipagination.currentPage;
            //     params.pageSize = this.ipagination.pageSize;
            // }
            // console.log("查询对象", params);
            //
            // getAction(this.url.list, params)
            //     .then((res) => {
            //         if (res.success) {
            //             this.$refs.GTable && this.$refs.GTable.handleScrollTop && this.$refs.GTable.handleScrollTop();
            //             const {records, total} = res.result;
            //             this.tableData = records || res.result;
            //
            //             this.ipagination &&
            //             (this.ipagination.total = total || res.result.length);
            //         }
            //     })
            //     .finally(() => {
            //         this.loading = false;
            //     });
        },
        noConvertBill() {
            // console.log("申请收款以下id的费用", this.selectionRows);
            if (this.selectedRowKeys.length === 0){
                this.$message.warning("请勾选费用")
                return
            }
            // this.$refs.noConvert.edit(this.selectedRowKeys, this.selectedNos);

            //批量开票申请
            this.$confirm('确定前往易票云进行开票？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'info',
                closeOnClickModal: false,
            }).then(() => {

                postAction(this.url.batchInvoiceApply,this.selectionRows).then((res) => {
                    // console.log(res);
                    //this.invoiceInfoList = res.result;   this.tableData
                    if (res.code === 200) {
                        this.$message.success("开票成功！");
                        this.handleQuery();
                    }
                })
            }).catch(res => {
                this.$message.error(res.message);
            })

        },
        convertBill() {
            /*   console.log("申请收款以下id的费用", this.selectedRowKeys);
               if (this.selectedRowKeys.length === 0) {
                   this.$message.warning("请勾选费用");
                   return;
               }*/
            let aa = this.selectionRows.map(item => {return item.cusId}).join(",").split(',')
            // console.log("申请收款以下id的费用", aa);
            postAction(this.url.getEmailsByCusId,aa)
                .then((res) => {
                    // console.log("申请收款以下id的费用:"+res.result);
                    this.emails=res.result;
                    this.dialogFormVisibleEmail = true;
                })
                .finally(() => {
                    this.loading = false;
                });
            //给邮箱赋值
            //使用$set
            /* this.$set(this.form,'emails',this.emailList.join(','))
              this.form.emails = this.emailList.join(',');
              console.log("发送邮件",this.form);*/
        },
        kdBill(){
            if(this.kdBillType === ''){
                this.$message.warning('请选择快递账单类型之后再进行下载')
                return
            }

            // console.log("申请收款以下id的费用",this.selectedRowKeys)
            if (this.selectedRowKeys.length === 0){
                this.$message.warning("请勾选费用")
                return
            }
            let preArr = this.getOrderPre()
            if(preArr.length > 1){
                this.$message.warning("请选择同一账单类型的费用进行开账单！")
                return
            }
            if(preArr[0]!=='KD'){
                this.$message.warning("海运空运的费用请选择海运空运账单")
                return
            }
            let count = [];
            let curCount = [];
            this.selectionRows.forEach(item => {
                count.push(item.settleId)
                curCount.push(item.moneyType)
            });
            // console.log(Array.from(new Set(count)))
            let arr = Array.from(new Set(count));
            let curArr = Array.from(new Set(curCount));
            if(arr.length > 1){
                this.$message.warning("所选费用的结算对象不一致！")
                return
            }
            if(curArr.length > 1){
                this.$message.warning("所选费用的币种不一致！")
                return
            }
            // console.log("curArr",curArr[0])
            let obj = {
                ids:this.selectedRowKeys,
                orderNos:this.selectedNos,
                billTitle:'5',
                billType:'2',
                realMoneyType:curArr[0]
            }
            let nos = this.selectedNos.join('-')
            let filename = this.selectionRows[0].realAgent +'-'+ this.getDateStr()+ '快递账单' + nos

            let url = this.url.openBill
            let type = {"type": "application/pdf"}
            if(this.kdBillType==='0'){
                url = this.url.openKdOrderReceiveBillExcel
                type = {type: 'application/vnd.ms-excel'}
            }
            if(this.kdBillType==='1'){
                url = this.url.openKdOrderReceiveBill
            }
            let that = this;
            postAction(this.url.checkBill,this.selectedRowKeys).then((res) => {
                //检测是同一个结算对象 才进行下载
                if(res.success){
                    // console.log("oobj",obj)
                    postAction(url, obj, {responseType: 'blob'})
                        .then(res => {
                          if (res && res.type === 'application/json') {
                            const reader = new FileReader()
                            reader.onload = function () {
                              const message = JSON.parse(reader.result).message
                              that.$message.error(message);// 将错误信息显示出来
                            }
                            reader.readAsText(res)
                            return false;
                          }else {
                            // console.log(res)
                            if(res.size ===0){
                              this.$message.error('导出失败，请稍后再试或联系管理员')
                              return
                            }
                            var binaryData = [];
                            binaryData.push(res);
                            if(this.kdBillType==='0'){
                              let url = window.URL.createObjectURL(new Blob(binaryData, {type: 'application/vnd.ms-excel'}));
                              let link = document.createElement('a');
                              link.style.display = 'none';
                              link.href = url;
                              link.setAttribute('download', filename + '.xls');
                              document.body.appendChild(link);
                              link.click();
                              document.body.removeChild(link);//下载完成移除元素
                              window.URL.revokeObjectURL(url) //释放掉blob对象
                            }else{
                              let urlencoded = window.URL.createObjectURL(new Blob(binaryData,type));
                              window.open("/static/pdf/web/viewer.html?filename=" + filename + "&file=" + encodeURIComponent(urlencoded));
                            }


                            // let file = new Blob(binaryData)
                            // file.lastModifiedDate = new Date();
                            // file.name = "mailAtt.pdf"
                            // let formData = new FormData();
                            // formData.append("file", file);
                            // formData.append("orderNo", this.selectedNos[0])
                            // //发送账单邮件给客户
                            // postAction(this.url.sendBillMail,formData).then((res)=>{
                            //     console.log(res)
                            // })
                            this.visible=false
                            setTimeout(()=>{   //设置延迟执行
                              this.$message.success("开账单成功！")
                              // this.$emit('ok')
                              this.handleCheck();
                            },3000)
                          }

                        })

                }
            })
        },
        invoiceInfoHandleClose() {
            // this.$emit('update:visible', false)
            // this.$refs.form.resetFields()
            this.invoiceInfoVisible = false;
        },
        kdBillAgain(){
            if(this.kdBillType === ''){
                this.$message.warning('请选择快递账单类型之后再进行下载')
                return
            }

            // console.log("申请收款以下id的费用",this.selectedRowKeys)
            if (this.selectedRowKeys.length === 0){
                this.$message.warning("请勾选费用")
                return
            }
            let preArr = this.getOrderPre()
            if(preArr.length > 1){
                this.$message.warning("请选择同一账单类型的费用进行开账单！")
                return
            }
            if(preArr[0]!=='KD'){
                this.$message.warning("海运空运的费用请选择海运空运账单")
                return
            }
            let count = [];
            let curCount = [];
            this.selectionRows.forEach(item => {
                count.push(item.settleId)
                curCount.push(item.moneyType)
            });
            // console.log(Array.from(new Set(count)))
            let arr = Array.from(new Set(count));
            let curArr = Array.from(new Set(curCount));
            if(arr.length > 1){
                this.$message.warning("所选费用的结算对象不一致！")
                return
            }
            if(curArr.length > 1){
                this.$message.warning("所选费用的币种不一致！")
                return
            }
            let obj = {
                ids:this.selectedRowKeys,
                orderNos:this.selectedNos,
                billTitle:'5',
                billType:'4',//2正常快递账单  4再次开快递账单
                realMoneyType:curArr[0]
            }

            let nos = Array.from(new Set(this.selectedNos)).join('-')
            let filename = this.selectionRows[0].realAgent +'-'+ this.getDateStr()+ '快递账单' + nos

            let url = this.url.openBill
            let type = {"type": "application/pdf"}
            if(this.kdBillType==='0'){
                url = this.url.openKdOrderReceiveBillExcel
                type = {type: 'application/vnd.ms-excel'}
            }
            if(this.kdBillType==='1'){
                url = this.url.openKdOrderReceiveBill
            }

            postAction(this.url.checkBill,this.selectedRowKeys).then((res) => {
                //检测是同一个结算对象 才进行下载
                if(res.success){
                    postAction(url, obj, {responseType: 'blob'})
                        .then(res => {
                            // console.log(res)
                            if(res.size ===0){
                                this.$message.error('导出失败，请稍后再试或联系管理员')
                                return
                            }
                            var binaryData = [];
                            binaryData.push(res);
                            if(this.kdBillType==='0'){
                                let url = window.URL.createObjectURL(new Blob(binaryData, {type: 'application/vnd.ms-excel'}));
                                let link = document.createElement('a');
                                link.style.display = 'none';
                                link.href = url;
                                link.setAttribute('download', filename + '.xlsx');
                                document.body.appendChild(link);
                                link.click();
                                document.body.removeChild(link);//下载完成移除元素
                                window.URL.revokeObjectURL(url) //释放掉blob对象
                            }else{
                                let urlencoded = window.URL.createObjectURL(new Blob(binaryData,type));
                                window.open("/static/pdf/web/viewer.html?filename=" + filename + "&file=" + encodeURIComponent(urlencoded));
                            }

                            // let file = new Blob(binaryData)
                            // file.lastModifiedDate = new Date();
                            // file.name = "mailAtt.pdf"
                            // let formData = new FormData();
                            // formData.append("file", file);
                            // formData.append("orderNo", this.selectedNos[0])
                            // //发送账单邮件给客户
                            // postAction(this.url.sendBillMail,formData).then((res)=>{
                            //     console.log(res)
                            // })
                            this.visible=false
                            setTimeout(()=>{   //设置延迟执行
                                this.$message.success("开账单成功！")
                                // this.$emit('ok')
                                this.handleCheck();
                            },3000)
                        })

                }
            })
        },
        getOrderPre(){
            let count = [];
            this.selectionRows.forEach(item => {
                let pre = this.deleteNum(item.orderNo)
                if(pre==='KD'){
                    count.push(pre)
                }else{
                    count.push('OS')
                }
                // count.push(this.deleteNum(item.orderNo))
            });
            // console.log(Array.from(new Set(count)))
            let arr = Array.from(new Set(count));
            // console.log('账单前缀',arr)

            return arr
        },
        deleteNum(str){
            let reg = /[0-9]+/g;
            return str.replace(reg, "");

        },
      //处理一下 查询参数
      handleBuildQueryForm(){

        if (this.stime && this.stime.length > 1) {
          this.queryForm.settleCreateTimeS = this.stime[0] ;
          this.queryForm.sCreateTimeS = this.stime[0] ;
          this.queryForm.settleCreateTimeE = this.stime[1] ;
          this.queryForm.sCreateTimeE = this.stime[1] ;
        }else {
          this.queryForm.settleCreateTimeS = '';
          this.queryForm.sCreateTimeS = '';
          this.queryForm.settleCreateTimeE = '';
          this.queryForm.sCreateTimeE = '';
        }
        if (this.otime && this.otime.length > 1) {
          this.queryForm.orderCreateTimeS = this.otime[0] ;
          this.queryForm.createTimeS = this.otime[0] ;
          this.queryForm.orderCreateTimeE = this.otime[1] ;
          this.queryForm.createTimeE = this.otime[1] ;
        }else {
          this.queryForm.orderCreateTimeS = '';
          this.queryForm.createTimeS = '';
          this.queryForm.orderCreateTimeE = '';
          this.queryForm.createTimeE = '';
        }
        if (this.etaTime && this.etaTime.length > 1) {
          this.queryForm.arriveTimeS = this.etaTime[0] ;
          this.queryForm.arrTimeStart = this.etaTime[0] ;
          this.queryForm.arriveTimeE = this.etaTime[1] ;
          this.queryForm.arrTimeEnd = this.etaTime[1] ;
        }else {
          this.queryForm.arriveTimeS = '';
          this.queryForm.arrTimeStart = '';
          this.queryForm.arriveTimeE = '';
          this.queryForm.arrTimeEnd = '';
        }
        if (this.wtime && this.wtime.length > 1) {
          this.queryForm.deliveryTimeStart = this.wtime[0] ;
          this.queryForm.wTimeStart = this.wtime[0] ;
          this.queryForm.deliveryTimeEnd = this.wtime[1] ;
          this.queryForm.wTimeEnd = this.wtime[1] ;
        }else {
          this.queryForm.deliveryTimeStart = '';
          this.queryForm.wTimeStart = '';
          this.queryForm.deliveryTimeEnd = '';
          this.queryForm.wTimeEnd = '';
        }
        if (this.btime && this.btime.length > 1) {
          this.queryForm.boatTimeS = this.btime[0] ;
          this.queryForm.bTimeStart = this.btime[0] ;
          this.queryForm.boatTimeE = this.btime[1] ;
          this.queryForm.bTimeEnd = this.btime[1] ;
        }else {
          this.queryForm.boatTimeS = '';
          this.queryForm.bTimeStart = '';
          this.queryForm.boatTimeE = '';
          this.queryForm.bTimeEnd = '';
        }
      },
        exportCustomerBillExcel(val){
            this.queryForm.statementType = val
            // console.log('queryForm',this.queryForm)
            if((this.queryForm.orderNo==='' || this.queryForm.orderNo===undefined)
                // && (this.queryForm.customer==='' || this.queryForm.customer===undefined)
                && (this.queryForm.username==='' || this.queryForm.username===undefined)
                && (this.queryForm.settlement==='' || this.queryForm.settlement===undefined)
                && (this.queryForm.moneyType==='' || this.queryForm.moneyType===undefined)
                && (this.queryForm.salesmanId==='' || this.queryForm.salesmanId===undefined)
                && (this.queryForm.docId==='' || this.queryForm.docId===undefined)
                && (this.queryForm.billmanId==='' || this.queryForm.billmanId===undefined)
                && (this.queryForm.operateId==='' || this.queryForm.operateId===undefined)
                && (this.queryForm.payType==='' || this.queryForm.payType===undefined)
                && (this.queryForm.payTypeList=== null || this.queryForm.payTypeList.length === 0)
                && (this.queryForm.feeIdList=== null || this.queryForm.feeIdList.length === 0)
                && (this.queryForm.feename==='' || this.queryForm.feename===undefined)
                && (this.queryForm.moneyType==='' || this.queryForm.moneyType===undefined)
                && (this.queryForm.realMoneyType==='' || this.queryForm.realMoneyType===undefined)
                && (this.queryForm.isBilled==='' || this.queryForm.isBilled===undefined)
                && (this.queryForm.isTax==='' || this.queryForm.isTax===undefined)
                && (this.queryForm.invoiceNo==='' || this.queryForm.invoiceNo===undefined)
                && (this.stime===null || this.stime.length===0)
                && (this.btime===null || this.btime.length===0)
                && (this.etaTime===null || this.etaTime.length===0)
                && (this.wtime===null || this.wtime.length===0)
                && (this.otime===null || this.otime.length===0)){
                if (!this.selectedRowKeys || this.selectedRowKeys.length === 0) {
                    this.$message.warning("导出账单时，须填写一个或多个查询条件");
                    return;
                }
                return;
            }

            this.loading = true;
            this.handleBuildQueryForm();

            if(this.queryForm.username!=='' && this.queryForm.username!==undefined){
                this.queryForm.customer = this.queryForm.username
            }

            let form = {...this.queryForm, ...this.filterForm};
            let fileName = '客户欠款账单_'+ new Date().toLocaleDateString();
            form.selectIds = this.selectedRowKeys;
            // console.log('导出的条件',form)
            let url = this.url.exportCustomerBill
            if (this.queryForm.orderType==='2'){
                url = this.url.exportCustomerKdBill
            }
            if(this.queryForm.statementType==='2'){
                fileName= '客户欠款账单费用明细_'+ new Date().toLocaleDateString();
                url = this.url.exportCustomerApplyInBill
                if (this.queryForm.orderType==='2'){
                    url = this.url.exportCustomerApplyInKdBill
                }
            }
            if(this.queryForm.statementType==='3'){
                fileName= '客户费用账单明细_'+ new Date().toLocaleDateString();
                url = this.url.exportBillingDetails
            }
            if (val==='0' && this.queryForm.orderType==='0') {
                url = this.url.downloadExportCustomerBill
            }
            postAction(url, form, {responseType: 'blob'}).then((data) => {
                if (typeof window.navigator.msSaveBlob !== 'undefined') {
                    window.navigator.msSaveBlob(new Blob([data], {type: 'application/vnd.ms-excel'}), fileName + '.xls')
                } else {
                    let url = window.URL.createObjectURL(new Blob([data], {type: 'application/vnd.ms-excel'}));
                    let link = document.createElement('a');
                    link.style.display = 'none';
                    link.href = url;
                    link.setAttribute('download', fileName + '.xls');
                    document.body.appendChild(link);
                    link.click()
                    document.body.removeChild(link);//下载完成移除元素
                    window.URL.revokeObjectURL(url) //释放掉blob对象
                    this.loading = false;
                }
            })
        },
        changeIpagination(val){
            if(val.currentPage){
                this.ipagination.currentPage=val.currentPage
            }
            if(val.pageSize){
                 this.ipagination.pageSize=val.pageSize
            }
            this.handleCheck(null,{isClearSelection: false},true)
        }
    },
};
</script>
<style lang="scss" scoped>
.flex_wait_box {
    display: flex;
    .select_width {
        width: 200px;
        margin: 0 10px;
    }
}
</style>
<style lang="scss">
.tab_class_billed {
    .el-tabs--top > .is-top {
        position: unset;
        padding-left: 0;
        width: 100%;
    }
    .el-tabs--top > .el-tabs__content {
        top: 0;
    }
    .el-tabs__header {
        margin: 0;
    }
    .el-tabs--card > .el-tabs__header {
        border-bottom: 0;
    }
}
.height_tab_bill {
  .el-tabs__item {
    line-height: 25px;
    height: 25px;
    font-size: 15px !important;
  }
}
</style>
