<template>
  <!-- 新增或编辑客户理赔 -->
  <el-dialog
    :title="row && row.createBy ? '编辑客户理赔' : '新增客户理赔'"
    :visible="visible"
    width="38%"
    center
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <el-form :model="form" :rules="rules" ref="form">
      <el-row>
        <el-col :span="18">
          <el-form-item
            label="理赔单号"
            prop="claimNo"
            :label-width="formLabelWidth"
          >
            <el-input v-model="form.claimNo" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="18">
          <el-form-item
            label="责任方"
            prop="responsibleParty"
            :label-width="formLabelWidth"
          >
            <f-select
              :dict="'responsible_party'"
              v-model="form.responsibleParty"
              :is-need="form.responsibleParty"
              @changet="changeResponsibility"
            ></f-select>
          </el-form-item>
        </el-col>
        <el-col :span="18">
          <el-form-item
            label="理赔类型"
            prop="claimType"
            :label-width="formLabelWidth"
          >
            <f-select
              :dict="'claim_type'"
              v-model="form.claimType"
              :is-need="form.claimType"
              @changet="changeCliamType"
            ></f-select>
          </el-form-item>
        </el-col>
        <el-col :span="18">
          <el-form-item
            label="订单号"
            prop="orderNo"
            :label-width="formLabelWidth"
          >
            <el-input
              v-model="form.orderNo"
              @keyup.enter.native="getOrderNo"
              :disabled="disableOrderNo"
              style="width: 320px;"
            ></el-input>
            <el-button size="small" type="primary"  @click = "getOrderNo" >新增</el-button>
          </el-form-item>
        </el-col>
        <el-col :span="18">
          <el-form-item
            label="币种"
            prop="moneyType"
            :label-width="formLabelWidth"
          >
            <f-select
              :dict="'bd_cur'"
              v-model="form.moneyType"
              :is-need="form.moneyType"
            ></f-select>
          </el-form-item>
        </el-col>
        <el-col :span="18">
          <el-form-item
              label="审批编号"
              prop="applyNo"
              :label-width="formLabelWidth"
          >
            <el-input
                v-model="form.applyNo"
                maxlength="30"
                show-word-limit
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-button
        type="primary"
        size="medium"
        @click="deleteInbBatches"
        :disabled="disableOrderNo"
        >批量删除</el-button
      >
      <f-table
        v-loading="loading"
        ref="GTable"
        border
        size="medium"
        row-key="id"
        class="c-th-has-bgcolor"
        :columns="columns"
        :data="tableData"
        :cell-style="{ 'text-align': 'center' }"
        @selection-change="handleSelectionChange"
        @sort-change="handleTableChange"
      >
        <template v-slot="scope">
          <template v-if="scope.column.property === 'cooperationType'">
            <span>{{
              parseDict(scope.row.cooperationType, "cooperation_type")
            }}</span>
          </template>
          <template v-else-if="scope.column.property === 'action'">
            <el-button
              type="text"
              @click="handleDele(scope.row)"
              :disabled="disableOrderNo"
              >删除
            </el-button>
          </template>
          <template v-else-if="scope.column.property === 'claimMoney'">
            <el-input v-model="scope.row.claimMoney" onk></el-input>
          </template>
          <template v-else-if="scope.column.property === 'realClaimMoney'">
            <el-input v-model="scope.row.realClaimMoney"></el-input>
          </template>
        </template>
      </f-table>
      <!-- <el-row class="f-text-right f-p-v-8">
        <el-pagination
          v-bind="ipaginationSelect"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChangeSelect"
          @current-change="handleCurrentChangeSelect"
        >
        </el-pagination>
      </el-row> -->
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button size="medium" @click="handleClose">取 消</el-button>
      <el-button size="medium" type="primary" @click="submit">确 定 </el-button>
    </div>
  </el-dialog>
</template>

<script>
import QueryMixins from "@/mixins/QueryMixins.js";
import { getAction, postAction, putAction } from "@/api/manage";
export default {
  name: "AddCustomerClaimsDialog",
  mixins: [QueryMixins],
  props: {
    visible: {
      type: Boolean,
    },
    row: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: "",
    },
  },

  watch: {
    visible(val) {
      if (val) {
        // 编辑-根据单号回显信息
        if (this.row && this.row.createBy) {
          this.loading = true;
          getAction(this.url.getClaimByClaimId, {
            id: this.row.id,
            orderNo: this.row.orderNo,
          })
            .then((res) => {
              if (res.success) {
                this.form = res.result;
                this.tableData = res.result.orderCustomerVos;
                this.form.orderNo = res.result.orderCustomerVos[0].orderNo;
                this.disableOrderNo = true;
                this.loading = false;
              } else {
                this.loading = false;
                this.$message.error(res.message);
              }
            })
            .catch((ree) => {
              console.log(6666);
              this.loading = false;
            });
        } else {
          // 新增-生成索赔单号
          this.init();
          this.disableOrderNo = false;
        }
      }
    },
  },
  data() {
    return {
      form: {
        claimNo: "",
        type: 0,
        // dingdanhao: "",
      },
      rules: {
        claimNo: [
          {
            required: true,
            message: "请输入索赔单号",
            trigger: "blur",
          },
        ],
        responsibleParty: [
          {
            required: true,
            message: "请选择责任方",
            trigger: "blur",
          },
        ],
        claimType: [
          {
            required: true,
            message: "请选择索赔类型",
            trigger: "blur",
          },
        ],
        orderNo: [
          {
            required: true,
            message: "请输入订单号",
            trigger: "blur",
          },
        ],
        moneyType: [
          {
            required: true,
            message: "请选择币种",
            trigger: "blur",
          },
        ],
        applyNo: [
          {
            required: false,
            message: "请输入备注",
            trigger: "blur",
          },
        ],
        // claimType: [
        //   {
        //     required: true,
        //     message: "请选择理赔类型",
        //     trigger: "blur",
        //   },
        // ],
      },
      tableData: [],
      formLabelWidth: "150px",
      countNumList: [],
      loading: false,
      flag: false,
      columns: [
        {
          type: "selection",
          width: 50,
          fixed: "left",
        },
        {
          prop: "orderNo",
          label: "订单号",
          width: 120,
          fixed: "left",
        },
        {
          prop: "customer",
          label: "客户",
          width: 129,
          fixed: "left",
        },
        {
          prop: "claimMoney",
          label: "索赔金额",
          width: 140,
          fixed: "left",
        },
        {
          prop: "realClaimMoney",
          label: "实际赔付金额",
          headerType: "require",
          width: 140,
          fixed: "left",
        },
        {
          prop: "action",
          label: "操作",
          width: 120,
          fixed: "left",
        },
      ],
      url: {
        // 生成索赔单号
        getClaimNo: "/file/claim/getClaimNo",
        //根据订单号获取信息
        getCustomerByOrderNo: "/file/claim/getCustomerByOrderNo",
        // 新增
        addBatchByOrder: "/file/claim/addBatchByOrder",
        // 回显
        getClaimByClaimId: "/file/claim/getClaimByClaimId",
        // 编辑
        updateBatchByOrder: "/file/claim/updateBatchByOrder",
      },
      // isSend: true,
      verifyOne: true,
      verifyTwo: true,
      disableOrderNo: false,
    };
  },
  methods: {
      changeCliamType(val){
          this.$set(this.form,'claimTypeStr',val);
      },
      changeResponsibility(val){
          this.$set(this.form,'responsibilityStr',val);
      },
    init() {
      getAction(this.url.getClaimNo, { type: 0 }).then((res) => {
        console.log(res, "ress");
        if (res.success) {
          if (res.result) {
            // Vue.set(this.form, "claimNo", res.result);
            this.form.claimNo = res.result;
            // console.log(this.form, "this.form");
            // console.log(this.form.claimNo, "this.form.claimNo");
          } else {
          }
        } else {
        }
      });
    },
    getOrderNo() {
      console.log(this.form.orderNo, "form.orderNo");
      if (this.form.orderNo) {
        this.loading = true;
        getAction(this.url.getCustomerByOrderNo, {
          orderNo: this.form.orderNo,
        })
          .then((res) => {
            if (res.success) {
              if (res.result) {
                this.tableData.push(res.result);
                this.$message.success(res.message);
                this.loading = false;
              } else {
                this.loading = false;
                this.$message.error(res.message);
              }
            }
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
    handleClose() {
      // this.row = {};
      this.form = {
        claimNo: "",
      };
      this.tableData = [];
      this.$refs.form.resetFields();
      this.$emit("update:visible", false);
      // this.isSend = true;
      this.verifyOne = true;
      this.verifyTwo = true;
      this.loading = false;
    },
    //删除
    handleDele(row) {
      this.tableData.forEach((tabItem, index) => {
        if (row.unqId == tabItem.unqId) {
          this.tableData.splice(index, 1);
        }
      });
      console.log(row, "rowww");
    },
    // 批量删除
    deleteInbBatches() {
      this.selectionRows.forEach((selectItem, selectIndex) => {
        this.tableData.forEach((tabItem, tabIndex) => {
          if (tabItem.unqId == selectItem.unqId) {
            console.log(1);
            this.tableData.splice(tabIndex, 1);
          }
        });
      });
      // console.log(arr, "arr");
      console.log(this.selectionRows, "selectionRows");
    },
    // 提交
    submit() {
      let isStraight = this.form.claimType == '海关追溯' || this.form.claimType == '丢件货物上架' ? true : false;
      this.tableData.forEach((item) => {
        this.changeClaimMoney(Number(item.realClaimMoney), "0", isStraight);
        this.changeClaimMoney(Number(item.claimMoney), "1", isStraight);
      });
      // console.log(this.verifyOne, "this.verifyOne");
      // console.log(this.verifyTwo, "this.verifyTwo");
      let message = isStraight ? '实际赔付金额只能为正数，最多7位整数2位小数，请检查输入的信息' : '实际赔付金额只能为负数，最多7位整数2位小数，请检查输入的信息';
      let messageb = isStraight ? '索赔金额只能为正数，最多7位整数2位小数，请检查输入的信息' : '索赔金额只能为负数，最多7位整数2位小数，请检查输入的信息';
      if(!this.verifyOne) {
        this.$message.error(message);
        return;
      } else if (!this.verifyTwo) {
        this.$message.error(messageb);
        return;
      }
      console.log(this.verifyOne, this.verifyTwo);
        //理赔方类型
        if (this.form.claimTypeStr) {
            this.$set(this.form,'claimType',this.form.claimTypeStr);
        }
        if (this.form.responsibilityStr) {
            this.$set(this.form,'responsibleParty',this.form.responsibilityStr);
        }
        //责任方
      const params = {
        ...this.form,
        orderCustomerVos: this.tableData,
      };
      params.type = params.type ? params.type : this.type;
      console.log(params, "params");
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          if (this.row && this.row.createBy) {
            // 编辑
            console.log("编辑");
            putAction(this.url.updateBatchByOrder, params).then((res) => {
              if (res.success) {
                this.loading = false;
                this.$message.success(res.message);
                this.$emit("ok");
                this.handleClose();
              } else {
                this.loading = false;
                this.$message.error(res.message);
              }
            }).catch(err=>{
              this.loading=false;
            })
            ;
          } else {
            // 新增
            console.log("新增");
            putAction(this.url.addBatchByOrder, params).then((res) => {
              if (res.success) {
                this.loading = false;
                this.$message.success(res.message);
                this.$emit("ok");
                this.handleClose();
              } else {
                this.loading = false;
                this.$message.error(res.message);
              }
            }).catch(err=>{
              this.loading=false;
            });
          }
        }
      });
    },
    // 索赔员
    changeClaimMan(e) {
      if (e) this.form.claimMan = e;
    },
    // 跟进人
    changFollowMan(e) {
      if (e) this.form.followMan = e;
    },
    //供应商
    changSupplier(e) {
      if (e) this.form.supplier = e;
    },
    // 操作员
    changOperation(e) {
      if (e) this.form.operation = e;
    },
    // 索赔类型
    changClaimType(e) {
      if (e) this.form.claimType = e;
    },
    // 责任方
    changResponsibleParty(e) {
      if (e) this.form.responsibleParty = e;
    },
    // 是否只允许输入正数，默认是false
    changeClaimMoney(value, flag, isStraight=false) {
      console.log(value, isStraight, "理赔类型");
      if (flag == "0") {
        this.verifyOne = true;
      } else {
        this.verifyTwo = true;
      }
      if (value) {
        let symbol = isStraight ? value < 0 : value > 0;
        console.log(symbol, '大于0小于0')
        if (symbol) {
          if (flag == "0") {
            this.verifyOne = false;
          } else {
            this.verifyTwo = false;
          }
          return;
        } else {
          let str = value.toString().split(".");
          if (str[0].length > 8) {
            // this.$message.warning("最多10位");
            this.isSend = false;
            if (flag == "0") {
              this.verifyOne = false;
            } else {
              this.verifyTwo = false;
            }
            return;
          } else if (str[1] && str[1].length > 2) {
            // this.$message.warning("最多2位小数");
            this.isSend = false;
            if (flag == "0") {
              this.verifyOne = false;
            } else {
              this.verifyTwo = false;
            }
            return;
          } else if (str[2]) {
            // this.$message.warning("小数不对");
            this.isSend = false;
            if (flag == "0") {
              this.verifyOne = false;
            } else {
              this.verifyTwo = false;
            }
            return;
          }
        }
      } else if (isNaN(value)) {
        console.log("不是数字");
        // this.$message.warning("必须为数字");
        if (flag == "0") {
          this.verifyOne = false;
        } else {
          this.verifyTwo = false;
        }
      } else if (value === 0) {
        console.log("必填项校验不通过");
        if (flag == "0") {
          this.verifyOne = false;
        }
      }
      console.log(this.verifyOne, "isSend");
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .el-table__fixed::before,
  .el-table__fixed-right::before {
    width: 0;
  }
  // .el-table__fixed,
  // .el-table__fixed-right {
  //   overflow-y: scroll !important;
  // }
  .el-table--scrollable-x .el-table__body-wrapper {
    overflow-x: hidden;
  }
  .el-table__fixed-body-wrapper {
    height: 240px !important;
    overflow: scroll !important;
  }
  .el-table {
    height: 300px !important;
    // overflow-x: scroll !important;

    // min-width: 55px !important;

    // &::before {
    //   height: 0;
    // }
  }
}
</style>
