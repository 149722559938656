/*四舍五入 保留两位*/
export function caculateSishewuru(a,b){
    let c = 0;
    if (!isNaN(a) && !isNaN(b)) {
        c = a * b;
    }
    if (c >= 0) {
        return Math.round(((c || 0) + Number.EPSILON) * Math.pow(10,2)) / Math.pow(10,2);
    }else {
        //负数单独处理
        c = c * -1;
        let num = Math.round(((c || 0) + Number.EPSILON) * Math.pow(10,2)) / Math.pow(10,2);
        return num * -1;
    }
}
/*保留两位*/
export function baoliuwei(c,n){
    if (c > 0) {
        return Math.round(((c || 0) + Number.EPSILON) * Math.pow(10,n)) / Math.pow(10,n);
    }else {
        //负数单独处理
        c = c * -1;
        let num = Math.round(((c || 0) + Number.EPSILON) * Math.pow(10,n)) / Math.pow(10,n);
        return num * -1;
    }
}
/*舍去 保留两位*/
export function caculateShequ(a,b) {
    let num = 0;
    if (!isNaN(a) && !isNaN(b)) {

        num = (a*1000*b*1000/1000000);
        console.log('a*b', num);
        num = num.toString()
        let index = num.indexOf('.')
        if (index !== -1) {
            num = num.substring(0, 2 + index + 1)
        } else {
            num = num.substring(0)
        }
        return Math.round(((parseFloat(num) || 0) + Number.EPSILON) * Math.pow(10, 2)) / Math.pow(10, 2)
    }
    return 0;
}
