<template>
  <div class="main-full-content">
    <el-form class="input_top" ref="form" :model="queryForm" label-width="80px" size="medium">
        <el-card class="box-card">
            <el-row>
        <el-col :xs="24" :sm="12" :md="12" :lg="6">
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="6">
          <el-form-item label="公司名">
           <el-input v-model="queryForm.company"></el-input>
          </el-form-item>
        </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <el-form-item label="用户名">
                        <el-input v-model="queryForm.username"></el-input>
                    </el-form-item>
                </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="4">
          <el-form-item label-width="30px">
            <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
            <el-button plain @click="handleReset">重置</el-button>
          </el-form-item>
        </el-col>
      </el-row>
        </el-card>

    </el-form>
    <el-card class="box-card last_card">
        <div class="f-p-b-15">
        <el-button size="medium" type="primary" icon="el-icon-download" @click="handleExportXls">导出</el-button>
    </div>
    <div class="c-alert f-m-b-10">
      已选择 <span class="stress">{{this.selectedRowKeys.length}}</span> 项
      <span class="stress f-m-l-12 f-cursor-pointer" @click="handleClearSelection">清空</span>
    </div>
    <f-table
        v-loading="loading"
        ref="GTable"
        border
        size="medium"
        row-key="id"
        class="c-th-has-bgcolor"
        :columns="columns"
        :data="tableData"
        :cell-style="{ 'text-align': 'center' }"
        tableHeight="58vh"
        @selection-change="handleSelectionChange"
        @sort-change="handleTableChange">
      <template v-slot="scope">
        <template v-if="scope.column.property === 'action'">
          <el-button type="text" @click="handleThaw(scope.row)">解冻</el-button>
          <el-button type="text" @click="handleDel(scope.row)">删除</el-button>
        </template>
      </template>
    </f-table>
    <el-row class="f-text-right f-p-v-8">
      <el-pagination
          v-bind="ipagination"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange">
      </el-pagination>
    </el-row>
    </el-card>

  </div>
</template>

<script>
import { putAction, deleteAction,getallusers } from '@/api/manage'
import QueryMixins from "@/mixins/QueryMixins.js"
export default {
  name: 'Frozen',
  mixins: [QueryMixins],
  components: {

  },
  data() {
    return {
      visible: false,
      row: {},
      props:{
        userName:{
          type :String
        }
      },
      columns: [
        {
          prop: 'company',
          label: '公司名',

        },
        {
          prop: 'companyCode',
          label:"统一社会信用代码",

        },
        {
          prop: 'address',
          label: '公司地址',

        },
        {
          prop: 'username',
          label: '用户名',

        },
          {
              prop: 'faRen',
              label: '授权代表',

          },
          {
              prop: 'email',
              label: '注册邮箱',

          },
          {
              prop: 'phone',
              label: '联系电话',

          },
      ],
      url: {
        list: '/customer/log/oceanCustomerPage',

        exportXlsUrl: '/customer/log/exportCusIngo',


      },
      userlist:[],


    }
  },
  methods: {
    handleEdit(row) {
      this.row = row
      this.visible = true
    },
    initUserList(){
      getallusers().then(
          res=>{
            if (res.success){
              for(var i=0;i<res.result.records.length;i++){
                var obj = {};
                obj.value = res.result.records[i].id;
                obj.label = res.result.records[i].username;
                this.userlist.push(obj);
              }
            }
          }
      )
    },
    handleDel() {
      // this.$confirm(`确定批量删除共?`, '提示', {
      //         confirmButtonText: '确定',
      //         cancelButtonText: '取消',
      //         type: 'success',
      //         closeOnClickModal: false,
      // }).then(() => {
      //     deleteAction(this.url.deleteBatch, {id}).then(res => {
      //         this.$message({
      //             type: 'success',
      //             message: res.message
      //         })
      //         this.handleQuery()
      //     })
      // }).catch(() => {

      // })
    },
    handleThaw(row) {
      this.$confirm(`确定解冻${row.name}？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error',
        closeOnClickModal: false,
      }).then(() => {
        deleteAction(this.url.jiedong, {id: row.id}).then(res => {
          this.$message({
            type: 'success',
            message: res.message
          })
          this.handleSearch()
        })
      }).catch(err=>{
        this.$message.error(err.message);
      })
    },
    handleReset(){
      this.queryForm = {}
      // this.queryForm.cusId = this.$route.query.id
      this.ipagination.currentPage = 1

      this.handleQuery()
    },
  },
  created() {
    // this.initUserList();
    // this.queryForm.cusId = this.$route.query.id
  }
}
</script>

<style lang="scss" scoped>

</style>
