<template>
  <el-dialog
    :title="title"
    width="1800"
    :visible="visible"
    :confirmLoading="confirmLoading"
    switchFullscreen
    @ok="handleCancel"
    @cancel="handleCancel"
    cancelText="关闭">
    <a-tabs @change="openOrderDetail">
      <a-tab-pane key="1" tab="客服页" forceRender>
          <order-doc ref="orderdoc"></order-doc>
      </a-tab-pane>
      <!--<a-tab-pane key="2" tab="应收账单" forceRender>
        <hs-pay-modal ref="hspay"></hs-pay-modal>
      </a-tab-pane>
      <a-tab-pane key="3" tab="应付帐单" forceRender>
        <money-out-pay-modal ref="moneyOutPay"></money-out-pay-modal>
      </a-tab-pane>
      <a-tab-pane key="4" tab="操作记录" forceRender>
        <opera-record-modal ref="operaRecord"></opera-record-modal>
      </a-tab-pane>-->
    </a-tabs>
    <template slot="footer">
      <el-select style="width: 180px;float: left" v-model="selectDefault">
        <el-option value="0" label="入仓单-PDF">

        </el-option>

      </el-select>

      <el-select  style="width: 180px;float: left;margin-left: 10px" v-model="selectDefault">
        <el-option value="0" label="入仓单-EXCEL">
        </el-option>

      </el-select>

      <el-select  style="width: 180px;float: left;margin-left: 10px" v-model="selectDefault">
        <el-option value="0" label="唛头标签">
        </el-option>
      </el-select>

      <el-select  style="width: 180px;float: left;margin-left: 10px" v-model="selectDefault">
        <el-option value="0" label="唛头标签-LOGO">
        </el-option>
      </el-select>


      <el-button key="3" type="primary" @click="handleCancel">
        关闭
      </el-button>


    </template>
  </el-dialog>



</template>

<script>

  import { getAction,httpAction,getFileHttpUrl } from '@/api/manage'
  import pick from 'lodash.pick'

  // import JDictSelectTag from "@/components/dict/JDictSelectTag"
  // import JSearchSelectTag from '@/components/dict/JSearchSelectTag'
  import orderDoc from "./orderDoc";
  import OperaRecordModal from "./OperaRecordModal";
  import hsPayModal from "./hsPayModal";
  import moneyOutPayModal from "./moneyOutPayModal";


  export default {
    name: "FbaWarehouseOrderReceiveModal",
    components: {
      OperaRecordModal,
      orderDoc,
      hsPayModal,
      moneyOutPayModal
    },
    data () {
      return {
        selectDefault:"",
        title:"订单基本信息",
        width:1800,
        visible: false,
        model: {},
        labelCol: {
          xs: { span: 24 },
          sm: { span: 8 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 },
        },

        confirmLoading: false,
        validatorRules: {
        },
        url: {
          fgDownUrl:'/os/order/FY/download',
          fyDownUrl:'/os/order/FY/download',
          gcDownUrl:'/os/order/GC/download',
          shDownUrl:'/os/order/SH2/download',
          nbDownUrl:'/os/order/SH2/download',
          xmDownUrl:'/os/order/XM/download',
          pdtDownUrl:'/os/order/XM/download',
          markDownUrl:'/os/order/markdownload',
          marktwoDownUrl:'/os/order/marktwodownload',
          markfourDownUrl:'/os/order/markfourdownload',
          markoneDownUrl:'/os/order/markonedownload',
          markoverseaDownUrl:'/os/order/markoverseadownload',

          marklogoDownUrl:'/os/order/markdownloadlogo',
          marktwologoDownUrl:'/os/order/marktwodownloadlogo',
          markfourlogoDownUrl:'/os/order/markfourdownloadlogo',
          markonelogoDownUrl:'/os/order/markonedownloadlogo',
          markoversealogoDownUrl:'/os/order/markoverseadownload',

        },
        fgurl:'',
        fyurl:'',
        gcurl:'',
        shurl:'',
        nburl:'',
        xmurl:'',
        pdturl:'',

        fgurlExcel:'',
        fyurlExcel:'',
        gcurlExcel:'',
        shurlExcel:'',
        nburlExcel:'',
        xmurlExcel:'',
        pdturlExcel:'',

        mark:'',
        marktwo:'',
        markfour:'',
        markone:'',
        markoversea:'',

        marklogo:'',
        marktwologo:'',
        markfourlogo:'',
        markonelogo:'',
        markoversealogo:'',

      }
    },
    created () {

    },
    methods: {
      catOrdersDetail(record){
        let that=this
        this.model = Object.assign({}, record);
        this.visible = true;
        setTimeout(() => {
          that.$refs.orderdoc.getOsOrderDetail(this.model)
        }, 100)

        //入仓单-pdf
        that.fgurl=getFileHttpUrl(that.url.fgDownUrl,{'orderNo':that.model.orderNo,'t':'pdf','city':'dgfg'})
        that.fyurl=getFileHttpUrl(that.url.fyDownUrl,{'orderNo':that.model.orderNo,'t':'pdf','city':'xw'})
        that.gcurl=getFileHttpUrl(that.url.gcDownUrl,{'orderNo':that.model.orderNo,'t':'pdf'})
        that.shurl=getFileHttpUrl(that.url.shDownUrl,{'orderNo':that.model.orderNo,'t':'pdf','city':'shanghai2'})
        that.nburl=getFileHttpUrl(that.url.nbDownUrl,{'orderNo':that.model.orderNo,'t':'pdf','city':'bc'})
        that.xmurl=getFileHttpUrl(that.url.xmDownUrl,{'orderNo':that.model.orderNo,'t':'pdf','city':'xiamen'})
        that.pdturl=getFileHttpUrl(that.url.pdtDownUrl,{'orderNo':that.model.orderNo,'t':'pdf','city':'PDT'})
        //入仓单-excel
        that.fgurlExcel=getFileHttpUrl(that.url.fgDownUrl,{'orderNo':that.model.orderNo,'t':'excel','city':'dgfg'})
        that.fyurlExcel=getFileHttpUrl(that.url.fyDownUrl,{'orderNo':that.model.orderNo,'t':'excel','city':'xw'})
        that.gcurlExcel=getFileHttpUrl(that.url.gcDownUrl,{'orderNo':that.model.orderNo,'t':'excel'})
        that.shurlExcel=getFileHttpUrl(that.url.shDownUrl,{'orderNo':that.model.orderNo,'t':'excel','city':'shanghai2'})
        that.nburlExcel=getFileHttpUrl(that.url.nbDownUrl,{'orderNo':that.model.orderNo,'t':'excel','city':'bc'})
        that.xmurlExcel=getFileHttpUrl(that.url.xmDownUrl,{'orderNo':that.model.orderNo,'t':'excel','city':'xiamen'})
        that.pdturlExcel=getFileHttpUrl(that.url.pdtDownUrl,{'orderNo':that.model.orderNo,'t':'excel','city':'PDT'})
        //唛头标签
        that.mark=getFileHttpUrl(that.url.markDownUrl,{'orderNo':that.model.orderNo,'t':'pdf'})
        that.marktwo=getFileHttpUrl(that.url.marktwoDownUrl,{'orderNo':that.model.orderNo,'t':'pdf'})
        that.markfour=getFileHttpUrl(that.url.markfourDownUrl,{'orderNo':that.model.orderNo,'t':'pdf'})
        that.markone=getFileHttpUrl(that.url.markoneDownUrl,{'orderNo':that.model.orderNo,'t':'pdf'})
        that.markoversea=getFileHttpUrl(that.url.markoverseaDownUrl,{'orderNo':that.model.orderNo,'t':'pdf','oversea':'0'})
        //唛头标签-logo
        that.marklogo=getFileHttpUrl(that.url.marklogoDownUrl,{'orderNo':that.model.orderNo,'t':'pdf'})
        that.marktwologo=getFileHttpUrl(that.url.marktwologoDownUrl,{'orderNo':that.model.orderNo,'t':'pdf'})
        that.markfourlogo=getFileHttpUrl(that.url.markfourlogoDownUrl,{'orderNo':that.model.orderNo,'t':'pdf'})
        that.markonelogo=getFileHttpUrl(that.url.markonelogoDownUrl,{'orderNo':that.model.orderNo,'t':'pdf'})
        that.markoversealogo=getFileHttpUrl(that.url.markoversealogoDownUrl,{'orderNo':that.model.orderNo,'t':'pdf','oversea':'1'})

      },
      handleOk () {

      },
      handleCancel () {
        this.close()
      },
      close () {
        this.$emit('close');
        this.visible = false;
      },
      openOrderDetail(key){

        if (key==='1'){
          console.log('1')
          this.$refs.orderdoc.getOsOrderDetail(this.model)
        }
        if (key==='2'){
          console.log('2')

          this.$refs.hspay.editHsPay(this.model)
        }
        if (key==='3'){
          console.log('3')
          this.$refs.moneyOutPay.editMoPay(this.model)
        }
        if (key==='4'){
          console.log('4')
          this.$refs.operaRecord.showDatas( this.model);
        }



      }


    }
  }
</script>
<style scoped>
  a:link {
    color: #808080;
    text-decoration:none;
  }
  a:visited {
    color:#808080;
    text-decoration:none;
  }
  a:hover {
    color:black;
    text-decoration:none;
  }
  a:active {
    color:#808080;
    text-decoration:none;
  }
</style>
