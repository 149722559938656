<template>
  <div id="app" v-loading.fullscreen.lock="fullscreenLoading">
    <router-view />
    <el-dialog :title="message.title" :visible="showMessage" :close-on-click-modal="false" :close-on-press-escape="false"
      @close="handleCloseMessage" width="25%" top="320px" center>
      <span style="font-size: 18px">{{ message.content }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleEditMessage" type="primary">设置为已读</el-button>
      </span>
    </el-dialog>
    <person-info-dialog ref="PersonInfoDialog"></person-info-dialog>
  </div>
</template>
<script>
import { putAction, getAction } from "@/api/manage";
import PersonInfoDialog from "@/views/isystem/PersonInfoDialog"
import store from "@/store";
import { mapState } from "vuex";
export default {
  components: {
    PersonInfoDialog
  },
  data() {
    return {
      websock: null,
      messageTime: null,
      url: {
        edit: "/sys/sysMsgLog/edit",
        query: "/sys/sysMsgLog/query",
      },
      message: {}, //消息的相关提示
      showMessage: false,
      timeoutObj: null,
    };
  },
  computed: {
    ...mapState(["token", "fullscreenLoading"]),
  },
  mounted() {
    window.addEventListener('beforeunload', e => this.beforeunloadHandler(e))
    window.addEventListener('unload', e => this.unloadHandler(e))
    store.commit("setToken", localStorage.getItem("TOKEN"));
  },
  created() {
    localStorage.setItem('intranet_id', process.env.intranet_id);
    // 判断pwd是否是初始密码a888888
    if (localStorage.getItem("USERINFO") && this.$route.path !== '/') {
      let userInfo = JSON.parse(localStorage.getItem("USERINFO"));
      if (userInfo.pwd == 'forest123') {
        // console.log('密码是初始密码');
        this.$message.warning('此账号为初始密码，请修改密码之后再进行操作');
        // 是否是初始密码存到缓存里面去
        localStorage.setItem("init_password", 'true');
        this.$nextTick(() => {
          this.$refs.PersonInfoDialog.visible = true;
          this.$refs.PersonInfoDialog.handleGetDepartData();
          this.$refs.PersonInfoDialog.getInfo();
        });
        // this.$router.push('/isystem/PersonInfo');
      } else if (userInfo.pwd == 'yes') {
        // console.log('密码不是初始密码');
        localStorage.removeItem('init_password');
      } else {
        // console.log('没有重新登录');
        localStorage.removeItem('init_password');
        this.$store.dispatch('user/resetToken').then(() => {
          // console.log('原来的store',this.$store)
          this.$router.push({'name':'Login'});
          localStorage.clear();
          store.commit("setToken", '');
          // location.reload();
          // console.log('后来的store',this.$store);
          //清除缓存
        })
      }
    }
  },
  watch: {
    token: {
      deep: true,
      immediate: true,
      handler(val) {
        // console.log("token", val);
        if (val) {
          if (this.websock) {
            this.websocketclose();
            this.websock = null;
          }
          //初始化websocket
          this.initWebSocket();
        } else {
          if (this.websock) {
            this.websocketclose();
            this.websock = null;
          }
        }
      },
    },
  },
  destroyed: function () {
    // // 离开页面生命周期函数
    if (this.websock) {
      this.websocketclose();
      this.websock = null;
    }
    window.removeEventListener('beforeunload', e => this.beforeunloadHandler(e))
    window.removeEventListener('unload', e => this.unloadHandler(e))
  },
  methods: {
    beforeunloadHandler() {
      if (this.websock) {
        this.websocketclose();
        this.websock = null;
      }
    },
    unloadHandler(e) {
      if (this.websock) {
        this.websocketclose();
        this.websock = null;
      }
    },
    getMessageQuery() {
      getAction(this.url.query)
        .then((res) => {
          if (res.success) {
            store.commit("setMessageQuery", res.result);
          }
        })
    },
    initWebSocket() {
      let userString = localStorage.getItem("USERINFO");
      let userId = JSON.parse(userString).id;
      // WebSocket与普通的请求所用协议有所不同，ws等同于http，wss等同于https
      if ("WebSocket" in window) {
        console.log("浏览器支持WebSocket", process.env.VUE_APP_API_URL, location.href);
        var url = "";
        if (process.env.VUE_APP_API_URL == "http://8.135.121.105:9000") {
          url =
            "http://sys.do2do.com".replace("http://", "wss://") +
            "/wss/websocket/" +
            userId;
        } else {
          url =
            process.env.VUE_APP_API_URL.replace("http://", "ws://") +
            "/websocket/" +
            userId;
        }
        console.log("websocketurl", location.href, url);
        this.websock = new WebSocket(url);
        this.websock.onopen = this.websocketonopen;
        this.websock.onerror = this.websocketonerror;
        this.websock.onmessage = this.websocketonmessage;
        this.websock.onclose = this.websocketclose;
        console.log(
          url,
          this.websock,
          typeof this.websock,
          JSON.stringify(this.websock.WebSocket),
          "WebSocket连接的url"
        );
        localStorage.setItem("websock", JSON.stringify(this.websock));
        console.log(localStorage.getItem("websock"), "存储");
      } else {
        this.$message({
          type: "warning",
          message: "您的浏览器不支持websocket。",
        });
      }
    },
    websocketonopen(e) {
      let _this = this;
      // 心跳检验
      var heartCheck = {
        timeout: 5000, //5秒
        timeoutObj: null,
        reset: function () {
          clearInterval(_this.timeoutObj);
          return this;
        },
        start: function () {
          let thisHeartCheck = this;
          _this.timeoutObj = setInterval(function () {
            // console.log(_this.websock, "websock心跳检测");
            if (_this.websock && _this.websock.readyState !== 1) {
              thisHeartCheck.reset();
              _this.websocketclose();
            }
          }, this.timeout);
        },
      };
      console.log(e, "WebSocket连接成功");
      heartCheck.reset().start();
    },
    websocketonerror(e) {
      console.log("WebSocket连接发生错误");
      this.websocketclose();
    },
    // actionType  websocket参数 1  数据字典更新
    // 2 - 强制刷新前端页面
    websocketonmessage(e) {
      let data = JSON.parse(e.data);
      console.log(data, "WebSocket收到的消息");
      if (data.actionType && data.actionType == "1") {
        if(data.message == '强制刷新页面'){
          window.location.reload(true);
        } else {
          // 更新sessionStorage缓存
          let dictKeyList = data.message.split(",");
          dictKeyList &&
            dictKeyList.length &&
            dictKeyList.forEach((element) => {
              getAction("/sys/dict/getDictItems/" + element).then((res) => {
                if (
                  res.result &&
                  res.result.length > 0 &&
                  Array.isArray(res.result)
                ) {
                  //存到前端页面缓存中-大于1000条存储到store里面
                  if (res.result.length > 1000) {
                    store.commit("dict/setDict", {
                      key: element,
                      value: res.result,
                    });
                  } else {
                    sessionStorage.setItem(
                      "dict:" + element,
                      JSON.stringify(res.result)
                    );
                  }
                }
              });
            });
        }
        return;
      }
      this.getMessageQuery();
      if (data.type == "0") {
        // 纯文本消息
        this.message = data;
        this.showMessage = true;
        this.messageTime = setTimeout(() => {
          this.showMessage = false;
        }, 5000);
      }
    },
    websocketclose(e) {
      clearInterval(this.timeoutObj);
      console.log(e, this.timeoutObj, "关闭websocket连接");
    },
    handleEditMessage() {
      // 设置为已读按钮
      clearTimeout(this.messageTime);
      this.showMessage = false;
      putAction(this.url.edit, { id: this.message.id }).then((res) => {
        if (res.success) {
          this.$message.success(res.message);
          this.getMessageQuery();
        }
      });
    },
    handleCloseMessage() {
      this.showMessage = false;
      clearTimeout(this.messageTime);
    },
  },
};
</script>

<style lang="scss">
@media screen and (max-width: 1100px) {
  #app {
    width: 1760px;
  }
}
html,
body,
#app {
  //   height: 99% !important;
  margin: 0;
  color: #515a6e;
}
#edui_fixedlayer {
  z-index: 9999 !important;
}
.el-table .el-table__fixed {
  height: auto !important;
  bottom: 16px;
}
</style>
