<template>
  <div class="main-full-content">
    <el-form
      class="input_top"
      ref="form"
      :model="queryForm"
      label-width="100px"
      size="medium"
    >
      <el-card class="box-card">
        <el-row>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="业务员">
              <f-select
                :dict="'sys_user'"
                v-model="queryForm.visitor"
                :is-need="queryForm.visitor"
              >
              </f-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="8">
            <el-form-item label="拜访时间">
              <div class="f-flex">
                <el-date-picker
                  class="f-flex-1"
                  v-model="queryForm.createTime"
                  type="datetime"
                  size="small"
                  placeholder="开始时间"
                  default-time="00:00:00"
                  :picker-options="editStartOptions"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  format="yyyy-MM-dd"
                >
                </el-date-picker>
                <span class="f-p-h-2">~</span>
                <el-date-picker
                  class="f-flex-1"
                  v-model="queryForm.createTimeEnd"
                  type="datetime"
                  size="small"
                  placeholder="结束时间"
                  default-time="23:59:59"
                  :picker-options="editStopOptions"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  format="yyyy-MM-dd"
                >
                </el-date-picker>
              </div>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="拜访方式">
              <f-select
                :dict="'visit_type'"
                v-model="queryForm.visitType"
                :is-need="queryForm.visitType"
              >
              </f-select>
            </el-form-item>
          </el-col>

          <el-col :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label-width="30px">
              <el-button
                      type="primary"
                      @click="handlAddRecord">新增
              </el-button>
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="handleSearch"
                >查询</el-button
              >
              <el-button plain @click="handleReset">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
    </el-form>

    <el-card class="box-card last_card">
<!--      <div class="f-p-b-15">-->
<!--        <el-button-->
<!--          size="medium"-->
<!--          type="primary"-->
<!--          icon="el-icon-download"-->
<!--          @click="handleExportXls"-->
<!--          >导出</el-button-->
<!--        >-->
<!--      </div>-->

      <f-table
        v-loading="loading"
        ref="GTable"
        border
        size="medium"
        row-key="id"
        class="c-th-has-bgcolor"
        :columns="columns"
        :data="tableData"
        :cell-style="{ 'text-align': 'center' }"
        tableHeight="62vh"
        @selection-change="handleSelectionChange"
        @sort-change="handleTableChange"
      >
        <template v-slot="scope">
          <template v-if="scope.column.property === 'action'">
            <!--          <el-button type="text" @click="handleEdit(scope.row)">编辑</el-button>-->
            <!--          <el-button type="text" @click="handDel(scope.row)">删除</el-button>-->
            <!--          <el-button type="text" @click="handRecord(scope.row)" v-if="scope.row.tag=='0'">备案</el-button>-->
            <!--          <el-button type="text" @click="handqxRecord(scope.row)" v-if="scope.row.tag=='1'">取消备案</el-button>-->
          </template>
          <template v-else-if="scope.column.property === 'visitType'">
            <span v-if="scope.row.visitType === '0'">上门拜访</span>
            <span v-else-if="scope.row.visitType === '1'">扫楼</span>
            <span v-else-if="scope.row.visitType === '2'">电话</span>
            <span v-else-if="scope.row.visitType === '3'">微信</span>
            <span v-else>QQ</span>
          </template>
          <template v-else-if="scope.column.property === 'goodsProp'">
            <span>{{parseDict(scope.row.goodsProp,'goodsProp')}}</span>
          </template>
          <template v-else-if="scope.column.property === 'circuStage'">
            <span>{{parseDict(scope.row.circuStage,'circuStage')}}</span>
          </template>
        </template>
      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination
          v-bind="ipagination"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
        <el-dialog
                title="拜访"
                :visible.sync="dialogFormVisible"
                v-if="dialogFormVisible"
                width="40%"
                center
                :close-on-click-modal="false"
        >
          <el-form :model="form" :rules="rules" ref="form">
            <el-row>
              <el-col >
                <el-form-item
                        label="客户名"
                        :label-width="formLabelWidth"
                >
                  <f-select :dict="'ocean_customer_id'"
                            v-model="form.cusId"
                            :isNeed="form.cusId"
                            @changet="getUsername"
                  ></f-select>
                </el-form-item>
              </el-col>
              <el-col >
                <el-form-item
                        label="拜访/沟通方式"
                        prop="visitType"
                        :label-width="formLabelWidth"
                >
                  <f-select
                          v-model="form.visitType"
                          :is-need="form.visitType"
                          :dict="'visit_type'" > </f-select>
                </el-form-item>
              </el-col>
              <el-col >
                <el-form-item
                        label="陪同拜访人"
                        prop="visitor"
                        :label-width="formLabelWidth"
                >
                  <f-select
                          v-model="form.visitor"
                          :isNeed="form.visitor"
                          :dict="'sys_user'" > </f-select>
                </el-form-item>
              </el-col>
<!--              <el-col >-->
<!--                <el-form-item-->
<!--                        label="货量情况"-->
<!--                        prop="volume"-->
<!--                        :label-width="formLabelWidth"-->
<!--                >-->
<!--                  <el-input-->
<!--                          type="textarea"-->
<!--                          v-model="form.volume"-->
<!--                          auto-complete="off"-->
<!--                  ></el-input>-->
<!--                </el-form-item>-->
<!--              </el-col>-->
<!--              <el-col >-->
<!--                <el-form-item-->
<!--                        label="发货周期"-->
<!--                        prop="shippingCycle"-->
<!--                        :rules="form.grade === '新客户' ? rules.shippingCycle : []"-->
<!--                        :label-width="formLabelWidth"-->
<!--                >-->
<!--                  <el-input v-model="form.shippingCycle" autocomplete="off"></el-input>-->
<!--                </el-form-item>-->
<!--              </el-col>-->
              <el-col >
                <el-form-item
                        label="服务商竞品"
                        prop="competitor"
                        :label-width="formLabelWidth"
                >
                  <el-input v-model="form.competitor" autocomplete="off"></el-input>
                </el-form-item>
              </el-col>
              <el-col >
                <el-form-item
                        label="服务痛点和需求"
                        prop="attentionPoint"
                        :label-width="formLabelWidth"
                >
                  <el-input
                          v-model="form.attentionPoint"
                          autocomplete="off"
                          type="textarea"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col >
                <el-form-item  label="目的国家地区"  label-width="110px" prop="destAddr">
                  <el-input v-model="form.destAddr" type="textarea" maxlength="255" show-word-limit></el-input>
                </el-form-item>
              </el-col>
              <!--          业务类型-->
              <el-col >
                <el-form-item label="业务类型"  label-width="110px" >
                  <el-select multiple filterabel clearable v-model="form.bussinessTypeList" @click.native="initBussinessTypeList">
                    <el-option v-for="(item,index) in bussinessTypeList" :key="index" :value="item.value" :label="item.text"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
<!--              <el-col >-->
<!--                <el-form-item-->
<!--                        prop="isNormalShipping"-->
<!--                        label="是否正常发货"-->
<!--                        :label-width="formLabelWidth"-->
<!--                >-->
<!--                  <el-radio-group v-model="form.isNormalShipping">-->
<!--                    <el-radio :label="'1'">是</el-radio>-->
<!--                    <el-radio :label="'0'">否</el-radio>-->
<!--                  </el-radio-group>-->
<!--                </el-form-item>-->
<!--              </el-col>-->
              <el-col>
                <el-form-item
                        label="沟通事项"
                        prop="communicate"
                        :label-width="formLabelWidth"
                >
                  <el-input
                          v-model="form.communicate"
                          autocomplete="off"
                          type="textarea"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col >
                <el-form-item
                        label="沟通结果"
                        prop="communicationResult"
                        :label-width="formLabelWidth"
                >
                  <el-input
                          v-model="form.communicationResult"
                          autocomplete="off"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col>
                <el-form-item
                        label="工作/收款计划"
                        prop="workPlan"
                        :label-width="formLabelWidth"
                >
                  <el-input v-model="form.workPlan" autocomplete="off"></el-input>
                </el-form-item>
              </el-col>
              <el-col>
                <el-form-item
                        label="货物属性"
                        v-if="form.type ==='3'"
                        :label-width="formLabelWidth"
                >
                  <el-checkbox-group v-model="form.goodsPropList">
                    <el-checkbox label="0">普货</el-checkbox>
                    <el-checkbox label="1">带电</el-checkbox>
                    <el-checkbox label="2">纯电</el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
              </el-col>
              <el-col>
                <el-form-item
                        label="物流规格"
                        v-if="form.type ==='3'"
                        :label-width="formLabelWidth"
                >
                  <el-checkbox-group v-model="form.circuStageList">
                    <el-checkbox label="0">整柜</el-checkbox>
                    <el-checkbox label="1">拼箱</el-checkbox>
                    <el-checkbox label="2">电商物流双清到门</el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
              </el-col>
              <el-col>
                <el-form-item
                        label="货量及发货频次"

                        :label-width="formLabelWidth"
                >
                  <el-input v-model="form.goodsHz" show-word-limit maxlength="500" type="textarea"></el-input>
                </el-form-item>
              </el-col>
              <el-col>
                <el-form-item prop="url" label="附件" :label-width="formLabelWidth">
                  <f-upload v-model="form.url" :baifang="form" :dir="'customer'"></f-upload>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisible = false">取 消</el-button>
            <el-button type="primary" @click="saveVisit"
            >确 定</el-button
            >
          </div>
        </el-dialog>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import {postAction,getAction} from "@/api/manage";
import QueryMixins from "@/mixins/QueryMixins.js";

export default {
  name: "VisitModule",
  mixins: [QueryMixins],
  components: {},
  data() {
    return {
      bussinessTypeList:[],
      rules: {
        type:[
          {required:true,message:'请选择类型',trigger:'change'}
        ],
        visitType: [
          { required: true, message: "请选择拜访沟通方式", trigger: "change" },
        ],
        // visitor: [
        //   { required: true, message: "请选择业务人员", trigger: "change" },
        // ],
        shippingCycle: [
          { required: true, message: "请输入发货周期", trigger: "blur" },
          { max: 50, message: "长度不能超过50", trigger: "change" },
        ],
        communicationResult: [
          { required: true, message: "请输入沟通结果", trigger: "blur" },
          { max: 255, message: "长度不能超过255", trigger: "change" },
        ],
        attentionPoint: [
          { required: false, message: "请输入关心的点", trigger: "blur" },
          { max: 255, message: "长度不能超过255", trigger: "change" },
        ],
        workPlan: [
          { required: true, message: "请输入工作/收款计划", trigger: "blur" },
        ],
      },
      dictCodes:['circuStage','goodsProp'],
      formLabelWidth: "120px",
      dialogFormVisible:false,
      ids: [],
      row: {},
      editStartOptions: {
        disabledDate: (time) => {
          if (!this.queryForm.createTimeEnd) {
            // time = new Date(time.replace(/-/g, '/'));
            return time.getTime() < new Date(1970 - 1 - 1).getTime(); //禁止选择1970年以前的日期
          } else {
            return time.getTime() > new Date(this.queryForm.createTimeEnd);
          }
        },
      },
      editStopOptions: {
        disabledDate: (time) => {
          // time = new Date(time.replace(/-/g, '/'));
          return (
            time.getTime() < new Date(this.queryForm.createTime) ||
            time.getTime() < new Date(1970 - 1 - 1).getTime() //禁止选择1970年以前的日期
          );
        },
      },

      assignTitle: "",

      columns: [
        {
          prop: "communicate",
          label: "沟通事项",
          sortable: "custom",
        },

        {
          prop: "visitor",
          label: "业务员",
          sortable: "custom",
        },
        {
          prop: "bussinessType",
          label: "业务类型",

        },
        {
          prop: "destAddr",
          label: "目的国家",

        },
        {
          prop: "createTime",
          label: "拜访时间",
          sortable: "custom",
        },
        {
          prop: "visitType",
          label: "拜访方式",
          sortable: "custom",
        },
        {
          prop: "communicationResult",
          label: "沟通结果",
          sortable: "custom",
          width: 160,
        },
        {
          prop: "goodsProp",
          label: "货物属性",
          sortable: "custom",

        },
        {
          prop: "circuStage",
          label: "物流规格",
          sortable: "custom",

        },
        {
          prop: "goodsHz",
          label: "货量以及发货频次",
          sortable: "custom",

        },
        {
          prop: "workPlan",
          label: "工作/收款计划",
          sortable: "custom",
          width: 160,
        },
      ],
      url: {
        list: "/customer/visitLog/list",
        exportXlsUrl: "/customer/visitLog/exportXlsOcean",
        check: "/customer/userPassById",
        deleteBatch: "",
        approvalBatch: "/customer/userPassBatch",
        fronzenUrl: "/customer/forbidUserById/",
        baturl: "/customer/tuihui",
        batbatch: "/customer/tuihuiBatch",
        getsalesmanlist: "/sys/user/salesman/list",
        getuserlist: "/sys/user/list",
        addVisit: "/customer/visitLog/add",
        getBussinessTypeList:'/sys/dict/getDictItems/ocean_bussiness_type',
      },
    };
  },
  methods: {
    initBussinessTypeList(){
      getAction(this.url.getBussinessTypeList).then(res=>{
        this.bussinessTypeList = res.result;
      })
    },
    getUsername(val){
      this.$set(this.form,'username',val);
    },
    handlAddRecord() {
      //给拜访记录赋值
      this.form={circuStageList:[],goodsPropList: []};
      this.dialogFormVisible = true;
    },
    saveVisit() {
      //保存拜访记录
      this.btnDisable = true;
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (this.form.goodsPropList) {
            this.form.goodsProp = this.form.goodsPropList.join(',')
          }
          if (this.form.circuStageList) {
            this.form.circuStage = this.form.circuStageList.join(',')
          }
          this.form.type ='3';
          postAction(this.url.addVisit, this.form)
                  .then((res) => {
                    if (res.success) {
                      this.$message.success(res.message);
                      this.dialogFormVisible = false;
                      this.handleQuery();
                    } else {
                      this.btnDisable = false;
                    }
                  });
        } else {

        }
      });
    },
    handleEdit({ id }) {
      // this.$router.push(`/customer/Edit?id=${id}`)
    },

    handleBatDel() {
      this.$confirm("确定删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
        closeOnClickModal: false,
      })
        .then(() => {
          // deleteAction(this.url.deleteBatch, {ids: this.selectedRowKeys.join(',')}).then(res => {
          //     this.$message({
          //         type: 'success',
          //         message: res.message
          //     })
          //     this.handleSearch()
          // })
        });
    },

    //初始化获取业务员列表

    handleReset() {
      this.queryForm = {};
      this.queryForm.type = '3';
      this.ipagination.currentPage = 1;

      this.handleQuery();
    },
  },
  created() {
    this.queryForm.type  = '3';
  },
};
</script>

<style lang="scss" scoped>
</style>
