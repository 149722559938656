<template>
    <div class="main-full-content">
        <el-form ref="form" :model="queryForm" :rules="rules" label-width="120px" size="medium">
            <el-card class="box-card">
                <el-row class="inputs_row">
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item prop="code" label="fbacode">
                            <el-input v-model="queryForm.code" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item prop="channelId" label="渠道名称">
                            <f-select v-model="queryForm.channelId" :dict="'transportStatus_enable'" :is-need="queryForm.channelId" ></f-select>
                        </el-form-item>
                    </el-col>
                    <!--<el-col :xs="24" :sm="12" :md="12" :lg="6">-->
                        <!--<el-form-item label="拼柜仓库">-->
                            <!--<f-select v-model="queryForm.lclPlaceId" :dict="'lclPlace'" :is-need="queryForm.lclPlaceId"></f-select>-->
                        <!--</el-form-item>-->
                    <!--</el-col>-->
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item prop="deliveryPointId" label="交货仓库">
                            <f-select v-model="queryForm.deliveryPointId" :dict="'delivery_point'" :is-need="queryForm.deliveryPointId"></f-select>
                        </el-form-item>
                    </el-col>
                  <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <el-form-item  label="目的港">
                      <f-select v-model="queryForm.podEndId" :dict="'tg_port'" :is-need="queryForm.podEndId"></f-select>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <el-form-item  label="报价版本">
                        <el-input v-model="queryForm.priceVersion"></el-input>
                    </el-form-item>
                  </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label-width="20px">
                            <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
                            <el-button plain @click="handleReset">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>
            <el-card class="box-card">
                <f-table
                        v-loading="loading"
                        ref="GTable"
                        border
                        size="medium"
                        row-key="id"
                        class="c-th-has-bgcolor"
                        :columns="columns"
                        :data="tableData"
                        :cell-style="{ 'text-align': 'center' }"
                        tableHeight="73vh"
                        @selection-change="handleSelectionChange"
                        @sort-change="handleTableChange"
                >
                    <template v-slot="scope">

                      <template v-if="scope.column.property === 'hasTax'">
                        <span v-if="scope.row.hasTax==='1'">是</span>
                        <span v-else>否</span>
                      </template>
                    </template>
                </f-table>
                <el-row class="f-text-right f-p-v-8">
                    <el-pagination
                            v-bind="ipagination"
                            layout="total, sizes, prev, pager, next, jumper"
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                    ></el-pagination>
                </el-row>
            </el-card>
        </el-form>

    </div>
</template>

<script>
  import {deleteAction, postAction, putAction} from "@/api/manage";
  import QueryMixins from "../../mixins/QueryMixins";
  import {getAction, getAllSupplier, getBdFeeTypeList} from "../../api/manage";
  import {zhengshuPattern, zhengshuxiaoshuPartten} from "@/utils/pattern";
  import {validateCmb,validateCtn} from "@/utils/validator";

  export default {
        name: "Potential",
        mixins: [QueryMixins],
        data() {
            return {
                dictCodes:['transportStatus','lclPlace','channel_price','shipment_type','shipment_type'],
                rules: {
                    code: [
                        {required: true, message: "请输入fbacode", trigger: "blur"}
                        ],
                    channelId: [
                        {required: true, message: "请选择渠道", trigger: "change"}
                        ],
                    deliveryPointId: [
                        {required: true, message: "请选择交货仓库", trigger: "change"}
                        ],

                },
                feeTemplateList: [],
                emailList: [],
                ids: [],
                row: {},
                form: {},
                formLabelWidth: "120px",
                //控制弹框显示
                dialogFormVisible: false,
                dialogFormVisibleEmail: false,
                columns: [
                    {
                        prop: "action",
                        label: "操作",
                    },
                  {
                    prop: "priceVersion",
                    label: "报价版本",

                  },
                  {
                    prop: "preDay",
                    label: "时效",

                  },
                  {
                    prop: "hasTax",
                    label: "是否包税",

                  },
                  {
                    prop: "country",
                    label: "国家",
                  },
                  {
                    prop: "shipmentType",
                    label: "地址类型",
                  },
                    {
                    prop: "lclPlace",
                    label: "拼柜仓库",

                    },
                {
                    prop: "deliveryPoint",
                    label: "交货仓库",

                },
                    {
                        prop: "channelName",
                        label: "渠道名称",

                    },
                    {
                        prop: "code",
                        label: "fbacode",

                    },
                    {
                        prop: "state",
                        label: "州",

                    },
                    {
                        prop: "unit",
                        label: "单位",

                    },
                    {
                        prop: "startNum",
                        label: "最低消费",

                    },
                    {
                        prop: "endNum",
                        label: "结束值",

                    },
                    {
                        prop: "price",
                        label: "成本价格",

                    },
                  {
                    prop: "salePrice",
                    label: "销售价格",

                  },
                  {
                    prop: "custPrice",
                    label: "官网价格",

                  },
                    {
                        prop: "minPrice",
                        label: "成本红线",

                    },
                  {
                    prop: "cbmKg",
                    label: "体重比",

                  },
                  {
                    prop: "deliveryType",
                    label: "派送方式",

                  },
                  {
                    prop: "podEnd",
                    label: "目的港",

                  },
                  {
                    prop: "stime",
                    label: "有效开始时间",

                  },
                  {
                    prop: "etime",
                    label: "有效结束时间",

                  },
                    {
                        prop: "createBy",
                        label: "创建人",

                    },
                    {
                        prop: "createTime",
                        label: "创建时间",

                    },
                    {
                        prop: "updateBy",
                        label: "修改人",

                    },
                    {
                        prop: "updateTime",
                        label: "修改时间",

                    },
                ],
                url: {
                    list: "/order/channel/price/pageListNoFirstQuery",
                    add: "/order/channel/price/add",
                    edit: "/order/channel/price/edit",
                    getById: "/order/channel/price/queryById",
                    delete: "/order/channel/price/delete",
                    // importFba:'/order/channel/price/importFba',
                    importFba:'/file/read/excel/importChannelPrice',
                    downloadFba:'/order/channel/price/downloadFba',
                    exportXlsUrl:'/order/channel/price/export',

                },
            };
        },
        methods: {
            uploadSectionFile(param) {
                //file就是当前添加的文件
                var fileObj = param.file;
                //FormData 对象
                var form = new FormData();
                //文件对象，key是后台接受的参数名称
                form.append("file", fileObj);
                //执行上传方法
                console.log("执行上传方法");
                postAction(this.url.importFba, form)
                    .then((res) => {
                        //这里做上传之后的操作
                        if (res.success) {
                            this.$message.success(res.message);
                            this.handleQuery();
                        } else {
                            this.handleQuery();
                        }
                    })
            },

            initBdFeeTypeList() {
                if (this.feeTypeList.length <= 0) {
                    this.feeTypeList = [];
                    getBdFeeTypeList("1")
                        .then((res) => {
                            if (res.success) {
                                for (var i = 0; i < res.result.length; i++) {
                                    var obj = {};
                                    obj.value = res.result[i].id;
                                    obj.label = res.result[i].chineseName;
                                    this.feeTypeList.push(obj);
                                }
                            }
                        })
                        .catch((err) => {
                            this.$message.error(err);
                        });
                }
            },

            initAllSupplier() {
                this.allSupplierList = [];
                //获取所有海运供应商
                getAllSupplier("1")
                    .then((res) => {
                        if (res.success) {
                            for (var i = 0; i < res.result.length; i++) {
                                var obj = {};
                                obj.value = res.result[i].id; //供应商ID
                                obj.label = res.result[i].chinaseName; //供应商中文名
                                this.allSupplierList.push(obj);
                            }
                        }
                    })
                    .catch((err) => {
                        this.$message.error(err);
                    });
            },
            //点击加号添加一行数据
            AddListRow() {
                /*  this.feeTemplateList=[];*/
                this.form.feeTemplateList.push({
                    feeId: "",
                    supId: "",
                    count: "",
                    cur: "",
                    price: "",
                });
            },
            handleDelete(index) {
                this.form.feeTemplateList.splice(index, 1);
            },
            handleSearch() {
                this.$refs.form.validate(valid => {
                    if (valid) {
                        this.handleQuery();
                    }
                });

            },
            handleSelectionChange(selection) {
                // 多选
                let arr = [];
                this.emailList = [];
                for (let i = 0; i < selection.length; i++) {
                    arr.push(selection[i]["id"]);
                    this.emailList.push(selection[i]["email"]);
                }
                this.selectionRows = selection;
                this.selectedRowKeys = [...arr];
            },
            handleAdd() {
                this.form = {};
                this.form.feeTemplateList = [];
                this.dialogFormVisible = true;
                this.$refs.form.resetFields();
            },
            handleEdit(row) {
                this.form = JSON.parse(JSON.stringify(row));
                this.initAllSupplier();
                getAction(this.url.getById + "?id=" + row.id).then((res) => {
                    if (res.success) {
                        this.form.feeTemplateList = res.result.feeTemplateList;
                        this.dialogFormVisible = true;
                    }
                });
            },

           async postOrPut(){
              var id = this.form.id;
              // this.form.feeTemplateList = this.form.feeTemplateList;
              //编辑
              if (this.form.id) {
              await  putAction(this.url.edit, this.form).then((res) => {
                  if (res.success) {
                    this.$message.success(res.message);
                    //关闭弹窗
                    this.dialogFormVisible = false;
                    this.handleQuery();
                  }
                });
              } else {
               await postAction(this.url.add, this.form).then((res) => {
                  if (res.success) {
                    this.$message.success(res.message);
                    id = res.id;
                    //关闭弹窗
                    this.dialogFormVisible = false;
                    this.handleQuery();
                  }
                });
              }
            },
            handleSave() {

                this.$refs.form.validate((valid) => {
                    if (valid) {
                      this.postOrPut();
                    }
                });
            },
            handleDele(row) {
                this.$confirm(`确定删除模板`, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "success",
                    closeOnClickModal: false,
                })
                    .then(() => {
                        deleteAction(this.url.delete, {id: row.id}).then((res) => {
                            this.$message({
                                type: "success",
                                message: res.message,
                            });
                            this.handleQuery();
                        });
                    })
                    .catch(() => {
                    });
            },
            handleReset() {
                this.queryForm = {};
                this.ipagination.currentPage = 1;
                this.handleQuery();
            },
        },
        created() {
            this.queryForm.initFeeType = true;
            this.queryForm.initAllSupplier = true;
        },
    };
</script>
<style lang="scss" scoped>
.addEditBox {
  max-height: 625px;
  overflow: scroll;
}
</style>
