<!--渠道设置-->
<template>
    <div class="main-full-content">
        <el-form  ref="form" :model="queryForm" label-width="120px" size="medium">
            <el-card class="box-card">
                <el-row>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="渠道名称">
                            <el-input v-model="queryForm.channelName" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="渠道编码">
                            <el-input v-model="queryForm.channelCode" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="国家">
                            <f-select v-model="queryForm.endAddress" :is-need="queryForm.endAddress" :dict="'bd_country'" @keyup.enter.native="handleSearch"></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label-width="30px">
                            <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
                            <el-button plain @click="handleReset">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>
        </el-form>
        <el-card class="box-card last_card">
            <div class="f-p-b-15">
                <el-button type="primary" size="medium" icon="el-icon-plus" @click="handleAdd"

                           v-if="buttonShowList['新增']"
                >新增
                </el-button>
            </div>
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="66vh"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange">
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'action'">
                        <el-button type="text" @click="handleEdit(scope.row)"

                                   v-if="buttonShowList['编辑']"
                        >编辑
                        </el-button>
                        <el-button type="text" @click="handleDele(scope.row)"

                                   v-if="buttonShowList['删除']"
                        >删除
                        </el-button>
                    </template>
                    <template v-else-if="scope.column.property === 'cabinetType'">
                        <span v-if="scope.row.cabinetType === '0'">自拼</span>
                        <span v-else>非拼</span>
                    </template>
                    <template v-else-if="scope.column.property === 'nameType'">
                        <span v-if="scope.row.nameType === '0'">普货</span>
                        <span v-else>电玩</span>
                    </template>
                    <template v-else-if="scope.column.property === 'isTax'">
                        <span v-if="scope.row.isTax === '1'">是</span>
                        <span v-else>否</span>
                    </template>
                    <template v-else-if="scope.column.property === 'bondVat'">
                        <span v-if="scope.row.bondVat === '0'">需要</span>
                        <span v-if="scope.row.bondVat === '1'">不需要</span>
                    </template>
                    <template v-else-if="scope.column.property === 'drawback'">
                        <span v-if="scope.row.drawback === '0'">接受</span>
                        <span v-if="scope.row.drawback === '1'">不接受</span>
                    </template>
                    <template v-else-if="scope.column.property === 'hasTax'">
                        <span>{{parseDict(scope.row.hasTax,'is_default')}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'isEnabled'">
                      <span>{{parseDict(scope.row.isEnabled,'is_default')}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'rangeType'">
                        <span>{{parseDict(scope.row.rangeType,'fba_solicitation')}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'podEndText'">
                        <span>{{parseDict(scope.row.podEnd,'bd_port')}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'cabinetAddress'">
                        <span>{{parseDict(scope.row.cabinetAddress,'lclPlace')}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'supplierIds'">
                        <span>{{parseDict(scope.row.supplierIds,'os_contact')}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'warehouse'">
                        <span>{{parseDict(scope.row.warehouse,'delivery_point')}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'detainWarehouse'">
                        <span>{{parseDict(scope.row.detainWarehouse,'delivery_point')}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'maintainBy'">
                        <span>{{parseDict(scope.row.maintainBy,'scqd_id')}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'ossIds'">
                        <el-button type="text" @click="handleSee(scope.row)">下载</el-button>
                    </template>
                    <template v-else-if="scope.column.property === 'costOssIds'">
                        <el-button type="text" @click="handleSeeCost(scope.row)">下载</el-button>
                    </template>
                  <template v-else-if="scope.column.property === 'isHaiyun'">
                    <span>{{parseDict(scope.row.isHaiyun,'is_haiyun')}}</span>
                  </template>
                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange">
                </el-pagination>
            </el-row>
        </el-card>

        <el-dialog :title="form.id?'编辑渠道':'新增渠道'" :visible.sync="dialogFormVisible" :close-on-click-modal="false"
                   v-if="dialogFormVisible">
            <el-form class="addEditBox" :model="form" :rules="rules" ref="form">
                <el-row class="inputs_row">
                  <el-col :span="12">
                    <el-form-item label="是否启用" prop="isEnabled" :label-width="formLabelWidth">
                      <f-select v-model="form.isEnabled"
                                :isNeed="form.isEnabled"
                                placeholder="请选择" filterable clearable :dict="'is_default'">
                      </f-select>
                    </el-form-item>
                  </el-col>
                    <el-col :span="12">
                        <el-form-item label="渠道名称" :label-width="formLabelWidth" prop="channelName">
                            <el-input v-model="form.channelName" autocomplete="off"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="渠道编码" :label-width="formLabelWidth" prop="channelCode">
                            <el-input v-model="form.channelCode" autocomplete="off" disabled></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="基本代码" :label-width="formLabelWidth" prop="baseCode">
                            <el-input v-model="form.baseCode" autocomplete="off" @change="generateCode"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="航线名称" :label-width="formLabelWidth" prop="rangeType">
                            <f-select clearable filterable class="f-full-width"
                                      :dict="'fba_solicitation'"

                                      v-model="form.rangeType"
                                      :isNeed="form.rangeType"
                                      placeholder="请选择">
                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="拼柜方式" :label-width="formLabelWidth" prop="cabinetType">
                            <f-select :isNeed="form.cabinetType"
                                      v-model="form.cabinetType"

                                      placeholder="请选择" filterable clearable :dict="'cabinetType'">

                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="交货仓库" :label-width="formLabelWidth" prop="warehouse">
                            <f-select  clearable filterable class="f-full-width"
                                      :dict="'delivery_point'"
                                      v-model="form.warehouse"
                                      :isNeed="form.warehouse"
                                      placeholder="请选择">
                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="扣货仓库" :label-width="formLabelWidth" prop="detainWarehouse">
                            <f-select  clearable filterable class="f-full-width"
                                      :dict="'delivery_point'"
                                      v-model="form.detainWarehouse"
                                      :isNeed="form.detainWarehouse"
                                      placeholder="请选择">
                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="拼柜仓库" :label-width="formLabelWidth" prop="cabinetAddressList">
                            <el-select clearable filterable class="f-full-width"
                                       @change="getCabaddress"
                                       v-model="form.cabinetAddressList" @click.native="initChannelList"
                                       multiple
                                       placeholder="请选择">
                                <el-option v-for="(item,index) in channelSetList" :key="index" :value="item.id"
                                           :label="item.content"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>

                    <el-col :span="12">
                        <el-form-item label="起运地" :label-width="formLabelWidth" prop="startAddress">
                            <f-select  clearable filterable class="f-full-width"
                                      :dict="'bd_country'"
                                      v-model="form.startAddress"
                                      :isNeed="form.startAddress"
                                      @changet="getqiyun"
                                      @change="generateCode"
                                      placeholder="请选择">
                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="目的地" :label-width="formLabelWidth" prop="endAddress">
                            <f-select  clearable filterable class="f-full-width"
                                      :dict="'bd_country'"
                                      v-model="form.endAddress"
                                      :isNeed="form.endAddress"
                                      @changet="getmudi"
                                      @change="generateCode"
                                      placeholder="请选择">
                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
`                        <el-form-item label="国家" :label-width="formLabelWidth" prop="countryList">
                            <f-select :dict="'bd_country'"  :is-need="form.countryList"
                                      :multiple="true" v-model="form.countryList" autocomplete="off"></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="品名类型" :label-width="formLabelWidth" prop="nameType">
                            <f-select  clearable filterable class="f-full-width"
                                      :dict="'product_type'"
                                      v-model="form.nameType"
                                      :isNeed="form.nameType"
                                      @changet="getpinming"
                                      @change="generateCode"
                                      placeholder="请选择">
                            </f-select>
                        </el-form-item>
                    </el-col>

                    <el-col :span="12">
                        <el-form-item label="客户BOND/VAT" :label-width="formLabelWidth" prop="bondVat">
                            <f-select
                                      v-model="form.bondVat"
                                      :isNeed="form.bondVat"
                                      placeholder="请选择" filterable clearable :dict="'bondVat'"
                                      @changet="getbond" @change="generateCode">
                            </f-select>
                        </el-form-item>
                    </el-col>
                     <el-col :span="12">
                        <el-form-item label="是否接受退税报关" :label-width="formLabelWidth" prop="drawback">
                            <f-select
                                      v-model="form.drawback"
                                      :isNeed="form.drawback"
                                      placeholder="请选择" filterable clearable :dict="'drawback'">
                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="优势" :label-width="formLabelWidth" prop="advantage">
                          <el-input v-model="form.advantage" autocomplete="off" type="textarea"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="劣势" :label-width="formLabelWidth" prop="inferiority">
                          <el-input v-model="form.inferiority" autocomplete="off" type="textarea"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="内部渠道说明" :label-width="formLabelWidth"
                                    prop="innerRemark">
                          <el-input v-model="form.innerRemark" autocomplete="off" type="textarea"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="外部渠道说明" :label-width="formLabelWidth"
                                    prop="outRemark">
                          <el-input v-model="form.outRemark" autocomplete="off" type="textarea"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="订单类型" :label-width="formLabelWidth" prop="isHaiyun">
                          <f-select v-model="form.isHaiyun" :dict="'is_haiyun'" :father-val="form.isHaiyun"
                                    :is-need="form.isHaiyun" @change="initFclAndLcl"></f-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12" v-if="form.isHaiyun === '0'">
                      <el-form-item label="运输方式" :label-width="formLabelWidth" prop="hySendType">
                          <f-select v-model="form.hySendType" :dict="'lcl_send_type'" :father-val="form.hySendType"
                                    :is-need="form.hySendType"></f-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12" v-if="form.isHaiyun === '0'">
                      <el-form-item label="业务类型" :label-width="formLabelWidth" prop="hyBussinessTypeList">
                          <el-select v-model="form.hyBussinessTypeList" clearable filterable multiple
                                      @change="gethyBussinessType" @click.native="initFclAndLcl">
                              <el-option v-for="(item,index) in lclBussinessTypeList" :value="item.value"
                                          :label="item.text" :key="index"></el-option>
                          </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12" v-if="form.isHaiyun === '0' && form.hyBussinessType && form.hyBussinessType.indexOf('4')!==-1">
                      <el-form-item label="派送方式" :label-width="formLabelWidth" prop="hyDeliveryType">
                          <f-select v-model="form.hyDeliveryType" :dict="'lcl_delivery_type'"
                                    :father-val="form.hyDeliveryType" :is-need="form.hyDeliveryType"></f-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12" v-if="form.isHaiyun === '1'">
                      <el-form-item label="运输方式1" :label-width="formLabelWidth" prop="hySendType">
                          <f-select v-model="form.hySendType" :dict="'fcl_send_type'" :father-val="form.hySendType"
                                    :is-need="form.hySendType"></f-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12" v-if="form.isHaiyun === '1'">
                      <el-form-item label="业务类型" :label-width="formLabelWidth" prop="hyBussinessTypeList">
                          <el-select v-model="form.hyBussinessTypeList" clearable filterable multiple
                                      @change="gethyBussinessType" @click.native="initFclAndLcl">
                              <el-option v-for="(item,index) in fclBussinessTypeList" :value="item.value"
                                          :label="item.text" :key="index"></el-option>
                          </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12" v-if="form.isHaiyun === '1' && form.hyBussinessType && form.hyBussinessType.indexOf('4')!==-1">
                      <el-form-item label="派送方式" :label-width="formLabelWidth" prop="hyDeliveryType">
                          <f-select v-model="form.hyDeliveryType" :dict="'fcl_delivery_type'"
                                    :father-val="form.hyDeliveryType" :is-need="form.hyDeliveryType"></f-select>
                      </el-form-item>
                    </el-col>
                        <!--                    <el-form-item label="空运运输方式" :label-width="formLabelWidth" prop="hySendType">-->
                        <!--                        <f-select v-model="form.hySendType" :dict="'air_send_type'" :father-val="form.hySendType" :is-need="form.hySendType"></f-select>-->
                        <!--                    </el-form-item>-->
                    <el-col :span="12" v-if="form.isHaiyun === '2'">
                      <el-form-item label="业务类型" :label-width="formLabelWidth" prop="hyBussinessTypeList">
                          <el-select v-model="form.hyBussinessTypeList" clearable filterable multiple
                                      @change="gethyBussinessType" @click.native="initFclAndLcl">
                              <el-option v-for="(item,index) in airBussinessTypeList" :value="item.value"
                                          :label="item.text" :key="index"></el-option>
                          </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12" v-if="form.isHaiyun === '2' && form.hyBussinessType && form.hyBussinessType.indexOf('4')!==-1">
                      <el-form-item label="派送方式" :label-width="formLabelWidth" prop="hyDeliveryType">
                          <f-select v-model="form.hyDeliveryType" :dict="'air_delivery_type'"
                                    :father-val="form.hyDeliveryType" :is-need="form.hyDeliveryType"></f-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="是否包税" :label-width="formLabelWidth" >
                          <f-select :dict="'is_default'" v-model="form.hasTaxList" :is-need="form.hasTaxList" :multiple="true"></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="是否递延" :label-width="formLabelWidth" >
                            <el-select v-model="form.isDeferred">
                                <el-option value="0" label="不递延"></el-option>
                                <el-option value="1" label="递延"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="目的港" :label-width="formLabelWidth" prop="podEndList">
                            <f-select v-model="form.podEndList" :dict="'bd_port'" :father-val="form.podEndList"
                                  :is-need="form.podEndList" @change="initFclAndLcl" :multiple="true"></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="体重比" :label-width="formLabelWidth" prop="weightRatio">
                          <el-input v-model="form.weightRatio" autocomplete="off"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="操作SOP" :label-width="formLabelWidth" prop="operatorSop">
                            <el-input v-model="form.operatorSop" autocomplete="off" type="textarea"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="销售SOP" :label-width="formLabelWidth" prop="salesmanSop">
                          <el-input v-model="form.salesmanSop" autocomplete="off" type="textarea"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="客服SOP" :label-width="formLabelWidth" prop="docSop">
                            <el-input v-model="form.docSop" autocomplete="off" type="textarea"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="渠道维护人" :label-width="formLabelWidth" prop="maintainBy">
                            <f-select  clearable filterable class="f-full-width"
                                      :dict="'scqd_id'"
                                      v-model="form.maintainBy"
                                      :isNeed="form.maintainBy"
                                      placeholder="请选择">
                            </f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="代理商" :label-width="formLabelWidth" prop="chooseSupplierList">
                            <el-select clearable filterable class="f-full-width"
                                       v-model="form.chooseSupplierList" @click.native="initSupplierList"
                                       multiple
                                       placeholder="请选择">
                                <el-option v-for="(item,index) in allSupplierList" :key="index" :value="item.id"
                                           :label="item.chinaseName"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                  <el-col :span="12">
                    <el-form-item label="包税计费单位" :label-width="formLabelWidth" prop="taxUnit">
                      <f-select  clearable filterable class="f-full-width"
                                 :dict="'per_price_unit'"
                                 v-model="form.taxUnit"
                                 :isNeed="form.taxUnit"
                                 placeholder="请选择">
                      </f-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="不包税计费单位" :label-width="formLabelWidth" prop="noTaxUnit">
                      <f-select  clearable filterable class="f-full-width"
                                 :dict="'per_price_unit'"
                                 v-model="form.noTaxUnit"
                                 :isNeed="form.noTaxUnit"
                                 placeholder="请选择">
                      </f-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="排序" :label-width="formLabelWidth" prop="orderNum">
                      <el-input v-model="form.orderNum" type="number" onkeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))" ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="最低限重" :label-width="formLabelWidth" prop="noTaxUnit">
                      <el-input v-model="form.minKg" type="number"></el-input>
                    </el-form-item>
                  </el-col>
                    <el-col :span="12">
                        <el-form-item prop="fileList" :label-width="formLabelWidth" label="同行附件上传">
                            <f-upload v-model="form.fileList" :dir="'channel'"></f-upload>
                        </el-form-item>
                    </el-col>

                    <el-col :span="12">
                        <el-form-item prop="costFileList" :label-width="formLabelWidth" label="成本附件上传">
                            <f-upload v-model="form.costFileList" :dir="'channel'"></f-upload>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item prop="minCbmNum" :label-width="formLabelWidth" label="商业地址/住宅地址最低消费-cbm">
                            <el-input v-model="form.minCbmNum" type="number"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item prop="minKgNum" :label-width="formLabelWidth" label="商业地址/住宅地址最低消费-kg">
                            <el-input v-model="form.minKgNum" type="number"></el-input>
                        </el-form-item>
                    </el-col>
                  <el-col :span="12">
                    <el-form-item prop="allowYubaoKaidan" :label-width="formLabelWidth" label="是否允许前置打单">
                      <f-select
                          v-model="form.allowYubaoKaidan"
                          :is-need="form.allowYubaoKaidan"
                          :dict="'is_default'"
                      ></f-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item prop="detentionCargo" :label-width="formLabelWidth" label="是否可扣住货">
                      <f-select
                          v-model="form.detentionCargo"
                          :is-need="form.detentionCargo"
                          :dict="'is_default'"
                      ></f-select>
                    </el-form-item>
                  </el-col>
                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="medium" @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" size="medium" @click="handleSave">确 定</el-button>
            </div>
        </el-dialog>

    </div>
</template>

<script>
    import {deleteAction, postAction, putAction} from '@/api/manage'
    import QueryMixins from "../../mixins/QueryMixins";
    import {getAction, getAllSupplier} from "../../api/manage";
    import {updatedDict} from "../../utils/util";

    export default {
        name: 'Potential',
        mixins: [QueryMixins],
        data() {
            return {
                fsee: false,
                /*汽运国家二字代码，目的国家二字代码，基本代码，是否FBA 品名类型 包税*/
                qiyun: '',
                mudi: '',
                isFba: '',
                pinming: '',
                bond: '',
                dictCodes: ['bondVat', 'cabinetType', 'fba_solicitation', 'lclPlace', 'delivery_point', 'scqd_id', 'os_contact', 'bd_port','is_haiyun',"is_default"],
                rules: {
                    channelName: [
                        {required: true, message: '请输入渠道名称', trigger: 'change'},
                        {max: 100, message: '长度不能超过100', trigger: 'change'},
                    ],
                  isEnabled: [
                    {required: true, message: '请选择是否启用', trigger: 'change'},
                  ],
                    channelCode: [
                        {required: true, message: '请输入渠道编码', trigger: 'change'},
                    ],
                    hySendType: [
                        {required: true, message: '请选择运输方式', trigger: 'change'},
                    ],
                    isHaiyun: [
                        {required: true, message: '请选择订单类型', trigger: 'change'},
                    ],
                    hyDeliveryType: [
                        {required: true, message: '请选择派送方式', trigger: 'change'},
                    ],
                    hyBussinessTypeList: [
                        {required: true, message: '请选择业务类型', trigger: 'change'},
                    ],

                    baseCode: [
                        {required: true, message: '请输入基本代码', trigger: 'change'},
                        {max: 50, message: '长度不能超过50', trigger: 'change'},
                    ],
                    rangeType: [
                        {required: true, message: '请输入航线名称', trigger: 'change'},
                    ],
                    cabinetType: [
                        {required: true, message: '请选择拼柜方式', trigger: 'change'},
                    ],
                    warehouse: [
                        {required: true, message: '请选择交货仓库', trigger: 'change'},
                    ],
                    cabinetAddressList: [
                        {required: true, message: '请选择拼柜仓库', trigger: 'change'},
                    ],
                    startAddress: [
                        {required: true, message: '请选择起运地', trigger: 'change'},
                    ],
                    endAddress: [
                        {required: true, message: '请选择目的地', trigger: 'change'},
                    ],
                    nameType: [
                        {required: true, message: '请选择品名类型', trigger: 'change'},
                    ],
                    bondVat: [
                        {required: true, message: '请选择', trigger: 'change'},
                    ],
                    drawback: [
                        {required: true, message: '请选择', trigger: 'change'},
                    ],
                    isFba: [
                        {required: true, message: '请选择', trigger: 'change'},
                    ],
                    isTax: [
                        {required: true, message: '请选择', trigger: 'change'},
                    ],
                    deliveryType: [
                        {required: true, message: '请选择', trigger: 'change'},
                    ],
                    advantage: [
                        {required: true, message: '请输入优势', trigger: 'change'},
                        {max: 255, message: '长度不能超过255', trigger: 'change'},
                    ],
                    inferiority: [
                        {required: true, message: '请输入劣势', trigger: 'change'},
                        {max: 255, message: '长度不能超过255', trigger: 'change'},
                    ],
                    outRemark: [
                        {required: true, message: '请输入外部渠道说明', trigger: 'change'},
                        {max: 255, message: '长度不能超过255', trigger: 'change'},
                    ],
                    innerRemark: [
                        {required: true, message: '请输入内部渠道说明', trigger: 'change'},
                        {max: 255, message: '长度不能超过255', trigger: 'change'},
                    ],
                    // hasTax: [
                    //     {required: true, message: '请选择是否税', trigger: 'change'},
                    // ],
                    podEndList: [
                        {required: true, message: '请选择目的港', trigger: 'change'},
                    ],
                    weightRatio: [
                        {required: true, message: '请填写体重比', trigger: 'change'},
                    ],
                },
                emailList: [],
                ids: [],
                row: {},
                form: {},
                formLabelWidth: '150px',
                channelSetList: [],
                allSupplierList: [],
                //控制弹框显示
                dialogFormVisible: false,
                columns: [
                    {
                        prop: 'action',
                        label: '操作',
                        width: 100,
                        fixed: 'left'
                    },
                  {
                    prop: 'orderNum',
                    label: '排序',

                    fixed: 'left'
                  },
                  {
                    prop: 'isEnabled',
                    label: '是否启用',
                    fixed: 'left'
                  },
                    {
                        prop: 'channelName',
                        label: '渠道名称',

                        width: 140,
                        fixed: 'left'
                    },
                    {
                        prop: 'channelCode',
                        label: '渠道编码',

                        width: 140,
                        fixed: 'left'
                    },
                  {
                    prop: 'isHaiyun',
                    label: '订单类型',

                    width: 100
                  },
                    {
                        prop: 'cabinetType',
                        label: '拼柜方式',

                        width: 100
                    },
                    {
                        prop: 'rangeTypeText',
                        label: '航线名称',

                        width: 100
                    },
                    {
                        prop: 'cabinetAddress',
                        label: '拼柜仓库',
                        width: 150
                    },
                    {
                        prop: 'warehouse',
                        label: '交货仓库',

                        width: 100
                    },
                    {
                        prop: 'detainWarehouse',
                        label: '扣货仓库',

                        width: 100
                    },
                    {
                        prop: 'nameType',
                        label: '产品类型',

                        width: 100
                    },
                    {
                        prop: 'bondVat',
                        label: '客户BOND/VAT',

                        minWidth: 140
                    },
                    {
                        prop: 'drawback',
                        label: '是否接受退税报关',

                        minWidth: 130
                    },
                    {
                        prop: 'hasTax',
                        label: '是否包税',

                        minWidth: 80
                    },
                  {
                    prop: 'taxUnit',
                    label: '包税单位',

                    minWidth: 70
                  },
                  {
                    prop: 'noTaxUnit',
                    label: '不包税单位',

                    minWidth: 70
                  },
                    {
                        prop: 'podEndText',
                        label: '目的港',

                        minWidth: 200,
                    },
                    {
                        prop: 'weightRatio',
                        label: '体重比',

                        minWidth: 90
                    },
                    {
                        prop: 'maintainBy',
                        label: '渠道维护人',

                    },
                    {
                        prop: 'supplierIds',
                        label: '代理商',

                        minWidth: 120
                    },
                  {
                    prop: 'minKg',
                    label: '最低限重',
                  },
                    {
                        prop: 'createTime',
                        label: '创建时间',

                        minWidth: 120
                    },
                    {
                        prop: 'ossIds',
                        label: '同行附件',

                        minWidth: 100
                    },
                    {
                        prop: 'costOssIds',
                        label: '成本附件',

                        minWidth: 100
                    },
                ],
                url: {
                    list: '/sys/channelSet/list',
                    add: '/sys/channelSet/add',
                    edit: '/sys/channelSet/edit',
                    getById: '/sys/channelSet/queryById',
                    delete: '/sys/channelSet/delete',
                    getFclBussinessTypeList: '/sys/dict/getDictItems/fcl_bussiness_type',
                    getLclBussinessTypeList: '/sys/dict/getDictItems/lcl_bussiness_type',
                    getAirBussinessTypeList: '/sys/dict/getDictItems/air_bussiness_type',
                    batchDownload: "/sys/oss/file/batchDownloadByIds",  //下载文件

                },
                fclBussinessTypeList: [],
                lclBussinessTypeList: [],
                airBussinessTypeList: [],
                bondVat: [{
                    value: '0', label: 'BOND'
                }, {
                    value: '1', label: 'VAT'
                }, {
                    value: '2', label: '递延'
                }],
                cabinetType: [{
                    value: '0', label: '自拼'
                }, {
                    value: '1', label: '非拼'
                }],
            }
        },
      computed: {
        buttonShowList() {
          return {
            '新增': this.$btnIsShow('channel/ChannelSet','1','新增'),
            '编辑': this.$btnIsShow('channel/ChannelSet','1','编辑'),
            '删除': this.$btnIsShow('channel/ChannelSet','1','删除'),
          }
        }
      },
        methods: {
            initChannelList() {
                getAction('/sys/deliverpoint/getList', {type: '1'})
                    .then(res => {
                        this.channelSetList = res.result;
                    })
            },
            initSupplierList() {
                //获取所有海运供应商
                getAllSupplier().then((res) => {
                    if (res.success) {
                        this.allSupplierList = res.result;
                        if (this.form.supplierIds) {
                            this.$set(this.form, 'chooseSupplierList', this.form.supplierIds.split(','));
                        } else {
                            this.$set(this.form, 'chooseSupplierList', []);
                        }
                    }
                })
                    .catch((err) => {
                        this.$message.error(err);
                    });
            },
            gethyBussinessType(hyBussinessTypeList) {
                if (hyBussinessTypeList && hyBussinessTypeList.length && Array.isArray(hyBussinessTypeList)) {
                    this.$set(this.form, 'hyBussinessType', hyBussinessTypeList.join(','))
                }
            },
            getCabaddress(cadAddressList) {
                if (cadAddressList && cadAddressList.length && Array.isArray(cadAddressList)) {
                    this.$set(this.form, 'cabinetAddress', cadAddressList.join(','))
                }
            },
            getqiyun(val) {
                if (val) {
                    this.qiyun = val.split('-')[0];
                }
            },
            getmudi(val) {
                if (val) {
                    this.mudi = val.split('-')[0];
                }
            },
            getpinming(val) {
                if (val) {
                    this.pinming = val == '普货' ? 'G' : 'S';
                }
            },
            getbond(val) {
                //如果不是包税  规则 有待改进？？zml

                    if (val == '需要') {
                        this.bondVat = 'B';
                    } else if (val == '不需要') {
                        this.bondVat = 'V';
                    }


            },
            /*根据规则生成渠道编码*/
            generateCode() {
                console.log("根据规则生成渠道编码")
                if (this.form.isFba == '1') {
                    this.isFba = 'F';
                } else if (this.form.isFba == '0') {
                    this.isFba = 'NF';
                }
                if (this.form.isTax == '1') {
                    this.bondVat = 'I4P';
                }
                console.log('生成渠道编码',this.qiyun,this.mudi,this.form.baseCode,this.pinming,this.bondVat)
                if (this.qiyun && this.mudi && this.form.baseCode && this.pinming && this.bondVat) { /*&& this.isFba*/
                    console.log("根据规则生成渠道编码111111")
                    this.$set(this.form, 'channelCode', this.qiyun + this.mudi + this.form.baseCode
                        + this.isFba + this.pinming + this.bondVat + Math.floor(Math.random()*10000));
                }
                console.log("根据规则生成渠道编码4343")

            },
            handleSelectionChange(selection) { // 多选
                let arr = []
                this.emailList = [];
                for (let i = 0; i < selection.length; i++) {
                    arr.push(selection[i]['id'])
                    this.emailList.push(selection[i]['email']);
                }
                this.selectionRows = selection
                this.selectedRowKeys = [...arr]
            },
            handleAdd() {
                if (this.$refs['form']) {
                    this.$refs['form'].clearValidate();
                }
                this.$set(this, 'form', {});
                this.$set(this.form, 'hyBussinessTypeList', []);
                this.$set(this.form, 'cabinetAddressList', []);
                this.$set(this.form, 'podEndList', []);
                this.$set(this.form, 'fileList', []);
                this.$set(this.form, 'costFileList', []);
                this.$set(this.form, 'chooseSupplierList', []);
                this.$set(this.form, 'hasTaxList', []);
                this.fsee = true;
                this.bondVat = '';
                this.pinming = '';
                this.qiyun = '';
                this.mudi = '';
                this.isFba = '';
                this.initFclAndLcl();
                this.dialogFormVisible = true;
              updatedDict("channel_set,fcl_transport_status");
            },
            handleEdit(row) {
                getAction(this.url.getById + '?id=' + row.id).then(res => {
                    if (res.success) {
                        this.form = res.result;

                        if (this.form.hyBussinessType) {
                            this.$set(this.form, 'hyBussinessTypeList', this.form.hyBussinessType.split(','));
                        } else {
                            this.$set(this.form, 'hyBussinessTypeList', []);
                        }
                        if (this.form.cabinetAddress) {
                            this.$set(this.form, 'cabinetAddressList', this.form.cabinetAddress.split(','));
                        } else {
                            this.$set(this.form, 'cabinetAddressList', []);
                        }
                        if (this.form.podEnd) {
                            this.$set(this.form, 'podEndList', this.form.podEnd.split(','));
                        } else {
                            this.$set(this.form, 'podEndList', []);
                        }
                      if (this.form.hasTax) {
                        this.$set(this.form, 'hasTaxList', this.form.hasTax.split(','));
                      } else {
                        this.$set(this.form, 'hasTaxList', []);
                      }
                        this.initSupplierList();
                        console.log(this.form.podEnd,'podEnd')
                        if (res.result.fileList && res.result.fileList.length > 0) {
                            this.$set(this.form, 'fileList', res.result.fileList);
                        } else {
                            this.$set(this.form, 'fileList', []);
                        }
                        if (res.result.costFileList && res.result.costFileList.length > 0) {
                            this.$set(this.form, 'costFileList', res.result.costFileList);
                        } else {
                            this.$set(this.form, 'costFileList', []);
                        }

                        this.fsee = true;
                        this.initFclAndLcl();
                        this.initChannelList();
                        this.dialogFormVisible = true;
                        this.bondVat = '';
                        this.pinming = '';
                        this.qiyun = '';
                        this.mudi = '';
                        this.isFba = '';

                        console.log("this.form", this.form)
                      updatedDict("channel_set,fcl_transport_status");
                    }
                })


            },
            handleSee(row) {
                if (row.ossIds) {
                    var arrstr = row.ossIds.split(",");
                    if (null == arrstr || arrstr.length <= 0) {
                        this.$message.warning("暂无附件");
                    } else {
                        postAction(this.url.batchDownload, arrstr, {
                            responseType: "blob",
                        }).then((response) => {
                            let u = window.URL.createObjectURL(response);
                            let aLink = document.createElement("a");
                            aLink.style.display = "none";
                            aLink.href = u;
                            aLink.setAttribute("download", row.channelName + "的同行附件.zip");
                            document.body.appendChild(aLink);
                            aLink.click();
                            document.body.removeChild(aLink);
                            window.URL.revokeObjectURL(u);
                        });
                    }
                } else {
                    this.$message.warning("暂无附件");
                }


            },
            handleSeeCost(row) {
                if (row.costOssIds) {
                    var arrstr = row.costOssIds.split(",");
                    if (null == arrstr || arrstr.length <= 0) {
                        this.$message.warning("暂无附件");
                    } else {
                        postAction(this.url.batchDownload, arrstr, {
                            responseType: "blob",
                        }).then((response) => {
                            let u = window.URL.createObjectURL(response);
                            let aLink = document.createElement("a");
                            aLink.style.display = "none";
                            aLink.href = u;
                            aLink.setAttribute("download", row.channelName + "的成本附件.zip");
                            document.body.appendChild(aLink);
                            aLink.click();
                            document.body.removeChild(aLink);
                            window.URL.revokeObjectURL(u);
                        });
                    }
                } else {
                    this.$message.warning("暂无附件");
                }
            },
            handleSave() {
              console.log(this.form,'this.form')
                this.$refs.form.validate(valid => {
                    if (valid) {
                        /*将filelist的id取出来拼成ids给form*/
                        if (this.form.fileList) {
                            this.$set(this.form, 'ossIds', this.form.fileList.map(function (e) {
                                return e.id
                            }).join(","));
                        }
                        if (this.form.costFileList) {
                            this.$set(this.form, 'costOssIds', this.form.costFileList.map(function (e) {
                                return e.id
                            }).join(","));
                        }
                        if (this.form.podEndList) {
                            this.$set(this.form, 'podEnd', this.form.podEndList.join(','));
                        }
                        if (this.form.chooseSupplierList) {
                            this.$set(this.form, 'supplierIds', this.form.chooseSupplierList.join(','));
                        }
                        //编辑
                        if (this.form.id) {
                            putAction(this.url.edit, this.form)
                                .then((res) => {
                                    if (res.success) {
                                        this.$message.success(res.message);
                                        //关闭弹窗
                                        this.dialogFormVisible = false;
                                        sessionStorage.removeItem("dict:transportStatus");
                                        this.handleQuery()
                                    }
                                })
                        } else {
                            postAction(this.url.add, this.form)
                                .then((res) => {
                                    if (res.success) {
                                        this.$message.success(res.message);
                                        //关闭弹窗
                                        this.dialogFormVisible = false;
                                        sessionStorage.removeItem("dict:transportStatus");
                                        this.handleQuery()
                                    }
                                })
                        }
                    }
                })

            },
            handleDele(row) {
                this.$confirm(`确定删除渠道${row.channelName}`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'success',
                    closeOnClickModal: false,
                }).then(() => {
                    deleteAction(this.url.delete, {id: row.id}).then(res => {
                        this.$message({
                            type: 'success',
                            message: res.message
                        })
                        this.handleQuery();
                        sessionStorage.removeItem("dict:transportStatus");
                    })
                }).catch(() => {

                })
            },
            handleReset() {
                this.queryForm = {};
                this.ipagination.currentPage = 1
                this.handleQuery()
            },
            //导入
            importExcel() {

            },
            initFclAndLcl() {
                if (this.form.isHaiyun === '1') {
                    getAction(this.url.getFclBussinessTypeList).then(res => {
                        this.fclBussinessTypeList = res.result;
                    })
                }
                if (this.form.isHaiyun === '0') {
                    getAction(this.url.getLclBussinessTypeList).then(res => {
                        this.lclBussinessTypeList = res.result;
                    })
                }
                if (this.form.isHaiyun === '2') {
                    getAction(this.url.getAirBussinessTypeList).then(res => {
                        this.airBussinessTypeList = res.result;
                    })
                }


            },
        }, created() {

        }
    }
</script>
<style lang="scss" scoped>
.addEditBox {
  max-height: 625px;
  overflow: scroll;
}
</style>
