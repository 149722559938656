<!--排柜表-->
<template>
    <div class="main-full-content">
        <el-form class="multiline_select" ref="form" :model="queryForm" label-width="120px" size="medium">
            <el-card class="box-card">
                <el-row class="inputs_row">
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="周数">
                            <el-input v-model="queryForm.week" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="专线Or拆柜">
                            <!--待提交 已拒绝-->
                            <f-select
                                    v-model="queryForm.cabinetCategory"
                                    :fatherVal="queryForm.cabinetCategory"
                                    :is-need="queryForm.cabinetCategory"
                                    :dict="'cabinet_category'"
                            ></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="柜型">
                            <f-select
                                    v-model="queryForm.size"
                                     :is-need="queryForm.size"
                                    :fatherVal="queryForm.size"
                                    :dict="'cabinet_type'"
                            ></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="订舱号">
                            <el-input v-model="queryForm.bookNo" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="柜号">
                            <el-input v-model="queryForm.containerNo" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="封条">
                            <el-input v-model="queryForm.originalNo" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label-width="30px">
                            <el-button
                                    type="primary"
                                    icon="el-icon-search"
                                    @click="handleSearch"
                            >查询
                            </el-button
                            >
                            <el-button plain @click="handleReset">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>
        </el-form>
        <el-card class="box-card last_card">
            <div class="f-p-b-15">

                <el-button type="primary" size="small" @click="beatchChuKu()" v-if="buttonShowList.ckB">批量出库</el-button>
                <el-button type="primary" size="small" @click="batchUpdateWarehouseOrder()" v-if="buttonShowList.readyCk">准备出库装柜</el-button>
            </div>
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="61vh"
                    :isRecordTableScroll="true"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange"
            >
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'action'">
                        <el-button type="text" @click="paiGui(scope.row)" v-if="buttonShowList.pgbxz">排柜表下载</el-button>
                        <el-upload
                                :data="scope.row"
                                action="/"
                                multiple
                                :show-file-list="false"
                                :http-request="uploadZhuangguiPicture"
                                accept=".jpg,.jpeg,.gif,.png,.pneg,.img"
                                style="display: inline-flex; margin: 10px"
                                :on-change="handFileChange"
                                class="upload-file"
                                @click.native="clickChear"
                        >
                            <el-button size="medium" type="primary" v-if="buttonShowList.sczgzp">上传装柜照片</el-button>
                        </el-upload>
                        <el-button type="text" @click="chuku(scope.row)" v-if="buttonShowList.ck">出库</el-button>
                        <el-button type="text" @click="jiaHuo(scope.row)" v-if="buttonShowList.ah">申请加货</el-button>
                        <el-button type="text" @click="tiHuo(scope.row)" v-if="buttonShowList.th">申请踢货</el-button>
                    </template>
                    <template v-else-if="scope.column.property === 'complianceState'">
                        <span>{{parseDict(scope.row.complianceState, "compliance_state")}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'cabinetCategory'">
                        <span>{{parseDict(scope.row.cabinetCategory, "cabinet_category")}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'bookAgentId'">
                        <span>{{ parseDict(scope.row.bookAgentId, "bookAgent_id") }}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'week'">
                        <a style="color: #00a0e9" @click.prevent="infomation(scope.row.id)">{{ scope.row.week }}</a>
                    </template>
                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                >
                </el-pagination>
            </el-row>
        </el-card>

        <el-dialog :title="'申请加货'" :visible.sync="dialogFormVisible" width="60%" center :close-on-click-modal="false"
                   v-if="dialogFormVisible">

            <div style="width: 300px;display: flex;align-items: center">
                <span style="width: 120px;font-size: 50px">加货体积:</span>
                <el-input v-model="cmb" type="number"></el-input>
                <el-button type="primary" size="medium" style="margin-left: 20px" @click="sureAdd()">确定加货</el-button>
            </div>
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columnAdd"
                    :data="tableDataAdd"
                    :cell-style="{ 'text-align': 'center' }"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange"
            >
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'action'">
                        <el-button type="text" @click="cancel(scope.row)"
                                   :disabled="scope.row.cancelBy != null || scope.row.sureBy != null">撤销
                        </el-button>
                    </template>
                    <template v-else-if="scope.column.property === 'createBy'">
                        <span>{{parseDict(scope.row.createBy, "sys_user_id")}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'cancelBy'">
                        <span>{{parseDict(scope.row.cancelBy, "sys_user_id")}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'sureBy'">
                        <span>{{parseDict(scope.row.sureBy, "sys_user_id")}}</span>
                    </template>
                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipaginationAdd"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChangeAdd"
                        @current-change="handleCurrentChangeAdd"
                >
                </el-pagination>
            </el-row>
        </el-dialog>

        <el-dialog :title="'申请踢货'" :visible.sync="dialogFormVisibletiHuo" width="60%" center
                   :close-on-click-modal="false"
                   v-if="dialogFormVisibletiHuo">

            <div style="width: 300px;display: flex;align-items: center">
                <span style="width: 120px;font-size: 50px">踢货单号:</span>
                <el-select clearable filterable class="f-full-width"
                           @change="getOrderNoListJoin"
                           v-model="form.orderNoList" @click.native="initTiHuoOrderNoList"
                           multiple
                           placeholder="请选择">
                    <el-option v-for="(item,index) in orderNoList" :key="index" :value="item"
                               :label="item"></el-option>
                </el-select>
                <el-button type="primary" size="medium" style="margin-left: 20px" @click="sureTihuo()">确定踢货</el-button>
            </div>

            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columnTihuo"
                    :data="tableDataTihuo"
                    :cell-style="{ 'text-align': 'center' }"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange"
            >
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'action'">
                        <el-button type="text" @click="cancel(scope.row)"
                                   :disabled="scope.row.cancelBy != null || scope.row.sureBy != null">撤销
                        </el-button>
                    </template>
                    <template v-else-if="scope.column.property === 'createBy'">
                        <span>{{parseDict(scope.row.createBy, "sys_user_id")}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'cancelBy'">
                        <span>{{parseDict(scope.row.cancelBy, "sys_user_id")}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'sureBy'">
                        <span>{{parseDict(scope.row.sureBy, "sys_user_id")}}</span>
                    </template>
                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipaginationTiHuo"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChangeAdd"
                        @current-change="handleCurrentChangeAdd"
                >
                </el-pagination>
            </el-row>

        </el-dialog>
    </div>
</template>

<script>
    import {deleteAction, getAction, postAction} from "@/api/manage";
    import QueryMixins from "../../mixins/QueryMixins";
    import { setUpRouteUtil } from '@/utils/util';

    export default {
        name: "Potential",
        mixins: [QueryMixins],
        data() {
            return {
                ipaginationAdd: {
                    currentPage: 1,
                    pageSize: 50,
                    pageSizes: [10, 20, 50, 100],
                    total: 0
                },
                ipaginationTiHuo: {
                    currentPage: 1,
                    pageSize: 50,
                    pageSizes: [10, 20, 50, 100],
                    total: 0
                },
                rules: {
                    cabinetCategory: [
                        {required: true, message: "请选择专线/拆柜", trigger: "change"},
                    ],
                    week: [{required: true, message: "请填写周数", trigger: "change"}],
                    size: [{required: true, message: "请选择柜型", trigger: "change"}],
                    weekStatus: [
                        {required: true, message: "请选择柜子用途", trigger: "change"},
                    ],
                },
                ids: [],
                row: {},
                form: {},
                cmb: '',
                tableDataAdd: [],
                tableDataTihuo: [],
                EditCabinetForm: {},
                updateAlertCabinrt: {},
                dictCodes: ["compliance_state", "cabinet_category", "bookAgent_id", "sys_user_id"],
                formLabelWidth: "120px",
                //控制弹框显示
                dialogFormVisible: false,
                dialogFormVisibletiHuo: false,
                columns: [
                    {
                        type: "selection",
                        width: 50,
                    },
                    {
                        prop: "action",
                        label: "操作",
                        // width: 170,
                        // fixed: 'left'
                    },
                    {
                        prop: "complianceState",
                        label: "关务审核状态",
                        sortable: "complianceState",
                    },
                    {
                        prop: "week",
                        label: "周数",
                        sortable: "week",
                        // width: 140,
                    },
                    {
                        prop: "cabinetCategory",
                        label: "专线or拆柜",
                        sortable: "cabinetCategory",
                        width: 120,
                    },
                    {
                        prop: "bookAgentId",
                        label: "订舱代理",
                        sortable: "bookAgentId",
                        width: 140,
                    },
                    {
                        prop: "size",
                        label: "柜型",
                        sortable: "size",
                        width: 140,
                    },
                    {
                        prop: "bookNo",
                        label: "订舱号",
                        sortable: "bookNo",
                        width: 140,
                    },
                    {
                        prop: "containerNo",
                        label: "柜号",
                        sortable: "containerNo",
                        width: 160,
                    },
                    {
                        prop: "originalNo",
                        label: "封条",
                        sortable: "originalNo",
                        // width: 100,
                    },
                    {
                        prop: "loadTime",
                        label: "装柜时间",
                        sortable: "loadTime",
                        width: 160,
                    },
                ],
                columnAdd: [
                    {
                        prop: "action",
                        label: "操作",
                    },
                    {
                        prop: "createBy",
                        label: "加货人",
                        sortable: "custom",
                    },
                    {
                        prop: "createTime",
                        label: "加货时间",
                        sortable: "custom",
                    },
                    {
                        prop: "cmb",
                        label: "加货体积",
                        sortable: "custom",
                    },
                    {
                        prop: "cancelBy",
                        label: "撤销人",
                        sortable: "custom",
                    },
                    {
                        prop: "cancelTime",
                        label: "撤销时间",
                        sortable: "custom",
                    },
                    {
                        prop: "sureBy",
                        label: "确认加货人",
                        sortable: "custom",
                    },
                    {
                        prop: "sureTime",
                        label: "确认加货时间",
                        sortable: "custom",
                    },

                ],
                columnTihuo: [
                    {
                        prop: "action",
                        label: "操作",
                    },
                    {
                        prop: "createBy",
                        label: "踢货人",
                        sortable: "custom",
                    },
                    {
                        prop: "createTime",
                        label: "踢货时间",
                        sortable: "custom",
                    },
                    {
                        prop: "tihuoOrderNos",
                        label: "踢货单号",
                        sortable: "custom",
                    },
                    {
                        prop: "cancelBy",
                        label: "撤销人",
                        sortable: "custom",
                    },
                    {
                        prop: "cancelTime",
                        label: "撤销时间",
                        sortable: "custom",
                    },
                    {
                        prop: "sureBy",
                        label: "确认踢货人",
                        sortable: "custom",
                    },
                    {
                        prop: "sureTime",
                        label: "确认踢货时间",
                        sortable: "custom",
                    },

                ],
                url: {
                    list: "/operation/container/getwaittingLoadinglist",
                    updateCirculationState: "/operation/container/updateCabinet",
                    updateCirculationStateSendMessage: "/operation/container/updateCirculationState",
                    delCabinet: "/operation/container/delCabinet",
                    getPaiGui: "/file/jasper/getPaiGui",
                    downloadById: "/sys/oss/file/downloadById",
                    upload: "/sys/oss/file/upload", //文件上传
                    uploadZhuangguiPic: "/operation/container/uploadZhuangguiPic", //排柜表上传
                    outByWeek: "/interface/warehouse/addOutBatchByWeek", //批量出库
                    updateWarehouseOrder: "/interface/warehouse/updateWarehouseOrder", //批量出库
                    addList: "/shipping/osContainerAddgood/list", // 加货列表
                    add: "/shipping/osContainerAddgood/add", // 确认加货
                    cancel: "/shipping/osContainerAddgood/cancel", // 撤销加货
                    getOrderNos: "/operation/container/getOrderNos", // 获取柜子里的所有单号
                },
                countryList: [],
                addressList: [],
                orderNoList: [],
                zhuangguiOssIds: [],
                zhuangguiOssIdLength: 0,
                isUsePost:true,
            };
        },
        methods: {
            infomation(id) {
                // 对于路由不在左侧菜单中的选中菜单处理
                setUpRouteUtil(this.$route.path, '/operation/cabinet/Edit');
                this.$router.push("/operation/cabinet/Edit?id=" + id);
                console.log(id);
            },
            handleReset() {
                this.queryForm = {};
                this.queryForm.pageType = "1";
                this.ipagination.currentPage = 1;
                this.handleQuery();
            },
            // 排柜表下载
            paiGui(row) {
                if (row.paiguiOssId) {
                    var id = {id: row.paiguiOssId};
                    postAction(this.url.downloadById, id, {responseType: "blob",}).then((response) => {
                        let u = window.URL.createObjectURL(response);
                        let aLink = document.createElement("a");
                        aLink.style.display = "none";
                        aLink.href = u;
                        aLink.setAttribute("download", row.week + "的排柜表.xls");
                        document.body.appendChild(aLink);
                        aLink.click();
                        document.body.removeChild(aLink);
                        window.URL.revokeObjectURL(u);
                    });
                } else {
                    this.$message.warning("暂无排柜表");
                }
            },
            handFileChange(files, fileList) {
              console.log(files, fileList,fileList.length,'files, fileList')
              this.zhuangguiOssIdLength = fileList.length;
              console.log(this.zhuangguiOssIdLength,'zhuangguiOssIdLength')
            },
            clickChear() {
              console.log('点击清空');
              this.zhuangguiOssIds = [];
              this.zhuangguiOssIdLength = 0;
            },
            //上传装柜图片
            uploadZhuangguiPicture(param) {
              console.log('上传专柜照片')
                //file就是当前添加的文件
                var fileObj = param.file;
                var row = param.data;
                //FormData 对象
                var form = new FormData();
                //文件对象，key是后台接受的参数名称
                form.append("file", fileObj);
                form.append("dir", 'warehouse');
                //执行上传方法
                postAction(this.url.upload, form).then((res) => {
                    //这里做上传之后的操作
                    if (res.success && res.result) {
                      this.zhuangguiOssIds.push(res.result.id);
                      console.log(this.zhuangguiOssIds.length,this.zhuangguiOssIdLength,'this.zhuangguiOssIdLength')
                      if(this.zhuangguiOssIds.length == this.zhuangguiOssIdLength) {
                        //将装柜图片的url 更新到 柜子
                        var contain = {
                            id: row.id,
                            zhuangguiOssId: this.zhuangguiOssIds.join(',')
                        };
                        postAction(this.url.uploadZhuangguiPic, contain).then((res) => {
                            //这里做上传之后的操作
                            if (res.success) {
                                this.zhuangguiOssIdLength = 0;
                                this.zhuangguiOssIds = [];
                                this.$message.success(res.message);
                            }
                        })
                      }
                    }
                })
            },
            // 批量准备出库装柜
            batchUpdateWarehouseOrder() {
                getAction(this.url.updateWarehouseOrder + "?containerId=" + this.selectedRowKeys.join(",")).then((res) => {
                    if (res.success) {
                        this.$message.success(res.message);
                    }
                })
            },
            //批量出库
            beatchChuKu() {
                getAction(this.url.outByWeek + "?containerId=" + this.selectedRowKeys.join(",")).then((res) => {
                    if (res.success) {
                        this.$message.success(res.message);
                    }
                })
            },
            //出库
            chuku(row) {
                getAction(this.url.outByWeek + "?containerId=" + row.id).then((res) => {
                    if (res.success) {
                        this.$message.success(res.message);
                    }
                })
            },
            //申请加货
            jiaHuo(row) {
                this.form = {};
                this.form.containerId = row.id;
                this.form.type = '0';
                this.addlist();
            },
            //查询加货列表
            addlist() {
                if(this.form.type=='0') {
                    if (this.ipaginationAdd) {
                        this.form.pageNo = this.ipaginationAdd.currentPage
                        this.form.pageSize = this.ipaginationAdd.pageSize
                    }
                }else{
                    if (this.ipaginationTiHuo) {
                        this.form.pageNo = this.ipaginationTiHuo.currentPage
                        this.form.pageSize = this.ipaginationTiHuo.pageSize
                    }
                }
                getAction(this.url.addList, this.form).then((res) => {
                    console.log(res)
                    if (res.success) {
                        if (res.result) {
                            const {records, total} = res.result
                            if(this.form.type=='0') {
                                this.tableDataAdd = records || res.result;
                                this.ipaginationAdd && (this.ipaginationAdd.total = total || res.result.length)
                            }else{
                                this.tableDataTihuo = records || res.result;
                                this.ipaginationTiHuo && (this.ipaginationTiHuo.total = total || res.result.length)
                            }
                        } else {
                            if(this.form.type=='0') {
                                this.tableDataAdd = [];
                                this.ipaginationAdd = 0;
                            }else{
                                this.tableDataTihuo = [];
                                this.ipaginationTiHuo = 0;
                            }

                        }
                        if(this.form.type=='0') {
                            this.dialogFormVisible = true;
                        }else{
                            this.dialogFormVisibletiHuo = true;
                        }

                    }
                })
            },
            //确认加货
            sureAdd() {
                this.form.cmb = this.cmb;
                postAction(this.url.add, this.form)
                    .then(res => {
                        if (res.success) {
                            this.$message.success(res.message);
                            this.form.cmb = '';
                            this.addlist();
                        }
                    })
            },
            //撤销加货
            cancel(row) {
                this.$confirm(`确定撤销`, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "success",
                    closeOnClickModal: false,
                })
                    .then(() => {
                        deleteAction(this.url.cancel, {id: row.id}).then((res) => {
                            this.$message({
                                type: "success",
                                message: res.message,
                            });
                            this.addlist();
                        });
                    })
                    .catch(() => {
                    });

            },
            //申请踢货
            tiHuo(row) {
                this.form = {};
                this.form.containerId = row.id;
                this.form.type = '1';
                this.addlist();
            },
            getOrderNoListJoin(orderNoList) {
                if (orderNoList && orderNoList.length && Array.isArray(orderNoList)) {
                    this.$set(this.form, 'tihuoOrderNos', orderNoList.join(','))
                }
            },
            //初始化柜子里的订单号
            initTiHuoOrderNoList() {
                getAction(this.url.getOrderNos + '?containerId=' + this.form.containerId).then((res) => {
                    if (res.success) {
                        this.orderNoList = res.result;
                    }
                })
            },
            //确定踢货
            sureTihuo(){
                postAction(this.url.add, this.form)
                    .then(res => {
                        if (res.success) {
                            this.$message.success(res.message);
                            this.form.tihuoOrderNos='';
                            this.addlist();
                        }
                    })
            },
            handleSizeChangeAdd(val) {
                if(this.form.type=='0') {
                    this.ipaginationAdd.currentPage = 1
                    this.ipaginationAdd.pageSize = val
                }else{
                    this.ipaginationTiHuo.currentPage = 1
                    this.ipaginationTiHuo.pageSize = val
                }
                this.addlist();
            },
            handleCurrentChangeAdd(val) {
                if(this.form.type=='0') {
                    this.ipaginationAdd.currentPage = val
                }else{
                    this.ipaginationTiHuo.currentPage = val
                }
                this.addlist();
            },
        },
        created() {
            this.queryForm.pageType = "1";
        },
      computed:{
        buttonShowList() {
          return {
            'pgbxz':this.$btnIsShow('warehouse/warehouseWaitingStuffing','1','排柜表下载'),
            'sczgzp':this.$btnIsShow('warehouse/warehouseWaitingStuffing','1','上传装柜照片'),

            'ck':this.$btnIsShow('warehouse/warehouseWaitingStuffing','1','出库'),
            'ah':this.$btnIsShow('warehouse/warehouseWaitingStuffing','1','申请加货'),
            'th':this.$btnIsShow('warehouse/warehouseWaitingStuffing','1','申请踢货'),
            'readyCk':this.$btnIsShow('warehouse/warehouseWaitingStuffing','0','准备出库装柜'),
            'ckB':this.$btnIsShow('warehouse/warehouseWaitingStuffing','0','批量出库'),
          }
        }
      }
    };
</script>

<style lang="scss" scoped>
</style>
