<template>
    <el-dialog
        :title="title"
        :visible="visible"
        width="680px"
        top="8vh"
        custom-class="c-custom-dialog"
        :close-on-click-modal="false"
        :destroy-on-close="destroyOnClose"
        :before-close="handleClose">
        <el-form :model="countryForm" :rules="rules" ref="countryForm" label-position="left">
            <el-row :gutter="20">
                <el-col :span="23">
                    <el-form-item prop="cnName" label="国家中文名称" :label-width="formLabelWidth">
                        <el-input v-model="countryForm.cnName" autocomplete="off" :maxlength="10" show-word-limit></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="23">
                    <el-form-item prop="enName" label="国家英文名称" :label-width="formLabelWidth">
                        <el-input v-model="countryForm.enName" autocomplete="off" :minlength="5" :maxlength="20" show-word-limit></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="23">
                    <el-form-item prop="twoCode" label="国家二字代码" :label-width="formLabelWidth">
                        <el-input v-model="countryForm.twoCode" autocomplete="off" :maxlength="2" show-word-limit></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="23">
                    <el-form-item prop="threeCode" label="国家三字代码" :label-width="formLabelWidth">
                        <el-input v-model="countryForm.threeCode" autocomplete="off" :maxlength="3" show-word-limit></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="23">
                    <el-form-item prop="countryNum" label="ISO数字代码" :label-width="formLabelWidth">
                        <el-input v-model="countryForm.countryNum" autocomplete="off" :maxlength="10" show-word-limit></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="23">
                    <el-form-item prop="capital" label="首都" :label-width="formLabelWidth">
                        <el-input v-model="countryForm.capital" autocomplete="off" :maxlength="20" show-word-limit></el-input>
                    </el-form-item>
                </el-col>
              <el-col :span="23">
                <el-form-item prop="isQg" label="是否需要清关属性" :label-width="formLabelWidth">
                 <f-select v-model="countryForm.isQg" :is-need="countryForm.isQg" :dict="'is_default'"></f-select>
                </el-form-item>
              </el-col>
              <el-col :span="23">
                <el-form-item prop="isHaiwai" label="是否需要海外中转仓服务" :label-width="formLabelWidth">
                  <f-select v-model="countryForm.isHaiwai" :is-need="countryForm.isHaiwai" :dict="'is_default'"></f-select>
                </el-form-item>
              </el-col>
              <el-col :span="23">
                <el-form-item prop="isCangzu" label="是否需要锁国外仓租柜租" :label-width="formLabelWidth">
                  <f-select v-model="countryForm.isCangzu" :is-need="countryForm.isCangzu" :dict="'is_default'"></f-select>
                </el-form-item>
              </el-col>
              <el-col :span="23">
                <el-form-item prop="isChayan" label="是否需要锁国外查验" :label-width="formLabelWidth">
                  <f-select v-model="countryForm.isChayan" :is-need="countryForm.isChayan" :dict="'is_default'"></f-select>
                </el-form-item>
              </el-col>
              <el-col :span="23">
                <el-form-item prop="isSuo" label="是否需要三锁服务" :label-width="formLabelWidth">
                  <f-select v-model="countryForm.isSuo" :is-need="countryForm.isSuo" :dict="'is_default'"></f-select>
                </el-form-item>
              </el-col>
                <el-col :span="23">
                    <el-form-item prop="orderNum" label="排序" :label-width="formLabelWidth">
                        <el-input v-model="countryForm.orderNum" autocomplete="off" type="number"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="23">
                    <el-form-item prop="continent" label="所在洲":label-width="formLabelWidth">
                        <el-select v-model="countryForm.continent" placeholder="请选择" clearable filterable>
                            <el-option
                                    v-for="item in continents"
                                    :key="item.id"
                                    :label="item.cnName"
                                    :value="item.code">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>



            </el-row>
        </el-form>
        <span slot="footer">
            <el-button type="primary" size="medium" @click="handleSubmit" :disabled="ifDis">确定</el-button>
        </span>
    </el-dialog>
</template>

<script>
import { getAction, postAction,httpAction } from '@/api/manage'
export default {
    name: 'addCountryDialog',
    props: {
        title: {
            type: String,
            default: '请输入标题'
        },
        visible: {
            type: Boolean
        },
        destroyOnClose: {
            type: Boolean,
            default: true
        },
        id: {
            type: String
        },
        row:{
            type:Object,
            default:()=>{}
        },
    },
    data() {
        return {
            clubList:[],
            // id:'',
            formLabelWidth:'120px',
            flag: false,
            // visible:'',
            isChecked: true,
            ifDis: false,
            allKeys: [],
            treeData: [],
            defaultProps: {
                children: 'children',
                label: 'title'
            },
            countryForm:{
                cnName : '',
                enName : '',
                twoCode : '',
                threeCode : '',
                countryNum : '',
                capital : '',
                continent : '',
            },
            continents:[],
            url: {
                deleteBatch: '/sys/bdCountry/deleteBatch',
                addCountry:'/sys/bdCountry/addCountry',
                editCountry:'/sys/bdCountry/edit',
                getContinents:'/sys/bdCountry/getContinents',
                getClubIdListByCountryId:'/sys/bdCountry/getClubByCountryId',
            },
            rules: {
                cnName: [
                    { required: true, message: '请输入国家的中文名称', trigger: 'change' },
                    { pattern: /^[\u4e00-\u9fa5]{0,}$/ , message: '中文名称只能由中文组成', trigger: 'change'}
                ],
                enName: [
                    { required: true, message: '请输入国家的英文名称', trigger: 'change' },
                    // { pattern: /^[A-Za-z\s]+$/ , message: '英文名称只能由英文组成', trigger: 'change'}
                    { pattern: /(^([a-zA-Z]+\s?)*[a-zA-Z]$)/ , message: '请输入合法的英文国家名称', trigger: 'change'}
                ],
                twoCode: [
                    { required: true, message: '请输入国家二字编码', trigger: 'change' },
                    { min:2,pattern: /[a-zA-Z]{2,2}/ ,message: "编码只能由二位英文组成！", trigger: 'change'}
                ],
                threeCode: [
                    { required: true, message: '请输入国家三字编码', trigger: 'change' },
                    { pattern: /[a-zA-Z]{3,3}/ ,message: "编码只能由三位英文组成！", trigger: 'change'}
                ],
                countryNum: [
                    { required: true, message: '请输入ISO数字代码', trigger: 'change' },
                    { pattern: /^[0-9]+$/ ,message: "ISO数字代码只能由数字组成！", trigger: 'change'}
                ],
                capital: [
                    { required: true, message: '请输入首都', trigger: 'change' },
                    // { pattern: /^[A-Za-z\s]+$/ ,message: "首都名称只能由英文组成！", trigger: 'change'}
                    { pattern: /(^([a-zA-Z]+\s?)*[a-zA-Z]$)/ ,message: "请输入合法的英文地名！", trigger: 'change'}
                ],
                continent: [
                    { required: true, message: '请输入编码', trigger: 'change' },
                    { pattern: /^[A-Za-z]+$/ ,message: "编码只能由英文组成！", trigger: 'change'}
                ],
            },
        }
    },

    watch: {
        visible(val){
            if (this.row){
                for (var name in this.row){
                    this.$set(this.countryForm,name,this.row[name]);
                }
                if (this.row.id){
                  getAction(this.url.getClubIdListByCountryId,{id:this.row.id})
                      .then(res=>{
                        let list =res.result;
                        if (list && list.length>0 && Array.isArray(list)){
                          this.$set(this.countryForm,'clubIdList',list);
                        }else {
                          this.$set(this.countryForm,'clubIdList',[]);
                        }

                      })
                }

            }else {
                this.$set(this.countryForm,'cnName','');
                this.$set(this.countryForm,'enName','');
                this.$set(this.countryForm,'twoCode','');
                this.$set(this.countryForm,'threeCode','');
                this.$set(this.countryForm,'countryNum','');
                this.$set(this.countryForm,'capital','');
                this.$set(this.countryForm,'continent','');
                this.$set(this.countryForm,'orderNum',100);

            }

        }

    },
    created(){
        this.getContinents();
    },
    methods: {
        handleSubmit(){
            this.$refs.countryForm.validate((valid) => {
                if (valid){
                    let url = this.url.addCountry;
                    let method = 'post';
                    if (this.countryForm.id){
                        url = this.url.editCountry;
                        method = "put";
                    }
                    this.ifDis = true
                    this.enabled()
                    this.countryForm.countryId = this.id
                    httpAction(url,method,this.countryForm).then((res) => {
                        if(res.success){
                            this.$message({
                                type: 'success',
                                message: res.message
                            })
                            this.handleClose();
                            this.$emit('ok');
                        }
                    })
                }
            })
        },
        handleClose(){
            this.$emit('update:visible', false)

            // this.$refs.form.resetFields()
            // this.visible = false;
            // this.$refs.countryForm.resetFields();
        },
        enabled(){
            setTimeout(()=>{
                this.ifDis = false;
            },2000)
        },
        getContinents(){
            getAction(this.url.getContinents).then((res)=>{
                // consoloe.log(res)
                this.continents = res.result
            })
        },
    }
}
</script>

<style lang="scss" scoped>
.btn-wrap {
    text-align: right;
    padding: 10px 30px;
}
.content {
    height: 620px;
    padding: 6px 0;
    overflow: auto;
}
::v-deep {
    .el-dialog__body {
        padding: 0 0 0 20px;
    }
    .el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content {
        background-color: #e0efff;
    }
}
</style>
