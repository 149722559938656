<template>
  <div class="main-full-content">
    <el-form
      class="input_top"
      ref="form"
      :model="queryForm"
      label-width="100px"
      size="medium"
    >
      <el-card class="box-card">
        <el-row>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="文件名">
              <el-input v-model="queryForm.name"></el-input>
            </el-form-item>
          </el-col>

          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="文件类型">
              <el-select
                clearable
                filterable
                class="f-full-width"
                v-model="queryForm.fileType"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in fileTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="3">
            <el-form-item label-width="30px">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="handleSearch"
                >查询</el-button
              >
              <el-button plain @click="handleReset">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
    </el-form>
    <el-card class="box-card last_card">
      <div class="f-p-b-15">
        <el-button size="medium" icon="el-icon-plus" type="primary" @click="handleAdd">新增</el-button>
        <el-button
            size="medium"
          type="primary"
          icon="el-icon-download"
          @click="handleExportXls"
          >导出</el-button
        >
        <el-button
          type="primary"
          size="medium"
          class="c-theme-error-button"
          @click="handleBatDel"
          :disabled="selectionRows.length === 0"
          >批量删除
        </el-button>
      </div>

      <div class="c-alert f-m-b-10">
        已选择 <span class="stress">{{ this.selectedRowKeys.length }}</span> 项
        <span
          class="stress f-m-l-12 f-cursor-pointer"
          @click="handleClearSelection"
          >清空</span
        >
      </div>
      <f-table
        v-loading="loading"
        ref="GTable"
        border
        size="medium"
        row-key="id"
        class="c-th-has-bgcolor"
        :columns="columns"
        :data="tableData"
        :cell-style="{ 'text-align': 'center' }"
        @selection-change="handleSelectionChange"
        @sort-change="handleTableChange"
      >
        <template v-slot="scope">
          <template v-if="scope.column.property === 'action'">
            <el-button type="text" @click="handleEdit(scope.row)"
              >编辑</el-button
            >
            <el-button type="text" @click="handDel(scope.row)">删除</el-button>
          </template>
          <template v-else-if="scope.column.property === 'fileType'">
            <span v-if="scope.row.fileType === '0'">大森林营业执照</span>
            <span v-else-if="scope.row.fileType === '1'">大森林开票信息</span>
            <span v-else-if="scope.row.fileType === '2'">供应商营业执照</span>
            <span v-else-if="scope.row.fileType === '3'">供应商法人身份证正反面</span>
            <span v-else-if="scope.row.fileType === '4'">法人授权书</span>
            <span v-else-if="scope.row.fileType === '5'">价格表</span>
            <span v-else></span>
          </template>
          <template v-else-if="scope.column.property === 'size'">
            <span>{{ scope.row.size + "KB" }}</span>
          </template>
        </template>
      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination
          v-bind="ipagination"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </el-row>
    </el-card>

    <el-dialog
      :title="form.id ? '编辑文件' : '新增文件'"
      :visible.sync="dialogFormVisible"
      center
      width="30%"
      :close-on-click-modal="false"
      v-if="dialogFormVisible"
    >
      <el-form :model="form" :rules="rules" ref="form" size="medium">
        <el-row>
          <el-col :span="24">
            <el-form-item prop="name" label="文件名" label-width="110px">
              <el-input v-model="form.name"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item prop="fileType" label="文件类型" label-width="110px">
              <el-select
                clearable
                filterable
                class="f-full-width"
                v-model="form.fileType"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in fileTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="文件" label-width="110px">
              <f-upload v-model="fileList" :isUseOss="true" :limit="1"></f-upload>
<!--              <el-upload-->
<!--                class="upload-demo"-->
<!--                action=""-->
<!--                :http-request="handleUpload"-->
<!--                :file-list="fileList"-->
<!--                :limit="1"-->
<!--                ref="upload"-->
<!--                :before-upload="beforeUpload"-->
<!--                :on-remove="removeFile"-->
<!--                :on-preview="handlePreview"-->
<!--                style="float: left; margin-right: 20px"-->
<!--              >-->
<!--                <el-button-->
<!--                  type="success"-->
<!--                  plain-->
<!--                  size="small"-->
<!--                  v-if="this.fileList.length !== 1"-->
<!--                  >上传</el-button-->
<!--                >-->
<!--              </el-upload>-->
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="medium" @click="dialogFormVisible = false"
          >取 消</el-button
        >
        <el-button
          size="medium"
          type="primary"
          @click="saveFile"
          :disabled="btnDisabled"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
        width="800px"
        title="预览"
        append-to-body
        :visible="imgVisible"
        custom-class="c-custom-dialog"
        :before-close="() => { imgVisible = false }">
        <img :src="imgUrl" width="100%" >
    </el-dialog>
  </div>
</template>

<script>
import { deleteAction, getAction, putAction } from "@/api/manage";
import QueryMixins from "@/mixins/QueryMixins.js";
import { httpAction, postAction } from "../../../api/manage";

export default {
  name: "FileModule",
  mixins: [QueryMixins],
  components: {},
  data() {
    return {
      fileList: [],
      ids: [],
      dialogFormVisible: false,
      btnDisabled: false,
      form: {
        url: {
          editNew: "/sys/supplierFile/editNew",
        },
        size: "",
        filelist: [],
      },
      //表单校验
      rules: {
        name: [
          { required: true, message: "请输入文件名", trigger: "blur" },
          { max: 50, message: "长度不能超过50", trigger: "change" },
        ],
        fileType: [
          { required: true, message: "请选择文件类型", trigger: "change" },
        ],
      },
      row: {},
      visible: false,
      imgVisible: false,
      imgUrl: '',
      assignVisible: false,
      msgVisible: false,
      emailVisible: false,
      followTitle: "",
      assignTitle: "",
      columns: [
        {
          type: "selection",
          width: 50,
        },
        {
          prop: "action",
          label: "操作",
        },
        {
          prop: "name",
          label: "文件名",
          sortable: "custom",
        },
        {
          prop: "fileType",
          label: "文件类型",
          sortable: "custom",
        },
        {
          prop: "size",
          label: "文件大小",
          sortable: "custom",
        },
        {
          prop: "createTime",
          label: "创建时间",
          sortable: "custom",
        },
      ],
      url: {
        list: "/sys/supplierFile/list",
        add: "/sys/supplierFile/add",
        edit: "/sys/supplierFile/edit",
        getbyid: "/sys/supplierFile/queryById",
        delById: "/sys/supplierFile/delete",
        editNew: "/sys/supplierFile/editNew",
        exportXlsUrl: "/sys/supplierFile/exportXls",
        addSup: "/sys/supplierFile/add",
        delSup: "/sys/supplierFile/delete",
        upload: "/sys/oss/file/upload",
        delOss: "/sys/oss/file/delete",
      },

      //文件类型 0：大森林营业执照 1：大森林开票信息 2：供应商营业执照 3：供应商法人身份证正反面，4：法人授权书
      fileTypeList: [
        { value: "0", label: "大森林营业执照" },
        { value: "1", label: "大森林开票信息" },
        { value: "2", label: "供应商营业执照" },
        { value: "3", label: "供应商法人身份证正反面" },
        { value: "4", label: "法人授权书" },
        { value: "5", label: "价格表" },
        { value: "6", label: "供应商合同" },
      ],
    };
  },
  created() {
    this.queryForm.supplierId = this.$route.query.id;
  },
  methods: {
    handlePreview(file) {
          if (!file.url) {
            this.$message.error('下载失败')
            return
          }
          const type = file.url.substring(file.url.lastIndexOf('.')+1);
          if (type === 'jpg' || type === 'jpeg' || type ==='img' || type === 'gif' || type === 'png' || type === 'pneg'){
              this.imgUrl = file.url
              this.imgVisible = true
          }
          // 判断文件类型
          else if (type === 'doc' || type === 'docx' || type === 'xlsx' || type === 'xls' || type === 'ppt' || type === 'pptx') {
            // 在当前浏览器直接下载
            document.location.href = file.url
          } else {
            // 图片在浏览器打开 新的页面展示
            window.open(file.url, 'hello')
          }
    },

    handleUpload(data) {
      console.log("上传文件", data);
      const formData = new FormData();
      formData.append("file", data.file);
      formData.append("dir", 'supplier');

      postAction(this.url.upload, formData).then((res) => {
        if (res.success) {
          //上传成功,返回结果赋值给form对象的文件集合
          let { id, size, ext, name, url } = res.result;
          //this.fileList.push({ossId: id, size, ext, name: fileName, url});
          this.form.url = url;
          this.form.ossId = id;
          this.form.size = size;
          this.form.type = ext;
          console.log("上传的客户文件", this.fileList);
          console.log("上传的客户文件", this.form);
          this.$message.success("上传成功");
          this.fileList.push(res.result);
        }
      });
    },
    removeFile(val) {
      if (val && val.status === "success") {
        //删除oss的文件
        const index = this.fileList.indexOf(val);
        deleteAction(this.url.delOss, { id: this.form.ossId })
          .then((res) => {
            if (res.success) {
              console.log("执行删除文件", index);
              this.fileList.slice(index, 1);
              //同时清空文件的url
              this.form.url = "";
              this.form.ossId = "";
              this.$message.success("删除文件成功");
            } else {
              //清空filelist
              this.$refs.upload.clearFiles();
              console.log("执行删除文件", index);
            }
          })
          .catch((err) => {
            this.$refs.upload.clearFiles();
            console.log("执行删除文件", index);
          });
        this.fileList = [];
      }
    },
    //文件上传之前校验文件合法
    beforeUpload(file) {
      //对文件大小进行限制
      const isLt2M = file.size / 1024 / 1024 < 15;
      if (!isLt2M) {
        this.$message.warning("文件大小不能超过15MB");
      }
      const isFile = file.name.indexOf(".") !== -1;
      if (!isFile) {
        this.$message.warning("该文件名不合法");
      }
      return isLt2M && isFile;
    },
    handleReset() {
      this.queryForm = {};
      this.queryForm.supplierId = this.$route.query.id;
      this.ipagination.currentPage = 1;
      this.handleQuery();
    },
    saveFile() {
      this.btnDisabled = true;
      this.form.supplierId = this.$route.query.id;
      //文件的删除和新增统一放到F-upload，这边传递表单对象过去
      this.$refs["form"].validate((valid) => {
        if (valid) {
          let method = "post";
          let url = this.url.add;
          if (this.fileList.length <= 0) {
            this.$message.error("请选择文件");
            this.btnDisabled = false;
            return false;
          } else {
            let fileObj = this.fileList[0];
            this.form.url = fileObj.url;
            this.form.ossId = fileObj.id;
            this.form.size = fileObj.size;
            this.form.type = fileObj.ext;
            /* this.form.fileName = this.fileList[0].fileName;*/
            if (this.form.id) {
              url = this.url.edit;
              method = "put";
            }
            httpAction(url, method, this.form)
              .then((res) => {
                if (res.success) {
                  this.dialogFormVisible = false;
                  this.$message.success(res.message);
                  this.handleQuery();
                } else {
                  this.btnDisable = false;
                }
              })
              .catch((err) => {
                this.btnDisable = false;
              });
          }
        } else {
          this.btnDisabled = false;
        }
      });
    },
    handleAdd() {
      this.btnDisabled = false;
      this.form = {};
      this.fileList = [];
      console.log("儿子", this.$refs.child);
      // this.$refs.filemodule.init();
      this.dialogFormVisible = true;
    },
    handleEdit({ id }) {
      this.btnDisabled = false;
      getAction(this.url.getbyid, { id: id })
        .then((res) => {
          this.form = res.result;
          if (this.form) {
            this.fileList = [];
            if (this.form.url) {
              if (res.result) {
                let id = res.result.ossId;
                let size = res.result.size;
                let url = res.result.url;
                let name = res.result.name;
                this.fileList.push({ id, size, url, name });
              }
            }
          }
        })
      this.dialogFormVisible = true;
    },
    handDel({ id, name }) {
      this.$confirm(`删除${name}?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "success",
        closeOnClickModal: false,
      })
        .then(() => {
          deleteAction(this.url.delById + "?ids=" + this.selectedRowKeys, {
            id,
          }).then((res) => {
            this.$message({
              type: "success",
              message: res.message,
            });
            this.handleSearch();
          });
        });
    },

    handleBatDel() {
      this.$confirm("确定删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
        closeOnClickModal: false,
      })
        .then(() => {
          deleteAction(this.url.deleteBatch, {
            ids: this.selectedRowKeys.join(","),
          }).then((res) => {
            this.$message({
              type: "success",
              message: res.message,
            });
            this.handleSearch();
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.input_top .el-form-item {
  margin-bottom: 2px;
}
</style>
