<template>
    <div>
        <el-card class="box-card">
            <el-form class="multiline_select" ref="form" :model="queryForm" label-width="80px" size="medium">
                <el-card class="box-card">
                    <el-row  class="inputs_row">
                        <el-col :xs="24" :sm="12" :md="12" :lg="6">
                            <el-form-item label="装柜顺序">
                                <el-input v-model="queryForm.outOrderly" placeholder="多个需要完整顺序" type="textarea"
                                          @keyup.enter.native="handleSearch"></el-input>
                            </el-form-item>
                        </el-col>


                        <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="6">
                            <el-form-item label="拼柜仓库">
                                <f-select :dict="'lclPlace'" v-model="queryForm.lclPlaceIdList" :multiple="true"
                                          :isNeed="queryForm.lclPlaceIdList" @keyup.enter.native="handleSearch"></f-select>
                            </el-form-item>
                        </el-col>

                        <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="7">
                            <el-form-item label="装柜时间">
                                <div class="f-flex">
                                    <el-date-picker
                                            class="f-flex-1"
                                            v-model="queryForm.loadingTimeStart"
                                            type="datetime"
                                            size="small"
                                            placeholder="开始时间"
                                            default-time="00:00:00"
                                            value-format="yyyy-MM-dd HH:mm:ss"
                                    >
                                    </el-date-picker>
                                    <span class="f-p-h-2">~</span>
                                    <el-date-picker
                                            class="f-flex-1"
                                            v-model="queryForm.loadingTimeEnd"
                                            type="datetime"
                                            size="small"
                                            placeholder="结束时间"
                                            default-time="23:59:59"
                                            value-format="yyyy-MM-dd HH:mm:ss"
                                    >
                                    </el-date-picker>
                                </div>
                            </el-form-item>
                        </el-col>

                        <el-col :xs="24" :sm="12" :md="12" :lg="4">
                            <el-form-item label-width="30px">
                                <el-button
                                        type="primary"
                                        icon="el-icon-search"
                                        @click="handleSearch"
                                >查询
                                </el-button
                                >
                                <el-button plain @click="handleReset">重置</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>


                </el-card>
            </el-form>
            <div class="bbb">
                <el-button
                        type="primary"
                        size="medium"
                        @click="showDetail"
                        class="margin_right_10"
                        v-if="buttonShowList.add">添加预约出库记录
                </el-button>

                <!--                <el-button type="primary" size="medium" @click="exportExcelPlus(url.downloadExcelUrl,'仓储部开单记录数据')" v-if="$btnIsShow('warehouse2.0/warehousoReceipts','0','导出')">导出-->
                <!--                </el-button>-->
            </div>
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="73vh"
            >
                <template v-slot="scope">

                    <template v-if="scope.column.property === 'isUseText'">
                        <span v-if="scope.row.isUseText === '已使用'" style="color: #FF0000">{{scope.row.isUseText}}</span>
                        <span v-else style="color: #00bb00">{{scope.row.isUseText}}</span>
                    </template>


                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange">
                </el-pagination>
            </el-row>
        </el-card>
        <add-warehouse-predict-out-record-dialog :visible.sync="addWarehousePredictOutDialogVisible"
                                        @ok="handleSearch"></add-warehouse-predict-out-record-dialog>
    </div>
</template>

<script>
    import QueryMixins from "@/mixins/QueryMixins.js";
    import addWarehousePredictOutRecordDialog from "@/views/warehouse2.0/modules/addWarehousePredictOutRecordDialog";

    export default {
        name: "WarehousePredictOutRecord",
        mixins: [QueryMixins],
        components: {addWarehousePredictOutRecordDialog},
        data() {
            return {
                row: {},
                isUsePost: true,
                dialogFormVisible: false,
                addWarehousePredictOutDialogVisible: false,
                formLabelWidth: "120px",
                //表单校验

                visible: false,
                //维护 派送费 价格模板计算 （费用名称，最小值，最大值，单位，fbacode，成本，（美元转cny汇率），基数，公式展示（(成本*汇率)/基数），计算出来的单价），结算对象
                columns: [
                    {
                        type: "selection",
                    },

                    {
                        prop: "outOrderly",
                        label: "装柜顺序",
                    },
                    {
                        prop: "lclPlaceName",
                        label: "拼柜仓库",
                    },
                    {
                        prop: "isLimitText",
                        label: "是否受最大装柜个数限制",
                    },
                    {
                        prop: "isUseText",
                        label: "是否已使用",
                    },
                    {
                        prop: "useTime",
                        label: "使用时间",
                    },
                    {
                        prop: "loadingTime",
                        label: "装柜时间",
                    },
                    {
                        prop: "createTime",
                        label: "录入时间",
                    },
                    {
                        prop: "createBy",
                        label: "录入人",
                    },

                ],
                url: {
                    list: "/warehouse/predictOutRecord/queryWarehousePredictOutRecordList",


                },
                selectedOrderNos: '',

            };
        },
        methods: {


            showDetail() {
                this.addWarehousePredictOutDialogVisible = true;
            },

        },
      computed:{
        buttonShowList() {
          return {
            'add':this.$btnIsShow('warehouse2.0/WarehousePredictOutRecord','0','添加预约出库记录'),
          }
        }
      }
    };
</script>

<style lang="scss" scoped>
    .bbb {
        display: flex;
    }

    .dialog-footer {
        text-align: right !important;
    }

    .margin_right_10 {
        margin-right: 10px;
    }
</style>
