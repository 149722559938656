<template>
    <div>
        <el-form ref="form" :model="queryForm" label-width="80px" size="medium" @submit.native.prevent>
            <el-card class="box-card">
                <el-row class="inputs_row">
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="订单号">
                            <el-input v-model="queryForm.orderNo" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="客户">
                            <el-input v-model="queryForm.customer" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="报关方式">
                            <f-select :dict="'os_declare_type'"
                                      v-model="queryForm.declareType"
                                      :isNeed="queryForm.declareType"
                            ></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="发票状态">
                            <f-select :dict="'invoice_status'"
                                      v-model="queryForm.invoiceStatus"
                                      :isNeed="queryForm.invoiceStatus"
                            ></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="渠道名称">
                            <f-select :dict="'transportStatus'"
                                      v-model="queryForm.transportStatus"
                                      :isNeed="queryForm.transportStatus"
                            ></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="客服">
                            <f-select :dict="'sys_user_id'"
                                      v-model="queryForm.docId"
                                      :isNeed="queryForm.docId"
                            ></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="8">
                        <el-form-item label="入仓时间">
                            <div class="f-flex">
                                <el-date-picker
                                        class="f-flex-1"
                                        v-model="queryForm.cargoArrivedStart"
                                        type="datetime"
                                        size="small"
                                        placeholder="开始时间"
                                        default-time="00:00:00"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                >
                                </el-date-picker>
                                <span class="f-p-h-2">~</span>
                                <el-date-picker
                                        class="f-flex-1"
                                        v-model="queryForm.cargoArrivedEnd"
                                        type="datetime"
                                        size="small"
                                        placeholder="结束时间"
                                        default-time="23:59:59"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                >
                                </el-date-picker>
                            </div>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="排柜备注">
                            <el-input v-model="queryForm.pgRemark" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="仓库代码">
                            <el-input v-model="queryForm.fbaCode" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="邮编">
                            <el-input v-model="queryForm.zip" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="材积录入人">
                            <f-select :dict="'sys_user_id'"
                                      v-model="queryForm.createBy"
                                      :isNeed="queryForm.createBy"
                            ></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label="操作员">
                            <f-select :dict="'sys_user_id'"
                                      v-model="queryForm.operateId"
                                      :isNeed="queryForm.operateId"
                            ></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="3">
                        <el-form-item label-width="30px" style="width: 400px">
                            <el-button size="medium" type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
                            <!-- <el-divider direction="vertical"></el-divider> -->
                            <el-button size="medium" plain @click="handleReset">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>

        </el-form>
      <el-card class="box-card">
        <f-table
            v-loading="loading"
            ref="GTable"
            border
            size="medium"
            row-key="orderNo"
            class="c-th-has-bgcolor"
            :columns="columns"
            :data="tableData"
            :isorter="isorter"
            :show-operation="true"
            :cell-style="{ 'text-align': 'center' }"
            tableHeight="65vh"
            :isRecordTableScroll="true"
            @selection-change="handleSelectionChange"
            @sort-change="handleTableChange"
            @queryChange="handleTableQueryChange"

        >
          <template v-slot="scope">
            <!--                    <template v-if="scope.column.property === 'action'">-->
            <!--                        <el-button type="text" @click="WaitingCabinet(scope.row)"-->
            <!--                        >排柜-->
            <!--                        </el-button-->
            <!--                        >-->
            <!--                    </template>-->
            <template v-if="scope.column.property === 'orderNo'">
              <a
                  style="color: #00a0e9"
                  @click.prevent="$intoDocPage(scope.row.orderNo)"
              >{{ scope.row.orderNo }}</a>
            </template>
            <template v-else-if="scope.column.property === 'declareType'">
              <span>{{ parseDict(scope.row.declareType, "os_declare_type") }}</span>
            </template>
            <template v-else-if="scope.column.property === 'invoiceStatus'">
            <span>{{
                parseDict(scope.row.invoiceStatus, "invoice_status")
              }}</span>
            </template>
            <template v-else-if="scope.column.property === 'transportStatus'">
            <span>{{
                parseDict(scope.row.transportStatus, "transportStatus")
              }}</span>
            </template>
            <template v-else-if="scope.column.property === 'customer'">
              <a type="text" @click.prevent="handleEdit(scope.row)" style="color:#57a3f3;"><span>{{ scope.row.customer }}</span></a>

            </template>
            <template v-else-if="scope.column.property === 'grade'">
              <span>{{ parseDict(scope.row.grade, "customer_type") }}</span>
            </template>
            <template v-else-if="scope.column.property === 'docId'">
              <span>{{scope.row.doc}}</span>
            </template>
            <template v-else-if="scope.column.property === 'operateId'">
              <span>{{ scope.row.operator}}</span>
            </template>
            <template v-else-if="scope.column.property === 'isVirtualCabinet'">
              <span v-if="scope.row.isVirtualCabinet === '0'">自拼</span>
              <span v-if="scope.row.isVirtualCabinet === '1'">非自拼</span>
            </template>
          </template>
        </f-table>
        <el-row class="f-text-right f-p-v-8">
          <el-pagination
              v-bind="ipagination"
              layout="total, sizes, prev, pager, next, jumper"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
          >
          </el-pagination>
        </el-row>
      </el-card>
    </div>
</template>

<script>
    import QueryMixins from "@/mixins/QueryMixins.js";


    export default {
        name: "ContactModule",
        mixins: [QueryMixins],
        components: {},
        data() {
            return {
                // rulesForm: {
                //     priceExplainName: [
                //         {required: true, message: '请填写排柜', trigger: 'change'},
                //     ],
                // },

                rules: {
                    airRoute: [
                        {required: true, message: "请选择航线", trigger: "change"},
                    ],
                    lclPlaceId: [
                        {required: true, message: "请选择拼柜仓库", trigger: "change"},
                    ],
                    bdPortId: [
                        {required: true, message: "请选择港口", trigger: "change"},
                    ],
                    cabinetCategory: [
                        {required: true, message: "请选择专线/拆柜", trigger: "change"},
                    ],
                    week: [{required: true, message: "请填写周数", trigger: "change"}],
                    size: [{required: true, message: "请选择柜型", trigger: "change"}],
                    weekStatus: [
                        {required: true, message: "请选择柜子用途", trigger: "change"},
                    ],
                    isVirtualCabinet: [
                        {required: true, message: "请选择是否虚拟柜", trigger: "change"},
                    ],
                },
                //联系人类型0公司负责人 1报价对接人 2操作联系人 3财务联系人',
                dictCodes: [
                    "os_declare_type",
                    "invoice_status",
                ],
                columns: [
                    // {
                    //     type: "selection",
                    //     width: 50,
                    //     fixed: 'left'
                    // },
                    // {
                    //     prop: "action",
                    //     label: "操作",
                    //     minWidth: 80,
                    //     fixed: 'left'
                    // },
                    {
                        prop: "declareType",
                        label: "报关方式",
                        minWidth: 100,
                        // sortable: "declareType",
                    },
                    {
                        prop: "invoiceStatus",
                        label: "发票状态",
                        minWidth: 100,
                        // sortable: "invoiceStatus",
                    },
                    {
                        prop: "orderNo",
                        label: "订单号",
                        minWidth: 100,
                        // sortable: "orderNo",
                        fixed: 'left'
                    },
                    {
                        prop: "channelName",
                        label: "渠道名称",
                        minWidth: 150,
                        // sortable: "transportStatus",
                    },
                    {
                        prop: "customer",
                        label: "客户",
                      /*  headerType: "select",
                        dict: "customer",*/
                        minWidth: 150,
                        // sortable: "username",
                        fixed: 'left'
                    },
                    {
                        prop: "gradeText",
                        label: "客户等级",
                        // headerType: "select",
                        dict: "customer_type",
                        minWidth: 80,
                        // sortable: "grade",
                    },
                    {
                        prop: "docId",
                        label: "客服",
                        minWidth: 100,
                        // sortable: "custom",
                    },
                    {
                        prop: "cname",
                        label: "中文品名",
                        // headerType: "string",
                        minWidth: 100,
                        // sortable: "custom",
                    },
                    {
                        prop: "cargoArrived",
                        label: "入仓时间",
                        minWidth: 100,
                        // sortable: "custom",
                    },
                    {
                        prop: "pgRemark",
                        label: "排柜备注",
                        minWidth: 100,
                        // sortable: "custom",
                    },
                    {
                        prop: "fbaCode",
                        label: "仓库代码",
                        minWidth: 100,
                        // sortable: "shipmentCode",
                    },
                    {
                        prop: "zip",
                        label: "邮编",
                        minWidth: 100,
                        // sortable: "shipmentZip",
                    },
                    {
                        prop: "realCtn",
                        label: "实际入仓件数",
                        // headerType: "rang",
                        minWidth: 50,
                        // sortable: "realCtn",
                    },
                    {
                        prop: "ctn",
                        label: "件数",
                        headerType: "rang",
                        minWidth: 50,
                        // sortable: "ctn",
                    },
                    {
                        prop: "kg",
                        label: "毛重",
                        headerType: "rang",
                        minWidth: 50,
                        // sortable: "kg",
                    },
                    {
                        prop: "cmb",
                        label: "体积",
                        headerType: "rang",
                        minWidth: 50,
                        // sortable: "cmb",
                    },
                    {
                        prop: "realCmb",
                        label: "实际体积",
                        // headerType: "rang",
                        minWidth: 50,
                        // sortable: "realCmb",
                    },
                    {
                        prop: "address",
                        label: "地址",
                        headerType: "rang",
                        minWidth: 100,
                        // sortable: "custom",
                    },
                    {
                        prop: "remark",
                        label: "内部备注",
                        headerType: "rang",
                        minWidth: 100,
                        // sortable: "custom",
                    },
                    {
                        prop: "storehouseRemark",
                        label: "入仓备注",
                        headerType: "rang",
                        minWidth: 100,
                        // sortable: "custom",
                    },

                    {
                        prop: "createBy",
                        label: "材积录入人",
                        minWidth: 100,
                        // sortable: "custom",
                    },
                    {
                        prop: "operateId",
                        label: "操作员",
                        minWidth: 100,
                        // sortable: "operateId",
                    },
                    // {
                    //     prop: "fbaCode",
                    //     label: "fbaCode",
                    //     headerType: "string",
                    //     minWidth: 150,
                    //     // sortable: "fbaCode",
                    // },
                    // {
                    //     prop: "zip",
                    //     label: "zip",
                    //     headerType: "string",
                    //     minWidth: 150,
                    //     // sortable: "zip",
                    // },
                    // {
                    //     prop: "isVirtualCabinet",
                    //     label: "是否自拼",
                    //     headerType: "string",
                    //     minWidth: 180,
                    //     sortable: "isVirtualCabinet",
                    // },
                ],
                url: {
                    list: "/interface/warehouse/getYilucaijIPage",
                },
              isUsePost:true,
            };
        },
        created() {
        },
        methods: {
            handleReset() {
                this.queryForm = {};
                this.filterForm = {};
                this.ipagination.currentPage = 1;
                this.queryForm.toExamine = '0';
                if (this.$refs['GTable']){
                    this.$refs['GTable'].handleReset();
                }
                this.handleQuery();
            },
        },
    };
</script>

<style lang="scss" scoped>
    .elrow {
        padding-left: 60px;
    }

    .elrow-a {
        padding-left: 15px;
    }
</style>
