<template>
  <div>
    <el-card class="box-card">
      <el-form class="multiline_select" ref="form" :model="queryForm" label-width="80px" size="medium">
        <el-card class="box-card">
          <el-row>
            <el-col :xs="24" :sm="12" :md="12" :lg="6">
              <el-form-item label="单号">
                <el-input v-model="queryForm.orderNo" type="textarea"
                          @keyup.enter.native="handleSearch"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="6">
              <el-form-item label="运单号">
                <el-input v-model="queryForm.runOrderNo" type="textarea"
                          @keyup.enter.native="handleSearch"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="6">
              <el-form-item label="跟踪号">
                <el-input v-model="queryForm.logNo" type="textarea"
                          @keyup.enter.native="handleSearch"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="6">
              <el-form-item label="申请编号">
                <el-input v-model="queryForm.applyNo" type="textarea"
                          @keyup.enter.native="handleSearch"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="6">
              <el-form-item label="申请人">
                <f-select v-model="queryForm.applyCreateId"
                          :is-need="queryForm.applyCreateId"
                          :dict="'sys_user_id'"
                          @keyup.enter.native="handleSearch"></f-select>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="12" :md="12" :lg="6">
              <el-form-item label="审批人">
                <f-select v-model="queryForm.removeById"
                          :is-need="queryForm.removeById"
                          :dict="'sys_user_id'"
                          @keyup.enter.native="handleSearch"></f-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="6">
              <el-form-item label="客户名">
                <el-input v-model="queryForm.customer"
                          @keyup.enter.native="handleSearch"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="6">
              <el-form-item label="结算对象">
                <el-input v-model="queryForm.settlement"
                          @keyup.enter.native="handleSearch"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="6">
              <el-form-item label="业务员">
                <f-select v-model="queryForm.salesmanId"
                          :is-need="queryForm.salesmanId"
                          :dict="'sys_user_id'"
                          @keyup.enter.native="handleSearch"></f-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="6">
              <el-form-item label="客服">
                <f-select v-model="queryForm.docId"
                          :is-need="queryForm.docId"
                          :dict="'sys_user_id'"
                          @keyup.enter.native="handleSearch"></f-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="6">
              <el-form-item label="业务类型">
                <el-select v-model="queryForm.businessType"
                           clearable
                           filterable
                           @keyup.enter.native="handleSearch">
                  <el-option value="1" label="空运"></el-option>
                  <el-option value="2" label="快递"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="6">
              <el-form-item label="币种">
                <f-select v-model="queryForm.moneyType"
                          :is-need="queryForm.moneyType"
                          :dict="'bd_cur'"
                          @keyup.enter.native="handleSearch"></f-select>
              </el-form-item>
            </el-col>
            <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="8">
              <el-form-item label="下单时间">
                <div class="f-flex">
                  <el-date-picker
                      class="f-flex-1"
                      v-model="queryForm.orderCreateTimeStart"
                      type="datetime"
                      size="small"
                      placeholder="开始时间"
                      default-time="00:00:00"
                      value-format="yyyy-MM-dd HH:mm:ss"
                      @keyup.enter.native="handleSearch"
                  >
                  </el-date-picker>
                  <span class="f-p-h-2">~</span>
                  <el-date-picker
                      class="f-flex-1"
                      v-model="queryForm.orderCreateTimeEnd"
                      type="datetime"
                      size="small"
                      placeholder="结束时间"
                      default-time="23:59:59"
                      value-format="yyyy-MM-dd HH:mm:ss"
                      @keyup.enter.native="handleSearch"
                  >
                  </el-date-picker>
                </div>
              </el-form-item>
            </el-col>
            <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="8">
              <el-form-item label="发货时间">
                <div class="f-flex">
                  <el-date-picker
                      class="f-flex-1"
                      v-model="queryForm.deliverGoodsTimeStart"
                      type="datetime"
                      size="small"
                      placeholder="开始时间"
                      default-time="00:00:00"
                      value-format="yyyy-MM-dd HH:mm:ss"
                      @keyup.enter.native="handleSearch"
                  >
                  </el-date-picker>
                  <span class="f-p-h-2">~</span>
                  <el-date-picker
                      class="f-flex-1"
                      v-model="queryForm.deliverGoodsTimeEnd"
                      type="datetime"
                      size="small"
                      placeholder="结束时间"
                      default-time="23:59:59"
                      value-format="yyyy-MM-dd HH:mm:ss"
                      @keyup.enter.native="handleSearch"
                  >
                  </el-date-picker>
                </div>
              </el-form-item>
            </el-col>
            <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="8">
              <el-form-item label="销账时间">
                <div class="f-flex">
                  <el-date-picker
                      class="f-flex-1"
                      v-model="queryForm.settleTimeStart"
                      type="datetime"
                      size="small"
                      placeholder="开始时间"
                      default-time="00:00:00"
                      value-format="yyyy-MM-dd HH:mm:ss"
                      @keyup.enter.native="handleSearch"
                  >
                  </el-date-picker>
                  <span class="f-p-h-2">~</span>
                  <el-date-picker
                      class="f-flex-1"
                      v-model="queryForm.settleTimeEnd"
                      type="datetime"
                      size="small"
                      placeholder="结束时间"
                      default-time="23:59:59"
                      value-format="yyyy-MM-dd HH:mm:ss"
                      @keyup.enter.native="handleSearch"
                  >
                  </el-date-picker>
                </div>
              </el-form-item>
            </el-col>

            <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="8">
              <el-form-item label="锁单时间">
                <div class="f-flex">
                  <el-date-picker
                      class="f-flex-1"
                      v-model="queryForm.orderLockTimeStart"
                      type="datetime"
                      size="small"
                      placeholder="开始时间"
                      default-time="00:00:00"
                      value-format="yyyy-MM-dd HH:mm:ss"
                      @keyup.enter.native="handleSearch"
                  >
                  </el-date-picker>
                  <span class="f-p-h-2">~</span>
                  <el-date-picker
                      class="f-flex-1"
                      v-model="queryForm.orderLockTimeEnd"
                      type="datetime"
                      size="small"
                      placeholder="结束时间"
                      default-time="23:59:59"
                      value-format="yyyy-MM-dd HH:mm:ss"
                      @keyup.enter.native="handleSearch"
                  >
                  </el-date-picker>

                </div>

              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="4">
            <el-form-item label="渠道名称">
              <f-select  v-model="queryForm.channelId" :dict="'kd_channel'"
                         :isNeed="queryForm.channelId" ></f-select>
            </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="4">
              <el-form-item label-width="30px">
                <el-button
                    type="primary"
                    icon="el-icon-search"
                    @click="handleSearch"
                >查询
                </el-button
                >
                <el-button plain @click="handleReset">重置</el-button>
              </el-form-item>
            </el-col>
          </el-row>


        </el-card>
      </el-form>
      <div class="bbb">

        <el-button
            type="primary"
            size="medium"
            @click="exportExcelPlus(url.exportExcelUrl,'快递订单利润')"
            v-if="buttonShowList.dckdlr">
          导出快递订单利润
        </el-button>
        <el-button
            type="primary"
            size="medium"
            @click="exportExcelPlus(url.exportExcelUrl2,'快递订单利润明细')"
           >
          导出快递订单利润明细
        </el-button>
        <el-button
            type="primary"
            size="medium"
            @click="exportExcelPlus(url.exportExcelUrl3,'快递渠道利润')"
        >
          导出快递订单渠道利润
        </el-button>
      </div>

      <f-table
          v-loading="loading"
          ref="GTable"
          border
          size="medium"
          row-key="id"
          class="c-th-has-bgcolor"
          :columns="columns"
          :data="tableData"
          @selection-change="handleSelectionChange"
          @sort-change="handleTableChange"
          :cell-style="{ 'text-align': 'center' }"
          tableHeight="73vh"
      >
        <template v-slot="scope">
          <template v-if="scope.column.property === 'orderNo'">
            <a
                style="color: #00a0e9"
                @click.prevent="$intoDocPage(scope.row.orderNo)"
            >{{ scope.row.orderNo }}</a
            >
          </template>
          <template v-else-if="scope.column.property === 'customer'">
            <a
                style="color: #00a0e9"
                @click.prevent="$intoCustomerEditPage(scope.row.cusId)"
            >{{ scope.row.customer }}</a
            >
          </template>
          <template v-else-if="scope.column.property === 'settleTime'">
            <span v-if="scope.row.lastRecSettleTime">应收：{{scope.row.lastRecSettleTime}}</span> <br>
            <span v-if="scope.row.lastPaySettleTime">应付：{{scope.row.lastPaySettleTime}}</span>
          </template>
        </template>
      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination
            v-bind="ipagination"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange">
        </el-pagination>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import QueryMixins from "@/mixins/QueryMixins.js";

export default {
  name: "TakeOrderWork",
  mixins: [QueryMixins],
  components: {},
  data() {
    return {
      isUsePost: true,

      columns: [

        {
          prop: "orderNo",
          label: "订单号",
        },
        {
          prop: "runOrderNo",
          label: "运单号",
        },
        {
          prop: "logNo",
          label: "跟踪单号",
        },
        {
          prop: "applyNo",
          label: "申请编号",
        },
        {
          prop: "applyCreateBy",
          label: "申请人",
        },
        {
          prop: "orderCreateTime",
          label: "下单时间",
          minWidth:120
        },
        {
          prop: "deliverGoodsTime",
          label: "发货时间",
          minWidth:120
        },
        {
          prop: "settleTime",
          label: "销账时间",
          minWidth:150
        },
        {
          prop: "removeBy",
          label: "审批人",
        },
        {
          prop: "customer",
          label: "客户名",
        },
        {
          prop: "settlement",
          label: "结算对象",
        },
        {
          prop: "salesman",
          label: "业务员",
        },
        {
          prop: "doc",
          label: "客服",
        },
        {
          prop: "businessType",
          label: "业务类型",
        },
        {
          prop: "moneyType",
          label: "币种",
        },
        {
          prop: "recMoney",
          label: "收款金额",
        },
        {
          prop: "payMoney",
          label: "付款金额",
        },
        {
          prop: "profit",
          label: "利润",
        },

      ],
      url: {
        //查询列表的接口

        list: "/order/kdOrderSettle/getKdOrderProfitVOIpage2",

        //导出excel的接口

        exportExcelUrl: '/order/kdOrderSettle/getKdOrderProfitVOExcel2',
        exportExcelUrl2: '/order/kdOrderSettle/exportKdOrderProfitDetailExcel',
        exportExcelUrl3: '/order/kdOrderSettle/exportKdOrderProfitSumExcel',
      },


    };
  },
  methods: {},
  computed:{
    buttonShowList(){
      return {
        'dckdlr':this.$btnIsShow('hyFinanceManage/modules/financialStatements/KdOrderProfit','0','导出快递订单利润'),
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.bbb {
  display: flex;
}

.dialog-footer {
  text-align: right !important;
}

.margin_right_10 {
  margin-right: 10px;
}
</style>
