<template>
  <el-dialog
      title="预览"
      :visible="visible"
      width="80%"
      :before-close="handleClose"
      @close="handleClose"
  >
    <div class="addEditBox">
      <div class="top">
        <h3>标题：{{form.title}}</h3>
        <h4 >工作场景：{{form.workPlace}}</h4>
        <h4 >人员：{{form.ren}}</h4>
        <h4 >工作流程：{{form.workProcess}}</h4>
        <h4 >出现的问题及影响：{{form.mayProblem}}</h4>
        <h4 >需要实现的效果：{{form.completeDesc}}</h4>
        <h4 >涉及的页面：{{form.shejiPages}}</h4>
        <h4 >是否影响其他部门：{{form.excuseOther}}</h4>
        <h4 >沟通备注：{{form.communicateRemark}}</h4>
        <h4 >暂停备注：{{form.pauseRemark}}</h4>
        <!--<div >
          &lt;!&ndash; style="text-align: center"  class="content preview_content" <span style="display: inline">发布端口：{{formatPushport(form.pushPort)}}</span>&nbsp;&nbsp;&ndash;&gt;
          <span >发布版本：{{form.version}}</span>&nbsp;&nbsp;
          <span >{{form.createTime}}</span>
        </div>-->
      </div>
      详细内容：
      <div  v-html="form.text" v-if="form.status !== '7'">
      </div>
    </div>
  </el-dialog>
</template>
<script>
export default {
  name:'NeedsPreview',
  props:{
    form:{
      type:Object
    },
    visible: {
      type: Boolean
    }

  },
  data(){
    return{

    }
  },
  methods:{
    format(val){
      return val.replace("\\","");
    },
    formatPushport(val){
      if (val == 0){
        return 'PC后台';
      }else if (val =='1'){
        return 'PC客户端';
      }else if (val == '2'){
        return 'Applets后台';
      }else {
        return 'Applets客户端'
      }
    },
    handleClose() {
      this.$emit('update:visible', false);

    }
  }
}
</script>
<style scoped>
img{
  width:860px
}
p{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.addEditBox {
  max-height: 625px;
  overflow: scroll;
}
</style>
