<template>
  <el-dialog
    title="新增退仓"
    :visible="visible"
    width="50%"
    custom-class="c-custom-dialog"
    :before-close="handleClose"
    append-to-body
    :close-on-click-modal="false"
  >
    <el-form
      class="addEditBox"
      :model="form"
      :rules="rules"
      ref="form"
      size="medium"
    >
      <el-row class="flex_center" :gutter="30">
        <!-- <el-col>
          <el-col :span="13">
            <el-form-item prop="orderNo" label="需要转仓的订单号" key="orderNo">
              <el-input v-model="queryOrderNo"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-button type="primary" @click="queryWarehouseRollDataByNo">查询</el-button>
          </el-col>
        </el-col> -->
        <el-col :span="13">
          <el-form-item
            prop="orderNo"
            label="需要退仓的订单号"
            key="orderNo"
            :label-width="formLabelWidth"
          >
            <el-input v-model="queryOrderNo" type="textarea"></el-input>
          </el-form-item>
        </el-col>
        <el-button
          type="primary"
          @click="queryWarehouseRollDataByNo"
          @keyup.enter.native="queryWarehouseRollDataByNo"
          style="margin-bottom: 12.735px"
          >查询</el-button
        >

        <el-col style="margin-bottom: 10px">
          <div>
            <span style="margin-left: 80px">合计</span>
          <span style="margin-left:114px">箱数：{{totalNumber}}</span>
          <span style="margin-left:108px">重量：{{totalWeight}}</span>
          <span style="margin-left:104px">体积：{{totalVolume}}</span>
          </div>
          <el-table
            :data="selectOrderList"
            border
            max-height="200"
            ref="innerTable"
            :key="Math.random()"
          >
            <el-table-column prop="orderNo" label="订单号">
              <template slot-scope="scope">
                <span>{{ scope.row.ttOrderNo }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="ctn" label="箱数">
              <template slot-scope="scope">
                <el-input v-model="scope.row.number" type="number" @change="caculateNumberAndWeightAndVolume"></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="kg" label="重量">
              <template slot-scope="scope">
                <el-input v-model="scope.row.weight" type="number" @change="caculateNumberAndWeightAndVolume"></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="cmb" label="体积">
              <template slot-scope="scope">
                <el-input v-model="scope.row.volume" type="number" @change="caculateNumberAndWeightAndVolume"></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="deliveryWarehouseName" label="交货仓库">
              <template slot-scope="scope">
                <span>{{ scope.row.deliveryWarehouseName }}</span>
              </template>
            </el-table-column>
            <el-table-column>
              <template slot-scope="scope">
                <el-button type="text" @click="removeOneLine(scope.$index)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-col>

        <!--            转仓类型-->

        <el-col :span="13">
          <el-form-item
            prop="originatingWarehouseId"
            label="起运仓库"
            :label-width="formLabelWidth"
            key="originatingWarehouseId"
          >
            <f-select
              v-model="form.originatingWarehouseId"
              :is-need="form.originatingWarehouseId"
              :data-list="selectWarehouseList"
              @change="
                changeWarehouseInfoByIdAndName(
                  form.originatingWarehouseId,
                  'originatingWarehouseInfo'
                )
              "
            ></f-select>
            <div>{{ form.originatingWarehouseInfo }}</div>
          </el-form-item>
        </el-col>

        <el-col :span="13">
          <el-form-item
            prop="shipToSupplierId"
            label="送货地址"
            :label-width="formLabelWidth"
            key="shipToSupplierId"
          >
            <f-select
              v-model="form.shipToSupplierId"
              :is-need="form.shipToSupplierId"
              :data-list="selectCustomerSupplierList"
              @change="changeCustomerSupplierName(form.shipToSupplierId)"
            ></f-select>
            <div>
              <span>公司：{{form.shipToCompany}}</span> <br>
              <span>地址：{{form.shipToAddress}}</span> <br>
              <span>联系人：{{form.shipToContact}}</span> <br>
              <span>联系电话：{{form.shipToPhone}}</span>
            </div>
          </el-form-item>
        </el-col>

        <el-col :span="13">
          <el-form-item
            prop="originatingWarehouseTime"
            label="退仓用车时间"
            :label-width="formLabelWidth"
            key="originatingWarehouseTime"
          >
            <el-date-picker
              id="getOriginatingWarehouseTime"
              v-model="form.originatingWarehouseTime"
              type="datetime"
              :format="'yyyy-MM-dd HH:mm:ss'"
              placeholder="选择时间"
              style="width: 100%"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>


        <el-col :span="13">
          <el-form-item
            prop="palletNum"
            label="托盘数"
            :label-width="formLabelWidth"
            key="palletNum"
          >
            <el-input v-model="form.palletNum" type="number"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="13">
          <el-form-item
            prop="remark"
            label="备注"
            :label-width="formLabelWidth"
            key="remark"
          >
            <el-input
              v-model="form.remark"
              maxlength="500"
              show-word-limit
              type="textarea"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer">
      <el-button size="medium" @click="handleClose">取消</el-button>
      <el-button type="primary" size="medium" @click="handleSubmit"
        >确认</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { postAction, getAction } from "@/api/manage";
import { validateCtn } from "../../../utils/validator";
import {baoliuwei} from "../../../utils/caculate";

export default {
  name: "addTuicangDialog",
  props: {
    visible: {
      type: Boolean,
    },
  },
  data() {
    return {
      totalNumber:0,
      totalWeight:0,
      totalVolume:0,
      isEdit: false,
      form: {},
      queryOrderNo: "",
      formLabelWidth: "110px",
      selectOrderList: [],
      selectWarehouseList: [],
      selectWarehouseMap: {},
      selectCustomerSupplierList: [],
      selectCustomerSupplierMap: {},
      rules: {
        rollType: [
          { required: true, message: "请选择退仓类型", trigger: "change" },
        ],
        originatingWarehouseId: [
          { required: true, message: "请选择起运仓库", trigger: "change" },
        ],
        shipToSupplierId: [
          { required: true, message: "请选择送货地址", trigger: "change" },
        ],
        originatingWarehouseTime: [
          { required: true, message: "请选择退仓用车时间", trigger: "change" },
        ],

        palletNum: [{ validator: validateCtn, trigger: "change" }],
      },
      url: {
        //生成其他出库数据
        addWarehouseRoll: "/warehouse/takeDelivery/addTuicang",
        queryWarehouseRollDataByNo: "/warehouse/takeDelivery/queryWarehouseRollDataByNo",
        getWarehouseSelectVOs: "/warehouse/takeDelivery/getWarehouseSelectVOs",
        getCustomerSupplierSelectDataListByNos: "/warehouse/takeDelivery/getCustomerSupplierSelectDataListByNos",

      },
      getsTime: "",
    };
  },
  watch: {
    visible(val) {
      if (val) {
        getAction(this.url.getWarehouseSelectVOs).then((res) => {
          if (res && res.success && res.result) {
            this.selectWarehouseList = res.result.vos;
            this.selectWarehouseMap = res.result.voMap;
          }
        });
      }
    },
  },
  methods: {
    changeCustomerSupplierName(val){
      if (this.selectCustomerSupplierMap && this.selectCustomerSupplierMap[val]) {
        let obj = this.selectCustomerSupplierMap[val];
        this.$set(this.form,'shipToCompany',obj.name);
        this.$set(this.form,'shipToAddress',obj.address);
        this.$set(this.form,'shipToContact',obj.contact);
        this.$set(this.form,'shipToPhone',obj.tel);
      }
    },
    caculateNumberAndWeightAndVolume(){
      this.totalNumber = 0;
      this.totalWeight = 0;
      this.totalVolume = 0;
      if (this.selectOrderList && this.selectOrderList.length > 0) {
        this.selectOrderList.forEach(item => {
          if (item.number) {
            this.totalNumber += Number(item.number);
          }
          if (item.weight) {
            this.totalWeight += Number(item.weight);
          }
          if (item.volume) {
            this.totalVolume += Number(item.volume);
          }
        })
      }
      this.totalWeight = baoliuwei(this.totalWeight,3);
      this.totalVolume = baoliuwei(this.totalVolume,3);
    },
    removeOneLine(index){
      this.selectOrderList.splice(index,1);
      this.caculateNumberAndWeightAndVolume()
    },
    //根据 仓库id 赋值 详情
    changeWarehouseInfoByIdAndName(val, name) {
      if (this.selectWarehouseMap && this.selectWarehouseMap[val]) {
        this.$set(this.form, name, this.selectWarehouseMap[val]["info"]);
      }
    },
    // 根据订单号查询之后 新增一行
    queryWarehouseRollDataByNo() {
      // 1.判断是否有输入订单号
      if (!this.queryOrderNo) {
        this.$message.warning("请输入订单号在查询");
        return false;
      }
      //判断这个订单号 是否已经用过了;
      if (!this.selectOrderList) {
        this.selectOrderList = [];
      }
      let useNos = this.selectOrderList.map(s=>s.ttOrderNo);
      for (let i = 0; i < useNos.length; i++) {
        let no = useNos[i];
        if (this.queryOrderNo.indexOf(no) !== -1) {
          this.$message.warning("请不要添加重复的订单");
          return false;
        }
      }

      getAction(this.url.queryWarehouseRollDataByNo, {
        orderNo: this.queryOrderNo,
      }).then((res) => {
        if (!this.selectOrderList) {
          this.selectOrderList = [];
        }
        if (res && res.success && res.result && res.result.length > 0) {
          for (let i = 0; i < res.result.length; i++) {
            this.selectOrderList.push(res.result[i]);
          }
        this.initCustomerSupplierList();
        }
        this.caculateNumberAndWeightAndVolume();
      });
    },
    listToMap(list, name) {
      var map = {};
      for (let i=0;i<list.length;i++) {
        map[list[i][name]] = list[i];
      }
      return map;
    },
    initCustomerSupplierList(){
      let list = this.selectOrderList;
      if (!list || list.length === 0) {
        this.$message.warning('请先输入单号');
      }
      let nos = this.selectOrderList.map(s=>s.ttOrderNo);
      postAction(this.url.getCustomerSupplierSelectDataListByNos,nos)
          .then(res=>{
            if (res && res.result) {
              this.selectCustomerSupplierList = res.result;
              this.selectCustomerSupplierMap = this.listToMap(this.selectCustomerSupplierList,'value');
            }
          })
    },
    updateThinfo() {
      if (this.form.contactId) {
        let obj =
          this.selectSupplierMap && this.selectSupplierMap[this.form.contactId];
        if (obj) {
          this.$set(this.form, "thSupplier", obj.name);
          this.$set(this.form, "contact", obj.contact);
          this.$set(this.form, "contactTel", obj.tel);
          this.$set(this.form, "contactAdd", obj.address);
        }
      }
    },
    handleSubmit() {

      if (!this.selectOrderList || this.selectOrderList.length === 0) {
        this.$message.warning("需要退仓的订单列表不能为空");
        return false;
      }
      this.form.originatingWarehouseTime = this.$momonet(
          new Date(this.form.originatingWarehouseTime)
      ).format("yyyy-MM-DD HH:mm:ss");
      this.form.innerDataList = this.selectOrderList;
      this.$refs["form"].validate((valid) => {
        if (valid) {

          postAction(this.url.addWarehouseRoll, this.form).then((res) => {
            this.$message.success(res.message);
            this.handleClose();
          });
        }
      });
    },
    handleClose() {
      this.form = {};
      this.selectOrderList = [];
      this.selectWarehouseMap = {};
      this.selectCustomerSupplierList = [];
      this.selectCustomerSupplierMap = {};
      this.queryOrderNo = "";
      this.totalWeight=0;
      this.totalVolume=0;
      this.totalNumber=0;
      this.$emit("ok");
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding: 20px 40px 20px 6px;
}

.addEditBox {
  max-height: 615px;
  overflow: scroll;
}
</style>
