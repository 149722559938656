<!--订单管理暂时未做-->
<template>
    <div class="main-full-content">
        <el-form ref="form" :model="queryForm" label-width="80px" size="medium">
            <el-row>
                <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <el-form-item label="客户名称">
                        <el-input v-model="queryForm.name"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <el-form-item label="业务员">
                        <el-select
                            clearable
                            filterable
                            class="f-full-width"
                            v-model="queryForm.salesman"
                            placeholder="请选择">
                            <el-option
                                v-for="item in []"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <el-form-item label="跟进人">
                        <el-select
                            clearable
                            filterable
                            class="f-full-width"
                            v-model="queryForm.createBy"
                            placeholder="请选择">
                            <el-option
                                v-for="item in []"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <el-form-item label="注册性质">
                        <el-select
                            clearable
                            filterable
                            class="f-full-width"
                            v-model="queryForm.createBy"
                            placeholder="请选择">
                            <el-option
                                v-for="item in []"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <el-form-item label="客户等级">
                        <el-select
                            clearable
                            filterable
                            class="f-full-width"
                            v-model="queryForm.createBy"
                            placeholder="请选择">
                            <el-option
                                v-for="item in []"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="12">
                    <el-form-item label="注册时间">
                        <div class="f-flex">
                            <el-date-picker
                                class="f-flex-1"
                                v-model="queryForm.start"
                                type="datetime"
                                size="small"
                                placeholder="开始时间"
                                default-time="00:00:00"
                                value-format="yyyy-MM-dd HH:mm:ss">
                            </el-date-picker>
                            <span class="f-p-h-2">~</span>
                            <el-date-picker
                                class="f-flex-1"
                                v-model="queryForm.end"
                                type="datetime"
                                size="small"
                                placeholder="结束时间"
                                default-time="23:59:59"
                                value-format="yyyy-MM-dd HH:mm:ss">
                            </el-date-picker>
                        </div>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <el-form-item label-width="30px">
                        <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
                        <el-button plain @click="handleReset">重置</el-button>
                        <el-button type="primary" icon="el-icon-download" @click="handleExportXls">导出</el-button>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <div class="f-p-b-15">
            <el-button type="primary" size="small" @click="handleBatTransfer" :disabled="selectionRows.length === 0">批量转让</el-button>
            <el-button type="primary" size="small" @click="handleBatAddFollower" :disabled="selectionRows.length === 0">批量设置跟进人</el-button>
            <el-button type="primary" size="small" class="c-theme-error-button" @click="handleBatDel" :disabled="selectionRows.length === 0">批量删除</el-button>
            <el-button type="primary" size="small" @click="handleSendEmail" :disabled="selectionRows.length !== 1">发送邮件</el-button>
            <el-button type="primary" size="small" @click="handleSendMsg" :disabled="selectionRows.length !== 1">发送短信</el-button>
        </div>
        <div class="c-alert f-m-b-10">
            已选择 <span class="stress">{{this.selectedRowKeys.length}}</span> 项
            <span class="stress f-m-l-12 f-cursor-pointer" @click="handleClearSelection">清空</span>
        </div>
        <f-table
            v-loading="loading"
            ref="GTable"
            border
            size="medium"
            row-key="id"
            class="c-th-has-bgcolor"
            :columns="columns"
            :data="tableData"
            :cell-style="{ 'text-align': 'center' }"
            @selection-change="handleSelectionChange"
            @sort-change="handleTableChange">
            <template v-slot="scope">
                <template v-if="scope.column.property === 'action'">
                    <el-button type="text" @click="handleEdit(scope.row)">编辑</el-button>
                    <el-button type="text" @click="handleFrozen(scope.row)">d冻结</el-button>
                    <el-button type="text" @click="handlTransfer(scope.row)">转让</el-button>
                    <el-button type="text" @click="handlAddRecord(scope.row)">添加拜访</el-button>
                    <el-button type="text" @click="handlAddFollower(scope.row)">设置跟进人</el-button>
                </template>
            </template>
        </f-table>
        <el-row class="f-text-right f-p-v-8">
            <el-pagination
                v-bind="ipagination"
                layout="total, sizes, prev, pager, next, jumper"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange">
            </el-pagination>
        </el-row>

        <msg-dialog :visible.sync="msgVisible" :row="row"></msg-dialog>
        <assign-dialog :visible.sync="assignVisible" :ids="ids" :row="row" :title="assignTitle"></assign-dialog>
        <add-follower-dialog :visible.sync="visible" :ids="ids" :row="row" :title="followTitle"></add-follower-dialog>
    </div>
</template>

<script>
import { putAction, deleteAction } from '@/api/manage'
import QueryMixins from "@/mixins/QueryMixins.js"
import { setUpRouteUtil } from '@/utils/util';

export default {
    name: 'Normal',
    mixins: [QueryMixins],
    components: {
        MsgDialog,
        AssignDialog,
        AddFollowerDialog
    },
    data() {
        return {
            ids: [],
            row: {},
            visible: false,
            assignVisible: false,
            msgVisible: false,
            emailVisible: false,
            followTitle: '',
            assignTitle: '',
            columns: [
                {
                    type: 'selection',
                    width: 50
                },
                {
                    prop: 'action',
                    label: '操作',
                    width: 140,
                },
                {
                    prop: 'name',
                    label: '客户名称',
                    sortable: 'custom',
                },
                {
                    prop: 'follow',
                    label: '客户等级',
                    sortable: 'custom',
                },
                {
                    prop: 'follow1',
                    label: '客户来源',
                    sortable: 'custom',
                },
                {
                    prop: 'follow2',
                    label: '提成性质',
                    sortable: 'custom',
                },
                {
                    prop: 'createBy',
                    label: '跟进人',
                    sortable: 'custom',
                },
                {
                    prop: 'salesman',
                    label: '业务员',
                    sortable: 'custom',
                },
                {
                    prop: 'email',
                    label: '最后跟进时间',
                    sortable: 'custom',
                    width: 160
                },
                {
                    prop: 'phone',
                    label: '注册时间',
                    sortable: 'custom',
                    width: 160
                },
                {
                    prop: 'need',
                    label: '分配时间',
                    sortable: 'custom',
                    width: 160
                },
                {
                    prop: 'source',
                    label: '跟进结果',
                    sortable: 'custom',
                },
            ],
            url: {
                list: '/customer/normalList',
                exportXlsUrl: '/customer/exportZcXls',
                deleteBatch: '',
                approvalBatch: '',
            }
        }
    },
    methods: {
        handleEdit({id}) {
            // 对于路由不在左侧菜单中的选中菜单处理
            setUpRouteUtil(this.$route.path, '/customer/Edit');
            this.$router.push(`/customer/Edit?id=${id}`)
        },
        handleBatTransfer() {
            this.ids = this.selectedRowKeys
            this.assignTitle = '批量转让'
            this.assignVisible = true
        },
        handleBatBack() {

        },
        handleBatAddFollower() {
            this.ids = this.selectedRowKeys
            this.followTitle = '批量添加跟进人'
            this.visible = true
        },
        handleBatDel() {
            // this.$confirm('确定删除？', '提示', {
            //     confirmButtonText: '确定',
            //     cancelButtonText: '取消',
            //     type: 'error',
            //     closeOnClickModal: false,
            // }).then(() => {
            //     deleteAction(this.url.deleteBatch, {ids: this.selectedRowKeys.join(',')}).then(res => {
            //         this.$message({
            //             type: 'success',
            //             message: res.message
            //         })
            //         this.handleSearch()
            //     })
            // }).catch(() => {

            // })
        },
        handleSendEmail() {
            this.row = this.selectionRows[0]
            this.emailVisible = true
        },
        handleSendMsg() {
            this.row = this.selectionRows[0]
            this.msgVisible = true
        },
        handleFrozen() {

        },
        handlTransfer() {
            this.row = row
            this.ids = [row.id]
            this.assignTitle = '转让'
            this.assignVisible = true
        },
        handlAddRecord() {

        },
      handleReset(){
        this.queryForm = {};
          this.filterForm = {};
        this.init();
        this.ipagination.currentPage = 1
        this.handleQuery()
      },
        // handlAddFollower() {
        //     this.row = row
        //     this.ids = [row.id]
        //     this.followTitle = '添加跟进人'
        //     this.visible = true
        // }
    }
}
</script>

<style lang="scss" scoped>

</style>
