<template>
  <!-- 批量下载入仓单 -->
  <div>
    <el-card class="box-card">
      <el-form class="multiline_select" ref="form" :model="queryForm" label-width="80px" size="medium" :rules="rules">
        <el-row :gutter="24" class="inputs_row">
          <el-col :md="4" :sm="24">
            <el-form-item label="账单类型" label-width="60px" >
              <el-select v-model="queryForm.settleType" clearable filterable
              >
                <el-option v-for="(item,index) in settleTypeList" :value="item.value"
                           :label="item.label" :key="index"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :md="4" :sm="24">
            <el-form-item label="录入人" label-width="60px">
              <f-select :dict="'sys_user_id'" v-model="queryForm.createByList"
                        :is-need="queryForm.createByList" :multiple="true"></f-select>
            </el-form-item>
          </el-col>
          <el-col :md="4" :sm="24">
            <el-form-item label="修改人" label-width="60px">
              <f-select :dict="'sys_user_id'" v-model="queryForm.updateByList"
                        :is-need="queryForm.updateByList" :multiple="true"></f-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="录入时间" label-width="70px">
              <div class="f-flex">
                <el-date-picker
                        class="f-flex-1"
                        v-model="queryForm.createBeginTime"
                        type="datetime"
                        size="small"
                        placeholder="开始时间"
                        :picker-options="editStartOptionsLock"
                        default-time="00:00:00"
                        value-format="yyyy-MM-dd HH:mm:ss"
                >
                </el-date-picker>
                <span class="f-p-h-2">~</span>
                <el-date-picker
                        class="f-flex-1"
                        v-model="queryForm.createEndTime"
                        type="datetime"
                        size="small"
                        :picker-options="editEndOptionsLock"
                        placeholder="结束时间"
                        default-time="23:59:59"
                        value-format="yyyy-MM-dd HH:mm:ss"
                >
                </el-date-picker>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="修改时间" label-width="70px">
              <div class="f-flex">
                <el-date-picker
                        class="f-flex-1"
                        v-model="queryForm.updateBeginTime"
                        type="datetime"
                        size="small"
                        placeholder="开始时间"
                        :picker-options="editUpdateStartOptionsLock"
                        default-time="00:00:00"
                        value-format="yyyy-MM-dd HH:mm:ss"
                >
                </el-date-picker>
                <span class="f-p-h-2">~</span>
                <el-date-picker
                        class="f-flex-1"
                        v-model="queryForm.updateEndTime"
                        type="datetime"
                        size="small"
                        :picker-options="editUpdateEndOptionsLock"
                        placeholder="结束时间"
                        default-time="23:59:59"
                        value-format="yyyy-MM-dd HH:mm:ss"
                >
                </el-date-picker>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-button size="medium" type="primary" icon="el-icon-download" @click="exportExcelPlus(url.prodDeptSettleExport,queryForm.settleType =='0'?'应收':'成本')" :loading="exportLoading">导出</el-button>
            <el-button plain @click="handleReset">重置</el-button>
          </el-col>

        </el-row>

      </el-form>

    </el-card>
  </div>
</template>
<script>
  import { printPdfDoc } from "@/utils/downloadJasper";
  import { getAction } from "../../api/manage";
  import QueryMixins from "@/mixins/QueryMixins.js";


  export default {
    components: {
    },
    mixins: [QueryMixins],
    props: {
    },
    data() {
      return {
        editUpdateStartOptionsLock: {
          disabledDate: (time) => {
            if (!this.queryForm.updateEndTime) {
              // time = new Date(time.replace(/-/g, '/'));
              return time.getTime() < new Date(1970 - 1 - 1).getTime(); //禁止选择1970年以前的日期
            } else {
              return !(new Date(this.queryForm.updateEndTime).getTime() > time.getTime() && time.getTime() > new Date(this.queryForm.updateEndTime).getTime() - this.day32);
            }
          },
        },
        editUpdateEndOptionsLock: {
          disabledDate: (time) => {
            if (!this.queryForm.updateBeginTime) {
              // time = new Date(time.replace(/-/g, '/'));
              return time.getTime() < new Date(1970 - 1 - 1).getTime(); //禁止选择1970年以前的日期
            } else {
              return !(new Date(this.queryForm.updateBeginTime).getTime() + this.day32 > time.getTime() && time.getTime() > new Date(this.queryForm.updateBeginTime).getTime());
            }
          },
        },
        editStartOptionsLock: {
          disabledDate: (time) => {
            if (!this.queryForm.createEndTime) {
              // time = new Date(time.replace(/-/g, '/'));
              return time.getTime() < new Date(1970 - 1 - 1).getTime(); //禁止选择1970年以前的日期
            } else {
              return !(new Date(this.queryForm.createEndTime).getTime() > time.getTime() && time.getTime() > new Date(this.queryForm.createEndTime).getTime() - this.day32);
            }
          },
        },
        editEndOptionsLock: {
          disabledDate: (time) => {
            if (!this.queryForm.createBeginTime) {
              // time = new Date(time.replace(/-/g, '/'));
              return time.getTime() < new Date(1970 - 1 - 1).getTime(); //禁止选择1970年以前的日期
            } else {
              return !(new Date(this.queryForm.createBeginTime).getTime() + this.day32 > time.getTime() && time.getTime() > new Date(this.queryForm.createBeginTime).getTime());
            }
          },
        },
        day32:2764800000,
        settleTypeList:[
          {
            label:'应收',
            value:0,
          },
          {
            label:'成本',
            value: 1
          }
        ],
        queryForm: {
          promotionNo: '',
        },
        rules: {
          orderNo: [
            { required: true, message: "请输入要下载入仓单的单号", trigger: "blur" },
          ],
        },
        url: {
          //下载入仓单
          prodDeptSettleExport: "/file/export/excel/prodDeptSettleExport",
          getDownLoadOrderNos:"file/jasper/download/getDownLoadOrderNos"
        }
      }
    },
    watch: {
    },
    created() {
    },
    mounted() {
    },
    methods: {
      handleReset() {
        this.$set(this.queryForm, 'promotionNo', '');
      },
    }
  }
</script>
<style lang="scss" scoped>
</style>