<template>
    <div>
        <el-card class="box-card">
            <el-form class="multiline_select" ref="form" :model="queryForm" label-width="80px" size="medium">
                <el-card class="box-card">
                    <el-row  class="inputs_row">
                        <el-col :xs="24" :sm="12" :md="12" :lg="6">
                            <el-form-item label="订单号">
                                <el-input v-model="queryForm.orderNo" placeholder="多个需要完整单号" type="textarea"
                                          @keyup.enter.native="handleSearch"></el-input>
                            </el-form-item>
                        </el-col>


                        <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="7">
                            <el-form-item label="操作时间">
                                <div class="f-flex">
                                    <el-date-picker
                                            class="f-flex-1"
                                            v-model="queryForm.createTimeStart"
                                            type="datetime"
                                            size="small"
                                            placeholder="开始时间"
                                            default-time="00:00:00"
                                            value-format="yyyy-MM-dd HH:mm:ss"
                                    >
                                    </el-date-picker>
                                    <span class="f-p-h-2">~</span>
                                    <el-date-picker
                                            class="f-flex-1"
                                            v-model="queryForm.createTimeEnd"
                                            type="datetime"
                                            size="small"
                                            placeholder="结束时间"
                                            default-time="23:59:59"
                                            value-format="yyyy-MM-dd HH:mm:ss"
                                    >
                                    </el-date-picker>
                                </div>
                            </el-form-item>
                        </el-col>

                        <el-col :xs="24" :sm="12" :md="12" :lg="4">
                            <el-form-item label-width="30px">
                                <el-button
                                        type="primary"
                                        icon="el-icon-search"
                                        @click="handleSearch"
                                >查询
                                </el-button
                                >
                                <el-button plain @click="handleReset">重置</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>


                </el-card>
            </el-form>
            <div class="bbb">
              <el-button type="primary" size="medium" @click="exportExcelPlus(url.downloadExcelUrl,'放货记录')">导出</el-button>
            </div>
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="73vh"
            >
              <template v-slot="scope">
                <template v-if="scope.column.property === 'orderNo'">
                  <a style="color: #00a0e9" @click.prevent="$intoDocPage(scope.row.orderNo)">{{ scope.row.orderNo }}</a>
                </template>
              </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange">
                </el-pagination>
            </el-row>
        </el-card>
    </div>
</template>

<script>
    import QueryMixins from "@/mixins/QueryMixins.js";

    export default {
        name: "FanghuoRecord",
        mixins: [QueryMixins],
        components: {},
        data() {
            return {
                row: {},
                isUsePost: true,
                columns: [
                    {
                        type: "selection",
                    },

                    {
                        prop: "orderNo",
                        label: "订单号",
                    },
                  {
                    prop: "salesman",
                    label: "业务员",
                  },
                  {
                    prop: "doc",
                    label: "客服",
                  },
                  {
                    prop: "operator",
                    label: "排柜操作",
                  },
                  {
                    prop: "endOperator",
                    label: "派送操作",
                  },
                    {
                        prop: "createBy",
                        label: "操作人",
                    },
                    {
                        prop: "createTime",
                        label: "操作时间",
                    },
                    {
                        prop: "content",
                        label: "描述",
                    },
                    {
                        prop: "eta",
                        label: "ETA",
                    },
                    {
                        prop: "podEnd",
                        label: "目的港",
                    },
                ],
                url: {
                    list: "/file/export/excel/queryFanghuoRecordIPage",
                    downloadExcelUrl:'/file/export/excel/downloadFanghuoRecordExcel',
                },
            };
        },
    };
</script>

<style lang="scss" scoped>
    .bbb {
        display: flex;
    }

    .dialog-footer {
        text-align: right !important;
    }

    .margin_right_10 {
        margin-right: 10px;
    }
</style>
