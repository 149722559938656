<template>
    <el-form :model="form" ref="form" :rules="rules" label-width="110px" size="medium">
        <el-row class="inputs_row">
            <el-col :span="8">
              <el-form-item label="性质" prop="supplierNatureList">
                  <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选
                  </el-checkbox>
                  <div style="margin: 15px 0;"></div>
                  <el-checkbox-group v-model="form.supplierNatureList" @change="handleCheckedCitiesChange" v-if="fsee">
                      <el-checkbox v-for="(item,index) in oldCities" :label="item.value" :key="index">{{item.text}}</el-checkbox>
                  </el-checkbox-group>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="供应商中文名" prop="chinaseName">
                  <el-input v-model="form.chinaseName" autocomplete="off" maxlength="50" show-word-limit :disabled="jinyongName"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="供应商英文名" prop="englishName">
                  <el-input v-model="form.englishName" autocomplete="off" maxlength="50" show-word-limit :disabled="jinyongName"></el-input>
              </el-form-item>
            </el-col>
      <!--      <el-col :span="8">
              <el-form-item label="简称" prop="shortname">
                <el-input v-model="form.shortname" autocomplete="off" maxlength="50" show-word-limit></el-input>
              </el-form-item>
            </el-col>-->
            <el-col :span="8">
              <el-form-item label="结算方式" prop="settlementType">
                  <f-select
                          v-model="form.settlementType"

                          :data-list="queryDictCodeListMap['sup_settle']"
                          :isNeed="form.settlementType"
                          v-if="fsee"
                          placeholder="请选择">

                  </f-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="供应商类型" prop="supplierTypeList">
                  <el-select v-model="form.supplierTypeList" placeholder="请选择" multiple>
                      <el-option
                              v-for="item in optionssupplierType"
                              :key="item.value"
                              :label="item.text"
                              :value="item.value"
                                  >
                      </el-option>
                  </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8" v-if="form.isVirtual !== '1'">
              <el-form-item label="联系人" prop="contact">
                  <el-input v-model="form.contact" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8" v-if="form.isVirtual !== '1'">
              <el-form-item label="联系电话" prop="tel">
                  <el-input v-model="form.tel" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8" v-if="form.isVirtual !== '1'">
              <el-form-item label="座机" prop="landline">
                  <el-input v-model="form.landline" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8" v-if="form.isVirtual !== '1'">
              <el-form-item label="邮箱" prop="email">
                  <el-input v-model="form.email" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="8" v-if="form.isVirtual !== '1'">
                <el-form-item label="详细地址" prop="registerAddress">
                    <el-input v-model="form.registerAddress" autocomplete="off"></el-input>
                </el-form-item>
            </el-col>
            <el-col :span="8" v-if="form.isVirtual !== '1'">
                <el-form-item label="" prop="advantageRoute" class="advantageRoute_item" label-width="140px">
                       <template slot="label">
                            <!-- 优势航线 -->
                        <el-tooltip placement="top" class="flex_center">
  <div slot="content" >
    <div>请参考以下示例填写：</div>
  该代理主营美国，欧洲航线，优势船司：emc，cosco。有5台自营车辆
    </div>
    <div>
        优势航线
   <img src="@assets/liru.png" style="width:20px;height:20px"/>
    </div>

</el-tooltip>

                        </template>
                             <el-input v-model="form.advantageRoute" autocomplete="off"></el-input>


                </el-form-item>
            </el-col>
            <el-col :span="8">
                <el-form-item label="备注" prop="remarks">
                    <el-input v-model="form.remarks" autocomplete="off"></el-input>
                </el-form-item>
            </el-col>
            <el-col :span="8">
                <el-form-item label="结算方式备注" prop="settlementTypeRemark">
                    <el-input v-model="form.settlementTypeRemark" autocomplete="off"></el-input>
                </el-form-item>
            </el-col>
            <el-col :span="8" v-if="form.isVirtual !== '1'">
                <el-form-item label="供应商跟进人" prop="salesmanIdList">
                    <f-select
                            v-model="form.salesmanIdList"

                            :data-list="queryDictCodeListMap['sys_user_id']"
                            :is-need="form.salesmanIdList"
                            v-if="fsee"
                            :multiple="true"
                            placeholder="请选择">

                    </f-select>
                </el-form-item>
            </el-col>
            <el-col :span="8">
                <el-form-item label="供应商推荐人" prop="recommendBy">
                    <el-input v-model="form.recommendBy" autocomplete="off"></el-input>
                </el-form-item>
            </el-col>
            <el-col :span="8">
                <el-form-item label="排序" prop="sort">
                    <el-input v-model="form.sort" autocomplete="off" type="number"></el-input>
                </el-form-item>
            </el-col>

            <el-col :span="16">
                <el-form-item class="address_select" prop="" label="注册地址">
                    <el-col :xs="24" :sm="12" :md="12" :lg="8">
                        <el-form-item prop="country" label=" " label-width="10px">
                            <el-select
                                    clearable
                                    filterable
                                    class="f-full-width"
                                    v-model="form.country"
                                    @change="initStateList"
                                    placeholder="请选择国家"
                                    @click.native="initCountryList">
                                <el-option
                                        v-for="(item,index) in countrylist"
                                        :key="index"
                                        :label="item.label"
                                        :value="item.value"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="8">
                        <el-form-item prop="state" label=" " label-width="10px">
                            <el-select

                                    clearable
                                    filterable
                                    @change="initCityList"
                                    class="f-full-width"
                                    v-model="form.state"
                                    placeholder="请选择州/省">
                                <el-option
                                        v-for="(item,index) in statelist"
                                        :key="index"
                                        :label="item.label"
                                        :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="8">
                        <el-form-item prop="city" label=" " label-width="10px">
                            <el-select
                                    clearable
                                    filterable
                                    class="f-full-width"
                                    v-model="form.city"
                                    placeholder="请选择城市">
                                <el-option
                                        v-for="(item,index) in citylist"
                                        :key="index"
                                        :label="item.label"
                                        :value="item.value">
                                </el-option>
                            </el-select>

                        </el-form-item>
                    </el-col>
                </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="8">
                <el-form-item prop="outletsName" label="网点" >
                    <f-select
                        :data-list="queryDictCodeListMap['sys_area_kingdee']"
                        v-model="form.outletsCode"
                        :is-need="form.outletsCode"
                        @changet="getOutSiteName"></f-select>

                </el-form-item>
            </el-col>
        </el-row>

    </el-form>
</template>

<script>
    import {emailPattern,zhengshuPattern} from '@/utils/pattern'
    import {getAction, getallcountries, getallrealtion, getallsalesmans, getcityList, getstateList} from "@/api/manage";
    import {commission, grade, payType, regType, userType} from '@/utils/options'
    import QueryMixins from "@/mixins/QueryMixins";

    const form = {}
    const supplierMoblieValidator = (rule, value, callback) => {
        const val = (value !== undefined && value !== null && (value + '').trim()) || ''
        let flag = null;
        getAction('/sys/supplier/checkPhone', {phone: value})
            .then(res => {
                flag = res.result;
                console.log("检查手机号是否存在", flag);
                if (!flag) {
                    callback(new Error('该手机号已经存在'));
                } else {
                    callback();
                }

            }).catch(err => {
            callback(new Error('请求错误'));
        })
        callback();
    }
    export default {
        name: 'SupplierForm',
      mixins:[QueryMixins],
        props: {
            row: {
                type: Object,
                default: () => {
                }
            },
            span: {
                type: Number,
                default: 8
            },
        },
        data() {
            return {
              disableMixinMounted: true,
              needQueryDictCodes:['sup_settle','sys_user_id','sys_area_kingdee'],
                optionssupplierType:[],
                ffvisible: false,
                rules: {
                    supplierNatureList: [
                        {required: true, message: '请选择供应商性质', trigger: 'change'},
                    ],
                    chinaseName: [
                        {required: true, message: '请输入中文名', trigger: 'blur'},
                     /*   {pattern: zhognwenPattern, message: '请输入中文', trigger: 'change'},*/
                        {max: 50, message: '长度不能超过50', trigger: 'change'},
                    ],
                    englishName: [
                        {required: true, message: '请输入英文名', trigger: 'blur'},
                        // {pattern: yingwenPattern, message: '请输入英文', trigger: 'change'},
                        {max: 50, message: '长度不能超过50', trigger: 'change'},
                    ],
                    settlementType: [
                        {required: true, message: '请选择结算方式', trigger: 'change'}
                    ],
                    supplierTypeList: [
                        {required: true, message: '请选择供应商类型', trigger: 'change'}
                    ],
                    contact: [
                        {required: true, message: '请输入联系人', trigger: 'blur'},
                        {max: 50, message: '长度不能超过50', trigger: 'change'},
                    ],
                    tel: [
                        {required: true, message: '请输入电话', trigger: 'blur'},
                        {validator: supplierMoblieValidator, trigger: 'blur'},
                        // {pattern: mobilePattern, message: '手机号格式有误', trigger: 'change'},
                    ],
                    landline: [
                        {required: true, message: '请输入座机电话', trigger: 'blur'},
                        {max: 250, message: '长度不能超过250', trigger: 'change'},
                    ],
                    email: [
                        {required: true, message: '请输入邮箱', trigger: 'blur'},
                        {pattern: emailPattern, message: '邮箱格式有误', trigger: 'change'},
                    ],
                    // country: [
                    //     {required: true, message: '请选择国家', trigger: 'change'}
                    // ],
                    // state: [
                    //     {required: true, message: '请选择州', trigger: 'change'}
                    // ],
                    // city: [
                    //     {required: true, message: '请选择城市', trigger: 'change'}
                    // ],
                    address: [
                        {required: true, message: '请输入地址', trigger: 'change'}
                    ],
                    registerAddress: [
                        {required: true, message: '请输入详细地址', trigger: 'blur'},
                        {max: 255, message: '长度不能超过255', trigger: 'change'},
                    ],
                    advantageRoute:[
                         {required: true, message: '请输入优势航线', trigger: 'change'}
                    ],
                    remarks: [
                        {max: 255, message: '长度不能超过255', trigger: 'change'},
                    ],
                    settlementTypeRemark: [
                        {max: 255, message: '长度不能超过255', trigger: 'change'},
                    ],
                    salesmanIdList: [
                        {required: true, message: '请选择供应商跟进人', trigger: 'change'}
                    ],
                    sort: [
                        {pattern: zhengshuPattern, message: '请输入正整数', trigger: 'change'},
                    ],
                },

                grade,
                payType,
                commission,
                regType,
                userType,
                props: {
                    userName: {
                        type: String
                    }
                },
                form: {...form, supplierNatureList: [],supplierTypeList:[],salesmanIdList:[]},

                //国家基础数据
                countrylist: [],
                //港前操作
                docList: [],
                //业务员
                salesmanList: [],
                //城市基础数据
                citylist: [],
                //州基础数据
                statelist: [],
                //
                url: {
                    queryById: '/sys/supplier/queryById'
                },
                checkAll: false,
                checkedCities: [],
                cities: [],
                oldCities:[],
                isIndeterminate: false,
                fsee:false,
                // 禁用中文名修改 英文名修改
                jinyongName:false,
                isVirtual:false,
            }
        },
        methods: {
            getOutSiteName(val){
                this.form.outletsName = val;
            },
            async initSupplierType(){
                if (sessionStorage.getItem('dict:sup_type')){
                    this.optionssupplierType = JSON.parse(sessionStorage.getItem('dict:sup_type'));

                }else {
                 await getAction('/sys/dict/getDictItems/sup_type')
                        .then(res=>{
                            this.optionssupplierType = res.result;
                        })
                }

            },
           async initCitites(){
                if (sessionStorage.getItem('dict:supplier_nature')){
                    this.oldCities = JSON.parse(sessionStorage.getItem('dict:supplier_nature'));
                    this.cities = this.oldCities.map(function (e) {
                        return e.value;
                    });
                }else {
                  await  getAction('/sys/dict/getDictItems/supplier_nature')
                    .then(res=>{
                        this.oldCities = res.result;
                        this.cities = this.oldCities.map(function (e) {
                            return e.value;
                        });
                    })
                }

            },
            handleCheckAllChange(val) {
                this.form.supplierNatureList = val ? this.cities : [];
                // console.log('后台数据',this.form.supplierNatureList)
                // this.form.supplierNatureList = this.checkedCities;
                this.isIndeterminate = false;
            },
            handleCheckedCitiesChange(value) {
                console.log('变化的单选', value);
                let checkedCount = value.length;
                this.checkAll = checkedCount === this.cities.length;
                this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length;
            },

            //初始化国家基础数据
            initCountryList() {
                if (this.countrylist.length <= 0) {
                    getallcountries().then(res => {
                        if (res.success) {
                            for (var i = 0; i < res.result.length; i++) {
                                let obj = {};
                                obj.value = res.result[i].cnName;
                                obj.label = res.result[i].twoCode + "-" + res.result[i].cnName;
                                this.countrylist.push(obj);
                            }
                        }
                    })
                }

            },
            initStateList(val) {
                this.statelist = [];
                this.citylist = [];
                // this.form.bdCity='';
                // this.form.bdState='';
                if (val) {

                    getstateList({cnName: val})
                        .then((res) => {

                            if (res.success && res.result) {
                                this.statelist =
                                    res.result.records.map((item) => {
                                        let {cname: value, cname: label} = item;
                                        return {value, label};
                                    });
                            }
                        })
                } else {
                    // this.form.state = '';
                    // this.form.city = '';
                }

            },
            initCityList(val) {

                this.citylist = [];
                if (val) {
                    getcityList({cname: val})
                        .then((res) => {
                            if (res.success && res.result) {
                                this.citylist =
                                    res.result.records.map((item) => {
                                        let {cname: value, cname: label} = item;
                                        return {value, label};
                                    });
                            }
                        })
                }
            },
            initSalesmanList() {
                if (this.salesmanList.length <= 0) {
                    getallsalesmans().then(res => {
                        if (res.success) {
                            var list = res.result.records;
                            for (var i = 0; i < list.length; i++) {
                                var obj = {};
                                obj.value = list[i].id;
                                obj.label = list[i].username + "-" + res.result.records[i].realname;
                                this.salesmanList.push(obj);
                            }
                        }
                    })
                }
            },
            initDocList(val) {
                this.docList = [];
                if (val) {
                    getallrealtion({salesmanid: val}).then(
                        res => {
                            if (res.success) {
                                var list = res.result;
                                for (var i = 0; i < list.length; i++) {
                                    var obj = {};
                                    obj.label = list[i].doc;
                                    obj.value = list[i].docId;
                                    this.docList.push(obj);
                                    if (i === 0) {
                                        this.$set(this.form, 'docId', list[i].docId)
                                    }
                                }
                            }
                        }
                    )
                } else {
                    this.form.docId = '';
                }

            },
            initForm() {
                this.jinyongName = false;
                var id = this.$route.query.id;
                var isVirtual = this.$route.query.isVirtual;

                console.log("id", id);
                if (id) {
                    getAction(this.url.queryById, {id: id})
                        .then(res => {
                            if (res.result) {
                                for (var name in res.result) {
                                    this.$set(this.form, name, res.result[name]);
                                }
                                if (isVirtual) {
                                  this.form.isVirtual = isVirtual;
                                }
                                this.form.supplierNatureList = this.form.supplierNature && this.form.supplierNature.split(',');
                                this.form.supplierTypeList = this.form.supplierType &&  this.form.supplierType.split(',');
                                this.form.salesmanIdList = this.form.salesmanId && this.form.salesmanId.split(',');
                                //正常已合作 问题合作 暂停合作 历史合作 的 供应商 不允许修改 中文名 英文名
                                if (['3','4','5','6'].indexOf(this.form.cooperationType) !== -1) {
                                    this.jinyongName = true;
                                }else {
                                    this.jinyongName = false;
                                }
                            }
                            // this.form = res.result;

                            console.log("aaa", this.form);


                            this.initStateList(this.form.country);
                            this.initCitites();
                            this.initSupplierType();
                            this.fsee=true;
                            // this.initCityList(this.form.state);

                        })
                } else {
                    this.form = {supplierNatureList: [],supplierTypeList:[]};
                    this.initCitites();
                    this.initSupplierType();
                    this.fsee=true;
                    if (isVirtual) {
                      this.form.isVirtual = isVirtual;
                    }
                }


            },
            validateForm() {
                let flag = null;

                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        flag = true;
                    } else {
                        flag = false;
                    }
                })

                this.form.supplierNature = this.form.supplierNatureList && this.form.supplierNatureList.length > 0 && this.form.supplierNatureList.join(',');
                this.form.supplierType = this.form.supplierTypeList && this.form.supplierTypeList.length > 0 && this.form.supplierTypeList.join(',');
                this.form.salesmanId = this.form.salesmanIdList && this.form.salesmanIdList.length > 0 && this.form.salesmanIdList.join(',');
                // 赋值 是否虚拟
                if (this.$route.query.isVirtual) {
                  this.form.isVirtual = this.$route.query.isVirtual;
                }
                return flag;
            },
            resetForm() {
                this.form = {};
            }
        },
        mounted() {
            this.initForm();
            console.log('对象', this.form)


        },

    }
</script>
<style lang="scss">
.address_select {
  .el-select {
    width: 98%;
  }
}
.advantageRoute_item {
    .el-form-item__label {
        display: flex;
    justify-content: flex-end;
    align-items: center;
    }
}
</style>
<style lang="scss" scoped>

</style>
