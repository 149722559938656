<template>
  <el-form
      :model="form"
      :rules="rules"
      ref="form"
      label-width="110px"
      size="medium"
      :validate-on-rule-change="false"
  >
    <el-row class="inputs_row">

      <el-dialog
          :visible.sync="getCompany"
          width="50%"
          trigger="click">
        <div style="display: flex;flex-wrap: wrap">
          <el-col :span="12" style="margin-bottom: 10px">
            <span style="color: #00a0e9">信用代码：</span>&nbsp;&nbsp;<span v-text="companyDetail.creditCode"></span>
          </el-col>
          <el-col :span="12">
            <span style="color: #00a0e9">工商注册号：</span>&nbsp;&nbsp;<span v-text="companyDetail.regNumber"></span>
          </el-col>

          <el-col :span="12" style="margin-bottom: 10px">
            <span style="color: #00a0e9">公司名：</span>&nbsp;&nbsp;<span v-text="companyDetail.companyName"></span>
          </el-col>
          <el-col :span="12">
            <span style="color: #00a0e9">组织机构代码：</span>&nbsp;&nbsp;<span
              v-text="companyDetail.companyCode"></span>
          </el-col>
          <el-col :span="12" style="margin-bottom: 10px">
            <span style="color: #00a0e9">营业状态：</span>&nbsp;&nbsp;<span
              v-text="companyDetail.businessStatus"></span>
          </el-col>
          <el-col :span="12">
            <span style="color: #00a0e9">注册类型：</span>&nbsp;&nbsp;<span v-text="companyDetail.regType"></span>
          </el-col>

          <el-col :span="12" style="margin-bottom: 10px">
            <span style="color: #00a0e9">公司类型：</span>&nbsp;&nbsp;<span
              v-text="companyDetail.companyType"></span>
          </el-col>

          <el-col :span="12">
            <span style="color: #00a0e9">成立日期：</span>&nbsp;&nbsp;<span v-text="companyDetail.issueTime"></span>
          </el-col>

          <el-col :span="12" style="margin-bottom: 10px">
            <span style="color: #00a0e9">法人：</span>&nbsp;&nbsp;<span v-text="companyDetail.faRen"></span>
          </el-col>
          <el-col :span="12">
            <span style="color: #00a0e9">注册资本：</span>&nbsp;&nbsp;<span v-text="companyDetail.regMoney"></span>
          </el-col>

          <el-col :span="12" style="margin-bottom: 10px">
            <span style="color: #00a0e9">登记机关：</span>&nbsp;&nbsp;<span v-text="companyDetail.regOrgName"></span>
          </el-col>
          <el-col :span="12">
            <span style="color: #00a0e9">地区代码：</span>&nbsp;&nbsp;<span v-text="companyDetail.areaCode"></span>
          </el-col>

          <el-col :span="12" style="margin-bottom: 10px">
            <span style="color: #00a0e9">地区名：</span>&nbsp;&nbsp;<span v-text="companyDetail.areaName"></span>
          </el-col>
          <el-col :span="12">
            <span style="color: #00a0e9">电话：</span>&nbsp;&nbsp;<span v-text="companyDetail.phone"></span>
          </el-col>
          <el-col :span="12" style="margin-bottom: 10px">
            <span style="color: #00a0e9">邮箱：</span>&nbsp;&nbsp;<span v-text="companyDetail.email"></span>
          </el-col>

          <el-col :span="12">
            <span style="color: #00a0e9">官网：</span>&nbsp;&nbsp;
            <span v-text="companyDetail.webSite"></span>

          </el-col>
          <el-col :span="12" style="margin-bottom: 10px">
            <span style="color: #00a0e9">地址：</span>&nbsp;&nbsp;<span v-text="companyDetail.address"></span>
          </el-col>
          <el-col :span="12">
            <span style="color: #00a0e9">股东成员：</span>
          </el-col>
          <el-col :span="24" style="margin-bottom: 10px">
            <span style="color: #00a0e9">经营范围：</span>&nbsp;&nbsp;<span
              v-text="companyDetail.bussinessDes"></span>
          </el-col>
          <div v-for="(item,index) in companyDetail.gdList" :key="index">
            <span style="color: #00a0e9">姓名：</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span
              v-text="item.gdName"></span>
            <span style="color: #00a0e9">出资：</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span
              v-text="item.money"></span>
            <span style="color: #00a0e9">占股：</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span
              v-text="item.proportion"></span>
          </div>
          <br>
          <div v-for="(item,index) in companyDetail.memberList" :key="index">
            <span style="color: #00a0e9">姓名：</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span
              v-text="item.memberName"></span>
            <span style="color: #00a0e9">职位：</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span
              v-text="item.position"></span>
          </div>

        </div>


      </el-dialog>

      <el-col :span="spanValue">
        <el-form-item prop="username" label="客户名">
          <el-input style=""
                    :disabled="(form.status =='0' && form.intent == '2') || vailCheck()" v-model="form.username"
                    @blur="form.username=$event.target.value.trim()" maxlength="50" show-word-limit>
            <el-button slot="append" icon="el-icon-search" @click="checkCompany" v-if="form.regType =='0'"></el-button>
          </el-input>

        </el-form-item>
      </el-col>

      <el-col :span="spanValue">
        <el-form-item prop="userCode" label="客户编码">
          <el-input style="padding: 0 45px 0 0"
                    disabled
                    v-model="form.kehuMa"
                    maxlength="50"
                    show-word-limit
          ></el-input>
        </el-form-item>
      </el-col>

      <el-col :span="spanValue">
        <el-form-item prop="name" label="物流联系人" :rules="form.isPush===1?[]:rules.name">
          <el-input style="padding: 0 45px 0 0"
                    v-model="form.name"
                    maxlength="50"
                    show-word-limit
                    :disabled=vailCheck()
          ></el-input>
        </el-form-item>
      </el-col>

      <el-col :span="spanValue">
        <el-form-item prop="phone" label="手机号" :rules="form.isPush===1?[]:rules.phone">
          <el-input v-model="form.phone" :disabled=vailCheck()></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="spanValue">
        <el-form-item prop="email" label="邮箱" :rules="form.isPush===1?[]:rules.email">
          <div class="flex_center_start" v-if="form.isForeign == '1' && form.id">
            <el-input v-model="form.email" disabled></el-input>
            <el-button type="text" v-if="!(vailCheck())" @click="handleEditEmail">修改</el-button>
          </div>
          <el-input v-else v-model="form.email" :disabled=vailCheck()></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="spanValue">
        <el-form-item prop="isForeign" label="是否国外" :rules="form.isPush===1?[]:rules.isForeign">
          <el-radio-group :disabled=vailCheck() v-model="form.isForeign">
            <el-radio label="0">否</el-radio>
            <el-radio label="1">是</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item prop="regType" label="注册性质" :rules="form.isPush===1?[]:rules.regType">
          <el-radio-group :disabled=vailCheck() v-model="form.regType">
            <el-radio label="0">企业</el-radio>
            <el-radio label="1">个人</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-col>
        <el-col :span="9">
            <el-form-item prop="isClothing" label="是否服装白名单客户" v-if="buttonShowList.whiteCustomerShow">
                <el-radio-group  v-model="form.isClothing">
                    <el-radio label="0">否</el-radio>
                    <el-radio label="1">是</el-radio>
                </el-radio-group>
            </el-form-item>
        </el-col>
      <el-col :span="12">
        <el-form-item
            prop="frName"
            label=" 企业老板/企业负责人"
            :rules="form.regType == '0' ? otherRules.frName : []"
            label-width="160px"
            v-if="form.regType == 0"
        >
          <el-input v-model="form.frName" :disabled=vailCheck()></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="9">
        <el-form-item
            prop="frPhone"
            label="企业老板/企业负责人手机号"
            label-width="180px"
            :rules="form.regType == '0' ? otherRules.frPhone : otherRules.frPhone2"
            v-if="form.regType == 0">
          <el-input v-model="form.frPhone" :disabled=vailCheck()></el-input>
        </el-form-item>
      </el-col>

      <el-col :span="24">
        <el-form-item prop="address1">
          <span class="is_require_form" slot="label">办公地址</span>
          <el-col :span="7" style="margin-right: 20px;">
            <el-form-item
                prop="bdCountry"
                :rules="form.isPush===1?[]:otherRules.bdCountry"
                label-width="15px"
            >
              <el-select
                  clearable
                  filterable
                  class="f-full-width"
                  v-model="form.bdCountry"
                  @change="initStateList"
                  placeholder="请选择"
                  @click.native="initCountryList"
                  :disabled=vailCheck()
              >
                <el-option
                    v-for="(item, index) in countrylist"
                    :key="index"
                    :label="item.label"
                    :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7" style="margin-right: 33px;">
            <el-form-item prop="bdState" :rules="form.isPush===1?[]:otherRules.state" label-width="15px">
              <el-select
                  clearable
                  filterable
                  @change="initCityList"
                  class="f-full-width"
                  v-model="form.bdState"
                  placeholder="获取州/省数据"
                  :disabled=vailCheck()
              >
                <el-option
                    v-for="(item, index) in statelist"
                    :key="index"
                    :label="item.label"
                    :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item prop="bdCity" :rules="form.isPush===1?[]:otherRules.city">
              <el-select
                  clearable
                  filterable
                  class="f-full-width"
                  v-model="form.bdCity"
                  placeholder="获取城市基础数据"
                  :disabled=vailCheck()
              >
                <el-option
                    v-for="item in citylist"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-form-item>
      </el-col>

      <el-col :span="spanValue">
        <el-form-item
            prop="address"
            label="详细办公地址"
            :rules="form.isPush===1?[]:otherRules.address"
        >
          <el-input v-model="form.address" type="textarea" rows="2" :disabled=vailCheck()></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="spanValue">
        <el-form-item prop="bookEmail" label="是否订阅邮箱" :rules="form.isPush===1?[]:rules.bookEmail">
          <el-radio-group v-model="form.bookEmail">
            <el-radio :label="'0'">否</el-radio>
            <el-radio :label="'1'">是</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-col>
      <el-col :span="spanValue">
        <el-form-item prop="bookCaijiSms" label="是否订阅材积短信" :rules="form.isPush===1?[]:rules.bookEmail">
          <el-radio-group v-model="form.bookCaijiSms">
            <el-radio :label="'0'">否</el-radio>
            <el-radio :label="'1'">是</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-col>
      <el-col :span="spanValue">
        <el-form-item prop="bookVolumeEmail" label="是否接收材积邮件">
          <el-radio-group v-model="form.bookVolumeEmail">
            <el-radio :label="'0'">否</el-radio>
            <el-radio :label="'1'">是</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-col>

      <el-col :span="spanValue">
        <el-form-item prop="grade" label="客户等级" :rules="form.isPush===1?[]:rules.grade">
          <f-select

              :dict="'customer_type'"
              v-model="form.grade"
              :isNeed="form.grade"
              placeholder="请选择"
          >
          </f-select>
        </el-form-item>
      </el-col>
      <el-col :span="spanValue">
        <el-form-item prop="userType" label="客户类型" :rules="form.isPush===1?[]:rules.userType">
          <f-select

              :dict="'user_type1'"
              v-model="form.userType"
              :isNeed="form.userType"
              :disabled=vailCheck()
              placeholder="请选择"
          >
          </f-select>
        </el-form-item>
      </el-col>
      <el-col :span="spanValue">
        <el-form-item prop="commission" label="提成类型" :rules="form.isPush===1?[]:rules.commission">
          <f-select
              :disabled="isAdmin() || vailCheck()"
              clearable
              filterable
              class="f-full-width"
              v-model="form.commission"
              :isNeed="form.commission"
              :dict="'commission'"></f-select>
        </el-form-item>
      </el-col>
      <el-col :span="spanValue">
        <el-form-item prop="bussinessTypeList" label="业务类型" :rules="form.isPush===1?[]:rules.bussinessTypeList">
          <!--                        <f-select-->
          <!--                                v-model="form.bussinessType"-->
          <!--                                :isNeed="form.bussinessType"-->
          <!--                                :dict="'customer_order_type'"-->
          <!--                        >-->
          <!--                        </f-select>-->
          <el-select filtable clearable multiple v-model="form.bussinessTypeList" :disabled=vailCheck()>
            <el-option value="0" label="海运"></el-option>
            <el-option value="1" label="空运"></el-option>
            <el-option value="2" label="快递"></el-option>
            <el-option value="3" label="海外仓"></el-option>
          </el-select>
        </el-form-item>
      </el-col>

      <el-col :span="6">
        <el-form-item prop="isExpensesBasic" label-width="130px" label="应收海运费自动录入" :rules="form.isPush===1?[]:rules.isExpensesBasic">
          <el-switch
              v-model="form.isExpensesBasic"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="1"
              :inactive-value="0"
              active-text="是"
              inactive-text="否"
          >
          </el-switch>
        </el-form-item>
      </el-col>

      <el-col :span="6">
        <el-form-item prop="isClientBillVisible" label-width="130px" label="客户端账单可见(海运)" :rules="rules.isClientBillVisible">
          <el-switch
              v-model="form.isClientBillVisible"
              active-color="#13ce66"
              inactive-color="#ff4949"
              active-value="1"
              inactive-value="0"
              active-text="是"
              inactive-text="否"
          >
          </el-switch>
        </el-form-item>
      </el-col>

      <el-col :span="spanValue">
        <el-form-item prop="source" label="客户来源" :rules="form.isPush===1?[]:rules.source">
          <f-select
              clearable
              filterable
              class="f-full-width"
              v-model="form.source"
              :isNeed="form.source"
              :dict="'cus_source'"
              :disabled=vailCheck()
          >
          </f-select>
        </el-form-item>
      </el-col>


      <el-col :span="spanValue">
        <el-form-item prop="needList" label="客户需求">
<!--          <el-input v-model="form.need" type="textarea" rows="3"></el-input>-->
          <f-select v-model="form.needList" :is-need="form.needList" :dict="'customer_need'" :multiple="true"></f-select>
        </el-form-item>
      </el-col>
      <el-col :span="spanValue">
        <el-form-item prop="remark" label="客户注意事项">
          <el-input
              v-model="form.remark"
              type="textarea"
              show-word-limit
              maxlength="500"
              placeholder="1.客户比较看中时效 2.客户包税、锁价 3.某某客户介绍....."
          ></el-input>
        </el-form-item>
      </el-col>
        <el-col :span="spanValue">
        <el-form-item prop="allowYubaoKaidan" label="是否允许前置打单">
          <el-radio-group  v-model="form.allowYubaoKaidan">
            <el-radio label="0">否</el-radio>
            <el-radio label="1">是</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-col>
<!--      <el-col :span="spanValue">-->
<!--        <el-form-item prop="isCanRecordOsHalf" label="是否需要计入海运间隔半年下单">-->
<!--          <el-radio-group  v-model="form.isCanRecordOsHalf">-->
<!--            <el-radio label="0">否</el-radio>-->
<!--            <el-radio label="1">是</el-radio>-->
<!--          </el-radio-group>-->
<!--        </el-form-item>-->
<!--      </el-col>-->
    </el-row>
    <edit-email-dialog :visible.sync="editEmailVisible" :row="{email: form.email,cusId: form.id}" @ok="() => {editEmailVisible = false;initForm()}"></edit-email-dialog>
  </el-form>
</template>

<script>
import {doublepattern, numberPattern,} from "../../../utils/pattern";
import {numberValidator, phoneValidator, usernameValidator} from "@/utils/validator";
import {getUrlKey} from "@/utils/route";
import {getAction, getallcountries, getallrealtion, getcityList, getstateList,} from "@/api/manage";
import {commission, grade, payType, regType, userType} from "@/utils/options";
import {deleteAction, postAction} from "../../../api/manage";
import EditEmailDialog from "./EditEmailDialog";

const form = {
  regType: "0",
  contract: [],
  haveContract: "0",
  isClientBillVisible: "1",
  needList:[],
  bookVolumeEmail:'1',
  bookCaijiSms:'1',
  bookEmail:'1'
};

export default {
  name: "CustomerForm",
  props: {
    row: {
      type: Object,
      default: () => {
      },
    },
    span: {
      type: Number,
      default: 8,
    },
  },
  components: {
    EditEmailDialog
  },
  data: function () {
    const validateNum = (rule, value, callback) => {
      if (value <= 0) {
        callback(new Error("要大于0"));
      } else if (
          value.toString().indexOf(".") !== -1 &&
          value.toString().split(".")[1].length > 3
      ) {
        callback(new Error("最多三位小数"));
      } else if (value.toString().split(".")[0].length > 10) {
        callback(new Error("最多十位数"));
      } else {
        callback();
      }
    };
    return {
      spanValue: 12,
      companyDetail: {},
      docvis: false,
      fileList: [],
      grade,
      payType,
      commission,
      regType,
      userType,
      form: {...form, fileList: []},
      editStartOptions: {
        disabledDate: (time) => {
          if (!this.form.contractEnd) {
            // time = new Date(time.replace(/-/g, '/'));
            return time.getTime() < new Date(1970 - 1 - 1).getTime(); //禁止选择1970年以前的日期
          } else {
            return time.getTime() > new Date(this.form.contractEnd);
          }
        },
      },
      editStopOptions: {
        disabledDate: (time) => {
          // time = new Date(time.replace(/-/g, '/'));
          return (
              time.getTime() < new Date(this.form.contractBegin) ||
              time.getTime() < new Date(1970 - 1 - 1).getTime() //禁止选择1970年以前的日期
          );
        },
      },
      otherRules: {
        frName: [
          {required: true, message: "请输入法人姓名", trigger: "blur"},
        ],
        frPhone: [
          {required: true, message: "请输入手机号", trigger: "blur"},
          // {
          //     pattern: mobilePattern,
          //     message: "请输入正确的手机号",
          //     trigger: "change",
          // },
        ],
        frPhone2: [{validator: phoneValidator, trigger: "blur"}],
        address1: [
          {required: true, message: "请输入注册地址", trigger: "blur"},
        ],
        bdCountry: [
          {required: true, message: "请选择国家", trigger: "change"},
        ],
        // bdState: [
        //     {required: true, message: "请选择州/省", trigger: "change"},
        // ],
        // 没有数据，校验暂时关闭
        // bdCity: [{ required: true, message: "请选择城市", trigger: "change" }],
        address: [
          {required: true, message: "请输入详细注册地址", trigger: "blur"},
        ],
        creditLimit: [
          {required: true, message: "请输入信用额度", trigger: "blur"},
          {validator: validateNum, trigger: "change"},
          {
            pattern: numberPattern,
            message: "请输入正确的数字",
            trigger: "change",
          },
        ],
        creditLimit2: [{validator: numberValidator, trigger: "blur"}],
        payNumber: [{required: true, trigger: "blur", message: '请输入天数'}],
        creditLimitTemp: [
          {required: true, message: "请输入临时信用额度", trigger: "blur"},

          {validator: validateNum, trigger: "change"},
          {
            pattern: doublepattern,
            message: "请输入正确的数字",
            trigger: "blur",
          },
        ],
        creditLimitTemp2: [{validator: numberValidator, trigger: "blur"}],
        contractBegin: [
          {required: true, message: "请选择合同开始时间", trigger: "change"},
        ],
        contractEnd: [
          {required: true, message: "请选择合同结束时间", trigger: "change"},
        ],
      },
      rules: {
        bussinessTypeList: [
          {required: true, message: '请选择业务类型', trigger: 'change'}
        ],
        source: [
          {required: true, message: "请选择购买来源", trigger: "change"},
        ],
        address: [
          {required: true, message: "请输入详细注册地址", trigger: "blur"},
          {max: 255, message: "长度不能超过255", trigger: "change"},
        ],
        name: [
          {required: true, message: "请输入联系人", trigger: "blur"},
          // {pattern: shuziPattern, message: '不能纯数字', trigger: 'change'},
        ],
        username: [
          {required: true, message: "请输入客户名称", trigger: "change"},
          // {pattern: shuziPattern, message: '不能纯数字', trigger: 'change'},
          {validator: usernameValidator, trigger: "blur"},
        ],
        phone: [
          {required: true, message: "请输入手机号", trigger: "blur"},
          // {
          //     pattern: mobilePattern,
          //     message: "请输入正确的手机号",
          //     trigger: "blur",
          // },
          // {validator: telValidator, trigger: 'blur'},
        ],

        email: [
          {required: true, message: "请输入邮箱", trigger: "blur"},
          {type: "email", message: "请输入正确的邮箱", trigger: "change"},
          // {validator: emailValidator, trigger: 'blur'},
        ],
        regType: [
          {required: true, message: "请选择注册性质", trigger: "blur"},
        ],
        grade: [
          {required: true, message: "请选择客户等级", trigger: "change"},
        ],
        userType: [
          {required: true, message: "请选择客户类型", trigger: "change"},
        ],
        commission: [
          {required: true, message: "请选择提成类型", trigger: "change"},
        ],
        salesmanId: [
          {required: true, message: "请选择业务员", trigger: "change"},
        ],
        // docId: [
        //     {required: true, message: '请选择港前操作', trigger: 'change'},
        // ],
        payType: [
          {required: true, message: "请选择付款方式", trigger: "change"},
        ],
        haveContract: [
          {required: true, message: "请选择有无合同", trigger: "change"},
        ],
        isExpensesBasic: [
          {required: true, message: "请选择是否自动录入费用", trigger: "change"},
        ],
        isClientBillVisible: [
          {required: true, message: "请选择客户端账单(海运)可见", trigger: "change"},
        ],
        needList: [
          {required: true, message: "请选择需求", trigger: "blur"},
        ],
        isForeign:[{
          required: true, message: "请选择是否国外", trigger: "change"
        }],
        remark:[
           { required: true, message: "请填写", trigger: "change"}
        ]
      },
      //国家基础数据
      countrylist: [],
      //付款方式 0信用付款 1单票送货前结算 2单票送货后结算
      payTypeList: [
        {value: "0", label: "信用付款"},
        {value: "1", label: "单票送货前结算"},
        {value: "2", label: "单票送货后结算"},
      ],

      //港前操作
      docList: [],
      //业务员
      //提成类型 0:自行开发1:公司客户2:团体客户，3:扶持区域

      //客户来源 0在线咨询 1电话咨询 2营销QQ 3邮件咨询 4客户介绍 5展会资源 6独立开发 7注册会员，8fbashipping ,9support 10 forest+（目前如果修改就是23，不用10了）
      //城市基础数据
      citylist: [],
      //州基础数据
      statelist: [],
      fsee: false,
      //
      url: {
        getbyid: "/customer/getById",
        upload: "/sys/oss/file/upload",
        delOss: "/sys/oss/file/delete",
        add: "/customer/file/add",
        checkCompany: '/customer/checkCompany',
      },
      isNormal: false,
      getCompany: false,
      editEmailVisible: false
    };
  },
  computed: {
    buttonShowList() {
      return {
        'saleCanSee': this.$btnIsShow('customer/Edit', '0', '销售是否可见'),
        'editCo': this.$btnIsShow('isystem/RoleManage', '0', '修改客户提成类型'),
        'whiteCustomerShow': this.$btnIsShow('customer/Edit', '0', '修改是否服装白名单客户'),
      }
    }
  },
  methods: {
    // 海外客户修改邮箱
    handleEditEmail() {
      this.editEmailVisible = true;
    },
    vailCheck() {
      // 只有正常客户才会根据权限去配置是否可以编辑，其他的都可以编辑
      if(this.form.status =='0' && this.form.intent == '2') {
        if (!this.form || !this.form.id) {
          //是新增
          // console.log("是新增",true);
          return false;
        } else if (this.buttonShowList.saleCanSee) {
          // console.log("你有权限",true);
          return false;
        } else {
          // console.log("你有一个鸡腿权限",true);
          return true
        }
      } else {
        return false;
      }
    },
    isAdmin() {
      let flag = true;
      if (!this.form || !this.form.id) {
        flag = false;
      } else if (this.form.status === '2') {
        //公海客户 可以编辑
        flag = false;
      } else if (this.form.status === '0' && this.form.intent === '0') {
        flag = false;
      } else {
        // let ss = localStorage.getItem("USERINFO");
        // if (ss) {

        // var user = JSON.parse(ss);
        // if (user && user.username === 'admin') {
        let flag2 = this.buttonShowList.editCo;
        if (flag2) {
          flag = false;
        }
        // }
        // }
      }
      return flag;
    },
    checkCompany() {
      this.companyDetail = {};
      if (!this.form.username) {
        //没有值 就不请求
        return false;
      }
      getAction(this.url.checkCompany, {companyName: this.form.username})
          .then(res => {
            if (!res.result || !res.result.result) {
              this.$message.warning('没有查询到该公司的相关信息呢');
            } else {
              this.companyDetail = res.result.result || {};
              if (this.companyDetail.creditCode) {
                this.getCompany = true;
              }
            }

          })

    },
    initCountryList() {
      if (this.countrylist.length <= 0) {
        getallcountries().then((res) => {
          if (res.success) {
            let filtrationArr = []
            for (var i = 0; i < res.result.length; i++) {
              let obj = {};
              obj.value = res.result[i].cnName;
              obj.label = res.result[i].twoCode + "-" + res.result[i].cnName;
              filtrationArr.push(obj);
            }
           this.countrylist =  this.moveItemToFront(filtrationArr,'中国')
          }
        });
      }
    },
    moveItemToFront(array, specifiedName) {
    let specifiedItemIndex = array.findIndex(item => item.value === specifiedName);
    if (specifiedItemIndex !== -1) {
        let specifiedItem = array.splice(specifiedItemIndex, 1)[0]; // Remove specified item
        array.unshift(specifiedItem); // Add it to the beginning of the array
    }
    return array;
},
    handleUpload(data) {
      const formData = new FormData();
      formData.append("file", data.file);
      formData.append("dir", 'customer');

      postAction(this.url.upload, formData).then((res) => {
        if (res.success) {
          //上传成功,返回结果赋值给form对象的文件集合
          let {id, size, ext, fileName, url} = res.result;
          //this.fileList.push({ossId: id, size, ext, name: fileName, url});

          this.$message.success("上传成功");
          if (!this.form.fileList) {
            this.form.fileList = [];
          }
          this.form.fileList.push(res.result);
        }
      });
    },
    removeFile(val) {
      const index = this.form.fileList.indexOf(val);
      if (val && val.status === "success") {
        //删除oss的文件

        deleteAction(this.url.delOss, {id: val.id})
            .then((res) => {
              if (res.success) {
                this.form.fileList.splice(index, 1);
                //同时清空文件的url
                this.$message.success("删除文件成功");
              } else {
                //清空filelist
                this.form.fileList.splice(index, 1);
              }
            })
            .catch((err) => {
              this.form.fileList.splice(index, 1);
            });
      }
    },
    //文件上传之前校验文件合法
    beforeUpload(file) {
      //对文件大小进行限制
      const isLt2M = file.size / 1024 / 1024 < 15;
      if (!isLt2M) {
        this.$message.warning("文件大小不能超过15MB");
      }
      const isFile = file.name.indexOf(".") !== -1;
      if (!isFile) {
        this.$message.warning("该文件名不合法");
      }
      const fileLnegth = file.name.length <= 25;
      if (!fileLnegth) {
        this.$message.warning('文件名长度最多25位')
      }
      return isLt2M && isFile && fileLnegth;
    },
    initStateList(val) {
      this.statelist = [];
      this.citylist = [];
      // this.form.bdCity='';
      // this.form.bdState='';
      if (val) {
        getstateList({cnName: val}).then((res) => {
          if (res.success) {
            for (var i = 0; i < res.result.records.length; i++) {
              let obj = {};
              obj.value = res.result.records[i].cname;
              obj.label =
                  res.result.records[i].code + "-" + res.result.records[i].cname;
              this.statelist.push(obj);
            }
          }
        });
      } else {
        // this.form.city = '';
        // this.form.state = '';
        // this.form.bdState = '';
        // this.form.bdCity = '';
      }
    },
    initCityList(val) {
      this.citylist = [];
      getcityList({cname: val})
          .then((res) => {
            if (res.success) {
              for (var i = 0; i < res.result.records.length; i++) {
                let obj = {};
                obj.value = res.result.records[i].cname;
                obj.label =
                    res.result.records[i].code + "-" + res.result.records[i].cname;
                this.citylist.push(obj);
              }
            }
          })
    },
    initDocList(val) {
      this.docvis = false;
      if (val) {
        getallrealtion({salesmanId: val})
            .then((res) => {
              if (res.success) {
                var list = res.result;
                if (list && list.length > 0) {
                  this.$set(this.form, "docId", list[0].docId);
                  this.docvis = true;
                  this.$set(this.form, "oceanSalesmanId", list[0].oceanSalesmanId);
                  // this.docvis = true;
                }

                // this.form.docId=list[i].docId;
              }
            })
      } else {
        this.form.docId = "";
      }
    },
    initForm() {
      var id = getUrlKey("id");
      var source = getUrlKey('source');
      this.companyDetail = {};
      if (id) {
        getAction(this.url.getbyid, {id: id}).then((res) => {
          this.form = res.result;
          if (this.form.bussinessType) {
            this.$set(this.form, 'bussinessTypeList', this.form.bussinessType.split(","));
          } else {
            this.$set(this.form, 'bussinessTypeList', []);
          }
          // this.$set(this.form,'creditLimit','')
          // this.$set(this.form,'creditLimitTemp','')
          this.form.fileList.map((f) => {
            f.id = f.ossId;
            // f.name = f.fileName;
          });
          this.initStateList(this.form.bdCountry);
          this.fsee = true;
          this.docvis = true;
          if (this.form.status === "0" && this.form.intent === "2") {
            this.isNormal = true;
          } else {
            this.isNormal = false;
          }
          if (this.$refs['form']) {
            this.$refs['form'].clearValidate();
          }
        });
      } else {
        this.form = {isClientBillVisible: "1", bookVolumeEmail:'1',
              bookCaijiSms:'1', bookEmail:'1', isClothing:'0'
        };
        this.fsee = true;
        this.isNormal = false;
        //设置为推广注册
        if (source) {
          this.$set(this.form, 'source', source);
          this.$set(this.form, 'isPush', 1);
        }

        if (this.$refs['form']) {
          this.$refs['form'].clearValidate();
        }
      }
    },
    validateForm() {
      let flag = null;
      this.$refs["form"].validate((valid) => {
        flag = valid;
      });
      return flag;
    },
    resetForm() {
      this.$refs.form.resetFields();
    },
  },
  created() {
    this.initForm();
  },
};
</script>

<style lang="scss" scoped>
</style>
