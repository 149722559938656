<template>
  <div class="main-full-content">

    <!--        <el-button type="primary"  icon="el-icon-download" @click="handleExportXls" size="small">导出</el-button>-->
    <f-table
        v-loading="loading"
        ref="GTable"
        border
        size="medium"
        row-key="id"
        class="c-th-has-bgcolor"
        :columns="columns"
        :data="tableData"
        :cell-style="{ 'text-align': 'center' }"
        @selection-change="handleSelectionChange"
        @sort-change="handleTableChange">
    </f-table>

    <el-row class="f-text-right f-p-v-8">
      <el-pagination
          v-bind="ipagination"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange">
      </el-pagination>
    </el-row>
  </div>
</template>

<script>
import {putAction, deleteAction, getallusers} from '@/api/manage'
import QueryMixins from "@/mixins/QueryMixins.js"

export default {
  name: "CurRateLogModule",
  mixins: [QueryMixins], props:{
    type:{
      type:String,
      default:'',
    }
  },
  data() {
    return {
      ipagination: {
        currentPage: 1,
        pageSize: 20,
        pageSizes: [20, 40, 60, 80, 100, 120],
        total: 0
      },
      columns: [

        {
          prop: 'name',
          label: '操作人',


          over:true,
        },
        {
          prop: 'content',
          label: '操作内容',

          over:true,
        },
        {
          prop: 'createTime',
          label: '操作时间',

          over:true,
        },
      ],
      url: {
        list: "/sys/bdCurRate/curRateLog/list",
        //exportXlsUrl: '/order/os/log/exportXls',
      },

    }
  },
  methods: {
    initVue(){
      this.queryForm.orderNo = this.$route.query.orderNo;
      this.queryForm.content = this.type;
      console.log('created,settleLog初始化....');
      this.handleQuery();
    },
  },


  created() {
    this.queryForm.orderNo = this.$route.query.orderNo;
    this.queryForm.content = this.type;
  }
}
</script>

<style scoped>

</style>
