<template>
    <div class="main-full-content">
        <el-form class="no_margin" ref="form" :model="queryForm" label-width="100px" size="small"
                 @submit.native.prevent>
            <el-card class="box-card">
                <el-row class="inputs_row">
                    <el-col :xs="24" :sm="12" :md="12" :lg="8">
                        <el-form-item label="主单号">
                            <el-input v-model="queryForm.masterBillNo" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="4">
                        <el-form-item label-width="30px">
                            <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
                            <el-button plain @click="handleReset">重置</el-button>

                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>
        </el-form>
        <el-card class="box-card last_card">
            <el-table
                    ref="GGTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor total_top_table"
                    :data="tableData"
                    max-height="600"
                    @selection-change="handleSelectionChange">
                <el-table-column
                        type="selection"
                        width="55">
                </el-table-column>
                <el-table-column prop="key" label="编号" width="32">
                    <template slot-scope="scope">
                        <span>{{scope.$index+1}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="week"
                        label="周数"
                        align="center"
                        width="120"
                >
                    <template slot-scope="scope">
                        <!-- 编辑状态下显示 -->
                        <span style="color: #00a0e9"
                              @click="$intoOperationCabinetEditPage(scope.row.containerId)">{{ scope.row.week }}
          </span>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="masterBillNo"
                        label="主单号"
                        align="center"
                        width="100"
                >
                    <template slot-scope="scope">
                        <!-- 编辑状态下显示 -->
                        <el-input
                                type="text"
                                style="width: 90%;height: 90%"
                                size="mini"
                                clearable
                                v-model.trim="scope.row.masterBillNo"
                        />
                    </template>
                </el-table-column>
                <el-table-column
                        prop="originAgentCode"
                        label="出口方代理"
                        align="center"
                        width="100"
                >
                    <template slot-scope="scope">
                        <!-- 编辑状态下显示 -->
                        <el-input
                                type="text"
                                style="width: 90%;height: 90%"
                                size="mini"
                                clearable
                                :disabled="true"
                                v-model.trim="scope.row.originAgentCode"
                        />
                    </template>
                </el-table-column>
                <el-table-column
                        prop="destinationAgentCode"
                        label="进口方代理"
                        align="center"
                        width="100"
                >
                    <template slot-scope="scope">
                        <!-- 编辑状态下显示 -->
                        <el-input
                                type="text"
                                style="width: 90%;height: 90%"
                                size="mini"
                                clearable
                                :disabled="true"
                                v-model.trim="scope.row.destinationAgentCode"
                        />
                    </template>
                </el-table-column>
                <el-table-column
                        prop="carrierNameCode"
                        label="船公司"
                        align="center"
                        width="100"
                >
                    <template slot-scope="scope">
                        <f-select
                                v-model="scope.row.carrierNameCode"
                                :isNeed="scope.row.carrierNameCode"
                                @focus="getSelectNode(scope.row)"
                                @change="changeShipCompany"
                                @clear="clearSelect(scope.row)"
                                :dict="'ams_ship_company'"></f-select>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="carrierNameCode"
                        label="船公司简码"
                        align="center"
                        width="100"
                >
                    <template slot-scope="scope">
                        <!-- 编辑状态下显示 -->
                        <el-input
                                type="text"
                                style="width: 90%;height: 90%"
                                size="mini"
                                :disabled="true"
                                clearable
                                v-model.trim="scope.row.carrierNameCode"
                        />
                    </template>
                </el-table-column>
                <el-table-column
                        prop="carrierNameValue"
                        label="船公司名称"
                        align="center"
                        width="100"
                >
                    <template slot-scope="scope">
                        <!-- 编辑状态下显示 -->
                        <el-input
                                type="text"
                                style="width: 90%;height: 90%"
                                size="mini"
                                clearable
                                v-model.trim="scope.row.carrierNameValue"
                        />
                    </template>
                </el-table-column>

                <el-table-column
                        prop="consignmentType"
                        label="业务模式"
                        align="center"
                        width="100"
                >
                    <template slot-scope="scope">
                        <el-select v-model="scope.row.consignmentType" placeholder="请选择">
                            <el-option
                                    v-for="item in consignmentTypeList"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                        <!-- 编辑状态下显示 -->
                    </template>
                </el-table-column>

                <el-table-column
                        prop="arrivalVesselValue"
                        label="船名"
                        align="center"
                        width="100"
                >
                    <template slot-scope="scope">
                        <!-- 编辑状态下显示 -->
                        <el-input
                                type="text"
                                style="width: 90%;height: 90%"
                                size="mini"
                                clearable
                                v-model.trim="scope.row.arrivalVesselValue"
                        />
                    </template>
                </el-table-column>

                <el-table-column
                        prop="arrivalVesselVoyage"
                        label="航次"
                        align="center"
                        width="100"
                >
                    <template slot-scope="scope">
                        <!-- 编辑状态下显示 -->
                        <el-input
                                type="text"
                                style="width: 90%;height: 90%"
                                size="mini"
                                clearable
                                v-model.trim="scope.row.arrivalVesselVoyage"
                        />
                    </template>
                </el-table-column>


                <el-table-column
                        prop="loadingUnPortCode"
                        label="起运港名称"
                        align="center"
                        width="100"
                >
                    <template slot-scope="scope">
                        <f-select
                                :isNeed="scope.row.loadingPortCode"
                                v-model="scope.row.loadingPortCode"
                                :dict="'ams_port'"></f-select>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="loadingPortDate"
                        label="起运港时间"
                        align="center" width="125"
                >
                    <template slot-scope="scope">
                        <el-date-picker
                                class="c-full-width"
                                v-model="scope.row.loadingPortDate"
                                type="date"
                                default-time="00:00:00"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                :picker-options="editStartOptions"
                                placeholder="请选择时间">
                        </el-date-picker>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="dischargeUnPortCode"
                        label="目的港名称"
                        align="center"
                        width="100"
                >
                    <template slot-scope="scope">
                        <f-select
                                :isNeed="scope.row.dischargePortCode"
                                v-model="scope.row.dischargePortCode"
                                :dict="'ams_port'"></f-select>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="dischargePortDate"
                        label="到港时间"
                        align="center" width="125"
                >
                    <template slot-scope="scope">
                        <el-date-picker
                                class="c-full-width"
                                v-model="scope.row.dischargePortDate"
                                type="date"
                                default-time="00:00:00"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                :picker-options="editStartOptions"
                                placeholder="请选择时间">
                        </el-date-picker>
                    </template>
                </el-table-column>
                <!--<el-table-column prop="createTime" label="创建时间">-->
                <!--<template slot-scope="scope">-->
                <!--<span>{{scope.row.createTime}}</span>-->
                <!--</template>-->
                <!--</el-table-column>-->
                <!--<el-table-column prop="createBy" label="录入人">-->
                <!--<template slot-scope="scope">-->
                <!--<span>{{scope.row.createBy}}</span>-->
                <!--</template>-->
                <!--</el-table-column>-->
                <!--<el-table-column prop="updateTime" label="修改时间">-->
                <!--<template slot-scope="scope">-->
                <!--<span>{{scope.row.updateTime}}</span>-->
                <!--</template>-->
                <!--</el-table-column>-->
                <el-table-column prop="name" label="操作">
                    <template slot-scope="scope">
                        <el-button type="text" @click="submitAmsInfo(scope.row)">提交</el-button>
                        <el-button type="text" @click="houseInfoDetail(scope.row)">New House</el-button>
                        <el-button type="text" @click="masterContainerDetail(scope.row)">Master Container</el-button>
                        <el-button type="text" @click="saveAmsInfo(scope.row)">保存</el-button>
                    </template>
                </el-table-column>

            </el-table>

            <el-dialog title="New House" :visible.sync="houseInfoDialogVisible" width="80vw">
                <el-button style="margin: 10px 0px" type="primary" icon="el-icon-search" @click="addHouseInfo">新增一行
                </el-button>
                <el-card class="box-card last_card">
                    <el-table :data="houseInfoList" class="c-th-has-bgcolor" :cell-style="{ 'text-align': 'center' }" border>
                        <el-table-column prop="key" label="编号" width="32">
                            <template slot-scope="scope">
                                <span>{{scope.$index+1}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="houseNo"
                                label="分单号"
                                align="center" width="100"
                        >
                            <template slot-scope="scope">
                                <!-- 编辑状态下显示 -->
                                <el-input
                                        type="text"
                                        style="height: 90%"
                                        size="mini"
                                        clearable
                                        v-model.trim="scope.row.houseNo"
                                />
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="placeReceiptCode"
                                label="出货地名称"
                                align="center" width="160"
                        >
                            <template slot-scope="scope">
                                <!-- 编辑状态下显示 -->
                                <f-select
                                        :isNeed="scope.row.placeReceiptCode"
                                        v-model="scope.row.placeReceiptCode"
                                        :dict="'ams_port'"></f-select>
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="placeReceiptValue"
                                label="出货日期"
                                align="center" width="135"
                        >
                            <template slot-scope="scope">
                                <el-date-picker
                                        class="c-full-width"
                                        v-model="scope.row.placeReceiptDate"
                                        type="date"
                                        default-time="00:00:00"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                        :picker-options="editStartOptions"
                                        placeholder="请选择时间">
                                </el-date-picker>
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="placeDeliveryCode"
                                label="交货地名称" width="160"
                                align="center"
                        >
                            <template slot-scope="scope">
                                <!-- 编辑状态下显示 -->
                                <f-select
                                        :isNeed="scope.row.placeDeliveryCode"
                                        v-model="scope.row.placeDeliveryCode"
                                        :dict="'ams_port'"></f-select>
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="placeReceiptValue"
                                label="交货日期"
                                align="center" width="135"
                        >
                            <template slot-scope="scope">
                                <el-date-picker
                                        class="c-full-width"
                                        v-model="scope.row.placeDeliveryDate"
                                        type="date"
                                        default-time="00:00:00"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                        :picker-options="editStartOptions"
                                        placeholder="请选择时间">
                                </el-date-picker>
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="placeReceiptValue"
                                label="Marks and Numbers"
                                align="center"
                                width="140"
                        >
                            <template slot-scope="scope">
                                <!-- 编辑状态下显示 -->
                                <el-input
                                        type="text"
                                        style="width: 90%;height: 90%"
                                        size="mini"
                                        clearable
                                        v-model.trim="scope.row.amsHouseContainerList && scope.row.amsHouseContainerList[0].marking"
                                />
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="placeReceiptValue"
                                label="货物描述"
                                align="center" width="140"
                        >
                            <template slot-scope="scope">
                                <!-- 编辑状态下显示 -->
                                <el-input
                                        type="text"
                                        style="width: 90%;height: 90%"
                                        size="mini"
                                        clearable
                                        v-model.trim="scope.row.amsHouseContainerList[0].description"
                                />
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="ShipperCompany"
                                label="Shipper Company"
                                align="center"
                                width="180"
                        >
                            <template slot-scope="scope">
                                <!--<f-select-->
                                        <!--:isNeed="scope.row.shipperRefCode"-->
                                        <!--v-model="scope.row.shipperRefCode"-->
                                        <!--:dict="'api_ams_customer'"></f-select>-->
                                <el-input
                                        type="text"
                                        style="width: 90%;height: 90%"
                                        size="mini"
                                        clearable
                                        v-model.trim="scope.row.shipperPartyName"
                                />
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="shipperCountry"
                                label="Shipper Country"
                                align="center"
                                width="120"
                        >
                            <template slot-scope="scope">
                                <f-select
                                        v-model="scope.row.shipperCountry"
                                        :isNeed="scope.row.shipperCountry"
                                        :dict="'ams_customer_country'">
                                </f-select>
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="shipperCity"
                                label="Shipper City"
                                align="center"
                                width="80"
                        >
                            <template slot-scope="scope">
                                <el-input
                                        type="text"
                                        style="width: 90%;height: 90%"
                                        size="mini"
                                        clearable
                                        v-model.trim="scope.row.shipperCity"
                                />
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="shipperPostal"
                                label="Shipper Postal"
                                align="center"
                                width="90"
                        >
                            <template slot-scope="scope">
                                <el-input
                                        type="text"
                                        style="width: 90%;height: 90%"
                                        size="mini"
                                        clearable
                                        v-model.trim="scope.row.shipperPostal"
                                />
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="ShipperAddress"
                                label="Shipper Address"
                                align="center"
                                width="160"
                        >
                            <template slot-scope="scope">
                                <el-input
                                        type="text"
                                        style="width: 90%;height: 90%"
                                        size="mini"
                                        clearable
                                        v-model.trim="scope.row.shipperAddressLine"
                                />
                            </template>
                        </el-table-column>

                        <el-table-column
                                prop="Consignee"
                                label="Consignee"
                                align="center"
                                width="160"
                        >
                            <template slot-scope="scope">
                                <!--<f-select-->
                                        <!--:isNeed="scope.row.consigneeRefCode"-->
                                        <!--v-model="scope.row.consigneeRefCode"-->
                                        <!--:dict="'api_ams_customer'"></f-select>-->
                                <el-input
                                        type="text"
                                        style="width: 90%;height: 90%"
                                        size="mini"
                                        clearable
                                        v-model.trim="scope.row.consigneePartyName"
                                />
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="consigneeCountry"
                                label="Consignee Country"
                                align="center"
                                width="115"
                        >
                            <template slot-scope="scope">
                                <f-select
                                        v-model="scope.row.consigneeCountry"
                                        :isNeed="scope.row.consigneeCountry"
                                        :dict="'ams_customer_country'">
                                </f-select>
                            </template>
                        </el-table-column>


                        <el-table-column
                                prop="consigneeCity"
                                label="Consignee City"
                                align="center"
                                width="120"
                        >
                            <template slot-scope="scope">
                                <el-input
                                        type="text"
                                        style="width: 90%;height: 90%"
                                        size="mini"
                                        clearable
                                        v-model.trim="scope.row.consigneeCity"
                                />
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="consigneePostal"
                                label="Consignee Postal"
                                align="center"
                                width="120"
                        >
                            <template slot-scope="scope">
                                <el-input
                                        type="text"
                                        style="width: 90%;height: 90%"
                                        size="mini"
                                        clearable
                                        v-model.trim="scope.row.consigneePostal"
                                />
                            </template>
                        </el-table-column>

                        <el-table-column
                                prop="ConsigneeAddress"
                                label="Consignee Address"
                                align="center"
                                width="160"
                        >
                            <template slot-scope="scope">
                                <el-input
                                        type="text"
                                        style="width: 90%;height: 90%"
                                        size="mini"
                                        clearable
                                        v-model.trim="scope.row.consigneeAddressLine"
                                />
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="containerRecNo"
                                label="柜号"
                                align="center" width="120"
                        >
                            <template slot-scope="scope">
                                <!-- 编辑状态下显示 -->
                                <el-input
                                        type="text"
                                        style="width: 90%;height: 90%"
                                        size="mini"
                                        clearable
                                        v-model.trim="scope.row.amsHouseContainerList[0].containerRefNo"
                                />
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="packAge"
                                label="件数"
                                align="center"
                        >
                            <template slot-scope="scope">
                                <!-- 编辑状态下显示 -->
                                <el-input
                                        type="text"
                                        style="width: 90%;height: 90%"
                                        size="mini"
                                        clearable
                                        v-model.trim="scope.row.amsHouseContainerList[0].packAge"
                                />
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="unitCode"
                                label="单位"
                                align="center"
                                width="110"
                        >
                            <template slot-scope="scope">
                                <f-select
                                        :isNeed="scope.row.amsHouseContainerList[0].unitCode"
                                        v-model="scope.row.amsHouseContainerList[0].unitCode"
                                        @change="e => scope.row.unitValue =e"
                                        :dict="'api_ams_unit'"></f-select>
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="grossWeight"
                                label="重量"
                                align="center"
                        >
                            <template slot-scope="scope">
                                <!-- 编辑状态下显示 -->
                                <el-input
                                        type="text"
                                        style="width: 90%;height: 90%"
                                        size="mini"
                                        clearable
                                        v-model.trim="scope.row.amsHouseContainerList[0].grossWeight"
                                />
                            </template>
                        </el-table-column>

                        <el-table-column
                                prop="cbm"
                                label="体积"
                                align="center"
                        >
                            <template slot-scope="scope">
                                <!-- 编辑状态下显示 -->
                                <el-input
                                        type="text"
                                        style="width: 90%;height: 90%"
                                        size="mini"
                                        clearable
                                        v-model.trim="scope.row.amsHouseContainerList[0].cbm"
                                />
                            </template>
                        </el-table-column>
                        <el-table-column prop="name" label="操作">
                            <template slot-scope="scope">
                                <!--<el-button type="text" @click="houseContainerDetail(scope.row)">House Container-->
                                <!--</el-button>-->
                                <el-button type="text" @click="saveHouseInfo(scope.row)">保存</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-card>

            </el-dialog>

            <!--<el-dialog title="House Container" :visible.sync="houseContainerDialogVisible" width="70vw">-->
                <!--<el-button style="margin: 10px 0px" type="primary" icon="el-icon-search" @click="addHouseInfo">新增一行-->
                <!--</el-button>-->
                <!--<el-card class="box-card last_card">-->
                    <!--<el-table :data="houseContainerList">-->
                        <!--<el-table-column prop="key" label="编号" width="32">-->
                            <!--<template slot-scope="scope">-->
                                <!--<span>{{scope.$index+1}}</span>-->
                            <!--</template>-->
                        <!--</el-table-column>-->
                        <!--<el-table-column-->
                                <!--prop="containerRecNo"-->
                                <!--label="柜号"-->
                                <!--align="center"-->
                        <!--&gt;-->
                            <!--<template slot-scope="scope">-->
                                <!--&lt;!&ndash; 编辑状态下显示 &ndash;&gt;-->
                                <!--<el-input-->
                                        <!--type="text"-->
                                        <!--style="width: 90%;height: 90%"-->
                                        <!--size="mini"-->
                                        <!--clearable-->
                                        <!--v-model.trim="scope.row.containerRefNo"-->
                                <!--/>-->
                            <!--</template>-->
                        <!--</el-table-column>-->
                        <!--<el-table-column-->
                                <!--prop="packAge"-->
                                <!--label="件数"-->
                                <!--align="center"-->
                        <!--&gt;-->
                            <!--<template slot-scope="scope">-->
                                <!--&lt;!&ndash; 编辑状态下显示 &ndash;&gt;-->
                                <!--<el-input-->
                                        <!--type="text"-->
                                        <!--style="width: 90%;height: 90%"-->
                                        <!--size="mini"-->
                                        <!--clearable-->
                                        <!--v-model.trim="scope.row.packAge"-->
                                <!--/>-->
                            <!--</template>-->
                        <!--</el-table-column>-->
                        <!--<el-table-column-->
                                <!--prop="unitCode"-->
                                <!--label="单位"-->
                                <!--align="center"-->
                        <!--&gt;-->
                            <!--<template slot-scope="scope">-->
                                <!--<f-select-->
                                        <!--:isNeed="scope.row.unitCode"-->
                                        <!--v-model="scope.row.unitCode"-->
                                        <!--@change="e => scope.row.unitValue =e"-->
                                        <!--:dict="'api_ams_unit'"></f-select>-->
                            <!--</template>-->
                        <!--</el-table-column>-->
                        <!--<el-table-column-->
                                <!--prop="countryOriginValue"-->
                                <!--label="Country Ori"-->
                                <!--align="center"-->
                        <!--&gt;-->
                            <!--<template slot-scope="scope">-->
                                <!--&lt;!&ndash; 编辑状态下显示 &ndash;&gt;-->
                                <!--<el-input-->
                                        <!--type="text"-->
                                        <!--style="width: 90%;height: 90%"-->
                                        <!--size="mini"-->
                                        <!--clearable-->
                                        <!--v-model.trim="scope.row.countryOriginValue"-->
                                <!--/>-->
                            <!--</template>-->
                        <!--</el-table-column>-->
                        <!--<el-table-column-->
                                <!--prop="grossWeight"-->
                                <!--label="重量"-->
                                <!--align="center"-->
                        <!--&gt;-->
                            <!--<template slot-scope="scope">-->
                                <!--&lt;!&ndash; 编辑状态下显示 &ndash;&gt;-->
                                <!--<el-input-->
                                        <!--type="text"-->
                                        <!--style="width: 90%;height: 90%"-->
                                        <!--size="mini"-->
                                        <!--clearable-->
                                        <!--v-model.trim="scope.row.grossWeight"-->
                                <!--/>-->
                            <!--</template>-->
                        <!--</el-table-column>-->
                        <!--<el-table-column-->
                                <!--prop="cbm"-->
                                <!--label="体积"-->
                                <!--align="center"-->
                        <!--&gt;-->
                            <!--<template slot-scope="scope">-->
                                <!--&lt;!&ndash; 编辑状态下显示 &ndash;&gt;-->
                                <!--<el-input-->
                                        <!--type="text"-->
                                        <!--style="width: 90%;height: 90%"-->
                                        <!--size="mini"-->
                                        <!--clearable-->
                                        <!--v-model.trim="scope.row.cbm"-->
                                <!--/>-->
                            <!--</template>-->
                        <!--</el-table-column>-->
                        <!--<el-table-column prop="name" label="操作">-->
                            <!--<template slot-scope="scope">-->
                                <!--<el-button type="text" @click="saveHouseInfo(scope.row)">保存</el-button>-->
                            <!--</template>-->
                        <!--</el-table-column>-->
                    <!--</el-table>-->
                <!--</el-card>-->

            <!--</el-dialog>-->

            <el-dialog title="masterContainer" :visible.sync="masterContainerDialogVisible">
                <el-button style="margin: 10px 0px" type="primary" icon="el-icon-search" @click="addMasterContainer">
                    新增一行
                </el-button>
                <el-card class="box-card last_card">
                    <el-table :data="masterContainerList" class="c-th-has-bgcolor" :cell-style="{ 'text-align': 'center' }">
                        <el-table-column prop="key" label="编号" width="32">
                            <template slot-scope="scope">
                                <span>{{scope.$index+1}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="containerNo"
                                label="柜号"
                                align="center"
                        >
                            <template slot-scope="scope">
                                <!-- 编辑状态下显示 -->
                                <el-input
                                        type="text"
                                        style="width: 90%;height: 90%"
                                        size="mini"
                                        clearable
                                        v-model.trim="scope.row.containerNo"
                                />
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="sealNo"
                                label="封条号"
                                align="center"
                        >
                            <template slot-scope="scope">
                                <!-- 编辑状态下显示 -->
                                <el-input
                                        type="text"
                                        style="width: 90%;height: 90%"
                                        size="mini"
                                        clearable
                                        v-model.trim="scope.row.sealNo"
                                />
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="containerType"
                                label="柜子类型"
                                align="center"
                        >
                            <template slot-scope="scope">
                                <!-- 编辑状态下显示 -->
                                <el-input
                                        type="text"
                                        style="width: 90%;height: 90%"
                                        size="mini"
                                        clearable
                                        v-model.trim="scope.row.containerType"
                                />
                            </template>
                        </el-table-column>
                        <el-table-column prop="name" label="操作">
                            <template slot-scope="scope">
                                <el-button type="text" @click="saveMasterContainer(scope.row)">保存</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-card>

            </el-dialog>


            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange">
                </el-pagination>
            </el-row>
        </el-card>
    </div>
</template>

<script>
    import {deleteAction, postAction, putAction} from '@/api/manage'
    import QueryMixins from "../../../mixins/QueryMixins";
    import {yingwenPattern} from '@/utils/pattern';
    import {getAction} from "../../../api/manage";

    export default {
        name: 'ams',
        mixins: [QueryMixins],

        data() {
            return {
                houseId: null,
                editStartOptions: {
                    disabledDate: time => {
                        if (!this.queryForm.createTimeEnd) {
                            // time = new Date(time.replace(/-/g, '/'));
                            return time.getTime() < new Date(1970 - 1 - 1).getTime();   //禁止选择1970年以前的日期
                        } else {
                            return time.getTime() > new Date(this.queryForm.createTimeEnd);
                        }
                    }
                },
                masterContainerList: [],
                houseInfoList: [],
                houseContainerList: [],

                houseInfoDialogVisible: false,
                houseContainerDialogVisible: false,
                masterContainerDialogVisible: false,
                consignmentTypeList: [
                    {
                        value: 'LCL',
                        label: 'LCL'
                    },
                    {
                        value: 'FLCL',
                        label: 'FLCL'
                    },
                ],
                tempNode: {},
                shipCompanyMap: null,
                dictCodes: [
                    "ams_ship_company", "ams_port", "api_ams_customer","api_ams_unit","ams_customer_country"
                ],
                isUsePost: true,
                options: [
                    {label: '是', value: '1'},
                    {label: '否', value: '0'},
                ],
                vueList: [],
                vueVis: false,
                vueModel: {vueIdList: []},
                menuVisible: false,
                menu: {},
                // dictCodes: ['apportion_type'],
                rules: {
                    name: [
                        {required: true, message: '请输入页面名字', trigger: 'blur'},
                        {max: 255, message: '长度不能超过50', trigger: 'change'},

                    ],
                    path: [
                        {required: true, message: '请输入页面路径', trigger: 'blur'},
                        {max: 255, message: '长度不能超过50', trigger: 'change'},
                    ],
                    menuId: [
                        {required: true, message: '请选择所属菜单', trigger: 'change'},

                    ],


                },
                ids: [],
                row: {},
                form: {},
                nameList: [],
                formLabelWidth: '110px',
                //控制弹框显示
                dialogFormVisible: false,
                columns: [
                    {
                        type: 'selection',
                        width: 50
                    },

                    {
                        prop: 'name',
                        label: '页面名字',
                        sortable: 'custom',

                    },
                    {
                        prop: 'path',
                        label: '页面路径',
                        sortable: 'custom',

                    },
                    {
                        prop: 'createBy',
                        label: '创建人',
                        sortable: 'custom',

                    },
                    {
                        prop: 'createTime',
                        label: '创建时间',
                        sortable: 'custom',

                    },
                    {
                        prop: 'action',
                        label: '操作',

                    },
                ],
                url: {
                    list: '/operation/linkams/getWaitDataInfo',
                    shipCompanyList: '/operation/linkams/getShipCompanyList',
                    saveAmsInfo: '/operation/amsInfo/saveAmsInfo',
                    masterContainerList: '/operation/amsMasterContainerInfo/getMasterContainersByMasterId',
                    saveMasterContainer: '/operation/amsMasterContainerInfo/saveOrUpdateMasterContainer',
                    saveHouseInfo: '/operation/amsMasterContainerInfo/saveOrUpdateHouseInfo',
                    saveHouseContainer: '/operation/amsMasterContainerInfo/saveOrUpdateHouseContainer',
                    houseInfoList: '/operation/amsMasterContainerInfo/getHouseInfoByMasterId',
                    houseContainerList: '/operation/amsMasterContainerInfo/getHouseContainerByHouseId',
                    submitAmsInfo: '/operation/linkams/submitAmsInfo',

                    // list: '/sys/button/auth/vueList',
                    // add: '/sys/button/auth/addVue',
                    // edit: '/sys/button/auth/updateVue',
                    // del:'/sys/button/auth/delVue',
                    // delBatch:'/sys/button/auth/delVueBatch',
                    // getVueList:'/sys/button/auth/getVueList',
                    // addMenuWithVueId:'/sys/button/auth/addMenuWithVueId',
                    // initVueList:'/sys/dict/getDictItems/btn_vue',
                },
                visible: false,
                vueId: '',
                path: '',
                ipagination: {
                    currentPage: 1,
                    pageSize: 20,
                    pageSizes: [10, 15, 20, 30],
                    total: 0
                },
            }
        },
        methods: {
            // 新增house container
            houseContainerDetail(row) {
                this.houseId = row.id
                postAction(this.url.houseContainerList, row).then(res => {
                    this.houseContainerList = res.result
                })
                this.houseContainerDialogVisible = true
            },
            //保存houseContainer 信息
            saveHouseContainerInfo(row) {
                postAction(this.url.saveHouseContainer, row).then(res => {
                    if (res.code == 200) {
                        this.$message.success("新增成功！")
                    }
                })
            },
            //保存houseInfo 信息
            saveHouseInfo(row) {
                postAction(this.url.saveHouseInfo, row).then(res => {
                    if (res.code == 200) {
                        this.$message.success("操作成功！")
                    }
                })
            },
            submitAmsInfo(row) {
                postAction(this.url.submitAmsInfo, {id: row.id}).then(res => {
                    if (res.code == 200) {
                        this.handleQuery()
                    }
                })
            },
            //保存masterContainer 信息
            saveMasterContainer(row) {
                postAction(this.url.saveMasterContainer, row).then(res => {
                    if (res.code == 200) {
                        this.$message.success("新增成功！")
                    }
                })
            },
            // 添加 HouseInfo 行
            addHouseInfo() {
                this.houseInfoList.push({
                    id:null,
                    busId: this.busId,
                    houseNo: "",
                    shipperRefCode:"",
                    consigneeRefCode:"",
                    notifyRefCode:"",
                    placeReceiptCode:"",
                    placeReceiptDate:"",
                    placeReceiptValue:"",
                    placeDeliveryCode:"",
                    placeDeliveryDate:"",
                    placeDeliveryValue:"",
                    totalWeightKg:"",
                    totalVolumeCbm:"",
                    marking:"",
                    description:"",
                    shipperPartyName:"",
                    shipperAddressLine:"",
                    consigneePartyName:"",
                    consigneeAddressLine:"",
                    consigneeCountry:"",
                    consigneeCity:"",
                    consigneePostal:"",
                    shipperCountry:"",
                    shipperCity:"",
                    shipperPostal:"",
                    masterBillNo:"",
                    originAgentCode:"",
                    destinationAgentCode:"",
                    loadingPortValue:"",
                    dischargePortValue:"",
                    loadingPortDate:"",
                    dischargePortDate:"",
                    packAge:"",
                    amsHouseContainerList:[ {
                        id:null,
                        busId:null,
                        houseNo:null,
                        containerRefNo:null,
                        packAge:null,
                        unitCode:null,
                        unitClassOwner:null,
                        unitValue:null,
                        countryOriginCode:null,
                        countryOriginValue:null,
                        grossWeight:null,
                        cbm:null,
                        marking:null,
                        description:null,
                    }]
                })
            },
            // 添加masterContainer行
            addMasterContainer() {
                this.masterContainerList.push({
                    busId: this.busId,
                    containerNo: "",
                    containerType: "",
                    sealNo: "",
                })
            },
            houseInfoDetail(row) {
                this.busId = row.id
                postAction(this.url.houseInfoList, row).then(res => {
                    this.houseInfoList = res.result
                })
                this.houseInfoDialogVisible = true
            },
            // 查看master Container 列表
            masterContainerDetail(row) {
                this.busId = row.id
                postAction(this.url.masterContainerList, row).then(res => {
                    this.masterContainerList = res.result
                })
                this.masterContainerDialogVisible = true
            },
            saveAmsInfo(row) {
                postAction(this.url.saveAmsInfo, row).then(res => {
                    if (res.code == 200) {
                        this.$message.success("保存成功！")
                        this.handleQuery()
                    }
                })
            },
            getShipComppanyList() {
                getAction(this.url.shipCompanyList).then(res => {
                    this.shipCompanyMap = this.listToMap(res.result, "hyEasyCode")
                })
            },
            listToMap(list, name) {
                var map = {};
                for (var index in list) {
                    map[list[index][name]] = list[index];
                }
                return map;
            },
            getSelectNode(row) {
                this.tempNode = row
            },
            changeShipCompany(e) {
                if (e) {
                    console.log(this.shipCompanyMap[e], e)
                    this.tempNode.carrierNameValue = this.shipCompanyMap[e]['carrierEnglishName']
                    this.tempNode.carrierNameCode = this.shipCompanyMap[e]['hyEasyCode']
                }

            },
            clearSelect(row) {
                row.carrierNameValue = ""
            },
            addVueList() {

                getAction(this.url.getVueList, {menuId: this.menuId})
                    .then(res => {
                        if (res.success) {
                            if (res.result && Array.isArray(res.result)) {
                                this.$set(this.vueModel, 'vueIdList', res.result || []);
                            } else {
                                this.$set(this.vueModel, 'vueIdList', []);
                            }
                        } else {
                            this.$set(this.vueModel, 'vueIdList', res.result || []);
                        }
                        this.vueVis = true;
                    })
            },
            saveVueList() {
                this.$refs['vueModel'].validate(vaid => {
                    if (vaid) {
                        putAction('/sys/button/auth/addMenuWithVueId' + '?menuId=' + this.menuId, this.vueModel.vueIdList)
                            .then(res => {
                                this.$message.success(res.message);
                                this.handleQuery();
                                this.vueVis = false;
                            })
                    }
                })
            },
            saveMenu() {
                this.$refs['menu'].validate(vaid => {
                    if (vaid) {
                        putAction('/sys/button/auth/addVueMenuId' + '?vueId=' + this.menu.vueId + '&menuId=' + this.menu.menuId)
                            .then(res => {
                                this.$message.success(res.message);
                                this.menuVisible = false;
                                this.handleQuery();
                            })
                    }
                })
            },
            addMenu({id, menuId, name}) {
                this.$set(this.menu, 'vueId', id);
                this.$set(this.menu, 'menuId', menuId);
                this.$set(this.menu, 'name', name);
                this.menuVisible = true;
            },
            ok() {
                this.visible = false;
            },
            handleSelectionChange(selection) { // 多选
                let arr = []
                this.emailList = [];
                this.nameList = [];
                for (let i = 0; i < selection.length; i++) {
                    arr.push(selection[i]['id'])
                    this.emailList.push(selection[i]['email']);
                    this.nameList.push(selection[i]['name']);
                }
                this.selectionRows = selection
                this.selectedRowKeys = [...arr]
            },
            handleAdd() {
                this.form = {};
                this.$set(this.form, 'menuId', this.menuId);
                this.dialogFormVisible = true;
            },
            handleEdit(row) {
                //将对象转为json字符串再转回对象
                //this.form = row; 复制的是指针 仍然指向这个对象row
                this.vueId = row.id;
                this.path = row.path;
                this.visible = true;

            },
            handleDel(row) {
                this.$confirm('确定删除该页面:' + row.name + ',该操作会同时删除页面下的按钮与权限', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'error',
                    closeOnClickModal: false,
                }).then(() => {
                    deleteAction(this.url.del, {id: row.id}).then(res => {
                        this.$message({
                            type: 'success',
                            message: res.message
                        })

                        this.handleSearch()
                    })
                }).catch(err => {
                    this.$message.error(err.message);
                })
            },
            handleDelBatch() {
                this.$confirm('确定批量删除：' + this.nameList.join(',') + '这些页面吗,该操作会同时删除页面下的按钮与权限', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'error',
                    closeOnClickModal: false,
                }).then(() => {
                    deleteAction(this.url.delBatch, {ids: this.selectedRowKeys.join(',')}).then(res => {
                        this.$message({
                            type: 'success',
                            message: res.message
                        })

                        this.handleSearch()
                    })
                }).catch(err => {
                    this.$message.error(err.message);
                })
            },
            handleSave() {
                this.$refs.form.validate(valid => {
                    if (valid) {
                        //编辑
                        // this.$set(this.form,'menuId',this.menuId);
                        if (this.form.id) {
                            putAction(this.url.edit, this.form)
                                .then((res) => {
                                    if (res.success) {
                                        this.$message.success(res.message);
                                        //关闭弹窗
                                        this.dialogFormVisible = false;
                                        sessionStorage.removeItem("dict:sys_vue");
                                        this.handleQuery()
                                    }
                                })
                        } else {
                            postAction(this.url.add, this.form)
                                .then((res) => {
                                    if (res.success) {
                                        this.$message.success(res.message);
                                        //关闭弹窗
                                        this.dialogFormVisible = false;
                                        sessionStorage.removeItem("dict:sys_vue");
                                        this.handleQuery()
                                    }
                                })
                        }
                    }
                })

            },
            handleReset() {
                this.queryForm = {};
                this.queryForm.masterStatus = '0'
                this.queryForm.menuId = this.menuId;
                this.filterForm = {};
                this.ipagination.currentPage = 1
                this.handleQuery()
            }
        }, created() {
            this.queryForm.masterStatus = '0'
            this.getShipComppanyList()
            // this.queryForm.menuId = this.menuId;
        }
    }
</script>

<style lang="scss" scoped>

</style>
