<template>
  <div>
    <el-card class="box-card">
      <el-form class="multiline_select" ref="form" :model="queryForm" label-width="80px" size="medium">
        <el-card class="box-card">
          <el-row :gutter="24" class="inputs_row">
            <el-col :xs="24" :sm="12" :md="12" :lg="6">
              <el-form-item label="订单号">
                <el-input v-model="queryForm.orderNo" type="textarea"
                          @keyup.enter.native="handleSearch"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="6">
              <el-form-item label="客户">
                <el-input v-model="queryForm.customer"
                          @keyup.enter.native="handleSearch"></el-input>
              </el-form-item>
            </el-col>
            <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="7">
              <el-form-item label="积分日期">
                <div class="f-flex">
                  <el-date-picker
                      class="f-flex-1"
                      v-model="queryForm.createTimeStart"
                      type="datetime"
                      size="small"
                      placeholder="开始时间"
                      default-time="00:00:00"
                      value-format="yyyy-MM-dd HH:mm:ss"
                      @keyup.enter.native="handleSearch"
                  >
                  </el-date-picker>
                  <span class="f-p-h-2">~</span>
                  <el-date-picker
                      class="f-flex-1"
                      v-model="queryForm.createTimeEnd"
                      type="datetime"
                      size="small"
                      placeholder="结束时间"
                      default-time="23:59:59"
                      value-format="yyyy-MM-dd HH:mm:ss"
                      @keyup.enter.native="handleSearch"
                  >
                  </el-date-picker>
                </div>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="6">
              <el-form-item label="业务员">
                <f-select v-model="queryForm.salesmanId" :is-need="queryForm.salesmanId"
                          :data-list="queryDictCodeListMap['sys_user_id']"
                          @keyup.enter.native="handleSearch"></f-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="6">
              <el-form-item label="客服">
                <f-select v-model="queryForm.docId" :is-need="queryForm.docId"
                          :data-list="queryDictCodeListMap['sys_user_id']"
                          @keyup.enter.native="handleSearch"></f-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="6">
              <el-form-item label="操作">
                <f-select v-model="queryForm.operatorId" :is-need="queryForm.operatorId"
                          :data-list="queryDictCodeListMap['sys_user_id']"
                          @keyup.enter.native="handleSearch"></f-select>
              </el-form-item>
            </el-col>


            <el-col :xs="24" :sm="12" :md="12" :lg="4">
              <el-form-item label-width="30px">
                <el-button
                    type="primary"
                    icon="el-icon-search"
                    @click="handleSearch"
                >查询
                </el-button
                >
                <el-button plain @click="handleReset">重置</el-button>
              </el-form-item>
            </el-col>
          </el-row>


        </el-card>
      </el-form>
      <div class="bbb">

        <el-button type="primary" size="medium" @click="exportExcelPlus(url.exportExcelUrl,'客户积分明细')"
                   v-if="buttonShowList.dc">导出明细
        </el-button>
      </div>

      <f-table
          v-loading="loading"
          ref="GTable"
          border
          size="medium"
          row-key="id"
          class="c-th-has-bgcolor"
          :columns="columns"
          :data="tableData"
          @selection-change="handleSelectionChange"
          @sort-change="handleTableChange"
          :cell-style="{ 'text-align': 'center' }"
          tableHeight="73vh"
      >
        <template v-slot="scope">
          <template v-if="scope.column.property === 'orderNo'">
            <a
                style="color: #00a0e9"
                @click.prevent="$intoDocPage(scope.row.orderNo)"
            >{{ scope.row.orderNo }}</a
            >
          </template>
          <template v-else-if="scope.column.property === 'customer'">
            <a type="text" @click.prevent="$intoCustomerEditPage(scope.row.cusId)"
               style="color:#57a3f3;">{{ scope.row.customer }}</a>
          </template>
          <template v-else-if="scope.column.property === 'action'">
            <el-button type="text"
                       @click="handleEdit(scope.row,'修改积分','edit')"
                       v-if="buttonShowList['edit']"
            >修改积分</el-button>
            <el-button
                type="text"
                @click="handleEdit(scope.row,'日志','log')"
            >日志</el-button>
          </template>
          <template v-else-if="scope.column.property === 'isLoseMoney'">
            <span>{{ parseDict2(scope.row.isLoseMoney, 'unit_whether') }}</span>
          </template>
          <template v-else-if="scope.column.property === 'points'">
            <span style="color:red" v-if="Number(scope.row.points) > 0">{{scope.row.points}}</span>
            <span style="color:green" v-else-if="Number(scope.row.points) < 0">{{scope.row.points}}</span>
            <span v-else>{{scope.row.points}}</span>
          </template>
        </template>

      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination
            v-bind="ipagination"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange">
        </el-pagination>
      </el-row>
    </el-card>

    <edit-points-dialog
        :visible.sync="editPointsVisible"
        :title="editPointsTitle"
        :row="editPointsRow"
        :type="editPointsType"
        @ok="handleSearch"
    ></edit-points-dialog>
  </div>
</template>

<script>
import QueryMixins from "@/mixins/QueryMixins.js";
import {deleteAction, postAction, httpAction} from "@/api/manage";
import editPointsDialog from "@/views/forestMarket/editPointsDialog.vue";

export default {
  name: "CusPointsDetail",
  mixins: [QueryMixins],
  components: {editPointsDialog},
  data() {
    return {
      isUsePost: true,

      columns: [
        {
          type: "selection",
        },
        {
          prop: "action",
          label: "操作",
        },
        {
          prop: "createTime",
          label: "积分日期",
        },
        {
          prop: "expireTime",
          label: "过期时间",
        },
        {
          prop: "orderNo",
          label: "订单号",
        },
        {
          prop: "customer",
          label: "客户名",
        },
        {
          prop: "exchangeTotal",
          label: "折合金额",
        },
        {
          prop: "isLoseMoney",
          label: "是否亏本",
        },
        {
          prop: "originalPoints",
          label: "原始积分",
        },
        {
          prop: "deliveryYear",
          label: "发货年限",
        },
        {
          prop: "yearFactor",
          label: "年限系数",
        },
        {
          prop: "monthShipmentVolume",
          label: "当月出货量（CBM）",
        },
        {
          prop: "volumeFactor",
          label: "货量系数",
        },
        {
          prop: "settlementCircle",
          label: "结算周期",
        },
        {
          prop: "settlementFactor",
          label: "周期系数",
        },
        {
          prop: "status",
          label: "销账状态",
        },
        {
          prop: "points",
          label: "积分",
        },
        {
          prop: "remark",
          label: "备注",
        },
        {
          prop: "updateBy",
          label: "修改人",
        },

      ],
      url: {
        //查询列表的接口
        list: "/shipping/cus/points/detail/page",

        //导出excel的接口
        exportExcelUrl: '/shipping/cus/points/detail/download',
      },
      editPointsVisible: false,
      editPointsRow: {points: '', remark: ''},
      editPointsTitle: '',
      editPointsType: '',
      needQueryDictCodes: ['sys_user_id']
    };
  },
  methods: {
    handleEdit(row, title, type) {
      this.editPointsTitle = title;
      this.editPointsType = type;
      this.editPointsRow = {id : row.id, points: row.points, remark: row.remark};
      this.editPointsVisible = true;
    }
  },
  computed: {
    buttonShowList() {
      return {
        'dc': this.$btnIsShow('forestMarket/CusPointsDetail', '0', '导出'),
        'edit': this.$btnIsShow('forestMarket/CusPointsDetail', '0', '修改积分'),
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.bbb {
  display: flex;
}

.dialog-footer {
  text-align: right !important;
}

.margin_right_10 {
  margin-right: 10px;
}
</style>
