<template>
    <div class="main-full-content">

        <el-form class="multiline_select" ref="form" :model="queryForm" label-width="110px" size="medium">
            <el-card class="box-card">
                <el-row>
                    <el-col :xs="24" :sm="12" :md="12" :lg="7">
                        <el-form-item label="下单时间">
                            <div class="f-flex">
                                <el-date-picker
                                    class="f-flex-1"
                                    v-model="queryForm.createTimeS"
                                    type="datetime"
                                    size="small"
                                    placeholder="开始时间"
                                    default-time="00:00:00"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                >
                                </el-date-picker>
                                <span class="f-p-h-2">~</span>
                                <el-date-picker
                                    class="f-flex-1"
                                    v-model="queryForm.createTimeE"
                                    type="datetime"
                                    size="small"
                                    placeholder="结束时间"
                                    default-time="23:59:59"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                >
                                </el-date-picker>
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :xs="24" :sm="12" :md="12" :lg="7">
                        <el-form-item label="开船时间">
                            <div class="f-flex">
                                <el-date-picker
                                    class="f-flex-1"
                                    v-model="queryForm.boatTimeS"
                                    type="datetime"
                                    size="small"
                                    placeholder="开始时间"
                                    default-time="00:00:00"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    :disabled="osBoardTimeStatus"
                                >
                                </el-date-picker>
                                <span class="f-p-h-2">~</span>
                                <el-date-picker
                                    class="f-flex-1"
                                    v-model="queryForm.boatTimeE"
                                    type="datetime"
                                    size="small"
                                    placeholder="结束时间"
                                    default-time="23:59:59"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    :disabled="osBoardTimeStatus"
                                >
                                </el-date-picker>
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>
<!--                <el-row>-->
<!--                    <el-col :xs="24" :sm="12" :md="12" :lg="7">-->
<!--                        <el-form-item label="快递发货时间">-->
<!--                            <div class="f-flex">-->
<!--                                <el-date-picker-->
<!--                                    class="f-flex-1"-->
<!--                                    v-model="queryForm.kdDeliveryTimeS"-->
<!--                                    type="datetime"-->
<!--                                    size="small"-->
<!--                                    placeholder="开始时间"-->
<!--                                    default-time="00:00:00"-->
<!--                                    value-format="yyyy-MM-dd HH:mm:ss"-->
<!--                                    :disabled="kdDeliveryStatus"-->
<!--                                >-->
<!--                                </el-date-picker>-->
<!--                                <span class="f-p-h-2">~</span>-->
<!--                                <el-date-picker-->
<!--                                    class="f-flex-1"-->
<!--                                    v-model="queryForm.kdDeliveryTimeE"-->
<!--                                    type="datetime"-->
<!--                                    size="small"-->
<!--                                    placeholder="结束时间"-->
<!--                                    default-time="23:59:59"-->
<!--                                    value-format="yyyy-MM-dd HH:mm:ss"-->
<!--                                    :disabled="kdDeliveryStatus"-->
<!--                                >-->
<!--                                </el-date-picker>-->
<!--                            </div>-->
<!--                        </el-form-item>-->
<!--                    </el-col>-->
<!--                </el-row>-->
                <el-row class="inputs_row">
                    <el-col :xs="24" :sm="12" :md="12" :lg="8">
                        <el-form-item label="工作单号">
                            <el-input v-model="queryForm.orderNos"

                                      type="textarea"
                                      :autosize="{ minRows: 20, maxRows: 50}"
                                      placeholder="请输入工作单号，每行输入一个"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row class="inputs_row">
                    <el-col :xs="24" :sm="12" :md="12" :lg="3">
                        <el-form-item label="锁定的订单类型">
                            <el-select v-model="queryForm.orderType" @change="changTime">
                                <el-option value="os" label="海运"></el-option>
<!--                                <el-option value="kd" label="快递"></el-option>-->
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="3">
                        <el-form-item label="锁定方向">
                            <el-select v-model="queryForm.isLock">
                                <el-option value="0" label="锁定"></el-option>
                                <!--<el-option value="1" label="解锁"></el-option>-->
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col>
                        <el-form-item label="应收/应付">
<!--                            <el-checkbox-group class="disabled_deep" v-model="queryForm.type">-->
                            <el-checkbox-group v-model="queryForm.type">
                                <el-checkbox :checked="true" label="0">应收</el-checkbox>
                                <el-checkbox :checked="true" label="1">应付</el-checkbox>
                            </el-checkbox-group>
                        </el-form-item>

                    </el-col>
                </el-row>
                <el-row class="inputs_row">

                    <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="3">
                        <el-form-item label-width="30px" style="width: 500px">
                            <!--                            <el-button-->
                            <!--                                type="primary"-->
                            <!--                                icon="el-icon-search"-->
                            <!--                                @click="handleCheck"-->
                            <!--                            >查询-->
                            <!--                            </el-button-->
                            <!--                            >-->
                            <el-button type="primary" @click="handleReset">确定提交</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>
        </el-form>

        <el-card class="box-card last_card">
            <span>操作记录</span>
            <f-table
                v-loading="loading"
                ref="GTable"
                border
                size="medium"
                row-key="id"
                class="c-th-has-bgcolor"
                :columns="columns"
                :data="tableData"
                tableHeight="74vh"
                :cell-style="{ 'text-align': 'center' }">

            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                    v-bind="ipagination"
                    layout="total, sizes, prev, pager, next, jumper"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                >
                </el-pagination>
            </el-row>
        </el-card>

    </div>
</template>
<script>

import {getAction, postAction} from "@/api/manage";
import QueryMixins from "@/mixins/QueryMixins";
import { setUpRouteUtil } from '@/utils/util';

export default {
    mixins: [QueryMixins],
    components: {
    },
    data() {
        return {
            osBoardTimeStatus:false,
            kdDeliveryStatus:false,
            tableData:[],
            columns:[
                {
                    prop: "createBy",
                    label: "操作人",
                    width: 140,
                    fixed: 'left'
                },
                {
                    prop: "createTime",
                    label: "操作时间",
                    width: 140,
                    fixed: 'left'
                },
                {
                    prop: "orderNos",
                    label: "锁单单号",
                },
            ],
            queryForm:{
                type:[]
            },
            picker:{},
            disableMixinMounted:true,
            boatStatus: true,
            selectedRowKeys: [],
            selectionRows: [],
            selectedNos: [],
            loading: false,
            form: {},
            imgVisible: false,
            imgUrl: '',
            url: {
                batchLock:"/shipping/BatchLock/edit",
                list:"/shipping/BatchLock/lockList"

            },

        };
    },
    computed: {},
    created() {
        // this.queryForm.statementType = '0'
        this.$set(this.queryForm,'isLock','0')
        this.handleQuery();
    },
    methods: {
        changTime(val){
            if (val == 'os'){
                this.$set(this.queryForm, 'kdDeliveryTimeS', '')
                this.$set(this.queryForm, 'kdDeliveryTimeE', '')
                this.osBoardTimeStatus = false;
                this.kdDeliveryStatus = true
            }else if (val=='kd'){
                this.$set(this.queryForm, 'boatTimeS', '')
                this.$set(this.queryForm, 'boatTimeE', '')
                this.osBoardTimeStatus = true;
                this.kdDeliveryStatus = false
            }
        },
        handleReset() {
            const { orderNos = '', type, orderType } = this.queryForm
            // if (!orderNos.trim()) {
            //     this.$message.error('请输入订单号')
            //     return
            // }
            if (!type.length) {
                this.$message.error('应收、应付至少选择一个')
                return
            }
            if (!orderType) {
                this.$message.error('请选择订单类型')
                return
            }
            postAction(this.url.batchLock, this.queryForm).then((res) => {
                if (res.success) {
                    this.$message.success(res.message)
                    this.handleQuery()
                } else {
                    this.$message.error(res.message)
                }
                if (res.code === 510) {
                    this.$message.warning(res.message)
                }
            })
        },

        handleSizeChange(val) {
            this.ipagination.currentPage = 1
            this.ipagination.pageSize = val
            // this.handleCheck()
            this.$emit('ok')
        },
        handleCurrentChange(val) {
            this.ipagination.currentPage = val
            // this.handleCheck()
            this.$emit('ok')
        },

        handleQuery() {
            this.loading = true;
            const {prop, order} = this.isorter;
            // 传入的参数
            const params = {

                column: prop,
                order: order.indexOf("asc") !== -1 ? "asc" : "desc",
            };
            //判断 是否要查询startTime

            if (!params.column) {
                if (this.queryForm.column === "startTime") {
                    this.$set(params, "column", "startTime");
                } else {
                    this.$set(params, "column", "createTime");
                }
                //提货中查询
                if (this.queryForm.column === "distributionTime") {
                    this.$set(params, "column", "distributionTime");
                }
            }
            if (this.ipagination) {
                params.pageNo = this.ipagination.currentPage;
                params.pageSize = this.ipagination.pageSize;
            }

            getAction(this.url.list, params)
                .then((res) => {
                    if (res.success) {
                        this.$refs.GTable && this.$refs.GTable.handleScrollTop && this.$refs.GTable.handleScrollTop();
                        const {records, total} = res.result;
                        this.tableData = records || res.result;

                        this.ipagination &&
                        (this.ipagination.total = total || res.result.length);
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
};
</script>
<style scoped>
.el-button {
    user-select: unset;
}
</style>
<style lang="scss">
.disabled_deep{
  .el-checkbox__input.is-disabled + span.el-checkbox__label {
    color: #74777c;
  }
  .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after {
    border-color: #74777c;
  }
  .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
    background-color: #FFFFFF;
    border-color: #abafb7;
  }
}
</style>
