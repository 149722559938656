<template>
  <div>


    <el-card class="box-card">
      <el-form :model="opeInfoForm" ref="opeInfoForm" :rules="opeInfoRules" :label-width="formLabelWidth2">
        <el-form-item label="供应商类型" >
          <f-select
                  v-model="opeInfoForm.supTypeList"
                  :is-need="opeInfoForm.supTypeList"
                  dict="sup_type"
                  :multiple="true"
                  :disabled="true"
          ></f-select>
        </el-form-item>
        <el-form-item label="公司名" prop="companyName">
          <el-input

            v-model="opeInfoForm.companyName"
            maxlength="100"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="详细地址" prop="address">
          <el-input

              v-model="opeInfoForm.address"
              maxlength="255"
              show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="联系人" prop="contact">
          <el-input

              v-model="opeInfoForm.contact"
              maxlength="100"
              show-word-limit
          ></el-input>
        </el-form-item>
          <el-form-item label="联系电话" prop="tel">
            <el-input

                v-model="opeInfoForm.tel"
                maxlength="100"
                show-word-limit
            ></el-input>
          </el-form-item>
            <el-form-item label="邮箱" prop="emailListStr">
              <el-input

                  v-model="opeInfoForm.emailListStr"
                  maxlength="255"
                  show-word-limit
              ></el-input>
            </el-form-item>
      </el-form>
      <div class="f-p-b-15" style="text-align: center">
        <el-button size="medium" type="primary" @click="saveOpeInfo">保存</el-button>
      </div>
    </el-card>
  </div>
</template>

<script>
import { getAction, postAction } from "@/api/manage";

export default {
  name: "OpeInfoModule",
  props:{

  },
  components: {},
  data() {
    return {

      opeInfoForm: {supId:'',supTypeList:[],companyName:'',address:'',contact:'',tel:'',emailListStr:''},
      formLabelWidth2: "80px",
      url: {
        saveOpeInfo: "/operation/container/delivery/addOrUpdateSupOpeInfo",
        queryOpeInfo: "/operation/container/delivery/querySupOpeInfoBySupId",
      },
      //表单校验
      opeInfoRules: {
        companyName: [{ required: true, message: "请输入公司名", trigger: "blur" }],
        address: [
          { required: true, message: "请输入详细地址", trigger: "change" },
        ],
        contact: [
          { required: true, message: "请输入联系人", trigger: "change" },
        ],
        tel: [
          { required: true, message: "请输入联系电话", trigger: "change" },
        ],
        emailListStr: [
          { required: true, message: "请输入邮箱", trigger: "change" },
        ],
      },
    };
  },
  created() {
    this.init();
  },
  mounted() {},
  methods: {
    init() {
      getAction(this.url.queryOpeInfo, { id:this.$route.query.id || ''}).then(
        (res) => {
          if (res.success) {
           this.opeInfoForm = res.result;
          }
        }
      );
    },
    saveOpeInfo() {
      this.$set(this.opeInfoForm, "supId", this.$route.query.id || '');
      this.$refs.opeInfoForm.validate(valid => {
        if (valid) {
          postAction(this.url.saveOpeInfo, this.opeInfoForm).then((res) => {
            this.$message.success(res.message);
            this.init();
          });
        }
      })

    },
  },
};
</script>

<style lang="scss" scoped>

</style>
