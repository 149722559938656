<template>
    <div class="main-full-content">
        <el-form
                class="input_top"
                ref="form"
                :model="queryForm"
                label-width="80px"
                size="medium"
        >
            <el-card class="box-card">
                <el-row>
                    <el-col :xs="24" :sm="12" :md="12" :lg="7">
                        <el-form-item label="标题">
                            <el-input v-model="queryForm.title" @keyup.enter.native="handleSearch"></el-input>
                        </el-form-item>
                    </el-col>
                    <!--<el-col :xs="24" :sm="12" :md="12" :lg="7">
                      <el-form-item label="发布端口">
                        <el-select
                            filterable
                            class="f-full-width"
                            v-model="queryForm.pushPort"
                            @change="selectPushPort($event)"
                            placeholder="请选择"
                            style="width: 100%"
                        >
                          <el-option
                              v-for="item in optionsType"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>-->
                    <!-- <el-col :xs="24" :sm="12" :md="12" :lg="7">
                       <el-form-item label="文章类别">
                         <el-select
                             clearable
                             filterable
                             v-model="queryForm.articleTypeId"
                             placeholder="获取所有文章类别"
                             style="width: 100%"
                         >
                           <el-option
                               v-for="(item, index) in allArticleTypeList"
                               :label="item.articleType"
                               :value="item.id"
                               :key="index"
                           ></el-option>
                         </el-select>
                       </el-form-item>
                     </el-col>-->
                    <el-col :xs="24" :sm="12" :md="12" :lg="3">
                        <el-form-item label-width="30px">
                            <el-button
                                    type="primary"
                                    icon="el-icon-search"
                                    @click="handleSearch"
                            >查询
                            </el-button
                            >
                            <el-button plain @click="reset">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>
        </el-form>
        <el-card class="box-card last_card">
            <div class="f-p-b-15">
                <el-button
                        type="primary"
                        size="medium"
                        icon="el-icon-plus"
                        @click="handleAdd"
                        v-if="buttonShowList.add"
                >新增
                </el-button
                >
                <el-button
                        type="primary"
                        size="medium"
                        class="c-theme-error-button"
                        @click="handleBatDel"
                        :disabled="selectionRows.length === 0"
                        v-if="buttonShowList.delB"
                >批量删除
                </el-button>
                <el-button
                        type="primary"
                        size="medium"
                        icon="el-icon-plus"
                        @click="handleUpdateCache"
                >更新缓存
                </el-button>
                <el-upload
                        class="upload-demo margin_right_10"
                        style="margin-left: 10px"
                        action=""
                        :http-request="sendCommissionEmail"
                        :show-file-list="false"
                        :before-upload="beforeFile"
                        accept=".xls,.xlsx"
                        v-if="buttonShowList.sendCEmail"
                >
                    <el-button type="primary" size="medium">发送提成汇总邮件</el-button>
                </el-upload>
                <el-upload
                        class="upload-demo margin_right_10"
                        style="margin-left: 10px"
                        action=""
                        :http-request="sendCommissionDetailEmail"
                        :show-file-list="false"
                        :before-upload="beforeFile"
                        accept=".xls,.xlsx"
                        v-if="buttonShowList.sendCsEmail"
                >
                    <el-button type="primary" size="medium">发送提成明细邮件</el-button>
                </el-upload>
                <el-button
                        style="margin-left: 5px"
                        type="primary"
                        size="medium"
                        icon="el-icon-plus"
                        @click="qq"
                >预览html
                </el-button>
              <el-button
                  style="margin-left: 5px"
                  type="primary"
                  size="medium"
                  icon="el-icon-plus"
                  @click="exportExcelPlus('/file/export/excel/getYundangDataExcel','云当网78月份数据和fba系统对比')"
                  v-if="buttonShowList.dcy"
              >导出云当网数据
              </el-button>
              <el-button
                  style="margin-left: 5px"
                  type="primary"
                  size="medium"
                  icon="el-icon-plus"
                  @click=downloadProfit();

              >导出利润
              </el-button>
            </div>
            <f-table
                    v-loading="loading"
                    border
                    ref="GTable"
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    :isorter="isorter"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="66vh"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange"
                    @queryChange="handleTableQueryChange"
            >
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'title'">
                        <el-badge
                                class="f-m-t-4 f-p-r-6"
                                is-dot
                                v-if="scope.row.istop === 0"
                        >
                            <span>{{ scope.row.title }}</span>
                        </el-badge>
                        <span v-else>{{ scope.row.title }}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'istop'">
                        {{ scope.row.istop ? "否" : "是" }}
                    </template>
                    <template v-else-if="scope.column.property === 'type'">
                        {{ scope.row.type ? "文章" : "视频" }}
                    </template>
                    <template v-else-if="scope.column.property === 'cover'">
                        <el-avatar
                                shape="square"
                                :size="40"
                                fit="fit"
                                icon="el-icon-picture"
                                :src="scope.row.cover"
                        ></el-avatar>
                    </template>
                    <template v-else-if="scope.column.property === 'vagrantsNum'">
                        {{ !scope.row.vagrantsNum ? 0 : scope.row.vagrantsNum }}
                    </template>
                    <template v-else-if="scope.column.property === 'action'">
                        <el-button type="text" @click="handleEdit(scope.row)"
                                   v-if="buttonShowList.edit"
                        >编辑
                        </el-button
                        >
                        <!-- <el-button type="text" @click="handleTop(scope.row)">置顶</el-button> -->
                        <el-button type="text" @click="handleDel(scope.row)"
                                   v-if="buttonShowList.del"
                        >删除
                        </el-button
                        >
                        <el-button type="text" @click="wenzhang(scope.row)"
                                   v-if="buttonShowList.deta"
                        >版本详情
                        </el-button
                        >
                        <!--<el-button type="text" @click="xiazai(scope.row)"
                        >附件下载</el-button
                        >-->
                    </template>
                    <template v-else-if="scope.column.property === 'pushPort'">
                        <span v-if="scope.row.pushPort == '0'">PC后台</span>
                        <span v-else-if="scope.row.pushPort == '1'">PC客户端</span>
                        <span v-else-if="scope.row.pushPort == '2'">Applets后台</span>
                        <span v-else>Applets客户端</span>
                    </template>
                    <template v-else-if="scope.column.property === 'articleTypeId'">
                        <span>{{ parseArticleType(scope.row.articleTypeId) }}</span>
                    </template>
                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                >
                </el-pagination>
            </el-row>
        </el-card>

        <el-dialog
                title="更新缓存"
                :visible="updateCacheForm"
                width="60%"
                custom-class="c-custom-dialog"
                :close-on-click-modal="false"
                :before-close="() =>{updateCacheForm = false}"
                top="30px"
        >
            <el-form
                    label-width="100px"
                    size="medium"
                    class="addEditBox"
            >
                <el-row>
                    <el-input
                            type="textarea"
                            :autosize="{ minRows: 4 }"
                            class="textarea_box tab_data_bot bolder_text"
                            v-model="cacheValue"
                    ></el-input>
                </el-row>
            </el-form>
            <span slot="footer">
                    <el-button size="medium" @click="updateCacheForm = false">取消</el-button>
                    <el-button type="primary" size="medium" @click="handleUpdateCacheButtom"
                    >确定</el-button
                    >
              </span>
        </el-dialog>

        <el-dialog
                :title="form.id ? '编辑' : '新增'"
                :visible.sync="edVis"
                width="60%"
                custom-class="c-custom-dialog"
                :close-on-click-modal="false"
                top="30px"
                v-if="edVis"
        >
            <el-form
                    :model="form"
                    :rules="rules"
                    ref="form"
                    label-width="100px"
                    size="medium"
                    class="addEditBox"
            >
                <el-row>
                    <!--<el-col :span="6">
                      <el-form-item prop="pushPort" label="发布端口">
                        <el-select
                            clearable
                            filterable
                            :multiple="form.id ? false : true"
                            class="f-full-width"
                            @change="selectPushPort($event)"
                            v-model="form.pushPort"
                            placeholder="请选择"
                            style="width: 100%"
                        >
                          <el-option
                              v-for="item in options"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>-->
                    <!--<el-col :span="6">
                      <el-form-item prop="articleTypeId" label="文章类别">
                        <el-select
                            clearable
                            filterable
                            v-model="form.articleTypeId"
                            placeholder="获取所有文章类别"
                            style="width: 100%"
                            @click.native="selectPushPort(form.pushPort)"
                        >
                          <el-option
                              v-for="(item, index) in allArticleTypeList"
                              :label="item.articleType"
                              :value="item.id"
                              :key="index"
                          ></el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>-->
                    <el-col :span="22">
                        <el-form-item prop="title" style="margin-left: 50px" label="标题" label-width="50px">
                            <el-input
                                    v-model="form.title"
                                    maxlength="50"
                                    show-word-limit
                                    type="textarea"
                                    style="width: 110%"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="22">
                        <el-form-item prop="version" style="margin-left: 50px" label="版本号" label-width="50px">
                            <el-input
                                    v-model="form.version"
                                    maxlength="50"
                                    show-word-limit
                                    type="textarea"
                                    style="width: 110%"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item prop="text" label="内容" ref="text2">
                            <!-- <el-input v-model="form.text" type="textarea" rows="5"></el-input> -->
                            <!-- 富文本编辑 -->
                            <quill-editor
                                    ref="text"
                                    v-model="form.text"
                                    class="myQuillEditor"
                                    :options="quillOption"
                            />
                            <!-- ueditor方式 -->
                            <!-- <vue-ueditor-wrap v-model="form.text" :config="myConfig" @beforeInit="addCustomButtom"></vue-ueditor-wrap> -->
                        </el-form-item>
                    </el-col>
                    <!--<el-col :span="12" class="no_margin">
                      <el-form-item prop="coverRef" label="封面" ref="coverUrl">
                        <f-upload
                            v-model="form.coverFile"
                            list-type="picture-card"
                            ref="coverRef"
                            :limit="1"
                            accept=".jpg,.jpeg,.img,.png,.pneg,.gif"
                            :on-exceed="tishi"
                            :dir="'content'"
                            :isDetail="form.id ? false : true"
                        ></f-upload>
                      </el-form-item>
                    </el-col>-->
                    <el-col :span="12" class="no_margin">
                        <el-form-item prop="attachRef" label="附件">
                            <!-- <f-upload v-model="fileList"></f-upload> -->
                            <f-upload v-model="form.attachFile" ref="attachRef" :dir="'content'"
                                      :isDetail="form.id ? false : true"></f-upload>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer">
        <el-button size="medium" @click="edVis = false">取消</el-button>
        <el-button type="primary" size="medium" @click="handleSubmit"
        >确定</el-button
        >
      </span>
        </el-dialog>
        <!--        <add-dialog :visible.sync="visible" :row="row" @ok="handleSearch"></add-dialog>-->
        <Preview :visible.sync="visible" :form="form" @handleSearch="handleSearch"></Preview>

        <div v-html="htmlText" class="htmlSty"></div>
        <iframe :src="this.htmlUrl" class="htmlShow" frameborder="0" width="100%" height="100%" scrolling="auto"></iframe>

    </div>
</template>

<script>
    import {
        deleteAction,
        getAction,
        getAllArticle,
        postAction,
    } from "@/api/manage";
    import QueryMixins from "@/mixins/QueryMixins";
    import StorePublishAddDialog from "./modules/StorePublishAddDialog";
    import Preview from "./modules/StorePublishPreview";
    import {httpAction} from "@/api/manage";
    import {quillEditor} from "vue-quill-editor";
    import quillConfig from "../../utils/quill-config";
    import VueUeditorWrap from "vue-ueditor-wrap";
    import qaqFile from "@/mixins/qaqFile";
    import {previewHtmlUrl} from "@/utils/downloadJasper";
    import {downloadJasperExcelByMethodAndByUrlAndParamsAndFileName} from "../../utils/downloadJasper";

    const form = {
        title: "", // 文章标题
        text: "",
        fileList: [], // 附件
        attachFileId: [], // 附件id

    };
    export default {
        name: "StorePublish",
        mixins: [QueryMixins,qaqFile],
        components: {
            StorePublishAddDialog,
            Preview,
            quillEditor,
            VueUeditorWrap,
        },
        data() {

            return {
                htmlUrl:'',
                updateCacheForm: false,
                cacheValue: "",
                edVis: false,
                // 富文本配置
                content: "<h2>hello quill-editor</h2>",
                quillOption: quillConfig,
                //                 editorOption: {
                //                     modules: {
                //                         ImageExtend: {
                //                             loading: true,
                //                             name: "img",
                //                             action: "",
                //                             response: (res) => {
                //                                 console.log(res);
                //                                 return res.info;
                //                             },
                //                         },
                //                         toolbar: {
                //                             container: container,
                //                             handlers: {
                //                                 image: function () {
                //                                     QuillWatch.emit(this.quill.id);
                //                                 },
                //                             },
                //                         },
                //                     },
                //                 },
                options: [
                    // {
                    //     value: '',
                    //     label: '全部'
                    // },
                    {
                        value: "0",
                        label: "PC员工端",
                    },
                    {
                        value: "1",
                        label: "PC客户端",
                    },
                    {
                        value: "2",
                        label: "小程序员工端",
                    },
                    {
                        value: "3",
                        label: "小程序客户端",
                    },
                ],
                rules: {
                    title: [
                        {required: true, message: "请输入标题", trigger: "change"},
                        {max: 255, message: "长度不能超过255", trigger: "change"},
                    ],
                    version: [
                        {required: true, message: "请输入版本号", trigger: "change"},
                        {max: 255, message: "长度不能超过255", trigger: "change"},
                    ],
                    text: [{required: true, message: "请输入内容", trigger: "blur"}],
                    /*pushPort: [
                      { required: true, message: "请输入端口类型", trigger: "change" },
                    ],
                    text: [{ required: true, message: "请输入内容", trigger: "blur" }],
                    articleTypeId: [
                      { required: true, message: "请输入文章类别", trigger: "change" },
                    ],
                    coverRef: [
                      // {
                      //   validator: (rule, value, callback) => {
                      //     if (this.form.coverFile.length <= 0) {
                      //       callback(new Error("请上传封面"));
                      //     } else {
                      //       callback();
                      //     }
                      //   },
                      //   message: "请上传封面",
                      //   trigger: "change",
                      // },
                    ],
                    // coverUrl: [
                    //   { required: true, message: "请上传封面", trigger: "change" },
                    // ],
                    coverId: [{ required: true, message: "请上传封面", trigger: "change" }],*/
                },
                visible: false,
                types: "",
                optionsType: [
                    {
                        value: "0",
                        label: "PC后台",
                    },
                    {
                        value: "1",
                        label: "PC客户端",
                    },
                    {
                        value: "2",
                        label: "Applets后台",
                    },
                    {
                        value: "3",
                        label: "Applets客户端",
                    },
                ],
                allArticleTypeList: [],
                columns: [
                    {
                        type: "selection",
                        width: 50,
                    },
                    {
                        prop: "action",
                        label: "操作",
                        width: 220,
                    },
                    /*{
                      prop: "pushPort",
                      label: "发布端口",
                      sortable: "custom",
                    },
                    {
                      prop: "articleTypeId",
                      label: "文章类别",
                      sortable: "custom",
                    },*/
                    {
                        prop: "title",
                        label: "标题",
                        sortable: "custom",
                    },
                    {
                        prop: "version",
                        label: "版本",
                        sortable: "custom",
                    },
                    // {
                    //     prop: 'istop',
                    //     label: '是否置顶',
                    //     sortable: 'custom',
                    // },
                    {
                        prop: "createTime",
                        label: "发布时间",
                        sortable: "custom",
                    },
                    {
                        prop: "createBy",
                        label: "创建人",
                        sortable: "custom",
                    },
                    // {
                    //     prop: 'cover',
                    //     label: '封面图片',
                    //     sortable: 'custom',
                    // },
                    /*{
                      prop: "vagrantsNum",
                      label: "浏览量",
                      sortable: "custom",
                    },*/
                ],

                form: {
                    title: "", // 文章标题
                    pushPort: "", // 发布端口
                    articleType: "", // 文章类别
                    articleTypeId: "", // 文章类别id
                    text: "",
                    coverUrl: '', // 封面
                    coverId: "", // 封面id
                    fileList: [], // 附件
                    attachFileId: [], // 附件id
                    coverFile: [],
                    attachFile: [],
                    allList: [],
                    allListMap: {},
                },
                url: {
                    list: "/sys/storePublish/queryList",
                    getAllList: "/sys/storePublish/getAllList",
                    isTop: "/sys/storePublish/isTop",
                    delete: "/sys/storePublish/delete",
                    deleteBatch: "/sys/storePublish/deleteBatch",
                    add: "/sys/storePublish/add",
                    edit: "/sys/storePublish/edit",
                    queryById: "/sys/storePublish/queryById",
                    batchDownload: "/sys/oss/file/batchDownload",
                    vagrantsNum: "/sys/managerFind/vagrantsNum",
                    updateCache: "/sys/storePublish/updateCache",
                },
                isFind: true,
                attachFile: [],
                htmlText:'',
            };
        },
        methods: {
          downloadProfit(){
            downloadJasperExcelByMethodAndByUrlAndParamsAndFileName("GET",
            '/file/do/error/data/downloadVickyProfitData',{},"利润报表.xls",this);
          },
            qq(){
                previewHtmlUrl("https://oss-label.oss-cn-shenzhen.aliyuncs.com/labelPrint/10336720261690515024707.html");
                // this.loadTxtOrHtml("https://oss-label.oss-cn-shenzhen.aliyuncs.com/labelPrint/10336720261690515024707.html",2);

            },
            loadTxtOrHtml(url,num) {
                var xh = window.Event ? new XMLHttpRequest() : new window.ActiveXObject('Microsoft.XMLHTTP')
                xh.open('GET', url, true)
                //这里改变编码格式，如果是txt格式，则为gb2312，如果是html格式，则为utf-8
                let code = num == '3'? 'gb2312':'utf-8'
                if (window.Event) xh.overrideMimeType('text/xml;charset='+ code)
                xh.onreadystatechange = ()=> {
                    if (xh.readyState != 4) return
                    var v
                    v = window.Event ? xh.responseText : window.gb2utf8(xh.responseBody)
                    this.htmlText = v//html文件内容
                }
                window.gb2utf8 = (data)=> {
                    var glbEncode = [],
                        t,
                        i,
                        j,
                        len
                    var gb2utf8_data = data
                    window.execScript("gb2utf8_data = MidB(gb2utf8_data, 1)+' '", 'vbscript')
                    t = escape(gb2utf8_data)
                        .replace(/%u/g, '')
                        .replace(/(.{2})(.{2})/g, '%$2%$1')
                        .replace(/%([A-Z].)%(.{2})/g, '@$1$2')
                    t = t.split('@')
                    i = 0
                    len = t.length
                    while (++i < len) {
                        j = t[i].substring(0, 4)
                        if (!glbEncode[j]) {
                            var gb2utf8_char = eval('0x' + j)
                            window.execScript('gb2utf8_char=Chr(gb2utf8_char)', 'vbscript')
                            glbEncode[j] = escape(gb2utf8_char).substring(1, 6)
                        }
                        t[i] = glbEncode[j] + t[i].substring(4)
                    }
                    gb2utf8_data = gb2utf8_char = null
                    return unescape(t.join('%')).slice(0, -1)
                }
                xh.send(null)
            },
            reset() {
                this.allArticleTypeList = [];
                this.handleReset();
            },
            tishi() {
                this.$message.warning("亲,一个文章只能有一张封面哦，不能贪心");
            },
            selectPushPort(val) {
                console.log("端口类型", val, typeof val, 'typeof val');
                const params = {
                    pushPort: (typeof val == 'string') ? val : val.join(','),
                };
                if (val != null && val != "" && val != undefined) {
                    getAllArticle(params).then((response) => {
                        //给allArticleTypeList数组赋值
                        this.allArticleTypeList = response.result;
                        console.log("文章类别数组", this.allArticleTypeList);
                    });
                }
            },
            handleAdd() {
                this.form = {
                    title: "", // 文章标题
                    pushPort: "", // 发布端口
                    articleType: "", // 文章类别
                    text: "",
                    coverUrl: '', // 封面
                    coverId: "", // 封面id
                    fileList: [], // 附件
                    attachFileId: [], // 附件id
                    coverFile: [],
                    attachFile: [],
                };
                this.edVis = true;
            },
            // 编辑/新增
            handleEdit(row) {
                console.log("这一行数据", row);
                // 如果是编辑，就钻取这一行的值，是新增就清空

                getAction(this.url.queryById, {id: row.id}).then((res) => {
                    this.form = res.result;
                    if (this.form.attachFile) {
                        let flag = true;
                        for (var i = 0; i < this.form.attachFile.length; i++) {
                            if (this.form.attachFile[i]) {
                                flag = false;
                            }
                        }
                        if (flag) {
                            this.form.attachFile = [];
                        }
                    } else {
                        this.form.attachFile = [];
                    }
                    if (!this.form.coverFile) {
                        this.form.coverFile = [];
                    } else {
                        let flag = true;
                        for (var i = 0; i < this.form.coverFile.length; i++) {
                            if (this.form.coverFile[i]) {
                                flag = false;
                            }
                        }
                        if (flag) {
                            this.form.coverFile = [];
                        }
                    }

                    //this.selectPushPort(this.form.pushPort);
                    this.edVis = true;
                });
                //初始化下拉框
                //回显附件名字操作
            },
            // handleTop({id, title}) {
            //     this.$confirm(`确定删除置顶【${title}】?`, '提示', {
            //         confirmButtonText: '确定',
            //         cancelButtonText: '取消',
            //         type: 'success',
            //         closeOnClickModal: false,
            //     }).then(() => {
            //         getAction(this.url.isTop, {id}).then(res => {
            //             this.$message({
            //                 type: 'success',
            //                 message: res.message
            //             })
            //             this.handleSearch()
            //         })
            //     }).catch(() => {

            //     })
            // },
            handleSubmit() {
                let url = this.url.add;
                let method = "POST";

                if (this.form.id) {
                    url = this.url.edit;
                    method = "PUT";
                    this.form.coverUrl == null ? this.form.coverId = '' : ''
                }
                this.$refs.form.validate((valid) => {
                    if (valid) {
                        console.log("上传获取数据", this.form);

                        //将上传的集合 转为字符串
                        let urllist = [];
                        console.log("封面地址", this.form.coverFile);
                        if (this.form.coverFile) {
                            for (var i = 0; i < this.form.coverFile.length; i++) {
                                if (i == 0) {
                                    this.form.coverId = this.form.coverFile[i].id;
                                    this.form.coverUrl = this.form.coverFile[i].url;
                                }
                            }
                            if (this.$refs["coverUrl"]) {
                                this.$refs["coverUrl"].clearValidate();
                            }
                        }

                        let attchlist = [];
                        if (this.form.attachFile) {
                            for (var i = 0; i < this.form.attachFile.length; i++) {
                                let id = this.form.attachFile[i].id;
                                attchlist.push(id);
                            }
                        }
                        this.form.pushPort = typeof this.form.pushPort == 'object' ? this.form.pushPort.join(',') : this.form.pushPort;
                        this.form.articleType = this.parseArticleType(this.form.articleTypeId);
                        this.form.attachFileIds = attchlist.join(",");
                        console.log("校验通过，填充的对象", this.form);

                        httpAction(url, method, this.form).then((res) => {
                            this.$message({
                                type: "success",
                                message: res.message,
                            });
                            this.edVis = false;
                            this.handleSearch();
                        });
                    }
                });
            },
            handleDel({id, title}) {
                this.$confirm(`确定删除${title}?`, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                    closeOnClickModal: false,
                })
                    .then(() => {
                        deleteAction(this.url.delete, {id}).then((res) => {
                            this.$message({
                                type: "success",
                                message: res.message,
                            });
                            this.handleSearch();
                        });
                    })
                    .catch(() => {
                    });
            },
            //文章预览
            wenzhang(row) {
                getAction(this.url.queryById, {id: row.id}).then((res) => {
                    this.form = res.result;
                    /*if(res.success){
                      console.log('浏览量')
                      getAction(this.url.vagrantsNum, { id: row.id }).then((res) => {
                        if(res.success){
                          console.log('浏览量+1')
                          // this.handleSearch();
                        }
                      });
                    }*/
                    if (this.form.attachFile) {
                        let flag = true;
                        for (var i = 0; i < this.form.attachFile.length; i++) {
                            if (this.form.attachFile[i]) {
                                this.form.attachFile[i].name = this.form.attachFile[i].fileName;
                                flag = false;
                            }
                        }
                        if (flag) {
                            this.form.attachFile = [];
                        }
                    } else {
                        this.form.attachFile = [];
                    }
                    if (!this.form.coverFile) {
                        this.form.coverFile = [];
                    } else {
                        let flag = true;
                        for (var i = 0; i < this.form.coverFile.length; i++) {
                            if (this.form.coverFile[i]) {
                                flag = false;
                            }
                        }
                        if (flag) {
                            this.form.coverFile = [];
                        }
                    }
                    this.visible = true;
                });
            },
            handleBatDel() {
                this.$confirm("确定删除？", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "error",
                    closeOnClickModal: false,
                })
                    .then(() => {
                        deleteAction(this.url.deleteBatch, {
                            ids: this.selectedRowKeys.join(","),
                        }).then((res) => {
                            this.$message({
                                type: "success",
                                message: res.message,
                            });
                            this.handleSearch();
                        });
                    })
                    .catch(() => {
                    });
            },
            handleUpdateCache() {
                this.updateCacheForm = true
            },
            handleUpdateCacheButtom() {
                postAction(this.url.updateCache + "?dict=" + this.cacheValue).then((res) => {
                    if (res.success) {
                        this.$message.success("更新成功！");
                        this.updateCacheForm = false;
                    } else {
                        this.$message.error("更新失败：" + res.message);
                    }
                }).catch((err) => {
                    this.$message.error(err);
                });
            },
            //获取所有文章类别
            initAllArticle() {
                getAllArticle()
                    .then((res) => {
                        if (res.success) {
                            for (var i = 0; i < res.result.length; i++) {
                                var obj = {};
                                obj.value = res.result[i].id;
                                obj.label = res.result[i].articleType;
                                this.allArticleTypeList.push(obj);
                            }
                        }
                    })
                    .catch((err) => {
                        this.$message.error(err);
                    });
            },
            //附件下载
            xiazai(row) {
                this.attachFile = [];
                getAction(this.url.queryById, {id: row.id}).then((res) => {
                    if (res.success) {
                        this.attachFile = res.result.attachFile;
                        if (null == this.attachFile || this.attachFile.length <= 0) {
                            this.$message.warning("暂无附件");
                        } else {
                            postAction(this.url.batchDownload, this.attachFile, {
                                responseType: "blob",
                            }).then((response) => {
                                let u = window.URL.createObjectURL(response);
                                let aLink = document.createElement("a");
                                aLink.style.display = "none";
                                aLink.href = u;
                                aLink.setAttribute("download", row.title + "的附件.zip");
                                document.body.appendChild(aLink);
                                aLink.click();
                                document.body.removeChild(aLink);
                                window.URL.revokeObjectURL(u);
                            });
                        }
                    }
                });
            },
            created() {

            },
        },

        computed: {
            coverFile() {
                return this.form.coverFile;
            },
            text() {
                return this.form.text;
            },
          buttonShowList() {
            return {
              'add':this.$btnIsShow('content/StorePublish','0','新增'),
              'delB':this.$btnIsShow('content/StorePublish','0','批量删除'),
              'sendCEmail':this.$btnIsShow('content/StorePublish','0','发送提成汇总邮件'),
              'sendCsEmail':this.$btnIsShow('content/StorePublish','0','发送提成明细邮件'),
              'dcy':this.$btnIsShow('content/StorePublish','1','导出云当网数据'),
              'edit':this.$btnIsShow('content/StorePublish','1','编辑'),
              'del':this.$btnIsShow('content/StorePublish','1','删除'),
              'deta':this.$btnIsShow('content/StorePublish','1','版本详情'),

            }
          }
        },
        watch: {
            coverFile(newV) {
                //console.log("选择的封面url", newV);
                if (newV && newV.length >= 1) {
                    if (this.$refs["coverUrl"]) {
                        this.$refs["coverUrl"].clearValidate();
                    }
                }
            },
            text(newV) {
                //console.log("选择的文本内容", newV);
                if (newV && newV.length >= 1) {
                    if (this.$refs["text2"]) {
                        this.$refs["text2"].clearValidate();
                    }
                }
            },
        },


    };
</script>
<style lang="scss" scope>
    .input_top .el-form-item {
        margin-bottom: 2px;
    }

    .addEditBox {
        max-height: 740px;
        overflow: scroll;
    }
</style>
