import Vue from 'vue'
import Vuex from 'vuex'
import mutations from './mutations'
import actions from './actions'

import user from './modules/user'
import dict from './modules/dict'

Vue.use(Vuex)

const state = {
    sidebarList: [],
    permissionList: [],

    // 权限数组
    permslist:[],

    routes: [
        {
          title: '首页',
          path: '/',
          closable: false
        }
    ],
    routesMap: {
        '/': '首页'
    },
    upRoute: '',  //上层路由，用来解决在菜单中找不到对应的当前路由的情况，存储他的上级路由，在菜单中默认显示
    messageQuery: 0,  //消息的数量
    token: '',  //token
    fullscreenLoading: false, //整个页面加载loading
    activityLists: [
      { label: '费用类-自动录入', value: 'ExpensesBasicInfo' },
      { label: '费用类-自动同步', value: 'FeeSyncBasicInfo' }
    ],  //活动配置-活动类型弹窗
    activityMap: {'ExpensesBasicInfo': '费用类-自动录入','FeeSyncBasicInfo': '费用类-自动同步'},  //活动配置-活动类型弹窗-map
    navHeight: {
      headerTopBox: 40,
      headerTwoBox: 44
    },//顶部导航高度获取
}

export default new Vuex.Store({
  state,
  mutations,
  actions,
  modules: {
    user,
    dict,
  }
})
