<template>
  <div class="main-full-content">
    <el-form
      class="input_top"
      ref="form"
      :model="queryForm"
      label-width="80px"
      size="medium"
    >
      <el-card class="box-card">
        <el-row>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="汇总时间">
              <el-date-picker
                class="f-flex-1"
                v-model="queryForm.createTime"
                type="date"
                size="small"
                placeholder="请选择日期"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label-width="30px">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="handleSearch"
                >查询</el-button
              >
              <el-button plain @click="handleReset">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
    </el-form>
    <el-card class="box-card last_card">
      <div class="f-p-b-15">
        <el-button
          type="primary"
          size="medium"
          @click="exportExcel('应收汇总')"
          v-if="$btnIsShow('hyFinanceManage/ReceivablesSummary', '0', '导出')"
          >导出</el-button
        >
        <el-button
          type="primary"
          size="medium"
          @click="synchronousData"
          v-if="
            $btnIsShow(
              'hyFinanceManage/ReceivablesSummary',
              '0',
              '手动同步应收账款数据'
            )
          "
          >手动同步应收账款数据</el-button
        >
      </div>
      <f-table
        v-loading="loading"
        ref="GTable"
        border
        size="medium"
        row-key="id"
        class="c-th-has-bgcolor"
        :columns="columns"
        :data="tableData"
        :cell-style="{ 'text-align': 'center' }"
        tableHeight="62vh"
        :isRecordTableScroll="true"
        @selection-change="handleSelectionChange"
        @sort-change="handleTableChange"
      >
        <template v-slot="scope">
          <template v-if="scope.column.property === 'action'"> </template>
          <template v-else-if="scope.column.property === 'toCnyCur'">
            {{ scope.row.id ? scope.row.toCnyCur : "折合后汇总" }}
          </template>
          <template v-else-if="scope.column.property === 'createTime'">
            <span :class="!scope.row.id ? 'bg_collect' : ''">{{
              scope.row.createTime
            }}</span>
          </template>
          <template v-else-if="scope.column.property === 'total'">
            <span :class="!scope.row.id ? 'bg_collect' : ''">{{
              scope.row.total
            }}</span>
          </template>
        </template>
      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination
          v-bind="ipagination"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import { postAction, getAction } from "@/api/manage";
import QueryMixins from "@/mixins/QueryMixins.js";
export default {
  name: "ReceivablesSummary",
  mixins: [QueryMixins],
  components: {},
  data() {
    return {
      visible: false,
      row: {},
      columns: [
        {
          type: "selection",
          width: 50,
        },
        {
          prop: "createTime",
          label: "汇总时间",
        },
        {
          prop: "toCnyCur",
          label: "汇率",
        },
        {
          prop: "moneyType",
          label: "币种",
        },
        {
          prop: "noSailedTotal",
          label: "未开船应收账款",
        },
        {
          prop: "noArrivalTotal",
          label: "已开船未到港应收账款",
        },
        {
          prop: "hasArrivalTotal",
          label: "已到港未派送应收账款 ",
        },
        {
          prop: "hasDeliveryTotal",
          label: "已派送应收账款",
        },
        {
          prop: "finishTotal",
          label: "快速完成应收账款",
        },
        {
          prop: "total",
          label: "应收账款合计",
        },
        // {
        //   prop: "action",
        //   label: "操作",
        //   width: 100,
        // },
      ],
      url: {
        list: "/finance/summary/getPage",
        exportXlsUrl: "/finance/summary/downloadSummary",
        syncSettleSummary: "/finance/fee/order/syncSettleSummary",
      },
    };
  },
  methods: {
    synchronousData() {
      postAction(this.url.syncSettleSummary)
        .then((res) => {
          if (res.success) {
            this.$message.success(res.message);
          }
        })
        .catch((err) => {
          this.$message.error(err.message || err.errorMsg);
        });
    },
  },
  created() {},
  mounted() {},
};
</script>

<style lang="scss" scoped>
</style>
