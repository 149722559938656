<template>
  <el-dialog
    title="关务记录"
    :visible="visible"
    width="840px"
    custom-class="c-record-dialog"
    append-to-body
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <div v-if="tableData.length" class="flex-col-every">
      <div
        v-for="(item, index) in tableData"
        :key="index"
        class="flex-col-every"
      >
        <div>
          <span>订单号：{{ item.orderNo }}</span>
          <span>创建人：{{ item.createBy }}</span>
          <span>创建时间：{{ item.createTime }}</span>
        </div>
        <div>
          <span>记录类型：{{ item.recordType }}</span>
          <span>国家：{{ item.shipmentCountry }}</span>
        </div>
        <div>记录内容：{{ item.content }}</div>
        <div>可能后果：{{ item.maybeResult }}</div>
        <div>关务建议：{{ item.customsAdvice }}</div>
      </div>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination
          v-bind="ipagination"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </el-row>
    </div>
    <div v-else style="margin: 140px auto 0 auto">暂无</div>
  </el-dialog>
</template>

<script>
import { getAction, postAction } from "@/api/manage";
export default {
  name: "CustomsRecordDialog",
  props: {
    visible: {
      type: Boolean,
    },
    orderNo: {
      type: String,
      default: () => {},
    },
  },
  data() {
    return {
      url: {
        judgeCusHasRecord: "/interface/ttOrder/judgeCusHasRecord",
        dict: "/sys/dict/getDictItems/",
      },
      tableData: [],
      ipagination: {
        currentPage: 1,
        pageSize: 1,
        pageSizes: [1],
        total: 0,
      },
      dictlist: {},
    };
  },
  watch: {
    visible(val) {
      if (val) {
        this.handleQuery();
        // this.getDict();
      }
    },
  },
  methods: {
    getDict() {
      getAction(this.url.dict + "customs_record_type").then((res) => {
        this.dictlist = res.result;
      });
    },
    handleClose() {
      this.ipagination = {
        currentPage: 1,
        pageSize: 1,
        pageSizes: [1],
        total: 0,
      };
      this.tableData = [];
      this.$emit("update:visible", false);
    },
    handleSizeChange(val) {
      this.ipagination.currentPage = 1;
      this.ipagination.pageSize = val;
      this.handleQuery();
    },
    handleCurrentChange(val) {
      this.ipagination.currentPage = val;
      this.handleQuery();
    },
    handleQuery() {
      this.$store.commit("setFullscreenLoading", true);
      getAction(this.url.judgeCusHasRecord, {
        orderNo: this.orderNo,
        pageNo: this.ipagination.currentPage,
      })
        .then((res) => {
          this.$store.commit("setFullscreenLoading", false);
          if (res.success) {
            const { records, total } = res.result;
            this.tableData = records || res.result;
            this.ipagination &&
              (this.ipagination.total = total || res.result.length);
          }
        })
        .catch((err) => {
          this.$store.commit("setFullscreenLoading", false);
        });
    },
  },
};
</script>

<style lang="scss">
.c-record-dialog {
  .el-pagination__sizes {
    display: none;
  }
  .el-dialog__body {
    padding: 0 20px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between !important;
    min-height: 360px;
    span {
      margin-right: 40px;
    }
  }
  .flex-col-every {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
}
</style>