<template>
  <el-dialog
      :title="row.id ? '编辑仓库信息' : '新增仓库信息'"
      :visible="visible"
      width="560px"
      custom-class="c-custom-dialog"
      :close-on-click-modal="false"
      :before-close="handleClose">
    <el-form :model="addForm" :rules="rules" ref="addForm" label-width="120px" size="medium">
      <el-row>
        <el-col :span="24">
          <el-form-item prop="type" label="类型">
            <el-radio-group v-model="addForm.type">
              <el-radio :label='"1"'>企业</el-radio>
              <el-radio :label='"2"'>仓库</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="24" v-if="addForm.type=='2'">
          <el-form-item prop="nature" label="性质">
            <el-radio-group v-model="addForm.nature">
              <el-radio :label='"1"'>自有</el-radio>
              <el-radio :label='"2"'>第三方</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item prop="content" label="名称">
            <el-input v-model="addForm.content" placeholder="请输入名称" maxlength="120" show-word-limit></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item prop="contentEn" label="名称英文">
            <el-input v-model="addForm.contentEn" placeholder="请输入名称英文" maxlength="120" show-word-limit></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item prop="address" label="地址">
            <el-input v-model="addForm.address" placeholder="请输入地址" maxlength="120" show-word-limit></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item prop="addressEn" label="地址英文">
            <el-input v-model="addForm.addressEn" placeholder="请输入地址英文" maxlength="220" show-word-limit></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item prop="contact" label="联系人">
            <el-input v-model="addForm.contact" placeholder="请输入联系人" maxlength="20" show-word-limit></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item prop="contactEn" label="联系人英文">
            <el-input v-model="addForm.contactEn" placeholder="请输入联系人英文" maxlength="20" show-word-limit></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item prop="phone" label="联系电话">
            <el-input v-model="addForm.phone" placeholder="请输入联系电话" maxlength="20" show-word-limit></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item prop="country" label="国家">
            <el-input v-model="addForm.country" placeholder="请输入国家" maxlength="2" show-word-limit></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item prop="state" label="省">
            <el-input v-model="addForm.state" placeholder="请输入省" maxlength="20" show-word-limit></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item prop="city" label="城市">
            <el-input v-model="addForm.city" placeholder="请输入城市" maxlength="20" show-word-limit></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item prop="district" label="街道">
            <el-input v-model="addForm.district" placeholder="请输入街道" maxlength="50" show-word-limit></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item prop="postalCode" label="邮编">
            <el-input v-model="addForm.postalCode" placeholder="请输入邮编" maxlength="20" show-word-limit></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="24">
          <el-form-item prop="lng" label="经度">
            <el-input v-model="addForm.lng" placeholder="请输入经度" maxlength="20" show-word-limit></el-input>
          </el-form-item>
        </el-col>


        <el-col :span="24">
          <el-form-item prop="lat" label="纬度">
            <el-input v-model="addForm.lat" placeholder="请输入纬度" maxlength="20" show-word-limit></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="24">
          <el-form-item prop="orderNum" label="排序">
            <el-input v-model="addForm.orderNum" placeholder="请输入排序" type="number" ></el-input>
          </el-form-item>
        </el-col>

      </el-row>
    </el-form>
    <span slot="footer">
            <el-button size="medium" @click="handleClose">取消</el-button>
            <el-button type="primary" size="medium" @click="handleSubmit">确定</el-button>
        </span>
  </el-dialog>
</template>

<script>
import { httpAction } from '@/api/manage'
import {mobilePattern} from "@/utils/pattern";
import {noZhongwenValidator} from "../../../utils/validator";
export default {
  name: 'DeliveryPointDialog',

  props: {
    visible: {
      type: Boolean
    },
    row: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {

      addForm: {
        content:'',
        contentEn:'',
        address:'',
        addressEn:'',
        contact:'',
        contactEn: ''
      },
      rules: {
        type: [
          { required: true, message: '请选择类型', trigger: 'change' },
        ],
        nature: [
          { required: true, message: '请选择性质', trigger: 'change' },
        ],
        content: [
          { required: true, message: '请输入名称', trigger: 'change' },
        ],
        contentEn: [
          {validator:noZhongwenValidator,trigger: 'change'}
        ],
        contact: [
          { required: true, message: '请输入联系人', trigger: 'change' },
        ],
        contactEn: [
          {validator:noZhongwenValidator,trigger: 'change'}
        ],
        state: [
          {validator:noZhongwenValidator,trigger: 'change'}
        ],
        city: [
          {validator:noZhongwenValidator,trigger: 'change'}
        ],
        district: [
          {validator:noZhongwenValidator,trigger: 'change'}
        ],
        postalCode: [
          {validator:noZhongwenValidator,trigger: 'change'}
        ],
        phone: [
          { required: true, message: '请输入电话', trigger: 'change' },
          {validator:noZhongwenValidator,trigger: 'change'}
          // {pattern:mobilePattern,message:'请输入正确的联系方式',trigger:'change'},
        ],
        address:[
          { required: true, message: '请输入地址', trigger: 'change' },
        ],
        addressEn: [
          {validator:noZhongwenValidator,trigger: 'change'}
        ],
      },
      url: {
        add: '/sys/deliverpoint/add',
        edit: '/sys/deliverpoint/edit'
      }
    }
  },
  watch: {
    visible(val) {
      if(val && this.row.id) {
        for (var name in this.row){
          this.$set(this.addForm,name,this.row[name])
        }
      } else {
        this.addForm = {}
      }
    },

  },
  created(){
    // this.countrylist
  },
  methods: {


    handleSubmit() {
      let url = this.url.add
      let method = 'POST'
      if(this.row.id) {
        url = this.url.edit
        method = 'PUT'
      }
      console.log("要提交的对象",this.addForm)
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          httpAction(url, method, this.addForm).then((res)=>{
            this.$message({
              type: 'success',
              message: res.message
            })
            localStorage.removeItem("dict:delivery_point");
            localStorage.removeItem("dict:lcl_place");
            this.handleClose()
            this.$emit('ok')
          })
        }
      })
    },
    handleClose() {
      this.$emit('update:visible', false)
      this.$refs.addForm.resetFields()
    },

  }
}
</script>

<style lang="scss" scoped>

</style>
