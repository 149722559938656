<!--理赔部-代理-->
<template>
  <div class="main-full-content">
    <el-form
      class="input_top"
      ref="form"
      :model="queryForm"
      label-width="120px"
    >
      <el-card class="box-card">
        <el-row>
          <el-row>
            <el-col :span="4">
              <el-form-item label="索赔员:">
                <f-select
                  v-model="queryForm.claimManId"
                  :is-need="queryForm.claimManId"
                  :dict="'sys_user_id'"
                  placeholder="请选择"
                  filterable
                  clearable
                >
                </f-select>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="跟进人:">
                <f-select
                  v-model="queryForm.followManId"
                  :is-need="queryForm.followManId"
                  :dict="'sys_user_id'"
                  placeholder="请选择"
                  filterable
                  clearable
                >
                </f-select>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="操作员:">
                <f-select
                  v-model="queryForm.operationId"
                  :is-need="queryForm.operationId"
                  :dict="'sys_user_id'"
                  placeholder="请选择"
                  filterable
                  clearable
                >
                </f-select>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="供应商:">
                <f-select
                  v-model="queryForm.supplierId"
                  :is-need="queryForm.supplierId"
                  :dict="'supplier_id'"
                  placeholder="请选择"
                  filterable
                  clearable
                >
                </f-select>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="订单号:">
                <el-input
                  v-model="queryForm.orderNo"
                  @keyup.enter.native="handleSearch"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="柜号:">
                <el-input
                  v-model="queryForm.containerNo"
                  @keyup.enter.native="handleSearch"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="4">
              <el-form-item label="索赔单号:">
                <el-input
                  v-model="queryForm.claimNo"
                  @keyup.enter.native="handleSearch"
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="4">
              <el-form-item label="销账状态:">
                <f-select
                  v-model="queryForm.settleStatus"
                  :is-need="queryForm.settleStatus"
                  :dict="'claim_settle_status'"
                  placeholder="请选择"
                  filterable
                  clearable
                >
                </f-select>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="索赔状态:">
                <f-select
                  v-model="queryForm.claimStatus"
                  :is-need="queryForm.claimStatus"
                  :dict="'claim_status'"
                  placeholder="请选择"
                  filterable
                  clearable
                >
                </f-select>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="币种:">
                <f-select
                  v-model="queryForm.moneyType"
                  :is-need="queryForm.moneyType"
                  :dict="'bd_cur'"
                  placeholder="请选择"
                  filterable
                  clearable
                >
                </f-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="录入时间:">
                <div class="f-flex">
                  <el-date-picker
                    style="width: 170px"
                    v-model="queryForm.createBeginTime"
                    type="datetime"
                    size="small"
                    placeholder="开始时间"
                    default-time="00:00:00"
                    value-format="yyyy-MM-dd HH:mm:ss"
                  >
                  </el-date-picker>
                  <span class="f-p-h-2">~</span>
                  <el-date-picker
                    style="width: 170px"
                    v-model="queryForm.createEndTime"
                    type="datetime"
                    size="small"
                    placeholder="结束时间"
                    default-time="23:59:59"
                    value-format="yyyy-MM-dd HH:mm:ss"
                  >
                  </el-date-picker>
                  <el-button
                    style="margin-left: 15px"
                    type="primary"
                    size="medium"
                    icon="el-icon-search"
                    @click="handleSearch"
                    >查询
                  </el-button>
                  <el-button plain size="medium" @click="handleReset"
                    >重置</el-button
                  >
                </div>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="4">
              <el-form-item label-width="30px">

              </el-form-item>
            </el-col> -->
          </el-row>
        </el-row>
      </el-card>
    </el-form>

    <el-card class="box-card last_card">
      <div class="f-p-b-15">
        <el-button
          size="medium"
          icon="el-icon-plus"
          type="primary"
          @click="handleAdd"
          v-if="buttonShowList.add"
          >新增
        </el-button>
        <el-button
          size="medium"
          type="primary"
          @click="multipleDelete"
          v-if="buttonShowList.delB"
          >删除
        </el-button>
        <el-button
          size="medium"
          @click="exportExcelPlus(url.downloadClaimList, '理赔明细')"
          v-if="buttonShowList.dc"
          >导出
        </el-button>
        <el-button
          size="medium"
          plain
          @click="dataStatistics"
          v-if="buttonShowList.tj"
          >统计
        </el-button>
        <el-button
          size="medium"
          plain
          @click="batchCheck"
          v-if="
            buttonShowList.checkB
          "
          >批量审核
        </el-button>
      </div>
      <f-table
        v-loading="loading"
        ref="GTable"
        border
        size="medium"
        row-key="id"
        class="c-th-has-bgcolor"
        :columns="columns"
        :data="tableData"
        :cell-style="{ 'text-align': 'center' }"
        tableHeight="65vh"
        @selection-change="handleSelectionChange"
        @sort-change="handleTableChange"
      >
        <template v-slot="scope">
          <template v-if="scope.column.property === 'action'">
            <el-button
              type="text"
              @click="handleEdit(scope.row)"
              v-if="
               buttonShowList.edit
              "
              :disabled="scope.row.settleStatus !== '0'"
              >编辑
            </el-button>
            <el-button
              type="text"
              @click="handleDele(scope.row)"
              v-if="
                buttonShowList.del
              "
              :disabled="scope.row.settleStatus !== '0'"
              >删除
            </el-button>
            <el-button
              type="text"
              @click="handleCheck(scope.row)"
              v-if="
                buttonShowList.check
              "
              :disabled="scope.row.claimStatus === '1'"
              >审核
            </el-button>
            <el-upload
              class="upload-demo"
              action=""
              :http-request="(file) => addUploadOssClirnt(file, scope.row)"
              :show-file-list="false"
              ref="add"
              :multiple="true"
              :before-upload="beforeUpload"
            >
              <el-button type="primary" size="small">上传水单</el-button>
            </el-upload>
          </template>
          <template v-else-if="scope.column.property === 'billStatus'">
            <span>{{ parseDict(scope.row.billStatus, "bill_status") }}</span>
          </template>
          <template v-else-if="scope.column.property === 'settleStatus'">
            <span>{{
              parseDict(scope.row.settleStatus, "claim_settle_status")
            }}</span>
          </template>
          <template v-else-if="scope.column.property === 'osOrderFileList'">
            <span v-if="scope.row.osOrderFileList !== null">
              <div v-for="(i, d) in scope.row.osOrderFileList" :key="d">
                <a style="color: #00a0e9" :href="i.url">{{ i.name }}</a>
                <el-button
                  type="text"
                  style="margin-left: 10px"
                  @click="handDel(i)"
                  >删除</el-button
                >
              </div>
            </span>
          </template>
          <template v-else-if="scope.column.property === 'orderNo'">
            <a
              style="color: #00a0e9"
              @click.prevent="$intoDocPage(scope.row.orderNo)"
              >{{ scope.row.orderNo }}</a
            >
          </template>
        </template>
      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination
          v-bind="ipagination"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </el-row>
    </el-card>

    <!-- 新增编辑供应商弹框 -->
    <insurance-add-dialog
      :visible="visible"
      :row="row"
      type="3"
      @update:visible="
        (val) => {
          visible = val;
        }
      "
      @ok="handleSearch"
      @openCheckBatchDialog="openCheckBatchDialog"
      @checkOne="checkOne"
      :is-need-check="isNeedCheck"
      :need-check-index="checkIndex"
      :need-check-list="batchCheckList"
      :noNeedQingguan="'0'"
    />
    <!-- 统计弹框 -->
    <statistics-dialog
      :visible="statisticsvisible"
      :params="queryForm"
      title="统计供应商索赔"
      type="3"
      @update:visible="
        (val) => {
          statisticsvisible = val;
        }
      "
      @ok="handleSearch"
    />
    <!--    批量审核弹框-->
    <check-batch-dialog
      :visible="dialogbatchCheckVisible"
      @closeCheckDialog="closeCheckDialog"
      :check-count="checkCount"
      :check-list="checkList"
    >
    </check-batch-dialog>

    <!-- 批量审核弹框---有实际赔付金额没录入的情况 -->
    <update-check-batch-dialog
      :visible="dialogUpdateBatchCheckFormVisible"
      @ok="handleSearch"
      :check-list="checkList"

      @update:visible="
        (val) => {
          dialogUpdateBatchCheckFormVisible = val;
        }
      "
    >
    </update-check-batch-dialog>

    <el-dialog
      title="审核"
      width="38%"
      center
      :visible.sync="dialogcheckVisible"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      确认录入实际赔付金额？
      <div slot="footer" class="dialog-footer">
        <el-button size="medium" @click="handleClose">取 消</el-button>
        <el-button size="medium" type="primary" @click="check()"
          >确 定
        </el-button>
      </div>
    </el-dialog>

    <el-dialog
      title="确认审核"
      width="25%"
      center
      :visible.sync="dialogcheckAckVisible"
      :before-close="handleAckClose"
      :close-on-click-modal="false"
    >
      <div class="hint_text">
        该订单的实际赔付金额为空，请去录入实际赔付金额完成审核
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="medium" @click="handleAckClose">取 消</el-button>
        <el-button size="medium" type="primary" @click="enterRealClaimMoney()"
          >去 录 入
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { setUpRouteUtil } from "@/utils/util";
import {
  deleteAction,
  getAction,
  postAction,
  putAction,
  httpAction,
} from "@/api/manage";
import { zhognwenPattern } from "@/utils/pattern";
import QueryMixins from "@/mixins/QueryMixins.js";
import InsuranceAddDialog from "../modules/InsuranceAddDialog";
import StatisticsDialog from "../modules/StatisticsDialog";

import CheckBatchDialog from "@/views/claimDepartment/modules/CheckBatchDialog.vue";
import updateCheckBatchDialog from "@/views/claimDepartment/modules/UpdateCheckBatchDialog";
import OssClientMixins from "../../../mixins/OssClientMixins";
export default {
  name: "insurance",
  mixins: [QueryMixins,OssClientMixins],
  components: {
    CheckBatchDialog,
    InsuranceAddDialog,
    StatisticsDialog,
    updateCheckBatchDialog,
  },
  data() {
    return {
      batchCheckList: [],
      dialogbatchCheckVisible: false,
      dialogcheckVisible: false,
      checkList: [],
      checkCount: 0,
      isNeedCheck: false,
      checkIndex: 0,
      // ipaginationSelect: {
      //   currentPage: 1,
      //   pageSize: 50,
      //   pageSizes: [10, 20, 50, 100],
      //   total: 0,
      // },
      row: {},
      form: {},
      dialogFormVisibleShenhe: false,
      dialogcheckAckVisible: false,
      dialogFormVisibleSelect: false,
      chinaseName: "",
      isSelect: false,
      visible: false,
      statisticsvisible: false,
      initData: { type: 3 },
      ossClient: null,
      dictCodes: [
        "claim_type",
        "responsible_party",
        "bd_cur",
        "sys_user_id",
        "supplier_id",
        "bill_status",
        "claim_settle_status",
      ],
      columns: [
        {
          type: "selection",
          width: 35,
          fixed: "left",
        },
        {
          prop: "action",
          label: "操作",
          width: 80,
          fixed: "left",
        },
        {
          prop: "claimNo",
          label: "索赔单号",
          width: 110,
          fixed: "left",
        },
        // {
        //   prop: "containerNo",
        //   label: "索赔单号",
        //   sortable: "custom",
        //   width: 140,
        //   fixed: "left",
        // },
        {
          prop: "claimMan",
          label: "索赔员",
          width: 110,
          fixed: "left",
        },
        {
          prop: "followMan",
          label: "跟进人",
          width: 110,
        },
        {
          prop: "supplier",
          label: "供应商",
        },
        {
          prop: "operation",
          label: "操作员",
          width: 110,
        },
        {
          prop: "orderNo",
          label: "订单号",
          width: 110,
        },
        {
          prop: "containerNo",
          label: "柜号",
          width: 110,
        },
        {
          prop: "responsibleParty",
          label: "责任方",
          width: 70,
        },
        {
          prop: "claimType",
          label: "索赔类型",
          width: 70,
        },
        {
          prop: "claimMoney",
          label: "索赔金额",
          width: 70,
        },
        {
          prop: "moneyType",
          label: "币种",
          width: 70,
        },
        {
          prop: "claimStatusStr",
          label: "索赔状态",
          width: 70,
        },
        // {
        //     prop: "rate",
        //     label: "汇率",
        //     width: 70,
        // },
        {
          prop: "realClaimMoney",
          label: "实际赔付金额",
          width: 80,
        },
        // {
        //   prop: "billStatus",
        //   label: "账单状态",
        //   width: 140,
        // },
        {
          prop: "settleStatus",
          label: "销账状态",
          width: 80,
        },
        {
          prop: "applyNo",
          label: "备注",
        },
        {
          prop: "createBy",
          label: "录入人",
        },
        {
          prop: "osOrderFileList",
          label: "文件列表",
        },
        {
          prop: "createTime",
          label: "录入时间",
        },
      ],
      url: {
        list: "/file/claim/getClaimOrderPage",
        // 删除
        delete: "/file/claim/deleteClaimNoById",
        upload: "/sys/oss/file/upload", //文件上传
        add: "/interface/ttOrder/addOneFile",
        uploadShuidanFire: "/file/claim/uploadShuidanFire",
        downloadClaimList: "/file/claim/downloadClaimList",
        getStatusById: "/file/claim/getSettleStatusBySettleid",
        // checkBatch: "/file/claim/checkBatch",
        uploadDataSave: "/sys/oss/file/uploadDataSave",
        delById: "/interface/ttOrder/deleteFileById/",
        // 审核
        batchUpdateCheck: "/file/claim/batchUpdateCheck",
      },
      deleteList: [],
      dialogUpdateBatchCheckFormVisible: false,
    };
  },
  created() {
    this.$set(this.queryForm, 'orderNo', this.$route.query.orderNo);
    // this.$emit('init');
  },
  methods: {
    closeCheckDialog() {
      this.dialogbatchCheckVisible = false;
      this.handleSearch();
    },
    openCheckBatchDialog() {
      this.dialogbatchCheckVisible = true;
    },
    handleAckClose() {
      this.dialogcheckAckVisible = false;
    },
    handleCheck(row) {
      if (row.realClaimMoney != null) {
        this.checkList = [];
        // this.checkList.push(row.id);
        this.checkList.push(row);
        this.dialogcheckVisible = true;
      } else {
        this.checkList = [];
        this.checkList.push(row);
        // //this.checkList = JSON.parse(JSON.stringify(row));
        this.checkCount = 1;
        // this.openInsuranceDialog(row);
        this.row = row;
        this.dialogcheckAckVisible = true;
      }
    },
    enterRealClaimMoney() {
      this.isNeedCheck = true;
      this.openInsuranceDialog(this.row);
      this.dialogcheckAckVisible = false;
    },
    check() {
      const params = [];
      params.push({
        id: this.checkList[0].id,
        claimMoney: this.checkList[0].claimMoney || "",
        realClaimMoney: this.checkList[0].realClaimMoney,
        settleId: this.checkList[0].orderSettleId,
      });
      postAction(this.url.batchUpdateCheck, params)
        .then((res) => {
          this.$message.success(res.message);
          this.dialogcheckVisible = false;
          this.checkList = [];
          this.handleQuery();
        })
        .catch((err) => {
          this.$message.error(err.message);
          this.dialogcheckVisible = false;
          this.checkList = [];
          this.handleQuery();
        });
      // postAction(this.url.checkBatch, this.checkList)
      //   .then((res) => {
      //     this.$message.success(res.message);
      //     this.dialogcheckVisible = false;
      //     this.checkList = [];
      //     this.handleQuery();
      //   })
      //   .catch((err) => {
      //     this.$message.error(err.message);
      //     this.dialogcheckVisible = false;
      //     this.checkList = [];
      //     this.handleQuery();
      //   });
    },
    handleClose() {
      this.dialogcheckVisible = false;
    },
    batchCheck() {
      this.isNeedCheck = true;
      this.checkList = JSON.parse(JSON.stringify(this.selectionRows));
      let result = this.checkList.some((item) => !item.realClaimMoney);
      if (result) {
        this.dialogUpdateBatchCheckFormVisible = true;
      } else {
        this.openCheckBatchDialog();
      }
      //拷贝勾选的集合  给batchCheckList
      // let checkList = JSON.parse(JSON.stringify(this.selectionRows));
      // //2.过滤掉实际赔付金额不为空的单号集合
      // this.batchCheckList = checkList;
      // this.checkList = checkList;
      // this.checkCount = checkList.length;
      // this.batchCheckList = this.batchCheckList.filter((item) => {
      //   return !item.realClaimMoney && item.settleStatus === "0";
      // });
      // // 3.如果全都不为空，弹出审核的直接弹出
      // if (!this.batchCheckList || this.batchCheckList.length === 0) {
      //   //4.直接弹框
      //   this.openCheckBatchDialog();
      // } else {
      //   this.checkOne(0);
      // }
      //this.showNextModal(selects, 0);
    },
    checkOne(index) {
      this.isNeedCheck = true;
      this.checkIndex = index + 1;
      this.openInsuranceDialog(this.batchCheckList[index]);
    },
    handleEdit(row) {
      this.isNeedCheck = false;
      this.openInsuranceDialog(row);
    },
    openInsuranceDialog(row) {
      getAction(this.url.getStatusById, { settleIds: row.orderSettleId }).then(
        (res) => {
          let s = res.result;
          if (s !== "0") {
            this.$message.warning("此费用已经在财务流程中，无法编辑");
            return false;
          } else {
            this.row = row;
            this.visible = true;
          }
        }
      );
    },
    //点击统计按钮
    dataStatistics() {
      this.statisticsvisible = true;
    },
    //点击新增按钮
    handleAdd() {
      this.row = {};
      this.visible = true;
      // // 对于路由不在左侧菜单中的选中菜单处理
      // setUpRouteUtil(this.$route.path, "/supplier/Edit");
      // this.$router.push(`/supplier/Edit`);
    },
    handleDele(row) {
      this.$confirm("是否确认删除本次索赔", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        closeOnClickModal: false,
      })
        .then(() => {
          this.deleteList = [];
          this.deleteList.push({
            id: row.id,
            orderSettleId: row.orderSettleId,
          });
          getAction(this.url.getStatusById, {
            settleIds: row.orderSettleId,
          }).then((res) => {
            let s = res.result;
            if (s !== "0") {
              this.$message.warning("此费用已经在财务流程中，无法编辑");
              return false;
            } else {
              putAction(this.url.delete, this.deleteList).then((res) => {
                this.$message({
                  type: "success",
                  message: res.message,
                });
                this.handleQuery();
              });
            }
          });
        })

    },

    //文件上传之前校验文件合法
    beforeUpload(file) {
      let name = "";
      if (file && file.name) {
        name = file.name;
      }
      const isFile = name.indexOf(".") !== -1;
      if (!isFile) {
        this.$message.warning("该文件名不合法");
      }
      return isFile;
    },
    generateUUID(length) {
      var d = new Date().getTime();
      let uuid = d.toString() + Math.random().toString().substr(3, length);
      return uuid;
    },
    //使用阿里云上传文件
    //:http-request="(file) => addUploadOssClirnt(file, scope.row)"
    async addUploadOssClirnt(data, row) {
      let uuid = this.generateUUID(6);
      const fileItem = await this.handleUploadOss2(data,'agency','finance',uuid);
      console.log("上传返回的结果：" , fileItem);
      if (!fileItem) {
        that.$message.warning('上传文件失败');
        return false;
      }
      let file = data["file"];
      const that = this;
      httpAction(that.url.add, "post", {
        url: fileItem.url,
        ossId: fileItem.id,
        size: file.size / 1000,
        type: row.id,
        name: file.name,
        orderNo: row.orderNo,
      }).then((res) => {
        this.$store.commit("setFullscreenLoading", false);
            console.log("res------------:::::", res);
            if (res.success) {
              that.dialogFormVisible = false;
              that.$message.success(res.message);
              that.$set(that.queryForm, "type", "");
              that.handleQuery();
            } else {
              // that.btnDisable = false;
            }
          })
          .catch((err) => {
            this.$store.commit("setFullscreenLoading", false);
            // that.btnDisable = false;
          });
    },
    handDel({ id, name }) {
      console.log("id", id);
      this.$confirm(`删除${name}?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "success",
        closeOnClickModal: false,
      })
        .then(() => {
          deleteAction(this.url.delById + id).then((res) => {
            this.$message({
              type: "success",
              message: res.message,
            });
            this.handleQuery();
          });
        })

    },

    // handleCheck(row) {
    //   getAction(this.url.getStatusById,{settleIds:row.orderSettleId})
    //       .then(res=>{
    //         let s = res.result;
    //         if (s !== '0'){
    //           this.$message.warning('此费用已经在财务流程中，无法编辑');
    //           return false;
    //         }else {
    //           this.row = row;
    //           console.log(row, "row");
    //           this.visible = true;
    //         }
    //       })
    // },

    // 多个删除
    multipleDelete() {
      if (this.selectionRows.length < 1) {
        this.$message.warning("请至少勾选一条数据");
        return;
      }
      this.$confirm("是否确认删除选中索赔?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        closeOnClickModal: false,
      })
        .then(() => {
          this.deleteList = [];
          this.selectionRows.forEach((select) => {
            this.deleteList.push({
              id: select.id,
              orderSettleId: select.orderSettleId,
            });
          });
          // console.log(row, "row6666");
          putAction(this.url.delete, this.deleteList).then((res) => {
            this.$message({
              type: "success",
              message: res.message,
            });
            this.handleQuery();
          });
        })
        .catch((err) => {
          this.$message.error(err.message);
        });
      console.log(this.selectionRows, "this.selectionRows");
    },

    // handleSelect() {
    //   this.isSelect = false;
    //   this.tableDataSelect = [];
    //   this.chinaseName = "";
    //   this.dialogFormVisibleSelect = true;
    // },
    // sureSelect() {
    //   this.form.chinaseName = this.chinaseName;
    //   this.form.cooperationType = "";
    //   this.pageSelect();
    // },
    // 页码变化，重新获取数据
    // pageSelect() {
    //   if (this.ipaginationSelect) {
    //     this.form.pageNo = this.ipaginationSelect.currentPage;
    //     this.form.pageSize = this.ipaginationSelect.pageSize;
    //   }
    //   getAction(this.url.list, this.form).then((res) => {
    //     console.log(res);
    //     if (res.success) {
    //       if (res.result) {
    //         const { records, total } = res.result;
    //         this.tableDataSelect = records || res.result;

    //         this.ipaginationSelect &&
    //           (this.ipaginationSelect.total = total || res.result.length);
    //         this.isSelect = true;
    //       } else {
    //         this.tableDataSelect = [];
    //         this.ipaginationSelect = 0;
    //       }
    //     }
    //   });
    // },

    // handleSizeChangeSelect(val) {
    //   this.ipaginationSelect.currentPage = 1;
    //   this.ipaginationSelect.pageSize = val;
    //   this.pageSelect();
    // },
    // handleCurrentChangeSelect(val) {
    //   this.ipaginationSelect.currentPage = val;
    //   this.pageSelect();
    // },
    handleReset() {
      this.queryForm = {};
      this.ipagination.currentPage = 1;
      this.handleQuery();
    },
    closeUpdateCheckDialog() {
      this.dialogUpdateBatchCheckFormVisible = false;
      this.handleSearch();
    },
  },
  computed :{
    buttonShowList() {
      return {
        'add':this.$btnIsShow('claimDepartment/supplier/agency', '0', '新增'),
        'delB':this.$btnIsShow('claimDepartment/supplier/agency', '0', '删除'),
        'dc':this.$btnIsShow('claimDepartment/supplier/agency', '0', '导出'),
        'tj':this.$btnIsShow('claimDepartment/supplier/agency', '0', '统计'),
        'checkB':this.$btnIsShow('claimDepartment/supplier/insurance', '0', '批量审核'),
        'edit':this.$btnIsShow('claimDepartment/supplier/insurance', '1', '编辑'),
        'del':this.$btnIsShow('claimDepartment/supplier/insurance', '1', '删除'),
        'check':this.$btnIsShow('claimDepartment/supplier/insurance', '1', '审核'),
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.hint_text {
  color: red;
}
.marginBot_10 {
  margin-bottom: 10px;
}
</style>
