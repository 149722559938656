<template>
    <div>
        <el-dialog
                title="新增导入"
                :visible="visible"
                width="580px"
                custom-class="c-custom-dialog"
                :close-on-click-modal="false"
                :destroy-on-close="true"
                :before-close="handleClose">
            <el-form :model="billForm" :rules="rules" ref="billForm" :inline="true" :label-position="'right'"
                     label-width="109px">
                <el-row>
                    <el-col :span="23">
                        <el-form-item prop="warehouseLocationCode" label="订单号">
                            <el-input type="textarea" v-model="billForm.orderNos" :rows="10" style="width: 300px"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row :gutter="24">
                    <el-col :xs="24" :sm="12" :md="12" :lg="8">
                        <el-form-item label="未收货订单">
                        </el-form-item>
                    </el-col>
                    <el-form-item>
                        <span>{{billForm.receive}}</span>
                    </el-form-item>
                </el-row>
                <el-row>
                    <el-col :xs="24" :sm="12" :md="12" :lg="8">
                        <el-form-item label="已有出库记录">
                        </el-form-item>
                    </el-col>
                    <el-form-item>
                        <span>{{billForm.out}}</span>
                    </el-form-item>
                </el-row>

                <el-row>
                    <el-col :xs="24" :sm="12" :md="12" :lg="8">
                        <el-form-item label="出库类型">
                        </el-form-item>
                    </el-col>
                    <el-form-item>
<!--                        <f-select-->
<!--                            v-model="billForm.type"-->
<!--                            :is-need="billForm.type"-->
<!--                            :dict="'warehouseOutType'"-->
<!--                            placeholder="请选择"-->
<!--                        >-->
<!--                        </f-select>-->
                        <el-select v-model="billForm.type">
                            <el-option value="0" label="正常出库"></el-option>
                            <el-option value="1" label="退仓"></el-option>
                            <el-option value="2" label="转仓"></el-option>
                        </el-select>
                    </el-form-item>
                </el-row>
            </el-form>

            <span slot="footer">
            <el-button type="primary" size="medium" @click="handleSubmit" :disabled="ifDis">确定</el-button>
        </span>

        </el-dialog>

    </div>
</template>

<script>
    import {getAction, postAction, httpAction} from '@/api/manage'
    import moment from 'moment'

    export default {
        name: 'importBatchOutDialog',

        data() {
            return {
                // id:'',
                flag: false,
                visible: false,
                isChecked: true,
                ifDis: false,
                allKeys: [],
                treeData: [],
                size: 'small',
                unusualInfoVisible: false,
                defaultProps: {
                    children: 'children',
                    label: 'title'
                },
                lang: [
                    {required: true, message: '请选择账单语言', trigger: 'change'},
                ],
                billForm: {
                    orderNos:'',
                    receive:'',
                    out:'',
                    type:'',
                },
                url: {
                    add: '/interface/saodaniu/addOutBatch',
                    edit: '/interface/warehouse/updateFbaWarehouseOrderOutById',
                },
                rules: {
                    orderNos: [
                        {required: true, message: '请输入库位编码', trigger: 'blur'},
                    ],

                },

                settleIds: [],

            }
        },
        methods: {
            handleChange(val) {
                this.$refs["billForm"].clearValidate();//重置校验
                if (val == '1') {
                    this.unusualInfoVisible = false;
                } else {
                    this.unusualInfoVisible = true;
                }


            },
            // 提交表单
            handleSubmit() {
                // if(true){
                //     console.log(this.billForm.type)
                //     return;
                // }

                let orderNosTemp = this.billForm.orderNos.trim();
                if (orderNosTemp === ""){
                    this.$message.warning("请输入单号")
                    return
                }
                let url = this.url.add;
                let method = "POST";
                console.log("billForm", this.billForm);
                if (this.billForm.id) {
                    url = this.url.edit;
                    method = "PUT";
                }
                this.$refs.billForm.validate((valid) => {
                    if (valid) {

                        console.log('上传获取数据', this.billForm)
                        httpAction(url, method, {'orderNos':this.billForm.orderNos, type:this.billForm.type}).then((res) => {
                            console.log(res)
                            if(res.code==='200'){
                                this.$message.success(res.message);
                                this.billForm.receive=''
                                this.billForm.out=''
                                this.handleClose();
                                this.$emit('ok');
                            }else{
                                this.billForm.receive=res.result.receiveBuffter
                                this.billForm.out=res.result.outBuffter
                                this.$message.warning(res.message);
                            }
                        });
                    }
                });
            },
            handleClose() {
                this.$refs.billForm.resetFields();
                this.visible = false
            },
            enabled() {
                setTimeout(() => {
                    this.ifDis = false;
                }, 2000)
            },
            edit() {
                this.billForm.orderNos = ''
                this.billForm.receive = '';
                this.billForm.out = '';
                this.visible = true
            },
        }
    }
</script>

<style lang="scss" scoped>
    .btn-wrap {
        text-align: right;
        padding: 10px 30px;
    }

    .content {
        height: 620px;
        padding: 6px 0;
        overflow: auto;
    }

</style>
