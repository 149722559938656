<template>
    <el-dialog
            :title="row.id ? '编辑推广渠道' : '新增推广渠道'"
            :visible="visible"
            width="560px"
            custom-class="c-custom-dialog"
            :close-on-click-modal="false"
            :before-close="handleClose"
    >
        <el-form
                :model="addForm"
                :rules="rules"
                ref="addForm"
                label-width="80px"
                size="medium"
        >
            <el-row>
                <el-col :span="24">
                    <el-form-item prop="itemText" label="文字">
                        <el-input v-model="addForm.itemText"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item prop="description" label="描述">
                        <el-input v-model="addForm.description" type="textarea" rows="2"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item prop="sortOrder" label="排序">
                        <el-input v-model="addForm.sortOrder"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item label="启用">
                        <el-switch v-model="addForm.status" :active-value="1" :inactive-value="0"></el-switch>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <span slot="footer">
      <el-button size="medium" @click="handleClose">取消</el-button>
      <el-button type="primary" size="medium" @click="handleSubmit"
      >确定</el-button
      >
    </span>
    </el-dialog>
</template>

<script>
    import {httpAction} from "@/api/manage";
    import {getallcountries, getcityList, getstateList} from "@/api/manage";
    import { intValidator } from '@/utils/validator'


    export default {
        name: "EditSuccessClearedCaseDialog",

        props: {
            visible: {
                type: Boolean,
            },
            row: {
                type: Object,
                default: () => {
                },
            },
        },
        data() {
            return {
                formLabelWidth: "100px",
                addForm: {},
                countrylist: [],
                citylist: [],
                statelist: [],
                recMap: {},
                rules: {
                    itemText: [
                        { required: true, message: '请输入推广渠道名称', trigger: 'change' },
                    ],
                    sortOrder: [
                        { validator: intValidator, trigger: 'change' }
                    ]
                },
                url: {
                    add: "/sys/bdCusSource/add",
                    edit: "/sys/bdCusSource/edit",
                },
                isWarehouse: null,
            };
        },
        watch: {
            visible(val) {
                if (val && this.row.id) {
                    const {
                        id,
                        itemText,
                        description,
                        sortOrder,
                        status,

                    } = this.row;
                    this.addForm = {
                        id,
                        itemText,
                        description,
                        sortOrder,
                        status,
                    };
                } else {
                    this.addForm = {};
                }
            },
        },
        created() {
        },
        methods: {
            initCountryList() {
                if (this.countrylist.length <= 0) {
                    this.countrylist = [];
                    getallcountries().then(res => {
                        if (res.success) {
                            for (var i = 0; i < res.result.length; i++) {
                                let obj = {};
                                obj.value = res.result[i].cnName;
                                obj.label = res.result[i].twoCode + "-" + res.result[i].cnName;
                                this.countrylist.push(obj);
                            }
                        }
                    }).catch(err => {
                        this.$message.error(err)
                    })
                }

            },
            handleSubmit() {
                let url = this.url.add;
                let method = "POST";
                if (this.row.id) {
                    url = this.url.edit;
                    method = "PUT";
                }
                const params = JSON.parse(JSON.stringify(this.addForm));
                this.$refs.addForm.validate((valid) => {
                    if (valid) {
                        httpAction(url, method, params).then((res) => {
                            this.$message({
                                type: "success",
                                message: res.message,
                            });
                            this.handleClose();
                            this.$emit("ok");
                        });
                    }
                });
            },
            showItemForm(e) {
                if (e === "仓库代码" && this.addForm.shipmentZip) {
                    this.isWarehouse = true;
                } else if (e === "FbaCode" && this.addForm.shipmentCode) {
                    this.isWarehouse = false;
                } else {
                    this.isWarehouse = null;
                }
            },
            handleClose() {
                this.isWarehouse = null;
                this.$emit("update:visible", false);
                this.$refs.addForm.resetFields();
            },
            changetChannelId(e) {
                if (e && e.itemText) {
                    this.addForm.channelName = e.itemText;
                } else {
                    this.addForm.channelName = "";
                }
            },
        },
    };
</script>

<style lang="scss" scoped></style>
