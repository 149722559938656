<template>
  <el-dialog
          :title="'基础按钮页面 -------------- ' + path"
          :visible="visible"
          width="60%"
          custom-class="c-custom-dialog"
          :close-on-click-modal="false"
          append-to-body
          :before-close="handleClose">
    <el-card class="box-card last_card">
      <div class="f-p-b-15">
        <el-button type="primary" size="medium" icon="el-icon-plus" @click="handleAdd"
        >新增</el-button>
        <el-button type="primary" size="medium" icon="el-icon-plus" @click="handleDelBatch"
                   :disabled="selectedRowKeys.length ===0"
        >批量删除</el-button>
      </div>
      <f-table
              v-loading="loading"
              ref="GTable"
              border
              size="medium"
              row-key="id"
              class="c-th-has-bgcolor"
              :columns="columns"
              :data="tableData"
              :cell-style="{ 'text-align': 'center' }"
              @selection-change="handleSelectionChange"
              @sort-change="handleTableChange">
        <template v-slot="scope">
          <template v-if="scope.column.property === 'action'">
            <el-button type="text" @click="handleEdit(scope.row)"
            >编辑</el-button>
            <el-button type="text" @click="handleDel(scope.row)"
            >删除</el-button>
          </template>
          <template v-else-if="scope.column.property === 'vueId'">
            <span>{{parseDict(scope.row.vueId,'sys_vue')}}</span>
          </template>
          <template v-else-if="scope.column.property === 'isTable'">
            <span>{{parseDict(scope.row.isTable,'is_default')}}</span>
          </template>
          <template v-else-if="scope.column.property === 'isShow'">
            <span>{{parseDict(scope.row.isShow,'is_show')}}</span>
          </template>
          <template v-else-if="scope.column.property === 'isDisabled'">
            <span>{{parseDict(scope.row.isDisabled,'is_disabled')}}</span>
          </template>
          <template v-else-if="scope.column.property === 'roleIds'">
            <el-tag size="medium">{{ parseDict(scope.row.roleIds,'sys_role_id') }}</el-tag>
          </template>
          <template v-else-if="scope.column.property === 'userIds'">
            <el-tag size="medium">{{ parseDict(scope.row.userIds,'sys_user_id') }}</el-tag>
          </template>
        </template>
      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination
                v-bind="ipagination"
                layout="total, sizes, prev, pager, next, jumper"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange">
        </el-pagination>
      </el-row>
    </el-card>
    <el-dialog :title="'新增按钮页面'" :visible.sync="dialogFormVisible" :close-on-click-modal="false" append-to-body
               v-if="dialogFormVisible">
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item label="所属页面" :label-width="formLabelWidth" prop="vueId">
          <f-select v-model="vueId" :dict="'sys_vue'" :is-need="vueId" :father-val="vueId" disabled ></f-select>

        </el-form-item>
        <el-form-item label="名字" :label-width="formLabelWidth" prop="name">
          <el-input v-model="form.name" autocomplete="off" type="textarea" autosize ></el-input>
        </el-form-item>

        <el-form-item prop="isTable" label="是否在表格里" :label-width="formLabelWidth">
          <el-radio-group v-model="form.isTable">
            <el-radio :label="'0'">否</el-radio>
            <el-radio :label="'1'">是</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item prop="isShow" label="显示/隐藏" :label-width="formLabelWidth">
          <el-radio-group v-model="form.isShow">
            <el-radio :label="'0'">隐藏</el-radio>
            <el-radio :label="'1'">显示</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item prop="isDisabled" label="启用/禁用" :label-width="formLabelWidth">
          <el-radio-group v-model="form.isDisabled">
            <el-radio :label="'0'">禁用</el-radio>
            <el-radio :label="'1'">启用</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item prop="roleIds" label="角色" :label-width="formLabelWidth">
          <el-select clearable filterable multiple v-model="form.roleIds" @click.native="getSysRoleList" @change='changeSelect'>
            <el-checkbox v-model="checked" @change='selectAll'>全选</el-checkbox>
            <el-option v-for="(item,index) in roleList" :value="item.value" :label="item.text" :key="index" ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="roleIds" label="用户" :label-width="formLabelWidth">
          <el-select clearable filterable multiple v-model="form.userIds" @click.native="getSysUserList">
            <el-option v-for="(item,index) in userList" :value="item.value" :label="item.text" :key="index"></el-option>
          </el-select>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="medium" @click="dialogFormVisible = false">取 消</el-button>
        <el-button size="medium" type="primary" @click="saveFile">确 定</el-button>
      </div>
    </el-dialog>
  </el-dialog>
</template>

<script>
  import {deleteAction, getAction, httpAction, postAction} from "../../../api/manage";
  import QueryMixins from "../../../mixins/QueryMixins";
  import Tempcclate from "../../isystem/Dict";

  export default {
  name: 'FileModule',
  mixins: [QueryMixins],
  components: {
    Tempcclate

  },
    props:{
    vueId:{
      type:String,
    },
      path:{
        type:String,
      },
      visible:{
      type:Boolean,
        default:false
      },
    },
  data() {
    return {
      formLabelWidth:'110px',
      fsee:false,
      dictCodes:['sys_vue','is_show','is_disabled','sys_role_id','is_default','sys_user_id'],
      ids: [],
      dialogFormVisible:false,
      form:{
        url:'',
        size:'',
        fileType:'',
        type:'',
        id:'',
        cusId:'',
        name:'',
        ossId:'',
        roleIds:[]
      },
      btnDisable:false,
      //表单校验
      rules: {
        name: [
          {required: true, message: '请输入按钮名', trigger: 'blur'},
          {max: 50, message: '长度不能超过50', trigger: 'change'},
        ],
        vueId: [
          {required: true, message: '请选择所属页面', trigger: 'change'}
        ],
        isTable: [
          {required: true, message: '请选择是否在表格里', trigger: 'change'}
        ],
        isDisabled: [
          {required: true, message: '请选择是否禁用', trigger: 'change'}
        ],
        isShow: [
          {required: true, message: '请选择显示/隐藏', trigger: 'change'}
        ]
      },
      selectedArray: [],
      fileList:[],
      row: {},
      checked: false,
      assignVisible: false,
      msgVisible: false,
      emailVisible: false,
      followTitle: '',
      assignTitle: '',
      columns: [
        {
          type: 'selection',
          width: 50
        },

        {
          prop: 'name',
          label: '按钮名字',
          sortable: 'custom',

        },
        {
          prop: 'vueId',
          label: '页面名字',
          sortable: 'custom',

        },
        {
          prop: 'isTable',
          label: '是否在表格里',
          sortable: 'custom',

        },
        {
          prop: 'isShow',
          label: '显示/隐藏',
          sortable: 'custom',
        },
        {
          prop: 'isDisabled',
          label: '隐藏/禁用',
          sortable: 'custom',
        },
        {
          prop: 'roleIds',
          label: '角色',
          sortable: 'custom',

        },
        {
          prop: 'userIds',
          label: '用户',
          sortable: 'custom',

        },
        {
          prop: 'createBy',
          label: '创建人',
          sortable: 'custom',

        },
        {
          prop: 'createTime',
          label: '创建时间',
          sortable: 'custom',

        },
        {
          prop: 'action',
          label: '操作',

        },

      ],
      url: {
        list: '/sys/button/auth/btnList',
        add: '/sys/button/auth/addBtn',
        edit: '/sys/button/auth/updateBtn',
        getBtnById:'/sys/button/auth/getBtnById',
        getSysRoles:'/sys/dict/getDictItems/sys_role_id',
        getSysUsers:'/sys/dict/getDictItems/sys_user_id',
        del:'/sys/button/auth/delBtn',
        delBatch:'/sys/button/auth/delBtnBatch',

      },
      roleList:[],
      userList:[],
      nameList:[],



    }
  },
  created() {
    this.queryForm.vueId = this.vueId;

    this.getSysRoleList();
    },
  mounted() {

  },
    watch:{

    },
  methods: {
    handleClose() {
      this.form.relationPeople = [];
      // this.$refs.tree.setCheckedNodes([]);
      if (this.$refs['form']){
        this.$refs['form'].clearValidate();
      }

      this.$emit("ok");
    },
   async getSysRoleList(){
     await getAction(this.url.getSysRoles)
      .then(res=>{
        this.roleList = res.result;
      })
    },
   async getSysUserList(){
    await  getAction(this.url.getSysUsers)
              .then(res=>{
                this.userList = res.result;
              })
    },
    saveFile: function () {
      this.btnDisable = true;
      // console.log("文件对象", this.fileList)
      this.form.vueId = this.vueId;
      //文件的删除和新增统一放到F-upload，这边传递表单对象过去
      this.$refs['form'].validate((valid) => {
        if (valid) {
          let method = "post";
          let url = this.url.add;
            // this.form.fileName = this.fileList[0].fileName;
            //讲文件中的fileType he name赋值
            if (this.form.id) {
              url = this.url.edit;
              method = "put";
            }

            httpAction(url, method, this.form)
                    .then(res => {
                      if (res.success) {
                        this.dialogFormVisible = false;
                        this.$message.success(res.message);

                        this.handleQuery();
                      } else {
                        this.btnDisable = false;
                      }
                    }).catch(err => {
              this.btnDisable = false;
            })




        } else {
          this.btnDisable = false;
        }
      })


    },
    handleReset(){
      this.queryForm = {};
      this.queryForm.vueId = this.vueId;
      this.ipagination.currentPage = 1
      this.handleQuery()
    },



    handleAdd(){
      this.fsee = false;
      this.form = {};
      // this.form.roleIds= [];
      this.$set(this.form,'roleIds',['f6817f48af4fb3af11b9e8bf182f618b']);
      this.$set(this.form,'isTable','0');
      this.$set(this.form,'isShow','1');
      this.$set(this.form,'isDisabled','1');
      this.fsee = true;
      this.fileList = [];
      this.btnDisable = false;
      // console.log("儿子",this.$refs.child);

      // this.$refs.filemodule.init();
    this.dialogFormVisible = true;
    },
    handleEdit({id}) {
    this.getSysUserList();
    this.getSysRoleList();
      this.btnDisable = false;
      getAction(this.url.getBtnById,{id:id})
      .then(res=>{
        for (var name in res.result){
          this.$set(this.form,name,res.result[name]);
        }
      })
      this.dialogFormVisible = true;
    },
    handleDel(row) {
      this.$confirm('确定删除该按钮:' + row.name + ',该操作会同时删除该按钮的权限', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error',
        closeOnClickModal: false,
      }).then(() => {
        deleteAction(this.url.del, {id: row.id}).then(res => {
          this.$message({
            type: 'success',
            message: res.message
          })

          this.handleSearch()
        })
      }).catch(err=>{
        this.$message.error(err.message);
      })
    },
    handleDelBatch(){
      this.$confirm('确定批量删除：'+this.nameList.join(',') + '这些按钮吗,该操作会同时删除按钮的权限', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error',
        closeOnClickModal: false,
      }).then(() => {
        deleteAction(this.url.delBatch, {ids: this.selectedRowKeys.join(',')}).then(res => {
          this.$message({
            type: 'success',
            message: res.message
          })

          this.handleSearch()
        })
      }).catch(err=>{
        this.$message.error(err.message);
      })
    },




    handleSelectionChange(selection) { // 多选
      let arr = []
      this.emailList = [];
      this.nameList = [];
      for (let i = 0; i < selection.length; i++) {
        arr.push(selection[i]['id'])
        this.emailList.push(selection[i]['email']);
        this.nameList.push(selection[i]['name']);
      }
      this.selectionRows = selection
      this.selectedRowKeys = [...arr]
    },
    handleBatDel() {
      this.$confirm('确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error',
        closeOnClickModal: false,
      }).then(() => {
        deleteAction(this.url.deleteBatch, {ids: this.selectedRowKeys.join(',')}).then(res => {
            this.$message({
                type: 'success',
                message: res.message
            })

            this.handleSearch()
        })
      }).catch(err=>{
        this.$message.error(err.message);
      })
    },

    changeSelect(val) {
      if (val.length === this.roleList.length) {
        this.checked = true
      } else {
        this.checked = false
      }
    },
    selectAll() {
      this.selectedArray = []
      if (this.checked) {
        this.roleList.map((item) => {
          this.selectedArray.push(item.value)
          this.form.roleIds=this.selectedArray
        })
        console.log(this.selectedArray)
      } else {
        this.selectedArray = []
      }
    },
  },

}
</script>

<style lang="scss" scoped>
.el-checkbox {
  text-align: right;
  width: 100%;
  padding-right: 10px;
}
</style>
