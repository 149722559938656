
<template>
    <div class="main-full-content">
        <el-form class="input_top" ref="form" :model="queryForm" label-width="120px" size="medium">
            <el-card class="box-card">
                <el-row>
                <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <el-form-item label="名称">
                        <el-input v-model="queryForm.name" @keyup.enter.native="handleSearch"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <el-form-item label="国家">
                        <el-input v-model="queryForm.country" @keyup.enter.native="handleSearch"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <el-form-item label-width="30px">
                        <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
                        <el-button plain @click="handleReset">重置</el-button>
                    </el-form-item>
                </el-col>
            </el-row>
            </el-card>

        </el-form>
        <el-card class="box-card">
             <div class="f-p-b-15">
                <el-button type="primary" size="medium" icon="el-icon-plus" @click="handleAdd"

                           v-if="buttonShowList.add"

                >新增</el-button>
            </div>
            <f-table
                v-loading="loading"
                ref="GTable"
                border
                size="medium"
                row-key="id"
                class="c-th-has-bgcolor"
                :columns="columns"
                :data="tableData"
                :cell-style="{ 'text-align': 'center' }"
                tableHeight="66vh"
                @selection-change="handleSelectionChange"
                @sort-change="handleTableChange">
            <template v-slot="scope">
                <template v-if="scope.column.property === 'action'">
                    <el-button type="text" @click="handleEdit(scope.row)"

                               v-if="buttonShowList.bj"
                    >编辑</el-button>
                    <el-button type="text" @click="handleDele(scope.row)"

                               v-if="buttonShowList.sc"
                    >删除</el-button>
                </template>
                <template v-else-if="scope.column.property === 'nature'">
                    <span v-if="scope.row.nature === '1'">个人</span>
                    <span v-else>企业</span>
                </template>
                <template v-else-if="scope.column.property === 'isDisabled'">
                    <span v-if="scope.row.isDisabled === '1'">启用</span>
                    <span v-else>禁用</span>
                </template>
            </template>
        </f-table>
        <el-row class="f-text-right f-p-v-8">
            <el-pagination
                    v-bind="ipagination"
                    layout="total, sizes, prev, pager, next, jumper"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange">
            </el-pagination>
        </el-row>
        </el-card>
        <el-dialog :title="form.id?'编辑进口商':'新增进口商'" :visible.sync="dialogFormVisible" :close-on-click-modal="false">
            <el-form :model="form" :rules="rules" ref="form">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="进口商名称" :label-width="formLabelWidth" prop="name">
                            <el-input v-model="form.name" autocomplete="off"></el-input>
                        </el-form-item>

                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="VAT" :label-width="formLabelWidth" prop="vat">
                            <el-input v-model="form.vat" autocomplete="off"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="进口商备注" :label-width="formLabelWidth" prop="importedArea">
                            <el-input v-model="form.importerRemark"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="税号" :label-width="formLabelWidth" prop="tax">
                            <el-input v-model="form.tax" autocomplete="off"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
               <el-row>
                   <el-col :span="12">
                       <el-form-item label="eori-欧洲线" :label-width="formLabelWidth" prop="eori">
                           <el-input v-model="form.eori" autocomplete="off"></el-input>
                       </el-form-item>
                   </el-col>

                   <el-col :span="12">
                       <el-form-item label="国家" :label-width="formLabelWidth" prop="country">
                           <el-select

                                   clearable
                                   filterable
                                   class="f-full-width"
                                   v-model="form.country"
                                   @change="initStateList"
                                   placeholder="获取国家基础数据"
                                   @click.native="initCountryList">
                               <el-option
                                       v-for="(item,index) in countrylist"
                                       :key="index"
                                       :label="item.label"
                                       :value="item.value"
                               >
                               </el-option>
                           </el-select>
                       </el-form-item>
                   </el-col>
               </el-row>
              <el-row>
                  <el-col :span="12">
                      <el-form-item label="详细地址" :label-width="formLabelWidth" prop="address">
                          <el-input v-model="form.address" autocomplete="off"></el-input>
                      </el-form-item>
                  </el-col>



                  <el-col :span="12">
                      <el-form-item label="联系人" :label-width="formLabelWidth" prop="contact">
                          <el-input v-model="form.contact" autocomplete="off"></el-input>
                      </el-form-item>
                  </el-col>
              </el-row>
              <el-row>
                  <el-col :span="12">
                      <el-form-item label="联系电话" :label-width="formLabelWidth" prop="tel">
                          <el-input v-model="form.tel" autocomplete="off"></el-input>
                      </el-form-item>
                  </el-col>

                  <el-col :span="12">
                      <el-form-item label="有效起始时间" :label-width="formLabelWidth" prop="startTime">
                          <el-date-picker
                                  class="f-flex-1"
                                  v-model="form.startTime"
                                  type="datetime"
                                  size="small"
                                  placeholder="有效起始时间"
                                  default-time="23:59:59"
                                  value-format="yyyy-MM-dd HH:mm:ss"
                                  format="yyyy-MM-dd HH:mm:ss"
                          >
                          </el-date-picker>
                      </el-form-item>
                  </el-col>
              </el-row>
             <el-row>
                 <el-col :span="12">
                     <el-form-item label="排序" :label-width="formLabelWidth" prop="sortNum">
                         <el-input v-model="form.sortNum" autocomplete="off"></el-input>
                     </el-form-item>
                 </el-col>
                 <el-col :span="12">
                     <el-form-item label="终止有效时间" :label-width="formLabelWidth" prop="endTime">
                         <el-date-picker
                                 class="f-flex-1"
                                 v-model="form.endTime"
                                 type="datetime"
                                 size="small"
                                 placeholder="终止有效时间"
                                 default-time="23:59:59"
                                 value-format="yyyy-MM-dd HH:mm:ss"
                                 format="yyyy-MM-dd HH:mm:ss"
                         >
                         </el-date-picker>
                     </el-form-item>
                 </el-col>
             </el-row>
                <el-col :span="12">
                    <el-form-item label="性质" :label-width="formLabelWidth" prop="nature">
                        <el-radio-group v-model="form.nature">
                            <el-radio :label='"1"'>个人</el-radio>
                            <el-radio :label='"2"'>企业</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="是否禁用" :label-width="formLabelWidth" prop="isDisabled">
                        <el-radio-group v-model="form.isDisabled">
                            <el-radio :label='"1"'>启用</el-radio>
                            <el-radio :label='"0"'>禁用</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="类型" :label-width="formLabelWidth" prop="type">
                        <el-radio-group v-model="form.type">
                            <el-radio :label='"1"'>系统进口商</el-radio>
                            <el-radio :label='"2"'>医疗进口商</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="门牌号"  :label-width="formLabelWidth">
          <el-input v-model="form.houseNo" autocomplete="off"></el-input>
        </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="城市"  :label-width="formLabelWidth">
          <el-input v-model="form.city" autocomplete="off"></el-input>
        </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="邮编"  :label-width="formLabelWidth">
          <el-input v-model="form.zipcode" autocomplete="off"></el-input>
        </el-form-item>
                </el-col>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="medium" @click="dialogFormVisible = false">取 消</el-button>
                <el-button size="medium" type="primary" @click="handleSave">确 定</el-button>
            </div>
        </el-dialog>

    </div>
</template>

<script>
    import {deleteAction, postAction, putAction,getcityList,getstateList,getallcountries} from '@/api/manage'
    import QueryMixins from "../../../mixins/QueryMixins";
    import {mobilePattern, zhengshuPattern} from '@/utils/pattern'

    export default {
        name: 'Importer',
        mixins: [QueryMixins],
        data() {
            return {
                rules: {
                    name: [
                        {required: true, message: '请输入进口商名称', trigger: 'change'},
                        {max: 255, message: '长度不能超过255', trigger: 'change'},

                    ],
                    vat: [
                   /*     {required: true, message: '请输入VAT', trigger: 'change'},*/
                        {max: 50, message: '长度不能超过50', trigger: 'change'},
                    ],
                    tax: [
                      /*  {required: true, message: '请输入税号', trigger: 'change'},*/
                        {max: 50, message: '长度不能超过50', trigger: 'change'},
                    ],
                    eori: [
                     /*   {required: true, message: '请输入欧洲线', trigger: 'change'},*/
                        {max: 50, message: '长度不能超过50', trigger: 'change'},
                    ],
                    country: [
                        {required: true, message: '请选择选择国家', trigger: 'change'},
                    ],
            /*        continent: [
                        {required: true, message: '请选择州/省', trigger: 'change'},
                    ],
                    city: [
                        {required: true, message: '请选择城市', trigger: 'change'},
                    ],*/
                    address: [
                        {required: true, message: '请输入地址', trigger: 'change'},
                        {max: 255, message: '长度不能超过255', trigger: 'change'},
                    ],
                    contact: [
                      /*  {required: true, message: '请输入联系人', trigger: 'change'},*/
                        {max: 50, message: '长度不能超过50', trigger: 'change'},
                    ],
                    tel: [
                    /*    {required: true, message: '请输入联系电话', trigger: 'change'},*/
                        // {pattern: mobilePattern, message: '手机号格式有误', trigger: 'change'},
                    ],
                    startTime: [
                        {required: true, message: '请选择时间', trigger: 'change'},
                    ],
                    endTime: [
                        {required: true, message: '请选择时间', trigger: 'change'},
                    ],
                    nature: [
                        {required: true, message: '请选择性质', trigger: 'change'},
                    ],
                    isDisabled: [
                        {required: true, message: '请选择是否禁用', trigger: 'change'},
                    ],
                  type: [
                    {required: true, message: '请选择类型', trigger: 'change'},
                  ],
                  sortNum: [
                    {pattern: zhengshuPattern, message: '请输入正整数', trigger: 'change'},
                  ],
                },
                emailList: [],
                ids: [],
                row: {},
                form: {},
                formLabelWidth: '110px',
                //控制弹框显示
                dialogFormVisible: false,
                columns: [
                    {
                        prop: 'action',
                        label: '操作',

                    },
                    {
                        prop: 'name',
                        label: '进口商名称',
                        sortable: 'custom',

                    },
                    {
                        prop: 'vat',
                        label: 'VAT',
                        sortable: 'custom',

                    },
                    {
                        prop: 'tax',
                        label: '税号',
                        sortable: 'custom',

                    },
                    {
                        prop: 'eori',
                        label: 'eori-欧洲线',
                        sortable: 'custom',

                    },
                    {
                        prop: 'country',
                        label: '国家',
                        sortable: 'custom',

                    },
                   /* {
                        prop: 'continent',
                        label: '州/省',
                        sortable: 'custom',

                    },
                    {
                        prop: 'city',
                        label: '城市',
                        sortable: 'custom',

                    },*/
                    {
                        prop: 'address',
                        label: '详细地址',
                        sortable: 'custom',
                        width: 200
                    },
                    {
                        prop: 'contact',
                        label: '联系人',
                        sortable: 'custom',

                    },
                    {
                        prop: 'tel',
                        label: '联系电话',
                        sortable: 'custom',

                    },
                    {
                        prop: 'createTime',
                        label: '创建时间',
                        sortable: 'custom',

                    },
                    {
                        prop: 'nature',
                        label: '性质',
                        sortable: 'custom',

                    },
                    {
                        prop: 'isDisabled',
                        label: '是否禁用',
                        sortable: 'custom',
                    },
                    {
                      prop: 'sortNum',
                      label: '排序',
                      sortable: 'custom',

                    },
                ],
                url: {
                    list: '/sys/importer/list',
                    add: '/sys/importer/add',
                    edit: '/sys/importer/edit',
                    getById: '/sys/importer/queryById',
                    delete: '/sys/importer/delete',
                },
                //国家基础数据
                countrylist:[],
                //城市基础数据
                citylist:[],
                //州基础数据
                statelist:[],
            }
        },
        methods: {
            handleSelectionChange(selection) { // 多选
                let arr = []
                this.emailList = [];
                for (let i = 0; i < selection.length; i++) {
                    arr.push(selection[i]['id'])
                    this.emailList.push(selection[i]['email']);
                }
                this.selectionRows = selection
                this.selectedRowKeys = [...arr]
            },
            //初始化国家基础数据
            initCountryList(){
                if (this.countrylist.length <=0){
                    getallcountries().then(res=>{
                        if (res.success){
                            for (var i=0;i<res.result.length;i++){
                                let obj = {};
                                obj.value = res.result[i].cnName;
                                obj.label = res.result[i].twoCode + "-" + res.result[i].cnName;
                                this.countrylist.push(obj);
                            }
                        }
                    })
                }

            },
            initStateList(val){
                this.statelist = [];
                this.citylist=[];
                if (val){

                    getstateList({cnName:val})
                        .then((res)=>{

                            if (res.success){
                                this.statelist =
                                    res.result.records.map((item)=>{
                                        let {cname:value,cname:label} = item;
                                        return {value,label};
                                    });
                            }
                        })
                }else {
                    // this.form.state = '';
                    // this.form.city = '';
                }

            },
            initCityList(val){

                this.citylist=[];
                if (val){
                    getcityList({cname:val})
                        .then((res)=>{
                            if (res.success){
                                this.citylist =
                                    res.result.records.map((item)=>{
                                        let {cname:value,cname:label} = item;
                                        return {value,label};
                                    });
                            }
                        })
                }

            },
            handleAdd() {
                this.form = {};
                this.dialogFormVisible = true;
            },
            handleEdit(row) {
                //将对象转为json字符串再转回对象
                //this.form = row; 复制的是指针 仍然指向这个对象row
                this.form = JSON.parse(JSON.stringify(row));
                this.dialogFormVisible = true;
            },
            handleSave() {
                this.$refs.form.validate(valid => {
                    if (valid) {
                        //编辑
                        if (this.form.id) {
                            putAction(this.url.edit, this.form)
                                .then((res) => {
                                    if (res.success) {
                                        this.$message.success(res.message);
                                        //关闭弹窗
                                        this.dialogFormVisible = false;
                                        this.handleQuery()
                                    }
                                })
                        } else {
                            postAction(this.url.add, this.form)
                                .then((res) => {
                                    if (res.success) {
                                        this.$message.success(res.message);
                                        //关闭弹窗
                                        this.dialogFormVisible = false;
                                        this.handleQuery()
                                    }
                                })
                        }
                    }
                })

            },
            handleDele(row) {
                this.$confirm(`确定删除进口商`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'success',
                    closeOnClickModal: false,
                }).then(() => {
                    deleteAction(this.url.delete, {id: row.id}).then(res => {
                        this.$message({
                            type: 'success',
                            message: res.message
                        })
                        this.handleQuery()
                    })
                }).catch(() => {

                })
            },
            handleReset() {
                this.queryForm = {};
                this.filterForm = {};
                this.ipagination.currentPage = 1
                this.handleQuery()
            }
        }, created() {
        },
      computed:{
        buttonShowList() {
          return {
            'add':this.$btnIsShow('isystem/BaseData/Importer','0','新增'),
            'bj':this.$btnIsShow('isystem/BaseData/Importer','1','编辑'),
            'sc':this.$btnIsShow('isystem/BaseData/Importer','1','删除'),
          }
        }
      }
    }
</script>

<style lang="scss" scoped>
</style>
