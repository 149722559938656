<template>

  <el-dialog
    :title="title"
    width="1000"
    :visible="visible"
    v-loading="confirmLoading"
    @ok="handleOk"
    @cancel="handleCancel"
    :before-close="handleCancel">

      <el-form :form="form">
        <!-- 主表单区域 -->
        <el-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="承运商"
          hasFeedback>
          <el-input
            v-model="address.carriers"
            disabled="disabled"
          />
        </el-form-item>
        <el-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="服务"
          hasFeedback>
          <el-input
            v-model="address.serviceName"
            disabled="disabled"
          />
        </el-form-item>
        <el-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="参考号1"
          hasFeedback>
          <el-input
            v-model="address.referenceNo1"
            disabled="disabled"
          />
        </el-form-item>
        <el-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="参考号2"
          hasFeedback>
          <el-input
            v-model="address.referenceNo2"
            disabled="disabled"
          />
        </el-form-item>
        <el-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="SKU"
          hasFeedback>
          <el-input
            v-model="address.sku"
            disabled="disabled"
          />
        </el-form-item>
        <el-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="发自"
          hasFeedback>
          <el-input
            v-model="address.sendAddress"
            disabled="disabled"
          />
        </el-form-item>
        <el-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="发到">
          <el-input
            v-model="address.sendArrive"
            disabled="disabled"
          />
        </el-form-item>
        <el-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="PAYMENT">
          <el-input
            v-model="address.payment"
            disabled="disabled"
          />
        </el-form-item>
        <el-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="FUEL">
          <el-input
            v-model="address.fuel"
            disabled="disabled"
          />
        </el-form-item>
        <el-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="TOTAL WEIGHT">
          <el-input
            v-model="address.weight"
            disabled="disabled"
          />
        </el-form-item>
        <el-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="TOTAL COMMISSION">
          <el-input
            v-model="address.totalCommission"
            disabled="disabled"
          />
        </el-form-item>
        <el-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="MISCELLANEOUS CHARGES">
          <el-input
            v-model="address.MiscellaneousCharges"
            disabled="disabled"
          />
        </el-form-item>

      </el-form>

  </el-dialog>
</template>

<script>


  import {addressEnum, checkAddress} from "../api/Ups";
  import { httpAction, getAction,postAction  } from '@/api/manage'
  import { setUpRouteUtil } from '@/utils/util';

  export default {
    name: "ShipDetail",
    components: {
    },
    data() {
      return {
        title: "操作",
        visible: false,

        orderMainModel: {
          jeecgOrderCustomerList: [{}],
          jeecgOrderTicketList: [{}]
        },
        address:[],
        columns: [
          {
            title: '跟单号',
            align: "center",
            width: 100,
            dataIndex: 'trackingNumbers',
            key: 'trackingNumbers',
          },
          {
            title: '状态',
            align: "center",
            width: 100,
            dataIndex: 'status',
            scopedSlots: {customRender: 'action'},
            // customRender: (text, record, index) => {
            //   //字典值替换通用方法
            //   if(text == '1'){
            //     return "跟踪中";
            //   }else {
            //     return "已取消";
            //   }
            //
            // },
          }
        ],

        innerData: [],
        labelCol: {
          xs: {span: 24},
          sm: {span: 5},
        },
        wrapperCol: {
          xs: {span: 24},
          sm: {span: 16},
        },
        confirmLoading: false,
        form: {},
        logisticsTrackingLoading: false,
        logisticsTrackingList:new Array(),

        url: {
          list: "/interface/label/list",
          add: "/interface/label/add",
          edit: "/interface/label/edit",
          orderCustomerList: "/test/order/listOrderCustomerByMainId",
          orderTicketList: "/test/order/listOrderTicketByMainId",
          saveOrderAddress: '/interface/label/saveOrderAddress',
          selecAddressList: '/interface/label/selecAddressList',
          HasUpsShipDetail: '/interface/label/HasUpsShipDetail',
          LogisticsTracking: '/interface/label/LogisticsTracking'
        },
      }
    },
    created () {
    },
    methods: {
      intoDocpage(orderNo) {
        // 对于路由不在左侧菜单中的选中菜单处理
        setUpRouteUtil(this.$route.path, '/docpage/Edit');
        this.$router.push("/docpage/Edit?orderNo=" + orderNo);

      },
      handleChange1 (selectedValue) {
        console.log("selectedValue",selectedValue)
        //如果字典项集合有数据

        addressEnum(Object.assign({'country':selectedValue}, this.queryParam))
          .then(res => {
            if(res.success){
              this.Data = res.result.list
            }
          })
      },
      add() {
        this.edit({});
      },
      LogisticsTracking(record){
        let that = this;
        this.logisticsTrackingLoading = true
         console.log(record)

        let url = this.url.LogisticsTracking, method = 'post'
        httpAction(url, {'trackingNumbers': record.trackingNumbers}, method).then((res) => {
          console.log(res)
          if(res.success){
            this.logisticsTrackingLoading = false;
            console.log(res.trackinfo.length)
            that.logisticsTrackingList = new Array();
            for (let i = 0; i <res.trackinfo.length; i++) {
             // let json = JSON.parse(res.trackinfo[i])
              that.logisticsTrackingList.push(res.trackinfo[i].Date+"@#@"+res.trackinfo[i].StatusDescription)
              console.log(that.logisticsTrackingList)
            }
          }else {
          }
        })

      },
      leave(record){
        this.logisticsTrackingLoading = false;
        this.logisticsTrackingList = new Array();
        console.log("22")
      },
      edit(record) {
        console.log(record)
        let that  = this ;
        let url = this.url.HasUpsShipDetail, method = 'post'

        httpAction(url, method,{'shipOrder': record.shipOrder,"upsStatus":'1'}).then((res) => {
          console.log(res)
           if(res.success){
             this.visible = true;
             that.$nextTick(() => {
               that.address = res.result
               that.address.payment = record.finalPrice
               that.address.totalWeight =res.weight
               that.address.sendArrive = res.result.address1+" "+res.result.address2+" "+res.result.city+" "+ res.result.state+" "+res.result.country+" "+res.result.zip
                                             +" "+res.result.phone

               var strArray = new Array(); //定义一数组
               that.address.trackingNumbers = that.address.trackingNumbers.replace(/"/g, "");
               strArray =  that.address.trackingNumbers.substring(1,that.address.trackingNumbers.length-1).split(","); //分割
               // for (let i=0; i<strArray.length; i++)
               // {
               //   this.dataSource.push({"trackingNumbers":strArray[i],"status":res.result.upsStatus})
               //
               // }
               if( res.result.priceDetail !=null){
                let obj  = JSON.parse(res.result.priceDetail)

                 that.address.totalCommission = obj.commission_charge
               }
             });
           }
        })
      },
      close() {
        this.$emit('close');
        this.visible = false;
        // this.dataSource =[];
      },
      handleOk() {
        this.$emit('close');
        this.visible = false;
        // this.dataSource =[];
      },
      handleCancel() {
        this.close()
      }
    }
  }
</script>

<style scoped>
  .ant-btn {
    padding: 0 10px;
    margin-left: 3px;
  }

  .ant-form-item-control {
    line-height: 0px;
  }

  /** 主表单行间距 */
  .ant-form .ant-form-item {
    margin-bottom: 10px;
  }

  /** Tab页面行间距 */
  .ant-tabs-content .ant-form-item {
    margin-bottom: 0px;
  }
</style>
