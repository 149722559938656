<template>
    <div>
        <el-card class="box-card">
            <el-form class="multiline_select" ref="form" :model="queryForm" label-width="80px" size="medium">
                <el-card class="box-card">
                    <el-row  class="inputs_row">
                        <el-col :xs="24" :sm="12" :md="12" :lg="6">
                            <el-form-item label="装柜顺序">
                                <el-input  v-model="queryForm.outOrderly" placeholder="多个请输入完整顺序" type="textarea"
                                           @keyup.enter.native="handleSearch"></el-input>
                            </el-form-item>
                        </el-col>

                        <el-col :xs="24" :sm="12" :md="12" :lg="6">
                            <el-form-item label="周期数">
                                <el-input  v-model="queryForm.week" @keyup.enter.native="handleSearch" placeholder="多个请输入完整周数" type="textarea"></el-input>
                            </el-form-item>
                        </el-col>



                        <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="7">
                            <el-form-item label="装柜时间">
                                <div class="f-flex">
                                    <el-date-picker
                                            class="f-flex-1"
                                            v-model="queryForm.loadingTimeStart"
                                            type="datetime"
                                            size="small"
                                            placeholder="开始时间"
                                            default-time="00:00:00"
                                            value-format="yyyy-MM-dd HH:mm:ss"
                                            @keyup.enter.native="handleSearch"
                                    >
                                    </el-date-picker>
                                    <span class="f-p-h-2">~</span>
                                    <el-date-picker
                                            class="f-flex-1"
                                            v-model="queryForm.loadingTimeEnd"
                                            type="datetime"
                                            size="small"
                                            placeholder="结束时间"
                                            default-time="23:59:59"
                                            value-format="yyyy-MM-dd HH:mm:ss"
                                            @keyup.enter.native="handleSearch"
                                    >
                                    </el-date-picker>
                                </div>
                            </el-form-item>
                        </el-col>

                        <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="6">
                            <el-form-item label="装柜状态">
                                <f-select :dict="'warehouse_out_status'" v-model="queryForm.statusList" :isNeed="queryForm.statusList"
                                          @keyup.enter.native="handleSearch" :multiple="true" ></f-select>
                            </el-form-item>
                        </el-col>

                        <el-col  class="no_margin" :xs="24" :sm="12" :md="12" :lg="6">
                            <el-form-item label="拖车行">
                                <f-select :dict="'twoAgent_id'" v-model="queryForm.tuocheIdList" :isNeed="queryForm.tuocheIdList"
                                          @keyup.enter.native="handleSearch" :multiple="true"></f-select>
                            </el-form-item>
                        </el-col>

                        <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="6">
                            <el-form-item label="专线or拆柜">
                                <f-select :dict="'cabinet_category'" v-model="queryForm.cabinetTypeList" :isNeed="queryForm.cabinetTypeList"
                                          @keyup.enter.native="handleSearch" :multiple="true"></f-select>
                            </el-form-item>
                        </el-col>

                        <el-col :xs="24" :sm="12" :md="12" :lg="4">
                            <el-form-item label-width="30px">
                                <el-button
                                        type="primary"
                                        icon="el-icon-search"
                                        @click="handleSearch"
                                >查询
                                </el-button
                                >
                                <el-button plain @click="handleReset">重置</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-card>
            </el-form>
            <div class="bbb">

                <el-button type="primary" size="medium" @click="exportExcelPlus(url.exportExcelUrl,'常规出库数据')"
                           v-if="buttonShowList.dc">导出
                </el-button>
            </div>
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="73vh"
            >
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'action'">
                        <el-button type="text" v-if="buttonShowList.xzysc && scope.row.paiguiUrl">
                            <a :href="scope.row.paiguiUrl">下载已上传的排柜表</a>
                        </el-button>
<!--                        <el-button type="text" @click="handleDo('1',scope.row)" v-if="$btnIsShow('warehouse2.0/OtherOutWarehouse','1','下载排柜表')">下载排柜表</el-button>-->
                        <el-button type="text" @click="handleDo('2',scope.row)" v-if="buttonShowList.zgz && ['1','5'].indexOf(scope.row.status) !==-1">装柜中</el-button>
                        <el-button type="text" @click="handleDo('5',scope.row)" v-if="buttonShowList.zgwc && scope.row.status==='2'">装柜完成</el-button>
                        <el-button type="text" @click="handleDo('3',scope.row)" v-if="buttonShowList.sy && scope.row.status !=='3'">顺延</el-button>
                        <el-button type="text" @click="handleDo('4',scope.row)" v-if="buttonShowList.bz && scope.row.status !=='3'">排柜备注</el-button>
                        <el-button type="text" @click="handleDo('6',scope.row)" v-if="buttonShowList.zgz">装柜备注</el-button>
                    </template>
                    <template v-else-if="scope.column.property==='statusStr'">
                        <span v-if="Number(scope.row.postponeNum)>0">
                            {{scope.row.statusStr}} <br>
                            <span style="color: red">（已顺延）</span>
                        </span>
                        <span v-else>{{scope.row.statusStr}}</span>
                    </template>
                    <template v-else-if="scope.column.property === 'outOrderly'">
                        <a style="color: #00a0e9" @click.prevent="seeDetail(scope.row)"
                        >{{ scope.row.outOrderly }}</a>
                    </template>
                    <template v-else-if="scope.column.property === 'week'">
                        <a style="color: #00a0e9" @click.prevent="$intoOperationCabinetEditPage(scope.row.containerId)"
                        >{{ scope.row.week }}</a>
                    </template>
                    <template v-else-if="scope.column.property === 'isLimit'">
                        <span v-if="scope.row.isLimit === '0'">否</span>
                        <span v-else>是</span>
                    </template>
                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange">
                </el-pagination>
            </el-row>
        </el-card>

        <warehosue-outorder-list-module :visible.sync="seeDetailVisible" :outId="outId" @ok="handleSearch"></warehosue-outorder-list-module>
        <edit-container-loading-dialog :visible.sync="containerLoadingVisible" :row="row" @ok="handleSearch"></edit-container-loading-dialog>
        <edit-paigui-remark-dialog :visible.sync="editPaiguiRemarkVisible" :id="outId" @ok="handleSearch"></edit-paigui-remark-dialog>
        <edit-contaier-loaded-dialog :visible.sync="editContainerLoadedVisible" :id="outId" :busNo=busNo :row="row" @ok="handleSearch"></edit-contaier-loaded-dialog>
        <choose-time-module :visible.sync="nextVisible" :row="selectedRow" @ok="handleSearch"></choose-time-module>
    </div>
</template>

<script>
    import QueryMixins from "@/mixins/QueryMixins.js";
    import {putAction,getAction} from "@/api/manage";
    import warehosueOutorderListModule from "@/views/warehouse2.0/modules/warehosueOutorderListModule";
    import editContainerLoadingDialog from "@/views/warehouse2.0/modules/editContainerLoadingDialog";
    import editPaiguiRemarkDialog from "@/views/warehouse2.0/modules/editPaiguiRemarkDialog";
    import editContaierLoadedDialog from "@/views/warehouse2.0/modules/editContaierLoadedDialog";
    import chooseTimeModule from "@/views/warehouse2.0/modules/chooseTimeModule";
    export default {
        name: "NormalOutWarehouse",
        mixins: [QueryMixins],
        components: {
            warehosueOutorderListModule,
            editContainerLoadingDialog,
            editPaiguiRemarkDialog,
            editContaierLoadedDialog,
            chooseTimeModule
        },
        data() {
            return {
                initData:{
                    type:'1'
                },
                isUsePost:true,
                formLabelWidth: "120px",
                row:{},
                //查看详情
                seeDetailVisible:false,
                //装柜中
                containerLoadingVisible:false,
                //排柜备注
                editPaiguiRemarkVisible:false,
                //装柜完成
                editContainerLoadedVisible:false,

                outId:'',
                busNo:'',
                //维护 派送费 价格模板计算 （费用名称，最小值，最大值，单位，fbacode，成本，（美元转cny汇率），基数，公式展示（(成本*汇率)/基数），计算出来的单价），结算对象
                columns: [
                    {
                        type: "selection",
                    },
                    // {
                    //     prop: "action",
                    //     label: "操作",
                    // },
                    {
                        prop: "outOrderly",
                        label: "装柜顺序",
                        width:130,
                    },
                    // {
                    //     prop: "isLimit",
                    //     label: "是否受限制",
                    // },
                    // {
                    //     prop: "lclPlaceName",
                    //     label: "拼柜仓库",
                    // },
                    {
                        prop: "week",
                        label: "周期数",
                    },
                    {
                        prop: "bookingNo",
                        label: "订舱号",
                    },
                    {
                        prop: "containerNo",
                        label: "柜号",
                    },
                    {
                        prop: "cabinetTypeStr",
                        label: "专线or拆柜",
                    },
                    {
                        prop: "tuoche",
                        label: "拖车行",
                    },
                    {
                        prop: "weight",
                        label: "货重(T)",
                    },
                    {
                        prop: "statusStr",
                        label: "装柜状态",
                    },
                    {
                        prop: "loadingTime",
                        label: "装柜时间",
                    },
                    {
                        prop: "lockerTime",
                        label: "锁柜时间",
                    },
                    {
                        prop: "paiguiRemark",
                        label: "排柜备注",
                        overFlow:true,
                    },
                    {
                        prop: "remark",
                        label: "装柜备注",
                        overFlow:true,
                    },
                    {
                        prop: "targetAddress",
                        label: "送货地址",
                        overFlow:true,
                    },
                    {
                        prop: "loadingMan",
                        label: "装柜人员",
                        overFlow:true,
                    },
                    {
                        prop: "foxMan",
                        label: "叉车人员",
                        overFlow:true,
                    },
                    {
                        prop: "createTime",
                        label: "录入时间",
                    },
                    {
                        prop: "isCanLoadText",
                        label: "是否已审核",
                    },
                    {
                        prop: "action",
                        label: "操作",
                        minWidth:100
                    },

                ],
                url: {
                    //查询列表的接口
                    list: "/warehouse/stock/queryWarehouseOutList",
                    //导出excel的接口
                    exportExcelUrl: '/file/export/excel/downloadWarehouseNormalOutExcel',


                    //判断是否可以装柜
                    checkCanLoad:'/warehouse/stock/checkCanLoad',
                    //下载排柜表
                    downloadOtherPgList:'/file/jasper/download/other/pgList',

                },
                nextVisible:false,
                selectedRow:{},

            };
        },
        methods: {
            handleDo(type,row){
                this.row = row;
                this.outId = row.id;
                this.busNo = row.outOrderly;
                if (type === '1'){
                    this.downloadExcel(this.url.downloadOtherPgList,'排柜表-' + row.outOrderly,
                        {t:'excel',busId:row.id,busNo:row.outOrderly})
                }else if (type === '2') {
                    getAction(this.url.checkCanLoad,{id:row.id})
                    .then(res=>{
                        this.containerLoadingVisible = true;
                    })

                }else if (type === '3') {
                    this.selectedRow = row;
                    this.nextVisible = true;

                }else if (type === '4') {
                    this.editPaiguiRemarkVisible = true;
                }else if (type === '5') {
                    getAction(this.url.checkCanLoad,{id:row.id,type:'2'})
                    .then(res=>{
                        this.editContainerLoadedVisible = true;
                    })

                } else if (type === '6') {
                    this.editContainerLoadedVisible = true;

                }
            },
            seeDetail({id}) {
                this.outId = id;
                this.seeDetailVisible = true;
            },
        },
      computed:{
        buttonShowList() {
          return {
            'zgz':this.$btnIsShow('warehouse2.0/NormalOutWarehouse','1','装柜中'),
            'bz':this.$btnIsShow('warehouse2.0/NormalOutWarehouse','1','排柜备注'),
            'sy':this.$btnIsShow('warehouse2.0/NormalOutWarehouse','1','顺延'),
            'zgwc':this.$btnIsShow('warehouse2.0/NormalOutWarehouse','1','装柜完成'),
            'xzysc':this.$btnIsShow('warehouse2.0/NormalOutWarehouse','1','下载已上传的排柜表'),
            'dc':this.$btnIsShow('warehouse2.0/NormalOutWarehouse','0','导出常规出库数据'),
          }
        }
      }
    };
</script>

<style lang="scss" scoped>
    .bbb {
        display: flex;
    }

    .dialog-footer {
        text-align: right !important;
    }

    .margin_right_10 {
        margin-right: 10px;
    }
</style>
