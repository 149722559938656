import {mobilePattern, numberPattern, chinesePattern, zhengshuPattern, fuzhengshuxiaoshuPartten} from './pattern'
import {getAction} from "@/api/manage";

const noZhongwenValidator = (rule, value, callback) => {
    const val = (value !== undefined && value !== null && (value + '').trim()) || ''
    if (val && val.length > 0) {
        for (var i = 0; i < val.length; i++) {
            let v = val.substr(i, 1);
            if (chinesePattern.test(v)) {
                callback(new Error('不能有中文'));
            }
        }
    }
    callback();
}

const fuzhengshuxiaoshuValidator = (rule, value, callback) => {
    const val = (value !== undefined && value !== null && (value + '').trim()) || ''
    if (val && val >= 0) {
        callback(new Error('请输入负数'));
    } else if (val && val < -10000000) {
        callback(new Error('负数整数部位不能超过7位'));
    } else if (val && (!(fuzhengshuxiaoshuPartten.test(val)))) {
        callback(new Error('请输入负数且最多三位小数'));
    }
    callback();
}

const phoneValidator = (rule, value, callback) => {
    const val = (value !== undefined && value !== null && (value + '').trim()) || ''
    if (val && !(mobilePattern.test(val))) {
        callback(new Error('请输入正确的手机号'));
    }
    callback();
}
const numberValidator = (rule, value, callback) => {
    const val = (value !== undefined && value !== null && (value + '').trim()) || ''
    if (val && !(numberPattern.test(val))) {
        callback(new Error('请输入正确的数字'));
    }
    callback();
}

const numValidator = (rule, value, callback) => {
    if (!Number.isInteger(parseFloat(value))) {
        callback(new Error('请输入数字'))
    } else {
        if (value < 0) {
            callback(new Error('不能小于0'))
        } else if (value.length >= 9) {
            callback(new Error('最多9位整数'))
        } else {
            callback();
        }
    }
}

const validateCtn2 = (rule, value, callback) => {
    if (value && value.length > 0) {
        let str = value.toString().split('.')
        if (str[0].length > 9) {
            callback(new Error('最多9位'))
        } else if (str[1] || value.toString().indexOf('.') !== -1) {
            callback(new Error('不能是小数'))
        } else {
            callback();
        }
    } else {
        callback();
    }
}
const validateQty = (rule, value, callback) => {
    if (value) {
        if (value <= 0) {
            callback(new Error('数量不能小于等于0'))
        } else {
            let str = value.toString().split('.')
            if (str[0].length > 9) {
                callback(new Error('最多9位'))
            } else if (str[1] || value.toString().indexOf('.') !== -1) {
                callback(new Error('不能是小数'))
            } else {
                callback();
            }
        }
    } else {
        callback();
    }
}
const validateCtn = (rule, value, callback) => {
    if (value) {
        if (value < 0) {
            callback(new Error('不能为负数'))
        } else {
            let str = value.toString().split('.')
            if (str[0].length > 9) {
                callback(new Error('最多9位'))
            } else if (str[1] || value.toString().indexOf('.') !== -1) {
                callback(new Error('不能是小数'))
            } else {
                callback();
            }
        }
    } else {
        callback();
    }
}
const validateInteger = (rule, value, callback)=> {
    if (!value) {
        return callback(new Error('整数值不能为空'));
    }
    if (value > 10000) {
        callback(new Error('整数值不能超过10000'));
    } else {
        callback();
    }
}

const validateCmb = (rule, value, callback) => {
    if (value) {
        if (isNaN(value)) {
            callback(new Error('必须为数字'))
        } else {
            if (value < 0) {
                callback(new Error('不能为负数'))
            } else {
                let str = value.toString().split('.')
                if (str[0].length > 7) {
                    callback(new Error('最多7位'))
                } else if (str[1] && str[1].length > 3) {
                    callback(new Error('最多3位小数'))
                } else if (str[2]) {
                    callback(new Error('小数不对'))
                } else {
                    callback();
                }
            }
        }
    } else {
        callback();
    }
}

const validateLipei = (rule, value, callback) => {
    if (value) {
        if (isNaN(value)) {
            callback(new Error('必须为数字'))
        } else {
            if (value > 0) {
                callback(new Error('必须为负数'))
            } else {
                let str = value.toString().split('.')
                if (str[0].length > 7) {
                    callback(new Error('最多7位'))
                } else if (str[1] && str[1].length > 2) {
                    callback(new Error('最多2位小数'))
                } else if (str[2]) {
                    callback(new Error('小数不对'))
                } else {
                    callback();
                }
            }
        }
    } else {
        callback();
    }
}

// notNegative是否限制可输入负数
const validateNum = (rule, value, callback, notNegative = true) => {
    if (value) {
        if (isNaN(value)) {
            callback(new Error('必须为数字'))
        } else {
            if (value < 0 && notNegative) {
                callback(new Error('不能为负数'))
            } else {
                let str = value.toString().split('.')
                if (str[0].length > 10) {
                    callback(new Error('最多10位'))
                } else if (str[1] && str[1].length > 3) {
                    callback(new Error('最多3位小数'))
                } else if (str[2]) {
                    callback(new Error('小数不对'))
                } else {
                    callback();
                }
            }
        }
    } else {
        callback();
    }
}
const validateSettlePrice = (rule, value, callback) => {
    if (value) {
        if (isNaN(value)) {
            callback(new Error('必须为数字'))
        } else {
            if (value < 0) {
                callback(new Error('不能为负数'))
            } else {
                let str = value.toString().split('.')
                if (str[0].length > 10) {
                    callback(new Error('最多10位'))
                } else if (str[1] && str[1].length > 2) {
                    callback(new Error('最多2位小数'))
                } else if (str[2]) {
                    callback(new Error('小数不对'))
                } else {
                    callback();
                }
            }
        }
    } else {
        callback();
    }
}
const validateSaleSum = (rule, value, callback) => {
    if (value) {
        if (isNaN(value)) {
            callback(new Error('必须为数字'))
        } else {
            let str = value.toString().split('.')
            if (str[0].length > 10) {
                callback(new Error('最多10位'))
            } else if (str[1] && str[1].length > 3) {
                callback(new Error('最多3位小数'))
            } else if (str[2]) {
                callback(new Error('小数不对'))
            } else {
                callback();
            }

        }
    } else {
        callback();
    }
}

// const validateUnitValue = (rule, value, callback) => {
//     if (value < -10000) {
//         callback(new Error('价格最小-10000'))
//     } else {
//         let str = value.toString().split('.')
//         if (str[0].length > 7) {
//             callback(new Error('最多7位'))
//         } else if (str[1] && str[1].length > 3) {
//             callback(new Error('最多3位小数'))
//         } else if (str[2]) {
//             callback(new Error('小数不对'))
//         } else {
//             callback();
//         }
//     }
// }

const validateUnitValue = (rule, value, callback) => {
    if (!value) {
        callback();
    }else {
        let str =  value.toString().split('.')
        if ( str[0].length > 7) {
            callback(new Error('最多7位'))
        } else if (str[1] && str[1].length > 3) {
            callback(new Error('最多3位小数'))
        } else if (str[2]) {
            callback(new Error('小数不对'))
        } else {
            callback();
        }
    }

}

const validateTrailerFee = (rule, value, callback) => {
    if (!value) {
        callback();
    }else {
        let str =  value.toString().split('.')
        if ( str[0].length > 7) {
            callback(new Error('最多7位'))
        } else if (str[1] && str[1].length > 2) {
            callback(new Error('最多2位小数'))
        } else if (str[2]) {
            callback(new Error('小数不对'))
        } else {
            callback();
        }
    }

}

const validateUnitValue4 = (rule, value, callback) => {
    if (value && value.length > 0) {
        let str = value.toString().split('.')
        if (str[0].length > 7) {
            callback(new Error('最多7位'))
        } else if (str[1] && str[1].length > 4) {
            callback(new Error('最多4位小数'))
        } else if (str[2]) {
            callback(new Error('小数不对'))
        } else {
            callback();
        }
    }else {
        callback();
    }
}

const validateOuhao = (rule, value, callback) => {
    if (value && value.length > 0) {
        if (value.indexOf(',') === -1) {
            callback(new Error('请用,分隔'))
        }else {
            callback();
        }
    }else {
        callback();
    }
}
//
const validatePrintNum = (rule, value, callback) => {
    if (value) {
        if (value < 0) {
            callback(new Error('不能为负数'))
        } else {
            let str = value.toString().split('.')
            if (str[0].length > 4) {
                callback(new Error('最多4位'))
            } else if (str[1] || value.toString().indexOf('.') !== -1) {
                callback(new Error('不能是小数'))
            } else {
                callback();
            }
        }
    } else {
        callback();
    }

}
const telValidator = async (rule, value, callback) => {
    const val = (value !== undefined && value !== null && (value + '').trim()) || ''
    let flag = null;
    let res = await getAction(`/customer/checkPhone?phone=${value}`);
    flag = res.result;
    console.log("检查手机号是否存在", flag);
    if (!flag) {
        callback(new Error('该手机号已经存在'));
    } else {
        callback();
    }
}
const emailValidator = async (rule, value, callback) => {
    const val = (value !== undefined && value !== null && (value + '').trim()) || ''
    let flag = null;
    let res = await getAction(`/customer/checkEmail?email=${value}`)
    flag = res.result;
    console.log("检查邮箱是否存在", flag);
    if (!flag) {
        callback(new Error('该邮箱已经存在'));
    } else {
        callback();
    }

}

const usernameValidator = (rule, value, callback) => {
    var reg = /^[0-9]+$/
    if (reg.test(value)) {
        callback(new Error('客户名不能是纯数字'))
    } else {
        callback();
    }

}

const lengthValidator = (rule, value, callback) => {

    if (value && Number(value) > 350) {
        callback(new Error('不能超过350cm'))
    } else {
        callback();
    }

}

const intValidator = (rule, value, callback) => {
    const val = (value !== undefined && value !== null && (value + '').trim()) || ''
    if (val && !(/^([1-9]\d*|0)$/.test(val))) {
        console.log(val)
        callback(new Error('请输入数字(整数)'));
    }
    callback();
}
const zhengshuPatternNew = (rule, value, callback) => {
    const val = (value !== undefined && value !== null && (value + '').trim()) || ''
    if (val && (!(zhengshuPattern.test(val)) || val.indexOf('.') !== -1)) {
        callback(new Error('请输入正整数'));
    }
    callback();
}
const validateShuliang = (rule, value, callback) => {
    if (value) {
        if (value < 0) {
            callback(new Error('不能是负数'))
        } else {
            let str = value.toString().split('.')
            if (str[0].length > 9) {
                callback(new Error('最多9位'))
            } else if (str[1]) {
                callback(new Error('不能是小数'))
            } else {
                callback();
            }
        }
    } else {
        callback();
    }
}

const validateLength = (rule, value, callback) => {
    let b = 0;  //初始化字节数递加变量并获取字符串参数的字符个数
    if (value) {  //如果存在字符串，则执行计划
        for (var i = 0; i < value.length; i++) {  //遍历字符串，枚举每个字符
            if (value.charCodeAt(i) > 255) {  //字符编码大于255，说明是双字节字符
                b += 2;  //则累加2个
            } else {
                b++;  //否则递加一次
            }
        }
        if (b > 230) {
            callback(new Error('最多230位英文或者115位中文'))
        } else {
            callback();
        }
    } else {
        callback();
    }
};
const validateInvoiceMoney = (rule, value, callback) => {
    if (value) {  //如果存在字符串，则执行计划
        if (Number(value) > 1500000) {
            callback(new Error('发票金额上限为1500000，请修改'))
        } else {
            callback();
        }
    } else {
        callback();
    }
};



/*p66480 不能纯数字 字符个数最小十位数*/
const validateVisitRemark = (rule, value, callback) => {
    if (value === '') {
        callback(new Error('不能为空'));
    }else if (/^\d+$/.test(value)) { // 检查是否全为数字
        callback(new Error('不能全部是数字'));
    } else if (value.length < 10) { // 检查长度是否小于10
        callback(new Error('长度至少为10个字符'));
    } else {
        callback();
    }
}


export {
    phoneValidator,
    numberValidator,
    intValidator,
    telValidator,
    emailValidator,
    usernameValidator,
    numValidator,
    validateCtn,
    validateCmb,
    validateSaleSum,
    validateNum,
    validateShuliang,
    validateUnitValue,
    validateCtn2,
    validateQty,
    lengthValidator,
    validateLength,
    validateInvoiceMoney,
    validateSettlePrice,
    noZhongwenValidator,
    validatePrintNum,
    validateUnitValue4,
    validateLipei,
    validateTrailerFee,
    zhengshuPatternNew,
    fuzhengshuxiaoshuValidator,
    validateInteger,
    validateOuhao,
    validateVisitRemark

}
