<template>
    <div class="main-full-content">
        <el-form
            class="input_top"
            ref="form"
            :model="queryForm"
            label-width="80px"
            size="medium"
        >
            <el-card class="box-card">
                <el-row class="inputs_row">
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="合并编号">
                            <el-input v-model="queryForm.mergeNo" @keyup.enter.native="handleCheck"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="费用币种">
                            <f-select
                                v-model="queryForm.payCur"
                                :is-need="queryForm.payCur"
                                dict="bd_cur"
                            ></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6">
                        <el-form-item label="费用时间">
                            <el-date-picker
                                class="f-flex-1"
                                v-model="stime"
                                type="datetimerange"
                                size="small"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                :picker-options="editStartOptions"
                                :default-time="['00:00:00', '23:59:59']"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                format="yyyy-MM-dd HH:mm:ss"
                                unlink-panels
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="3">
                        <el-form-item label-width="110px" style="width: 400px">
                            <el-button
                                type="primary"
                                icon="el-icon-search"
                                @click="handleCheck"
                            >查询
                            </el-button
                            >
                            <el-button plain @click="handleReset">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>
        </el-form>

        <el-card class="box-card last_card">
            <div class="f-p-b-15">
                <!--                        <el-button plain @click="mergeBatch">合并</el-button>-->
                <!--                        <el-popconfirm title="是否确认合并选中的付款申请？"-->
                <!--                                       confirm-button-text='确认'-->
                <!--                                       cancel-button-text='不用了'-->
                <!--                                       icon="el-icon-info"-->
                <!--                                       icon-color="red"-->
                <!--                                       @confirm="mergeBatch()">-->
                <!--                            <el-button slot="reference" plain>-->
                <!--                                合并-->
                <!--                            </el-button>-->
                <!--                        </el-popconfirm>-->
            </div>
            <f-table
                v-loading="loading"
                ref="GTable"
                border
                size="medium"
                row-key="id"
                class="c-th-has-bgcolor"
                :columns="columns"
                :data="tableData"
                :cell-style="{ 'text-align': 'center' }"
                tableHeight="69vh"
                @selection-change="handleSelectionChange"
                @sort-change="handleTableChange"
            >
                <template v-slot="scope">
                    <template v-if="scope.column.property === 'action'">
                        <el-popconfirm
                            title="是否退回至待合并？"
                            confirm-button-text="确认"
                            cancel-button-text="不用了"
                            icon="el-icon-info"
                            icon-color="red"
                            @confirm="backToWaitMerge(scope.row)"
                        >
                            <el-button type="text" slot="reference" plain
                                       v-if="buttonShowList.qxsq"
                            >
                                取消申请
                            </el-button>
                        </el-popconfirm>
                        <el-divider direction="vertical"></el-divider>
                        <el-button
                            type="text"
                            slot="reference"
                            plain
                            @click="pay(scope.row)"
                            v-if="buttonShowList.fk"
                        >
                            付款
                        </el-button>
                    </template>
                    <template v-else-if="scope.column.property === 'mergeNo'">
<!--                        <el-button type="text" @click="showMergeDetail(scope.row)">{{scope.row.mergeNo}}</el-button>-->
                        <a style="color: #00a0e9;cursor:pointer" @click.prevent="showMergeDetail(scope.row)">{{ scope.row.mergeNo }}</a>
                    </template>
                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                    v-bind="ipagination"
                    layout="total, sizes, prev, pager, next, jumper"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                >
                </el-pagination>
            </el-row>
        </el-card>

        <pay-dialog ref="payDialog" @ok="this.handleQuery"></pay-dialog>
        <merge-detail-dialog ref="mergeDetailDialog"></merge-detail-dialog>
    </div>
</template>
<script>
import QueryMixins from "../../../../mixins/QueryMixins";
import {getAction, postAction} from "@/api/manage";
import payDialog from "./payDialog";
import mergeDetailDialog from "@/views/hyFinanceManage/modules/pay/mergeDetailDialog";


export default {
    mixins: [QueryMixins],
    components: {
        payDialog,mergeDetailDialog
    },
    data() {
        return {
            editStartOptions: {
                disabledDate: (time) => {
                    if (!this.form.etime) {
                        // time = new Date(time.replace(/-/g, '/'));
                        return time.getTime() < new Date(1970 - 1 - 1).getTime(); //禁止选择1970年以前的日期
                    } else {
                        return time.getTime() > new Date(this.form.etime);
                    }
                },
            },
            editStopOptions: {
                disabledDate: (time) => {
                    // time = new Date(time.replace(/-/g, '/'));
                    return (
                        time.getTime() < new Date(this.form.stime) ||
                        time.getTime() < new Date(1970 - 1 - 1).getTime() //禁止选择1970年以前的日期
                    );
                },
            },
            selectedRowKeys: [],
            selectionRows: [],
            selectedNos: [],
            loading: false,
            form: {},
            columns: [
                {
                    type: "selection",
                    width: 50,
                },
                {
                    prop: "action",
                    label: "操作",
                    width: 145,
                },
                {
                    prop: "mergeNo",
                    label: "合并编号",
                },
                // {
                //     prop: 'invoiceNo',
                //     label: '发票号',
                // },
                // {
                //     prop: 'url',
                //     label: '账单附件',
                // },
                {
                    prop: "agent",
                    label: "代理商",
                },
                {
                    prop: "payMoney",
                    label: "应付金额",
                },
                {
                    prop: "payCur",
                    label: "应付币种",
                },
                {
                    prop: "bank",
                    label: "收款开户行",
                },
                {
                    prop: "account",
                    label: "收款账号",
                },
                {
                    prop: "accountMan",
                    label: "收款人",
                },
                {
                    prop: "creator",
                    label: "申请人",
                },
                {
                    prop: "createTime",
                    label: "申请时间",
                },
            ],

            url: {
                list: "/shipping/hySettlePay/waitPayList",
                payBatch: "/shipping/hySettlePay/payBatch",
                backToWaitMerge: "/shipping/hySettlePay/backToWaitMerge",
            },

            rules: {
                realCtn: [
                    {required: true, message: "请输入实际总件数", trigger: "blur"},
                    {pattern: /^[1-9]\d*$/, message: "只能输入整数", trigger: "blur"},
                ],
                realKg: [
                    {required: true, message: "请输入实际总重量", trigger: "blur"},
                    {
                        pattern:
                            /^(?=([1-9]{1,10}$|[0-9]{1,7}\.))(0|[1-9][0-9]*)(\.[0-9]{1,3})?$/,
                        message: "只能输入最多三位小数的数字",
                        trigger: "change",
                    },
                ],
            },
            stime: [],
            otime: [],
        };
    },
    computed: {
      buttonShowList(){
        return {
          'qxsq':this.$btnIsShow('hyFinanceManage/modules/pay/waitPay','1','取消申请'),
          'fk':this.$btnIsShow('hyFinanceManage/modules/pay/waitPay','1','付款'),
        }
      }
    },
    created() {
    },
    methods: {
        showMergeDetail(row){
            // console.log("进入合并详情")
            // console.log('申请对象->', row);
            // getAction(this.url.getSettlesByMergeNo, {mergeNo: row.mergeNo}).then((res) => {
            //     console.log(res)
            //     this.billForm.settleList = res.result.settleList;
            //     this.billForm.payMan = res.result.payMan;
            //     this.billForm.amount = res.result.amount;
            //     this.billForm.remark = res.result.remark;
            //
            //
            // })
            // this.title = '收款申请详情'
            // this.showOnly = false;
            // this.visible = true
            this.$refs.mergeDetailDialog.showDetail(row);

        },
        importBill() {
            this.$message.warning("此功能后续开放！");
        },
        backToWaitMerge(row) {
            postAction(this.url.backToWaitMerge, row).then((res) => {
                // console.log(res);
                if (res.success === true) {
                    this.$message.success("退回成功！");
                    this.handleQuery();
                }
            });
        },
        handleReset() {
            this.stime = ''
            this.queryForm = {}
            this.ipagination.currentPage = 1
            this.handleQuery()
        },
        pay(row) {
            this.$refs.payDialog.edit(row);
        },
        payBatch() {
            // console.log("申请付款以下id的费用", this.selectionRows);
            if (this.selectedRowKeys.length === 0) {
                this.$message.warning("请勾选付款申请");
                return;
            }
            let payCount = [];
            this.selectionRows.forEach((item) => {
                payCount.push(item.id);
            });

            let payArr = Array.from(new Set(payCount));
            // if(payArr.length > 1){
            //     this.$message.warning("所选费用的结算对象不一致！")
            //     return
            // }
            // let arr = this.tableData.filter(item => {return this.selectionRows.indexOf(item) === -1})
            // console.log("arr--",arr)
            // this.$refs.payApply.edit(this.selectionRows,arr,this.columns)
            //跳过审批流程，直接审批完成
            postAction(this.url.mergeBatch, payArr).then((res) => {
                // console.log(res);
                if (res.success === true) {
                    this.$message.success("合并成功");
                    this.handleQuery();
                }
            });
        },
        handleSelectionChange(selection) {
            // 多选
            // console.log(selection);
            let arr = [];
            let nos = [];
            this.nameList = [];
            this.cnamelist = [];
            this.emailList = [];
            for (let i = 0; i < selection.length; i++) {
                arr.push(selection[i]["id"]);
                nos.push(selection[i]["orderNo"]);
                this.nameList.push(selection[i]["username"]);
                this.cnamelist.push(selection[i]["cname"]);
                this.emailList.push(selection[i]["email"]);
            }
            this.selectionRows = selection;
            this.selectedRowKeys = [...arr];
            this.selectedNos = [...nos];
        },
        handleReceive() {
            // console.log("申请收款以下id的费用", this.selectedRowKeys);
        },
        handleCheck() {
            this.loading = true;

            if (this.stime && this.stime.length > 1) {
                this.queryForm.sCreateTimeS = this.stime[0];
                this.queryForm.sCreateTimeE = this.stime[1];
            }else{
                this.queryForm.sCreateTimeS = '';
                this.queryForm.sCreateTimeE = '';
            }
            let form = {...this.queryForm, ...this.filterForm};
            for (let item in form) {
                if (typeof form[item] === "string") {
                    form[item] = form[item].trim();
                    if (form[item] === "") {
                        form[item] = null;
                    }
                }
            }
            const {prop, order} = this.isorter;
            // 传入的参数
            const params = {
                ...form,
                column: prop,
                order: order.indexOf("asc") !== -1 ? "asc" : "desc",
            };
            //判断 是否要查询startTime

            if (!params.column) {
                if (this.queryForm.column === "startTime") {
                    this.$set(params, "column", "startTime");
                } else {
                    this.$set(params, "column", "createTime");
                }
                //提货中查询
                if (this.queryForm.column === "distributionTime") {
                    this.$set(params, "column", "distributionTime");
                }
            }
            if (this.ipagination) {
                params.pageNo = this.ipagination.currentPage;
                params.pageSize = this.ipagination.pageSize;
            }
            // console.log("查询对象", params);

            getAction(this.url.list, params)
                .then((res) => {
                    if (res.success) {
                        const {records, total} = res.result;
                        this.tableData = records || res.result;

                        this.ipagination &&
                        (this.ipagination.total = total || res.result.length);
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        noConvertBill() {
            // console.log("申请收款以下id的费用", this.selectedRowKeys);
            if (this.selectedRowKeys.length === 0) {
                this.$message.warning("请勾选费用");
                return;
            }

            let countCur = [];
            this.selectionRows.forEach((item) => {
                countCur.push(item.moneyType);
            });
            let curArr = Array.from(new Set(countCur));
            // console.log("curArr->", curArr);
            if (curArr.length > 1) {
                this.$message.warning("所选费用的费用币种不一致！");
                return;
            }

            let settleIdCount = [];
            let settleNameCount = [];
            this.selectionRows.forEach((item) => {
                settleIdCount.push(item.settleId);
                settleNameCount.push(item.settlement);
            });
            let idArr = Array.from(new Set(settleIdCount));
            let nameArr = Array.from(new Set(settleNameCount));
            if (idArr.length > 1) {
                this.$message.warning("所选费用的结算对象不一致！");
                return;
            }
            if (nameArr.length > 1) {
                this.$message.warning("所选费用的结算对象不一致！");
                return;
            }
            this.$refs.noConvert.edit(this.selectedRowKeys, this.selectedNos);
        },
    },
};
</script>
<style scoped>
.el-form-item {
    margin-bottom: 2px;
}
</style>
