<template>
  <div class="main-full-content">
    <el-form class="input_top" label-width="80px" size="medium" @submit.native.prevent>
      <el-card class="box-card">
        <el-row>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="手机号">
              <el-input v-model="queryForm.phone" @keyup.enter.native="handleSearch"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label-width="30px">
              <el-button
                  type="primary"
                  icon="el-icon-search"
                  @click="handleSearch"
              >查询
              </el-button
              >
              <el-button plain @click="handleReset">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
    </el-form>
    <el-card class="box-card last_card">
      <div class="f-p-b-15">
        <el-button
            type="primary"
            size="medium"
            icon="el-icon-plus"
            @click="handleEdit({})"


        >新增
        </el-button
        >
      </div>
      <f-table
          v-loading="loading"
          ref="GTable"
          border
          size="medium"
          row-key="id"
          class="c-th-has-bgcolor"
          :columns="columns"
          :data="tableData"
          :show-operation="true"
          :cell-style="{ 'text-align': 'center' }"
          tableHeight="66vh"
          @selection-change="handleSelectionChange"
          @sort-change="handleTableChange"
      >
        <template v-slot="scope">
          <template v-if="scope.column.property === 'action'">
            <el-button type="text" @click="handleEdit(scope.row)"

            >编辑
            </el-button
            >
            <el-button type="text" @click="handleDel(scope.row.id)"

            >删除
            </el-button
            >

          </template>
        </template>
      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination
            v-bind="ipagination"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        >
        </el-pagination>
      </el-row>
    </el-card>
    <el-dialog :title="'新增跨境堡账号'" :visible.sync="relVis" v-if="relVis"
               :close-on-click-modal="false">
      <el-form :model="re" ref="re" :rules="rules">
        <el-form-item prop="name" label="姓名">
          <el-input placeholder="请输入姓名" v-model="name" />
        </el-form-item>
        <el-form-item prop="phone" label="手机号">
          <el-input placeholder="请输入手机号" v-model="phone" />
        </el-form-item>
        <el-form-item prop="kjbAccount" label="跨境堡账号">
          <el-input placeholder="请输入跨境堡账号" v-model="kjbAccount" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="medium" @click="relVis = false">取 消</el-button>
        <el-button size="medium" type="primary" @click="saveRelation()" :loading="bbLoading">确 定</el-button>
      </div>
    </el-dialog>

    <Add-Kjb-Dialog
        :visible.sync="visible"
        :row="row"
        @ok="handleSearch"
    ></Add-Kjb-Dialog>





  </div>
</template>

<script>
import QueryMixins from "../../../mixins/QueryMixins";
import {deleteAction} from '@/api/manage'
import AddKjbDialog from "@/views/operation/baoxian/modules/AddKJBDialog";


export default {
  name: "BindingKJBRelation",
  mixins: [QueryMixins],
  components: {
    AddKjbDialog

  },
  data() {
    return {
      re: {},
      roleList: [],
      relVis: false,
      visible: false,
      row: {},
      options: [],
      id: "",
      authUrl: {
        list: "/sys/permission/list",
        auth: "/sys/permission/queryRolePermission",
        ok: "/sys/permission/saveRolePermission",
      },
      columns: [
        {
          prop: "action",
          label: "操作",
          width: 180,
        },
   /*     {
          type: "index",
          label: "#",
        },*/
        {
          prop: "name",
          label: "姓名",
          sortable: "custom",
        },
        {
          prop: "phone",
          label: "手机号",
          sortable: "custom",
        },
        {
          prop: "kjbAccount",
          label: "跨境堡账号",
          sortable: "custom",
        },

      ],
      url: {
        list: "/order/insurance/binding/list",
        delete: "/order/insurance/binding/delete",
        deleteBatch: '/order/insurance/binding/deleteBatch',

      },
      bbLoading: false,
      rules: {
        roleIdList: [],
      },
    };
  },
  created() {

  },
  methods: {

    handleDel(id) {
      this.$confirm("确定删除该跨境堡账号?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
        closeOnClickModal: false,
      })
          .then(() => {
            deleteAction(this.url.delete, {id}).then((res) => {
              this.$message({
                type: "success",
                message: res.message,
              });
              this.handleSearch();

            });
          })
          .catch(() => {
          });
    },
    handleEdit(row) {
      this.row = row;
      this.visible = true;
    },

  },
};
</script>

<style lang="scss" scoped>
</style>
