<template>
    <div>
      <el-form ref="form" :model="queryForm" label-width="120px" size="medium">
        <el-card class="box-card">
          <el-row style="display: flex; flex-wrap: wrap">
            <el-col :xs="24" :sm="12" :md="12" :lg="6">
              <el-form-item label="国家">
                <el-input
                    v-model="queryForm.country"
                    @keyup.enter.native="handleSearch"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="6">
              <el-form-item label="渠道">
                <f-select
                    class="textarea_table"
                    v-model="queryForm.channelId"
                    :is-need="queryForm.channelId"
                    :dict="'channel_set'"
                    @keyup.enter.native="handleSearch"
                ></f-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="6">
              <el-form-item label="航线名称">
                <f-select
                    class="textarea_table"
                    v-model="queryForm.solicitation"
                    :is-need="queryForm.solicitation"
                    :dict="'fba_solicitation'"
                    @keyup.enter.native="handleSearch"
                ></f-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="6">
              <el-form-item label="是否包税">
                <f-select
                    class="textarea_table"
                    v-model="queryForm.hasTax"
                    :is-need="queryForm.hasTax"
                    :dict="'is_default'"
                    @keyup.enter.native="handleSearch"
                ></f-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="6">
              <el-form-item label="费用名称">
                <f-select
                    class="textarea_table"
                    v-model="queryForm.feeId"
                    :is-need="queryForm.feeId"
                    :dict="'bd_fee_type'"
                    @keyup.enter.native="handleSearch"
                ></f-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="3">
              <el-form-item label-width="30px">
                <el-button
                    type="primary"
                    icon="el-icon-search"
                    @click="handleSearch"
                >查询
                </el-button
                >
                <el-button plain @click="handleReset">重置</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-card>
      </el-form>
        <el-card class="box-card">
        <div class="bbb">
            <el-button
                    type="primary"
                    size="medium"
                    icon="el-icon-plus"
                    @click="handleAdd"
                    class="margin_right_10"
            >新增
            </el-button>
            <el-upload
                    class="upload-demo margin_right_10"
                    action=""
                    :http-request="importOperation"
                    :show-file-list="false"
                    :before-upload="beforeUpload"
                    accept=".xls,.xlsx"
            >
                <el-button type="primary" size="medium"
                >导入三锁服务</el-button>
            </el-upload>
            <el-button
                    type="primary"
                    size="medium"
                    @click="downloadModel(url.download, '三锁服务模板')"

            >三锁服务模板</el-button>
          <el-button type="primary" @click="handleDelDuo" size="medium" class="c-theme-error-button"
                     :disabled="selectionRows.length === 0">批量删除</el-button>

        </div>
        <f-table
                v-loading="loading"
                ref="GTable"
                border
                size="medium"
                row-key="id"
                class="c-th-has-bgcolor"
                :columns="columns"
                :data="tableData"
                @selection-change="handleSelectionChange"
                @sort-change="handleTableChange"
                :cell-style="{ 'text-align': 'center' }"
                tableHeight="73vh"
        >
            <template v-slot="scope">
                <template v-if="scope.column.property === 'action'">
                    <el-button type="text" @click="handleEdit(scope.row)"
                    >编辑</el-button>
                    <el-button type="text" @click="handleDel(scope.row)"
                    >删除</el-button>
                </template>
              <template v-else-if="scope.column.property === 'countNum'">
                <el-popover trigger="hover" placement="top" >
                  <!-- 鼠标移动需要提示的其他字段信息（showMessage字段传递对应要展示的字段名称） : 自己的字段超出的鼠标移动展示  -->
                  <p><span>{{parseCountNum(scope.row.countNum)}}</span></p>
                  <div slot="reference" class="name-wrapper tag-center">
                    <el-tag size="medium" class="tag-ellipsis">{{ subLongStr(parseCountNum(scope.row.countNum)) }}</el-tag>
                  </div>
                </el-popover>

              </template>
              <template v-else-if="scope.column.property === 'hasTax'">
               <span v-if="scope.row.hasTax==='1'">是</span>
               <span v-if="scope.row.hasTax==='0'">否</span>
              </template>
            </template>
        </f-table>
        <el-row class="f-text-right f-p-v-8">
            <el-pagination
                    v-bind="ipagination"
                    layout="total, sizes, prev, pager, next, jumper"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
            >
            </el-pagination>
        </el-row>
        </el-card>
        <el-dialog
                :title="form.id ? '编辑绑定' : '新增绑定'"
                v-if="dialogFormVisible"
                @close="dialogFormVisible = false"
                :visible.sync="dialogFormVisible"
                width="30%"
                center
                :close-on-click-modal="false"
        >
            <el-form :model="form" :rules="rules" ref="form">
                <el-form-item
                        label="费用名称"
                        prop="feeId"
                        :label-width="formLabelWidth"
                >
                    <f-select
                            :dict="'bd_fee_type'"
                            v-model="form.feeId"
                            :is-need="form.feeId"
                    ></f-select>
                </el-form-item>
                <el-form-item
                        label="渠道名称"
                        prop="channelId"
                        :label-width="formLabelWidth">
                  <f-select
                      :dict="'channel_set'"
                      v-model="form.channelId"
                      :is-need="form.channelId"
                  ></f-select>
                </el-form-item>
                <el-form-item label="国家" prop="country" :label-width="formLabelWidth">
                   <el-input v-model="form.country" maxlength="50" show-word-limit></el-input>
                </el-form-item>
                <el-form-item
                        label="航线名称"
                        prop="solicitation"
                        :label-width="formLabelWidth"
                >
                    <f-select
                            :dict="'fba_solicitation'"
                            v-model="form.solicitation"
                            :is-need="form.solicitation"
                    ></f-select>
                </el-form-item>
                <el-form-item
                        label="是否包税"
                        prop="hasTax"
                        :label-width="formLabelWidth"
                >
                    <f-select
                            :dict="'is_default'"
                            v-model="form.hasTax"
                            :is-need="form.hasTax"
                    ></f-select>
                </el-form-item>
              <el-form-item
                  label="币种"
                  prop="moneyType"
                  :label-width="formLabelWidth"
              >
                <f-select
                    :dict="'bd_cur'"
                    v-model="form.moneyType"
                    :is-need="form.moneyType"
                ></f-select>
              </el-form-item>
              <el-form-item label="单价" prop="price" :label-width="formLabelWidth">
                <el-input v-model="form.price" type="number"></el-input>
              </el-form-item>
              <el-form-item label="最低方数" prop="minCbm" :label-width="formLabelWidth">
                <el-input v-model="form.minCbm" type="number"></el-input>
              </el-form-item>
              <el-form-item label="最低公斤数" prop="minKg" :label-width="formLabelWidth">
                <el-input v-model="form.minKg" type="number"></el-input>
              </el-form-item>
              <el-form-item label="计费数量" prop="countNum" :label-width="formLabelWidth">
                <el-select v-model="form.countNum" clearable filterable>
                  <el-option v-for="(i,d) in countNumList" :value="i.value" :label="i.label" :key="d"></el-option>
                </el-select>
              </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="medium" @click="dialogFormVisible = false">取 消</el-button>
                <el-button size="medium" type="primary" @click="saveImporter" :disabled="btnDisable"
                >确 定
                </el-button
                >
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import QueryMixins from "@/mixins/QueryMixins.js";
    import {deleteAction, postAction, putAction} from "@/api/manage";
    import {getAction} from "../../api/manage";
    import {emailPattern} from "../../utils/pattern";

    export default {
        name: "LockFeeModule",
        mixins: [QueryMixins],
        components: {},
        data() {
            return {
                deliveryList: [],
                btnDisable: false,
                row: {},
                form: {},
                dialogFormVisible: false,
                formLabelWidth: "120px",
                countrylist: [],

                //表单校验
                rules: {
                   price:[
                     {required: true, message: "请输入单价", trigger: "change"},
                   ],
                  moneyType:[
                    {required: true, message: "请选择币种", trigger: "change"},
                  ],
                    channelId: [
                        {required: true, message: "请选择渠道名称", trigger: "change"},
                    ],
                  feeId: [
                    {required: true, message: "请选择费用", trigger: "change"},
                  ],
                  countNum: [
                    {required: true, message: "请选择计费数量", trigger: "change"},
                  ],

                },
                visible: false,
                columns: [
                  {
                    type: 'selection',
                    width: 33
                  },
                    {
                        prop: "action",
                        label: "操作",
                    },
                    {
                        prop: "channelName",
                        label: "渠道名称",
                    },
                    {
                        prop: "country",
                        label: "国家",
                    },
                    {
                        prop: "solicitationText",
                        label: "航线名称",
                    },
                    {
                        prop: "hasTax",
                        label: "是否包税",
                    },
                  {
                    prop: "feename",
                    label: "费用名称",
                  },
                  {
                    prop: "feenameUs",
                    label: "费用英文名",
                  },
                  {
                    prop: "feecode",
                    label: "费用编码",
                  },
                  {
                    prop: "price",
                    label: "单价",
                  },
                  {
                    prop: "moneyType",
                    label: "币种",
                  },
                  {
                    prop: "countNum",
                    label: "计费数量",
                    overFlow:true,
                  },
                  {
                    prop: "minCbm",
                    label: "最低方数",
                  },
                  {
                    prop: "minKg",
                    label: "最低公斤数",
                  },
                  {
                    prop: "createTime",
                    label: "录入时间",
                  },
                  {
                    prop: "createByStr",
                    label: "录入人",
                  },
                  {
                    prop: "updateTime",
                    label: "修改时间",
                  },
                  {
                    prop: "updateByStr",
                    label: "修改人",
                  },
                ],
                url: {
                    list: "/sys/lockFee/list",
                    delByid: "/sys/lockFee/delete/",
                    delDuo: "/sys/lockFee/deleteDuo",
                    add: "/sys/lockFee/add",
                    edit: "/sys/lockFee/edit",
                    import: "/sys/lockFee/import",
                    download: "/sys/lockFee/downloadExcel",
                },
                countNumList:[
                  {value:'A',label:'过磅重与体积重取大者，如无过磅重按照预报重量/体积重取大者，体积重=入仓体积*167'},
                  {value:'B',label:'入仓体积与过磅重÷363取大者，如无过磅重按照入仓体积与预报重量÷363取大者'},
                  {value:'C',label:'入仓体积与过磅重÷350取大者，如无过磅重按照入仓体积与预报重量÷350取大者'},
                ],

            };
        },
        created() {
        },
        methods: {
          handleDelDuo() {
            this.$confirm(`确定删除吗`, "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "success",
              closeOnClickModal: false,
            })
                .then(() => {
                  postAction(this.url.delDuo, this.selectedRowKeys).then((res) => {
                    this.$message({
                      type: "success",
                      message: res.message,
                    });
                    this.handleSearch();
                  });
                });
          },
          subLongStr(val){
            if (val && val.length >= 15){
              return val.substring(0,15) +"...";
            }else {
              return val;
            }
          },
          parseCountNum(val){
            if (val) {
              for (let i = 0; i < this.countNumList.length; i++) {
                let obj=this.countNumList[i];
                if (val === obj.value){
                  return obj.label;
                }
              }
            }
          },
            handleClose() {
                if (this.$refs["form"]) {
                    this.$refs["form"].clearValidate();
                }
            },
            //导入操作绑定
            importOperation(data) {
                console.log("上传文件", data);
                const formData = new FormData();
                formData.append("file", data.file);
                postAction(this.url.import, formData)
                    .then((res) => {
                        console.log("产品返回数据", res);
                        if (res.success) {
                            this.$message.success(res.message);
                            this.handleQuery();
                        }
                    })
            },
            beforeUpload(file) {
                //对文件大小进行限制
                const isLt2M = file.size / 1024 / 1024 < 15;
                if (!isLt2M) {
                    this.$message.warning("文件大小不能超过15MB");
                }
                const isFile = file.name.indexOf(".") !== -1;
                if (!isFile) {
                    this.$message.warning("该文件名不合法");
                }
                return isLt2M && isFile;
            },
            handleDel(row) {
                this.$confirm(`确定删除${row.operator}吗`, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "success",
                    closeOnClickModal: false,
                })
                    .then(() => {
                        deleteAction(this.url.delByid+row.id, {id: row.id}).then((res) => {
                            this.$message({
                                type: "success",
                                message: res.message,
                            });
                            this.handleSearch();
                        });
                    });
            },
            handleAdd() {
                this.form = {};
                this.dialogFormVisible = true;
                this.btnDisable = false;
                this.handleClose();
            },
            handleEdit(row) {
                this.btnDisable = false;
                for (var name in row) {
                    this.$set(this.form, name, row[name]);
                }
                this.dialogFormVisible = true;
            },
            saveImporter() {
                this.btnDisable = true;
                console.log("进口商对象", this.form);
                this.$refs["form"].validate((valid) => {
                    if (valid) {
                        if (this.form.id) {
                            putAction(this.url.edit, this.form)
                                .then((res) => {
                                    if (res.success) {
                                        this.$message.success(res.message);
                                        this.dialogFormVisible = false;
                                        this.handleQuery();
                                    } else {
                                        this.btnDisable = false;
                                    }
                                })
                                .catch((err) => {
                                    this.btnDisable = false;
                                });
                        } else {
                            postAction(this.url.add, this.form)
                                .then((res) => {
                                    if (res.success) {
                                        this.$message.success(res.message);
                                        this.dialogFormVisible = false;
                                        this.handleQuery();
                                    } else {
                                        this.btnDisable = false;
                                    }
                                })
                                .catch((err) => {
                                    this.btnDisable = false;
                                });
                        }
                    } else {
                        this.btnDisable = false;
                    }
                });
            },
        },
    };
</script>

<style lang="scss" scoped>
    .bbb {
        display: flex;
    }

    .dialog-footer {
        text-align: right !important;
    }
    .margin_right_10 {
      margin-right: 10px;
    }
</style>
