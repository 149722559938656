<template>
    <div>
        <el-card class="box-card">
            <el-form class="multiline_select" ref="form" :model="queryForm" label-width="80px" size="medium">
                <el-card class="box-card">
                    <el-row  class="inputs_row">
                        <el-col :xs="24" :sm="12" :md="12" :lg="6">
                            <el-form-item label="订单号">
                                <el-input v-model="queryForm.orderNo" placeholder="多个单号需要完整单号" type="textarea"
                                          @keyup.enter.native="handleSearch"></el-input>
                            </el-form-item>
                        </el-col>


                        <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="6">
                            <el-form-item label="渠道">
                                <f-select :dict="'transportStatus'" v-model="queryForm.channelIdList" :multiple="true"
                                          :isNeed="queryForm.channelIdList" @keyup.enter.native="handleSearch"></f-select>
                            </el-form-item>
                        </el-col>

                        <el-col class="no_margin" :xs="24" :sm="12" :md="12" :lg="6">
                            <el-form-item label="拼柜仓库">
                                <f-select :dict="'lclPlace'" v-model="queryForm.lclPlaceIdList" :multiple="true"
                                          :isNeed="queryForm.lclPlaceIdList" @keyup.enter.native="handleSearch"></f-select>
                            </el-form-item>
                        </el-col>


                        <el-col :xs="24" :sm="12" :md="12" :lg="4">
                            <el-form-item label-width="30px">
                                <el-button
                                        type="primary"
                                        icon="el-icon-search"
                                        @click="handleSearch"
                                >查询
                                </el-button
                                >
                                <el-button plain @click="handleReset">重置</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>


                </el-card>
            </el-form>
            <div class="bbb">
                <el-button
                        type="primary"
                        size="medium"
                        @click="showDetail"
                        class="margin_right_10"
                        :disabled="selectionRows.length === 0"
                        v-if="buttonShowList.addck">创建其他出库
                </el-button>

                <!--                <el-button type="primary" size="medium" @click="exportExcelPlus(url.downloadExcelUrl,'仓储部开单记录数据')" v-if="$btnIsShow('warehouse2.0/warehousoReceipts','0','导出')">导出-->
                <!--                </el-button>-->
            </div>
            <f-table
                    v-loading="loading"
                    ref="GTable"
                    border
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor"
                    :columns="columns"
                    :data="tableData"
                    @selection-change="handleSelectionChange"
                    @sort-change="handleTableChange"
                    :cell-style="{ 'text-align': 'center' }"
                    tableHeight="73vh"
            >
                <template v-slot="scope">

                    <template v-if="scope.column.property === 'orderNo'">
                        <a style="color: #00a0e9" @click.prevent="$intoDocPage(scope.row.orderNo)">{{ scope.row.orderNo
                            }}</a>
                    </template>


                </template>
            </f-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange">
                </el-pagination>
            </el-row>
        </el-card>
        <add-other-warehouse-out-dialog :visible.sync="addOtherWarehouseOutDialogVisible" :innerDataList="selectionRows"
                                        :sortRuleDtoList = sortRuleDtoList
                                        @ok="handleSearch"></add-other-warehouse-out-dialog>
    </div>
</template>

<script>
    import QueryMixins from "@/mixins/QueryMixins.js";
    import {postAction} from "@/api/manage";
    import addOtherWarehouseOutDialog from "@/views/warehouse2.0/modules/addOtherWarehouseOutDialog";

    export default {
        name: "WaitOtherWarehouseOut",
        mixins: [QueryMixins],
        components: {addOtherWarehouseOutDialog},
        data() {
            return {
                row: {},
                isUsePost: true,
                dialogFormVisible: false,
                addOtherWarehouseOutDialogVisible: false,
                formLabelWidth: "120px",
                //表单校验

                visible: false,
                //维护 派送费 价格模板计算 （费用名称，最小值，最大值，单位，fbacode，成本，（美元转cny汇率），基数，公式展示（(成本*汇率)/基数），计算出来的单价），结算对象
                columns: [
                    {
                        type: "selection",
                    },

                    {
                        prop: "orderNo",
                        label: "订单号",
                    },
                    {
                        prop: "lclPlaceName",
                        label: "拼柜仓库",
                    },
                    {
                        prop: "realCtn",
                        label: "实际件数",
                    },
                    {
                        prop: "realKg",
                        label: "实际重量",
                    },
                    {
                        prop: "realCmb",
                        label: "实际体积",
                    },
                    {
                        prop: "channelName",
                        label: "订单渠道",
                    },
                    {
                        prop: "createTime",
                        label: "订单录入时间",
                    },

                ],
                url: {
                    list: "/warehouse/stock/queryWaitWarehouseOtherOutData",

                    //查询
                    querySortRuleDtoListByOrderNoList:'/warehouse/stock/querySortRuleDtoListByOrderNoList'
                },
                selectedOrderNos: '',
                sortRuleDtoList:[],

            };
        },
        methods: {


            showDetail() {
                postAction(this.url.querySortRuleDtoListByOrderNoList,this.selectionRows.map(s=>s.orderNo))
                    .then(res=>{
                        this.$set(this,'sortRuleDtoList',res.result);
                        this.addOtherWarehouseOutDialogVisible = true;
                    })

            },

        },
      computed:{
        buttonShowList() {
          return {
            'addck':this.$btnIsShow('warehouse2.0/WaitOtherWarehouseOut','0','创建其他出库'),
          }
        }
      }
    };
</script>

<style lang="scss" scoped>
    .bbb {
        display: flex;
    }

    .dialog-footer {
        text-align: right !important;
    }

    .margin_right_10 {
        margin-right: 10px;
    }
</style>
